import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import AdminFacialRecognitionAutomaticEnrolmentsContainer from '../../../containers/AdminFacialRecognitionAutomaticEnrolmentsContainer';

export interface IVenueFacialRecognitionNotificationsEventsProps {
    venueId: number;
    venueMode: boolean;
    history: any;
    location: any;
}

class VenueFacialRecognitionNotificationsEvents extends Component<IVenueFacialRecognitionNotificationsEventsProps, any> {
    
    render() {
        return <AdminFacialRecognitionAutomaticEnrolmentsContainer venueId={this.props.venueId} venueMode={true} />
    }
}

export default withStyles(styles, { withTheme: true })(VenueFacialRecognitionNotificationsEvents);