import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../../../common/ImagePreview';
import DocumentIcon from '../../../../resources/DocumentIcon';
import CheckListItemAttachmentFile from './CheckListItemAttachmentFile';

export interface ICheckListItemAttachmentProps {
    checkListDisplayItem: CheckListDisplayItem;
}

interface ICheckListItemAttachmentState {
    isLoading: boolean;
    preview: string;
}

class CheckListItemAttachment extends Component<ICheckListItemAttachmentProps, ICheckListItemAttachmentState> {
    constructor(props: ICheckListItemAttachmentProps) {
        super(props)
        this.state = {
            isLoading: false,
            preview: ""
        }
        this.onPreview = this.onPreview.bind(this);
    }

    onPreview(show: boolean) {
        if (this.props.checkListDisplayItem.template.attachment.fileType == "application/pdf") {
            this.setState({ preview: !show ? "" : `data:application/pdf;base64,${this.props.checkListDisplayItem.template.attachment.data}` })
        } else {
            this.setState({ preview: !show ? "" : `data:${this.props.checkListDisplayItem.template.attachment.fileType}/jpeg;base64,${this.props.checkListDisplayItem.template.attachment.data}` })
        }
    }

    render() {
        let isPdf = this.props.checkListDisplayItem.template.attachment && this.props.checkListDisplayItem.template.attachment.fileType == "application/pdf";
        
        return (
            <Grid container>
                <Grid item xs={12}>
                    {isPdf && <>
                        <a style={{ color: '#919191', display: 'block', textAlign: 'center', margin: '10px' }}
                            download={this.props.checkListDisplayItem.template.attachment.fileName}
                            href={`data:application/pdf;base64,${this.props.checkListDisplayItem.template.attachment.data}`}
                            title='Download pdf document'>
                                <DocumentIcon
                                    fill={"#919191"}
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        margin: '0 10px'
                                    }}
                                />
                                {this.props.checkListDisplayItem.template.attachment.fileName}
                        </a>
                    </>}
                    {!isPdf && this.props.checkListDisplayItem.template.attachment && (
                        <div style={{ display: 'block', textAlign: 'center' }} onClick={() => this.onPreview(true)}>
                            <img src={`data:${this.props.checkListDisplayItem.template.attachment.fileType}/jpeg;base64,${this.props.checkListDisplayItem.template.attachment.data}`}
                                style={{ objectFit: 'contain', maxWidth: '90%', maxHeight: '200px' }} />
                        </div>
                    )}
                     <CheckListItemAttachmentFile checkListDisplayItem={this.props.checkListDisplayItem} />
                </Grid>
                <ImagePreview filePath={this.state.preview} fileName={this.props.checkListDisplayItem.template.name} onDismissed={() => this.onPreview(false)} />
               
            </Grid>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemAttachment);