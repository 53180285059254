import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import TimeZoneDateControlGroup from '../controls/TimeZoneDateControlGroup';
import TextAreaControlGroup from '../controls/TextAreaControlGroup';
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import FullPageDialog from '../layout/FullPageDialog';

export interface ISignOnRegisterBreakProps {
    signOnRegisterId: number;
    signOnRegisterBreak: AusComplyDTOs.SignOnRegisterBreak;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    onSet: Function;
    onSave: Function;
    onCancel: Function;
    onClose?: Function;
    classes: any;
    theme: any;
}

interface ISignOnRegisterBreakState {
    open: boolean;
}

class SignOnRegisterBreak extends React.Component<ISignOnRegisterBreakProps, ISignOnRegisterBreakState> {

    constructor(props: ISignOnRegisterBreakProps) {
        super(props);
        this.state = {
            open: false
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && this.props.error == "") {
            this.props.onCancel();
            if (this.props.onClose){
                this.props.onClose();
            }
        } else if (this.props.signOnRegisterId == 0 && this.state.open) {
            this.setState({ open: false });
        } else if (this.props.signOnRegisterId !== 0 && !this.state.open) {
            this.setState({ open: true });
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let signOnRegisterBreak = { ...this.props.signOnRegisterBreak };
        signOnRegisterBreak[fieldName] = value;
        this.props.onSet(signOnRegisterBreak)
    }

    onSave() {
        this.props.onSave(this.props.signOnRegisterBreak);
    }

    onCancel() {
        this.props.onCancel()
    }

    render() {
        const { classes, theme, signOnRegisterBreak, signOnRegisterId, isLoading, isSaving, error } = this.props;
        let self = this;
        let content;
        let started: boolean = signOnRegisterBreak && signOnRegisterBreak.signOnRegisterBreakId > 0;

        if (!isLoading && signOnRegisterBreak) {
            content = <>
                <TimeZoneDateControlGroup
                    text='Start'
                    date={signOnRegisterBreak.startDate}
                    readonly={started}
                    onChange={(value) => this.onValueChanged("startDate", value)}
                />
                {started && (
                    <TimeZoneDateControlGroup
                        text='End'
                        date={signOnRegisterBreak.endDate}
                        onChange={(value) => this.onValueChanged("endDate", value)}
                    />
                )}
                <TextAreaControlGroup
                    text='Note'
                    defaultValue={signOnRegisterBreak.notes}
                    onChange={(value) => this.onValueChanged("notes", value)} />
            </>
        }


        return <FullPageDialog
            open={this.state.open}
            notFullWidth={true}
            title="Break"
            error={error}
            loading={isLoading}
            saving={isSaving}
            footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
            footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave} />}
            onDismissed={this.onCancel}>
            {content}
        </FullPageDialog>;
    }
}

export default withStyles(styles, { withTheme: true })(SignOnRegisterBreak);
