import { connect } from 'react-redux' 
import ThemeSwitcher from '../components/general/ThemeSwitcher'

const mapStateToProps = state => {
    return {
        isDark: state.profile.isDark,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const ThemeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ThemeSwitcher)

export default ThemeContainer