import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import UserIcon from '../../resources/UserIcon';
import { appSource } from '../../utilities/constants';

export interface IProfileAvatarProps {
    avatar: AusComplyDtos.File;
    style?: any;
    classes: any;
    theme: any;
}

class ProfileAvatar extends React.Component<IProfileAvatarProps, any> {
    render() {
        const { classes, theme } = this.props;
        let avatar;

        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }

        // empty profile picture - default
        if (this.props.avatar && this.props.avatar.isImage) {
            avatar = (<div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden', textAlign: 'center', borderWidth: '2px', borderColor: 'white', borderStyle: 'solid', ...this.props.style }}>
                <img src={base + this.props.avatar.filePreviewPath}
                    style={{ objectFit: 'cover', width: '30px', height: '30px', ...this.props.style }}
                    alt="avatar" />
            </div>)
        } else  {

            avatar = (<UserIcon style={{ width: '30px', height: '30px', ...this.props.style }} />)
        }
        return (
            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                {avatar}
            </Box>
        );

    }
}
export default withStyles(styles, { withTheme: true })(ProfileAvatar);