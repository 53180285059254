import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import DefaultButton from '../../controls/DefaultButton';
import Box from '@material-ui/core/Box';
import NoRowsMessage from '../../common/NoRowsMessage';
import TableContainer from '../../layout/TableContainer';
import TextControl from '../../controls/TextControl';
import SelectControl from '../../controls/SelectControl';
import TableRow from '../../common/TableRow';
import TransparentButton from '../../controls/TransparentButton';

export interface IEventFiltersProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
    theme: any;
    classes: any;
}

interface IEventFiltersState {
    items: AusComplyDtos.ExternalEventFilter[];
}

class EventFilters extends Component<IEventFiltersProps, IEventFiltersState> {
    constructor(props: IEventFiltersProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onItemValueChanged = this.onItemValueChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.externalEvent) !== JSON.stringify(this.props.externalEvent)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: AusComplyDtos.ExternalEventFilter[] = [];
        if (this.props.externalEvent && this.props.externalEvent.externalEventFilters) {
            items = [...this.props.externalEvent.externalEventFilters];
        }
        this.setState({
            items
        });
    }

    onAdd() {
        let externalEvent = { ...this.props.externalEvent };
        var filter = new AusComplyDtos.ExternalEventFilter();
        filter.operator = AusComplyDtos.ngtOperator.Equal;
        externalEvent.externalEventFilters = [
            ...externalEvent.externalEventFilters,
            filter
        ];
        this.props.onUpdate(externalEvent);
    }

    onRemove(index: number) {
        let externalEvent = { ...this.props.externalEvent };
        
        externalEvent.externalEventFilters.splice(index, 1);
        this.props.onUpdate(externalEvent);
    }

    onItemValueChanged(index: number, fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent.externalEventFilters[index][fieldName] = value;
        this.props.onUpdate(externalEvent);
    }

    onChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        const { externalEvent } = this.props;
        const { classes } = this.props;

        let actions;
        let filters;
        if (externalEvent) {
            actions = <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}>
                </Box>
                <Box p={0}>
                    <DefaultButton onClick={this.onAdd} text="Add" />
                </Box>
            </Box>
            if (externalEvent.externalEventFilters && externalEvent.externalEventFilters.length > 0) {
                let rows = externalEvent.externalEventFilters.map((item, index) => {
                    return (
                        <TableRow>
                            <td>
                                <TextControl
                                    value={item.fieldName || ''}
                                    onChange={value => this.onItemValueChanged(index, "fieldName", value)}
                                    onBlur={value => this.onItemValueChanged(index, "fieldName", value)} />
                            </td>
                            <td>
                                <SelectControl
                                    onChange={(value) => this.onItemValueChanged(index, "operator", value)}
                                    defaultValue={item.operator}>
                                        <option value={AusComplyDtos.ngtOperator.Equal}>{"="}</option>
                                        <option value={AusComplyDtos.ngtOperator.EqualAny}>{"IN"}</option>
                                        <option value={AusComplyDtos.ngtOperator.GreaterThan}>{">"}</option>
                                        <option value={AusComplyDtos.ngtOperator.GreatOrEqualThan}>{">="}</option>
                                        <option value={AusComplyDtos.ngtOperator.LessThan}>{"<"}</option>
                                        <option value={AusComplyDtos.ngtOperator.LessOrEqualThan}>{"<="}</option>
                                </SelectControl>
                            </td>
                            <td>
                                <TextControl
                                    value={item.values || ''}
                                    onChange={value => this.onItemValueChanged(index, "values", value)}
                                    onBlur={value => this.onItemValueChanged(index, "values", value)} />
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <TransparentButton onClick={() => this.onRemove(index)} style={{ width: 'auto' }} text={"Remove"}></TransparentButton>
                            </td>
                        </TableRow>
                    );
                });
                filters = (
                    <TableContainer
                        header={
                            <tr>
                                <th className={classes.tableHrIndicator}></th>
                                <th className={classes.tableHr}>Field</th>
                                <th className={classes.tableHr}>Operator</th>
                                <th className={classes.tableHr}>Value</th>
                                <th className={classes.tableHr}></th>
                            </tr>
                        }
                        rows={rows}
                    />
                );
            } else {
                filters = <NoRowsMessage message={"No Filters"} />
            }
        }
        return (
            <CardContainer
                title={"Filters"}>
                <CardContent>
                    {filters}
                    {actions}
                </CardContent>
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(EventFilters)