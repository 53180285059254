import { connect } from 'react-redux'
import Directory from '../components/directory/Directory'
import * as directoryActions from '../common/actions/directory'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        entityType: AusComplyDtos.ngtEntityType.User,
        id: props.userId,
        tabs: props.tabs,
        isLoading: state.directories.isLoading,
        isSaving: state.directories.isSaving,
        directory: state.directories.directory,
        showObsolete: state.directories.showObsolete,
        readOnly: props.readOnly,
        title: props.title,
        complianceDocumentsOnly: props.complianceDocumentsOnly,
        canViewObsolete: permissionsLogic.isLevelSix(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (entityType, id, showObsolete) => {
            dispatch(directoryActions.directoryRequest(entityType, id, showObsolete))
        },
        onUpload: (documentUpload) => {
            dispatch(directoryActions.directoryUploadRequest(documentUpload))
        },
        onUpdate: (document) => {
            dispatch(directoryActions.directoryUpdateRequest(document))
        },
        onRemove: (document) => {
            dispatch(directoryActions.directoryRemoveRequest(document))
        },
        onUpsertFolder: (entityType, id, folder) => {
            dispatch(directoryActions.directoryUpsertFolderRequest(entityType, id, folder))
        }
    }
}

const UserDirectoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Directory)

export default UserDirectoryContainer