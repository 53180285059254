import React, { Component } from 'react';
import TransparentButton from './TransparentButton';
import ImagePreview from '../common/ImagePreview';

export interface IImageProcessingProps {
    incidentDocumentId?: number;
    incidentPatronPhotoId?: number;
    isProcessing: boolean;
    hasPermission: boolean;
    onProcessTemporaryFile: Function;
    onProcessIncidentDocument: Function;
    onProcessIncidentPatronPhoto: Function;
    onDismissed: Function;
    filePath: string;
    fileName?: string;
    imageText?: string;
    processedImageText: string;
    saveToDocument?: boolean;
}

export default class ImageProcessing extends React.PureComponent<IImageProcessingProps, any> {
    
    render() {
        let self = this;
        
        if (!this.props.hasPermission || (!this.props.filePath && !this.props.incidentDocumentId && !this.props.incidentPatronPhotoId)) return <ImagePreview 
            filePath={this.props.filePath} 
            fileName={this.props.fileName} 
            imageText={this.props.imageText}
            onDismissed={this.props.onDismissed} />

        let processButton = <TransparentButton 
            disabled={this.props.isProcessing} 
            text={"Scan Image" + (this.props.isProcessing ? "..." : "")} 
            onClick={() => {
                if (self.props.incidentDocumentId === 0) {
                    self.props.onProcessTemporaryFile(this.props.filePath);
                } else if(self.props.incidentDocumentId) {
                    self.props.onProcessIncidentDocument(self.props.incidentDocumentId, this.props.saveToDocument);
                } else if (self.props.incidentPatronPhotoId) {

                }
            }} />;

        return <ImagePreview 
            filePath={this.props.filePath} 
            fileName={this.props.fileName} 
            processButton={processButton}
            imageText={this.props.imageText}
            onDismissed={this.props.onDismissed} />
    }
}
