import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageLayout from '../../layout/PageLayout';
import ResponsiveList from '../../common/ResponsiveList';

export interface ISecurityFirmVenuesProps {
    isLoading: boolean;
    securityFirmId: number;
    items: AusComplyDtos.SecurityFirmVenueSummary[];
    paging: AusComplyDtos.Paging;
    onLoad: Function;
    isLevelSix: boolean;
    history: any;
    location: any;
}

export default class SecurityFirmVenues extends Component<ISecurityFirmVenuesProps, any> {
    constructor(props: ISecurityFirmVenuesProps) {
        super(props)
        this.handlePageSelected = this.handlePageSelected.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId, this.props.paging.pageSize, this.props.paging.page);
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.securityFirmId, this.props.paging.pageSize, page);
    }

    render() {
        return <PageLayout
            headerText={"Venue Associations"}
        ><ResponsiveList
                loading={this.props.isLoading}
                data={this.props.items}
                paging={this.props.paging}
                onPageSelected={i => this.handlePageSelected(i)}
                headers={["Venue", "Suburb", "State", "Phone", "Manager(s)", "Parent MLH", "Created", "Modified"]}
                columns={["venue", "suburb", "stateDisplayName", "phone", "contactName", "parentMLH", "dateEnteredDisplay", "dateModifiedDisplay"]}
                getCommands={item => []}
                onCommand={(command, item) => {}}
                isObsolete={item => item.obsolete}
                toggleObsolete={false}
                canEdit={false}
                onGetLink={(columnName, rowData) => {
                    if (columnName == "venue" && this.props.isLevelSix) {
                        return "/admin/venue/" + rowData["venueId"];
                    }
                    return "";
                }}
            />
        </PageLayout>
    }
}