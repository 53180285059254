
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const venueReportingPeriodScheduleLogic = {

    getCommands(venueReportingPeriodSchedule: AusComplyDtos.VenueReportingPeriodSchedule) {
        var commands: KeyName[] = [];
        if (venueReportingPeriodSchedule.active) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}