import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as subLocationActions from "../actions/subLocation";

const initialState = {
    error: null,
    errors: {},
    subLocations: {
        isLoading: false,
        filter: {},
        subLocations: [],
        paging: { pageSize: 10, page: 1 }
    },
    subLocation: {
        subLocationId: -1,
        isLoading: false,
        isSaving: false,
        subLocation: { subLocationId: 0 }
    },
};


function loadingSubLocations(state, isLoading) {
    let newState = update(state, {
        subLocations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSubLocations(state, subLocations, filter, paging) {
    let newState = update(state, {
        subLocations: {
            subLocations: { $set: subLocations },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingSubLocation(state, isLoading) {
    let newState = update(state, {
        subLocation: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSubLocation(state, subLocation) {
    let newState = update(state, {
        subLocation: {
            subLocation: { $set: subLocation }
        }
    });
    return newState;
}

function savingSubLocation(state, isSaving) {
    let newState = update(state, {
        subLocation: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setSubLocationId(state, subLocationId) {
    let newState = update(state, {
        subLocation: {
            subLocationId: { $set: subLocationId }
        }
    });
    return newState;
}

export default function subLocations(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case subLocationActions.SUBLOCATIONS_REFRESH:
            return loadingSubLocations(setSubLocationId(state, -1), true);
        case subLocationActions.SUBLOCATIONS_REQUEST:
            return setSubLocations(loadingSubLocations(setSubLocationId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case subLocationActions.SUBLOCATIONS_REQUEST_SUCCESS:
            return setSubLocations(loadingSubLocations(state, false), action.data, action.filter, action.paging);
        case subLocationActions.SUBLOCATIONS_REQUEST_FAILURE:
            return loadingSubLocations(state, false);
        case subLocationActions.SUBLOCATION_RESET:
            return setSubLocationId(state, -1);
        case subLocationActions.SUBLOCATION_REQUEST:
            return setSubLocation(loadingSubLocation(setSubLocationId(state, action.subLocationId), true), {});
        case subLocationActions.SUBLOCATION_REQUEST_SUCCESS:
            return setSubLocation(loadingSubLocation(state, false), action.subLocation);
        case subLocationActions.SUBLOCATION_REQUEST_FAILURE:
            return loadingSubLocation(setSubLocationId(state, -1), false);
        case subLocationActions.SUBLOCATION_CREATE_REQUEST:
            return setSubLocation(loadingSubLocation(setSubLocationId(state, 0), true), {});
        case subLocationActions.SUBLOCATION_CREATE_REQUEST_SUCCESS:
            return setSubLocation(loadingSubLocation(state, false), action.subLocation);
        case subLocationActions.SUBLOCATION_CREATE_REQUEST_FAILURE:
            return loadingSubLocation(setSubLocationId(state, -1), false);
        case subLocationActions.SUBLOCATION_SAVE_REQUEST:
            return savingSubLocation(state, true);
        case subLocationActions.SUBLOCATION_SAVE_REQUEST_SUCCESS:
            return setSubLocation(savingSubLocation(setSubLocationId(state, -1), false), action.subLocation);
        case subLocationActions.SUBLOCATION_SAVE_REQUEST_FAILURE:
            return savingSubLocation(state, false);
        case subLocationActions.SUBLOCATION_UPDATE:
            return setSubLocation(state, action.subLocation);

    }

    return state;
}