import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUnderConstruction {
    theme: any;
    name: string;
}

class UnderConstruction extends Component<IUnderConstruction, any> {
    constructor(props) {
        super(props)
    }

    render() {
        const { theme } = this.props;
        return (
            <div>
               <p style={{color: theme.palette.text.primary}}>{this.props.name ? "The" : "This"} page {this.props.name} is under construction</p>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UnderConstruction);