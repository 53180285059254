import React, { Component } from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ResponsiveList from '../common/ResponsiveList';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../common/ImagePreview';
import Thumbnail from '../common/Thumbnail';
import Box from '@material-ui/core/Box';
import FileUpload from '../common/FileUpload';
import SelectControlGroup from '../controls/SelectControlGroup';
import SimpleDateControlGroup from '../controls/SimpleDateControlGroup';
import Grid from '@material-ui/core/Grid';
import View from '../common/View';
import ControlGroup from '../controls/ControlGroup';
import DefaultButton from '../controls/DefaultButton';
import Saving from '../common/Saving';
import Information from '../common/Information';
import Error from '../common/Error';

export interface IWelcomeUserComplianceDocumentsProps {
    isLoading: boolean;
    isSaving: boolean;
    documents: AusComplyDTOs.UserComplianceDocument[];
    documentTypes: AusComplyDTOs.DocumentType[];
    onLoad: Function;
    onSaveComplianceDocuments: Function;
    onRemoveComplianceDocument: Function;
    classes: any;
    theme: any;
}

interface IWelcomeUserComplianceDocumentsState {
    selectedFilePath: string;
    documentTypeId: number;
    files: AusComplyDTOs.File[];
    expiryDate: AusComplyDTOs.SimpleDate;
}

class WelcomeUserComplianceDocuments extends Component<IWelcomeUserComplianceDocumentsProps, IWelcomeUserComplianceDocumentsState> {
    constructor(props: IWelcomeUserComplianceDocumentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            documentTypeId: 0,
            files: [],
            expiryDate: new AusComplyDTOs.SimpleDate()
        };
        this.onUpload = this.onUpload.bind(this);
        this.onDocumentTypeChanged = this.onDocumentTypeChanged.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeTemporary = this.removeTemporary.bind(this);
        this.onExpiryDateChanged = this.onExpiryDateChanged.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    componentDidUpdate(prevProps: Readonly<IWelcomeUserComplianceDocumentsProps>, prevState: Readonly<IWelcomeUserComplianceDocumentsState>, snapshot?: any): void {
        if (!this.props.isSaving && prevProps.isSaving) {
            this.setState({
                documentTypeId: 0,
                files: [],
                expiryDate: new AusComplyDTOs.SimpleDate()
            })
        }
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    onExpiryDateChanged(value: AusComplyDTOs.SimpleDate) {
        this.setState({ expiryDate: value });
    }

    fileWasUploaded(temporaryFiles: AusComplyDTOs.File[]) {
        let files = [...this.state.files, ...temporaryFiles];
        this.setState({ files });
    }

    removeTemporary(index: number) {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({ files });
    }

    onDocumentTypeChanged(value) {
        this.setState({ documentTypeId: value });
    }

    onUpload() {
        this.props.onSaveComplianceDocuments(this.state.documentTypeId, this.state.expiryDate, this.state.files);
    }

    getCommands(item: any) {

    }

    onCommand(command: string, document: AusComplyDTOs.UserComplianceDocument) {

    }

    render() {
        let list;
        let hasDocuments = false;
        if (this.props.documents) {
            let activeDocuments = this.props.documents.filter(item => !item.obsolete);
            if (activeDocuments.length > 0) {
                hasDocuments = true;
                list = <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.documents}
                    headers={["Preview", "Name", "Document Type", "Expiry"]}
                    columns={["filePreviewPath", "originalFileName", "documentType", "dateOfExpiry.display"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={this.onCommand}
                    isDanger={item => !item.obsolete && item.expired}
                    isWarning={item => item.obsolete}
                    canEdit={false}
                    toggleDanger={false}
                    isDangerLabel={"Expired"}
                    isWarningLabel={"Obsolete"}
                />;
            }
        }

        let files = this.state.files.map((item, index) => {
            return (
                <Box p={0} key={"file-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        onPreview={path => this.onSelectFilePath(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporary(index)}
                    />
                </Box >
            );
        })

        return (
            <View>
                <Error message={"Please upload all relevant compliance documents using the upload form below."} />
                <CardContainer
                    isLoading={this.props.isLoading}
                    title={"Upload Compliance Documents"}>
                    <CardContent>
                        {this.props.isSaving && (<Saving />)}
                        {!this.props.isSaving && (
                            <>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <SelectControlGroup text={"Document Folder"} onChange={this.onDocumentTypeChanged}>
                                            <option value={0} />
                                            {this.props.documentTypes && this.props.documentTypes.map((item, index) => (
                                                <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                                            ))}
                                        </SelectControlGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SimpleDateControlGroup text="Expiry Date"
                                            date={this.state.expiryDate}
                                            onChange={(value) => this.onExpiryDateChanged(value)} />
                                    </Grid>
                                </Grid>
                                <FileUpload onFileWasUploaded={this.fileWasUploaded} alternateIcons={true} />
                                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                    {files}
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DefaultButton text={"Upload"} disabled={this.state.files.length === 0 || this.state.expiryDate.isEmpty} onClick={this.onUpload} />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        {hasDocuments && (
                            <ControlGroup text="Compliance Documents" labelAbove={true}>
                                {list}
                            </ControlGroup>
                        )}
                    </CardContent>
                </CardContainer>
                <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
            </View>
        );
    }
}
export default withStyles(styles, { withTheme: true })(WelcomeUserComplianceDocuments);