import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../common/Loading';
import FormErrorMessage from '../alerts/FormErrorMessage'
import { VenueWithRange, NamedValue } from '../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultButton from '../controls/DefaultButton';
import TransparentButton from '../controls/TransparentButton';
import DarkButton from '../controls/DarkButton';
import SingleSelectListControl from '../controls/SingleSelectListControl';
import { SingleSelectList } from '../../common/dto/common';
import Map from '../common/Map'
import PageTitle from '../common/PageTitle';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CardTitle from '../common/CardTitle';
import ButtonLink from '../controls/ButtonLink';
import { Palette } from '../../common/constants/palette';
import Error from '../common/Error';
import Box from '@material-ui/core/Box';
import View from '../common/View';
import MapLocationIcon from '../../resources/MapLocationIcon';
import RefreshIcon from '../../resources/RefreshIcon';
import LargeIconClearButton from '../controls/LargeIconClearButton';
import SmallIconClearButton from '../controls/SmallIconClearButton';
import ClearSelectionButton from '../controls/ClearSelectionButton';

export interface IVenueSecurityFirmSelectionProps {
    theme: any;
    classes: any;
    venueId: number;
    venueEventId: number;
    onCancelChangeVenue: Function;
    onRefresh: Function;
    onRefreshLocation: Function;
    isLoading: boolean;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    canSelectNoVenue: boolean;
    venues: VenueWithRange[];
    securityFirms: NamedValue[];
    onSelectVenue: Function;
    onSelectVenueSecurityFirm: Function;
    onClearVenue: Function;
}

class VenueSecurityFirmSelection extends Component<IVenueSecurityFirmSelectionProps, any> {
    constructor(props: IVenueSecurityFirmSelectionProps) {
        super(props)
        this.onCancel = this.onCancel.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onSelectVenue = this.onSelectVenue.bind(this);
        this.onSelectSecurityFirm = this.onSelectSecurityFirm.bind(this);
        this.handleCancelOutOfRangeSelection = this.handleCancelOutOfRangeSelection.bind(this);
        this.onSelectVenueOutOfRange = this.onSelectVenueOutOfRange.bind(this);
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
        this.onSelectNoVenue = this.onSelectNoVenue.bind(this);
        this.onToggleShowMap = this.onToggleShowMap.bind(this);
        this.onClearVenue = this.onClearVenue.bind(this);
        this.state = {
            openOutOfRangeAlert: false,
            outOfRangeVenue: {},
            noVenue: false,
            showMap: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // on changed...
        if (prevProps.isLocationLoading && !this.props.isLocationLoading) {
            this.props.onRefresh();
        }
        // loading finished
        if (prevProps.isLoading && !this.props.isLoading) {

            // is a venue selected
            if (this.props.venueId && this.props.venueId > 0) {
                // is there only one security firm option
                if (this.props.securityFirms && this.props.securityFirms.length == 1) {
                    this.props.onSelectVenueSecurityFirm(this.props.venueId, this.props.securityFirms[0].id, this.props.venueEventId);
                }
            } else if (!this.props.venueId || this.props.venueId === 0) {
                // is there only one venue option and the user cannot select no venue
                if (!this.props.canSelectNoVenue && this.props.venues && Array.isArray(this.props.venues) && this.props.venues.length === 1) {
                    // Auto select the first item
                    var venue = { id: this.props.venues[0].venueId, name: this.props.venues[0].display, isCommon: this.props.venues[0].inRange, selected: false, info: '', infoColor: '' };
                    this.onSelectedItemChanged(venue);
                }
            }
        }
    }

    onToggleShowMap() {
        this.setState({ showMap: !this.state.showMap })
    }

    onCancel() {
        this.props.onClearVenue();
        this.props.onCancelChangeVenue();
    }

    onRefresh() {
        this.props.onRefreshLocation();
    }

    onClearVenue() {
        this.props.onClearVenue();
    }

    onSelectedItemChanged(item) {
        if (item) {
            let selectedVenue;
            if (item.parentId === 1) {
                // no venue selected
                selectedVenue = new VenueWithRange();
                selectedVenue.venueId = 1;
                selectedVenue.inRange = true;
            } else {
                if (this.props.venues && Array.isArray(this.props.venues)) {
                    this.props.venues.forEach((venue) => {
                        if (venue.venueId === item.parentId && venue.venueEventId == item.subParentId) {
                            selectedVenue = venue;
                        }
                    });
                }
            }
            if (selectedVenue) {
                this.onSelectVenue(selectedVenue);
            }
        } else {
            this.props.onClearVenue();
            this.onRefresh();
        }
    }

    onSelectVenue(venue) {
        if (!venue.inRange) {
            this.setState({ openOutOfRangeAlert: true, outOfRangeVenue: venue });
        } else {
            this.props.onSelectVenue(venue);
        }
    }

    onSelectNoVenue() {
        let selectedVenue = new VenueWithRange();
        selectedVenue.venueId = 1;
        selectedVenue.inRange = true;
        this.props.onSelectVenue(selectedVenue);
    }

    onSelectVenueOutOfRange() {
        this.setState({ openOutOfRangeAlert: false }, () => {
            this.props.onSelectVenue(this.state.outOfRangeVenue)
        });
    }

    handleCancelOutOfRangeSelection = () => {
        this.setState({ openOutOfRangeAlert: false });
    };

    onSelectSecurityFirm(item) {
        this.props.onSelectVenueSecurityFirm(this.props.venueId, item.id, this.props.venueEventId);
    }

    render() {
        const { theme, classes } = this.props;

        let locationInformation;
        let noVenue = (<></>);
        let currentVenueId = 0;
        let currentVenueEventId;
        let venueSelect;
        let venueRecent;
        let securityFirmSelect;
        let venuesInRangeSelect;
        if (this.props.venueId && this.props.venueId > 0) {
            currentVenueId = this.props.venueId;
        }
        if (this.props.venueEventId && this.props.venueEventId > 0) {
            currentVenueEventId = this.props.venueEventId;
        }

        if (this.props.isLocationLoading) {
            locationInformation = (
                <View>
                    <Loading />
                    <p style={{ fontWeight: 200, textAlign: 'center', color: '#fff' }}>Refreshing location...</p>
                </View>
            );
        } else if (this.props.isLoading && currentVenueId === 0) {
            venueSelect = (
                <CardContainer title={"Search out of range venues"}>
                    <CardContent>
                        <Loading />
                    </CardContent>
                </CardContainer>
            );
        } else if (!this.props.isLoading) {
            if (this.props.venueId == 0) {
                let items: SingleSelectList[] = [];
                let itemInRanges: SingleSelectList[] = [];
                let itemRecent: SingleSelectList[] = [];
                if (this.props.venues && Array.isArray(this.props.venues)) {
                    this.props.venues.forEach((venue, index) => {
                        let info, infoColor;
                        if (venue.inRange) {
                            info = venue.range + " meters";
                            infoColor = "prefix"; ////"success";
                        }
                        if (venue.inRange) {
                            itemInRanges.push({ id: index, parentId: venue.venueId, subParentId: venue.venueEventId, name: venue.display, isCommon: true, selected: venue.venueId === currentVenueId && venue.venueEventId == currentVenueEventId, info: info, infoColor: infoColor });
                        } else {
                            items.push({ id: index, parentId: venue.venueId, subParentId: venue.venueEventId, name: venue.display, isCommon: false, selected: venue.venueId === currentVenueId && venue.venueEventId == currentVenueEventId, info: info, infoColor: infoColor });
                        }
                        if (venue.mostRecent) {
                            itemRecent.push({ id: index, parentId: venue.venueId, subParentId: venue.venueEventId, name: venue.display, isCommon: true, selected: venue.venueId === currentVenueId && venue.venueEventId == currentVenueEventId, info: info, infoColor: infoColor });
                        }
                    });
                }
                if (items.length > 0) {
                    venueSelect = (
                        <CardContainer title={"Search out of range venues"} style={{ paddingBottom: '0px' }}>
                            <CardContent>
                                <SingleSelectListControl
                                    items={items}
                                    selectedTitle="Venue"
                                    emptyMessage="Select a venue"
                                    hideEmptyMessage={true}
                                    availableTitle=""
                                    hideAvailableText={true}
                                    searchText="Type at least three letters..."
                                    minimumCharactersRequired={3}
                                    onSelectedItemChanged={this.onSelectedItemChanged}
                                    autoHideOnSelected={true}
                                ></SingleSelectListControl>
                            </CardContent>
                        </CardContainer>
                    );
                }
                if (itemInRanges.length > 0) {
                    venuesInRangeSelect = (
                        <View style={{padding: '10px 10px 0 10px' }}>
                            <SingleSelectListControl
                                items={itemInRanges}
                                selectedTitle="Venue"
                                emptyMessage="Select a venue"
                                commonTitle=" "
                                hideEmptyMessage={true}
                                availableTitle="Search out of range venues"
                                searchText="Type at least three letters..."
                                minimumCharactersRequired={3}
                                onSelectedItemChanged={this.onSelectedItemChanged}
                                autoHideOnSelected={true}
                            ></SingleSelectListControl></View>
                    );
                }
                if (itemRecent.length > 0) {
                    venueRecent = (
                        <CardContainer title={"Recent Venue"} style={{ padding: '0px' }}>
                            <CardContent style={{padding: '0 10px 10px'}}>
                                <SingleSelectListControl
                                    items={itemRecent}
                                    selectedTitle="Venue"
                                    emptyMessage="Select a venue"
                                    commonTitle=" "
                                    hideEmptyMessage={true}
                                    availableTitle="Search out of range venues"
                                    searchText="Type at least three letters..."
                                    minimumCharactersRequired={3}
                                    onSelectedItemChanged={this.onSelectedItemChanged}
                                    autoHideOnSelected={true}
                                ></SingleSelectListControl>
                            </CardContent>
                        </CardContainer>
                    );
                }

                if (this.props.canSelectNoVenue) {
                    noVenue = (
                        <Box p={1}>
                            <DefaultButton onClick={() => this.onSelectNoVenue()}>No Venue</DefaultButton>
                        </Box>
                    );
                }

            } else {
                let venue = this.props.venues.find(f => f.venueId == this.props.venueId && f.venueEventId == this.props.venueEventId);
                venuesInRangeSelect =
                    <ClearSelectionButton
                        text={venue ? venue.display : "Clear selection"}
                        onClick={() => this.onClearVenue()} />
            }
        }

        // insert venue select options
        if (this.props.isLocationLoading && !this.state.isLocationLoadingCancelled) {
        }

        if (!this.props.isLocationLoading) {
            let locationControls;
            let map;
            let errorMessage = this.props.locationError || "Failed to find location";

            errorMessage = errorMessage + ", click 'Refresh location' to try again.";
            if (this.props.locationError || !this.props.locationFound) {
                map = <View>
                    <Error message={errorMessage} style={{margin: 0}} />
                    <View style={{ padding: 10 }}></View>
                </View>;
            } else if (this.state.showMap && this.props.locationFound && this.props.latitude && this.props.longitude) {
                map = (
                    <Map
                        hideByDefault={false}
                        hideControls={true}
                        latitude={this.props.latitude}
                        longitude={this.props.longitude} />
                );
            }
            locationControls = this.state.showMap ? (
                <View style={{ margin: '10px', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                        <SmallIconClearButton
                            text={"Hide Location"}
                            icon={<MapLocationIcon
                                fill={Palette.ControlBorder} />}
                            onClick={() => this.onToggleShowMap()} />
                    </View>
                    <View className={classes.horizontalDivider} style={{ flex: 0 }}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <SmallIconClearButton
                            text={"Refresh"}
                            icon={<RefreshIcon fill={Palette.ControlBorder} />}
                            onClick={() => this.onRefresh()} />

                    </View>
                </View>
            ) : (
                <View style={{ margin: '10px', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                        <LargeIconClearButton
                            text={"Show Location"}
                            icon={<MapLocationIcon
                                fill={Palette.ControlBorder} />}
                            onClick={() => this.onToggleShowMap()} />
                    </View>
                    <View className={classes.horizontalDivider} style={{ flex: 0 }}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <LargeIconClearButton
                            text={"Refresh"}
                            icon={<RefreshIcon fill={Palette.ControlBorder} />}
                            onClick={() => this.onRefresh()} />

                    </View>
                </View>);

            locationInformation = <View>
                {map}
                {locationControls}
                {venuesInRangeSelect}
            </View>;

        }

        if (this.props.venueId && this.props.venueId > 0) {
            let securityFirms;
            if (this.props.isLoading) {
                securityFirms = (
                    <Loading />
                );
            } else {
                // security firms
                let items: SingleSelectList[] = [];
                this.props.securityFirms.forEach((securityFirm) => {
                    items.push({ id: securityFirm.id, name: securityFirm.name, isCommon: false, selected: false, info: "", infoColor: "" });
                });
                if (items.length > 0) {
                    securityFirms = (
                        <SingleSelectListControl
                            items={items}
                            hideEmptyMessage={true}
                            hideAvailableText={true}
                            //center={true}
                            selectedTitle="Security firm"
                            emptyMessage="Select security firm"
                            availableTitle="Select security firm"
                            onSelectedItemChanged={this.onSelectSecurityFirm}
                        ></SingleSelectListControl>
                    );
                }
            }

            securityFirmSelect = (
                <CardContainer title={"Select Security Firm"}>
                    <CardContent>
                        {securityFirms}
                    </CardContent>
                </CardContainer>
            );
        }

        return (
            <div>
                <CardContainer title={"Venue/s in Range"}>
                    {locationInformation}
                </CardContainer>
                {venueRecent}
                {venueSelect}
                {securityFirmSelect}
                <Box display="flex" flexDirection="row" justifyContent="center">
                    {noVenue}
                </Box>
                <Dialog
                    open={this.state.openOutOfRangeAlert}
                    onClose={this.handleCancelOutOfRangeSelection}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Confirm Venue Selection"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.outOfRangeVenue.name} does not appear to be nearby, are you sure you want to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DefaultButton onClick={this.handleCancelOutOfRangeSelection} style={{ minWidth: '70px', width: 'auto', marginRight: '5px' }}>No</DefaultButton>
                        <DefaultButton onClick={this.onSelectVenueOutOfRange} style={{ minWidth: '70px', width: 'auto', marginLeft: '5px' }} autoFocus>Yes</DefaultButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenueSecurityFirmSelection);