import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';

export interface IRegisterUserEmergencyContactProps {
    user: AusComplyDTOs.User;
    errors: any[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserEmergencyContact extends Component<IRegisterUserEmergencyContactProps, any> {
    constructor(props: IRegisterUserEmergencyContactProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error){
                return error.error;
            }
        }
        return "";
    }

    onChange(fieldname, value) {
        let user = { ...this.props.user };
        user[fieldname] = value;
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Emergency Contact"}>
                    <CardContent>
                        <LayoutForm>
                            <TextControlGroup
                                text={"Contact Name"}
                                error={this.fieldError("emergencyContactName")}
                                defaultValue={this.props.user.emergencyContactName}
                                onBlur={(value) => this.onChange("emergencyContactName", value)} />
                            <TextControlGroup
                                text={"Contact Mobile"}
                                error={this.fieldError("emergencyContactNumber")}
                                defaultValue={this.props.user.emergencyContactNumber}
                                onBlur={(value) => this.onChange("emergencyContactNumber", value)} />
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}