import update from "immutability-helper";
import * as profileActions from "../actions/profile";
import { userLogic } from '../logic/userLogic';

const initialState = {
    source: 'DEVELOPMENT', // default to development for now, change to production later
    isDark: true,
    profile: {},
    avatar: { isImage: false },
    genders: [],
    states: [],
    otherDocuments: [],
    otherDocumentTypes: [],
    complianceDocuments: [],
    complianceDocumentTypes: [],
    isProfileLoading: false,
    venuesAvailableToRequest: [],
    securityFirmsAvailableToRequest: [],
    isVenuesLoading: false,
    isSecurityFirmsLoading: false,
    isSaving: false,
    isCheckingLicense: false,
    errors: {},
    error: null,
    changePasswordError: null,
    groupAccessRequests: []
};

function loadingProfile(state, isLoading) {
    let newState = update(state, {
        isProfileLoading: { $set: isLoading },
        isSaving: { $set: false },
        isCheckingLicense: { $set: false },
        error: { $set: "" }
    });
    return newState;
}

function setProfile(state, profile, genders, states, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, avatar, groupAccessRequests) {
    let newState = update(state, {
        profile: { $set: profile },
        genders: { $set: genders },
        states: { $set: states },
        otherDocuments: { $set: otherDocuments },
        otherDocumentTypes: { $set: otherDocumentTypes },
        complianceDocuments: { $set: complianceDocuments },
        complianceDocumentTypes: { $set: complianceDocumentTypes },
        avatar: { $set: avatar },
        changePasswordError: { $set: "" },
        groupAccessRequests: { $set: groupAccessRequests }
    });
    return newState;
}

function setAvatar(state, avatar) {
    let newState = update(state, {
        avatar: { $set: avatar }
    });
    return newState;
}

function updateProfile(state, profile) {
    let newState = update(state, {
        profile: { $set: profile }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        otherDocuments: { $set: otherDocuments }
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        complianceDocuments: { $set: complianceDocuments }
    });
    return newState;
}

function updateApiSouce(state, source) {
    let newState = update(state, {
        source: { $set: source }
    });
    return newState;
}

function hydrateApiSource(state) {
    let newState = update(state, {
        privacyPolicy: { $set: content }
    });
    return newState;
}

function setTheme(state, isDark) {
    let newState = update(state, {
        isDark: { $set: isDark }
    });
    return newState;
}

function saving(state, isSaving, error) {
    let newState = update(state, {
        isSaving: { $set: isSaving },
        error: { $set: error }
    });
    return newState;
}

function isCheckingLicense(state, isCheckingLicense) {
    let newState = update(state, {
        isCheckingLicense: { $set: isCheckingLicense }
    });
    return newState;
}

function loadingVenues(state, isVenuesLoading) {
    let newState = update(state, {
        isSaving: { $set: false },
        isVenuesLoading: { $set: isVenuesLoading },
        error: { $set: null }
    });
    return newState;
}

function updateVenuesAvailableToRequestList(state, venues) {
    let newState = update(state, {
        venuesAvailableToRequest: { $set: venues }
    });
    return newState;
}

function loadingSecurityFirms(state, isSecurityFirmsLoading) {
    let newState = update(state, {
        isSecurityFirmsLoading: { $set: isSecurityFirmsLoading },
        error: { $set: null }
    });
    return newState;
}

function updateSecurityFirmsAvailableToRequestList(state, securityFirms) {
    let newState = update(state, {
        securityFirmsAvailableToRequest: { $set: securityFirms }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: userLogic.validateUser(state.profile) }
    });
    return newState;
}

function savingPasswordChange(state, isSaving, error) {
    let newState = update(state, {
        isSaving: { $set: isSaving },
        changePasswordError: { $set: error }
    });
    return newState;
}

export default function content(state = initialState, action) {
    switch (action.type) {
        case profileActions.PROFILE_RESET:
            return { ...initialState };
        case profileActions.PROFILE_REQUEST:
            return loadingProfile(setProfile(state, {}, [], [], [], [], [], [], { isImage: false }, []), true);
        case profileActions.PROFILE_REQUEST_SUCCESS:
            return loadingProfile(setErrors(setProfile(state, action.profile, action.genders, action.states, action.otherDocuments, action.otherDocumentTypes, action.complianceDocuments, action.complianceDocumentTypes, action.avatar, action.groupAccessRequests)), false);
        case profileActions.PROFILE_REQUEST_FAILURE:
            return loadingProfile(state, false);
        case profileActions.PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST:
            return loadingVenues(updateVenuesAvailableToRequestList(state, []), true);
        case profileActions.PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS:
            return loadingVenues(updateVenuesAvailableToRequestList(state, action.venues), false);
        case profileActions.PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_FAILURE:
            return loadingVenues(state, false);
        case profileActions.PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST:
            return loadingSecurityFirms(updateSecurityFirmsAvailableToRequestList(state, []), true);
        case profileActions.PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS:
            return loadingSecurityFirms(updateSecurityFirmsAvailableToRequestList(state, action.securityFirms), false);
        case profileActions.PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_FAILURE:
            return loadingSecurityFirms(state, false);
        case profileActions.PROFILE_SET_API_SOURCE:
            return updateApiSouce(state, action.source);
        case profileActions.PROFILE_SET_API_SOURCE_ON_HYRDATE:
            return hydrateApiSource(state);
        case profileActions.PROFILE_SET_THEME:
            return setTheme(state, action.isDark);
        case profileActions.PROFILE_USER_ROLE_REQUEST:
            return saving(state, true, null);
        case profileActions.PROFILE_USER_ROLE_REQUEST_SUCCESS:
            return saving(state, false, null);
        case profileActions.PROFILE_USER_ROLE_REQUEST_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_OTHER_DOCUMENTS_SAVE:
            return saving(state, true, null);
        case profileActions.PROFILE_OTHER_DOCUMENTS_SAVE_SUCCESS:
            return saving(setOtherDocuments(state, action.userOtherDocuments), false, null);
        case profileActions.PROFILE_OTHER_DOCUMENTS_SAVE_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_OTHER_DOCUMENT_REMOVE:
            return saving(state, true, null);
        case profileActions.PROFILE_OTHER_DOCUMENT_REMOVE_SUCCESS:
            return saving(setOtherDocuments(state, action.userOtherDocuments), false, null);
        case profileActions.PROFILE_OTHER_DOCUMENT_REMOVE_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENTS_SAVE:
            return saving(state, true, null);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS:
            return saving(setComplianceDocuments(state, action.userComplianceDocuments), false, null);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENT_REMOVE:
            return saving(state, true, null);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
            return saving(setComplianceDocuments(state, action.userComplianceDocuments), false, null);
        case profileActions.PROFILE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_UPDATE:
            return setErrors(updateProfile(state, action.profile));
        case profileActions.PROFILE_SAVE_REQUEST:
            return saving(state, true, null);
        case profileActions.PROFILE_SAVE_REQUEST_SUCCESS:
            return setErrors(saving(updateProfile(state, action.profile), false, null));
        case profileActions.PROFILE_SAVE_REQUEST_FAILURE:
            return saving(state, false, action.error);
        case profileActions.PROFILE_SLED_CHECK_REQUEST:
            return isCheckingLicense(state, true);
        case profileActions.PROFILE_SLED_CHECK_REQUEST_SUCCESS:
            return isCheckingLicense(setErrors(updateProfile(state, action.profile)), false);
        case profileActions.PROFILE_SLED_CHECK_REQUEST_FAILURE:
            return isCheckingLicense(state, false);
        case profileActions.PROFILE_UPDATE_AVATAR_REQUEST:
            return setAvatar(state, action.file);
        case profileActions.PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS:
            return setAvatar(state, action.file);
        case profileActions.PROFILE_CHANGE_PASSWORD_REQUEST:
            return savingPasswordChange(state, true, null);
        case profileActions.PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE:
            return savingPasswordChange(state, false, action.error);
        case profileActions.PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS:
            return savingPasswordChange(state, false, null);
        case profileActions.PROFILE_DELETE_REQUEST:
            return saving(state, true, null);
        case profileActions.PROFILE_DELETE_REQUEST_SUCCESS:
            return saving(state, false, null);
        case profileActions.PROFILE_DELETE_REQUEST_FAILURE:
            return saving(state, false, action.error);
    }
    return state;
}