import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import PrimaryButton from '../../controls/PrimaryButton';
import ErrorButton from '../../controls/ErrorButton';
import DefaultButton from '../../controls/DefaultButton';
import InfoButton from '../../controls/InfoButton';
import DraftSaveIcon from '../../../resources/DraftSaveIcon';
import ListIcon from '../../../resources/ListIcon';
import SaveIcon from '../../../resources/SaveIcon';
import CrossIcon from '../../../resources/CrossIcon';
import NavigationButton from '../../controls/NavigationButton';
import TransparentButton from '../../controls/TransparentButton';
import { Palette } from '../../../common/constants/palette';

export interface IChecklistTemplateNavigationProps {
    onCancel: Function;
    onSave: Function;
    onPreview: Function;
    isSaving: boolean;
}

export default class ChecklistTemplateNavigation extends Component<IChecklistTemplateNavigationProps, any> {

    render() {
        let buttonStyle = {
            minHeight: '30px',
            minWidth: '30px'
        };
        let cancelButton;
        let saveButton;
        let previewButton;


        cancelButton = (
            <Box flex={0} p={1}>
                <DefaultButton
                    className={"IncidentWizardNavigation-buttons"}
                    style={buttonStyle}
                    onClick={this.props.onCancel} >
                    <CrossIcon />
                </DefaultButton>
            </Box>
        );

        saveButton = (
            <Box flex={0} p={1}>
                <DefaultButton
                    className={"IncidentWizardNavigation-buttons"}
                    style={buttonStyle}
                    disabled={this.props.isSaving}
                    onClick={this.props.onSave} >
                    <SaveIcon />
                </DefaultButton>
            </Box>
        );

        previewButton = (
            <Box flex={0} p={1}>
                <InfoButton
                    className={"IncidentWizardNavigation-buttons"}
                    style={buttonStyle}
                    onClick={this.props.onPreview}
                    text={"Preview"} />
            </Box>
        );


        let saving;
        if (this.props.isSaving) {
            saving = <div style={{ position: 'absolute', top: '10px', left: '10px', width: '20px', height: '20px' }}>
                <SaveIcon fill={Palette.Primary} style={{ width: '20px', height: '20px' }} className="animation-pulse" />
            </div>;
        } else {
            saving = <></>;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: '0 20px',
                position: 'relative'
            }}>
                {saving}
                <div style={{ flex: 0 }}>
                    <NavigationButton
                        onClick={this.props.onCancel}
                        text={"Close"}
                        disabled={this.props.isSaving}
                        isError={true}
                        Icon={CrossIcon} />
                </div>
                <div style={{ flex: 0 }}>
                    <NavigationButton
                        onClick={this.props.onSave}
                        text={"Save"}
                        isSuccess={this.props.isSaving}
                        Icon={DraftSaveIcon}
                        active={true} />
                </div>
                <div style={{ flex: 0 }}>
                    <NavigationButton
                        onClick={this.props.onPreview}
                        text={"Preview"}
                        disabled={this.props.isSaving}
                        isInfo={true}
                        Icon={ListIcon} />
                </div>
            </div>
        );

    }
}