import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ImagePreview from './ImagePreview';
import Thumbnail from './Thumbnail';

export interface IFilePreviewProps {
    file: AusComplyDtos.File;
    small?: boolean; 
}

interface IFilePreviewState {
    selectedFilePath: string;
    selectedFileName: string;
}

export default class FilePreview extends Component<IFilePreviewProps, IFilePreviewState> {
    constructor(props: IFilePreviewProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
    }

    onSelectFilePath(filePath, text) {
        // console.log(filePath, text);
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    render() {
        let file = this.props.file;
        if (!file) return <></>;

        let image = (
                <>
                    <Thumbnail
                        key={"file-face-" + file.attachmentId}
                        previewPath={file.filePreviewPath}
                        displayPath={file.fileDisplayPath}
                        isImage={file.isImage}
                        text={""}
                        fullText={""}
                        title={""}
                        style={{  }}
                        onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        small={this.props.small}
                    />
                </>
            );

        return (
            <>
                {image}
                <ImagePreview 
                    filePath={this.state.selectedFilePath} 
                    fileName={this.state.selectedFileName} 
                    onDismissed={() => this.onSelectFilePath("", "")} />
            </>
        );

    }
}