import { connect } from 'react-redux'
import IncidentFind from '../components/incidents/IncidentFind';
import { withRouter } from "react-router";
import { incidentFindFilterRequest, incidentFindRequest } from '../common/actions/incidentFind';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.incidentFind.isLoading,
        items: state.incidentFind.items,
        paging: state.incidentFind.paging,
        filter: state.incidentFind.filter,
        onSelected: props.onSelected,
        excludeIncidentId: props.excludeIncidentId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetFilter:() => {
            dispatch(incidentFindFilterRequest(true))
        },
        onRefresh:(incidentFilter) => {
            dispatch(incidentFindRequest(incidentFilter))
        }
    }
}

const IncidentFindContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentFind))

export default IncidentFindContainer