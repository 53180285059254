import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as externalEventActions from "../actions/externalEvent";

const initialState = {
    error: null,
    errors: {},
    externalEvents: {
        isLoading: false,
        filter: {},
        externalEvents: [],
        paging: { pageSize: 10, page: 1 }
    },
    externalEvent: {
        externalEventId: -1,
        isLoading: false,
        isSaving: false,
        externalEvent: { externalEventId: 0 }
    },
    externalEventMessageAudits: {
        isLoading: false,
        isSaving: false,
        filter: {},
        externalEventMessageAudits: [],
        paging: { pageSize: 10, page: 1 }
    },
    copy: {
        venues: [],
        externalEventSelections: [],
        isLoading: false,
        isSaving: false,
        toVenueId: 0,
        canSave: false,
        errorMessage: ""
    }
};

function loadingExternalEvents(state, isLoading) {
    let newState = update(state, {
        externalEvents: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setExternalEvents(state, externalEvents, filter, paging) {
    let newState = update(state, {
        externalEvents: {
            externalEvents: { $set: externalEvents },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingExternalEvent(state, isLoading) {
    let newState = update(state, {
        externalEvent: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setExternalEvent(state, externalEvent) {
    let newState = update(state, {
        externalEvent: {
            externalEvent: { $set: externalEvent }
        }
    });
    return newState;
}

function savingExternalEvent(state, isSaving) {
    let newState = update(state, {
        externalEvent: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setExternalEventId(state, externalEventId) {
    let newState = update(state, {
        externalEvent: {
            externalEventId: { $set: externalEventId }
        }
    });
    return newState;
}

function loadingExternalEventMessageAudits(state, isLoading) {
    let newState = update(state, {
        externalEventMessageAudits: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingExternalEventMessageAudit(state, isSaving) {
    let newState = update(state, {
        externalEventMessageAudits: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setExternalEventMessageAudits(state, externalEventMessageAudits, filter, paging) {
    let newState = update(state, {
        externalEventMessageAudits: {
            externalEventMessageAudits: { $set: externalEventMessageAudits },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingCopy(state, isLoading) {
    let newState = update(state, {
        copy: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingCopy(state, isSaving) {
    let newState = update(state, {
        copy: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setCopyVenues(state, venues) {
    let newState = update(state, {
        copy: {
            venues: { $set: venues },
            toVenueId: { $set: 0 }
        }
    });
    return newState;
}

function setCopySelections(state, externalEventSelections) {
    let newState = update(state, {
        copy: {
            externalEventSelections: { $set: externalEventSelections }
        }
    });
    return setCanSave(newState);;
}

function setCopyVenue(state, toVenueId) {
    let newState = update(state, {
        copy: {
            toVenueId: { $set: toVenueId }
        }
    });
    return setCanSave(newState);
}

function setCopyErrorMessage(state, errorMessage) {
    let newState = update(state, {
        copy: {
            errorMessage: { $set: errorMessage }
        }
    });
    return newState;
}

function setCanSave(state) {
    let canSave = state.copy.toVenueId > 0 && state.copy.externalEventSelections.filter(f => f.selected).length > 0;
    let newState = update(state, {
        copy: {
            canSave: { $set: canSave }
        }
    });
    return newState;
}

export default function externalEvents(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case externalEventActions.EXTERNALEVENTS_REFRESH:
            return loadingExternalEvents(setExternalEventId(state, -1), true);
        case externalEventActions.EXTERNALEVENTS_REQUEST:
            return setExternalEvents(loadingExternalEvents(setExternalEventId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case externalEventActions.EXTERNALEVENTS_REQUEST_SUCCESS:
            return setExternalEvents(loadingExternalEvents(state, false), action.data, action.filter, action.paging);
        case externalEventActions.EXTERNALEVENTS_REQUEST_FAILURE:
            return loadingExternalEvents(state, false);
        case externalEventActions.EXTERNALEVENT_RESET:
            return setExternalEventId(state, -1);
        case externalEventActions.EXTERNALEVENT_REQUEST:
            return setExternalEvent(loadingExternalEvent(setExternalEventId(state, action.externalEventId), true), {});
        case externalEventActions.EXTERNALEVENT_REQUEST_SUCCESS:
            return setExternalEvent(loadingExternalEvent(state, false), action.externalEvent);
        case externalEventActions.EXTERNALEVENT_REQUEST_FAILURE:
            return loadingExternalEvent(setExternalEventId(state, -1), false);
        case externalEventActions.EXTERNALEVENT_CREATE_REQUEST:
            return setExternalEvent(loadingExternalEvent(setExternalEventId(state, 0), true), {});
        case externalEventActions.EXTERNALEVENT_CREATE_REQUEST_SUCCESS:
            return setExternalEvent(loadingExternalEvent(state, false), action.externalEvent);
        case externalEventActions.EXTERNALEVENT_CREATE_REQUEST_FAILURE:
            return loadingExternalEvent(setExternalEventId(state, -1), false);
        case externalEventActions.EXTERNALEVENT_SAVE_REQUEST:
            return savingExternalEvent(state, true);
        case externalEventActions.EXTERNALEVENT_SAVE_REQUEST_SUCCESS:
            return setExternalEvent(savingExternalEvent(setExternalEventId(state, -1), false), action.externalEvent);
        case externalEventActions.EXTERNALEVENT_SAVE_REQUEST_FAILURE:
            return savingExternalEvent(state, false);
        case externalEventActions.EXTERNALEVENT_UPDATE:
            return setExternalEvent(state, action.externalEvent);

        case externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REFRESH:
            return loadingExternalEventMessageAudits(state, true);
        case externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REQUEST:
            return setExternalEventMessageAudits(loadingExternalEventMessageAudits(state, true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REQUEST_SUCCESS:
            return setExternalEventMessageAudits(loadingExternalEventMessageAudits(state, false), action.data, action.filter, action.paging);
        case externalEventActions.EXTERNALEVENTMESSAGEAUDITS_REQUEST_FAILURE:
            return loadingExternalEventMessageAudits(state, false);
        case externalEventActions.EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST:
            return savingExternalEventMessageAudit(state, true);
        case externalEventActions.EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_SUCCESS:
        case externalEventActions.EXTERNALEVENTMESSAGEAUDIT_REPROCESS_REQUEST_FAILURE:
            return savingExternalEventMessageAudit(state, false);

        case externalEventActions.EXTERNALEVENT_COPY_REQUEST:
            return setCopyErrorMessage(setCopySelections(setCopyVenues(setCopyVenue(loadingCopy(state, true), 0), []), []), "");
        case externalEventActions.EXTERNALEVENT_COPY_REQUEST_SUCCESS:
            return setCopySelections(setCopyVenues(loadingCopy(state, false), action.venues), action.externalEventSelections);
        case externalEventActions.EXTERNALEVENT_COPY_REQUEST_FAILURE:
            return loadingCopy(state, false);
        case externalEventActions.EXTERNALEVENT_COPY_SET_SELECTIONS:
            return setCopySelections(state, action.externalEventSelections);
        case externalEventActions.EXTERNALEVENT_COPY_SET_VENUE:
            return setCopyVenue(state, action.toVenueId);
        case externalEventActions.EXTERNALEVENT_COPY_SAVE_REQUEST:
            return setCopyErrorMessage(savingCopy(state, true), "");
        case externalEventActions.EXTERNALEVENT_COPY_SAVE_REQUEST_SUCCESS:
            return setCopyErrorMessage(setCopySelections(setCopyVenues(savingCopy(state, false), action.venues), action.externalEventSelections), action.errorMessage);
        case externalEventActions.EXTERNALEVENT_COPY_SAVE_REQUEST_FAILURE:
            return savingCopy(state, false);
    }

    return state;
}