import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import PasswordControl from './PasswordControl'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export interface IPasswordControlGroupProps {
    autocomplete?: string;
    text?: string;
    error?: string;
    defaultValue?: string;
    readonly?: boolean;
    onChange?: Function;
    labelAbove?: boolean;
}
class PasswordControlGroup extends React.PureComponent<IPasswordControlGroupProps, any> {

    constructor(props: IPasswordControlGroupProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: ""
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.defaultValue
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultValue != this.props.defaultValue) {
            this.setState({ value: this.props.defaultValue })
        }
    }

    onChange(value) {
        if (value != null) {
            let val = value;
            this.setState({ value: val }, () => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            });
        }
    }

    render() {
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true: false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <PasswordControl
                        autocomplete={this.props.autocomplete}
                        error={this.props.error ? true : false}
                        value={this.state.value || ''}
                        onChange={this.onChange}
                        readonly={this.props.readonly}
                    />
                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default PasswordControlGroup;