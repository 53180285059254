import React, { Component } from 'react';
import View from './View';

export interface IPageInfoProps {
    text: string;
}

export default class PageInfo extends React.PureComponent<IPageInfoProps, any> {
    render() {
        return (
            <View>
                {this.props.text && (
                    <p style={{ textAlign: 'center', margin: 10, color: '#636364' }}>{this.props.text}</p>
                )}
            </View>
        );
    }
}