import { makeActionCreator } from './helpers';

export const
    USER_ACTION_AUDIT_INSERT_REQUEST = "USER_ACTION_AUDIT_INSERT_REQUEST",
    USER_ACTION_AUDIT_INSERT_REQUEST_SUCCESS = "USER_ACTION_AUDIT_INSERT_REQUEST_SUCCESS",
    USER_ACTION_AUDIT_INSERT_REQUEST_FAILURE = "USER_ACTION_AUDIT_INSERT_REQUEST_FAILURE"
    ;

export const userActionAuditInsertRequest = makeActionCreator(USER_ACTION_AUDIT_INSERT_REQUEST, 'actionType', 'locationFound', 'latitude', 'longitude');
export const userActionAuditInsertRequestSuccess = makeActionCreator(USER_ACTION_AUDIT_INSERT_REQUEST_SUCCESS);
export const userActionAuditInsertRequestFailure = makeActionCreator(USER_ACTION_AUDIT_INSERT_REQUEST_FAILURE, 'error');