import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as externalApiConnectionActions from "../actions/externalApiConnection";
import { externalApiConnectionLogic } from "../logic/externalApiConnectionLogic";

const initialState = {
    isSaving: false,
    isLoading: false,
    externalApiConnections: {
        isConnected: false
    },
    externalApiConnectionWebhookMessages: {
        isLoading: false,
        isSaving: false,
        filter: {},
        externalApiConnectionWebhookMessages: [],
        paging: { pageSize: 10, page: 1 }
    },
    roublerEmployeeMapping: {
        isSaving: false,
        isLoading: false,
        roublerEmployeeMapping: {
            mappings: []
        }
    },
    rollerConfiguration: {
        isSaving: false,
        isLoading: false,
        rollerConfiguration: {}
    }
};

function setExternalApiConnection(state, externalApiConnection) {
    let externalApiConnections = { ...state.externalApiConnections };
    if (externalApiConnection != undefined && externalApiConnection != null) {
        let key = externalApiConnectionLogic.buildKey(externalApiConnection.entityType, externalApiConnection.venueId, externalApiConnection.securityFirmId);
        externalApiConnections[key] = {
            externalApiConnection: externalApiConnection
        };
    }
    let newState = update(state, {
        externalApiConnections: { $set: externalApiConnections }
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setRoublerEmployeeMapping(state, roublerEmployeeMapping) {

    let newState = update(state, {
        roublerEmployeeMapping: {
            roublerEmployeeMapping: { $set: roublerEmployeeMapping }
        }
    });
    return newState;
}

function loadingRoublerEmployee(state, isLoading) {
    let newState = update(state, {
        roublerEmployeeMapping: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingRoublerEmployee(state, isSaving) {
    let newState = update(state, {
        roublerEmployeeMapping: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setRollerConfiguration(state, rollerConfiguration) {

    let newState = update(state, {
        rollerConfiguration: {
            rollerConfiguration: { $set: rollerConfiguration }
        }
    });
    return newState;
}

function loadingRollerConfiguration(state, isLoading) {
    let newState = update(state, {
        rollerConfiguration: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingRollerConfiguration(state, isSaving) {
    let newState = update(state, {
        rollerConfiguration: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function loadingExternalApiConnectionWebhookMessages(state, isLoading) {
    let newState = update(state, {
        externalApiConnectionWebhookMessages: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingExternalApiConnectionWebhookMessages(state, isSaving) {
    let newState = update(state, {
        externalApiConnectionWebhookMessages: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setExternalApiConnectionWebhookMessages(state, externalApiConnectionWebhookMessages, filter, paging) {
    let newState = update(state, {
        externalApiConnectionWebhookMessages: {
            externalApiConnectionWebhookMessages: { $set: externalApiConnectionWebhookMessages },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

export default function externalApiConnections(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REQUEST:
            return loading(state, true);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REQUEST_SUCCESS:
            return loading(setExternalApiConnection(state, action.externalApiConnection), false);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REQUEST_FAILURE:
            return loading(state, false);

        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_UPDATE_REQUEST:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REFRESH_REQUEST:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REMOVE_REQUEST:
            return saving(state, true);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_UPDATE_REQUEST_SUCCESS:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REFRESH_REQUEST_SUCCESS:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REMOVE_REQUEST_SUCCESS:
            return saving(setExternalApiConnection(state, action.externalApiConnection), false);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_UPDATE_REQUEST_FAILURE:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REFRESH_REQUEST_FAILURE:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_REMOVE_REQUEST_FAILURE:
            return saving(state, false);

        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_SET:
            return setExternalApiConnection(state, action.externalApiConnection);


        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_REQUEST:
            return loadingRoublerEmployee(state, true);
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_REQUEST_SUCCESS:
            return loadingRoublerEmployee(setRoublerEmployeeMapping(state, action.roublerEmployeeMapping), false);
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_REQUEST_FAILURE:
            return loadingRoublerEmployee(state, false);


        case externalApiConnectionActions.ROUBLER_EMPLOYEE_EXPORT_REQUEST:
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST:
            return savingRoublerEmployee(state, true);
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_EXPORT_REQUEST_SUCCESS:
            return savingRoublerEmployee(state, false);
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_SUCCESS:
            return savingRoublerEmployee(setRoublerEmployeeMapping(state, action.roublerEmployeeMapping), false);
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_EXPORT_REQUEST_FAILURE:
        case externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_FAILURE:
            return savingRoublerEmployee(state, false);

        case externalApiConnectionActions.ROLLER_CONFIGURATION_REQUEST:
            return loadingRollerConfiguration(state, true);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_REQUEST_SUCCESS:
            return loadingRollerConfiguration(setRollerConfiguration(state, action.rollerConfiguration), false);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_REQUEST_FAILURE:
            return loadingRollerConfiguration(state, false);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_SET:
            return setRollerConfiguration(state, action.rollerConfiguration);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_UPDATE_REQUEST:
            return savingRollerConfiguration(state, true);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_UPDATE_REQUEST_SUCCESS:
            return savingRollerConfiguration(setRollerConfiguration(state, action.rollerConfiguration), false);
        case externalApiConnectionActions.ROLLER_CONFIGURATION_UPDATE_REQUEST_FAILURE:
            return savingRollerConfiguration(state, false);

        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REQUEST:
            return setExternalApiConnectionWebhookMessages(loadingExternalApiConnectionWebhookMessages(state, true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REQUEST_SUCCESS:
            return setExternalApiConnectionWebhookMessages(loadingExternalApiConnectionWebhookMessages(state, false), action.data, action.filter, action.paging);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REQUEST_FAILURE:
            return loadingExternalApiConnectionWebhookMessages(state, false);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_BATCH_REPROCESS_REQUEST:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REPROCESS_REQUEST:
            return savingExternalApiConnectionWebhookMessages(state, true);
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REPROCESS_REQUEST_SUCCESS:
        case externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REPROCESS_REQUEST_FAILURE:
            return savingExternalApiConnectionWebhookMessages(state, false);
    }

    return state;
}