import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FileUpload from '../common/FileUpload';
import TransparentButton from '../controls/TransparentButton';
import ProfileAvatar from './ProfileAvatar';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ConfirmDialog from '../controls/ConfirmDialog';

export interface IProfileAvatarEditProps {
    avatar: AusComplyDtos.File;
    onUpdate?: Function;
}

interface IProfileAvatarEditState {
    selectedFilePath: string;
    file: AusComplyDtos.File;
    confirmAvatarRemoval: boolean;
}

class ProfileAvatarEdit extends React.Component<IProfileAvatarEditProps, IProfileAvatarEditState> {
    constructor(props: IProfileAvatarEditProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            file: new AusComplyDtos.File(),
            confirmAvatarRemoval: false
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.remove = this.remove.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.removeAvatarCancel = this.removeAvatarCancel.bind(this);
        this.removeAvatarConfirmed = this.removeAvatarConfirmed.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    remove() {
        this.setState({
            confirmAvatarRemoval: true
        });
    }

    removeTemporary() {
        this.setState({ file: new AusComplyDtos.File() });
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        let images = temporaryFiles.filter(t => t.isImage);
        if (images.length > 0) {
            let file = images[0];
            if (this.props.onUpdate) {
                this.props.onUpdate(file);
            }
        }
    }

    onUpload() {

    }

    removeAvatarCancel() {
        this.setState({
            confirmAvatarRemoval: false
        });
    }

    removeAvatarConfirmed() {
        if (this.props.onUpdate) {
            this.props.onUpdate(new AusComplyDtos.File());
            this.setState({
                confirmAvatarRemoval: false
            });
        }
    }

    render() {
        let cameraButton;
        let removeButton;
        if (this.props.onUpdate) {
            cameraButton = (
                <FileUpload onFileWasUploaded={this.fileWasUploaded} buttonModeIcon={
                    <CameraAltIcon style={{ fontSize: '1.5em', color: '#888' }} />
                } />
            );
            removeButton = (
                <TransparentButton style={{ width: '100%', height: '100%' }} onClick={this.remove}>
                    <DeleteOutlinedIcon style={{ fontSize: '1.5em', color: '#888' }} />
                </TransparentButton>
            );
        }

        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">

                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box p={1}>
                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                            <Box p={1} style={{ position: 'relative' }}>
                                {cameraButton}
                            </Box>
                        </Box>
                    </Box>
                    <Box p={1}>
                        <ProfileAvatar avatar={this.props.avatar} style={{ width: '70px', height: '70px' }} />
                    </Box>
                    <Box p={1}>
                        <Box display="flex" flexDirection="column" justifyContent="center" flexWrap="wrap" style={{ height: '100%' }}>
                            <Box p={1}>
                                {removeButton}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ConfirmDialog
                    title={"Confirm removal"}
                    text={"Are you sure you wish to remove your profile image"}
                    show={this.state.confirmAvatarRemoval}
                    onCancel={this.removeAvatarCancel}
                    onConfirm={this.removeAvatarConfirmed} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ProfileAvatarEdit);