import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import YesNoControlGroup from '../../controls/YesNoControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';


export interface IRegisterVenueTypeProps {
    venue: AusComplyDTOs.Venue;
    industryCategories: AusComplyDTOs.IndustryCategory[];
    precincts: AusComplyDTOs.VenuePrecinct[];
    licenceTypes: AusComplyDTOs.VenueLicenceType[];
    tradingHours: AusComplyDTOs.VenueTradingHours[];
    canEditVenueIndustryCategory: boolean;
    errors: any;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterVenueType extends Component<IRegisterVenueTypeProps, any> {
    constructor(props: IRegisterVenueTypeProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let venue = { ...this.props.venue };
        venue[fieldname] = value;
        this.props.onUpdate(venue);
    }

    render() {

        let patronage = "0";
        if (this.props.venue.patronCapacity) {
            patronage = this.props.venue.patronCapacity.toString();
        }
        let abn;
        if(!this.props.venue.soleTrader) {
            abn = (
                <TextControlGroup
                    text={"ABN"}
                    defaultValue={this.props.venue.abn}
                    error={this.props.errors["abn"]}
                    onBlur={(value) => this.onChange("abn", value)}
                />
            );
        }
        
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Venue Type"}>
                    <CardContent>
                        <LayoutForm>
                            <YesNoControlGroup
                                name="soleTrader"
                                error={this.props.errors["soleTrader"]}
                                text={"Sole Trader"}
                                value={this.props.venue.soleTrader}
                                onChanged={(value) => this.onChange("soleTrader", value)}
                            />
                            {abn}
                            { this.props.canEditVenueIndustryCategory && (
                                <SelectControlGroup
                                    text="Industry Category"
                                    error={this.props.errors["industryCategoryId"]}
                                    onChange={(value) => this.onChange("industryCategoryId", value)}
                                    defaultValue={this.props.venue.industryCategoryId}>
                                    <option value={0} />
                                    {this.props.industryCategories && this.props.industryCategories.map((item, index) => (
                                        <option key={item.industryCategoryId} value={item.industryCategoryId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            )}
                            
                            <TextControlGroup
                                text={"Liquor License #"}
                                defaultValue={this.props.venue.liquorLicenceNumber}
                                error={this.props.errors["liquorLicenceNumber"]}
                                onBlur={(value) => this.onChange("liquorLicenceNumber", value)}
                            />
                            <SimpleDateControlGroup 
                                text={"Licence Review Date"}
                                date={this.props.venue.liquorLicenceDate}
                                error={this.props.errors["liquorLicenceDate"]}
                                onChange={(value) => this.onChange("liquorLicenceDate", value)}
                            />
                            <SelectControlGroup
                                text="License Type"
                                error={this.props.errors["venueLicenceTypeId"]}
                                onChange={(value) => this.onChange("venueLicenceTypeId", value)}
                                defaultValue={this.props.venue.venueLicenceTypeId}>
                                <option value={0} />
                                {this.props.licenceTypes && this.props.licenceTypes.map((item, index) => (
                                    <option key={item.venueLicenceTypeId} value={item.venueLicenceTypeId}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            <TextControlGroup
                                text={"Average Patronage"}
                                defaultValue={patronage}
                                error={this.props.errors["patronCapacity"]}
                                onBlur={(value) => this.onChange("patronCapacity", value)}
                            />
                            <SelectControlGroup
                                text="Precinct"
                                error={this.props.errors["venuePrecinctId"]}
                                onChange={(value) => this.onChange("venuePrecinctId", value)}
                                defaultValue={this.props.venue.venuePrecinctId}>
                                <option value={0} />
                                {this.props.precincts && this.props.precincts.map((item, index) => (
                                    <option key={item.venuePrecinctId} value={item.venuePrecinctId}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            <SelectControlGroup
                                text="End of Trading Hours"
                                error={this.props.errors["venueTradingHoursId"]}
                                onChange={(value) => this.onChange("venueTradingHoursId", value)}
                                defaultValue={this.props.venue.venueTradingHoursId}>
                                <option value={0} />
                                {this.props.tradingHours && this.props.tradingHours.map((item, index) => (
                                    <option key={item.venueTradingHoursId} value={item.venueTradingHoursId}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}