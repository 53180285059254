import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import PageTitle from '../common/PageTitle';
import ControlGroup from '../controls/ControlGroup';
import TextDisplay from '../controls/TextDisplay';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import DefaultFab from '../common/DefaultFab';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';

export interface ISecurityFirmDetailsProps {
    securityFirmId: number;
    isLoading: boolean;
    canEdit: boolean;
    canAdminEdit: boolean;
    securityFirm: AusComplyDtos.SecurityFirm;
    onLoad: Function;
    history: any;
}

export default class SecurityFirmDetails extends Component<ISecurityFirmDetailsProps, any> {
    constructor(props: ISecurityFirmDetailsProps) {
        super(props)
        this.state = {
        };
        this.onEdit = this.onEdit.bind(this);
        this.onAdminEdit = this.onAdminEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId);
    }

    onEdit() {
        this.props.history.push('/securityFirm/edit');
    }

    onAdminEdit() {
        this.props.history.push('/admin/securityFirm/' + this.props.securityFirmId);
    }

    render() {
        var editLink;
        if (this.props.canEdit) {
            editLink =(
                <TransparentButton onClick={() => this.onEdit()} text={"Edit Security Firm"} />
            );
        }
        var adminVenueLink;
        if (this.props.canAdminEdit) {
            adminVenueLink =(
                <TransparentButton onClick={() => this.onAdminEdit()} text={"Admin"} />
            );
        }
        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={false}
                    error={""}
                    footerLeftContent={editLink}
                    footerRightContent={adminVenueLink}
                    headerText={"Security Firm"}
                >
                    <form>
                        <ControlGroup text="Company Name">
                            <TextDisplay>{this.props.securityFirm.name}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="ID">
                            <TextDisplay>{this.props.securityFirm.securityFirmId}</TextDisplay>
                        </ControlGroup>
                        {this.props.securityFirm.stateLicensedDisplayName && (
                            <ControlGroup text="State Licensed">
                                <TextDisplay>{this.props.securityFirm.stateLicensedDisplayName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.securityLicenceClassName && (
                            <ControlGroup text="MLH Licence Class">
                                <TextDisplay>{this.props.securityFirm.securityLicenceClassName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.mlhNominatedPerson && (
                            <ControlGroup text="MLH Nominated Person">
                                <TextDisplay>{this.props.securityFirm.mlhNominatedPerson}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.masterLicenceExpiryDisplay && (
                            <ControlGroup text="MLH Expiry">
                                <TextDisplay>{this.props.securityFirm.masterLicenceExpiryDisplay}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.abn && (
                            <ControlGroup text="ABN">
                                <TextDisplay>{this.props.securityFirm.abn}</TextDisplay>
                            </ControlGroup>
                        )}

                        {this.props.securityFirm.address && (
                            <ControlGroup text="Address">
                                <TextDisplay>{this.props.securityFirm.address}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.suburb && (
                            <ControlGroup text="Suburb">
                                <TextDisplay>{this.props.securityFirm.suburb}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.stateDisplayName && (
                            <ControlGroup text="State">
                                <TextDisplay>{this.props.securityFirm.stateDisplayName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.postcode && (
                            <ControlGroup text="Postcode">
                                <TextDisplay>{this.props.securityFirm.postcode}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.phone && (
                            <ControlGroup text="Phone">
                                <TextDisplay>{this.props.securityFirm.phone}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.securityFirm.website && (
                            <ControlGroup text="Website">
                                <TextDisplay>{this.props.securityFirm.website}</TextDisplay>
                            </ControlGroup>
                        )}
                    </form>
                </PageLayout>
            </>
        );
    }
}