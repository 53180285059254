import React from 'react';
import { styles } from '../../styles';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardTypography from '../common/CardTypography';
import CardTitle from '../common/CardTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { appSource } from '../../utilities/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SecondaryFab from '../common/SecondaryFab';
import DefaultFab from '../common/DefaultFab';
import CardRow from '../common/CardRow';
import Error from '../common/Error';
import DownloadPdfIcon from '../../resources/DownloadPdfIcon';
import FullPageDialog from '../layout/FullPageDialog';
import View from '../common/View';
import TransparentButton from '../controls/TransparentButton';


export interface IPrintQueueProps {
    queue: AusComplyDtos.ReportGeneration[];
    onRefresh?: Function;
    onDownloaded?: Function;
    onDismissed?: Function;
    onDismissAll?: Function;
    theme: any;
    classes: any;
}

export interface IPrintQueueState {
    show: boolean;
    isProcessing: boolean;
    isComplete: boolean;
    isError: boolean;
}

class PrintQueue extends React.PureComponent<IPrintQueueProps, IPrintQueueState> {

    constructor(props: IPrintQueueProps) {
        super(props);
        this.state = {
            show: false,
            isProcessing: false,
            isComplete: false,
            isError: false
        };
        this.onDownloaded = this.onDownloaded.bind(this);
        this.onDismissed = this.onDismissed.bind(this);
        this.onDismissAll = this.onDismissAll.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onAction = this.onAction.bind(this);
    }

    timer;

    componentDidMount() {
        this.onRefresh();
        this.calculateState();
    }

    componentDidUpdate(prevprops) {
        if (this.props.queue && prevprops.queue) {
            // Only poll if there are items in the queue, requesting a print will put items in the queue, and 
            // returning to the home page will also check the queue
            if (this.props.queue.length === 0 && prevprops.queue.length !== 0) {
                clearInterval(this.timer);
            }
            if (this.props.queue.length !== 0 && prevprops.queue.length === 0) {
                this.timer = setInterval(() => this.onRefresh(), 3500);
            }
        }
        this.calculateState();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    calculateState() {
        let isProcessing = false;
        let isComplete = false;
        let isError = false;
        if (this.props.queue && this.props.queue.length > 0) {
            isComplete = this.props.queue.filter(item => item.status == 'Ready').length > 0;
            isError = this.props.queue.filter(item => item.status == 'Error' || item.status == 'Cancelled').length > 0;
            isProcessing = this.props.queue.filter(item => item.status == 'Requested' || item.status == 'Generating').length > 0;
        }
        if (this.state.isProcessing != isProcessing ||
            this.state.isComplete != isComplete ||
            this.state.isError != isError) {
            this.setState({ isProcessing, isComplete, isError });
        }
    }

    onRefresh() {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    onDownloaded(item: AusComplyDtos.ReportGeneration) {
        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let filePath = base + '/api/reportgenerated/' + item.reportGenerationId + "?downloadToken=" + item.downloadToken;
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            window.open(response.file);
        }, 100);
        if (this.props.onDownloaded) {
            this.props.onDownloaded(item.reportGenerationId);
        }
    }

    onDismissed(item: AusComplyDtos.ReportGeneration) {
        if (this.props.onDismissed) {
            this.props.onDismissed(item.reportGenerationId);
        }
    }

    onDismissAll() {
        if (this.props.onDismissAll) {
            this.props.onDismissAll();
        }
    }

    onAction(item: AusComplyDtos.ReportGeneration) {
        let closeAfterAction = this.props.queue.length == 1;
        if (item.status == 'Ready') {
            this.onDownloaded(item);
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            this.onDismissed(item);
        }
        if (closeAfterAction) {
            this.setState({show: false})
        }
    }

    onShow(show) {
        this.setState({ show });
    }

    renderCard(item: AusComplyDtos.ReportGeneration) {
        const { theme } = this.props;
        let isSuccess = false;
        let isCancelled = false;
        let actionText = "";
        if (item.status == 'Ready') {
            isSuccess = true;
            actionText = "download";
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            isCancelled = true;
            actionText = "dismiss";
        }
        return (
            <CardRow isSuccess={isSuccess}
                isDanger={isCancelled}
                isWarning={!isSuccess && !isCancelled}
                style={{cursor: 'pointer'}}
                key={"report-generation-" + item.reportGenerationId.toString()}>
                <Box p={0} display="flex" flexDirection={'row'} onClick={() => this.onAction(item)}>
                    <Box flexGrow={1} p={0}>
                        <CardTypography>{item.title}</CardTypography>
                    </Box>
                    <Box flexGrow={0} p={0}>
                        <CardTypography style={{ color: theme.palette.text.secondary }}>{actionText}</CardTypography>
                    </Box>
                </Box>
                {item.error && (
                    <Error message={item.error} />
                )}
            </CardRow>
        );
    }

    render() {
        const { theme, classes } = this.props;
        let fab;
        let queue;
        let legend;
        if (this.state.isProcessing) {
            fab = (
                <SecondaryFab
                    onClick={() => this.onShow(true)}>
                    <View style={{ marginTop: '5px' }}>
                        <DownloadPdfIcon fill={'#000'} />
                    </View>
                </SecondaryFab>
            );
        } else if (this.state.isError) {
            fab = (
                <SecondaryFab
                    style={{
                        backgroundColor: '#d2242b'
                    }}
                    onClick={() => this.onShow(true)}>
                    <View style={{ marginTop: '5px' }}>
                        <DownloadPdfIcon fill={'#fefbf7'} />
                    </View>
                </SecondaryFab>
            );
        } else if (this.state.isComplete) {
            fab = (
                <SecondaryFab
                    style={{
                        backgroundColor: '#43C23F'
                    }}
                    onClick={() => this.onShow(true)
                    }>
                    <View style={{ marginTop: '5px' }}>
                        <DownloadPdfIcon fill={'rgba(255, 255, 255, 1)'} />
                    </View>
                </SecondaryFab >
            );
        }
        if (this.state.show && this.props.queue && this.props.queue.length > 0) {
            queue = this.props.queue.map((item, index) => this.renderCard(item));
            legend = (
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistInProgress}></div>
                        <div className={classes.colorSwatchText}>Processing</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistRejected}></div>
                        <div className={classes.colorSwatchText}>Error</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistApproved}></div>
                        <div className={classes.colorSwatchText}>Ready</div>
                    </Box>
                </Box>
            );
        }
        return (
            <>
                {fab}
                <FullPageDialog
                    open={this.state.show}
                    title="Print queue"
                    containerStyle={{ display: 'inline-block' }}
                    onDismissed={() => this.onShow(false)}
                >
                    <View style={{ margin: 20 }}>
                        {queue}
                        {legend}
                        <View style={{ marginTop: 50 }}>
                            <TransparentButton text={"Clear"} onClick={() => this.onDismissAll()} />
                        </View>
                    </View>
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrintQueue);