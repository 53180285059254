import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { checklistLogic } from '../../common/logic/checklistLogic';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import moment from "moment";
import View from '../common/View';
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';


export interface ICheckListCardProps {
    checklistSummary: AusComplyDtos.ChecklistSummary;
    canApprove: boolean;
    canReject: boolean;
    canClose: boolean;
    canArchive: boolean;
    onClick?: Function;
    onApprove?: Function;
    onVoid?: Function;
    onUndoArchive?: Function;
    onArchive?: Function;
    showEvents: boolean;
    classes: any;
    theme: any;
}

class CheckListCard extends React.Component<ICheckListCardProps, any> {

    constructor(props: ICheckListCardProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { classes, theme, showEvents } = this.props;
        let className = classes.cardChecklistInClosed;
        let color = theme.custom.colors.cardContainerBorder;
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.InProgress) {
            className = classes.cardChecklistInProgress;
            color = theme.custom.colors.warning;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Approved) {
            className = classes.cardChecklistApproved;
            color = theme.custom.colors.primary;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted) {
            className = classes.cardChecklistSubmitted;
            color = theme.custom.colors.info;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Rejected) {
            className = classes.cardChecklistRejected;
            color = theme.custom.colors.rejected;
        }
        if (this.props.checklistSummary.obsolete) {
            className = classes.cardChecklistArchived;
            color = theme.custom.colors.error;
        }

        let isReadonly = this.props.checklistSummary.obsolete || checklistLogic.isReadonly(this.props.checklistSummary.checklistStatus);
        let icon;
        if (isReadonly) {
            icon = (
                <ListAltIcon style={{ color: color }} />
            );
        } else {
            icon = (
                <EditIcon style={{ color: color }} />
            );
        }
        let approveButton;
        let voidButton;
        let archiveButton;
        let undoArchiveButton;
        let showButtons = false;

        if (!this.props.checklistSummary.obsolete && checklistLogic.canApprove(this.props.checklistSummary.checklistStatus, this.props.canApprove)) {
            approveButton = (
                <PrimaryButton text={"Approve"} onClick={this.props.onApprove} />
            );
            showButtons = true;
        }
        if (!this.props.checklistSummary.obsolete && checklistLogic.canArchive(this.props.checklistSummary.checklistStatus, this.props.canArchive)) {
            archiveButton = (
                <DefaultButton text={"Archive"}  onClick={this.props.onArchive} />
            );
            showButtons = true;
        }
        if (!this.props.checklistSummary.obsolete && checklistLogic.canClose(this.props.checklistSummary.checklistStatus, this.props.canClose)) {
            voidButton = (
                <DefaultButton text={"Void"}  onClick={this.props.onVoid} />
            );
            showButtons = true;
        }
        if (this.props.checklistSummary.obsolete && checklistLogic.canUndoArchive(this.props.checklistSummary, this.props.canArchive)) {
            undoArchiveButton = (
                <DefaultButton text={"Restore"}  onClick={this.props.onUndoArchive} />
            );
            showButtons = true;
        }

        return (
            <Card style={{ marginTop: '6px', marginBottom: '0' }} className={className}>
                <CardContent style={{}}>
                    <Box p={0} display="flex" flexDirection={'row'} >
                        <Box flexGrow={1} onClick={() => this.onClick()} style={{ overflow: 'hidden' }}>
                            <Grid container style={{ width: '100%' }}>
                                <Grid item xs={12}>
                                    <CardTypography><b>{this.props.checklistSummary.name}</b></CardTypography>
                                </Grid>
                            </Grid>
                            {showEvents && (
                                <Grid container style={{ width: '100%' }}>
                                    <Grid item xs={12}>
                                        <CardTypography>{this.props.checklistSummary.venueEventName}</CardTypography>
                                    </Grid>
                                </Grid>)
                            }
                            <Grid container>
                                <Grid item xs={12}>
                                    <CardTypography style={{ fontStyle: 'italic' }}>
                                        <>
                                            Created by {this.props.checklistSummary.enteredByUser.displayName}{" "}
                                            {moment(this.props.checklistSummary.dateEntered).fromNow()}, last
                                    modified {moment(this.props.checklistSummary.dateModified).fromNow()}
                                        </>
                                    </CardTypography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box flexGrow={0} onClick={() => this.onClick()}>
                            <View style={{ marginTop: '10px', marginLeft: '10px', width: '20px', height: '20px' }}>
                                {icon}
                            </View>
                        </Box>
                    </Box>
                    {showButtons && (
                        <Box p={0} display="flex" flexDirection={'row'} >
                            <Box flexGrow={1}>

                            </Box>
                            <Box flexGrow={0} p={0}>
                                {archiveButton}
                            </Box>
                            <Box flexGrow={0} p={1}>
                            </Box>
                            <Box flexGrow={0} p={0}>
                                {voidButton}
                            </Box>
                            <Box flexGrow={0} p={1}>
                            </Box>
                            <Box flexGrow={0} p={0}>
                                {approveButton}
                            </Box>
                            <Box flexGrow={0} p={0}>
                                {undoArchiveButton}
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </Card >
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListCard);