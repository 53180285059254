import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { SingleSelectList } from '../../../../common/dto/common';
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Title from '../../../common/Title';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import CheckboxControlGroup from '../../../controls/CheckboxControlGroup';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckListItemAttachments from './CheckListItemAttachments';
import CheckListItemAttachment from './CheckListItemAttachment';
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';
import SingleSelectListControl from '../../../controls/SingleSelectListControl';

export interface ICheckListItemStaffSingleSelectionProps {
    checkListDisplayItem: CheckListDisplayItem;
    checklistUserSelections: AusComplyDtos.ChecklistUserSelection[];
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemStaffSingleSelectionState {
    items: SingleSelectList[];
    display: string;
    includeAssociated: boolean;
}

class CheckListItemStaffSingleSelection extends Component<ICheckListItemStaffSingleSelectionProps, ICheckListItemStaffSingleSelectionState> {
    constructor(props: ICheckListItemStaffSingleSelectionProps) {
        super(props)
        this.state = {
            items: [],
            display: "",
            includeAssociated: false
        }
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
    }

    componentDidMount() {
        this.buildOptions();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.checkListDisplayItem.item) != JSON.stringify(preProps.checkListDisplayItem.item)) {
            this.buildOptions();
        } else if (JSON.stringify(this.props.checkListDisplayItem.template) != JSON.stringify(preProps.checkListDisplayItem.template)) {
            this.buildOptions();
        }
    }

    buildOptions() {
        let items: SingleSelectList[] = [];
        let display:string = "";

        if (this.props.checklistUserSelections) {
            this.props.checklistUserSelections.forEach(u => {
                let isSelected = this.props.checkListDisplayItem.item.value != null && this.props.checkListDisplayItem.item.value != undefined && this.props.checkListDisplayItem.item.value == u.userId.toString();
                if((!u.obsolete && (this.state.includeAssociated || !u.isAssociated)) || isSelected) {
                    items.push({ id: u.userId, name: u.display, isCommon: u.isLoggedIn, selected: isSelected, info: "", infoColor: "" });
                    if (isSelected) {
                        display = u.display;
                    }
                }
            });
        }
        this.setState({ items, display });
    }

    onSelectedItemChanged(item) {
        let checklistItem = { ...this.props.checkListDisplayItem.item };
        if (item) {
            checklistItem.value = item.id;
        } else {
            checklistItem.value = "";
        }
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
    }

    render() {
        const { theme } = this.props;
        let self = this;

        let cardStyle;
        if (this.props.checkListDisplayItem.isRequired) {
            cardStyle = { borderColor: theme.custom.colors.error };
        }

        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplay>{this.state.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <>
                            <Title text={this.props.checkListDisplayItem.template.name} />
                            <CheckboxControlGroup text={"Include associated users"}
                                defaultValue={this.state.includeAssociated} onChanged={(value) => self.setState({includeAssociated: value}, () => self.buildOptions())} />
                            <SingleSelectListControl
                                items={this.state.items}
                                selectedTitle={""}
                                canShowAll={true}
                                hideSelectedTitle={true}
                                hideEmptyMessage={false}                                
                                emptyMessage='Select a staff member'
                                commonTitle="Online"
                                hideAvailableText={false}
                                availableTitle="Search staff"
                                searchText="Type at least three letters..."
                                minimumCharactersRequired={3}
                                onSelectedItemChanged={this.onSelectedItemChanged}
                                autoHideOnSelected={true}
                            ></SingleSelectListControl></>
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemStaffSingleSelection);