import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import ButtonLink from '../controls/ButtonLink';
import Box from '@material-ui/core/Box';
import Media from "react-media";
import Logo from '../../resources/Logo';
import { appSource } from '../../utilities/constants';

export interface ILoginLegacyProps {
    theme: any;
    classes: any;
}

const CancelLink = forwardRef((props, ref) => <Link to="/" {...props} />);

class LoginLegacy extends Component<ILoginLegacyProps, any> {
    constructor(props: ILoginLegacyProps) {
        super(props)
        this.state = {};
    }

    render() {
        let baseURL = appSource.getBaseImagesPath();
        if (baseURL == '/') {
            baseURL = window.location.origin;
        }
        return (
            <>
                <Media query={{ maxWidth: 1025 }}>
                    {matches =>
                        matches ? (
                            <img src={require('../../resources/MobileBackground.jpg')} style={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} alt="backdrop" />
                        ) : (
                                <img src={require('../../resources/DesktopBackground.jpg')} style={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} alt="backdrop" />
                            )
                    }
                </Media>
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <Box p={0} flexGrow={1}></Box>
                    <Box p={0}>
                        <form noValidate autoComplete="off" style={{ margin: '20px' }} >

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1} md={2} lg={4}></Grid>
                                <Grid item xs={12} sm={10} md={8} lg={4} style={{ textAlign: 'center' }}>
                                    <Logo fill={'#fff'} style={{ width: '80%', height: 'auto' }} />
                                    <h3 style={{
                                        color: '#999999',
                                        padding: '0',
                                        margin: '10px',
                                        textAlign: 'center',
                                        marginBottom: '10px',
                                        textShadow: '0 0 5px #000000',
                                        fontSize: '22px'
                                    }}>Digital Incident Register</h3>
                                    <h5 style={{
                                        color: '#777777',
                                        padding: '0',
                                        margin: '0',
                                        textAlign: 'center',
                                        textShadow: '0 0 5px #000000',
                                        fontStyle: 'italic',
                                        fontSize: '12px'
                                    }}>for the Web</h5>
                                    
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box p={0}>
                                            <ButtonLink text="Cancel" small={true} component={CancelLink} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    <Box p={0} flexGrow={0}></Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(LoginLegacy);