import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardInformation from '../../common/CardInformation';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { venueReportingPeriodLogic } from '../../../common/logic/venueReportingPeriodLogic';

export interface IVenueReportingSecurityCodeProps {
    venueReportingPeriod: AusComplyDtos.VenueReportingPeriod;
}

export default class VenueReportingSecurityCode extends React.Component<IVenueReportingSecurityCodeProps, any> {
    constructor(props: IVenueReportingSecurityCodeProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onToggleSecurityCode = this.onToggleSecurityCode.bind(this);
    }

    onToggleSecurityCode() {
        this.setState({ showSecurityCode: !this.state.showSecurityCode });
    }

    render() {
        if (!this.props.venueReportingPeriod) {
            return (<></>);
        }

        let securityCode;
        if (this.props.venueReportingPeriod.securityCodeNotRequired) {
            securityCode = (
                <CardTypography style={{textAlign: 'center'}}>Not Required</CardTypography>
            );
        }
        else if (this.state.showSecurityCode) {
            securityCode = (
                <CardInformation >{this.props.venueReportingPeriod.securityCode}<VisibilityOffIcon onClick={this.onToggleSecurityCode} style={{ fontSize: '20px' }} /></CardInformation>
            );
        } else {
            securityCode = (
                <CardInformation >{"****"} <VisibilityIcon onClick={this.onToggleSecurityCode} style={{ fontSize: '20px' }} /></CardInformation>
            );
        }

        return <>{securityCode}</>     
    }
}