import React from "react";

const GroupIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 86 86"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g >
                <path fill={fill} d="m15.80003,38.67691c-5.6,0.3 -11,4 -13.3,9.9c-0.2,0.4 -1.8,5.6 -2.1,8l15.8,0c3.4,-5.7 9.1,-9.6 15.5,-10.2c-1.8,-1.4 -3.3,-3.3 -4.3,-5.3c-1.2,0.4 -2.5,0.7 -3.9,0.7c-2.9,-0.2 -5.6,-1.3 -7.7,-3.1m17.7,-19.1c-2.1,-3.3 -5.8,-5.5 -9.9,-5.5c-6.5,0 -11.7,5.3 -11.7,11.7c0,6.5 5.3,11.7 11.7,11.7c0.9,0 1.7,-0.1 2.6,-0.3c-0.3,-1.2 -0.4,-2.5 -0.4,-3.8c-0.1,-5.8 3,-10.9 7.7,-13.8m38.8,5.2c0,-6.4 -5.3,-11.7 -11.7,-11.7c-4.5,0 -8.4,2.5 -10.3,6.2c4.9,2.8 8.3,8.1 8.3,14.2c0,1 -0.1,1.9 -0.3,2.9c0.8,0.2 1.6,0.2 2.4,0.2c6.3,0 11.6,-5.3 11.6,-11.8m11.3,30.7c-0.2,-2.3 -1.7,-7.5 -1.9,-8c-2.4,-5.9 -7.7,-9.6 -13.4,-9.9c-2.1,1.8 -4.8,2.9 -7.8,2.9c-1.2,0 -2.4,-0.2 -3.5,-0.5c-1.1,2.4 -2.7,4.5 -4.7,6.2c6.1,0.6 11.5,4.1 15,9.2l16.3,0l0,0.1z" />
                <path fill={fill} d="m56.00003,33.77691c0,-7.7 -6.3,-13.9 -13.9,-13.9c-7.7,0 -13.9,6.2 -13.9,13.9c0,7.7 6.2,13.9 13.9,13.9c7.6,0 13.9,-6.2 13.9,-13.9m11.1,26.9c-2.8,-7 -9.2,-11.4 -15.9,-11.7c-2.5,2.2 -5.7,3.5 -9.2,3.5c-3.5,0 -6.7,-1.3 -9.2,-3.5c-6.7,0.4 -13,4.8 -15.8,11.7c-0.2,0.5 -2.2,6.7 -2.4,9.4l54.6,0c-0.1,-2.7 -1.9,-8.9 -2.1,-9.4" />
            </g>

        </svg>
    );

export default GroupIcon;
