import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CrossIcon from '../../../resources/CrossIcon';
import DraftSaveIcon from '../../../resources/DraftSaveIcon';
import NavigateNextIcon from '../../../resources/NavigateNextIcon';
import NavigatePreviousIcon from '../../../resources/NavigatePreviousIcon';
import SubmitIcon from '../../../resources/SubmitIcon';
import DeleteOutlinedIcon from '../../../resources/DeleteOutlinedIcon';
import NavigationButton from '../../controls/NavigationButton';

export interface IIncidentWizardNavigationProps {
    step: number;
    steps: number;
    onBack: Function;
    onNext: Function;
    onCancel: Function;
    onSubmit: Function;
    onApprove: Function;
    onDraft: Function;
    canDelete: boolean;
    canDraft: boolean;
    canApprove?: boolean;
    canSubmit?: boolean;
    attachButton?: any;
}

export default class IncidentWizardNavigation extends Component<IIncidentWizardNavigationProps, any> {

    render() {

        let deleteButton;
        if (this.props.canDelete) {
            deleteButton = <NavigationButton
                onClick={this.props.onCancel}
                text={"Discard"}
                isError={true}
                Icon={DeleteOutlinedIcon} />;
        } else {
            deleteButton = <NavigationButton
                onClick={this.props.onCancel}
                text={"Close"}
                isError={true}
                Icon={CrossIcon} />;
        }

        let submitButton = <NavigationButton
            onClick={this.props.onSubmit}
            text={"Submit"}
            isSuccess={true}
            active={true}
            disabled={!this.props.canSubmit}
            Icon={SubmitIcon} />

        let draftButton;
        if (this.props.canDraft) {
            draftButton = <NavigationButton
                onClick={this.props.onDraft}
                text={"Draft"}
                isWarning={true}
                Icon={DraftSaveIcon} />;
        }


        let previousButton = <NavigationButton
            onClick={this.props.onBack}
            text={"Back"}
            Icon={NavigatePreviousIcon}
            active={this.props.step > 1}
            disabled={this.props.step < 2} />;

        let nextButton = <NavigationButton
            onClick={this.props.onNext}
            text={"Next"}
            Icon={NavigateNextIcon}
            active={this.props.step < this.props.steps}
            disabled={this.props.step == this.props.steps} />;

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'space-between', 
                            padding: '0 20px' }}>
                        <div style={{ flex: 0 }}>
                            {previousButton}
                        </div>
                        {deleteButton && (
                            <div style={{ flex: 0 }}>
                                {deleteButton}
                            </div>
                        )}
                        {this.props.attachButton && (
                            <div style={{ flex: 0 }}>
                                {this.props.attachButton}
                            </div>
                        )}
                        {draftButton && (
                            <div style={{ flex: 0 }}>
                                {draftButton}
                            </div>
                        )}
                        {submitButton && (
                            <div style={{ flex: 0 }}>
                                {submitButton}
                            </div>
                        )}
                        <div style={{ flex: 0 }}>
                            {nextButton}
                        </div>
                    </div>
                </Grid>
            </Grid >
        );

    }
}