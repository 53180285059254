import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import FilePreview from '../../common/FilePreview';
import ListIcon from '../../../resources/ListIcon';
import { KeyName } from '../../../common/dto/common';
import DescriptionIcon from '@material-ui/icons/Description';

export interface ICorrectionalFacilityInMateCellRowProps {
    data: AusComplyDtos.CorrectionalFacilityInMateCell;
    onCommand: Function;
    commands: KeyName[];
}

export default class CorrectionalFacilityInMateCellRow extends React.Component<ICorrectionalFacilityInMateCellRowProps, any> {
    constructor(props: ICorrectionalFacilityInMateCellRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            if (this.props.commands && this.props.commands.find(f => f.key == command)) {
                this.props.onCommand(command, this.props.data);
            }
        }
    }

    render() {
        if (!this.props.data) {
            return (<></>);
        }

        var item = this.props.data;


        return (
            <TableRow isDanger={item.correctionalFacilityInMateVenueLocation.isNextInspectionOverdue} commands={this.props.commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.venueLocation.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.correctionalFacilityInMateVenueLocation.subLocation}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.correctionalFacilityInMate.firstName}&nbsp;{item.correctionalFacilityInMate.lastName}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.correctionalFacilityInMate.gender.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.correctionalFacilityInMate.age}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.correctionalFacilityInMate.nationality ? item.correctionalFacilityInMate.nationality.name : ""}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>

                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")} style={{ textAlign: 'center', verticalAlign: 'middle', paddingTop: '10px' }}>
                    {item.correctionalFacilityInMateNotes.length > 0 && (
                        <DescriptionIcon fill={"#999"} />
                    )}
                </td>
                {item.nextInspectionIncident && (
                    <td onClick={() => this.onCommand("inspection")}>
                        <p style={{textDecoration: 'underline', color: '#03a9f4', cursor: 'pointer'}}>{item.nextInspectionIncident.incidentSerialNumber}</p>
                    </td>
                )}
                {!item.nextInspectionIncident && (
                    <td onClick={() => this.onCommand("edit")}>
                        <p>
                            {item.correctionalFacilityInMateVenueLocation.nextInspectionDateDisplay}
                        </p>
                    </td>
                )}
                <td>
                    <FilePreview file={item.correctionalFacilityInMate.photo} small={true} />
                </td>
            </TableRow>
        );
    }
}