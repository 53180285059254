import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { KeyName, PrintSend } from '../../common/dto/common';
import FullPageDialog from '../layout/FullPageDialog';
import PrintAndSend from '../print/PrintAndSend';
import LayoutForm from '../layout/LayoutForm';

export interface ISignOnOffRegisterPrintProps {
    theme: any;
    classes: any;
    filter: AusComplyDTOs.SignOnRegisterFilter,
    printOptions?: AusComplyDTOs.PrintOptions,
    show: boolean;
    isLoading: boolean;
    onHide: Function;
    onLoadPrintOptions: Function;
    onPrint: Function;
    onErrorNotification: Function;
}

export interface ISignOnOffRegisterPrintState {
}

class SignOnOffRegisterPrint extends React.PureComponent<ISignOnOffRegisterPrintProps, ISignOnOffRegisterPrintState> {

    constructor(props: ISignOnOffRegisterPrintProps) {
        super(props);
        this.state = {
        };
        this.onDismissed = this.onDismissed.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.props.onLoadPrintOptions();
        }
    }

    onDismissed() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    handleExport(option: string) {
        this.onPrint("EXPORT", [], [], []);
    }

    handlePrint(option: string) {
        this.onPrint("DOWNLOAD", [], [], []);
    }

    handleEmail(option: string, send: PrintSend) {
        this.onPrint("EMAIL", send.recipients, send.ccRecipients, send.bccRecipients);
    }

    onPrint(option: string, to: string[], cc: string[], bcc: string[]) {
        if (this.props.onPrint) {
            this.props.onPrint(option, { ...this.props.filter }, to, cc, bcc);
        }
    }

    render() {
        let pdfOptions: KeyName[] = [];

        return (
            <FullPageDialog open={this.props.show}
                notFullWidth={true}
                onDismissed={this.onDismissed} title={"Print"}>
                <LayoutForm>
                    <PrintAndSend
                        isLoading={this.props.isLoading}
                        printOptions={this.props.printOptions}
                        onPrint={this.handlePrint}
                        onCancel={this.onDismissed}
                        onEmail={this.handleEmail}
                        onExport={this.handleExport}
                        pdfOptions={pdfOptions} />
                </LayoutForm>
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SignOnOffRegisterPrint);