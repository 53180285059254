import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POIRegistration from '../components/facialRecognition/facialRecognitionPOI/POIRegistration'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { 
    facialRecognitionPoiRegistrationRequest, 
    facialRecognitionPoiRegistrationSet,
    facialRecognitionPoiRegistrationReset,
    facialRecognitionPoiRegistrationScanFacesRequest,
    facialRecognitionPoiRegistrationSearchFaceRequest,
    facialRecognitionPoiRegistrationSaveRequest
 } from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        incidentId: props.incidentId,
        venueId: 0,
        isScanning: state.facialRecognitionPOI.poiRegistration.isScanning,
        isSearching: state.facialRecognitionPOI.poiRegistration.isSearching,
        isSaving: state.facialRecognitionPOI.poiRegistration.isSaving,
        isLoading: state.facialRecognitionPOI.poiRegistration.isLoading,
        poiRegistration: state.facialRecognitionPOI.poiRegistration.poiRegistration,
        canCreate: permissionsLogic.hasPermissionForState(state, 'RegisterFacialRecognitionPOI'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreate: (incidentId, venueId) => {
            dispatch(facialRecognitionPoiRegistrationRequest(incidentId))
        },
        onUpdate: (poiRegistration) => {
            dispatch(facialRecognitionPoiRegistrationSet(poiRegistration))
        },
        onScanFaces: () => {
            dispatch(facialRecognitionPoiRegistrationScanFacesRequest())
        },
        onSearchFace: () => {
            dispatch(facialRecognitionPoiRegistrationSearchFaceRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionPoiRegistrationReset())
        },
        onSave: () => {
            dispatch(facialRecognitionPoiRegistrationSaveRequest())
        }
    }
}

const FacialRecognitionPOIRegistrationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POIRegistration))

export default FacialRecognitionPOIRegistrationContainer