import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import LayoutForm from '../../layout/LayoutForm';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import View from '../../common/View';
import RadioChipControlGroup from '../../controls/RadioChipControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';

export interface IPlayTraxPatronProps {
    incident: AusComplyDtos.Incident;
    incidentPatron: AusComplyDtos.IncidentPatron;
    incidentOptions: AusComplyDtos.IncidentOptions;
    onUpdated: Function;
    theme: any;
    classes: any;
}

interface IPlayTraxPatronState {
    requiresDOB: boolean;
    requiresPersonDescription: boolean;
    requiresContactTracing: boolean;
}

class PlayTraxPatron extends Component<IPlayTraxPatronProps, IPlayTraxPatronState> {
    constructor(props: IPlayTraxPatronProps) {
        super(props)
        this.state = {
            requiresDOB: false,
            requiresPersonDescription: false,
            requiresContactTracing: false
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    componentDidUpdate(prevProps) {
        let requiresDOB = incidentLogic.isDateOfBirthRequired(this.props.incident, this.props.incidentOptions);
        let requiresPersonDescription = incidentLogic.isPersonDescriptionRequired(this.props.incident, this.props.incidentOptions);
        let requiresContactTracing = incidentLogic.isIncidentContactTracing(this.props.incident, this.props.incidentOptions);
        if (requiresDOB != this.state.requiresDOB ||
            requiresPersonDescription != this.state.requiresPersonDescription ||
            requiresContactTracing != this.state.requiresContactTracing) {
            this.setState({
                requiresDOB,
                requiresPersonDescription,
                requiresContactTracing
            });
        }
    }

    onSelectionChanged(fieldName: string, value: any) {
        let patron = { ...this.props.incidentPatron };
        patron[fieldName] = value;
        this.props.onUpdated(patron);
    }

    render() {
        let patron = this.props.incidentPatron;
        let patronGenderWidthStyle = (!patron.genderId || patron.genderId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        let patronAgeWidthStyle = (!patron.incidentPatronAgeRangeId || patron.incidentPatronAgeRangeId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        let patronAppearanceWidthStyle = (!patron.incidentPatronRacialAppearanceId || patron.incidentPatronRacialAppearanceId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        return (
            <View style={{ marginBottom: '20px'}}>
                <LayoutForm
                    fullWidth={true}
                >
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {this.state.requiresContactTracing && (
                                    <>
                                        <TextControlGroup text="Name"
                                            defaultValue={patron.name}
                                            onBlur={(value) => this.onSelectionChanged("name", value)}
                                            labelAbove={true} />
                                        <TextControlGroup text="Phone"
                                            defaultValue={patron.phone}
                                            onChange={(value) => this.onSelectionChanged("phone", value)}
                                            labelAbove={true} />
                                        <TextControlGroup text="Email / Member Number"
                                            defaultValue={patron.email}
                                            onChange={(value) => this.onSelectionChanged("email", value)}
                                            labelAbove={true} />
                                    </>
                                )}
                                {this.state.requiresDOB && (
                                    <SimpleDateControlGroup text="Date of Birth"
                                        date={patron.dobDate}
                                        readonly={patron.isUnderAgeLocked}
                                        onChange={(value) => this.onSelectionChanged("dobDate", value)}
                                        labelAbove={true} />
                                )}
                                {this.state.requiresPersonDescription && (
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" p={0} m={0} style={{ marginTop: '10px' }}>
                                        <Box style={patronGenderWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>The patron involved is </p></Box>
                                        <Box p={0} style={patronGenderWidthStyle}>
                                            <RadioChipControlGroup
                                                chipWidth={"120px"}
                                                name="gender"
                                                selected={patron.genderId}
                                                onChanged={(value) => this.onSelectionChanged("genderId", value)}
                                                list={this.props.incidentOptions.incidentPatronGenders}
                                                required={true}
                                                labelAbove={true} />
                                        </Box>
                                        <Box style={patronAgeWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>aged</p></Box>
                                        <Box p={0} style={patronAgeWidthStyle}>
                                            <RadioChipControlGroup
                                                chipWidth={"120px"}
                                                name="age"
                                                selected={patron.incidentPatronAgeRangeId}
                                                onChanged={(value) => this.onSelectionChanged("incidentPatronAgeRangeId", value)}
                                                list={this.props.incidentOptions.incidentPatronAgeRanges}
                                                readonly={patron.isUnderAgeLocked}
                                                required={true}
                                                labelAbove={true} />
                                        </Box>
                                        <Box style={patronAppearanceWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>appearing</p></Box>
                                        <Box style={patronAppearanceWidthStyle} p={0}>
                                            <RadioChipControlGroup
                                                chipWidth={"120px"}
                                                name="racial"
                                                selected={patron.incidentPatronRacialAppearanceId}
                                                onChanged={(value) => this.onSelectionChanged("incidentPatronRacialAppearanceId", value)}
                                                list={this.props.incidentOptions.incidentPatronRacialAppearances}
                                                required={true}
                                                labelAbove={true} />
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </LayoutForm>
            </View>);
    }
}

export default withStyles(styles, { withTheme: true })(PlayTraxPatron);