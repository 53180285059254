import React, { Component } from 'react';
import UserContainer from '../../containers/UserContainer'

export interface IUserRoleUser {
    userId: number;
}

export default class UserRoleUser extends Component<IUserRoleUser, any> {
    render() {
        return (
            <UserContainer userId={this.props.userId} />
        );
    }
}