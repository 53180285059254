export const
    ROLE_TYPES_REQUEST = "ROLE_TYPES_REQUEST",
    ROLE_TYPES_REQUEST_FAILURE = "ROLE_TYPES_REQUEST_FAILURE",
    ROLE_TYPES_REQUEST_SUCCESS = "ROLE_TYPES_REQUEST_SUCCESS",
    ROLE_TYPES_FILTER_META_REQUEST = "ROLE_TYPES_FILTER_META_REQUEST",
    ROLE_TYPES_FILTER_META_REQUEST_SUCCESS = "ROLE_TYPES_FILTER_META_REQUEST_SUCCESS",
    ROLE_TYPES_FILTER_META_REQUEST_FAILURE = "ROLE_TYPES_FILTER_META_REQUEST_FAILURE",
    ROLE_TYPES_FILTER_SET = "ROLE_TYPES_FILTER_SET",
    ROLE_TYPE_LOAD_REQUEST = "ROLE_TYPE_LOAD_REQUEST",
    ROLE_TYPE_LOAD_REQUEST_SUCCESS = "ROLE_TYPE_LOAD_REQUEST_SUCCESS",
    ROLE_TYPE_LOAD_REQUEST_FAILURE = "ROLE_TYPE_LOAD_REQUEST_FAILURE",
    ROLE_TYPE_UPDATE = "ROLE_TYPE_UPDATE",
    ROLE_TYPE_ADD_REQUEST = "ROLE_TYPE_ADD_REQUEST",
    ROLE_TYPE_ADD_REQUEST_SUCCESS = "ROLE_TYPE_ADD_REQUEST_SUCCESS",
    ROLE_TYPE_ADD_REQUEST_FAILURE = "ROLE_TYPE_ADD_REQUEST_FAILURE",
    ROLE_TYPE_ADD_SAVE_REQUEST = "ROLE_TYPE_ADD_SAVE_REQUEST",
    ROLE_TYPE_ADD_SAVE_REQUEST_SUCCESS = "ROLE_TYPE_ADD_SAVE_REQUEST_SUCCESS",
    ROLE_TYPE_ADD_SAVE_REQUEST_FAILURE = "ROLE_TYPE_ADD_SAVE_REQUEST_FAILURE",
    ROLE_TYPE_UPDATE_SAVE = "ROLE_TYPE_UPDATE_SAVE",
    ROLE_TYPE_UPDATE_SAVE_SUCCESS = "ROLE_TYPE_UPDATE_SAVE_SUCCESS",
    ROLE_TYPE_UPDATE_SAVE_FAILURE = "ROLE_TYPE_UPDATE_SAVE_FAILURE",
    ROLE_TYPE_DELETE_REQUEST = "ROLE_TYPE_DELETE_REQUEST",
    ROLE_TYPE_DELETE_REQUEST_SUCCESS = "ROLE_TYPE_DELETE_REQUEST_SUCCESS",
    ROLE_TYPE_DELETE_REQUEST_FAILURE = "ROLE_TYPE_DELETE_REQUEST_FAILURE",

    USER_ROLES_REFRESH = "USER_ROLES_REFRESH",
    USER_ROLES_RESET = "USER_ROLES_RESET",
    USER_ROLES_REQUEST = "USER_ROLES_REQUEST",
    USER_ROLES_REQUEST_SUCCESS = "USER_ROLES_REQUEST_SUCCESS",
    USER_ROLES_REQUEST_FAILURE = "USER_ROLES_REQUEST_FAILURE",
    USER_ROLES_FILTER_SET = "USER_ROLES_FILTER_SET",
    USER_ROLE_UPDATE = "USER_ROLE_UPDATE",
    USER_ROLE_ADD_REQUEST = "USER_ROLE_ADD_REQUEST",
    USER_ROLE_ADD_REQUEST_SUCCESS = "USER_ROLE_ADD_REQUEST_SUCCESS",
    USER_ROLE_ADD_REQUEST_FAILURE = "USER_ROLE_ADD_REQUEST_FAILURE",
    USER_ROLE_ADD_SAVE = "USER_ROLE_ADD_SAVE",
    USER_ROLE_ADD_SAVE_SUCCESS = "USER_ROLE_ADD_SAVE_SUCCESS",
    USER_ROLE_ADD_SAVE_FAILURE = "USER_ROLE_ADD_SAVE_FAILURE",
    USER_ROLE_LOAD_REQUEST = "USER_ROLE_LOAD_REQUEST",
    USER_ROLE_LOAD_REQUEST_SUCCESS = "USER_ROLE_LOAD_REQUEST_SUCCESS",
    USER_ROLE_LOAD_REQUEST_FAILURE = "USER_ROLE_LOAD_REQUEST_FAILURE",
    USER_ROLE_UPDATE_SAVE = "USER_ROLE_UPDATE_SAVE",
    USER_ROLE_UPDATE_SAVE_SUCCESS = "USER_ROLE_UPDATE_SAVE_SUCCESS",
    USER_ROLE_UPDATE_SAVE_FAILURE = "USER_ROLE_UPDATE_SAVE_FAILURE",
    USER_ROLE_REMOVE_REQUEST = "USER_ROLE_REMOVE_REQUEST",
    USER_ROLE_REMOVE_REQUEST_SUCCESS = "USER_ROLE_REMOVE_REQUEST_SUCCESS",
    USER_ROLE_REMOVE_REQUEST_FAILURE = "USER_ROLE_REMOVE_REQUEST_FAILURE",
    USER_ROLE_ENABLE_REQUEST = "USER_ROLE_ENABLE_REQUEST",
    USER_ROLE_ENABLE_REQUEST_SUCCESS = "USER_ROLE_ENABLE_REQUEST_SUCCESS",
    USER_ROLE_ENABLE_REQUEST_FAILURE = "USER_ROLE_ENABLE_REQUEST_FAILURE",
    USER_ROLE_REQUEST_DISMISS_REQUEST = "USER_ROLE_REQUEST_DISMISS_REQUEST",
    USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS = "USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS",
    USER_ROLE_REQUEST_DISMISS_REQUEST_FAILURE = "USER_ROLE_REQUEST_DISMISS_REQUEST_FAILURE",
    USER_ROLE_USER_VIEW_REQUEST = "USER_ROLE_USER_VIEW_REQUEST",
    USER_ROLE_USER_VIEW_REQUEST_SUCCESS = "USER_ROLE_USER_VIEW_REQUEST_SUCCESS",
    USER_ROLE_USER_VIEW_REQUEST_FAILURE = "USER_ROLE_USER_VIEW_REQUEST_FAILURE",
    USER_ROLE_USER_REQUEST = "USER_ROLE_USER_REQUEST",
    USER_ROLE_USER_REQUEST_SUCCESS = "USER_ROLE_USER_REQUEST_SUCCESS",
    USER_ROLE_USER_REQUEST_FAILURE = "USER_ROLE_USER_REQUEST_FAILURE",
    USER_ROLE_USER_REFRESH = "USER_ROLE_USER_REFRESH",
    USER_ROLES_EXPORT_REQUEST = "USER_ROLE_EXPORT_REQUEST",
    USER_ROLES_EXPORT_REQUEST_SUCCESS = "USER_ROLE_EXPORT_REQUEST_SUCCESS",
    USER_ROLES_EXPORT_REQUEST_FAILURE = "USER_ROLE_EXPORT_REQUEST_FAILURE",

    USER_ROLE_OTHER_DOCUMENTS_SAVE = "USER_ROLE_OTHER_DOCUMENTS_SAVE",
    USER_ROLE_OTHER_DOCUMENTS_SAVE_SUCCESS = "USER_ROLE_OTHER_DOCUMENTS_SAVE_SUCCESS",
    USER_ROLE_OTHER_DOCUMENTS_SAVE_FAILURE = "USER_ROLE_OTHER_DOCUMENTS_SAVE_FAILURE",
    USER_ROLE_OTHER_DOCUMENT_REMOVE = "USER_ROLE_OTHER_DOCUMENT_REMOVE",
    USER_ROLE_OTHER_DOCUMENT_REMOVE_SUCCESS = "USER_ROLE_OTHER_DOCUMENT_REMOVE_SUCCESS",
    USER_ROLE_OTHER_DOCUMENT_REMOVE_FAILURE = "USER_ROLE_OTHER_DOCUMENT_REMOVE_FAILURE",
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE = "USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE",
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS = "USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS",
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE = "USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE",
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE = "USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE",
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS = "USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS",
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE = "USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE",

    USER_ROLES_SUSPEND_SAVE = "USER_ROLES_SUSPEND_SAVE",
    USER_ROLES_SUSPEND_SAVE_SUCCESS = "USER_ROLES_SUSPEND_SAVE_SUCCESS",
    USER_ROLES_SUSPEND_SAVE_FAILURE = "USER_ROLES_SUSPEND_SAVE_FAILURE"
    ;
export function userRolesSuspend(forVenueId, forSecurityFirmId, undo) {
    return {
        type: USER_ROLES_SUSPEND_SAVE,
        forVenueId, 
        forSecurityFirmId, 
        undo
    };
}

export function userRolesSuspendSuccess() {
    return {
        type: USER_ROLES_SUSPEND_SAVE_SUCCESS
    };
}

export function userRolesSuspendFailure(error) {
    return {
        type: USER_ROLES_SUSPEND_SAVE_FAILURE,
        error
    };
}

export function roleTypesRequest(filter, page, pageSize) {
    return {
        type: ROLE_TYPES_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function roleTypesRequestSuccess(users, paging, filter) {
    return {
        type: ROLE_TYPES_REQUEST_SUCCESS,
        users,
        paging,
        filter
    };
}

export function roleTypesRequestFailure(error) {
    return {
        type: ROLE_TYPES_REQUEST_FAILURE,
        error
    };
}

export function roleTypesFilterSet(filter) {
    return {
        type: ROLE_TYPES_FILTER_SET,
        filter
    }
}


export function roleTypeLoadRequest(userRoleTypeId) {
    return {
        type: ROLE_TYPE_LOAD_REQUEST,
        userRoleTypeId
    };
}

export function roleTypeLoadRequestSuccess(userRoleType, availableUserPermissions, availableIndustryCategories, availableStates) {
    return {
        type: ROLE_TYPE_LOAD_REQUEST_SUCCESS,
        userRoleType,
        availableUserPermissions,
        availableIndustryCategories,
        availableStates
    };
}

export function roleTypeLoadRequestFailure(error) {
    return {
        type: ROLE_TYPE_LOAD_REQUEST_FAILURE,
        error
    };
}

export function roleTypeUpdate(roleType) {
    return {
        type: ROLE_TYPE_UPDATE,
        roleType
    };
}

export function roleTypeAdd(cloneRoleTypeId) {
    return {
        type: ROLE_TYPE_ADD_REQUEST,
        cloneRoleTypeId
    };
}

export function roleTypeAddSuccess(userRoleType, availableUserPermissions, availableIndustryCategories, availableStates) {
    return {
        type: ROLE_TYPE_ADD_REQUEST_SUCCESS,
        userRoleType,
        availableUserPermissions,
        availableIndustryCategories,
        availableStates
    };
}

export function roleTypeAddFailure(error) {
    return {
        type: ROLE_TYPE_ADD_REQUEST_FAILURE,
        error
    };
}

export function roleTypeAddSave(roleType, localRoleEditMode, venueId, securityFirmId) {
    return {
        type: ROLE_TYPE_ADD_SAVE_REQUEST,
        roleType,
        localRoleEditMode,
        venueId,
        securityFirmId
    };
}

export function roleTypeAddSaveSuccess(roleType) {
    return {
        type: ROLE_TYPE_ADD_SAVE_REQUEST_SUCCESS,
        roleType
    };
}

export function roleTypeAddSaveFailure(error) {
    return {
        type: ROLE_TYPE_ADD_SAVE_REQUEST_FAILURE,
        error
    };
}

export function roleTypeUpdateSave(roleType, localRoleEditMode, venueId, securityFirmId) {
    return {
        type: ROLE_TYPE_UPDATE_SAVE,
        roleType,
        localRoleEditMode,
        venueId,
        securityFirmId
    };
}

export function roleTypeUpdateSaveSuccess(roleType) {
    return {
        type: ROLE_TYPE_UPDATE_SAVE_SUCCESS,
        roleType
    };
}

export function roleTypeUpdateSaveFailure(error) {
    return {
        type: USER_ROLE_UPDATE_SAVE_FAILURE,
        error
    };
}

export function roleTypeDeleteRequest(roleTypeId) {
    return {
        type: ROLE_TYPE_DELETE_REQUEST,
        roleTypeId
    };
}

export function roleTypeDeleteRequestSuccess() {
    return {
        type: ROLE_TYPE_DELETE_REQUEST_SUCCESS
    };
}

export function roleTypeDeleteRequestFailure(error) {
    return {
        type: ROLE_TYPE_DELETE_REQUEST_FAILURE,
        error
    };
}

export function userRoleSaveOtherDocuments(userId, documentTypeId, files) {
    return {
        type: USER_ROLE_OTHER_DOCUMENTS_SAVE,
        userId,
        documentTypeId,
        files
    };
}

export function userRoleSaveOtherDocumentsSuccess(userOtherDocuments) {
    return {
        type: USER_ROLE_OTHER_DOCUMENTS_SAVE_SUCCESS,
        userOtherDocuments
    };
}

export function userRoleSaveOtherDocumentsFailure(error) {
    return {
        type: USER_ROLE_OTHER_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function userRoleRemoveOtherDocument(userId, otherDocument) {
    return {
        type: USER_ROLE_OTHER_DOCUMENT_REMOVE,
        userId,
        otherDocument
    };
}

export function userRoleRemoveOtherDocumentSuccess(userOtherDocuments) {
    return {
        type: USER_ROLE_OTHER_DOCUMENT_REMOVE_SUCCESS,
        userOtherDocuments
    };
}

export function userRoleRemoveOtherDocumentFailure(error) {
    return {
        type: USER_ROLE_OTHER_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function userRoleSaveComplianceDocuments(userId, documentTypeId, expiryDate, files) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE,
        userId,
        documentTypeId,
        expiryDate,
        files
    };
}

export function userRoleSaveComplianceDocumentsSuccess(userComplianceDocuments) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
        userComplianceDocuments
    };
}

export function userRoleSaveComplianceDocumentsFailure(error) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function userRoleRemoveComplianceDocument(userId, complianceDocument) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE,
        userId,
        complianceDocument
    };
}

export function userRoleRemoveComplianceDocumentSuccess(userComplianceDocuments) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
        userComplianceDocuments
    };
}

export function userRoleRemoveComplianceDocumentFailure(error) {
    return {
        type: USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function userRolesExportRequest() {
    return {
        type: USER_ROLES_EXPORT_REQUEST
    };
}

export function userRolesExportRequestSuccess() {
    return {
        type: USER_ROLES_EXPORT_REQUEST_SUCCESS
    };
}

export function userRolesExportRequestFailure(error) {
    return {
        type: USER_ROLES_EXPORT_REQUEST_FAILURE,
        error
    };
}

export function userRolesRefresh() {
    return {
        type: USER_ROLES_REFRESH
    };
}

export function userRolesReset() {
    return {
        type: USER_ROLES_RESET
    };
}

export function userRolesRequest(filter, page, pageSize) {
    return {
        type: USER_ROLES_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function userRolesRequestSuccess(userRoles, paging, filter) {
    return {
        type: USER_ROLES_REQUEST_SUCCESS,
        userRoles,
        paging,
        filter
    };
}

export function userRolesRequestFailure(error) {
    return {
        type: USER_ROLES_REQUEST_FAILURE,
        error
    };
}

export function userRolesFilterSet(filter) {
    return {
        type: USER_ROLES_FILTER_SET,
        filter
    }
}

export function userRoleEnableRequest(userRoleId) {
    return {
        type: USER_ROLE_ENABLE_REQUEST,
        userRoleId
    };
}

export function userRoleEnableRequestSuccess(userRole) {
    return {
        type: USER_ROLE_ENABLE_REQUEST_SUCCESS,
        userRole
    };
}

export function userRoleEnableRequestFailure(error) {
    return {
        type: USER_ROLE_ENABLE_REQUEST_FAILURE,
        error
    };
}

export function userRoleRequestDismissRequest(notificationId) {
    return {
        type: USER_ROLE_REQUEST_DISMISS_REQUEST,
        notificationId
    };
}

export function userRoleRequestDismissRequestSuccess() {
    return {
        type: USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS
    };
}

export function userRoleRequestDismissRequestFailure(error) {
    return {
        type: USER_ROLE_REQUEST_DISMISS_REQUEST_FAILURE,
        error
    };
}

export function userRoleRemoveRequest(userRoleId, adminNotes) {
    return {
        type: USER_ROLE_REMOVE_REQUEST,
        userRoleId,
        adminNotes
    };
}

export function userRoleRemoveRequestSuccess(userRole) {
    return {
        type: USER_ROLE_REMOVE_REQUEST_SUCCESS,
        userRole
    };
}

export function userRoleRemoveRequestFailure(error) {
    return {
        type: USER_ROLE_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function userRoleUpdate(userRole) {
    return {
        type: USER_ROLE_UPDATE,
        userRole
    };
}

export function userRoleAddRequest(userId, venueId, securityFirmId, forSecurityFirm) {
    return {
        type: USER_ROLE_ADD_REQUEST,
        userId,
        venueId,
        securityFirmId,
        forSecurityFirm
    };
}

export function userRoleAddRequestSuccess(userRole, venues, securityFirms, userRoleTypes) {
    return {
        type: USER_ROLE_ADD_REQUEST_SUCCESS,
        userRole,
        venues,
        securityFirms,
        userRoleTypes
    };
}

export function userRoleAddRequestFailure(error) {
    return {
        type: USER_ROLE_ADD_REQUEST_FAILURE,
        error
    };
}

export function userRoleAddSave(userRole, notificationId) {
    return {
        type: USER_ROLE_ADD_SAVE,
        userRole,
        notificationId
    };
}

export function userRoleAddSaveSuccess(userRole) {
    return {
        type: USER_ROLE_ADD_SAVE_SUCCESS,
        userRole
    };
}

export function userRoleAddSaveFailure(error) {
    return {
        type: USER_ROLE_ADD_SAVE_FAILURE,
        error
    };
}

export function userRoleLoadRequest(userRoleId) {
    return {
        type: USER_ROLE_LOAD_REQUEST,
        userRoleId
    };
}

export function userRoleLoadRequestSuccess(userRole, userRoleTypes, userRoleStatus) {
    return {
        type: USER_ROLE_LOAD_REQUEST_SUCCESS,
        userRole,
        userRoleTypes,
        userRoleStatus
    };
}

export function userRoleLoadRequestFailure(error) {
    return {
        type: USER_ROLE_LOAD_REQUEST_FAILURE,
        error
    };
}

export function userRoleUpdateSave(userRole) {
    return {
        type: USER_ROLE_UPDATE_SAVE,
        userRole
    };
}

export function userRoleUpdateSaveSuccess(userRole) {
    return {
        type: USER_ROLE_UPDATE_SAVE_SUCCESS,
        userRole
    };
}

export function userRoleUpdateSaveFailure(error) {
    return {
        type: USER_ROLE_UPDATE_SAVE_FAILURE,
        error
    };
}

export function userRolesUserViewRequest(userId) {
    return {
        type: USER_ROLE_USER_VIEW_REQUEST,
        userId
    };
}

export function userRolesUserViewRequestSuccess(userView) {
    return {
        type: USER_ROLE_USER_VIEW_REQUEST_SUCCESS,
        userView
    };
}

export function userRolesUserViewRequestFailure(error) {
    return {
        type: USER_ROLE_USER_VIEW_REQUEST_FAILURE,
        error
    };
}

export function userRolesUserRequest(userId) {
    return {
        type: USER_ROLE_USER_REQUEST,
        userId
    };
}

export function userRolesUserRequestSuccess(user, avatar, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    return {
        type: USER_ROLE_USER_REQUEST_SUCCESS,
        user,
        avatar,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes
    };
}

export function userRolesUserRequestFailure(error) {
    return {
        type: USER_ROLE_USER_REQUEST_FAILURE,
        error
    };
}

export function userRoleUserRefresh() {
    return {
        type: USER_ROLE_USER_REFRESH
    }
}