import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import View from '../common/View';
import MoreIcon from '../../resources/MoreIcon';
import TransparentButton from '../controls/TransparentButton';
import PrintIcon from '../../resources/PrintIcon';
import FileEarmarkArrowDownIcon from '../../resources/FileEarmarkArrowDownIcon';
import { KeyName } from '../../common/dto/common';
import NavigationButton from '../controls/NavigationButton';
import PopupModal from '../layout/PopupModal';
import { Palette } from '../../common/constants/palette';
import FullPageDialog from '../layout/FullPageDialog';
import { appSource } from '../../utilities/constants';
import CardTypography from '../common/CardTypography';
import CardRow from '../common/CardRow';
import Error from '../common/Error';

export interface IPrintNavigationButtonProps {
    hideQueue?: boolean;
    queue: AusComplyDTOs.ReportGeneration[];
    onRefresh?: Function;
    onDownloaded?: Function;
    onDismissed?: Function;
    onDismissAll?: Function;
    navigation: any;
    moreCommands?: KeyName[];
    onCommand?: Function;
    onPrint?: Function;
    theme: any;
    classes: any;
}

export interface IPrintNavigationButtonState {
    isProcessing: boolean;
    isComplete: boolean;
    isError: boolean;
    open: boolean;
    show: boolean;
}

class PrintNavigationButton extends React.PureComponent<IPrintNavigationButtonProps, IPrintNavigationButtonState> {

    constructor(props: IPrintNavigationButtonProps) {
        super(props);
        this.state = {
            show: false,
            isProcessing: false,
            isComplete: false,
            isError: false,
            open: false
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSelectAction = this.onSelectAction.bind(this);
        this.getActions = this.getActions.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onDismissed = this.onDismissed.bind(this);
        this.onDismissAll = this.onDismissAll.bind(this);
        this.onDownloaded = this.onDownloaded.bind(this);
        this.onAction = this.onAction.bind(this);
    }

    timer;

    componentDidMount() {
        this.onRefresh();
        this.calculateState();
    }

    componentDidUpdate(prevprops) {
        if (this.props.queue && prevprops.queue) {
            // Only poll if there are items in the queue, requesting a print will put items in the queue, and 
            // returning to the home page will also check the queue
            if (this.props.queue.length === 0 && prevprops.queue.length !== 0) {
                clearInterval(this.timer);
            }
            if (this.props.queue.length !== 0 && prevprops.queue.length === 0) {
                this.timer = setInterval(() => this.onRefresh(), 3500);
            }
        }
        this.calculateState();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    calculateState() {
        let isProcessing = false;
        let isComplete = false;
        let isError = false;
        if (this.props.queue && this.props.queue.length > 0) {
            isComplete = this.props.queue.filter(item => item.status == 'Ready').length > 0;
            isError = this.props.queue.filter(item => item.status == 'Error' || item.status == 'Cancelled').length > 0;
            isProcessing = this.props.queue.filter(item => item.status == 'Requested' || item.status == 'Generating').length > 0;
        }
        if (this.state.isProcessing != isProcessing ||
            this.state.isComplete != isComplete ||
            this.state.isError != isError) {
            this.setState({ isProcessing, isComplete, isError });
        }
    }

    onRefresh() {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    onOpen() {
        if (this.getActions().length > 1) {
            this.setState({ open: true });
        } else {
            if (this.props.onPrint){
                this.props.onPrint();
            }
        }
    }

    onSelectAction(command: string) {
        this.setState({ open: false }, () => {
            if (command) {
                switch (command) {
                    case "print":
                        if (this.props.onPrint){
                            this.props.onPrint();
                        }
                        break;
                    case "printqueue":
                        this.setState({ show: true });
                        break;
                    case "cancel":
                        break;
                    default:
                        if (this.props.onCommand) {
                            this.props.onCommand(command);
                        }
                        break;
                }
            }
        });
    }

    getActions(): KeyName[] {
        let actions: KeyName[] = [];
        if (this.props.moreCommands && this.props.moreCommands.length > 0) {
            actions = [
                ...this.props.moreCommands
            ];
        }
        actions = [
            ...actions,
            { key: "print", name: "Print" }
        ];
        if (!this.props.hideQueue && (this.state.isProcessing || this.state.isError || this.state.isComplete)) {
            actions = [
                ...actions,
                { key: "printqueue", name: "Print Queue" }
            ]
        }
        return actions;
    }

    onAction(item: AusComplyDTOs.ReportGeneration) {
        let closeAfterAction = this.props.queue.length == 1;
        if (item.status == 'Ready') {
            this.onDownloaded(item);
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            this.onDismissed(item);
        }
        if (closeAfterAction) {
            this.setState({show: false})
        }
    }

    onShow(show) {
        this.setState({ show });
    }

    onDownloaded(item: AusComplyDTOs.ReportGeneration) {
        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let filePath = base + '/api/reportgenerated/' + item.reportGenerationId + "?downloadToken=" + item.downloadToken;
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            window.open(response.file);
        }, 100);
        if (this.props.onDownloaded) {
            this.props.onDownloaded(item.reportGenerationId);
        }
    }

    onDismissed(item: AusComplyDTOs.ReportGeneration) {
        if (this.props.onDismissed) {
            this.props.onDismissed(item.reportGenerationId);
        }
    }

    onDismissAll() {
        if (this.props.onDismissAll) {
            this.props.onDismissAll();
        }
    }

    renderCard(item: AusComplyDTOs.ReportGeneration) {
        const { theme } = this.props;
        let isSuccess = false;
        let isCancelled = false;
        let actionText = "";
        if (item.status == 'Ready') {
            isSuccess = true;
            actionText = "download";
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            isCancelled = true;
            actionText = "dismiss";
        }
        return (
            <CardRow isSuccess={isSuccess}
                isDanger={isCancelled}
                isWarning={!isSuccess && !isCancelled}
                style={{cursor: 'pointer'}}
                key={"report-generation-" + item.reportGenerationId.toString()}>
                <Box p={0} display="flex" flexDirection={'row'} onClick={() => this.onAction(item)}>
                    <Box flexGrow={1} p={0}>
                        <CardTypography>{item.title}</CardTypography>
                    </Box>
                    <Box flexGrow={0} p={0}>
                        <CardTypography style={{ color: theme.palette.text.secondary }}>{actionText}</CardTypography>
                    </Box>
                </Box>
                {item.error && (
                    <Error message={item.error} />
                )}
            </CardRow>
        );
    }


    render() {
        const { theme, classes } = this.props;

        let queue;
        let legend;

        let actions = this.getActions();

        if (this.state.show && this.props.queue && this.props.queue.length > 0) {
            queue = this.props.queue.map((item, index) => this.renderCard(item));
            legend = (
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistInProgress}></div>
                        <div className={classes.colorSwatchText}>Processing</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistRejected}></div>
                        <div className={classes.colorSwatchText}>Error</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistApproved}></div>
                        <div className={classes.colorSwatchText}>Ready</div>
                    </Box>
                </Box>
            );
        }

        return (
            <>
                <NavigationButton
                    onClick={() => this.onOpen()}
                    text={"Print"}
                    isError={this.state.isError}
                    isSuccess={this.state.isComplete}
                    isWarning={this.state.isProcessing}
                    Icon={FileEarmarkArrowDownIcon} />
                <PopupModal open={this.state.open} onAction={action => this.onSelectAction(action)} actions={actions} />
                <FullPageDialog
                    open={this.state.show}
                    title="Print queue"
                    containerStyle={{ display: 'inline-block' }}
                    footerRightContent={<TransparentButton text={"Clear"} onClick={() => this.onDismissAll()} />}
                    onDismissed={() => this.onShow(false)}
                >
                    <View style={{ margin: 20 }}>
                        {queue}
                        {legend}
                    </View>
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrintNavigationButton);