import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../utilities/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ButtonLink from '../controls/ButtonLink';
import DocumentIcon from '../../resources/DocumentIcon';
import Box from '@material-ui/core/Box';
import Progressing from '../controls/Progressing';
import GroupIcon from '../../resources/GroupIcon';
import PersonIcon from '../../resources/PersonIcon';
import BlockIcon from '@material-ui/icons/Block';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import BadgeWrapper from '../common/BadgeWrapper';
import DeleteOutlinedIcon from '../../resources/DeleteOutlinedIcon';
import { Palette } from '../../common/constants/palette';

export interface IPeopleProps {
    theme: any;
    classes: any;
    previewPath?: string;
    text?: string;
    groupCount?: number;
    isGroup?: boolean;
    isError?: boolean;
    isBlocked?: boolean;
    onClick?: Function;
    onRemove?: Function;
    style?: any;
}

class People extends React.PureComponent<IPeopleProps, any> {
    constructor(props: IPeopleProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick()
        }
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove()
        }
    }

    render() {
        const { classes, theme } = this.props;

        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let view;
        if (this.props.previewPath) {
            view = base + this.props.previewPath;
        }

        let preview;
        if (this.props.previewPath && !this.props.isBlocked) {
            preview = (
                <img src={view}
                    style={{...theme.custom.fileUploaderImage, width: '76px', height: '50px', margin: '10px 2px 23px', objectFit: 'cover'}}
                    alt="photo"
                    onClick={() => this.onClick()} />
            );
        } else {
            preview = (
                <div onClick={() => this.onClick()}>
                    {(!this.props.isGroup && this.props.isBlocked) && (
                        <BlockIcon
                            style={{
                                color: this.props.isError ? theme.custom.colors.error : theme.custom.colors.primary,
                                width: '46px',
                                height: '46px',
                                margin: '15px 17px 22px'
                            }}
                        />
                    )}
                    {(!this.props.isGroup && !this.props.isBlocked) && (
                        <PersonIcon
                            fill={this.props.isError ? theme.custom.colors.error : theme.custom.colors.primary}
                            style={{
                                width: '50px',
                                height: '50px',
                                margin: '13px 15px 20px'
                            }}
                        />
                    )}
                    {this.props.isGroup && (
                        <BadgeWrapper count={this.props.groupCount || 0} style={{
                            margin: '0',
                            width: 'auto'
                        }}
                            classes={{ badge: classes.badgeTopRightInside }}>
                            <GroupIcon
                                fill={this.props.isError ? theme.custom.colors.error : theme.custom.colors.primary}
                                style={{
                                    width: '60px',
                                    height: '60px',
                                    margin: '9px 10px 14px'
                                }}
                            />
                        </BadgeWrapper>
                    )}
                </div>
            );
        }

        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box p={1} flexGrow={1}>
                    <div style={{
                        ...theme.custom.fileUploaderContainer,
                        position: 'relative',
                        ...this.props.style,
                        borderColor: this.props.isError ? theme.custom.colors.error : theme.custom.colors.primary
                    }}>
                        {this.props.isError && (
                            <>
                                <FiberManualRecordIcon
                                    style={{
                                        color: theme.palette.background.default,
                                        position: 'absolute',
                                        width: '28px',
                                        height: '28px',
                                        left: '29px',
                                        top: '-13px'
                                    }}
                                />
                                <WarningRoundedIcon
                                    style={{
                                        color: this.props.isError ? theme.custom.colors.error : theme.custom.colors.controlBorder,
                                        position: 'absolute',
                                        width: '24px',
                                        height: '24px',
                                        left: '31px',
                                        top: '-13px'
                                    }} />
                            </>
                        )}
                        {preview}
                        {this.props.text && (
                            <label style={{ ...theme.custom.fileUploaderText, padding: '1px 3px', whiteSpace:'nowrap', overflow: 'hidden'}}>{this.props.text}</label>
                        )}
                    </div>
                </Box>
                <Box p={0} style={{ textAlign: 'center' }}>
                    {this.props.onRemove && (
                        <ButtonLink onClick={this.onRemove} style={{ padding: '0' }} >
                            <DeleteOutlinedIcon style={{
                                color: Palette.ControlBorder,
                                borderRadius: '12px',
                                width: '24px',
                                height: '24px'
                            }} />
                        </ButtonLink>
                    )}
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(People);