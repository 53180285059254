import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionServerClone from '../components/facialRecognition/facialRecognitionServers/FacialRecognitionServerClone';
import * as facialRecognitionServerActions from "../common/actions/facialRecognitionServer";
import * as referenceDataActions from '../common/actions/referenceData';

const mapStateToProps = (state, props) => {
    return {
        facialRecognitionServerId: props.facialRecognitionServerId,
        venues: state.referenceData.venues,
        isLoading: state.referenceData.venuesLoading,
        isSaving: state.facialRecognitionServer.facialRecognitionServer.isSaving,
        onDismissed: props.onDismissed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadVenues: () => {
            dispatch(referenceDataActions.loadVenuesRequest())
        },
        onSave: (facialRecognitionServerId, toVenueId) => {
            dispatch(facialRecognitionServerActions.facialRecognitionServerCloneRequest(facialRecognitionServerId, toVenueId))
        }
    }
}

const FacialRecognitionServerCloneContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionServerClone))

export default FacialRecognitionServerCloneContainer