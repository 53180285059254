import { connect } from 'react-redux'
import Login from '../components/login/Login'
import { userLoginRequest, userChanged } from '../common/actions/userLogin'

const mapStateToProps = state => {
    return {
        userIsLoading: state.user.isLoading,
        username: state.user.details.userSession.user.email
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAttemptLogin: (username, password) =>{
            dispatch(userLoginRequest(username, password))
        },
        onUserChanged: (username) => {
            dispatch(userChanged(username))
        }
    }
}

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default LoginContainer