import { makeActionCreator } from './helpers';

export const
SIGN_ON_RESET = "SIGN_ON_RESET",
SIGN_ON_REQUEST = "SIGN_ON_REQUEST",
SIGN_ON_REQUEST_SUCCESS = "SIGN_ON_REQUEST_SUCCESS",
SIGN_ON_REQUEST_FAILURE = "SIGN_ON_REQUEST_FAILURE",
SIGN_ON_CHECK_USER_REQUEST = "SIGN_ON_CHECK_USER_REQUEST",
SIGN_ON_CHECK_USER_RESULT = "SIGN_ON_CHECK_USER_RESULT",
SIGN_ON_SAVE_REQUEST = "SIGN_ON_SAVE_REQUEST",
SIGN_ON_SAVE_REQUEST_SUCCESS = "SIGN_ON_SAVE_REQUEST_SUCCESS",
SIGN_ON_SAVE_REQUEST_FAILURE = "SIGN_ON_SAVE_REQUEST_FAILURE",
SIGN_OFF_REQUEST = "SIGN_OFF_REQUEST",
SIGN_OFF_REQUEST_SUCCESS = "SIGN_OFF_REQUEST_SUCCESS",
SIGN_OFF_REQUEST_FAILURE = "SIGN_OFF_REQUEST_FAILURE",
SIGN_OFF_SAVE_REQUEST = "SIGN_OFF_SAVE_REQUEST",
SIGN_OFF_SAVE_REQUEST_SUCCESS = "SIGN_OFF_SAVE_REQUEST_SUCCESS",
SIGN_OFF_SAVE_REQUEST_FAILURE = "SIGN_OFF_SAVE_REQUEST_FAILURE",
SIGN_ON_EXTEND_REQUEST = "SIGN_ON_EXTEND_REQUEST",
SIGN_ON_EXTEND_REQUEST_SUCCESS = "SIGN_ON_EXTEND_REQUEST_SUCCESS",
SIGN_ON_EXTEND_REQUEST_FAILURE = "SIGN_ON_EXTEND_REQUEST_FAILURE",
SIGN_ON_EXTEND_SAVE_REQUEST = "SIGN_ON_EXTEND_SAVE_REQUEST",
SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS = "SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS",
SIGN_ON_EXTEND_SAVE_REQUEST_FAILURE = "SIGN_ON_EXTEND_SAVE_REQUEST_FAILURE",
SIGN_ON_REGISTER_REQUEST = "SIGN_ON_REGISTER_REQUEST",
SIGN_ON_REGISTER_REQUEST_SUCCESS = "SIGN_ON_REGISTER_REQUEST_SUCCESS",
SIGN_ON_REGISTER_REQUEST_FAILURE = "SIGN_ON_REGISTER_REQUEST_FAILURE",
SIGN_ON_REGISTER_FILTER_REQUEST = "SIGN_ON_REGISTER_FILTER_REQUEST",
SIGN_ON_REGISTER_FILTER_REQUEST_FAILURE = "SIGN_ON_REGISTER_FILTER_REQUEST_FAILURE",
SIGN_ON_REGISTER_FILTER_REQUEST_SUCCESS = "SIGN_ON_REGISTER_FILTER_REQUEST_SUCCESS",

SIGN_ON_BREAK_CLEAR = "SIGN_ON_BREAK_CLEAR",
SIGN_ON_BREAK_SET = "SIGN_ON_BREAK_SET",
SIGN_ON_BREAK_REQUEST = "SIGN_ON_BREAK_REQUEST",
SIGN_ON_BREAK_REQUEST_SUCCESS = "SIGN_ON_BREAK_REQUEST_SUCCESS",
SIGN_ON_BREAK_REQUEST_FAILURE = "SIGN_ON_BREAK_REQUEST_FAILURE",
SIGN_ON_BREAK_UPSERT_REQUEST = "SIGN_ON_BREAK_UPSERT_REQUEST",
SIGN_ON_BREAK_UPSERT_REQUEST_SUCCESS = "SIGN_ON_BREAK_UPSERT_REQUEST_SUCCESS",
SIGN_ON_BREAK_UPSERT_REQUEST_FAILURE = "SIGN_ON_BREAK_UPSERT_REQUEST_FAILURE";

export function signOnReset() {
    return {
        type: SIGN_ON_RESET
    };
}

export function signOnRequest() {
    return {
        type: SIGN_ON_REQUEST
    };
}

export function signOnRequestSuccess(userSignOnStart) {
    return {
        type: SIGN_ON_REQUEST_SUCCESS,
        userSignOnStart
    };
}

export function signOnRequestFailure(error) {
    return {
        type: SIGN_ON_REQUEST_FAILURE,
        error
    };
}

export function signOnCheckUserRequest(venueId, userRoleId) {
    return {
        type: SIGN_ON_CHECK_USER_REQUEST,
        venueId,
        userRoleId
    };
}

export function signOnCheckUserResult(isSignedOnToADifferentVenue, canSignOnToMultipleVenues) {
    return {
        type: SIGN_ON_CHECK_USER_RESULT,
        isSignedOnToADifferentVenue,
        canSignOnToMultipleVenues
    };
}

export function signOnSaveRequest(userSignOnStatus) {
    return {
        type: SIGN_ON_SAVE_REQUEST,
        userSignOnStatus
    };
}

export function signOnSaveRequestSuccess() {
    return {
        type: SIGN_ON_SAVE_REQUEST_SUCCESS
    };
}

export function signOnSaveRequestFailure(error) {
    return {
        type: SIGN_ON_SAVE_REQUEST_FAILURE,
        error
    };
}

export function signOffRequest(userSignOnStatusId) {
    return {
        type: SIGN_OFF_REQUEST,
        userSignOnStatusId
    };
}

export function signOffRequestSuccess(userSignOnStatus, signOffDetails) {
    return {
        type: SIGN_OFF_REQUEST_SUCCESS,
        userSignOnStatus,
        signOffDetails
    };
}

export function signOffRequestFailure(error) {
    return {
        type: SIGN_OFF_REQUEST_FAILURE,
        error
    };
}

export function signOffSaveRequest(userSignOnStatus, isAcknowledged) {
    return {
        type: SIGN_OFF_SAVE_REQUEST,
        userSignOnStatus,
        isAcknowledged
    };
}

export function signOffSaveRequestSuccess() {
    return {
        type: SIGN_OFF_SAVE_REQUEST_SUCCESS
    };
}

export function signOffSaveRequestFailure(error) {
    return {
        type: SIGN_OFF_SAVE_REQUEST_FAILURE,
        error
    };
}

export function signOnExtendRequest(userSignOnStatusId) {
    return {
        type: SIGN_ON_EXTEND_REQUEST,
        userSignOnStatusId
    };
}

export function signOnExtendRequestSuccess(userSignOnStatus) {
    return {
        type: SIGN_ON_EXTEND_REQUEST_SUCCESS,
        userSignOnStatus
    };
}

export function signOnExtendRequestFailure(error) {
    return {
        type: SIGN_ON_EXTEND_REQUEST_FAILURE,
        error
    };
}

export function signOnExtendSaveRequest(userSignOnStatus) {
    return {
        type: SIGN_ON_EXTEND_SAVE_REQUEST,
        userSignOnStatus
    };
}

export function signOnExtendSaveRequestSuccess() {
    return {
        type: SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS
    };
}

export function signOnExtendSaveRequestFailure(error) {
    return {
        type: SIGN_ON_EXTEND_SAVE_REQUEST_FAILURE,
        error
    };
}

export function signOnRegisterRequest(filter){
    return {
        type: SIGN_ON_REGISTER_REQUEST,
        filter
    };
}

export function signOnRegisterRequestSuccess(items, paging, filter) {
    return {
        type: SIGN_ON_REGISTER_REQUEST_SUCCESS,
        items,
        paging,
        filter
    };
}

export function signOnRegisterRequestFailure(error) {
    return {
        type: SIGN_ON_REGISTER_REQUEST_FAILURE,
        error
    };
}

export function signOnRegisterFilterRequest(reset) {
    return {
        type: SIGN_ON_REGISTER_FILTER_REQUEST,
        reset
    };
}

export function signOnRegisterFilterSuccess(signOnRegisterFilter) {
    return {
        type: SIGN_ON_REGISTER_FILTER_REQUEST_SUCCESS,
        signOnRegisterFilter
    };
}

export function signOnRegisterFilterFailure(error) {
    return {
        type: SIGN_ON_REGISTER_FILTER_REQUEST_FAILURE,
        error
    };
}

export const signOnBreakClear = makeActionCreator(SIGN_ON_BREAK_CLEAR);
export const signOnBreakSet = makeActionCreator(SIGN_ON_BREAK_SET, 'signOnRegisterBreak');
export const signOnBreakRequest = makeActionCreator(SIGN_ON_BREAK_REQUEST, 'signOnRegisterId');
export const signOnBreakRequestSuccess = makeActionCreator(SIGN_ON_BREAK_REQUEST_SUCCESS, 'signOnRegisterBreak');
export const signOnBreakRequestFailure = makeActionCreator(SIGN_ON_BREAK_REQUEST_FAILURE, 'error');
export const signOnBreakUpsertRequest = makeActionCreator(SIGN_ON_BREAK_UPSERT_REQUEST, 'signOnRegisterBreak');
export const signOnBreakUpsertRequestSuccess = makeActionCreator(SIGN_ON_BREAK_UPSERT_REQUEST_SUCCESS, 'userSignOnStatus');
export const signOnBreakUpsertRequestFailure = makeActionCreator(SIGN_ON_BREAK_UPSERT_REQUEST_FAILURE, 'error');