import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const adminDashboardServiceApi = {
  newFilter: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboardFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, filter, excludeVenues, excludeIncidents) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboard();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    query.excludeVenues = excludeVenues;
    query.excludeIncidents = excludeIncidents;
    const request = client.post(query);
    return from(request);
  },
  totals: (venueId, securityFirmId, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboardTotals();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  overview: (venueId, securityFirmId, mode ) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboardOverview();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.mode = mode;
    const request = client.post(query);
    return from(request);
  },
  usertotals: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboardUserTotals();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  incidentTotals: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminDashboardIncidentTotals();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  }
};
