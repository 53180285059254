
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const incidentCategoryTypeLogic = {

    getCommands(incidentCategoryType: AusComplyDtos.IncidentCategoryType, canEditNotifications?: boolean) {
        var commands: KeyName[] = [];
        if (canEditNotifications || incidentCategoryType.editAllowed ) {
            commands.push({ key: "edit", name: "Edit" });
        }
        if (incidentCategoryType.deleteAllowed) {
            commands.push({ key: "delete", name: "Remove" });
        }
        if (incidentCategoryType.unDeleteAllowed) {
            commands.push({ key: "undelete", name: "Re-enable" });
        }
        if (incidentCategoryType.hideAllowed) {
            commands.push({ key: "hide", name: "Hide" });
        }
        if (incidentCategoryType.unHideAllowed) {
            commands.push({ key: "unhide", name: "Un-Hide" });
        }
        return commands;
    }
}