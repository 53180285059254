import { connect } from 'react-redux'
import { withRouter } from "react-router";
import PlayTraxEventMessageAudits from '../components/externalEvents/playTrax/PlayTraxEventMessageAudits'
import * as externalEventActions from '../common/actions/externalEvent';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.externalEvents.externalEventMessageAudits.paging,
        filter: state.externalEvents.externalEventMessageAudits.filter,
        isLoading: state.externalEvents.externalEventMessageAudits.isLoading,
        isSaving: state.externalEvents.externalEventMessageAudits.isSaving,
        externalEventMessageAudits: state.externalEvents.externalEventMessageAudits.externalEventMessageAudits
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(externalEventActions.externalEventMessageAuditsRequest(page, pageSize, filter))
        },
        onReProcess: (externalEventMessageAudit) => {
            dispatch(externalEventActions.externalEventMessageAuditReProcessRequest(externalEventMessageAudit))
        }
    }
}

const VenuePlayTraxEventMessageAuditsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayTraxEventMessageAudits))

export default VenuePlayTraxEventMessageAuditsContainer