import React, { Component } from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ISelectControlProps {
    theme: any;
    classes: any;
    error?: string;
    defaultValue?: any;
    readonly?: boolean;
    onChange?: Function;
    items?: any;
}
class SelectControl extends React.PureComponent<ISelectControlProps, any> {

    constructor(props: ISelectControlProps) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = name => event => {
        if (event != null) {
            if (this.props.onChange) {
                this.props.onChange(event.target.value);
            }
        }
    }


    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        return (
            <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
                <NativeSelect
                    value={this.props.defaultValue}
                    onChange={this.handleChange('name')}
                    name="name"
                    classes={{ icon: classes.backgroundColorDefault }}
                    input={<InputBase style={style}
                    />}
                >
                    {this.props.items}
                    {this.props.children}
                </NativeSelect>
                {this.props.error && (
                    <FormHelperText>{this.props.error}</FormHelperText>
                )}
            </FormControl>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SelectControl);