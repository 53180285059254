import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IVenuesHeaderProps {
    classes: any;
    theme: any;
}

class VenuesHeader extends React.Component<IVenuesHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Address</th>
                <th className={classes.tableHr}>Suburb</th>
                <th className={classes.tableHr}>State</th>
                <th className={classes.tableHr}>Phone</th>
                <th className={classes.tableHr}>Licencee(s)/Owner(s)</th>
                <th className={classes.tableHr}>Active</th>
                <th className={classes.tableHr}>Industry Category</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenuesHeader);