import { makeActionCreator } from './helpers';

export const
    DASHBOARD_RESET = "DASHBOARD_RESET",
    DASHBOARD_FILTER_REQUEST = "DASHBOARD_FILTER_REQUEST",
    DASHBOARD_FILTER_REQUEST_FAILURE = "DASHBOARD_FILTER_REQUEST_FAILURE",
    DASHBOARD_FILTER_REQUEST_SUCCESS = "DASHBOARD_FILTER_REQUEST_SUCCESS",
    DASHBOARD_SET_FILTER = "DASHBOARD_SET_FILTER",
    DASHBOARD_REQUEST = "DASHBOARD_REQUEST",
    DASHBOARD_REQUEST_FAILURE = "DASHBOARD_REQUEST_FAILURE",
    DASHBOARD_REQUEST_SUCCESS = "DASHBOARD_REQUEST_SUCCESS",
    DASHBOARD_EXPORT_REQUEST = "DASHBOARD_EXPORT_REQUEST",
    DASHBOARD_EXPORT_REQUEST_SUCCESS = "DASHBOARD_EXPORT_REQUEST_SUCCESS",
    DASHBOARD_EXPORT_REQUEST_FAILURE = "DASHBOARD_EXPORT_REQUEST_FAILURE",
    DASHBOARD_REQUEST_INCIDENT_TYPE_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_TYPE_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_CATEGORIES_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_CATEGORIES_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_LOCATIONS_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_LOCATIONS_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_REFUSE_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_REFUSE_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_ASKED_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_ASKED_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_WATCHLISTS_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_WATCHLISTS_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_DEMOGRAPHICS_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_DEMOGRAPHICS_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_GENDER_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_GENDER_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_RACE_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_RACE_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_ENTERED_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_ENTERED_SUCCESS",
    DASHBOARD_REQUEST_INCIDENT_APPROVED_SUCCESS = "DASHBOARD_REQUEST_INCIDENT_APPROVED_SUCCESS",
    DASHBOARD_REQUEST_CHECKLIST_SUCCESS = "DASHBOARD_REQUEST_CHECKLIST_SUCCESS",
    DASHBOARD_REQUEST_RGO_INCIDENT_TYPE_SUCCESS = "DASHBOARD_REQUEST_RGO_INCIDENT_TYPE_SUCCESS",
    DASHBOARD_REQUEST_RGO_INCIDENT_CATEGORIES_SUCCESS = "DASHBOARD_REQUEST_RGO_INCIDENT_CATEGORIES_SUCCESS",
    DASHBOARD_REQUEST_RGO_INCIDENT_LOCATIONS_SUCCESS = "DASHBOARD_REQUEST_RGO_INCIDENT_LOCATIONS_SUCCESS"
    ;

export const dashboardRequestIncidentTypeSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_TYPE_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentCategoriesSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_CATEGORIES_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentLocationsSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_LOCATIONS_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentRefusedSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_REFUSE_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentAskedSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_ASKED_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentWatchlistSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_WATCHLISTS_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentDemographicsSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_DEMOGRAPHICS_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentGenderSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_GENDER_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentRaceSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_RACE_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentEnteredSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_ENTERED_SUCCESS, 'filter', 'data');
export const dashboardRequestIncidentApprovedSuccess = makeActionCreator(DASHBOARD_REQUEST_INCIDENT_APPROVED_SUCCESS, 'filter', 'data');
export const dashboardRequestChecklistSuccess = makeActionCreator(DASHBOARD_REQUEST_CHECKLIST_SUCCESS, 'filter', 'data');
export const dashboardRequestRGOIncidentTypeSuccess = makeActionCreator(DASHBOARD_REQUEST_RGO_INCIDENT_TYPE_SUCCESS, 'filter', 'data');
export const dashboardRequestRGOIncidentCategoriesSuccess = makeActionCreator(DASHBOARD_REQUEST_RGO_INCIDENT_CATEGORIES_SUCCESS, 'filter', 'data');
export const dashboardRequestRGOIncidentLocationsSuccess = makeActionCreator(DASHBOARD_REQUEST_RGO_INCIDENT_LOCATIONS_SUCCESS, 'filter', 'data');


export function dashboardExportRequest() {
    return {
        type: DASHBOARD_EXPORT_REQUEST
    };
}

export function dashboardExportRequestSuccess() {
    return {
        type: DASHBOARD_EXPORT_REQUEST_SUCCESS
    };
}

export function dashboardExportRequestFailure(error) {
    return {
        type: DASHBOARD_EXPORT_REQUEST_FAILURE
    };
}

export function dashboardReset() {
    return {
        type: DASHBOARD_RESET
    };
}

export function dashboardSetFilter(filter) {
    return {
        type: DASHBOARD_SET_FILTER,
        filter
    }
}

export function dashboardFilterRequest(forVenueId, forSecurityFirmId) {
    return {
        type: DASHBOARD_FILTER_REQUEST,
        forVenueId,
        forSecurityFirmId
    };
}

export function dashboardFilterRequestSuccess(filter) {
    return {
        type: DASHBOARD_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function dashboardFilterRequestFailure(error) {
    return {
        type: DASHBOARD_FILTER_REQUEST_FAILURE,
        error
    };
}

export function dashboardRequest() {
    return {
        type: DASHBOARD_REQUEST
    };
}

export function dashboardRequestSuccess(filter, data) {
    return {
        type: DASHBOARD_REQUEST_SUCCESS,
        filter,
        data
    };
}

export function dashboardRequestFailure(error) {
    return {
        type: DASHBOARD_REQUEST_FAILURE,
        error
    };
}
