import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { noteServiceApi } from '../services/noteService';

import * as noteActions from '../actions/notes';
import {
    notifyError
} from './common';

const notesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            noteServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => noteActions.notesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "notesRequestEpic.find", noteActions.notesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "notesRequestEpic", noteActions.notesRequestFailure))
    );


const notesRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            noteServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.notes.paging.page,
                state.notes.paging.pageSize,
                state.notes.filter)
                .pipe(
                    map(response => noteActions.notesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "notesRefreshEpic.find", noteActions.notesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "notesRefreshEpic", noteActions.notesRequestFailure))
    );

const noteSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTE_SAVE_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            noteServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.notes.newNote)
                .pipe(
                    map(response => noteActions.noteSaveNewRequestSuccess(response.note)),
                    catchError(error => notifyError(error, "noteUpsertEpic.upsert", noteActions.noteSaveNewRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "noteUpsertEpic", noteActions.noteSaveNewRequestFailure))
    );

const noteSaveExistingEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTE_SAVE_EXISTING_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            noteServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.notes.note)
                .pipe(
                    map(response => noteActions.noteSaveExistingRequestSuccess(response.note)),
                    catchError(error => notifyError(error, "noteUpsertEpic.upsert", noteActions.noteSaveExistingRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "noteUpsertEpic", noteActions.noteSaveExistingRequestFailure))
    );

const noteSaveNewSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTE_SAVE_NEW_REQUEST_SUCCESS),
        map((action: any) => noteActions.notesRefresh())
    );

const noteSaveExistingSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(noteActions.NOTE_SAVE_EXISTING_REQUEST_SUCCESS),
        map((action: any) => noteActions.notesRefresh())
    );

export const noteEpics = combineEpics(
    notesRefreshEpic,
    notesRequestEpic,
    noteSaveNewEpic,
    noteSaveExistingEpic,
    noteSaveNewSuccessRefreshEpic,
    noteSaveExistingSuccessRefreshEpic
);