import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUserRoleHeaderProps {
    hasGroups?: boolean;
    classes: any;
}

class UserRoleHeader extends React.Component<IUserRoleHeaderProps, any> {
    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                {this.props.hasGroups && (
                    <th className={classes.tableHr}>Group</th>
                )}
                <th className={classes.tableHr}>Venue</th>
                <th className={classes.tableHr}>Security Firm</th>
                <th className={classes.tableHr}>Type</th>
                <th className={classes.tableHr}>Access level</th>
                <th className={classes.tableHr}>Status</th>
                <th className={classes.tableHrCentered}>Temporary</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleHeader);