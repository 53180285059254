
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionFeedServiceApi } from '../services/facialRecognitionFeedService';

import {
    FACIAL_RECOGNITION_FEED_REQUEST,
    facialRecognitionFeedRequestSuccess,
    facialRecognitionFeedRequestFailure
} from "../actions/facialRecognitionFeed";

import {
    notifyError, notifyErrorMessage, notifyErrorNoTrack
} from './common'; 
import { notificationSuccessShow, notificationShow } from "../actions/notification";

const facialRecognitionFeedRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_FEED_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        facialRecognitionFeedServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId, 
                action['forVenueId'], 
                action['venueLocationId'],
                action['limit'])
                .pipe(
                    map(response => facialRecognitionFeedRequestSuccess(response.data)),
                    catchError(error => notifyErrorNoTrack(error, "facialRecognitionFeedRequestEpic.get", facialRecognitionFeedRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionFeedRequestEpic", facialRecognitionFeedRequestFailure))
    );


export const facialRecognitionFeedEpics = combineEpics(
    facialRecognitionFeedRequestEpic
);