import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import { KeyName } from '../../../common/dto/common';
import { correctionalFacilityRiskStatusLogic } from '../../../common/logic/correctionalFacilityRiskStatusLogic';
import CardRow from '../../common/CardRow';
import CardTitle from '../../common/CardTitle';
import CardTypography from '../../common/CardTypography';

export interface ICorrectionalFacilityRiskStatusCardProps {
    riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus;
    onCommand?: Function;
}

export default class CorrectionalFacilityRiskStatusCard extends React.Component<ICorrectionalFacilityRiskStatusCardProps, any> {
  constructor(props: ICorrectionalFacilityRiskStatusCardProps) {
    super(props);

    this.onCommand = this.onCommand.bind(this);
  }

  onCommand(command) {
    if(this.props.onCommand) {
        this.props.onCommand(command, this.props.riskStatus);
    }
  }

  render() {
    if(!this.props.riskStatus) {
        return (<></>);
    }

    var commands = correctionalFacilityRiskStatusLogic.getCommands(this.props.riskStatus);

    var item = this.props.riskStatus;

    return (
        <CardRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
            <Grid container spacing={1}>
                <Grid item sm={2} xs={2}>
                    <CardTitle>Risk Status</CardTitle>
                    <CardTypography>{item.name}</CardTypography>
                </Grid>
                <Grid item sm={2} xs={2}>
                    <CardTitle>Include Cell Status</CardTitle>
                    <CardTypography>In Cell</CardTypography>
                </Grid>
                <Grid item sm={2} xs={2}>
                    <CardTitle>Exclude Cell Status</CardTitle>
                    <CardTypography></CardTypography>
                </Grid>
                <Grid item sm={3} xs={3}>
                    <CardTitle>Default Inspection Frequency</CardTitle>
                    <CardTypography>{item.inspectionFrequency} minutes</CardTypography>
                </Grid>
                <Grid item sm={2} xs={2}>
                    <CardTitle>Rank</CardTitle>
                    <CardTypography>{item.rank}</CardTypography>
                </Grid>
            </Grid>
        </CardRow>
    );
  }
}
