import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IParagraphProps {
    theme: any;
    text: string;
}

class Paragraph extends React.PureComponent<IParagraphProps, any> {
    constructor(props: IParagraphProps){
        super(props)
    }
    render() {
        const { theme } = this.props;
        return (
            <p
                style={{...theme.custom.paragraph}}
                >{this.props.text}</p>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Paragraph);