import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextControl from '../controls/TextControl';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import PrimaryButton from '../controls/PrimaryButton';
import SecondaryButton from '../controls/SecondaryButton';
import DefaultButton from '../controls/DefaultButton';

export interface IMobileVerifyCodeProps {
    showSmsCodeVerification: boolean;
    onSave: Function
    onResend: Function
}



export default class MobileVerifyCode extends Component<IMobileVerifyCodeProps, any> {
    constructor(props: IMobileVerifyCodeProps) {
        super(props)
        this.onSave = this.onSave.bind(this);
        this.state = {
            code: ""
        }
    }

    onSave() {
        if (this.props.onSave) {
            this.props.onSave(this.state.code);
        }
    }

    render() {
        if (!this.props.showSmsCodeVerification) {
            return (<></>);
        }
        return (
            <CardContainer title={"Mobile Verification Code"}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextControl
                                value={this.state.code}
                                placeholder="6 digit verification code..."
                                onSubmit={this.onSave}
                                onChange={(value) => this.setState({ code: value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <PrimaryButton onClick={this.onSave} >Verify</PrimaryButton>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <DefaultButton onClick={() => this.props.onResend()}>Resend</DefaultButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardContainer>
        );
    }
}
