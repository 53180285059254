import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const systemLogApi = {
    get: (venueId: number, securityFirmId: number) => {
        var client = new AusComplyJsonServiceClient();
        var query = new AusComplyDtos.GetSystemLog();
        query.venueId = venueId;
        query.securityFirmId = securityFirmId;
        const request = client.get(query);
        return from(request);
    }
};
