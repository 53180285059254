import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITextDisplayMultiLineProps {
    lines: string[];
    theme: any;
    classes: any;
    style?: any;
}

class TextDisplayMultiLine extends React.PureComponent<ITextDisplayMultiLineProps, any> {
    render() {
        const { theme, classes } = this.props;
        return (
            <pre style={{...theme.custom.textDisplay, ...this.props.style}}>
                {(this.props.lines && this.props.lines.length > 0) &&
                    this.props.lines.map((display, displayIndex) => <p key={"line-" + displayIndex.toString()} style={{padding: 0, margin: 0}}>
                    {display}
                    </p>
                )}
            </pre>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextDisplayMultiLine);