import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueLocationServiceApi } from '../services/venueLocation';

import {
    VENUE_LOCATIONS_REQUEST,
    venueLocationsRequest,
    venueLocationsRequestSuccess,
    venueLocationsRequestFailure,
    VENUE_LOCATION_REQUEST,
    venueLocationRequestSuccess,
    venueLocationRequestFailure,
    VENUE_LOCATION_CREATE_REQUEST,
    venueLocationCreateRequestSuccess,
    venueLocationCreateRequestFailure,
    VENUE_LOCATION_UPSERT_REQUEST,
    venueLocationUpsertRequestSuccess,
    venueLocationUpsertRequestFailure,
    VENUE_LOCATION_UPSERT_REQUEST_SUCCESS,
    venueLocationReset,
    VENUE_LOCATIONS_REFRESH,
    venueLocationsRefresh
} from '../actions/venue';
import {
    notifyError
} from './common';

const venueLocationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => venueLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueLocationsRequestEpic.find", venueLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueLocationsRequestEpic", venueLocationsRequestFailure))
    );

const venueLocationsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueLocations.paging.page,
                state.venue.venueLocations.paging.pageSize,
                state.venue.venueLocations.filter)
                .pipe(
                    map(response => venueLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueLocationsRefreshEpic.find", venueLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueLocationsRefreshEpic", venueLocationsRequestFailure))
    );

const venueLocationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueLocationId'])
                .pipe(
                    map(response => venueLocationRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "venueLocationRequestEpic.get", venueLocationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueLocationRequestEpic", venueLocationRequestFailure))
    );

const venueLocationCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueLocationCreateRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "venueLocationCreateEpic.create", venueLocationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueLocationCreateEpic", venueLocationCreateRequestFailure))
    );

const venueLocationUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATION_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueLocation.venueLocation)
                .pipe(
                    map(response => venueLocationUpsertRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "venueLocationUpsertEpic.upsert", venueLocationUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueLocationUpsertEpic", venueLocationUpsertRequestFailure))
    );

const venueLocationUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueLocationReset())
    );

const venueLocationUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_LOCATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueLocationsRefresh())
    );

export const venueLocationEpics = combineEpics(
    venueLocationsRefreshEpic,
    venueLocationsRequestEpic,
    venueLocationRequestEpic,
    venueLocationCreateEpic,
    venueLocationUpsertEpic,
    venueLocationUpsertSuccessEpic,
    venueLocationUpsertSuccessRefreshEpic
);