import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { 
    correctionalFacilityInMatesCellsRequest,
    correctionalFacilityInMateCellRemoveRequest,
    correctionalFacilityInMateCellUpdateSet,
    correctionalFacilityInMateCellInspectRequest
} from '../common/actions/correctionalFacility';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import CorrectionalFacilityInmatesCells from '../components/facility/correctionalFacilityInmatesCells/CorrectionalFacilityInmatesCells';

const mapStateToProps = state => {
    return {
        isLoading: state.correctionalFacility.inmatesCells.isLoading,
        isSaving: state.correctionalFacility.inmatesCells.isSaving,
        correctionalFacilityInMatesCells: state.correctionalFacility.inmatesCells.correctionalFacilityInMateCells,
        draftIncidentId: state.correctionalFacility.inmatesCells.draftIncidentId,
        canAdd: permissionsLogic.hasPermissionForState(state, "CorrectionalFacilityInmateEdit"),
        canEdit: permissionsLogic.hasPermissionForState(state, "CorrectionalFacilityInmateEdit")
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(correctionalFacilityInMatesCellsRequest())
        },
        onRemove: (correctionalFacilityInMateVenueLocationId) => {
            dispatch(correctionalFacilityInMateCellRemoveRequest(correctionalFacilityInMateVenueLocationId))
        },
        onEdit: (correctionalFacilityInMate) => {
            dispatch(correctionalFacilityInMateCellUpdateSet(correctionalFacilityInMate))
        },
        onInspect: (correctionalFacilityInMateVenueLocationId) => {
            dispatch(correctionalFacilityInMateCellInspectRequest(correctionalFacilityInMateVenueLocationId))
        }
    }
}
const CorrectionalFacilityInmatesCellsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CorrectionalFacilityInmatesCells))
export default CorrectionalFacilityInmatesCellsContainer