import { connect } from 'react-redux'
import { withRouter } from "react-router";
import IncidentActionTemplates from '../components/incidentActions/incidentActionTemplates/IncidentActionTemplates'
import * as incidentActionTemplateActions from '../common/actions/incidentActionTemplates';

const mapStateToProps = (state, props) => {
    return {
        venueId: undefined,
        groupId: undefined,
        filter: state.incidentActionTemplates.incidentActionTemplates.filter,
        isLoading: state.incidentActionTemplates.incidentActionTemplates.isLoading,
        isSaving: state.incidentActionTemplates.incidentActionTemplate.isSaving,
        incidentActionTemplates: state.incidentActionTemplates.incidentActionTemplates.incidentActionTemplates,
        canEdit: true,
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        isLoadingItem: state.incidentActionTemplates.incidentActionTemplate.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplatesSetFilter(filter))
        },
        onSet: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateSet(incidentActionTemplate))
        },
        onUpdate: () => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateUpsertRequest())
        },
        onReset: () => {
            dispatch(incidentActionTemplateActions.incidentActionTemplatesReset())
        },
        onLoadItem: (checklistTemplateId) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateRequest(checklistTemplateId))
        },
        onClone: (incidentActionTemplateId, forGroupId, forVenueId) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateCloneRequest(incidentActionTemplateId, forGroupId, forVenueId))
        }
    }
}

const AdminIncidentActionTemplatesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentActionTemplates))

export default AdminIncidentActionTemplatesContainer