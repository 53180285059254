import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import DefaultButton from '../../controls/DefaultButton';
import AdminVenueAssociationsContainer from '../../../containers/AdminVenueAssociationsContainer';
import AdminVenueEditContainer from '../../../containers/AdminVenueEditContainer';
import AdminVenueSubscriptionContainer from '../../../containers/AdminVenueSubscriptionContainer';
import AdminVenueFacialRecognitionContainer from '../../../containers/AdminVenueFacialRecognitionContainer';
import AdminFacialRecognitionServersContainer from '../../../containers/AdminFacialRecognitionServersContainer';
import AdminFacialRecognitionCamerasContainer from '../../../containers/AdminFacialRecognitionCamerasContainer';
import AdminFacialRecognitionAutomaticEnrolmentsContainer from '../../../containers/AdminFacialRecognitionAutomaticEnrolmentsContainer';
import FacialRecognitionWatchlistsContainer from '../../../containers/FacialRecognitionWatchlistsContainer';
import FacialRecognitionEventsContainer from '../../../containers/FacialRecognitionEventsContainer';
import AdminVenueUtilitiesContainer from '../../../containers/AdminVenueUtilitiesContainer';
import VenueTabs from './VenueTabs';
import FacialRecognitionTabs from './facialRecognition/FacialRecognitionTabs';
import Tabs from '../../common/Tabs';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';

export interface IVenueProps {
    venueId: number;
    history: any;
    location: any;
}

interface IVenueState {
}

export default class Venue extends Component<IVenueProps, IVenueState> {
    constructor(props: IVenueProps) {
        super(props)
        this.onCancel = this.onCancel.bind(this);
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

    }

    onCancel() {
        this.props.history.push("/admin/venues");
    }

    render() {
        let location = this.props.location;
        let path = location.pathname.split("/");
        let selectedTab = "Details";
        let selectedSubTab = ""
        if (path.length > 4) {
            switch (path[4].toString().toLowerCase()) {
                case "subscription":
                    selectedTab = "Subscription";
                    break;
                case "securityfirms":
                    selectedTab = "Security Firms";
                    break;
                case "facialrecognition":
                    selectedTab = "Facial Recognition";
                    switch (path[5].toString().toLowerCase()) {
                        case "servers":
                            selectedSubTab = "Servers";
                            break;
                        case "cameras":
                            selectedSubTab = "Cameras";
                            break;
                        case "watchlists":
                            selectedSubTab = "Watchlists";
                            break;
                        case "events":
                            selectedSubTab = "Events";
                            break;
                        case "enrol":
                            selectedSubTab = "Enrol";
                            break;
                    }
                    break;
                case "utilities":
                    selectedTab = "Utilities";
                    break;
            }
        }
        
        return (
            <>
                {selectedTab === "Details" && (
                    <>
                        <AdminVenueEditContainer venueId={this.props.venueId} />
                    </>
                )}
                {selectedTab === "Subscription" && (
                    <>
                        <AdminVenueSubscriptionContainer venueId={this.props.venueId} />
                    </>
                )}
                {selectedTab === "Security Firms" && (
                    <>
                        <AdminVenueAssociationsContainer venueId={this.props.venueId} />
                    </>
                )}
                {selectedTab === "Facial Recognition" && (
                    <>
                        {selectedSubTab === "Servers" && (
                            <AdminFacialRecognitionServersContainer venueId={this.props.venueId} adminMode={true} history={this.props.history} />
                        )}
                        {selectedSubTab === "Cameras" && (
                            <AdminFacialRecognitionCamerasContainer venueId={this.props.venueId} venueMode={false} history={this.props.history} />
                        )}
                        {selectedSubTab === "Watchlists" && (
                            <FacialRecognitionWatchlistsContainer venueId={this.props.venueId} groupId={0} history={this.props.history} adminMode={true} />
                        )}
                        {selectedSubTab === "Events" && (
                            <FacialRecognitionEventsContainer venueId={this.props.venueId} history={this.props.history} adminMode={true} />
                        )}
                        {selectedSubTab === "Enrol" && (
                            <AdminFacialRecognitionAutomaticEnrolmentsContainer venueId={this.props.venueId} history={this.props.history} adminMode={true} />
                        )}
                    </>
                )}
                {selectedTab === "Utilities" && (
                    <>
                        <AdminVenueUtilitiesContainer venueId={this.props.venueId} />
                    </>
                )}
            </>
        );
    }
}