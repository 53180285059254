import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Box from '@material-ui/core/Box';
import SubTitle from '../../common/SubTitle';
import List from '../../common/List';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import VenueReportingPeriodScheduleCard from './VenueReportingPeriodScheduleCard';
import VenueReportingPeriodScheduleHeader from './VenueReportingPeriodScheduleHeader';
import VenueReportingPeriodScheduleRow from './VenueReportingPeriodScheduleRow';
import PageMessage from '../../common/PageMessage';

export interface IVenueReportingPeriodSchedulesProps {
    isLoading: boolean;
    venueId: number;
    venueReportingPeriodSchedules: AusComplyDtos.VenueReportingPeriodSchedule[];
    venueReportingPeriodScheduleId: number;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class VenueReportingPeriodSchedules extends Component<IVenueReportingPeriodSchedulesProps, any> {
    constructor(props: IVenueReportingPeriodSchedulesProps) {
        super(props)

        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
    }


    onCommand(command: string, venueReportingPeriodSchedule: AusComplyDtos.VenueReportingPeriodSchedule) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(venueReportingPeriodSchedule.venueReportingPeriodScheduleId);
                break;
            case "delete":
                var updated = { ...venueReportingPeriodSchedule };
                updated.active = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    render() {
        if (this.props.isLoading) {
            return (
                <Loading />
            );
        }
        let scheduled;
        let scheduledTitle;
        let holidayExceptions;
        let holidayExceptionsTitle;
        let nonRecurring;
        let nonRecurringTitle;

        if (this.props.venueReportingPeriodSchedules) {
            const scheduledItems = this.props.venueReportingPeriodSchedules.filter(f => f.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Recurring);
            const holidayExceptionsItems = this.props.venueReportingPeriodSchedules.filter(f => f.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday);
            const nonRecurringItems = this.props.venueReportingPeriodSchedules.filter(f => f.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff);

            if (scheduledItems.length > 0) {
                scheduledTitle = (<SubTitle text={"Recurring Schedule"} />);
                let scheduledCards = scheduledItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleCard
                            onCommand={this.onCommand}
                            key={"card-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let scheduledRows = scheduledItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleRow
                            onCommand={this.onCommand}
                            key={"row-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let scheduledRowList = (
                    <TableContainer
                        header={<VenueReportingPeriodScheduleHeader scheduleType={AusComplyDtos.ngtVenueReportingPeriodScheduleType.Recurring} />}
                        rows={scheduledRows}
                    />
                );
                scheduled = (
                    <List
                        loading={false}
                        count={scheduledItems.length}
                        cards={scheduledCards}
                        rows={scheduledRowList}
                    />
                );
            }
            if (holidayExceptionsItems.length > 0) {
                holidayExceptionsTitle = (<SubTitle text={"Holiday Schedule"} />);
                let holidayExceptionsCards = holidayExceptionsItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleCard
                            onCommand={this.onCommand}
                            key={"card-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let holidayExceptionsRows = holidayExceptionsItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleRow
                            onCommand={this.onCommand}
                            key={"row-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let holidayExceptionsRowList = (
                    <TableContainer
                        header={<VenueReportingPeriodScheduleHeader scheduleType={AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday} />}
                        rows={holidayExceptionsRows}
                    />
                );
                holidayExceptions = (
                    <List
                        loading={false}
                        count={holidayExceptionsItems.length}
                        cards={holidayExceptionsCards}
                        rows={holidayExceptionsRowList}
                    />
                );
            }
            if (nonRecurringItems.length > 0) {
                nonRecurringTitle = (<SubTitle text={"One time schedule"} />);
                let nonRecurringCards = nonRecurringItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleCard
                            onCommand={this.onCommand}
                            key={"card-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let nonRecurringRows = nonRecurringItems.map((item, index) => {
                    return (
                        <VenueReportingPeriodScheduleRow
                            onCommand={this.onCommand}
                            key={"row-" + item.venueReportingPeriodScheduleId.toString()}
                            venueReportingPeriodSchedule={item}
                        />
                    );
                });
                let nonRecurringRowList = (
                    <TableContainer
                        header={<VenueReportingPeriodScheduleHeader scheduleType={AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff} />}
                        rows={nonRecurringRows}
                    />
                );
                nonRecurring = (
                    <List
                        loading={false}
                        count={nonRecurringItems.length}
                        cards={nonRecurringCards}
                        rows={nonRecurringRowList}
                    />
                );
            }
            if (scheduledItems.length == 0 && holidayExceptionsItems.length == 0 && nonRecurringItems.length == 0) {
                scheduledTitle = (
                    <SubTitle text={"Schedule"} />
                );
                scheduled = (
                    <PageMessage text={"No reporting period schedule has been defined"} >
                    </PageMessage>
                );
            }
        }

        return (<>
            {nonRecurringTitle}
            {nonRecurring}
            {scheduledTitle}
            {scheduled}
            {holidayExceptionsTitle}
            {holidayExceptions}
        </>);
    }
}