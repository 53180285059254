import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import View from '../common/View';
import CardRow from '../common/CardRow';
import Error from '../common/Error';
import { appSource } from '../../utilities/constants';
import CardTypography from '../common/CardTypography';
import { HorizontalSplitOutlined } from '@material-ui/icons';
import SubTitle from '../common/SubTitle';
import CardContainer from '../common/CardContainer';
import { CardContent } from '@material-ui/core';

export interface IPrintQueueEmbeddedProps {
    queue: AusComplyDTOs.ReportGeneration[];
    onRefresh?: Function;
    onDownloaded?: Function;
    onDismissed?: Function;
    onDismissAll?: Function;
    navigation: any;
    theme: any;
    classes: any;
}

export interface IPrintQueueEmbeddedState {
    isProcessing: boolean;
    isComplete: boolean;
    isError: boolean;
}

class PrintQueueEmbedded extends React.PureComponent<IPrintQueueEmbeddedProps, IPrintQueueEmbeddedState> {

    constructor(props: IPrintQueueEmbeddedProps) {
        super(props);
        this.state = {
            isProcessing: false,
            isComplete: false,
            isError: false
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.onDismissed = this.onDismissed.bind(this);
        this.onDismissAll = this.onDismissAll.bind(this);
        this.onDownloaded = this.onDownloaded.bind(this);
        this.onAction = this.onAction.bind(this);
    }

    timer;

    componentDidMount() {
        this.onRefresh();
        this.calculateState();
    }

    componentDidUpdate(prevprops) {
        if (this.props.queue && prevprops.queue) {
            // Only poll if there are items in the queue, requesting a print will put items in the queue, and 
            // returning to the home page will also check the queue
            if (this.props.queue.length === 0 && prevprops.queue.length !== 0) {
                clearInterval(this.timer);
            }
            if (this.props.queue.length !== 0 && prevprops.queue.length === 0) {
                this.timer = setInterval(() => this.onRefresh(), 3500);
            }
        }
        this.calculateState();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    calculateState() {
        let isProcessing = false;
        let isComplete = false;
        let isError = false;
        if (this.props.queue && this.props.queue.length > 0) {
            isComplete = this.props.queue.filter(item => item.status == 'Ready').length > 0;
            isError = this.props.queue.filter(item => item.status == 'Error' || item.status == 'Cancelled').length > 0;
            isProcessing = this.props.queue.filter(item => item.status == 'Requested' || item.status == 'Generating').length > 0;
        }
        if (this.state.isProcessing != isProcessing ||
            this.state.isComplete != isComplete ||
            this.state.isError != isError) {
            this.setState({ isProcessing, isComplete, isError });
        }
    }

    onRefresh() {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    onAction(item: AusComplyDTOs.ReportGeneration) {
        if (item.status == 'Ready') {
            this.onDownloaded(item);
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            this.onDismissed(item);
        }
    }

    onDownloaded(item: AusComplyDTOs.ReportGeneration) {
        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let filePath = base + '/api/reportgenerated/' + item.reportGenerationId + "?downloadToken=" + item.downloadToken;
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            window.open(response.file);
        }, 100);
        if (this.props.onDownloaded) {
            this.props.onDownloaded(item.reportGenerationId);
        }
    }

    onDismissed(item: AusComplyDTOs.ReportGeneration) {
        if (this.props.onDismissed) {
            this.props.onDismissed(item.reportGenerationId);
        }
    }

    onDismissAll() {
        if (this.props.onDismissAll) {
            this.props.onDismissAll();
        }
    }

    renderCard(item: AusComplyDTOs.ReportGeneration) {
        const { theme } = this.props;
        let isSuccess = false;
        let isCancelled = false;
        let actionText = "";
        if (item.status == 'Ready') {
            isSuccess = true;
            actionText = "download";
        } else if (item.status == 'Error' || item.status == 'Cancelled') {
            isCancelled = true;
            actionText = "dismiss";
        }
        return (
            <CardRow isSuccess={isSuccess}
                isDanger={isCancelled}
                isWarning={!isSuccess && !isCancelled}
                style={{ cursor: 'pointer' }}
                key={"report-generation-" + item.reportGenerationId.toString()}>
                <Box p={0} display="flex" flexDirection={'row'} onClick={() => this.onAction(item)}>
                    <Box flexGrow={1} p={0}>
                        <CardTypography>{item.title}</CardTypography>
                    </Box>
                    <Box flexGrow={0} p={0}>
                        <CardTypography style={{ color: theme.palette.text.secondary }}>{actionText}</CardTypography>
                    </Box>
                </Box>
                {item.error && (
                    <Error message={item.error} />
                )}
            </CardRow>
        );
    }

    render() {
        const { classes } = this.props;
        let queue;
        let legend;

        if (this.props.queue && this.props.queue.length > 0) {
            queue = this.props.queue.map((item, index) => this.renderCard(item));
            legend = (
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistInProgress}></div>
                        <div className={classes.colorSwatchText}>Processing</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistArchived}></div>
                        <div className={classes.colorSwatchText}>Error</div>
                    </Box>
                    <Box p={1} >
                        <div className={classes.colorSwatchChecklistApproved}></div>
                        <div className={classes.colorSwatchText}>Ready</div>
                    </Box>
                </Box>
            );
            return (
                <CardContainer title='Queue' style={{ marginBottom: 20 }}>
                    <View style={{padding: 10, paddingTop: 5, paddingBottom: 5}}>
                        {queue}
                        {legend}
                    </View>
                </CardContainer>
            );
        } else {
            return <></>
        }
    }
}
export default withStyles(styles, { withTheme: true })(PrintQueueEmbedded);