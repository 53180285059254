import update from "immutability-helper";
import {
    LOCATION_CHANGED,
    LOCATION_REQUEST
} from "../actions/location";

const initialState = {
    isLoading: false,
    found: false,
    latitude: 0,
    longitude: 0,
    error: ''
};

export default function location(state = initialState, action) {
    switch (action.type) {
        case LOCATION_REQUEST:
            return update(state, {
                isLoading: {$set: true},
            });
        case LOCATION_CHANGED:
            return update(state, {
                isLoading: {$set: false},
                found: { $set: action.found },
                latitude: { $set: action.latitude },
                longitude: { $set: action.longitude },
                error: { $set: action.error }
            });
    }
    return state;
}
