import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, debounce, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentActionTemplateServiceApi } from '../services/incidentActionTemplate';
import * as incidentActionTemplateActions from "../actions/incidentActionTemplates";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as epicCommon from './common';

const incidentActionTemplatesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentActionTemplates.incidentActionTemplates.filter)
                .pipe(
                    map(response => incidentActionTemplateActions.incidentActionTemplatesRequestSuccess(response.filter, response.incidentActionTemplates)),
                    catchError(error => epicCommon.notifyError(error, "incidentActionTemplatesRequestEpic.find", incidentActionTemplateActions.incidentActionTemplatesRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "incidentActionTemplatesRequestEpic", incidentActionTemplateActions.incidentActionTemplatesRequestFailure))
    );

const incidentActionTemplateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentActionTemplateId'])
                .pipe(
                    map(response => incidentActionTemplateActions.incidentActionTemplateRequestSuccess(response.incidentActionTemplate, response.incidentCategoryTypes)),
                    catchError(error => epicCommon.notifyError(error, "incidentActionTemplateRequestEpic.get", incidentActionTemplateActions.incidentActionTemplateRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "incidentActionTemplateRequestEpic", incidentActionTemplateActions.incidentActionTemplateRequestFailure))
    );

const incidentActionTemplateCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'],
                action['groupId'])
                .pipe(
                    map(response => incidentActionTemplateActions.incidentActionTemplateCreateRequestSuccess(response.incidentActionTemplate, response.incidentCategoryTypes)),
                    catchError(error => epicCommon.notifyError(error, "incidentActionTemplateCreateEpic.create", incidentActionTemplateActions.incidentActionTemplateCreateRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "incidentActionTemplateCreateEpic", incidentActionTemplateActions.incidentActionTemplateCreateRequestFailure))
    );

const incidentActionTemplateUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate)
                .pipe(
                    map(response => incidentActionTemplateActions.incidentActionTemplateUpsertRequestSuccess(response.incidentActionTemplate)),
                    catchError(error => epicCommon.notifyError(error, "incidentActionTemplateUpsertEpic.upsert", incidentActionTemplateActions.incidentActionTemplateUpsertRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "incidentActionTemplateUpsertEpic", incidentActionTemplateActions.incidentActionTemplateUpsertRequestFailure))
    );

const incidentActionTemplateUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => action.incidentActionTemplate.groupId == undefined || action.incidentActionTemplate.groupId == 0),
        map((action: any) => incidentActionTemplateActions.incidentActionTemplatesRequest())
    );

const incidentActionTemplateSetFilter = (action$, state$) =>
    action$.pipe(
        ofType(incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_SET_FILTER),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => incidentActionTemplateActions.incidentActionTemplatesRequest())
    );

const incidentActionTemplateCloneEpic = epicCommon.epicApiPost(
    incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST,
    AusComplyDtos.PostIncidentActionTemplateCopy,
    ['incidentActionTemplateId', 'forGroupId', 'forVenueId'],
    response => incidentActionTemplateActions.incidentActionTemplateCloneRequestSuccess(response.incidentActionTemplate),
    incidentActionTemplateActions.incidentActionTemplateCloneRequestFailure);

export const incidentActionTemplateEpics = combineEpics(
    incidentActionTemplatesRequestEpic,
    incidentActionTemplateRequestEpic,
    incidentActionTemplateCreateEpic,
    incidentActionTemplateUpsertEpic,
    incidentActionTemplateUpsertSuccessRefreshEpic,
    incidentActionTemplateSetFilter,
    incidentActionTemplateCloneEpic
);