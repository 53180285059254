import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueEdit from '../components/admin/venue/VenueEdit'
import {
    venueRequest,
    venueUpdate,
    venueSaveRequest
} from '../common/actions/venue'

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        isLoading: state.venue.isLoading,
        isSaving: state.venue.isSaving,
        venue: state.venue.venue,
        states: state.venue.states,
        industryCategories: state.venue.industryCategories,
        precincts: state.venue.precincts,
        licenceTypes: state.venue.licenceTypes,
        tradingHours: state.venue.tradingHours,
        error: state.venue.error,
        errors: state.venue.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueRequest(venueId, true))
        },
        onUpdate: (venue) => {
            dispatch(venueUpdate(venue))
        },
        onSave: () => {
            dispatch(venueSaveRequest())
        }
    }
}

const AdminVenueEditContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueEdit))

export default AdminVenueEditContainer