import { connect } from 'react-redux'
import SystemLog from '../components/systemLog/SystemLog'
import { withRouter } from "react-router";
import { systemLogRequest } from '../common/actions/systemLog';

const mapStateToProps = state => {
    return {
        isLoading: state.systemLog.isLoading,
        notifications: state.systemLog.notifications
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad:(venueId, securityFirmId) => {
            dispatch(systemLogRequest(venueId, securityFirmId))
        }
    }
}

const AdminSystemLogContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemLog))

export default AdminSystemLogContainer