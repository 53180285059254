import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICardInformationProps {
    theme: any;
    classes: any;
    text?: string;
    style?: any;
}

class CardInformation extends React.PureComponent<ICardInformationProps, any> {
    render() {
        const { classes } = this.props;
        return (
            <p className={classes.cardInformation} style={{ ...this.props.style }}>
                {this.props.text}{this.props.children}
            </p>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardInformation);