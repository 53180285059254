import { connect } from 'react-redux'
import { withRouter } from "react-router";
import IncidentFlagTypeNotification from '../components/venue/incidentFlagType/IncidentFlagTypeNotification'
import { 
    incidentFlagTypeNotificationReset,
    incidentFlagTypeNotificationSet,
    incidentFlagTypeNotificationUpsertRequest
 } from '../common/actions/incidentFlagTypeNotification';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.incidentFlagTypeNotification.incidentFlagTypeNotification.isLoading,
        isSaving: state.incidentFlagTypeNotification.incidentFlagTypeNotification.isSaving,
        incidentFlagTypeNotification: state.incidentFlagTypeNotification.incidentFlagTypeNotification.incidentFlagTypeNotification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(incidentFlagTypeNotificationReset())
        },
        onUpdate: (incidentFlagTypeNotification) => {
            dispatch(incidentFlagTypeNotificationSet(incidentFlagTypeNotification))
        },
        onSave: () => {
            dispatch(incidentFlagTypeNotificationUpsertRequest())
        }
    }
}

const IncidentFlagTypeNotificationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentFlagTypeNotification))

export default IncidentFlagTypeNotificationContainer