import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ResponsiveList from '../common/ResponsiveList';
import PrimaryButton from '../controls/PrimaryButton';
import { KeyName } from '../../common/dto/common';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';


export interface ISchedulesProps {
    schedules: AusComplyDtos.Schedule[];
    isLoading: boolean;
    processingId: number;
    isProcessing: boolean;
    onProcess: Function;
    onLoad: Function;
    showEvents: boolean;
    history: any;
    location: any;
}

export default class Schedules extends Component<ISchedulesProps, any> {
    constructor(props: ISchedulesProps) {
        super(props)
        this.state = {
            cloneFacialRecognitionServerId: 0
        };
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onOpenItem = this.onOpenItem.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    componentDidUpdate(prevProps: Readonly<ISchedulesProps>, prevState: Readonly<any>, snapshot?: any): void {
        if(prevProps.isProcessing && !this.props.isProcessing && !this.props.isLoading)
        {
            this.props.onLoad();
        }
    }

    getCommands(schedule: AusComplyDtos.Schedule) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "copy", name: "Copy" });
        commands.push({ key: "logs", name: "Logs" });
        if (this.props.processingId === 0) {
            commands.push({ key: "trigger", name: "Trigger" });
        }
        return commands;
    }

    onCommand(command: string, schedule: AusComplyDtos.Schedule) {
        switch (command) {
            case "edit":
                var url = this.props.location.pathname + "/" + schedule.scheduleId + '/0';
                this.props.history.push(url);
                break;
            case "copy":
                var url = this.props.location.pathname + "/0/" + schedule.scheduleId;
                this.props.history.push(url);
                break;
            case "trigger":
                this.props.onProcess(schedule.scheduleId);
                break;
            case "logs":
                var url = this.props.location.pathname + "/" + schedule.scheduleId + "/logs";
                this.props.history.push(url);
                break;
            default:
                break;
        }
    }

    onOpenItem(id: number) {
        var url = this.props.location.pathname + "/" + id + "/0";
        this.props.history.push(url);
    }

    render() {
        let self = this;
        return (
            <PageLayout
                headerText={"Scheduled Tasks"}
                loading={this.props.isLoading}
                footerLeftContent={<PrimaryButton text={"Add Schedule"} onClick={() => this.onOpenItem(0)} />}
                footerRightContent={<TransparentButton text="Refresh" onClick={() => this.props.onLoad()} />}
            >
                <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.schedules}
                    headers={["Task", "Entered", "By", "Modified", "By", "Last Ran", "Count"]}
                    minWidths={[0,130,150,130,150,130,0]}
                    columns={["display", "dateEnteredDisplay", "enteredByDisplay", "dateModifiedDisplay", "lastModifiedByDisplay", "lastRanDisplay", "successCount"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isObsolete={item => item.obsolete}
                    isDanger={item => 
                        item.scheduleId !== this.props.processingId && 
                        item.lastScheduleLog !== undefined && 
                        item.lastScheduleLog.errorMessage !== ""}
                    isDangerLabel={"Error"}
                    isSuccess={item => item.scheduleId === this.props.processingId}
                    isSuccessLabel={"Processing"}
                    toggleObsolete={true}
                    canEdit={true}
                    onGetLink={(columnName, rowData) => {
                        if (columnName == "successCount") {
                            return this.props.location.pathname + "/" + rowData["scheduleId"] + "/logs";
                        }
                        return "";
                    }}
                />
            </PageLayout>
        );

    }
}