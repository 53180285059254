import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import LayoutForm from '../../layout/LayoutForm';
import RadioControlGroup from '../../controls/RadioControlGroup';
import MutiSelectControlGroup from '../../controls/MutiSelectControlGroup';
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IRequestUserRoleProps {
    isLoading: boolean;
    isSaving: boolean;
    venues: AusComplyDtos.VenueSelection[],
    securityFirms: AusComplyDtos.SecurityFirmSelection[],
    onRequested: Function;
    onVenuesAvailableToRequest: Function;
    onSecurityFirmsAvailableToRequest: Function;
    onRequestUserRole: Function;
    error: string;
    theme: any;
    classes: any;
}

interface IRequestUserRoleState {
    registerAs: string;
    venues: MulitSelectOption[];
    securityFirms: MulitSelectOption[];
}

export default class RequestUserRole extends Component<IRequestUserRoleProps, IRequestUserRoleState> {
    constructor(props: IRequestUserRoleProps) {
        super(props)
        this.state = {
            registerAs: "VENUE",
            venues: [],
            securityFirms: []
        }
        this.onRegisterAsChanged = this.onRegisterAsChanged.bind(this);
        this.onSelectVenuesChanged = this.onSelectVenuesChanged.bind(this);
        this.onSelectSecurityFirmsChanged = this.onSelectSecurityFirmsChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.onVenuesAvailableToRequest();
        this.props.onSecurityFirmsAvailableToRequest();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            let venues: MulitSelectOption[] = [];
            if (this.props.venues && Array.isArray(this.props.venues)) {
                this.props.venues.forEach(venue => {
                    venues.push({
                        id: venue.venueId,
                        name: venue.name,
                        isCommon: false,
                        selected: false
                    });
                });
            }
            let securityFirms: MulitSelectOption[] = [];
            if (this.props.securityFirms) {
                this.props.securityFirms.forEach(securityFirm => {
                    securityFirms.push({
                        id: securityFirm.securityFirmId,
                        name: securityFirm.name,
                        isCommon: false,
                        selected: false
                    });
                });
            }
            this.setState({ venues, securityFirms });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error) {
            this.props.onRequested();
        }
    }

    onRegisterAsChanged(value) {
        this.setState({ registerAs: value.value });
    }

    onSelectVenuesChanged(items) {
        let venues = [...this.state.venues];
        if (venues) {
            venues.forEach(venue => {
                venue.selected = (items.filter(item => item.id == venue.id).length > 0);
            });
        }
        this.setState({ venues });
    }

    onSelectSecurityFirmsChanged(items) {
        let securityFirms = [...this.state.securityFirms];
        securityFirms.forEach(securityFirm => {
            securityFirm.selected = (items.filter(item => item.id == securityFirm.id).length > 0);
        });
        this.setState({ securityFirms });
    }

    onSubmit() {
        var venueIds = this.state.venues.filter(v => v.selected).map(item => item.id);
        var securityFirmIds = this.state.securityFirms.filter(v => v.selected).map(item => item.id);
        this.props.onRequestUserRole(this.state.registerAs, venueIds, securityFirmIds);
    }

    render() {
        let radioOptions: RadioOption[] = [
            { id: "VENUE", name: "Venue Staff" },
            { id: "SECURITY", name: "Security Staff" },
            { id: "OTHER", name: "Other" }
        ];

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    <RadioControlGroup
                        text={"Register As"}
                        name="registerAs"
                        selected={this.state.registerAs}
                        options={radioOptions}
                        onChanged={this.onRegisterAsChanged} />
                    <CardContainer
                        title={"Request access to"}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <div style={{ marginTop: '-4px' }}>
                                {this.state.registerAs != "SECURITY" && (
                                    <MutiSelectControlGroup
                                        text={""}
                                        label={"Venues"}
                                        items={this.state.venues}
                                        searchText="Type at least three letters..."
                                        minimumCharactersRequired={3}
                                        onSelectItemsChanged={this.onSelectVenuesChanged}
                                        canShowAll={false} />
                                )}
                                {this.state.registerAs === "SECURITY" && (
                                    <MutiSelectControlGroup
                                        text={""}
                                        label={"Security Firms"}
                                        items={this.state.securityFirms}
                                        searchText="Type at least three letters..."
                                        minimumCharactersRequired={3}
                                        onSelectItemsChanged={this.onSelectSecurityFirmsChanged}
                                        canShowAll={false} />
                                )}
                            </div>
                        </CardContent>
                    </CardContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PrimaryButton text={"SUBMIT REQUEST"} onClick={this.onSubmit}></PrimaryButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm>
        );
    }
}