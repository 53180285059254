
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueServiceApi } from '../services/venueService';
import { venueReportingPeriodServiceApi } from '../services/venueReportingPeriod';
import { venueSubscriptionServiceApi } from '../services/venueSubscriptionService';

import {
    USER_LOG_INTO_VENUE_REQUEST,
    USER_LOG_INTO_VENUE_REQUEST_SUCCESS,
    userLogIntoVenueRequestSuccess,
    userLogIntoVenueRequestFailure,
    userDetailsRefresh
} from "../actions/userLogin";
import {
    VENUE_REQUEST,
    venueRequestSuccess,
    venueRequestFailure,
    VENUE_REPORTING_PERIODS_REQUEST,
    venueReportingPeriodsRequestSuccess,
    venueReportingPeriodsRequestFailure,
    VENUE_REPORTING_PERIOD_REQUEST,
    venueReportingPeriodRequestSuccess,
    venueReportingPeriodRequestFailure,
    VENUE_REPORTING_PERIOD_CREATE_REQUEST,
    venueReportingPeriodCreateRequestSuccess,
    venueReportingPeriodCreateRequestFailure,
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST,
    venueReportingPeriodUpsertRequestSuccess,
    venueReportingPeriodUpsertRequestFailure,
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS,
    venueReportingPeriodReset,
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST,
    venueReportingPeriodCurrentRequest,
    venueReportingPeriodCurrentRequestSuccess,
    venueReportingPeriodCurrentRequestFailure,
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST,
    venueReportingPeriodCloseRequestSuccess,
    venueReportingPeriodCloseRequestFailure,
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS,
    VENUE_SAVE_REQUEST,
    venueSaveRequestSuccess,
    venueSaveRequestFailure,

    VENUE_SUBSCRIPTION_SUMMARY_REQUEST,
    venueSubscriptionSummaryRequestSuccess,
    venueSubscriptionSummaryRequestFailure

} from '../actions/venue';
import {
    REGISTER_VENUE_NEW_REQUEST,
    registerVenueNewSuccess,
    registerVenueNewFailure,
    REGISTER_VENUE_SAVE_REQUEST,
    registerVenueSaveRequestSuccess,
    REGISTER_VENUE_SAVE_REQUEST_SUCCESS,
    registerVenueSaveRequestFailure,
    registerVenueReset
} from '../actions/registerVenue';
import {
    notifyError, notifyErrorMessage
} from './common';

const getVenueEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.get(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['venueId'], action['includeOptions'])
                .pipe(
                    map(response => venueRequestSuccess(response.venue,
                        response.includesOptions,
                        response.states,
                        response.industryCategories,
                        response.venuePrecincts,
                        response.venueLicenceTypes,
                        response.venueTradingHours)),
                    catchError(error => notifyError(error, "getVenueEpic.get", venueRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getVenueEpic", venueRequestFailure))
    );

const userLogInToVenueEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_LOG_INTO_VENUE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.postVenueCode(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId, 
                state.user.details.userSession.user.venueEventId, 
                action['code'], state.location.found, state.location.latitude, state.location.longitude)
                .pipe(
                    map(response => userLogIntoVenueRequestSuccess()),
                    catchError(error => notifyError(error, "userLogInToVenueEpic.postVenueCode", userLogIntoVenueRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userLogInToVenueEpic", userLogIntoVenueRequestFailure))
    );

const userLogInToVenueSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_LOG_INTO_VENUE_REQUEST_SUCCESS),
        map(action => userDetailsRefresh())
    );

const venueReportingPeriodsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIODS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'], action['page'], action['pageSize'])
                .pipe(
                    map(response => venueReportingPeriodsRequestSuccess(response.data, response.paging)),
                    catchError(error => notifyError(error, "venueReportingPeriodsRequestEpic.find", venueReportingPeriodsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodsRequestEpic", venueReportingPeriodsRequestFailure))
    );

const venueReportingPeriodRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueReportingPeriodId'])
                .pipe(
                    map(response => venueReportingPeriodRequestSuccess(response.venueReportingPeriod)),
                    catchError(error => notifyError(error, "venueReportingPeriodRequestEpic.get", venueReportingPeriodRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodRequestEpic", venueReportingPeriodRequestFailure))
    );

const venueReportingPeriodCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueReportingPeriodCreateRequestSuccess(response.venueReportingPeriod)),
                    catchError(error => notifyError(error, "venueReportingPeriodCreateEpic.create", venueReportingPeriodCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodCreateEpic", venueReportingPeriodCreateRequestFailure))
    );

const venueReportingPeriodUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.reportingPeriod.reportingPeriod)
                .pipe(
                    map(response => venueReportingPeriodUpsertRequestSuccess(response.venueReportingPeriod)),
                    catchError(error => notifyError(error, "venueReportingPeriodUpsertEpic.upsert", venueReportingPeriodUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodUpsertEpic", venueReportingPeriodUpsertRequestFailure))
    );

const venueReportingPeriodUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueReportingPeriodReset())
    );

const venueReportingPeriodUpsertCurrentSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueReportingPeriodCurrentRequest(action['venueReportingPeriod']['venueId']))
    );

const venueReportingPeriodCurrentEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_CURRENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.current(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueReportingPeriodCurrentRequestSuccess(response.venueReportingPeriod, response.venueEventReportingPeriods)),
                    catchError(error => notifyError(error, "venueReportingPeriodCurrentEpic.current", venueReportingPeriodCurrentRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodCurrentEpic", venueReportingPeriodCurrentRequestFailure))
    );

const venueReportingPeriodCloseEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_CLOSE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.close(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueReportingPeriodId'])
                .pipe(
                    map(response => venueReportingPeriodCloseRequestSuccess(response.venueReportingPeriod)),
                    catchError(error => notifyError(error, "venueReportingPeriodCloseEpic.close", venueReportingPeriodCloseRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodCloseEpic", venueReportingPeriodCloseRequestFailure))
    );

const venueReportingPeriodCloseSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS),
        map((action: any) => venueReportingPeriodCurrentRequest(action['venueReportingPeriod']['venueId']))
    );


const registerVenueNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_VENUE_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.createNew()
                .pipe(
                    map(response => registerVenueNewSuccess(
                        response.venue,
                        response.venueContact,
                        response.licenseContact,
                        response.states,
                        response.industryCategories,
                        response.venuePrecincts,
                        response.venueLicenceTypes,
                        response.venueTradingHours,
                        response.otherDocuments,
                        response.otherDocumentTypes,
                        response.complianceDocuments,
                        response.complianceDocumentTypes)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise a new venue", registerVenueNewFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise a new venue", registerVenueNewFailure))
    );

const registerVenueSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_VENUE_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.saveNew(state.registerVenue.venue,
                state.registerVenue.venueContact,
                state.registerVenue.licenseContact,
                state.registerVenue.otherDocumnets, state.registerVenue.complianceDocuments)
                .pipe(
                    map(response => registerVenueSaveRequestSuccess(response.venue)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise a new venue", registerVenueSaveRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise a new venue", registerVenueSaveRequestFailure))
    );

const registerVenueSaveNewSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_VENUE_SAVE_REQUEST_SUCCESS),
        map(action => registerVenueReset())
    );

const venueSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venue)
                .pipe(
                    map(response => venueSaveRequestSuccess(response.venue)),
                    catchError(error => notifyError(error, "venueSaveNewEpic.save", venueSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSaveNewEpic", venueSaveRequestFailure))
    );

const venueSubscriptionSummaryRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SUBSCRIPTION_SUMMARY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueSubscriptionServiceApi.getSummary(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => venueSubscriptionSummaryRequestSuccess(
                        response.venueSubscriptionSummary)),
                    catchError(error => notifyError(error, "venueSubscriptionSummaryRequestEpic.getSummary", venueSubscriptionSummaryRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSubscriptionSummaryRequestEpic", venueSubscriptionSummaryRequestFailure))
    );


export const venueEpics = combineEpics(
    getVenueEpic,
    userLogInToVenueEpic,
    userLogInToVenueSuccessEpic,
    venueReportingPeriodsRequestEpic,
    venueReportingPeriodRequestEpic,
    venueReportingPeriodCreateEpic,
    venueReportingPeriodUpsertEpic,
    venueReportingPeriodCurrentEpic,
    venueReportingPeriodCloseEpic,
    venueReportingPeriodCloseSuccessEpic,
    venueReportingPeriodUpsertSuccessEpic,
    venueReportingPeriodUpsertCurrentSuccessEpic,
    registerVenueNewEpic,
    registerVenueSaveNewEpic,
    registerVenueSaveNewSuccessEpic,
    venueSaveNewEpic,
    venueSubscriptionSummaryRequestEpic
);