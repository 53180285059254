import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as incidentDocumentActions from "../actions/incidentDocument";

const initialState = {
    incidentId: 0,
    incidentCategoryTypeDocumentId: 0,
    isBuilding: false,
};


function setBuilding(state, isBuilding, incidentId, incidentCategoryTypeDocumentId) {
    let newState = update(state, {
        isBuilding: { $set: isBuilding }
    });
    return newState;
}

export default function incidentDocuments(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        case incidentDocumentActions.INCIDENT_DOCUMENT_BUILD_REQUEST:
            return setBuilding(state, true, action.incidentId, action.incidentCategoryTypeDocumentId);
        case incidentDocumentActions.INCIDENT_DOCUMENT_BUILD_REQUEST_SUCCESS:
            return setBuilding(state, false, 0, 0);
        case incidentDocumentActions.INCIDENT_DOCUMENT_BUILD_REQUEST_FAILURE:
            return setBuilding(state, false, 0, 0);
    }
    return state;
}
