import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import View from './View'

export interface IInlineMediaProps {
    icon: any;
    text: string;
}

export default class InlineMedia extends Component<IInlineMediaProps, any> {

    render() {
        return (
            <View style={{ display: 'inline-block' }}>
                <Box style={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }}>
                    <Box p={0}>
                        <Box style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', paddingRight: '3px' }}>
                            <Box p={0}>
                                {this.props.icon}
                            </Box>
                        </Box>
                    </Box>
                    <Box p={0}>
                        <span>{this.props.text}</span>
                    </Box>
                </Box>
            </View>
        );
    }
}

