import * as AusComplyDtos from "../dto/AusComply.dtos";

// Currently service stack serializes the list permissions differently to the DTOs, so cannot use strong typing
export const permissionsLogic = {
    hasPermissionForState (state, permission: string) { //AusComplyDtos.ngtUserPermission) {
        if (state.user.details.userSession.permissions) {
            return state.user.details.userSession.permissions.indexOf(permission) > -1;
        }
        return false;
    },
    hasAnyPermissionForState(state, permissionsToCheck: string[]) { //: AusComplyDtos.ngtUserPermission[]) {
        let hasPermission: boolean = false;
        if (state.user.details.userSession.permissions){
            permissionsToCheck.forEach(p => {
                if (state.user.details.userSession.permissions.indexOf(p) > -1) {
                    hasPermission = true;
                }
            });
        }
        return hasPermission;
    },
    hasPermission (permissions: string[], permission: string) { //: AusComplyDtos.ngtUserPermission) {
        if (permissions) {
            return permissions.indexOf(permission) > -1;
        }
        return false;
    },
    hasAnyPermission(permissions: string[], permissionsToCheck: string[]) { // AusComplyDtos.ngtUserPermission[]) {
        let hasPermission: boolean = false;
        if (permissions){
            permissionsToCheck.forEach(p => {
                if (permissions.indexOf(p) > -1) {
                    hasPermission = true;
                }
            });
        }
        return hasPermission;
    },
    hasGroupPermissionForState (state, groupId: number, permission: string) { //AusComplyDtos.ngtUserPermission) {
        if (state.user.details.userSession.permissions) {
            // global permission
            if(state.user.details.userSession.permissions.indexOf(permission) > -1) return true;
        }
        if (state.user.details.userSession.user && state.user.details.userSession.user.groups) {
            // global permission
            const group = state.user.details.userSession.user.groups.find(f => f.groupId == groupId);
            if(group && group.permissions.indexOf(permission) > -1) return true;
        }
        return false;
    },
    hasAnyGroupPermissionForState (state, groupId: number, permissionsToCheck: string[]) { //AusComplyDtos.ngtUserPermission) {
        let hasPermission: boolean = false;
        if (state.user.details.userSession.permissions) {
            // global permission
            permissionsToCheck.forEach(p => {
                if (state.user.details.userSession.permissions.indexOf(p) > -1) {
                    hasPermission = true;
                }
            });
        }
        if (!hasPermission && state.user.details.userSession.user && state.user.details.userSession.user.groups && groupId !== undefined) {
            // global permission
            const group = state.user.details.userSession.user.groups.find(f => f.groupId == groupId);
            permissionsToCheck.forEach(p => {
                if (group.permissions.indexOf(p) > -1) {
                    hasPermission = true;
                }
            });
        }
        return hasPermission;
    },
    isVenueUserRoleType(state) {
        return state.user.details.userSession.user.userRoleTypes == 1 || state.user.details.userSession.user.userRoleTypes == 3;
    },
    isSecurityFirmRoleType(state) {
        return state.user.details.userSession.user.userRoleTypes == 2 || state.user.details.userSession.user.userRoleTypes == 3;
    },
    isLevelSix(state) { 
        if (state.user.details.userSession.user) {
            return state.user.details.userSession.user.accessLevel === 6;
        }
        return false;
    },
    isLevelFourVenueRole(state) { 
        if (state.user.details.userSession.user) {
            return state.user.details.userSession.user.accessLevel === 4 && this.isVenueUserRoleType(state);
        }
        return false;
    },
    isLevelFourVenueRoleOrSix(state) {
        return this.isLevelSix(state) || this.isLevelFourVenueRole(state);
    }
};