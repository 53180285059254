import { connect } from 'react-redux'
import SecurityFirm from '../components/admin/securityFirm/SecurityFirm'
import { withRouter } from "react-router";

const mapStateToProps = (state, props) => {
    return {
        securityFirmId: props.match.params.securityFirmId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const AdminSecurityFirmContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirm))

export default AdminSecurityFirmContainer