import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../common/dto/AusComply.dtos";
import IncidentViewDates from './view/IncidentViewDates';
import IncidentViewSnapshot from './view/IncidentViewSnapshot';
import IncidentViewType from './view/IncidentViewType';
import IncidentViewCategories from './view/IncidentViewCategories';
import IncidentViewLocations from './view/IncidentViewLocations';
import IncidentViewPersonnel from './view/IncidentViewPersonnel';
import IncidentViewNarrative from './view/IncidentViewNarrative';
import IncidentViewNumber from './view/IncidentViewNumber';
import IncidentViewRelated from './view/IncidentViewRelated';
import IncidentViewFlags from './view/IncidentViewFlags';
import IncidentViewDocuments from './view/IncidentViewDocuments';
import IncidentViewPeopleAndGroups from './view/IncidentViewPeopleAndGroups';
import IncidentViewFacialRecognition from './view/IncidentViewFacialRecognition';
import IncidentViewChecklists from './view/IncidentViewChecklists';
import IncidentViewRadioChannelFunctionalArea from './view/IncidentViewRadioChannelFunctionalArea';
import Loading from '../common/Loading';

export interface IIncidentDisplayProps {
    incidentId: number;
    isLoading: boolean;
    incident: IncidentDTO;
    onLoadIncident: Function;
    canShowVersions?: boolean;
    classes: any;
    theme: any;
}

class IncidentDisplay extends Component<IIncidentDisplayProps, any> {

    componentDidMount() {
        // eslint-disable-next-line
        if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incidentId != 0) {
            this.props.onLoadIncident(this.props.incidentId);
        }
    }

    componentDidUpdate(preProps) {
        // eslint-disable-next-line
        if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incidentId != 0) {
            this.props.onLoadIncident(this.props.incidentId);
        }
    }

    render() {
        // eslint-disable-next-line
        if (this.props.isLoading || this.props.incident.incidentId != this.props.incidentId) {
            return (
                <Loading />
            );
        }
        
        return (
            <>
                <IncidentViewNumber incident={this.props.incident} />
                <IncidentViewRadioChannelFunctionalArea incident={this.props.incident} />
                <IncidentViewDates incident={this.props.incident} canShowVersions={this.props.canShowVersions} />
                <IncidentViewType incident={this.props.incident} />
                <IncidentViewDocuments incident={this.props.incident} />
                <IncidentViewCategories incident={this.props.incident} />
                <IncidentViewSnapshot incident={this.props.incident} />
                <IncidentViewLocations incident={this.props.incident} />
                <IncidentViewPeopleAndGroups incident={this.props.incident} />
                <IncidentViewPersonnel incident={this.props.incident} />
                <IncidentViewNarrative incident={this.props.incident} />
                <IncidentViewFlags incident={this.props.incident} />
                <IncidentViewRelated incident={this.props.incident} />
                <IncidentViewChecklists incident={this.props.incident} />
            </>
        );
    }
}


export default withStyles(styles, { withTheme: true })(IncidentDisplay);