import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TransparentButton from '../controls/TransparentButton';
import Box from '@material-ui/core/Box';
import View from '../common/View';
import Loading from './Loading';
import Saving from './Saving';
import { Palette } from '../../common/constants/palette';
import Error from './Error';

export interface ICardContainerProps {
    theme: any;
    classes: any;
    actions?: any;
    style?: any;
    title?: string;
    isLoading?: boolean;
    isSaving?: boolean;
    collapsed?: any;
    expanded?: any;
    isExpanded?: boolean;
    onExpanded?: Function; // let parent control know expanded changed
    showLabel?: string;
    hideLabel?: string;
    errorText?: string;
}

interface ICardContainerState {
    isExpanded: boolean;
}

class CardContainer extends React.PureComponent<ICardContainerProps, ICardContainerState> {
    constructor(props: ICardContainerProps) {
        super(props)
        this.state = {
            isExpanded: false
        };
        this.onToggleExpanded = this.onToggleExpanded.bind(this);
    }

    componentDidMount() {
        if (this.props.isExpanded != undefined) {
            this.setState({ isExpanded: this.props.isExpanded })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isExpanded != undefined && this.props.isExpanded != prevProps.isExpanded) {
            this.setState({ isExpanded: this.props.isExpanded })
        }
    }
    
    onToggleExpanded() {
        this.setState({ isExpanded: !this.state.isExpanded }, () => {
            if (this.props.onExpanded) {
                this.props.onExpanded(this.state.isExpanded);
            }
        })
    }

    render() {
       // let isLoading;
        let self = this;
        /*if (this.props.isLoading) {
            isLoading = (
                <View style={{ position: 'absolute', top: 4, right: 4 }}>
                    <Loading />
                </View>
            );
        }
            */
        let isExpandCollapseMode = this.props.collapsed != null && this.props.expanded != null;
        let showLabel = this.props.showLabel ? this.props.showLabel : "Show More";
        let hideLabel = this.props.hideLabel ? this.props.hideLabel : "Show Less";

        const { theme, classes } = this.props;

        if(this.props.isSaving) {
            return <Card className={classes.cardContainer} style={{ ...this.props.style }}>
            {this.props.title && (
                <CardContent style={{ padding: '0' }}>
                    <Box p={0} display="flex" flexDirection="row" className={classes.cardContainerHeader}>
                        {isExpandCollapseMode && (
                            <Box p={0} flex={0} style={{ minWidth: '100px' }}>
                            </Box>
                        )}
                        <Box p={0} flex={1}>
                            <h3 className={classes.cardContainerHeaderText}>{this.props.title}</h3>
                        </Box>
                        {isExpandCollapseMode && (
                            <Box p={0} flex={0} style={{ minWidth: '100px', textAlign: 'right' }}>
                                <TransparentButton
                                    text={this.state.isExpanded ? hideLabel : showLabel}
                                    style={{ margin: '2px', padding: '0 5px', borderColor: Palette.PrimaryText, color: Palette.PrimaryText }}
                                    compact={true}
                                    noWrap={true}
                                    onClick={() => self.onToggleExpanded()} />
                            </Box>
                        )}
                    </Box>
                </CardContent>
            )}
            <CardContent style={{ padding: '0' }}>
                <Saving />
            </CardContent>
        </Card>
        }
        if(this.props.isLoading) {
            return <Card className={classes.cardContainer} style={{ ...this.props.style }}>
            {this.props.title && (
                <CardContent style={{ padding: '0' }}>
                    <Box p={0} display="flex" flexDirection="row" className={classes.cardContainerHeader}>
                        {isExpandCollapseMode && (
                            <Box p={0} flex={0} style={{ minWidth: '100px' }}>
                            </Box>
                        )}
                        <Box p={0} flex={1}>
                            <h3 className={classes.cardContainerHeaderText}>{this.props.title}</h3>
                        </Box>
                        {isExpandCollapseMode && (
                            <Box p={0} flex={0} style={{ minWidth: '100px', textAlign: 'right' }}>
                                <TransparentButton
                                    text={this.state.isExpanded ? hideLabel : showLabel}
                                    style={{ margin: '2px', padding: '0 5px', borderColor: Palette.PrimaryText, color: Palette.PrimaryText }}
                                    compact={true}
                                    noWrap={true}
                                    onClick={() => self.onToggleExpanded()} />
                            </Box>
                        )}
                    </Box>
                </CardContent>
            )}
            <CardContent style={{ padding: '0' }}>
                <Loading />
            </CardContent>
        </Card>
        }
        return (
            <Card className={classes.cardContainer} style={{ position: 'relative', ...this.props.style }} >
                {this.props.title && (
                    <CardContent style={{ padding: '0' }}>
                        <Box p={0} display="flex" flexDirection="row" className={classes.cardContainerHeader}>
                            {isExpandCollapseMode && (
                                <Box p={0} flex={0} style={{ minWidth: '100px' }}>
                                </Box>
                            )}
                            <Box p={0} flex={1}>
                                <h3 className={classes.cardContainerHeaderText}>{this.props.title}</h3>
                            </Box>
                            {isExpandCollapseMode && (
                                <Box p={0} flex={0} style={{ minWidth: '100px', textAlign: 'right' }}>
                                    <TransparentButton
                                        text={this.state.isExpanded ? hideLabel : showLabel}
                                        style={{ margin: '2px', padding: '0 5px', borderColor: Palette.PrimaryText, color: Palette.PrimaryText }}
                                        compact={true}
                                        noWrap={true}
                                        onClick={() => self.onToggleExpanded()} />
                                </Box>
                            )}
                        </Box>
                        {this.props.errorText && (
                            <Error message={this.props.errorText} style={{padding: '3px', margin: 0, borderRadius: 0}} />
                        )}
                    </CardContent>
                )}
                <CardContent style={{ padding: '0' }}>
                    {this.props.children}
                    {!isExpandCollapseMode && (
                        <>
                            {this.props.collapsed}
                            {this.props.expanded}
                        </>
                    )}
                    {(isExpandCollapseMode && this.state.isExpanded) && (
                        <>
                            {this.props.expanded}
                        </>
                    )}
                    {(isExpandCollapseMode && !this.state.isExpanded) && (
                        <>
                            {this.props.collapsed}
                        </>
                    )}
                </CardContent>
                {this.props.actions && (
                    <CardActions style={{ padding: '0 16px 16px 16px', textAlign: 'center' }}>
                        {this.props.actions}
                    </CardActions>
                )}
                {(isExpandCollapseMode && this.state.isExpanded) && (
                    <CardContent style={{ padding: '0' }}>
                        <Box p={0} display="flex" flexDirection="row" style={{ backgroundColor: Palette.Primary }}>
                            <Box p={0} flex={1}>
                            </Box>
                            <Box p={0} flex={0} style={{ minWidth: '100px', textAlign: 'right' }}>
                                <TransparentButton
                                    text={this.state.isExpanded ? hideLabel : showLabel}
                                    compact={true}
                                    noWrap={true}
                                    style={{ margin: '2px', padding: '0 5px', borderColor: Palette.PrimaryText, color: Palette.PrimaryText }}
                                    onClick={() => self.onToggleExpanded()} />
                            </Box>
                        </Box>
                    </CardContent>
                )}
            </Card>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardContainer);