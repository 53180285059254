import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import PageTitle from '../../../common/PageTitle'
import FullPageDialog from '../../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../../common/DefaultFab';
import SubTitle from '../../../common/SubTitle';
import List from '../../../common/List';
import SecondaryFab from '../../../common/SecondaryFab';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckboxControl from "../../../controls/CheckboxControl";
import PagedList from '../../../common/PagedList';
import Loading from '../../../common/Loading';
import TableContainer from '../../../layout/TableContainer';
import UserComplianceDocumentExpiredCard from './UserComplianceDocumentExpiredCard';
import UserComplianceDocumentExpiredHeader from './UserComplianceDocumentExpiredHeader';
import UserComplianceDocumentExpiredRow from './UserComplianceDocumentExpiredRow';
import PageMessage from '../../../common/PageMessage';
import PageLayout from '../../../layout/PageLayout';
import TransparentButton from '../../../controls/TransparentButton';

export interface IUserComplianceDocumentsExpiredProps {
    isLoading: boolean;
    userComplianceDocuments: AusComplyDtos.UserComplianceDocumentSummary[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.UserComplianceDocumentFilter;
    onLoad: Function;
    onExport: Function;
    history: any;
}

export default class UserComplianceDocumentsExpired extends Component<IUserComplianceDocumentsExpiredProps, any> {
    constructor(props: IUserComplianceDocumentsExpiredProps) {
        super(props)

        this.state = {
        };
        this.onExport = this.onExport.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onIncludeAssociatedSecurityFirmsChanged = this.onIncludeAssociatedSecurityFirmsChanged.bind(this);
    }

    componentDidMount() {
        var page = this.props.paging.page === 0 ? 1 : this.props.paging.page;
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {
       
    }

    onIncludeAssociatedSecurityFirmsChanged(value) {
        var filter = { ...this.props.filter };
        filter.includeAssociatedSecurityFirms = value;
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(filter));
    }

    prepareFilter(filter: AusComplyDtos.UserComplianceDocumentFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onExport() {
        if(this.props.onExport) {
            this.props.onExport();
        }
    }

    onCommand(command: string, item: AusComplyDtos.UserComplianceDocumentSummary) {
        switch (command) {
            case "edit":
                this.props.history.push('/venue/expiringcompliancedocument/' + item.userId);
                break
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.userComplianceDocuments && this.props.userComplianceDocuments.length > 0) {
            cardList = this.props.userComplianceDocuments.map((item, index) => {
                return (
                    <UserComplianceDocumentExpiredCard
                        onCommand={this.onCommand}
                        key={"card-" + item.userComplianceDocumentId.toString()}
                        userComplianceDocument={item}
                    />
                );
            });
            rows = this.props.userComplianceDocuments.map((item, index) => {
                return (
                    <UserComplianceDocumentExpiredRow
                        onCommand={this.onCommand}
                        key={"row-" + item.userComplianceDocumentId.toString()}
                        userComplianceDocument={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<UserComplianceDocumentExpiredHeader />}
                    rows={rows}
                />
            );
        }

        return (<PageLayout
            headerText="Expired/Expiring Compliance Documents"
            footerLeftContent={<TransparentButton text="Export" onClick={this.onExport} />}
        >
            <CardContainer title={"Filter"}>
                <CardContent>
                    <CheckboxControl
                        text={"Show Associated Firm"}
                        defaultValue={this.props.filter.includeAssociatedSecurityFirms}
                        onChanged={this.onIncludeAssociatedSecurityFirmsChanged} />
                </CardContent>
            </CardContainer>
            <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
        </PageLayout>);
    }
}