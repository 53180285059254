import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const incidentTypeApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  newEmpty: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  addOrUpdate: (venueId, securityFirmId, incidentType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentTypeId = incidentType.incidentTypeId;
    query.incidentType = incidentType;
    const request = client.post(query);
    return from(request);
  }
};
