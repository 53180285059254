import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import POIRegistrationWatchlists from './POIRegistrationWatchlists';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import ErrorButton from '../../controls/ErrorButton';
import PrimaryButton from '../../controls/PrimaryButton';
import NoRowsMessage from '../../common/NoRowsMessage';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import FileUpload from '../../common/FileUpload';
import Information from '../../common/Information';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { KeyName } from '../../../common/dto/common';
import RadioButtonUnCheckedIcon from '../../../resources/RadioButtonUnCheckedIcon';
import RadioButtonCheckedIcon from '../../../resources/RadioButtonCheckedIcon';
import PersonAddIcon from '../../../resources/PersonAddIcon';
import PoiPerson from '../../../resources/PoiPerson';
import ClearButton from '../../controls/ClearButton';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';


export interface IPOIRegistrationProps {
    incidentId: number;
    venueId: number;
    groupId: number;
    buttonMode?: boolean;
    poiRegistration: AusComplyDtos.POIRegistration;
    isLoading: boolean;
    isSaving: boolean;
    isScanning: boolean;
    isSearching: boolean;
    canCreate: boolean;
    onCreate: Function;
    onCreateGroup: Function;
    onUpdate: Function;
    onScanFaces: Function;
    onSearchFace: Function;
    onSave: Function;
    onCancel: Function;
    classes: any;
    theme: any;
}

interface IPOIRegistrationState {
    createOption: string;
    selectedFilePath: string;
    selectedFileName: string;
}

class POIRegistration extends Component<IPOIRegistrationProps, IPOIRegistrationState> {
    constructor(props: IPOIRegistrationProps) {
        super(props)
        this.state = {
            createOption: "CREATE",
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onScanDocument = this.onScanDocument.bind(this);
        this.onScanPatronPhoto = this.onScanPatronPhoto.bind(this);
        this.onClearScan = this.onClearScan.bind(this);
        this.onSearchFace = this.onSearchFace.bind(this);
        this.onClearFace = this.onClearFace.bind(this);
        this.onSelectPOI = this.onSelectPOI.bind(this);
        this.onClearPOI = this.onClearPOI.bind(this);
        this.onCreatePOI = this.onCreatePOI.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onPoiOptionSelect = this.onPoiOptionSelect.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.isSearching && !this.props.isSearching) || (prevProps.isScanning && !this.props.isScanning)) {
            let createOption = "CREATE";
            let displayName = "";
            let notes = "";
            if (this.props.poiRegistration && this.props.poiRegistration.pois) {
                displayName = this.props.poiRegistration.defaultName;
                notes = this.props.poiRegistration.defaultNotes;
                if (this.props.poiRegistration.pois.length > 0) {
                    createOption = this.props.poiRegistration.pois[0].id;
                    displayName = this.props.poiRegistration.pois[0].displayName;
                    notes = this.props.poiRegistration.pois[0].notes;
                }
            }
            let self = this;
            setTimeout(() => {
                self.onPoiOptionSelect(createOption, displayName, notes);
            }, 100);

        }
    }

    onValueChanged(fieldName: string, value: any) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration[fieldName] = value;
        this.props.onUpdate(poiRegistration)
    }

    onClearScan() {
        let poiRegistration = { ...this.props.poiRegistration };
        // @ts-ignore
        poiRegistration.incidentDocument = undefined;
        // @ts-ignore
        poiRegistration.incidentPatronPhoto = undefined;
        // @ts-ignore
        poiRegistration.file = undefined;
        // @ts-ignore
        poiRegistration.scannedFace = undefined;
        // @ts-ignore
        poiRegistration.poi = undefined;
        // @ts-ignore
        poiRegistration.pois = undefined;
        this.props.onUpdate(poiRegistration);
    }

    onScanDocument(document: AusComplyDtos.IncidentDocument) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration.incidentDocument = document;
        // @ts-ignore
        poiRegistration.incidentPatronPhoto = undefined;
        // @ts-ignore
        poiRegistration.file = undefined;
        this.props.onUpdate(poiRegistration);
        this.props.onScanFaces();
    }

    onScanPatronPhoto(patronPhoto: AusComplyDtos.IncidentPatronPhoto) {
        let poiRegistration = { ...this.props.poiRegistration };
        // @ts-ignore
        poiRegistration.incidentDocument = undefined;
        poiRegistration.incidentPatronPhoto = patronPhoto;
        // @ts-ignore
        poiRegistration.file = undefined;
        this.props.onUpdate(poiRegistration);
        this.props.onScanFaces();
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onSearchFace(scannedFace: AusComplyDtos.ScannedFace) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration.scannedFace = scannedFace;
        this.props.onUpdate(poiRegistration);
        this.props.onSearchFace();
    }

    onClearFace() {
        let poiRegistration = { ...this.props.poiRegistration };
        // @ts-ignore
        poiRegistration.scannedFace = undefined;
        // @ts-ignore
        poiRegistration.pois = undefined;
        // @ts-ignore
        poiRegistration.poi = undefined;
        this.props.onUpdate(poiRegistration);
    }

    onSelectPOI(poi: AusComplyDtos.Poi) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration.poi = poi;
        this.props.onUpdate(poiRegistration);
        this.props.onSave();
    }

    onCreatePOI() {
        let poiRegistration = { ...this.props.poiRegistration };
        // @ts-ignore
        poiRegistration.poi = undefined;
        this.props.onUpdate(poiRegistration);
        this.props.onSave();

    }

    onPoiOptionSelect(createOption: string, displayName: string, notes: string) {
        let self = this;
        this.setState({
            createOption
        }, () => {
            //if (!notes || notes === "") notes = " ";
            //if (!displayName || displayName === "") displayName = "Patron";
            let poiRegistration = { ...self.props.poiRegistration };
            poiRegistration.poiGuid = createOption;
            poiRegistration.name = displayName;
            poiRegistration.notes = notes;
            self.props.onUpdate(poiRegistration)
        });
    }

    onClearPOI() {
        let poiRegistration = { ...this.props.poiRegistration };
        // @ts-ignore
        poiRegistration.poi = undefined;
        this.props.onUpdate(poiRegistration);
    }

    onCancel() {
        this.props.onCancel();
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        if (temporaryFiles.length > 0) {
            let poiRegistration = { ...this.props.poiRegistration };
            poiRegistration.file = temporaryFiles[0];
            // @ts-ignore
            poiRegistration.incidentDocument = undefined;
            // @ts-ignore
            poiRegistration.incidentPatronPhoto = undefined;
            this.props.onUpdate(poiRegistration);
            this.props.onScanFaces();
        }
    }

    onCreate() {
        if (this.props.groupId && this.props.groupId > 0) {
            this.props.onCreateGroup(this.props.groupId);
        } else {
            this.props.onCreate(this.props.incidentId, this.props.venueId);
        }
    }

    render() {
        let self = this;
        const { theme } = this.props;
        const showModal = this.props.poiRegistration != undefined &&
            (this.props.poiRegistration.incidentId > 0 || this.props.poiRegistration.venueId > 0 || this.props.poiRegistration.groupId > 0 || this.props.isLoading);
        const watchlistSelected = this.props.poiRegistration.registerWatchlists && this.props.poiRegistration.registerWatchlists.length > 0;

        let buttonLabel = "Add POI to Watchlist" + (this.props.isLoading ? "..." : "")
        let hasScanned = this.props.poiRegistration != undefined &&
            (this.props.poiRegistration.incidentDocument != undefined || this.props.poiRegistration.incidentPatronPhoto != undefined || this.props.poiRegistration.file != undefined);

        let hasScannedFaces = hasScanned && this.props.poiRegistration.scannedFaces && this.props.poiRegistration.scannedFaces.length > 0;

        let documents;
        if (this.props.poiRegistration.incidentDocuments && this.props.poiRegistration.incidentDocuments.length > 0) { //(!hasScanned && this.props.poiRegistration.incidentDocuments && this.props.poiRegistration.incidentDocuments.length > 0) {
            documents = this.props.poiRegistration.incidentDocuments.filter(item => !item.obsolete).map((item, index) => {
                return (
                    <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFilename + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        />
                        {(!this.props.isScanning && watchlistSelected) && (
                            <TransparentButton text={"Scan"} style={{ height: '25px', marginLeft: '22px' }} onClick={() => this.onScanDocument(item)} />
                        )}
                    </Box>
                )
            });
        } else if (false && hasScanned && this.props.poiRegistration.incidentDocument != undefined) {
            let item = this.props.poiRegistration.incidentDocument;
            documents = (
                <>
                    <Box p={0} key={"file-box-" + item.originalFilename} >
                        <Thumbnail
                            key={"file-" + item.originalFilename}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        />
                    </Box>
                    {(!this.props.isScanning) && (
                        <Box p={0} key={"file-box-document-clear"}>
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                                <Box p={0} flexGrow={1} >
                                </Box>
                                <Box p={1} flexGrow={0} >
                                    <ErrorButton text="Clear" onClick={() => this.onClearScan()} />
                                </Box>
                                <Box p={0} flexGrow={1} >
                                </Box>
                            </Box>
                        </Box>
                    )}

                </>
            );
        }

        let patronPhotos;
        if (this.props.poiRegistration.incidentPatronPhotos && this.props.poiRegistration.incidentPatronPhotos.length > 0) { //(!hasScanned && this.props.poiRegistration.incidentPatronPhotos && this.props.poiRegistration.incidentPatronPhotos.length > 0) {
            patronPhotos = this.props.poiRegistration.incidentPatronPhotos.filter(item => !item.obsolete).map((item, index) => {
                return (
                    <Box p={0} key={"file-box-patron" + item.originalFileName + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-patron-" + item.originalFileName + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFileName}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        />
                        {(!this.props.isScanning && watchlistSelected) && (
                            <TransparentButton text={"Scan"} style={{ height: '25px', marginLeft: '22px' }} onClick={() => this.onScanPatronPhoto(item)} />
                        )}
                    </Box>
                )
            });
        } else if (false && hasScanned && this.props.poiRegistration.incidentPatronPhoto != undefined) {
            let item = this.props.poiRegistration.incidentPatronPhoto;
            patronPhotos = (
                <>
                    <Box p={0} key={"file-box-patron" + item.originalFileName}>
                        <Thumbnail
                            key={"file-patron-" + item.originalFileName}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFileName}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        />
                    </Box>
                    {!this.props.isScanning && (
                        <Box p={0} key={"file-box-patron-clear"}>
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                                <Box p={0} flexGrow={1} >
                                </Box>
                                <Box p={1} flexGrow={0} >
                                    <ErrorButton text="Clear" onClick={() => this.onClearScan()} />
                                </Box>
                                <Box p={0} flexGrow={1} >
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            );
        }

        let file;
        if (this.props.poiRegistration.file == undefined && (this.props.venueId > 0 || this.props.groupId)) { //(!hasScanned && this.props.poiRegistration.file == undefined && this.props.venueId > 0) {
            file = <Box p={0} flex={1}><FileUpload onFileWasUploaded={this.fileWasUploaded} /></Box>
        } else if (false && this.props.poiRegistration.file != undefined) {
            let item = this.props.poiRegistration.file;
            file = (
                <>
                    <Box p={0} key={"file-box-patron" + item.originalName}>
                        <Thumbnail
                            key={"file-patron-" + item.originalName}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalName}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        />
                    </Box>
                    {!this.props.isScanning && (
                        <Box p={0} key={"file-box-patron-clear"}>
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                                <Box p={0} flexGrow={1} >
                                </Box>
                                <Box p={1} flexGrow={0} >
                                    <ErrorButton text="Clear" onClick={() => this.onClearScan()} />
                                </Box>
                                <Box p={0} flexGrow={1} >
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            );
        }

        let availablePhotos;
        if (watchlistSelected && !hasScannedFaces && !this.props.isScanning) {
            availablePhotos = (
                <CardContainer
                    title={"Select the image to search for faces"}>
                    <CardContent>
                        <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                            {file} {documents} {patronPhotos}
                        </Box>
                    </CardContent>
                </CardContainer>
            );
        }

        let faceScans;
        if (this.props.isScanning) {
            faceScans = (
                <ControlGroup labelAbove={true}>
                    <Loading message={"Scanning faces"} />
                </ControlGroup>
            )
        } else if (hasScanned) {
            if (this.props.poiRegistration.scannedFaces == undefined || this.props.poiRegistration.scannedFaces.length == 0) {
                faceScans = (
                    <CardContainer
                        title={"No faces detected"}>
                        <CardContent>
                            <NoRowsMessage message={"Select a different image and try again"} />
                        </CardContent>
                    </CardContainer>
                );
            } else if (this.props.poiRegistration.scannedFaces.length > 0) {
                let title = this.props.poiRegistration.scannedFaces.length > 1 ? "Select a face to search for an existing POI" : "Face found";
                faceScans = (
                    <CardContainer
                        title={title}>
                        <CardContent>
                            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                                {this.props.poiRegistration.scannedFaces.map((face, faceIndex) =>
                                    <Box p={0} key={"file-box-face-" + faceIndex} >
                                        <Thumbnail
                                            key={"file-face-" + faceIndex.toString()}
                                            previewPath={""}
                                            displayPath={""}
                                            base64={face.image}
                                            base64Type={"image/jpeg"}
                                            isImage={true}
                                            text={"Detected face"}
                                            onPreview={(path, text) => { this.onSearchFace(face) }}
                                        />
                                    </Box>
                                )}
                                <Box p={0} key={"file-box-patron-clear"}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
                                        <Box p={0} flexGrow={1} >
                                        </Box>
                                        <Box p={1} flexGrow={0} >
                                            <ErrorButton text="Clear" onClick={() => this.onClearScan()} />
                                        </Box>
                                        <Box p={0} flexGrow={1} >
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardContainer>
                );
            }
        }

        let pois;
        if (this.props.isSearching) {
            pois = (
                <ControlGroup labelAbove={true}>
                    <Loading message={"Searching faces"} />
                </ControlGroup>
            )
        } else if (!this.props.isSearching && this.props.poiRegistration.scannedFace && this.props.poiRegistration.pois) {
            let label = this.props.poiRegistration.pois.length > 0 ? this.props.poiRegistration.pois.length == 0 ? "Match found" : "Matches found" : "No match found, create one";

            // potential pois found
            pois = <ControlGroup text="Select an existing POI or create a new record" labelAbove={true}>
                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                    {this.props.poiRegistration.pois.map((poi, poiIndex) =>
                        <Box p={1} key={"file-box-poi-" + poiIndex}>
                            <Thumbnail
                                key={"file-face-" + poiIndex.toString()}
                                previewPath={""}
                                displayPath={""}
                                base64={poi.image}
                                base64Type={"image/jpeg"}
                                isImage={true}
                                title={Math.round(poi.confidence).toString() + "% " + poi.displayName}
                                fullText={Math.round(poi.confidence).toString() + "% " + poi.displayName}
                                text={poi.displayName}
                                onPreview={(path, text) => { }}
                            />
                            <TransparentButton text={"Use"} small={true} style={{ height: '25px', width: '100%' }} onClick={() => this.onSelectPOI(poi)} />
                        </Box>
                    )}
                </Box>
            </ControlGroup >;



            let poiOption;
            if (true) {
                //let poiOptions: KeyName[] = [
                //    { key: "USE", name: "Add Face to existing POI" },
                //    { key: "CREATE", name: "Create a new POI" }
                //];

                let poiCreateColor = this.state.createOption === "CREATE" ? "#fff" : "#999";
                let poiCreateIcon = this.state.createOption === "CREATE" ? <RadioButtonCheckedIcon fill={"#fff"} /> : <RadioButtonUnCheckedIcon fill={"#999"} />;
                let poiCreate = <Box flex={0} p={1} key={"CREATE"}>
                    <Box p={1} key={"file-box-poi-create"}>
                        <Box p={1} style={{ paddingBottom: '8px' }} onClick={() => self.onPoiOptionSelect("CREATE", this.props.poiRegistration.defaultName || "", this.props.poiRegistration.defaultNotes || "")}>
                            <div
                                style={{
                                    background: 'transparent',
                                    border: '1px solid rgb(84, 84, 84)',
                                    borderRadius: '5px',
                                    padding: '3px',
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: '0px'
                                }}
                            >
                                <div style={{ width: '80px', height: '85px', padding: '8px' }}>
                                    <p style={{
                                        color: poiCreateColor,
                                        position: 'absolute',
                                        fontSize: '8px',
                                        top: '2px',
                                        background: 'black',
                                        opacity: 0.7,
                                        bottom: 'auto',
                                        left: 0,
                                        textAlign: 'center',
                                        width: "100%",
                                        margin: "0",
                                        padding: "3px 0",
                                        lineHeight: "11px"
                                    }}>Create New POI</p>
                                    <PoiPerson fill={poiCreateColor} />
                                </div>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <ClearButton onClick={() => self.onPoiOptionSelect("CREATE", this.props.poiRegistration.defaultName || "", this.props.poiRegistration.defaultNotes || "")} style={{ height: '25px', width: '25px' }} >
                                {poiCreateIcon}
                            </ClearButton>
                        </Box>
                    </Box>
                </Box>;
                poiOption = <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {this.props.poiRegistration.pois.map((item, index) => {
                        let icon = item.id === this.state.createOption ? <RadioButtonCheckedIcon fill={"#fff"} /> : <RadioButtonUnCheckedIcon fill={"#999"} />;
                        return (
                            <Box flex={0} p={1} key={item.id}>
                                <Box p={1} key={"file-box-poi-" + index}>
                                    <Thumbnail
                                        key={"file-face-" + index.toString()}
                                        previewPath={""}
                                        displayPath={""}
                                        base64={item.image}
                                        base64Type={"image/jpeg"}
                                        isImage={true}
                                        title={Math.round(item.confidence).toString() + "% " + item.displayName}
                                        fullText={Math.round(item.confidence).toString() + "% " + item.displayName}
                                        text={item.displayName}
                                        onPreview={(path, text) => self.onPoiOptionSelect(item.id, item.displayName, item.notes)}
                                    />
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <ClearButton onClick={() => self.onPoiOptionSelect(item.id, item.displayName, item.notes)} style={{ height: '25px', width: '25px' }} >
                                            {icon}
                                        </ClearButton>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                    {poiCreate}
                </Box>;
            }

            let isSharedWatchlist = false;
            if (this.props.poiRegistration.registerWatchlists.filter(f => f.facialRecognitionWatchlist.venueId === 0).length > 0) {
                isSharedWatchlist = true;
            }

            let poiContent =
                <Box key={this.state.createOption} display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box p={1} key={"file-box-poi-create"} style={{ minWidth: '280px' }}>
                        <Information
                            isWarning={true}
                        >
                            <p style={{ ...theme.custom.label, fontSize: '0.7em', maxWidth: '400px', padding: '0 10px', textAlign: 'center', color: theme.custom.colors.warning }}>
                                {isSharedWatchlist && (<>
                                    <span>
                                        WARNING: INFORMATION ENTERED HERE WILL BE VISIBLE TO OTHER VENUES SHARING THIS WATCHLIST.
                                        <br /> <br />
                                    </span>
                                </>)}
                                <span>AusComply Facial Recognition must continue to be used in line with our Terms &amp; Conditions, Privacy Statement and any Service Level Agreement. A breach of these conditions may result in restriction or termination of service.</span>
                            </p>
                        </Information>
                        <TextControlGroup text={"Name"} labelAbove={true}
                            defaultValue={this.props.poiRegistration.name}
                            onChange={(value) => this.onValueChanged("name", value)}
                        />
                        <TextAreaControlGroup text={"Notes"} labelAbove={true}
                            defaultValue={this.props.poiRegistration.notes}
                            onChange={(value) => this.onValueChanged("notes", value)}
                        />

                        {this.state.createOption !== "CREATE" && (
                            <CheckboxControlGroup
                                label={" "}
                                labelAbove={true}
                                text={"Set new image as display / primary image"}
                                defaultValue={this.props.poiRegistration.setAsPrimaryImage}
                                onChanged={(value) => this.onValueChanged("setAsPrimaryImage", value)} />
                        )}

                        <PrimaryButton text={this.state.createOption === "CREATE" ? "Create New POI" : "Update Existing POI"} style={{ width: '100%', marginTop: '10px' }} onClick={() => this.onCreatePOI()} />
                    </Box>
                </Box>;


            pois = <CardContainer
                title={label}>
                <CardContent>
                    {poiOption}
                    {poiContent}
                </CardContent>
            </CardContainer>;
        }



        let poiRegistration;
        if (showModal && this.props.poiRegistration != undefined && this.props.poiRegistration) {

            poiRegistration = (
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}>
                    <POIRegistrationWatchlists
                        poiRegistration={this.props.poiRegistration}
                        facialRecognitionWatchlists={this.props.poiRegistration.watchlists}
                        onUpdate={value => this.props.onUpdate(value)} />
                    {availablePhotos}
                    {faceScans}
                    {pois}
                    <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
                </LayoutForm>
            );
        }


        return (
            <>
                {this.props.buttonMode ? (<>
                    <TransparentButton text={buttonLabel} onClick={() => { if (!self.props.isLoading) { self.onCreate() } }} />
                </>) : (<SecondaryFab
                    isExtended={true}
                    onClick={() => { if (!self.props.isLoading) { self.onCreate() } }}>{buttonLabel}</SecondaryFab>)}
                <div style={{ display: 'inline-table' }}>
                    <FullPageDialog open={showModal} title={this.props.poiRegistration.incidentSerialNumber} onDismissed={() => this.onCancel()}>
                        {poiRegistration}
                    </FullPageDialog>
                </div>
            </>
        );

    }
}
export default withStyles(styles, { withTheme: true })(POIRegistration);