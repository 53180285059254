import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyName } from '../../common/dto/common';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircleMoreIcon from '../../resources/CircleMoreIcon';

export interface IMoreCommandsProps {
    theme: any;
    style?: any;
    iconStyle?: any;
    commands?: KeyName[];
    onCommand?: Function;
}

class MoreCommands extends React.Component<IMoreCommandsProps, any> {
    constructor(props: IMoreCommandsProps) {
        super(props)
        this.handleClickItem = this.handleClickItem.bind(this);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickItem = option => event => {
        if (this.props.onCommand) {
            this.props.onCommand(option.key);
        }
        this.setState({ anchorEl: null });
    };

    render() {
        const { theme } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <>
                <IconButton
                    aria-label="More"
                    aria-owns={open ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    style={{ padding: 0, width: '20px', height: '20px', ...this.props.style }}
                    onClick={this.handleClick}
                >
                    <CircleMoreIcon style={{ color: theme.custom.colors.cardContainerBorder, ...this.props.iconStyle }} />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            width: 280,
                            border: '1px solid #666'
                        },
                    }}
                >
                    {this.props.commands && this.props.commands.map(option => {
                        
                            if(option.key == 'heading') {
                                return (
                                    <h5 style={{marginTop: '6px', marginBottom: '2px', marginLeft: '10px'}} key={option.key + option.name}>{option.name}</h5>
                             );
                            }
                            return (
                                <MenuItem key={option.key} onClick={this.handleClickItem(option)}>
                                    <Typography variant="inherit" noWrap>{option.name}</Typography>
                                </MenuItem>
                            );
                            })}
                </Menu>
            </>
        );

    }
}
export default withStyles(styles, { withTheme: true })(MoreCommands);