import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SimpleDateControlGroup from '../../../controls/SimpleDateControlGroup';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckListItemAttachments from './CheckListItemAttachments';
import CheckListItemAttachment from './CheckListItemAttachment';
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';

export interface ICheckListItemDateProps {
    checkListDisplayItem: CheckListDisplayItem;
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemDateState {
}

class CheckListItemDate extends Component<ICheckListItemDateProps, ICheckListItemDateState> {
    constructor(props: ICheckListItemDateProps) {
        super(props)
        this.state = {

        }
        this.onUpdate = this.onUpdate.bind(this)
    }

    onUpdate(value) {
        let checklistItem = { ...this.props.checkListDisplayItem.item };
        if (value.isEmpty) {
            checklistItem.value = "";
        } else {
            checklistItem.value = new Date(value.year, value.month - 1, value.day).toString();
        }
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
    }

    render() {
        const { theme } = this.props;

        let date = new AusComplyDtos.SimpleDate();
        date.isEmpty = true;
        if (this.props.checkListDisplayItem.item && this.props.checkListDisplayItem.item.value && this.props.checkListDisplayItem.item.value != "") {
            try {
                let dateValue = new Date(this.props.checkListDisplayItem.item.value);
                date.isEmpty = false;
                date.year = dateValue.getFullYear();
                date.month = dateValue.getMonth() + 1;
                date.day = dateValue.getDate();
                date.display = dateValue.toLocaleDateString();
            } catch (error) {

            }
        }

        let cardStyle;
        if (this.props.checkListDisplayItem.isRequired) {
            cardStyle = { borderColor: theme.custom.colors.error };
        }

        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplay>{date.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <SimpleDateControlGroup text={this.props.checkListDisplayItem.template.name}
                                date={date}
                                readonly={this.props.isReadonly}
                                onChange={this.onUpdate}
                            />
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemDate);