import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IVenueLocationHeaderProps {
    showIndustyCagetories?: boolean;
    showCameras?: boolean;
    showSubLocations?: boolean;
    classes: any;
}

class VenueLocationHeader extends React.Component<IVenueLocationHeaderProps, any> {
    render() {
        const { classes } = this.props;
        if (this.props.showIndustyCagetories) {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Industries</th>
                    <th className={classes.tableHr}>Code</th>
                    <th className={classes.tableHr}>Rank</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        if (this.props.showCameras && this.props.showSubLocations)
        {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Cameras</th>
                    <th className={classes.tableHr}>Sub Locations</th>
                    <th className={classes.tableHr}>Code</th>
                    <th className={classes.tableHr}>Rank</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        if (this.props.showSubLocations)
        {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Sub Locations</th>
                    <th className={classes.tableHr}>Code</th>
                    <th className={classes.tableHr}>Rank</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        if (this.props.showCameras) {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Cameras</th>
                    <th className={classes.tableHr}>Code</th>
                    <th className={classes.tableHr}>Rank</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Code</th>
                <th className={classes.tableHr}>Rank</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenueLocationHeader);