import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { deviceInfo } from '../../utilities/device';

export const userActionAuditServiceApi = {
  getForUser: (venueId: number, securityFirmId: number, userId: number, page: number, pageSize: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminUserActionAudits();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId;
    query.pageSize = pageSize;
    query.page = page;
    const request = client.post(query);
    return from(request);
  },
  postActionAudit: (venueId: number, securityFirmId: number, venueEventId: number, actionType: AusComplyDtos.ngtUserActionType, locationFound?: boolean, latitude?: number, longitude?: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserActionAudit();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.actionType = actionType;
    try {
      // set the location metadata
      query.locationFound = locationFound || false;
      query.latitude = latitude || 0;
      query.longitude = longitude || 0;
    } catch (error) {
    }
    try {
      // set the device metadata
      let info = deviceInfo.get();
      query.version = info.version;
      query.appVersion = info.appVersion;
      query.deviceId = info.deviceId;
      query.os = info.os;
      query.model = info.model;
      query.make = info.make;
    } catch (error) {
      //console . log(error);
    }
    const request = client.post(query);
    return from(request);
  }
};
