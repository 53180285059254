import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import { incidentLogic } from '../../common/logic/incidentLogic';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IScheduleVenueLocationsProps {
    schedule: AusComplyDtos.Schedule;
    venueLocations: AusComplyDtos.VenueLocation[];
    onUpdate: Function;
}

interface IScheduleVenueLocationsState {
    items: MulitSelectOption[];
}

export default class ScheduleVenueLocations extends Component<IScheduleVenueLocationsProps, IScheduleVenueLocationsState> {
    constructor(props: IScheduleVenueLocationsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.venueLocations) !== JSON.stringify(this.props.venueLocations)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleVenueLocations) !== JSON.stringify(this.props.schedule.scheduleVenueLocations)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let venueLocationIds: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleVenueLocations) {
            venueLocationIds = this.props.schedule.scheduleVenueLocations.filter(f => !f.obsolete).map(item => item.venueLocationId);
        }
        if (this.props.venueLocations) {
            this.props.venueLocations.map(item => {
                let isSelected = venueLocationIds.indexOf(item.venueLocationId) > -1;
                items.push({
                    id: item.venueLocationId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.schedule.scheduleVenueLocations.map(item => item.venueLocationId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.schedule.scheduleVenueLocations.filter(item => currentIds.indexOf(item.venueLocationId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ScheduleVenueLocation = new AusComplyDtos.ScheduleVenueLocation();
            newItem.venueLocationId = item.id;
            newItems.push(newItem);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleVenueLocations = newItems;
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {

        return (
            <CardContainer title={"Locations"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} /></CardContent>
            </CardContainer>
        );
    }
}