import update from "immutability-helper";
import * as facialRecognitionWatchlistActions from "../actions/facialRecognitionWatchlist";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";

const initialState = {
    facialRecognitionWatchlists: {
        venueId: -1,
        groupId: -1,
        isLoading: false,
        facialRecognitionWatchlists: []
    },
    facialRecognitionWatchlist: {
        facialRecognitionWatchlistId: -1,
        isLoading: false,
        isSaving: false,
        facialRecognitionWatchlistTypes: [],
        facialRecognitionServers: [],
        facialRecognitionWatchlist: { facialRecognitionWatchlistId: 0 },
    },
    facialRecognitionWatchlistPois: {
        isLoading: false,
        isSaving: false,
        filter: {},
        thumbnails: false,
        facialRecognitionWatchlistId: 0,
        facialRecognitionWatchlistPois: [],
        facialRecognitionWatchlistPoi: {
            poiGuid: ""
        },
        cameras: [],
        paging: { pageSize: 0, page: 1 }
    },
    facialRecognitionWatchlistImports: {
        isLoading: false,
        isSaving: false,
        isSavedSuccessful: false,
        facialRecognitionWatchlistImports: []
    },
    facialRecognitionWatchlistMigration: {
        facialRecognitionWatchlistMigration: { facialRecognitionWatchlistId: -1},
        isLoading: false,
        isSaving: false,
    },
    facialRecognitionWatchlistMove: {
        facialRecognitionWatchlistMove: { facialRecognitionWatchlistId: -1},
        isLoading: false,
        isSaving: false,
    }
};

function setIsLoading(state, isLoading, venueId, groupId) {
    let newState = update(state, {
        facialRecognitionWatchlists: {
            isLoading: { $set: isLoading },
            venueId: { $set: venueId },
            groupId: { $set: groupId }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlists(state, facialRecognitionWatchlists) {
    let newState = update(state, {
        facialRecognitionWatchlists: {
            facialRecognitionWatchlists: { $set: facialRecognitionWatchlists }
        }
    });
    return newState;
}

function setIsSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function loadingFacialRecognitionWatchlist(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlist(state, facialRecognitionWatchlist) {
    // set the default server
    if (facialRecognitionWatchlist.facialRecognitionWatchlistId == 0 &&
        (!facialRecognitionWatchlist.facialRecognitionServerId || facialRecognitionWatchlist.facialRecognitionServerId == 0)) {
        if (state.facialRecognitionWatchlist.facialRecognitionServers.length > 0) {
            var cloudServers = state.facialRecognitionWatchlist.facialRecognitionServers.filter(f => f.serverType == AusComplyDtos.ngtServerType.Cloud && !f.obsolete);

            if (cloudServers.length == 1) {
                facialRecognitionWatchlist.facialRecognitionServerId = cloudServers[0].facialRecognitionServerId;
            }
        }
    }
    let newState = update(state, {
        facialRecognitionWatchlist: {
            facialRecognitionWatchlist: { $set: facialRecognitionWatchlist }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistTypes(state, facialRecognitionWatchlistTypes) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            facialRecognitionWatchlistTypes: { $set: facialRecognitionWatchlistTypes }
        }
    });
    return newState;
}

function setFacialRecognitionServers(state, facialRecognitionServers) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            facialRecognitionServers: { $set: facialRecognitionServers }
        }
    });
    return newState;
}

function savingFacialRecognitionWatchlist(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistId(state, facialRecognitionWatchlistId) {
    let newState = update(state, {
        facialRecognitionWatchlist: {
            facialRecognitionWatchlistId: { $set: facialRecognitionWatchlistId }
        }
    });
    return newState;
}

function setPoisIsLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistPoi(state, facialRecognitionWatchlistPoi) {
    let newFRWPState = update(state.facialRecognitionWatchlistPois, {
        facialRecognitionWatchlistPoi: { $set: facialRecognitionWatchlistPoi }
    });
    let newState = update(state, {
        facialRecognitionWatchlistPois: { $set: newFRWPState }
    });
    return newState;
}

function setFacialRecognitionWatchlistPois(state, facialRecognitionWatchlistPois) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            facialRecognitionWatchlistPois: { $set: facialRecognitionWatchlistPois }
        }
    });
    return newState;
}

function savingFacialRecognitionWatchlistPoi(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setPoisFacialRecognitionWatchlistId(state, facialRecognitionWatchlistId) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            facialRecognitionWatchlistId: { $set: facialRecognitionWatchlistId }
        }
    });
    return newState;
}

function setPois(state, facialRecognitionWatchlistPois, filter, paging, cameras) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            facialRecognitionWatchlistPois: { $set: facialRecognitionWatchlistPois },
            filter: { $set: filter },
            paging: { $set: paging },
            cameras: { $set: cameras }
        }
    });
    return newState;
}

function setThumbnails(state, thumbnails) {
    let newState = update(state, {
        facialRecognitionWatchlistPois: {
            thumbnails: { $set: thumbnails }
        }
    });
    return newState;
}

function setIsImportsLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistImports: {
            isLoading: { $set: isLoading },
            isSavedSuccessful: { $set: false },
        }
    });
    return newState;
}

function setImports(state, facialRecognitionWatchlistImports) {
    let newState = update(state, {
        facialRecognitionWatchlistImports: {
            facialRecognitionWatchlistImports: { $set: facialRecognitionWatchlistImports }
        }
    });
    return newState;
}

function setIsImportsSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlistImports: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setIsImportsSaveSuccess(state, facialRecognitionWatchlistImports: AusComplyDtos.FacialRecognitionWatchlistImport[]) {
    var isSavedSuccessful = facialRecognitionWatchlistImports.filter(f => f.selected && !f.success).length == 0;
    let newState = update(state, {
        facialRecognitionWatchlistImports: {
            isSavedSuccessful: { $set: isSavedSuccessful }
        }
    });
    return newState;
}

function setIsMigrationLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistMigration: {
            isLoading: { $set: isLoading },
        }
    });
    return newState;
}

function setMigration(state, facialRecognitionWatchlistMigration) {
    let newState = update(state, {
        facialRecognitionWatchlistMigration: {
            facialRecognitionWatchlistMigration: { $set: facialRecognitionWatchlistMigration }
        }
    });
    return newState;
}

function setIsMigrationSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlistMigration: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setIsMoveLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistMove: {
            isLoading: { $set: isLoading },
        }
    });
    return newState;
}

function setMove(state, facialRecognitionWatchlistMove) {
    let newState = update(state, {
        facialRecognitionWatchlistMove: {
            facialRecognitionWatchlistMove: { $set: facialRecognitionWatchlistMove }
        }
    });
    return newState;
}

function setIsMoveSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlistMove: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}


export default function facialRecognitionWatchlists(state = initialState, action) {
    switch (action.type) {
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLISTS_REQUEST:
            return setIsLoading(state, true, action.forVenueId, action.forGroupId);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLISTS_REQUEST_SUCCESS:
            return setIsLoading(setFacialRecognitionWatchlists(state, action.facialRecognitionWatchlists), false, state.facialRecognitionWatchlists.venueId, state.facialRecognitionWatchlists.groupId);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLISTS_REQUEST_FAILURE:
            return setIsLoading(state, false, state.facialRecognitionWatchlists.venueId, state.facialRecognitionWatchlists.groupId);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST:
            return setIsSaving(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_FAILURE:
            return setIsSaving(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlists(setIsSaving(state, false), action.facialRecognitionWatchlists);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_RESET:
            return setFacialRecognitionWatchlistId(state, -1);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_REQUEST:
            return setFacialRecognitionWatchlist(loadingFacialRecognitionWatchlist(setFacialRecognitionWatchlistId(state, action.facialRecognitionWatchlistId), true), {});
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_REQUEST_SUCCESS:
            return setFacialRecognitionServers(setFacialRecognitionWatchlistTypes(
                setFacialRecognitionWatchlist(loadingFacialRecognitionWatchlist(state, false), action.facialRecognitionWatchlist),
                action.facialRecognitionWatchlistTypes), action.facialRecognitionServers);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_REQUEST_FAILURE:
            return loadingFacialRecognitionWatchlist(setFacialRecognitionWatchlistId(state, -1), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_CREATE:
            return setFacialRecognitionWatchlist(loadingFacialRecognitionWatchlist(setFacialRecognitionWatchlistId(state, 0), true), {});;
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_CREATE_SUCCESS:
            return setFacialRecognitionWatchlistTypes(
                setFacialRecognitionWatchlist(
                    loadingFacialRecognitionWatchlist(
                        setFacialRecognitionServers(state, action.facialRecognitionServers), false),
                    action.facialRecognitionWatchlist),
                action.facialRecognitionWatchlistTypes);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_CREATE_FAILURE:
            return loadingFacialRecognitionWatchlist(setFacialRecognitionWatchlistId(state, -1), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST:
            return savingFacialRecognitionWatchlist(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlists(savingFacialRecognitionWatchlist(setFacialRecognitionWatchlistId(state, -1), false), action.facialRecognitionWatchlists);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionWatchlist(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SET:
            return setFacialRecognitionWatchlist(state, action.facialRecognitionWatchlist);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POI_SET:
            return setFacialRecognitionWatchlistPoi(state, action.facialRecognitionWatchlistPoi);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST:
            return savingFacialRecognitionWatchlistPoi(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlistPoi(setFacialRecognitionWatchlistPois(savingFacialRecognitionWatchlistPoi(state, false), action.facialRecognitionWatchlistPois), { poiGuid: "" });
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionWatchlistPoi(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_RESET:
            return setPoisFacialRecognitionWatchlistId(setPois(state, [], new AusComplyDtos.FacialRecognitionWatchlistPoisFilter(), { page: 1, pageSize: 0 }, []), 0);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST:
            return setPoisIsLoading(setPoisFacialRecognitionWatchlistId(state, action.facialRecognitionWatchlistId), true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST:
            return setPoisIsLoading(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_SUCCESS:
            return setPoisIsLoading(setPois(state, action.facialRecognitionWatchlistPois, action.filter, action.paging, action.cameras), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_SUCCESS:
            return setPoisIsLoading(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_FAILURE:
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_FAILURE:
            return setPoisIsLoading(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_THUMBNAILS:
            return setThumbnails(state, action.thumbnails);

        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST:
            return setImports(setIsImportsLoading(state, true), []);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_SUCCESS:
            return setImports(setIsImportsLoading(state, false), action.facialRecognitionWatchlistImports);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_FAILURE:
            return setImports(setIsImportsLoading(state, false), []);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST:
            return setIsImportsSaving(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_SUCCESS:
            return setIsImportsSaving(setIsImportsSaveSuccess(setImports(state, action.facialRecognitionWatchlistImports), action.facialRecognitionWatchlistImports), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_FAILURE:
            return setIsImportsSaving(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_IMPORT_SET:
            return setImports(state, action.facialRecognitionWatchlistImports);

        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST:
            return setMigration(setIsMigrationLoading(state, true), { facialRecognitionWatchlistId: action.facialRecognitionWatchlistId });
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_SUCCESS:
            return setMigration(setIsMigrationLoading(state, false), action.facialRecognitionWatchlistMigration);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_FAILURE:
            return setMigration(setIsMigrationLoading(state, false), { facialRecognitionWatchlistId: -1});
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST:
            return setIsMigrationSaving(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_SUCCESS:
            return setIsMigrationSaving(setMigration(state, action.facialRecognitionWatchlistMigration), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_FAILURE:
            return setIsMigrationSaving(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_SET:
            return setMigration(state, action.facialRecognitionWatchlistMigration);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST:
            return setIsMigrationSaving(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_SUCCESS:
            return setIsMigrationSaving(setMigration(state, action.facialRecognitionWatchlistMigration), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_FAILURE:
            return setIsMigrationSaving(state, false);
        // move
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST:
            return setMove(setIsMoveLoading(state, true), { facialRecognitionWatchlistId: action.facialRecognitionWatchlistId });
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_SUCCESS:
            return setMove(setIsMoveLoading(state, false), action.facialRecognitionWatchlistMove);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_FAILURE:
            return setMove(setIsMoveLoading(state, false), { facialRecognitionWatchlistId: -1});
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST:
            return setIsMoveSaving(state, true);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_SUCCESS:
            return setIsMoveSaving(setMove(state, action.facialRecognitionWatchlistMove), false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_FAILURE:
            return setIsMoveSaving(state, false);
        case facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MOVE_SET:
            return setMove(state, action.facialRecognitionWatchlistMove);
    }
    return state;
}