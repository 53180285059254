import { connect } from 'react-redux'
import { withRouter } from "react-router";
import RadioChannel from '../components/radioChannels/RadioChannel'
import * as radioChannelActions from '../common/actions/radioChannel';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.radioChannels.radioChannel.isLoading,
        isSaving: state.radioChannels.radioChannel.isSaving,
        radioChannel: state.radioChannels.radioChannel.radioChannel
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(radioChannelActions.radioChannelReset())
        },
        onUpdate: (radioChannel) => {
            dispatch(radioChannelActions.radioChannelUpdate(radioChannel))
        },
        onSave: () => {
            dispatch(radioChannelActions.radioChannelSaveRequest())
        }
    }
}

const RadioChannelContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RadioChannel))

export default RadioChannelContainer