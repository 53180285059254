import update from "immutability-helper";
import {
    USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST,
    USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST,
    USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_SUCCESS,
    USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_FAILURE,
    USER_VENUE_SECURITYFIRM_STATISTICS_RESET
} from "../actions/userVenueSecurityFirm";

import {
    NOTIFICATIONS_REQUEST_SUCCESS
} from '../actions/notifications';

const initialState = {
    isLoading: true,
    error: "",
    statistics: { venueName: "", securityFirmName: "", notificationCount: 0 }
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function updateStatistics(state, statistics) {
    let newState = update(state, {
        statistics: { $set: statistics }
    });
    return newState;
}

function updateNotificationCount(state, groupedNotifications) {
    let statistics = { ...state.statistics };
    statistics.notificationCount = 0;
    groupedNotifications.forEach(g => {
        statistics.notificationCount += g.count;
    });

    let newState = update(state, {
        statistics: { $set: statistics }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

export default function userVenueSecurityFirm(state = initialState, action) {
    switch (action.type) {
        case USER_VENUE_SECURITYFIRM_STATISTICS_RESET:
            return loading(updateStatistics(setError(state,""), { venueName: "", securityFirmName: "" }), false);
        case USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST:
            return loading(updateStatistics(setError(state,""), {}), true);
        case USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST:
            return state;
        case USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_SUCCESS:
            return loading(updateStatistics(setError(state,""), action.statistics), false);
        case USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case NOTIFICATIONS_REQUEST_SUCCESS:
            return updateNotificationCount(state, action.groupedNotifications);
    }
    return state;
}