import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import BroadcastNotificationCard from './BroadcastNotificationCard';
import BroadcastNotificationHeader from './BroadcastNotificationHeader';
import BroadcastNotificationRow from './BroadcastNotificationRow';
import BroadcastNotificationContainer from '../../../containers/BroadcastNotificationContainer';
import BroadcastNotificationLegend from './BroadcastNotificationLegend';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IBroadcastNotificationsProps {
    isLoading: boolean;
    venueId: number;
    securityFirmId: number;
    broadcastNotifications: AusComplyDtos.BroadcastNotification[];
    broadcastNotificationId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.BroadcastNotificationFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onTest: Function;
    history: any;
    location: any;
}

export default class BroadcastNotifications extends Component<IBroadcastNotificationsProps, any> {
    constructor(props: IBroadcastNotificationsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            contactToRemove: {}
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.BroadcastNotificationFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
        } else {
            preparedFilter.forVenueId = 0;
        }
        if (this.props.securityFirmId && this.props.securityFirmId > 0) {
            preparedFilter.forSecurityFirmId = this.props.securityFirmId;
        } else {
            preparedFilter.forSecurityFirmId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, broadcastNotification: AusComplyDtos.BroadcastNotification) {
        switch (command) {
            case "add":
                var url = this.props.location.pathname + "/0";
                this.props.history.push(url);
                //this.props.onCreate(this.props.venueId, this.props.securityFirmId);
                break;
            case "view":
            case "edit":
                var url = this.props.location.pathname + "/" + broadcastNotification.broadcastNotificationId;
                this.props.history.push(url);
                //this.props.onLoadItem(broadcastNotification.broadcastNotificationId);
                break;
            case "clone":
                var url = this.props.location.pathname + "/-" + broadcastNotification.broadcastNotificationId;
                this.props.history.push(url);
                break;
            case "send":
                var updated = { ...broadcastNotification };
                updated.isSent = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "resend":
                var updated = { ...broadcastNotification };
                updated.resend = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...broadcastNotification };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    contactToRemove: updated
                });
                break;
            case "test":
                if (this.props.onTest) {
                    this.props.onTest(broadcastNotification.broadcastNotificationId)
                }
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let broadcastNotification = { ...this.state.contactToRemove };
            this.props.onUpdate(broadcastNotification);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                contactToRemove: {}
            });
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.broadcastNotifications && this.props.broadcastNotifications.length > 0) {
            cardList = this.props.broadcastNotifications.map((item, index) => {
                return (
                    <BroadcastNotificationCard
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"card-" + item.broadcastNotificationId.toString()}
                        broadcastNotification={item}
                    />
                );
            });
            rows = this.props.broadcastNotifications.map((item, index) => {
                return (
                    <BroadcastNotificationRow
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"row-" + item.broadcastNotificationId.toString()}
                        broadcastNotification={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<BroadcastNotificationHeader />}
                    rows={rows}
                />
            );
        }

        let broadcastNotification;
        if (this.props.broadcastNotificationId != -1) {
            broadcastNotification = (
                <BroadcastNotificationContainer />
            );
        }

        let create;
        if (this.props.canEdit) {
            create = (
                <TransparentButton onClick={() => this.onCommand("add", new AusComplyDtos.BroadcastNotification())} text={"Add Broadcast"} />
            );
        }

        return (<PageLayout
            headerText={"Broadcast Notifications"}
            footerLeftContent={create}
        >
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                footer={<BroadcastNotificationLegend />}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <FullPageDialog
                open={this.props.broadcastNotificationId != -1}
                title="Broadcast"
                notFullWidth={true}
                onDismissed={() => this.props.onResetItem()}>
                {broadcastNotification}
            </FullPageDialog>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this broadcast"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </PageLayout>);
    }
}