import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import ControlGroup from "./ControlGroup";
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NumericUpDown from './NumericUpDown';

export interface INumericUpDownControlGroupProps {
    theme: any;
    text?: string;
    error?: string;
    value: number;
    readonly?: boolean;
    onChange?: Function;
    labelAbove?: boolean;
    editable?: boolean;
}
class NumericUpDownControlGroup extends React.PureComponent<INumericUpDownControlGroupProps, any> {

    constructor(props: INumericUpDownControlGroupProps) {
        super(props)
    }

    render() {
        const { theme } = this.props;
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <NumericUpDown
                        value={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.readonly}
                        editable={this.props.editable}
                    />
                    
                    {this.props.error && (
                        <FormHelperText style={{color: "#d2242b"}}>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(NumericUpDownControlGroup);