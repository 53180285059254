import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';
import Map from '../../../common/Map'

export interface IUserActionAuditProps {
    userActionAudit: AusComplyDtos.UserActionAudit;
}

export default class UserActionAudit extends Component<IUserActionAuditProps, any> {
    constructor(props: IUserActionAuditProps) {
        super(props)

        this.state = {
        };
    }

    render() {

        return (
            <>
                <ControlGroup text="Date (utc)">
                    <TextDisplay>{this.props.userActionAudit.dateEnteredUtcDisplay}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Date (Sydney Server)">
                    <TextDisplay>{this.props.userActionAudit.dateEnteredDisplay}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Action">
                    <TextDisplay>{this.props.userActionAudit.actionTypeDisplay}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Operating System">
                    <TextDisplay>{this.props.userActionAudit.os}</TextDisplay>
                </ControlGroup>
                {this.props.userActionAudit.model && (
                    <ControlGroup text="Model">
                        <TextDisplay>{this.props.userActionAudit.model}</TextDisplay>
                    </ControlGroup>
                )}
                {this.props.userActionAudit.make && (
                    <ControlGroup text="Make">
                        <TextDisplay>{this.props.userActionAudit.make}</TextDisplay>
                    </ControlGroup>
                )}
                {this.props.userActionAudit.version && (
                    <ControlGroup text="Version">
                        <TextDisplay>{this.props.userActionAudit.version}</TextDisplay>
                    </ControlGroup>
                )}
                <ControlGroup text="Device Id">
                    <TextDisplay>{this.props.userActionAudit.device}</TextDisplay>
                </ControlGroup>
                {this.props.userActionAudit.appVersion && (
                    <ControlGroup text="Application Version">
                        <TextDisplay>{this.props.userActionAudit.appVersion}</TextDisplay>
                    </ControlGroup>
                )}
                {this.props.userActionAudit.locationFound && (
                    <ControlGroup text="Location">
                        <Map hideByDefault={true} latitude={this.props.userActionAudit.latitude} longitude={this.props.userActionAudit.longitude} />
                    </ControlGroup>
                )}
            </>
        );
    }
}