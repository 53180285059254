import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';
import SubTitle from '../common/SubTitle';
import TextControlGroup from '../controls/TextControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import SelectControl from '../controls/SelectControl';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import ControlGroup from '../controls/ControlGroup';
import CheckboxControl from '../controls/CheckboxControl';
import ControlText from '../controls/ControlText';
import TextControl from '../controls/TextControl';
import ScheduleIncidentCategoryTypes from './ScheduleIncidentCategoryTypes';
import ScheduleVenueLocations from './ScheduleVenueLocations';
import ScheduleIncidentFlags from './ScheduleIncidentFlags';
import ScheduleUserRoleTypes from './ScheduleUserRoleTypes';
import HourMinuteControlGroup from '../controls/HourMinuteControlGroup';
import TimeZoneDateControlGroup from '../controls/TimeZoneDateControlGroup';
import RadioControlGroup from '../controls/RadioControlGroup';
import RadioVerticalControl from '../controls/RadioVerticalControl';
import { RadioOption } from '../../common/dto/common';

export interface ISchedulePatternProps {
    schedule: AusComplyDtos.Schedule;
    onUpdate: Function;
}

interface ISchedulePatternState {
}

export default class SchedulePattern extends Component<ISchedulePatternProps, ISchedulePatternState> {
    constructor(props: ISchedulePatternProps) {
        super(props)
        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onPatternChanged = this.onPatternChanged.bind(this);
        this.onMonthPatternChanged = this.onMonthPatternChanged.bind(this);
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    onValueChanged(fieldName: string, value: any) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    onTimeChanged(value: AusComplyDtos.LocalDate) {
        let schedule = { ...this.props.schedule };
        if (value.isEmpty) {
            schedule.hour = 9;
            schedule.minute = 0;

        } else {
            schedule.hour = value.hour;
            schedule.minute = value.minute;
        }
        this.props.onUpdate(schedule)
    }

    onUntilTimeChanged(value: AusComplyDtos.LocalDate) {
        let schedule = { ...this.props.schedule };
        if (value.isEmpty) {
            schedule.untilHour = 9;
            schedule.untilMinute = 0;

        } else {
            schedule.untilHour = value.hour;
            schedule.untilMinute = value.minute;
        }
        this.props.onUpdate(schedule)
    }

    onPatternChanged(value){
        if(value && value.value){
            const fieldValue = value.value;
            if(fieldValue == 'WEEK') {
                this.onValueChanged("schedulePattern", AusComplyDtos.ngtSchedulePattern.Weekly);
            } else if(fieldValue == 'MONTH') {
                this.onValueChanged("schedulePattern", AusComplyDtos.ngtSchedulePattern.Monthly);
            } else if(fieldValue == 'YEAR') {
                this.onValueChanged("schedulePattern", AusComplyDtos.ngtSchedulePattern.Yearly);
            }
        }
    }

    onMonthPatternChanged(value) {
        if(value && value.value){
            const fieldValue = value.value;
            if(fieldValue == 'DAY') {
                this.onValueChanged("monthlySchedule", AusComplyDtos.ngtMonthlySchedule.Day);
            } else if(fieldValue == 'NTH') {
                this.onValueChanged("monthlySchedule", AusComplyDtos.ngtMonthlySchedule.DayOccuranceInMonth);
            } 
        }
    }

    render() {
        if (!this.props.schedule) return <></>;

        let content;
        let selectedPattern = "WEEK";
        const patternOptions: RadioOption[] = [
            { id: "WEEK", name: "Weekly" },
            { id: "MONTH", name: "Monthly" },
        ];

        if (this.props.schedule) {
            if (this.props.schedule.schedulePattern == AusComplyDtos.ngtSchedulePattern.Weekly) {
                selectedPattern = "WEEK";
                content = <>
                    <ControlGroup text='Every'>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={0} p={0}>
                                <TextControl numericOnly={true}
                                    value={(this.props.schedule.weeklyWeeks || "0").toString()}
                                    onChange={(value) => this.onValueChanged("weeklyWeeks", Number(value))}
                                />
                            </Box>
                            <Box flexGrow={1} p={0}>
                                <ControlText text='Weeks' />
                            </Box>
                        </Box>
                        <CheckboxControl text='Monday' defaultValue={this.props.schedule.weeklyMonday} onChanged={(value) => this.onValueChanged("weeklyMonday", value)} />
                        <CheckboxControl text='Tuesday' defaultValue={this.props.schedule.weeklyTuesday} onChanged={(value) => this.onValueChanged("weeklyTuesday", value)} />
                        <CheckboxControl text='Wednesday' defaultValue={this.props.schedule.weeklyWednesday} onChanged={(value) => this.onValueChanged("weeklyWednesday", value)} />
                        <CheckboxControl text='Thursday' defaultValue={this.props.schedule.weeklyThursday} onChanged={(value) => this.onValueChanged("weeklyThursday", value)} />
                        <CheckboxControl text='Friday' defaultValue={this.props.schedule.weeklyFriday} onChanged={(value) => this.onValueChanged("weeklyFriday", value)} />
                        <CheckboxControl text='Saturday' defaultValue={this.props.schedule.weeklySaturday} onChanged={(value) => this.onValueChanged("weeklySaturday", value)} />
                        <CheckboxControl text='Sunday' defaultValue={this.props.schedule.weeklySunday} onChanged={(value) => this.onValueChanged("weeklySunday", value)} />
                    </ControlGroup>                
                </>
            }
            if (this.props.schedule.schedulePattern == AusComplyDtos.ngtSchedulePattern.Monthly) {
                selectedPattern = "MONTH";
                const key = "month-pattern" + this.props.schedule.monthlySchedule + "-" + this.props.schedule.monthlyDayOccurance + "-" + 
                    this.props.schedule.monthlyDayOccuranceWeekday + "-" + this.props.schedule.monthlyDayEveryMonths.toString();
                const selectedMonthlyPattern: string = this.props.schedule.monthlySchedule == AusComplyDtos.ngtMonthlySchedule.DayOccuranceInMonth ? "NTH" : "DAY";
                const patternMonthOptions: RadioOption[] = [
                    { id: "DAY", name: "Day", content: <Box p={0} display="flex" flexDirection={'row'}>
                    <Box flexGrow={1} p={0}>
                        <TextControl numericOnly={true}
                            value={(this.props.schedule.monthlyDay || "0").toString()}
                            readonly={selectedMonthlyPattern != "DAY"}
                            onChange={(value) => this.onValueChanged("monthlyDay", value)} />
                    </Box>
                    <Box flexGrow={0} p={0} style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <ControlText text='of every' />
                    </Box>
                    <Box flexGrow={1} p={0}>
                        <TextControl numericOnly={true}
                            value={(this.props.schedule.monthlyDayEveryMonths || "0").toString()}
                            readonly={selectedMonthlyPattern != "DAY"}
                            onChange={(value) => this.onValueChanged("monthlyDayEveryMonths", value)} />
                    </Box>
                    <Box flexGrow={0} p={0} style={{paddingLeft: "20px"}}>
                        <ControlText text='month(s)' />
                    </Box>
                </Box> },
                    { id: "NTH", name: "The", content: <Box p={0} display="flex" flexDirection={'row'}>
                    <Box flexGrow={1} p={0}>
                        <SelectControl
                            onChange={(value) => this.onValueChanged("monthlyDayOccurance", value)}
                            readonly={selectedMonthlyPattern != "NTH"}
                            defaultValue={this.props.schedule.monthlyDayOccurance}>
                            <option key={AusComplyDtos.ngtMonthlyDayOccurance.First} value={AusComplyDtos.ngtMonthlyDayOccurance.First}>First</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccurance.Second} value={AusComplyDtos.ngtMonthlyDayOccurance.Second}>Second</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccurance.Third} value={AusComplyDtos.ngtMonthlyDayOccurance.Third}>Third</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccurance.Fourth} value={AusComplyDtos.ngtMonthlyDayOccurance.Fourth}>Fourth</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccurance.Last} value={AusComplyDtos.ngtMonthlyDayOccurance.Last}>Last</option>
                        </SelectControl>
                    </Box>
                    <Box flexGrow={1} p={0} style={{paddingLeft: "20px "}}>
                    <SelectControl
                            onChange={(value) => this.onValueChanged("monthlyDayOccuranceWeekday", value)}
                            readonly={selectedMonthlyPattern != "NTH"}
                            defaultValue={this.props.schedule.monthlyDayOccuranceWeekday}>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Monday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Monday}>Monday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Tuesday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Tuesday}>Tuesday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Wednesday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Wednesday}>Wednesday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Thursday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Thursday}>Thursday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Friday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Friday}>Friday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Saturday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Saturday}>Saturday</option>
                            <option key={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Sunday} value={AusComplyDtos.ngtMonthlyDayOccuranceWeekday.Sunday}>Sunday</option>
                        </SelectControl>
                    </Box>
                    <Box flexGrow={0} p={0} style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <ControlText text='of every' />
                    </Box>
                    <Box flexGrow={1} p={0}>
                        <TextControl numericOnly={true}
                            value={(this.props.schedule.monthlyDayEveryMonths || "0").toString()}
                            readonly={selectedMonthlyPattern != "NTH"}
                            onChange={(value) => this.onValueChanged("monthlyDayEveryMonths", value)} />
                    </Box>
                    <Box flexGrow={0} p={0} style={{paddingLeft: "20px"}}>
                        <ControlText text='month(s)' />
                    </Box>
                </Box> },
                ];
                content=<>
                    <ControlGroup text=" " key={key}>
                        <RadioVerticalControl 
                            name="monthlySchedule"
                            selected={selectedMonthlyPattern}
                            options={patternMonthOptions}
                            onChange={this.onMonthPatternChanged}
                        />
                    </ControlGroup>
                </>
            }
            if (this.props.schedule.schedulePattern == AusComplyDtos.ngtSchedulePattern.Yearly) {
                selectedPattern = "YEAR";
            }
        }

        return <>
            <SubTitle text='Pattern' />
            <RadioControlGroup
                        selected={selectedPattern}
                        text={"Recurrence"}
                        name="selectedPattern"
                        options={patternOptions}
                        onChanged={this.onPatternChanged} />
            {content}
        </>;
    }
}