import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionFeed from '../components/facialRecognition/facialRecognitionFeed/FacialRecognitionFeed';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { 
    facialRecognitionFeedRequest,
    facialRecognitionFeedSetAutorefresh
 } from '../common/actions/facialRecognitionFeed';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        venueLocationId: props.venueLocationId,
        isLoading: state.facialRecognitionFeed.isLoading,
        feed: state.facialRecognitionFeed.feed,
        onSelected: props.onSelected,
        autoRefresh: state.facialRecognitionFeed.autoRefresh
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoad: (forVenueId, venueLocationId, limit) => {
            dispatch(facialRecognitionFeedRequest(forVenueId, venueLocationId, limit))
        },
        onSetAutoRefresh: (value) => {
            dispatch(facialRecognitionFeedSetAutorefresh(value))
        }
    }
}

const FacialRecognitionFeedContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionFeed))

export default FacialRecognitionFeedContainer