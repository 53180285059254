import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IFacialRecognitionCameraWatchlistsProps {
    facialRecognitionCamera: AusComplyDtos.FacialRecognitionCamera;
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[];
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IFacialRecognitionCameraWatchlistsState {
    watchlists: MulitSelectOption[];
}

class FacialRecognitionCameraWatchlists extends Component<IFacialRecognitionCameraWatchlistsProps, IFacialRecognitionCameraWatchlistsState> {
    constructor(props: IFacialRecognitionCameraWatchlistsProps) {
        super(props)

        this.state = {
            watchlists: []
        };
        this.onSelectedChanged = this.onSelectedChanged.bind(this);
    }

    componentDidMount() {
        this.loadWatchlists();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.facialRecognitionWatchlists || {}) != JSON.stringify(prevProps.facialRecognitionWatchlists || {}) ||
            JSON.stringify(this.props.facialRecognitionCamera || {}) != JSON.stringify(prevProps.facialRecognitionCamera || {})) {
            this.loadWatchlists();
        }
    }

    loadWatchlists() {
        let watchlists: MulitSelectOption[] = [];
        if (this.props.facialRecognitionWatchlists) {
            this.props.facialRecognitionWatchlists.forEach((watchlist, index) => {
                let isSelected = false;
                if (this.props.facialRecognitionCamera.facialRecognitionCameraWatchlists) {
                    isSelected = this.props.facialRecognitionCamera.facialRecognitionCameraWatchlists
                        .filter(f => f.facialRecognitionWatchlistId == watchlist.facialRecognitionWatchlistId && !f.obsolete).length > 0;
                }
                var info = "";
                if (watchlist.venueId === 0 && watchlist.groupId === 0) {
                    info = "Global";
                }
                if (watchlist.venueId === 0 && watchlist.groupId > 0) {
                    info = "Group";
                }
                if (isSelected || !watchlist.obsolete) {
                    watchlists.push({
                        id: watchlist.facialRecognitionWatchlistId,
                        name: watchlist.displayName,
                        isCommon: false,
                        selected: isSelected,
                        info
                    });
                }
            });
        }
        // add any missing items
        if (this.props.facialRecognitionCamera.facialRecognitionCameraWatchlists) {
            this.props.facialRecognitionCamera.facialRecognitionCameraWatchlists.forEach((cw, index) => {
                if (watchlists.filter(w => w.id == cw.facialRecognitionWatchlistId).length == 0) {
                    watchlists.push({
                        id: cw.facialRecognitionWatchlistId,
                        name: cw.facialRecognitionWatchlist.displayName,
                        isCommon: false,
                        selected: true
                    });
                }
            });
        }

        this.setState({
            watchlists
        }, () => {
        });
    }

    onSelectedChanged(items) {
        let facialRecognitionCamera = { ...this.props.facialRecognitionCamera };
        facialRecognitionCamera.facialRecognitionCameraWatchlists = [];
        items.forEach(item => {
            var watchlist = new AusComplyDtos.FacialRecognitionCameraWatchlist();
            watchlist.facialRecognitionWatchlistId = item.id;
            facialRecognitionCamera.facialRecognitionCameraWatchlists.push(watchlist);
        });
        this.props.onUpdate(facialRecognitionCamera);
    }


    render() {

        let self = this;

        return (
            <ControlGroup text={" "}>
                <CardContainer
                    title={"Watchlists"}
                    style={{ paddingTop: 0 }}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <MultiSelectControl
                                    label={""}
                                    items={this.state.watchlists}
                                    searchText="Type at least three letters..."
                                    minimumCharactersRequired={3}
                                    onSelectItemsChanged={this.onSelectedChanged}
                                    canShowAll={true} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionCameraWatchlists);