export const
    NOTIFICATION_SHOW = "NOTIFICATION_SHOW",
    NOTIFICATION_HIDE = "NOTIFICATION_HIDE";

export function notificationShow(message) {
    //debugger;
    return {
        type: NOTIFICATION_SHOW,
        message,
        messageType: "error"
    };
}

export function notificationSuccessShow(message) {
    //debugger;
    return {
        type: NOTIFICATION_SHOW,
        message,
        messageType: "success"
    };
}

export function notificationHide() {
    return {
        type: NOTIFICATION_HIDE
    };
}
