import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IRegisterUserVenuesProps {
    venueIds: number[];
    venues: AusComplyDTOs.VenueSelection[],
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

interface IRegisterUserVenuesState {
    items: MulitSelectOption[];
}

export default class RegisterUserVenues extends Component<IRegisterUserVenuesProps, IRegisterUserVenuesState> {
    constructor(props: IRegisterUserVenuesProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
    }

    componentDidMount() {
        let items: MulitSelectOption[] = [];
        if (this.props.venues) {
            this.props.venues.map((item, index) => {
                let isSelected =  this.props.venueIds && this.props.venueIds.indexOf(item.venueId) > -1;
                items.push({
                    id: item.venueId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items: items
        });
    }

    onSelectItemsChanged(items) {
        let currentIds = items.map(item => item.id);
        this.props.onUpdate(currentIds);
    }

    render() {
        
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Select venue(s)"}>
                    <CardContent style={{paddingTop: 0}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                    <MultiSelectControl
                                            items={this.state.items}
                                            label={""}
                                            clearSearchOnSelect={true}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectItemsChanged}
                                            canShowAll={false} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}