import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IPasswordControlProps {
    theme: any;
    classes: any;
    value?: string;
    error?: boolean;
    placeholder?: string;
    readonly?: boolean;
    onChange?: Function;
    onSubmit?: Function;
    center?: boolean;
    id?: string;
    autocomplete?: string;
}
class PasswordControl extends React.PureComponent<IPasswordControlProps, any> {

    constructor(props: IPasswordControlProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: ""
        }
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value })
        }
    }

    onKeyPress = event => {
      if (event.key === 'Enter') {
        if (this.props.onSubmit){
            this.props.onSubmit();
            event.preventDefault();
        }
      }
    }

    onChange(event) {
        if (event != null) {
            let val = event.target.value;
            this.setState({ value: val }, () => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            });
        }
    }

    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        let inputClasses;
        if (this.props.center) {
            inputClasses={root:classes.inputCenter, input: classes.inputCenter};
        } else {
            inputClasses={root: classes.passwordField, input: classes.inputRoot};
        }
        return (
            <InputBase
                //inputProps={{textContentType="oneTimeCode"}}
                autoComplete='new-password'
                role='presentation'
                value={this.state.value || ''}
                style={{...style}}
                type="password"
                classes={inputClasses}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                disabled={this.props.readonly}
                id={this.props.id}
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(PasswordControl);