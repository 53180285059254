import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { signOnRegisterTypeServiceApi } from '../services/signOnRegisterTypeService';
import * as signOnRegisterTypeActions from '../actions/signOnRegisterType';
import {
    notifyError
} from './common';

const signOnRegisterTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        signOnRegisterTypeServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => signOnRegisterTypeActions.signOnRegisterTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "signOnRegisterTypesRequestEpic.find", signOnRegisterTypeActions.signOnRegisterTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterTypesRequestEpic", signOnRegisterTypeActions.signOnRegisterTypesRequestFailure))
    );


const signOnRegisterTypesRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnRegisterTypeServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.signOnRegisterTypes.signOnRegisterTypes.paging.page,
                state.signOnRegisterTypes.signOnRegisterTypes.paging.pageSize,
                state.signOnRegisterTypes.signOnRegisterTypes.filter)
                .pipe(
                    map(response => signOnRegisterTypeActions.signOnRegisterTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "signOnRegisterTypesRefreshEpic.find", signOnRegisterTypeActions.signOnRegisterTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterTypesRefreshEpic", signOnRegisterTypeActions.signOnRegisterTypesRequestFailure))
    );

const signOnRegisterTypeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnRegisterTypeServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['signOnRegisterTypeId'])
                .pipe(
                    map(response => signOnRegisterTypeActions.signOnRegisterTypeRequestSuccess(response.signOnRegisterType)),
                    catchError(error => notifyError(error, "signOnRegisterTypeRequestEpic.get", signOnRegisterTypeActions.signOnRegisterTypeRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterTypeRequestEpic", signOnRegisterTypeActions.signOnRegisterTypeRequestFailure))
    );

const signOnRegisterTypeCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnRegisterTypeServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forGroupId'],
                action['forVenueId'])
                .pipe(
                    map(response => signOnRegisterTypeActions.signOnRegisterTypeCreateRequestSuccess(response.signOnRegisterType)),
                    catchError(error => notifyError(error, "signOnRegisterTypeCreateEpic.create", signOnRegisterTypeActions.signOnRegisterTypeCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterTypeCreateEpic", signOnRegisterTypeActions.signOnRegisterTypeCreateRequestFailure))
    );

const signOnRegisterTypeSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnRegisterTypeServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.signOnRegisterTypes.signOnRegisterType.signOnRegisterType)
                .pipe(
                    map(response => signOnRegisterTypeActions.signOnRegisterTypeSaveRequestSuccess(response.signOnRegisterType)),
                    catchError(error => notifyError(error, "signOnRegisterTypeUpsertEpic.upsert", signOnRegisterTypeActions.signOnRegisterTypeSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterTypeUpsertEpic", signOnRegisterTypeActions.signOnRegisterTypeSaveRequestFailure))
    );

const signOnRegisterTypeSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS),
        map((action: any) => signOnRegisterTypeActions.signOnRegisterTypeReset())
    );

const signOnRegisterTypeSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS),
        map((action: any) => signOnRegisterTypeActions.signOnRegisterTypesRefresh())
    );

export const signOnRegisterTypeEpics = combineEpics(
    signOnRegisterTypesRefreshEpic,
    signOnRegisterTypesRequestEpic,
    signOnRegisterTypeRequestEpic,
    signOnRegisterTypeCreateEpic,
    signOnRegisterTypeSaveEpic,
    signOnRegisterTypeSaveSuccessEpic,
    signOnRegisterTypeSaveSuccessRefreshEpic
);