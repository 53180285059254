import { connect } from 'react-redux'
import { withRouter } from "react-router";
import IncidentViewPOIs from '../components/incidents/view/IncidentViewPOIs'
import {
    facialRecognitionPoisIncidentsRequest
} from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        poiGuids: props.poiGuids,
        isLoading: state.facialRecognitionPOI.pois.isLoading,
        pois: state.facialRecognitionPOI.pois.poiIncidents,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad:(poiGuids) => {
            dispatch(facialRecognitionPoisIncidentsRequest(poiGuids))
        }
    }
}

const IncidentViewPOIsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentViewPOIs))

export default IncidentViewPOIsContainer