import { connect } from 'react-redux'
import LoginResetPasswordCode from '../components/login/LoginResetPasswordCode'
import { userLoginResetPasswordWithCodeRequest } from '../common/actions/userLogin'

const mapStateToProps = (state, props) => {
    return {
        resetMobile: state.user.resetMobile,
        resetCode: state.user.resetCode,
        isCodeValid: state.user.isCodeValid,
        isSaving: state.user.resetPasswordSaving,
        error: state.user.resetPasswordError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (mobile, code, newPassword, newPasswordConfirm) => {
            dispatch(userLoginResetPasswordWithCodeRequest(mobile, code, newPassword, newPasswordConfirm))
        }
    }
}

const LoginResetPasswordCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginResetPasswordCode)

export default LoginResetPasswordCodeContainer