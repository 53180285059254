import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ControlGroup from '../../controls/ControlGroup';
import NoRowsMessage from '../../common/NoRowsMessage';

export interface IUserComplianceDocumentsProps {
    documents: AusComplyDtos.UserComplianceDocument[];
    classes: any;
    theme: any;
}

interface IUserComplianceDocumentsState {
    selectedFilePath: string;
}

class UserComplianceDocuments extends React.Component<IUserComplianceDocumentsProps, IUserComplianceDocumentsState> {
    constructor(props: IUserComplianceDocumentsProps) {
        super(props)
        let expiryDate = new AusComplyDtos.SimpleDate();
        expiryDate.isEmpty = true;
        this.state = {
            selectedFilePath: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render() {
        const { classes, theme } = this.props;

        let documents;
        let hasDocuments = false;
        if (this.props.documents) {
            documents = this.props.documents.filter(item => !item.obsolete).map((item, index) => {
                hasDocuments = true;
                let borderColor = item.hasExpired ? theme.custom.colors.error : "#151515";
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"file-poi-item-" + item.originalFileName + "_" + index.toString()}>
                        <div key={"file-poi-" + item.originalFileName + "_" + index.toString()}
                            style={{
                                backgroundColor: '#151515',
                                borderRadius: '5px',
                                borderColor: borderColor,
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                margin: '0 10px 10px 0',
                                padding: '10px',
                                minWidth: '180px',
                                overflow: 'hidden'
                            }}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + item.originalFileName + "_" + index.toString()}
                                        previewPath={item.filePreviewPath}
                                        displayPath={item.fileDisplayPath}
                                        isImage={item.isImage}
                                        text={""}
                                        onPreview={path => this.onSelectFilePath(path)}
                                        onDownload={path => this.onDownload(path)}
                                    />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                    <p style={{ borderRadius: '3px', backgroundColor: borderColor, display: 'inline-block', padding: '3px', margin: '3px 0' }}>{item.dateOfExpiry.display}</p>
                                    <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                )
            });
        }

        return (
            <>
                {!hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <NoRowsMessage message={"No compliance documents"} />
                    </ControlGroup>
                )}
                {hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <Grid container spacing={1}>
                            {documents}
                        </Grid>
                    </ControlGroup>
                )}
                <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserComplianceDocuments);