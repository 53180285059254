import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { venueSecurityFirmLogic } from '../../../common/logic/venueSecurityFirmLogic';

export interface ISecurityFirmAssociationCardProps {
    venueSecurityFirmSummary: AusComplyDtos.VenueSecurityFirmSummary;
    canRemove: boolean;
    onCommand?: Function;
}

export default class SecurityFirmAssociationCard extends React.Component<ISecurityFirmAssociationCardProps, any> {
    constructor(props: ISecurityFirmAssociationCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueSecurityFirmSummary);
        }
    }

    render() {
        if (!this.props.venueSecurityFirmSummary) {
            return (<></>);
        }

        var commands = venueSecurityFirmLogic.getCommands(this.props.venueSecurityFirmSummary, this.props.canRemove);
        var item = this.props.venueSecurityFirmSummary;

        return (
            <CardRow 
                commands={commands} 
                onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Venue</CardTitle>
                        <CardTypography>{item.venue}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.securityFirm}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Master Licence</CardTitle>
                        <CardTypography>{item.masterLicenceNumber}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Suburb</CardTitle>
                        <CardTypography>{item.suburb}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>State</CardTitle>
                        <CardTypography>{item.stateDisplayName}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Postcode</CardTitle>
                        <CardTypography>{item.postcode}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Phone</CardTitle>
                        <CardTypography>{item.phone}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Manager(s)</CardTitle>
                        <CardTypography>{item.managerName}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Website</CardTitle>
                        <CardTypography>{item.website}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Created</CardTitle>
                        <CardTypography>{item.dateEnteredDisplay}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Modified</CardTitle>
                        <CardTypography>{item.dateModifiedDisplay}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}