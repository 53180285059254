import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SecurityFirmContacts from '../components/securityFirm/securityFirmContacts/SecurityFirmContacts'
import { 
    securityFirmContactsRequest, 
    securityFirmContactCreateRequest, 
    securityFirmContactRequest, 
    securityFirmContactReset,
    securityFirmContactSet,
    securityFirmContactUpsertRequest
 } from '../common/actions/securityFirm';

const mapStateToProps = (state, props) => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        paging: state.securityFirm.securityFirmContacts.paging,
        filter: state.securityFirm.securityFirmContacts.filter,
        isLoading: state.securityFirm.securityFirmContacts.isLoading,
        securityFirmContacts: state.securityFirm.securityFirmContacts.securityFirmContacts,
        securityFirmContactId: state.securityFirm.securityFirmContact.securityFirmContactId,
        canEdit: permissionsLogic.hasPermissionForState(state, "SecurityFirmContactEdit")
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(securityFirmContactsRequest(page, pageSize, filter))
        },
        onLoadItem: (securityFirmContactId) => {
            dispatch(securityFirmContactRequest(securityFirmContactId))
        },
        onCreate: (securityFirmId) => {
            dispatch(securityFirmContactCreateRequest(securityFirmId))
        },
        onResetItem: () => {
            dispatch(securityFirmContactReset())
        },
        onUpdate: (securityFirmContact) => {
            dispatch(securityFirmContactSet(securityFirmContact))
        },
        onSave: () => {
            dispatch(securityFirmContactUpsertRequest())
        }
    }
}

const SecurityFirmContactsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmContacts))

export default SecurityFirmContactsContainer