import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueUtilities from '../components/admin/venue/VenueUtilities'
import * as adminActions from '../common/actions/admin'

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNotificationClear: (venueId, simpleDate) => {
            dispatch(adminActions.adminNotificationsVenueClearRequest(venueId, simpleDate))
        },
        onApproveIncidents: (venueId, simpleDate) => {
            dispatch(adminActions.adminVenueIncidentsClearRequest(venueId, simpleDate))
        }
    }
}

const AdminVenueUtilitiesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueUtilities))

export default AdminVenueUtilitiesContainer