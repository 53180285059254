import { connect } from 'react-redux'
import VenueSecurityFirmSummary from '../components/home/VenueSecurityFirmSummary'
import { userDetailsVenueSecurityFirmChange } from '../common/actions/userLogin'
import { venueSecurityFirmAvailableClearVenue } from '../common/actions/venueSecurityFirm'


const mapStateToProps = state => {
    return {
        venue: state.user.details.userSession.user.venue,
        securityFirm: state.user.details.userSession.user.securityFirm,
        venueEvent: state.user.details.userSession.user.venueEvent,
        showSecurityCode: state.user.details.showSecurityCode,
        securityCode: state.user.details.securityCode,
        currentReportingEndTimeFormatted: state.user.details.userSignOnSummary.currentReportingEndTimeFormatted
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onChangeVenue: () =>{
            dispatch(userDetailsVenueSecurityFirmChange())
        },
        onClearVenue: () => {
            dispatch(venueSecurityFirmAvailableClearVenue())
        }
    }
}

const VenueSecurityFirmSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueSecurityFirmSummary)

export default VenueSecurityFirmSummaryContainer