import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SecurityFirmVenues from '../components/securityFirm/securityFirmVenues/SecurityFirmVenues'
import { 
    securityFirmVenueAssociationsRequest
 } from '../common/actions/securityFirm';

const mapStateToProps = (state, props) => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        paging: state.securityFirm.securityFirmVenueAssociations.paging,
        isLoading: state.securityFirm.securityFirmVenueAssociations.isLoading,
        items: state.securityFirm.securityFirmVenueAssociations.securityFirmVenueAssociations,
        isLevelSix: permissionsLogic.isLevelSix(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (securityFirmId, pageSize, page) => {
            dispatch(securityFirmVenueAssociationsRequest(securityFirmId, page, pageSize))
        }
    }
}

const SecurityFirmVenuesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmVenues))

export default SecurityFirmVenuesContainer