import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../../controls/PrimaryButton';
import ErrorButton from '../../controls/ErrorButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import SwipeContainer from '../../layout/SwipeContainer';

export interface IIDScanActionProps {
    action: string;
    onChange: Function;
}

export default class IDScanAction extends Component<IIDScanActionProps, any> {
    constructor(props: IIDScanActionProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    onChange(action) {
        this.props.onChange(action);
    }

    render() {
        return (
            <CardContainer
                title={"Action"}>
                <CardContent>
                    <Grid container spacing={1}>
                        {(this.props.action == "ALLOW" ) && (
                            <Grid item xs={4}>
                                <PrimaryButton text={"Allow Entry"} onClick={() => this.onChange("ALLOW")} />
                            </Grid>
                        )}
                        {(this.props.action != "ALLOW") && (
                            <Grid item xs={4}>
                                <TransparentButton text={"Allow Entry"} onClick={() => this.onChange("ALLOW")} />
                            </Grid>
                        )}
                        {this.props.action == "UNDERAGE" && (
                            <Grid item xs={4}>
                                <ErrorButton text={"Refuse Underage"} onClick={() => this.onChange("UNDERAGE")} />
                            </Grid>
                        )}
                        {this.props.action != "UNDERAGE" && (
                            <Grid item xs={4}>
                                <TransparentButton text={"Refuse Underage"} onClick={() => this.onChange("UNDERAGE")} />
                            </Grid>
                        )}
                        {this.props.action == "REFUSE" && (
                            <Grid item xs={4}>
                                <ErrorButton text={"Refuse Entry"} onClick={() => this.onChange("REFUSE")} />
                            </Grid>
                        )}
                        {this.props.action != "REFUSE" && (
                            <Grid item xs={4}>
                                <TransparentButton text={"Refuse Entry"} onClick={() => this.onChange("REFUSE")} />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </CardContainer>
        );
    }
}