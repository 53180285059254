import React, { Component } from 'react';
import PageTitle from '../../common/PageTitle';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueReportingPeriodCurrentContainer from '../../../containers/VenueReportingPeriodCurrentContainer';
import VenueReportingPeriodScheduleContainer from '../../../containers/VenueReportingPeriodScheduleContainer';
import VenueReportingPeriodSchedulesContainer from '../../../containers/VenueReportingPeriodSchedulesContainer';
import VenueReportingPeriodContainer from '../../../containers/VenueReportingPeriodContainer';
import SecondaryFab from '../../common/SecondaryFab';
import RefreshIcon from '@material-ui/icons/Refresh';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IVenueReportingPeriodsProps {
    isLoading: boolean;
    venueId: number;
    venueReportingPeriodId: number;
    venueReportingPeriodScheduleId: number;
    canCreateSchedule: boolean;
    onCreateSchedule: Function;
    onResetItem: Function;
    onResetScheduleItem: Function;
    onLoadCurrent: Function;
    onLoadSchedule: Function;
}

export default class VenueReportingPeriods extends Component<IVenueReportingPeriodsProps, any> {
    constructor(props: IVenueReportingPeriodsProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    onCommand(command: string, item: any) {
        switch (command) {
            default:
                break;
        }
    }

    onRefresh() {
        this.props.onLoadCurrent(this.props.venueId);
        this.props.onLoadSchedule(this.props.venueId);

    }

    render() {
        let venueReportingPeriod;
        if (this.props.venueReportingPeriodId != -1) {
            venueReportingPeriod = (
                <VenueReportingPeriodContainer />
            );
        }

        let venueReportingPeriodSchedule;
        if (this.props.venueReportingPeriodScheduleId != -1) {
            venueReportingPeriodSchedule = (
                <VenueReportingPeriodScheduleContainer />
            );
        }

        let create;
        if (this.props.canCreateSchedule) {
            create = (
                <TransparentButton
                    text={"Add Schedule"}
                    onClick={() => this.props.onCreateSchedule(this.props.venueId)} />
            );
        }

        return (
            <PageLayout
                headerText={"Reporting Periods"}
                loading={this.props.isLoading}
                footerLeftContent={create}
                footerRightContent={<TransparentButton text={"Refresh"}
                    onClick={this.onRefresh} />}
            >
                <VenueReportingPeriodCurrentContainer />

                <VenueReportingPeriodSchedulesContainer />

                <FullPageDialog
                    open={this.props.venueReportingPeriodId != -1}
                    notFullWidth={true}
                    title="Reporting Period"
                    onDismissed={() => this.props.onResetItem()}>
                    {venueReportingPeriod}
                </FullPageDialog>
                <FullPageDialog
                    notFullWidth={true}
                    open={this.props.venueReportingPeriodScheduleId != -1} title="Reporting Period Schedule" onDismissed={() => this.props.onResetScheduleItem()}>
                    {venueReportingPeriodSchedule}
                </FullPageDialog>
            </PageLayout>
        );
    }
}