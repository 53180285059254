import { connect } from 'react-redux'
import Notebook from '../components/notes/Notebook'

const mapStateToProps = state => {
    return {
        userId: state.user.details.userSession.userId,
        firstName:  state.user.details.userSession.user.firstName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const NotebookContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Notebook)

export default NotebookContainer