import React, { Component } from 'react';
import { Incident as IncidentDto, Paging as PagingDto, IncidentHistoryFilter } from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PagedList from '../common/PagedList';
import RadioControl from '../controls/RadioControl';
import RadioButtonControl from '../controls/RadioButtonControl';
import DefaultButton from '../controls/DefaultButton';
import PrimaryButton from '../controls/PrimaryButton';
import IncidentCard from './IncidentCard';
import { KeyName, RadioOption } from '../../common/dto/common';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../controls/SelectControlGroup';
import TextControlGroup from '../controls/TextControlGroup';
import SearchDateControlGroup from '../controls/SearchDateControlGroup';
import IncidentLegend from './IncidentLegend';
import CardContainer from '../common/CardContainer';
import IncidentHeader from './IncidentHeader';
import IncidentRow from './IncidentRow';
import TableContainer from '../layout/TableContainer';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import IncidentsPrintContainer from '../../containers/IncidentsPrintContainer';

export interface IIncidentFindProps {
    isLoading: boolean;
    excludeIncidentId: number;
    items: IncidentDto[];
    paging: PagingDto;
    filter: IncidentHistoryFilter;
    onRefresh: Function;
    onResetFilter: Function;
    onSelected: Function;
    history: any;
    classes: any;
    theme: any;
}

interface IIncidentFindState {
    filter: IncidentHistoryFilter;
}

class IncidentFind extends Component<IIncidentFindProps, IIncidentFindState> {
    constructor(props: IIncidentFindProps) {
        super(props)

        this.state = {
            filter: new IncidentHistoryFilter()
        };

        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onSelectedReportingPeriodChanged = this.onSelectedReportingPeriodChanged.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);
        this.onSelected = this.onSelected.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoading) {
            this.props.onResetFilter();
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.filter !== prevProps.filter) {
            this.setState({ filter: this.props.filter });
        }
    }

    onFromChanged(value) {
        let filter = { ...this.state.filter };
        filter.from = value;
        this.setState({ filter });
    }

    onToChanged(value) {
        let filter = { ...this.state.filter };
        filter.to = value;
        this.setState({ filter });
    }

    handlePageSelected(page) {
        let filter = { ...this.state.filter };
        filter.paging.page = page;
        this.props.onRefresh(filter);
    }

    onOptionChanged(value) {
        let filter = { ...this.props.filter };
        filter.byReportingPeriod = value.value === "1" || !filter.canFilterAdvanced;
        filter.paging.page = 1;
        this.setState({ filter });
        this.props.onRefresh(filter);
    }

    onSelectedReportingPeriodChanged(value: any) {
        let filter = { ...this.props.filter };
        filter.reportingPeriodId = parseInt(value.toString());
        filter.paging.page = 1;
        this.setState({ filter });
        this.props.onRefresh(filter);
    }

    onSearchChange(value) {
        let filter = { ...this.state.filter };
        filter.search = value;
        this.setState({ filter });
    }

    onSearch() {
        let filter = { ...this.state.filter };
        filter.paging.page = 1;
        this.props.onRefresh(filter);
    }

    onResetSearch() {
        this.props.onResetFilter();
    }

    onSelected(incident: IncidentDto) {
        if (this.props.onSelected && this.props.excludeIncidentId != incident.incidentId) {
            this.props.onSelected(incident);
        }
    }

    render() {
        let paging = this.props.paging;
        let cardList;
        let rowList;
        let rows;

        let incidentHistoryFilters;
        let filter = this.props.filter != null ? this.state.filter : null;
        const { theme } = this.props;

        if (filter != null) {
            paging = this.props.paging;
        }

        if (this.props.items && this.props.items.length > 0) {
            cardList = this.props.items.map((item, index) => {
                return (
                    <IncidentCard key={"incident-find-card-" + item.incidentId}
                        incident={item}
                        isCurrent={item.incidentId == this.props.excludeIncidentId}
                        canApprove={false}
                        canEdit={false}
                        canView={true}
                        showEvents={false}
                        onView={() => this.onSelected(item)}
                        onEdit={() => this.onSelected(item)}
                    />
                );
            });

            rows = this.props.items.map((item, index) => {
                return (
                    <IncidentRow
                        key={"incident-find-row-" + item.incidentId.toString()}
                        incident={item}
                        canApprove={false}
                        canEdit={false}
                        canView={true}
                        showEvents={false}
                        onView={() => this.onSelected(item)}
                        onEdit={() => this.onSelected(item)} />
                );
            });
        }

        let viewOptions: RadioOption[] = [
            { id: "1", name: "Reporting Period" },
            { id: "2", name: "History" }
        ];
        let viewOption = "1";

        if (filter && !filter.byReportingPeriod) {
            viewOption = "2";
        }

        if (filter) {

            let reportingPeriodSelector
            if (filter.reportingPeriods) {
                reportingPeriodSelector = (
                    <SelectControlGroup
                        text="Reporting period"
                        onChange={this.onSelectedReportingPeriodChanged}
                        defaultValue={filter.reportingPeriodId}>
                        {(!filter.reportingPeriodId || filter.reportingPeriodId == 0) && (
                            <option value={0} />
                        )}
                        {filter.reportingPeriods.map((item, index) => (
                            <option key={item.venueReportingPeriodID} value={item.venueReportingPeriodID}>{item.reportingPeriodDisplayString}</option>
                        ))}
                    </SelectControlGroup>
                );
            }

            incidentHistoryFilters = (
                <CardContainer style={{ marginTop: '10px', position: 'relative' }} title={"Search"}>
                    <CardContent>
                        <>
                            {filter.canFilterAdvanced && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={3}>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <RadioButtonControl
                                            selected={viewOption}
                                            options={viewOptions}
                                            name={"viewOptions"}
                                            onChange={this.onOptionChanged} />
                                    </Grid>
                                </Grid>
                            )}
                            {viewOption === "1" && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={3}>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        {reportingPeriodSelector}
                                    </Grid>
                                </Grid>
                            )}
                            {viewOption === "2" && (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <SearchDateControlGroup
                                                text="Time Period"
                                                rightText={"From"}
                                                date={filter.from}
                                                onEnter={this.onSearch}
                                                onChange={this.onFromChanged}
                                                defaultTime={"00:00"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <SearchDateControlGroup
                                                rightText="To"
                                                date={filter.to}
                                                onEnter={this.onSearch}
                                                onChange={this.onToChanged}
                                                defaultTime={"23:59"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <TextControlGroup text={"Search"}
                                                defaultValue={filter.search}
                                                onSubmit={this.onSearch}
                                                onChange={this.onSearchChange}
                                            ></TextControlGroup>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Grid container spacing={2} style={{ marginTop: '10px'}}>
                                                <Grid item xs={12} sm={6} lg={6}>
                                                    <DefaultButton
                                                        disabled={this.props.isLoading}
                                                        onClick={this.onResetSearch}
                                                    >Reset</DefaultButton>
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={6}>
                                                    <PrimaryButton
                                                        disabled={this.props.isLoading}
                                                        onClick={this.onSearch}
                                                    >Search</PrimaryButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </>
                    </CardContent>
                </CardContainer>
            );
        }

        rowList = (
            <TableContainer
                header={<IncidentHeader canApprove={false} canEdit={false} canView={true} showEvents={false} />}
                rows={rows}
            />
        );

        return (
            <>
                {!this.props.isLoading && (
                    <>{incidentHistoryFilters}</>
                )}
                <PagedList
                    loading={this.props.isLoading}
                    paging={paging}
                    cards={cardList}
                    rows={rowList}
                    footer={<IncidentLegend showCurrent={true} />}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <IncidentsPrintContainer />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentFind);