export const
    INCIDENT_FIND_RESET = "INCIDENT_FIND_RESET",
    INCIDENT_FIND_REQUEST = "INCIDENT_FIND_REQUEST",
    INCIDENT_FIND_REFRESH_REQUEST = "INCIDENT_FIND_REFRESH_REQUEST",
    INCIDENT_FIND_REQUEST_FAILURE = "INCIDENT_FIND_REQUEST_FAILURE",
    INCIDENT_FIND_REQUEST_SUCCESS = "INCIDENT_FIND_REQUEST_SUCCESS",
    INCIDENT_FIND_FILTER_REQUEST = "INCIDENT_FIND_FILTER_REQUEST",
    INCIDENT_FIND_FILTER_REQUEST_FAILURE = "INCIDENT_FIND_FILTER_REQUEST_FAILURE",
    INCIDENT_FIND_FILTER_REQUEST_SUCCESS = "INCIDENT_FIND_FILTER_REQUEST_SUCCESS";

export function incidentFindReset() {
    return {
        type: INCIDENT_FIND_RESET
    };
}

export function incidentFindRequest(incidentFilter) {
    return {
        type: INCIDENT_FIND_REQUEST,
        incidentFilter
    };
}

export function incidentFindRefreshRequest() {
    return {
        type: INCIDENT_FIND_REFRESH_REQUEST
    }
}

export function incidentFindRequestSuccess(incidents, paging, incidentFilter) {
    return {
        type: INCIDENT_FIND_REQUEST_SUCCESS,
        incidents,
        paging,
        incidentFilter
    };
}

export function incidentFindRequestFailure(error) {
    return {
        type: INCIDENT_FIND_REQUEST_FAILURE,
        error
    };
}

export function incidentFindFilterRequest(reset) {
    return {
        type: INCIDENT_FIND_FILTER_REQUEST,
        reset
    };
}

export function incidentFindFilterRequestSuccess(incidentFilter) {
    return {
        type: INCIDENT_FIND_FILTER_REQUEST_SUCCESS,
        incidentFilter
    };
}

export function incidentFindFilterRequestFailure(error) {
    return {
        type: INCIDENT_FIND_FILTER_REQUEST_FAILURE,
        error
    };
}