
import * as AusComplyDtos from '../../common/dto/AusComply.dtos';
import { KeyName } from '../../common/dto/common';

export const correctionalFacilityRiskStatusLogic = {

    getCommands(riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus) {
        var commands: KeyName[] = [];
        // if (riskStatus.editAllowed) {
        //     commands.push({ key: "edit", name: "Edit" });
        // }
        // if (riskStatus.deleteAllowed) {
        //     commands.push({ key: "delete", name: "Delete" });
        // }

        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "delete", name: "Delete" });
        return commands;
    }
}
