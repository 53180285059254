import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import DocumentTypes from '../components/admin/documentTypes/DocumentTypes'
import { 
    adminDocumentTypesRequest, 
    adminDocumentTypeCreateRequest, 
    adminDocumentTypeRequest, 
    adminDocumentTypeReset,
    adminDocumentTypeSet,
    adminDocumentTypeUpsertRequest
 } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        venueId:  null,
        paging: state.admin.documentTypes.paging,
        filter: state.admin.documentTypes.filter,
        isLoading: state.admin.documentTypes.isLoading,
        documentTypes: state.admin.documentTypes.documentTypes,
        documentTypeId: state.admin.documentType.documentTypeId,
        canCreate: permissionsLogic.hasPermissionForState(state, "EditReferenceData")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (filter, page, pageSize) => {
            dispatch(adminDocumentTypesRequest(page, pageSize, filter))
        },
        onLoadItem: (documentTypeId) => {
            dispatch(adminDocumentTypeRequest(documentTypeId))
        },
        onCreate: () => {
            dispatch(adminDocumentTypeCreateRequest())
        },
        onResetItem: () => {
            dispatch(adminDocumentTypeReset())
        },
        onUpdate: (documentType) => {
            dispatch(adminDocumentTypeSet(documentType))
        },
        onSave: () => {
            dispatch(adminDocumentTypeUpsertRequest())
        }
    }
}

const AdminDocumentTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentTypes))

export default AdminDocumentTypesContainer