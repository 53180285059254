import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';
import LayoutForm from '../layout/LayoutForm';
import TextControlGroup from '../controls/TextControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';

export interface ISubLocationProps {
    subLocation: AusComplyDtos.SubLocation;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ISubLocationState {
}

export default class SubLocation extends Component<ISubLocationProps, ISubLocationState> {
    constructor(props: ISubLocationProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {

        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let subLocation = { ...this.props.subLocation };
        subLocation[fieldName] = value;
        this.props.onUpdate(subLocation)
    }

    render() {

        if (!this.props.subLocation) {
            return (<></>);
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <TextControlGroup
                        text={"First name"}
                        defaultValue={this.props.subLocation.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />

                    <CheckboxControlGroup
                        text={"Active"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.subLocation.obsolete}
                        onChanged={(value) => this.onValueChanged("obsolete", value)} />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}