import React from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { NumberLiteralType } from 'typescript';
import { useDrag, DragSourceMonitor, DragSourceConnector, ConnectDragSource, DragSource } from 'react-dnd';
import { ItemTypes } from '../../../checklists/checklistTemplate/ItemTypes';

export interface IFacialRecognitionEventsListCardDragProps {
    facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent;
    index: number;
    onMove: Function;
    connectDragSource: ConnectDragSource;
    isDragging?: boolean;
    isDropped?: boolean;
}

export interface IFacialRecognitionEventsListCardDragState {

}

class FacialRecognitionEventsListCardDrag extends React.Component<IFacialRecognitionEventsListCardDragProps, IFacialRecognitionEventsListCardDragState> {
    constructor(props: IFacialRecognitionEventsListCardDragProps) {
        super(props);
    }

    public render() {
        const item = this.props.facialRecognitionEvent;
        const index = this.props.facialRecognitionEvent;

        return this.props.connectDragSource(<div key={"ChecklistTemplateItemCardDrag-" + item.processRank + "-" + index} style={{ display: 'block', margin: '5px', cursor: 'move' }}>
            {this.props.children}
        </div>);
    }
}

export default DragSource((props: IFacialRecognitionEventsListCardDragProps) => 
    ItemTypes.ITEM,
    {
        beginDrag: (props: IFacialRecognitionEventsListCardDragProps) => {
            return { name: props.facialRecognitionEvent.facialRecognitionEventId };
        },
        endDrag: (props: IFacialRecognitionEventsListCardDragProps, monitor: DragSourceMonitor) => {
            const item = monitor.getItem();
            const dropResult = monitor.getDropResult();

            if (dropResult) {
                let alertMessage = '';
                const isDropAllowed = dropResult.allowedDropEffect === 'any';
                
                if(props.facialRecognitionEvent)

                if (isDropAllowed) {
                    if (props.onMove) {
                        props.onMove(props.facialRecognitionEvent, dropResult.position);
                    }
                }
            }
        }
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    })
)(FacialRecognitionEventsListCardDrag)
