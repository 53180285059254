import React from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import TableRow from '../../../common/TableRow';
import { userComplianceDocumentLogic } from '../../../../common/logic/userComplianceDocumentLogic';

export interface IUserComplianceDocumentExpiredRowProps {
    userComplianceDocument: AusComplyDtos.UserComplianceDocumentSummary;
    onCommand?: Function;
}

export default class UserComplianceDocumentExpiredRow extends React.Component<IUserComplianceDocumentExpiredRowProps, any> {
    constructor(props: IUserComplianceDocumentExpiredRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.userComplianceDocument);
        }
    }

    render() {
        if (!this.props.userComplianceDocument) {
            return (<></>);
        }

        var item = this.props.userComplianceDocument;
        var commands = userComplianceDocumentLogic.getExpiredCommands(item);

        return (
            <TableRow isDanger={item.expires < 0} 
                commands={commands} 
                onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.documentType}
                    </p>
                </td>
                <td>
                    <p>
                        {item.expires}
                    </p>
                </td>
                <td>
                    <p>
                        {item.givenName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.surname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.nickname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.venue}
                    </p>
                </td>
                <td>
                    <p>
                        {item.securityFirms}
                    </p>
                </td>
            </TableRow>
        );
    }
}