import { connect } from 'react-redux'
import RegisterVenue from '../components/register/venue/RegisterVenue';
import {
    registerVenueNew,
    registerVenueSetStep,
    registerVenueReset,
    registerVenueUpdate,
    registerVenueUpdateOtherDocuments,
    registerVenueUpdateComplianceDocuments,
    registerVenueSaveRequest
} from '../common/actions/registerVenue'

const mapStateToProps = state => {
    return {
        venue: state.registerVenue.venue,
        venueContact: state.registerVenue.venueContact,
        licenseContact: state.registerVenue.licenseContact,
        states: state.registerVenue.states,
        industryCategories: state.registerVenue.industryCategories,
        precincts: state.registerVenue.precincts,
        licenceTypes: state.registerVenue.licenceTypes,
        tradingHours: state.registerVenue.tradingHours,
        otherDocuments: state.registerVenue.otherDocuments,
        otherDocumentTypes: state.registerVenue.otherDocumentTypes,
        complianceDocuments: state.registerVenue.complianceDocuments,
        complianceDocumentTypes: state.registerVenue.complianceDocumentTypes,
        step: state.registerVenue.step,
        isLoading: state.registerVenue.isLoading,
        isSaving: state.registerVenue.isSaving,
        error: state.registerVenue.error,
        errors: state.registerVenue.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitVenue: () => {
            dispatch(registerVenueNew())
        },
        onSetStep: (step) => {
            dispatch(registerVenueSetStep(step))
        },
        onUpdate: (venue, venueContact, licenseContact) => {
            dispatch(registerVenueUpdate(venue, venueContact, licenseContact))
        },
        onReset: () => {
            dispatch(registerVenueReset())
        },
        onUpdateOtherDocuments: (otherDocuments) => {
            dispatch(registerVenueUpdateOtherDocuments(otherDocuments))
        },
        onUpdateComplianceDocuments: (complianceDocuments) => {
            dispatch(registerVenueUpdateComplianceDocuments(complianceDocuments))
        },
        onSave: () => {
            dispatch(registerVenueSaveRequest())
        },
    }
}

const RegisterVenueContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterVenue)

export default RegisterVenueContainer