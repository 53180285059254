import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IIndustryCategoryMultiSelectProps {
    industryCategorySelections: AusComplyDtos.IndustryCategorySelection[];
    onUpdated: Function;
}

interface IIndustryCategoryMultiSelectState {
    industryCategories: MulitSelectOption[];
}

export default class IndustryCategoryMultiSelect extends Component<IIndustryCategoryMultiSelectProps, IIndustryCategoryMultiSelectState> {
    constructor(props: IIndustryCategoryMultiSelectProps) {
        super(props)
        let incidentCategoryType = new AusComplyDtos.IncidentCategoryType();
        incidentCategoryType.industryCategorySelections = [];
        this.state = {
            industryCategories: []
        }
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
    }

    componentDidMount() {
        let industryCategories: MulitSelectOption[] = [];
        if (this.props.industryCategorySelections) {
            this.props.industryCategorySelections.forEach((item, index) => {
                let industryCategory = new MulitSelectOption();
                industryCategory.id = item.industryCategoryId;
                industryCategory.name = item.name;
                industryCategory.selected = item.selected;
                industryCategory.isCommon = false;
                industryCategories.push(industryCategory);
            });
        }

        this.setState({
            industryCategories
        });
    }

    componentDidUpdate(prevProps) {

    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        }, () => {
            let industryCategorySelections = [ ...this.props.industryCategorySelections ];
            industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
            this.props.onUpdated(industryCategorySelections)
        });
    }


    render() {
        return (
            <CardContainer
                title={"Industries"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <div style={{ marginTop: '-4px' }}>
                        <MultiSelectControl
                            items={this.state.industryCategories}
                            minimumCharactersRequired={3}
                            canShowAll={true}
                            searchText={"Type at least three letters..."}
                            onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                    </div>
                </CardContent>
            </CardContainer>
        );
    }
}