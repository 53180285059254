import React, { Component } from 'react';
import FormErrorMessage from '../../alerts/FormErrorMessage';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import ControlGroup from "../../controls/ControlGroup";
import DefaultButton from '../../controls/DefaultButton';

export interface IUserChangePasswordProps {
    userId: number;
    isSavingPassword: boolean;
    changePasswordError: string;
    onChangePassword: Function;
}

interface IUserChangePasswordState {
    newPassword: string;
    newPasswordConfirm: string;
}

export default class UserChangePassword extends Component<IUserChangePasswordProps, IUserChangePasswordState> {
    constructor(props: IUserChangePasswordProps) {
        super(props)
        this.state = {
            newPassword: "",
            newPasswordConfirm: ""
        };
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onNewPasswordConfirmChanged = this.onNewPasswordConfirmChanged.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(!this.props.isSavingPassword && prevProps.isSavingPassword && this.props.changePasswordError == "") {
            this.setState({
                newPassword: "",
                newPasswordConfirm: ""
            })
        }
    }

    onNewPasswordChanged(value) {
        this.setState({ newPassword: value });
    }

    onNewPasswordConfirmChanged(value) {
        this.setState({ newPasswordConfirm: value });
    }

    onSubmitNewPassword() {
        this.props.onChangePassword(this.props.userId, this.state.newPassword, this.state.newPasswordConfirm);
    }

    render() {
        return (
            <>
                <FormErrorMessage error={this.props.changePasswordError} />
                <PasswordControlGroup
                    text={"New Password"}
                    defaultValue={this.state.newPassword}
                    readonly={this.props.isSavingPassword}
                    onChange={(value) => this.onNewPasswordChanged(value)} />
                <PasswordControlGroup
                    text={"Confirm New Password"}
                    readonly={this.props.isSavingPassword}
                    defaultValue={this.state.newPasswordConfirm}
                    onChange={(value) => this.onNewPasswordConfirmChanged(value)} />
                <ControlGroup text={" "}>
                    <DefaultButton disabled={this.props.isSavingPassword} onClick={() => this.onSubmitNewPassword()}>Change Password</DefaultButton>
                </ControlGroup>
            </>
        );
    }
}