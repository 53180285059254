import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import ImagePreview from '../../common/ImagePreview';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';

export interface IChecklistTemplateAttachmentProps {
    attachment?: AusComplyDtos.Attachment;
    file?: AusComplyDtos.File;
    onUpdate: Function;
    theme: any;
}

interface ICheckListItemAttachmentsState {
    selectedFilePath: string;
    selectedFileName: string;
}

class ChecklistTemplateAttachment extends Component<IChecklistTemplateAttachmentProps, ICheckListItemAttachmentsState> {
    constructor(props: IChecklistTemplateAttachmentProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        }
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onUploadFiles = this.onUploadFiles.bind(this);
        this.getBase64 = this.getBase64.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
    }

    onRemove() {
        this.props.onUpdate(null, null);
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        if (temporaryFiles.length > 0) {
            // only care about the first item
            if (temporaryFiles[0].isImage) {
                let attachment;
                if (this.props.attachment) {
                    attachment = { ...this.props.attachment };
                    attachment.obsolete = true;
                }
                this.props.onUpdate(temporaryFiles[0], null);
            }
        }
    }

    onUpdated(attachment: AusComplyDtos.Attachment) {
        this.props.onUpdate(null, attachment);
    }

    getBase64(file) {
        return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onUploadFiles(files: any) {
        if (files && files.length > 0) {
            this.getBase64(files[0]).then(data => {
                var attachment = new AusComplyDtos.Attachment();
                attachment.attachmentId = 0;
                attachment.fileName = files[0].name;
                attachment.fileType = files[0].type;
                attachment.mimeType = files[0].type;
                attachment.obsolete = false;
                attachment.data = data.replace(/^data:.+;base64,/, '');
                attachment.isImage = true;
                //console .log(data, files[0], attachment)
                this.onUpdated(attachment);
            });
        }
    }

    render() {
        const { theme } = this.props;
        let attachments;
        if (this.props.attachment && !this.props.attachment.obsolete) {
            let item = this.props.attachment;
            if(item.fileType == "application/pdf") {
                attachments = (
                    <Box p={0} key={"file-box-" + item.fileName}>
                        <Thumbnail
                            key={"file-" + item.fileName}
                            base64={""}
                            base64Type={""}
                            previewPath={""}
                            displayPath={""}
                            isImage={false}
                            alwaysShowText={true}
                            text={item.fileName}
                            fullText={item.fileName}
                            onRemove={() => this.onRemove()}
                        />
                    </Box>
                )
            } else {
                attachments = (
                    <Box p={0} key={"file-box-" + item.fileName}>
                        <Thumbnail
                            key={"file-" + item.fileName}
                            base64={item.data}
                            base64Type={item.fileType}
                            previewPath={""}
                            displayPath={""}
                            isImage={true}
                            alwaysShowText={true}
                            text={item.filePreviewText}
                            fullText={item.fileName}
                            onRemove={() => this.onRemove()}
                        />
                    </Box>
                )
            }
        }

        let fileUpload;
        if (!this.props.file && !this.props.attachment || (this.props.attachment && this.props.attachment.obsolete)) {
            fileUpload = (
                <FileUpload
                    onFileWasUploaded={this.fileWasUploaded}
                    onUploadFiles={this.onUploadFiles}
                    alternateIcons={false}
                    imageIcon={true}
                    altText={"Upload Image"}
                    hasFiles={false} />
            );
        }
        return (
            <div >
                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                    <Box p={1} key={"file-box-space"}></Box>
                    {attachments}
                </Box>
                {fileUpload}
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(ChecklistTemplateAttachment);