
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentServiceApi } from '../services/incidentService';

import {
    DISPLAY_INCIDENT_LOAD_REQUEST,
    displayIncidentLoadSuccess,
    displayIncidentLoadFailure,
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST,
    displayIncidentVersionsLoadSuccess,
    displayIncidentVersionsLoadFailure,
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST,
    displayIncidentVersionLoadSuccess,
    displayIncidentVersionLoadFailure
} from "../actions/displayIncident";
import { 
    notifyError, notifyErrorMessage
  } from './common'; 
import { notificationShow } from "../actions/notification";

const loadDisplayIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(DISPLAY_INCIDENT_LOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.loadIncident(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,  
                state.user.details.userSession.user.venueEventId, 
                action["incidentId"])
                .pipe(
                    map(response => displayIncidentLoadSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident", displayIncidentLoadFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident", displayIncidentLoadFailure))
    );

const loadDisplayIncidentVersionsEpic = (action$, state$) =>
    action$.pipe(
        ofType(DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentVersions(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,  
                state.user.details.userSession.user.venueEventId, 
                action["incidentId"])
                .pipe(
                    map(response => displayIncidentVersionsLoadSuccess(response.incidentVersions)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident versions", displayIncidentVersionsLoadFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident versions", displayIncidentVersionsLoadFailure))
    );

const loadDisplayIncidentVersionEpic = (action$, state$) =>
    action$.pipe(
        ofType(DISPLAY_INCIDENT_LOAD_VERSION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentVersion(
                state.user.details.userSession.user.venueId, 
                action["securityFirmId"],  
                state.user.details.userSession.user.venueEventId, 
                action["incidentId"], action["incidentRelaventId"], action["version"])
                .pipe(
                    map(response => displayIncidentVersionLoadSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident version", displayIncidentVersionLoadFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident version", displayIncidentVersionLoadFailure))
    );

export const incidentDisplayEpics = combineEpics(
    loadDisplayIncidentEpic,
    loadDisplayIncidentVersionsEpic,
    loadDisplayIncidentVersionEpic
);