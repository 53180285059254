import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, VenueLocation, UserRole, User } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';

export interface IIncidentWizardPersonnelProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

interface IIncidentWizardPersonnelState {
    items: MulitSelectOption[];
}

export default class IncidentWizardPersonnel extends Component<IIncidentWizardPersonnelProps, IIncidentWizardPersonnelState> {
    constructor(props: IIncidentWizardPersonnelProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
    }

    componentDidMount() {
        try {
            let items: MulitSelectOption[] = [];
            let selectedIds: number[] = [];
            if (this.props.incident && this.props.incident.incidentUsers) {
                selectedIds = this.props.incident.incidentUsers.map(item => item.userRoleId);
            }
            if (this.props.incidentOptions && this.props.incidentOptions.personnel) {
                this.props.incidentOptions.personnel.map((item, index) => {
                    let isSelected = selectedIds.indexOf(item.userRoleId) > -1;
                    items.push({
                        id: item.userRoleId,
                        name: item.display,
                        isCommon: item.isPopular,
                        selected: isSelected
                    });
                });
            }
            this.setState({
                items: items
            });
        } catch (err) {
            commonLogic.trackException(err, "IncidentWizardPersonnel.componentDidMount", {})
        }
    }

    onSelectItemsChanged(items) {
        // get the old and new
        let previousIds = this.props.incident.incidentUsers.map(item => item.userRoleId);
        let currentIds = items.map(item => item.id);

        // remove
        let newPersonnel = this.props.incident.incidentUsers.filter(item => currentIds.indexOf(item.userRoleId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            newPersonnel.push({
                incidentUserId: 0,
                incidentId: 0,
                userRoleId: item.id,
                userRole: new UserRole(),
                user: new User(),
                userId: 0,
                display: item.name
            });
        });
        let newIncident = { ... this.props.incident };
        newIncident.incidentUsers = newPersonnel;
        this.props.onUpdateIncident(newIncident)
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Staff Involved"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <MultiSelectControl
                                            items={this.state.items}
                                            label={""}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectItemsChanged}
                                            canShowAll={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}