import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import DarkButton from '../../controls/DarkButton';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import TextDisplay from '../../controls/TextDisplay';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';

export interface IIncidentWizardSnapshotProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class IncidentWizardSnapshot extends Component<IIncidentWizardSnapshotProps, any> {
    constructor(props: IIncidentWizardSnapshotProps) {
        super(props)
        this.state = {
            summary: "",
            visible: false,
            isRegulated: false
        }
        this.onToggle = this.onToggle.bind(this);
    }

    componentDidMount() {
        let isRegulated = incidentLogic.isIncidentRegulatedWalkthrough(this.props.incident, this.props.incidentOptions);
        let summary = this.props.incident.regulatedWalkthroughSummary;
        let visible = false;
        let changed = false;

        if (isRegulated && !summary) {
            summary = this.props.incident.snapshotSummary;
            changed = true;
        }
        if (summary) {
            visible = true;
        }
        this.setState({ summary, visible, isRegulated }, () => {
            if (changed) {
                if (this.props.onUpdateIncident) {
                    let newIncident = this.props.incident;
                    newIncident.regulatedWalkthroughSummary = summary;
                    this.props.onUpdateIncident(newIncident);
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        let changed = false;
        let visible = this.state.visible;
        let summary = this.state.summary;

        let isRegulated = incidentLogic.isIncidentRegulatedWalkthrough(this.props.incident, this.props.incidentOptions);
        if (isRegulated !== this.state.isRegulated) {
            changed = true;
        }
        if (isRegulated && !summary) {
            summary = this.props.incident.snapshotSummary;
            changed = true;
        }
        if (this.props.incident.regulatedWalkthroughSummary !== prevProps.incident.regulatedWalkthroughSummary) {
            if (this.props.incident.regulatedWalkthroughSummary) {
                summary = this.props.incident.regulatedWalkthroughSummary;
                visible = true;
            } else {
                summary = this.props.incident.regulatedWalkthroughSummary;
                visible = false;
            }
            changed = true;
        }
        if (changed) {
            this.setState({ summary, visible, isRegulated });
        }
    }

    onToggle() {
        let visible = !this.state.visible;
        let summary = "";
        if (visible) {
            summary = this.props.incident.snapshotSummary;
        }
        this.setState({ summary, visible }, () => {
            if (this.props.onUpdateIncident) {
                let newIncident = this.props.incident;

                if ((!newIncident.snapshotSummary || newIncident.snapshotSummary === "") && newIncident.regulatedWalkthroughSummary !== "") {
                    // save the original summary incase its re-enabled
                    newIncident.snapshotSummary = newIncident.regulatedWalkthroughSummary;
                }
                newIncident.regulatedWalkthroughSummary = summary;
                this.props.onUpdateIncident(newIncident);
            }
        });

    }

    render() {

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Snapshot Summary"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {this.state.summary && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextDisplay>{this.state.summary}</TextDisplay>
                                        </Grid>
                                    </Grid>
                                )}
                                {!this.state.isRegulated && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TransparentButton text={this.state.summary ? "Remove Snapshot Summary" : "Add Snapshot Summary"} onClick={this.onToggle} />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );

    }
}