import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { incidentFlagTypeNotificationLogic } from '../../../common/logic/incidentFlagTypeNotificationLogic';

export interface IIncidentFlagTypeNotificationRowProps {
    incidentFlagTypeNotification: AusComplyDtos.IncidentFlagTypeNotification;
    canEdit: boolean;
    onCommand?: Function;
}

export default class IncidentFlagTypeNotificationRow extends React.Component<IIncidentFlagTypeNotificationRowProps, any> {
    constructor(props: IIncidentFlagTypeNotificationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.incidentFlagTypeNotification);
        }
    }

    render() {
        if (!this.props.incidentFlagTypeNotification) {
            return (<></>);
        }

        var commands = incidentFlagTypeNotificationLogic.getCommands(this.props.incidentFlagTypeNotification, this.props.canEdit);
        var item = this.props.incidentFlagTypeNotification;

        return (
            <TableRow commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.incidentFlagType.name}
                    </p>
                </td>
                <td>
                    <p>
                        {item.notificationTrigger.selectedUserRoleTypesDisplay}
                    </p>
                </td>
            </TableRow>
        );
    }
}