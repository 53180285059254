import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentDate } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import PrimaryButton from '../../controls/PrimaryButton';
import TransparentButton from '../../controls/TransparentButton';
import ControlGroup from '../../controls/ControlGroup';
import IncidentDateControlGroup from '../../controls/IncidentDateControlGroup';
import ErrorButton from '../../controls/ErrorButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';

export interface IIncidentWizardDatesProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class IncidentWizardDates extends Component<IIncidentWizardDatesProps, any> {
    constructor(props: IIncidentWizardDatesProps) {
        super(props)
        this.state = {
            reportingPeriod: 1,
            reportingPeriodRequired: 1,
            reportingPeriods: [
                { reportingPeriod: 1, name: "Current" },
                { reportingPeriod: 2, name: "Past" }
            ]
        }
        this.onChange = this.onChange.bind(this);
        this.onIncidentDateChanged = this.onIncidentDateChanged.bind(this);
    }

    onChange(reportingPeriod) {
        this.setState({ reportingPeriod: reportingPeriod }, () => {
            let newIncident = { ...this.props.incident };
            newIncident.isCurrentReportingPeriodConfirm = reportingPeriod == 1;
            this.props.onUpdateIncident(newIncident);
        });
    }

    componentDidMount() {
        if (this.props.incident) {
            this.setState({
                reportingPeriod: this.props.incident.isCurrentReportingPeriodConfirm ? 1 : 2,
                reportingPeriodRequired: this.props.incident.isCurrentReportingPeriod ? 1 : 2
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.incident && this.props.incident) {
            if (prevProps.incident.isCurrentReportingPeriodConfirm != this.props.incident.isCurrentReportingPeriodConfirm ||
                prevProps.incident.isCurrentReportingPeriod != this.props.incident.isCurrentReportingPeriod) {
                this.setState({
                    reportingPeriod: this.props.incident.isCurrentReportingPeriodConfirm ? 1 : 2,
                    reportingPeriodRequired: this.props.incident.isCurrentReportingPeriod ? 1 : 2
                });
            }
        }
    }

    onIncidentDateChanged(value: IncidentDate) {
        let newIncident = { ...this.props.incident };
        newIncident.incidentDate = value;
        this.props.onUpdateIncident(newIncident);
        // validate the reporting period - this should come from the object state

    }

    render() {
        let errorText = "";
        if (this.state.reportingPeriod == 1 && this.state.reportingPeriodRequired == 2) {
            errorText = "Confirm that the incident date is outside the current reporting period";
        }
        if (this.state.reportingPeriod == 2 && this.state.reportingPeriodRequired == 1) {
            errorText = "Confirm that the incident date is inside the current reporting period";
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Date and Reporting Period"}>
                    <CardContent style={{paddingTop: 0}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ControlGroup text="Reporting Period" errorText={errorText}>
                                            <Grid container spacing={1}>
                                                {this.state.reportingPeriods.map((item, index) => {
                                                    return (
                                                        <Grid item xs={6} key={item.reportingPeriod}>
                                                            {(item.reportingPeriod == this.state.reportingPeriod && errorText.length == 0) && (
                                                                <PrimaryButton text={item.name} onClick={() => this.onChange(item.reportingPeriod)} />
                                                            )}
                                                            {(item.reportingPeriod == this.state.reportingPeriod && errorText.length > 0) && (
                                                                <ErrorButton text={item.name} onClick={() => this.onChange(item.reportingPeriod)} />
                                                            )}
                                                            {item.reportingPeriod != this.state.reportingPeriod && (
                                                                <TransparentButton text={item.name} onClick={() => this.onChange(item.reportingPeriod)} />
                                                            )}
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </ControlGroup>
                                        <IncidentDateControlGroup text={"Date"}
                                            date={this.props.incident.incidentDate}
                                            timezones={this.props.incidentOptions.timezones}
                                            onChange={this.onIncidentDateChanged} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}