import { connect } from 'react-redux';
import { 
    userVenueSecurityFirmLoadStatistics, 
    userVenueSecurityFirmResetStatistics, 
    userVenueSecurityFirmQuietLoadStatistics 
} from '../common/actions/userVenueSecurityFirm';
import { 
    userDetailsRefresh, 
    clearUserVenueMessage 
} from '../common/actions/userLogin';
import { incidentsFilterRequest } from '../common/actions/incidents';
import { venueSecurityFirmAvailableClearVenue } from '../common/actions/venueSecurityFirm'
import { userDetailsVenueSecurityFirmCancelChange } from '../common/actions/userLogin'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { checklistFilterReset } from '../common/actions/checklist';
import { locationRequest } from '../common/actions/location';
import { registerUserPostSave } from '../common/actions/registerUser';
import Home from '../components/home/Home';

const showVenueMessage = (state) =>
{
    let result:boolean = false;
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        let showVenueMessageId = "";
        if (state.user.details.userSession.user.showVenueMessageId && state.user.details.userSession.user.showVenueMessageId.length > 0) {
            showVenueMessageId = state.user.details.userSession.user.showVenueMessageId;
            result = true;
        }
        if (result && state.user.clearedVenueMessageId) {
            if (showVenueMessageId == state.user.clearedVenueMessageId) {
                result = false;
            }
        }
    }
    return result;
}

const maintenanceMessage = (state) => {
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        return state.user.details.userSession.user.maintenanceMessage;
    }
    return "";
}


const mapStateToProps = state => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        isVenueSelected: state.user.details.venueIsSelected,
        isSecurityFirmSelected: state.user.details.securityFirmIsSelected,
        isAndHasNothingSelected: state.user.details.isAndHasNothingSelected,
        isUserLoading: state.user.isLoading,
        requiresSecurityCode: state.user.details.userSession.user.requiresSecurityCode,
        hasOpenReportingPeriod: state.user.details.userSignOnSummary.hasOpenReportingPeriod,
        pendingInvalidSLEDAccess: state.user.details.userSession.user.pendingInvalidSLEDAccess,
        isStatisticsLoading: state.userVenueSecurityFirm.isLoading,
        statistics: state.userVenueSecurityFirm.statistics,
        statisticsError: state.userVenueSecurityFirm.error,
        canViewIncidents: permissionsLogic.hasPermissionForState(state, "ViewIncidentList"),
        canViewCommsLogs: permissionsLogic.hasPermissionForState(state, "CommsLogsView"),
        canSignOnToRegister: permissionsLogic.hasPermissionForState(state, "SignOnToRegister"),
        hasUserRoles: state.user.details && state.user.details.userRoleSummaries && state.user.details.userRoleSummaries.length > 0,
        verified: state.user.details && state.user.details.userSession.user.verified,
        maintenanceMessage: maintenanceMessage(state),
        showVenueMessage: showVenueMessage(state),
        postSaveAction: state.registerUser.postSaveAction,
        showGamblingIncidentRegister: state.user.details && state.user.details.showGamblingIncidentRegister
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onResetDataQuiet: () => {
            dispatch(userVenueSecurityFirmQuietLoadStatistics())
        },
        onResetData: () => {
            dispatch(userVenueSecurityFirmResetStatistics())
        },
        onRefreshData: () => {
            dispatch(userVenueSecurityFirmLoadStatistics())
        },
        onRefresh: (venueId, securityFirmId) => {
            dispatch(userDetailsRefresh())
        },
        onResetFilter: () => {
            dispatch(incidentsFilterRequest(true, ""))
        },
        onClearVenue: () => {
            dispatch(venueSecurityFirmAvailableClearVenue())
        },
        onCancelChangeVenue: () => {
            dispatch(userDetailsVenueSecurityFirmCancelChange())
        },
        onResetChecklistFilter:() => {
            dispatch(checklistFilterReset())
        },
        onDismissVenueMessage:() => {
            dispatch(clearUserVenueMessage())
        },
        onRegisterUserPostSave: (postSaveAction) => {
            dispatch(registerUserPostSave(postSaveAction))
        }
    }
}

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)

export default HomeContainer