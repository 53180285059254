import { connect } from 'react-redux'
import Directory from '../components/directory/Directory'
import * as directoryActions from '../common/actions/directory'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        entityType: AusComplyDtos.ngtEntityType.SecurityFirm,
        id: state.user.details.userSession.user.securityFirmId,
        isLoading: state.directories.isLoading,
        isSaving: state.directories.isSaving,
        directory: state.directories.directory,
        showObsolete: state.directories.showObsolete,
        canViewObsolete: permissionsLogic.isLevelSix(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (entityType, id) => {
            dispatch(directoryActions.directoryRequest(entityType, id))
        },
        onUpload: (documentUpload) => {
            dispatch(directoryActions.directoryUploadRequest(documentUpload))
        },
        onUpdate: (document) => {
            dispatch(directoryActions.directoryUpdateRequest(document))
        },
        onRemove: (document) => {
            dispatch(directoryActions.directoryRemoveRequest(document))
        },
        onUpsertFolder: (entityType, id, folder) => {
            dispatch(directoryActions.directoryUpsertFolderRequest(entityType, id, folder))
        }
    }
}

const SecurityFirmDirectoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Directory)

export default SecurityFirmDirectoryContainer