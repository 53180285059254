import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardRow from '../../common/CardRow';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { venueLogic } from '../../../common/logic/venueLogic';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IVenuesCardProps {
    venue: AusComplyDtos.Venue;
    onClick: Function;
    onCommand?: Function;
    classes: any;
    theme: any;
}

class VenuesCard extends React.Component<IVenuesCardProps, any> {
    constructor(props: IVenuesCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venue);
        }
    }

    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        if (!this.props.venue.registrationComplete) {
            className = classes.cardIncidentRejected;
        }
        let activeTick;
        if (this.props.venue.isActive) {
            activeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }
        var commands = venueLogic.getAdminCommands(this.props.venue);
        return (
            <CardRow
                isDanger={!this.props.venue.registrationComplete}
                commands={commands}
                onCommand={this.onCommand}
                >
                <Grid container spacing={1} onClick={() => this.props.onClick(this.props.venue)}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography><b>{this.props.venue.name}</b></CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Industry</CardTitle>
                        <CardTypography>{this.props.venue.industryCategoryDisplay}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.props.onClick(this.props.venue)}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Licencee(s)/Owner(s)</CardTitle>
                        <CardTypography>{this.props.venue.licenceeOwnerName}</CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Active</CardTitle>
                        <CardTypography>{activeTick}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenuesCard);