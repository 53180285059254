import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const incidentFlagTypeNotificationServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentFlagTypeNotifications();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, incidentFlagTypeNotification) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentFlagTypeNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentFlagTypeId = incidentFlagTypeNotification.incidentFlagTypeId;
    query.incidentFlagTypeNotification = incidentFlagTypeNotification;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, incidentFlagTypeId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentFlagTypeNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentFlagTypeId = incidentFlagTypeId;
    const request = client.get(query);
    return from(request);
  }
};
