import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import CardTitle from '../common/CardTitle';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';
import MoreCommands from '../common/MoreCommands';

export interface IUserRoleCardProps {
    userRoleSummary: AusComplyDtos.UserRoleSummary;
    onEdit?: Function;
    canAdminEdit?: boolean;
    onRemove?: Function;
    onEnable?: Function;
    onAdminEdit?: Function;
    classes: any;
    theme: any;
}

class UserRoleCard extends React.Component<IUserRoleCardProps, any> {
    constructor(props: IUserRoleCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit && this.props.userRoleSummary.editAllowed && !this.props.userRoleSummary.userRoleObsolete) {
                    this.props.onEdit(this.props.userRoleSummary.userRoleId);
                }
                break;
            case "remove":
                if (this.props.onRemove && this.props.userRoleSummary.deleteAllowed) {
                    this.props.onRemove(this.props.userRoleSummary.userRoleId);
                }
                break;
            case "enable":
                if (this.props.onEnable && this.props.userRoleSummary.userRoleObsolete) {
                    this.props.onEnable(this.props.userRoleSummary.userRoleId);
                }
                break;
        }
    }

    render() {
        const { classes, theme } = this.props;
        var commands: KeyName[] = [];
        let className = classes.cardIncidentPending;
        if (this.props.userRoleSummary.userRoleObsolete && this.props.onEnable) {
            className = classes.cardIncidentRejected;
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.userRoleSummary.editAllowed) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.userRoleSummary.deleteAllowed) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        return (
            <Card style={{ marginTop: '6px', marginBottom: '6px', position: 'relative' }} className={className}>
                <CardContent>
                    <Box p={0} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}>
                            <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                                <Grid item xs={6}>
                                    {this.props.userRoleSummary.group && (
                                        <>
                                            <CardTitle>Group</CardTitle>
                                            <CardTypography>{this.props.userRoleSummary.group}</CardTypography>
                                        </>
                                    )}
                                    {this.props.userRoleSummary.venue && (
                                        <>
                                            <CardTitle>Venue</CardTitle>
                                            <CardTypography>{this.props.userRoleSummary.venue}</CardTypography>
                                        </>
                                    )}
                                    {this.props.userRoleSummary.securityFirm && (
                                        <>
                                            <CardTitle>Security Firm</CardTitle>
                                            <CardTypography>{this.props.userRoleSummary.securityFirm}</CardTypography>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <CardTitle>{this.props.userRoleSummary.userRoleType}</CardTitle>
                                    <CardTypography>{this.props.userRoleSummary.userRoleStatus}{this.props.userRoleSummary.isTemporary && (<span> (temporary)</span>)}</CardTypography>
                                    <CardTypography style={{ fontStyle: 'italic', paddingRight: '5px' }}>Access level {this.props.userRoleSummary.accessLevel}</CardTypography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={0}>
                            {(commands.length > 0) && (
                                <MoreCommands
                                    commands={commands}
                                    onCommand={this.onCommand}
                                    style={{ marginLeft: '20px' }} />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleCard);