import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { roleTypeLogic } from '../../common/logic/roleTypeLogic';
import TableRow from '../common/TableRow';

export interface IRoleTypeRowProps {
    onCommand: Function;
    roleType: AusComplyDtos.UserRoleTypeSummary;
}

class RoleTypeRow extends React.Component<IRoleTypeRowProps, any> {
    constructor(props: IRoleTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand){
            this.props.onCommand(command, this.props.roleType);
        }
    }

    render() {
        let item = this.props.roleType;
        var commands = roleTypeLogic.getCommands(item);

        const onClickCommand = item.isGlobalRole ? 'view' : 'edit';
        
        return (
            <TableRow commands={commands} onCommand={this.onCommand}  isDanger={this.props.roleType.obsolete}>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.isGlobalRole ? 'System ' : 'Custom'}
                    </p>
                </td>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.baseName}
                    </p>
                </td>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.name !== item.baseName && item.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.accessLevel}
                    </p>
                </td>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.userRolesCount}
                    </p>
                </td>
                <td onClick={() => this.onCommand(onClickCommand)}>
                    <p>
                        {item.canBeAssigned ? 'Yes' : 'No'}
                    </p>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RoleTypeRow);