import { connect } from 'react-redux'
import VenueEdit from '../components/venue/VenueEdit'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    venueRequest,
    venueUpdate,
    venueSaveRequest
} from '../common/actions/venue'

const mapStateToProps = state => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        canEdit: permissionsLogic.hasPermissionForState(state, "ManageVenue"),
        canEditVenueIndustryCategory: permissionsLogic.hasPermissionForState(state, "EditVenueIndustryCategory"),
        isLoading: state.venue.isLoading,
        isSaving: state.venue.isSaving,
        venue: state.venue.venue,
        states: state.venue.states,
        industryCategories: state.venue.industryCategories,
        precincts: state.venue.precincts,
        licenceTypes: state.venue.licenceTypes,
        tradingHours: state.venue.tradingHours,
        error: state.venue.error,
        errors: state.venue.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueRequest(venueId, true))
        },
        onUpdate: (venue) => {
            dispatch(venueUpdate(venue))
        },
        onSave: () => {
            dispatch(venueSaveRequest())
        }
    }
}

const VenueEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueEdit)

export default VenueEditContainer