import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import { MulitSelectOption, RadioOption } from '../../../common/dto/common';
import CardContainer from '../../common/CardContainer';
import { CardContent } from '@material-ui/core';
import MultiSelectControl from '../../controls/MutiSelectControl';

export interface IEditRoleTypeProps {
    roleTypeId?: number,
    isNewRoleType: boolean,
    isLoading: boolean;
    roleType: AusComplyDtos.UserRoleType;
    availableUserPermissions: AusComplyDtos.Permission[];
    venueId?: number,
    securityFirmId?: number,
    aliasEditOnly: boolean;
    isSaving: boolean;
    error: string;
    onSuccess: Function;
    onLoad: Function;
    onChange: Function;
    onSave: Function;
    onClose: Function,
    theme: any;
    classes: any;
}

export default class EditRoleType extends Component<IEditRoleTypeProps, any> {
    constructor(props: IEditRoleTypeProps) {
        super(props)
        this.state = {
            error: {},
            permissions: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.validate = this.validate.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.roleTypeId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            let permissions: MulitSelectOption[] = [];
            if (this.props.availableUserPermissions) {
                this.props.availableUserPermissions.forEach((item, index) => {
                    if(this.props.roleType.userRoleTypePermissions && this.props.roleType.userRoleTypePermissions.findIndex(utp => utp == item.permissionId) !== -1){
                        let permission = new MulitSelectOption();
                        permission.id = index;
                        permission.code = item.permissionId;
                        permission.name = item.name;
                        permission.info = "Access level " + item.accessLevelRange;
                        permission.selected = true;
                        
                        permissions.push(permission);
                    }
                });
            }

            this.setState({
                error: {},
                permissions
            });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error && this.props.onSuccess) {
            this.setState({ error: {} });
            this.props.onSuccess();
        }
    }

    onSubmit() {
        if (this.validate()) {
            this.props.onSave(this.props.roleType, this.props.venueId, this.props.securityFirmId);
        }
    }

    onClose() {
        this.props.onClose();
    }

    onValueChanged(fieldName: string, value: any) {
        let roleType = { ...this.props.roleType };
        roleType[fieldName] = value;
        this.props.onChange(roleType);
    }

    onValueChangedMulti(fields: { fieldName: string, value: any }[]) {
        let roleType = { ...this.props.roleType };
        fields.forEach(field => {
            roleType[field.fieldName] = field.value;
        })
        this.props.onChange(roleType);
    }

    validate() {
        let error = {};
        let isValid = true;
        this.setState({ error: error });
        return isValid;
    }

    render() {
        const accessLevelDisabled = this.props.roleType && (this.props.roleType.defaultRole || this.props.roleType.obsolete  || this.props.aliasEditOnly);

        const restrictedAccessLevelOptions = accessLevelDisabled ? <>
            <option value={'1'}>{"Level 1 (staff)"}</option>
            <option value={'2'}>{"Level 2 (manager)"}</option>
            <option value={'3'}>{"Level 3 (head manager)"}</option>
            <option value={'4'}>{"Level 4 (owner)"}</option>
            <option value={'5'}>{"Level 5 (group)"}</option>
        </> : null;

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    {this.props.roleType && (
                        <>
                            <TextControlGroup
                                text={"System Name"}
                                defaultValue={this.props.roleType.name}
                                readonly={this.props.roleType.defaultRole || this.props.roleType.obsolete || this.props.aliasEditOnly}
                                onChange={(value) => this.onValueChanged("name", value)} />
                            <TextControlGroup
                                text={"Alias"}
                                defaultValue={this.props.roleType.nameAlias !== this.props.roleType.name ? this.props.roleType.nameAlias : ''}
                                readonly={this.props.roleType.obsolete}
                                onChange={(value) => this.onValueChanged("NameAlias", value)} />
                            <SelectControlGroup
                                text={"Access Level"}
                                error={this.state.error["accessLevel"]}
                                readonly={accessLevelDisabled}
                                onChange={(value) => this.onValueChanged("accessLevel", parseInt(value))}
                                defaultValue={this.props.roleType.accessLevel}>
                                <option value={'0'}>{"Level 0 (notifications)"}</option>
                                {restrictedAccessLevelOptions}
                            </SelectControlGroup>
                            {
                                this.state.permissions.length > 0 && <CardContainer
                                    title={"Permissions"}
                                    style={{ marginTop: '16px' }}>
                                    <CardContent style={{ paddingTop: 0 }}>
                                        <div style={{ marginTop: '-4px' }}>
                                            <MultiSelectControl
                                                items={this.state.permissions}
                                                isReadonly={true}
                                                minimumCharactersRequired={3}
                                                canShowAll={true} />
                                        </div>
                                    </CardContent>
                                </CardContainer>
                            }
                            <Grid container spacing={2}>
                                {
                                    this.props.roleType.obsolete ? <PrimaryButton text={"Close"} onClick={this.onClose}></PrimaryButton>
                                        : <>
                                            <Grid item xs={6}>
                                                <PrimaryButton text={"Save"} onClick={this.onSubmit}></PrimaryButton>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DefaultButton text={"Cancel"} onClick={() => this.props.onSuccess()}></DefaultButton>
                                            </Grid>
                                        </>
                                }
                            </Grid>
                        </>
                    )}
                </form>
            </LayoutForm>
        );
    }
}