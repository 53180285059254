import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentPatron as IncidentPatronDTO, IncidentOptions as IncidentOptionsDTO } from "../../../../common/dto/AusComply.dtos";
import LayoutForm from '../../../layout/LayoutForm';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import FormWarningMessage from '../../../alerts/FormWarningMessage';
import IncidentPatronGeneralNotes from './IncidentPatronGeneralNotes';
import IncidentPatronAppearanceAlt from './IncidentPatronAppearanceAlt';
import IncidentPatronAddress from './IncidentPatronAddress';
import Tabs from '../../../common/Tabs';

export interface IIncidentPatronProps {
    incident: IncidentDTO;
    incidentPatron: IncidentPatronDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdated: Function;
    onUploadPatronFiles: Function;
    contactTracing: boolean;
    isUploading: boolean;
    theme: any;
    classes: any;
}

class IncidentPatron extends Component<IIncidentPatronProps, any> {
    constructor(props: IIncidentPatronProps) {
        super(props)
        this.state = {
            error: "",
            tabValue: 0,
            alternateView: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onAttachmentsUpdated = this.onAttachmentsUpdated.bind(this);
        this.toggleAlternateView = this.toggleAlternateView.bind(this);
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
    };

    toggleAlternateView = () => {
        this.setState({ alternateView: !this.state.alternateView });
    };

    onSelectionChanged(fieldName: string, value: any) {
        let patron = { ...this.props.incidentPatron };
        patron[fieldName] = value;
        this.props.onUpdated(patron);
    }

    onAttachmentsUpdated(patron: IncidentPatronDTO) {
        this.props.onUpdated(patron);
    }

    render() {
        let patron = this.props.incidentPatron;
        let underAgeLockedWarning;
        if (patron.isUnderAgeLocked) {
            underAgeLockedWarning = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormWarningMessage><LockIcon style={{ fontSize: '1em' }} />&nbsp;Some content is locked as this patron is underage</FormWarningMessage>
                    </Grid>
                </Grid >
            );
        }
        return (
            <>
                <div style={{ display: 'display-block', marginTop: '10px' }}>
                    <Tabs selected={this.state.tabValue} labels={["General", "Appearance", "Address"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
                </div>
                <LayoutForm
                    fullWidth={true}
                    error={this.state.error}
                >
                    <form>
                        {underAgeLockedWarning}
                        <Grid container spacing={0}>
                            <Grid item xs={12}>

                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {this.state.tabValue === 0 && (
                                    <IncidentPatronGeneralNotes
                                        incidentPatron={patron}
                                        incident={this.props.incident}
                                        incidentOptions={this.props.incidentOptions}
                                        onUploadPatronFiles={this.props.onUploadPatronFiles}
                                        contactTracing={this.props.contactTracing}
                                        isUploading={this.props.isUploading}
                                        onUpdated={this.props.onUpdated} />
                                )}
                                {this.state.tabValue === 1 && (
                                    <IncidentPatronAppearanceAlt
                                        incidentPatron={patron}
                                        incidentOptions={this.props.incidentOptions}
                                        onUpdated={this.props.onUpdated} />
                                )}
                                {this.state.tabValue === 2 && (
                                    <IncidentPatronAddress
                                        incidentPatron={patron}
                                        incidentOptions={this.props.incidentOptions}
                                        contactTracing={this.props.contactTracing}
                                        onUpdated={this.props.onUpdated} />
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </LayoutForm>
            </>);
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPatron);