import React, { Component } from 'react';
import { Paging as PagingDto } from '../../common/dto/AusComply.dtos';
import Media from "react-media";
import Button from '@material-ui/core/Button';
import PageSize from './PageSize';

export interface IPagingProps {
    paging: PagingDto;
    onPageSelected?: Function;
    onPageSizeSelected?: Function;
    label?: string;
}

class Paging extends React.PureComponent<IPagingProps, any> {

    constructor(props: IPagingProps){
        super(props)
        this.onPageSelected = this.onPageSelected.bind(this);
        this.onPageSizeSelected = this.onPageSizeSelected.bind(this);
    }

    onPageSelected(page){
        if(this.props.onPageSelected){
            this.props.onPageSelected(page);
        }
    }

    onPageSizeSelected(page, pageSize) {
        if(this.props.onPageSelected){
            this.props.onPageSelected(page);
        }
    }

    render() {
        let pageLinks:any = [];
        let pageMobileLinks:any = [];
        let pageMinPage = this.props.paging.page;
        let pageMaxPage = this.props.paging.page;
        let pageMinMobilePage = this.props.paging.page;
        let pageMaxMobilePage = this.props.paging.page;
        let pageLinksToShowDesktop = 12;
        let pageLinksToShowMobile = 6;
        let showFirstButton = this.props.paging.page > 1;
        let showLastButton = this.props.paging.pages > 1 && this.props.paging.page < this.props.paging.pages;

        if (showFirstButton) {
            pageLinksToShowDesktop--;
            pageLinksToShowMobile--;
        }
        if (showLastButton) {
            pageLinksToShowDesktop--;
            pageLinksToShowMobile--;
        }

        if (pageLinksToShowDesktop > this.props.paging.pages) {
            pageLinksToShowDesktop = this.props.paging.pages;
        }

        if (pageLinksToShowMobile > this.props.paging.pages) {
            pageLinksToShowMobile = this.props.paging.pages;
        }

        var i = 100;
        while (i > 0 && (pageLinksToShowDesktop > 0 || pageLinksToShowMobile > 0)) {
            // next
            if (pageLinksToShowDesktop > 0 && pageMinPage > 1) {
                pageMinPage--;
                pageLinksToShowDesktop--;
            }
            if (pageLinksToShowMobile > 0 && pageMinMobilePage > 1) {
                pageMinMobilePage--;
                pageLinksToShowMobile--;
            }
            // previous
            if (pageLinksToShowDesktop > 0 && this.props.paging.pages > pageMaxPage) {
                pageMaxPage++;
                pageLinksToShowDesktop--;
            }
            if (pageLinksToShowMobile > 0 && this.props.paging.pages > pageMaxMobilePage) {
                pageMaxMobilePage++;
                pageLinksToShowMobile--;
            }
            i--; // safety
        }

        let showPreviousButton = false;
        let showNextButton = false;
        if (pageMinPage > 1) {
            pageMinPage++;
            showPreviousButton = true;
        }
        if (pageMaxPage < this.props.paging.pages) {
            pageMaxPage--;
            showNextButton = true;
        }

        let showPreviousMobileButton = false;
        let showNextMobileButton = false;
        if (pageMinMobilePage > 1) {
            pageMinMobilePage++;
            showPreviousMobileButton = true;
        }

        if (pageMaxMobilePage < this.props.paging.pages) {
            pageMaxMobilePage--;
            showNextMobileButton = true;
        }

        // build the buttons list
        if (showFirstButton) {
            pageLinks.push(<Button key={"first"} variant="outlined" size="small" onClick={() => this.onPageSelected(1)}>&lt;&lt;</Button>);
            pageMobileLinks.push(<Button key={"first"} variant="outlined" size="small"  onClick={() => this.onPageSelected(1)}>&lt;&lt;</Button>);
        }
        if (showPreviousButton) {
            pageLinks.push(<Button key={"previous"} variant="outlined" size="small"  onClick={() => this.onPageSelected(this.props.paging.page - 1)}>...</Button>);
        }
        if (showPreviousMobileButton) {
            pageMobileLinks.push(<Button key={"previous"} variant="outlined"  size="small" onClick={() => this.onPageSelected(this.props.paging.page - 1)}>...</Button>);
        }
        for (let i = 0; i < this.props.paging.pages; i++) {
            if (i + 1 === this.props.paging.page)
            {
                if (i + 1 >= pageMinPage && i + 1 <= pageMaxPage) {
                    pageLinks.push(<Button key={i} variant="contained" size="small"  onClick={() => this.onPageSelected(i + 1)}>{i + 1}</Button>);
                }
                if (i + 1 >= pageMinMobilePage && i + 1 <= pageMaxMobilePage) {
                    pageMobileLinks.push(<Button key={i} variant="contained" size="small"  onClick={() => this.onPageSelected(i + 1)}>{i + 1}</Button>);
                }
            } else {
                if (i + 1 >= pageMinPage && i + 1 <= pageMaxPage) {
                    pageLinks.push(<Button key={i} variant="outlined" size="small"  onClick={() => this.onPageSelected(i + 1)}>{i + 1}</Button>);
                }
                if (i + 1 >= pageMinMobilePage && i + 1 <= pageMaxMobilePage) {
                    pageMobileLinks.push(<Button key={i} variant="outlined" size="small"  onClick={() => this.onPageSelected(i + 1)}>{i + 1}</Button>);
                }
            }
        }
        if (showNextButton) {
            pageLinks.push(<Button key={"next"} variant="outlined" size="small"  onClick={() => this.onPageSelected(this.props.paging.page + 1)}>...</Button>);
        }
        if (showNextMobileButton) {
            pageMobileLinks.push(<Button key={"next"} variant="outlined" size="small"  onClick={() => this.onPageSelected(this.props.paging.page + 1)}>...</Button>);
        }
        if (showLastButton) {
            pageLinks.push(<Button key={"last"} variant="outlined" size="small"  onClick={() => this.onPageSelected(this.props.paging.pages)}>&gt;&gt;</Button>);
            pageMobileLinks.push(<Button key={"last"} variant="outlined" size="small"  onClick={() => this.onPageSelected(this.props.paging.pages)}>&gt;&gt;</Button>);
        }

        return (
            <div className="paging" style={{margin: '10px 0', textAlign: 'center'}}>
                <Media query="(max-width: 799px)">
                    {matches =>
                        matches ? (<div>{ pageMobileLinks }</div>) : (<div>{ pageLinks }</div>)
                    }
                </Media>
                {this.props.onPageSizeSelected && (<div style={{ display: "block"}}>
                    <PageSize paging={this.props.paging} onPageSizeSelected={this.props.onPageSizeSelected} label={this.props.label}/>
                </div>)}
            </div>
        );
    }
}

export default Paging;
