import { connect } from 'react-redux';
import {
    adminDashboardFilterRequest,
    adminDashboardRequest,
    adminDashboardSetFilter,
    adminDashboardSetOptions,
    adminDashboardTotalsRequest,
    adminDashboardOverviewQuickRequest,
    adminDashboardIncidentTotalsRequest,
    adminDashboardUserTotalsRequest

} from '../common/actions/adminDashboard';
import { loadAllReferenceData } from '../common/actions/referenceData';
import Dashboard from '../components/admin/dashboard/Dashboard';

const isLoading = (state) =>
{
    if (state.adminDashboard.isLoading) {
        return true;
    }
    if (state.referenceData.incidentTypesLoading) {
        return true;
    }
    if (state.referenceData.incidentCategoryTypesLoading) {
        return true;
    }
    return false;
}

const mapStateToProps = state => {
    return {
        isLoading: isLoading(state),
        isLoadingIncidents: state.adminDashboard.isLoadingIncidents,
        filter: state.adminDashboard.filter,
        incidents: state.adminDashboard.filteredIncidents,
        venueMarkers: state.adminDashboard.filteredVenueMarkers,
        options: state.adminDashboard.options,
        totals: state.adminDashboard.totals,
        heatmapData: state.adminDashboard.heatmap,
        overviewQuick: state.adminDashboard.overviewQuick,
        incidentTotals: state.adminDashboard.incidentTotals,
        userTotals: state.adminDashboard.userTotals,
        incidentCategoryTypes: state.referenceData.incidentCategoryTypes,
        incidentTypes: state.referenceData.incidentTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(adminDashboardSetFilter(filter))
        },
        onResetFilter: () => {
            dispatch(adminDashboardFilterRequest())
        },
        onLoad: () => {
            dispatch(adminDashboardRequest())
        },
        onSetOptions: (options) => {
            dispatch(adminDashboardSetOptions(options))
        },
        onLoadTotals: () => {
            dispatch(adminDashboardTotalsRequest())
        },
        onLoadOverviewQuick: () => {
            dispatch(adminDashboardOverviewQuickRequest())
        },
        onLoadIncidentTotals: () => {
            dispatch(adminDashboardIncidentTotalsRequest())
        },
        onLoadUserTotals: () => {
            dispatch(adminDashboardUserTotalsRequest())
        },
        onLoadReferenceData: () => {
            dispatch(loadAllReferenceData());
        }
        
    }
}

const AdminDashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

export default AdminDashboardContainer