import React, { Component } from 'react';
import { UserSignOnStatus } from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import Map from '../common/Map'
import LayoutForm from '../layout/LayoutForm'
import PageTitle from '../common/PageTitle'
import FormWarningMessage from '../alerts/FormWarningMessage'
import FormErrorMessage from '../alerts/FormErrorMessage'
import TextControlGroup from '../controls/TextControlGroup'
import ControlGroup from '../controls/ControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup'
import PasswordControlGroup from '../controls/PasswordControlGroup'
import { withRouter } from "react-router";
import IncidentSummaryCard from '../incidents/IncidentSummaryCard';
import NoRows from '../alerts/NoRows';
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';
import Location from '../common/Location';
import View from '../common/View';


export interface ISignOffProps {
    userSignOnStatusId: number,
    isLoading: boolean,
    isSaving: boolean,
    saveError: string,
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    userSignOnStatus: UserSignOnStatus;
    signOffDetails: any,
    onRefreshLocation: Function;
    onRefresh: Function;
    onSignOff: Function;
    history: any;
    location: any;
    canViewSignOnRegister: boolean;
}

export interface ISignOffState {
    error: string;
    errors: any;
    password: string;
    isAcknowledged: boolean;
}

class SignOff extends React.PureComponent<ISignOffProps, ISignOffState> {
    constructor(props: ISignOffProps) {
        super(props);

        this.onRefresh = this.onRefresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onReturn = this.onReturn.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onAcknowledgeChanged = this.onAcknowledgeChanged.bind(this);

        this.state = {
            error: "",
            errors: {},
            password: "",
            isAcknowledged: false
        }
    }

    componentDidMount() {
        if (!this.props.isLoading && !this.props.isLocationLoading) {
            this.props.onRefreshLocation();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // on changed...
        if (prevProps.isLocationLoading && !this.props.isLocationLoading) {
            this.props.onRefresh(this.props.userSignOnStatusId);
        }
        if (prevProps.userSignOnStatus != this.props.userSignOnStatus && !this.props.isLoading) {

        }

        // save complete
        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.saveError) {
                this.setState({
                    errors: {},
                    error: this.props.saveError
                });
            } else {
                // save was successful - redirect back
                this.onReturn();
            }
        }


    }

    onRefresh() {
        this.props.onRefreshLocation();
    }

    onPasswordChanged(value) {
        if (value != null) {
            this.setState({ password: value });
        }
    }

    onAcknowledgeChanged(value) {
        if (value != null) {
            this.setState({ isAcknowledged: value });
        }
    }

    onSave() {

        // Validate
        let errors = {}
        let error = "";

        if (!this.state.password || this.state.password.length === 0) {
            errors["password"] = "Password is required";
        }

        if (Object.keys(errors).length > 0) {
            if (Object.keys(errors).length > 1) {
                error = "Please fix the highlighted fields and try again";
            } else if (Object.keys(errors).length === 1) {
                error = errors[Object.keys(errors)[0]];
            }
            this.setState({ errors: errors, error: error });
            return;
        }

        this.setState({
            errors: {},
            error: ""
        });

        // saving
        var signOnStatus = this.props.userSignOnStatus;
        signOnStatus.password = this.state.password;
        this.props.onSignOff(signOnStatus, this.state.isAcknowledged);
    }

    onReturn() {
        if (this.props.canViewSignOnRegister) {
            this.props.history.push('/signonoffregister');
        } else {
            this.props.history.push('/');
        }
        /*
        let location = this.props.location.pathname || "";
        if (location.indexOf('/signonoffregister/history') > -1) {
            this.props.history.push('/signonoffregister/history');
        } else if (location.indexOf('/signonoffregister') > -1) {
            this.props.history.push('/signonoffregister/current');
        } else {
            this.props.history.push('/');
        }
        */
    }

    locationRefreshComponent(children?: any) {
        return <Location
            isLocationLoading={this.props.isLocationLoading}
            latitude={this.props.latitude}
            longitude={this.props.longitude}
            locationFound
            locationError={this.props.locationError}
            onRefreshLocation={this.onRefresh}
        >
            {children}
        </Location>
    }

    render() {
        let locationRefresh;
        let signOffForm;
        let incidentCards;
        let loading = this.props.isLoading || this.props.isLocationLoading;
        if (this.props.signOffDetails.incidents) {
            incidentCards = this.props.signOffDetails.incidents.map((item, index) => {
                return (
                    <IncidentSummaryCard key={item.incidentId} incident={item} />
                );
            });
        }

        let noIncidents;
        if (!this.props.signOffDetails.incidents || this.props.signOffDetails.incidents.length === 0) {
            noIncidents = (
                <NoRows message={"No incidents occured"}>
                </NoRows>
            );
        }
        let name = this.props.userSignOnStatus.givenName + " " + this.props.userSignOnStatus.surname;

        let alternateMessage;
        if (!this.props.userSignOnStatus.isSignedOn) {
            alternateMessage = (
                <span>The sign on was not found or it has already been signed off</span>
            );
        } else if (!this.props.signOffDetails.isInRangeOfVenue && !this.props.signOffDetails.allowOutOfRangeSignIn) {
            if (!this.props.locationFound) {
                alternateMessage = (
                    <span>We could not determine your location. Please ensure location services are enabled on your device and try again or speak to your supervisor.</span>
                );
                locationRefresh = this.locationRefreshComponent();                
            } else {
                alternateMessage = (
                    <span>You do not appear to be within range of your selected venue, return to the venue to sign off or contact your manager.</span>
                );
                locationRefresh = this.locationRefreshComponent();
            }
        }
        let warning;
        if (!this.props.signOffDetails.isInRangeOfVenue && this.props.signOffDetails.allowOutOfRangeSignIn) {
            let warning2 = <View style={{ paddingLeft: '10px', paddingRight: '10px' }}><FormWarningMessage warning="You do not appear to be within range of your selected venue."></FormWarningMessage></View>;
            locationRefresh = this.locationRefreshComponent(warning2);
            //locationRefresh = (<Map hideByDefault={true} latitude={this.props.latitude} longitude={this.props.longitude} onRefreshLocation={this.onRefresh} />);
        }
        let acknowledgeIncidents;
        if (this.props.signOffDetails.acknowledgmentRequired) {
            acknowledgeIncidents = (
                <div>
                    <ControlGroup text="">
                        {incidentCards}
                        {noIncidents}
                    </ControlGroup>
                    <CheckboxControlGroup text="I note and acknowledge the recorded incidents and register details over this period"
                        defaultValue={this.state.isAcknowledged}
                        onChanged={(value) => this.onAcknowledgeChanged(value)}
                    />
                </div>
            );
        }

        if (this.props.userSignOnStatus) {
            signOffForm = (
                <>
                    <TextControlGroup text="Venue"
                        defaultValue={this.props.userSignOnStatus.venue}
                        readonly={true}></TextControlGroup>
                    <TextControlGroup text="Signed on user"
                        defaultValue={name}
                        readonly={true} />
                    <TextControlGroup text="Sign off date"
                        defaultValue={this.props.signOffDetails.signOutDateDisplay}
                        readonly={true} />

                    {acknowledgeIncidents}
                    {/* decoy control for autofill */}
                    <input style={{ border: 'none', fontSize: '1px', color: 'transparent', backgroundColor: 'transparent' }} name="username" id="username" />
                    <PasswordControlGroup
                        text="Password"
                        error={this.state.errors["password"]}
                        onChange={this.onPasswordChanged}
                    />
                </>
            );
        }

        return (
            <PageLayout
                headerText={"Sign Off Register"}
                loading={loading}
                saving={this.props.isSaving}
                error={this.state.error}
                notFullWidth={true}
                hideQuickActions={true}
                alternateMessage={alternateMessage}
                extraMessages={locationRefresh}
                footerLeftContent={<TransparentButton text="Cancel" onClick={this.onReturn} />}
                footerRightContent={<PrimaryButton text="Sign off"
                    onClick={this.onSave}
                    disabled={this.props.signOffDetails.acknowledgmentRequired && !this.state.isAcknowledged}
                />}
            >
                {warning}
                {signOffForm}
            </PageLayout>
        );

    }
}

export default withRouter(SignOff)