export const
    SECURITY_FIRM_REQUEST = "SECURITY_FIRM_REQUEST",
    SECURITY_FIRM_REQUEST_SUCCESS = "SECURITY_FIRM_REQUEST_SUCCESS",
    SECURITY_FIRM_REQUEST_FAILURE = "SECURITY_FIRM_REQUEST_FAILURE",
    SECURITY_FIRM_UPDATE = "SECURITY_FIRM_UPDATE",
    SECURITY_FIRM_SAVE_REQUEST = "SECURITY_FIRM_SAVE_REQUEST",
    SECURITY_FIRM_SAVE_REQUEST_SUCCESS = "SECURITY_FIRM_SAVE_REQUEST_SUCCESS",
    SECURITY_FIRM_SAVE_REQUEST_FAILURE = "SECURITY_FIRM_SAVE_REQUEST_FAILURE",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REFRESH = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REFRESH",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_RESET = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_RESET",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_SET = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_SET",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS",
    SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE = "SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE",
    SECURITY_FIRM_CONTACTS_REQUEST = "SECURITY_FIRM_CONTACTS_REQUEST",
    SECURITY_FIRM_CONTACTS_REFRESH = "SECURITY_FIRM_CONTACTS_REFRESH",
    SECURITY_FIRM_CONTACTS_REQUEST_SUCCESS = "SECURITY_FIRM_CONTACTS_REQUEST_SUCCESS",
    SECURITY_FIRM_CONTACTS_REQUEST_FAILURE = "SECURITY_FIRM_CONTACTS_REQUEST_FAILURE",
    SECURITY_FIRM_CONTACT_RESET = "SECURITY_FIRM_CONTACT_RESET",
    SECURITY_FIRM_CONTACT_SET = "SECURITY_FIRM_CONTACT_SET",
    SECURITY_FIRM_CONTACT_REQUEST = "SECURITY_FIRM_CONTACT_REQUEST",
    SECURITY_FIRM_CONTACT_REQUEST_SUCCESS = "SECURITY_FIRM_CONTACT_REQUEST_SUCCESS",
    SECURITY_FIRM_CONTACT_REQUEST_FAILURE = "SECURITY_FIRM_CONTACT_REQUEST_FAILURE",
    SECURITY_FIRM_CONTACT_CREATE_REQUEST = "SECURITY_FIRM_CONTACT_CREATE_REQUEST",
    SECURITY_FIRM_CONTACT_CREATE_REQUEST_SUCCESS = "SECURITY_FIRM_CONTACT_CREATE_REQUEST_SUCCESS",
    SECURITY_FIRM_CONTACT_CREATE_REQUEST_FAILURE = "SECURITY_FIRM_CONTACT_CREATE_REQUEST_FAILURE",
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST = "SECURITY_FIRM_CONTACT_UPSERT_REQUEST",
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS = "SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS",
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST_FAILURE = "SECURITY_FIRM_CONTACT_UPSERT_REQUEST_FAILURE",

    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST = "SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST",
    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS = "SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS",
    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE = "SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE",

    SECURITY_FIRM_DOCUMENTS_REQUEST = "SECURITY_FIRM_DOCUMENTS_REQUEST",
    SECURITY_FIRM_DOCUMENTS_REQUEST_SUCCESS = "SECURITY_FIRM_DOCUMENTS_REQUEST_SUCCESS",
    SECURITY_FIRM_DOCUMENTS_REQUEST_FAILURE = "SECURITY_FIRM_DOCUMENTS_REQUEST_FAILURE",
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE = "SECURITY_FIRM_OTHER_DOCUMENTS_SAVE",
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_SUCCESS = "SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_SUCCESS",
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_FAILURE = "SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_FAILURE",
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE = "SECURITY_FIRM_OTHER_DOCUMENT_REMOVE",
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_SUCCESS = "SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_SUCCESS",
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_FAILURE = "SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_FAILURE",
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE = "SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE",
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS = "SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS",
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_FAILURE = "SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_FAILURE",
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE = "SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE",
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS = "SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS",
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_FAILURE = "SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_FAILURE",

    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST = "SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST",
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_SUCCESS = "SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_SUCCESS",
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_FAILURE = "SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_FAILURE"
    
    ;

    
export function securityFirmVenueAssociationsRequest(forSecurityFirmId, page, pageSize) {
    return {
        type: SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST,
        forSecurityFirmId,
        page,
        pageSize
    };
}

export function securityFirmVenueAssociationsRequestSuccess(securityFirmVenueAssociations, paging) {
    return {
        type: SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_SUCCESS,
        securityFirmVenueAssociations,
        paging
    };
}

export function securityFirmVenueAssociationsRequestFailure(error) {
    return {
        type: SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_FAILURE,
        error
    };
}

    export function securityFirmDocumentsRequest(securityFirmId, showObsoleteDocuments) {
        return {
            type: SECURITY_FIRM_DOCUMENTS_REQUEST,
            securityFirmId,
            showObsoleteDocuments
        };
    }
    
    export function securityFirmDocumentsRequestSuccess(securityFirmOtherDocuments, securityFirmOtherDocumentTypes, securityFirmComplianceDocuments, securityFirmComplianceDocumentTypes) {
        return {
            type: SECURITY_FIRM_DOCUMENTS_REQUEST_SUCCESS,
            securityFirmOtherDocuments,
            securityFirmOtherDocumentTypes,
            securityFirmComplianceDocuments,
            securityFirmComplianceDocumentTypes
        };
    }
    
    export function securityFirmDocumentsRequestFailure(error) {
        return {
            type: SECURITY_FIRM_DOCUMENTS_REQUEST_FAILURE,
            error
        };
    }
    
    export function securityFirmSaveOtherDocuments(forSecurityFirmId, documentTypeId, files) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENTS_SAVE,
            forSecurityFirmId,
            documentTypeId,
            files
        };
    }
    
    export function securityFirmSaveOtherDocumentsSuccess(securityFirmOtherDocuments) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_SUCCESS,
            securityFirmOtherDocuments
        };
    }
    
    export function securityFirmSaveOtherDocumentsFailure(error) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_FAILURE,
            error
        };
    }
    
    export function securityFirmRemoveOtherDocument(forSecurityFirmId, otherDocument) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENT_REMOVE,
            forSecurityFirmId,
            otherDocument
        };
    }
    
    export function securityFirmRemoveOtherDocumentSuccess(securityFirmOtherDocuments) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_SUCCESS,
            securityFirmOtherDocuments
        };
    }
    
    export function securityFirmRemoveOtherDocumentFailure(error) {
        return {
            type: SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_FAILURE,
            error
        };
    }
    
    export function securityFirmSaveComplianceDocuments(forSecurityFirmId, documentTypeId, visibleToVenue, expiryDate, files) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE,
            forSecurityFirmId,
            documentTypeId,
            visibleToVenue,
            expiryDate,
            files
        };
    }
    
    export function securityFirmSaveComplianceDocumentsSuccess(securityFirmComplianceDocuments) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
            securityFirmComplianceDocuments
        };
    }
    
    export function securityFirmSaveComplianceDocumentsFailure(error) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
            error
        };
    }
    
    export function securityFirmRemoveComplianceDocument(forSecurityFirmId, complianceDocument) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE,
            forSecurityFirmId,
            complianceDocument
        };
    }
    
    export function securityFirmRemoveComplianceDocumentSuccess(securityFirmComplianceDocuments) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
            securityFirmComplianceDocuments
        };
    }
    
    export function securityFirmRemoveComplianceDocumentFailure(error) {
        return {
            type: SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
            error
        };
    }

export function securityFirmUpdate(securityFirm) {
    return {
        type: SECURITY_FIRM_UPDATE,
        securityFirm
    }
}

export function securityFirmSaveRequest() {
    return {
        type: SECURITY_FIRM_SAVE_REQUEST
    };
}

export function securityFirmSaveRequestSuccess(securityFirm) {
    return {
        type: SECURITY_FIRM_SAVE_REQUEST_SUCCESS,
        securityFirm
    };
}

export function securityFirmSaveRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SAVE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmRequest(securityFirmId, includeOptions) {
    return {
        type: SECURITY_FIRM_REQUEST,
        securityFirmId,
        includeOptions
    };
}

export function securityFirmRequestSuccess(securityFirm, includesOptions, states, securityLicenceClasses) {
    return {
        type: SECURITY_FIRM_REQUEST_SUCCESS,
        securityFirm,
        states,
        includesOptions,
        securityLicenceClasses
    };
}

export function securityFirmRequestFailure(error) {
    return {
        type: SECURITY_FIRM_REQUEST_FAILURE,
        error
    };
}

export function securityFirmIncidentCategoryTypesRefresh() {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REFRESH
    }
}

export function securityFirmIncidentCategoryTypesRequest(page, pageSize, filter) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function securityFirmIncidentCategoryTypesRequestSuccess(incidentCategoryTypes, paging, filter) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS,
        incidentCategoryTypes,
        paging,
        filter
    };
}

export function securityFirmIncidentCategoryTypesRequestFailure(error) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE,
        error
    };
}

export function securityFirmIncidentCategoryTypeReset() {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_RESET
    };
}

export function securityFirmIncidentCategoryTypeSet(incidentCategoryType) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_SET,
        incidentCategoryType
    };
}

export function securityFirmIncidentCategoryTypeRequest(incidentCategoryTypeId) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST,
        incidentCategoryTypeId
    };
}

export function securityFirmIncidentCategoryTypeRequestSuccess(incidentCategoryType) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function securityFirmIncidentCategoryTypeRequestFailure(error) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmIncidentCategoryTypeCreateRequest(securityFirmId) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST,
        securityFirmId
    };
}

export function securityFirmIncidentCategoryTypeCreateRequestSuccess(incidentCategoryType) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function securityFirmIncidentCategoryTypeCreateRequestFailure(error) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmIncidentCategoryTypeUpsertRequest() {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST
    };
}

export function securityFirmIncidentCategoryTypeUpsertRequestSuccess(incidentCategoryType) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function securityFirmIncidentCategoryTypeUpsertRequestFailure(error) {
    return {
        type: SECURITY_FIRM_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}


export function securityFirmContactsRefresh() {
    return {
        type: SECURITY_FIRM_CONTACTS_REFRESH
    }
}

export function securityFirmContactsRequest(page, pageSize, filter) {
    return {
        type: SECURITY_FIRM_CONTACTS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function securityFirmContactsRequestSuccess(securityFirmContacts, paging, filter) {
    return {
        type: SECURITY_FIRM_CONTACTS_REQUEST_SUCCESS,
        securityFirmContacts,
        paging,
        filter
    };
}

export function securityFirmContactsRequestFailure(error) {
    return {
        type: SECURITY_FIRM_CONTACTS_REQUEST_FAILURE,
        error
    };
}

export function securityFirmContactReset() {
    return {
        type: SECURITY_FIRM_CONTACT_RESET
    };
}

export function securityFirmContactSet(securityFirmContact) {
    return {
        type: SECURITY_FIRM_CONTACT_SET,
        securityFirmContact
    };
}

export function securityFirmContactRequest(securityFirmContactId) {
    return {
        type: SECURITY_FIRM_CONTACT_REQUEST,
        securityFirmContactId
    };
}

export function securityFirmContactRequestSuccess(securityFirmContact, securityFirmContactTypes, securityFirmUsers) {
    return {
        type: SECURITY_FIRM_CONTACT_REQUEST_SUCCESS,
        securityFirmContact,
        securityFirmContactTypes,
        securityFirmUsers
    };
}

export function securityFirmContactRequestFailure(error) {
    return {
        type: SECURITY_FIRM_CONTACT_REQUEST_FAILURE,
        error
    };
}

export function securityFirmContactCreateRequest(securityFirmId) {
    return {
        type: SECURITY_FIRM_CONTACT_CREATE_REQUEST,
        securityFirmId
    };
}

export function securityFirmContactCreateRequestSuccess(securityFirmContact, securityFirmContactTypes, securityFirmUsers) {
    return {
        type: SECURITY_FIRM_CONTACT_CREATE_REQUEST_SUCCESS,
        securityFirmContact,
        securityFirmContactTypes,
        securityFirmUsers
    };
}

export function securityFirmContactCreateRequestFailure(error) {
    return {
        type: SECURITY_FIRM_CONTACT_CREATE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmContactUpsertRequest() {
    return {
        type: SECURITY_FIRM_CONTACT_UPSERT_REQUEST
    };
}

export function securityFirmContactUpsertRequestSuccess(securityFirmContact) {
    return {
        type: SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS,
        securityFirmContact
    };
}

export function securityFirmContactUpsertRequestFailure(error) {
    return {
        type: SECURITY_FIRM_CONTACT_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function securityFirmSubscriptionSummaryRequest() {
    return {
        type: SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST
    };
}

export function securityFirmSubscriptionSummaryRequestSuccess(securityFirmSubscriptionSummary) {
    return {
        type: SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS,
        securityFirmSubscriptionSummary
    };
}

export function securityFirmSubscriptionSummaryRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE,
        error
    };
}