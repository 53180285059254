import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SimpleDate } from "../../common/dto/AusComply.dtos";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import DarkButton from '../controls/DarkButton';
import Grid from '@material-ui/core/Grid';

export interface ISimpleDateControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    rightText?: string;
    error?: string;
    date?: SimpleDate;
    readonly?: boolean;
    onChange?: Function;
    onEnter?: Function;
    labelAbove?: boolean;
}
class SimpleDateControlGroup extends React.PureComponent<ISimpleDateControlGroupProps, any> {
    constructor(props: ISimpleDateControlGroupProps) {
        super(props)
        this.state = {
            date: "",
            isEmpty: true
        }

        this.onClear = this.onClear.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onBlurDate = this.onBlurDate.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        let date = this.convertedDate(this.props.date, false);
        let isEmpty = false;
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
        }
        this.setState({
            date,
            isEmpty
        });
    }


    componentDidUpdate(prevProps) {
        let date = this.convertedDate(this.props.date, false);
        let datePrevious = this.convertedDate(prevProps.date, false);
        let isEmpty = this.state.isEmpty;
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
        }
        if (datePrevious != date) {
            this.setState({
                date,
                isEmpty
            });
        }
    }

    convertedDate(value, force) {
        if (value && (!value.isEmpty || force)) {
            return [value.year, "-",
            (value.month > 9 ? '' : '0') + value.month, "-",
            (value.day > 9 ? '' : '0') + value.day
            ].join('');
        }
        return "";
    }

    onChangeDate(event) {
        if (event.target.value) {
            this.setState({
                date: event.target.value.toString(),
                isEmpty: false
            });
        }
    }

    onBlurDate(event) {
        this.onUpdated(false);
    }

    onClear() {
        this.setState({
            date: "",
            isEmpty: true
        }, () => {
            this.onUpdated(false);
        });

    }

    onUpdated(wasEnter) {
        var newDate = new SimpleDate();
        if (this.props.date) {
            newDate = this.props.date;
        }
        if (this.props.onChange && newDate) {
            newDate.isEmpty = this.state.isEmpty;
            if (this.state.date) {
                let dateValue = new Date(this.state.date);
                if (isNaN(dateValue.getFullYear())) {
                    newDate.year = new Date().getFullYear();
                } else {
                    newDate.year = dateValue.getFullYear();
                }
                if (isNaN(dateValue.getMonth() + 1)) {
                    newDate.month = new Date().getMonth() + 1;
                } else {
                    newDate.month = dateValue.getMonth() + 1;
                }
                if (isNaN(dateValue.getDate())) {
                    newDate.day = new Date().getDate();
                } else {
                    newDate.day = dateValue.getDate();
                }
            }
            this.props.onChange(newDate);
            if (wasEnter && this.props.onEnter) {
                this.props.onEnter();
            }
        }
    }

    onKeyPress = (event) => {
        if (event.key == 'Enter') {
            this.onUpdated(true);
        }
    }

    render() {

        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;

        return (
            <ControlGroup text={this.props.text} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <InputBase
                                type="date"
                                value={this.state.date}
                                onBlur={this.onBlurDate}
                                onChange={this.onChangeDate}
                                disabled={this.props.readonly}
                                onKeyPress={this.onKeyPress}
                                style={style}
                                error={this.props.error ? true : false}
                                inputProps={{max: '9999-12-31'}}
                                //classes={{ root: classes.inputControl, input: classes.inputRoot }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {!this.props.readonly && (
                                <DarkButton onClick={this.onClear} >X</DarkButton>
                            )}
                        </Grid>
                    </Grid>


                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SimpleDateControlGroup);