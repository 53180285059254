export const
    LOAD_ALL_REFERENCE_DATA = "LOAD_ALL_REFERENCE_DATA",
    LOAD_STATES_REQUEST = "LOAD_STATES_REQUEST",
    LOAD_STATES_SUCCESS = "LOAD_STATES_SUCCESS",
    LOAD_STATES_FAILURE = "LOAD_STATES_FAILURE",
    LOAD_INDUSTRY_CATEGORIES_REQUEST = "LOAD_INDUSTRY_CATEGORIES_REQUEST",
    LOAD_INDUSTRY_CATEGORIES_SUCCESS = "LOAD_INDUSTRY_CATEGORIES_SUCCESS",
    LOAD_INDUSTRY_CATEGORIES_FAILURE = "LOAD_INDUSTRY_CATEGORIES_FAILURE",
    LOAD_REFERENCE_DATA_VENUES_REQUEST = "LOAD_REFERENCE_DATA_VENUES_REQUEST",
    LOAD_REFERENCE_DATA_VENUES_SUCCESS = "LOAD_REFERENCE_DATA_VENUES_SUCCESS",
    LOAD_REFERENCE_DATA_VENUES_FAILURE = "LOAD_REFERENCE_DATA_VENUES_FAILURE",
    LOAD_SECURITY_FIRMS_REQUEST = "LOAD_SECURITY_FIRMS_REQUEST",
    LOAD_SECURITY_FIRMS_SUCCESS = "LOAD_SECURITY_FIRMS_SUCCESS",
    LOAD_SECURITY_FIRMS_FAILURE = "LOAD_SECURITY_FIRMS_FAILURE",
    GET_VENUE_PERSONNEL_REQUEST = "GET_VENUE_PERSONNEL_REQUEST",
    GET_VENUE_PERSONNEL_SUCCESS = "GET_VENUE_PERSONNEL_SUCCESS",
    GET_VENUE_PERSONNEL_FAILURE = "GET_VENUE_PERSONNEL_FAILURE",
    GET_CHECKLIST_STATUS_REQUEST = "GET_CHECKLIST_STATUS_REQUEST",
    GET_CHECKLIST_STATUS_SUCCESS = "GET_CHECKLIST_STATUS_SUCCESS",
    GET_CHECKLIST_STATUS_FAILURE = "GET_CHECKLIST_STATUS_FAILURE",
    GET_INCIDENT_TYPES_REQUEST = "GET_INCIDENT_TYPES_REQUEST",
    GET_INCIDENT_TYPES_SUCCESS = "GET_INCIDENT_TYPES_SUCCESS",
    GET_INCIDENT_TYPES_FAILURE = "GET_INCIDENT_TYPES_FAILURE",
    GET_INCIDENT_CATEGORY_TYPES_REQUEST = "GET_INCIDENT_CATEGORY_TYPES_REQUEST",
    GET_INCIDENT_CATEGORY_TYPES_SUCCESS = "GET_INCIDENT_CATEGORY_TYPES_SUCCESS",
    GET_INCIDENT_CATEGORY_TYPES_FAILURE = "GET_INCIDENT_CATEGORY_TYPES_FAILURE",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE",
    LOAD_USER_ROLE_TYPES_REQUEST = "LOAD_USER_ROLE_TYPES_REQUEST",
    LOAD_USER_ROLE_TYPES_SUCCESS = "LOAD_USER_ROLE_TYPES_SUCCESS",
    LOAD_USER_ROLE_TYPES_FAILURE = "LOAD_USER_ROLE_TYPES_FAILURE",
    LOAD_USER_VENUES_REQUEST = "LOAD_USER_VENUES_REQUEST",
    LOAD_USER_VENUES_SUCCESS = "LOAD_USER_VENUES_SUCCESS",
    LOAD_USER_VENUES_FAILURE = "LOAD_USER_VENUES_FAILURE",
    LOAD_USER_SECURITY_FIRMS_REQUEST = "LOAD_USER_SECURITY_FIRMS_REQUEST",
    LOAD_USER_SECURITY_FIRMS_SUCCESS = "LOAD_USER_SECURITY_FIRMS_SUCCESS",
    LOAD_USER_SECURITY_FIRMS_FAILURE = "LOAD_USER_SECURITY_FIRMS_FAILURE";


export function loadUserRoleTypesRequest(forVenueId?: number, forSecurityFirmId?: number) {
    return {
        type: LOAD_USER_ROLE_TYPES_REQUEST,
        forVenueId,
        forSecurityFirmId
    }
}

export function loadUserRoleTypesSuccess(userRoleTypes) {
    return {
        type: LOAD_USER_ROLE_TYPES_SUCCESS,
        userRoleTypes
    };
}

export function loadUserRoleTypesFailure(responseStatus) {
    return {
        type: LOAD_USER_ROLE_TYPES_FAILURE,
        responseStatus
    };
}

export function loadUserVenuesRequest() {
    return {
        type: LOAD_USER_VENUES_REQUEST
    }
}

export function loadUserVenuesSuccess(venues) {
    return {
        type: LOAD_USER_VENUES_SUCCESS,
        venues
    };
}

export function loadUserVenuesFailure(responseStatus) {
    return {
        type: LOAD_USER_VENUES_FAILURE,
        responseStatus
    };
}

export function loadUserSecurityFirmsRequest() {
    return {
        type: LOAD_USER_SECURITY_FIRMS_REQUEST
    }
}

export function loadUserSecurityFirmsSuccess(securityFirms) {
    return {
        type: LOAD_USER_SECURITY_FIRMS_SUCCESS,
        securityFirms
    };
}

export function loadUserSecurityFirmsFailure(responseStatus) {
    return {
        type: LOAD_USER_SECURITY_FIRMS_FAILURE,
        responseStatus
    };
}

export function loadChecklistTemplateCategoriesRequest() {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST
    }
}


export function loadChecklistTemplateCategoriesSuccess(checklistTemplateCategories) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS,
        checklistTemplateCategories
    };
}

export function loadChecklistTemplateCategoriesFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE,
        responseStatus
    };
}

export function loadAllReferenceData() {
    return {
        type: LOAD_ALL_REFERENCE_DATA
    }
}

export function loadStatesRequest() {
    return {
        type: LOAD_STATES_REQUEST
    };
}

export function loadStatesSuccess(states) {
    return {
        type: LOAD_STATES_SUCCESS,
        states
    };
}

export function loadStatesFailure(responseStatus) {
    return {
        type: LOAD_STATES_FAILURE,
        responseStatus
    };
}

export function loadIndustryCategoriesRequest() {
    return {
        type: LOAD_INDUSTRY_CATEGORIES_REQUEST
    };
}

export function loadIndustryCategoriesSuccess(industryCategories) {
    return {
        type: LOAD_INDUSTRY_CATEGORIES_SUCCESS,
        industryCategories
    };
}

export function loadIndustryCategoriesFailure(responseStatus) {
    return {
        type: LOAD_INDUSTRY_CATEGORIES_FAILURE,
        responseStatus
    };
}

export function loadVenuesRequest() {
    return {
        type: LOAD_REFERENCE_DATA_VENUES_REQUEST
    };
}

export function loadVenuesSuccess(venues) {
    return {
        type: LOAD_REFERENCE_DATA_VENUES_SUCCESS,
        venues
    };
}

export function loadVenuesFailure(responseStatus) {
    return {
        type: LOAD_REFERENCE_DATA_VENUES_FAILURE,
        responseStatus
    };
}

export function loadSecurityFirmsRequest() {
    return {
        type: LOAD_SECURITY_FIRMS_REQUEST
    };
}

export function loadSecurityFirmsSuccess(securityFirms) {
    return {
        type: LOAD_SECURITY_FIRMS_SUCCESS,
        securityFirms
    };
}

export function loadSecurityFirmsFailure(responseStatus) {
    return {
        type: LOAD_SECURITY_FIRMS_FAILURE,
        responseStatus
    };
}

export function getVenuePersonnelRequest(venueId) {
    return {
        type: GET_VENUE_PERSONNEL_REQUEST,
        venueId
    };
}
export function getVenuePersonnelSuccess(personnel) {
    return {
        type: GET_VENUE_PERSONNEL_SUCCESS,
        personnel
    };
}

export function getVenuePersonnelFailure(responseStatus) {
    return {
        type: GET_VENUE_PERSONNEL_FAILURE,
        responseStatus
    };
}

export function getChecklistStatusRequest() {
    return {
        type: GET_CHECKLIST_STATUS_REQUEST
    };
}
export function getChecklistStatusSuccess(checklistStatuses) {
    return {
        type: GET_CHECKLIST_STATUS_SUCCESS,
        checklistStatuses
    };
}
export function getChecklistStatusFailure(responseStatus) {
    return {
        type: GET_CHECKLIST_STATUS_FAILURE,
        responseStatus
    };
}

export function getIncidentTypesRequest() {
    return {
        type: GET_INCIDENT_TYPES_REQUEST
    };
}
export function getIncidentTypesSuccess(incidentTypes) {
    return {
        type: GET_INCIDENT_TYPES_SUCCESS,
        incidentTypes
    };
}
export function getIncidentTypesFailure(responseStatus) {
    return {
        type: GET_INCIDENT_TYPES_FAILURE,
        responseStatus
    };
}

export function getIncidentCategoryTypesRequest() {
    return {
        type: GET_INCIDENT_CATEGORY_TYPES_REQUEST
    };
}
export function getIncidentCategoryTypesSuccess(incidentCategoryTypes) {
    return {
        type: GET_INCIDENT_CATEGORY_TYPES_SUCCESS,
        incidentCategoryTypes
    };
}
export function getIncidentCategoryTypesFailure(responseStatus) {
    return {
        type: GET_INCIDENT_CATEGORY_TYPES_FAILURE,
        responseStatus
    };
}
