import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { groupLogic } from '../../../common/logic/groupLogic';

export interface IGroupCardProps {
    group: AusComplyDtos.Group;
    canEdit: boolean;
    onCommand?: Function;
}

export default class GroupCard extends React.Component<IGroupCardProps, any> {
    constructor(props: IGroupCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.group);
        }
    }

    render() {
        if (!this.props.group) {
            return (<></>);
        }

        var commands = groupLogic.getCommands(this.props.group, this.props.canEdit);
        var item = this.props.group;

        return (
            <CardRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.name}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Industries</CardTitle>
                        <CardTypography>{item.industryCategoriesDisplay}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Venues</CardTitle>
                        <CardTypography>{item.venuesDisplay}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Managers</CardTitle>
                        <CardTypography>{item.managersDisplay}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}