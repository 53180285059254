
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const broadcastNotificationLogic = {

    getCommands(broadcastNotification: AusComplyDtos.BroadcastNotification, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            if (!broadcastNotification.isSent) {
                commands.push({ key: "send", name: "Send" });
                commands.push({ key: "test", name: "Test" });
            } else {
                commands.push({ key: "resend", name: "Re-send" });
            }
            commands.push({ key: "clone", name: "Clone" });
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}