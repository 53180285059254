import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TransparentButton from '../../controls/TransparentButton';
import Box from '@material-ui/core/Box';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import FeedImage from './FeedImage';
import NoRowsMessage from '../../common/NoRowsMessage';

export interface IFacialRecognitionFeedProps {
    venueId: number;
    venueLocationId: number;
    feed: AusComplyDtos.FacialRecognitionFeed;
    isLoading: boolean;
    onLoad: Function;
    onSelected?: Function;
    autoRefresh: boolean;
    onSetAutoRefresh: Function;
}

interface IFacialRecognitionFeedState {
    limit: number;
}

export default class FacialRecognitionFeed extends Component<IFacialRecognitionFeedProps, IFacialRecognitionFeedState> {
    constructor(props: IFacialRecognitionFeedProps) {
        super(props)
        this.state = {
            limit: 10
        };
    }

    timer;

    componentDidMount() {
        this.timer = setTimeout(() => this.onRefresh(), 2000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            if (this.props.autoRefresh) {
                this.timer = setTimeout(() => this.onRefresh(), 2000);
            }
        } else if (this.props.venueLocationId != prevProps.venueLocationId) {
            this.onRefresh();
        } else if (!prevProps.autoRefresh && this.props.autoRefresh) {
            this.timer = setTimeout(() => this.onRefresh(), 2000);
        }
    }

    onRefresh() {
        if (!this.props.isLoading) {
            this.props.onLoad(this.props.venueId, this.props.venueLocationId, this.state.limit)
        }
    }

    render() {
        let self = this;
        let canSelect = this.props.onSelected != undefined;

        let label = "Live Feed";
        if (this.props.isLoading) {
            label = "... Live Feed ...";
        }

        let imagesSummary;
        let imagesAll;
        let controls;



        imagesSummary = imagesAll =
            <Box display="flex" flexDirection="row">
                <Box p={1} flex={1} >
                    <p style={{ textAlign: 'center', margin: '5px' }}>Connecting...</p>
                </Box>
            </Box>

        if (this.props.feed == undefined ||
            this.props.feed.venueId != this.props.venueId ||
            this.props.feed.venueLocationId != this.props.venueLocationId) {
            imagesSummary = imagesAll =
                <Box display="flex" flexDirection="row">
                    <Box p={1} flex={1} >
                        <p style={{ textAlign: 'center', margin: '5px' }}>Checking for feed...</p>
                    </Box>
                </Box>
        } else if (!this.props.feed.hasCameras) {
            imagesSummary = imagesAll =
                <Box display="flex" flexDirection="row">
                    <Box p={1} flex={1} >
                        <p style={{ textAlign: 'center', margin: '5px' }}>There is no feed available at this location</p>
                    </Box>
                </Box>
        } else if (this.props.feed.events.length == 0) {
            imagesSummary = imagesAll =
                <Box display="flex" flexDirection="row">
                    <Box p={1} flex={1} >
                        <p style={{ textAlign: 'center', margin: '5px' }}>No feed images available</p>
                    </Box>
                </Box>;
        } else {
            if (this.props.feed && this.props.feed.events) {

                imagesSummary = this.props.feed.events.slice(0, 8).map((item, index) =>
                    <Box p={0} key={"file-box-face-" + index}>
                        <FeedImage feedEvent={item} />
                        {canSelect && (
                            <Box display="flex" flexDirection="row">
                                <Box p={1} flex={1} >
                                </Box>
                                <Box p={1} flex={1} >
                                    <TransparentButton text={"Use"} compact={true} onClick={() => {
                                        if (this.props.onSelected) {
                                            let img = item.poi_display_img && item.poi_display_img != '' ? item.poi_display_img : item.crop_data.face_crop_img;
                                            this.props.onSelected(img, "image/jpeg", item.event_id + ".jpeg", item.match_data.poi_id, item.appearance_data.appearance_id);
                                        }
                                    }} />
                                </Box>
                                <Box p={1} flex={1} >
                                </Box>
                            </Box>

                        )}
                    </Box>
                )
                imagesAll = this.props.feed.events.map((item, index) =>
                    <Box p={0} key={"file-box-face-" + index} >
                        <FeedImage feedEvent={item} />
                        {canSelect && (
                            <Box display="flex" flexDirection="row">
                                <Box p={1} flex={1} >
                                </Box>
                                <Box p={1} flex={1} >
                                    <TransparentButton text={"Use"} compact={true} onClick={() => {
                                        if (this.props.onSelected) {
                                            let img = item.poi_display_img && item.poi_display_img != '' ? item.poi_display_img : item.crop_data.face_crop_img;
                                            this.props.onSelected(img, "image/jpeg", item.event_id + ".jpeg", item.match_data.poi_id, item.appearance_data.appearance_id);
                                        }
                                    }} />
                                </Box>
                                <Box p={1} flex={1} >
                                </Box>
                            </Box>

                        )}
                    </Box>
                )
            }
        }
        if (this.props.feed && this.props.feed.hasCameras) {
            controls = (<Box display="flex" flexDirection="row">
                <Box p={1} flex={1} >

                </Box>
                <Box p={1} flex={0} >
                    <TransparentButton text={"Refresh"} compact={true} noWrap={true} disabled={this.props.isLoading} onClick={() => this.onRefresh()} />
                </Box>
                <Box p={1} flex={0} >
                    <TransparentButton text={"Autorefresh: " + (this.props.autoRefresh ? "on" : "off")} style={{ WhiteSpace: "no-wrap" }} compact={true} noWrap={true} disabled={this.props.isLoading} onClick={() => this.props.onSetAutoRefresh(!this.props.autoRefresh)} />
                </Box>
                <Box p={1} flex={1} >

                </Box>
            </Box>);
        }

        return (
            <CardContainer
                title={label}
                onExpanded={isExpanded => {self.setState({limit: (isExpanded ? 100: 15 ) }, () => self.onRefresh())}}
                expanded={<Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">{imagesAll}</Box>}
                collapsed={<Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">{imagesSummary}</Box>}
            >
                {controls}
            </CardContainer>
        );

    }
}