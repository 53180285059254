import React, { Component } from 'react';
import * as AuscomplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import RadioButtonControl from '../../controls/RadioButtonControl';
import { RadioOption } from '../../../common/dto/common';
import Information from '../../common/Information';

export interface IBroadcastNotificationSendModeProps {
    broadcastNotification: AuscomplyDtos.BroadcastNotification;
    onUpdate: Function;
}

export default class BroadcastNotificationSendMode extends Component<IBroadcastNotificationSendModeProps, any> {
    constructor(props: IBroadcastNotificationSendModeProps) {
        super(props)
        this.state = {
            sendMode: AuscomplyDtos.ngtBroadcastOption.EmailAndSms,
            sendModes: [
                { sendMode: AuscomplyDtos.ngtBroadcastOption.EmailAndSms, name: "Email and SMS" },
                { sendMode: AuscomplyDtos.ngtBroadcastOption.Email, name: "Email" },
                { sendMode: AuscomplyDtos.ngtBroadcastOption.Sms, name: "Sms" }
            ]
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.broadcastNotification && this.props.broadcastNotification.sendMode !== this.state.sendMode) {
            this.setState({
                sendMode: this.props.broadcastNotification.sendMode
            });
        }
    }

    componentDidUpdate(prevProps, preState, snapshot) {
        if (this.props.broadcastNotification && this.props.broadcastNotification.sendMode !== this.state.sendMode) {
            this.setState({
                sendMode: this.props.broadcastNotification.sendMode
            });
        }
    }

    onChange(sendMode: AuscomplyDtos.ngtBroadcastOption) {
        this.setState({ sendMode: sendMode }, () => {
            let updated = { ...this.props.broadcastNotification };
            updated.sendMode = sendMode;
            this.props.onUpdate(updated);
        });
    }

    render() {
        let options: RadioOption[] = [];
        if (this.state.sendModes) {
            this.state.sendModes.forEach(item => {
                options.push({ id: item.sendMode.toString(), name: item.name });
            });
        };

        let message = "Only users with "; // option enabled will receive an email or SMS";
        if (this.props.broadcastNotification.venueId || this.props.broadcastNotification.securityFirmId) {
            message = message + "'Venue / Security Firm Specific Notification' ";
        } else {
            message = message + "'AusComply / Administrator Notification' ";
        }
        message = message + "options enabled will receive an ";
        if (this.props.broadcastNotification.sendMode == AuscomplyDtos.ngtBroadcastOption.Email) {
            message = message + "Email";
        }
        else if (this.props.broadcastNotification.sendMode == AuscomplyDtos.ngtBroadcastOption.Sms) {
            message = message + "Sms";
        }
        else {
            message = message + "Email or SMS";
        }

        return (
            <ControlGroup text={"Mode"}>
                <RadioButtonControl
                    selected={this.state.sendMode}
                    options={options}
                    name={"sendMode"}
                    readonly={this.props.broadcastNotification.isSent}
                    onChange={(value) => this.onChange(value.value)} />
                <br />
                <Information message={message} />
            </ControlGroup>
        );
    }
}