import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CheckboxControl from '../../controls/CheckboxControl'
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import { MulitSelectOption } from '../../../common/dto/common';
import MultiSelectControl from '../../controls/MutiSelectControl';

export interface IDashboardFilterProps {
    filter: AusComplyDTOs.AdminDashboardFilter;
    incidentCategoryTypes: AusComplyDTOs.IncidentCategoryType[],
    incidentTypes: AusComplyDTOs.IncidentType[],
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IDashboardFilterState {
    filter: AusComplyDTOs.AdminDashboardFilter;
    types: MulitSelectOption[];
    categories: MulitSelectOption[];
}

export default class DashboardFilter extends React.Component<IDashboardFilterProps, IDashboardFilterState> {
    constructor(props: IDashboardFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCategoriesChanged = this.onCategoriesChanged.bind(this);
        this.onIncidentTypesChanged = this.onIncidentTypesChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.AdminDashboardFilter(),
            types: [],
            categories: []
        };
    }

    componentDidMount() {
        let types = this.getIncidentTypesOptions(this.props.filter);
        let categories = this.getCategoriesOptions(this.props.filter);
        this.setState({
            filter: this.props.filter,
            types,
            categories
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter) ||
            JSON.stringify(prevProps.incidentCategoryTypes) != JSON.stringify(this.props.incidentCategoryTypes) ||
            JSON.stringify(prevProps.incidentTypes) != JSON.stringify(this.props.incidentTypes)) {
            let types = this.getIncidentTypesOptions(this.props.filter);
            let categories = this.getCategoriesOptions(this.props.filter);
            this.setState({
                filter: this.props.filter,
                types,
                categories
            });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    getCategoriesOptions(filter: AusComplyDTOs.AdminDashboardFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        let itemIds: number[] = [];
        try {
            if (this.props.incidentCategoryTypes && filter.incidentCategoryTypeIds) {
                this.props.incidentCategoryTypes.forEach((item, index) => {
                    let isSelected = filter.incidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1;
                    let canShow = false;
                    if (itemIds.indexOf(item.incidentCategoryTypeId) === -1) {
                        item.incidentTypes.forEach(t => {
                            if (filter.incidentTypeIds.indexOf(t) > -1 ) {
                                canShow = true;
                            }
                        });
                    }
                    if (canShow) {
                        itemIds.push(item.incidentCategoryTypeId);
                        items.push({
                            id: item.incidentCategoryTypeId,
                            name: item.display,
                            isCommon: false,
                            selected: isSelected
                        });
                    }
                });
            }
        } catch (error) {
            //console .log(error)
        }
        return items;
    }

    onCategoriesChanged(items) {
        let filter = { ...this.state.filter };
        let categories = [...this.state.categories];
        filter.incidentCategoryTypeIds = items.map(item => item.id);
        categories.forEach(category => {
            category.selected = filter.incidentCategoryTypeIds.indexOf(category.id) !== -1;
        });
        this.setState({ filter, categories }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    getIncidentTypesOptions(filter: AusComplyDTOs.AdminDashboardFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.incidentTypes && filter.incidentTypeIds) {
                this.props.incidentTypes.forEach((item, index) => {
                    let isSelected = filter.incidentTypeIds.indexOf(item.incidentTypeId) > -1;
                    items.push({
                        id: item.incidentTypeId,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch  {

        }
        return items;
    }

    onIncidentTypesChanged(items) {
        let filter = { ...this.state.filter };
        let types = [...this.state.types];
        filter.incidentTypeIds = items.map(item => item.id);
        types.forEach(type => {
            type.selected = filter.incidentTypeIds.indexOf(type.id) !== -1;
        });
        let categories = this.getCategoriesOptions(filter);
        this.setState({ filter, types, categories }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                </Grid>
            </Grid>
        );

        return (
            <LayoutForm>
                <SimpleDateControlGroup
                    text="Time Period"
                    rightText={"From"}
                    labelAbove={true}
                    date={this.state.filter.from}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("from", value)}
                />
                <SimpleDateControlGroup
                    rightText="To"
                    labelAbove={true}
                    date={this.state.filter.to}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("to", value)}
                />
                <MultiSelectControl
                    label={"Incident Types"}
                    items={this.state.types}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onIncidentTypesChanged}
                    canShowAll={true} />
                <MultiSelectControl
                    label={"Incident Category Types"}
                    items={this.state.categories}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onCategoriesChanged}
                    canShowAll={true} />
                {resetButton}
            </LayoutForm>
        );
    }
}