import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as apiRequest from "../services/common";

export const checklistApi = {
  saveChecklist: (
    venueId,
    securityFirmId, 
    venueEventId: number,
    checklist) => {
    var client = new AusComplyJsonServiceClient();
    var saveChecklistRequest = new AusComplyDtos.SaveChecklist();
    saveChecklistRequest.venueId = venueId;
    saveChecklistRequest.securityFirmId = securityFirmId;
    saveChecklistRequest.venueEventId = venueEventId;
    saveChecklistRequest.checklist = checklist;
    const request = client.post(saveChecklistRequest);
    return from(request);
  },
  getChecklist: (venueId, securityFirmId, venueEventId: number, checklistId) => {
    var client = new AusComplyJsonServiceClient();
    var getChecklistRequest = new AusComplyDtos.GetChecklist();
    getChecklistRequest.venueId = venueId;
    getChecklistRequest.securityFirmId = securityFirmId;
    getChecklistRequest.venueEventId = venueEventId;
    getChecklistRequest.id = checklistId;
    const response = client.get(getChecklistRequest);
    return from(response);
  },
  getChecklists: (venueId, securityFirmId, venueEventId: number, page, pageSize, filter: AusComplyDtos.ChecklistFilter) => {
    var client = new AusComplyJsonServiceClient();
    var getChecklistsRequest = new AusComplyDtos.GetChecklistSummaries();
    getChecklistsRequest.venueId = venueId;
    getChecklistsRequest.securityFirmId = securityFirmId;
    getChecklistsRequest.venueEventId = venueEventId;
    getChecklistsRequest.page = page;
    getChecklistsRequest.pageSize = pageSize;
    getChecklistsRequest.filter = filter;
    getChecklistsRequest.searchText = "";
    getChecklistsRequest.checklistStatuses = []; //checklistStatuses;
    getChecklistsRequest.startDate = new Date().toISOString();
    getChecklistsRequest.endDate = new Date().toISOString();
    const response = client.post(getChecklistsRequest);
    return from(response);
  },
  archiveChecklists: (venueId, securityFirmId, venueEventId: number, checklistIds) => {
    var client = new AusComplyJsonServiceClient();
    var archiveChecklistsRequest = new AusComplyDtos.ArchiveChecklists();
    archiveChecklistsRequest.venueId = venueId;
    archiveChecklistsRequest.securityFirmId = securityFirmId;
    archiveChecklistsRequest.venueEventId = venueEventId;
    archiveChecklistsRequest.checklistIds = checklistIds;
    const request = client.post(archiveChecklistsRequest);
    return from(request);
  },
  approveChecklists: (venueId, securityFirmId, venueEventId: number, checklistIds) => {
    var client = new AusComplyJsonServiceClient();
    var approveChecklistsRequest = new AusComplyDtos.ApproveChecklists();
    approveChecklistsRequest.venueId = venueId;
    approveChecklistsRequest.securityFirmId = securityFirmId;
    approveChecklistsRequest.venueEventId = venueEventId;
    approveChecklistsRequest.checklistIds = checklistIds;
    const request = client.post(approveChecklistsRequest);
    return from(request);
  },
  restoreChecklists:(venueId, securityFirmId, venueEventId: number, checklistIds) => {
    var client = new AusComplyJsonServiceClient();
    var restoreChecklistsRequest = new AusComplyDtos.RestoreChecklists();
    restoreChecklistsRequest.venueId = venueId;
    restoreChecklistsRequest.securityFirmId = securityFirmId;
    restoreChecklistsRequest.venueEventId = venueEventId;
    restoreChecklistsRequest.checklistIds = checklistIds;
    const request = client.post(restoreChecklistsRequest);
    return from(request);
  },
  closeChecklists: (venueId, securityFirmId, venueEventId: number, checklistIds) => {
    var client = new AusComplyJsonServiceClient();
    var closeChecklistsRequest = new AusComplyDtos.CloseChecklists();
    closeChecklistsRequest.venueId = venueId;
    closeChecklistsRequest.securityFirmId = securityFirmId;
    closeChecklistsRequest.venueEventId = venueEventId;
    closeChecklistsRequest.checklistIds = checklistIds;
    const request = client.post(closeChecklistsRequest);
    return from(request);
  }
};