import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ButtonLink from '../controls/ButtonLink';
import TransparentButton from '../controls/TransparentButton'
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ILoading {
    message?: string;
    onCancel?: Function;
    theme: any;
}

class Loading extends Component<ILoading, any> {
    constructor(props: ILoading) {
        super(props)
    }

    render() {
        const { theme } = this.props;
        let style = {};
        if (theme.custom) {
            style = theme.custom.primaryColor
        }
        return (
            <div style={{ display: 'block', padding: '10px', textAlign: "center", color: 'white' }}>
                <CircularProgress style={{ ...style }} />
                {this.props.message && (
                    <h6>{this.props.message}...</h6>
                )}
                {this.props.onCancel && (
                    <Box display="flex" flexDirection="row" justifyContent="center">
                        <Box  p={0}>
                            <TransparentButton text={"Cancel"} onClick={this.props.onCancel} />
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Loading);