import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DefaultButton from '../controls/DefaultButton';
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import ButtonLink from '../controls/ButtonLink';
import TextControl from '../controls/TextControl';
import CardContainer from '../common/CardContainer';
import Box from '@material-ui/core/Box';
import Media from "react-media";
import Logo from '../../resources/Logo';
import AusComplyIcon from '../../resources/AusComplyIcon';
import Loading from '../common/Loading';
import { appSource } from '../../utilities/constants';
import { Palette } from '../../common/constants/palette';

export interface ILoginForgotPasswordProps {
    onForgotPassword: Function;
    resetMobile: string;
    resetCode: number;
    resetLoading: boolean;
    onRequestCode: Function;
    onValidateCode: Function;
    isCodeValid: boolean;
    onSetCodeInvalid: Function;
    onSetMobile: Function;
    onSetCode: Function;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

const CancelLink = forwardRef((props, ref) => <Link to="/" {...props} />);
const TermsLink = forwardRef((props, ref) => <Link to="/terms" {...props} />);
const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/privacypolicy" {...props} />);

class LoginForgotPassword extends Component<ILoginForgotPasswordProps, any> {
    constructor(props: ILoginForgotPasswordProps) {
        super(props)

        this.state = {
            email: "",
            by: 1,
            byTypes: [
                { id: 1, name: "Email" },
                { id: 2, name: "SMS" },
            ]
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeBy = this.onChangeBy.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onSubmitCode = this.onSubmitCode.bind(this);
    }

    componentDidMount() {
        this.props.onSetCodeInvalid();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resetLoading && !this.props.resetLoading && !prevProps.isCodeValid && this.props.isCodeValid) {
            this.props.history.push('/resetpasswordcode');
        }
    }

    onSubmit() {
        if (this.state.by == 1 && this.state.email != "") {
            this.props.onForgotPassword(this.state.email);
            this.props.history.push('/');
        } else if (this.state.by == 2 && this.props.resetMobile != "") {
            this.props.onRequestCode(this.props.resetMobile);
        }
    }

    onChange(value) {
        this.setState({ email: value });
    }

    onChangeMobile(value) {
        this.props.onSetMobile(value);
    }

    onChangeCode(value) {
        this.props.onSetCode(value);
    }

    onChangeBy(id) {
        this.setState({ by: id });
    }

    onSubmitCode() {
        this.props.onValidateCode(this.props.resetMobile, this.props.resetCode);
    }

    render() {
        let resetCode = "";
        const { classes } = this.props;
        let baseURL = appSource.getBaseImagesPath();
        if (baseURL == '/') {
            baseURL = window.location.origin;
        }
        if (this.props.resetCode) {
            resetCode = this.props.resetCode.toString();
        }
        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" style={{ position: 'absolute', top: '30px', left: 0, width: '100%', zIndex: -1 }}>
                    <Box p={0} flexGrow={1}></Box>
                    <Box p={0}>
                        <img src={require('../../resources/Background.png')} style={{ width: '340px' }} alt="backdrop" />
                    </Box>
                    <Box p={0} flexGrow={1}></Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <Box p={0} flexGrow={1}></Box>
                    <Box p={0}>
                        <form noValidate autoComplete="off" style={{ margin: '20px' }} >

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1} md={2} lg={4}></Grid>
                                <Grid item xs={12} sm={10} md={8} lg={4} style={{ textAlign: 'center' }}>
                                    <div style={{ height: '50px', display: 'none' }}><div style={{ border: '1px solid red' }}><AusComplyIcon /></div></div>
                                    <Logo fill={'#fff'} style={{ width: '80%', height: 'auto' }} />
                                    <h3 className={classes.loginTitle}>Digital Incident Register</h3>
                                    <CardContainer style={{ padding: '30px 30px 0 30px', margin: '20px 0' }}>


                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <p>
                                                    If you have forgotten your password we can help.<br /><br />Simply fill in your email address and we will email you a link or mobile number and we will send you a code to reset your password.
                                                </p>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    {this.state.byTypes && this.state.byTypes.map((item, index) => {
                                                        return (
                                                            <Grid item xs={6} key={item.id}>
                                                                {item.id === this.state.by && (
                                                                    <PrimaryButton text={item.name} onClick={() => this.onChangeBy(item.id)} />
                                                                )}
                                                                {item.id !== this.state.by && (
                                                                    <TransparentButton text={item.name} onClick={() => this.onChangeBy(item.id)} />
                                                                )}
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {this.state.by == 1 && (
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextControl
                                                            value={this.state.email}
                                                            placeholder="EMAIL"
                                                            center={true}
                                                            onChange={(value) => this.onChange(value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <DefaultButton text="Submit" onClick={this.onSubmit} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        {(this.state.by == 2 && this.props.resetLoading) && (
                                            <Loading />
                                        )}
                                        {(this.state.by == 2 && !this.props.resetLoading) && (
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextControl
                                                            value={this.props.resetMobile}
                                                            placeholder="MOBILE #"
                                                            center={true}
                                                            onChange={(value) => this.onChangeMobile(value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <DefaultButton text="Submit" onClick={this.onSubmit} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextControl
                                                            value={resetCode}
                                                            placeholder="ALREADY HAVE A CODE, ENTER IT HERE"
                                                            center={true}
                                                            onChange={(value) => this.onChangeCode(value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <DefaultButton text="Verify Code" disabled={resetCode == ""} onClick={this.onSubmitCode} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            <Box p={2} flexGrow={1}>
                                                <ButtonLink text="Cancel" small={true} component={CancelLink} />
                                            </Box>
                                        </Box>
                                    </CardContainer>
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box p={0}>
                                            <ButtonLink text="Terms of Use" small={true} component={TermsLink} />
                                        </Box>
                                        <Box p={1}>
                                            <span style={{ color: '#545454', textShadow: '#fff' }}>|</span></Box>
                                        <Box p={0}>
                                            <ButtonLink text="Privacy Policy" small={true} component={PrivacyPolicyLink} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </form>

                    </Box>
                    <Box p={0} flexGrow={0}></Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(LoginForgotPassword));