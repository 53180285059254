import { connect } from 'react-redux'
import IncidentFlagTypes from '../components/admin/incidentFlagTypes/IncidentFlagTypes'
import { withRouter } from "react-router";
import { adminIncidentFlagTypesRequest, adminIncidentFlagTypeCreateNewRequest, adminIncidentFlagTypeUpdateRequest, adminIncidentFlagTypeSet } from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.incidentFlagTypes.isLoading,
        isSaving: state.admin.incidentFlagTypes.isSaving,
        incidentFlagTypes: state.admin.incidentFlagTypes.incidentFlagTypes,
        incidentFlagType: state.admin.incidentFlagTypes.incidentFlagType,
        paging: state.admin.incidentFlagTypes.paging,
        filter: state.admin.incidentFlagTypes.filter,
        error: state.admin.incidentFlagTypes.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(adminIncidentFlagTypesRequest(page, pageSize, filter))
        },
        onCreateNew:() => {
            dispatch(adminIncidentFlagTypeCreateNewRequest())
        },
        onSet:(incidentFlagType) => {
            dispatch(adminIncidentFlagTypeSet(incidentFlagType))
        },
        onUpdate:(incidentFlagType) => {
            dispatch(adminIncidentFlagTypeUpdateRequest(incidentFlagType))
        }
    }
}

const IncidentFlagTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentFlagTypes))

export default IncidentFlagTypesContainer