import update from "immutability-helper";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { incidentLogic } from '../../common/logic/incidentLogic';
import { checklistLogic } from '../../common/logic/checklistLogic';
import {
    DRAFT_INCIDENT_SET_STEP,
    DRAFT_INCIDENT_SET_ACTION,
    DRAFT_INCIDENT_SET_DEFAULTS,
    DRAFT_INCIDENT_CREATE_REQUEST,
    DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS,
    DRAFT_INCIDENT_CREATE_REQUEST_FAILURE,
    DRAFT_INCIDENT_CLEAR,
    DRAFT_INCIDENT_UPDATE,
    DRAFT_INCIDENT_SAVE_REQUEST,
    DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS,
    DRAFT_INCIDENT_SAVE_REQUEST_FAILURE,
    DRAFT_INCIDENT_CLEAR_ERROR,
    DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS,
    DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST,
    DRAFT_INCIDENT_UPLOAD_REQUEST,
    DRAFT_INCIDENT_UPLOAD_REQUEST_SUCCESS,
    DRAFT_INCIDENT_UPLOAD_REQUEST_FAILURE,
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST,
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS,
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE,
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST,
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE,
    DRAFT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS,
    DRAFT_INCIDENT_CHECKLISTS_REQUEST_FAILURE,
    DRAFT_INCIDENT_CHECKLISTS_REQUEST
} from "../actions/draftIncident";
import * as imageProcessingActions from "../actions/imageProcessing";

const initialState = {
    isLoading: false,
    hasDraftIncident: false,
    incident: {},
    //incidentOptions: {},
    isSaving: false,
    error: "",
    step: 1,
    isUploading: false,
    venueLocationId: 0,
    closeAfterSave: true,
    action: "",
    //hasChecklists: false,
    isLoadingChecklists: false,
    //entryPage: ""
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function uploading(state, isUploading) {
    let newState = update(state, {
        isUploading: { $set: isUploading }
    });
    return newState;
}

function loadingChecklists(state, isLoadingChecklists) {
    let newState = update(state, {
        isLoadingChecklists: { $set: isLoadingChecklists }
    });
    return newState;
}

function setDefaults(state, venueLocationId, closeAfterSave) {
    let newState = update(state, {
        venueLocationId: { $set: venueLocationId },
        closeAfterSave: { $set: closeAfterSave }
    });
    return newState;
}

function setAction(state, action) {
    let newState = update(state, {
        action: { $set: action }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setStep(state, step) {

    let newState = update(state, {
        step: { $set: step }
    });
    return newState;
}

function updateIncident(state, incident) {
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
    //return setHasChecklists(newState);
}

function setIncident(state, incident, incidentOptions) {
    let newState = update(state, {
        incident: { $set: incident },
        //incidentOptions: { $set: {} }
    });
    return newState;
    //return setHasChecklists(newState);
}

function setChecklists(state, incident) {
    var newIncident = { ...state.incident };
    newIncident.checklists = [...incident.checklists];
    let newState = update(state, {
        incident: { $set: newIncident }
    });
    return newState;
    //return setHasChecklists(newState);
}

/*
function setHasChecklists(state) {
    let newState = update(state, {
        hasChecklists: { $set: incidentLogic.hasChecklists(state.incident, state.incidentOptions) }
    });
    return newState;
}
*/

function setHasDraftIncident(state, value) {
    let newState = update(state, {
        hasDraftIncident: { $set: value }
    });
    return newState;
}

function updateIncidentIsCurrentReportingPeriod(state, isCurrentReportingPeriod) {
    let newState = update(state, {
        incident: { isCurrentReportingPeriod: { $set: isCurrentReportingPeriod } }
    });
    return newState;
}

function filesAttachmentUploaded(state, temporaryFiles: AusComplyDtos.File[], checklistTemplateItemId) {
    let incident = { ...state.incident };
    if (incident.checklists != null) {
        incident.checklists.forEach(checklist => {
            checklist.checklistItems.forEach((item, index) => {
                if (item.checklistTemplateItemId == checklistTemplateItemId) {
                    temporaryFiles.forEach(file => {
                        if (!checklist.checklistItems[index].files) {
                            checklist.checklistItems[index].files = [];
                        }
                        checklist.checklistItems[index].files.push(file);
                    });
                }
            });
            checklist.checkListDisplayItems.forEach((item, index) => {
                if (item.template.checklistTemplateItemId == checklistTemplateItemId) {
                    temporaryFiles.forEach(file => {
                        if (!checklist.checkListDisplayItems[index].item.files) {
                            checklist.checkListDisplayItems[index].item.files = [];
                        }
                        checklist.checkListDisplayItems[index].item.files.push(file);
                        checklist.checkListDisplayItems[index].isRequired = checklistLogic.validateChecklistDisplayItemIsRequired(checklist.checkListDisplayItems[index]);
                    });
                }
            });
            checklist.checkListDisplayItems = checklistLogic.validateChecklistDisplayItemsShown(checklist.checkListDisplayItems);
            checklist.checklistItems = [...checklist.checklistItems];
            checklist.checkListDisplayItems = [...checklist.checkListDisplayItems];
        });
    }

    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function filesUploaded(state, temporaryFiles: AusComplyDtos.File[]) {
    let incident = { ...state.incident };
    temporaryFiles.forEach(item => {
        let document = new AusComplyDtos.IncidentDocument();
        document.obsolete = false;
        document.originalFilename = item.originalName;
        document.isImage = item.isImage;
        document.incidentId = incident.incidentId;
        document.canvasRescaled = false;
        document.filePath = item.path;
        document.filePreviewPath = item.filePreviewPath;
        document.fileDisplayPath = item.fileDisplayPath;
        document.extension = item.extension;
        document.incidentDocumentId = 0;
        document.filePreviewText = item.filePreviewText;
        document.hasChanged = true;
        document.canvasRescaled = item.canvasRescaled;
        document.poiGuid = item.poiGuid;
        document.appearanceGuid = item.appearanceGuid;
        incident.incidentDocuments.push(document);
    });
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function patronFilesUploaded(state, patronIndex: number, temporaryFiles: AusComplyDtos.File[]) {
    let incident = { ...state.incident };
    if (incident.incidentPatrons && incident.incidentPatrons.length > patronIndex) {
        if (incident.incidentPatrons[patronIndex].incidentPatronPhotos == null) {
            incident.incidentPatrons[patronIndex].incidentPatronPhotos = [];
        }
        temporaryFiles.forEach(item => {
            let photo = new AusComplyDtos.IncidentPatronPhoto();
            photo.obsolete = false;
            photo.originalFileName = item.originalName;
            photo.isImage = item.isImage;
            photo.incidentPatronId = incident.incidentPatrons[patronIndex].incidentPatronId;
            photo.canvasRescaled = false;
            photo.filePath = item.path;
            photo.filePreviewPath = item.filePreviewPath;
            photo.fileDisplayPath = item.fileDisplayPath;
            photo.extension = item.extension;
            photo.incidentPatronPhotoId = 0;
            photo.filePreviewText = item.filePreviewText;
            photo.hasChanged = true;
            photo.canvasRescaled = item.canvasRescaled;
            photo.poiGuid = item.poiGuid;
            photo.appearanceGuid = item.appearanceGuid;
            incident.incidentPatrons[patronIndex].incidentPatronPhotos.push(photo);
        });
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

/*
function setEntryPage(state) {
    let newState = update(state, {
        entryPage: { $set: incidentLogic.incidentTypeEntryPage(state.incident, state.incidentOptions) }
    });
    return newState;
}
    */

function incidentTemporaryFileUpdated(state, imageText, filePath) {
    let incident = { ...state.incident };
    if (incident.incidentDocuments) {
        for (let index = 0; index < incident.incidentDocuments.length; index++) {
            if (filePath.indexOf(incident.incidentDocuments[index].fileDisplayPath) > -1) {
                incident.incidentDocuments[index].imageText = imageText;
                incident.incidentDocuments[index].hasChanged = true;
            }
        }
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

export default function draftIncident(state = initialState, action) {
    switch (action.type) {
        case DRAFT_INCIDENT_CREATE_REQUEST:
            return loading(setIncident(setError(setStep(uploading(setAction(state, ""), false), 1), ""), {}, {}), true);
        case DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS:
            //return setHasDraftIncident(loading(setEntryPage(setIncident(state, action.incident, action.incidentOptions)), false), true);
            return setHasDraftIncident(loading(setIncident(state, action.incident, action.incidentOptions), false), true);
        case DRAFT_INCIDENT_CREATE_REQUEST_FAILURE:
            return setHasDraftIncident(setError(loading(state, false), action.error), false);
        case DRAFT_INCIDENT_CLEAR:
            return setHasDraftIncident(setIncident(saving(loading(setStep(uploading(setAction(state, ""), false), 1), false), false), {}, {}), false);
        case DRAFT_INCIDENT_UPDATE:
            return updateIncident(state, action.incident);
        case DRAFT_INCIDENT_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS:
            return saving(updateIncident(state, action.incident), false);
        case DRAFT_INCIDENT_SAVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case DRAFT_INCIDENT_CLEAR_ERROR:
            return setError(state, "");
        case DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS:
            return updateIncidentIsCurrentReportingPeriod(state, action.isCurrentReportingPeriod);
        case DRAFT_INCIDENT_SET_STEP:
            return setStep(state, action.step);
        case DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST:
        case DRAFT_INCIDENT_UPLOAD_REQUEST:
            return uploading(state, true);
        case DRAFT_INCIDENT_UPLOAD_REQUEST_SUCCESS:
            return filesUploaded(uploading(state, false), action.temporaryFiles);
        case DRAFT_INCIDENT_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST:
            return uploading(state, true);
        case DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS:
            return patronFilesUploaded(uploading(state, false), action.patronIndex, action.temporaryFiles);
        case DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case DRAFT_INCIDENT_SET_DEFAULTS:
            return setDefaults(state, action.venueLocationId, action.closeAfterSave);
        case DRAFT_INCIDENT_SET_ACTION:
            return setAction(state, action.action);
        case DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST:
            return uploading(state, true);
        case DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS:
            return filesAttachmentUploaded(uploading(state, false), action.temporaryFiles, action.checklistTemplateItemId);
        case DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case DRAFT_INCIDENT_CHECKLISTS_REQUEST:
            return loadingChecklists(state, true);
        case DRAFT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS:
            return setChecklists(loadingChecklists(state, false), action.incident);
        case DRAFT_INCIDENT_CHECKLISTS_REQUEST_FAILURE:
            return loadingChecklists(state, false);
        case imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS:
            return incidentTemporaryFileUpdated(state, action.imageText, action.filePath);
    }
    return state;
}