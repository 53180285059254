import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

class FormAlternateMessage extends React.PureComponent<any, any> {
    render() {
        const { theme } = this.props;
        return (
            <div style={{
                display: 'block',
                padding: '6px',
                textAlign: 'center',
                margin: '0'
                }}>
                <p style={{margin: 0, color: theme.palette.text.primary}}>
                    {this.props.children}
                </p>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FormAlternateMessage);