import React from "react";

const Paperclip2Icon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 16 16"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/>
            </g>
        </svg>
    );

export default Paperclip2Icon;
