import { makeActionCreator } from './helpers';

export const
    INCIDENTS_RESET = "INCIDENTS_RESET",
    INCIDENTS_REQUEST = "INCIDENTS_REQUEST",
    INCIDENTS_REFRESH_REQUEST = "INCIDENTS_REFRESH_REQUEST",
    INCIDENTS_REQUEST_FAILURE = "INCIDENTS_REQUEST_FAILURE",
    INCIDENTS_REQUEST_SUCCESS = "INCIDENTS_REQUEST_SUCCESS",
    INCIDENTS_REQUIRING_APPROVAL = "INCIDENTS_REQUIRING_APPROVAL",
    INCIDENTS_FILTER_REQUEST = "INCIDENTS_FILTER_REQUEST",
    INCIDENTS_FILTER_REQUEST_FAILURE = "INCIDENTS_FILTER_REQUEST_FAILURE",
    INCIDENTS_FILTER_REQUEST_SUCCESS = "INCIDENTS_FILTER_REQUEST_SUCCESS",
    INCIDENTS_SELECT_FOR_APPROVAL = "INCIDENTS_SELECT_FOR_APPROVAL",
    INCIDENTS_SELECT_ALL_FOR_APPROVAL = "INCIDENTS_SELECT_ALL_FOR_APPROVAL",
    INCIDENTS_APPROVE_SELECTED_REQUEST = "INCIDENTS_APPROVE_SELECTED_REQUEST",
    INCIDENTS_APPROVE_SELECTED_REQUEST_FAILURE = "INCIDENTS_APPROVE_SELECTED_REQUEST_FAILURE",
    INCIDENTS_APPROVE_SELECTED_REQUEST_SUCCESS = "INCIDENTS_APPROVE_SELECTED_REQUEST_SUCCESS",
    INCIDENTS_SNAPSHOT_SUMMARY_REQUEST = "INCIDENTS_SNAPSHOT_SUMMARY_REQUEST",
    INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_FAILURE = "INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_FAILURE",
    INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_SUCCESS = "INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_SUCCESS";

export const incidentsSnapshotSummaryRequest = makeActionCreator(INCIDENTS_SNAPSHOT_SUMMARY_REQUEST);
export const incidentsSnapshotSummaryRequestFailure = makeActionCreator(INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_FAILURE, 'error');
export const incidentsSnapshotSummaryRequestSuccess = makeActionCreator(INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_SUCCESS, 'snapshotSummary');

export function incidentsReset() {
    return {
        type: INCIDENTS_RESET
    };
}

export function incidentsRequest(incidentFilter) {
    return {
        type: INCIDENTS_REQUEST,
        incidentFilter
    };
}

export function incidentsRequiringApproval() {
    return {
        type: INCIDENTS_REQUIRING_APPROVAL
    }
}

export function incidentsRefreshRequest() {
    return {
        type: INCIDENTS_REFRESH_REQUEST
    }
}

export function incidentsRequestSuccess(incidents, paging, incidentFilter) {
    return {
        type: INCIDENTS_REQUEST_SUCCESS,
        incidents,
        paging,
        incidentFilter
    };
}

export function incidentsRequestFailure(error) {
    return {
        type: INCIDENTS_REQUEST_FAILURE,
        error
    };
}

export function incidentsFilterRequest(reset, forEntryPage) {
    return {
        type: INCIDENTS_FILTER_REQUEST,
        reset, 
        forEntryPage
    };
}

export function incidentsFilterRequestSuccess(incidentFilter) {
    return {
        type: INCIDENTS_FILTER_REQUEST_SUCCESS,
        incidentFilter
    };
}

export function incidentsFilterRequestFailure(error) {
    return {
        type: INCIDENTS_FILTER_REQUEST_FAILURE,
        error
    };
}

export function incidentsSelectForApproval(incidentId, isSelected) {
    return {
        type: INCIDENTS_SELECT_FOR_APPROVAL,
        incidentId,
        isSelected
    }
}

export function incidentsSelectAllForApproval(isSelected) {
    return {
        type: INCIDENTS_SELECT_ALL_FOR_APPROVAL,
        isSelected
    }
}

export function incidentsApproveSelectedRequest() {
    return {
        type: INCIDENTS_APPROVE_SELECTED_REQUEST
    }
}

export function incidentsApproveSelectedRequestSuccess() {
    return {
        type: INCIDENTS_APPROVE_SELECTED_REQUEST_SUCCESS
    }
}

export function incidentsApproveSelectedRequestFailure(error) {
    return {
        type: INCIDENTS_APPROVE_SELECTED_REQUEST_FAILURE,
        error
    }
}
