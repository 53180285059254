
import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, debounce, filter, throttle } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueServiceApi } from '../services/venueService';

import * as venueSecurityFirmActions from "../actions/userVenueSecurityFirm";


import { DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS } from "../actions/draftIncident";
import { LOAD_NEW_CHECKLIST_REQUEST_SUCCESS, ARCHIVE_CHECKLISTS_SUCCESS } from "../actions/checklist";
import { EDIT_INCIDENT_SAVE_REQUEST_SUCCESS } from "../actions/editIncident";
import {
    notifyError, notifyErrorMessage
} from './common';


const loadUserVenueSecurityFirmStatisticsEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueSecurityFirmActions.USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getUserStatistics(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, state.user.details.userSession.user.venueEventId)
                .pipe(
                    map(response =>
                        venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsResponse(response.statistics)
                        //venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsSuccess(response.statistics)
                    ),
                    catchError(error => notifyErrorMessage(error, "Failed to load statistics", venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load statistics", venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsFailure))        
    );

const loadUserVenueSecurityFirmStatisticsSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueSecurityFirmActions.USER_VENUE_SECURITYFIRM_STATISTICS_RESPONSE),
        withLatestFrom(state$),
        filter(([action, state]) => {
            let currentVenueId = 0;
            let responseVenueId = 0;
            let currentSecurityFirmId = 0;
            let responseSecurityFirmId = 0;

            if(state.user.details.userSession && state.user.details.userSession.user && state.user.details.userSession.user.venueId){
                currentVenueId = state.user.details.userSession.user.venueId;
            }
            
            if(state.user.details.userSession && state.user.details.userSession.user && state.user.details.userSession.user.securityFirmId){
                currentSecurityFirmId = state.user.details.userSession.user.securityFirmId;
            }

            if (action.statistics && action.statistics.venueId) {
                responseVenueId = action.statistics.venueId
            }

            if (action.statistics && action.statistics.securityFirmId) {
                responseSecurityFirmId = action.statistics.securityFirmId
            }
            return (currentVenueId === responseVenueId) && (currentSecurityFirmId === responseSecurityFirmId);
        }),
        map((args: any) => venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsSuccess(args[0].statistics))
    );

const loadUserVenueSecurityFirmQueietStatisticsEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueSecurityFirmActions.USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getUserStatistics(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, state.user.details.userSession.user.venueEventId)
                .pipe(
                    map(response =>
                        venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsSuccess(response.statistics)
                    ),
                    catchError(error => notifyErrorMessage(error, "Failed to load statistics", venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsFailure))                    
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load statistics", venueSecurityFirmActions.userVenueSecurityFirmLoadStatisticsFailure))
    );

const loadUserVenueSecurityFirmBackgroundStatisticsEpic = (action$, state$) =>
    action$.pipe(
    withLatestFrom(state$),
    filter(([action, state]) => {
        if (state.user.details.venueIsSelected || state.user.details.securityFirmIsSelected ) {
            return true;
        }
        return false;
    }),
    throttle(() => timer(30000)),
    map(() => venueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
);


const reloadStatisticsAfterDraftSaveEpic = action$ =>
    action$.pipe(
        ofType(DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => venueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );

const reloadStatisticsAfterIncidentSaveEpic = action$ =>
    action$.pipe(
        ofType(EDIT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => venueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );

const reloadStatisticsAfterChecklistNewEpic = action$ =>
    action$.pipe(
        ofType(LOAD_NEW_CHECKLIST_REQUEST_SUCCESS),
        debounce(() => timer(3000)),
        map((action: any) => venueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );

const reloadStatisticsAfterChecklistArchiveEpic = action$ =>
    action$.pipe(
        ofType(ARCHIVE_CHECKLISTS_SUCCESS),
        debounce(() => timer(1000)),
        map((action: any) => venueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );

export const userVenueSecurityFirmEpics = combineEpics(
    loadUserVenueSecurityFirmBackgroundStatisticsEpic,
    loadUserVenueSecurityFirmStatisticsEpic,
    reloadStatisticsAfterDraftSaveEpic,
    reloadStatisticsAfterIncidentSaveEpic,
    loadUserVenueSecurityFirmQueietStatisticsEpic,
    reloadStatisticsAfterChecklistNewEpic,
    reloadStatisticsAfterChecklistArchiveEpic,
    loadUserVenueSecurityFirmStatisticsSuccessEpic
);