import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionWatchlistMigration from '../components/facialRecognition/facialRecognitionWatchlists/FacialRecognitionWatchlistMigration'
import * as facialRecognitionWatchlistActions from '../common/actions/facialRecognitionWatchlist';

const mapStateToProps = (state, props) => {
    return {
        migration: state.facialRecognitionWatchlists.facialRecognitionWatchlistMigration.facialRecognitionWatchlistMigration,
        isLoading: state.facialRecognitionWatchlists.facialRecognitionWatchlistMigration.isLoading,
        isSaving: state.facialRecognitionWatchlists.facialRecognitionWatchlistMigration.isSaving
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (facialRecognitionWatchlistMigration) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationSet(facialRecognitionWatchlistMigration))
        },
        onSave: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationRequest())
        }
    }
}

const FacialRecognitionWatchlistMigrationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionWatchlistMigration))

export default FacialRecognitionWatchlistMigrationContainer