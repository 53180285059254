import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITextAreaControlProps {
    theme: any;
    classes: any;
    value?: string;
    inputType?: string;
    error?: boolean;
    placeholder?: string;
    readonly?: boolean;
    onChange?: Function;
    onBlur?: Function;
    inputComponent?: any;
    style?: any;
    center?: boolean;
    numericOnly?: boolean;
    maxlength?: number;
    id?: string;
    autoComplete?: string;
    minRows: number;
}
class TextAreaControl extends React.PureComponent<ITextAreaControlProps, any> {

    constructor(props: ITextAreaControlProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.state = {
            value: "",
            changed: false
        }
    }

    componentDidMount() {
        let value: string = "";
        if (this.props.value || this.props.value === "") {
            value = this.props.value.toString(); //.replace(/[^\x00-\x7F]/g, "");
        } else {
            value = "";
        }
        this.setState({
            value,
            changed: false
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            let value: string = this.state.value;
            if (this.props.value || this.props.value === "") {
                value = this.props.value.toString(); //.replace(/[^\x00-\x7F]/g, "");
            } else {
                value = "";
            }
            this.setState({
                value,
                changed: false
            })
        }
    }

    onChange(event) {
        if (event != null) {
            //let val = event.target.value;
            let val = event.target.value; //.replace(/[^\x00-\x7F]/g, "");
            if (this.props.numericOnly && val != "") {
                if (isNaN(val)) {
                    val = "0";
                } else {
                    val = parseInt(val);
                }
            }
            this.setState({
                value: val,
                changed: true
            }, () => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            });
        }
    }

    onBlur() {
        if (this.state.changed) {
            this.setState({
                changed: false
            }, () => {
                if (this.props.onBlur) {
                    this.props.onBlur(this.state.value);
                }
            })
        }
    }

    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        const inputType = this.props.inputType ? this.props.inputType : "text";
        let inputClasses;
        if (this.props.center) {
            inputClasses = { input: classes.inputCenter, root: classes.inputCenter };
        }

        if (this.props.inputComponent) {
            return (
                <InputBase
                    value={this.state.value || ''}
                    style={{ ...style, ...this.props.style }}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    type={inputType}
                    autoComplete={this.props.autoComplete !== undefined ? this.props.autoComplete : 'off' }
                    classes={inputClasses}
                    inputComponent={this.props.inputComponent}
                    disabled={this.props.readonly}
                    inputProps={{maxlength: this.props.maxlength}}
                    id={this.props.id}
                    name={this.props.id}
                    multiline
                    maxRows="6"
                    minRows={this.props.minRows}
                />
            );
        }
        return (
            <InputBase
                value={this.state.value || ''}
                style={{ ...style, ...this.props.style }}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                onBlur={this.onBlur}
                autoComplete={this.props.autoComplete !== undefined ? this.props.autoComplete : 'off' }
                type={inputType}
                classes={inputClasses}
                disabled={this.props.readonly}
                inputProps={{maxLength: this.props.maxlength}}
                id={this.props.id}
                name={this.props.id}
                multiline
                maxRows="6"
                minRows={this.props.minRows}
            />
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextAreaControl);