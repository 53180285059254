import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '../../../resources/PersonIcon';
import IdCardIcon from '../../../resources/IdCardIcon';
import TickIcon from '../../../resources/TickIcon';
import DocumentIcon from '../../../resources/DocumentIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import ImagePreview from '../../common/ImagePreview';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import Thumbnail from '../../common/Thumbnail';
import InmatePhoto from './InmatePhoto';
import Box from '@material-ui/core/Box';

export interface IInmateSummaryProps {
    correctionalFacilityInMate: AusComplyDtos.CorrectionalFacilityInMate;
    onUpdate: Function;
    theme: any;
}

class InmateSummary extends Component<IInmateSummaryProps, any> {
    constructor(props: IInmateSummaryProps) {
        super(props)
        this.state = {
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let correctionalFacilityInMate = { ...this.props.correctionalFacilityInMate };
        correctionalFacilityInMate[fieldName] = value;
        this.props.onUpdate(correctionalFacilityInMate);
    }

    render() {
        const { theme } = this.props;


        return (
            <>
                <Box p={0} display="flex" flexDirection={'row'}>
                    <Box flexGrow={1} p={0}>
                        <ControlGroup text="CNI Number">
                            <TextDisplay>{this.props.correctionalFacilityInMate.cniNumber}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="Master Index Number">
                            <TextDisplay>{this.props.correctionalFacilityInMate.masterIndexNumber}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="Name">
                            <TextDisplay>{this.props.correctionalFacilityInMate.firstName} {this.props.correctionalFacilityInMate.lastName}</TextDisplay>
                        </ControlGroup>
                    </Box>
                    <Box p={0}>
                        <InmatePhoto
                            file={this.props.correctionalFacilityInMate.photo}
                            onUpdate={(value) => this.onValueChanged("photo", value)} />
                    </Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(InmateSummary);