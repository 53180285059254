
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const groupLogic = {

    getCommands(group: AusComplyDtos.Group, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            if (!group.obsolete)  {
                commands.push({ key: "delete", name: "Delete" });
            } else if (group.obsolete) {
                commands.push({ key: "undodelete", name: "Undo Delete" });
            }
        }
        return commands;
    },
    getVenueGroupCommands(group: AusComplyDtos.GroupVenue) {
        var commands: KeyName[] = [];
        if (group.userPermissions.includes(AusComplyDtos.ngtUserPermission.ViewGroupDashboard)) {
            commands.push({ key: "dashboard", name: "Dashboard" });
        }
        if (group.userPermissions.includes(AusComplyDtos.ngtUserPermission.MaintainGroupVenues)) {
            if (!group.obsolete)  {
                commands.push({ key: "delete", name: "Remove" });
            } 
        }
        if (!group.userPermissions.includes(AusComplyDtos.ngtUserPermission.ViewGroupDashboard) && 
            !group.userPermissions.includes(AusComplyDtos.ngtUserPermission.ViewGroup)) {
            commands.push({ key: "request", name: "Request Access" });
        }
        return commands;
    },
    getGroupVenueCommands(groupVenue: AusComplyDtos.GroupVenue, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            if (!groupVenue.obsolete)  {
                commands.push({ key: "delete", name: "Remove" });
            } else {
                commands.push({ key: "restore", name: "Restore" });
            } 
        }
        return commands;
    },
    getGroupUserCommands(groupUser: AusComplyDtos.GroupUser, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            if (!groupUser.obsolete)  {
                commands.push({ key: "edit", name: "Edit" });
                commands.push({ key: "delete", name: "Remove" });
            }  else {
                commands.push({ key: "restore", name: "Restore" });
            } 
        }
        return commands;
    }
}