import * as AusComplyDtos from "../dto/AusComply.dtos";
import { makePost } from './common';

export const correctionalFacilityServiceApi = {
  get: makePost(
    AusComplyDtos.PostCorrectionalFacilityInMateCells,
    'venueId', 'securityFirmId', 'forVenueId'
  ),
  create: makePost(
    AusComplyDtos.PostNewCorrectionalFacilityInMateCells,
    'venueId', 'securityFirmId', 'forVenueId', 'search'
  ),
  save: makePost(
    AusComplyDtos.PostCorrectionalFacilityInMateCell,
    'venueId', 'securityFirmId', 'correctionalFacilityInMateCell'
  ),
  removeInmateFromCell: makePost(
    AusComplyDtos.PostCorrectionalFacilityInMateCellRemove,
    'venueId', 'securityFirmId', 'correctionalFacilityInMateVenueLocationId'
  ),
  getRisks: makePost(
    AusComplyDtos.PostCorrectionalFacilityRiskStatuses,
    'pageSize', 'page', 'forVenueId'
  ),
  saveRisk: makePost(
    AusComplyDtos.PostNewCorrectionalFacilityRiskStatus,
    'forVenueId', 'riskStatus'
  ),
  inspect: makePost(
    AusComplyDtos.PostCorrectionalFacilityInMateCellInspect,
    'venueId', 'securityFirmId', 'correctionalFacilityInMateVenueLocationId'
  ),
};
