import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
//import { google.maps.LatLng } from 'react-google-maps';
//import {LatLng } from 'react-google-maps/lib/constants';

import {
    ADMIN_DASHBOARD_RESET,
    ADMIN_DASHBOARD_SET_FILTER,
    ADMIN_DASHBOARD_FILTER_REQUEST,
    ADMIN_DASHBOARD_FILTER_REQUEST_FAILURE,
    ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_REQUEST,
    ADMIN_DASHBOARD_REQUEST_FAILURE,
    ADMIN_DASHBOARD_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_INCIDENTS_REQUEST,
    ADMIN_DASHBOARD_INCIDENTS_REQUEST_FAILURE,
    ADMIN_DASHBOARD_INCIDENTS_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_SET_OPTIONS,
    ADMIN_DASHBOARD_TOTALS_REQUEST,
    ADMIN_DASHBOARD_TOTALS_REQUEST_FAILURE,
    ADMIN_DASHBOARD_TOTALS_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST,
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_FAILURE,
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST,
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_FAILURE,
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_SUCCESS,
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST,
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST_FAILURE,
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST_SUCCESS
} from "../actions/adminDashboard";

const initialState = {
    isLoading: false,
    filter: { dashboardId: -1 },
    isLoadingIncidents: false,
    incidents: [],
    venueMarkers: [],
    options: {
        showVenueMarkers: true,
        showActiveVenues: true,
        showInactiveVenues: true,
        showPostcodeBoundaries: true,
        showHeatmap: false,
        postcodes: []
    },
    heatmapCount: 0,
    filteredVenueMarkers: [],
    filteredIncidents: [],
    heatmap: [],
    totals: {
        isLoading: false,
        venues: [],
        securityFirms: [],
        incidents: [],
        users: []
    },
    overviewQuick: {
        isLoading: false,
        data: []
    },
    incidentTotals: {
        isLoading: false,
        incidents: [],
        venueIncidents: [],
        venueIncidentsGridLines: 5,
        securityFirmIncidents: [],
        securityFirmIncidentsGridLines: 5
    },
    userTotals: {
        isLoading: false,
        venueUsers: [],
        venueUsersGridLines: 5,
        securityFirmUsers: [],
        securityFirmUsersGridLines: 5
    }
};

function setQuick(state, isLoading, data) {
    let newState = update(state, {
        overviewQuick: {
            isLoading: { $set: isLoading },
            data: { $set: data }
        }
    });
    return newState;
}

function setIncidentTotals(state, isLoading, incidents, venueIncidents, securityFirmIncidents) {
    let venueIncidentsGridLines = getGridlinesCount(venueIncidents);
    let securityFirmIncidentsGridLines = getGridlinesCount(securityFirmIncidents);

    let venueUsersData: any[] = [
        [
            { type: 'string', label: 'Entered By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    venueIncidents.forEach(d => venueUsersData.push([d.name, d.total, d.annotation]));

    let securityFirmUsersData: any[] = [
        [
            { type: 'string', label: 'Entered By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    securityFirmIncidents.forEach(d => securityFirmUsersData.push([d.name, d.total, d.annotation]));

    let newState = update(state, {
        incidentTotals: {
            isLoading: { $set: isLoading },
            incidents: { $set: incidents },
            venueIncidents: { $set: venueUsersData },
            venueIncidentsGridLines: { $set: venueIncidentsGridLines },
            securityFirmIncidents: { $set: securityFirmUsersData },
            securityFirmIncidentsGridLines: { $set: securityFirmIncidentsGridLines },
        }
    });
    return newState;
}

function setUserTotals(state, isLoading, venueUsers, securityFirmUsers) {

    let venueUsersGridLines = getGridlinesCount(venueUsers);
    let securityFirmUsersGridLines = getGridlinesCount(securityFirmUsers);

    let venueUsersData: any[] = [
        [
            { type: 'string', label: 'Entered By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    venueUsers.forEach(d => venueUsersData.push([d.name, d.total, d.annotation]));

    let securityFirmUsersData: any[] = [
        [
            { type: 'string', label: 'Entered By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    securityFirmUsers.forEach(d => securityFirmUsersData.push([d.name, d.total, d.annotation]));

    let newState = update(state, {
        userTotals: {
            isLoading: { $set: isLoading },
            venueUsers: { $set: venueUsersData },
            venueUsersGridLines: { $set: venueUsersGridLines },
            securityFirmUsers: { $set: securityFirmUsersData },
            securityFirmUsersGridLines: { $set: securityFirmUsersGridLines },
        }
    });
    return newState;
}

function setTotals(state, isLoading, venues, securityFirms, incidents, users) {
    if (!isLoading && venues) {
        venues.forEach(venue => {
            venue.name = new Date(venue.dateEntered)
        });
    }
    let newState = update(state, {
        totals: {
            isLoading: { $set: isLoading },
            venues: { $set: venues },
            securityFirms: { $set: securityFirms },
            incidents: { $set: incidents },
            users: { $set: users }
        }
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function loadingIncidents(state, isLoadingIncidents) {
    let newState = update(state, {
        isLoadingIncidents: { $set: isLoadingIncidents }
    });
    return newState;
}

function updateFilter(state, filter) {
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setData(state, incidents, venueMarkers) {
    let newState = update(state, {
        incidents: { $set: incidents },
        venueMarkers: { $set: venueMarkers }
    });
    return newState;
}

function setIncidentsData(state, incidents) {
    let newState = update(state, {
        incidents: { $set: incidents }
    });
    return newState;
}


function setOptions(state, options) {
    let newState = update(state, {
        options: { $set: options }
    });
    return newState;
}

function processMapData(state) {
    let filteredVenueMarkers = [...state.venueMarkers];
    if (!state.options.showVenueMarkers) {
        filteredVenueMarkers = [];
    }
    if (!state.options.showActiveVenues) {
        filteredVenueMarkers = filteredVenueMarkers.filter(v => !v.isActive);
    }
    if (!state.options.showInactiveVenues) {
        filteredVenueMarkers = filteredVenueMarkers.filter(v => v.isActive);
    }
    // filter venues on postcodes
    let venueIds = state.venueMarkers.map((v) => v.venueId);
    let filteredIncidents: AusComplyDtos.IncidentHeatmap[] = [...state.incidents];
    if (state.options.postcodes.length > 0) {
        filteredVenueMarkers = filteredVenueMarkers.filter(v => state.options.postcodes.indexOf(v.postcode) > -1);
        venueIds = [...state.venueMarkers].filter(v => state.options.postcodes.indexOf(v.postcode) > -1).map((v) => v.venueId);
    }

    // filter incidents
    filteredIncidents = filteredIncidents.filter(i => venueIds.indexOf(i.venueId) > -1);

    let heatmap: any[] = [];
    if (state.options.showHeatmap) {
        filteredIncidents.forEach(i => {
            if (i.latitude && i.longitude) {
                heatmap.push(new google.maps.LatLng(i.latitude, i.longitude));
            }
        });
    }

    let newState = update(state, {
        filteredVenueMarkers: { $set: filteredVenueMarkers },
        heatmap: { $set: heatmap },
        filteredIncidents: { $set: filteredIncidents }
    });
    return newState;
}


function getMaxValue(dataValues) {
    var _iMaxValue = -1;
    for (var i = 0; i < dataValues.length; i++) {
        if (dataValues[i].Total > _iMaxValue) {
            _iMaxValue = dataValues[i].Total;
        }
    }
    return _iMaxValue;
}

function getGridlinesCount(dataValues) {
    var _iMaxValue = getMaxValue(dataValues);

    if (_iMaxValue <= 5) {
        return _iMaxValue + 1;
    } else {
        return 5;
    }
}

export default function adminDashboard(state = initialState, action) {
    switch (action.type) {
        case ADMIN_DASHBOARD_RESET:
            return { ...initialState };
        case ADMIN_DASHBOARD_SET_FILTER:
            return updateFilter(state, action.filter);
        case ADMIN_DASHBOARD_FILTER_REQUEST:
            return loading(state, true);
        case ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS:
            return loading(updateFilter(state, action.filter), false);
        case ADMIN_DASHBOARD_FILTER_REQUEST_FAILURE:
            return loading(state, false);
        case ADMIN_DASHBOARD_REQUEST:
            return setData(loading(state, true), [], []);
        case ADMIN_DASHBOARD_REQUEST_SUCCESS:
            return processMapData(
                setData(updateFilter(loading(state, false), action.filter), action.incidents, action.venueMarkers)
            );
        case ADMIN_DASHBOARD_REQUEST_FAILURE:
            return loading(state, false);
            
        case ADMIN_DASHBOARD_INCIDENTS_REQUEST:
            return loadingIncidents(state, true);
        case ADMIN_DASHBOARD_INCIDENTS_REQUEST_SUCCESS:
                return processMapData(
                    setIncidentsData(loadingIncidents(state, false), action.incidents)
                );
        case ADMIN_DASHBOARD_INCIDENTS_REQUEST_FAILURE:
            return loadingIncidents(state, false);

        case ADMIN_DASHBOARD_SET_OPTIONS:
            return processMapData(setOptions(state, action.options));
        case ADMIN_DASHBOARD_TOTALS_REQUEST:
            return setTotals(state, true, [], [], [], []);
        case ADMIN_DASHBOARD_TOTALS_REQUEST_SUCCESS:
            return setTotals(state, false, action.totalVenues, action.totalSecurityFirms, action.totalIncidents, action.totalUsers);
        case ADMIN_DASHBOARD_TOTALS_REQUEST_FAILURE:
            return setTotals(state, false, [], [], [], []);

        case ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST:
            return setQuick(state, true, []);
        case ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_SUCCESS:
            return setQuick(state, false, action.data);
        case ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_FAILURE:
            return setQuick(state, false, []);

        case ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST:
            return setIncidentTotals(state, true, [], [], []);
        case ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_SUCCESS:
            return setIncidentTotals(state, false, action.incidents, action.venueIncidents, action.securityFirmIncidents);
        case ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_FAILURE:
            return setIncidentTotals(state, false, [], [], []);

        case ADMIN_DASHBOARD_USER_TOTALS_REQUEST:
            return setUserTotals(state, true, [], []);
        case ADMIN_DASHBOARD_USER_TOTALS_REQUEST_SUCCESS:
            return setUserTotals(state, false, action.venueUsers, action.securityFirmUsers);
        case ADMIN_DASHBOARD_USER_TOTALS_REQUEST_FAILURE:
            return setUserTotals(state, false, [], []);
    }
    return state;
}