import { connect } from 'react-redux'
import IncidentTypes from '../components/admin/incidentTypes/IncidentTypes'
import { withRouter } from "react-router";
import { adminIncidentTypesRequest, adminIncidentTypeCreateNewRequest, adminIncidentTypeUpdateRequest, adminIncidentTypeSet } from '../common/actions/admin';
import { loadIndustryCategoriesRequest } from '../common/actions/referenceData';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.incidentTypes.isLoading,
        isSaving: state.admin.incidentTypes.isSaving,
        incidentTypes: state.admin.incidentTypes.incidentTypes,
        incidentType: state.admin.incidentTypes.incidentType,
        industryCategories: state.referenceData.industryCategories,
        paging: state.admin.incidentTypes.paging,
        filter: state.admin.incidentTypes.filter,
        error: state.admin.incidentTypes.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(adminIncidentTypesRequest(page, pageSize, filter))
        },
        onCreateNew:() => {
            dispatch(adminIncidentTypeCreateNewRequest())
        },
        onSet:(incidentType) => {
            dispatch(adminIncidentTypeSet(incidentType))
        },
        onUpdate:(incidentType) => {
            dispatch(adminIncidentTypeUpdateRequest(incidentType))
        },
        onLoadReferenceData:() => {
            dispatch(loadIndustryCategoriesRequest());
        }
    }
}

const IncidentTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentTypes))

export default IncidentTypesContainer