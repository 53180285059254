import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import {
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_FAILURE,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_RESET,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_SET,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_FAILURE,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_FAILURE,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_FAILURE

} from "../actions/facialRecognitionAutomaticEnrolment";
import { MulitSelectOption } from '../dto/common';

const initialState = {
    facialRecognitionAutomaticEnrolments: {
        venueId: -1,
        isLoading: false,
        facialRecognitionAutomaticEnrolments: [],
    },
    facialRecognitionAutomaticEnrolment: {
        facialRecognitionAutomaticEnrolmentId: -1,
        isLoading: false,
        isSaving: false,
        incidentTypes: [],
        incidentTypeCategoryTypes: [],
        incidentCategoryTypes: [],
        watchlists: [],
        facialRecognitionAutomaticEnrolment: { facialRecognitionAutomaticEnrolmentId: 0 },
    }
};

function setIsLoading(state, isLoading, venueId) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolments: {
            isLoading: { $set: isLoading },
            venueId: { $set: venueId }
        }
    });
    return newState;
}

function setAutomaticEnrolments(state, AutomaticEnrolments) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolments: {
            facialRecognitionAutomaticEnrolments: { $set: AutomaticEnrolments }
        }
    });
    return newState;
}

function loadingFacialRecognitionAutomaticEnrolment(state, isLoading) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolment: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionAutomaticEnrolment(state, facialRecognitionAutomaticEnrolment: AusComplyDtos.FacialRecognitionAutomaticEnrolment ) {
    // update the available categories based on the incident type
    let incidentTypeId = facialRecognitionAutomaticEnrolment.incidentTypeId || 0;
    let incidentCategoryTypes:MulitSelectOption[] = [];
    let allowedIncidentCategoryTypeIds:number[] = [];

    if(state.facialRecognitionAutomaticEnrolment.incidentTypeCategoryTypes)
    {
        let foundType:AusComplyDtos.IncidentTypeCategoryTypes = state.facialRecognitionAutomaticEnrolment.incidentTypeCategoryTypes
            .find(f => f.incidentTypeId.toString() == incidentTypeId.toString());
        if (foundType) {
            foundType.incidentCategoryTypes.forEach(ict => {
                allowedIncidentCategoryTypeIds.push(ict.incidentCategoryTypeId);
                let isSelected: boolean = false;
                if (facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes) {
                    isSelected = facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes.filter(f => f.incidentCategoryTypeId == ict.incidentCategoryTypeId).length > 0;
                }
                incidentCategoryTypes.push({
                    id: ict.incidentCategoryTypeId,
                    name: ict.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
    }

    // remove any invalid incident category types if the incident type does not match
    if (facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes) {
        let facialRecognitionAutomaticEnrolmentIncidentCategoryTypes = [...facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes];
        facialRecognitionAutomaticEnrolmentIncidentCategoryTypes = facialRecognitionAutomaticEnrolmentIncidentCategoryTypes.filter(f =>
            allowedIncidentCategoryTypeIds.indexOf(f.incidentCategoryTypeId) > -1);
            
        facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes = facialRecognitionAutomaticEnrolmentIncidentCategoryTypes;
    }
    /*if (facialRecognitionAutomaticEnrolment && 
        facialRecognitionAutomaticEnrolment.incidentTypeId && 
        state.facialRecognitionAutomaticEnrolment.incidentTypeCategoryTypes)
    {
        let foundType = state.facialRecognitionAutomaticEnrolment.incidentTypeCategoryTypes
            .find(f => f.incidentTypeId.toString() == facialRecognitionAutomaticEnrolment.incidentTypeId.toString());
        if (foundType) 
        {
            incidentCategoryTypes = [...foundType.incidentCategoryTypes];
        }
    }
    */
    
    //if (!incidentCategoryTypes.find(f => f.incidentCategoryTypeId == facialRecognitionAutomaticEnrolment.incidentCategoryTypeId)){
    //    facialRecognitionAutomaticEnrolment.incidentCategoryTypeId = 0;
    //}
    let newState = update(state, {
        facialRecognitionAutomaticEnrolment: {
            facialRecognitionAutomaticEnrolment: { $set: facialRecognitionAutomaticEnrolment },
            incidentCategoryTypes: { $set: incidentCategoryTypes }
        }
    });
    return newState;
}

function setFacialRecognitionAutomaticEnrolmentLists(state, incidentTypes, incidentTypeCategoryTypes, watchlists) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolment: {
            incidentTypes: { $set: incidentTypes },
            incidentTypeCategoryTypes: { $set: incidentTypeCategoryTypes },
            watchlists: { $set: watchlists }
        }
    });
    return newState;
}

function savingFacialRecognitionAutomaticEnrolment(state, isSaving) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolment: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFacialRecognitionAutomaticEnrolmentId(state, facialRecognitionAutomaticEnrolmentId) {
    let newState = update(state, {
        facialRecognitionAutomaticEnrolment: {
            facialRecognitionAutomaticEnrolmentId: { $set: facialRecognitionAutomaticEnrolmentId }
        }
    });
    return newState;
}

export default function facialRecognitionAutomaticEnrolments(state = initialState, action) {
    switch (action.type) {
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST:
            return setIsLoading(state, true, action.venueId);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_SUCCESS:
            return setIsLoading(setAutomaticEnrolments(state, action.facialRecognitionAutomaticEnrolments), false, state.facialRecognitionAutomaticEnrolments.venueId);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_FAILURE:
            return setIsLoading(state, false, state.facialRecognitionAutomaticEnrolments.venueId);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_RESET:
            return setFacialRecognitionAutomaticEnrolmentId(state, -1);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST:
            return setFacialRecognitionAutomaticEnrolment(loadingFacialRecognitionAutomaticEnrolment(setFacialRecognitionAutomaticEnrolmentId(state, action.facialRecognitionAutomaticEnrolmentId), true), new AusComplyDtos.FacialRecognitionAutomaticEnrolment());
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_SUCCESS:
            return setFacialRecognitionAutomaticEnrolment(
                setFacialRecognitionAutomaticEnrolmentLists(
                    loadingFacialRecognitionAutomaticEnrolment(state, false)
                    , action.incidentTypes, action.incidentTypeCategoryTypes, action.watchlists)
                , action.facialRecognitionAutomaticEnrolment);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_FAILURE:
            return loadingFacialRecognitionAutomaticEnrolment(setFacialRecognitionAutomaticEnrolmentId(state, -1), false);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST:
            return setFacialRecognitionAutomaticEnrolment(loadingFacialRecognitionAutomaticEnrolment(setFacialRecognitionAutomaticEnrolmentId(state, 0), true), new AusComplyDtos.FacialRecognitionAutomaticEnrolment());
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_SUCCESS:
            return setFacialRecognitionAutomaticEnrolment(
                setFacialRecognitionAutomaticEnrolmentLists(
                    loadingFacialRecognitionAutomaticEnrolment(state, false)
                    , action.incidentTypes, action.incidentTypeCategoryTypes, action.watchlists)
                , action.facialRecognitionAutomaticEnrolment);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_FAILURE:
            return loadingFacialRecognitionAutomaticEnrolment(setFacialRecognitionAutomaticEnrolmentId(state, -1), false);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST:
            return savingFacialRecognitionAutomaticEnrolment(state, true);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS:
            return setFacialRecognitionAutomaticEnrolment(savingFacialRecognitionAutomaticEnrolment(setFacialRecognitionAutomaticEnrolmentId(state, -1), false), action.facialRecognitionAutomaticEnrolment);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_FAILURE:
            return savingFacialRecognitionAutomaticEnrolment(state, false);
        case FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_SET:
            return setFacialRecognitionAutomaticEnrolment(state, action.facialRecognitionAutomaticEnrolment);

    }
    return state;
}