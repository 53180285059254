import update from "immutability-helper";
import {
    LOAD_ALL_REFERENCE_DATA,
    LOAD_INDUSTRY_CATEGORIES_REQUEST,
    LOAD_INDUSTRY_CATEGORIES_SUCCESS,
    LOAD_INDUSTRY_CATEGORIES_FAILURE,
    LOAD_STATES_REQUEST,
    LOAD_STATES_SUCCESS,
    LOAD_STATES_FAILURE,
    LOAD_REFERENCE_DATA_VENUES_REQUEST,
    LOAD_REFERENCE_DATA_VENUES_SUCCESS,
    LOAD_REFERENCE_DATA_VENUES_FAILURE,
    LOAD_SECURITY_FIRMS_REQUEST,
    LOAD_SECURITY_FIRMS_SUCCESS,
    LOAD_SECURITY_FIRMS_FAILURE,
    GET_VENUE_PERSONNEL_REQUEST,
    GET_VENUE_PERSONNEL_SUCCESS,
    GET_VENUE_PERSONNEL_FAILURE,
    GET_CHECKLIST_STATUS_REQUEST,
    GET_CHECKLIST_STATUS_SUCCESS,
    GET_CHECKLIST_STATUS_FAILURE,
    GET_INCIDENT_TYPES_REQUEST,
    GET_INCIDENT_TYPES_SUCCESS,
    GET_INCIDENT_TYPES_FAILURE,
    GET_INCIDENT_CATEGORY_TYPES_REQUEST,
    GET_INCIDENT_CATEGORY_TYPES_SUCCESS,
    GET_INCIDENT_CATEGORY_TYPES_FAILURE,
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST,
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS,
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE,
    LOAD_USER_VENUES_REQUEST,
    LOAD_USER_VENUES_SUCCESS,
    LOAD_USER_VENUES_FAILURE,
    LOAD_USER_SECURITY_FIRMS_REQUEST,
    LOAD_USER_SECURITY_FIRMS_SUCCESS,
    LOAD_USER_SECURITY_FIRMS_FAILURE,
    LOAD_USER_ROLE_TYPES_REQUEST,
    LOAD_USER_ROLE_TYPES_FAILURE,
    LOAD_USER_ROLE_TYPES_SUCCESS
} from "../actions/referenceData";

const initialState = {
    industryCategoriesLoading: false,
    statesLoading: false,
    venuesLoading: false,
    securityFirmsLoading: false,
    venuePersonnelLoading: false,
    industryCategories: [],
    states: [],
    venues: [],
    securityFirms: [],
    venuePersonnel: [],
    checklistStatuses: [],
    checklistStatusesLoading: false,
    incidentTypes: [],
    incidentTypesLoading: false,
    incidentCategoryTypes: [],
    incidentCategoryTypesLoading: false,
    checklistTemplateCategories: [],
    checklistTemplateCategoriesLoading: false,
    userRoleTypes: [],
    userRoleTypesLoading: false,
    userVenues: [],
    userVenuesLoading: false,
    userSecurityFirms: [],
    userSecurityFirmsLoading: false,
};

function setUserRoleTypes(state, loading, userRoleTypes) {
    let newState = update(state, {
        userRoleTypes: { $set: userRoleTypes },
        userRoleTypesLoading: { $set: loading }
    });
    return newState;
}

function setUserVenues(state, loading, venues) {
    let newState = update(state, {
        userVenues: { $set: venues },
        userVenuesLoading: { $set: loading }
    });
    return newState;
}

function setUserSecurityFirms(state, loading, securityFirms) {
    let newState = update(state, {
        userSecurityFirms: { $set: securityFirms },
        userSecurityFirmsLoading: { $set: loading }
    });
    return newState;
}

function loadIncidentCategoryTypes(state, action) {
    let newState = update(state, {
        incidentCategoryTypes: { $set: action.incidentCategoryTypes }
    });
    return loadingIncidentCategoryTypes(newState, false);
}

function loadingIncidentCategoryTypes(state, isLoading) {
    let newState = update(state, {
        incidentCategoryTypesLoading: { $set: isLoading }
    });
    return newState;
}

function loadIncidentTypes(state, action) {
    let newState = update(state, {
        incidentTypes: { $set: action.incidentTypes }
    });
    return loadingIncidentTypes(newState, false);
}

function loadingIncidentTypes(state, isLoading) {
    let newState = update(state, {
        incidentTypesLoading: { $set: isLoading }
    });
    return newState;
}

function loadChecklistStatuses(state, action) {
    let newState = update(state, {
        checklistStatuses: { $set: action.checklistStatuses }
    });
    return loadingChecklistStatuses(newState, false);
}

function loadingChecklistStatuses(state, isLoading) {
    let newState = update(state, {
        checklistStatusesLoading: { $set: isLoading }
    });
    return newState;
}

function loadIndustryCategories(state, action) {
    let newState = update(state, {
        industryCategories: { $set: action.industryCategories }
    });
    return loadingIndustryCategories(newState, false);
}

function loadingIndustryCategories(state, isLoading) {
    let newState = update(state, {
        industryCategoriesLoading: { $set: isLoading }
    });
    return newState;
}

function loadStates(state, action) {
    let newState = update(state, {
        states: { $set: action.states }
    });
    return loadingStates(newState, false);
}

function loadingStates(state, isLoading) {
    let newState = update(state, {
        statesLoading: { $set: isLoading }
    });
    return newState;
}

function loadVenues(state, action) {
    let newState = update(state, {
        venues: { $set: action.venues }
    });
    return loadingVenues(newState, false);
}

function loadingVenues(state, isLoading) {
    let newState = update(state, {
        venuesLoading: { $set: isLoading }
    });
    return newState;
}

function loadVenuePersonnel(state, action) {
    let newState = update(state, {
        venuePersonnel: { $set: action.personnel }
    });
    return loadingVenuePersonnel(newState, false);
}

function loadingVenuePersonnel(state, isLoading) {
    let newState = update(state, {
        venuePersonnelLoading: { $set: isLoading }
    });
    return newState;
}

function loadSecurityFirms(state, action) {
    let newState = update(state, {
        securityFirms: { $set: action.securityFirms }
    });
    return loadingSecurityFirms(newState, false);
}

function loadingSecurityFirms(state, isLoading) {
    let newState = update(state, {
        securityFirmsLoading: { $set: isLoading }
    });
    return newState;
}

function loadChecklistTemplateCategories(state, action) {
    let newState = update(state, {
        checklistTemplateCategories: { $set: action.checklistTemplateCategories }
    });
    return loadingChecklistTemplateCategories(newState, false);
}

function loadingChecklistTemplateCategories(state, isLoading) {
    let newState = update(state, {
        checklistTemplateCategoriesLoading: { $set: isLoading }
    });
    return newState;
}

export default function userSession(state = initialState, action) {
    switch (action.type) {
        case LOAD_INDUSTRY_CATEGORIES_REQUEST:
            return loadingIndustryCategories(state, true);
        case LOAD_INDUSTRY_CATEGORIES_FAILURE:
            return loadingIndustryCategories(state, false);
        case LOAD_INDUSTRY_CATEGORIES_SUCCESS:
            return loadIndustryCategories(state, action);
        case LOAD_STATES_REQUEST:
            return loadingStates(state, true);
        case LOAD_STATES_FAILURE:
            return loadingStates(state, false);
        case LOAD_STATES_SUCCESS:
            return loadStates(state, action);
        case LOAD_REFERENCE_DATA_VENUES_REQUEST:
            return loadingVenues(state, true);
        case LOAD_REFERENCE_DATA_VENUES_FAILURE:
            return loadingVenues(state, false);
        case LOAD_REFERENCE_DATA_VENUES_SUCCESS:
            return loadVenues(state, action);
        case LOAD_SECURITY_FIRMS_REQUEST:
            return loadingSecurityFirms(state, true);
        case LOAD_SECURITY_FIRMS_FAILURE:
            return loadingSecurityFirms(state, false);
        case LOAD_SECURITY_FIRMS_SUCCESS:
            return loadSecurityFirms(state, action);
        case GET_VENUE_PERSONNEL_REQUEST:
            return loadingVenuePersonnel(state, true);
        case GET_VENUE_PERSONNEL_FAILURE:
            return loadingVenuePersonnel(state, false);
        case GET_VENUE_PERSONNEL_SUCCESS:
            return loadVenuePersonnel(state, action);
        case GET_CHECKLIST_STATUS_REQUEST:
            return loadingChecklistStatuses(state, true);
        case GET_CHECKLIST_STATUS_FAILURE:
            return loadingChecklistStatuses(state, false);
        case GET_CHECKLIST_STATUS_SUCCESS:
            return loadChecklistStatuses(state, action);
        case GET_INCIDENT_TYPES_REQUEST:
            return loadingIncidentTypes(state, true);
        case GET_INCIDENT_TYPES_FAILURE:
            return loadingIncidentTypes(state, false);
        case GET_INCIDENT_TYPES_SUCCESS:
            return loadIncidentTypes(state, action);
        case GET_INCIDENT_CATEGORY_TYPES_REQUEST:
            return loadingIncidentCategoryTypes(state, true);
        case GET_INCIDENT_CATEGORY_TYPES_FAILURE:
            return loadingIncidentCategoryTypes(state, false);
        case GET_INCIDENT_CATEGORY_TYPES_SUCCESS:
            return loadIncidentCategoryTypes(state, action);
        case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST:
            return loadingChecklistTemplateCategories(state, true);
        case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE:
            return loadingChecklistTemplateCategories(state, false);
        case LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS:
            return loadChecklistTemplateCategories(state, action);
        case LOAD_USER_ROLE_TYPES_REQUEST:
            return setUserRoleTypes(state, true, []);
        case LOAD_USER_ROLE_TYPES_FAILURE:
            return setUserRoleTypes(state, false, []);
        case LOAD_USER_ROLE_TYPES_SUCCESS:
            return setUserRoleTypes(state, false, action.userRoleTypes);
        case LOAD_USER_VENUES_REQUEST:
            return setUserVenues(state, true, []);
        case LOAD_USER_VENUES_FAILURE:
            return setUserVenues(state, false, []);
        case LOAD_USER_VENUES_SUCCESS:
            return setUserVenues(state, false, action.venues);
        case LOAD_USER_SECURITY_FIRMS_REQUEST:
            return setUserSecurityFirms(state, true, []);
        case LOAD_USER_SECURITY_FIRMS_FAILURE:
            return setUserSecurityFirms(state, false, []);
        case LOAD_USER_SECURITY_FIRMS_SUCCESS:
            return setUserSecurityFirms(state, false, action.securityFirms);
    }

    return state;
}
