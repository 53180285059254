import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import LayoutForm from '../../layout/LayoutForm';
import PageInfo from '../../common/PageInfo';
import ControlGroup from "../../controls/ControlGroup";

export interface IRegisterUserPasswordProps {
    user: AusComplyDTOs.User;
    errors: any[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserPassword extends Component<IRegisterUserPasswordProps, any> {
    constructor(props: IRegisterUserPasswordProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error) {
                return error.error;
            }
        }
        return "";
    }

    onChange(fieldName, value) {
        let user = { ...this.props.user };
        user[fieldName] = value;
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <LayoutForm>
                    <PasswordControlGroup
                        text={"Password"}
                        error={this.fieldError("newPassword")}
                        defaultValue={this.props.user.newPassword}
                        onChange={(value) => this.onChange("newPassword", value)} />
                    <PasswordControlGroup
                        text={"Confirm Password"}
                        error={this.fieldError("newPasswordConfirm")}
                        defaultValue={this.props.user.newPasswordConfirm}
                        onChange={(value) => this.onChange("newPasswordConfirm", value)} />
                    <ControlGroup text={" "}>
                        <PageInfo
                            text={"Password must be over 8 characters and include at least one uppercase letter, lowercase letter, number and special character"}
                        />
                    </ControlGroup>
                </LayoutForm>
            </SwipeContainer>
        );
    }
}