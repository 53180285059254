import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";

export function makePost(type, ...argNames) {
  return function (...args) {
    var client = new AusComplyJsonServiceClient();
    var query = new type();
    argNames.forEach((arg, index) => {
      query[argNames[index]] = args[index]
    })
    const request = client.post<any>(query);
    return from<Promise<any>>(request);
  }
}
export function post(type, ...argNames) {
  return function (state, action) {
    var client = new AusComplyJsonServiceClient();
    var query = createQuery(type, state, argNames, action);
    const request = client.post<any>(query);
    return from<Promise<any>>(request);
  }
}
export function makeGet(type, ...argNames) {
  return function (...args) {
    var client = new AusComplyJsonServiceClient();
    var query = new type();
    argNames.forEach((arg, index) => {
      query[argNames[index]] = args[index]
    })
    const request = client.get<any>(query);
    return from<Promise<any>>(request);
  }
}
export function get(type, ...argNames) {
  return function (state, action) {
    var client = new AusComplyJsonServiceClient();
    var query = createQuery(type, state, argNames, action);
    const request = client.get<any>(query);
    return from<Promise<any>>(request);
  }
}
export function makeDelete(type, ...argNames) {
  return function (...args) {
    var client = new AusComplyJsonServiceClient();
    var query = new type();
    argNames.forEach((arg, index) => {
      query[argNames[index]] = args[index]
    })
    const request = client.delete<any>(query);
    return from<Promise<any>>(request);
  }
}
export function remove(type, ...argNames) {
  return function (state, action) {
    var client = new AusComplyJsonServiceClient();
    var query = createQuery(type, state, argNames, action);
    const request = client.delete<any>(query);
    return from<Promise<any>>(request);
  }
}
function createQuery(type, state, argNames, action) {
    var query = new type();

    query.venueId = state.user.details.userSession.user.venueId;
    query.securityFirmId = state.user.details.userSession.user.securityFirmId;
    query.venueEventId = state.user.details.userSession.user.venueEventId;

    argNames.forEach((arg, index) => {
      query[argNames[index]] = action[argNames[index]]
    })

    return query;
}
