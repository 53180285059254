import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../common/ImagePreview';
import Thumbnail from '../common/Thumbnail';
import Box from '@material-ui/core/Box';
import FileUpload from '../common/FileUpload';
import Grid from '@material-ui/core/Grid';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../controls/SelectControlGroup';
import SimpleDateControlGroup from '../controls/SimpleDateControlGroup';
import DefaultButton from '../controls/DefaultButton';
import ControlGroup from '../controls/ControlGroup';
import ConfirmDialog from '../controls/ConfirmDialog';
import NoRowsMessage from '../common/NoRowsMessage';

export interface IProfileComplianceDocumentsProps {
    documents: AusComplyDtos.UserComplianceDocument[];
    documentTypes: AusComplyDtos.DocumentType[];
    onSave: Function;
    onRemove: Function;
    showObsolete?: boolean;
    classes: any;
    theme: any;
}

interface IProfileComplianceDocumentsState {
    selectedFilePath: string;
    files: AusComplyDtos.File[];
    documentToRemove: AusComplyDtos.UserComplianceDocument,
    documentTypeId: number;
    expiryDate: AusComplyDtos.SimpleDate;
}

class ProfileComplianceDocuments extends React.Component<IProfileComplianceDocumentsProps, IProfileComplianceDocumentsState> {
    constructor(props: IProfileComplianceDocumentsProps) {
        super(props)
        let expiryDate = new AusComplyDtos.SimpleDate();
        expiryDate.isEmpty = true;
        this.state = {
            selectedFilePath: "",
            files: [],
            documentToRemove: new AusComplyDtos.UserComplianceDocument(),
            documentTypeId: 0,
            expiryDate
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onDocumentTypeChanged = this.onDocumentTypeChanged.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.removeDocumentConfirmed = this.removeDocumentConfirmed.bind(this);
        this.removeDocumentCancel = this.removeDocumentCancel.bind(this);
        this.onExpiryDateChanged = this.onExpiryDateChanged.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    removeDocument(document: AusComplyDtos.UserComplianceDocument) {
        this.setState({
            documentToRemove: document
        });
    }

    removeDocumentCancel() {
        this.setState({
            documentToRemove: new AusComplyDtos.UserComplianceDocument()
        });
    }

    removeDocumentConfirmed() {
        this.props.onRemove(this.state.documentToRemove);
        this.setState({
            documentToRemove: new AusComplyDtos.UserComplianceDocument()
        });
    }

    removeTemporary(index: number) {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({ files });
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {

        let files = [...this.state.files, ...temporaryFiles];
        this.setState({ files });

    }

    onDocumentTypeChanged(value) {
        this.setState({ documentTypeId: value });
    }

    onExpiryDateChanged(value: AusComplyDtos.SimpleDate) {
        this.setState({ expiryDate: value });
    }

    onUpload() {
        this.props.onSave(this.state.documentTypeId, this.state.expiryDate, this.state.files);
    }

    render() {
        const { classes, theme } = this.props;

        let documents;
        let hasDocuments = false;
        if (this.props.documents) {
            documents = this.props.documents.filter(item => !item.obsolete || this.props.showObsolete).map((item, index) => {
                hasDocuments = true;
                let borderColor = item.hasExpired ? theme.custom.colors.error : "#151515";
                if (item.obsolete) {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={"file-poi-item-" + item.originalFileName + "_" + index.toString()}>
                            <div key={"file-poi-" + item.originalFileName + "_" + index.toString()}
                                style={{
                                    backgroundColor: '#151515',
                                    borderRadius: '5px',
                                    borderColor: theme.custom.colors.warning,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    margin: '0 10px 10px 0',
                                    padding: '10px',
                                    minWidth: '180px',
                                    overflow: 'hidden'
                                }}>
                                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                    <Box p={0}>
                                        <Thumbnail
                                            key={"file-" + item.originalFileName + "_" + index.toString()}
                                            previewPath={item.filePreviewPath}
                                            displayPath={item.fileDisplayPath}
                                            isImage={item.isImage}
                                            text={""}
                                            onPreview={path => this.onSelectFilePath(path)}
                                            onDownload={path => this.onDownload(path)}
                                        />
                                    </Box>
                                    <Box p={0} flexGrow={1}>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0', color: theme.custom.colors.warning }}>REMOVED</p>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                        <p style={{ borderRadius: '3px', backgroundColor: borderColor, display: 'inline-block', padding: '3px' , margin: '3px 0' }}>{item.dateOfExpiry.display }</p>
                                        <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    )
                }
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"file-poi-item-" + item.originalFileName + "_" + index.toString()}>
                        <div key={"file-poi-" + item.originalFileName + "_" + index.toString()}
                            style={{
                                backgroundColor: '#151515',
                                borderRadius: '5px',
                                borderColor: borderColor,
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                margin: '0 10px 10px 0',
                                padding: '10px',
                                minWidth: '180px',
                                overflow: 'hidden'
                            }}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + item.originalFileName + "_" + index.toString()}
                                        previewPath={item.filePreviewPath}
                                        displayPath={item.fileDisplayPath}
                                        isImage={item.isImage}
                                        text={""}
                                        onPreview={path => this.onSelectFilePath(path)}
                                        onDownload={path => this.onDownload(path)}
                                        onRemove={() => this.removeDocument(item)}
                                    />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                    <p style={{ borderRadius: '3px', backgroundColor: borderColor, display: 'inline-block', padding: '3px' , margin: '3px 0' }}>{item.dateOfExpiry.display }</p>
                                    <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                )
            });
        }

        let files = this.state.files.map((item, index) => {
            return (
                <Box p={0} key={"file-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        onPreview={path => this.onSelectFilePath(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporary(index)}
                    />
                </Box >
            );
        })

        let showConfirm = false;
        if (this.state.documentToRemove && this.state.documentToRemove.userDocumentId && this.state.documentToRemove.userDocumentId != 0) {
            showConfirm = true;
        }

        return (
            <>
                {!hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <NoRowsMessage message={"No compliance documents"} />
                    </ControlGroup>
                )}
                {hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <Grid container spacing={1}>
                            {documents}
                        </Grid>
                    </ControlGroup>
                )}
                <CardContainer
                    title={"Upload compliance document"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <SelectControlGroup text={"Document Folder"}
                                    onChange={this.onDocumentTypeChanged}>
                                    <option value={0} />
                                    {this.props.documentTypes.map((item, index) => (
                                        <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SimpleDateControlGroup text="Expiry Date"
                                    date={this.state.expiryDate}
                                    onChange={(value) => this.onExpiryDateChanged(value)} />
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                            {files}
                        </Box>
                        {this.state.documentTypeId != 0 && (
                            <>
                                <FileUpload onFileWasUploaded={this.fileWasUploaded} alternateIcons={true} hasFiles={this.state.files.length > 0} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DefaultButton text={"Upload"} disabled={this.state.files.length === 0 || this.state.expiryDate.isEmpty} onClick={this.onUpload} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </CardContainer>
                <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
                <ConfirmDialog
                    title={"Confirm document removal"}
                    text={"Are you sure you wish to remove '" + this.state.documentToRemove.documentType + "' document '" + this.state.documentToRemove.originalFileName + "'"}
                    show={showConfirm}
                    onCancel={this.removeDocumentCancel}
                    onConfirm={this.removeDocumentConfirmed} />
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(ProfileComplianceDocuments);