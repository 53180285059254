import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { checklistApi } from "../services/checklist";
import { notificationShow } from "../actions/notification";
import {
  notifyError, notifyErrorMessage, notifyErrorResponseStatus
} from './common';
import { referenceDataApi } from "../services/referenceData";
import {
  LOAD_ALL_REFERENCE_DATA,
  getVenuePersonnelSuccess,
  getVenuePersonnelFailure,
  GET_VENUE_PERSONNEL_REQUEST,
  GET_VENUE_PERSONNEL_SUCCESS,
  GET_VENUE_PERSONNEL_FAILURE,
  loadIndustryCategoriesRequest,
  loadIndustryCategoriesSuccess,
  loadIndustryCategoriesFailure,
  LOAD_INDUSTRY_CATEGORIES_REQUEST,
  LOAD_INDUSTRY_CATEGORIES_SUCCESS,
  LOAD_INDUSTRY_CATEGORIES_FAILURE,
  loadStatesRequest,
  loadStatesSuccess,
  loadStatesFailure,
  LOAD_STATES_REQUEST,
  LOAD_STATES_SUCCESS,
  LOAD_STATES_FAILURE,
  loadVenuesRequest,
  loadVenuesSuccess,
  loadVenuesFailure,
  LOAD_REFERENCE_DATA_VENUES_REQUEST,
  LOAD_REFERENCE_DATA_VENUES_SUCCESS,
  LOAD_REFERENCE_DATA_VENUES_FAILURE,
  loadSecurityFirmsRequest,
  loadSecurityFirmsSuccess,
  loadSecurityFirmsFailure,
  LOAD_SECURITY_FIRMS_REQUEST,
  LOAD_SECURITY_FIRMS_SUCCESS,
  LOAD_SECURITY_FIRMS_FAILURE,
  GET_CHECKLIST_STATUS_REQUEST,
  getChecklistStatusFailure,
  getChecklistStatusSuccess,
  GET_INCIDENT_TYPES_REQUEST,
  getIncidentTypesRequest,
  getIncidentTypesFailure,
  getIncidentTypesSuccess,
  GET_INCIDENT_CATEGORY_TYPES_REQUEST,
  getIncidentCategoryTypesRequest,
  getIncidentCategoryTypesFailure,
  getIncidentCategoryTypesSuccess,
  LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST,
  loadChecklistTemplateCategoriesRequest,
  loadChecklistTemplateCategoriesSuccess,
  loadChecklistTemplateCategoriesFailure,
  LOAD_USER_ROLE_TYPES_REQUEST,
  loadUserRoleTypesRequest,
  loadUserRoleTypesSuccess,
  loadUserRoleTypesFailure,
  LOAD_USER_VENUES_REQUEST,
  loadUserVenuesRequest,
  loadUserVenuesSuccess,
  loadUserVenuesFailure,
  LOAD_USER_SECURITY_FIRMS_REQUEST,
  loadUserSecurityFirmsRequest,
  loadUserSecurityFirmsSuccess,
  loadUserSecurityFirmsFailure
} from "../actions/referenceData";


const loadAllReferenceDataStatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadStatesRequest())
  );

const loadAllReferenceDataVenuesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadVenuesRequest())
  );

const loadAllReferenceDataSecurityFirmsEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadSecurityFirmsRequest())
  );

const loadAllReferenceDataCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadIndustryCategoriesRequest())
  );

const loadAllReferenceChecklistTemplateCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadChecklistTemplateCategoriesRequest())
  );

const loadAllReferenceIncidentCategoryTypesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => getIncidentCategoryTypesRequest())
  );

const loadAllReferenceIncidentTypesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => getIncidentTypesRequest())
  );

const loadAllReferenceUserVenuesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadUserVenuesRequest())
  );

const loadAllReferenceUserSecurityFirmsEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_ALL_REFERENCE_DATA),
    map(action => loadUserSecurityFirmsRequest())
  );

const getIndustryCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_INDUSTRY_CATEGORIES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getIndustryCategories(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => loadIndustryCategoriesSuccess(response.industryCategories)),
          catchError(error => notifyErrorResponseStatus(error, "getIndustryCategoriesEpic.getIndustryCategories", loadIndustryCategoriesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getIndustryCategoriesEpic", loadIndustryCategoriesFailure))
  );

const getChecklistStatusesEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_CHECKLIST_STATUS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getChecklistStatuses(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => getChecklistStatusSuccess(response.checklistStatuses)),
          catchError(error => notifyErrorResponseStatus(error, "getChecklistStatusesEpic.getChecklistStatuses", getChecklistStatusFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getChecklistStatusesEpic", getChecklistStatusFailure))
  );


const getStatesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_STATES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getStates(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => loadStatesSuccess(response.states)),
          catchError(error => notifyErrorResponseStatus(error, "getStatesEpic.getStates", loadStatesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getStatesEpic", loadStatesFailure))
  );

const getVenuesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_REFERENCE_DATA_VENUES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getVenues(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => loadVenuesSuccess(response.result)),
          catchError(error => notifyErrorResponseStatus(error, "getVenuesEpic.getVenues", loadVenuesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getVenuesEpic", loadVenuesFailure))
  );

const getSecurityFirmsEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_SECURITY_FIRMS_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getSecurityFirms(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => loadSecurityFirmsSuccess(response.result)),
          catchError(error => notifyErrorResponseStatus(error, "getSecurityFirmsEpic.getSecurityFirms", loadSecurityFirmsFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getSecurityFirmsEpic", loadSecurityFirmsFailure))
  );

/*
 

export const getVenuePersonnelEpic = action$ =>
action$.ofType(GET_VENUE_PERSONNEL_REQUEST).mergeMap(action =>
  referenceDataApi
    .getVenuePersonnel(action.venueId)
    .map(response => getVenuePersonnelSuccess(response.personnel))
    .catch(error =>
      Observable.of(getVenuePersonnelFailure(error.responseStatus))
    )
);
*/

const getIncidentTypesEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_INCIDENT_TYPES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getIncidentTypes(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => getIncidentTypesSuccess(response.incidentTypes)),
          catchError(error => notifyErrorResponseStatus(error, "getIncidentTypesEpic.getIncidentTypes", getIncidentTypesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getIncidentTypesEpic", getIncidentTypesFailure))
  );

const getIncidentCategoryTypesEpic = (action$, state$) =>
  action$.pipe(
    ofType(GET_INCIDENT_CATEGORY_TYPES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getIncidentCategoryTypes(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => getIncidentCategoryTypesSuccess(response.incidentCategoryTypes)),
          catchError(error => notifyErrorResponseStatus(error, "getIncidentCategoryTypesEpic.getIncidentCategoryTypes", getIncidentCategoryTypesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getIncidentCategoryTypesEpic", getIncidentCategoryTypesFailure))
  );

const getChecklistTemplateCategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getChecklistTemplateCategories(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
        .pipe(
          map(response => loadChecklistTemplateCategoriesSuccess(response.checklistTemplateCategories)),
          catchError(error => notifyErrorResponseStatus(error, "getChecklistTemplateCategoriesEpic.getChecklistTemplateCategories", loadChecklistTemplateCategoriesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "getChecklistTemplateCategoriesEpic", loadChecklistTemplateCategoriesFailure))
  );

const loadUserRoleTypesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_USER_ROLE_TYPES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getUserRoleTypes(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['forVenueId'], action['forSecurityFirmId'])
        .pipe(
          map(response => loadUserRoleTypesSuccess(response.userRoleTypes)),
          catchError(error => notifyErrorResponseStatus(error, "loadUserRoleTypesRequestEpic.getUserRoleTypes", loadUserVenuesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "loadUserRoleTypesRequestEpic", loadUserVenuesFailure))
  );

const loadUserVenuesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_USER_VENUES_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      referenceDataApi.getUserVenues()
        .pipe(
          map(response => loadUserVenuesSuccess(response.result)),
          catchError(error => notifyErrorResponseStatus(error, "loadUserVenuesRequestEpic.getUserVenues", loadUserVenuesFailure))
        )
    ),
    catchError(error => notifyErrorResponseStatus(error, "loadUserVenuesRequestEpic", loadUserVenuesFailure))
  );

  const loadUserSecurityFirmsRequestEpic = (action$, state$) =>
    action$.pipe(
      ofType(LOAD_USER_SECURITY_FIRMS_REQUEST),
      withLatestFrom(state$),
      mergeMap(([action, state]) =>
        referenceDataApi.getUserSecurityFirms()
          .pipe(
            map(response => loadUserSecurityFirmsSuccess(response.result)),
            catchError(error => notifyErrorResponseStatus(error, "loadUserSecurityFirmsRequestEpic.getUserSecurityFirms", loadUserSecurityFirmsFailure))
          )
      ),
      catchError(error => notifyErrorResponseStatus(error, "loadUserSecurityFirmsRequestEpic", loadUserSecurityFirmsFailure))
    );

export const referenceDataEpics = combineEpics(
  loadUserRoleTypesRequestEpic,
  getChecklistStatusesEpic,
  loadAllReferenceDataStatesEpic,
  loadAllReferenceDataVenuesEpic,
  loadAllReferenceDataSecurityFirmsEpic,
  loadAllReferenceDataCategoriesEpic,
  getIndustryCategoriesEpic,
  getStatesEpic,
  getVenuesEpic,
  getSecurityFirmsEpic,
  loadAllReferenceChecklistTemplateCategoriesEpic,
  getChecklistTemplateCategoriesEpic,
  getIncidentCategoryTypesEpic,
  getIncidentTypesEpic,
  loadAllReferenceIncidentCategoryTypesEpic,
  loadAllReferenceIncidentTypesEpic,
  loadAllReferenceUserVenuesEpic,
  loadAllReferenceUserSecurityFirmsEpic,
  loadUserVenuesRequestEpic,
  loadUserSecurityFirmsRequestEpic
);
