import { connect } from 'react-redux'
import BroadcastNotifications from '../components/broadcastNotifications/broadcastNotifications/BroadcastNotifications'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { withRouter } from "react-router";
import { 
    broadcastNotificationsRequest, 
    broadcastNotificationCreateRequest, 
    broadcastNotificationRequest, 
    broadcastNotificationReset,
    broadcastNotificationSet,
    broadcastNotificationUpsertRequest,
    broadcastNotificationsTestRequest
 } from '../common/actions/broadcastNotification';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        paging: state.broadcastNotification.broadcastNotifications.paging,
        filter: state.broadcastNotification.broadcastNotifications.filter,
        isLoading: state.broadcastNotification.broadcastNotifications.isLoading,
        broadcastNotifications: state.broadcastNotification.broadcastNotifications.broadcastNotifications,
        broadcastNotificationId: state.broadcastNotification.broadcastNotification.broadcastNotificationId,
        canEdit: permissionsLogic.hasPermissionForState(state, "CreateBroadcastNotification")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(broadcastNotificationsRequest(page, pageSize, filter))
        },
        onLoadItem: (broadcastNotificationId) => {
            dispatch(broadcastNotificationRequest(broadcastNotificationId))
        },
        onCreate: (venueId, securityFirmId, cloneId) => {
            dispatch(broadcastNotificationCreateRequest(venueId, securityFirmId, cloneId))
        },
        onResetItem: () => {
            dispatch(broadcastNotificationReset())
        },
        onUpdate: (broadcastNotification) => {
            dispatch(broadcastNotificationSet(broadcastNotification))
        },
        onSave: () => {
            dispatch(broadcastNotificationUpsertRequest())
        },
        onTest: (broadcastNotificationId) => {
            dispatch(broadcastNotificationsTestRequest(broadcastNotificationId))
        }
    }
}

const BroadcastNotificationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BroadcastNotifications))

export default BroadcastNotificationsContainer