import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewNotifiedUsersProps {
    incident: IncidentDTO;
}

export default class IncidentViewNotifiedUsers extends Component<IIncidentViewNotifiedUsersProps, any> {
    render() {
        if (!this.props.incident.incidentNotifiedUserRoles || this.props.incident.incidentNotifiedUserRoles.length == 0) {
            return (<></>);
        }
        let users = this.props.incident.incidentNotifiedUserRoles.map((item, index) => <span key={index}>{index > 0 && (<br />)}{item.display}</span>);
        
        return (
            <ControlGroup text="Notified Users">
                <TextDisplay>
                    {users}
                  </TextDisplay>
            </ControlGroup>
        );
    }
}