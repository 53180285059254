import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "../epics/rootEpic";
import user from '../reducers/user'
import notification from '../reducers/notification';
import location from '../reducers/location';
import venueSecurityFirm from '../reducers/venueSecurityFirm';
import userVenueSecurityFirm from '../reducers/userVenueSecurityFirm';
import content from '../reducers/content';
import signOn from '../reducers/signOn';
import profile from '../reducers/profile';
import draftIncident from '../reducers/draftIncident';
import editIncident from '../reducers/editIncident';
import incidents from '../reducers/incidents';
import print from '../reducers/print';
import incidentFind from '../reducers/incidentFind';
import displayIncident from '../reducers/displayIncident';
import admin from '../reducers/admin';
import userRole from '../reducers/userRole';
import registerUser from '../reducers/registerUser';
import notifications from '../reducers/notifications';
import checklist from '../reducers/checklist';
import checklistTemplate from '../reducers/checklistTemplate';
import referenceData from '../reducers/referenceData';
import venue from '../reducers/venue';
import securityFirm from '../reducers/securityFirm';
import userComplianceDocument from '../reducers/userComplianceDocument';
import registerVenue from '../reducers/registerVenue';
import { userLoginRequestSuccess, userLogoutRequest, userReset } from '../actions/userLogin';
import { locationRequest } from '../actions/location';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/hardSet'
import { commonLogic } from '../logic/common';
import { STORE_TIMEOUT } from '../../utilities/constants';
import { LocalStorage } from '../../utilities/storage';
import systemLog from '../reducers/systemLog';
import registerSecurityFirm from '../reducers/registerSecurityFirm';
import dashboard from '../reducers/dashboard';
import adminDashboard from '../reducers/adminDashboard';
import securityFirmAssociations from '../reducers/securityFirmAssociations';
import checklistTemplates from '../reducers/checklistTemplates';
import broadcastNotification from '../reducers/broadcastNotification';
import facialRecognitionNotification from '../reducers/facialRecognitionNotification';
import incidentFlagTypeNotification from '../reducers/incidentFlagTypeNotification';
import incidentActionTemplates from '../reducers/incidentActionTemplate';
import groups from '../reducers/group';
import facialRecognitionServer from '../reducers/facialRecognitionServer';
import facialRecognitionWatchlists from '../reducers/facialRecognitionWatchlist';
import facialRecognitionPOI from '../reducers/facialRecognitionPOI';
import facialRecognitionFeed from '../reducers/facialRecognitionFeed';
import facialRecognitionAutomaticEnrolments from '../reducers/facialRecognitionAutomaticEnrolment';
import correctionalFacility from '../reducers/correctionalFacility';
import directories from '../reducers/directories';
import schedules from '../reducers/schedule';
import imageProcessing from '../reducers/imageProcessing';
import radioChannels from '../reducers/radioChannel';
import subLocations from '../reducers/subLocation';
import functionalAreas from '../reducers/functionalArea';
import incidentCategoryTypes from '../reducers/incidentCategoryType';
import notes from '../reducers/notes';
import incidentDocuments from '../reducers/incidentDocuments';
import rosters from '../reducers/roster';
import externalApiConnections from '../reducers/externalApiConnection';
import signOnRegisterTypes from '../reducers/signOnRegisterType';
import externalEvents from '../reducers/externalEvent';
import draftIncidentOptions from '../reducers/draftIncidentOptions';
import notificationTrigger from "../reducers/notificationTrigger";
import venueEvent from "../reducers/venueEvent";

export const persistConfig: PersistConfig = {
  key: 'auscomplyV2_27_9',
  storage,
  stateReconciler: autoMergeLevel1,
  timeout: STORE_TIMEOUT,
  blacklist: [
    'notification',
    'dashboard',
    'adminDashboard',
    'content',
    'signOn',
    'draftIncidentOptions',
    'editIncident',
    'incidents',
    'incidentFind',
    'displayIncident',
    'systemLog',
    'securityFirmAssociations',
    'checklist',
    'checklistTemplates',
    'checklistTemplate',
    'referenceData',
    'userRole',
    'venue',
    'securityFirm',
    'userComplianceDocument',
    'broadcastNotification',
    'facialRecognitionNotification',
    'incidentFlagTypeNotification',
    'incidentActionTemplates',
    'group',
    'groups',
    'facialRecognitionServer',
    'facialRecognitionWatchlists',
    'facialRecognitionPOI',
    'facialRecognitionFeed',
    'facialRecognitionAutomaticEnrolments',
    'notifications',
    'admin',
    'correctionalFacility',
    'directories',
    'schedules',
    'imageProcessing',
    'radioChannels',
    'subLocations',
    'functionalAreas',
    'incidentCategoryTypes',
    'notes',
    'incidentDocuments',
    'rosters',
    'externalApiConnections',
    'signOnRegisterTypes',
    'externalEvents',
    'notificationTrigger',
    'venueEvent'
  ] // will not be persisted to save storage
}

export const configureStore = () => {
  const composeEnhancers = compose;

  LocalStorage.clearStartWithExcept("persist:auscomply", "persist:" + persistConfig.key

  )

  const appReducer = combineReducers({
    user,
    notification,
    location,
    venueSecurityFirm,
    userVenueSecurityFirm,
    content,
    signOn,
    profile,
    draftIncident,
    draftIncidentOptions,
    editIncident,
    incidents,
    print,
    incidentFind,
    displayIncident,
    admin,
    registerUser,
    userRole,
    notifications,
    checklist,
    checklistTemplate,
    referenceData,
    venue,
    securityFirm,
    userComplianceDocument,
    registerVenue,
    systemLog,
    registerSecurityFirm,
    dashboard,
    adminDashboard,
    securityFirmAssociations,
    checklistTemplates,
    broadcastNotification,
    facialRecognitionNotification,
    incidentFlagTypeNotification,
    incidentActionTemplates,
    groups,
    facialRecognitionServer,
    facialRecognitionWatchlists,
    facialRecognitionPOI,
    facialRecognitionFeed,
    facialRecognitionAutomaticEnrolments,
    correctionalFacility,
    directories,
    schedules,
    imageProcessing,
    radioChannels,
    subLocations,
    functionalAreas,
    incidentCategoryTypes,
    notes,
    incidentDocuments,
    rosters,
    externalApiConnections,
    signOnRegisterTypes,
    externalEvents,
    notificationTrigger,
    venueEvent
  });

  const rootReducer = (state, action) => {
    if (action.type === 'PROFILE_RESET') {
      state = undefined
    }
    return appReducer(state, action)
  }

  const crashReporter = store => next => action => {
    try {
      return next(action)
    } catch (err) {
      commonLogic.trackException(err, "crashReporter", { action: JSON.stringify(action) });
      //state: store.getState()
      throw err
    }
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const epicMiddleware = createEpicMiddleware();

  const store = createStore(
    persistedReducer, //rootReducer,
    composeWithDevTools(
      applyMiddleware(epicMiddleware, crashReporter)
    )
  );
  let persistor = persistStore(store, undefined, () => {
    try {
      // The store has been rehydrated from local storage
      var state = store.getState();
      // check the last action date

      // Re-load the last user if found, this will put the bearerToken in a local object that the JSON service can access it
      if (state.user && state.user.details && state.user.details.userSession && state.user.bearerToken && state.user.details.userSession.user) {

        let forceLogout = false;
        if (state.user && state.user.lastActionDate) {
          let date1: Date = new Date();
          let date2 = new Date(state.user.lastActionDate);
          // get the duration as minutes
          let duration = (date1.getTime() - date2.getTime()) / 1000 / 60;
          if (duration > 120) {
            // log out due to inactivity
            forceLogout = true;
          }
        }
        if (forceLogout) {
          store.dispatch(userReset());
        } else {
          let venueId = state.user.details.userSession.user.venueId;
          let securityFirmId = state.user.details.userSession.user.securityFirmId;
          let venueEventid = state.user.details.userSession.user.venueEventId;
          store.dispatch(userLoginRequestSuccess(state.user.bearerToken, { venueid: venueId, securityfirmid: securityFirmId, venueEventId: venueEventid }, state.user.refreshToken));
        }
      } else {
        // force a logout
        store.dispatch(userReset());
      }
      store.dispatch(locationRequest());
    } catch (error) {
      //  ('persist error', error);
      store.dispatch(userReset());
    }
  });

  epicMiddleware.run(rootEpic);

  // use this for seeing changes when debugging react-native
  //store.subscribe(() => console .log('store', store.getState()))

  return { store, persistor };
};