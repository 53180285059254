import React, { Component } from 'react';
import { styles } from '../../styles';
import { checklistLogic } from '../../common/logic/checklistLogic';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import moment from "moment";
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';

export interface ICheckListRowProps {
    classes: any;
    theme: any;
    checklistSummary: AusComplyDtos.ChecklistSummary;
    onClick?: Function;
    canApprove: boolean;
    canReject: boolean;
    canClose: boolean;
    canArchive: boolean;
    onArchive?: Function;
    onUndoArchive?: Function;
    onApprove?: Function;
    onVoid?: Function;
    showEvents: boolean;
}

class CheckListRow extends React.Component<ICheckListRowProps, any> {
    constructor(props: ICheckListRowProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { classes, theme, showEvents } = this.props;
        let item = this.props.checklistSummary;

        let className = classes.cardChecklistRowClosed;
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.InProgress) {
            className = classes.cardChecklistRowInProgress;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Approved) {
            className = classes.cardChecklistRowApproved;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted) {
            className = classes.cardChecklistRowSubmitted;
        }
        if (this.props.checklistSummary.checklistStatus == AusComplyDtos.ChecklistStatuses.Rejected) {
            className = classes.cardChecklistRowRejected;
        }
        if (this.props.checklistSummary.obsolete) {
            className = classes.cardChecklistRowArchived;
        }
        let approveButton;
        let voidButton;
        let archiveButton;
        let undoArchiveButton;
        let showButtons = false;

        if (!this.props.checklistSummary.obsolete && checklistLogic.canApprove(this.props.checklistSummary.checklistStatus, this.props.canApprove)) {
            approveButton = (
                <PrimaryButton text={"Approve"} onClick={this.props.onApprove} />
            );
            showButtons = true;
        }
        if (!this.props.checklistSummary.obsolete && checklistLogic.canArchive(this.props.checklistSummary.checklistStatus, this.props.canArchive)) {
            archiveButton = (
                <DefaultButton text={"Archive"} onClick={this.props.onArchive} />
            );
            showButtons = true;
        }
        if (!this.props.checklistSummary.obsolete && checklistLogic.canClose(this.props.checklistSummary.checklistStatus, this.props.canClose)) {
            voidButton = (
                <DefaultButton text={"Void"} onClick={this.props.onVoid} />
            );
            showButtons = true;
        }
        if (this.props.checklistSummary.obsolete && checklistLogic.canUndoArchive(this.props.checklistSummary, this.props.canArchive)) {
            undoArchiveButton = (
                <DefaultButton text={"Restore"} onClick={this.props.onUndoArchive} />
            );
            showButtons = true;
        }

        return (
            <tr className={classes.tableTr} key={"row-" + item.checklistId.toString()}>
                <td className={className}  onClick={this.onClick}></td>
                <td onClick={this.onClick}>
                    <p>
                        {item.name}
                    </p>
                </td>
                {showEvents && <td onClick={this.onClick}>
                    <p>
                        {item.venueEventName}
                    </p>
                </td>}
                <td onClick={this.onClick}>
                    <p>
                        {item.enteredByUser.displayName}
                    </p>
                </td>
                <td onClick={this.onClick}>
                    <p>
                        {moment(item.dateEntered).local().format("DD/MM/YYYY HH:mm")}
                    </p>
                </td>
                <td onClick={this.onClick}>
                    <p>
                        {moment(item.dateModified).local().format("DD/MM/YYYY HH:mm")}
                    </p>
                </td>
                <td>
                    <Box p={0} display="flex" flexDirection={'row'} >
                        <Box flexGrow={1}>

                        </Box>
                        <Box flexGrow={0} p={0}>
                            {voidButton}
                        </Box>
                        <Box flexGrow={0} p={1}>
                        </Box>
                        <Box flexGrow={0} p={0}>
                            {approveButton}
                        </Box>
                        <Box flexGrow={0} p={1}>
                        </Box>
                        <Box flexGrow={0} p={0}>
                            {archiveButton}
                        </Box>
                        <Box flexGrow={0} p={0}>
                            {undoArchiveButton}
                        </Box>
                    </Box>
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListRow);