import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import TextControlGroup from '../../controls/TextControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import Saving from '../../common/Saving';
import SelectControlGroup from '../../controls/SelectControlGroup';
import DefaultButton from '../../controls/DefaultButton';

export interface IRegisterUserLicenseProps {
    user: AusComplyDTOs.User;
    errors: any[];
    states: AusComplyDTOs.State[];
    isCheckingSled: boolean;
    onUpdate: Function;
    onCheckLicense: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserLicense extends Component<IRegisterUserLicenseProps, any> {
    constructor(props: IRegisterUserLicenseProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error){
                return error.error;
            }
        }
        return "";
    }


    onChange(fieldName, value) {
        let user = { ...this.props.user };
        user[fieldName] = value;
        if (fieldName == "licenceNumber") {
            user["sledChecked"] = false;
            user["sledValid"] = false;
            user["sledStatus"] = AusComplyDTOs.ngtSLEDStatus.Unchecked;
            user["sledStatusDisplay"] = "";
        }
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"License"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <SelectControlGroup
                                        text="State Licensed"
                                        error={this.fieldError("stateLicensedId")}
                                        onChange={(value) => this.onChange("stateLicensedId", value)}
                                        defaultValue={this.props.user.stateLicensedId}>
                                        <option value={0} />
                                        {this.props.states && this.props.states.map((item, index) => (
                                            <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                        ))}
                                    </SelectControlGroup>
                                    <TextControlGroup
                                        text={this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.Staff ? "License Number" : "License Number (optional)"}
                                        error={this.fieldError("licenceNumber")}
                                        defaultValue={this.props.user.licenceNumber}
                                        onBlur={(value) => {
                                            this.onChange("licenceNumber", value);
                                        }} />

                                    {(this.props.user.stateLicensedId == 2 && this.props.user.sledEnabled) && (
                                        <>
                                            {(this.props.user.sledStatusDisplay) && (
                                                <TextControlGroup text={"Licence Status"} defaultValue={this.props.user.sledStatusDisplay} readonly={true} />
                                            )}
                                            {(this.props.isCheckingSled) && (
                                                <ControlGroup text={" "}>
                                                    <Saving message={"Checking License"} />
                                                </ControlGroup>
                                            )}
                                            {(!this.props.isCheckingSled) && (
                                                <ControlGroup text={" "}>
                                                    <DefaultButton text={"Check License"} onClick={() => this.props.onCheckLicense(this.props.user)} />
                                                </ControlGroup>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}