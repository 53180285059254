import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FunctionalAreas from '../components/functionalAreas/FunctionalAreas'
import * as functionalAreaActions from '../common/actions/functionalArea';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.functionalAreas.functionalAreas.paging,
        filter: state.functionalAreas.functionalAreas.filter,
        isLoading: state.functionalAreas.functionalAreas.isLoading,
        functionalAreas: state.functionalAreas.functionalAreas.functionalAreas,
        functionalAreaId: state.functionalAreas.functionalArea.functionalAreaId,
        canEdit: permissionsLogic.hasPermissionForState(state, "FunctionalAreasEdit")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(functionalAreaActions.functionalAreasRequest(page, pageSize, filter))
        },
        onLoadItem: (functionalAreaId) => {
            dispatch(functionalAreaActions.functionalAreaRequest(functionalAreaId))
        },
        onCreate: (forGroupId, forVenueId) => {
            dispatch(functionalAreaActions.functionalAreaCreateRequest(forGroupId, forVenueId))
        },
        onResetItem: () => {
            dispatch(functionalAreaActions.functionalAreaReset())
        },
        onUpdate: (functionalArea) => {
            dispatch(functionalAreaActions.functionalAreaUpdate(functionalArea))
        },
        onSave: () => {
            dispatch(functionalAreaActions.functionalAreaSaveRequest())
        }
    }
}

const VenueFunctionalAreasContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionalAreas))

export default VenueFunctionalAreasContainer