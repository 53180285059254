// This component is a wrapper so that UI components can be easily shared (copied) between react and react natve
import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

export interface ITouchableOpacity {
    style?: any;
    onPress: Function;
}

export default class TouchableOpacity extends React.PureComponent<ITouchableOpacity, any> {
    render() {
        return (
            <ButtonBase style={{ ...this.props.style }} onClick={() => this.props.onPress()}>
                {this.props.children}
            </ButtonBase>
        );
    }
}