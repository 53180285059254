import React, { Component } from 'react';
import { styles } from '../../styles';
import { Palette } from '../../common/constants/palette';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import View from './View';
import MoreIcon from '../../resources/MoreIcon';
import VerticalMoreIcon from '../../resources/VerticalMoreIcon';

export interface IVerticalCollapsedSpacerProps {
    theme: any;
    style?: any;
}

class VerticalCollapsedSpacer extends React.PureComponent<IVerticalCollapsedSpacerProps, any> {
    constructor(props: IVerticalCollapsedSpacerProps) {
        super(props)
    }
    render() {
        const { theme } = this.props;
        return (
            <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}>
                    
                </Box>
                <Box flexGrow={0} p={0}>
                    <View style={{height: '40px'}}>
                        <VerticalMoreIcon fill={Palette.Boundary} />
                    </View>
                </Box>
                <Box flexGrow={1} p={0}>
                    
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(VerticalCollapsedSpacer);