import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import RadioControlGroup from '../../controls/RadioControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import MultiSelectControl from '../../controls/MutiSelectControl';
import FullPageDialog from '../../layout/FullPageDialog';
import PrimaryButton from '../../controls/PrimaryButton';

export interface ICheckListTemplatesFilterProps {
    open: boolean;
    filter: AusComplyDTOs.ChecklistTemplateFilter;
    checklistTemplateCategories: AusComplyDTOs.ChecklistTemplateCategory[];
    industryCategories: AusComplyDTOs.IndustryCategory[];
    venues: AusComplyDTOs.NamedValue[];
    securityFirms: AusComplyDTOs.NamedValue[];
    states: AusComplyDTOs.State[];
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
    onClose: Function;
}

interface ICheckListTemplatesFilterState {
    filter: AusComplyDTOs.ChecklistTemplateFilter;
    categories: MulitSelectOption[];
    industries: MulitSelectOption[];
    venues: MulitSelectOption[];
    securityFirms: MulitSelectOption[];
    states: MulitSelectOption[];
}

export default class CheckListTemplatesFilter extends React.Component<ICheckListTemplatesFilterProps, ICheckListTemplatesFilterState> {
    constructor(props: ICheckListTemplatesFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeAndSubmit = this.onChangeAndSubmit.bind(this);
        this.onCategoriesChanged = this.onCategoriesChanged.bind(this);
        this.getIndustryOptions = this.getIndustryOptions.bind(this);
        this.getVenueOptions = this.getVenueOptions.bind(this);
        this.getSecurityFirmOptions = this.getSecurityFirmOptions.bind(this);
        this.getStateOptions = this.getStateOptions.bind(this);
        this.onIndustriesChanged = this.onIndustriesChanged.bind(this);
        this.onVenuesChanged = this.onVenuesChanged.bind(this);
        this.onSecurityFirmsChanged = this.onSecurityFirmsChanged.bind(this);
        this.onStatesChanged = this.onStatesChanged.bind(this);
        this.removedChanged = this.removedChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.ChecklistTemplateFilter(),
            categories: [],
            industries: [],
            venues: [],
            securityFirms: [],
            states: []
        };
    }

    componentDidMount() {
        let categories = this.getCategoriesOptions(this.props.filter);
        let industries = this.getIndustryOptions(this.props.filter);
        let venues = this.getVenueOptions(this.props.filter);
        let securityFirms = this.getSecurityFirmOptions(this.props.filter);
        let states = this.getStateOptions(this.props.filter);
        this.setState({
            filter: this.props.filter,
            categories,
            industries,
            venues,
            securityFirms,
            states
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter) ||
            JSON.stringify(prevProps.checklistTemplateCategories) != JSON.stringify(this.props.checklistTemplateCategories) ||
            JSON.stringify(prevProps.industryCategories) != JSON.stringify(this.props.industryCategories) ||
            JSON.stringify(prevProps.venues) != JSON.stringify(this.props.venues) ||
            JSON.stringify(prevProps.securityFirms) != JSON.stringify(this.props.securityFirms) ||
            JSON.stringify(prevProps.states) != JSON.stringify(this.props.states)
        ) {
            let categories = this.getCategoriesOptions(this.props.filter);
            let industries = this.getIndustryOptions(this.props.filter);
            let venues = this.getVenueOptions(this.props.filter);
            let securityFirms = this.getSecurityFirmOptions(this.props.filter);
            let states = this.getStateOptions(this.props.filter);
            this.setState({
                filter: this.props.filter,
                categories,
                industries,
                venues,
                securityFirms,
                states
            });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onSearchChange(value) {
        let filter = { ... this.state.filter };
        filter.search = value;
        this.setState({ filter }, () => {
            //this.props.onUpdateFilter(this.state.filter);
        });
    }

    onSearch() {
        this.props.onUpdateFilter(this.state.filter);
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            //this.props.onUpdateFilter(this.state.filter);
        });
    }

    onChangeAndSubmit(fieldName, value) {
        let self = this;
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            self.onSearch();
        });
    }

    getCategoriesOptions(filter: AusComplyDTOs.ChecklistTemplateFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.checklistTemplateCategories && filter.checklistTemplateCategoryIds) {
                this.props.checklistTemplateCategories.forEach((item, index) => {
                    let isSelected = filter.checklistTemplateCategoryIds.indexOf(item.checklistTemplateCategoryId) > -1;
                    items.push({
                        id: item.checklistTemplateCategoryId,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    getIndustryOptions(filter: AusComplyDTOs.ChecklistTemplateFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.industryCategories && filter.industryCategoryIds) {
                this.props.industryCategories.forEach((item, index) => {
                    let isSelected = filter.industryCategoryIds.indexOf(item.industryCategoryId) > -1;
                    items.push({
                        id: item.industryCategoryId,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    getVenueOptions(filter: AusComplyDTOs.ChecklistTemplateFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.venues && filter.venueIds && Array.isArray(this.props.venues)) {
                this.props.venues.forEach((item, index) => {
                    let isSelected = filter.venueIds.indexOf(item.id) > -1;
                    items.push({
                        id: item.id,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    getSecurityFirmOptions(filter: AusComplyDTOs.ChecklistTemplateFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.securityFirms && filter.securityFirmIds) {
                this.props.securityFirms.forEach((item, index) => {
                    let isSelected = filter.securityFirmIds.indexOf(item.id) > -1;
                    items.push({
                        id: item.id,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    getStateOptions(filter: AusComplyDTOs.ChecklistTemplateFilter): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.states && filter.stateIds) {
                this.props.states.forEach((item, index) => {
                    let isSelected = filter.stateIds.indexOf(item.stateId) > -1;
                    items.push({
                        id: item.stateId,
                        name: item.displayName,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    onCategoriesChanged(items) {
        let filter = { ...this.state.filter };
        let categories = [...this.state.categories];
        filter.checklistTemplateCategoryIds = items.map(item => item.id);
        categories.forEach(category => {
            category.selected = filter.checklistTemplateCategoryIds.indexOf(category.id) !== -1;
        });
        this.setState({ filter, categories }, () => {
            //this.props.onUpdateFilter(this.state.filter);
        });
    }

    onIndustriesChanged(items) {
        let filter = { ...this.state.filter };
        let industries = [...this.state.industries];
        filter.industryCategoryIds = items.map(item => item.id);
        industries.forEach(p => {
            p.selected = filter.industryCategoryIds.indexOf(p.id) !== -1;
        });
        this.setState({ filter, industries }, () => {
        });
    }

    onVenuesChanged(items) {
        let filter = { ...this.state.filter };
        let venues = [...this.state.venues];
        filter.venueIds = items.map(item => item.id);
        if (venues) {
            venues.forEach(p => {
                p.selected = filter.venueIds.indexOf(p.id) !== -1;
            });
        }
        this.setState({ filter, venues }, () => {
        });
    }

    onSecurityFirmsChanged(items) {
        let filter = { ...this.state.filter };
        let securityFirms = [...this.state.securityFirms];
        filter.securityFirmIds = items.map(item => item.id);
        securityFirms.forEach(p => {
            p.selected = filter.securityFirmIds.indexOf(p.id) !== -1;
        });
        this.setState({ filter, securityFirms }, () => {
        });
    }

    onStatesChanged(items) {
        let filter = { ...this.state.filter };
        let states = [...this.state.states];
        filter.stateIds = items.map(item => item.id);
        states.forEach(p => {
            p.selected = filter.stateIds.indexOf(p.id) !== -1;
        });
        this.setState({ filter, states }, () => {
        });
    }

    removedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter }, () => {
            // this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let viewOptions;
        if (this.state.filter && this.state.filter.viewOptions) {
            viewOptions = this.state.filter.viewOptions.map((item, index)=> {
                var option = new RadioOption();
                option.id =  item.id.toString();
                option.name = item.name;
                return option;
            });
        }

        return <FullPageDialog
            open={this.props.open}
            footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.onSearch()} />}
            footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
            notFullWidth={true}
            title="Search" onDismissed={() => this.props.onClose()}>
            <LayoutForm>
                {(viewOptions) && (
                    <RadioControlGroup 
                        text="Show"
                        name="viewOptions"
                        labelAbove={true}
                        selected={this.state.filter.viewOption.toString()}
                        options={viewOptions}
                        onChanged={value => this.onChange('viewOption', Number(value.value))}
                    />
                )}

                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChangeAndSubmit('search', value);
                    }}
                    onChange={value => this.onChange('search', value)} />

                <CheckboxControlGroup text="Show Removed" defaultValue={this.state.filter.showObsolete} onChanged={this.removedChanged} />

                <MultiSelectControl
                    label={"Template Categories"}
                    items={this.state.categories}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onCategoriesChanged}
                    canShowAll={true} />
                <MultiSelectControl
                    label={"Industry Categories"}
                    items={this.state.industries}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onIndustriesChanged}
                    canShowAll={true} />
                <MultiSelectControl
                    label={"Venues"}
                    items={this.state.venues}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onVenuesChanged}
                    canShowAll={true} />
                <MultiSelectControl
                    label={"Security Firms"}
                    items={this.state.securityFirms}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onSecurityFirmsChanged}
                    canShowAll={true} />
                <MultiSelectControl
                    label={"States"}
                    items={this.state.states}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onStatesChanged}
                    canShowAll={true} />
            </LayoutForm>
        </FullPageDialog>
    }
}