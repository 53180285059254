import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { venueLocationLogic } from '../../../common/logic/venueLocationLogic';

export interface ICellRowProps {
    venueLocation: AusComplyDtos.VenueLocation;
    showCameras?: boolean;
    onCommand?: Function;
}

export default class CellRow extends React.Component<ICellRowProps, any> {
    constructor(props: ICellRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueLocation);
        }
    }

    render() {
        if (!this.props.venueLocation) {
            return (<></>);
        }

        var commands = venueLocationLogic.getCommands(this.props.venueLocation);
        var item = this.props.venueLocation;

        return (
            <TableRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                {this.props.showCameras && (
                    <td>
                        <p>
                            {item.facialRecognitionCameraSummary}
                        </p>
                    </td>
                )}
                <td>
                    <p>
                        {item.code}
                    </p>
                </td>
                <td>
                    <p>
                        {item.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}