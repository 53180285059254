import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SubTitle from '../../common/SubTitle';
import List from '../../common/List';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import IncidentFlagTypeNotificationCard from './IncidentFlagTypeNotificationCard';
import IncidentFlagTypeNotificationHeader from './IncidentFlagTypeNotificationHeader';
import IncidentFlagTypeNotificationRow from './IncidentFlagTypeNotificationRow';
import IncidentFlagTypeNotificationContainer from '../../../containers/IncidentFlagTypeNotificationContainer';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import IncidentFlagTypeSummary from '../incidentFlagType/IncidentFlagTypeSummary';
import NotificationTriggerEditContainer from '../../../containers/NotificationTriggerEditContainer';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IIncidentFlagTypeNotificationsProps {
    venueId: number;
    currentVenueId?: number;
    isLoading: boolean;
    incidentFlagTypeNotifications: AusComplyDtos.IncidentFlagTypeNotification[];
    incidentFlagTypeId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentFlagTypeFilter;
    notificationTrigger: AusComplyDtos.NotificationTrigger;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onSaveNotification: Function;
}

interface IIncidentFlagTypeNotificationsState {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    incidentFlagTypeId: number;
}

export default class IncidentFlagTypeNotifications extends Component<IIncidentFlagTypeNotificationsProps, IIncidentFlagTypeNotificationsState> {
    constructor(props: IIncidentFlagTypeNotificationsProps) {
        super(props)

        this.state = {
            incidentFlagTypeId: -1,
            incidentFlagType: new AusComplyDtos.IncidentFlagType()
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onReload = this.onReload.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.IncidentFlagTypeFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    onReload() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, incidentFlagTypeNotification: AusComplyDtos.IncidentFlagTypeNotification) {
        switch (command) {
            case "edit":
                this.setState({
                    incidentFlagType: incidentFlagTypeNotification.incidentFlagType,
                    incidentFlagTypeId: incidentFlagTypeNotification.incidentFlagType.incidentFlagTypeId
                })
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;
        let self = this;

        if (this.props.incidentFlagTypeNotifications && this.props.incidentFlagTypeNotifications.length > 0) {
            cardList = this.props.incidentFlagTypeNotifications.map((item, index) => {
                return (
                    <IncidentFlagTypeNotificationCard
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"card-" + item.incidentFlagTypeId.toString()}
                        incidentFlagTypeNotification={item}
                    />
                );
            });
            rows = this.props.incidentFlagTypeNotifications.map((item, index) => {
                return (
                    <IncidentFlagTypeNotificationRow
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"row-" + item.incidentFlagTypeId.toString()}
                        incidentFlagTypeNotification={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<IncidentFlagTypeNotificationHeader />}
                    rows={rows}
                />
            );
        }

        let incidentFlagTypeNotification;
        if (this.props.incidentFlagTypeId != -1) {
            incidentFlagTypeNotification = (
                <IncidentFlagTypeNotificationContainer />
            );
        }

        return (<PageLayout
            headerText={"Incident Flags"}
        >
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <FullPageDialog
                open={this.state.incidentFlagTypeId != -1}
                notFullWidth={true}
                title="Incident Flag" 
                footerRightContent={<PrimaryButton text='Save' onClick={() => this.props.onSaveNotification(this.props.notificationTrigger)} />}
                onDismissed={() => this.setState({incidentFlagTypeId: -1})}>
                    <IncidentFlagTypeSummary incidentFlagType={this.state.incidentFlagType}/>
                    <NotificationTriggerEditContainer
                        notificationTriggerParentType={AusComplyDtos.ngtNotificationTriggerParentType.IncidentFlagType}
                        parentId={this.state.incidentFlagTypeId}
                        venueId={this.props.currentVenueId}
                        onClose={() => this.setState({incidentFlagTypeId: -1}, () => self.onReload())}
                     />
            </FullPageDialog>
        </PageLayout>);
    }
}