import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewTypeProps {
    incident: IncidentDTO;
}

export default class IncidentViewType extends Component<IIncidentViewTypeProps, any> {
    render() {
        return (
            <ControlGroup text="Type">
                <TextDisplay>{this.props.incident.incidentType}</TextDisplay>
            </ControlGroup>
        );
    }
}