import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { groupLogic } from '../../../common/logic/groupLogic';
import { incidentActionTemplateLogic } from '../../../common/logic/incidentActionTemplateLogic';
import { checklistTemplatesLogic } from '../../../common/logic/checklistTemplatesLogic';
import { withRouter } from "react-router";
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import LayoutForm from '../../layout/LayoutForm';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import ResponsiveList from '../../common/ResponsiveList';
import View from '../../common/View';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import ConfirmDialog from '../../controls/ConfirmDialog';
import LocalDateControlGroup from '../../controls/LocalDateControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption as MultiSelectOption } from '../../../common/dto/common';
import FullPageDialog from '../../layout/FullPageDialog';
import Tabs from '../../common/Tabs';
import SubTitle from '../../common/SubTitle';
import Box from '@material-ui/core/Box';
import ControlGroup from '../../controls/ControlGroup';
import FacialRecognitionWatchlistsContainer from '../../../containers/FacialRecognitionWatchlistsContainer';
import IncidentActionTemplatePreview from '../../incidentActions/incidentActionTemplates/IncidentActionTemplatePreview';
import ChecklistTemplatePreview from '../../checklists/checklistTemplates/ChecklistTemplatePreview';
import GroupDashboardContainer from '../../../containers/GroupDashboardContainer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Notification from '../../notifications/Notification';
import IndustryCategoryMultiSelect from '../../admin/industryCategories/IndustryCategoryMultiSelect';
import FacialRecognitionEventsContainer from '../../../containers/FacialRecognitionEventsContainer';
import GroupUsersContainer from '../../../containers/GroupUsersContainer';
import GroupDirectoryContainer from '../../../containers/GroupDirectoryContainer';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IGroupProps {
    groupId: number;
    isAdmin: boolean;
    group: AusComplyDtos.Group,
    timeZones: AusComplyDtos.TimeZone[],
    isLoading: boolean,
    isSaving: boolean,
    onLoad: Function;
    onCreate: Function;
    onUpdate: Function;
    onSave: Function;
    onReset: Function;
    venues: AusComplyDtos.VenueSelection[],
    venueAutoApproval: boolean;
    isLoadingVenues: boolean,
    isSavingVenues: boolean,
    canAddVenues: boolean,
    canAddIndustries: boolean,
    users: AusComplyDtos.UserSelection[],
    userAutoApproval: boolean;
    isTemporary: boolean;
    expiryDate: AusComplyDtos.LocalDate;
    userRoleTypeId: number;
    userRoleTypes: AusComplyDtos.UserRoleType[],
    userRoleStatuses: AusComplyDtos.UserRoleStatus[],
    isLoadingUsers: boolean,
    isSavingUsers: boolean,
    canEditFeatures: boolean,
    canAddUsers: boolean,
    canEdit: boolean,
    canViewDashboard: boolean,
    canAddChecklistTemplates: boolean,
    canAddIncidentActions: boolean,
    canAddWatchlists: boolean,
    canAddEvents: boolean,
    canAddDirectory: boolean,
    onLoadVenues: Function;
    onSetVenues: Function;
    onSetVenueAutoApproval: Function;
    onSaveVenues: Function;
    onLoadUsers: Function;
    onSetUsers: Function;
    onSetUserAutoApproval: Function;
    onSaveUsers: Function;
    onSetUserRoleType: Function;
    onSetTemporary: Function;
    tabIndex: number;
    onSetTab: Function;
    onLoadItem: Function;
    onArchiveTemplate: Function;
    onSetIncidentActionTemplate: Function;
    onUpdateIncidentActionTemplate: Function;
    incidentActionTemplate: AusComplyDtos.IncidentActionTemplate;
    isLoadingItem: boolean;
    isSavingIncidentActionTemplate: boolean,
    isSavingChecklistTemplate: boolean,
    onUpdateUser: Function;
    onUpdateVenue: Function;
    onRemoveGroupRequest: Function;
    isCloningChecklistTemplate: boolean;
    checklistTemplateId: number;
    checklistTemplate: AusComplyDtos.ChecklistTemplate,
    isLoadingChecklistTemplate: boolean,
    onLoadChecklistTemplate: Function,
    onCloneChecklistTemplate: Function;
    onCloneIncidentAction: Function;
    history: any;
    location: any;
}

interface IGroupState {
    venues: MultiSelectOption[];
    users: MultiSelectOption[];
    groupUser?: AusComplyDtos.GroupUser;
    removeGroupUser?: AusComplyDtos.GroupUser;
    restoreGroupUser?: AusComplyDtos.GroupUser;
    previewItemId: number,
    previewChecklistTemplateId: number,
    confirmRemoveIncidentActionTemplate: boolean,
    confirmUndoRemoveIncidentActionTemplate: boolean,
}

class Group extends React.Component<IGroupProps, IGroupState> {

    constructor(props: IGroupProps) {
        super(props)
        this.state = {
            venues: [],
            users: [],
            previewItemId: 0,
            previewChecklistTemplateId: 0,
            confirmRemoveIncidentActionTemplate: false,
            confirmUndoRemoveIncidentActionTemplate: false,
            groupUser: undefined,
            removeGroupUser: undefined,
            restoreGroupUser: undefined
        }
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.tabs = this.tabs.bind(this);
        this.selectedTabDisplay = this.selectedTabDisplay.bind(this);
        this.onVenuesChanged = this.onVenuesChanged.bind(this);
        this.setVenues = this.setVenues.bind(this);
        this.onGroupVenueCommand = this.onGroupVenueCommand.bind(this);
        this.onUsersChanged = this.onUsersChanged.bind(this);
        this.setUsers = this.setUsers.bind(this);
        this.setGroupUser = this.setGroupUser.bind(this);
        this.setRemoveGroupUser = this.setRemoveGroupUser.bind(this);
        this.setRestoreGroupUser = this.setRestoreGroupUser.bind(this);
        this.onGroupUsersCommand = this.onGroupUsersCommand.bind(this);
        this.onIncidentActionTemplateCommand = this.onIncidentActionTemplateCommand.bind(this);
        this.onChecklistTemplateCommand = this.onChecklistTemplateCommand.bind(this);
        this.onPreviewItem = this.onPreviewItem.bind(this);
        this.removeCancelIncidentActionTemplate = this.removeCancelIncidentActionTemplate.bind(this);
        this.removeConfirmedIncidentActionTemplate = this.removeConfirmedIncidentActionTemplate.bind(this);
        this.removeUndoConfirmedIncidentActionTemplate = this.removeUndoConfirmedIncidentActionTemplate.bind(this);
        this.canSaveGroupUser = this.canSaveGroupUser.bind(this);
        this.onPreviewChecklistTemplate = this.onPreviewChecklistTemplate.bind(this);
    }

    componentDidMount() {
        this.onEnsureLoaded();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isCloningChecklistTemplate && !this.props.isCloningChecklistTemplate) {
            if (this.props.checklistTemplateId > 0) {
                this.props.onLoad(this.props.groupId);
                var urlChecklistTemplate = this.props.location.pathname + "/checklistTemplate/" + this.props.checklistTemplateId;
                this.props.history.push(urlChecklistTemplate);
                return;
            }
        }
        if (!this.props.isSavingIncidentActionTemplate && prevProps.isSavingIncidentActionTemplate) {
            this.props.onLoad(this.props.groupId);
        } else if (!this.props.isSaving && prevProps.isSaving &&
            this.props.groupId != this.props.group.groupId
            && this.props.group.groupId > 0) {
            // reload correctly
            this.props.onReset();
            var path = this.props.location.pathname.split('/');
            var updatedPath = path.pop();
            path.push(this.props.group.groupId);
            var url = path.join('/');
            this.props.history.push(url);
        } else if ((prevProps.isLoadingVenues && !this.props.isLoadingVenues) || (prevProps.isSavingVenues && !this.props.isSavingVenues)) {
            let venues: MultiSelectOption[] = [];
            try {
                if (this.props.venues && Array.isArray(this.props.venues)) {
                    this.props.venues.forEach((item, index) => {
                        venues.push({
                            id: item.venueId,
                            name: item.name,
                            isCommon: false,
                            selected: item.selected
                        });
                    });
                }
            } catch {

            }
            this.setState({ venues });
        } else if ((prevProps.isLoadingUsers && !this.props.isLoadingUsers) || (prevProps.isSavingUsers && !this.props.isSavingUsers)) {
            let users: MultiSelectOption[] = [];
            try {
                if (this.props.users) {
                    this.props.users.forEach((item, index) => {
                        users.push({
                            id: item.userId,
                            name: item.name,
                            isCommon: false,
                            selected: item.selected
                        });
                    });
                }
            } catch {

            }
            this.setState({ users });
        } else {
            this.onEnsureLoaded();
        }
    }

    removeCancelIncidentActionTemplate() {
        this.setState({
            confirmRemoveIncidentActionTemplate: false,
            confirmUndoRemoveIncidentActionTemplate: false
        });
    }

    removeConfirmedIncidentActionTemplate() {
        if (this.props.onUpdateIncidentActionTemplate) {
            this.setState({
                confirmRemoveIncidentActionTemplate: false
            }, () => {
                this.props.onUpdateIncidentActionTemplate();
            });
        }
    }

    removeUndoConfirmedIncidentActionTemplate() {
        if (this.props.onUpdateIncidentActionTemplate) {
            this.setState({
                confirmUndoRemoveIncidentActionTemplate: false
            }, () => {
                this.props.onUpdateIncidentActionTemplate();
            });
        }
    }

    onEnsureLoaded() {
        if (!this.props.isLoading && !this.props.isSaving) {
            let id = "-1";
            let groupId = "";
            if (this.props.group && this.props.group.groupId !== undefined) {
                if (this.props.group.groupId == this.props.groupId) {
                    // this module is shared between venues and groups
                    id = this.props.group.groupId.toString();
                }
            }
            if (this.props.groupId != undefined && parseInt(this.props.groupId.toString()) > -1) {
                groupId = this.props.groupId.toString();
            }
            if (id !== groupId) {
                if (this.props.isAdmin && this.props.groupId.toString() === "0") {
                    this.props.onCreate();
                } else {
                    this.props.onLoad(this.props.groupId);
                }
                this.props.onSetTab(0);
            }
        }
    }

    handleTabChange = (event, value) => {
        this.props.onSetTab(value);
    };

    onUpdate(group: AusComplyDtos.Group) {
        // this.props.onUpdate(group);
    }

    onPreviewItem(previewItemId: number) {
        if (previewItemId > 0) {
            this.props.onLoadItem(previewItemId);
        }
        this.setState({ previewItemId });
    }

    onPreviewChecklistTemplate(checklistTemplateId: number) {
        if (checklistTemplateId > 0) {
            this.props.onLoadChecklistTemplate(checklistTemplateId);
        }
        this.setState({ previewChecklistTemplateId: checklistTemplateId });
    }

    onClose() {
        this.props.onReset();
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        var url = path.join('/');
        this.props.history.push(url);
    }

    onSave() {
        this.props.onSave();
    }

    onValueChanged(fieldName: string, value: any) {
        let group = { ...this.props.group };
        group[fieldName] = value;
        this.props.onUpdate(group);
        if (fieldName === "hideDefaultFolders") {
            this.props.onSave();
        }
    }

    onVenuesChanged(items) {
        let venues = [...this.state.venues];
        let venueIds = items.map(item => item.id);
        if (venues) {
            venues.forEach(p => {
                p.selected = venueIds.indexOf(p.id) !== -1;
            });
        }
        this.setState({ venues }, () => {
            var updated = [...this.props.venues];
            updated.forEach(v => {
                v.selected = venueIds.indexOf(v.venueId) !== -1;
            });
            this.props.onSetVenues(updated);
        });
    }

    tabs() {
        let tabs: string[] = [];
        // the tabs are dymanic based on permissions
        if (this.props.group && this.props.group.groupId > 0) {

            if (this.props.isAdmin && this.props.canEdit) {
                tabs.push('Details');
            }
            if (this.props.group.featureDashboard && this.props.canViewDashboard) {
                tabs.push('Dashboard');
            }
            if (this.props.group.featureIndustry && this.props.canAddIndustries) {
                tabs.push('Industries');
            }
            if (this.props.group.featureVenue && this.props.canAddVenues) {
                tabs.push('Venues');
            }
            if (this.props.group.featureUserRoles && this.props.canAddUsers) {
                tabs.push('Managers');
            }
            if (this.props.group.featureUsers && this.props.canAddUsers) {
                tabs.push('Users');
            }
            if (this.props.group.featureChecklist && this.props.canAddChecklistTemplates) {
                tabs.push('Checklist Templates');
            }
            if (this.props.group.featureIncidentAction && this.props.canAddIncidentActions) {
                tabs.push('Incident Actions');
            }
            if (this.props.group.featureWatchlist && this.props.canAddWatchlists) {
                tabs.push('Watchlists');
            }
            if (this.props.group.featureWatchlist && this.props.canAddEvents) {
                tabs.push('Events');
            }
            if (this.props.group.featureDirectory && this.props.canAddDirectory) {
                tabs.push('Document Folders');
            }
        } else {

        }
        return tabs;
    }

    selectedTabDisplay(tabs: string[], display: string) {
        if (tabs.length == 0 && display == "Details") return true;
        return tabs[this.props.tabIndex] == display;
    }

    setVenues(venues) {
        if (venues.length == 0) {
            this.setState({ venues: [] });
        }
        this.props.onSetVenues(venues);
    }

    onUsersChanged(items) {
        let users = [...this.state.users];
        let userIds = items.map(item => item.id);
        if (users) {
            users.forEach(p => {
                p.selected = userIds.indexOf(p.id) !== -1;
            });
        }
        this.setState({ users }, () => {
            var updated = [...this.props.users];
            updated.forEach(u => {
                u.selected = userIds.indexOf(u.userId) !== -1;
            });
            this.props.onSetUsers(updated);
        });
    }

    onGroupVenueCommand(command: string, groupVenue: AusComplyDtos.GroupVenue) {
        switch (command) {
            case "delete":
                var updated = { ...groupVenue };
                updated.obsolete = true;
                this.props.onUpdateVenue(this.props.groupId, updated);
                break;
            case "restore":
                var updated = { ...groupVenue };
                updated.obsolete = false;
                this.props.onUpdateVenue(this.props.groupId, updated);
                break;
            default:
                break;
        }
    }

    setUsers(users) {
        if (users.length == 0) {
            this.setState({ users: [] });
        }
        this.props.onSetUsers(users);
    }

    setGroupUser(groupUser, save: boolean) {
        // console.log('setGroupUser', groupUser);
        if (save) {
            this.props.onUpdateUser(this.props.groupId, { ...groupUser });
            groupUser = undefined;
        }
        this.setState({ groupUser });
    }

    canSaveGroupUser() {
        if (!this.state.groupUser) return false;
        if (!this.state.groupUser.userRoleStatusId || this.state.groupUser.userRoleStatusId < 1) return false;
        if (!this.state.groupUser.userRoleTypeId || this.state.groupUser.userRoleTypeId < 1) return false;
        return true;
    }

    setRemoveGroupUser(removeGroupUser, save: boolean) {
        if (save) {
            this.props.onUpdateUser(this.props.groupId, { ...removeGroupUser });
            removeGroupUser = undefined;
        }
        this.setState({ removeGroupUser });
    }

    setRestoreGroupUser(restoreGroupUser, save: boolean) {
        if (save) {
            this.props.onUpdateUser(this.props.groupId, { ...restoreGroupUser });
            restoreGroupUser = undefined;
        }
        this.setState({ restoreGroupUser });
    }

    onGroupUsersCommand(command: string, groupUser: AusComplyDtos.GroupUser) {
        switch (command) {
            case "edit":
                this.setState({ groupUser: { ...groupUser } });
                break;
            case "delete":
                this.setState({ removeGroupUser: { ...groupUser } });
                //var updated = { ...groupUser };
                //updated.obsolete = true;
                //this.props.onUpdateUser(this.props.groupId, updated);
                break;
            case "restore":
                this.setState({ restoreGroupUser: { ...groupUser } });
                //var updated = { ...groupUser };
                //updated.obsolete = false;
                //this.props.onUpdateUser(this.props.groupId, updated);
                break;
            default:
                break;
        }
    }

    onIncidentActionTemplateCommand(command: string, incidentActionTemplate: AusComplyDtos.IncidentActionTemplate) {
        switch (command) {
            case "add":
                var url = this.props.location.pathname + "/incidentActionTemplate/0";
                this.props.history.push(url);
                break;
            case "edit":
                var url = this.props.location.pathname + "/incidentActionTemplate/" + incidentActionTemplate.incidentActionTemplateId;
                this.props.history.push(url);
                break;
            case "delete":
                var updated = { ...incidentActionTemplate };
                updated.obsolete = true;
                this.props.onSetIncidentActionTemplate(updated);
                this.setState({
                    confirmRemoveIncidentActionTemplate: true
                });
                break;
            case "undodelete":
                var updated = { ...incidentActionTemplate };
                updated.obsolete = false;
                this.props.onSetIncidentActionTemplate(updated);
                this.setState({
                    confirmUndoRemoveIncidentActionTemplate: true
                });
                break;
            case "preview":
                this.onPreviewItem(incidentActionTemplate.incidentActionTemplateId);
                break;
            case "copy":
                if (this.props.onCloneIncidentAction) {
                    this.props.onCloneIncidentAction(incidentActionTemplate.incidentActionTemplateId, this.props.groupId, null);
                }
                break;
            default:
                break;
        }
    }

    onChecklistTemplateCommand(command: string, checklistTemplate: AusComplyDtos.ChecklistTemplateSummary) {
        switch (command) {
            case "add":
                var url = this.props.location.pathname + "/checklistTemplate/0";
                this.props.history.push(url);
                break;
            case "edit":
                var url = this.props.location.pathname + "/checklistTemplate/" + checklistTemplate.checklistTemplateId;
                this.props.history.push(url);
                break;
            case "remove":
                this.props.onArchiveTemplate(checklistTemplate.checklistTemplateId, this.props.isAdmin, false);
                break;
            case "restore":
                this.props.onArchiveTemplate(checklistTemplate.checklistTemplateId, this.props.isAdmin, true);
                break;
            case "preview":
                this.onPreviewChecklistTemplate(checklistTemplate.checklistTemplateId);
                break;
            case "clone":
                this.props.onCloneChecklistTemplate(this.props.groupId, checklistTemplate.checklistTemplateId);
                break
            default:
                break;
        }
    }

    render() {
        const tabs = this.tabs();
        let self = this;
        const showAddVenueModal = this.selectedTabDisplay(tabs, "Venues") && (this.props.isLoadingVenues ||
            this.props.isSavingVenues || (this.state.venues && this.state.venues.length > 0));

        const showAddUserModal = this.selectedTabDisplay(tabs, "Managers") && (this.props.isLoadingUsers ||
            this.props.isSavingUsers || (this.state.users && this.state.users.length > 0));

        let leftButton;
        let centerButton;
        let rightButton;

        let venues;
        let venueRequests;
        if (this.selectedTabDisplay(tabs, "Venues")) {
            venues = <ResponsiveList
                loading={this.props.isLoading || this.props.isSaving}
                data={this.props.group.groupVenues}
                headers={["Name", "Status"]}
                columns={["venue.name", "groupVenueStatus.name"]}
                getCommands={item => groupLogic.getGroupVenueCommands(item, this.props.canAddVenues)}
                onCommand={this.onGroupVenueCommand}
                isDanger={item => item.groupVenueStatusId == 3 || item.groupVenueStatusId == 4 || item.obsolete}
                canEdit={this.props.canAddVenues}
                toggleDanger={true}
            />

            if (this.props.group.venueNotifications && this.props.group.venueNotifications.length > 0) {
                venueRequests = this.props.group.venueNotifications.map((item, index) =>
                    <Notification
                        key={"venue-notification" + item.notificationId.toString()}
                        notification={item}
                        onDismiss={notification => self.props.onRemoveGroupRequest(notification.notificationId, notification.groupId)}
                        canAccept={false}
                        canDismiss={true} />
                );
            }
        }

        let users;
        let userRequests;
        if (this.selectedTabDisplay(tabs, "Managers")) {
            users = <ResponsiveList
                loading={this.props.isLoading || this.props.isSaving}
                data={this.props.group.managers}
                headers={["Name", "Role", "Status", "Temporary"]}
                columns={["displayName", "userRoleType", "userRoleStatus", "expiryDisplay"]}
                getCommands={item => groupLogic.getGroupUserCommands(item, this.props.canAddUsers)}
                onCommand={this.onGroupUsersCommand}
                isDanger={item => item.userRoleStatusId == 1 || item.userRoleStatusId == 3 || item.userRoleStatusId == 4 || item.obsolete}
                canEdit={this.props.canAddUsers}
                toggleDanger={true}
            />;

            if (this.props.group.accessRequests.length > 0) {

                userRequests = this.props.group.accessRequests.map((groupUser, index) =>
                    <Box
                        key={"notification-" + groupUser.notificationId.toString()}
                        display="flex" flexDirection="row" justifyContent="center" style={{ borderRadius: '5px', backgroundColor: '#061570', width: '100%', marginBottom: '10px' }}>
                        <Box p={2}>

                            {groupUser.notification.notificationCategoryId == 1 && (
                                <PermIdentityIcon style={{ color: '#888' }} />
                            )}
                            {groupUser.notification.notificationCategoryId == 2 && (
                                <BusinessIcon style={{ color: '#888' }} />
                            )}
                            {groupUser.notification.notificationCategoryId == 3 && (
                                <BusinessIcon style={{ color: '#888' }} />
                            )}
                            {groupUser.notification.notificationCategoryId == 4 && (
                                <AssignmentIcon style={{ color: '#888' }} />
                            )}
                        </Box>
                        <Box p={2} flexGrow={1}>
                            <Box display="flex" flexDirection="column">
                                <Box p={0}>
                                    <p style={{ margin: '2px 0', color: 'white', whiteSpace: 'pre-line' }}>
                                        <span style={{ color: '#888', marginRight: '10px' }}>{groupUser.notification.dateTimeFormatted}</span>
                                        {groupUser.notification.message}
                                    </p>
                                </Box>
                                <Box p={0}>
                                    <Box display="flex" flexDirection="row">
                                        <Box flexGrow={1} p={1}><DefaultButton text={"Accept"} onClick={() => { this.setState({ groupUser: { ...groupUser } }); }} /></Box>
                                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => self.props.onRemoveGroupRequest(groupUser.notificationId, groupUser.notification.groupId)} /></Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            }
        }

        let checklistTemplates;
        if (this.selectedTabDisplay(tabs, "Checklist Templates")) {
            if (this.props.isSavingChecklistTemplate) {
                checklistTemplates = <Saving />;
            } else {

                checklistTemplates = <ResponsiveList
                    loading={this.props.isLoading || this.props.isCloningChecklistTemplate}
                    data={this.props.group.checklistTemplates}
                    headers={["Name", "Description"]}
                    columns={["name", "description"]}
                    getCommands={item => checklistTemplatesLogic.getCommands(item, item.groupId == this.props.groupId, false, item.isGlobal, item.groupId == this.props.groupId, true)}
                    onCommand={(command, item) => this.onChecklistTemplateCommand(command, item)}
                    isInfo={item => item.isGroup}
                    isInfoLabel="Group"
                    isSuccess={item => (item.isGlobal)}
                    isSuccessLabel="Global"
                    isDanger={item => item.obsolete}
                    canEdit={this.props.canAddChecklistTemplates}
                    toggleDanger={true}
                    toggleSuccess={true}
                />;
            }
        }

        let incidentActionTemplates;
        if (this.selectedTabDisplay(tabs, "Incident Actions")) {
            if (this.props.isSavingIncidentActionTemplate) {
                incidentActionTemplates = <Saving />;
            } else {
                incidentActionTemplates = <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.group.incidentActionTemplates}
                    headers={["Name", "Incident Categories"]}
                    columns={["name", "incidentCategoryTypeDisplay"]}
                    getCommands={item => incidentActionTemplateLogic.getCommands(item, item.groupId == this.props.groupId && this.props.canAddIncidentActions)}
                    onCommand={(command, item) => this.onIncidentActionTemplateCommand(command, item)}
                    isSuccess={item => !item.groupId || item.groupId == 0}
                    isSuccessLabel="Global"
                    isInfo={item => (item.groupId != null && item.groupId > 0)}
                    isInfoLabel="Group"
                    isDanger={item => item.obsolete}
                    canEdit={this.props.canAddIncidentActions}
                    toggleDanger={true}
                />;
            }
        }

        let preview;
        if (this.state.previewItemId > 0 && this.props.isLoadingItem) {
            preview = <Loading />;
        } else if (this.state.previewItemId > 0) {
            preview = (<IncidentActionTemplatePreview
                incidentActionTemplate={this.props.incidentActionTemplate}
                isLoadingItem={this.props.isLoadingItem} />);
        }

        let previewChecklistTemplate;
        if (this.state.previewChecklistTemplateId > 0 && this.props.isLoadingChecklistTemplate) {
            previewChecklistTemplate = <Loading />;
        } else if (this.state.previewChecklistTemplateId > 0) {
            previewChecklistTemplate = (<ChecklistTemplatePreview
                checklistTemplate={this.props.checklistTemplate}
                isLoadingItem={this.props.isLoadingChecklistTemplate} />);
        }

        let title = "Group";
        if (!this.props.isLoading && this.props.group) {
            title = title + " [" + this.props.group.name + "]";
        }

        let features;
        if (this.props.canEditFeatures) {
            features = <>
                <SubTitle text={"Group Features"} />
                <CheckboxControlGroup text="Dashboard"
                    defaultValue={this.props.group.featureDashboard}
                    onChanged={(value) => this.onValueChanged("featureDashboard", value)}
                    label={" "} />
                <CheckboxControlGroup text="Industries"
                    defaultValue={this.props.group.featureIndustry}
                    readonly={this.props.group.featureVenue}
                    onChanged={(value) => this.onValueChanged("featureIndustry", value)}
                    label={" "} />
                <CheckboxControlGroup text="Venues"
                    defaultValue={this.props.group.featureVenue}
                    onChanged={(value) => this.onValueChanged("featureVenue", value)}
                    readonly={this.props.group.featureIndustry}
                    label={" "} />
                <CheckboxControlGroup text="Managers"
                    defaultValue={this.props.group.featureUserRoles}
                    onChanged={(value) => this.onValueChanged("featureUserRoles", value)}
                    label={" "} />
                <CheckboxControlGroup text="Users"
                    defaultValue={this.props.group.featureUsers}
                    onChanged={(value) => this.onValueChanged("featureUsers", value)}
                    label={" "} />
                <CheckboxControlGroup text="Checklist Templates"
                    defaultValue={this.props.group.featureChecklist}
                    onChanged={(value) => this.onValueChanged("featureChecklist", value)}
                    label={" "} />
                <CheckboxControlGroup text="Incident Actions"
                    defaultValue={this.props.group.featureIncidentAction}
                    onChanged={(value) => this.onValueChanged("featureIncidentAction", value)}
                    label={" "} />
                <CheckboxControlGroup text="Watchlists and Events"
                    defaultValue={this.props.group.featureWatchlist}
                    onChanged={(value) => this.onValueChanged("featureWatchlist", value)}
                    label={" "} />
                <CheckboxControlGroup text="Document Folders"
                    defaultValue={this.props.group.featureDirectory}
                    onChanged={(value) => this.onValueChanged("featureDirectory", value)}
                    label={" "} />
            </>;
        }
        let tabControl;
        if (tabs.length > 0) {
            tabControl = (<View style={{ marginTop: '10px' }}>
                <Tabs selected={this.props.tabIndex} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
            </View>);
        }
        let pageContent;
        if (this.props.group && this.selectedTabDisplay(tabs, "Details")) {
            rightButton = <PrimaryButton text={"Save"} onClick={() => this.onSave()} />;

            pageContent = (<form>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.props.group.name}
                    onBlur={(value) => this.onValueChanged("name", value)} />
                <SelectControlGroup
                    text="Time Zone"
                    defaultValue={this.props.group.timeZone}
                    onChange={(value) => this.onValueChanged("timeZone", value)}>
                    <option value={undefined} />
                    {this.props.timeZones && this.props.timeZones.map((item) => (
                        <option key={item.timezoneId}
                            value={item.timezoneId}>{item.timezoneName}</option>
                    ))}
                </SelectControlGroup>
                <CheckboxControlGroup
                    text={"Active"}
                    label={" "}
                    isReversed={true}
                    defaultValue={this.props.group.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
                {features}
            </form>);

        } else if (this.selectedTabDisplay(tabs, "Venues")) {
            if (this.props.canAddVenues && this.selectedTabDisplay(tabs, "Venues") && this.props.groupId > 0) {
                leftButton = <TransparentButton onClick={() => this.props.onLoadVenues(this.props.groupId)} text={"Add Venue"} />;
            }
            pageContent = (<>
                {venueRequests}
                {venues}
            </>);
        } else if (this.selectedTabDisplay(tabs, "Industries")) {
            rightButton = <PrimaryButton text={"Save"} onClick={() => this.onSave()} />;
            pageContent = (<form>
                <IndustryCategoryMultiSelect
                    industryCategorySelections={this.props.group.industryCategorySelections}
                    onUpdated={(industryCategorySelections) => this.onValueChanged('industryCategorySelections', industryCategorySelections)}
                />
            </form>);
        } else if (this.selectedTabDisplay(tabs, "Managers")) {
            if (this.props.canAddUsers && this.selectedTabDisplay(tabs, "Managers") && this.props.groupId > 0) {
                leftButton = <TransparentButton onClick={() => this.props.onLoadUsers(this.props.groupId)} text={"Add Manager"} />;
            }
            pageContent = (<>
                {userRequests}
                {users}
            </>);
        } else if (this.selectedTabDisplay(tabs, "Users")) {
            if (this.props.canAddUsers && this.selectedTabDisplay(tabs, "Users") && this.props.groupId > 0) {
                //leftButton = <TransparentButton onClick={() => this.props.onLoadUsers(this.props.groupId)} text={"Add Manager"} />;
            }
            pageContent = (<GroupUsersContainer groupId={this.props.groupId} />);
        } else if (this.selectedTabDisplay(tabs, "Dashboard")) {
            pageContent = (<GroupDashboardContainer groupId={this.props.groupId} />);
        } else if (this.selectedTabDisplay(tabs, "Checklist Templates")) {
            if (this.props.canAddChecklistTemplates && this.selectedTabDisplay(tabs, "Checklist Templates") && this.props.groupId > 0) {
                leftButton = <TransparentButton onClick={() => this.onChecklistTemplateCommand("add", new AusComplyDtos.ChecklistTemplateSummary())} text={"Add Checklist Template"} />
            }
            pageContent = (<>{checklistTemplates}</>);
        } else if (this.selectedTabDisplay(tabs, "Incident Actions")) {
            if (this.props.canAddIncidentActions && this.selectedTabDisplay(tabs, "Incident Actions") && this.props.groupId > 0) {
                leftButton = <TransparentButton onClick={() => this.onIncidentActionTemplateCommand("add", new AusComplyDtos.IncidentActionTemplate())} text={"Add Incident Action Template"} />
            }
            pageContent = (<>{incidentActionTemplates}</>);
        } else if (this.selectedTabDisplay(tabs, "Watchlists")) {

            if (this.props.isAdmin) {
                leftButton = <TransparentButton text={"Back"} onClick={() => this.onClose()} />
            }
            return <FacialRecognitionWatchlistsContainer tabs={tabControl} backButton={leftButton} groupName={title} venueId={0} groupId={this.props.groupId} groupMode={true} />;
            // pageContent = (<FacialRecognitionWatchlistsContainer venueId={0} groupId={this.props.groupId} groupMode={true} />);
        } else if (this.selectedTabDisplay(tabs, "Events")) {
            pageContent = (<FacialRecognitionEventsContainer venueId={0} groupId={this.props.groupId} groupMode={true} />);
        } else if (this.selectedTabDisplay(tabs, "Document Folders")) {
            if (this.props.isAdmin) {
                leftButton = <TransparentButton text={"Back"} onClick={() => this.onClose()} />
            }
            return <>
                <GroupDirectoryContainer groupId={this.props.groupId} tabs={<>
                    {tabControl}
                    <Box p={0} display="flex" flexDirection={'row'} alignItems={"center"} justifyContent={"center"}>
                        <Box flexGrow={1} p={1} style={{ maxWidth: "700px" }}>
                            <CheckboxControlGroup text="Hide default folders from all Venues"
                                defaultValue={this.props.group.hideDefaultFolders}
                                onChanged={(value) => this.onValueChanged("hideDefaultFolders", value)}
                                labelAbove={true}
                                label={" "} />
                        </Box>
                    </Box>
                </>} title={title} backButton={leftButton} />
            </>
        }

        if (this.props.isAdmin) {
            if (leftButton && rightButton) {
                centerButton = leftButton;
                leftButton = <TransparentButton text={"Back"} onClick={() => this.onClose()} />
            } else if (leftButton) {
                rightButton = leftButton;
            }
            leftButton = <TransparentButton text={"Back"} onClick={() => this.onClose()} />
        }

        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={""}
                    headerText={title}

                    footerLeftContent={leftButton}
                    footerCenterContent={centerButton}
                    footerRightContent={rightButton}
                >
                    {tabControl}
                    {pageContent}
                </PageLayout >
                <FullPageDialog
                    open={showAddVenueModal}
                    title={"Add Venue(s)"}
                    onDismissed={() => this.setVenues([])}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {showAddVenueModal && (
                        <LayoutForm
                            loading={this.props.isLoadingVenues}
                            saving={this.props.isSavingVenues}>
                            <MultiSelectControl
                                label={"Venues"}
                                items={this.state.venues}
                                searchText="Type at least three letters..."
                                minimumCharactersRequired={3}
                                onSelectItemsChanged={this.onVenuesChanged}
                                canShowAll={true} />
                            {this.props.isAdmin && (
                                <CheckboxControlGroup
                                    text={"Approve without accept request"}
                                    label={" "}
                                    isReversed={false}
                                    defaultValue={this.props.venueAutoApproval}
                                    onChanged={(value) => this.props.onSetVenueAutoApproval(value)} />
                            )}
                            <ControlGroup text={" "}>
                                <DefaultButton onClick={() => this.props.onSaveVenues(this.props.groupId, this.props.venues, this.props.venueAutoApproval)}>Save</DefaultButton>
                            </ControlGroup>
                        </LayoutForm>
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={showAddUserModal}
                    title={"Add Manager(s)"}
                    onDismissed={() => this.setUsers([])}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {showAddUserModal && (
                        <LayoutForm
                            loading={this.props.isLoadingUsers}
                            saving={this.props.isSavingUsers}>
                            <SelectControlGroup
                                text="Role"
                                defaultValue={this.props.userRoleTypeId}
                                readonly={false}
                                onChange={(value) => this.props.onSetUserRoleType(value)}
                                labelAbove={false}>
                                <option value={undefined} />
                                {this.props.userRoleTypes && this.props.userRoleTypes.map((item) => (
                                    <option key={"user-role-type" + item.userRoleTypeId}
                                        value={item.userRoleTypeId}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            <MultiSelectControl
                                label={"Users"}
                                items={this.state.users}
                                searchText="Type at least three letters..."
                                minimumCharactersRequired={3}
                                onSelectItemsChanged={this.onUsersChanged}
                                canShowAll={true} />

                            <ControlGroup text=" ">
                                <CheckboxControlGroup text={"Temporary"}
                                    defaultValue={this.props.isTemporary}
                                    onChanged={(value) => this.props.onSetTemporary(value, self.props.expiryDate)} />
                            </ControlGroup>

                            {this.props.isTemporary && (
                                <LocalDateControlGroup
                                    text="Expiry Date"
                                    date={this.props.expiryDate}
                                    onChange={(value) => this.props.onSetTemporary(self.props.isTemporary, value)} />
                            )}
                            <ControlGroup text={" "}>
                                <DefaultButton onClick={() => this.props.onSaveUsers(this.props.groupId, this.props.users, this.props.userAutoApproval, this.props.userRoleTypeId, this.props.isTemporary, this.props.expiryDate)}>Save</DefaultButton>
                            </ControlGroup>
                        </LayoutForm>
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.groupUser != undefined}
                    title={"Edit Manager"}
                    onDismissed={() => this.setGroupUser(undefined, false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.groupUser && (
                        <LayoutForm
                            loading={this.props.isLoadingUsers}
                            saving={this.props.isSavingUsers}>
                            <TextControlGroup readonly={true} text={"User"} defaultValue={this.state.groupUser.displayName} />
                            <SelectControlGroup
                                text="Role"
                                defaultValue={this.state.groupUser.userRoleTypeId}
                                readonly={false}
                                onChange={(value) => self.setGroupUser({ ...self.state.groupUser, userRoleTypeId: value }, false)}
                                labelAbove={false}>
                                <option value={undefined} />
                                {this.props.userRoleTypes && this.props.userRoleTypes.map((item) => {
                                    let selected = item.userRoleTypeId == ((self.state.groupUser == undefined || self.state.groupUser.userRoleTypeId == undefined) ? 0 : self.state.groupUser.userRoleTypeId);
                                    return (
                                        <option key={"user-role-type" + item.userRoleTypeId}
                                            value={item.userRoleTypeId} selected={selected}>{item.name}</option>
                                    )
                                })}
                            </SelectControlGroup>

                            {(this.props.userRoleStatuses.length > 1) && (
                                <SelectControlGroup
                                    text="Status"
                                    onChange={(value) => self.setGroupUser({ ...self.state.groupUser, userRoleStatusId: value }, false)}
                                    defaultValue={this.state.groupUser.userRoleStatusId}>
                                    <option value={undefined} />
                                    {this.props.userRoleStatuses && this.props.userRoleStatuses.map((item, index) => (
                                        <option key={item.userRoleStatusId} value={item.userRoleStatusId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            )}

                            <ControlGroup text=" ">
                                <CheckboxControlGroup text={"Temporary"}
                                    defaultValue={this.state.groupUser.isTemporary}
                                    onChanged={(value) => self.setGroupUser({ ...self.state.groupUser, isTemporary: value }, false)} />
                            </ControlGroup>

                            {this.state.groupUser.isTemporary && (
                                <LocalDateControlGroup
                                    text="Expiry Date"
                                    date={this.state.groupUser.expiry}
                                    onChange={(value) => self.setGroupUser({ ...self.state.groupUser, expiry: value }, false)} />
                            )}

                            {this.props.isAdmin && (
                                <TextAreaControlGroup text={"Admin notes"}
                                    defaultValue={this.state.groupUser.adminNotes}
                                    onChange={value => self.setGroupUser({ ...self.state.groupUser, adminNotes: value }, false)} />
                            )}

                            <ControlGroup text={" "}>
                                <DefaultButton disabled={!this.canSaveGroupUser()} onClick={() => this.setGroupUser(self.state.groupUser, true)}>Save</DefaultButton>
                            </ControlGroup>
                        </LayoutForm>
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeGroupUser != undefined}
                    title={"Remove Manager"}
                    onDismissed={() => this.setRemoveGroupUser(undefined, false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.removeGroupUser && (
                        <LayoutForm
                            loading={this.props.isLoadingUsers}
                            saving={this.props.isSavingUsers}>
                            <TextControlGroup readonly={true} text={"User"} defaultValue={this.state.removeGroupUser.displayName} />
                            <TextControlGroup readonly={true} text={"Role"} defaultValue={this.state.removeGroupUser.userRoleType} />


                            {this.props.isAdmin && (
                                <TextAreaControlGroup text={"Admin notes"}
                                    defaultValue={this.state.removeGroupUser.adminNotes}
                                    onChange={value => self.setRemoveGroupUser({ ...this.state.removeGroupUser, adminNotes: value }, false)} />
                            )}

                            <ControlGroup text={" "}>
                                <DefaultButton onClick={() => this.setRemoveGroupUser({ ...self.state.removeGroupUser, obsolete: true }, true)}>Save</DefaultButton>
                            </ControlGroup>
                        </LayoutForm>
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.restoreGroupUser != undefined}
                    title={"Restore Manager"}
                    onDismissed={() => this.setRestoreGroupUser(undefined, false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.restoreGroupUser && (
                        <LayoutForm
                            loading={this.props.isLoadingUsers}
                            saving={this.props.isSavingUsers}>
                            <TextControlGroup readonly={true} text={"User"} defaultValue={this.state.restoreGroupUser.displayName} />
                            <TextControlGroup readonly={true} text={"Role"} defaultValue={this.state.restoreGroupUser.userRoleType} />


                            {this.props.isAdmin && (
                                <TextAreaControlGroup text={"Admin notes"}
                                    defaultValue={this.state.restoreGroupUser.adminNotes}
                                    onChange={value => self.setRestoreGroupUser({ ...this.state.restoreGroupUser, adminNotes: value }, false)} />
                            )}

                            <ControlGroup text={" "}>
                                <DefaultButton onClick={() => this.setRestoreGroupUser({ ...self.state.restoreGroupUser, obsolete: false }, true)}>Save</DefaultButton>
                            </ControlGroup>
                        </LayoutForm>
                    )}
                </FullPageDialog>
                <FullPageDialog open={this.state.previewItemId > 0}
                    title="Preview"
                    onDismissed={() => this.onPreviewItem(0)}>
                    {preview}
                </FullPageDialog>
                <FullPageDialog open={this.state.previewChecklistTemplateId > 0}
                    title="Preview Checklist"
                    onDismissed={() => this.onPreviewChecklistTemplate(0)}>
                    {previewChecklistTemplate}
                </FullPageDialog>
                <ConfirmDialog
                    title={"Confirm remove"}
                    text={"Are you sure you wish to remove this incident action template"}
                    show={this.state.confirmRemoveIncidentActionTemplate}
                    onCancel={this.removeCancelIncidentActionTemplate}
                    onConfirm={this.removeConfirmedIncidentActionTemplate} />
                <ConfirmDialog
                    title={"Confirm undo remove"}
                    text={"Are you sure you wish to re-enable this incident action template"}
                    show={this.state.confirmUndoRemoveIncidentActionTemplate}
                    onCancel={this.removeCancelIncidentActionTemplate}
                    onConfirm={this.removeUndoConfirmedIncidentActionTemplate} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Group));