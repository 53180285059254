import update from "immutability-helper";
import {
    FACIAL_RECOGNITION_FEED_REQUEST,
    FACIAL_RECOGNITION_FEED_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_FEED_REQUEST_FAILURE,
    FACIAL_RECOGNITION_FEED_SET_AUTOREFRESH
} from "../actions/facialRecognitionFeed";

const initialState = {
    isLoading: false,
    autoRefresh: false,
    feed: []
};

function isLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setFeed(state, feed) {
    let newState = update(state, {
        feed: { $set: feed }
    });
    return newState;
}

function setAutoRefresh(state, value) {
    let newState = update(state, {
        autoRefresh: { $set: value }
    });
    return newState;
}

export default function facialRecognitionFeed(state = initialState, action) {
    switch (action.type) {
        case FACIAL_RECOGNITION_FEED_REQUEST:
            return isLoading(state, true);
        case FACIAL_RECOGNITION_FEED_REQUEST_SUCCESS:
            return isLoading(setFeed(state, action.feed), false);
        case FACIAL_RECOGNITION_FEED_REQUEST_FAILURE:
            return isLoading(state, false);
        case FACIAL_RECOGNITION_FEED_SET_AUTOREFRESH:
            return setAutoRefresh(state, action.value);
    }
    return state;
}