import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import LayoutForm from '../layout/LayoutForm';
import Grid from '@material-ui/core/Grid';
import ResponsiveList from '../common/ResponsiveList';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import PageTitle from '../common/PageTitle';


export interface IUserPermissionsProps {
    permissions: string[];
}

interface IUserPermissionsState {
    search: string;
}

class UserPermissions extends Component<IUserPermissionsProps, IUserPermissionsState> {
    constructor(props: IUserPermissionsProps) {
        super(props)

        this.state = {
            search: ''
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(value: string) {
        this.setState({ search: value });
    }

    render() {
        let list;
        if (this.props.permissions) {
            let data = this.props.permissions.filter(f => this.state.search == "" || f.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 ).map((p, index) => {
                return { "code": p };
                //var o = { "code": p };
                //return o;
            });
            list = <ResponsiveList
                data={data}
                headers={["Permission Code"]}
                columns={["code"]}
                getCommands={item => []}
                onCommand={() => { }}
                canEdit={false}
            />;
        }

        let filter;
        filter = (<CardContainer title={"Filter"}>
            <CardContent>
                <TextControlGroup
                    text={"Search"}
                    defaultValue={this.state.search}
                    onChange={(value) => this.onValueChanged(value)}
                />
            </CardContent>
        </CardContainer>);

        return (
            <>
                <LayoutForm
                    header={<PageTitle title={"User Permissions"}></PageTitle>}
                    fullWidth={false}
                >
                    {filter}
                    {list}

                </LayoutForm>
                <VenueQuickActionsContainer />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserPermissions);