import React, { Component } from 'react';
import * as AuscomplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import CheckboxControl from "../../controls/CheckboxControl";
import Information from '../../common/Information';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IBroadcastNotificationUserRolesProps {
    broadcastNotification: AuscomplyDtos.BroadcastNotification;
    onUpdate: Function;
}

interface IBroadcastNotificationUserRolesState {
    userRoleTypes: MulitSelectOption[];
}

export default class BroadcastNotificationUserRoles extends Component<IBroadcastNotificationUserRolesProps, IBroadcastNotificationUserRolesState> {
    constructor(props: IBroadcastNotificationUserRolesProps) {
        super(props)
        this.state = {
            userRoleTypes: []
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onUserRoleTypesChanged = this.onUserRoleTypesChanged.bind(this);
        this.setUserRoleTypes = this.setUserRoleTypes.bind(this);
    }

    componentDidMount(): void {
        if (this.props.broadcastNotification) {
            this.setUserRoleTypes();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.broadcastNotification &&
            (this.hasChangedHash(this.props.broadcastNotification) != this.hasChangedHash(prevProps.broadcastNotification))) {
            this.setUserRoleTypes(); 
        }
    }

    hasChangedHash(broadcastNotification) {
        let hash = "";
        if (broadcastNotification && broadcastNotification.userRoleTypeSelections) {
            broadcastNotification.userRoleTypeSelections.forEach((item, index) => {
                hash += "#u" + item.userRoleTypeId.toString();
            });
        }
        if (broadcastNotification) {
            if (broadcastNotification.accessLevel0) {
                hash += "#a0";
            };
            if (broadcastNotification.accessLevel1) {
                hash += "#a1";
            };
            if (broadcastNotification.accessLevel2) {
                hash += "#a2";
            };
            if (broadcastNotification.accessLevel3) {
                hash += "#a3";
            };
            if (broadcastNotification.accessLevel4) {
                hash += "#a4";
            };

            if (broadcastNotification.includeAssociatedUsers) {
                hash += "#inc";
            }
        }
        return hash;
    }

    onValueChanged(fieldName: string, value: any) {
        let broadcastNotification = { ...this.props.broadcastNotification };
        broadcastNotification[fieldName] = value;
        this.props.onUpdate(broadcastNotification);
    }

    setUserRoleTypes() {
        let excludeSecurity = false;
        if (!this.props.broadcastNotification.securityFirmId || this.props.broadcastNotification.securityFirmId == 0) {
            excludeSecurity = !this.props.broadcastNotification.includeAssociatedUsers;
        }
        let userRoleTypes: MulitSelectOption[] = [];
        if (this.props.broadcastNotification.userRoleTypeSelections) {
            this.props.broadcastNotification.userRoleTypeSelections.forEach((item, index) => {
                if (item.accessLevel == 0 && !this.props.broadcastNotification.accessLevel0) {
                    // exclude
                } else if (item.accessLevel == 1 && !this.props.broadcastNotification.accessLevel1) {
                    // exclude
                } else if (item.accessLevel == 2 && !this.props.broadcastNotification.accessLevel2) {
                    // exclude
                } else if (item.accessLevel == 3 && !this.props.broadcastNotification.accessLevel3) {
                    // exclude
                } else if (item.accessLevel == 4 && !this.props.broadcastNotification.accessLevel4) {
                    // exclude
                } else if (item.security && excludeSecurity) {
                    // exclude
                } else {
                    let userRoleType = new MulitSelectOption();
                    userRoleType.id = item.userRoleTypeId;
                    userRoleType.name = item.name;
                    userRoleType.selected = item.selected;
                    userRoleType.isCommon = false;
                    userRoleType.info = "Access Level " + item.accessLevel;
                    userRoleTypes.push(userRoleType);
                }
            });
        }

        this.setState({
            userRoleTypes
        });
    }

    onUserRoleTypesChanged(value) {
        let userRoleTypes = [...this.state.userRoleTypes];
        userRoleTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            userRoleTypes
        });

        let broadcastNotification = { ...this.props.broadcastNotification };
        if (broadcastNotification.userRoleTypeSelections) {
            broadcastNotification.userRoleTypeSelections.forEach((item, index) => {
                item.selected = this.state.userRoleTypes.filter(s => s.id === item.userRoleTypeId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(broadcastNotification)
    }

    render() {

        let userRoleTypes;
        if (this.props.broadcastNotification && this.props.broadcastNotification.userRoleTypeSelections) {
            userRoleTypes = (
                <MultiSelectControl
                    items={this.state.userRoleTypes}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    isReadonly={this.props.broadcastNotification.isSent}
                    searchText={"Type at least three letters..."}
                    onSelectItemsChanged={this.onUserRoleTypesChanged} />
            );
        }

        return (
            <ControlGroup text={"Recipient User roles"}>
                {userRoleTypes}
            </ControlGroup>
        );
    }
}