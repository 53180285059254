import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import View from '../../common/View';
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import SimpleFilter from '../../common/SimpleFilter';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import DocumentTypeCard from './DocumentTypeCard';
import DocumentTypeHeader from './DocumentTypeHeader';
import DocumentTypeRow from './DocumentTypeRow';
import AdminDocumentTypeContainer from '../../../containers/AdminDocumentTypeContainer';

export interface IDocumentTypesProps {
    isLoading: boolean;
    documentTypes: AusComplyDtos.DocumentType[];
    documentTypeId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    canCreate: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

interface IDocumentTypesState {
    filterExpanded: boolean;
}

export default class DocumentTypes extends Component<IDocumentTypesProps, IDocumentTypesState> {
    constructor(props: IDocumentTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, documentType: AusComplyDtos.DocumentType) {
        switch (command) {
            case "add":
                this.props.onCreate();
                break;
            case "edit":
                this.props.onLoadItem(documentType.documentTypeId);
                break;
            case "delete":
                var updated = { ...documentType };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {        
        this.props.onLoad({...filter}, 1, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(new AusComplyDtos.IncidentTypeFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    onUpdateFilter(filter: AusComplyDtos.SimpleFilter) {
     //   this.props.onFilterUpdate(filter);
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            cardList = this.props.documentTypes.map((item, index) => {
                return (
                    <DocumentTypeCard
                        onCommand={this.onCommand}
                        key={"card-" + item.documentTypeId.toString()}
                        documentType={item}
                    />
                );
            });
            rows = this.props.documentTypes.map((item, index) => {
                return (
                    <DocumentTypeRow
                        onCommand={this.onCommand}
                        key={"row-" + item.documentTypeId.toString()}
                        documentType={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<DocumentTypeHeader />}
                    rows={rows}
                />
            );
        }

        let documentType;
        if (this.props.documentTypeId != -1) {
            documentType = (
                <AdminDocumentTypeContainer />
            );
        }

        let createFab;
        if (this.props.canCreate) {
            createFab = (
                <DefaultFab onClick={() => this.props.onCreate()} isExtended={true}>Add Document Folder</DefaultFab>
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let industryCategoriesFilter = new AusComplyDtos.SimpleFilter();
            industryCategoriesFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={industryCategoriesFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }


        return (<>
            <PageTitle title="Document Folder"></PageTitle>
            {filterSummary}
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <VenueQuickActionsContainer>
                {createFab}
            </VenueQuickActionsContainer>
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <FullPageDialog open={this.props.documentTypeId != -1} title="Document Folder" onDismissed={() => this.props.onResetItem()}>
                {documentType}
            </FullPageDialog>
        </>);
    }
}