import { connect } from 'react-redux'
import IncidentVersions from '../components/incidents/versions/IncidentVersions';
import { withRouter } from "react-router";
import { displayIncidentVersionsLoad } from '../common/actions/displayIncident';

const mapStateToProps = (state, props) => {
    return {
        incidentId: props.match.params.incidentid,
        isLoading: state.displayIncident.isLoadingIncidentVersions,
        incidentVersions: state.displayIncident.incidentVersions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (incidentId) => {
            dispatch(displayIncidentVersionsLoad(incidentId))
        }
    }
}

const IncidentVersionsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentVersions))

export default IncidentVersionsContainer