import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import View from '../common/View';
import { Palette } from '../../common/constants/palette';

export interface INavigationButtonProps {
    classes: any;
    text?: string;
    Icon?: any;
    disabled?: boolean;
    onClick?: Function;
    active?: boolean;
    isWarning?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    isInfo?: boolean;
    isRejected?: boolean;
    style?: any;
}

class NavigationButton extends React.PureComponent<INavigationButtonProps, any> {
    constructor(props: INavigationButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;
        let color = Palette.ControlBorder;
        if (this.props.isError && !this.props.disabled) {
            color = Palette.Error;
        } else if (this.props.isWarning && !this.props.disabled) {
            color = Palette.Warning;
        } else if (this.props.isSuccess && !this.props.disabled) {
            color = Palette.Primary;
        } else if (this.props.isInfo && !this.props.disabled) {
            color = Palette.Info;
        } else if (this.props.isRejected && !this.props.disabled) {
            color = Palette.Rejected;
        } else if (this.props.active) {
            color = Palette.Active;
        } else if (this.props.disabled) {
            color = Palette.ControlBorder;
        }
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                className={classNames(classes.buttonClear, classes.buttonState)}
                style={{ width: '100%', height: '100%', paddingLeft: 0, paddingRight: 0, ...this.props.style }}>
                    <View style={{
                        display: 'flex',
                        flex: 0,
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        <View style={{ flex: 1 }}></View>
                        <View style={{ flex: 0 }}>
                            <View style={{ width: '30px', height: '30px', marginTop: '5px' }}>
                               <this.props.Icon fill={color} />
                            </View>
                        </View>
                        <View style={{ flex: 0 }}>
                            <p style={{ width: '40px', margin: 0, color: color, padding: '5px 0 0 0', fontSize: '9px' }}>{this.props.text}</p>
                        </View>
                        <View style={{ flex: 1 }}></View>
                    </View>

            </Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(NavigationButton);