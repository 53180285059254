import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { deviceInfo } from '../../utilities/device';

export const loginServiceApi = {
  postLogin: (username: string, password: string, locationFound: boolean, latitude: number, longitude: number) => {
    
    var client = new AusComplyJsonServiceClient();
    var authenticateRequest = new AusComplyDtos.Authenticate();
    authenticateRequest.userName = username;
    authenticateRequest.password = password;
    authenticateRequest.rememberMe = true;
    authenticateRequest.provider = 'credentials';
    if (!authenticateRequest.meta)
    {
      authenticateRequest.meta = {};
    }
    authenticateRequest.meta["locationfound"] = locationFound.toString();
    authenticateRequest.meta["latitude"] = latitude.toString();
    authenticateRequest.meta["longitude"] = longitude.toString();
    
    let info = deviceInfo.get();
    authenticateRequest.meta["deviceId"] = info.deviceId;
    authenticateRequest.meta["os"] = info.os;
    authenticateRequest.meta["model"] = info.model;
    authenticateRequest.meta["make"] = info.make;
    authenticateRequest.meta["version"] = info.version;
    authenticateRequest.meta["appVersion"] = info.appVersion;

    const request = client.post(authenticateRequest);
    return from(request);    
  },
  getUserDetails: (locationFound: boolean, latitude: number, longitude: number, venueId: number, securityFirmId: number, venueEventId: number) => {
    // TO DO - combine the header and session get into a single request GetFullUserDetails()
    var client = new AusComplyJsonServiceClient();
    var getUserSessionRequest = new AusComplyDtos.GetUserDetailSession();
    getUserSessionRequest.locationFound = locationFound;
    getUserSessionRequest.latitude = latitude;
    getUserSessionRequest.longitude = longitude;
    getUserSessionRequest.venueId = venueId;
    getUserSessionRequest.securityFirmId = securityFirmId;
    getUserSessionRequest.venueEventId = venueEventId;
    const request = client.get(getUserSessionRequest);
    return from(request);  
  },
  impersonate: (locationFound: boolean, latitude: number, longitude: number, userId: number) => {
    // TO DO - combine the header and session get into a single request GetFullUserDetails()
    var client = new AusComplyJsonServiceClient();
    var getUserSessionRequest = new AusComplyDtos.GetUserDetailSession();
    getUserSessionRequest.locationFound = false;
    getUserSessionRequest.impersonateUserId = userId;
    const request = client.get(getUserSessionRequest);
    return from(request);  
  },
  postLogout: (userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var userLogoutRequest = new AusComplyDtos.UserLogout();
    userLogoutRequest.userId = userId;
    const request = client.post(userLogoutRequest);
    return from(request);    
  }
};
