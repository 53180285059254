import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TableRow from '../../common/TableRow';

export interface IIndustryCategoryRowProps {
    industryCategory: AusComplyDtos.IndustryCategory;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IndustryCategoryRow extends React.Component<IIndustryCategoryRowProps, any> {
    constructor(props: IIndustryCategoryRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.industryCategory);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let industryCategory = { ...this.props.industryCategory };
                    industryCategory.obsolete = true;
                    this.props.onUpdate(industryCategory);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let industryCategory = { ...this.props.industryCategory };
                    industryCategory.obsolete = false;
                    this.props.onUpdate(industryCategory);
                }
                break;
        }
    }

    render() {
        if (!this.props.industryCategory) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.industryCategory.obsolete && this.props.onUpdate) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        var types = this.props.industryCategory.incidentCategoryTypesDisplay;
        if (types && types.length > 100) {
            types = types.substring(0, 100) + "... (" + this.props.industryCategory.incidentCategoryTypeSelections.filter(f => f.selected).length.toString() + ")";
        }

        return (
            <TableRow isDanger={this.props.industryCategory.obsolete} commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.industryCategory.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p style={{fontStyle: 'italic', color: '#aaa'}}>
                        {types}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.industryCategory.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}