import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '../../../resources/PersonIcon';
import IdCardIcon from '../../../resources/IdCardIcon';
import TickIcon from '../../../resources/TickIcon';
import DocumentIcon from '../../../resources/DocumentIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import ImagePreview from '../../common/ImagePreview';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';

export interface IInmatePhotoProps {
    file: AusComplyDtos.File;
    //isUploading: boolean;
    onUpdate?: Function;
    theme: any;
}

interface IInmatePhotoState {
    selectedFilePath: string;
    selectedFileName: string;
}

class InmatePhoto extends Component<IInmatePhotoProps, IInmatePhotoState> {
    constructor(props: IInmatePhotoProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    removeDocument() {
        let file = { ...this.props.file };
        if (file.attachmentId === 0) {
            file = new AusComplyDtos.File();
            file.attachmentId = 0;
        } else {
            file.obsolete = true;
        }
        if (this.props.onUpdate) this.props.onUpdate(file);
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        let self = this;
        temporaryFiles.forEach(item => {
            if (self.props.onUpdate) self.props.onUpdate(item);
        });
    }

    render() {
        const { theme } = this.props;

        let photo;

        photo = (
            <Box p={0} key={"file-box-id"}>
                <FileUpload
                    onFileWasUploaded={f => this.fileWasUploaded(f)}
                    //isUploading={this.props.isUploading}
                    requestCamera={true}
                    alternateContent={
                        <Box display="flex" flexDirection="column" justifyContent="center" p={0}
                            style={{
                                position: 'relative',
                                textAlign: 'center',
                                width: '75px',
                                margin: '0 8px',
                                height: '84px',
                                borderRadius: '3px',
                                border: '1px solid #636364'
                            }}>
                            <Box p={0}></Box>
                            <Box p={0}>
                                <PersonIcon style={{ fontSize: '2.5em' }} />
                            </Box>
                            <Box p={0}>
                                <span style={{ fontSize: '10px' }}>Take Photo</span>
                            </Box>
                            <Box p={0}></Box>
                        </Box>}
                    hasFiles={false} />
            </Box>
        );

        if (this.props.file && this.props.file.fileDisplayPath && this.props.file.fileDisplayPath.length > 0 && this.props.file.obsolete != true) {
            photo = <Box p={0} key={"file-box-" + this.props.file.filePreviewPath }>
                <Thumbnail
                    key={"file-" + this.props.file.filePreviewPath}
                    previewPath={this.props.file.filePreviewPath}
                    displayPath={this.props.file.fileDisplayPath}
                    isImage={this.props.file.isImage}
                    text={this.props.file.filePreviewText}
                    fullText={this.props.file.originalName}
                    onPreview={(path, text) => this.onSelectFilePath(path, text)}
                    onDownload={path => this.onDownload(path)}
                    onRemove={() => this.removeDocument()}
                />
            </Box>
        }

        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {photo}
                </Box>
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(InmatePhoto);