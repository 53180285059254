import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { checklistTemplatesLogic } from '../../../common/logic/checklistTemplatesLogic';

export interface IChecklistTemplateRowProps {
    checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary;
    canEdit?: boolean;
    canEditGlobal?: boolean;
    canClone?: boolean;
    onCommand?: Function;
}

export default class ChecklistTemplateRow extends React.Component<IChecklistTemplateRowProps, any> {
    constructor(props: IChecklistTemplateRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.checklistTemplateSummary);
        }
    }

    render() {
        if (!this.props.checklistTemplateSummary) {
            return (<></>);
        }

        var commands = checklistTemplatesLogic.getCommands(this.props.checklistTemplateSummary,
            this.props.canEdit,
            this.props.canEditGlobal,
            this.props.canClone);

        var item = this.props.checklistTemplateSummary;

        return (
            <TableRow commands={commands}
                isSuccess={this.props.checklistTemplateSummary.isGlobal}
                isInfo={this.props.checklistTemplateSummary.isGroup}
                isDanger={this.props.checklistTemplateSummary.obsolete}
                onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td>
                    <p style={{ textAlign: 'center' }}>
                        {item.itemCount}
                    </p>
                </td>
                <td>
                    <p style={{ textAlign: 'center' }}>
                        {item.instanceCount}
                    </p>
                </td>
            </TableRow>
        );
    }
}