import { makeActionCreator } from './helpers';

export const
    INCIDENTCATEGORYTYPES_USAGE_REQUEST = "INCIDENTCATEGORYTYPES_USAGE_REQUEST",
    INCIDENTCATEGORYTYPES_USAGE_REQUEST_SUCCESS = "INCIDENTCATEGORYTYPES_USAGE_REQUEST_SUCCESS",
    INCIDENTCATEGORYTYPES_USAGE_REQUEST_FAILURE = "INCIDENTCATEGORYTYPES_USAGE_REQUEST_FAILURE";


export const incidentCategoryTypesUsageRequest = makeActionCreator(INCIDENTCATEGORYTYPES_USAGE_REQUEST, 'incidentTypeId', 'entryPage', 'reportingPeriodId');
export const incidentCategoryTypesUsageRequestSuccess = makeActionCreator(INCIDENTCATEGORYTYPES_USAGE_REQUEST_SUCCESS, 'data', 'incidentTypeId', 'entryPage', 'reportingPeriodId');
export const incidentCategoryTypesUsageRequestFailure = makeActionCreator(INCIDENTCATEGORYTYPES_USAGE_REQUEST_FAILURE, 'error');