import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import SubTitle from '../common/SubTitle';
import TableContainer from '../layout/TableContainer';
import List from '../common/List';
import UserRoleCard from './UserRoleCard';
import UserRoleRow from './UserRoleRow';
import UserRoleHeader from './UserRoleHeader';
import ResponsiveList from '../common/ResponsiveList';

export interface IUserRolesProps {
    userRoleSummaries: AusComplyDtos.UserRoleSummary[];
    canAdminEdit?: boolean;
    onEdit?: Function;
    onRemove?: Function;
    onEnable?: Function;
    classes: any;
    theme: any;
}

class UserRoles extends Component<IUserRolesProps, any> {

    render() {
        const { classes } = this.props;

        if (!this.props.userRoleSummaries || this.props.userRoleSummaries.length == 0) {
            return (<></>);
        }

        let hasGroups = this.props.userRoleSummaries.filter(f => f.isGroup).length > 0;

        let cardList = this.props.userRoleSummaries.map((item) => {
            return (
                <UserRoleCard
                    key={"user-request-card-summary-" + item.userRoleId.toString()}
                    userRoleSummary={item}
                    canAdminEdit={this.props.canAdminEdit}
                    onEdit={this.props.onEdit}
                    onRemove={this.props.onRemove}
                    onEnable={this.props.onEnable}
                />
            );
        });


        let rows = this.props.userRoleSummaries.map((item) => {
            return (
                <UserRoleRow
                    key={"user-request-rowsummary-" + item.userRoleId.toString()}
                    userRoleSummary={item}
                    canAdminEdit={this.props.canAdminEdit}
                    onEdit={this.props.onEdit}
                    onRemove={this.props.onRemove}
                    onEnable={this.props.onEnable}
                    hasGroups={hasGroups} />
            );
        });


        let rowList = (
            <TableContainer
                header={<UserRoleHeader hasGroups={hasGroups} />}
                rows={rows}
            />
        );

        return (
            <>
                <SubTitle text={"User Roles"}></SubTitle>
                <List
                    loading={false}
                    count={this.props.userRoleSummaries.length}
                    cards={cardList}
                    rows={rowList}
                />
            </>
        );

        return (<></>);
    }
}
export default withStyles(styles, { withTheme: true })(UserRoles);