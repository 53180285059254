import { connect } from 'react-redux'
import RegisterSecurityFirm from '../components/register/securityFirm/RegisterSecurityFirm';
import {
    registerSecurityFirmNew,
    registerSecurityFirmSetStep,
    registerSecurityFirmReset,
    registerSecurityFirmUpdate,
    registerSecurityFirmUpdateOtherDocuments,
    registerSecurityFirmUpdateComplianceDocuments,
    registerSecurityFirmSaveRequest
} from '../common/actions/registerSecurityFirm'

const mapStateToProps = state => {
    return {
        securityFirm: state.registerSecurityFirm.securityFirm,
        primaryContact: state.registerSecurityFirm.primaryContact,
        managerContact: state.registerSecurityFirm.managerContact,
        states: state.registerSecurityFirm.states,
        securityLicenceClasses: state.registerSecurityFirm.securityLicenceClasses,
        otherDocuments: state.registerSecurityFirm.otherDocuments,
        otherDocumentTypes: state.registerSecurityFirm.otherDocumentTypes,
        complianceDocuments: state.registerSecurityFirm.complianceDocuments,
        complianceDocumentTypes: state.registerSecurityFirm.complianceDocumentTypes,
        step: state.registerSecurityFirm.step,
        isLoading: state.registerSecurityFirm.isLoading,
        isSaving: state.registerSecurityFirm.isSaving,
        error: state.registerSecurityFirm.error,
        errors: state.registerSecurityFirm.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitSecurityFirm: () => {
            dispatch(registerSecurityFirmNew())
        },
        onSetStep: (step) => {
            dispatch(registerSecurityFirmSetStep(step))
        },
        onUpdate: (securityFirm, managerContact, primaryContact) => {
            dispatch(registerSecurityFirmUpdate(securityFirm, managerContact, primaryContact))
        },
        onReset: () => {
            dispatch(registerSecurityFirmReset())
        },
        onUpdateOtherDocuments: (otherDocuments) => {
            dispatch(registerSecurityFirmUpdateOtherDocuments(otherDocuments))
        },
        onUpdateComplianceDocuments: (complianceDocuments) => {
            dispatch(registerSecurityFirmUpdateComplianceDocuments(complianceDocuments))
        },
        onSave: () => {
            dispatch(registerSecurityFirmSaveRequest())
        },
    }
}

const RegisterSecurityFirmContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterSecurityFirm)

export default RegisterSecurityFirmContainer