import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const incidentActionTemplateServiceApi = {
  find: (venueId, securityFirmId, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentActionTemplates();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId,forGroupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentActionTemplateNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forGroupId = forGroupId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, incidentActionTemplate) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentActionTemplate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentActionTemplate = incidentActionTemplate;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, incidentActionTemplateId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentActionTemplate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentActionTemplateId = incidentActionTemplateId;
    const request = client.get(query);
    return from(request);
  }
};
