import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentCategoryType } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import TextControlGroup from '../../controls/TextControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';

export interface IIncidentWizardCategoryProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

interface IIncidentWizardCategoryState {
    items: MulitSelectOption[];
    showOther: boolean;
    showSeriousOther: boolean;
}

export default class IncidentWizardCategory extends Component<IIncidentWizardCategoryProps, IIncidentWizardCategoryState> {
    constructor(props: IIncidentWizardCategoryProps) {
        super(props)
        this.state = {
            items: [],
            showOther: false,
            showSeriousOther: false
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        let items: MulitSelectOption[] = [];
        let selectedIncidentCategoryTypeIds: number[] = [];
        if (this.props.incident && this.props.incident.incidentCategory) {
            selectedIncidentCategoryTypeIds = this.props.incident.incidentCategory.map(item => item.incidentCategoryTypeId);
        }
        if (this.props.incidentOptions && this.props.incidentOptions.incidentTypeCategoryTypes) {
            this.props.incidentOptions.incidentTypeCategoryTypes.map(incidentType => {
                if (incidentType.incidentTypeId == this.props.incident.incidentTypeId) {
                    incidentType.incidentCategoryTypes.map(item => {
                        let isSelected = selectedIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1;
                        items.push({
                            id: item.incidentCategoryTypeId,
                            name: item.display,
                            isCommon: (item.usageCount > 0),
                            selected: isSelected
                        });
                    });
                }
            })
        }
        let showOther: boolean = incidentLogic.isOtherTextRequired(this.props.incident, this.props.incidentOptions);
        let showSeriousOther: boolean = incidentLogic.isSeriousOtherTextRequired(this.props.incident, this.props.incidentOptions);
        this.setState({
            items,
            showOther,
            showSeriousOther
        });
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.incident.incidentCategory.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = items.map(item => item.id);

        // remove
        let newIncidentCategories = this.props.incident.incidentCategory.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        items.filter(item => previousIncidentCategoryTypeIds.indexOf(item.id) == -1).forEach(item => {
            newIncidentCategories.push({
                incidentCategoryId: 0,
                incidentId: 0,
                incidentCategoryTypeId: item.id,
                incidentCategoryType: new IncidentCategoryType()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentCategory = newIncidentCategories;
        let showOther: boolean = incidentLogic.isOtherTextRequired(newIncident, this.props.incidentOptions);
        let showSeriousOther: boolean = incidentLogic.isSeriousOtherTextRequired(newIncident, this.props.incidentOptions);
        this.setState({
            showOther,
            showSeriousOther
        });
        this.props.onUpdateIncident(newIncident);
    }

    onChanged(fieldName: string, value) {
        let newIncident = { ...this.props.incident };
        newIncident[fieldName] = value;
        this.props.onUpdateIncident(newIncident)
    }

    render() {

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Categories"}
                    style={{ paddingTop: 0 }}>
                    <CardContent style={{paddingTop: 0}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                {(this.state.showOther || this.state.showSeriousOther) && (
                                    <Grid container spacing={1} style={{marginBottom: '10px'}}>
                                        <Grid item xs={12}>
                                            {this.state.showOther && (
                                                <TextControlGroup text="Other (specify)"
                                                    defaultValue={this.props.incident.other}
                                                    onChange={(value) => this.onChanged("other", value)}
                                                    labelAbove={true} />
                                            )}
                                            {this.state.showSeriousOther && (
                                                <TextControlGroup text="Serious Other (specify)"
                                                    defaultValue={this.props.incident.seriousOther}
                                                    onChange={(value) => this.onChanged("seriousOther", value)}
                                                    labelAbove={true} />
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <MultiSelectControl
                                            items={this.state.items}
                                            label={""}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectItemsChanged}
                                            canShowAll={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}