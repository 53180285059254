
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { notificationTriggerServiceApi } from '../services/notificationTriggerService';

import * as notificationTriggerActions from "../actions/notificationTrigger";
import * as epicCommon from './common';
import * as apiRequest from "../services/common";

const notificationTriggerRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(notificationTriggerActions.NOTIFICATION_TRIGGER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationTriggerServiceApi.get(
                    state.user.details.userSession.user.venueId
                    , state.user.details.userSession.user.securityFirmId
                    , action['notificationTriggerParentType']
                    , action['parentId']
                    , action['forGroupId']
                    , action['forVenueId']
                    , action['forSecurityFirmID']
                )
                .pipe(
                    map(response => notificationTriggerActions.notificationTriggerRequestSuccess(response.notificationTrigger)),
                    catchError(error => epicCommon.notifyErrorMessage(error, "Failed to load notifications", notificationTriggerActions.notificationTriggerRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyErrorMessage(error, "Failed to load notifications", notificationTriggerActions.notificationTriggerRequestFailure))
    );

const notificationTriggerUpsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(notificationTriggerActions.NOTIFICATION_TRIGGER_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationTriggerServiceApi.upsert(
                state.user.details.userSession.user.venueId
                , state.user.details.userSession.user.securityFirmId
                , action['notificationTrigger'])
                .pipe(
                    map(response => notificationTriggerActions.notificationTriggerUpsertRequestSuccess(action.notificationTrigger)),
                    catchError(error => epicCommon.notifyError(error, "notificationTriggerUpsertRequestEpic.upsert", notificationTriggerActions.notificationTriggerUpsertRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "notificationTriggerUpsertRequestEpic", notificationTriggerActions.notificationTriggerUpsertRequestFailure))
    );

const notificationTriggerUsersSummaryRequestEpic = epicCommon.epicApiGet(
    notificationTriggerActions.NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST,
    AusComplyDtos.GetNotificationTriggerUsersSummary,
    ['notificationTriggerId'],
    response => notificationTriggerActions.notificationTriggerUsersSummaryRequestSuccess(response.notificationTriggerUsersSummary),
    notificationTriggerActions.notificationTriggerUsersSummaryRequestFailure);

export const notificationTriggerEpics = combineEpics(
    notificationTriggerRequestEpic,
    notificationTriggerUpsertRequestEpic,
    notificationTriggerUsersSummaryRequestEpic
);
