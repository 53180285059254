import React, { Component } from 'react';
import UserContainer from '../../../containers/UserContainer'

export interface IGroupUser {
    groupId: number;
    userId: number;
}

export default class GroupUser extends Component<any, any> {
    render() {
        return (
            <UserContainer groupId={this.props.groupId} userId={this.props.userId} />
        );
    }
}