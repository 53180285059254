import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardContainer from '../common/CardContainer';
import Fab from '@material-ui/core/Fab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardLabel from '../common/CardLabel';
import MagnifyingIcon from '../../resources/MagnifyingIcon';

export interface IFilterDisplayProps {
    text: string;
    onClick: Function;
}

class FilterDisplay extends React.Component<IFilterDisplayProps, any> {

    constructor(props: IFilterDisplayProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }


    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <CardContainer style={{ position: 'relative' }} title={"Search"}>
                <CardContent onClick={this.onClick}>
                    <CardLabel text={this.props.text || ""} />
                    <Fab color={'inherit'}
                        style={{ position: 'absolute', top: '2px', right: '2px' }}
                        size="small"
                        onClick={this.onClick} >
                        <div style={{ width: '24px', height: '24px' }}>
                            <MagnifyingIcon style={{ color: '#000' }} />
                        </div>
                    </Fab>
                </CardContent>
            </CardContainer>
        );

    }
}


export default withStyles(styles, { withTheme: true })(FilterDisplay);
