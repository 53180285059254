import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentFlagTypeNotificationServiceApi } from '../services/incidentFlagTypeNotification';

import {
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST,
    incidentFlagTypeNotificationsRequest,
    incidentFlagTypeNotificationsRequestSuccess,
    incidentFlagTypeNotificationsRequestFailure,
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST,
    incidentFlagTypeNotificationRequestSuccess,
    incidentFlagTypeNotificationRequestFailure,
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST,
    incidentFlagTypeNotificationUpsertRequestSuccess,
    incidentFlagTypeNotificationUpsertRequestFailure,
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS,
    incidentFlagTypeNotificationReset,
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH,
    incidentFlagTypeNotificationsRefresh
} from '../actions/incidentFlagTypeNotification';
import {
    notifyError
} from './common';

const incidentFlagTypeNotificationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => incidentFlagTypeNotificationsRequestSuccess(response.incidentFlagTypeNotifications, response.paging, response.filter)),
                    catchError(error => notifyError(error, "incidentFlagTypeNotificationsRequestEpic.find", incidentFlagTypeNotificationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentFlagTypeNotificationsRequestEpic", incidentFlagTypeNotificationsRequestFailure))
    );

const incidentFlagTypeNotificationsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentFlagTypeNotification.incidentFlagTypeNotifications.paging.page,
                state.incidentFlagTypeNotification.incidentFlagTypeNotifications.paging.pageSize,
                state.incidentFlagTypeNotification.incidentFlagTypeNotifications.filter)
                .pipe(
                    map(response => incidentFlagTypeNotificationsRequestSuccess(response.incidentFlagTypeNotifications, response.paging, response.filter)),
                    catchError(error => notifyError(error, "incidentFlagTypeNotificationsRefreshEpic.find", incidentFlagTypeNotificationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentFlagTypeNotificationsRefreshEpic", incidentFlagTypeNotificationsRequestFailure))
    );

const incidentFlagTypeNotificationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeNotificationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentFlagTypeId'])
                .pipe(
                    map(response => incidentFlagTypeNotificationRequestSuccess(response.incidentFlagTypeNotification)),
                    catchError(error => notifyError(error, "incidentFlagTypeNotificationRequestEpic.get", incidentFlagTypeNotificationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentFlagTypeNotificationRequestEpic", incidentFlagTypeNotificationRequestFailure))
    );

const incidentFlagTypeNotificationUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeNotificationServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentFlagTypeNotification.incidentFlagTypeNotification.incidentFlagTypeNotification)
                .pipe(
                    map(response => incidentFlagTypeNotificationUpsertRequestSuccess(response.incidentFlagTypeNotification)),
                    catchError(error => notifyError(error, "incidentFlagTypeNotificationUpsertEpic.upsert", incidentFlagTypeNotificationUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentFlagTypeNotificationUpsertEpic", incidentFlagTypeNotificationUpsertRequestFailure))
    );

const incidentFlagTypeNotificationUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => incidentFlagTypeNotificationReset())
    );

const incidentFlagTypeNotificationUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => incidentFlagTypeNotificationsRefresh())
    );

export const incidentFlagTypeNotificationEpics = combineEpics(
    incidentFlagTypeNotificationsRefreshEpic,
    incidentFlagTypeNotificationsRequestEpic,
    incidentFlagTypeNotificationRequestEpic,
    incidentFlagTypeNotificationUpsertEpic,
    incidentFlagTypeNotificationUpsertSuccessEpic,
    incidentFlagTypeNotificationUpsertSuccessRefreshEpic
);