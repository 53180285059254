import React, { Component, forwardRef } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Progress from '../../controls/Progress';
import { withRouter } from "react-router";
import PageTitle from '../../common/PageTitle';
import ButtonLink from '../../controls/ButtonLink';
import RegisterUserTo from './RegisterUserTo';
import RegisterUserAs from './RegisterUserAs';
import RegisterUserPassword from './RegisterUserPassword';
import RegisterUserAddress from './RegisterUserAddress';
import RegisterUserAgree from './RegisterUserAgree';
import RegisterUserVenues from './RegisterUserVenues';
import RegisterUserSecurityFirms from './RegisterUserSecurityFirms';
import RegisterUserDetails from './RegisterUserDetails';
import RegisterUserNotifications from './RegisterUserNotifications';
import RegisterUserLicense from './RegisterUserLicense';
import RegisterUserRSANumber from './RegisterUserRSANumber';
import RegisterUserEmergencyContact from './RegisterUserEmergencyContact';
import RegisterUserNavigation from './RegisterUserNavigation';
import RegisterUserAvatar from './RegisterUserAvatar';
import Information from '../../common/Information';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Error from '../../common/Error';
import { Link } from 'react-router-dom';
import { ProgressItem } from '../../controls/Progress';

import PageLayout from '../../layout/PageLayout';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IRegisterUserProps {
    isLoggedIn: boolean;
    isLoading: boolean;
    isSaving: boolean;
    isCheckingSled: boolean;
    error: string;
    errors: any[];
    user: AusComplyDTOs.User;
    isLicensedVenue: boolean;
    avatar: AusComplyDTOs.File;
    venues: AusComplyDTOs.VenueSelection[],
    securityFirms: AusComplyDTOs.SecurityFirmSelection[],
    venueIds: number[],
    securityFirmIds: number[],
    states: AusComplyDTOs.State[],
    genders: AusComplyDTOs.Gender[],
    otherDocuments: AusComplyDTOs.File[],
    otherDocumentTypes: AusComplyDTOs.DocumentType[],
    complianceDocuments: AusComplyDTOs.File[],
    complianceDocumentTypes: AusComplyDTOs.DocumentType[],
    onReset: Function;
    onInitUser: Function;
    onSave: Function;
    onAttemptLogin: Function;
    onUpdate: Function;
    onUpdateOtherDocuments: Function;
    onUpdateComplianceDocuments: Function;
    onUpdateAvatar: Function;
    onCheckLicense: Function;
    onErrorNotification: Function;
    onRegisterUserPostSave: Function;
    step: number;
    onSetStep: Function;
    history: any;
    location: any;
}

interface IRegisterUserState {
    canSubmit: boolean;
    validSubmit: boolean;
    postSaveAction: string;
}

const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/register/user/privacypolicy" {...props} />);

class RegisterUser extends Component<IRegisterUserProps, IRegisterUserState> {

    constructor(props: IRegisterUserProps) {
        super(props)

        this.state = {
            canSubmit: true,
            validSubmit: true,
            postSaveAction: ""
        }

        this.onBack = this.onBack.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onStep = this.onStep.bind(this);
        this.onUpdateVenueIds = this.onUpdateVenueIds.bind(this);
        this.onUpdateSecurityFirmIds = this.onUpdateSecurityFirmIds.bind(this);
        this.canNext = this.canNext.bind(this);
        this.canBack = this.canBack.bind(this);
        this.onUpdateOtherDocuments = this.onUpdateOtherDocuments.bind(this);
        this.onUpdateComplianceDocuments = this.onUpdateComplianceDocuments.bind(this);
        this.onUpdateAvatar = this.onUpdateAvatar.bind(this);
        this.pageHasError = this.pageHasError.bind(this);
    }

    componentDidMount() {
        if (this.props.user.userId == -1) {
            this.props.onInitUser();
        }

        // Read the query parameters for the 
        let postSaveAction = "";
        if (window.location.search) {
            postSaveAction = window.location.search.slice(1).split("&")[0].split("=")[1];
        }
        this.props.onRegisterUserPostSave(postSaveAction);
        this.setState({ postSaveAction });
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                if (this.props.isLoggedIn) {
                    this.props.history.push('/users');
                } else {
                    this.onLoginOnSuccess();
                }
            }
        }

        // eslint-disable-next-line

        if (this.props.isLoading && !this.props.user.userId != preProps.user.userId) {
            // set the step to 1
        }
    }

    onBack() {
        if (this.canBack()) {
            this.props.onSetStep(this.props.step - 1);
        }
    }

    onNext() {
        if (this.canNext()) {
            if(this.props.step == 2) {
                if (this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security && this.props.user.sledEnabled) {
                    if (!this.props.user.sledChecked && this.props.user.stateLicensedId == 2) {
                        this.props.onCheckLicense(this.props.user);
                    }
                }
            }
            
            this.props.onSetStep(this.props.step + 1);
        }
    }

    canNext() {
        let canNext = false;
        if (this.state.postSaveAction != "") {
            canNext = true;
        }
        if (this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Venue) {
            canNext = this.props.venueIds.length > 0;
        }
        if (this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security) {
            canNext = this.props.securityFirmIds.length > 0;
        }
        if (this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.None) {
            canNext = true;
        }
        return canNext;
    }

    canBack() {
        let canBack = !this.props.isCheckingSled;
        return canBack;
    }

    onStep(progressItem: ProgressItem) {
        
        if (this.canNext() && this.canBack()) {
            if(this.props.step == 2) {
                if (this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security && this.props.user.sledEnabled) {
                    if (!this.props.user.sledChecked && this.props.user.stateLicensedId == 2) {
                        this.props.onCheckLicense(this.props.user);
                    }
                }
            }

            this.props.onSetStep(progressItem.step);
        }
    }

    onLoginOnSuccess() {
        let userName = this.props.user.email;
        let password = this.props.user.newPassword;
        // cancel and navigate to home
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.props.onAttemptLogin(userName, password);
        this.props.history.push('/');
    }

    onCancel() {
        // cancel and navigate to home
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.props.history.push('/');
    }

    onSubmit() {
        this.props.onSave();
    }

    onUpdateVenueIds(venueIds) {
        this.props.onUpdate(this.props.user, venueIds, this.props.securityFirmIds);
    }

    onUpdateSecurityFirmIds(securityFirmIds) {
        this.props.onUpdate(this.props.user, this.props.venueIds, securityFirmIds);
    }

    onUpdate(user) {
        this.props.onUpdate(user, this.props.venueIds, this.props.securityFirmIds);
    }

    onUpdateOtherDocuments(otherDocuments) {
        this.props.onUpdateOtherDocuments(otherDocuments);
    }

    onUpdateComplianceDocuments(complianceDocuments) {
        this.props.onUpdateComplianceDocuments(complianceDocuments);
    }

    onUpdateAvatar(value: AusComplyDTOs.File) {
        this.props.onUpdateAvatar(value);
    }

    pageHasError(pageName: string) {
        return this.props.errors && Array.isArray(this.props.errors) && this.props.errors.filter(f => f.page == pageName).length > 0;
    }

    renderPageError(pageName: string) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            var pageError = this.props.errors.find(f => f.page == pageName && f.field === "");
            if (pageError) {
                return <Error message={pageError.error} />
            }
        }
        return <></>
    }

    render() {
        let registerMessage;
        let progressItems: ProgressItem[] = [];
        if (this.state.postSaveAction == "venue") {
            registerMessage = (
                <Information message={"To register a venue you must have a user account. Complete the user registration or"} newItemLink={"login"} newItemText={"login"} newItemLinkNoBreak={true} />
            );
        }
        if (this.state.postSaveAction == "securityfirm") {
            registerMessage = (
                <Information message={"To register a security firm you must have a user account. Complete the user registration or"} newItemLink={"login"} newItemText={"login"} newItemLinkNoBreak={true} />
            );
        }

        let stepDisplays: JSX.Element[] = [];
        if (this.state.postSaveAction == "") {
            stepDisplays.push(<>
                <RegisterUserTo user={this.props.user} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} />
                {this.renderPageError("REGISTER_TO")}
                {(this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Venue) && (
                    <RegisterUserVenues venueIds={this.props.venueIds} venues={this.props.venues} onUpdate={this.onUpdateVenueIds} onSwipedLeft={this.onNext} />
                )}
                {(this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security) && (
                    <RegisterUserSecurityFirms securityFirmIds={this.props.securityFirmIds} securityFirms={this.props.securityFirms} onUpdate={this.onUpdateSecurityFirmIds} onSwipedLeft={this.onNext} />
                )}
                <ButtonLink text="Privacy Policy" small={false} component={PrivacyPolicyLink} />
            </>);
            progressItems.push({ step: progressItems.length + 1, key: "to", name: "To", isError: this.pageHasError("REGISTER_TO") });
            if (this.props.user.registerUserTo !== AusComplyDTOs.ngtRegisterUserTo.None) {
                stepDisplays.push(
                    <>
                        <RegisterUserAs user={this.props.user} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                        {this.renderPageError("REGISTER_AS")}
                        {(this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Venue && (this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.Staff || this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.RSAMarshal)) && (
                            <RegisterUserRSANumber user={this.props.user} errors={this.props.errors} isLicensedVenue={this.props.isLicensedVenue} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                        )}
                        {(this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security && (this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.RSAMarshal)) && (
                            <RegisterUserRSANumber user={this.props.user} errors={this.props.errors} isLicensedVenue={this.props.isLicensedVenue} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                        )}
                        {(this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security && (this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.Staff)) && (
                            <RegisterUserLicense user={this.props.user} errors={this.props.errors} states={this.props.states} isCheckingSled={this.props.isCheckingSled} onCheckLicense={this.props.onCheckLicense} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                        )}
                    </>
                );
                progressItems.push({ step: progressItems.length + 1, key: "as", name: "As", isError: (this.pageHasError("REGISTER_AS") || (this.props.error !== undefined && this.props.error !== null && this.props.error !== "" && this.props.error.indexOf("Licence is invalid") !== -1)) });
            }
            stepDisplays.push(<>
                {this.renderPageError("REGISTER_DETAILS")}
                <RegisterUserAvatar avatar={this.props.avatar} onUpdate={this.onUpdateAvatar} />
                <RegisterUserDetails user={this.props.user} errors={this.props.errors} genders={this.props.genders} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} isCheckingSled={this.props.isCheckingSled} />
            </>);
            progressItems.push({ step: progressItems.length + 1, key: "details", name: "Details", isError: this.pageHasError("REGISTER_DETAILS") });
        } else {
            stepDisplays.push(<>
                {registerMessage}
                {this.renderPageError("REGISTER_DETAILS")}
                <RegisterUserAvatar avatar={this.props.avatar} onUpdate={this.onUpdateAvatar} />
                <RegisterUserDetails user={this.props.user} errors={this.props.errors} genders={this.props.genders} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} isCheckingSled={this.props.isCheckingSled} />
            </>);
            progressItems.push({ step: progressItems.length + 1, key: "details", name: "Details", isError: this.pageHasError("REGISTER_DETAILS") });
        }
        stepDisplays.push(<>
            {this.renderPageError("REGISTER_ADDRESS")}
            <RegisterUserAddress states={this.props.states} user={this.props.user} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            <RegisterUserEmergencyContact user={this.props.user} onUpdate={this.onUpdate} errors={this.props.errors} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
        </>);
        progressItems.push({ step: progressItems.length + 1, key: "address", name: "Address", isError: this.pageHasError("REGISTER_ADDRESS") });
        /*
        stepDisplays.push(
            <>
                {this.renderPageError("REGISTER_DOCUMENTS")}
                <RegisterUserComplianceDocuments user={this.props.user} documents={this.props.complianceDocuments} documentTypes={this.props.complianceDocumentTypes} onUpdate={this.onUpdateComplianceDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <RegisterUserDocuments user={this.props.user} documents={this.props.otherDocuments} documentTypes={this.props.otherDocumentTypes} onUpdate={this.onUpdateOtherDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>);
        progressItems.push({ step: progressItems.length + 1, key: "documents", name: "Documents", isError: this.pageHasError("REGISTER_DOCUMENTS") });
        */
        stepDisplays.push(<>
            {this.renderPageError("REGISTER_PASSWORD")}
            <RegisterUserPassword user={this.props.user} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedRight={this.onBack} />
            <RegisterUserAgree user={this.props.user} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedRight={this.onBack} />
            <RegisterUserNotifications user={this.props.user} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedRight={this.onBack}/>
        </>);
        progressItems.push({ step: progressItems.length + 1, key: "password", name: "Password", isError: this.pageHasError("REGISTER_PASSWORD") });

        let stepDisplay = stepDisplays[this.props.step - 1];

        if (this.props.isLoading) {
            return (
                <>
                    <PageTitle title="Register User" />
                    <Loading message={"Initialising"} onCancel={this.onCancel} />
                </>
            );
        }

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title="Registering User" />
                    <Saving />
                </>
            );
        }

        return (
            <PageLayout
                headerText={"Register User"}
                noDrawer={!this.props.isLoggedIn}
                footerContent={
                    <RegisterUserNavigation
                        step={this.props.step}
                        steps={stepDisplays.length}
                        onNext={this.onNext}
                        onBack={this.onBack}
                        onCancel={this.onCancel}
                        onSubmit={this.onSubmit}
                        canSubmit={this.props.errors.length == 0}
                        canNext={this.canNext()}
                    />
                }
                aboveFooterContent={<>
                    {(this.props.step == stepDisplays.length && this.state.canSubmit) && (<>
                        <PrimaryButton text={"Register User"} onClick={this.onSubmit} />
                    </>)}
                </>}
            >
                <Progress
                    value={this.props.step}
                    items={progressItems}
                    //isError={this.props.errors.count > 0}
                    showStep={true}
                    onClick={this.onStep} />

                {this.props.error && (
                    <Error message={this.props.error} />
                )}

                {stepDisplay}
            </PageLayout>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(RegisterUser));