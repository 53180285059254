import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import PageTitle from '../common/PageTitle';
import ControlGroup from '../controls/ControlGroup';
import TextDisplay from '../controls/TextDisplay';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import Map from '../common/Map';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import DefaultFab from '../common/DefaultFab';
import { runInThisContext } from 'vm';

export interface IUserRolesSuspendProps {
    venueId?: number;
    securityFirmId?: number;
    undo?: boolean;
    isSaving: boolean;
    onSave: Function;
    onSaved?: Function;
}

export default class UserRolesSuspend extends Component<IUserRolesSuspendProps, any> {
    constructor(props: IUserRolesSuspendProps) {
        super(props)
        this.state = {
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.onSaved) {
                this.props.onSaved()
            }
        }
    }

    render() {
        var label = this.props.undo ? "Restore user roles" : "Suspend user roles";
        return (
            <DefaultFab
                onClick={() => this.props.onSave(this.props.venueId, this.props.securityFirmId, this.props.undo)}
                disabled={this.props.isSaving}
                isExtended={true}>{label}</DefaultFab>
        );
    }
}