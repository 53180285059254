import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SecurityFirmAssociations from '../components/venue/securityFirmAssociations/SecurityFirmAssociations'
import * as venueActions from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        venueSecurityFirmId: state.venue.venueSecurityFirmAssociation.venueSecurityFirm.venueSecurityFirmId,
        paging: state.venue.venueSecurityFirmAssociations.paging,
        isLoading: state.venue.venueSecurityFirmAssociations.isLoading,
        VenueSecurityFirmSummaries: state.venue.venueSecurityFirmAssociations.venueSecurityFirmAssociations,
        canRemove: permissionsLogic.hasPermissionForState(state, "RemoveAssocSecurityFirmAndVenue"),
        canCreate: permissionsLogic.hasPermissionForState(state, "AddAssocSecurityFirmAndVenue"),
        canRestore: permissionsLogic.isLevelFourVenueRoleOrSix(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId, pageSize, page) => {
            dispatch(venueActions.venueSecurityAssociationsRequest(venueId, page, pageSize))
        },
        onCreate: (venueId) => {
            dispatch(venueActions.venueSecurityAssociationCreateRequest(venueId))
        },
        onReset: () => {
            dispatch(venueActions.venueSecurityAssociationReset())
        },
        onRemove: (venueSecurityFirmId) => {
            dispatch(venueActions.venueSecurityAssociationRemoveRequest(venueSecurityFirmId))
        },
        onRestore: (venueSecurityFirmId) => {
            dispatch(venueActions.venueSecurityAssociationRestoreRequest(venueSecurityFirmId))
        },
        
    }
}

const VenueSecurityFirmAssociationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmAssociations))

export default VenueSecurityFirmAssociationsContainer