
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const venueLocationLogic = {

    getCommands(venueLocation: AusComplyDtos.VenueLocation) {
        var commands: KeyName[] = [];
        if (venueLocation.editAllowed) {
            commands.push({ key: "edit", name: "Edit" });
        }
        if (venueLocation.deleteAllowed) {
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}