import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import MoreCommands from '../../common/MoreCommands';

export interface IIndustryCategoryCardProps {
    industryCategory: AusComplyDtos.IndustryCategory;
    onEdit?: Function;
    onUpdate?: Function;
    classes: any;
    theme: any;
}

class IndustryCategoryCard extends React.Component<IIndustryCategoryCardProps, any> {
    constructor(props: IIndustryCategoryCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.industryCategory);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let industryCategory = { ...this.props.industryCategory };
                    industryCategory.obsolete = true;
                    this.props.onUpdate(industryCategory);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let industryCategory = { ...this.props.industryCategory };
                    industryCategory.obsolete = false;
                    this.props.onUpdate(industryCategory);
                }
                break;
        }
    }

    render() {
        if (!this.props.industryCategory) {
            return (<></>);
        }

        const { classes } = this.props;
        var commands: KeyName[] = [];
        let className = classes.cardIncidentPending;
        if (this.props.industryCategory.obsolete && this.props.onUpdate) {
            className = classes.cardIncidentRejected;
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        var types = this.props.industryCategory.incidentCategoryTypesDisplay;
        if (types && types.length > 100) {
            types = types.substring(0, 100) + "... (" + this.props.industryCategory.incidentCategoryTypeSelections.filter(f => f.selected).length.toString() + ")";
        }

        return (
            <Card style={{ marginTop: '6px', marginBottom: '6px', position: 'relative' }} className={className}>
                <CardContent>
                    <Box p={0} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}>
                            <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                                <Grid item xs={9}>
                                    <CardTitle>Name</CardTitle>
                                    <CardTypography>{this.props.industryCategory.name}</CardTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <CardTitle style={{ textAlign: 'right' }}>Rank</CardTitle>
                                    <CardTypography style={{ fontStyle: 'italic', color: '#aaa', textAlign: 'right', paddingRight: '10px' }}>{this.props.industryCategory.rank}</CardTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                                <Grid item xs={12}>
                                    <CardTitle>Incident Types</CardTitle>
                                    <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{types}</CardTypography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={0}>
                            {(commands.length > 0) && (
                                <MoreCommands
                                    commands={commands}
                                    onCommand={this.onCommand}
                                    style={{ marginLeft: '20px' }} />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IndustryCategoryCard);