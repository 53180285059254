import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const userComplianceDocumentServiceApi = {
  getExpiringSummary: (venueId: number, securityFirmId: number, page: number, pageSize: number, filter: AusComplyDtos.UserComplianceDocumentFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocumentsExpiring();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  getMissingSummary: (venueId: number, securityFirmId: number,  page: number, pageSize: number, filter: AusComplyDtos.UserComplianceDocumentFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocumentsMissing();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  exportExpiring: (venueId: number, securityFirmId: number, filter: AusComplyDtos.UserComplianceDocumentFilter) => {
      var client = new AusComplyJsonServiceClient();
      var parameters = new AusComplyDtos.AnyUserComplianceDocumentsExpiringExport();
      parameters.venueId = venueId;
      parameters.securityFirmId = securityFirmId;
      parameters.filter = filter;
      const request = client.post(parameters);
      return from(request);
  },
  exportMissing: (venueId: number, securityFirmId: number, filter: AusComplyDtos.UserComplianceDocumentFilter) => {
      var client = new AusComplyJsonServiceClient();
      var parameters = new AusComplyDtos.AnyUserComplianceDocumentsMissingExport();
      parameters.venueId = venueId;
      parameters.securityFirmId = securityFirmId;
      parameters.filter = filter;
      const request = client.post(parameters);
      return from(request);
  }
};
