import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from "../../controls/ControlGroup";
import LayoutForm from '../../layout/LayoutForm';
import Information from '../../common/Information';

export interface IRegisterUserNotificationsProps {
    user: AusComplyDTOs.User;
    errors: any[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserNotifications extends Component<IRegisterUserNotificationsProps, any> {
    constructor(props: IRegisterUserNotificationsProps) {
        super(props)
        this.state = {

        }
    }


    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <LayoutForm>
                    <ControlGroup text={" "}>
                        <Information
                            message={"AusComply sends relevant incident and checklist notifications by SMS and email. You can opt-out of notifications from the My Profile page."}
                         />
                    </ControlGroup>
                </LayoutForm>
            </SwipeContainer>
        );
    }
}