import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import TableRow from '../common/TableRow';

export interface ICheckListTemplateSummaryRowProps {
    classes: any;
    theme: any;
    checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary;
    onClick?: Function;
}

class CheckListTemplateSummaryRow extends React.Component<ICheckListTemplateSummaryRowProps, any> {
    constructor(props: ICheckListTemplateSummaryRowProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        let item = this.props.checklistTemplateSummary;
        return (
            <TableRow isSuccess={item.isGlobal} isInfo={this.props.checklistTemplateSummary.isGroup} onClick={this.onClick} key={"row-" + item.checklistTemplateId.toString()}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td>
                    <p style={{ textAlign: 'center' }}>
                        {item.itemCount}
                    </p>
                </td>
                <td>
                    <p style={{ textAlign: 'center' }}>
                        {item.instanceCount}
                    </p>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListTemplateSummaryRow);