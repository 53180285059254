import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IMonthSelectControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    error?: string;
    defaultValue?: any;
    readonly?: boolean;
    onChange?: Function;
    labelAbove?: boolean;
    items?: any;
}
class MonthSelectControlGroup extends React.PureComponent<IMonthSelectControlGroupProps, any> {

    constructor(props: IMonthSelectControlGroupProps) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = name => event => {
        if (event != null) {
            if (this.props.onChange) {
                this.props.onChange(event.target.value);
            }
        }
    }


    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
                    <NativeSelect
                        value={this.props.defaultValue}
                        onChange={this.handleChange('name')}
                        name="name"
                        classes={{ icon: classes.backgroundColorDefault }}
                        input={<InputBase style={style}
                        />}
                    >
                        <option value={0} />
                        <option key={1} value={1}>{"January"}</option>
                        <option key={2} value={2}>{"February"}</option>
                        <option key={3} value={3}>{"March"}</option>
                        <option key={4} value={4}>{"April"}</option>
                        <option key={5} value={5}>{"May"}</option>
                        <option key={6} value={6}>{"June"}</option>
                        <option key={7} value={7}>{"July"}</option>
                        <option key={8} value={8}>{"August"}</option>
                        <option key={9} value={9}>{"September"}</option>
                        <option key={10} value={10}>{"October"}</option>
                        <option key={11} value={11}>{"November"}</option>
                        <option key={12} value={12}>{"December"}</option>
                    </NativeSelect>
                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MonthSelectControlGroup);