export const
    REGISTER_VENUE_RESET = "REGISTER_VENUE_RESET",
    REGISTER_VENUE_NEW_REQUEST = "REGISTER_VENUE_NEW_REQUEST",
    REGISTER_VENUE_NEW_REQUEST_SUCCESS = "REGISTER_VENUE_NEW_REQUEST_SUCCESS",
    REGISTER_VENUE_NEW_REQUEST_FAILURE = "REGISTER_VENUE_NEW_REQUEST_FAILURE",
    REGISTER_VENUE_SET_STEP = "REGISTER_VENUE_SET_STEP",
    REGISTER_VENUE_UPDATE = "REGISTER_VENUE_UPDATE",
    REGISTER_VENUE_UPDATE_OTHER_DOCUMENTS = "REGISTER_VENUE_UPDATE_OTHER_DOCUMENTS",
    REGISTER_VENUE_UPDATE_COMPLIANCE_DOCUMENTS = "REGISTER_VENUE_UPDATE_COMPLIANCE_DOCUMENTS",
    REGISTER_VENUE_SAVE_REQUEST = "REGISTER_VENUE_SAVE_REQUEST",
    REGISTER_VENUE_SAVE_REQUEST_SUCCESS = "REGISTER_VENUE_SAVE_REQUEST_SUCCESS",
    REGISTER_VENUE_SAVE_REQUEST_FAILURE = "REGISTER_VENUE_SAVE_REQUEST_FAILURE";

export function registerVenueReset() {
    return {
        type: REGISTER_VENUE_RESET
    };
}

export function registerVenueNew() {
    return {
        type: REGISTER_VENUE_NEW_REQUEST
    };
}

export function registerVenueNewSuccess(
    venue, 
    venueContact, 
    licenseContact, 
    states, 
    industryCategories,
    precincts, 
    licenceTypes, 
    tradingHours, 
    otherDocuments, 
    otherDocumentTypes, 
    complianceDocuments, 
    complianceDocumentTypes) {
    return {
        type: REGISTER_VENUE_NEW_REQUEST_SUCCESS,
        venue,
        venueContact,
        licenseContact,
        precincts,
        licenceTypes,
        states,
        industryCategories,
        tradingHours,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes
    };
}

export function registerVenueNewFailure(error) {
    return {
        type: REGISTER_VENUE_NEW_REQUEST_FAILURE,
        error
    };
}

export function registerVenueSetStep(step) {
    return {
        type: REGISTER_VENUE_SET_STEP,
        step
    };
}

export function registerVenueUpdate(venue, venueContact, licenseContact) {
    return {
        type: REGISTER_VENUE_UPDATE,
        venue,
        venueContact,
        licenseContact
    };
}

export function registerVenueUpdateOtherDocuments(otherDocuments) {
    return {
        type: REGISTER_VENUE_UPDATE_OTHER_DOCUMENTS,
        otherDocuments
    }
}

export function registerVenueUpdateComplianceDocuments(complianceDocuments) {
    return {
        type: REGISTER_VENUE_UPDATE_COMPLIANCE_DOCUMENTS,
        complianceDocuments
    }

}

export function registerVenueSaveRequest() {
    return {
        type: REGISTER_VENUE_SAVE_REQUEST
    };
}

export function registerVenueSaveRequestSuccess(venue) {
    return {
        type: REGISTER_VENUE_SAVE_REQUEST_SUCCESS,
        venue
    };
}

export function registerVenueSaveRequestFailure(error) {
    return {
        type: REGISTER_VENUE_SAVE_REQUEST_FAILURE,
        error
    };
}