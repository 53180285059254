import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const industryCategoryApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIndustryCategories();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  getAvailable: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIndustryCategoriesAvailable();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  get: (venueId, securityFirmId, industryCategoryId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIndustryCategory();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.industryCategoryId = industryCategoryId;
    const request = client.get(query);
    return from(request);
  },
  create: (venueId, securityFirmId, industryCategory) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIndustryCategory();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.industryCategory = industryCategory;
    const request = client.post(query);
    return from(request);
  },
  update: (venueId, securityFirmId, industryCategory) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIndustryCategoryUpdate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.industryCategoryId = industryCategory.industryCategoryId;
    query.industryCategory = industryCategory;
    const request = client.post(query);
    return from(request);
  }
};
