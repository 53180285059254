import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface IClearButtonProps {
    classes: any;
    className?: any;
    text?: string;
    onClick?: Function;
    style?: any;
    autoFocus?: boolean;
    component?: any;
    to?: any;
}

class ClearButton extends React.PureComponent<IClearButtonProps, any> {
    constructor(props: IClearButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;
        if(this.props.component){
            return (
                <Button
                    variant="contained"
                    onClick={this.onClick}
                    color="default"
                    component={this.props.component}
                    className={classNames(classes.buttonClear, classes.buttonState, this.props.className)}
                    style={{ width: '100%', height: '100%', ...this.props.style }}>{this.props.text}{this.props.children}</Button>
            );
        }
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                autoFocus={this.props.autoFocus}
                className={classNames(classes.buttonClear, classes.buttonState, this.props.className)}
                style={{ width: '100%', height: '100%', ...this.props.style }}>{this.props.text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ClearButton);