import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import PageTitle from '../common/PageTitle';
import ControlGroup from '../controls/ControlGroup';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import RegisterSecurityFirmDetails from '../register/securityFirm/RegisterSecurityFirmDetails';
import RegisterSecurityFirmAddress from '../register/securityFirm/RegisterSecurityFirmAddress';
import DefaultButton from '../controls/DefaultButton';
import TransparentButton from '../controls/TransparentButton';
import Grid from '@material-ui/core/Grid';

export interface ISecurityFirmEditProps {
    securityFirmId: number;
    isLoading: boolean;
    isSaving: boolean;
    canEdit: boolean;
    canEditMasterLicence: boolean;
    securityFirm: AusComplyDtos.SecurityFirm;
    states: AusComplyDtos.State[],
    securityLicenceClasses: AusComplyDtos.SecurityLicenceClass[],
    error: string;
    errors: any;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class SecurityFirmEdit extends Component<ISecurityFirmEditProps, any> {
    constructor(props: ISecurityFirmEditProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.securityFirmId);
                this.props.history.push('/securityFirm');
            }
        }
    }
    
    onValueChanged(fieldName: string, value: any) {
        let venue = { ...this.props.securityFirm };
        venue[fieldName] = value;
        this.props.onUpdate(venue);
    }

    onUpdate(securityFirm) {
        this.props.onUpdate(securityFirm);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onLoad(this.props.securityFirmId);
        this.props.history.push('/securityFirm');
    }

    render() {
        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    fullWidth={true}
                    header={<PageTitle title={"Edit Security Firm"}></PageTitle>}
                >
                    <form>
                        <RegisterSecurityFirmDetails 
                            securityFirm={this.props.securityFirm} 
                            states={this.props.states} 
                            errors={this.props.errors} 
                            onUpdate={this.onUpdate} 
                            securityLicenceClasses={this.props.securityLicenceClasses}
                            canEditMasterLicence={this.props.canEditMasterLicence}
                             />
                        <RegisterSecurityFirmAddress securityFirm={this.props.securityFirm} states={this.props.states} errors={this.props.errors} onUpdate={this.onUpdate}  />
                        
                        
                        <ControlGroup text={" "}>
                            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <TransparentButton text={"Cancel"} onClick={this.onCancel} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</DefaultButton>
                                </Grid>
                            </Grid>
                            
                        </ControlGroup>
                    </form>
                </LayoutForm>
                <VenueQuickActionsContainer>
                </VenueQuickActionsContainer>
            </>
        );
    }
}