import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import Tabs from '../../common/Tabs';
import DefaultFab from '../../common/DefaultFab';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import FacialRecognitionNotificationFilterSummary from './FacialRecognitionNotificationFilterSummary';
import FacialRecognitionNotificationFilter from './FacialRecognitionNotificationFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import FacialRecognitionNotificationCard from './FacialRecognitionNotificationCard';
import FacialRecognitionNotificationHeader from './FacialRecognitionNotificationHeader';
import FacialRecognitionNotificationRow from './FacialRecognitionNotificationRow';
//import FacialRecognitionNotification from './FacialRecognitionNotification';
import AdminFacialRecognitionCamerasContainer from '../../../containers/AdminFacialRecognitionCamerasContainer';
import AdminFacialRecognitionAutomaticEnrolmentsContainer from '../../../containers/AdminFacialRecognitionAutomaticEnrolmentsContainer';
import FacialRecognitionWatchlistsContainer from '../../../containers/FacialRecognitionWatchlistsContainer';
import FacialRecognitionEventsContainer from '../../../containers/FacialRecognitionEventsContainer';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import VenueFacialRecognitionNotificationsTabsContainer from '../../../containers/VenueFacialRecognitionNotificationsTabsContainer';

export interface IFacialRecognitionNotificationsProps {
    venueId: number;
    isLoading: boolean;
    facialRecognitionNotifications: AusComplyDtos.FacialRecognitionNotification[];
    facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.FacialRecognitionNotificationFilter;
    error: string;
    isLoadingItem: boolean;
    onLoadItem: Function;
    onSetItem: Function;
    onLoadFilter: Function;
    onSetFilter: Function;
    onSetPaging: Function;
    onProcessItem: Function;
    canManageCameras: boolean;
    canManageWatchlists: boolean;
    canManageEvents: boolean;
    canManageEnrolments: boolean;
    classes: any;
    theme: any;
    history: any;
    location: any;
}

interface IFacialRecognitionNotificationsState {
    selectedTab: number;
    filterExpanded: boolean;
}

class FacialRecognitionNotifications extends Component<IFacialRecognitionNotificationsProps, IFacialRecognitionNotificationsState> {
    constructor(props: IFacialRecognitionNotificationsProps) {
        super(props)

        this.state = {
            selectedTab: 0,
            filterExpanded: false
        };
        this.tabs = this.tabs.bind(this);
        this.selectedTabDisplay = this.selectedTabDisplay.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onClickIncident = this.onClickIncident.bind(this);
        this.onProcess = this.onProcess.bind(this);
    }

    componentDidMount() {
        this.props.onLoadFilter();
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        let paging = { ...this.props.paging };
        paging.page = page;
        this.props.onSetPaging(paging);
    }

    onSearch(filter: AusComplyDtos.FacialRecognitionNotificationFilter) {
        var searchFilter = { ...filter };
        searchFilter.venueId = this.props.venueId;
        this.props.onSetFilter(searchFilter);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoadFilter();
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onEdit(facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification) {
        this.props.onLoadItem(facialRecognitionNotification.facialRecognitionNotificationId);
    }

    onProcess(facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification) {
        this.props.onProcessItem(facialRecognitionNotification.facialRecognitionNotificationId);
    }

    onCancelEdit() {
        let facialRecognitionNotification = new AusComplyDtos.FacialRecognitionNotification();
        facialRecognitionNotification.facialRecognitionNotificationId = -1;
        this.props.onSetItem(facialRecognitionNotification);
    }

    onUpdateFilter(filter: AusComplyDtos.FacialRecognitionNotificationFilter) {

    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onClickIncident(incidentId) {
        this.props.history.push('/incidentview/' + incidentId);
    }

    tabs() {
        let tabs: string[] = [];
        // the tabs are dymanic based on permissions
        tabs.push('Notifications');
        if (this.props.canManageCameras) {
            tabs.push('Cameras');
        }
        if (this.props.canManageWatchlists) {
            tabs.push('Watchlists');
        }
        if (this.props.canManageEvents) {
            tabs.push('Events');
        }
        if (this.props.canManageEnrolments) {
            tabs.push('Enrol');
        }
        return tabs;
    }

    selectedTabDisplay(tabs: string[], display: string) {
        if (tabs.length == 0 && display == "Details") return true;
        return tabs[this.state.selectedTab] == display;
    }

    render() {
        const tabs = this.tabs();
        let cardList;
        let rows;
        let rowList;

        if (this.props.facialRecognitionNotifications &&
            this.props.facialRecognitionNotifications.length > 0 &&
            this.props.filter.venueId > 0) {
            cardList = this.props.facialRecognitionNotifications.map((item, index) => {
                return (
                    <FacialRecognitionNotificationCard
                        onEdit={this.onEdit}
                        onProcess={this.onProcess}
                        key={"card-" + item.facialRecognitionNotificationId.toString()}
                        facialRecognitionNotification={item}
                    />
                );
            });

            rows = this.props.facialRecognitionNotifications.map((item, index) => {
                return (
                    <FacialRecognitionNotificationRow
                        onEdit={this.onEdit}
                        onProcess={this.onProcess}
                        key={"row-" + item.facialRecognitionNotificationId.toString()}
                        facialRecognitionNotification={item} />
                );
            });
        }

        let filter;
        let filterSummary;

        if (this.state.filterExpanded) {
            filter = <FacialRecognitionNotificationFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let facialRecognitionNotificationFilter = new AusComplyDtos.FacialRecognitionNotificationFilter();
            facialRecognitionNotificationFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <FacialRecognitionNotificationFilterSummary filter={facialRecognitionNotificationFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <FacialRecognitionNotificationFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        rowList = (
            <TableContainer
                header={<FacialRecognitionNotificationHeader />}
                rows={rows}
            />
        );

        let recievedNotification = this.props.facialRecognitionNotification.recievedNotification;
        let facialRecognitionNotification;
        if (this.props.facialRecognitionNotification && this.props.facialRecognitionNotification.facialRecognitionNotificationId > -1) {
            facialRecognitionNotification = this.props.isLoadingItem ? (<Loading />) : (
                <LayoutForm>
                    <ControlGroup text="Created At">
                        <TextDisplay>{this.props.facialRecognitionNotification.dateEntered}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Event ID">
                        <TextDisplay>{this.props.facialRecognitionNotification.recievedNotification.event_id}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Event Type">
                        <TextDisplay>{this.props.facialRecognitionNotification.recievedNotification.event_type}</TextDisplay>
                    </ControlGroup>
                    {(recievedNotification && recievedNotification.camera_data) && (
                        <>
                            <SubTitle text={"Camera Data"} />
                            <ControlGroup text="Camera Description">
                                <TextDisplay>{recievedNotification.camera_data.camera_description}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Camera ID">
                                <TextDisplay>{recievedNotification.camera_data.camera_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Stream ID">
                                <TextDisplay>{recievedNotification.camera_data.stream_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.frame_data) && (
                        <>
                            <SubTitle text={"Frame Data"} />
                            <ControlGroup text="UTC Time Recorded">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_recorded}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Zone">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_zone}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Frame ID">
                                <TextDisplay>{recievedNotification.frame_data.frame_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Bounded Box">
                                {recievedNotification.frame_data.bounding_box && (
                                    <TextDisplay>x1: {recievedNotification.frame_data.bounding_box.x1} y1: {recievedNotification.frame_data.bounding_box.y1} x2: {recievedNotification.frame_data.bounding_box.x2} y2: {recievedNotification.frame_data.bounding_box.y2}</TextDisplay>
                                )}
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.appearance_data) && (
                        <>
                            <SubTitle text={"Appearance Data"} />
                            <ControlGroup text="Appearance ID">
                                <TextDisplay>{recievedNotification.appearance_data.appearance_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Started">
                                <TextDisplay>{recievedNotification.appearance_data.utc_time_started}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="First frame ID">
                                <TextDisplay>{recievedNotification.appearance_data.first_frame_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.crop_data) && (
                        <>
                            <SubTitle text={"Crop Data"} />
                            <ControlGroup text="Face Crop Image">
                                {(recievedNotification.crop_data.face_crop_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.crop_data.face_crop_img}`} alt="photo" />
                                )}
                            </ControlGroup>
                            <ControlGroup text="Face Score">
                                <TextDisplay>{recievedNotification.crop_data.face_score}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Pitch">
                                <TextDisplay>{recievedNotification.crop_data.pitch}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Yaw">
                                <TextDisplay>{recievedNotification.crop_data.yaw}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Masked Score">
                                <TextDisplay>{recievedNotification.crop_data.masked_score}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.face_features_data) && (
                        <>
                            <SubTitle text={"Face Features Data"} />
                            <ControlGroup text="Mask Outcome">
                                <TextDisplay>{recievedNotification.face_features_data.mask_outcome}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Age Group Outcome">
                                <TextDisplay>{recievedNotification.face_features_data.age_group_outcome}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data) && (
                        <>
                            <SubTitle text={"Match Data"} />
                            <ControlGroup text="UTC Time matched">
                                <TextDisplay>{recievedNotification.match_data.utc_time_matched}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI ID">
                                <TextDisplay>{recievedNotification.match_data.poi_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Coonfidence">
                                <TextDisplay>{recievedNotification.match_data.poi_confidence}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Display Image">
                                {(recievedNotification.match_data.poi_display_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.match_data.poi_display_img}`} alt="photo" />
                                )}
                                {(!recievedNotification.match_data.poi_display_img) && (
                                    <TextDisplay>Empty</TextDisplay>
                                )}
                            </ControlGroup>
                            <ControlGroup text="POI Display Name">
                                <TextDisplay>{recievedNotification.match_data.poi_display_name}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data && recievedNotification.match_data.watchlists) && (
                        <>
                            <SubTitle text={"Watchlists"} />
                            {recievedNotification.match_data.watchlists.map((item, index) => (<View key={"watchlist-" + item.watchlist_id}>
                                <ControlGroup text="Watchlist ID">
                                    <TextDisplay>{item.watchlist_id}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Watchlist Type">
                                    <TextDisplay>{item.watchlist_type}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="match Outcome">
                                    <TextDisplay>{item.match_outcome}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Name">
                                    <TextDisplay>{item.display_name}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Colour">
                                    <TextDisplay style={{ color: item.display_color }}>{item.display_color}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text=" ">
                                    <hr />
                                </ControlGroup>
                            </View>))}
                        </>
                    )}

                    <ControlGroup text="Created Incidents">
                        <TextDisplay>
                            {this.props.facialRecognitionNotification.incidents && this.props.facialRecognitionNotification.incidents.map((item, index) => (
                                <span key={index}>{index > 0 && (<br />)}<a href="#" onClick={e => { this.onClickIncident(item.incidentId); e.preventDefault(); }} style={{ color: '#03a9f4', textDecoration: 'underline' }}>{item.incidentSerialNumber}</a></span>
                            ))}
                        </TextDisplay>
                    </ControlGroup>


                    <TextAreaControlGroup text={"Raw Json Data"} defaultValue={this.props.facialRecognitionNotification.jsonString} />
                </LayoutForm>
            );
        }

        const showConfig = this.props.canManageCameras || this.props.canManageWatchlists || this.props.canManageEvents || this.props.canManageEnrolments;

        let title = "Facial Recognition Notifications";
        let tabsDisplay;
        let tabContent;
        if (showConfig) {
            title = "Facial Recognition";
            tabsDisplay = (
                <View style={{ marginTop: '10px' }}>
                    <Tabs selected={this.state.selectedTab} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
                </View>
            );
            if (this.selectedTabDisplay(tabs, "Notifications")) {
                tabContent = <>
                    {filterSummary}
                    <PagedList
                        loading={this.props.isLoading}
                        paging={this.props.paging}
                        cards={cardList}
                        rows={rowList}
                        onPageSelected={i => this.handlePageSelected(i)}
                    />
                </>;
            }
            /*if (this.selectedTabDisplay(tabs, "Cameras")) {
                tabContent = <>
                    <AdminFacialRecognitionCamerasContainer venueId={this.props.venueId} />
                </>;
            }
            if (this.selectedTabDisplay(tabs, "Watchlists")) {
                tabContent = <>
                    <FacialRecognitionWatchlistsContainer venueId={this.props.venueId} groupId={0} />
                </>;
            }
            if (this.selectedTabDisplay(tabs, "Events")) {
                tabContent = <>
                    <FacialRecognitionEventsContainer venueId={this.props.venueId} />
                </>;
            }
            if (this.selectedTabDisplay(tabs, "Enrol")) {
                tabContent = <>
                    <AdminFacialRecognitionAutomaticEnrolmentsContainer venueId={this.props.venueId} />
                </>;
            }*/
        } else {
            tabContent = (
                <>
                    {filterSummary}
                    <PagedList
                        loading={this.props.isLoading}
                        paging={this.props.paging}
                        cards={cardList}
                        rows={rowList}
                        onPageSelected={i => this.handlePageSelected(i)}
                    />
                </>
            );
        }
        return (
            <PageLayout
                headerText={title}
            >
                <VenueFacialRecognitionNotificationsTabsContainer selectedTab={"Notifications"} history={this.props.history} />
                {tabContent}
                <FullPageDialog open={this.state.filterExpanded} notFullWidth={true} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog open={this.props.facialRecognitionNotification.facialRecognitionNotificationId != -1} title="Facial Recognition" onDismissed={() => this.onCancelEdit()}>
                    {facialRecognitionNotification}
                </FullPageDialog>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionNotifications);