import { connect } from 'react-redux'
import MobileVerifyCode from '../components/home/MobileVerifyCode'
import * as profileActions from '../common/actions/profile';

const mapStateToProps = state => {
    return {
        showSmsCodeVerification: state.user.details.showSmsCodeVerification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSave: (code) => {
            dispatch(profileActions.profileVerificationSmsCodeRequest(code))
        },
        onResend: () => {
            dispatch(profileActions.profileSendVerificationSmsRequest())
        },
    }
}

const MobileVerifyCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileVerifyCode)

export default MobileVerifyCodeContainer