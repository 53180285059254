import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { RadioOption } from '../../../common/dto/common';
import SubTitle from '../../common/SubTitle';
import Loading from '../../common/Loading';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import LocalDateTimeControlGroup from '../../controls/LocalDateTimeControlGroup';
import LocalDateControlGroup from '../../controls/LocalDateControlGroup';
import MonthSelectControlGroup from '../../controls/MonthSelectControlGroup';
import MonthDaySelectControlGroup from '../../controls/MonthDaySelectControlGroup';
import WeekDaySelectControlGroup from '../../controls/WeekDaySelectControlGroup';
import HourMinuteSelectControlGroup from '../../controls/HourMinuteSelectControlGroup';
import HourMinuteControlGroup from '../../controls/HourMinuteControlGroup';
import RadioControlGroup from '../../controls/RadioControlGroup';
import ReportingPeriodSchedule from './ReportingPeriodSchedule';

export interface IVenueReportingPeriodScheduleProps {
    venueReportingPeriodSchedule: AusComplyDtos.VenueReportingPeriodSchedule;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IVenueReportingPeriodScheduleState {
}

export default class VenueReportingPeriodSchedule extends Component<IVenueReportingPeriodScheduleProps, IVenueReportingPeriodScheduleState> {
    constructor(props: IVenueReportingPeriodScheduleProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        /*this.onValueChanged = this.onValueChanged.bind(this);
        this.onSubValueChanged = this.onSubValueChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
        */
    }

    componentDidMount() {
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }
/*
    onValueChanged(fieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriodSchedule };
        venueReportingPeriod[fieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }

    onSubValueChanged(fieldName: string, subFieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriodSchedule };
        venueReportingPeriod[fieldName][subFieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }

    onStartTimeChanged(value: AusComplyDtos.LocalDate) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriodSchedule };
        if (value.isEmpty){
            venueReportingPeriod.startHour = 0;
            venueReportingPeriod.startMinute = 0;
        } else {
            venueReportingPeriod.startHour = value.hour;
            venueReportingPeriod.startMinute = value.minute;
        }
        this.props.onUpdate(venueReportingPeriod)
    }

    onEndTimeChanged(value: AusComplyDtos.LocalDate) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriodSchedule };
        if (value.isEmpty){
            venueReportingPeriod.endHour = 23;
            venueReportingPeriod.endMinute = 59;
        } else {
            venueReportingPeriod.endHour = value.hour;
            venueReportingPeriod.endMinute = value.minute;
        }
        this.props.onUpdate(venueReportingPeriod)
    }*/

    render() {
        let self = this;
        if (this.props.isLoading) {
            return (<Loading />);
        }

        if (!this.props.venueReportingPeriodSchedule) {
            return (<></>);
        }
/*
        let scheduleType;
        if (this.props.venueReportingPeriodSchedule.venueReportingPeriodScheduleId) {
            if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Recurring) {
                scheduleType = (<SubTitle text={"Recurring Schedule"} />);
            }
            if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Daily) {
                scheduleType = (<SubTitle text={"Daily Schedule"} />);
            }
            if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday) {
                scheduleType = (<SubTitle text={"Holiday Schedule"} />);
            }
            if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff) {
                scheduleType = (<SubTitle text={"One time schedule"} />);
            }
        } else {
            let radioOptions: RadioOption[] = [];
            radioOptions.push({ id: AusComplyDtos.ngtVenueReportingPeriodScheduleType.Recurring, name: "Recurring" });
            radioOptions.push({ id: AusComplyDtos.ngtVenueReportingPeriodScheduleType.Daily, name: "Daily" });
            radioOptions.push({ id: AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday, name: "Holiday" });
            radioOptions.push({ id: AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff, name: "One time" });
            scheduleType = (
                <RadioControlGroup
                    text={"Schedule Type"}
                    name="scheduleType"
                    selected={this.props.venueReportingPeriodSchedule.scheduleType}
                    options={radioOptions}
                    onChanged={value => this.onValueChanged("scheduleType", value.value)}
                />
            );
        }
        let content;
        if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Recurring) {
            content = (<>
                <WeekDaySelectControlGroup
                    text={"Start Day"}
                    defaultValue={this.props.venueReportingPeriodSchedule.startWeekDay}
                    onChange={value => this.onValueChanged("startWeekDay", parseInt(value))}
                />
                <HourMinuteControlGroup
                    text={"Start time"}
                    hourValue={this.props.venueReportingPeriodSchedule.startHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.startMinute}
                    onChange={(value) => this.onStartTimeChanged(value)}
                />
                <WeekDaySelectControlGroup
                    text={"End Day"}
                    defaultValue={this.props.venueReportingPeriodSchedule.endWeekDay}
                    onChange={value => this.onValueChanged("endWeekDay", parseInt(value))}
                />
                <HourMinuteControlGroup
                    text={"End time"}
                    hourValue={this.props.venueReportingPeriodSchedule.endHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.endMinute}
                    onChange={(value) => this.onEndTimeChanged(value)}
                />
                <LocalDateControlGroup
                    text="Expires"
                    date={this.props.venueReportingPeriodSchedule.expiresDate}
                    onChange={(value) => this.onValueChanged("expiresDate", value)} />
            </>);
        }
        if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Daily) {
            content = (<>
                <HourMinuteControlGroup
                    text={"Start time"}
                    hourValue={this.props.venueReportingPeriodSchedule.startHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.startMinute}
                    onChange={(value) => this.onStartTimeChanged(value)}
                />
                <HourMinuteControlGroup
                    text={"End time"}
                    hourValue={this.props.venueReportingPeriodSchedule.endHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.endMinute}
                    onChange={(value) => this.onEndTimeChanged(value)}
                />
                <LocalDateControlGroup
                    text="Expires"
                    date={this.props.venueReportingPeriodSchedule.expiresDate}
                    onChange={(value) => this.onValueChanged("expiresDate", value)} />
            </>);
        }
        if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday) {
            content = (<>
                <MonthSelectControlGroup
                    text={"Start Month"}
                    defaultValue={this.props.venueReportingPeriodSchedule.startMonth}
                    onChange={value => this.onValueChanged("startMonth", parseInt(value))}
                />
                <MonthDaySelectControlGroup
                    text={"Start Day"}
                    defaultValue={this.props.venueReportingPeriodSchedule.startDay}
                    onChange={value => this.onValueChanged("startDay", parseInt(value))}
                />
                <HourMinuteControlGroup
                    text={"Start time"}
                    hourValue={this.props.venueReportingPeriodSchedule.startHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.startMinute}
                    onChange={(value) => this.onStartTimeChanged(value)}
                />
                <MonthSelectControlGroup
                    text={"End Month"}
                    defaultValue={this.props.venueReportingPeriodSchedule.endMonth}
                    onChange={value => this.onValueChanged("endMonth", parseInt(value))}
                />
                <MonthDaySelectControlGroup
                    text={"End Day"}
                    defaultValue={this.props.venueReportingPeriodSchedule.endDay}
                    onChange={value => this.onValueChanged("endDay", parseInt(value))}
                />
                <HourMinuteControlGroup
                    text={"End time"}
                    hourValue={this.props.venueReportingPeriodSchedule.endHour}
                    minuteValue={this.props.venueReportingPeriodSchedule.endMinute}
                    onChange={(value) => this.onEndTimeChanged(value)}
                />
                <LocalDateControlGroup
                    text="Expires"
                    date={this.props.venueReportingPeriodSchedule.expiresDate}
                    onChange={(value) => this.onValueChanged("expiresDate", value)} />
            </>);
        }
        if (this.props.venueReportingPeriodSchedule.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff) {
            content = (<>
                <LocalDateTimeControlGroup
                    text="Start"
                    date={this.props.venueReportingPeriodSchedule.startDate}
                    onChange={(value) => this.onValueChanged("startDate", value)} />
                <LocalDateTimeControlGroup
                    text="End"
                    date={this.props.venueReportingPeriodSchedule.endDate}
                    onChange={(value) => this.onValueChanged("endDate", value)} />

            </>);
        }

        {scheduleType}
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.venueReportingPeriodSchedule.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />
                    {content}
                    <CheckboxControlGroup
                        text={"Security Code Required"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.venueReportingPeriodSchedule.securityCodeNotRequired}
                        onChanged={(value) => this.onValueChanged("securityCodeNotRequired", value)} />

            */
        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <ReportingPeriodSchedule
                        venueReportingPeriodSchedule={this.props.venueReportingPeriodSchedule}
                        onUpdate={value => self.props.onUpdate(value)}
                    />
                    
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}