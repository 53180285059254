import React from 'react';
import { styles } from '../../../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IVenueSecurityFirmHeaderProps {
    classes: any;
    theme: any;
}

class VenueSecurityFirmHeader extends React.Component<IVenueSecurityFirmHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr}>Security Firm</th>
                <th className={classes.tableHr}>Parent Security Firm</th>
                <th className={classes.tableHr}>Created</th>
                <th className={classes.tableHr}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenueSecurityFirmHeader);