import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Error from '../../common/Error'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import VenueSecurityCodeContainer from '../../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../../containers/SignOnOffSummaryContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../../common/SecondaryFab';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import Information from '../../common/Information';
import WelcomeContainer from '../../../containers/WelcomeContainer';
import TransparentButton from '../../controls/TransparentButton';
import HomeStatistics from '../../home/HomeStatistics';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../common/NoRowsMessage';

export interface IDashboardIncidentTotalsProps {
    isLoading: boolean;
    incidents: any[];
    venueIncidents: any[];
    venueIncidentGridLines: number;
    securityFirmIncidents: any[];
    securityFirmIncidentGridLines: number;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

class DashboardIncidentTotals extends Component<IDashboardIncidentTotalsProps, any> {
    constructor(props: IDashboardIncidentTotalsProps) {
        super(props)
        this.state = {
            hideZeros: true
        };
        this.onHideZeros = this.onHideZeros.bind(this);
    }

    componentDidMount() {
    }


    onHideZeros(hideZeros) {
        this.setState({ hideZeros });
    }

    barChartOptions(title: string, gridLinesCount: number, count: number) {

        var chartAreaHeight = count * 30;
        var chartHeight = chartAreaHeight;

        return {
            //title: title,
            legend: { position: 'none' },
            isStacked: true,
            annotations: {
                alwaysOutside: true
            },
            backgroundColor: '#2E2C2D',
            chartArea: {
                left: '40%',
                width: '55%',
                backgroundColor: '#000000',
                height: chartAreaHeight
            },
            colors: ['#818183'],
            fontSize: '14',
            height: chartHeight,
            hAxis: {
                format: '#',
                minValue: 0,
                gridlines: {
                    count: gridLinesCount,
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            titleTextStyle: {
                color: '#ffffff'
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff',
                    fontSize: '14'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            animation: {
                duration: 1200,
                easing: 'out',
                startup: true
            }
        };
    }

    renderBarChart(name: string, data: any[], gridLinesCount: number) {
        let chart;
        let filteredData = [...data];
        if (this.state.hideZeros) {
            filteredData = filteredData.filter((f, index) => index === 0 || f[1] > 0);
        }
        if (!this.props.isLoading && data.length > 1) {
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                    <SubTitle text={name} />
                    <div className={name.replace(" ", "") + "Chart-container"}>
                        <Chart
                            options={this.barChartOptions(name, gridLinesCount, filteredData.length)}
                            chartType="BarChart"
                            data={filteredData}
                            legendToggle
                        />
                    </div>
                </View>
            );
        }
        return chart;
    }

    rangeColumnChartOptions(title: string, maxValue: number) {
        return {
            //title: title,
            backgroundColor: '#2E2C2D',
            fontSize: '14',
            chartArea: {
                backgroundColor: '#000000',
                height: '80%',
                width: '90%'
            },
            colors: ['#818183'],
            height: 400,
            legend: {
                position: 'none',
                textStyle: {
                    color: '#ffffff'
                }
            },
            sliceVisibilityThreshold: 0,
            titleTextStyle: {
                color: '#ffffff'
            },
            hAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                },
                slantedText: false
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                },
                viewWindow: {
                    'min': 0,
                    'max': maxValue
                }
            },
        };
    }

    incidentChart() {

        let chart;
        if (!this.props.isLoading && this.props.incidents && this.props.incidents.length > 1) {
            let data: any[] = [
                ['Date', 'Value']
            ];
            let maxValue: number = 100;
            this.props.incidents.forEach(v => {
                data.push([new Date(v.dateEntered), v.total]);
                if (v.total > maxValue) {
                    maxValue = v.total;
                }
            });

            let startDate = new Date(2000, 0, 1);
            let endDate = new Date(2000, 0, 2);
            let rangeInDays = 30;

            if (data != null &&
                data.length > 0) {
                endDate = new Date(data[data.length - 1][0]);

                if (typeof endDate !== "undefined" && endDate != null) {
                    if (rangeInDays > 0) {
                        startDate = new Date();
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * rangeInDays));
                    } else {
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * 365));
                    }
                }
            }
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px 10px 60px 10px' }}>
                    <SubTitle text={"Total Incidents"} />
                    <div className={"gchRangeCountStatsIncidents"}>
                        <Chart
                            options={this.rangeColumnChartOptions("Total Incidents", maxValue)}
                            chartType="ColumnChart"
                            data={data}
                            width="100%"
                            legendToggle
                            formatters={
                                [
                                    {
                                        type: 'DateFormat',
                                        column: 1,
                                        options: {
                                            formatType: 'short',
                                        },
                                    }
                                ]
                            }
                            controls={[
                                {
                                    controlType: 'ChartRangeFilter',
                                    options: {
                                        filterColumnIndex: 0,
                                        ui: {
                                            chartType: 'LineChart',
                                            chartOptions: {
                                                backgroundColor: '#2E2C2D',
                                                chartArea: {
                                                    backgroundColor: '#000000',
                                                    width: '90%'
                                                },
                                                colors: ['#818183'],
                                                hAxis: {
                                                    baselineColor: 'none',
                                                    gridlines: {
                                                        color: '#fefefe'
                                                    },
                                                    textStyle: {
                                                        color: '#ffffff'
                                                    },
                                                    titleTextStyle: {
                                                        color: '#ffffff'
                                                    }
                                                },
                                                height: '50'
                                            },
                                            chartView: {
                                                columns: [0, 1]
                                            },
                                            minRangeSize: (86400000 * 28)
                                        },
                                    },
                                    controlPosition: 'bottom',
                                    controlWrapperParams: {
                                        state: {
                                            range: { start: startDate, end: endDate },
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </View>
            );
        }

        return chart;
    }

    render() {

        if (this.props.isLoading) {
            return (<Loading />);
        }

        return (
            <LayoutForm fullWidth={true}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CheckboxControlGroup
                            text={"Hide records with zero values"}
                            defaultValue={this.state.hideZeros}
                            onChanged={(value) => this.onHideZeros(value)} />
                    </Grid>
                </Grid>
                {this.incidentChart()}
                {this.renderBarChart("Incidents per venue", this.props.venueIncidents, this.props.venueIncidentGridLines)}
                {this.renderBarChart("Incidents per security firm", this.props.securityFirmIncidents, this.props.securityFirmIncidentGridLines)}
            </LayoutForm>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(DashboardIncidentTotals))