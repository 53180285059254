import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../../common/CardRow';
import CardTypography from '../../../common/CardTypography';
import CardTitle from '../../../common/CardTitle';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { userComplianceDocumentLogic } from '../../../../common/logic/userComplianceDocumentLogic';

export interface IUserComplianceDocumentExpiredCardProps {
    userComplianceDocument: AusComplyDtos.UserComplianceDocumentSummary;
    onCommand?: Function;
}

export default class UserComplianceDocumentExpiredCard extends React.Component<IUserComplianceDocumentExpiredCardProps, any> {
    constructor(props: IUserComplianceDocumentExpiredCardProps) {
        super(props)
        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.userComplianceDocument);
        }
    }

    render() {
        if (!this.props.userComplianceDocument) {
            return (<></>);
        }

        var item = this.props.userComplianceDocument;
        var commands = userComplianceDocumentLogic.getExpiredCommands(item);

        return (
            <CardRow isDanger={true} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item sm={4} xs={8}>
                        <CardTitle>Type</CardTitle>
                        <CardTypography>{item.documentType}</CardTypography>
                    </Grid>
                    <Grid item sm={2} xs={4}>
                        <CardTitle>Expires (days)</CardTitle>
                        <CardTypography>{item.expires}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.userDisplayName}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Venue</CardTitle>
                        <CardTypography>{item.venue}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Security firm(s)</CardTitle>
                        <CardTypography>{item.securityFirms}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}