import React from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import FilterDisplay from '../../common/FilterDisplay';

export interface IIncidentTypesFilterSummaryProps {
    filter: AusComplyDTOs.IncidentTypeFilter;
    onClick: Function;
}

export default class IncidentTypesFilterSummaryFilterSummary extends React.Component<IIncidentTypesFilterSummaryProps, any> {
    render() {
        if (!this.props.filter) {
            return (<></>);
        }
        return (
            <FilterDisplay text={this.props.filter.display} onClick={this.props.onClick} />
        );
    }
}