import { connect } from 'react-redux'
import GroupDashboard from '../components/groups/group/dashboard/GroupDashboard'
import { withRouter } from "react-router";
import { notificationShow } from '../common/actions/notification';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { loadAllReferenceData } from '../common/actions/referenceData';
import { printShow } from '../common/actions/print';
import {
    groupDashboardFilterRequest,
    groupDashboardRequest,
    groupDashboardSetFilter,
    groupDashboardExportRequest,
    groupDashboardSetOptions
} from '../common/actions/groups';

const mapStateToProps = (state, props) => {
    return {
        groupId: props.groupId,
        isLoading: state.groups.dashboard.isLoading,
        isLoadingIncidents: state.groups.dashboard.isLoadingIncidents,
        incidents: state.groups.dashboard.filteredIncidents,
        venueMarkers: state.groups.dashboard.filteredVenueMarkers,
        heatmapData: state.groups.dashboard.heatmap,
        incidentTotals: state.groups.dashboard.incidentTotals,
        filter: state.groups.dashboard.filter,
        data: state.groups.dashboard.data,
        options: state.groups.dashboard.options,
        canFilter: permissionsLogic.hasGroupPermissionForState(state, props.groupId, 'ViewGroupDashboardFilter')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(groupDashboardSetFilter(filter))
        },
        onResetFilter: (groupId) => {
            dispatch(groupDashboardFilterRequest(groupId))
        },
        onLoad: () => {
            dispatch(groupDashboardRequest())
        },
        onExport: () => {
            dispatch(groupDashboardExportRequest())
        },
        onSetOptions: (options) => {
            dispatch(groupDashboardSetOptions(options))
        }
    }
}

const GroupDashboardContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupDashboard))

export default GroupDashboardContainer