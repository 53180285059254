import React, { Component } from 'react';
import { Paging as PagingDto } from '../../common/dto/AusComply.dtos';
import SelectControl from '../controls/SelectControl';
import Box from '@material-ui/core/Box';

export interface IPageSizeProps {
    paging: PagingDto;
    onPageSizeSelected?: Function;
    label?: string;
}

class PageSize extends React.PureComponent<IPageSizeProps, any> {

    constructor(props: IPageSizeProps) {
        super(props)
        this.onPageSizeSelected = this.onPageSizeSelected.bind(this);
    }

    onPageSizeSelected(pageSize) {
        if (this.props.onPageSizeSelected) {
            let newPage = this.props.paging.page;
            if (pageSize != this.props.paging.pageSize) {
                newPage = 1;
            }
            this.props.onPageSizeSelected(newPage, pageSize);
        }
    }

    render() {
        let label = this.props.label ? this.props.label : "records";
        return (
            <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}>

                </Box>
                <Box p={0} style={{ minWidth: "200px" }}>
                    <SelectControl
                        defaultValue={this.props.paging.pageSize}
                        readonly={false}
                        onChange={(value) => this.onPageSizeSelected(value)}
                    >
                        <option key={"page-size-10"} value={10} selected={this.props.paging.pageSize == 10}>10 {label} per page</option>
                        <option key={"page-size-50"} value={50} selected={this.props.paging.pageSize == 50}>50 {label} per page</option>
                        <option key={"page-size-100"} value={100} selected={this.props.paging.pageSize == 100}>100 {label} per page</option>
                        <option key={"page-size-200"} value={200} selected={this.props.paging.pageSize == 200}>200 {label} per page</option>
                        {(this.props.paging.pageSize && this.props.paging.pageSize != 10 && this.props.paging.pageSize != 50 && this.props.paging.pageSize != 100 && this.props.paging.pageSize != 200) && (
                            <option key={"page-size-" + this.props.paging.pageSize.toString()} value={this.props.paging.pageSize} selected={this.props.paging.pageSize == this.props.paging.pageSize}>{this.props.paging.pageSize.toString()} {label} per page</option>
                        )}
                    </SelectControl>
                </Box>
                <Box flexGrow={1} p={0}>

                </Box>
            </Box>
        );
    }
}

export default PageSize;
