import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueFacialRecognitionNotificationsEnrol from '../components/venue/facialRecognitionNotifications/VenueFacialRecognitionNotificationsEnrol'

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueFacialRecognitionNotificationsEnrolContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueFacialRecognitionNotificationsEnrol))

export default VenueFacialRecognitionNotificationsEnrolContainer