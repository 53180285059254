import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import FullPageDialog from '../layout/FullPageDialog';
import Img from 'react-image';
import Loading from './Loading';
import Box from '@material-ui/core/Box';
import Thumbnail from './Thumbnail';
import CopyToClipboardButton from '../controls/CopyToClipboardButton';

interface IImagePreviewProps {
    theme: any;
    filePath: string;
    fileName?: string;
    filePaths?: string[];
    onDismissed?: Function;
    extraContent?: any;
    imageText?: string;
    processButton?: any;
}

class ImagePreview extends React.Component<IImagePreviewProps, any> {
    render() {
        const { theme } = this.props;
        let hasFilePath = false;
        if (this.props.filePath) {
            hasFilePath = true;
        }

        let copyText;
        if (this.props.imageText) {
            copyText = <CopyToClipboardButton text={this.props.imageText} />;
        }

        return (
            <FullPageDialog
                title={"Image"}
                open={hasFilePath}
                footerCenterContent={copyText}
                footerRightContent={this.props.processButton}
                onDismissed={this.props.onDismissed} 
                style={{
                    top: '10px',
                    bottom: '100px',
                    position: 'absolute',
                    left: '10px',
                    right: '10px'
                }}>
                <Box display="flex" flexDirection="column" style={{ height: '100%', position: 'relative' }}>
                    <Box p={0} flex={1}>
                        {false && this.props.filePath && (
                            <Img
                                src={this.props.filePath}
                                style={theme.custom.fileUploaderDisplay}
                                loader={<Loading />}
                            />
                        )}
                        {this.props.filePath && (
                            <div style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: "url('" + this.props.filePath + "')",
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                position: 'relative'
                            }}></div>
                        )}
                        {this.props.extraContent}
                    </Box>
                    <Box p={0} flex={0} >
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="none" overflow={"auto"}>
                            {this.props.filePaths && this.props.filePaths.map((item, index) =>
                                <Box p={1} key={"image-box-" + index} >
                                    <Thumbnail
                                        key={"image-" + index.toString()}
                                        previewPath={""}
                                        displayPath={""}
                                        base64={item}
                                        base64Type={"image/jpeg"}
                                        isImage={true}
                                        text={"Detected face"}
                                        fullText={""}
                                        title={""}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {this.props.fileName && (
                        <Box p={0} flex={0}>
                            <p style={theme.custom.fileNameText}>{this.props.fileName}</p>
                        </Box>
                    )}
                    {(this.props.imageText) && (
                        <Box p={0} flex={0}>
                            <Box display="flex" flexDirection="row">
                                <Box p={0} flex={1}>
                                    <p style={theme.custom.imageViewText}>{this.props.imageText}</p>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ImagePreview);