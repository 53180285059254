import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import { PickerItem } from '../../common/PickerItem';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import Paragraph from '../../common/Paragraph';
import RadioControl from '../../controls/RadioControl';
import CheckboxControl from '../../controls/CheckboxControl'
import SelectControlGroup from '../../controls/SelectControlGroup';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface IIncidentActionTemplateFilterProps {
    filter: AusComplyDTOs.IncidentActionTemplateFilter;
    onUpdateFilter: Function;
    onReset: Function;
}

interface IIncidentActionTemplateFilterState {
    filter: AusComplyDTOs.IncidentActionTemplateFilter;
}

export default class IncidentActionTemplateFilter extends React.Component<IIncidentActionTemplateFilterProps, IIncidentActionTemplateFilterState> {
    constructor(props: IIncidentActionTemplateFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.removedChanged = this.removedChanged.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.state = {
            filter: new AusComplyDTOs.IncidentActionTemplateFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter });
    }

    removedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter });
    }

    onSearch() {
        this.props.onUpdateFilter(this.state.filter);
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.onSearch()} />
                </Grid>
            </Grid>
        );


        return (
            <LayoutForm>
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChange('search', value);
                        this.onSearch();
                    }}
                    onChange={value => this.onChange('search', value)} />
                <CheckboxControlGroup text="Show Removed" defaultValue={this.state.filter.showObsolete} onChanged={this.removedChanged} />
                {resetButton}
            </LayoutForm>
        );
    }
}