export const
VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST = "VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST",
VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_SUCCESS",
VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_FAILURE = "VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_FAILURE",
VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE = "VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE",
VENUE_SECURITYFIRM_AVAILABLE_CLEAR_VENUE = "VENUE_SECURITYFIRM_AVAILABLE_CLEAR_VENUE",
VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST = "VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST",
VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_SUCCESS",
VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_FAILURE = "VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_FAILURE",
VENUE_SECURITYFIRM_SELECT = "VENUE_SECURITYFIRM_SELECT";


export function venueSecurityFirmLoadAvailable() {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST
    };
}

export function venueSecurityFirmLoadAvailableSuccess(venues, canSelectNoVenue) {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_SUCCESS,
        venues,
        canSelectNoVenue
    };
}

export function venueSecurityFirmLoadAvailableFailure() {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_FAILURE
    };
}

export function venueSecurityFirmAvailableSelectVenue(venue) {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE,
        venue
    };
}

export function venueSecurityFirmAvailableClearVenue() {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_CLEAR_VENUE
    }
}

export function venueSecurityFirmLoadAvailableSecurityFirms() {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST
    };
}

export function venueSecurityFirmLoadAvailableSecurityFirmsSuccess(securityFirms) {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_SUCCESS,
        securityFirms
    };
}

export function venueSecurityFirmLoadAvailableSecurityFirmsFailure() {
    return {
        type: VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_FAILURE
    };
}

export function venueSecurityFirmSelect(venueId, securityFirmId, venueEventId) {
    return {
        type: VENUE_SECURITYFIRM_SELECT,
        venueId,
        securityFirmId,
        venueEventId
    };
}