import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import { broadcastNotificationLogic } from '../../../common/logic/broadcastNotificationLogic';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IBroadcastNotificationCardProps {
    broadcastNotification: AusComplyDtos.BroadcastNotification;
    canEdit: boolean;
    onCommand?: Function;
}

export default class BroadcastNotificationCard extends React.Component<IBroadcastNotificationCardProps, any> {
    constructor(props: IBroadcastNotificationCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.broadcastNotification);
        }
    }

    render() {
        if (!this.props.broadcastNotification) {
            return (<></>);
        }

        var commands = broadcastNotificationLogic.getCommands(this.props.broadcastNotification, this.props.canEdit);
        var item = this.props.broadcastNotification;

        return (
            <CardRow isSuccess={item.isSent} isWarning={!item.isSent && !item.obsolete} isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item sm={12} xs={12}>
                        <CardTitle>Message</CardTitle>
                        <CardTypography>{item.message}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Recipients</CardTitle>
                        <CardTypography>{item.userRoleTypesDisplay}</CardTypography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <CardTitle>Created</CardTitle>
                        <CardTypography>{item.dateEnteredDisplay}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}