import update from "immutability-helper";
import {
    DISPLAY_INCIDENT_LOAD_REQUEST,
    DISPLAY_INCIDENT_LOAD_REQUEST_SUCCESS,
    DISPLAY_INCIDENT_LOAD_REQUEST_FAILURE,
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST,
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_SUCCESS,
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_FAILURE,
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST,
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_SUCCESS,
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_FAILURE
} from "../actions/displayIncident";

const initialState = {
    isLoading: false,
    incident: { incidentId: 0 },
    isLoadingIncidentVersions: false,
    incidentVersions: [],
    isLoadingIncidentVersion: false,
    incidentVersion: {},
    error: ""
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setIncident(state, incident) {
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function loadingIncidentVersions(state, isLoadingIncidentVersions) {
    let newState = update(state, {
        isLoadingIncidentVersions: { $set: isLoadingIncidentVersions }
    });
    return newState;
}

function setIncidentVersions(state, incidentVersions) {
    let newState = update(state, {
        incidentVersions: { $set: incidentVersions }
    });
    return newState;
}

function loadingIncidentVersion(state, isLoadingIncidentVersion) {
    let newState = update(state, {
        isLoadingIncidentVersion: { $set: isLoadingIncidentVersion }
    });
    return newState;
}

function setIncidentVersion(state, incidentVersion) {
    let newState = update(state, {
        incidentVersion: { $set: incidentVersion }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

export default function displayIncident(state = initialState, action) {
    switch (action.type) {
        case DISPLAY_INCIDENT_LOAD_REQUEST:
            return loading(setIncident(state, { incidentId: 0 }), true);
        case DISPLAY_INCIDENT_LOAD_REQUEST_SUCCESS:
            return loading(setIncident(state, action.incident), false);
        case DISPLAY_INCIDENT_LOAD_REQUEST_FAILURE:
            return loading(state, false);
        case DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST:
            return loadingIncidentVersions(setIncidentVersions(setError(state, ""), []), true);
        case DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_SUCCESS:
            return loadingIncidentVersions(setIncidentVersions(state, action.incidentVersions), false);
        case DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_FAILURE:
            return loadingIncidentVersions(setError(state, action.error), false);
        case DISPLAY_INCIDENT_LOAD_VERSION_REQUEST:
            return loadingIncidentVersion(setIncidentVersion(setError(state, ""), {}), true);
        case DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_SUCCESS:
            return loadingIncidentVersion(setIncidentVersion(state, action.incidentVersion), false);
        case DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_FAILURE:
            return loadingIncidentVersion(setError(state, action.error), false);
    }
    return state;
}