import { connect } from 'react-redux'
import Venues from '../components/admin/venues/Venues'
import { withRouter } from "react-router";
import * as adminActions from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.venues.isLoading,
        venues: state.admin.venues.venues,
        paging: state.admin.venues.paging,
        filter: state.admin.venues.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (filter, page, pageSize) => {
            dispatch(adminActions.adminVenuesRequest(filter, page, pageSize))
        },
        onFilterUpdate: (filter) => {
            dispatch(adminActions.adminVenuesFilterSet(filter))
        },
        onObsolete: (venueToRemoveId) => {
            dispatch(adminActions.adminVenueObsoleteRequest(venueToRemoveId))
        },
        onDelete: (venueToDeleteId) => {
            dispatch(adminActions.adminVenueDeleteRequest(venueToDeleteId))
        },
        onExport: () => {
            dispatch(adminActions.adminVenueExportRequest())
        },
        onDismissRegistration: (venueId) => {
            dispatch(adminActions.adminVenueRegistratoinDismissRequest(venueId))
        }
    }
}

const AdminVenuesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Venues))

export default AdminVenuesContainer