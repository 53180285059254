import { connect } from 'react-redux'
import VenueSubscription from '../components/venue/venueSubscription/VenueSubscription'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    venueSubscriptionSummaryRequest
} from '../common/actions/venue'

const mapStateToProps = state => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        isLoading: state.venue.venueSubscriptionSummary.isLoading,
        error: state.venue.venueSubscriptionSummary.error,
        canAdminEdit: permissionsLogic.isLevelSix(state),
        venueSubscriptionSummary: state.venue.venueSubscriptionSummary.venueSubscriptionSummary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(venueSubscriptionSummaryRequest())
        }
    }
}

const VenueSubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueSubscription)

export default VenueSubscriptionContainer