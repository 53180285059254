import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import SubTitle from '../../common/SubTitle';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import VenueTabs from './VenueTabs';
import PrimaryButton from '../../controls/PrimaryButton';
import PageLayout from '../../layout/PageLayout';
import { KeyName } from '../../../common/dto/common';
import VenueImageProcessingHistoricalContainer from '../../../containers/VenueImageProcessingHistoricalContainer';
import ExternalApiConnectionContainer from '../../../containers/ExternalApiConnectionContainer';
import NotesContainer from '../../../containers/NotesContainer';

export interface IVenueSubscriptionProps {
    venueId: number;
    isLoading: boolean;
    isSaving: boolean;
    venueSubscription: AusComplyDtos.VenueSubscription;
    industryCategories: AusComplyDtos.IndustryCategory[],
    error: string;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    onSaveUserRole: Function;
    history: any;
}

export default class VenueSubscription extends Component<IVenueSubscriptionProps, any> {
    constructor(props: IVenueSubscriptionProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.venueId);
            }
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let venueSubscription = { ...this.props.venueSubscription };
        venueSubscription[fieldName] = value;
        if (fieldName == "idCheck" && value) {
            venueSubscription["ocrProcessing"] = true;
        }
        this.props.onUpdate(venueSubscription);
    }

    onUpdate(venueSubscription) {
        this.props.onUpdate(venueSubscription);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        //this.props.onLoad(this.props.venueId);
        this.props.history.push('/admin/venues');
    }

    onCommand(command: string) {
        switch (command) {
            case "suspend":
                this.props.onSaveUserRole(this.props.venueId, null, false);
                break;
            case "undosuspend":
                this.props.onSaveUserRole(this.props.venueId, null, true);
                break;
        }
    }

    render() {
        let moreCommands: KeyName[] = [];
        if (this.props.venueSubscription) {
            if (this.props.venueSubscription.suspendUserRoles) {
                moreCommands.push({ key: "suspend", name: "Suspend user roles" })
            }
            if (this.props.venueSubscription.unsuspendUserRoles) {
                moreCommands.push({ key: "undosuspend", name: "Restore user roles" })
            }
        }
        return (
            <PageLayout
                headerText={"Venue"}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                moreCommands={moreCommands}
                onCommand={this.onCommand}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                footerCenterContent={<PrimaryButton onClick={() => this.onSave()}>Save</PrimaryButton>}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Subscription"} history={this.props.history} />
                <ControlGroup text="ID">
                    <TextDisplay>{this.props.venueSubscription.venueId}</TextDisplay>
                </ControlGroup>
                {this.props.venueSubscription.dateEntered && !this.props.venueSubscription.dateEntered.isEmpty && (
                    <ControlGroup text="Creation Date">
                        <TextDisplay>{this.props.venueSubscription.dateEntered.display}</TextDisplay>
                    </ControlGroup>
                )}
                <ControlGroup text="Direct Debit">
                    <PrimaryButton
                        component={forwardRef((props, ref) => (
                            <a
                                href={this.props.venueSubscription.paywayUrl}
                                rel="noreferrer"
                                target="_blank"
                                {...props}
                            />
                        ))}>Enter Details</PrimaryButton>
                </ControlGroup>
                <CheckboxControlGroup text="Active" defaultValue={this.props.venueSubscription.active} label={" "} onChanged={(value) => this.onValueChanged("active", value)} />
                {!this.props.venueSubscription.active && (
                    <SimpleDateControlGroup text="Cut Off Date" date={this.props.venueSubscription.cutOffDate} onChange={(value) => this.onValueChanged("cutOffDate", value)} />
                )}
                <SelectControlGroup
                    text="Industry Category"
                    onChange={(value) => this.onValueChanged("industryCategoryId", value)}
                    defaultValue={this.props.venueSubscription.industryCategoryId}>
                    <option value={0} />
                    {this.props.industryCategories && this.props.industryCategories.map((item, index) => (
                        <option key={item.industryCategoryId} value={item.industryCategoryId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <ControlGroup text=" ">
                    <NotesContainer entityType={AusComplyDtos.ngtEntityType.VenueSubscription} entityId={this.props.venueSubscription.venueId} wrapInCard={true} />
                </ControlGroup>
                <SubTitle text={"Subscription Features"} />
                <CheckboxControlGroup text="Checklists"
                    defaultValue={this.props.venueSubscription.checklists}
                    onChanged={(value) => this.onValueChanged("checklists", value)}
                    label={" "} />
                <CheckboxControlGroup text="Incident Actions"
                    defaultValue={this.props.venueSubscription.incidentActions}
                    onChanged={(value) => this.onValueChanged("incidentActions", value)}
                    label={" "} />
                <CheckboxControlGroup text="Can View Security Firm Sign-on Register"
                    defaultValue={this.props.venueSubscription.canViewSecurityFirmSignOnRegister}
                    onChanged={(value) => this.onValueChanged("canViewSecurityFirmSignOnRegister", value)}
                    label={" "} />
                <CheckboxControlGroup text="Always Use Licensed Incident Report"
                    defaultValue={this.props.venueSubscription.useLicensedIncidentReport}
                    onChanged={(value) => this.onValueChanged("useLicensedIncidentReport", value)}
                    label={" "} />
                <CheckboxControlGroup text="Role Type Notifications"
                    defaultValue={this.props.venueSubscription.roleTypeNotifications}
                    onChanged={(value) => this.onValueChanged("roleTypeNotifications", value)}
                    label={" "} />
                <CheckboxControlGroup text="Facial Recognition Notifications"
                    defaultValue={this.props.venueSubscription.facialRecognitionNotification}
                    onChanged={(value) => this.onValueChanged("facialRecognitionNotification", value)}
                    label={" "} />
                <CheckboxControlGroup text="ID Check"
                    defaultValue={this.props.venueSubscription.idCheck}
                    onChanged={(value) => this.onValueChanged("idCheck", value)}
                    label={" "} />
                <CheckboxControlGroup text="OCR Image Scanning"
                    defaultValue={this.props.venueSubscription.ocrProcessing}
                    onChanged={(value) => this.onValueChanged("ocrProcessing", value)}
                    label={" "} />
                {this.props.venueSubscription.ocrProcessing && (<ControlGroup text={" "}>
                    <VenueImageProcessingHistoricalContainer venueId={this.props.venueId} />
                </ControlGroup>)}
                <CheckboxControlGroup text="Access the Industry Groups"
                    defaultValue={this.props.venueSubscription.groupIndustries}
                    onChanged={(value) => this.onValueChanged("groupIndustries", value)}
                    label={" "} />
                <CheckboxControlGroup text="Correctional Facility"
                    defaultValue={this.props.venueSubscription.correctionalFacility}
                    onChanged={(value) => this.onValueChanged("correctionalFacility", value)}
                    label={" "} />
                <CheckboxControlGroup text="Auto Approval"
                    defaultValue={this.props.venueSubscription.autoApproval}
                    onChanged={(value) => this.onValueChanged("autoApproval", value)}
                    label={" "} />
                <CheckboxControlGroup text="Comms Logs"
                    defaultValue={this.props.venueSubscription.commLogs}
                    onChanged={(value) => this.onValueChanged("commLogs", value)}
                    label={" "} />
                <CheckboxControlGroup text="Notebook"
                    defaultValue={this.props.venueSubscription.notebook}
                    onChanged={(value) => this.onValueChanged("notebook", value)}
                    label={" "} />
                <CheckboxControlGroup text="Roubler Roster"
                    defaultValue={this.props.venueSubscription.roublerRoster}
                    onChanged={(value) => this.onValueChanged("roublerRoster", value)}
                    label={" "} />
                {this.props.venueSubscription.roublerRoster && <ControlGroup text={" "}>
                    <ExternalApiConnectionContainer entityType={AusComplyDtos.ngtEntityType.RoublerRoster} venueId={this.props.venueSubscription.venueId} />
                </ControlGroup>}
                <CheckboxControlGroup text="Cash/Play Trax"
                    defaultValue={this.props.venueSubscription.cashPlayTrax}
                    onChanged={(value) => this.onValueChanged("cashPlayTrax", value)}
                    label={" "} />
                {this.props.venueSubscription.cashPlayTrax && <ControlGroup text={" "}>
                    <TextControlGroup
                        text='Cash/Play Trax Venue Identifier'
                        defaultValue={this.props.venueSubscription.cashPlayTraxId}
                        onChange={(value) => this.onValueChanged("cashPlayTraxId", value)}
                    />
                </ControlGroup>}
                <CheckboxControlGroup text="Events"
                    defaultValue={this.props.venueSubscription.eventVenues}
                    onChanged={(value) => this.onValueChanged("eventVenues", value)}
                    label={" "} />
                <CheckboxControlGroup text="Sub Locations"
                    defaultValue={this.props.venueSubscription.subLocations}
                    onChanged={(value) => this.onValueChanged("subLocations", value)}
                    label={" "} />
                <CheckboxControlGroup text="Roller Integration"
                    defaultValue={this.props.venueSubscription.roller}
                    onChanged={(value) => this.onValueChanged("roller", value)}
                    label={" "} />
            </PageLayout>
        );
    }
}