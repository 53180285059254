import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface IDefaultButtonProps {
    classes: any;
    text?: string;
    className?: any;
    onClick?: Function;
    component?: any;
    style?: any;
    disabled? :boolean;
    autoFocus?: boolean;
}

class DefaultButton extends React.PureComponent<IDefaultButtonProps, any> {
    constructor(props: IDefaultButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;
        let style = this.props.style;
        if(!style) {
            style = {
                width: '100%', 
                height: '100%'
            };
        }
        let className=classNames(classes.buttonDefault, this.props.className);
        if(this.props.disabled) {
            className=classNames(classes.buttonDefault, classes.buttonDefaultDisabledState, classes.buttonDefaultDisabled, this.props.className);
        }
        if (this.props.autoFocus) {
            return (
                <Button
                    variant="contained"
                    onClick={this.onClick}
                    color="default"
                    component={this.props.component}
                    className={className}
                    style={style}
                    autoFocus
                    >{this.props.text}{this.props.children}</Button>
            );
        }
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                component={this.props.component}
                className={className}
                style={style}
                >{this.props.text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DefaultButton);