import update from "immutability-helper";
import {
    REGISTER_SECURITY_FIRM_RESET,
    REGISTER_SECURITY_FIRM_NEW_REQUEST,
    REGISTER_SECURITY_FIRM_NEW_REQUEST_SUCCESS,
    REGISTER_SECURITY_FIRM_NEW_REQUEST_FAILURE,
    REGISTER_SECURITY_FIRM_SET_STEP,
    REGISTER_SECURITY_FIRM_UPDATE,
    REGISTER_SECURITY_FIRM_UPDATE_OTHER_DOCUMENTS,
    REGISTER_SECURITY_FIRM_UPDATE_COMPLIANCE_DOCUMENTS,
    REGISTER_SECURITY_FIRM_SAVE_REQUEST,
    REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS,
    REGISTER_SECURITY_FIRM_SAVE_REQUEST_FAILURE
} from "../actions/registerSecurityFirm";
import { securityFirmLogic } from '../logic/securityFirmLogic';

const initialState = {
    securityFirm: { securityFirmId: -1 },
    managerContact: {},
    primaryContact: {},
    securityLicenseClasses: [],
    states: [],
    otherDocuments: [],
    otherDocumentTypes: [],
    complianceDocuments: [],
    complianceDocumentTypes: [],
    isLoading: false,
    isSaving: false,
    error: null,
    errors: {},
    step: 1
};

function updateSecurityFirm(state, securityFirm, managerContact, primaryContact) {
    let newState = update(state, {
        securityFirm: { $set: securityFirm },
        managerContact: { $set: managerContact },
        primaryContact: { $set: primaryContact }
    });
    return newState;
}


function setOptions(state, states, securityLicenceClasses, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    let newState = update(state, {
        states: { $set: states },
        securityLicenceClasses: { $set: securityLicenceClasses },
        otherDocuments: { $set: otherDocuments },
        otherDocumentTypes: { $set: otherDocumentTypes },
        complianceDocuments: { $set: complianceDocuments },
        complianceDocumentTypes: { $set: complianceDocumentTypes }
    });
    return newState;
}

function setStep(state, step) {
    let newState = update(state, {
        step: { $set: step }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: securityFirmLogic.validateRegisterSecurityFirm(state.securityFirm, state.managerContact, state.primaryContact) }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        otherDocuments: { $set: otherDocuments },
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        complianceDocuments: { $set: complianceDocuments },
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

export default function registerSecurityFirm(state = initialState, action) {
    switch (action.type) {
        case REGISTER_SECURITY_FIRM_NEW_REQUEST:
            return loading(updateSecurityFirm(setStep(state, 1), { securityFirmId: 0 }, {}, {}), true);
        case REGISTER_SECURITY_FIRM_NEW_REQUEST_SUCCESS:
            return setErrors(loading(
                updateSecurityFirm(
                    setOptions(
                        setError(state, "")
                        , action.states
                        , action.securityLicenceClasses
                        , action.otherDocuments
                        , action.otherDocumentTypes
                        , action.complianceDocuments
                        , action.complianceDocumentTypes)
                    , action.securityFirm, action.managerContact, action.primaryContact)
                , false));
        case REGISTER_SECURITY_FIRM_NEW_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case REGISTER_SECURITY_FIRM_RESET:
            return setErrors(updateSecurityFirm(state, { securityFirmId: -1 }, {}, {}));
        case REGISTER_SECURITY_FIRM_SET_STEP:
            return setStep(state, action.step);
        case REGISTER_SECURITY_FIRM_UPDATE:
            return setErrors(updateSecurityFirm(state, action.securityFirm, action.managerContact, action.primaryContact));
        case REGISTER_SECURITY_FIRM_UPDATE_OTHER_DOCUMENTS:
            return setOtherDocuments(state, action.otherDocuments);
        case REGISTER_SECURITY_FIRM_UPDATE_COMPLIANCE_DOCUMENTS:
            return setComplianceDocuments(state, action.complianceDocuments);
        case REGISTER_SECURITY_FIRM_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS:
            return saving(state, false);
        case REGISTER_SECURITY_FIRM_SAVE_REQUEST_FAILURE:
            return setErrors(saving(setError(state, action.error), false));
    }
    return state;
}