import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import ControlGroup from '../../controls/ControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { MulitSelectOption } from '../../../common/dto/common';
import FullPageDialog from '../../layout/FullPageDialog';
import Spacer from '../../common/Spacer';
import CreateEntitySelection from '../common/CreateEntitySelection';
import IncidentCategoryTypes from '../common/IncidentCategoryTypes';
import NotificationTrigger from '../../notifications/NotificationTrigger';
import VenueLocations from '../common/VenueLocations';
import IncidentFlags from '../common/IncidentFlags';
import IncidentType from '../common/IncidentType';
import ChecklistTemplates from '../common/ChecklistTemplates';
import EventFilters from '../common/EventFilters';

export interface ICashTraxEventProps {
    externalEventId: number;
    externalEvent: AusComplyDtos.ExternalEvent;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ICashTraxEventState {
    functionalAreas: MulitSelectOption[];
}

export default class CashTraxEvent extends Component<ICashTraxEventProps, ICashTraxEventState> {
    constructor(props: ICashTraxEventProps) {
        super(props)
        this.state = {
            functionalAreas: []
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        const { externalEvent, onUpdate } = this.props;
        let content;
        if (externalEvent) {
            content = <>
                <EventFilters
                    externalEvent={externalEvent}
                    onUpdate={onUpdate} />
                <CreateEntitySelection
                    externalEvent={externalEvent}
                    onUpdate={onUpdate} />
                {externalEvent.createEntityType == AusComplyDtos.ngtEntityType.Incident && (<>
                    <Spacer />
                    <IncidentType
                        externalEvent={externalEvent}
                        onUpdate={onUpdate}
                    />
                    <Spacer />
                    <IncidentCategoryTypes
                        externalEvent={externalEvent}
                        onUpdate={onUpdate}
                    />
                    <VenueLocations
                        externalEvent={externalEvent}
                        onUpdate={onUpdate}
                    />
                    <IncidentFlags
                        externalEvent={externalEvent}
                        onUpdate={onUpdate}
                    />
                    <TextAreaControlGroup
                        text={externalEvent.notificationTrigger.notify ? "Narrative and notification message template" :"Narrative template"}
                        defaultValue={externalEvent.messageTemplate}
                        onChange={value => this.onValueChanged("messageTemplate", value)}
                    />
                    <Spacer />
                </>)}
                {externalEvent.createEntityType == AusComplyDtos.ngtEntityType.Checklist && (<>
                    <Spacer />
                    <ChecklistTemplates
                        externalEvent={externalEvent}
                        onUpdate={onUpdate}
                    />
                    {externalEvent.notificationTrigger.notify && (
                        <>
                            <Spacer />
                            <TextAreaControlGroup
                                text={"Notification Message Template"}
                                defaultValue={externalEvent.messageTemplate}
                                onChange={value => this.onValueChanged("messageTemplate", value)}
                            />
                        </>
                    )}
                    <Spacer />
                </>)}
                {externalEvent.createEntityType == AusComplyDtos.ngtEntityType.Notification && (<>
                    <Spacer />
                    <TextAreaControlGroup
                        text={"Notification Message Template"}
                        defaultValue={externalEvent.messageTemplate}
                        onChange={value => this.onValueChanged("messageTemplate", value)}
                    />
                    <Spacer />
                </>
                )}
                {(externalEvent.notificationTrigger) && (
                    <>
                        <NotificationTrigger
                            notificationTrigger={externalEvent.notificationTrigger}
                            showEscalation={true}
                            noControlGroup={true}
                            hideSummary={true}
                            lockNotify={externalEvent.createEntityType == AusComplyDtos.ngtEntityType.Notification}
                            onUpdate={(value) => this.onValueChanged("notificationTrigger", value)} />
                    </>
                )}
                <CheckboxControlGroup
                    text={"Active"}
                    label={" "}
                    labelAbove={true}
                    isReversed={true}
                    defaultValue={this.props.externalEvent.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
            </>;
        }

        return (
            <FullPageDialog
                open={this.props.externalEventId > -1}
                loading={this.props.isLoading}
                footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                title="CashTrax Event"
                onDismissed={() => this.onCancel()}>

                {content}

            </FullPageDialog>
        );
    }
}