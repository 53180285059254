import React, { Component } from 'react';
import MapWrapper from './MapWrapper'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import Loading from '../../../common/Loading';

export interface IMapProps {
    filter: AusComplyDtos.DashboardFilter;
    venueMarkers: AusComplyDtos.VenueMarker[];
    postcodeBoundaries: string[];
    heatmapData?: any[];
    height?: string;
    style?: any;
    draggableMarker?: boolean;
    onDragged?: Function;
}

interface IMapState {
    hidden: boolean;
}


export default class Map extends React.Component<IMapProps, IMapState> {
    constructor(props: IMapProps) {
        super(props);
        this.state = {
            hidden: true
        }
    }

    render() {
        let height = this.props.height || '600px';
        let map = (
                <div key={"admin-map"} style={{ textAlign: "center", display: 'block', margin: '10px', ...this.props.style }}>
                    <MapWrapper
                        filter={this.props.filter}
                        venueMarkers={this.props.venueMarkers}
                        postcodeBoundaries={this.props.postcodeBoundaries}
                        heatmapData={this.props.heatmapData}
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB4D0gfDc7dpa248E0GmMar9U5fctLRL_k&v=3.exp&libraries=geometry,drawing,places,visualization"
                        // loadingElement={<div style={{ height: height, width: `100%` }} />}
                        loadingElement={<Loading />}
                        containerElement={<div style={{ height: height, width: `100%` }} />}
                        mapElement={<div style={{ height: height, width: `100%` }} />}
                        mapTypeControlOptions={{ mapTypeIds: [] }}
                        draggable={this.props.draggableMarker}
                        onDragged={this.props.onDragged}
                        isMarkerShown={true} />
                </div>
            );

        return (
            <Grid container spacing={1} >
                <Grid item xs={12} >
                    {map}
                </Grid>
            </Grid>
        );
    }
}

