import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IUserViewProps {
    userId: number,
    userView: AusComplyDtos.UserView,
    isLoading: boolean,
    onLoad: Function
}

export default class IUserView extends Component<IUserViewProps, any> {
    constructor(props: IUserViewProps) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
        this.props.onLoad(this.props.userId);
    }

    render() {
        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={false}
                    error={""}
                    fullWidth={false}
                >
                    <form>
                        <ControlGroup text="Name">
                            <TextDisplay>{this.props.userView.displayName}</TextDisplay>
                        </ControlGroup>
                        {this.props.userView.rsaNumber && (
                            <ControlGroup text="RSA Number">
                                <TextDisplay>{this.props.userView.rsaNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.stateLicensed && (
                            <ControlGroup text="State Licensed">
                                <TextDisplay>{this.props.userView.stateLicensed}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.licenceNumber && (
                            <ControlGroup text="Licence Number">
                                <TextDisplay>{this.props.userView.licenceNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.dateOfBirth && (
                            <ControlGroup text="DOB">
                                <TextDisplay>{this.props.userView.dateOfBirth.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.email && (
                            <ControlGroup text="Email">
                                <TextDisplay>{this.props.userView.email}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.mobileNo && (
                            <ControlGroup text="Mobile">
                                <TextDisplay>{this.props.userView.mobileNo}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.emergencyContactName && (
                            <ControlGroup text="Emergency Contact Name">
                                <TextDisplay>{this.props.userView.emergencyContactName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.userView.emergencyContactNumber && (
                            <ControlGroup text="Emergency Contact Mobile">
                                <TextDisplay>{this.props.userView.emergencyContactNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                    </form>
                </LayoutForm>
            </>
        );
    }
}