import React from 'react';

const NotificationIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M7.61,41.21c3.34-4.47,5.14-9.88,5.14-15.54V21c0-6.73,5.48-12.21,12.21-12.21S37.17,14.26,37.17,21v4.67c0,5.66,1.81,11.07,3.63,13.52c0.83,1.12,0.38,2.02-1.01,2.02H7.61z M24.96,47.07c-1.91,0-3.54-1.23-4.14-2.93h8.29C28.5,45.84,26.87,47.07,24.96,47.07 M23.5,4.39c0-0.81,0.66-1.46,1.46-1.46s1.46,0.66,1.46,1.46v1.54c-0.48-0.05-0.97-0.07-1.46-0.07c-0.49,0-0.98,0.02-1.46,0.07V4.39z M40.1,25.67V21c0-6.82-4.53-12.6-10.74-14.49V4.39c0-2.42-1.97-4.39-4.39-4.39s-4.39,1.97-4.39,4.39v2.12C14.36,8.4,9.82,14.18,9.82,21v4.67c0.6,4.51-2.28,11.67-6.43,15.99C3.01,42,2.88,42.71,3.11,43.25c0.23,0.54,0.76,0.89,1.35,0.89h10.81c1.4,0,2.68,0.75,2.87,1.67c0.34,1.67,3.3,4.19,6.84,4.19s6.5-2.52,6.84-4.19c0.19-0.92,1.47-1.67,2.87-1.67h10.81c0.51,0.05,1.12-0.35,1.35-0.89c0.25-0.45,0.12-1.16-0.29-1.59C42.38,37.34,40.1,31.66,40.1,25.67" />
            <path d="M44,21c0,0.81,0.66,1.46,1.46,1.46s1.46-0.66,1.46-1.46c0.6-4.43-2.29-11.39-6.44-15.54c-0.57-0.57-1.5-0.57-2.07,0c-0.57,0.57-0.57,1.5,0,2.07C42.02,11.13,44,15.91,44,21" />
            <path d="M4.45,22.46c0.81,0,1.46-0.66,1.46-1.46C5.4,17.16,7.9,11.13,11.5,7.53c0.57-0.57,0.57-1.5,0-2.07c-0.57-0.57-1.5-0.57-2.07,0C5.27,9.61,2.99,15.13,2.99,21C2.99,21.8,3.64,22.46,4.45,22.46" />
        </g>
    </svg>
);

export default NotificationIcon;


