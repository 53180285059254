export const
    REGISTER_USER_RESET = "REGISTER_USER_RESET",
    REGISTER_USER_NEW_REQUEST = "REGISTER_USER_NEW_REQUEST",
    REGISTER_USER_NEW_REQUEST_SUCCESS = "REGISTER_USER_NEW_REQUEST_SUCCESS",
    REGISTER_USER_NEW_REQUEST_FAILURE = "REGISTER_USER_NEW_REQUEST_FAILURE",
    REGISTER_USER_SET_STEP = "REGISTER_USER_SET_STEP",
    REGISTER_USER_UPDATE = "REGISTER_USER_UPDATE",
    REGISTER_USER_UPDATE_AVATAR = "REGISTER_USER_UPDATE_AVATAR",
    REGISTER_USER_UPDATE_OTHER_DOCUMENTS = "REGISTER_USER_UPDATE_OTHER_DOCUMENTS",
    REGISTER_USER_UPDATE_COMPLIANCE_DOCUMENTS = "REGISTER_USER_UPDATE_COMPLIANCE_DOCUMENTS",
    REGISTER_USER_SLED_CHECK_REQUEST = "REGISTER_USER_SLED_CHECK_REQUEST",
    REGISTER_USER_SLED_CHECK_REQUEST_SUCCESS = "REGISTER_USER_SLED_CHECK_REQUEST_SUCCESS",
    REGISTER_USER_SLED_CHECK_REQUEST_FAILURE = "REGISTER_USER_SLED_CHECK_REQUEST_FAILURE",
    REGISTER_USER_SAVE_REQUEST = "REGISTER_USER_SAVE_REQUEST",
    REGISTER_USER_SAVE_REQUEST_SUCCESS = "REGISTER_USER_SAVE_REQUEST_SUCCESS",
    REGISTER_USER_SAVE_REQUEST_FAILURE = "REGISTER_USER_SAVE_REQUEST_FAILURE",
    REGISTER_USER_POST_SAVE = "REGISTER_USER_POST_SAVE";

export function registerUserPostSave(postSaveAction) {
    return {
        type: REGISTER_USER_POST_SAVE,
        postSaveAction
    };
}

export function registerUserReset() {
    return {
        type: REGISTER_USER_RESET
    };
}

export function registerUserNew() {
    return {
        type: REGISTER_USER_NEW_REQUEST
    };
}

export function registerUserNewSuccess(user, venues, securityFirms, states, genders, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, avatar) {
    return {
        type: REGISTER_USER_NEW_REQUEST_SUCCESS,
        user,
        venues,
        securityFirms,
        states,
        genders,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes,
        avatar
    };
}

export function registerUserNewFailure(error) {
    return {
        type: REGISTER_USER_NEW_REQUEST_FAILURE,
        error
    };
}

export function registerUserSetStep(step) {
    return {
        type: REGISTER_USER_SET_STEP,
        step
    };
}

export function registerUserUpdate(user, venueIds, securityFirmIds) {
    return {
        type: REGISTER_USER_UPDATE,
        user,
        venueIds,
        securityFirmIds
    };
}

export function registerUserUpdateAvatar(avatar) {
    return {
        type: REGISTER_USER_UPDATE_AVATAR,
        avatar
    }
}

export function registerUserSledCheckRequest(user) {
    return {
        type: REGISTER_USER_SLED_CHECK_REQUEST,
        user
    };
}

export function registerUserSledCheckRequestSuccess(user) {
    return {
        type: REGISTER_USER_SLED_CHECK_REQUEST_SUCCESS,
        user
    };
}

export function registerUserSledCheckRequestFailure(error) {
    return {
        type: REGISTER_USER_SLED_CHECK_REQUEST_FAILURE,
        error
    };
}

export function registerUserUpdateOtherDocuments(otherDocuments) {
    return {
        type: REGISTER_USER_UPDATE_OTHER_DOCUMENTS,
        otherDocuments
    }
}

export function registerUserUpdateComplianceDocuments(complianceDocuments) {
    return {
        type: REGISTER_USER_UPDATE_COMPLIANCE_DOCUMENTS,
        complianceDocuments
    }

}

export function registerUserSaveRequest() {
    return {
        type: REGISTER_USER_SAVE_REQUEST
    };
}

export function registerUserSaveRequestSuccess(user) {
    return {
        type: REGISTER_USER_SAVE_REQUEST_SUCCESS,
        user
    };
}

export function registerUserSaveRequestFailure(error) {
    return {
        type: REGISTER_USER_SAVE_REQUEST_FAILURE,
        error
    };
}