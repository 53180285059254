import { connect } from 'react-redux'
import RequestUserRole from '../components/userRoles/requestUserRole/RequestUserRole'
import { profileVenuesAvailableToRequestRequest, profileSecurityFirmsAvailableToRequestRequest, profileUserRoleRequest } from '../common/actions/profile'

const isLoading = (isVenuesLoading: boolean, isSecurityFirmsLoading: boolean) => {
    return isVenuesLoading || isSecurityFirmsLoading;
}

const mapStateToProps = (state, props) => {
    return {
        isLoading: isLoading(state.profile.isVenuesLoading, state.profile.isSecurityFirmsLoading),
        venues: state.profile.venuesAvailableToRequest,
        securityFirms: state.profile.securityFirmsAvailableToRequest,
        onRequested: props.onRequested,
        isSaving: state.profile.isSaving,
        error: state.profile.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onVenuesAvailableToRequest: () => {
            dispatch(profileVenuesAvailableToRequestRequest())
        },
        onSecurityFirmsAvailableToRequest: () => {
            dispatch(profileSecurityFirmsAvailableToRequestRequest())
        },
        onRequestUserRole: (registerAs, venueIds, securityFirmIds) => {
            dispatch(profileUserRoleRequest(registerAs, venueIds, securityFirmIds))
        }
    }
}

const RequestUserRoleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestUserRole)

export default RequestUserRoleContainer