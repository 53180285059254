import { connect } from 'react-redux'
import CheckList from '../components/checklists/checklist/CheckList'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as notificationActions from '../common/actions/notification';
import * as printActions from '../common/actions/print';
import * as checklistActions from '../common/actions/checklist';

const mapStateToProps = (state, props) => {
    return {
        checklistId: props.match.params.checklistId,
        checklist: state.checklist.checklist,
        checklistLoading: state.checklist.checklistLoading,
        checklistSaving: state.checklist.checklistSaving,
        checklistTemplate: state.checklistTemplate.checklistTemplate,
        checklistUserSelections: state.checklistTemplate.checklistTemplate.checklistUserSelections,
        checklistTemplateLoading: state.checklistTemplate.checklistTemplateLoading,
        items: state.checklist.checklistDisplayItems,
        isUploading: state.checklist.isUploading,
        checklistIsModified: state.checklist.checklistModified,
        canApprove: permissionsLogic.hasPermissionForState(state, "ChecklistApprove"),
        canReject: permissionsLogic.hasPermissionForState(state, "ChecklistReject"),
        canClose: permissionsLogic.hasPermissionForState(state, "ChecklistClose"),
        canArchive: permissionsLogic.hasPermissionForState(state, "ChecklistArchive"),
        canPrint: permissionsLogic.hasPermissionForState(state, "ChecklistPrint"),
        canViewList: permissionsLogic.hasPermissionForState(state, "ChecklistListView"),
        isReadonly: (state.checklist.checklist.obsolete || state.checklist.checkListIsReadonly),
        hasErrors: state.checklist.checkListHasErrors
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoadCheckList: (checklistId) => {
            dispatch(checklistActions.loadChecklistRequest(checklistId))
        },
        onUploadAttachment: (files, checklistTemplateItemId) => {
            dispatch(checklistActions.checklistItemUploadRequest(files, checklistTemplateItemId))
        },
        onUpdate: (checklist) => {
            dispatch(checklistActions.updateChecklist(checklist))
        },
        onAutoSave: () => {
            dispatch(checklistActions.autoSaveCurrentChecklistRequest())
        },
        onSubmit: () => {
            dispatch(checklistActions.submitChecklist())
        },
        onSave: (checklist) => {
            dispatch(checklistActions.saveChecklistRequest(checklist))
        },
        onErrorNotification: (message) => {
            dispatch(notificationActions.notificationShow(message));
        },
        onPrint: () => {
            dispatch(printActions.printShow())
        }
    }
}
const CheckListContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckList))

export default CheckListContainer