import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import RadioControlGroup from '../../controls/RadioControlGroup';
import { RadioOption } from '../../../common/dto/common';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IRoleTypesFilterProps {
    filter: AusComplyDTOs.UserRoleTypeFilter;
    industryCategories: AusComplyDTOs.IndustryCategory[];
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IRoleTypesFilterState {
    filter: AusComplyDTOs.UserRoleTypeFilter;
}

export default class RoleTypesFilter extends React.Component<IRoleTypesFilterProps, IRoleTypesFilterState> {
    constructor(props: IRoleTypesFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRoleForChanged = this.onRoleForChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.UserRoleTypeFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onChangeMulti(fields: { fieldName: string, value: any }[]) {
        let filter = { ... this.state.filter };
        fields.forEach(field => {
            filter[field.fieldName] = field.value;
        })
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onRoleForChanged(value){
        if(value && value.value){
            const fieldValue = value.value;

            if(fieldValue == 'GROUP') {
                this.onChangeMulti([
                    { fieldName: 'isGroupRole', value: true},
                    { fieldName: 'isVenueRole', value: false},
                    { fieldName: 'isSecurityRole', value: false},
                    { fieldName: 'isMixedVenueAndSecurityRole', value: false}
                ]);
            } else if(fieldValue == 'VENUE') {
                this.onChangeMulti([
                    { fieldName: 'isGroupRole', value: false},
                    { fieldName: 'isVenueRole', value: true},
                    { fieldName: 'isSecurityRole', value: false},
                    { fieldName: 'isMixedVenueAndSecurityRole', value: false}
                ]);
            } else if(fieldValue == 'SECURITY') {
                this.onChangeMulti([
                    { fieldName: 'isGroupRole', value: false},
                    { fieldName: 'isVenueRole', value: false},
                    { fieldName: 'isSecurityRole', value: true},
                    { fieldName: 'isMixedVenueAndSecurityRole', value: false}
                ]);
            } else if(fieldValue == 'BOTH') {
                this.onChangeMulti([
                    { fieldName: 'isGroupRole', value: false},
                    { fieldName: 'isVenueRole', value: false},
                    { fieldName: 'isSecurityRole', value: false},
                    { fieldName: 'isMixedVenueAndSecurityRole', value: true}
                ]);
            } else if(fieldValue == 'ANY') {
                this.onChangeMulti([
                    { fieldName: 'isGroupRole', value: false},
                    { fieldName: 'isVenueRole', value: false},
                    { fieldName: 'isSecurityRole', value: false},
                    { fieldName: 'isMixedVenueAndSecurityRole', value: false}
                ]);
            }
        }
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        const radioOptions: RadioOption[] = [
            { id: "GROUP", name: "Group" },
            { id: "VENUE", name: "Venue" },
            { id: "SECURITY", name: "Security" },
            { id: "BOTH", name: "Both" },
            { id: "ANY", name: "Any" }
        ];

        const selectedRoleForValue = 
        this.state.filter.isGroupRole ? 'GROUP' 
        : this.state.filter.isVenueRole ? 'VENUE' 
        : this.state.filter.isSecurityRole ? 'SECURITY'
        : this.state.filter.isMixedVenueAndSecurityRole ? 'BOTH' 
        : 'ANY';

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch()} />
                </Grid>
            </Grid>
        );

        return (
            <LayoutForm>
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChange('search', value);
                        this.props.onSearch();
                    }}
                    onChange={value => this.onChange('search', value)} />
                    <RadioControlGroup
                        selected={selectedRoleForValue}
                        text={"Venue or Security role"}
                        name="roleFor"
                        options={radioOptions}
                        onChanged={this.onRoleForChanged} />
                    <SelectControlGroup 
                        text="Industry Category"
                        defaultValue={this.state.filter.industryCategoryId}
                        readonly={!this.state.filter.isVenueRole || this.state.filter.isMixedVenueAndSecurityRole}
                        onChange={(value) => this.onChange("industryCategoryId", value)}
                        labelAbove={true}>
                        <option value={undefined} />
                        {this.props.industryCategories && this.props.industryCategories.map((item) => (
                            <option key={"industry-category-" + item.industryCategoryId} value={item.industryCategoryId}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    <CheckboxControlGroup
                        text={"Deleted"}
                        defaultValue={this.state.filter.deleted}
                        onChanged={(value) => this.onChange("deleted", value)}/>
                {resetButton}
            </LayoutForm>
        );
    }
}