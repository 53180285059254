export const
USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST = "USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST",
USER_VENUE_SECURITYFIRM_STATISTICS_RESPONSE = "USER_VENUE_SECURITYFIRM_STATISTICS_RESPONSE",
USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST = "USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST",
USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_SUCCESS = "USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_SUCCESS",
USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_FAILURE = "USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_FAILURE",
USER_VENUE_SECURITYFIRM_STATISTICS_RESET = "USER_VENUE_SECURITYFIRM_STATISTICS_RESET";

export function userVenueSecurityFirmLoadStatistics() {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST
    };
}

export function userVenueSecurityFirmQuietLoadStatistics() {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_QUIET_REQUEST
    };
}

export function userVenueSecurityFirmResetStatistics() {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_RESET
    };
}

export function userVenueSecurityFirmLoadStatisticsResponse(statistics) {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_RESPONSE,
        statistics
    };
}

export function userVenueSecurityFirmLoadStatisticsSuccess(statistics) {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_SUCCESS,
        statistics
    };
}

export function userVenueSecurityFirmLoadStatisticsFailure(error) {
    return {
        type: USER_VENUE_SECURITYFIRM_STATISTICS_REQUEST_FAILURE,
        error
    };
}