import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SubTitle from '../../common/SubTitle';
import TableContainer from '../../layout/TableContainer';
import List from '../../common/List';
import UserRoleRequestCard from './UserRoleRequestCard';
import UserRoleRequestRow from './UserRoleRequestRow';
import UserRoleRequestHeader from './UserRoleRequestHeader';

export interface IUserRoleRequestsProps {
    userRoleRequests: AusComplyDtos.UserRoleRequest[];
    onDismiss?: Function;
}

export default class UserRoleRequests extends Component<IUserRoleRequestsProps, any> {

    render() {
        if (!this.props.userRoleRequests || this.props.userRoleRequests.length == 0) {
            return (<></>);
        }

        let cardList = this.props.userRoleRequests.map((item) => {
            return (
                <UserRoleRequestCard
                    key={"card-" + item.notificationID.toString()}
                    userRoleRequest={item}
                    onDismiss={this.props.onDismiss}
                />
            );
        });


        let rows = this.props.userRoleRequests.map((item) => {
            return (
                <UserRoleRequestRow
                    key={"row-" + item.notificationID.toString()}
                    userRoleRequest={item}
                    onDismiss={this.props.onDismiss} />
            );
        });

        let rowList = (
            <TableContainer
                header={<UserRoleRequestHeader />}
                rows={rows}
            />
        );

        return (
            <>
                <SubTitle text={"Role Requests"}></SubTitle>
                <List
                    loading={false}
                    count={this.props.userRoleRequests.length}
                    cards={cardList}
                    rows={rowList}
                />
            </>
        );
    }
}