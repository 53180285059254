
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { permissionsLogic } from '../logic/permissionsLogic';
import { venueServiceApi } from '../services/venueService';
import { securityFirmServiceApi } from '../services/securityFirmService';

import {
    VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST,
    VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE,
    VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST,
    VENUE_SECURITYFIRM_SELECT,
    venueSecurityFirmLoadAvailableSuccess,
    venueSecurityFirmLoadAvailableSecurityFirms,
    venueSecurityFirmLoadAvailableSecurityFirmsSuccess
} from "../actions/venueSecurityFirm";

import * as venueActions from "../actions/venue";

import {
    securityFirmSubAssociationsRefresh
} from "../actions/securityFirmAssociations";

import * as securityFirmAssociationActions from "../actions/securityFirmAssociations";

import {
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST,
    securityFirmVenueAssociationsRequestSuccess,
    securityFirmVenueAssociationsRequestFailure
} from "../actions/securityFirm";

import {
    userDetailsRequest, USER_DETAILS_REQUEST_SUCCESS
} from '../actions/userLogin';

import {
    userVenueSecurityFirmLoadStatistics,
    userVenueSecurityFirmResetStatistics
} from "../actions/userVenueSecurityFirm";
import {
    notifyError, notifyErrorMessage
} from './common';

const startChangeSecurityFirmEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getAvailable(state.location.latitude, state.location.longitude)
                .pipe(
                    map(response => venueSecurityFirmLoadAvailableSuccess(response.result, response.canSelectNoVenue)),
                    catchError(error => notifyErrorMessage(error, "Failed to load available venues", venueSecurityFirmLoadAvailableSuccess))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load available venues", venueSecurityFirmLoadAvailableSuccess))
    );

const loadVenueSecurityFirmsEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE),
        map(() => venueSecurityFirmLoadAvailableSecurityFirms())
    );

const loadVenueAvailableSecurityFirmEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getAvailableSecurityFirms(state.venueSecurityFirm.venueId)
                .pipe(
                    map(response => venueSecurityFirmLoadAvailableSecurityFirmsSuccess(response.result)),
                    catchError(error => notifyErrorMessage(error, "Failed to load available venues"))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load available venues"))
    );

const venueAndSecurityFirmSelectedEpic = action$ =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_SELECT),
        map((action: any) => userDetailsRequest(action['venueId'], action['securityFirmId'], action['venueEventId']))
    );

const venueAndSecurityFirmSelectedStatisticsResetEpic = action$ =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_SELECT),
        map((action: any) => userVenueSecurityFirmResetStatistics())
    );

const venueAndSecurityFirmSelectedLoadStatisticsEpic = action$ =>
    action$.pipe(
        ofType(USER_DETAILS_REQUEST_SUCCESS),
        map((action: any) => userVenueSecurityFirmLoadStatistics())
    );

const venueSecurityFirmAssociationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST, venueActions.VENUE_SECURITYFIRM_ASSOCIATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getSecurityFirmAssociations(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueSecurityFirmAssociations.venueId, //action['forVenueId'],
                state.venue.venueSecurityFirmAssociations.paging.page, //action['page'],
                state.venue.venueSecurityFirmAssociations.paging.pageSize, //action['pageSize']
                permissionsLogic.isLevelFourVenueRoleOrSix(state)
            ).pipe(
                map(response => venueActions.venueSecurityAssociationsRequestSuccess(response.data, response.paging)),
                catchError(error => notifyError(error, "venueSecurityFirmAssociationsRequestEpic.getSecurityFirmAssociations", venueActions.venueSecurityAssociationsRequestFailure))
            )
        ),
        catchError(error => notifyError(error, "venueSecurityFirmAssociationsRequestEpic", venueActions.venueSecurityAssociationsRequestFailure))
    );

const venueSecurityAssociationCreateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.createVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 0)
                .pipe(
                    map(response => venueActions.venueSecurityAssociationCreateRequestSuccess(response.venueSecurityFirm, response.securityFirms)),
                    catchError(error => notifyError(error, "venueSecurityAssociationCreateRequestEpic.createVenueSecurityFirmAssociation", venueActions.venueSecurityAssociationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSecurityAssociationCreateRequestEpic", venueActions.venueSecurityAssociationCreateRequestFailure))
    );

const venueSecurityAssociationInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.saveVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueSecurityFirmAssociation.venueSecurityFirm)
                .pipe(
                    map(response => venueActions.venueSecurityAssociationInsertRequestSuccess()),
                    catchError(error => notifyError(error, "venueSecurityAssociationInsertRequestEpic.saveVenueSecurityFirmAssociation", venueActions.venueSecurityAssociationInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSecurityAssociationInsertRequestEpic", venueActions.venueSecurityAssociationInsertRequestFailure))
    );

const venueSecurityAssociationInsertRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS),
        map((action: any) => venueActions.venueSecurityAssociationReset())
    );

const venueSecurityAssociationSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS, venueActions.VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS, venueActions.VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS),
        map((action: any) => venueActions.venueSecurityAssociationsRefresh())
    );

const venueSecurityAssociationRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.deleteVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueSecurityFirmId'])
                .pipe(
                    map(response => venueActions.venueSecurityAssociationRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "venueSecurityAssociationRemoveRequestEpic.deleteVenueSecurityFirmAssociation", venueActions.venueSecurityAssociationRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSecurityAssociationRemoveRequestEpic", venueActions.venueSecurityAssociationRemoveRequestFailure))
    );

const venueSecurityAssociationRestoreRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueActions.VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.restoreVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueSecurityFirmId'])
                .pipe(
                    map(response => venueActions.venueSecurityAssociationRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "venueSecurityAssociationRestoreRequestEpic.restoreVenueSecurityFirmAssociation", venueActions.venueSecurityAssociationRestoreRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueSecurityAssociationRestoreRequestEpic", venueActions.venueSecurityAssociationRestoreRequestFailure))
    );

const securityFirmVenueAssociationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.getSecurityFirmVenueAssociations(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirm.securityFirmVenueAssociations.securityFirmId,
                state.securityFirm.securityFirmVenueAssociations.paging.page,
                state.securityFirm.securityFirmVenueAssociations.paging.pageSize 
            ).pipe(
                map(response => securityFirmVenueAssociationsRequestSuccess(response.data, response.paging)),
                catchError(error => notifyError(error, "securityFirmVenueAssociationsRequestEpic.getSecurityFirmVenueAssociations", securityFirmVenueAssociationsRequestFailure))
            )
        ),
        catchError(error => notifyError(error, "securityFirmVenueAssociationsRequestEpic", securityFirmVenueAssociationsRequestFailure))
    );


// SECURITY_FIRM_ASSOCIATIONS

const securityFirmAssociationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATIONS_REQUEST, securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getSecurityFirmAssociations2(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirmAssociations.venueSecurityFirms.venueId, //action['forVenueId'],
                state.securityFirmAssociations.venueSecurityFirms.securityFirmId, //action['forSecurityFirmId'],
                state.securityFirmAssociations.venueSecurityFirms.paging.page, //action['page'],
                state.securityFirmAssociations.venueSecurityFirms.paging.pageSize //action['pageSize']
            ).pipe(
                map(response => securityFirmAssociationActions.securityFirmAssociationsRequestSuccess(response.data, response.paging)),
                catchError(error => notifyError(error, "securityFirmAssociationsRequestEpic.getSecurityFirmAssociations2", securityFirmAssociationActions.securityFirmAssociationsRequestFailure))
            )
        ),
        catchError(error => notifyError(error, "securityFirmAssociationsRequestEpic", securityFirmAssociationActions.securityFirmAssociationsRequestFailure))
    );

const securityFirmAssociationCreateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.createVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], action['forParentSecurityFirmId'])
                .pipe(
                    map(response => securityFirmAssociationActions.securityFirmAssociationCreateRequestSuccess(response.venueSecurityFirm, response.securityFirms)),
                    catchError(error => notifyError(error, "securityFirmAssociationCreateRequestEpic.createVenueSecurityFirmAssociation", securityFirmAssociationActions.securityFirmAssociationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmAssociationCreateRequestEpic", securityFirmAssociationActions.securityFirmAssociationCreateRequestFailure))
    );

const securityFirmAssociationInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.saveVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirmAssociations.venueSecurityFirm.venueSecurityFirm)
                .pipe(
                    map(response => securityFirmAssociationActions.securityFirmAssociationInsertRequestSuccess()),
                    catchError(error => notifyError(error, "securityFirmAssociationInsertRequestEpic.saveVenueSecurityFirmAssociation", securityFirmAssociationActions.securityFirmAssociationInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmAssociationInsertRequestEpic", securityFirmAssociationActions.securityFirmAssociationInsertRequestFailure))
    );

const securityFirmAssociationInsertRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS),
        map((action: any) => securityFirmAssociationActions.securityFirmAssociationReset())
    );

const securityFirmAssociationSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS, securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS, securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS),
        map((action: any) => securityFirmAssociationActions.securityFirmAssociationsRefresh())
    );

const securityFirmSubAssociationSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS, securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS, securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS),
        map((action: any) => securityFirmSubAssociationsRefresh())
    );


const securityFirmAssociationRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.deleteVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueSecurityFirmId'])
                .pipe(
                    map(response => securityFirmAssociationActions.securityFirmAssociationRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "securityFirmAssociationRemoveRequestEpic.deleteVenueSecurityFirmAssociation", securityFirmAssociationActions.securityFirmAssociationRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmAssociationRemoveRequestEpic", securityFirmAssociationActions.securityFirmAssociationRemoveRequestFailure))
    );

const securityFirmAssociationRestoreRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(securityFirmAssociationActions.SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.restoreVenueSecurityFirmAssociation(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueSecurityFirmId'])
                .pipe(
                    map(response => securityFirmAssociationActions.securityFirmAssociationRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "securityFirmAssociationRemoveRequestEpic.deleteVenueSecurityFirmAssociation", securityFirmAssociationActions.securityFirmAssociationRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmAssociationRemoveRequestEpic", securityFirmAssociationActions.securityFirmAssociationRemoveRequestFailure))
    );
    
// ~ SECURITY_FIRM_ASSOCIATION

export const venueSecurityFirmEpics = combineEpics(
    startChangeSecurityFirmEpic,
    loadVenueSecurityFirmsEpic,
    loadVenueAvailableSecurityFirmEpic,
    venueAndSecurityFirmSelectedEpic,
    venueAndSecurityFirmSelectedStatisticsResetEpic,
    venueAndSecurityFirmSelectedLoadStatisticsEpic,
    venueSecurityFirmAssociationsRequestEpic,
    venueSecurityAssociationCreateRequestEpic,
    venueSecurityAssociationInsertRequestEpic,
    venueSecurityAssociationRemoveRequestEpic,
    venueSecurityAssociationRestoreRequestEpic,
    venueSecurityAssociationInsertRequestSuccessEpic,
    venueSecurityAssociationSuccessRefreshEpic,
    securityFirmVenueAssociationsRequestEpic,
    securityFirmAssociationsRequestEpic,
    securityFirmAssociationCreateRequestEpic,
    securityFirmAssociationInsertRequestEpic,
    securityFirmAssociationInsertRequestSuccessEpic,
    securityFirmAssociationSuccessRefreshEpic,
    securityFirmAssociationRemoveRequestEpic,
    securityFirmSubAssociationSuccessRefreshEpic
);