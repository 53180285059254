import React, { Component } from 'react';
import { KeyName } from '../../../common/dto/common';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import AdminUserActionAuditContainer from '../../../containers/AdminUserActionAuditContainer';
import AdminUserChangePasswordContainer from '../../../containers/AdminUserChangePasswordContainer';
import UserRoleRequests from '../../userRoles/userRoleRequests/UserRoleRequests';
import UserRoles from '../../userRoles/UserRoles';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import ProfileOtherDocuments from '../../profile/ProfileOtherDocuments';
import ProfileComplianceDocuments from '../../profile/ProfileComplianceDocuments';
import Tabs from '../../common/Tabs';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import ControlGroup from "../../controls/ControlGroup";
import TextDisplay from '../../controls/TextDisplay';
import CardContent from '@material-ui/core/CardContent';
import CheckboxControl from "../../controls/CheckboxControl";
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import DefaultButton from '../../controls/DefaultButton';
import ProfileAvatarEdit from '../../profile/ProfileAvatarEdit';
import Box from '@material-ui/core/Box';
import Saving from '../../common/Saving';
import FullPageDialog from '../../layout/FullPageDialog';
import DefaultFab from '../../common/DefaultFab';
import PersonAddIcon from '../../../resources/PersonAddIcon';
import AddUserRoleContainer from '../../../containers/AddUserRoleContainer';
import EditUserRoleContainer from '../../../containers/EditUserRoleContainer';
import RemoveUserRoleContainer from '../../../containers/RemoveUserRoleContainer';
import UserComplianceDocuments from './UserComplianceDocuments';
import UserOtherDocuments from './UserOtherDocuments';
import UserRoleCard from '../UserRoleCard';
import UserRoleHeader from '../UserRoleHeader';
import UserRoleRow from '../UserRoleRow';
import List from '../../common/List';
import TableContainer from '../../layout/TableContainer';
import PageMessage from '../../common/PageMessage';
import FormWarningMessage from '../../alerts/FormWarningMessage';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import UserDirectoryContainer from '../../../containers/UserDirectoryContainer';


export interface IUserProps {
    groupId?: number;
    venueId?: number;
    securityFirmId?: number;
    userId: number;
    isLoading: boolean;
    user: AusComplyDtos.User;
    avatar: AusComplyDtos.File;
    otherDocuments: AusComplyDtos.UserOtherDocument[];
    otherDocumentTypes: AusComplyDtos.DocumentType[];
    complianceDocuments: AusComplyDtos.UserComplianceDocument[];
    complianceDocumentTypes: AusComplyDtos.DocumentType[];
    onLoad: Function;
    onUserRoleEnableRequest: Function;
    hasEditPermission: boolean,
    isVenueUserRoleType: boolean,
    isSecurityFirmRoleType: boolean,
    onSaveOtherDocuments: Function;
    onRemoveOtherDocument: Function;
    onSaveComplianceDocuments: Function;
    onRemoveComplianceDocument: Function;
    history: any;
    location: any;
}

interface IUserState {
    selectedTab: number;
    editUserRoleId: number;
    removeUserRoleId: number;
    showAddVenueUserRole: boolean;
    showAddSecurityFirmUserRole: boolean;
    obsoleteUserRoles: boolean;
}

export default class User extends Component<IUserProps, IUserState> {
    constructor(props: IUserProps) {
        super(props)
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSaveOtherDocuments = this.onSaveOtherDocuments.bind(this);
        this.onRemoveOtherDocument = this.onRemoveOtherDocument.bind(this);
        this.onSaveComplianceDocuments = this.onSaveComplianceDocuments.bind(this);
        this.onRemoveComplianceDocument = this.onRemoveComplianceDocument.bind(this);
        this.onMoreCommand = this.onMoreCommand.bind(this);
        this.onToggleShowAddVenueUserRole = this.onToggleShowAddVenueUserRole.bind(this);
        this.onToggleShowAddSecurityFirmUserRole = this.onToggleShowAddSecurityFirmUserRole.bind(this);
        this.onObsoleteUserRolesChanged = this.onObsoleteUserRolesChanged.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.state = {
            selectedTab: 0,
            editUserRoleId: 0,
            removeUserRoleId: 0,
            showAddVenueUserRole: false,
            showAddSecurityFirmUserRole: false,
            obsoleteUserRoles: false
        };
    }

    componentDidMount() {
        this.props.onLoad(this.props.userId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.userId != this.props.user.userId && this.props.userId > 0 && !this.props.isLoading) {
            this.props.onLoad(this.props.userId);
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onCancel() {
        if (this.props.groupId) {
            this.props.history.push('/groups/' + this.props.groupId);
        } else if (this.props.venueId) {
            var path = this.props.location.pathname.split('/');
            var updatedPath = path.pop();
            path[path.length - 1] = path[path.length - 1] + "s";
            var url = path.join('/');
            this.props.history.push(url);
            // this.props.history.push('/venue/users');
        } else if (this.props.securityFirmId) {
            var path = this.props.location.pathname.split('/');
            var updatedPath = path.pop();
            path[path.length - 1] = path[path.length - 1] + "s";
            var url = path.join('/');
            this.props.history.push(url);
            //this.props.history.push('/securityfirm/users');
        } else {
            this.props.history.push('/users');
        }
    }

    onObsoleteUserRolesChanged(value) {
        this.setState({ obsoleteUserRoles: value });
    }

    onMoreCommand(command) {

        switch (command) {
            case "addVenueUserRole":
                this.onToggleShowAddVenueUserRole(false);
                break;
            case "addSecurityFirmUserRole":
                this.onToggleShowAddSecurityFirmUserRole(false);
                break;
        }
    }

    onCommand(command: string, userRole: AusComplyDtos.UserRoleSummary) {

        switch (command) {
            case "enable":
                this.props.onUserRoleEnableRequest(userRole.userRoleId);
                break;
            case "edit":
                this.setState({ editUserRoleId: userRole.userRoleId, removeUserRoleId: 0 });
                break;
            case "delete":
                this.setState({ editUserRoleId: 0, removeUserRoleId: userRole.userRoleId });
                break;
            case "close":
                this.setState({ editUserRoleId: 0, removeUserRoleId: 0 });
                break;
            case "refresh":
                this.setState({ editUserRoleId: 0, removeUserRoleId: 0 });
                this.props.onLoad(this.props.userId);
                break;
            default:
                break;
        }

    }

    onToggleShowAddVenueUserRole(reload: boolean) {
        this.setState({ showAddVenueUserRole: !this.state.showAddVenueUserRole }, () => {
            if (reload) {
                this.props.onLoad(this.props.userId)
            }
        });
    }

    onToggleShowAddSecurityFirmUserRole(reload: boolean) {
        this.setState({ showAddSecurityFirmUserRole: !this.state.showAddSecurityFirmUserRole }, () => {
            if (reload) {
                this.props.onLoad(this.props.userId)
            }
        });
    }

    getCommands(): KeyName[] {
        let moreCommands: KeyName[] = [];
        if (this.props.user) {
            if (this.props.user.canAddVenueRoles) {
                moreCommands.push({ key: "addVenueUserRole", name: "Add Venue User Role" });
            }
            if (this.props.user.canAddSecurityRoles) {
                moreCommands.push({ key: "addSecurityFirmUserRole", name: "Add Security Firm User Role" });
            }
        }
        return moreCommands;
    }

    onSaveOtherDocuments(documentTypeId, files) {
        this.props.onSaveOtherDocuments(this.props.userId, documentTypeId, files);
    }

    onRemoveOtherDocument(otherDocument) {
        this.props.onRemoveOtherDocument(this.props.userId, otherDocument);
    }

    onSaveComplianceDocuments(documentTypeId, expiryDate, files) {
        this.props.onSaveComplianceDocuments(this.props.userId, documentTypeId, expiryDate, files);
    }

    onRemoveComplianceDocument(complianceDocument) {
        this.props.onRemoveComplianceDocument(this.props.userId, complianceDocument);
    }

    readonlyUser() {
        const details = this.props.user.canViewDetails;
        const basic = this.props.user.canViewBasic || details;

        if (!basic) {
            return (
                <FormWarningMessage><span>You do not have access to view this record</span></FormWarningMessage>
            );
        }
        return (
            <>
                {basic && (
                    <ControlGroup text="Name">
                        <TextDisplay>{this.props.user.displayName}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.rsaNumber && (
                    <ControlGroup text="RSA Number">
                        <TextDisplay>{this.props.user.rsaNumber}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.stateLicensed && (
                    <ControlGroup text="State Licensed">
                        <TextDisplay>{this.props.user.stateLicensed}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.licenceNumber && (
                    <ControlGroup text="Licence Number">
                        <TextDisplay>{this.props.user.licenceNumber}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.dateLastSledCheckDisplay && this.props.user.sledEnabled && (
                    <ControlGroup text="Last Licence Check">
                        <TextDisplay>{this.props.user.dateLastSledCheckDisplay}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.sledStatusDisplay && this.props.user.sledEnabled && this.props.user.stateId == 2 && (
                    <ControlGroup text="Licence Status">
                        <TextDisplay>{this.props.user.sledStatusDisplay}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.gender && (
                    <ControlGroup text="Gender">
                        <TextDisplay>{this.props.user.gender}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.dateOfBirth && (
                    <ControlGroup text="DOB">
                        <TextDisplay>{this.props.user.dateOfBirth.display}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.address && (
                    <ControlGroup text="Address">
                        <TextDisplay>{this.props.user.address}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.suburb && (
                    <ControlGroup text="Suburb">
                        <TextDisplay>{this.props.user.suburb}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.state && (
                    <ControlGroup text="State">
                        <TextDisplay>{this.props.user.state}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.postcode && (
                    <ControlGroup text="Postcode">
                        <TextDisplay>{this.props.user.postcode}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.email && (
                    <ControlGroup text="Email">
                        <TextDisplay>{this.props.user.email}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.mobileNo && (
                    <ControlGroup text="Mobile">
                        <TextDisplay>{this.props.user.mobileNo}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.emergencyContactName && (
                    <ControlGroup text="Emergency Contact Name">
                        <TextDisplay>{this.props.user.emergencyContactName}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.emergencyContactNumber && (
                    <ControlGroup text="Emergency Contact Mobile">
                        <TextDisplay>{this.props.user.emergencyContactNumber}</TextDisplay>
                    </ControlGroup>
                )}
                {details && this.props.user.emergencyContactNumber && (
                    <CheckboxControlGroup label=" " text={"I agree to the Terms and Conditions"} defaultValue={this.props.user.hasAcceptedTerms} readonly={true} />
                )}
            </>
        );
    }

    userRoles() {
        const details = this.props.user.canViewDetails;
        const basic = this.props.user.canViewBasic || details;

        if (!basic) {
            return (
                <FormWarningMessage><span>You do not have access to view this record</span></FormWarningMessage>
            );
        }

        let userRoleSummaries: AusComplyDtos.UserRoleSummary[] = [];
        if (this.props.user.userRoleSummaries) {
            userRoleSummaries = this.props.user.userRoleSummaries.filter((item) => this.state.obsoleteUserRoles || !item.userRoleObsolete);
        }
        let cardList;
        let rowList;
        let rows;

        if (userRoleSummaries.length > 0) {
            cardList = userRoleSummaries.map((item, index) => {
                return (
                    <UserRoleCard
                        onCommand={this.onCommand}
                        excludeViewEditCommands={true}
                        key={"card-" + item.userRoleId.toString()}
                        userRole={item}
                    />
                );
            });

            rows = userRoleSummaries.map((item, index) => {
                return (
                    <UserRoleRow
                        onCommand={this.onCommand}
                        excludeViewEditCommands={true}
                        key={"row-" + item.userRoleId.toString()}
                        userRole={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<UserRoleHeader />}
                rows={rows}
            />
        );
        return (<>
            <List
                loading={this.props.isLoading}
                cards={cardList}
                rows={rowList}
                count={userRoleSummaries.length}
            />
        </>);
    }

    readOnlyDocuments() {
        const details = this.props.user.canViewDetails;
        const basic = this.props.user.canViewBasic || details;

        if (!basic) {
            return (
                <FormWarningMessage><span>You do not have access to view this record</span></FormWarningMessage>
            );
        }

        if (this.props.user.canEditDocuments) {
            return (
                <>
                    <ProfileComplianceDocuments
                        showObsolete={false}
                        documents={this.props.complianceDocuments}
                        onSave={this.onSaveComplianceDocuments}
                        onRemove={this.onRemoveComplianceDocument}
                        documentTypes={this.props.complianceDocumentTypes}
                    />
                    <ProfileOtherDocuments
                        showObsolete={false}
                        documents={this.props.otherDocuments}
                        onSave={this.onSaveOtherDocuments}
                        onRemove={this.onRemoveOtherDocument}
                        documentTypes={this.props.otherDocumentTypes} />
                </>
            );
        }
        if (basic && !details) {
            // return only required compliance documents
            let complianceDocuments = [...this.props.complianceDocuments].filter(f => f.documentTypeIsRequired);
            return (
                <>
                    <UserComplianceDocuments documents={complianceDocuments} />
                </>
            );
        }
        if (details) {
            return (
                <>
                    <UserComplianceDocuments documents={this.props.complianceDocuments} />
                    <UserOtherDocuments documents={this.props.otherDocuments} />
                </>
            );
        }
        return <></>;
    }

    render() {
        let title = "User"
        const details = this.props.user.canViewDetails;
        const basic = this.props.user.canViewBasic || details;
        if (this.props.user && !this.props.isLoading) {
            title = this.props.user.displayName || title;
        }
        let tabs = <div style={{ display: 'display-block', marginTop: '10px' }}>
            <Tabs selected={this.state.selectedTab} labels={["Roles", "General", "Documents"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </div>;
        if (this.state.selectedTab == 2 && (basic || details)) {
            return <UserDirectoryContainer userId={this.props.user.userId} tabs={tabs} readOnly={!this.props.user.canEditDocuments} title={title} complianceDocumentsOnly={!details} />
        }
        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={false}
                    headerText={title}
                    moreCommands={this.getCommands()}
                    onCommand={this.onMoreCommand}
                    footerLeftContent={<TransparentButton text={"Back"} onClick={() => this.onCancel()} />}
                >
                    <form>
                        {tabs}
                        {this.state.selectedTab === 0 && (
                            <>
                                <CardContainer title={"Filter"}>
                                    <CardContent>
                                        <CheckboxControl
                                            text={"Show removed user roles"}
                                            defaultValue={this.state.obsoleteUserRoles}
                                            onChanged={this.onObsoleteUserRolesChanged} />
                                    </CardContent>
                                </CardContainer>
                                {this.userRoles()}
                            </>
                        )}
                        {this.state.selectedTab === 1 && (
                            <>
                                <ProfileAvatarEdit avatar={this.props.avatar} />
                                {this.readonlyUser()}
                            </>
                        )}
                        {this.state.selectedTab === 2 && (
                            <FormWarningMessage><span>You do not have access to view this record</span></FormWarningMessage>
                        )}
                    </form>
                </PageLayout>
                <FullPageDialog
                    open={this.state.showAddVenueUserRole}
                    title={"Add Venue User Role(s)"}
                    notFullWidth={true}
                    onDismissed={() => this.onToggleShowAddVenueUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddVenueUserRole && (
                        <AddUserRoleContainer userId={this.props.userId} onSuccess={() => this.onToggleShowAddVenueUserRole(true)} forSecurityFirm={false} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.showAddSecurityFirmUserRole}
                    title={"Add Security Firm User Role(s)"}
                    notFullWidth={true}
                    onDismissed={() => this.onToggleShowAddSecurityFirmUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddSecurityFirmUserRole && (
                        <AddUserRoleContainer userId={this.props.userId} onSuccess={() => this.onToggleShowAddSecurityFirmUserRole(true)} forSecurityFirm={true} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editUserRoleId != 0}
                    title={"Edit User Role"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditUserRoleContainer
                            userRoleId={this.state.editUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeUserRoleId != 0}
                    title={"Remove User Role"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.removeUserRoleId != 0) && (
                        <RemoveUserRoleContainer
                            userRoleId={this.state.removeUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
            </>
        );
    }
}