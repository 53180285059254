import { connect } from 'react-redux'
import Notifications from '../components/notifications/Notifications'
import { 
    notificationsRequest,
    notificationsActionRequest,
    notificationsActionsRequest
 } from '../common/actions/notifications'
import { incidentsRequiringApproval } from '../common/actions/incidents';

const mapStateToProps = state => {
    return {
        userId: state.user.details.userSession.user.userID,
        groupedNotifications: state.notifications.groupedNotifications,
        alerts: state.notifications.alerts,
        groupNotifications: state.notifications.groupNotifications,
        isLoading: state.notifications.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () => {
            dispatch(notificationsRequest())
        },
        onIncidentsRequiringApprovals: () => {
            dispatch(incidentsRequiringApproval())
        },
        onDismiss: (notificationId, actionResponse) => {
            dispatch(notificationsActionRequest(notificationId, actionResponse))
        },
        onDismissAll: (notificationIds, actionResponse) => {
            dispatch(notificationsActionsRequest(notificationIds, actionResponse))
        }
    }
}

const NotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications)

export default NotificationsContainer