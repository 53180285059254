import { connect } from 'react-redux'
import FacialRecognitionServers from '../components/facialRecognition/facialRecognitionServers/FacialRecognitionServers'
import {
    facialRecognitionServersRequest,
    facialRecognitionServersTestRequest, 
    facialRecognitionServerRequest, 
    facialRecognitionServerReset,
    facialRecognitionServerSet,
    facialRecognitionServerCreateRequest,
    facialRecognitionServerUpsertRequest
} from '../common/actions/facialRecognitionServer';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        adminMode: props.adminMode,
        isLoading: state.facialRecognitionServer.facialRecognitionServers.isLoading,
        isSaving: state.facialRecognitionServer.facialRecognitionServer.isSaving,
        facialRecognitionServers: state.facialRecognitionServer.facialRecognitionServers.facialRecognitionServers,
        facialRecognitionServer: state.facialRecognitionServer.facialRecognitionServer.facialRecognitionServer,
        facialRecognitionServerId: state.facialRecognitionServer.facialRecognitionServer.facialRecognitionServerId,
        isLoadingItem: state.facialRecognitionServer.facialRecognitionServer.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(facialRecognitionServersRequest(venueId))
        },
        onTest: (venueId) => {
            dispatch(facialRecognitionServersTestRequest(venueId))
        },
        onLoadItem: (facialRecognitionServerId) => {
            dispatch(facialRecognitionServerRequest(facialRecognitionServerId))
        },
        onCreate: (forVenueId) => {
            dispatch(facialRecognitionServerCreateRequest(forVenueId))
        },
        onResetItem: () => {
            dispatch(facialRecognitionServerReset())
        },
        onUpdate: (facialRecognitionServer) => {
            dispatch(facialRecognitionServerSet(facialRecognitionServer))
        },
        onSave: () => {
            dispatch(facialRecognitionServerUpsertRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionServerReset())
        }
    }
}

const AdminFacialRecognitionServersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionServers)

export default AdminFacialRecognitionServersContainer