import { connect } from 'react-redux'
import GroupRequestAccess from '../components/groups/group/requestAccess/GroupRequestAccess'
import { withRouter } from "react-router";
import {
    groupsSelectionRequest,
    groupAccessRequest
} from '../common/actions/groups';

const mapStateToProps = (state, props) => {
    return {
        show: props.show,
        groups: state.groups.groups.userRequestAvailable,
        isLoading: state.groups.groups.isLoading,
        isSaving: state.groups.group.isSavingUserRequest
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoad: () => {
            dispatch(groupsSelectionRequest())
        },
        onSave: (groupId) => {
            dispatch(groupAccessRequest(groupId));
        },
        onClose: () => {
            props.onClose();
        }
    }
}

const GroupRequestAccessContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupRequestAccess))

export default GroupRequestAccessContainer