import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import AdminFacialRecognitionWatchlistTypes from '../components/admin/adminFacialRecognitionWatchlistTypes/AdminFacialRecognitionWatchlistTypes'
import { 
    facialRecognitionNotificationWatchlistTypesRefresh, 
    facialRecognitionNotificationWatchlistTypesRequest, 
    facialRecognitionNotificationWatchlistTypeRequest, 
    facialRecognitionNotificationWatchlistTypeReset,
    facialRecognitionNotificationWatchlistTypeSet,
    facialRecognitionNotificationWatchlistTypeCreateRequest,
    facialRecognitionNotificationWatchlistTypeUpsertRequest
 } from '../common/actions/facialRecognitionNotification';

const mapStateToProps = (state, props) => {
    return {
        paging: state.facialRecognitionNotification.facialRecognitionWatchlistTypes.paging,
        filter: state.facialRecognitionNotification.facialRecognitionWatchlistTypes.filter,
        isLoading: state.facialRecognitionNotification.facialRecognitionWatchlistTypes.isLoading,
        facialRecognitionWatchlistTypes: state.facialRecognitionNotification.facialRecognitionWatchlistTypes.facialRecognitionWatchlistTypes,
        facialRecognitionWatchlistType: state.facialRecognitionNotification.facialRecognitionWatchlistType.facialRecognitionWatchlistType,
        facialRecognitionWatchlistTypeId: state.facialRecognitionNotification.facialRecognitionWatchlistType.facialRecognitionWatchlistTypeId,
        isLoadingItem: state.facialRecognitionNotification.facialRecognitionWatchlistType.isLoading,
        isSaving: state.facialRecognitionNotification.facialRecognitionWatchlistType.isSaving,
        canCreate: permissionsLogic.hasPermissionForState(state, "EditReferenceData")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (filter, page, pageSize) => {
            dispatch(facialRecognitionNotificationWatchlistTypesRequest(page, pageSize, filter))
        },
        onLoadItem: (facialRecognitionWatchlistTypeId) => {
            dispatch(facialRecognitionNotificationWatchlistTypeRequest(facialRecognitionWatchlistTypeId))
        },
        onCreate: () => {
            dispatch(facialRecognitionNotificationWatchlistTypeCreateRequest())
        },
        onResetItem: () => {
            dispatch(facialRecognitionNotificationWatchlistTypeReset())
        },
        onUpdate: (facialRecognitionWatchlistType) => {
            dispatch(facialRecognitionNotificationWatchlistTypeSet(facialRecognitionWatchlistType))
        },
        onSave: () => {
            dispatch(facialRecognitionNotificationWatchlistTypeUpsertRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionNotificationWatchlistTypeReset())
        }
    }
}

const AdminFacialRecognitionWatchlistTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminFacialRecognitionWatchlistTypes))

export default AdminFacialRecognitionWatchlistTypesContainer