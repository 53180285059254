import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICardTitleProps {
    theme: any;
    classes: any;
    isObsolete?: boolean;
    text?: string;
    style?: any;
}

class CardTitle extends React.PureComponent<ICardTitleProps, any> {
    constructor(props: ICardTitleProps){
        super(props)
    }
    render() {
        const { theme, classes } = this.props;

        return (
            <h5
                className={this.props.isObsolete ? classes.cardTitleObsolete : classes.cardTitle}
                style={{ ...this.props.style }}
                >{this.props.text}{this.props.children}</h5>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardTitle);