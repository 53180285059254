import React, { Component } from 'react';
import BroadcastNotificationsContainer from '../../../containers/BroadcastNotificationsContainer'

export interface IVenueBroadcastNotifications {
    venueId: number;
}

export default class VenueBroadcastNotifications extends Component<IVenueBroadcastNotifications, any> {
    render() {
        return (
            <BroadcastNotificationsContainer venueId={this.props.venueId}/>
        );
    }
}