import React, { Component } from 'react';
import { commonLogic } from '../../common/logic/common';

export default class ErrorBoundary extends Component<any, any> {
    constructor(props) {
        super(props)

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        commonLogic.trackException(error, "Error Boundary", {});
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // get the current state
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ color: 'white', backgroundColor: 'red', padding: '10px' }}>
                    <h2>Something went wrong.</h2>
                    {(this.state.error ) && (
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    )}
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}