import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FacialRecognitionNotifications from '../components/venue/facialRecognitionNotifications/FacialRecognitionNotifications'
import { 
    facialRecognitionNotificationFilterRequest, 
    facialRecognitionNotificationSetItem,
    facialRecognitionNotificationSetPaging,
    facialRecognitionNotificationSetFilter,
    facialRecognitionNotificationGetRequest,
    facialRecognitionNotificationProcessRequest
 } from '../common/actions/facialRecognitionNotification';

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        paging: state.facialRecognitionNotification.paging,
        filter: state.facialRecognitionNotification.filter,
        isLoading: state.facialRecognitionNotification.isLoading,
        facialRecognitionNotifications: state.facialRecognitionNotification.facialRecognitionNotifications,
        isLoadingItem: state.facialRecognitionNotification.facialRecognitionNotification.isLoading,
        facialRecognitionNotification: state.facialRecognitionNotification.facialRecognitionNotification.facialRecognitionNotification,
        canManageCameras: permissionsLogic.hasPermissionForState(state, 'ManageVenueCameras'),
        canManageWatchlists: permissionsLogic.hasPermissionForState(state, 'ManageVenueWatchlists'),
        canManageEvents: permissionsLogic.hasPermissionForState(state, 'ManageVenueEvents'),
        canManageEnrolments: permissionsLogic.hasPermissionForState(state, 'ManageVenueEnrolments'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadItem: (facialRecognitionNotificationId) => {
            dispatch(facialRecognitionNotificationGetRequest(facialRecognitionNotificationId))
        },
        onSetItem: (facialRecognitionNotification) => {
            dispatch(facialRecognitionNotificationSetItem(facialRecognitionNotification))
        },
        onLoadFilter: () => {
            dispatch(facialRecognitionNotificationFilterRequest())
        },
        onSetFilter: (filter) => {
            dispatch(facialRecognitionNotificationSetFilter(filter))
        },
        onSetPaging: (paging) => {
            dispatch(facialRecognitionNotificationSetPaging(paging))
        },
        onProcessItem: (facialRecognitionNotificationId) => {
            dispatch(facialRecognitionNotificationProcessRequest(facialRecognitionNotificationId))
        }
    }
}

const VenueFacialRecognitionNotificationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionNotifications))

export default VenueFacialRecognitionNotificationsContainer