import { connect } from 'react-redux';
import SignOnOffRegister from '../components/signon/SignOnOffRegister';
import { printShow } from '../common/actions/print';
import { withRouter } from "react-router";
import * as signOnActions from '../common/actions/signOn';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const isSecurityRole = (user) => {
    if (user){
        return user.userRoleType.indexOf("Security") > -1;
    }
    return false;
}

const canViewSignOnRegister = (userSignOnSummary) => {
    if (userSignOnSummary){
        return userSignOnSummary.canViewSignOnRegister;
    }
    return false;
}

const mapStateToProps = state => {
    return {
        isLoading: state.signOn.isLoading,
        items: state.signOn.signOnRegister.items,
        paging: state.signOn.signOnRegister.paging,
        hasOpenReportingPeriod: state.user.details.userSignOnSummary.hasOpenReportingPeriod,
        canStartReportingPeriod: state.user.details.userSignOnSummary.canStartReportingPeriod,
        isSecurityRole: isSecurityRole(state.user.details.user),
        canViewSignOnRegister: canViewSignOnRegister(state.user.details.userSignOnSummary),
        canSignOn: state.user.details.userSignOnSummary.canSignOn,
        showEvents: permissionsLogic.hasPermissionForState(state, "ViewEvent"),
        filter: state.signOn.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter) => {
            dispatch(signOnActions.signOnRegisterRequest(filter))
        },
        onLoadFilter: (reset) => {
            dispatch(signOnActions.signOnRegisterFilterRequest(reset))
        },
        onPrint: () => {
            dispatch(printShow())
        },
        onBreak: (signOnRegisterId) => {
            dispatch(signOnActions.signOnBreakRequest(signOnRegisterId))
        }
    }
}

const SignOnOffRegisterContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnOffRegister))

export default SignOnOffRegisterContainer