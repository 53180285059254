import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { number } from "prop-types";
import * as request from './common';

export const notificationServiceApi = {
    userRoleRequestNotification: (registerAs: string, venueIds: number[], securityFirmIds: number[]) => {
        var client = new AusComplyJsonServiceClient();
        var query = new AusComplyDtos.SendUserRoleRequest();
        
        if (registerAs == "SECURITYFIRM" || registerAs == "SECURITY") {
            query.securityFirmIds = securityFirmIds;
        } else {
            query.venueIds = venueIds;
        }
        const request = client.post(query);
        return from(request);
    },
    getNotifications: (venueId: number, securityFirmId: number) => {
        var client = new AusComplyJsonServiceClient();
        var query = new AusComplyDtos.GetNotifications();
        query.venueId = venueId;
        query.securityFirmId = securityFirmId;
        const request = client.get(query);
        return from(request);
    },
    actionNotification: (venueId: number, securityFirmId: number, notificationId: number, action: boolean) => {
        var client = new AusComplyJsonServiceClient();
        var query = new AusComplyDtos.SendNotificationAction();
        query.venueId = venueId;
        query.securityFirmId = securityFirmId;
        query.notificationId = notificationId;
        query.actionResponse = action;
        const request = client.post(query);
        return from(request);
    },
    actionNotifications: (venueId: number, securityFirmId: number, notificationIds: number[], action: boolean) => {
        var client = new AusComplyJsonServiceClient();
        var query = new AusComplyDtos.SendNotificationsAction();
        query.venueId = venueId;
        query.securityFirmId = securityFirmId;
        query.notificationIds = notificationIds;
        query.actionResponse = action;
        const request = client.post(query);
        return from(request);
    },
    venueClear: request.makePost(
        AusComplyDtos.ClearVenueNotifications,
        'venueId', 'securityFirmId', 'forVenueId', 'simpleDate')
    ,
    securityFirmClear: request.makePost(
        AusComplyDtos.ClearSecurityFirmNotifications,
        'venueId', 'securityFirmId', 'forSecurityFirmId', 'simpleDate')
};
