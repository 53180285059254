import { connect } from 'react-redux'
import IncidentCategoryTypes from '../components/admin/incidentCategoryTypes/IncidentCategoryTypes'
import { withRouter } from "react-router";
import {
    adminIncidentCategoryTypesRequest,
    adminIncidentCategoryTypeCreateNewRequest, 
    adminIncidentCategoryTypeUpdateRequest,
    adminIncidentCategoryTypeSet,
} from '../common/actions/admin';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.incidentCategoryTypes.isLoading,
        isSaving: state.admin.incidentCategoryTypes.isSaving,
        incidentCategoryTypes: state.admin.incidentCategoryTypes.incidentCategoryTypes,
        incidentCategoryType: state.admin.incidentCategoryTypes.incidentCategoryType,
        paging: state.admin.incidentCategoryTypes.paging,
        filter: state.admin.incidentCategoryTypes.filter,
        states: state.admin.incidentCategoryTypes.states,
        error: state.admin.incidentCategoryTypes.error,
        canAdd: permissionsLogic.hasPermissionForState(state, "AddIncidentCategory"),
        canEdit: permissionsLogic.hasPermissionForState(state, "EditIncidentCategory"),
        canDelete: permissionsLogic.hasPermissionForState(state, "DelIncidentCategory"),
        canEditNotifications: permissionsLogic.hasPermissionForState(state, "MaintainUserRoleNotification")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (filter, page, pageSize) => {
            dispatch(adminIncidentCategoryTypesRequest(page, pageSize, filter))
        },
        onCreateNew: () => {
            dispatch(adminIncidentCategoryTypeCreateNewRequest())
        },
        onSet: (incidentCategoryType) => {
            dispatch(adminIncidentCategoryTypeSet(incidentCategoryType))
        },
        onUpdate: (incidentCategoryType) => {
            dispatch(adminIncidentCategoryTypeUpdateRequest(incidentCategoryType))
        }
    }
}

const IncidentCategoryTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentCategoryTypes))

export default IncidentCategoryTypesContainer