import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import FullPageDialog from '../layout/FullPageDialog';
import ResponsiveList from '../common/ResponsiveList';
import ConfirmDialog from '../controls/ConfirmDialog';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import { KeyName } from '../../common/dto/common';
import SimpleFilter from '../common/SimpleFilter';
import SimpleFilterSummary from '../common/SimpleFilterSummary';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../controls/ControlGroup';
import View from '../common/View';
import { Palette } from '../../common/constants/palette';
import Box from '@material-ui/core/Box';
import PrimaryButton from '../controls/PrimaryButton';
import TextControl from '../controls/TextControl';
import TextAreaControl from '../controls/TextAreaControl';
import TextAreaControlGroup from '../controls/TextAreaControlGroup';
import Saving from '../common/Saving';

export interface INotesProps {
    entityType: AusComplyDtos.ngtEntityType;
    entityId: number;
    wrapInCard?: boolean;
    isLoading: boolean;
    isSaving: boolean;
    note: AusComplyDtos.Note;
    newNote: AusComplyDtos.Note;
    notes: AusComplyDtos.Note[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    onLoad: Function;
    onUpdateExisting: Function;
    onUpdateNew: Function;
    onSaveExisting: Function;
    onSaveNew: Function;
    onResetExisting: Function;
}

interface INotesState {
    confirmRemove: boolean;
    noteToRemove?: AusComplyDtos.Note;
}

export default class Notes extends Component<INotesProps, INotesState> {
    constructor(props: INotesProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            noteToRemove: undefined
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading) {
            if (this.props.entityId != prevProps.entityId || this.props.entityType != prevProps.entityType) {
                this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
            }
        }
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        preparedFilter.entityId = this.props.entityId;
        preparedFilter.entityType = this.props.entityType;
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(note: AusComplyDtos.Note) {
        var commands: KeyName[] = [];
        if (note.canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "remove", name: "Remove" });
        }
        return commands;
    }

    onCommand(command: string, note: AusComplyDtos.Note) {
        switch (command) {
            case "edit":
                this.props.onUpdateExisting(note);
                break;
            case "remove":
                var updated = { ...note };
                updated.obsolete = true;
                this.props.onUpdateExisting(updated);
                this.setState({
                    confirmRemove: true,
                    noteToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.props.onResetExisting();
        this.setState({
            confirmRemove: false,
            noteToRemove: undefined
        });
    }

    removeConfirmed() {
        if (this.props.onSaveExisting) {
            this.props.onSaveExisting();
            this.setState({
                confirmRemove: false,
                noteToRemove: undefined
            });
        }
    }

    onNewValueChanged(fieldName: string, value: any) {
        let newNote = { ...this.props.newNote };
        newNote[fieldName] = value;
        this.props.onUpdateNew(newNote);
    }

    onExistingValueChanged(fieldName: string, value: any) {
        let existingNote = { ...this.props.note };
        existingNote[fieldName] = value;
        this.props.onUpdateExisting(existingNote);
    }

    render() {
        let self = this;
        let content = <>
            {this.props.isSaving ? <Saving /> : <>
                <Box display="flex" flexDirection="row" justifyContent="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Box flex={1}>
                        <TextAreaControl value={this.props.newNote.text} onChange={value => this.onNewValueChanged("text", value)} placeholder='New note...' minRows={1} />
                    </Box>
                    <Box>
                        <Box display="flex" flexDirection="col" justifyContent="left">
                            <Box>
                                <PrimaryButton text='Save' onClick={this.props.onSaveNew} disabled={this.props.newNote.text == undefined || this.props.newNote.text == ""} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.notes}
                    paging={this.props.paging}
                    onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                    onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                    headers={["", "", ""]}
                    columns={["text", "createdDisplay", "locationDisplay"]}
                    styles={[{}, { fontStyle: 'italic', color: Palette.Obsolete, padding: 0 }, { fontStyle: 'italic', color: Palette.Obsolete, padding: 0, fontSize: '8px' }]}
                    cardsOnly={true}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isObsolete={item => item.obsolete}
                    canEdit={true}
                />
            </>
            }
            <FullPageDialog
                open={this.props.note.noteId > 0 && this.state.noteToRemove == undefined && !this.props.isSaving && !this.props.isLoading}
                title="Note"
                saving={this.props.isSaving}
                notFullWidth={true}
                footerRightContent={<PrimaryButton text={"Save"} disabled={this.props.isSaving} onClick={this.props.onSaveExisting}></PrimaryButton>}
                onDismissed={() => this.props.onResetExisting()}>
                <TextAreaControlGroup
                    text={"Edit note"}
                    defaultValue={this.props.note.text}
                    onChange={value => this.onExistingValueChanged("text", value)}
                />
            </FullPageDialog>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this note?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </>;


        return this.props.wrapInCard ? <CardContainer
            title={"Notes"}
            style={{ paddingTop: 0 }}>
            <CardContent style={{ paddingTop: 0 }}>
                {content}
            </CardContent>
        </CardContainer> : <>{content}</>;

        /*
        return (<PageLayout
            headerText={"Functional Areas"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            {filterSummary}
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.functionalAreas}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Name"]}
                columns={["name"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                isSuccess={item => item.groupId !== undefined && item.groupId > 0}
                isSuccessLabel={"Group"}
                isInfo={item => item.groupId !== undefined && item.groupId == 0 && item.venueId !== undefined && item.venueId == 0}
                isInfoLabel={"Global"}
                canEdit={true}
            />
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <FullPageDialog
                open={this.props.functionalAreaId != -1}
                title="Functional Area"
                notFullWidth={true}
                onDismissed={() => this.props.onResetItem()}>
                {functionalArea}
            </FullPageDialog>
        </PageLayout>);*/
    }
}