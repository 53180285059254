import { makeActionCreator } from './helpers';

export const
    SIGNONREGISTERTYPES_REQUEST = "SIGNONREGISTERTYPES_REQUEST",
    SIGNONREGISTERTYPES_REQUEST_SUCCESS = "SIGNONREGISTERTYPES_REQUEST_SUCCESS",
    SIGNONREGISTERTYPES_REQUEST_FAILURE = "SIGNONREGISTERTYPES_REQUEST_FAILURE",
    SIGNONREGISTERTYPES_REFRESH = "SIGNONREGISTERTYPES_REFRESH",
    SIGNONREGISTERTYPE_CREATE_REQUEST = "SIGNONREGISTERTYPE_CREATE_REQUEST",
    SIGNONREGISTERTYPE_CREATE_REQUEST_SUCCESS = "SIGNONREGISTERTYPE_CREATE_REQUEST_SUCCESS",
    SIGNONREGISTERTYPE_CREATE_REQUEST_FAILURE = "SIGNONREGISTERTYPE_CREATE_REQUEST_FAILURE",
    SIGNONREGISTERTYPE_REQUEST = "SIGNONREGISTERTYPE_REQUEST",
    SIGNONREGISTERTYPE_REQUEST_SUCCESS = "SIGNONREGISTERTYPE_REQUEST_SUCCESS",
    SIGNONREGISTERTYPE_REQUEST_FAILURE = "SIGNONREGISTERTYPE_REQUEST_FAILURE",
    SIGNONREGISTERTYPE_UPDATE = "SIGNONREGISTERTYPE_UPDATE",
    SIGNONREGISTERTYPE_SAVE_REQUEST = "SIGNONREGISTERTYPE_SAVE_REQUEST",
    SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS = "SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS",
    SIGNONREGISTERTYPE_SAVE_REQUEST_FAILURE = "SIGNONREGISTERTYPE_SAVE_REQUEST_FAILURE",
    SIGNONREGISTERTYPE_RESET = "SIGNONREGISTERTYPE_RESET";


export const signOnRegisterTypesRequest = makeActionCreator(SIGNONREGISTERTYPES_REQUEST, 'page', 'pageSize', 'filter');
export const signOnRegisterTypesRequestSuccess = makeActionCreator(SIGNONREGISTERTYPES_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const signOnRegisterTypesRequestFailure = makeActionCreator(SIGNONREGISTERTYPES_REQUEST_FAILURE, 'error');
export const signOnRegisterTypeCreateRequest = makeActionCreator(SIGNONREGISTERTYPE_CREATE_REQUEST, 'forGroupId', 'forVenueId');
export const signOnRegisterTypeCreateRequestSuccess = makeActionCreator(SIGNONREGISTERTYPE_CREATE_REQUEST_SUCCESS, 'signOnRegisterType');
export const signOnRegisterTypeCreateRequestFailure = makeActionCreator(SIGNONREGISTERTYPE_CREATE_REQUEST_FAILURE, 'error');
export const signOnRegisterTypeRequest = makeActionCreator(SIGNONREGISTERTYPE_REQUEST, 'signOnRegisterTypeId');
export const signOnRegisterTypeRequestSuccess = makeActionCreator(SIGNONREGISTERTYPE_REQUEST_SUCCESS, 'signOnRegisterType');
export const signOnRegisterTypeRequestFailure = makeActionCreator(SIGNONREGISTERTYPE_REQUEST_FAILURE, 'error');
export const signOnRegisterTypeSaveRequest = makeActionCreator(SIGNONREGISTERTYPE_SAVE_REQUEST, 'signOnRegisterType');
export const signOnRegisterTypeSaveRequestSuccess = makeActionCreator(SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS, 'signOnRegisterType');
export const signOnRegisterTypeSaveRequestFailure = makeActionCreator(SIGNONREGISTERTYPE_SAVE_REQUEST_FAILURE, 'error');
export const signOnRegisterTypeUpdate = makeActionCreator(SIGNONREGISTERTYPE_UPDATE, 'signOnRegisterType');
export const signOnRegisterTypesRefresh = makeActionCreator(SIGNONREGISTERTYPES_REFRESH);
export const signOnRegisterTypeReset = makeActionCreator(SIGNONREGISTERTYPE_RESET);