import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import {
    VENUE_REQUEST,
    VENUE_REQUEST_SUCCESS,
    VENUE_REQUEST_FAILURE,
    VENUE_UPDATE,

    VENUE_SAVE_REQUEST,
    VENUE_SAVE_REQUEST_SUCCESS,
    VENUE_SAVE_REQUEST_FAILURE,

    VENUE_REPORTING_PERIODS_REQUEST,
    VENUE_REPORTING_PERIODS_REQUEST_FAILURE,
    VENUE_REPORTING_PERIODS_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST,
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_RESET,
    VENUE_REPORTING_PERIOD_REQUEST,
    VENUE_REPORTING_PERIOD_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_CREATE_REQUEST,
    VENUE_REPORTING_PERIOD_CREATE_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_CREATE_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST,
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_SET,
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST,
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST,
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_SCHEDULE_RESET,
    VENUE_REPORTING_PERIOD_SCHEDULE_SET,
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST,
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST,
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_FAILURE,
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST,
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS,
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_FAILURE,

    VENUE_LOCATIONS_REFRESH,
    VENUE_LOCATIONS_REQUEST,
    VENUE_LOCATIONS_REQUEST_SUCCESS,
    VENUE_LOCATIONS_REQUEST_FAILURE,
    VENUE_LOCATION_RESET,
    VENUE_LOCATION_SET,
    VENUE_LOCATION_REQUEST,
    VENUE_LOCATION_REQUEST_SUCCESS,
    VENUE_LOCATION_REQUEST_FAILURE,
    VENUE_LOCATION_CREATE_REQUEST,
    VENUE_LOCATION_CREATE_REQUEST_SUCCESS,
    VENUE_LOCATION_CREATE_REQUEST_FAILURE,
    VENUE_LOCATION_UPSERT_REQUEST,
    VENUE_LOCATION_UPSERT_REQUEST_SUCCESS,
    VENUE_LOCATION_UPSERT_REQUEST_FAILURE,

    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST,
    VENUE_INCIDENT_CATEGORY_TYPES_REFRESH,
    VENUE_INCIDENT_CATEGORY_TYPES_FILTER_SET,
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS,
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE,
    VENUE_INCIDENT_CATEGORY_TYPE_RESET,
    VENUE_INCIDENT_CATEGORY_TYPE_SET,
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST,
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS,
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE,
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST,
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS,
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE,
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST,
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS,
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE,

    VENUE_CONTACTS_REFRESH,
    VENUE_CONTACTS_REQUEST,
    VENUE_CONTACTS_REQUEST_SUCCESS,
    VENUE_CONTACTS_REQUEST_FAILURE,
    VENUE_CONTACT_RESET,
    VENUE_CONTACT_SET,
    VENUE_CONTACT_REQUEST,
    VENUE_CONTACT_REQUEST_SUCCESS,
    VENUE_CONTACT_REQUEST_FAILURE,
    VENUE_CONTACT_CREATE_REQUEST,
    VENUE_CONTACT_CREATE_REQUEST_SUCCESS,
    VENUE_CONTACT_CREATE_REQUEST_FAILURE,
    VENUE_CONTACT_UPSERT_REQUEST,
    VENUE_CONTACT_UPSERT_REQUEST_SUCCESS,
    VENUE_CONTACT_UPSERT_REQUEST_FAILURE,

    VENUE_SUBSCRIPTION_SUMMARY_REQUEST,
    VENUE_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS,
    VENUE_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE,

    VENUE_DOCUMENTS_REQUEST,
    VENUE_DOCUMENTS_REQUEST_SUCCESS,
    VENUE_DOCUMENTS_REQUEST_FAILURE,
    VENUE_OTHER_DOCUMENTS_SAVE,
    VENUE_OTHER_DOCUMENTS_SAVE_SUCCESS,
    VENUE_OTHER_DOCUMENTS_SAVE_FAILURE,
    VENUE_OTHER_DOCUMENT_REMOVE,
    VENUE_OTHER_DOCUMENT_REMOVE_SUCCESS,
    VENUE_OTHER_DOCUMENT_REMOVE_FAILURE,
    VENUE_COMPLIANCE_DOCUMENTS_SAVE,
    VENUE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
    VENUE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
    VENUE_COMPLIANCE_DOCUMENT_REMOVE,
    VENUE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
    VENUE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,

    VENUE_SECURITYFIRM_ASSOCIATIONS_REFRESH,
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST,
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_FAILURE,
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_ASSOCIATION_RESET,
    VENUE_SECURITYFIRM_ASSOCIATION_SET,
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST,
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_FAILURE,
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST,
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_FAILURE,
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST,
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE

} from "../actions/venue";

import { venueLogic } from '../logic/venueLogic';

const initialState = {
    venueId: 0,
    venue: {},
    industryCategories: [],
    precincts: [],
    licenceTypes: [],
    tradingHours: [],
    states: [],
    isSaving: false,
    isLoading: false,
    error: null,
    errors: {},
    reportingPeriods: {
        isLoading: false,
        reportingPeriods: [],
        paging: { pageSize: 0, page: 1 }
    },
    reportingPeriod: {
        venueReportingPeriodId: -1,
        isLoading: false,
        isSaving: false,
        reportingPeriod: { venueReportingPeriodId: 0 }
    },
    reportingPeriodCurrent: {
        isLoading: false,
        isSaving: false,
        reportingPeriod: { venueReportingPeriodId: 0 },
        eventReportingPeriods: []
    },
    venueReportingPeriodSchedules: {
        isLoading: false,
        venueReportingPeriodSchedules: [],
    },
    venueReportingPeriodSchedule: {
        venueReportingPeriodScheduleId: -1,
        isLoading: false,
        isSaving: false,
        venueReportingPeriodSchedule: { venueReportingPeriodScheduleId: 0 }
    },
    venueLocations: {
        isLoading: false,
        filter: {},
        venueLocations: [],
        paging: { pageSize: 0, page: 1 }
    },
    venueLocation: {
        venueLocationId: -1,
        isLoading: false,
        isSaving: false,
        venueLocation: { venueLocationId: 0 }
    },
    incidentCategoryTypes: {
        isLoading: false,
        filter: {},
        incidentCategoryTypes: [],
        paging: { pageSize: 0, page: 1 }
    },
    incidentCategoryType: {
        incidentCategoryTypeId: -1,
        isLoading: false,
        isSaving: false,
        incidentCategoryType: { incidentCategoryTypeId: 0 }
    },
    venueContacts: {
        isLoading: false,
        filter: {},
        venueContacts: [],
        paging: { pageSize: 0, page: 1 }
    },
    venueContact: {
        venueContactId: -1,
        isLoading: false,
        isSaving: false,
        venueContactTypes: [],
        venueUsers: [],
        venueContact: { venueContactId: 0 }
    },
    venueSubscriptionSummary: {
        isLoading: false,
        error: null,
        venueSubscriptionSummary: { venueId: 0 }
    },
    documents: {
        isSaving: false,
        isLoading: false,
        error: null,
        otherDocuments: [],
        otherDocumentTypes: [],
        complianceDocuments: [],
        complianceDocumentTypes: [],
        associatedComplianceDocuments: [],
        associatedComplianceDocumentTypes: []
    },
    venueSecurityFirmAssociations: {
        venueId: 0,
        isLoading: false,
        venueSecurityFirmAssociations: [],
        paging: { pageSize: 0, page: 1 }
    },
    venueSecurityFirmAssociation: {
        isLoading: false,
        isSaving: false,
        venueSecurityFirm: { venueSecurityFirmId: -1, securityFirmId: 0 },
        securityFirms: [],
        error: ""
    }
};

function loadingVenueSecurityFirmAssociation(state, isLoading, error) {
    let newState = update(state, {
        venueSecurityFirmAssociation: {
            isLoading: { $set: isLoading },
            isSaving: { $set: false },
            error: { $set: error }
        }
    });
    return newState;
}

function savingVenueSecurityFirmAssociation(state, isSaving, error) {
    let newState = update(state, {
        venueSecurityFirmAssociation: {
            isLoading: { $set: false },
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setVenueSecurityFirmAssociation(state, venueSecurityFirm) {
    let newState = update(state, {
        venueSecurityFirmAssociation: {
            venueSecurityFirm: { $set: venueSecurityFirm }
        }
    });
    return newState;
}

function setVenueSecurityFirmAssociationOptions(state, securityFirms) {
    let newState = update(state, {
        venueSecurityFirmAssociation: {
            securityFirms: { $set: securityFirms }
        }
    });
    return newState;
}

function loadingVenueSecurityFirmAssociations(state, isLoading) {
    let newState = update(state, {
        venueSecurityFirmAssociations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueSecurityFirmAssociations(state, venueSecurityFirmAssociations, paging) {
    let newState = update(state, {
        venueSecurityFirmAssociations: {
            venueSecurityFirmAssociations: { $set: venueSecurityFirmAssociations },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setVenueSecurityFirmAssociationsVenueId(state, venueId) {
    let newState = update(state, {
        venueSecurityFirmAssociations: {
            venueId: { $set: venueId }
        }
    });
    return newState;
}

function loadingDocuments(state, isLoading, error) {
    let newState = update(state, {
        documents: {
            isLoading: { $set: isLoading },
            isSaving: { $set: false },
            error: { $set: error }
        }
    });
    return newState;
}

function savingDocuments(state, isSaving, error) {
    let newState = update(state, {
        documents: {
            isLoading: { $set: false },
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setDocuments(state, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, associatedComplianceDocuments, associatedComplianceDocumentTypes) {
    let newState = update(state, {
        documents: {
            otherDocuments: { $set: otherDocuments },
            otherDocumentTypes: { $set: otherDocumentTypes },
            complianceDocuments: { $set: complianceDocuments },
            complianceDocumentTypes: { $set: complianceDocumentTypes },
            associatedComplianceDocuments: { $set: associatedComplianceDocuments },
            associatedComplianceDocumentTypes: { $set: associatedComplianceDocumentTypes }
        }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        documents: {
            otherDocuments: { $set: otherDocuments }
        }
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        documents: {
            complianceDocuments: { $set: complianceDocuments }
        }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: venueLogic.validateVenue(state.venue) }
    });
    return newState;
}

function loadingVenue(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setOptions(state, includesOptions, states, industryCategories, precincts, licenceTypes, tradingHours) {
    if (!includesOptions) {
        return state;
    }
    let newState = update(state, {
        states: { $set: states },
        industryCategories: { $set: industryCategories },
        precincts: { $set: precincts },
        licenceTypes: { $set: licenceTypes },
        tradingHours: { $set: tradingHours }
    });
    return newState;
}

function setVenue(state, venue, venueId) {
    let newState = update(state, {
        venue: { $set: venue },
        venueId: { $set: venueId }
    });
    return newState;
}

function loadingReportingPeriods(state, isLoading) {
    let newState = update(state, {
        reportingPeriods: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setReportingPeriods(state, reportingPeriods, paging) {
    let newState = update(state, {
        reportingPeriods: {
            reportingPeriods: { $set: reportingPeriods },
            paging: { $set: paging },
        }
    });
    return newState;
}

function loadingReportingPeriod(state, isLoading) {
    let newState = update(state, {
        reportingPeriod: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setReportingPeriod(state, reportingPeriod) {
    let newState = update(state, {
        reportingPeriod: {
            reportingPeriod: { $set: reportingPeriod }
        }
    });
    return newState;
}

function savingReportingPeriod(state, isSaving) {
    let newState = update(state, {
        reportingPeriod: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setReportingPeriodId(state, venueReportingPeriodId) {
    let newState = update(state, {
        reportingPeriod: {
            venueReportingPeriodId: { $set: venueReportingPeriodId }
        }
    });
    return newState;
}

function loadingCurrentReportingPeriod(state, isLoading) {
    let newState = update(state, {
        reportingPeriodCurrent: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingCurrentReportingPeriod(state, isSaving) {
    let newState = update(state, {
        reportingPeriodCurrent: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setCurrentReportingPeriod(state, reportingPeriod, eventReportingPeriods) {
    reportingPeriod = reportingPeriod || { venueReportingPeriodId: 0 };
    let newState = update(state, {
        reportingPeriodCurrent: {
            reportingPeriod: { $set: reportingPeriod },
            eventReportingPeriods: { $set: eventReportingPeriods }
        }
    });
    return newState;
}

function loadingReportingPeriodSchedules(state, isLoading) {
    let newState = update(state, {
        venueReportingPeriodSchedules: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setReportingPeriodSchedules(state, venueReportingPeriodSchedules) {
    let newState = update(state, {
        venueReportingPeriodSchedules: {
            venueReportingPeriodSchedules: { $set: venueReportingPeriodSchedules }
        }
    });
    return newState;
}

function loadingReportingPeriodSchedule(state, isLoading) {
    let newState = update(state, {
        venueReportingPeriodSchedule: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setReportingPeriodSchedule(state, venueReportingPeriodSchedule) {
    let newState = update(state, {
        venueReportingPeriodSchedule: {
            venueReportingPeriodSchedule: { $set: venueReportingPeriodSchedule }
        }
    });
    return newState;
}

function savingReportingPeriodSchedule(state, isSaving) {
    let newState = update(state, {
        venueReportingPeriodSchedule: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setReportingPeriodScheduleId(state, venueReportingPeriodScheduleId) {
    let newState = update(state, {
        venueReportingPeriodSchedule: {
            venueReportingPeriodScheduleId: { $set: venueReportingPeriodScheduleId }
        }
    });
    return newState;
}

function loadingVenueLocations(state, isLoading) {
    let newState = update(state, {
        venueLocations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueLocations(state, venueLocations, filter, paging) {
    let newState = update(state, {
        venueLocations: {
            venueLocations: { $set: venueLocations },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingVenueLocation(state, isLoading) {
    let newState = update(state, {
        venueLocation: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueLocation(state, venueLocation) {
    let newState = update(state, {
        venueLocation: {
            venueLocation: { $set: venueLocation }
        }
    });
    return newState;
}

function savingVenueLocation(state, isSaving) {
    let newState = update(state, {
        venueLocation: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setVenueLocationId(state, venueLocationId) {
    let newState = update(state, {
        venueLocation: {
            venueLocationId: { $set: venueLocationId }
        }
    });
    return newState;
}

function loadingVenueIncidentCategoryTypes(state, isLoading) {
    let newState = update(state, {
        incidentCategoryTypes: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueIncidentCategoryTypes(state, incidentCategoryTypes, filter, paging) {
    let newState = update(state, {
        incidentCategoryTypes: {
            incidentCategoryTypes: { $set: incidentCategoryTypes },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setVenueIncidentCategoryTypesFilter(state, filter) {
    let newState = update(state, {
        incidentCategoryTypes: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function loadingVenueIncidentCategoryType(state, isLoading) {
    let newState = update(state, {
        incidentCategoryType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueIncidentCategoryType(state, incidentCategoryType) {
    let newState = update(state, {
        incidentCategoryType: {
            incidentCategoryType: { $set: incidentCategoryType }
        }
    });
    return newState;
}

function savingVenueIncidentCategoryType(state, isSaving) {
    let newState = update(state, {
        incidentCategoryType: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setVenueIncidentCategoryTypeId(state, incidentCategoryTypeId) {
    let newState = update(state, {
        incidentCategoryType: {
            incidentCategoryTypeId: { $set: incidentCategoryTypeId }
        }
    });
    return newState;
}

function loadingVenueContacts(state, isLoading) {
    let newState = update(state, {
        venueContacts: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueContacts(state, venueContacts, filter, paging) {
    let newState = update(state, {
        venueContacts: {
            venueContacts: { $set: venueContacts },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingVenueContact(state, isLoading) {
    let newState = update(state, {
        venueContact: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueContact(state, venueContact, venueContactTypes, venueUsers) {
    let newState = update(state, {
        venueContact: {
            venueContact: { $set: venueContact },
            venueContactTypes: { $set: venueContactTypes },
            venueUsers: { $set: venueUsers }
        }
    });
    return newState;
}

function savingVenueContact(state, isSaving) {
    let newState = update(state, {
        venueContact: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setVenueContactId(state, venueContactId) {
    let newState = update(state, {
        venueContact: {
            venueContactId: { $set: venueContactId }
        }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function loadingVenueSubscription(state, isLoading, error) {
    let newState = update(state, {
        venueSubscriptionSummary: {
            isLoading: { $set: isLoading },
            error: { $set: error },
        }
    });
    return newState;
}

function setVenueSubscriptionSummary(state, venueSubscriptionSummary) {
    let newState = update(state, {
        venueSubscriptionSummary: {
            venueSubscriptionSummary: { $set: venueSubscriptionSummary },
        }
    });
    return newState;
}

export default function venue(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        case VENUE_REQUEST:
            return setError(setVenue(loadingVenue(state, true), {}, action.venueId), null);
        case VENUE_REQUEST_SUCCESS:
            return setErrors(setOptions(
                setVenue(loadingVenue(state, false), action.venue, action.venue.venueId),
                action.includesOptions, action.states, action.industryCategories, action.precincts, action.licenceTypes, action.tradingHours
            ));
        case VENUE_SAVE_REQUEST:
            return saving(setError(state, null), true);
        case VENUE_SAVE_REQUEST_SUCCESS:
            return saving(setVenue(state, action.venue, action.venue.venueId), false);
        case VENUE_SAVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case VENUE_UPDATE:
            return setErrors(setVenue(state, action.venue, action.venue.venueId));
        case VENUE_REQUEST_FAILURE:
            return loadingVenue(state, false);
        case VENUE_REPORTING_PERIODS_REQUEST:
            return setReportingPeriods(loadingReportingPeriods(setReportingPeriodId(state, -1), true), {}, { pageSize: action.pageSize, page: action.page });
        case VENUE_REPORTING_PERIODS_REQUEST_SUCCESS:
            return setReportingPeriods(loadingReportingPeriods(state, false), action.reportingPeriods, action.paging);
        case VENUE_REPORTING_PERIODS_REQUEST_FAILURE:
            return loadingReportingPeriods(state, false);
        case VENUE_REPORTING_PERIOD_RESET:
            return setReportingPeriodId(state, -1);
        case VENUE_REPORTING_PERIOD_REQUEST:
            return setReportingPeriod(loadingReportingPeriod(setReportingPeriodId(state, action.venueReportingPeriodId), true), {});
        case VENUE_REPORTING_PERIOD_REQUEST_SUCCESS:
            return setReportingPeriod(loadingReportingPeriod(state, false), action.reportingPeriod);
        case VENUE_REPORTING_PERIOD_REQUEST_FAILURE:
            return loadingReportingPeriod(setReportingPeriodId(state, -1), false);
        case VENUE_REPORTING_PERIOD_CREATE_REQUEST:
            return setReportingPeriod(loadingReportingPeriod(setReportingPeriodId(state, 0), true), {});;
        case VENUE_REPORTING_PERIOD_CREATE_REQUEST_SUCCESS:
            return setReportingPeriod(loadingReportingPeriod(state, false), action.venueReportingPeriod);;
        case VENUE_REPORTING_PERIOD_CREATE_REQUEST_FAILURE:
            return loadingReportingPeriod(setReportingPeriodId(state, -1), false);
        case VENUE_REPORTING_PERIOD_UPSERT_REQUEST:
            return savingReportingPeriod(state, true);
        case VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS:
            return setReportingPeriod(savingReportingPeriod(setReportingPeriodId(state, -1), false), action.venueReportingPeriod);
        case VENUE_REPORTING_PERIOD_UPSERT_REQUEST_FAILURE:
            return savingReportingPeriod(state, false);
        case VENUE_REPORTING_PERIOD_CURRENT_REQUEST:
            return setCurrentReportingPeriod(loadingCurrentReportingPeriod(savingCurrentReportingPeriod(state, false), true), { venueReportingPeriodId: 0 }, []);
        case VENUE_REPORTING_PERIOD_CURRENT_REQUEST_SUCCESS:
            return setCurrentReportingPeriod(loadingCurrentReportingPeriod(state, false), action.venueReportingPeriod, action.venueEventReportingPeriods);
        case VENUE_REPORTING_PERIOD_CURRENT_REQUEST_FAILURE:
            return loadingReportingPeriod(state, false);
        case VENUE_REPORTING_PERIOD_SET:
            return setReportingPeriod(state, action.venueReportingPeriod);
        case VENUE_REPORTING_PERIOD_CLOSE_REQUEST:
            return savingCurrentReportingPeriod(state, true);
        case VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS:
            return savingCurrentReportingPeriod(state, false);
        case VENUE_REPORTING_PERIOD_CLOSE_REQUEST_FAILURE:
            return savingCurrentReportingPeriod(state, false);

        case VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST:
            return setReportingPeriodSchedules(loadingReportingPeriodSchedules(setReportingPeriodScheduleId(state, -1), true), {});
        case VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_SUCCESS:
            return setReportingPeriodSchedules(loadingReportingPeriodSchedules(state, false), action.venueReportingPeriodSchedules);
        case VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_FAILURE:
            return loadingReportingPeriodSchedules(state, false);
        case VENUE_REPORTING_PERIOD_SCHEDULE_RESET:
            return setReportingPeriodScheduleId(state, -1);
        case VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST:
            return setReportingPeriodSchedule(loadingReportingPeriodSchedule(setReportingPeriodScheduleId(state, action.venueReportingPeriodScheduleId), true), {});
        case VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_SUCCESS:
            return setReportingPeriodSchedule(loadingReportingPeriodSchedule(state, false), action.venueReportingPeriodSchedule);
        case VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_FAILURE:
            return loadingReportingPeriodSchedule(setReportingPeriodScheduleId(state, -1), false);
        case VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST:
            return setReportingPeriodSchedule(loadingReportingPeriodSchedule(setReportingPeriodScheduleId(state, 0), true), {});;
        case VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_SUCCESS:
            return setReportingPeriodSchedule(loadingReportingPeriodSchedule(state, false), action.venueReportingPeriodSchedule);;
        case VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_FAILURE:
            return loadingReportingPeriodSchedule(setReportingPeriodScheduleId(state, -1), false);
        case VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST:
            return savingReportingPeriodSchedule(state, true);
        case VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS:
            return setReportingPeriodSchedule(savingReportingPeriodSchedule(setReportingPeriodScheduleId(state, -1), false), action.venueReportingPeriodSchedule);
        case VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_FAILURE:
            return savingReportingPeriodSchedule(state, false);
        case VENUE_REPORTING_PERIOD_SCHEDULE_SET:
            return setReportingPeriodSchedule(state, action.venueReportingPeriodSchedule);

        case VENUE_LOCATIONS_REFRESH:
            return loadingVenueLocations(setVenueLocationId(state, -1), true);
        case VENUE_LOCATIONS_REQUEST:
            return setVenueLocations(loadingVenueLocations(setVenueLocationId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case VENUE_LOCATIONS_REQUEST_SUCCESS:
            return setVenueLocations(loadingVenueLocations(state, false), action.venueLocations, action.filter, action.paging);
        case VENUE_LOCATIONS_REQUEST_FAILURE:
            return loadingVenueLocations(state, false);
        case VENUE_LOCATION_RESET:
            return setVenueLocationId(state, -1);
        case VENUE_LOCATION_REQUEST:
            return setVenueLocation(loadingVenueLocation(setVenueLocationId(state, action.venueLocationId), true), {});
        case VENUE_LOCATION_REQUEST_SUCCESS:
            return setVenueLocation(loadingVenueLocation(state, false), action.venueLocation);
        case VENUE_LOCATION_REQUEST_FAILURE:
            return loadingVenueLocation(setVenueLocationId(state, -1), false);
        case VENUE_LOCATION_CREATE_REQUEST:
            return setVenueLocation(loadingVenueLocation(setVenueLocationId(state, 0), true), {});;
        case VENUE_LOCATION_CREATE_REQUEST_SUCCESS:
            return setVenueLocation(loadingVenueLocation(state, false), action.venueLocation);;
        case VENUE_LOCATION_CREATE_REQUEST_FAILURE:
            return loadingVenueLocation(setVenueLocationId(state, -1), false);
        case VENUE_LOCATION_UPSERT_REQUEST:
            return savingVenueLocation(state, true);
        case VENUE_LOCATION_UPSERT_REQUEST_SUCCESS:
            return setVenueLocation(savingVenueLocation(setVenueLocationId(state, -1), false), action.venueLocation);
        case VENUE_LOCATION_UPSERT_REQUEST_FAILURE:
            return savingVenueLocation(state, false);
        case VENUE_LOCATION_SET:
            return setVenueLocation(state, action.venueLocation);


        case VENUE_INCIDENT_CATEGORY_TYPES_REFRESH:
            return loadingVenueIncidentCategoryTypes(setVenueIncidentCategoryTypeId(state, -1), true);
        case VENUE_INCIDENT_CATEGORY_TYPES_REQUEST:
            return setVenueIncidentCategoryTypes(loadingVenueIncidentCategoryTypes(setVenueIncidentCategoryTypeId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case VENUE_INCIDENT_CATEGORY_TYPES_FILTER_SET:
            return setVenueIncidentCategoryTypesFilter(state, action.filter);
        case VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS:
            return setVenueIncidentCategoryTypes(loadingVenueIncidentCategoryTypes(state, false), action.incidentCategoryTypes, action.filter, action.paging);
        case VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE:
            return loadingVenueIncidentCategoryTypes(state, false);
        case VENUE_INCIDENT_CATEGORY_TYPE_RESET:
            return setVenueIncidentCategoryTypeId(state, -1);
        case VENUE_INCIDENT_CATEGORY_TYPE_REQUEST:
            return setVenueIncidentCategoryType(loadingVenueIncidentCategoryType(setVenueIncidentCategoryTypeId(state, action.incidentCategoryTypes), true), {});
        case VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS:
            return setVenueIncidentCategoryType(loadingVenueIncidentCategoryType(state, false), action.incidentCategoryType);
        case VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE:
            return loadingVenueIncidentCategoryType(setVenueIncidentCategoryTypeId(state, -1), false);
        case VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST:
            return setVenueIncidentCategoryType(loadingVenueIncidentCategoryType(setVenueIncidentCategoryTypeId(state, 0), true), {});;
        case VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS:
            return setVenueIncidentCategoryType(loadingVenueIncidentCategoryType(state, false), action.incidentCategoryType);;
        case VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE:
            return loadingVenueIncidentCategoryType(setVenueIncidentCategoryTypeId(state, -1), false);
        case VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST:
            return savingVenueIncidentCategoryType(state, true);
        case VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS:
            return setVenueIncidentCategoryType(savingVenueIncidentCategoryType(setVenueIncidentCategoryTypeId(state, -1), false), action.incidentCategoryType);
        case VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE:
            return savingVenueIncidentCategoryType(state, false);
        case VENUE_INCIDENT_CATEGORY_TYPE_SET:
            return setVenueIncidentCategoryType(state, action.incidentCategoryType);

        case VENUE_CONTACTS_REFRESH:
            return loadingVenueContacts(setVenueContactId(state, -1), true);
        case VENUE_CONTACTS_REQUEST:
            return setVenueContacts(loadingVenueContacts(setVenueContactId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case VENUE_CONTACTS_REQUEST_SUCCESS:
            return setVenueContacts(loadingVenueContacts(state, false), action.venueContacts, action.filter, action.paging);
        case VENUE_CONTACTS_REQUEST_FAILURE:
            return loadingVenueContacts(state, false);
        case VENUE_CONTACT_RESET:
            return setVenueContactId(state, -1);
        case VENUE_CONTACT_REQUEST:
            return setVenueContact(loadingVenueContact(setVenueContactId(state, action.venueContactId), true), {}, [], []);
        case VENUE_CONTACT_REQUEST_SUCCESS:
            return setVenueContact(loadingVenueContact(state, false), action.venueContact, action.venueContactTypes, action.venueUsers);
        case VENUE_CONTACT_REQUEST_FAILURE:
            return loadingVenueContact(setVenueContactId(state, -1), false);
        case VENUE_CONTACT_CREATE_REQUEST:
            return setVenueContact(loadingVenueContact(setVenueContactId(state, 0), true), {}, [], []);
        case VENUE_CONTACT_CREATE_REQUEST_SUCCESS:
            return setVenueContact(loadingVenueContact(state, false), action.venueContact, action.venueContactTypes, action.venueUsers);
        case VENUE_CONTACT_CREATE_REQUEST_FAILURE:
            return loadingVenueContact(setVenueContactId(state, -1), false);
        case VENUE_CONTACT_UPSERT_REQUEST:
            return savingVenueContact(state, true);
        case VENUE_CONTACT_UPSERT_REQUEST_SUCCESS:
            return setVenueContact(savingVenueContact(setVenueContactId(state, -1), false), action.venueContact, state.venueContact.venueContactTypes, state.venueContact.venueUsers);
        case VENUE_CONTACT_UPSERT_REQUEST_FAILURE:
            return savingVenueContact(state, false);
        case VENUE_CONTACT_SET:
            return setVenueContact(state, action.venueContact, state.venueContact.venueContactTypes, state.venueContact.venueUsers);

        case VENUE_SUBSCRIPTION_SUMMARY_REQUEST:
            return loadingVenueSubscription(setVenueSubscriptionSummary(state, {}), true, null);
        case VENUE_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS:
            return loadingVenueSubscription(setVenueSubscriptionSummary(state, action.venueSubscriptionSummary), false, null);
        case VENUE_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE:
            return loadingVenueSubscription(state, false, action.error);

        case VENUE_DOCUMENTS_REQUEST:
            return loadingDocuments(setDocuments(state, [], [], [], [], [], []), true, "");
        case VENUE_DOCUMENTS_REQUEST_SUCCESS:
            return loadingDocuments(setDocuments(state, action.venueOtherDocuments, action.venueOtherDocumentTypes, action.venueComplianceDocuments, action.venueComplianceDocumentTypes, action.associatedSecurityComplianceDocuments, action.associatedSecurityComplianceDocumentTypes), false, "");
        case VENUE_DOCUMENTS_REQUEST_FAILURE:
            return loadingDocuments(state, false, action.error);
        case VENUE_OTHER_DOCUMENTS_SAVE:
        case VENUE_OTHER_DOCUMENT_REMOVE:
        case VENUE_COMPLIANCE_DOCUMENTS_SAVE:
        case VENUE_COMPLIANCE_DOCUMENT_REMOVE:
            return savingDocuments(state, true, "");
        case VENUE_OTHER_DOCUMENT_REMOVE_SUCCESS:
        case VENUE_OTHER_DOCUMENTS_SAVE_SUCCESS:
            return savingDocuments(setOtherDocuments(state, action.venueOtherDocuments), false, null);
        case VENUE_OTHER_DOCUMENTS_SAVE_FAILURE:
        case VENUE_OTHER_DOCUMENT_REMOVE_FAILURE:
        case VENUE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE:
        case VENUE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE:
            return savingDocuments(state, false, action.error);
        case VENUE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS:
        case VENUE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
            return savingDocuments(setComplianceDocuments(state, action.venueComplianceDocuments), false, null);

        case VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST:
            return setVenueSecurityFirmAssociations(
                setVenueSecurityFirmAssociationsVenueId(
                    loadingVenueSecurityFirmAssociations(state, true), 
                action.forVenueId
                ),
                [], { pageSize: action.pageSize, page: action.page });
        case VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_SUCCESS:
            return setVenueSecurityFirmAssociations(loadingVenueSecurityFirmAssociations(state, false), action.venueSecurityFirmAssociations, action.paging);
        case VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_FAILURE:
            return loadingVenueSecurityFirmAssociations(state, false);

        case VENUE_SECURITYFIRM_ASSOCIATION_RESET:
            return setVenueSecurityFirmAssociationOptions(setVenueSecurityFirmAssociation(state, { venueSecurityFirmId: -1, securityFirmId: 0 }), []);
        case VENUE_SECURITYFIRM_ASSOCIATION_SET:
            return setVenueSecurityFirmAssociation(state, action.venueSecurityFirm);
        case VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST:
            return loadingVenueSecurityFirmAssociation(state, true, "");
        case VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS:
            return setVenueSecurityFirmAssociation(
                setVenueSecurityFirmAssociationOptions(
                    loadingVenueSecurityFirmAssociation(state, false, "")
                    , action.securityFirms)
                , action.venueSecurityFirm);
        case VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_FAILURE:
            return loadingVenueSecurityFirmAssociation(state, false, action.error);
        case VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST:
            return savingVenueSecurityFirmAssociation(state, true, "");
        case VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS:
            return savingVenueSecurityFirmAssociation(state, false, "");
        case VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_FAILURE:
            return savingVenueSecurityFirmAssociation(state, false, action.error);
        case VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST:
            return savingVenueSecurityFirmAssociation(state, true, "");
        case VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS:
            return savingVenueSecurityFirmAssociation(state, false, "");
        case VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE:
            return savingVenueSecurityFirmAssociation(state, false, action.error);
    }

    return state;
}