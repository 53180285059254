import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SubTitle from '../../common/SubTitle';
import NotificationTrigger from '../../notifications/NotificationTrigger';

export interface IIncidentFlagTypeNotificationProps {
    incidentFlagTypeNotification: AusComplyDtos.IncidentFlagTypeNotification;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IIncidentFlagTypeNotificationState {
    userRoleTypes: MulitSelectOption[];
}

export default class IncidentFlagTypeNotification extends Component<IIncidentFlagTypeNotificationProps, IIncidentFlagTypeNotificationState> {
    constructor(props: IIncidentFlagTypeNotificationProps) {
        super(props)
        this.state = {
            userRoleTypes: []
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onUserRoleTypesChanged = this.onUserRoleTypesChanged.bind(this);
        this.setUserRoleTypes = this.setUserRoleTypes.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            let includeSecurity = true;
            if (!this.props.incidentFlagTypeNotification.includeAssociatedUsers) {
                includeSecurity = false;
            }
            this.setUserRoleTypes(includeSecurity);
        }
    }

    setUserRoleTypes(includeSecurity: boolean) {
        let excludeSecurity = false;
        if (this.props.incidentFlagTypeNotification.venueId && this.props.incidentFlagTypeNotification.venueId > 0 && !includeSecurity) {
            excludeSecurity = true;
        }
        let userRoleTypes: MulitSelectOption[] = [];
        if (this.props.incidentFlagTypeNotification.userRoleTypeSelections) {
            this.props.incidentFlagTypeNotification.userRoleTypeSelections.forEach((item, index) => {
                if (item.security && excludeSecurity) {
                    // dont include it
                } else {
                    let userRoleType = new MulitSelectOption();
                    userRoleType.id = item.userRoleTypeId;
                    userRoleType.name = item.name;
                    userRoleType.selected = item.selected;
                    userRoleType.isCommon = false;
                    userRoleTypes.push(userRoleType);
                }
            });
        }

        this.setState({
            userRoleTypes
        });
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentFlagTypeNotification = { ...this.props.incidentFlagTypeNotification };
        incidentFlagTypeNotification[fieldName] = value;
        if (fieldName == "includeAssociatedUsers" && value == true) {
            incidentFlagTypeNotification.onlySignedOn = true;
        }
        this.props.onUpdate(incidentFlagTypeNotification);
        if (fieldName == "includeAssociatedUsers") {
            this.setUserRoleTypes(value);
        }
    }

    onUserRoleTypesChanged(value) {
        let userRoleTypes = [...this.state.userRoleTypes];
        userRoleTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            userRoleTypes
        });

        let incidentFlagTypeNotification = { ...this.props.incidentFlagTypeNotification };
        if (incidentFlagTypeNotification.userRoleTypeSelections) {
            incidentFlagTypeNotification.userRoleTypeSelections.forEach((item, index) => {
                item.selected = this.state.userRoleTypes.filter(s => s.id === item.userRoleTypeId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(incidentFlagTypeNotification)
    }

    render() {

        if (!this.props.incidentFlagTypeNotification) {
            return (<></>);
        }

        let userRoleTypes;
        if (this.props.incidentFlagTypeNotification && this.props.incidentFlagTypeNotification.userRoleTypeSelections) {
            userRoleTypes = (
                <MultiSelectControl
                    items={this.state.userRoleTypes}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    searchText={"Type at least three letters..."}
                    onSelectItemsChanged={this.onUserRoleTypesChanged} />

            );
        }


        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    { this.props.incidentFlagTypeNotification.incidentFlagType && (
                        <>
                        <SubTitle text={"Details"}/>
                        <TextControlGroup
                            text={"Name"}
                            readonly={true}
                            defaultValue={this.props.incidentFlagTypeNotification.incidentFlagType.name} />
    
                        <TextControlGroup
                            text={"Rank"}
                            readonly={true}
                            defaultValue={this.props.incidentFlagTypeNotification.incidentFlagType.rank.toString()} />
    
                        <CheckboxControlGroup
                            label={" "}
                            text={"Additional Field"}
                            readonly={true}
                            defaultValue={this.props.incidentFlagTypeNotification.incidentFlagType.additionalField} />
                        {this.props.incidentFlagTypeNotification.incidentFlagType.additionalField && (
                            <TextControlGroup
                                text={"Additional Field Name"}
                                readonly={true}
                                defaultValue={this.props.incidentFlagTypeNotification.incidentFlagType.fieldLabel} />
                        )}
                        </>
                    )}
                    
                    <SubTitle text={"Notifications"} style={{marginTop: '20px'}} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Include associated security firm users"}
                        defaultValue={this.props.incidentFlagTypeNotification.includeAssociatedUsers}
                        onChanged={(value) => this.onValueChanged("includeAssociatedUsers", value)} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Only users currently logged in"}
                        readonly={this.props.incidentFlagTypeNotification.includeAssociatedUsers}
                        defaultValue={this.props.incidentFlagTypeNotification.onlySignedOn}
                        onChanged={(value) => this.onValueChanged("onlySignedOn", value)} />
                    <ControlGroup text={"Recipient User roles"}>
                        {userRoleTypes}
                    </ControlGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}