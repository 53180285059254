import update from "immutability-helper";
import {
    SECURITY_FIRM_REQUEST,
    SECURITY_FIRM_REQUEST_SUCCESS,
    SECURITY_FIRM_REQUEST_FAILURE,
    SECURITY_FIRM_UPDATE,

    SECURITY_FIRM_SAVE_REQUEST,
    SECURITY_FIRM_SAVE_REQUEST_SUCCESS,
    SECURITY_FIRM_SAVE_REQUEST_FAILURE,

    SECURITY_FIRM_CONTACTS_REFRESH,
    SECURITY_FIRM_CONTACTS_REQUEST,
    SECURITY_FIRM_CONTACTS_REQUEST_SUCCESS,
    SECURITY_FIRM_CONTACTS_REQUEST_FAILURE,
    SECURITY_FIRM_CONTACT_RESET,
    SECURITY_FIRM_CONTACT_SET,
    SECURITY_FIRM_CONTACT_REQUEST,
    SECURITY_FIRM_CONTACT_REQUEST_SUCCESS,
    SECURITY_FIRM_CONTACT_REQUEST_FAILURE,
    SECURITY_FIRM_CONTACT_CREATE_REQUEST,
    SECURITY_FIRM_CONTACT_CREATE_REQUEST_SUCCESS,
    SECURITY_FIRM_CONTACT_CREATE_REQUEST_FAILURE,
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST,
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS,
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST_FAILURE,

    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST,
    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS,
    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE,

    SECURITY_FIRM_DOCUMENTS_REQUEST,
    SECURITY_FIRM_DOCUMENTS_REQUEST_SUCCESS,
    SECURITY_FIRM_DOCUMENTS_REQUEST_FAILURE,
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE,
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_SUCCESS,
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_FAILURE,
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE,
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_SUCCESS,
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_FAILURE,
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE,
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE,
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST,
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_SUCCESS,
    SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_FAILURE

} from "../actions/securityFirm";

import { securityFirmLogic } from '../logic/securityFirmLogic';

const initialState = {
    securityFirmId: 0,
    securityFirm: {},
    securityLicenceClasses: [],
    states: [],
    isSaving: false,
    isLoading: false,
    error: null,
    errors: {},
    securityFirmContacts: {
        isLoading: false,
        filter: {},
        securityFirmContacts: [],
        paging: { pageSize: 0, page: 1 }
    },
    securityFirmContact: {
        securityFirmContactId: -1,
        isLoading: false,
        isSaving: false,
        securityFirmContactTypes: [],
        securityFirmUsers: [],
        securityFirmContact: { securityFirmContactId: 0 }
    },
    documents: {
        isSaving: false,
        isLoading: false,
        error: null,
        otherDocuments: [],
        otherDocumentTypes: [],
        complianceDocuments: [],
        complianceDocumentTypes: [],
    },
    securityFirmVenueAssociations: {
        securityFirmId: 0,
        isLoading: false,
        securityFirmVenueAssociations: [],
        paging: { pageSize: 0, page: 1 }
    },
    securityFirmSubscriptionSummary: {
        isLoading: false,
        error: null,
        securityFirmSubscriptionSummary: { securityFirmId: 0 }
    }
};

function loadingDocuments(state, isLoading, error) {
    let newState = update(state, {
        documents: {
            isLoading: { $set: isLoading },
            isSaving: { $set: false },
            error: { $set: error }
        }
    });
    return newState;
}

function savingDocuments(state, isSaving, error) {
    let newState = update(state, {
        documents: {
            isLoading: { $set: false },
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setDocuments(state, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    let newState = update(state, {
        documents: {
            otherDocuments: { $set: otherDocuments },
            otherDocumentTypes: { $set: otherDocumentTypes },
            complianceDocuments: { $set: complianceDocuments },
            complianceDocumentTypes: { $set: complianceDocumentTypes }
        }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        documents: {
            otherDocuments: { $set: otherDocuments }
        }
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        documents: {
            complianceDocuments: { $set: complianceDocuments }
        }
    });
    return newState;
}

function setOptions(state, includesOptions, states, securityLicenceClasses) {
    if (!includesOptions) {
        return state;
    }
    let newState = update(state, {
        states: { $set: states },
        securityLicenceClasses: { $set: securityLicenceClasses }
    });
    return newState;
}

function loadingSecurityFirm(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setSecurityFirm(state, securityFirm, securityFirmId) {
    let newState = update(state, {
        securityFirm: { $set: securityFirm },
        securityFirmId: { $set: securityFirmId }
    });
    return newState;
}

function loadingSecurityFirmContacts(state, isLoading) {
    let newState = update(state, {
        securityFirmContacts: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSecurityFirmContacts(state, securityFirmContacts, filter, paging) {
    let newState = update(state, {
        securityFirmContacts: {
            securityFirmContacts: { $set: securityFirmContacts },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingSecurityFirmContact(state, isLoading) {
    let newState = update(state, {
        securityFirmContact: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSecurityFirmContact(state, securityFirmContact, securityFirmContactTypes, securityFirmUsers) {
    let newState = update(state, {
        securityFirmContact: {
            securityFirmContact: { $set: securityFirmContact },
            securityFirmContactTypes: { $set: securityFirmContactTypes },
            securityFirmUsers: { $set: securityFirmUsers }
        }
    });
    return newState;
}

function savingSecurityFirmContact(state, isSaving) {
    let newState = update(state, {
        securityFirmContact: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setSecurityFirmContactId(state, securityFirmContactId) {
    let newState = update(state, {
        securityFirmContact: {
            securityFirmContactId: { $set: securityFirmContactId }
        }
    });
    return newState;
}

function loadingSecurityFirmSubscription(state, isLoading, error) {
    let newState = update(state, {
        securityFirmSubscriptionSummary: {
            isLoading: { $set: isLoading },
            error: { $set: error },
        }
    });
    return newState;
}

function setSecurityFirmSubscriptionSummary(state, securityFirmSubscriptionSummary) {
    let newState = update(state, {
        securityFirmSubscriptionSummary: {
            securityFirmSubscriptionSummary: { $set: securityFirmSubscriptionSummary },
        }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: securityFirmLogic.validateSecurityFirm(state.securityFirm) }
    });
    return newState;
}


function loadingSecurityFirmVenueAssociations(state, isLoading) {
    let newState = update(state, {
        securityFirmVenueAssociations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSecurityFirmVenueAssociations(state, securityFirmVenueAssociations, paging) {
    let newState = update(state, {
        securityFirmVenueAssociations: {
            securityFirmVenueAssociations: { $set: securityFirmVenueAssociations },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setVenueSecurityFirmAssociationsSecurityFirmId(state, securityFirmId) {
    let newState = update(state, {
        securityFirmVenueAssociations: {
            securityFirmId: { $set: securityFirmId }
        }
    });
    return newState;
}

export default function securityFirm(state = initialState, action) {
    switch (action.type) {
        case SECURITY_FIRM_REQUEST:
            return setSecurityFirm(loadingSecurityFirm(state, true), {}, action.securityFirmId);
        case SECURITY_FIRM_REQUEST_SUCCESS:
            return setErrors(setSecurityFirm(
                setOptions(
                    loadingSecurityFirm(state, false)
                    , action.includesOptions, action.states, action.securityLicenceClasses)
                , action.securityFirm, action.securityFirm.securityFirmId));
        case SECURITY_FIRM_REQUEST_FAILURE:
            return loadingSecurityFirm(state, false);

        case SECURITY_FIRM_SAVE_REQUEST:
            return saving(setError(state, null), true);
        case SECURITY_FIRM_SAVE_REQUEST_SUCCESS:
            return saving(state, false);
        case SECURITY_FIRM_SAVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case SECURITY_FIRM_UPDATE:
            return setErrors(setSecurityFirm(state, action.securityFirm, action.securityFirm.securityFirmId));

        case SECURITY_FIRM_CONTACTS_REFRESH:
            return loadingSecurityFirmContacts(setSecurityFirmContactId(state, -1), true);
        case SECURITY_FIRM_CONTACTS_REQUEST:
            return setSecurityFirmContacts(loadingSecurityFirmContacts(setSecurityFirmContactId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case SECURITY_FIRM_CONTACTS_REQUEST_SUCCESS:
            return setSecurityFirmContacts(loadingSecurityFirmContacts(state, false), action.securityFirmContacts, action.filter, action.paging);
        case SECURITY_FIRM_CONTACTS_REQUEST_FAILURE:
            return loadingSecurityFirmContacts(state, false);
        case SECURITY_FIRM_CONTACT_RESET:
            return setSecurityFirmContactId(state, -1);
        case SECURITY_FIRM_CONTACT_REQUEST:
            return setSecurityFirmContact(loadingSecurityFirmContact(setSecurityFirmContactId(state, action.securityFirmContactId), true), {}, [], []);
        case SECURITY_FIRM_CONTACT_REQUEST_SUCCESS:
            return setSecurityFirmContact(loadingSecurityFirmContact(state, false), action.securityFirmContact, action.securityFirmContactTypes, action.securityFirmUsers);
        case SECURITY_FIRM_CONTACT_REQUEST_FAILURE:
            return loadingSecurityFirmContact(setSecurityFirmContactId(state, -1), false);
        case SECURITY_FIRM_CONTACT_CREATE_REQUEST:
            return setSecurityFirmContact(loadingSecurityFirmContact(setSecurityFirmContactId(state, 0), true), {}, [], []);
        case SECURITY_FIRM_CONTACT_CREATE_REQUEST_SUCCESS:
            return setSecurityFirmContact(loadingSecurityFirmContact(state, false), action.securityFirmContact, action.securityFirmContactTypes, action.securityFirmUsers);
        case SECURITY_FIRM_CONTACT_CREATE_REQUEST_FAILURE:
            return loadingSecurityFirmContact(setSecurityFirmContactId(state, -1), false);
        case SECURITY_FIRM_CONTACT_UPSERT_REQUEST:
            return savingSecurityFirmContact(state, true);
        case SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS:
            return setSecurityFirmContact(savingSecurityFirmContact(setSecurityFirmContactId(state, -1), false), action.securityFirmContact, state.securityFirmContact.securityFirmContactTypes, state.securityFirmContact.securityFirmUsers);
        case SECURITY_FIRM_CONTACT_UPSERT_REQUEST_FAILURE:
            return savingSecurityFirmContact(state, false);
        case SECURITY_FIRM_CONTACT_SET:
            return setSecurityFirmContact(state, action.securityFirmContact, state.securityFirmContact.securityFirmContactTypes, state.securityFirmContact.securityFirmUsers);


        case SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST:
            return loadingSecurityFirmSubscription(setSecurityFirmSubscriptionSummary(state, {}), true, null);
        case SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS:
            return loadingSecurityFirmSubscription(setSecurityFirmSubscriptionSummary(state, action.securityFirmSubscriptionSummary), false, null);
        case SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE:
            return loadingSecurityFirmSubscription(state, false, action.error);

        case SECURITY_FIRM_DOCUMENTS_REQUEST:
            return loadingDocuments(setDocuments(state, [], [], [], []), true, "");
        case SECURITY_FIRM_DOCUMENTS_REQUEST_SUCCESS:
            return loadingDocuments(setDocuments(state, action.securityFirmOtherDocuments, action.securityFirmOtherDocumentTypes, action.securityFirmComplianceDocuments, action.securityFirmComplianceDocumentTypes), false, "");
        case SECURITY_FIRM_DOCUMENTS_REQUEST_FAILURE:
            return loadingDocuments(state, false, action.error);
        case SECURITY_FIRM_OTHER_DOCUMENTS_SAVE:
        case SECURITY_FIRM_OTHER_DOCUMENT_REMOVE:
        case SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE:
        case SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE:
            return savingDocuments(state, true, "");
        case SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_SUCCESS:
        case SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_SUCCESS:
            return savingDocuments(setOtherDocuments(state, action.securityFirmOtherDocuments), false, null);
        case SECURITY_FIRM_OTHER_DOCUMENTS_SAVE_FAILURE:
        case SECURITY_FIRM_OTHER_DOCUMENT_REMOVE_FAILURE:
        case SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_FAILURE:
        case SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_FAILURE:
            return savingDocuments(state, false, action.error);
        case SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS:
        case SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
            return savingDocuments(setComplianceDocuments(state, action.securityFirmComplianceDocuments), false, null);

        case SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST:
            return setSecurityFirmVenueAssociations(
                setVenueSecurityFirmAssociationsSecurityFirmId(
                    loadingSecurityFirmVenueAssociations(state, true),
                    action.forSecurityFirmId
                ),
                [], { pageSize: action.pageSize, page: action.page });
        case SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_SUCCESS:
            return setSecurityFirmVenueAssociations(loadingSecurityFirmVenueAssociations(state, false), action.securityFirmVenueAssociations, action.paging);
        case SECURITY_FIRM_VENUE_ASSOCIATIONS_REQUEST_FAILURE:
            return loadingSecurityFirmVenueAssociations(state, false);
    }

    return state;
}