import React, { Component } from 'react';
import SaveIcon from '../../../resources/SaveIcon';
import CrossIcon from '../../../resources/CrossIcon';
import DraftSaveIcon from '../../../resources/DraftSaveIcon';
import EditIcon from '../../../resources/EditIcon';
import SubmitIcon from '../../../resources/SubmitIcon';
import CloseIcon from '../../../resources/CrossIcon';
import TickIcon from '../../../resources/TickIcon';
import BlockIcon from '../../../resources/BlockIcon';
import PrintNavigationButtonContainer from '../../../containers/PrintNavigationButtonContainer';
import NavigationButton from '../../controls/NavigationButton';
import { Palette } from '../../../common/constants/palette';

export interface ICheckListNavigationProps {
    onCancel: Function;
    onSave: Function;
    onSubmit: Function;
    isSaving: boolean;
    canSubmit: boolean;
    isReadonly: boolean;
    canApprove: boolean;
    canReject: boolean;
    canClose: boolean;
    canPrint: boolean;
    onApprove: Function;
    onReject: Function;
    onClose: Function;
    onPrint: Function;
}

export default class CheckListNavigation extends Component<ICheckListNavigationProps, any> {

    render() {
        let buttonStyle = {
            minHeight: '30px',
            minWidth: '30px'
        };
        let cancelButton;
        let saveProgressButton;
        let submitButton;
        let rejectButton;
        let approveButton;
        let voidButton;

        if (this.props.isReadonly) {
            cancelButton = <NavigationButton
                onClick={this.props.onCancel}
                text={"Close"}
                isError={true}
                Icon={CrossIcon} />;

            if (!this.props.isSaving && this.props.canApprove) {
                approveButton = <NavigationButton
                    onClick={this.props.onApprove}
                    text={"Approve"}
                    isSuccess={true}
                    Icon={TickIcon} />;
            }
            if (!this.props.isSaving && this.props.canClose) {
                voidButton = <NavigationButton
                    onClick={this.props.onClose}
                    text={"Void"}
                    active={true}
                    Icon={BlockIcon} />;
            }
            if (!this.props.isSaving && this.props.canReject) {
                rejectButton = <NavigationButton
                    onClick={this.props.onReject}
                    text={"Resubmit"}
                    isRejected={true}
                    Icon={EditIcon} />;
            }
        } else {
            cancelButton = <NavigationButton
                onClick={this.props.onCancel}
                text={"Close"}
                isError={true}
                Icon={CloseIcon}
            />;

            saveProgressButton = <NavigationButton
                onClick={this.props.onSave}
                text={"Save"}
                isSuccess={this.props.isSaving}
                Icon={DraftSaveIcon}
                active={true} />;


            submitButton = <NavigationButton
                onClick={this.props.onSubmit}
                text={"Submit"}
                Icon={SubmitIcon}
                isInfo={true}
                disabled={!this.props.canSubmit || this.props.isSaving} />
        }

        let saving;
        if (this.props.isSaving) {
            saving = <div style={{ position: 'absolute', top: '10px', left: '10px', width: '20px', height: '20px' }}>
                <SaveIcon fill={Palette.Primary} style={{ width: '20px', height: '20px' }} className="animation-pulse" />
            </div>;
        } else {
            saving = <></>;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: '0 20px',
                position: 'relative'
            }}>
                {saving}
                {rejectButton && (
                    <div style={{ flex: 0 }}>
                        {rejectButton}
                    </div>
                )}
                {voidButton && (
                    <div style={{ flex: 0 }}>
                        {voidButton}
                    </div>
                )}
                {approveButton && (
                    <div style={{ flex: 0 }}>
                        {approveButton}
                    </div>
                )}
                {cancelButton && (
                    <div style={{ flex: 0 }}>
                        {cancelButton}
                    </div>
                )}
                {saveProgressButton && (
                    <div style={{ flex: 0 }}>
                        {saveProgressButton}
                    </div>
                )}
                {submitButton && (
                    <div style={{ flex: 0 }}>
                        {submitButton}
                    </div>
                )}
                {this.props.canPrint && (
                    <div style={{ flex: 0 }}>
                        <PrintNavigationButtonContainer hideQueue={true} onPrint={this.props.onPrint} />
                    </div>
                )}
            </div>
        );

    }
}