import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import SecurityFirmContactCard from './SecurityFirmContactCard';
import SecurityFirmContactHeader from './SecurityFirmContactHeader';
import SecurityFirmContactRow from './SecurityFirmContactRow';
import SecurityFirmContactContainer from '../../../containers/SecurityFirmContactContainer';
import SecurityFirmContactLegend from './SecurityFirmContactLegend';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface ISecurityFirmContactsProps {
    isLoading: boolean;
    securityFirmId: number;
    securityFirmContacts: AusComplyDtos.SecurityFirmContact[];
    securityFirmContactId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.ContactFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class SecurityFirmContacts extends Component<ISecurityFirmContactsProps, any> {
    constructor(props: ISecurityFirmContactsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            contactToRemove: {}
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.ContactFilter) {
        let preparedFilter = { ...filter };
        if (this.props.securityFirmId && this.props.securityFirmId > 0) {
            preparedFilter.forSecurityFirmId = this.props.securityFirmId;
        } else {
            preparedFilter.forSecurityFirmId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, securityFirmContact: AusComplyDtos.SecurityFirmContact) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.securityFirmId);
                break;
            case "edit":
                this.props.onLoadItem(securityFirmContact.securityFirmContactId);
                break;
            case "primary":
                var updated = { ...securityFirmContact };
                updated.primaryContact = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...securityFirmContact };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    contactToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let securityFirmContact = { ...this.state.contactToRemove };
            this.props.onUpdate(securityFirmContact);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                contactToRemove: {}
            });
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.securityFirmContacts && this.props.securityFirmContacts.length > 0) {
            cardList = this.props.securityFirmContacts.map((item, index) => {
                return (
                    <SecurityFirmContactCard
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"card-" + item.securityFirmContactId.toString()}
                        securityFirmContact={item}
                    />
                );
            });
            rows = this.props.securityFirmContacts.map((item, index) => {
                return (
                    <SecurityFirmContactRow
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"row-" + item.securityFirmContactId.toString()}
                        securityFirmContact={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<SecurityFirmContactHeader />}
                    rows={rows}
                />
            );
        }

        let securityFirmContact;
        if (this.props.securityFirmContactId != -1) {
            if (!this.props.securityFirmId || this.props.securityFirmId == 0) {
                //securityFirmContact = (
                //    <AdminSecurityFirmContactContainer />
                //);
            } else {
                securityFirmContact = (
                    <SecurityFirmContactContainer />
                );
            }
        }

        let create;
        if (this.props.canEdit) {
            create = (
                <TransparentButton 
                    onClick={() => this.props.onCreate(this.props.securityFirmId)} 
                    text={"Add Contact"} />
            );
        }

        return (<PageLayout
            headerText={"Security Firm Contacts"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                footer={<SecurityFirmContactLegend />}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <FullPageDialog 
                open={this.props.securityFirmContactId != -1} 
                title="Security Firm Contact" 
                notFullWidth={true}
                onDismissed={() => this.props.onResetItem()}>
                {securityFirmContact}
            </FullPageDialog>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this contact"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </PageLayout>);
    }
}