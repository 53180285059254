import { connect } from 'react-redux'
import User from '../components/admin/user/User'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    adminUserRequest,
    adminUserReset,
    adminSaveOtherDocuments,
    adminRemoveOtherDocument,
    adminSaveComplianceDocuments,
    adminRemoveComplianceDocument,
    adminUserSet,
    adminUserSaveRequest,
    adminUserSledCheckRequest,
    adminUserImpersonate
} from '../common/actions/admin';
import * as adminActions from '../common/actions/admin';
import {
    userRoleRequestDismissRequest,
    userRoleEnableRequest
} from '../common/actions/userRole';

const isVenueUserRoleType = (userRoleType: number) => {
    return userRoleType == 1 || userRoleType == 3;
}

const isSecurityFirmRoleType = (userRoleType: number) => {
    return userRoleType == 2 || userRoleType == 3;
}


const mapStateToProps = (state, props) => {
    return {
        userId: props.match.params.userId,
        user: state.admin.user.user,
        errors: state.admin.user.errors,
        avatar: state.admin.user.avatar,
        states: state.admin.user.states,
        genders: state.admin.user.genders,
        userRoleRequests: state.admin.user.user.userRoleRequests,
        userRoleSummaries: state.admin.user.user.userRoleSummaries,
        isLoading: state.admin.user.isLoading,
        isSaving: state.admin.user.isSaving,
        isCheckingLicense: state.admin.user.isCheckingLicense,
        otherDocuments: state.admin.user.otherDocuments,
        otherDocumentTypes: state.admin.user.otherDocumentTypes,
        complianceDocuments: state.admin.user.complianceDocuments,
        complianceDocumentTypes: state.admin.user.complianceDocumentTypes,
        currentuser: state.user.details.userSession.user,
        hasEditPermission: permissionsLogic.hasAnyPermissionForState(state, ["EditL1UserAccess", "EditTempL1UserAccess", "EditTempL2UserAccess", "EditL2UserAccess", "EditTempL3UserAccess", "EditL3UserAccess", "EditL4UserAccess", "EditTempL4UserAccess"]),
        isVenueUserRoleType: isVenueUserRoleType(state.user.details.userSession.user.userRoleTypes),
        isSecurityFirmRoleType: isSecurityFirmRoleType(state.user.details.userSession.user.userRoleTypes)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (userId) => {
            dispatch(adminUserRequest(userId))
        },
        onUpdate: (user) => {
            dispatch(adminUserSet(user))
        },
        onReset: () => {
            dispatch(adminUserReset())
        },
        onSaveOtherDocuments: (userId, documentTypeId, files) => {
            dispatch(adminSaveOtherDocuments(userId, documentTypeId, files))
        },
        onRemoveOtherDocument: (userId, otherDocument) => {
            dispatch(adminRemoveOtherDocument(userId, otherDocument))
        },
        onSaveComplianceDocuments: (userId, documentTypeId, expiryDate, files) => {
            dispatch(adminSaveComplianceDocuments(userId, documentTypeId, expiryDate, files))
        },
        onRemoveComplianceDocument: (userId, complianceDocument) => {
            dispatch(adminRemoveComplianceDocument(userId, complianceDocument))
        },
        onSave: (user) => {
            dispatch(adminUserSaveRequest(user))
        },
        onCheckLicense: (user) => {
            dispatch(adminUserSledCheckRequest(user))
        },
        onUserRequestDismiss: (notificationId) => {
            dispatch(userRoleRequestDismissRequest(notificationId))
        },
        onUserRoleEnableRequest: (userRoleId) => {
            dispatch(userRoleEnableRequest(userRoleId))
        },
        onImpersonate: (userId) => {
            dispatch(adminUserImpersonate(userId))
        },
        onPushTest: (userId, incidentId) => {
            dispatch(adminActions.adminUserPushTestRequest(userId, incidentId))
        }
    }
}

const AdminUserContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(User))

export default AdminUserContainer