import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import { RadioOption } from '../../common/dto/common';
import RadioControl from './RadioControl';

export interface IRadioControlGroupProps {
    text?: string;
    name: string;
    error?: string;
    selected?: string;
    options?: RadioOption[];
    readonly?: boolean;
    onChanged?: Function;
    labelAbove?: boolean;
}

class RadioControlGroup extends React.PureComponent<IRadioControlGroupProps, any> {

    constructor(props: IRadioControlGroupProps) {
        super(props);
        this.state = {
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChanged) {
            this.props.onChanged(value);
        }
    }

    render() {
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} errorText={this.props.error} labelAbove={this.props.labelAbove}>
                <RadioControl selected={this.props.selected} options={this.props.options} readonly={this.props.readonly} name={this.props.name} onChange={(val) => this.onChange(val)} />
            </ControlGroup>
        );
    }
}

export default RadioControlGroup;