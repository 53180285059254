export const
CONTENT_TERMS_REQUEST = "CONTENT_TERMS_REQUEST",
CONTENT_TERMS_REQUEST_SUCCESS = "CONTENT_TERMS_REQUEST_SUCCESS",
CONTENT_TERMS_REQUEST_FAILURE = "CONTENT_TERMS_REQUEST_FAILURE",
CONTENT_PRIVACYPOLICY_REQUEST = "CONTENT_PRIVACYPOLICY_REQUEST",
CONTENT_PRIVACYPOLICY_REQUEST_SUCCESS = "CONTENT_PRIVACYPOLICY_REQUEST_SUCCESS",
CONTENT_PRIVACYPOLICY_REQUEST_FAILURE = "CONTENT_PRIVACYPOLICY_REQUEST_FAILURE";

export function contentTermsRequest() {
    return {
        type: CONTENT_TERMS_REQUEST
    };
}

export function contentTermsRequestSuccess(content) {
    return {
        type: CONTENT_TERMS_REQUEST_SUCCESS,
        content
    };
}

export function contentTermsRequestFailure() {
    return {
        type: CONTENT_TERMS_REQUEST_FAILURE
    };
}

export function contentPrivacyPolicyRequest() {
    return {
        type: CONTENT_PRIVACYPOLICY_REQUEST
    };
}

export function contentPrivacyPolicyRequestSuccess(content) {
    return {
        type: CONTENT_PRIVACYPOLICY_REQUEST_SUCCESS,
        content
    };
}

export function contentPrivacyPolicyRequestFailure() {
    return {
        type: CONTENT_PRIVACYPOLICY_REQUEST_FAILURE
    };
}