import { connect } from 'react-redux';
import ImageProcessing from '../components/controls/ImageProcessing';
import * as imageProcessingActions from '../common/actions/imageProcessing';
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        incidentDocumentId: props.incidentDocumentId,
        incidentPatronPhotoId: props.incidentPatronPhotoId,
        isProcessing: state.imageProcessing.isProcessing,
        hasPermission: permissionsLogic.hasPermissionForState(state, "OCRImageProcessing"),
        onDismissed: props.onDismissed,
        filePath: props.filePath,
        fileName: props.fileName,
        imageText: props.imageText,
        processedImageText: state.imageProcessing.imageText,
        saveToDocument: props.saveToDocument
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onProcessTemporaryFile: (filePath) => {
            dispatch(imageProcessingActions.imageProcessingTemporaryFileRequest(filePath))
        },
        onProcessIncidentDocument: (incidentDocumentId, saveToDocument) => {
            dispatch(imageProcessingActions.imageProcessingIncidentDocumentRequest(incidentDocumentId, saveToDocument))
        },
        onProcessIncidentPatronPhoto: (incidentPatronPhotoId, saveToDocument) => {
            dispatch(imageProcessingActions.imageProcessingIncidentPatronPhotoRequest(incidentPatronPhotoId, saveToDocument))
        }
    }
}

const ImageProcessingContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ImageProcessing))

export default ImageProcessingContainer