import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const signOnServiceApi = {
  postSignOnStart: (venueId, securityFirmId, venueEventId: number, latitude, longitude, locationFound) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriodSignOnStart();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.latitude = latitude;
    query.longitude = longitude;
    query.locationFound = locationFound;
    const request = client.post(query);
    return from(request);
  },
  postSignOnToRegisterUserCheck: (venueId, venueEventId: number, userRoleId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SignOnToRegisterUserCheck();
    query.venueId = venueId;
    query.venueEventId = venueEventId;
    query.userRoleId = userRoleId;
    const request = client.post(query);
    return from(request);
  },
  postSignOnToRegister: (userSignOnStatus: AusComplyDtos.UserSignOnStatus, latitude: number, longitude: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SignOnToRegister();

    // legacy
    query.userId = userSignOnStatus.userId;
    query.userRoleId = userSignOnStatus.userRoleId;
    query.password = userSignOnStatus.password;
    query.venueId = userSignOnStatus.venueId;
    query.securityFirmId = userSignOnStatus.securityFirmId;
    query.venueEventId = userSignOnStatus.venueEventId;
    query.guardNumber = userSignOnStatus.guardNum;
    query.supervisorRequired = userSignOnStatus.supervisorRequired;
    query.supervisorUserId = userSignOnStatus.supervisorUserRoleId;
    query.latitude = latitude;
    query.longitude = longitude;
    query.rosteredStart = userSignOnStatus.rosteredStartDate;
    query.shiftHours = userSignOnStatus.shiftHours;
    query.shiftMinutes = userSignOnStatus.shiftMinutes;
    // ~ legacy
    
    query.userSignOnStatus = userSignOnStatus;
    query.userSignOnStatus.rosteredStart = userSignOnStatus.rosteredStartDate;
    query.userSignOnStatus.latitude = latitude;
    query.userSignOnStatus.longitude = longitude;

    const request = client.post(query);
    return from(request);
  },
  getUserSignOnRegisterStatusWithLocationAndIncidents: (venueId, securityFirmId, venueEventId: number, signOnRegisterId, latitude, longitude, locationFound) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriodUserSignOnStatus();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.signOnRegisterId = signOnRegisterId;
    query.latitude = latitude;
    query.longitude = longitude;
    query.locationFound = locationFound;
    query.includeIncidents = true;
    query.validateLocation = true;
    const request = client.get(query);
    return from(request);
  },
  getUserSignOnRegisterStatus: (venueId, securityFirmId, venueEventId: number, signOnRegisterId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriodUserSignOnStatus();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.signOnRegisterId = signOnRegisterId;
    query.includeIncidents = false;
    query.validateLocation = false;
    const request = client.get(query);
    return from(request);
  },
  postSignOffRegister: (userId, userRoleId, password, venueId, securityFirmId, venueEventId: number, latitude, longitude, signOnRegisterId, isAcknowledged, signOutDate) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SignOffRegister();
    query.userId = userId;
    query.userRoleId = userRoleId;
    query.password = password;
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.latitude = latitude;
    query.longitude = longitude;
    query.signOnRegisterId = signOnRegisterId;
    query.isAcknowledged = isAcknowledged;
    query.signOutDate = signOutDate;
    const request = client.post(query);
    return from(request);
  },
  postSignOnRegisterExtend: (venueId, securityFirmId, venueEventId: number, signOnRegisterId, password, hours, minutes) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SignOnRegisterExtend();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.signOnRegisterId = signOnRegisterId;
    query.password = password;
    query.hours = hours;
    query.minutes = minutes;

    const request = client.post(query);
    return from(request);
  },
  getSignOnRegister: (venueId: number, securityFirmId: number, venueEventId: number, filter: AusComplyDtos.SignOnRegisterFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSignOnRegisters();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  getSignOnRegisterFilter: (venueId: number, securityFirmId: number, venueEventId: number, reset: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSignOnRegisterFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.reset = reset;
    const request = client.post(query);
    return from(request);
  },
  break: (venueId: number, securityFirmId: number, venueEventId: number, signOnRegisterId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSignOnRegisterBreak();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.signOnRegisterId = signOnRegisterId;
    const request = client.post(query);
    return from(request);
  },
  breakUpsert: (venueId: number, securityFirmId: number, venueEventId: number, signOnRegisterBreak: AusComplyDtos.SignOnRegisterBreak) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSignOnRegisterBreakUpdate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.signOnRegisterBreak = signOnRegisterBreak;
    const request = client.post(query);
    return from(request);
  }
};
