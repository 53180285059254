import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DefaultButton from '../controls/DefaultButton';
import ButtonLink from '../controls/ButtonLink';
import PasswordControlGroup from '../controls/PasswordControlGroup';
import FormErrorMessage from '../alerts/FormErrorMessage';
import CardContainer from '../common/CardContainer';
import Box from '@material-ui/core/Box';
import Media from "react-media";
import Logo from '../../resources/Logo';
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import { appSource } from '../../utilities/constants';

export interface ILoginResetPasswordCodeProps {
    resetMobile: string
    resetCode: number,
    isCodeValid: boolean;
    isSaving: boolean;
    error: string;
    onResetPassword: Function;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

const CancelLink = forwardRef((props, ref) => <Link to="/" {...props} />);
const TermsLink = forwardRef((props, ref) => <Link to="/terms" {...props} />);
const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/privacypolicy" {...props} />);

class LoginResetPasswordCode extends Component<ILoginResetPasswordCodeProps, any> {
    constructor(props: ILoginResetPasswordCodeProps) {
        super(props)

        this.state = {
            password: "",
            passwordConfirm: ""
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error) {
            this.props.history.push('/');
        }
    }

    onSubmit() {
        this.props.onResetPassword(this.props.resetMobile, this.props.resetCode, this.state.password, this.state.passwordConfirm);
    }

    onChangePassword(value) {
        this.setState({ password: value });
    }

    onChangePasswordConfirm(value) {
        this.setState({ passwordConfirm: value });
    }

    render() {
        const { classes, theme } = this.props;
        let content;
        if (this.props.isSaving) {
            content = (<Saving message={"Resetting password"} />);
        } else {
            content = (
                <>
                    <FormErrorMessage error={this.props.error} />
                    <PasswordControlGroup
                        text={"New Password"}
                        defaultValue={this.state.password}
                        onChange={(value) => this.onChangePassword(value)} />
                    <PasswordControlGroup
                        text={"Confirm New Password"}
                        defaultValue={this.state.passwordConfirm}
                        onChange={(value) => this.onChangePasswordConfirm(value)} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DefaultButton text="Submit" onClick={this.onSubmit} />
                        </Grid>
                    </Grid>
                </>
            );
        }
        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" style={{ position: 'absolute', top: '30px', left: 0, width: '100%', zIndex: -1 }}>
                    <Box p={0} flexGrow={1}></Box>
                    <Box p={0}>
                        <img src={require('../../resources/Background.png')} style={{ width: '340px' }} alt="backdrop" />
                    </Box>
                    <Box p={0} flexGrow={1}></Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <Box p={0} flexGrow={1}></Box>
                    <Box p={0}>
                        <form noValidate autoComplete="off" style={{ margin: '20px' }} >

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1} md={2} lg={4}></Grid>
                                <Grid item xs={12} sm={10} md={8} lg={4} style={{ textAlign: 'center' }}>
                                    <Logo fill={'#fff'} style={{ width: '80%', height: 'auto' }} />
                                    <h3 className={classes.loginTitle}>Digital Incident Register</h3>
                                    <CardContainer style={{ padding: '30px 30px 0 30px', margin: '20px 0' }}>
                                        {content}

                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            <Box p={2} flexGrow={1}>
                                                <ButtonLink text="Cancel" small={true} component={CancelLink} />
                                            </Box>
                                        </Box>
                                    </CardContainer>
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <Box p={0}>
                                            <ButtonLink text="Terms of Use" small={true} component={TermsLink} />
                                        </Box>
                                        <Box p={1}>
                                            <span style={{ color: '#545454', textShadow: '#fff' }}>|</span></Box>
                                        <Box p={0}>
                                            <ButtonLink text="Privacy Policy" small={true} component={PrivacyPolicyLink} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                        </form>

                    </Box>
                    <Box p={0} flexGrow={0}></Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(LoginResetPasswordCode));