export const
    CHECKLIST_TEMPLATES_RESET = "CHECKLIST_TEMPLATES_RESET",
    CHECKLIST_TEMPLATES_FILTER_REQUEST = "CHECKLIST_TEMPLATES_FILTER_REQUEST",
    CHECKLIST_TEMPLATES_FILTER_REQUEST_FAILURE = "CHECKLIST_TEMPLATES_FILTER_REQUEST_FAILURE",
    CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS = "CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS",
    CHECKLIST_TEMPLATES_SET_FILTER = "CHECKLIST_TEMPLATES_SET_FILTER",
    CHECKLIST_TEMPLATES_PAGE = "CHECKLIST_TEMPLATES_PAGE",
    CHECKLIST_TEMPLATES_REQUEST = "CHECKLIST_TEMPLATES_REQUEST",
    CHECKLIST_TEMPLATES_REQUEST_FAILURE = "CHECKLIST_TEMPLATES_REQUEST_FAILURE",
    CHECKLIST_TEMPLATES_REQUEST_SUCCESS = "CHECKLIST_TEMPLATES_REQUEST_SUCCESS",
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST = "CHECKLIST_TEMPLATES_ARCHIVE_REQUEST",
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_FAILURE = "CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_FAILURE",
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS = "CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS",
    CHECKLIST_TEMPLATE_ARCHIVE_REQUEST = "CHECKLIST_TEMPLATE_ARCHIVE_REQUEST",
    CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_FAILURE = "CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_FAILURE",
    CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_SUCCESS = "CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_SUCCESS";

export function checklistTemplatesReset() {
    return {
        type: CHECKLIST_TEMPLATES_RESET
    };
}

export function checklistTemplatesSetFilter(filter) {
    return {
        type: CHECKLIST_TEMPLATES_SET_FILTER, 
        filter
    }
}

export function checklistTemplatesFilterRequest(isAdmin) {
    return {
        type: CHECKLIST_TEMPLATES_FILTER_REQUEST,
        isAdmin
    };
}
    
export function checklistTemplatesFilterRequestSuccess(filter) {
    return {
        type: CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function checklistTemplatesFilterRequestFailure(error) {
    return {
        type: CHECKLIST_TEMPLATES_FILTER_REQUEST_FAILURE,
        error
    };
}

export function checklistTemplatesPage(page) {
    return {
        type: CHECKLIST_TEMPLATES_PAGE,
        page
    }
}

export function checklistTemplatesRequest() {
    return {
        type: CHECKLIST_TEMPLATES_REQUEST
    };
}
    
export function checklistTemplatesRequestSuccess(filter, paging, checklistTemplates) {
    return {
        type: CHECKLIST_TEMPLATES_REQUEST_SUCCESS,
        filter, 
        paging,
        checklistTemplates
    };
}

export function checklistTemplatesRequestFailure(error) {
    return {
        type: CHECKLIST_TEMPLATES_REQUEST_FAILURE,
        error
    };
}

export function checklistTemplatesArchiveRequest(checklistTemplateIds, restore) {
    return {
        type: CHECKLIST_TEMPLATES_ARCHIVE_REQUEST,
        checklistTemplateIds,
        restore
    };
}
    
export function checklistTemplatesArchiveRequestSuccess() {
    return {
        type: CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS
    };
}

export function checklistTemplatesArchiveRequestFailure(error) {
    return {
        type: CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_FAILURE,
        error
    };
}

export function checklistTemplateArchiveRequest(checklistTemplateId, isAdmin, restore) {
    return {
        type: CHECKLIST_TEMPLATE_ARCHIVE_REQUEST,
        checklistTemplateId, 
        isAdmin,
        restore
    };
}
    
export function checklistTemplateArchiveRequestSuccess() {
    return {
        type: CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_SUCCESS
    };
}

export function checklistTemplateArchiveRequestFailure(error) {
    return {
        type: CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_FAILURE,
        error
    };
}