import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FacilityRiskStatusContainer from '../../../containers/FacilityRiskStatusContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import PageTitle from '../../common/PageTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import TableContainer from '../../layout/TableContainer';
//import VenueLocationCard from '../../venue/venueLocations/VenueLocationCard';
import CorrectionalFacilityRiskStatusCard from './CorrectionalFacilityRiskStatusCard';
import CorrectionalFacilityRiskStatusHeader from './CorrectionalFacilityRiskStatusHeader';
import CorrectionalFacilityRiskStatusRow from './CorrectionalFacilityRiskStatusRow';

export interface ICorrectionalFacilityRiskStatusProps {
    isLoading: boolean;
    venueId: number; 
    riskStatuses: AusComplyDtos.CorrectionalFacilityRiskStatus[];
    riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.VenueLocationFilter;
    canCreate: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onSet: Function;
}

export default class CorrectionalFacilityRiskStatus extends React.Component<ICorrectionalFacilityRiskStatusProps, any> {
    constructor(props: ICorrectionalFacilityRiskStatusProps) {
        super(props);

        this.onCommand = this.onCommand.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page)
    }

    componentDidUpdate() {}

    onCreate() {
        var correctionalFacilityRiskStatus = new AusComplyDtos.CorrectionalFacilityRiskStatus();
        correctionalFacilityRiskStatus.venueId = this.props.venueId;
        correctionalFacilityRiskStatus.inspectionFrequency = 5;
        correctionalFacilityRiskStatus.rank = 0;
        correctionalFacilityRiskStatus.obsolete = false;
        this.props.onSet(correctionalFacilityRiskStatus);
    }

    onCommand(command: string, riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus) {
        switch (command) {
            case "edit":
                this.props.onLoadItem(riskStatus);
                break;
            case "delete":
                var riskStatus = { ...riskStatus };
                riskStatus.obsolete = true;
                this.props.onSet(riskStatus);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page);
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.riskStatuses && this.props.riskStatuses.length > 0) {
            cardList = this.props.riskStatuses.map((item, index) => {
                return (
                    <CorrectionalFacilityRiskStatusCard
                        onCommand={this.onCommand}
                        key={"card-" + item.correctionalFacilityRiskStatusId.toString()}
                        riskStatus={item}
                    />
                );
            });
            rows = this.props.riskStatuses.map((item, index) => {
                return (
                    <CorrectionalFacilityRiskStatusRow
                        onCommand={this.onCommand}
                        key={"row-" + item.correctionalFacilityRiskStatusId.toString()}
                        riskStatus={item}
                        showCameras={this.props.filter.showCameras}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<CorrectionalFacilityRiskStatusHeader
                        showCameras={this.props.filter.showCameras} />}
                    rows={rows}
                />
            );
        }

        let createFab;
        if (this.props.canCreate) {
            createFab = (
                <DefaultFab onClick={() => this.onCreate()} isExtended={true}>Add Risk Status</DefaultFab>
            )
        }

        let riskStatus;
        if (this.props.riskStatus.correctionalFacilityRiskStatusId != -1) {
            riskStatus = (
                <FacilityRiskStatusContainer />
            );
        }

        return (<>
            <PageTitle title="Risk Statuses"></PageTitle>

            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />

            <VenueQuickActionsContainer>
                {createFab}
            </VenueQuickActionsContainer>
            <FullPageDialog open={this.props.riskStatus.correctionalFacilityRiskStatusId != -1} title="Risk Status" onDismissed={() => this.props.onResetItem()}>
                {riskStatus}
            </FullPageDialog>
        </>);
    }
}
