import { connect } from 'react-redux'
import CheckListTemplate from '../components/checklists/checklistTemplate/ChecklistTemplate'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { loadAllReferenceData } from '../common/actions/referenceData';
import {
    loadChecklistTemplateRequest,
    checklistTemplatesReset,
    newChecklistTemplateRequest,
    checklistTemplateUpdate,
    updateChecklistTemplateItems,
    saveChecklistTemplateRequest,
    setChecklistTemplateItem,
    setChecklistTemplateGroupedItem,
    addChecklistTemplateItem,
    moveChecklistTemplateItem,
    removeChecklistTemplateItem
} from '../common/actions/checklistTemplate';

const isLoading = (state) =>
{
    if (state.checklistTemplate.checklistTemplateLoading) {
        return true;
    }
    if (state.referenceData.statesLoading) {
        return true;
    }
    if (state.referenceData.userVenuesLoading) {
        return true;
    }
    if (state.referenceData.userSecurityFirmsLoading) {
        return true;
    }
    if (state.referenceData.industryCategoriesLoading) {
        return true;
    }
    if (state.referenceData.checklistTemplateCategoriesLoading) {
        return true;
    }
    return false;
}

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        checklistTemplateId: props.match.params.checklistTemplateId,
        checklistTemplate: state.checklistTemplate.checklistTemplate,
        groupedChecklistTemplateItems: state.checklistTemplate.checklistTemplate.groupedChecklistTemplateItems,
        checklistTemplateItemsVersion: state.checklistTemplate.checklistTemplateItemsVersion,
        isLoading: isLoading(state),
        isSaving: state.checklistTemplate.checklistTemplateSaving,
        canEditVenues: permissionsLogic.hasPermissionForState(state, 'ChecklistTemplateEditVenues'),
        canEditSecurityFirms: permissionsLogic.hasPermissionForState(state, 'ChecklistTemplateEditSecurityFirms'),
        canEditIndustryCategories: permissionsLogic.hasPermissionForState(state, 'ChecklistTemplateEditIndustryCategories'),
        canEditStates: permissionsLogic.hasPermissionForState(state, 'ChecklistTemplateEditStates'),
        canEditCategories: permissionsLogic.hasPermissionForState(state, 'ChecklistTemplateEditCategories'),
        venues: state.referenceData.userVenues,
        securityFirms: state.referenceData.userSecurityFirms,
        industryCategories: state.referenceData.industryCategories,
        states: state.referenceData.states,
        checklistTemplateCategories: state.referenceData.checklistTemplateCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (checklistTemplateId) => {
            dispatch(loadChecklistTemplateRequest(checklistTemplateId))
        },
        onCreate: () => {
            dispatch(newChecklistTemplateRequest())
        },
        onLoadReferenceData: () => {
            dispatch(loadAllReferenceData());
        },
        onUpdate: (checklistTemplate) => {
            dispatch(checklistTemplateUpdate(checklistTemplate));
        },
        onSave: (checklistTemplate) => {
            dispatch(saveChecklistTemplateRequest(checklistTemplate));
        },
        onUpdateItem: (checklistTemplateItem) => {
            dispatch(setChecklistTemplateItem(checklistTemplateItem));
        },
        onAddItem: (checklistItemType, parentId, position) => {
            dispatch(addChecklistTemplateItem(checklistItemType, parentId, position))
        },
        onMoveItem: (checklistTemplateItemId, parentId, position) => {
            dispatch(moveChecklistTemplateItem(checklistTemplateItemId, parentId, position))
        },
        onRemoveItem: (checklistTemplateItem) => {
            dispatch(removeChecklistTemplateItem(checklistTemplateItem))
        }
    }
}

const CheckListTemplateContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckListTemplate))

export default CheckListTemplateContainer