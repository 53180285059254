import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { deviceInfo } from '../../utilities/device';

export const venueServiceApi = {
  get: (venueId: number, securityFirmId: number, forVenueId: number, includeOptions: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenue();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.includeOptions = includeOptions;
    const request = client.get(query);
    return from(request);
  },
  getAvailable: (latitude: number, longitude: number) => {
    var client = new AusComplyJsonServiceClient();
    var userVenuesWithLocation = new AusComplyDtos.UserVenuesWithLocation();
    userVenuesWithLocation.latitude = latitude;
    userVenuesWithLocation.longitude = longitude;
    const request = client.get(userVenuesWithLocation);
    return from(request);
  },
  getAvailableSecurityFirms: (venueId: number) => {
    var client = new AusComplyJsonServiceClient();
    var userVenueSecurityFirms = new AusComplyDtos.UserVenueSecurityFirms();
    userVenueSecurityFirms.venueId = venueId;
    const request = client.get(userVenueSecurityFirms);
    return from(request);
  },
  getUserStatistics: (venueId: number, securityFirmId: number, venueEventId: number) => {
    var client = new AusComplyJsonServiceClient();
    var getUserStatistics = new AusComplyDtos.GetUserStatistics();
    getUserStatistics.venueId = venueId;
    getUserStatistics.securityFirmId = securityFirmId;
    getUserStatistics.venueEventId= venueEventId;
    const request = client.get(getUserStatistics);
    return from(request);
  },
  postVenueCode: (venueId: number, securityFirmId: number, venueEventId: number, code: string, locationFound?: boolean, latitude?: number, longitude?: number) => {
    var client = new AusComplyJsonServiceClient();
    var postVenueLogin = new AusComplyDtos.PostVenueLogin();
    postVenueLogin.venueId = venueId;
    postVenueLogin.securityFirmId = securityFirmId;
    postVenueLogin.venueEventId = venueEventId;
    postVenueLogin.code = code;
    try {
      // set the location metadata
      postVenueLogin.locationFound = locationFound || false;
      postVenueLogin.latitude = latitude || 0;
      postVenueLogin.longitude = longitude || 0;
    } catch (error) {
    }
    try {
      // set the device metadata
      let info = deviceInfo.get();
      postVenueLogin.deviceId = info.deviceId;
      postVenueLogin.os = info.os;
      postVenueLogin.model = info.model;
      postVenueLogin.make = info.make;
      postVenueLogin.version = info.version;
      postVenueLogin.appVersion = info.version;
    } catch (error) {

    }
    const request = client.post(postVenueLogin);
    return from(request);
  },
  availableToRequestUserRoles: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenuesAvailableForUserRoleRequest();
    const request = client.get(query);
    return from(request);
  },
  getAdminVenues: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.VenueFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminVenues();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  getAdminVenueAssociations: (venueId: number, securityFirmId: number, forVenueId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminVenueAssocations();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  removeVenueSecurityFirm: (venueId: number, securityFirmId: number, venueSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.AdminVenueSecurityFirmRemove();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueSecurityFirmId = venueSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  removeSecurityFirmDescendant: (venueId: number, securityFirmId: number, securityFirmDescendantId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.AdminVenueSecurityFirmDescendantRemove();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.securityFirmDescendantId = securityFirmDescendantId;
    const request = client.post(query);
    return from(request);
  },
  createNew: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetNewVenue();
    const request = client.get(query);
    return from(request);
  },
  saveNew: (
    venue: AusComplyDtos.Venue, 
    venueContact: AusComplyDtos.VenueContact,
    licenseContact: AusComplyDtos.VenueContact,
    otherDocuments: AusComplyDtos.File[],
    complianceDocuments: AusComplyDtos.File[]) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostNewVenue();
      query.venue = venue;
      query.venueContact = venueContact;
      query.licenseContact = licenseContact;
      query.complianceDocuments = complianceDocuments;
      query.otherDocuments = otherDocuments;
      const request = client.post(query);
      return from(request);
  },
  obsolete: (venueId: number, securityFirmId: number, venueToRemoveId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueObsolete();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueToRemoveId = venueToRemoveId;
    const request = client.post(query);
    return from(request);
  },
  delete: (venueId: number, securityFirmId: number, venueToDeleteId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.DeleteVenue();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueToDeleteId = venueToDeleteId;
    const request = client.delete(query);
    return from(request);
  },
  export: (venueId: number, securityFirmId: number, filter: AusComplyDtos.VenueFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.AnyVenuesExport();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  save:(venueId: number, securityFirmId: number, venue: AusComplyDtos.Venue) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostVenue();
      query.venue = venue;
      query.venueId = venueId;
      query.securityFirmId = securityFirmId;
      query.venue = venue;
      const request = client.post(query);
      return from(request);
  },
  getDocuments: (venueId: number, securityFirmId: number, forVenueId: number, showObsoleteDocuments: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueDocuments();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.showObsoleteDocuments = showObsoleteDocuments;
    const request = client.get(query);
    return from(request);
  },
  saveOtherDocuments: (venueId: number, securityFirmId: number, forVenueId: number, documentTypeId: number, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueOtherDocument();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.documentTypeId = documentTypeId;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeOtherDocuments: (venueId: number, securityFirmId: number, forVenueId: number, document: AusComplyDtos.VenueOtherDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueOtherDocumentRemove();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.venueDocumentId = document.venueDocumentId;
    query.venueOtherDocumentId = document.venueOtherDocumentId;
    const request = client.post(query);
    return from(request);
  },
  saveComplianceDocuments: (venueId: number, securityFirmId: number, forVenueId: number, documentTypeId: number, expiryDate: AusComplyDtos.SimpleDate, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueComplianceDocument();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.documentTypeId = documentTypeId;
    query.expiryDate = expiryDate;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeComplianceDocuments: (venueId: number, securityFirmId: number, forVenueId: number, document: AusComplyDtos.VenueComplianceDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueComplianceDocumentRemove();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.venueDocumentId = document.venueDocumentId;
    query.venueComplianceDocumentId = document.venueComplianceDocumentId;
    const request = client.post(query);
    return from(request);
  },
  getSecurityFirmAssociations: (venueId: number, securityFirmId: number, forVenueId: number, page: number, pageSize: number, showObsolete: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueSecurityFirmSummary();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.page = page;
    query.pageSize = pageSize;
    query.showObsolete = showObsolete;
    const request = client.post(query);
    return from(request);
  },
  getSecurityFirmAssociations2: (venueId: number, securityFirmId: number, forVenueId: number, forSecurityFirmId: number, page: number, pageSize: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueSecurityFirmSummary();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  createVenueSecurityFirmAssociation: (venueId: number, securityFirmId: number, forVenueId: number, forSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueSecurityFirmCreate();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  saveVenueSecurityFirmAssociation: (venueId: number, securityFirmId: number, venueSecurityFirm: AusComplyDtos.VenueSecurityFirm ) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueSecurityFirm();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.venueSecurityFirm = venueSecurityFirm;
    const request = client.post(query);
    return from(request);
  },
  deleteVenueSecurityFirmAssociation: (venueId: number, securityFirmId: number, venueSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.DeleteVenueSecurityFirm();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.venueSecurityFirmId = venueSecurityFirmId;
    const request = client.delete(query);
    return from(request);
  },
  restoreVenueSecurityFirmAssociation: (venueId: number, securityFirmId: number, venueSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueSecurityFirmRestore();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.venueSecurityFirmId = venueSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  adminDismissRegistration: (venueId: number, securityFirmId: number, forVenueId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminVenueDismiss();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  }
};
