import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Palette } from '../../common/constants/palette';

export interface IButtonLinkProps {
    theme: any;
    classes: any;
    text?: string;
    className?: any;
    onClick?: Function;
    component?: any;
    style?: any;
    disabled? :boolean;
    small?: boolean;
    to?: any;
}

class ButtonLink extends React.PureComponent<IButtonLinkProps, any> {
    constructor(props: IButtonLinkProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { classes, theme } = this.props;
        let style = this.props.style;
        if(!style) {
            style = {
                width: '100%', height: '100%'
            };
        }
        let buttonClasses = this.props.small ? classNames(classes.buttonLink, classes.buttonLinkSmall, classes.buttonState, this.props.className) : classNames(classes.buttonLink, classes.buttonState, this.props.className);
        if(this.props.component){
            return (
                <Button
                    onClick={this.onClick}
                    color="default"
                    size={this.props.small ? "small" : "medium"}
                    component={this.props.component}
                    disabled={this.props.disabled}
                    className={buttonClasses}
                    style={{ color: Palette.Border, ...style }}>{this.props.text}{this.props.children}</Button>
            );
        }
        return (
            <Button
                onClick={this.onClick}
                color="default"
                size={this.props.small ? "small" : "medium"}
                disabled={this.props.disabled}
                className={buttonClasses}
                style={{ color: Palette.Border, ...style }}>{this.props.text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ButtonLink);