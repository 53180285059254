import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmBroadcastNotifications from '../components/securityFirm/broadcastNotifications/SecurityFirmBroadcastNotifications'

const mapStateToProps = (state, props) => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const SecurityFirmBroadcastNotificationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmBroadcastNotifications))

export default SecurityFirmBroadcastNotificationsContainer