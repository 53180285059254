import { connect } from 'react-redux'
import AddUserRole from '../components/userRoles/addUserRole/AddUserRole'
import { userRoleAddRequest, userRoleUpdate, userRoleAddSave  } from '../common/actions/userRole'
import { notificationsActionRequest } from '../common/actions/notifications'

const mapStateToProps = (state, props) => {
    return {
        notificationId: props.notificationId,
        forVenueId: props.venueId,
        forSecurityFirmId: props.securityFirmId,
        currentVenueId: state.user.details.userSession.user.venueId,
        currentSecurityFirmId: state.user.details.userSession.user.securityFirmId,
        isLoading: state.userRole.isLoading,
        userRole: state.userRole.userRole,
        venues: state.userRole.venues,
        securityFirms: state.userRole.securityFirms,
        userRoleTypes: state.userRole.userRoleTypes,
        isSaving: state.userRole.isSaving || state.notifications.isSaving,
        error: state.userRole.error,
        userId: props.userId,
        forSecurityFirm: props.forSecurityFirm,
        onSuccess: props.onSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNew: (userId, venueId, securityFirmId, forSecurityFirm) => {
            dispatch(userRoleAddRequest(userId, venueId, securityFirmId, forSecurityFirm))
        },
        onChange: (userRole) => {
            dispatch(userRoleUpdate(userRole))
        },
        onSave: (userRole, notificationId) => {
            dispatch(userRoleAddSave(userRole, notificationId))
        },
        onDeny: (notificatoinId) => {
            dispatch(notificationsActionRequest(notificatoinId, false))
        }
    }
}

const AddUserRoleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUserRole)

export default AddUserRoleContainer