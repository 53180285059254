import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TransparentButton from '../../controls/TransparentButton';
import DefaultButton from '../../controls/DefaultButton';
import InlineMedia from '../../common/InlineMedia';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import View from '../../common/View';
import CardRow from '../../common/CardRow';
import PaperclipIcon from '../../../resources/Paperclip2Icon';
import TickIcon from '../../../resources/Tick2Icon';
import QuestionIcon from '../../../resources/QuestionIcon';
import ChecklistTemplateItemDrop from './ChecklistTemplateItemDrop';
import ChecklistTemplateItemCards from './ChecklistTemplateItemCards';
import ChecklistTemplateItemCardDrag from './ChecklistTemplateItemCardDrag';


export interface IChecklistTemplateItemCardProps {
    checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem;
    notificationTriggerParentIds: number[];
    notification?: boolean;
    index: number;
    onEdit: Function;
    onRemove: Function;
    onMove: Function;
    // Collected Props
    //connectDragSource: ConnectDragSource;
    //isDragging?: boolean;
    //isDropped?: boolean;
    //style?: any;
}

class ChecklistTemplateItemCard extends Component<IChecklistTemplateItemCardProps, any> {
    constructor(props: IChecklistTemplateItemCardProps) {
        super(props)
    }

    render() {
        const item = this.props.checklistTemplateItem;
        const index = this.props.index;

        let content;
        if (false) { //this.props.isDragging) {
            /*
            content = (
                <CardRow style={{ margin: '5px', cursor: 'move' }}>
                    <CardTitle>
                        <ListIcon fill={"#999"} style={{ width: '13px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                        {item.checklistItemType}
                    </CardTitle>
                    {
                        item.description && (
                            <CardTypography style={{ whiteSpace: 'none' }}>{item.description}</CardTypography>
                        )
                    }
                </CardRow>
                <CardTitle style={{ cursor: 'ns-resize' }}>
                        <ListIcon fill={"#999"} style={{ width: '13px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                        {item.checklistItemType}
                    </CardTitle>
                    {item.name && (
                        <CardTypography style={{ whiteSpace: 'none' }}>{item.name}</CardTypography>
                    )}
                    {item.description && (
                        <CardTypography style={{ whiteSpace: 'none', fontStyle: 'italic', color: '#ccc' }}>{item.description}</CardTypography>
                    )}
            );*/
        } else if (false) {
            content = (
                <CardRow style={{ margin: '5px' }}>
                    <ChecklistTemplateItemCardDrag
                        checklistTemplateItem={this.props.checklistTemplateItem}
                        index={this.props.index}
                        onMove={this.props.onMove}
                    />
                </CardRow>
            );
        } else {
            content = (
                <CardRow style={{ margin: '5px' }}>
                    <ChecklistTemplateItemCardDrag
                        checklistTemplateItem={this.props.checklistTemplateItem}
                        index={this.props.index}
                        onMove={this.props.onMove}
                    />
                    <CardTypography style={{ whiteSpace: 'none', color: '#999' }}>
                        {item.attachment && item.attachment.fileName && (
                            <InlineMedia
                                icon={<PaperclipIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={item.attachment.fileName} />
                        )}
                        {item.file && item.file.fileName && (
                            <InlineMedia
                                icon={<PaperclipIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={item.file.originalName} />
                        )}
                        {item.mandatory && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Mandatory"} />
                        )}
                        {item.requiredAttachmentCount > 0 && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={item.requiredAttachmentCount.toString() + " Attachments required"} />
                        )}
                        {(item.enableInstanceAttachments && (!item.requiredAttachmentCount || item.requiredAttachmentCount < 1)) && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Attachments enabled"} />
                        )}
                        {item.onCreate && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Incident Create"} />
                        )}
                        {item.onEdit && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Incident Edit"} />
                        )}
                        {item.userRoleTypeSelections && item.userRoleTypeSelections.filter(f => f.selected == true).length > 0 && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Notify"} />
                        )}
                        {item.checklistTemplateItemFilters && item.checklistTemplateItemFilters.filter(f => !f.obsolete).length > 0 && (
                            <InlineMedia
                                icon={<QuestionIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Conditional"} />
                        )}
                        {this.props.notification && (
                            <InlineMedia
                                icon={<TickIcon fill={"#999"} style={{ width: '18px', height: '18px' }} />}
                                text={"Notification"} />
                        )}
                    </CardTypography>
                    {(item.checklistItemType === AusComplyDtos.ChecklistItemType.MultipleChoice ||
                        item.checklistItemType === AusComplyDtos.ChecklistItemType.Question ||
                        item.checklistItemType === AusComplyDtos.ChecklistItemType.Selection) && (
                            <>
                                <CardTitle>Answers</CardTitle>
                                <Grid>
                                    <Grid item xs={12}>
                                        {item.checklistTemplateItemOptions.length === 0 && (
                                            <CardTypography style={{ whiteSpace: 'none' }}>No answers specified yet</CardTypography>
                                        )}
                                        {item.checklistTemplateItemOptions.filter(f => !f.obsolete).map((option, index) => {
                                            return (
                                                <Chip key={"checklistTemplateItems-option-" + option.rank + "-" + index} label={option.name} style={{ marginRight: '5px' }}></Chip>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    <Grid style={{ marginBottom: '5px' }}>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <TransparentButton text={"Remove"} onClick={() => this.props.onRemove(item)} style={{ width: 'auto', marginRight: '10px' }} />
                            <DefaultButton text={"Edit"} onClick={() => this.props.onEdit(item)} style={{ width: 'auto', marginRight: '10px' }} />
                        </Grid>
                    </Grid>
                    {item.checklistItemType === AusComplyDtos.ChecklistItemType.Group && (
                        <>
                            <CardTitle>Sub Items</CardTitle>
                            <ChecklistTemplateItemCards
                                checklistTemplateItems={item.groupedItems}
                                notificationTriggerParentIds={this.props.notificationTriggerParentIds}
                                parentId={item.checklistTemplateItemId}
                                onEdit={this.props.onEdit}
                                onRemove={this.props.onRemove}
                                onMove={this.props.onMove}
                            />
                        </>
                    )}
                </CardRow>
            );
        }

        //return //this.props.connectDragSource(
        return (
            <div
                key={"checklistTemplateItems-" + item.temporaryId + "-" + index}>
                <ChecklistTemplateItemDrop position={index} parentId={item.temporaryParentId} />
                {content}
            </div>
        );
    }
}

export default ChecklistTemplateItemCard;

/*
export default DragSource((props: IChecklistTemplateItemCardProps) =>
    ItemTypes.ITEM,
    {
        beginDrag: (props: IChecklistTemplateItemCardProps) => ({ name: props.checklistTemplateItem.temporaryId }),
        endDrag(props: IChecklistTemplateItemCardProps, monitor: DragSourceMonitor) {
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult()

            if (dropResult) {
                let alertMessage = '';
                const isDropAllowed = dropResult.allowedDropEffect === 'any';

                if (isDropAllowed) {
                    // console .log('drop', dropResult, props.checklistTemplateItem.temporaryId);
                    if (props.onMove) {
                        props.onMove(props.checklistTemplateItem, dropResult.parentId, dropResult.position);
                    }
                }
            }
        },
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(ChecklistTemplateItemCard)
*/