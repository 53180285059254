import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../utilities/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonLink from '../controls/ButtonLink';
import DocumentIcon from '../../resources/DocumentIcon';
import Box from '@material-ui/core/Box';
import Progressing from '../controls/Progressing';
import BlockIcon from '@material-ui/icons/Block';

export interface IThumbnailLargeProps {
    theme: any;
    classes: any;
    previewPath: string;
    displayPath: string;
    isImage: boolean;
    alwaysShowText?: boolean;
    text: string;
    fullText?: string;
    title?: string;
    isAbsolulePath?: boolean;
    onPreview?: Function;
    onDownload?: Function;
    style?: any;
}

class ThumbnailLarge extends React.PureComponent<IThumbnailLargeProps, any> {
    constructor(props: IThumbnailLargeProps) {
        super(props)
        this.onPreview = this.onPreview.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onPreview(path) {
        if (this.props.onPreview) {
            this.props.onPreview(path, this.props.fullText);
        }
    }


    onDownload(path) {
        if (this.props.onDownload) {
            this.props.onDownload(path)
        }
    }

    render() {
        const { theme } = this.props;
        let boxStyle: any = {};

        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let display = base + this.props.displayPath;
        let view = base + this.props.previewPath;
        if (this.props.isAbsolulePath) {
            display = this.props.displayPath;
            view = this.props.previewPath;
        }

        let cursor = "default";
        if (this.props.onPreview || this.props.onDownload) {
            cursor = "pointer";
        }

        let preview;
        if (this.props.isImage) {
            preview = (
                <img src={view}
                    style={{ ...theme.custom.fileUploaderImage, width: "200px", height: "100px" }}
                    alt="photo"
                    onClick={() => this.onPreview(display)} />
            );
        } else if (view.indexOf("api/file") > -1) {
            preview = (
                <Box display="flex" flexDirection="row" justifyContent="center" style={{ minWidth: "200px" }} onClick={() => this.onDownload(display)} >
                    <Box p={0} >
                        <img src={view}
                            style={{ ...theme.custom.fileUploaderImage, "height": "20px", "width": "20px", marginTop: '4px' }}
                            alt="document"
                        />
                    </Box>
                    <Box p={0} >
                        <p style={{ fontSize: '12px', margin: '4px 2px 2px 2px', paddingTop: '2px', paddingRight: '2px' }}>{this.props.fullText}</p>
                    </Box>
                </Box>);
        }

        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box p={1} flexGrow={1} style={{ cursor: cursor }}>
                    <div style={{
                        ...theme.custom.fileUploaderContainer,
                        position: 'relative',
                        ...this.props.style
                    }}>
                        {preview}
                    </div>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ThumbnailLarge);