import { connect } from 'react-redux';
import UserPermissions from '../components/profile/UserPermissions';

const mapStateToProps = state => {
    return {
        permissions: state.user.details.userSession.permissions
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const UserPermissionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPermissions)

export default UserPermissionsContainer