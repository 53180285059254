import { connect } from 'react-redux'
import Notes from '../components/notes/Notes'
import * as notesActions from '../common/actions/notes'

const mapStateToProps = (state, props) => {
    return {
        entityType: props.entityType,
        entityId: props.entityId,
        wrapInCard: props.wrapInCard,
        isLoading: state.notes.isLoading,
        isSaving: state.notes.isSaving,
        note: state.notes.note,
        newNote: state.notes.newNote,
        notes: state.notes.notes,
        paging: state.notes.paging,
        filter: state.notes.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(notesActions.notesRequest(page, pageSize, filter))
        },
        onUpdateNew: (note) => {
            dispatch(notesActions.noteNewUpdate(note))
        },
        onUpdateExisting: (note) => {
            dispatch(notesActions.noteExistingUpdate(note))
        },
        onSaveNew: () => {
            dispatch(notesActions.noteSaveNewRequest())
        },
        onSaveExisting: () => {
            dispatch(notesActions.noteSaveExistingRequest())
        },
        onResetExisting: () => {
            dispatch(notesActions.noteResetExisting())
        }
    }
}

const NotesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Notes)

export default NotesContainer