import { connect } from 'react-redux'
import { withRouter } from "react-router";
import ExternalApiConnectionWebhookMessages from '../components/admin/externalApiConnection/ExternalApiConnectionWebhookMessages';
import * as externalApiConnectionActions from '../common/actions/externalApiConnection';

const mapStateToProps = (state, props) => {
    return {
        entityType: props.entityType,
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.externalApiConnections.externalApiConnectionWebhookMessages.paging,
        filter: state.externalApiConnections.externalApiConnectionWebhookMessages.filter,
        isLoading: state.externalApiConnections.externalApiConnectionWebhookMessages.isLoading,
        isSaving: state.externalApiConnections.externalApiConnectionWebhookMessages.isSaving,
        externalApiConnectionWebhookMessages: state.externalApiConnections.externalApiConnectionWebhookMessages.externalApiConnectionWebhookMessages
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(externalApiConnectionActions.externalApiConnectionWebhookMessagesRequest(page, pageSize, filter))
        },
        onReProcess: (entityId, externalApiConnectionWebhookMessageId) => {
            dispatch(externalApiConnectionActions.externalApiConnectionWebhookMessageReProcessRequest(entityId, externalApiConnectionWebhookMessageId))
        }
    }
}

const ExternalApiConnectionWebhookMessagesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalApiConnectionWebhookMessages))

export default ExternalApiConnectionWebhookMessagesContainer