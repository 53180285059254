
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const venueContactLogic = {

    getCommands(venueContact: AusComplyDtos.VenueContact, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            if (!venueContact.obsolete && !venueContact.primaryContact) {
                commands.push({ key: "primary", name: "Make Primary" });
            }
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}