import React from "react";

const Logo = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 229.6 53.6"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={fill}>
                <path className="st0" d="M37.1,15.3L30.8,4.5c3.9,0.7,7.4,2.4,10.3,4.8L37.1,15.3z M22.6,4.5L5.4,34.3c-0.8-2.4-1.3-4.9-1.3-7.5 C4.1,15.7,12.1,6.5,22.6,4.5 M26.8,44.5L54.6,1.1h-8l-3.2,4.7C38.9,2.2,33.1,0,26.8,0C12,0,0,12,0,26.8s12,26.8,26.8,26.8 c10.4,0,19.5-6,23.9-14.7l-2.6-4.4l-4.7-8.2l-4.5,7l4.9,8.6c-4.2,4.7-10.2,7.7-17,7.7c-6.8,0-12.9-3-17.1-7.8l17-29.5l5.7,9.9 l-5.7,8.4l-3.1-4.6l-4.4,7.6l7.3,10.7L26.8,44.5z M70.7,36.5L84,14.8h-4l-3.6,5.7L72,12.4h-2.5L56.8,35.8h5.4l8.5-16.4l0,0l0,0 l2.9,5.5l-2.9,4.6l-1.6-2.4L67,31l3.7,5.3L70.7,36.5z M79.3,35.8h5.3l-5.4-9.9l-2.8,4.4L79.3,35.8z M100.6,29.1v-9.9h-4.2V28 c0,3-1.2,4.2-3.7,4.2s-3.6-1.2-3.6-4.2v-8.8h-4.2v9.9c0,3.9,2.7,6.9,7.9,6.9C97.9,36,100.6,33,100.6,29.1 M107.7,23.8 c0-0.8,0.7-1.3,2.1-1.3c1.6,0,3.6,0.5,5.2,1.1l1.8-3.3c-1.8-0.7-4-1.4-6.8-1.4c-4.1,0-6.6,2.2-6.6,5.2c0,3.4,2.9,4.4,6.5,5.2 c2.4,0.5,3.1,1,3.1,1.8c0,0.9-0.8,1.4-2.3,1.4c-2,0-3.8-0.2-6-1.2l-1.6,3.2c2.7,1.3,4.4,1.6,7.4,1.6c4.1,0,6.8-2,6.8-5.3 c0-3.1-2.5-4.3-6.3-5.1C108.5,25.1,107.7,24.7,107.7,23.8 M124.7,24.6c0-4.3,3.3-7.1,7.4-7.1c2.4,0,4.6,1.1,5.9,2.9l3.7-3 c-2.3-2.6-5.7-4.3-9.6-4.3c-6.8,0-12.3,5.1-12.3,11.4s5.5,11.4,12.3,11.4c3.9,0,7.3-1.6,9.6-4.2l-3.7-3c-1.3,1.8-3.5,2.9-5.9,2.9 C128.1,31.8,124.7,28.9,124.7,24.6 M156.8,27.5c0,2.6-2.1,4.7-4.8,4.7c-2.6,0-4.8-2.1-4.8-4.7c0-2.6,2.1-4.7,4.8-4.7 C154.7,22.8,156.8,24.9,156.8,27.5 M161.2,27.5c0-4.7-4.1-8.5-9.2-8.5c-5.1,0-9.2,3.8-9.2,8.5c0,4.7,4.1,8.5,9.2,8.5 C157.1,36,161.2,32.2,161.2,27.5 M177.3,35.8h4.4V19.2h-2.1l-7,8.7l-7-8.7h-2.1v16.6h4.3v-7.6l4.7,5.8l4.7-5.8V35.8z M194.9,25.7 c0,1.5-1,2.9-3.6,2.9h-1.8v-5.8h2.2C194.1,22.7,194.9,24.2,194.9,25.7 M199.5,25.4c0-3.2-1.8-6.2-7-6.2h-7.3v16.6h4.3V32h2.2 C196.3,32,199.5,30.1,199.5,25.4 M214.7,32.4h-8.5V19.2h-4.3v16.6h12.8V32.4z M223,30.2l6.6-11h-4.9l-3.8,6.5l-3.8-6.5h-4.9l6.5,11 v5.6h4.4V30.2z" />
            </g>
        </svg>
    );

export default Logo;
