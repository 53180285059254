import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const checklistTemplatesServiceApi = {
  newFilter: (venueId, securityFirmId, isAdmin) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostChecklistTemplatesFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.isAdmin = isAdmin;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostChecklistTemplates();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  archive: (venueId, securityFirmId, checklistTemplateIds, restore) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.ArchiveChecklistTemplates();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.restore = restore;
    query.checklistTemplateIds = checklistTemplateIds;
    const request = client.post(query);
    return from(request);
  },
  archiveTemplate: (venueId, securityFirmId, checklistTemplateId, isAdmin, restore) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.ArchiveChecklistTemplate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.restore = restore;
    query.isAdmin = isAdmin;
    query.checklistTemplateId = checklistTemplateId;
    const request = client.post(query);
    return from(request);
  }
};
