import { makeActionCreator } from './helpers';

export const
    INCIDENT_ACTION_TEMPLATES_RESET = "INCIDENT_ACTION_TEMPLATES_RESET",
    INCIDENT_ACTION_TEMPLATES_SET_FILTER = "INCIDENT_ACTION_TEMPLATES_SET_FILTER",
    INCIDENT_ACTION_TEMPLATES_REQUEST = "INCIDENT_ACTION_TEMPLATES_REQUEST",
    INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_SET = "INCIDENT_ACTION_TEMPLATE_SET",
    INCIDENT_ACTION_TEMPLATE_REQUEST = "INCIDENT_ACTION_TEMPLATE_REQUEST",
    INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE = "INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE",
    INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST = "INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST",
    INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_FAILURE"
    ;

export const incidentActionTemplatesReset = makeActionCreator(INCIDENT_ACTION_TEMPLATES_RESET);
export const incidentActionTemplatesSetFilter = makeActionCreator(INCIDENT_ACTION_TEMPLATES_SET_FILTER, 'filter');
export const incidentActionTemplatesRequest = makeActionCreator(INCIDENT_ACTION_TEMPLATES_REQUEST);
export const incidentActionTemplatesRequestSuccess = makeActionCreator(INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS, 'filter', 'incidentActionTemplates');
export const incidentActionTemplatesRequestFailure = makeActionCreator(INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE, 'error');
export const incidentActionTemplateSet = makeActionCreator(INCIDENT_ACTION_TEMPLATE_SET, 'incidentActionTemplate');
export const incidentActionTemplateSetObsolete = makeActionCreator(INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE, 'incidentActionTemplate');
export const incidentActionTemplateRequest = makeActionCreator(INCIDENT_ACTION_TEMPLATE_REQUEST, 'incidentActionTemplateId');
export const incidentActionTemplateRequestSuccess = makeActionCreator(INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS, 'incidentActionTemplate', 'incidentCategoryTypes');
export const incidentActionTemplateRequestFailure = makeActionCreator(INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE, 'error');
export const incidentActionTemplateCreateRequest = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST, 'venueId', 'groupId');
export const incidentActionTemplateCreateRequestSuccess = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS, 'incidentActionTemplate', 'incidentCategoryTypes');
export const incidentActionTemplateCreateRequestFailure = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE, 'error');
export const incidentActionTemplateUpsertRequest = makeActionCreator(INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST);
export const incidentActionTemplateUpsertRequestSuccess = makeActionCreator(INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS, 'incidentActionTemplate');
export const incidentActionTemplateUpsertRequestFailure = makeActionCreator(INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE, 'error');
export const incidentActionTemplateAddChecklistTemplateItem = makeActionCreator(INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM, 'checklistItemType', 'parentId', 'position');
export const incidentActionRemoveChecklistTemplateItem = makeActionCreator(INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM, 'checklistTemplateItem');
export const incidentActionMoveChecklistTemplateItem = makeActionCreator(INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM, 'checklistTemplateItem', 'parentId', 'position');
export const incidentActionSetChecklistTemplateItem = makeActionCreator(INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM, 'checklistTemplateItem');

export const incidentActionTemplateCloneRequest = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST, 'incidentActionTemplateId', 'forGroupId', 'forVenueId');
export const incidentActionTemplateCloneRequestSuccess = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_SUCCESS, 'incidentActionTemplate');
export const incidentActionTemplateCloneRequestFailure = makeActionCreator(INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_FAILURE, 'error');