import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../../common/dto/common';
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Title from '../../../common/Title';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckListItemAttachments from './CheckListItemAttachments';
import CheckListItemAttachment from './CheckListItemAttachment';
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';
import TextDisplayMultiLine from '../../../controls/TextDisplayMultiLine';
import MultiSelectControl from '../../../controls/MutiSelectControl';
import CheckboxControlGroup from '../../../controls/CheckboxControlGroup';

export interface ICheckListItemStaffMultipleSelectionProps {
    checkListDisplayItem: CheckListDisplayItem;
    checklistUserSelections: AusComplyDtos.ChecklistUserSelection[];
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemStaffMultipleSelectionState {
    items: MulitSelectOption[];
    selectedDisplay: string[];
    includeAssociated: boolean;
}

class CheckListItemStaffMultipleSelection extends Component<ICheckListItemStaffMultipleSelectionProps, ICheckListItemStaffMultipleSelectionState> {
    constructor(props: ICheckListItemStaffMultipleSelectionProps) {
        super(props)
        this.state = {
            items: [],
            selectedDisplay: [],
            includeAssociated: false
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
    }

    componentDidMount() {
        this.buildOptions();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.checkListDisplayItem.item) != JSON.stringify(preProps.checkListDisplayItem.item)) {
            this.buildOptions();
        } else if (JSON.stringify(this.props.checkListDisplayItem.template) != JSON.stringify(preProps.checkListDisplayItem.template)) {
            this.buildOptions();
        }
    }

    buildOptions() {
        let items: MulitSelectOption[] = [];
        let selectedDisplay: string[] = [];
        let selected: string[] = [];

        if (this.props.checkListDisplayItem.item.value) {
            selected = (this.props.checkListDisplayItem.item.value as any) as string[];
            if (!(selected  instanceof Array))
            {
                let raw = this.props.checkListDisplayItem.item.value;
                if (raw !== undefined && raw !== null && typeof raw === 'string') {
                    if (raw.startsWith("[")) {
                        raw = raw.substring(1);
                    }
                    if (raw.endsWith("]")) {
                        raw = raw.substring(0, raw.length - 1);
                    }
                    selected = raw.split(",");
                }
            }
            if (!(selected  instanceof Array)) {
                selected = [this.props.checkListDisplayItem.item.value];
            }
        }
        selected = selected || [];
        let selectedAsString = selected.map((item, index)=> item.toString());
        if (this.props.checklistUserSelections) {
            this.props.checklistUserSelections.forEach(u => {
                let isSelected = selectedAsString.indexOf(u.userId.toString()) > -1;
                if((!u.obsolete && (this.state.includeAssociated || !u.isAssociated)) || isSelected) {
                    items.push({ id: u.userId, name: u.display, isCommon: u.isLoggedIn, selected: isSelected, info: "" });
                    if (isSelected) {
                        selectedDisplay.push(u.display);
                    }
                }
            });
        }
        this.setState({ items, selectedDisplay });
    }

    onSelectItemsChanged(value) {
        var selected: string[] = [];
        var selectedDisplay: string[] = [];
        value.forEach(item => {
            this.props.checklistUserSelections.filter(i => i.userId == item.id).forEach(option => {
                selected.push(option.userId.toString());
                selectedDisplay.push(option.display);
            });
        });

        let checklistItem = { ...this.props.checkListDisplayItem.item };
        // @ts-ignore
        checklistItem.value = selected;
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
        this.setState({selectedDisplay});
    }

    render() {
        const { theme } = this.props;
        let self = this;

        let cardStyle;
        if (this.props.checkListDisplayItem.isRequired) {
            cardStyle = { borderColor: theme.custom.colors.error };
        }
        // <TextDisplay>{this.state.selectedDisplay.join('; ')}</TextDisplay>
        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplayMultiLine lines={this.state.selectedDisplay} />
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <>
                            <Title text={this.props.checkListDisplayItem.template.name} />
                            <CheckboxControlGroup text={"Include associated users"}
                                defaultValue={this.state.includeAssociated} onChanged={(value) => self.setState({includeAssociated: value}, () => self.buildOptions())} />
                            <MultiSelectControl
                                items={this.state.items}
                                selectedTitle={""}
                                hideSelectedTitle={true}
                                canShowAll={true}
                                //hideEmptyMessage={false}                                
                                //emptyMessage='Select a staff member'
                                commonTitle="Online"
                                //hideAvailableText={true}
                                availableTitle="Search staff"
                                searchText="Type at least three letters..."
                                minimumCharactersRequired={3}
                                onSelectItemsChanged={this.onSelectItemsChanged}
                                //autoHideOnSelected={true}
                            ></MultiSelectControl></>
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemStaffMultipleSelection);