
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionAutomaticEnrolmentServiceApi } from '../services/facialRecognitionAutomaticEnrolmentService';

import {
    facialRecognitionAutomaticEnrolmentsRequest,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST,
    facialRecognitionAutomaticEnrolmentsRequestSuccess,
    facialRecognitionAutomaticEnrolmentsRequestFailure,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST,
    facialRecognitionAutomaticEnrolmentRequestSuccess,
    facialRecognitionAutomaticEnrolmentRequestFailure,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST,
    facialRecognitionAutomaticEnrolmentCreateRequestSuccess,
    facialRecognitionAutomaticEnrolmentCreateRequestFailure,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST,
    facialRecognitionAutomaticEnrolmentUpsertRequestSuccess,
    facialRecognitionAutomaticEnrolmentUpsertRequestFailure,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS,
    facialRecognitionAutomaticEnrolmentReset,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST,
    facialRecognitionAutomaticEnrolmentProcessRequestFailure,
    facialRecognitionAutomaticEnrolmentProcessRequestSuccess,
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_SUCCESS
} from "../actions/facialRecognitionAutomaticEnrolment";

import {
    notifyError, notifyErrorMessage
} from './common'; 
import { notificationSuccessShow, notificationShow } from "../actions/notification";

const facialRecognitionAutomaticEnrolmentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionAutomaticEnrolmentServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionAutomaticEnrolmentsRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentsRequestEpic.find", facialRecognitionAutomaticEnrolmentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentsRequestEpic", facialRecognitionAutomaticEnrolmentsRequestFailure))
    );

const facialRecognitionAutomaticEnrolmentRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionAutomaticEnrolmentServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionAutomaticEnrolmentId'])
                .pipe(
                    map(response => facialRecognitionAutomaticEnrolmentRequestSuccess(
                        response.facialRecognitionAutomaticEnrolment,
                        response.incidentTypes,
                        response.incidentTypeCategoryTypes,
                        response.watchlists
                    )),
                    catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentRequestEpic.get", facialRecognitionAutomaticEnrolmentRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentRequestEpic", facialRecognitionAutomaticEnrolmentRequestFailure))
    );

const facialRecognitionAutomaticEnrolmentCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionAutomaticEnrolmentServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionAutomaticEnrolmentCreateRequestSuccess(
                        response.facialRecognitionAutomaticEnrolment,
                        response.incidentTypes,
                        response.incidentTypeCategoryTypes,
                        response.watchlists
                    )),
                    catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentCreateEpic.create", facialRecognitionAutomaticEnrolmentCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentCreateEpic", facialRecognitionAutomaticEnrolmentCreateRequestFailure))
    );

const facialRecognitionAutomaticEnrolmentUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionAutomaticEnrolmentServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolment)
                .pipe(
                    map(response => facialRecognitionAutomaticEnrolmentUpsertRequestSuccess(response.facialRecognitionAutomaticEnrolment)),
                    catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentUpsertEpic.upsert", facialRecognitionAutomaticEnrolmentUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentUpsertEpic", facialRecognitionAutomaticEnrolmentUpsertRequestFailure))
    );

const facialRecognitionAutomaticEnrolmentUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionAutomaticEnrolmentReset())
    );

const facialRecognitionAutomaticEnrolmentUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionAutomaticEnrolmentsRequest(action['facialRecognitionAutomaticEnrolment']['venueId']))
    );

const facialRecognitionAutomaticEnrolmentProcessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionAutomaticEnrolmentServiceApi.process(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionAutomaticEnrolmentId'],
                action['date'])
                .pipe(
                    map(response => facialRecognitionAutomaticEnrolmentProcessRequestSuccess()),
                    catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentProcessEpic.process", facialRecognitionAutomaticEnrolmentProcessRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionAutomaticEnrolmentProcessEpic", facialRecognitionAutomaticEnrolmentProcessRequestFailure))
    );

const facialRecognitionAutomaticEnrolmentProcessSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Incidents have been queued for processing"))
    );

export const facialRecognitionAutomaticEnrolmentEpics = combineEpics(
    facialRecognitionAutomaticEnrolmentsRequestEpic,
    facialRecognitionAutomaticEnrolmentRequestEpic,
    facialRecognitionAutomaticEnrolmentCreateEpic,
    facialRecognitionAutomaticEnrolmentUpsertEpic,
    facialRecognitionAutomaticEnrolmentUpsertSuccessEpic,
    facialRecognitionAutomaticEnrolmentUpsertSuccessRefreshEpic,
    facialRecognitionAutomaticEnrolmentProcessEpic,
    facialRecognitionAutomaticEnrolmentProcessSuccessEpic
);