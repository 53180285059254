import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as notificationTriggerActions from "../actions/notificationTrigger";

const initialState = {
    isLoading: false,
    isSaving: false,
    notificationTrigger: {},
    error: "",
    notificationTriggerUsersSummary: {
        isLoading: false,
        notificationTriggerUsersSummary: {}
    }
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setErrror(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setNotificationTrigger(state, notificationTrigger) {
    let newState = update(state, {
        notificationTrigger: { $set: notificationTrigger }
    });
    return newState;
}

function loadingNotificationTriggerUsersSummary(state, isLoading) {
    let newState = update(state, {
        notificationTriggerUsersSummary: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setNotificationTriggerUsersSummary(state, notificationTriggerUsersSummary) {
    let newState = update(state, {
        notificationTriggerUsersSummary: {
            notificationTriggerUsersSummary: { $set: notificationTriggerUsersSummary }
        }
    });
    return newState;
}

export default function notificationTrigger(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case notificationTriggerActions.NOTIFICATION_TRIGGER_REQUEST:
            return loading(setNotificationTrigger(setErrror(state, ""), {}), true);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_REQUEST_SUCCESS:
            return loading(setNotificationTrigger(state, action.notificationTrigger), false);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_REQUEST_FAILURE:
            return loading(state, false);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_UPSERT_REQUEST:
            return saving(setErrror(state, ""), true);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_UPSERT_REQUEST_SUCCESS:
            return saving(setNotificationTrigger(state, action.notificationTrigger), false);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_UPSERT_REQUEST_FAILURE:
            return saving(setErrror(state, action.error), false);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_SET:
            return setNotificationTrigger(state, action.notificationTrigger);

        case notificationTriggerActions.NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST:
            return loadingNotificationTriggerUsersSummary(state, true);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_SUCCESS:
            return loadingNotificationTriggerUsersSummary(setNotificationTriggerUsersSummary(state, action.notificationTriggerUsersSummary), false);
        case notificationTriggerActions.NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_FAILURE:
            return loadingNotificationTriggerUsersSummary(state, false);
    }

    return state;
}