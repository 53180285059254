import { createTheme } from '@material-ui/core/styles';
import { Palette as CustomPalette } from '../common/constants/palette';

export const darkTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Montserrat"',
      '"Helvetica Neue"',
      'sans-serif'].join(','),
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: '14px',
        fontWeight: 'bold'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'transparent',
        margin: '0px !important',
        minHeight: "40px",
        border: "none",
        borderBottomColor: CustomPalette.ControlBorder,
        borderBottomWidth: "1",
        width: "100%",
        borderBottomStyle: "solid",
        padding: "2px 0 !important",
        fontSize: "14px"
      },
      input: {
        textAlign: 'left',
        padding: '10px !important'
      },
    },
    MuiNativeSelect: {
      root: {
        padding: "10px 0 10px 10px !important"
      }
    },
    TextControl: {
      inputControl: {
        padding: '0 !important'
      }
    },
    MuiDialog: {
      paper: {
        border: '1px solid ' + CustomPalette.Primary,
        borderRadius: '5px',
        backgroundColor: CustomPalette.SubPage
      },
      paperFullScreen: {
        top: '0',
        paddingTop: '0',
        border: '1px solid #262626',
        backgroundColor: '#212121',
        paddingBottom: '61px'
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: CustomPalette.Primary,
        padding: '4px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: CustomPalette.Page
      }
    },
    MuiDialogContentText: {
      root: {
        color: CustomPalette.Text,
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 500,
        paddingTop: '20px',
        lineHeight: '2em'
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center'
      }
    },
    MuiCard: {
      root: {
        marginBottom: '20px'
      }
    },
    MuiListItemText: {
      inset: {
        paddingLeft: '40px'
      }
    },
    MuiDivider: {
      inset: {
        marginLeft: '16px'
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '4px',
        paddingRight: '4px'
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        right: '0'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: CustomPalette.SubPage
      }
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: '16px'
        }
      }
    }
  },
  "palette":
  {
    "common":
    {
      "black": "#000",
      "white": "rgba(255, 255, 255, 1)"
    },
    "primary":
    {
      "light": "rgba(72, 72, 72, 1)",
      "main": "rgba(33, 33, 33, 1)",
      "dark": "rgba(0, 0, 0, 1)",
      "contrastText": "#fff"
    },
    "secondary":
    {
      "light": "rgba(239, 239, 239, 1)",
      "main": "rgba(189, 189, 189, 1)",
      "dark": "rgba(141, 141, 141, 1)",
      "contrastText": "#fff"
    },
    "background": {
      "paper": "#28292F",
      "default": "#0E0E14"
    },
    "text": {
      "disabled": "rgba(255, 255, 255, 0.38)",
      "hint": "rgba(255, 255, 255, 0.38)",
      "primary": "rgba(255, 255, 255, 0.87)",
      "secondary": "rgba(255, 255, 255, 0.54)"
    }
  },
  "shadows": [
    "none",
    "0px 1px 3px 0px rgba(15,15,15,0.2),0px 1px 1px 0px rgba(15,15,15,0.14),0px 2px 1px -1px rgba(15,15,15,0.12)",
    "0px 1px 5px 0px rgba(15,15,15,0.2),0px 2px 2px 0px rgba(15,15,15,0.14),0px 3px 1px -2px rgba(15,15,15,0.12)",
    "0px 1px 8px 0px rgba(15,15,15,0.2),0px 3px 4px 0px rgba(15,15,15,0.14),0px 3px 3px -2px rgba(15,15,15,0.12)",
    "0px 2px 4px -1px rgba(15,15,15,0.2),0px 4px 5px 0px rgba(15,15,15,0.14),0px 1px 10px 0px rgba(15,15,15,0.12)",
    "0px 3px 5px -1px rgba(15,15,15,0.2),0px 5px 8px 0px rgba(15,15,15,0.14),0px 1px 14px 0px rgba(15,15,15,0.12)",
    "0px 3px 5px -1px rgba(15,15,15,0.2),0px 6px 10px 0px rgba(15,15,15,0.14),0px 1px 18px 0px rgba(15,15,15,0.12)",
    "0px 4px 5px -2px rgba(15,15,15,0.2),0px 7px 10px 1px rgba(15,15,15,0.14),0px 2px 16px 1px rgba(15,15,15,0.12)",
    "0px 5px 5px -3px rgba(15,15,15,0.2),0px 8px 10px 1px rgba(15,15,15,0.14),0px 3px 14px 2px rgba(15,15,15,0.12)",
    "0px 5px 6px -3px rgba(15,15,15,0.2),0px 9px 12px 1px rgba(15,15,15,0.14),0px 3px 16px 2px rgba(15,15,15,0.12)",
    "0px 6px 6px -3px rgba(15,15,15,0.2),0px 10px 14px 1px rgba(15,15,15,0.14),0px 4px 18px 3px rgba(15,15,15,0.12)",
    "0px 6px 7px -4px rgba(15,15,15,0.2),0px 11px 15px 1px rgba(15,15,15,0.14),0px 4px 20px 3px rgba(15,15,15,0.12)",
    "0px 7px 8px -4px rgba(15,15,15,0.2),0px 12px 17px 2px rgba(15,15,15,0.14),0px 5px 22px 4px rgba(15,15,15,0.12)",
    "0px 7px 8px -4px rgba(15,15,15,0.2),0px 13px 19px 2px rgba(15,15,15,0.14),0px 5px 24px 4px rgba(15,15,15,0.12)",
    "0px 7px 9px -4px rgba(15,15,15,0.2),0px 14px 21px 2px rgba(15,15,15,0.14),0px 5px 26px 4px rgba(15,15,15,0.12)",
    "0px 8px 9px -5px rgba(15,15,15,0.2),0px 15px 22px 2px rgba(15,15,15,0.14),0px 6px 28px 5px rgba(15,15,15,0.12)",
    "0px 8px 10px -5px rgba(15,15,15,0.2),0px 16px 24px 2px rgba(15,15,15,0.14),0px 6px 30px 5px rgba(15,15,15,0.12)",
    "0px 8px 11px -5px rgba(15,15,15,0.2),0px 17px 26px 2px rgba(15,15,15,0.14),0px 6px 32px 5px rgba(15,15,15,0.12)",
    "0px 9px 11px -5px rgba(15,15,15,0.2),0px 18px 28px 2px rgba(15,15,15,0.14),0px 7px 34px 6px rgba(15,15,15,0.12)",
    "0px 9px 12px -6px rgba(15,15,15,0.2),0px 19px 29px 2px rgba(15,15,15,0.14),0px 7px 36px 6px rgba(15,15,15,0.12)",
    "0px 10px 13px -6px rgba(15,15,15,0.2),0px 20px 31px 3px rgba(15,15,15,0.14),0px 8px 38px 7px rgba(15,15,15,0.12)",
    "0px 10px 13px -6px rgba(15,15,15,0.2),0px 21px 33px 3px rgba(15,15,15,0.14),0px 8px 40px 7px rgba(15,15,15,0.12)",
    "0px 10px 14px -6px rgba(15,15,15,0.2),0px 22px 35px 3px rgba(15,15,15,0.14),0px 8px 42px 7px rgba(15,15,15,0.12)",
    "0px 11px 14px -7px rgba(15,15,15,0.2),0px 23px 36px 3px rgba(15,15,15,0.14),0px 9px 44px 8px rgba(15,15,15,0.12)",
    "0px 11px 15px -7px rgba(15,15,15,0.2),0px 24px 38px 3px rgba(15,15,15,0.14),0px 9px 46px 8px rgba(15,15,15,0.12)"

  ],
  "custom": {
    "colors": {
      "primary": "#42c23b",
      "primaryDark": "#308a2b",
      "border": "#818183",
      "error": "#DE0000",
      "success": "#42c23b",
      "warning": "#FF6721",
      "info": "#16A3B8",
      "rejected": "purple",
      "boundary": "rgb(76,76,76)",
      "controlContrastText": "#919191",
      "controlBorder": "rgb(111,111,111)",
      "controlBorderDisabled": "rgb(47,47,47)",
      "controlBackgroundLight": "#cfd0d2",
      "controlBackgroundDark": "#313131",
      "controlTextLight": "#111",
      "cardContainerBorder": "#545454",
      "popupListBackground": "#373737",
      "popupListBorder": "#4e4e4e",
      "page": "#0E0E14",
      "header": "#1C1D23",
      "popout": "#28292F",
      "border": "#808080",
    },
    "primaryColor": {
      "color": '#42c23b'
    },
    "errorColor": {
      "color": '#d2242b'
    },
    "errorBorder": {
      "borderColor": '#d2242b'
    },
    "label": {
      "color": 'rgba(255, 255, 255, 0.54)'
    },
    "subTitle": {
      "color": '#fefbf7',
      "textTransform": 'uppercase',
      "fontSize": "16px",
      'fontWeight': 'normal',
      'margin': '6px 0'
    },
    "title": {
      "color": '#fefbf7',
      "fontSize": "16px",
      'fontWeight': 'normal',
      'margin': '6px 0'
    },
    "paragraph": {
      "color": '#fff',
      "fontSize": "12px",
      'fontWeight': 'normal',
      'margin': '6px 0'
    },
    "controlGroup": {
      "label": {
        "color": "#8a8a8a",
        "textTransform": "uppercase"
      },
      "labelError": {
        "color": "#d2242b",
        "textTransform": "uppercase"
      },
      "descriptionError": {
        "color": "#d2242b",
        "fontSize": "12px"
      }
    },
    "passwordField": {
      "padding": "20px 0 !important"
    },
    "inputRoot": {
      "padding": "0"
    },
    "textField": {
    },
    "textFieldError": {
      "height": "40",
      "borderColor": CustomPalette.Error,
      "borderWidth": "1",
      "width": "100%",
      "borderStyle": "solid",
      "backgroundColor": "#342f30",
      "padding": "0"
    },
    "textFieldReadonly": {
      "height": "40",
      "borderColor": "rgba(255, 255, 255, 0.20)",
      "borderWidth": "1",
      "width": "100%",
      "borderStyle": "solid",
      "backgroundColor": "#342f30",
      "padding": "0",
      "color": "rgba(255, 255, 255, 0.20)"
    },
    "card": {
      "title": {
        "color": "rgba(255, 255, 255, 0.54)",
        "margin": "6px 0 10px 0"
      },
      "typography": {
        "color": "rgba(255, 255, 255, 0.87)",
        "margin": "1px 0 1px 0"
      }
    },
    "fileUploaderDragDrop": {
      "border": "none",
      "display": "block",
      "cursor": "pointer",
      "padding": "10px",
      "backgroundColor": "transparent",
      "color": "#919191"
    },
    "fileUploaderDragDropActive": {
      "border": "none",
      "display": "block",
      "cursor": "pointer",
      "padding": "10px",
      "backgroundColor": "transparent",
      "color": "white"
    },
    "fileUploaderTinyContainer": {
      "background": "transparent",
      "padding": "0px",
      "position": "relative",
      "display": "inline-block",
      "margin": "0"
    },
    "fileUploaderContainer": {
      "background": "transparent",
      "border": "1px solid #545454",
      "borderRadius": "10px",
      "padding": "3px",
      "position": "relative",
      "display": "inline-block",
      "margin": "0"
    },
    "fileUploaderContainerSelected": {
      "border": "1px solid #f99a1d"
    },
    "fileUploaderContainerUploading": {
      "border": "1px solid #42c23b"
    },
    "fileUploaderDisplay": {
      "objectFit": "contain",
      "height": "100%",
      "width": "100%",
      "padding": "0",
      "margin": "0px",
      "background": "#2e2c2d"
    },
    "fileUploaderImage": {
      "objectFit": "contain",
      "height": "80px",
      "width": "80px",
      "padding": "0",
      "margin": "0px",
      "background": "transparent"
    },
    "fileUploaderImageTiny": {
      "objectFit": "contain",
      "height": "30px",
      "width": "30px",
      "padding": "0",
      "margin": "0px",
      "background": "transparent"
    },
    "fileUploaderImageSmall": {
      "objectFit": "contain",
      "height": "40px",
      "width": "40px",
      "padding": "0",
      "margin": "0px",
      "background": "transparent"
    },
    "fileUploaderText": {
      "textOverflow": "ellipsis",
      "background": "transparent",
      "position": "absolute",
      "bottom": "5px",
      "left": "0",
      "textAlign": "center",
      "width": "100%",
      "margin": "0",
      "padding": "3px 0",
      "lineHeight": "11px",
      "fontSize": "10px",
      "color": "white"
    },
    "fileNameText": {
      "background": "transparent",
      "textAlign": "center",
      "display": "block",
      "padding": "3px 0",
      "lineHeight": "11px",
      "fontSize": "10px",
      "color": "white"
    },
    "imageViewText": {
      "background": "transparent",
      "textAlign": "center",
      "display": "block",
      "padding": "0 0 3px 0",
      "lineHeight": "14px",
      "fontSize": "13px",
      "color": "white",
      "margin": "10px 0"
    },
    "fileUploaderRemoveButton": {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "margin": "3px",
      "padding": "0",
      "minWidth": "0",
      "minHeight": "0"
    },
    "textDisplay": {
      "padding": "6px",
      "display": "block",
      "width": "100%",
      "minHeight": "32px",
      "margin": "0",
      "color": CustomPalette.Active, // "rgb(129, 129, 131)",
      "borderColor": "rgb(129, 129, 131)",
      "borderStyle": "dotted",
      "borderRadius": "4px",
      "borderWidth": "1px",
      "boxShadow": "none",
      "fontFamily": '"Montserrat", "Helvetica Neue", sans-serif'
    },
    "poiRemoveButton": {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "margin": "3px",
      "padding": "0",
      "minWidth": "0",
      "minHeight": "0"
    },
    "checklist": {
      "container": {
        "marginBottom": '20px',
        "display": 'block'
      }
    }
  }
});
