import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IIncidentFlagsProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface IIncidentFlagsState {
    items: MulitSelectOption[];
}

export default class IncidentFlags extends Component<IIncidentFlagsProps, IIncidentFlagsState> {
    constructor(props: IIncidentFlagsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.externalEvent) !== JSON.stringify(this.props.externalEvent)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let incidentFlagTypeIds: number[] = [];
        if (this.props.externalEvent && this.props.externalEvent.externalEventIncidentFlagTypes) {
            incidentFlagTypeIds = this.props.externalEvent.externalEventIncidentFlagTypes.filter(f => !f.obsolete).map(item => item.incidentFlagTypeId);
        }
        if (this.props.externalEvent.incidentFlagTypes) {
            this.props.externalEvent.incidentFlagTypes.map(item => {
                let isSelected = incidentFlagTypeIds.indexOf(item.incidentFlagTypeId) > -1;
                items.push({
                    id: item.incidentFlagTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.externalEvent.externalEventIncidentFlagTypes.map(item => item.incidentFlagTypeId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.externalEvent.externalEventIncidentFlagTypes.filter(item => currentIds.indexOf(item.incidentFlagTypeId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ExternalEventIncidentFlagType = new AusComplyDtos.ExternalEventIncidentFlagType();
            newItem.incidentFlagTypeId = item.id;
            newItems.push(newItem);
        });
        let externalEvent = { ...this.props.externalEvent };
        externalEvent.externalEventIncidentFlagTypes = newItems;
        this.props.onUpdate(externalEvent);
    }

    onChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        return (
            <MultiSelectControl
                wrapControlInCard={true}
                cardTitle={"Incident Flags"}
                items={this.state.items}
                label={""}
                searchText="Type at least three letters..."
                minimumCharactersRequired={3}
                onSelectItemsChanged={this.onSelectItemsChanged}
                canShowAll={true} />
        );
    }
}