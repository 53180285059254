import React, { forwardRef } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ButtonLink from './ButtonLink';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICheckboxControlProps {
    theme: any;
    text?: string;
    label?: string;
    link?: any;
    linkText?: string;
    defaultValue: boolean;
    readonly?: boolean;
    onChanged?: Function;
}

//

class CheckboxControl extends React.PureComponent<ICheckboxControlProps, any> {

    constructor(props: ICheckboxControlProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.state = {
            value: false
        }
    }

    onChange = event => {
        let val = event.target.checked;
        this.setState({ value: val }, () => {
            if (this.props.onChanged) {
                this.props.onChanged(val);
            }
        });
    };

    componentDidMount() {
        if (typeof this.props.defaultValue !== 'undefined') {
            this.setState({
                value: this.props.defaultValue
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.defaultValue !== 'undefined') {
            if (prevProps.defaultValue != this.props.defaultValue) {
                this.setState({ value: this.props.defaultValue })
            }
        }
    }


    render() {
        const { theme } = this.props;
        return (
            <>
                <FormControlLabel
                    disabled={this.props.readonly}
                    style={{color: theme.palette.text.primary}}
                    control={
                        <Checkbox
                            checked={this.state.value}
                            onChange={this.onChange}
                            style={{ color: theme.palette.text.primary }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="large" style={{ color: theme.palette.text.primary }} />}
                            checkedIcon={<CheckBoxIcon fontSize="large" style={{ color: theme.palette.text.primary }} />}
                        />
                    }
                    label={this.props.text}
                />
                {this.props.link && (
                    <ButtonLink text={this.props.linkText} style={{width: 'auto'}} component={this.props.link} />
                )}
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CheckboxControl);