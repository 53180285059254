import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IIncidentCategoryTypesProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface IIncidentCategoryTypesState {
    items: MulitSelectOption[];
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[];
}

export default class IncidentCategoryTypes extends Component<IIncidentCategoryTypesProps, IIncidentCategoryTypesState> {
    constructor(props: IIncidentCategoryTypesProps) {
        super(props)
        this.state = {
            items: [],
            incidentCategoryTypes: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps: Readonly<IIncidentCategoryTypesProps>, prevState: Readonly<IIncidentCategoryTypesState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.externalEvent) !== JSON.stringify(this.props.externalEvent)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        const { externalEvent } = this.props;

        let items: MulitSelectOption[] = [];
        let selectedIncidentCategoryTypeIds: number[] = [];
        let incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[] = [];
        if (externalEvent && externalEvent.incidentTypeCategoryTypes && externalEvent.incidentTypeId) {
            const incidentType = externalEvent.incidentTypeCategoryTypes.find(p => p.incidentTypeId == externalEvent.incidentTypeId);
            if (incidentType) {
                incidentCategoryTypes = [...incidentType.incidentCategoryTypes];
            }
        }
        if (externalEvent && externalEvent.externalEventIncidentCategoryTypes) {
            selectedIncidentCategoryTypeIds = externalEvent.externalEventIncidentCategoryTypes.filter(f => !f.obsolete).map(item => item.incidentCategoryTypeId);
        }
        if (incidentCategoryTypes.length > 0) {
            incidentCategoryTypes.map(item => {
                let isSelected = selectedIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1;
                items.push({
                    id: item.incidentCategoryTypeId,
                    name: item.display,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.externalEvent.externalEventIncidentCategoryTypes.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = items.map(item => item.id);

        // remove
        let newIncidentCategories = this.props.externalEvent.externalEventIncidentCategoryTypes.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        items.filter(item => previousIncidentCategoryTypeIds.indexOf(item.id) == -1).forEach(item => {
            let externalEventIncidentCategoryType: AusComplyDtos.ExternalEventIncidentCategoryType = new AusComplyDtos.ExternalEventIncidentCategoryType();
            externalEventIncidentCategoryType.incidentCategoryTypeId = item.id;
            newIncidentCategories.push(externalEventIncidentCategoryType);
        });
        let externalEvent = { ...this.props.externalEvent };
        externalEvent.externalEventIncidentCategoryTypes = newIncidentCategories;
        this.props.onUpdate(externalEvent);
    }

    onChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {

        return (
            <MultiSelectControl
                wrapControlInCard={true}
                cardTitle={"Categories"}
                items={this.state.items}
                label={""}
                searchText="Type at least three letters..."
                minimumCharactersRequired={3}
                onSelectItemsChanged={this.onSelectItemsChanged}
                canShowAll={true} />
        );
    }
}