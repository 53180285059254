import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import RadioControlGroup from '../../controls/RadioControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { RadioOption } from '../../../common/dto/common';
import LocalDateControlGroup from '../../controls/LocalDateControlGroup';

export interface IEditUserRoleProps {
    userRoleId: number,
    isLoading: boolean;
    userRole: AusComplyDtos.UserRole;
    userRoleTypes: AusComplyDtos.UserRoleType[];
    userRoleStatuses: AusComplyDtos.UserRoleStatus[];
    isSaving: boolean;
    error: string;
    onSuccess: Function;
    onLoad: Function;
    onChange: Function;
    onSave: Function;
    theme: any;
    classes: any;
}

export default class EditUserRole extends Component<IEditUserRoleProps, any> {
    constructor(props: IEditUserRoleProps) {
        super(props)
        this.state = {
            error: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.userRoleId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.setState({ error: {} });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error && this.props.onSuccess) {
            this.setState({ error: {} });
            this.props.onSuccess();
        }
    }

    onSubmit() {
        if (this.validate()) {
            this.props.onSave(this.props.userRole);
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let userRole = { ...this.props.userRole };
        userRole[fieldName] = value;
        this.props.onChange(userRole);
    }

    selectedUserRoleType() {
        let selectedUserRoleType: AusComplyDtos.UserRoleType = new AusComplyDtos.UserRoleType();
        selectedUserRoleType.userRoleTypeId = 0;
        if (this.props.userRole && this.props.userRoleTypes) {
            this.props.userRoleTypes.forEach(userRoleType => {
                if (this.props.userRole.userRoleTypeId && this.props.userRole.userRoleTypeId == userRoleType.userRoleTypeId) {
                    selectedUserRoleType = userRoleType;
                }
            })
        }
        return selectedUserRoleType;
    }

    validate() {
        let error = {};
        let isValid = true;
        let userRoleType = this.selectedUserRoleType();
        if (!userRoleType || userRoleType.userRoleTypeId == 0) {
            error["userRoleTypeId"] = "Required";
            isValid = false;
        }
        if (userRoleType.allowTemporary && this.props.userRole.temporary && this.props.userRole.expiry.isEmpty) {
            error["expiry"] = "Required";
            isValid = false;
        }
        this.setState({ error: error });
        return isValid;
    }


    render() {
        let selectedUserRoleType = this.selectedUserRoleType();

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    {this.props.userRole && (
                        <>
                            {(this.props.userRole.securityFirmID && this.props.userRole.securityFirmID != 0) && (
                                <TextControlGroup readonly={true} text={"Security Firm"} defaultValue={this.props.userRole.securityFirm} />
                            )}
                            {(this.props.userRole.venueID && this.props.userRole.venueID != 0) && (
                                <TextControlGroup readonly={true} text={"Venue"} defaultValue={this.props.userRole.venue} />
                            )}

                            {(this.props.userRoleStatuses.length < 2 && this.props.userRole.userRoleStatus) && (
                                <TextControlGroup readonly={true} text={"Status"} defaultValue={this.props.userRole.userRoleStatus.name} />
                            )}
                            {(this.props.userRoleStatuses.length > 1) && (
                                <SelectControlGroup
                                    text="Status"
                                    error={this.state.error["userRoleStatusId"]}
                                    onChange={(value) => this.onValueChanged("userRoleStatusId", value)}
                                    defaultValue={this.props.userRole.userRoleStatusId}>
                                    {this.props.userRoleStatuses && this.props.userRoleStatuses.map((item, index) => (
                                        <option key={item.userRoleStatusId} value={item.userRoleStatusId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            )}
                            <SelectControlGroup
                                text={"Access Level"}
                                error={this.state.error["userRoleTypeId"]}
                                onChange={(value) => this.onValueChanged("userRoleTypeId", parseInt(value))}
                                defaultValue={this.props.userRole.userRoleTypeId}>
                                {this.props.userRoleTypes && this.props.userRoleTypes.map((item, index) => (
                                    <option key={item.userRoleTypeId} value={item.userRoleTypeId}>{"Level " + item.accessLevel.toString() + " (" + item.name + ")"}</option>
                                ))}
                            </SelectControlGroup>
                            {selectedUserRoleType.allowTemporary && (
                                <>
                                    <ControlGroup text=" ">
                                        <CheckboxControlGroup text={"Temporary"}
                                            defaultValue={this.props.userRole.temporary}
                                            readonly={!selectedUserRoleType.allowPermanent}
                                            onChanged={(value) => this.onValueChanged("temporary", value)} />
                                    </ControlGroup>

                                    {this.props.userRole.temporary && (
                                        <LocalDateControlGroup
                                            text="Expiry Date"
                                            error={this.state.error["expiry"]}
                                            date={this.props.userRole.expiry}
                                            onChange={(value) => this.onValueChanged("expiry", value)} />
                                    )}
                                </>
                            )}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"Save"} onClick={this.onSubmit}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onSuccess()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>
            </LayoutForm>
        );
    }
}