import React from "react";

const TickIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 32 22.6"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={fill}>
                <polygon className="st0" points="29.9,0 11.5,18.4 2.1,9 0,11.1 9.4,20.5 11.5,22.6 13.6,20.5 32,2.1 "/>
            </g>
        </svg>
    );

export default TickIcon;
