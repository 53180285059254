import { connect } from 'react-redux'
import SecurityFirmDocuments from '../components/securityFirm/securityFirmDocuments/SecurityFirmDocuments'
import {
    securityFirmDocumentsRequest,
    securityFirmSaveOtherDocuments,
    securityFirmRemoveOtherDocument,
    securityFirmSaveComplianceDocuments,
    securityFirmRemoveComplianceDocument
} from '../common/actions/securityFirm'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        error: state.securityFirm.documents.error,
        isLoading: state.securityFirm.documents.isLoading,
        isSaving: state.securityFirm.documents.isSaving,
        otherDocuments: state.securityFirm.documents.otherDocuments,
        otherDocumentTypes: state.securityFirm.documents.otherDocumentTypes,
        complianceDocuments: state.securityFirm.documents.complianceDocuments,
        complianceDocumentTypes: state.securityFirm.documents.complianceDocumentTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (securityFirmId) => {
            dispatch(securityFirmDocumentsRequest(securityFirmId, false))
        },
        onSaveOther: (securityFirmId, documentTypeId, files) => {
            dispatch(securityFirmSaveOtherDocuments(securityFirmId, documentTypeId, files))
        },
        onRemoveOther: (securityFirmId, otherDocument) => {
            dispatch(securityFirmRemoveOtherDocument(securityFirmId, otherDocument))
        },
        onSaveCompliance: (securityFirmId, documentTypeId, visibleToVenue, expiryDate, files) => {
            dispatch(securityFirmSaveComplianceDocuments(securityFirmId, documentTypeId, visibleToVenue, expiryDate, files))
        },
        onRemoveCompliance: (securityFirmId, complianceDocument) => {
            dispatch(securityFirmRemoveComplianceDocument(securityFirmId, complianceDocument))
        }
    }
}

const SecurityFirmDocumentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmDocuments)

export default SecurityFirmDocumentsContainer