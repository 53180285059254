import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import { incidentLogic } from '../../common/logic/incidentLogic';
import TextControlGroup from '../controls/TextControlGroup';
import { IdName } from "../../common/dto/common";
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IScheduleVenuesProps {
    schedule: AusComplyDtos.Schedule;
    venues: IdName[];
    onUpdate: Function;
}

interface IScheduleVenuesState {
    items: MulitSelectOption[];
}

export default class ScheduleVenues extends Component<IScheduleVenuesProps, IScheduleVenuesState> {
    constructor(props: IScheduleVenuesProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.venues) !== JSON.stringify(this.props.venues)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleVenues) !== JSON.stringify(this.props.schedule.scheduleVenues)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let venueIds: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleVenues) {
            venueIds = this.props.schedule.scheduleVenues.filter(f => !f.obsolete).map(item => item.venueId);
        }
        if (this.props.venues) {
            this.props.venues.map(item => {
                let isSelected = venueIds.indexOf(item.id) > -1;
                items.push({
                    id: item.id,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.schedule.scheduleVenues.map(item => item.venueId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.schedule.scheduleVenues.filter(item => currentIds.indexOf(item.venueId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ScheduleVenue = new AusComplyDtos.ScheduleVenue();
            newItem.venueId = item.id;
            newItems.push(newItem);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleVenues = newItems;
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {

        return (
            <CardContainer title={"Venues"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} /></CardContent>
            </CardContainer>
        );
    }
}