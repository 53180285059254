import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../common/View';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import TableContainer from '../../layout/TableContainer';
import SecurityFirmCard from './SecurityFirmCard';
import SecurityFirmHeader from './SecurityFirmHeader';
import SecurityFirmRow from './SecurityFirmRow';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SecondaryFab from '../../common/SecondaryFab';
import SecurityFirmFilterSummary from './SecurityFirmFilterSummary';
import SecurityFirmFilter from './SecurityFirmFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import ConfirmDialog from '../../controls/ConfirmDialog';
import DialogContentText from '@material-ui/core/DialogContentText';

export interface ISecurityFirmsProps {
    isLoading: boolean;
    securityFirms: AusComplyDtos.SecurityFirm[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SecurityFirmFilter;
    onRefresh: Function;
    onFilterUpdate: Function;
    onObsolete: Function;
    onDelete: Function;
    onExport: Function;
    onDismissRegistration: Function;
    history: any;
}

interface ISecurityFirmsState {
    filterExpanded: boolean;
    securityFirmToObsolete: AusComplyDtos.SecurityFirm;
    securityFirmToDelete: AusComplyDtos.SecurityFirm;
    securityFirmToDismiss: AusComplyDtos.SecurityFirm;
}

class SecurityFirms extends Component<ISecurityFirmsProps, ISecurityFirmsState> {
    constructor(props: ISecurityFirmsProps) {
        super(props)

        var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
        emptySecurityFirm.securityFirmId = 0;

        this.state = {
            filterExpanded: false,
            securityFirmToObsolete: { ...emptySecurityFirm },
            securityFirmToDelete: { ...emptySecurityFirm },
            securityFirmToDismiss: { ...emptySecurityFirm }
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.deleteCancel = this.deleteCancel.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.obsoleteCancel = this.obsoleteCancel.bind(this);
        this.obsoleteConfirmed = this.obsoleteConfirmed.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.dismissCancel = this.dismissCancel.bind(this);
        this.dismissConfirmed = this.dismissConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onEdit(securityFirm: AusComplyDtos.SecurityFirm) {
        this.props.history.push('/admin/securityFirm/' + securityFirm.securityFirmId);
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(securityFirmFilter: AusComplyDtos.SecurityFirmFilter) {
        this.props.onFilterUpdate(securityFirmFilter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let securityFirmFilter = new AusComplyDtos.SecurityFirmFilter();
            this.props.onFilterUpdate(securityFirmFilter);
            this.props.onRefresh(securityFirmFilter, 1, this.props.paging.pageSize);
        });
    }

    deleteCancel() {
        var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
        emptySecurityFirm.securityFirmId = 0;
        this.setState({
            securityFirmToDelete: emptySecurityFirm
        });
    }

    deleteConfirmed() {
        if (this.props.onDelete) {
            let securityFirmId = this.state.securityFirmToDelete.securityFirmId;
            this.props.onDelete(securityFirmId);

            var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
            emptySecurityFirm.securityFirmId = 0;
            this.setState({
                securityFirmToDelete: emptySecurityFirm
            });
        }
    }

    obsoleteCancel() {
        var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
        emptySecurityFirm.securityFirmId = 0;
        this.setState({
            securityFirmToObsolete: emptySecurityFirm
        });
    }

    obsoleteConfirmed() {
        if (this.props.onDelete) {
            let securityFirmId = this.state.securityFirmToObsolete.securityFirmId;
            this.props.onObsolete(securityFirmId);

            var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
            emptySecurityFirm.securityFirmId = 0;
            this.setState({
                securityFirmToObsolete: emptySecurityFirm
            });
        }
    }
    
    dismissCancel() {
        var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
        emptySecurityFirm.securityFirmId = 0;
        this.setState({
            securityFirmToDismiss: emptySecurityFirm
        });
    }

    dismissConfirmed() {
        if (this.props.onDismissRegistration) {
            let securityFirmId = this.state.securityFirmToDismiss.securityFirmId;
            this.props.onDismissRegistration(securityFirmId);

            var emptySecurityFirm = new AusComplyDtos.SecurityFirm();
            emptySecurityFirm.securityFirmId = 0;
            this.setState({
                securityFirmToDismiss: emptySecurityFirm
            });
        }
    }

    onCommand(command: string, securityFirm: AusComplyDtos.SecurityFirm) {
        switch (command) {
            case "add":
                this.props.history.push('/register/securityFirm/');
                break;
            case "edit":
                this.props.history.push('/admin/securityFirm/' + securityFirm.securityFirmId);
                break;
            case "export":
                this.props.onExport();
                break;
            case "delete":
                var securityFirmToDelete = { ...securityFirm };
                this.setState({
                    securityFirmToDelete
                });
                break;
            case "remove":
                var securityFirmToObsolete = { ...securityFirm };
                this.setState({
                    securityFirmToObsolete
                });
                break;
            case "dismiss":
                var securityFirmToDismiss = { ...securityFirm };
                this.setState({
                    securityFirmToDismiss
                });
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rowList;
        let rows;

        if (this.props.securityFirms && this.props.securityFirms.length > 0) {
            cardList = this.props.securityFirms.map((item, index) => {
                return (
                    <SecurityFirmCard
                        onClick={this.onEdit}
                        onCommand={this.onCommand}
                        key={"card-" + item.securityFirmId.toString()}
                        securityFirm={item}
                    />
                );
            });

            rows = this.props.securityFirms.map((item, index) => {
                return (
                    <SecurityFirmRow
                        onClick={this.onEdit}
                        onCommand={this.onCommand}
                        key={"row-" + item.securityFirmId.toString()}
                        securityFirm={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<SecurityFirmHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SecurityFirmFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let securityFirmFilter = new AusComplyDtos.SecurityFirmFilter();
            securityFirmFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SecurityFirmFilterSummary filter={securityFirmFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SecurityFirmFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let obsoleteSecurityFirmDetails;
        if (this.state.securityFirmToObsolete && this.state.securityFirmToObsolete.securityFirmId != 0) {
            obsoleteSecurityFirmDetails = (
                <>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you wish to remove this security firm"}
                    </DialogContentText>
                    <ControlGroup text="Name">
                        <TextDisplay>{this.state.securityFirmToObsolete.name}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Address">
                        <TextDisplay>{this.state.securityFirmToObsolete.address}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Suburb">
                        <TextDisplay>{this.state.securityFirmToObsolete.suburb}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Postcode">
                        <TextDisplay>{this.state.securityFirmToObsolete.postcode}</TextDisplay>
                    </ControlGroup>
                </>
            );
        }

        let dismissSecurityFirmDetails;
        if (this.state.securityFirmToDismiss && this.state.securityFirmToDismiss.securityFirmId != 0) {
            dismissSecurityFirmDetails = (
                <>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you wish to dismiss this security firm registration"}
                    </DialogContentText>
                    <ControlGroup text="Name">
                        <TextDisplay>{this.state.securityFirmToDismiss.name}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Address">
                        <TextDisplay>{this.state.securityFirmToDismiss.address}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Suburb">
                        <TextDisplay>{this.state.securityFirmToDismiss.suburb}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Postcode">
                        <TextDisplay>{this.state.securityFirmToDismiss.postcode}</TextDisplay>
                    </ControlGroup>
                </>
            );
        }

        return (
            <>
                <PageTitle title="Security Firms"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                    <SecondaryFab onClick={() => this.onCommand("export", new AusComplyDtos.SecurityFirm())} isExtended={true}>Export</SecondaryFab>
                    <DefaultFab onClick={() => this.onCommand("add", new AusComplyDtos.SecurityFirm())} isExtended={true}>Add SecurityFirm</DefaultFab>
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <ConfirmDialog
                    title={"Confirm delete"}
                    text={"Are you sure you wish to PERMANENTLY DELETE this security firm"}
                    show={this.state.securityFirmToDelete && this.state.securityFirmToDelete.securityFirmId != 0}
                    onCancel={this.deleteCancel}
                    onConfirm={this.deleteConfirmed} />
                <ConfirmDialog
                    title={"Confirm remove"}
                    dialogContent={obsoleteSecurityFirmDetails}
                    show={this.state.securityFirmToObsolete && this.state.securityFirmToObsolete.securityFirmId != 0}
                    onCancel={this.obsoleteCancel}
                    onConfirm={this.obsoleteConfirmed} />
                <ConfirmDialog
                    title={"Confirm dismiss registration"}
                    dialogContent={dismissSecurityFirmDetails}
                    show={this.state.securityFirmToDismiss && this.state.securityFirmToDismiss.securityFirmId != 0}
                    onCancel={this.dismissCancel}
                    onConfirm={this.dismissConfirmed} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SecurityFirms);