import React, { Component } from 'react';
import { IncidentPatron as IncidentPatronDTO, File as FileDTO, IncidentPatronPhoto } from "../../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import FileUpload from '../../../common/FileUpload';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../../../utilities/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../../controls/ErrorButton';
import ImagePreview from '../../../common/ImagePreview';
import Thumbnail from '../../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import { partition } from 'rxjs';

export interface IIncidentPatronAttachmentsProps {
    incidentPatron: IncidentPatronDTO;
    onUpdate: Function;
    onUploadPatronFiles: Function;
    isUploading: boolean;
    theme: any;
}


interface IIncidentPatronAttachmentsState {
    selectedFilePath: string;
    selectedFileName: string;
}

class IncidentPatronAttachments extends Component<IIncidentPatronAttachmentsProps, IIncidentPatronAttachmentsState> {
    constructor(props: IIncidentPatronAttachmentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    componentDidMount() {

    }
    componentDidUpdate() {

    }

    removeDocument(photo: IncidentPatronPhoto) {
        let newIncidentPatron = { ...this.props.incidentPatron };
        if (photo.incidentPatronPhotoId === 0) {
            newIncidentPatron.incidentPatronPhotos = newIncidentPatron.incidentPatronPhotos.filter(item => item.fileDisplayPath !== photo.fileDisplayPath);
        } else {
            newIncidentPatron.incidentPatronPhotos.forEach(item => {
                if (item.fileDisplayPath === photo.fileDisplayPath) {
                    item.hasChanged = true;
                    item.obsolete = true;
                }
            });
        }
        this.props.onUpdate(newIncidentPatron);
    }

    expandImage(document: IncidentPatronPhoto) {

    }

    fileWasUploaded(temporaryFiles: FileDTO[]) {
        let newIncidentPatron = { ...this.props.incidentPatron };
        temporaryFiles.forEach(item => {
            let photo = new IncidentPatronPhoto();
            photo.obsolete = false;
            photo.originalFileName = item.originalName;
            photo.isImage = item.isImage;
            photo.incidentPatronId = this.props.incidentPatron.incidentPatronId;
            photo.canvasRescaled = false;
            photo.filePath = item.path;
            photo.filePreviewPath = item.filePreviewPath;
            photo.fileDisplayPath = item.fileDisplayPath;
            photo.extension = item.extension;
            photo.incidentPatronPhotoId = 0;
            photo.filePreviewText = item.filePreviewText;
            photo.hasChanged = true;
            photo.canvasRescaled = item.canvasRescaled;
            if (!newIncidentPatron.incidentPatronPhotos) {
                newIncidentPatron.incidentPatronPhotos = [];
            }
            newIncidentPatron.incidentPatronPhotos.push(photo);
        });
        this.props.onUpdate(newIncidentPatron);
    }

    render() {
        const { theme } = this.props;

        // build the current list of documents
        let documents;
        let hasDocuments = false;
        if (this.props.incidentPatron && this.props.incidentPatron.incidentPatronPhotos) {

            documents = this.props.incidentPatron.incidentPatronPhotos.filter(item => !item.obsolete).map((item, index) => {
                hasDocuments = true;
                if (this.props.incidentPatron.isUnderAgeLocked) {
                    return (
                        <Box p={0} key={"file-poi-" + item.originalFileName + "_" + index.toString()}>
                            <Thumbnail
                                key={"file-" + item.originalFileName + "_" + index.toString()}
                                previewPath={item.filePreviewPath}
                                displayPath={item.fileDisplayPath}
                                isImage={item.isImage}
                                isBlocked={true}
                                text={item.filePreviewText}
                            />
                        </Box>
                    );
                }

                return (
                    <Box p={0} key={"file-poi-" + item.originalFileName + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFileName + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFileName}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                        />
                    </Box>
                )
            });
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <label style={{ color: 'rgb(138, 138, 138)', textTransform: 'uppercase', marginTop: '10px', display: 'block' }}>Attachments</label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                {documents}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FileUpload alternateIcons={true} hasFiles={hasDocuments} onUploadFiles={this.props.onUploadPatronFiles} isUploading={this.props.isUploading} />
                        </Grid>
                    </Grid>
                </Grid>
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
            </Grid >
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPatronAttachments);