import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewCategoriesProps {
    incident: IncidentDTO;
}

export default class IncidentViewCategories extends Component<IIncidentViewCategoriesProps, any> {
    render() {
        if (!this.props.incident.incidentCategory) {
            return (<></>);
        }
        let categories = this.props.incident.incidentCategory.map((item, index) => <span key={index}>{index > 0 && (<br />)}{item.incidentCategoryType.display}</span>);
        let other;
        if (this.props.incident.other && this.props.incident.other.length > 0) {
            other = "Other (Specify): " + this.props.incident.other;
        }
        let seriousOther;
        if (this.props.incident.seriousOther && this.props.incident.seriousOther.length > 0) {
            seriousOther = "Serious Other (Specify): " + this.props.incident.seriousOther;
        }

        return (
            <ControlGroup text="Categories">
                <TextDisplay>{categories}
                    {(categories && (other || seriousOther)) && (<br />)}
                    {(categories && other) && (<br />)}
                 {other}
                    {(seriousOther && (other || categories)) && (<br />)}
                  {seriousOther}
                  </TextDisplay>
            </ControlGroup>
        );
    }
}