import React from 'react';

const SecurityIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M22.69,25.95c0.11,0.11,0.29,0.11,0.4,0l8.2-8.2c0.76-0.76,2-0.76,2.76,0c0.76,0.76,0.76,2,0,2.76l-9.78,9.78c-0.76,0.76-2,0.76-2.76,0l-5.46-5.46c-0.76-0.76-0.76-2,0-2.76c0.76-0.76,2-0.76,2.76,0L22.69,25.95z M42.23,19.56c0,11.31-6.64,21.72-16.93,26.25c-0.14,0.06-0.37,0.06-0.51,0C14.78,41.4,7.86,31.17,7.86,19.56v-8.08c0-0.15,0.12-0.33,0.26-0.39l16.67-6.92c0.14-0.06,0.38-0.06,0.52,0l16.67,6.92c0.14,0.06,0.26,0.23,0.26,0.39V19.56z M44.93,8.1L25.79,0.15c-0.48-0.2-1.02-0.2-1.5,0L5.15,8.1c-0.73,0.3-1.2,1.01-1.2,1.8v9.66c-1.07,11.7,8.04,25.27,20.36,30.29c0.34,0.19,1,0.19,1.48,0c12.32-5.03,20.36-16.99,20.36-30.29V9.9C46.14,9.11,45.66,8.4,44.93,8.1" />
        </g>
    </svg>
);

export default SecurityIcon;


