import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICheckListTemplateSummaryHeaderProps {
    classes: any;
    theme: any;
}

class CheckListTemplateSummaryHeader extends React.Component<ICheckListTemplateSummaryHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr} style={{textAlign: 'center'}}>Item count</th>
                <th className={classes.tableHr} style={{textAlign: 'center'}}>Checklist count</th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListTemplateSummaryHeader);