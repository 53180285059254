import { connect } from 'react-redux'
import SecurityFirmUsers from '../components/securityFirm/SecurityFirmUsers'

const mapStateToProps = state => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const SecurityFirmUsersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmUsers)

export default SecurityFirmUsersContainer