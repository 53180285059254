import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withGoogleMap, GoogleMap, Marker, withScriptjs, InfoWindow, KmlLayer } from "react-google-maps"
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { makeStyles } from '@material-ui/core';
import moment from "moment";
import { commonLogic } from '../../../common/logic/common';

const styles = require('../../../resources/GoogleMapStyles.json')

class MapWrapper extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.state = {
            venueId: 0
        }
    }

    onMarkerDragEnd = (e) => {
        // console .log(e);
        const lat = (e.latLng.lat());
        const lng = (e.latLng.lng());

        // console .log(lat, lng);

        if (this.props.onDragged) {
            this.props.onDragged(lat, lng);
        }
    };

    onClick = (marker) => {
        this.setState({ venueId: marker.venueId });
    }

    onCloseClick = (marker) => {
        this.setState({ venueId: 0 });
    }

    /*
    temporary (seed: number) {
        let date = new Date();
        let dateString = moment(date).format("DDHHmmss");
        let dateNumber = Number(dateString);
        let offset = (new Date().getUTCMilliseconds()) * 10;
        return dateNumber + offset + seed;
    }
        */

    render() {
        return (
            <GoogleMap
                defaultZoom={4}
                defaultCenter={{ lat: -28.053791, lng: 134.146343 }}
                defaultMapTypeId="terrain"
                defaultOptions={{
                    styles: [
                        {
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#212121"
                                }
                            ]
                        },
                        {
                            elementType: "labels.icon",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#757575"
                                }
                            ]
                        },
                        {
                            elementType: "labels.text.stroke",
                            stylers: [
                                {
                                    color: "#212121"
                                }
                            ]
                        },
                        {
                            featureType: "administrative",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#757575"
                                }
                            ]
                        },
                        {
                            featureType: "administrative.country",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#9e9e9e"
                                }
                            ]
                        },
                        {
                            featureType: "administrative.land_parcel",
                            stylers: [
                                {
                                    visibility: "off"
                                }
                            ]
                        },
                        {
                            featureType: "administrative.locality",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#bdbdbd"
                                }
                            ]
                        },
                        {
                            featureType: "poi",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#757575"
                                }
                            ]
                        },
                        {
                            featureType: "poi.park",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#181818"
                                }
                            ]
                        },
                        {
                            featureType: "poi.park",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#616161"
                                }
                            ]
                        },
                        {
                            featureType: "poi.park",
                            elementType: "labels.text.stroke",
                            stylers: [
                                {
                                    color: "#1b1b1b"
                                }
                            ]
                        },
                        {
                            featureType: "road",
                            elementType: "geometry.fill",
                            stylers: [
                                {
                                    color: "#2c2c2c"
                                }
                            ]
                        },
                        {
                            featureType: "road",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#8a8a8a"
                                }
                            ]
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#373737"
                                }
                            ]
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#3c3c3c"
                                }
                            ]
                        },
                        {
                            featureType: "road.highway.controlled_access",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#4e4e4e"
                                }
                            ]
                        },
                        {
                            featureType: "road.local",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#616161"
                                }
                            ]
                        },
                        {
                            featureType: "transit",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#757575"
                                }
                            ]
                        },
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [
                                {
                                    color: "#000000"
                                }
                            ]
                        },
                        {
                            featureType: "water",
                            elementType: "labels.text.fill",
                            stylers: [
                                {
                                    color: "#3d3d3d"
                                }
                            ]
                        }
                    ]
                }}
            >
                {(this.props.heatmapData) && (
                    <HeatmapLayer
                        // data={this.props.heatmapData}
                        options={{
                            data: this.props.heatmapData,
                            radius: 20,
                            gradient: [
                                "rgba(265, 235, 59, 0)",
                                "rgba(265, 235, 59, 1)",
                                "rgba(249, 221, 56, 1)",
                                "rgba(245, 205, 54, 1)",
                                "rgba(239, 191, 52, 1)",
                                "rgba(234, 176, 50, 1)",
                                "rgba(229, 161, 48, 1)",
                                "rgba(225, 145, 46, 1)",
                                "rgba(219, 131, 44, 1)",
                                "rgba(213, 116, 41, 1)",
                                "rgba(209, 101, 39, 1)",
                                "rgba(204, 86, 38, 1)",
                                "rgba(198, 72, 34, 1)",
                                "rgba(194, 57, 31, 1)",
                                "rgba(188, 42, 30, 1)",
                                "rgba(184, 27, 28, 1)"
                            ],
                            opacity: 0.95
                        }}
                    />
                )}
                {this.props.postcodeBoundaries && this.props.postcodeBoundaries.map((postcode, index) => (
                    <KmlLayer
                        key={postcode}
                        url={`https://secure.auscomply.com.au/kml/${postcode}.kml?${commonLogic.uniqueNumber(index).toString()}`}
                        options={{ preserveViewport: false }}
                    />
                ))}
                {this.props.venueMarkers && this.props.venueMarkers.map((marker, index) => (
                    <Marker
                        key={index}
                        position={marker.position}
                        title={marker.name}
                        onClick={() => this.onClick(marker)}
                        visible={marker.visible}
                    >
                        {this.state.venueId === marker.venueId && (
                            <InfoWindow onCloseClick={() => this.onCloseClick(marker)}>
                                <div className="text-gray-dark" style={{ color: '#555' }}>
                                    <address>
                                        <strong>{marker.name}</strong>
                                        <br />
                                        {marker.address}
                                        <br />
                                        {marker.suburb}, {marker.state} {marker.postcode}
                                    </address>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}
            </GoogleMap>
        );
    }
}

export default withScriptjs(withGoogleMap(MapWrapper))