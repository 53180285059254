import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import { incidentLogic } from '../../common/logic/incidentLogic';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IScheduleIncidentFlagsProps {
    schedule: AusComplyDtos.Schedule;
    incidentFlagTypes: AusComplyDtos.IncidentFlagType[];
    onUpdate: Function;
}

interface IScheduleIncidentFlagsState {
    items: MulitSelectOption[];
}

export default class ScheduleIncidentFlags extends Component<IScheduleIncidentFlagsProps, IScheduleIncidentFlagsState> {
    constructor(props: IScheduleIncidentFlagsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.incidentFlagTypes) !== JSON.stringify(this.props.incidentFlagTypes)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleIncidentFlagTypes) !== JSON.stringify(this.props.schedule.scheduleIncidentFlagTypes)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let incidentFlagTypeIds: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleIncidentFlagTypes) {
            incidentFlagTypeIds = this.props.schedule.scheduleIncidentFlagTypes.filter(f => !f.obsolete).map(item => item.incidentFlagTypeId);
        }
        if (this.props.incidentFlagTypes) {
            this.props.incidentFlagTypes.map(item => {
                let isSelected = incidentFlagTypeIds.indexOf(item.incidentFlagTypeId) > -1;
                items.push({
                    id: item.incidentFlagTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.schedule.scheduleIncidentFlagTypes.map(item => item.incidentFlagTypeId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.schedule.scheduleIncidentFlagTypes.filter(item => currentIds.indexOf(item.incidentFlagTypeId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ScheduleIncidentFlagType = new AusComplyDtos.ScheduleIncidentFlagType();
            newItem.incidentFlagTypeId = item.id;
            newItems.push(newItem);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleIncidentFlagTypes = newItems;
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {

        return (
            <CardContainer title={"Incident Flags"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} /></CardContent>
            </CardContainer>
        );
    }
}