import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import TransparentButton from '../../controls/TransparentButton';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import DashboardTotals from './DashboardTotals';
import DashboardOverview from './DashboardOverview';
import DashboardUserTotals from './DashboardUserTotals';
import DashboardIncidentTotals from './DashboardIncidentTotals';
import DashboardPostcodes from './DashboardPostcodes';
import PageLayout from '../../layout/PageLayout';

export interface IDashboardProps {
    isLoading: boolean;
    isLoadingIncidents: boolean;
    filter: AusComplyDtos.AdminDashboardFilter;
    incidents: AusComplyDtos.IncidentHeatmap[];
    venueMarkers: AusComplyDtos.VenueMarker[];
    options: AdminDashboardOptions;
    totals: any;
    incidentTotals: any;
    heatmapData: any;
    userTotals: any;
    overviewQuick: any;
    onSetFilter: Function;
    onResetFilter: Function;
    onSetOptions: Function;
    onLoad: Function;
    onLoadTotals: Function;
    onLoadOverviewQuick: Function;
    onLoadIncidentTotals: Function;
    onLoadUserTotals: Function;
    onLoadReferenceData: Function;
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[],
    incidentTypes: AusComplyDtos.IncidentType[],
    history: any;
    location: any;
    theme: any;
    classes: any;
}

interface IDashboardState {
    filterExpanded: boolean;
    selectedTab: number;
}

class Dashboard extends Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props)
        this.state = {
            filterExpanded: false,
            selectedTab: 0
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onLoadByTab = this.onLoadByTab.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onChangePostcodes = this.onChangePostcodes.bind(this);
    }

    componentDidMount() {
        /*
        if (this.props.filter.dashboardId == -1) {
            if (this.props.onResetFilter) {
                this.props.onResetFilter();
            }
        } else if (this.props.incidents) {
            this.loadData();
        }
        */
        this.onLoadByTab();

        this.props.onLoadReferenceData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isLoading && !this.props.isLoading || (!prevProps.incidents && this.props.incidents)) {
            this.loadData();
        }
    }

    loadData() {
        // process the data
        if (this.props.incidents) {
            //venueMarkers = 
        }
    }

    onChangeOptions(fieldName, value) {
        let options = { ... this.props.options };
        options[fieldName] = value;
        this.props.onSetOptions(options);
    }

    onChangeFilter(fieldName, value) {
        let filter = { ... this.props.filter };
        filter[fieldName] = value;
        this.props.onSetFilter(filter);
    }

    onChangePostcodes(value) {
        let options = { ... this.props.options };
        options.postcodes = value;
        this.props.onSetOptions(options);
    }

    handleTabChange = (event, value) => {
        var self = this;
        this.setState({ selectedTab: value }, () => {
            //if (value !== 0) {
            self.onLoadByTab();
            //}
        });
    };

    onLoadByTab() {
        if (this.state.selectedTab === 0) {
            this.props.onLoadOverviewQuick();
        }
        if (this.state.selectedTab === 1) {
            if (this.props.filter.dashboardId == -1) {
                if (this.props.onResetFilter) {
                    this.props.onResetFilter();
                }
            }
        }
        if (this.state.selectedTab === 2) {
            this.props.onLoadTotals();
        }
        if (this.state.selectedTab === 3) {
            this.props.onLoadIncidentTotals();
        }
        if (this.state.selectedTab === 4) {
            this.props.onLoadUserTotals();
        }
    }

    onSearch(filter: AusComplyDtos.AdminDashboardFilter) {
        this.props.onSetFilter(filter);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onResetFilter();
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.AdminDashboardFilter) {

    }

    render() {
        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <DashboardFilter
                filter={this.props.filter}
                incidentCategoryTypes={this.props.incidentCategoryTypes}
                incidentTypes={this.props.incidentTypes}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let tempFilter = new AusComplyDtos.AdminDashboardFilter();
            tempFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <DashboardFilterSummary filter={tempFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <DashboardFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let map;
        if (!this.props.isLoading) {
            let postcodes: string[] = [];
            if (this.props.options.postcodes && this.props.options.postcodes.length > 0 && this.props.options.showPostcodeBoundaries) {
                postcodes = [...this.props.options.postcodes];
            }
            map = (<Map
                filter={this.props.filter}
                venueMarkers={this.props.venueMarkers}
                postcodeBoundaries={postcodes}
                heatmapData={this.props.heatmapData}
            />);
        }

        let incidentCount = "0";
        if (this.props.isLoadingIncidents) {
            incidentCount = "...";
        } else if (this.props.incidents) {
            incidentCount = this.props.incidents.length.toString();
        }

        let content = <Loading />;
        let options;
        if (this.state.selectedTab === 1 && !this.props.isLoading) {
            content = (
                <>
                    <SubTitle text={"Incidents and Venues"} />
                    {map}
                </>
            );
            options = (
                <LayoutForm fullWidth={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show venue markers"}
                                defaultValue={this.props.options.showVenueMarkers}
                                onChanged={(value) => this.onChangeOptions("showVenueMarkers", value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show active Venues"}
                                defaultValue={this.props.options.showActiveVenues}
                                onChanged={(value) => this.onChangeOptions("showActiveVenues", value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show postcode boundaries"}
                                defaultValue={this.props.options.showPostcodeBoundaries}
                                onChanged={(value) => this.onChangeOptions("showPostcodeBoundaries", value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show heatmap for " + incidentCount + " incidents"}
                                defaultValue={this.props.options.showHeatmap}
                                onChanged={(value) => this.onChangeOptions("showHeatmap", value)} />

                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show inactive Venues"}
                                defaultValue={this.props.options.showInactiveVenues}
                                onChanged={(value) => this.onChangeOptions("showInactiveVenues", value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <DashboardPostcodes
                                items={this.props.options.postcodes}
                                onUpdate={postcodes => this.onChangePostcodes(postcodes)}
                            />
                        </Grid>
                    </Grid>
                </LayoutForm>
            );
        }

        return (
            <PageLayout
                headerText={"Admin Dashboard"}
                footerLeftContent={<TransparentButton text="Refresh" onClick={() => this.onLoadByTab()} />}
            >
                <Tabs selected={this.state.selectedTab} labels={["Overview", "Map", "Totals", "Incident Analysis", "User Analysis"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
                {this.state.selectedTab === 0 && (
                    <DashboardOverview
                        isLoading={this.props.overviewQuick.isLoading}
                        data={this.props.overviewQuick.data}
                    />
                )}
                {this.state.selectedTab === 1 && (
                    <>
                        {filterSummary}
                        {options}
                        {content}
                    </>
                )}
                {this.state.selectedTab === 2 && (
                    <DashboardTotals
                        isLoading={this.props.totals.isLoading}
                        venues={this.props.totals.venues}
                        securityFirms={this.props.totals.securityFirms}
                        incidents={this.props.totals.incidents}
                        users={this.props.totals.users}
                    />
                )}
                {this.state.selectedTab === 3 && (
                    <DashboardIncidentTotals
                        isLoading={this.props.incidentTotals.isLoading}
                        incidents={this.props.incidentTotals.incidents}
                        venueIncidents={this.props.incidentTotals.venueIncidents}
                        venueIncidentsGridLines={this.props.incidentTotals.venueIncidentsGridLines}
                        securityFirmIncidents={this.props.incidentTotals.securityFirmIncidents}
                        securityFirmIncidentsGridLines={this.props.incidentTotals.securityFirmIncidentsGridLines}
                    />
                )}
                {this.state.selectedTab === 4 && (
                    <DashboardUserTotals
                        isLoading={this.props.userTotals.isLoading}
                        venueUsers={this.props.userTotals.venueUsers}
                        venueUsersGridLines={this.props.userTotals.venueUsersGridLines}
                        securityFirmUsers={this.props.userTotals.securityFirmUsers}
                        securityFirmUsersGridLines={this.props.userTotals.securityFirmUsersGridLines}
                    />
                )}
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
            </PageLayout>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(Dashboard))