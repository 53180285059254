import React from 'react';

const RefreshIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M36.16,16.9c1.76,2.19,2.87,4.92,3.01,7.87c0.11,2.16-0.3,4.2-1.07,6.05c-2.07,4.91-6.87,8.39-12.5,8.47v-5.12c0-0.29-0.36-0.44-0.59-0.27l-10.55,7.76c-0.17,0.13-0.17,0.4,0,0.52l10.55,7.76c0.24,0.17,0.59,0.02,0.59-0.26v-5.13c8.56-0.09,15.79-5.86,18.08-13.72c0.69-2.36,0.94-4.92,0.64-7.56c-0.41-3.6-1.85-6.9-4.02-9.61c-0.9-1.12-2.49-1.35-3.64-0.51C35.45,14.02,35.23,15.73,36.16,16.9 M5.69,26.75c-0.31-2.65-0.05-5.2,0.64-7.56c2.3-7.85,9.52-13.62,18.08-13.72V0.34c0-0.29,0.36-0.44,0.59-0.26l10.56,7.76c0.17,0.13,0.17,0.4,0,0.52L25.01,16.1c-0.25,0.17-0.59,0.02-0.59-0.26v-5.12c-5.63,0.09-10.43,3.56-12.5,8.47c-0.78,1.85-1.18,3.9-1.07,6.05c0.15,2.95,1.26,5.67,3.01,7.87c0.94,1.17,0.71,2.88-0.5,3.77c-1.15,0.84-2.76,0.61-3.64-0.51C7.55,33.65,6.11,30.35,5.69,26.75" />
        </g>
    </svg>
);


export default RefreshIcon;