// This component is a wrapper so that UI components can be easily shared (copied) between react and react natve
import React from 'react';

export interface IView {
    style?: any;
    className?: string;
}

export default class View extends React.PureComponent<IView, any> {
    render() {
        return (
            <div className={this.props.className} style={{ ...this.props.style }}>
                {this.props.children}
            </div>
        );
    }
}