import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueContacts from '../components/venue/venueContacts/VenueContacts'
import { 
    venueContactsRequest, 
    venueContactCreateRequest, 
    venueContactRequest, 
    venueContactReset,
    venueContactSet,
    venueContactUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.venue.venueContacts.paging,
        filter: state.venue.venueContacts.filter,
        isLoading: state.venue.venueContacts.isLoading,
        venueContacts: state.venue.venueContacts.venueContacts,
        venueContactId: state.venue.venueContact.venueContactId,
        canEdit: permissionsLogic.hasPermissionForState(state, "VenueContactEdit")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(venueContactsRequest(page, pageSize, filter))
        },
        onLoadItem: (venueContactId) => {
            dispatch(venueContactRequest(venueContactId))
        },
        onCreate: (venueId) => {
            dispatch(venueContactCreateRequest(venueId))
        },
        onResetItem: () => {
            dispatch(venueContactReset())
        },
        onUpdate: (venueContact) => {
            dispatch(venueContactSet(venueContact))
        },
        onSave: () => {
            dispatch(venueContactUpsertRequest())
        }
    }
}

const VenueContactsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueContacts))

export default VenueContactsContainer