import { connect } from 'react-redux'
import { withRouter } from "react-router";
import AdminFacialRecognitionNotifications from '../components/admin/adminFacialRecognitionNotifications/AdminFacialRecognitionNotifications'
import { 
    adminFacialRecognitionNotificationFilterRequest, 
    adminFacialRecognitionNotificationSetItem,
    adminFacialRecognitionNotificationSetPaging,
    adminFacialRecognitionNotificationSetFilter,
    adminFacialRecognitionNotificationGetRequest
 } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        paging: state.admin.facialRecognitionNotifications.paging,
        filter: state.admin.facialRecognitionNotifications.filter,
        isLoading: state.admin.facialRecognitionNotifications.isLoading,
        facialRecognitionNotifications: state.admin.facialRecognitionNotifications.facialRecognitionNotifications,
        isLoadingItem: state.admin.facialRecognitionNotifications.facialRecognitionNotification.isLoading,
        facialRecognitionNotification: state.admin.facialRecognitionNotifications.facialRecognitionNotification.facialRecognitionNotification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadItem: (facialRecognitionNotificationId) => {
            dispatch(adminFacialRecognitionNotificationGetRequest(facialRecognitionNotificationId))
        },
        onSetItem: (facialRecognitionNotification) => {
            dispatch(adminFacialRecognitionNotificationSetItem(facialRecognitionNotification))
        },
        onLoadFilter: () => {
            dispatch(adminFacialRecognitionNotificationFilterRequest())
        },
        onSetFilter: (filter) => {
            dispatch(adminFacialRecognitionNotificationSetFilter(filter))
        },
        onSetPaging: (paging) => {
            dispatch(adminFacialRecognitionNotificationSetPaging(paging))
        }
    }
}

const AdminFacialRecognitionNotificationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminFacialRecognitionNotifications))

export default AdminFacialRecognitionNotificationsContainer