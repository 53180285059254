import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IIncidentCategoryTypeHeaderProps {
    canEditNotifications?: boolean;
    classes: any;
}

class IncidentCategoryTypeHeader extends React.Component<IIncidentCategoryTypeHeaderProps, any> {
    render() {
        const { classes } = this.props;
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Incident Types</th>
                <th className={classes.tableHr}>Code</th>
                <th className={classes.tableHr}>Industries</th>
                <th className={classes.tableHr}>State</th>
                <th className={classes.tableHrCentered}>Narrative</th>
                <th className={classes.tableHrCentered}>Legislated</th>
                <th className={classes.tableHrCentered}>Bulk Approval</th>
                <th className={classes.tableHrCentered}>Auto Approve</th>
                <th className={classes.tableHrCentered}>Snapshot</th>
                <th className={classes.tableHrCentered}>POI Required</th>
                <th className={classes.tableHrCentered}>Contact Tracing</th>
                {this.props.canEditNotifications && (
                    <th className={classes.tableHrCentered}>Notify</th>
                )}
                <th className={classes.tableHr}>Rank</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentCategoryTypeHeader);