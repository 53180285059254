import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SignOnRegisterTypes from '../components/signOnRegisterTypes/SignOnRegisterTypes'
import * as signOnRegisterTypeActions from '../common/actions/signOnRegisterType';

const mapStateToProps = (state, props) => {
    return {
        paging: state.signOnRegisterTypes.signOnRegisterTypes.paging,
        filter: state.signOnRegisterTypes.signOnRegisterTypes.filter,
        isLoading: state.signOnRegisterTypes.signOnRegisterTypes.isLoading,
        signOnRegisterTypes: state.signOnRegisterTypes.signOnRegisterTypes.signOnRegisterTypes,
        signOnRegisterTypeId: state.signOnRegisterTypes.signOnRegisterType.signOnRegisterTypeId,
        canEdit: permissionsLogic.hasPermissionForState(state, "SignOnRegisterTypes")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypesRequest(page, pageSize, filter))
        },
        onLoadItem: (signOnRegisterTypeId) => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeRequest(signOnRegisterTypeId))
        },
        onCreate: (forGroupId, forVenueId) => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeCreateRequest(forGroupId, forVenueId))
        },
        onResetItem: () => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeReset())
        },
        onUpdate: (signOnRegisterType) => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeUpdate(signOnRegisterType))
        },
        onSave: () => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeSaveRequest())
        }
    }
}

const AdminSignOnRegisterTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnRegisterTypes))

export default AdminSignOnRegisterTypesContainer