import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import ResponsiveList from '../../common/ResponsiveList';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import { KeyName } from '../../../common/dto/common';
import SimpleFilter from '../../common/SimpleFilter';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import View from '../../common/View';
import VenueTabs from '../../admin/venue/VenueTabs';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';

export interface IPOIExpiryFailuresProps {
    isLoading: boolean;
    isSaving: boolean;
    venueId: number;
    data: AusComplyDtos.FacialRecognitionPOIIncident[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    onLoad: Function;
    onRetry: Function;
    onDismiss: Function;
    history: any;
    location: any;
}

export default class POIExpiryFailures extends Component<IPOIExpiryFailuresProps, any> {
    constructor(props: IPOIExpiryFailuresProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.page, this.props.paging.pageSize, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving) {
            // reload
            this.props.onLoad(this.props.paging.page, this.props.paging.pageSize, this.prepareFilter(this.props.filter));
        }
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
            preparedFilter.forGroupId = 0;
        } else {
            preparedFilter.forGroupId = 0;
            preparedFilter.forVenueId = 0;
        }
        return preparedFilter;
    }


    getCommands(facialRecognitionPOIIncident: AusComplyDtos.FacialRecognitionPOIIncident) {
        var commands: KeyName[] = [];
        commands.push({ key: "retry", name: "Retry" });
        commands.push({ key: "dismiss", name: "Dismiss" });
        return commands;
    }

    onCommand(command: string, facialRecognitionPOIIncident: AusComplyDtos.FacialRecognitionPOIIncident) {
        switch (command) {
            case "retry":
                this.props.onRetry(this.props.filter, facialRecognitionPOIIncident.facialRecognitionPOIIncidentId);
                break;
            case "dismiss":
                this.props.onDismiss(facialRecognitionPOIIncident.facialRecognitionPOIIncidentId);
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(1, this.props.paging.pageSize, this.props.filter);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {
        this.props.onLoad(1, this.props.paging.pageSize, this.prepareFilter({ ...filter }));
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(1, this.props.paging.pageSize, this.prepareFilter(new AusComplyDtos.SimpleFilter()));
        this.setState({ filterExpanded: false });
    }

    render() {
        let self = this;

        let retryAll;
        if (!this.props.isLoading && !this.props.isSaving) {
            retryAll = (
                <TransparentButton
                    onClick={() => self.props.onRetry(self.props.filter, 0)}
                    text={"Retry All"} />
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onReset={() => self.onResetSearch()}
                onSearch={(filter) => self.onSearch(filter)}
            />;
        } else if (this.props.isLoading) {
            let simpleFilter = new AusComplyDtos.SimpleFilter();
            simpleFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={simpleFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<PageLayout
            headerText={"Venue"}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            footerLeftContent={retryAll}
        >
            <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
            <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Watchlist Removal Failures"} history={this.props.history} />
            {filterSummary}
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.data}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(page, this.props.paging.pageSize, this.prepareFilter(this.props.filter))}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(page, pageSize, this.prepareFilter(this.props.filter))}
                headers={["Full Name", "Server", "Watchlist", "Expiry", "Error"]}
                columns={["fullName", "server", "watchlist", "expiryDisplay", "failedWatchlistRemovalError"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
            />
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
        </PageLayout>);
    }
}