import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueLocation from '../components/venue/venueLocation/VenueLocation'
import { 
    adminVenueLocationReset,
    adminVenueLocationSet,
    adminVenueLocationUpsertRequest
 } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.admin.venueLocation.isLoading,
        isSaving: state.admin.venueLocation.isSaving,
        venueLocationId: state.admin.venueLocation.venueLocationId,
        venueLocation: state.admin.venueLocation.venueLocation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(adminVenueLocationReset())
        },
        onUpdate: (venueLocation) => {
            dispatch(adminVenueLocationSet(venueLocation))
        },
        onSave: () => {
            dispatch(adminVenueLocationUpsertRequest())
        }
    }
}

const AdminVenueLocationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueLocation))

export default AdminVenueLocationContainer