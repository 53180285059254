import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import TransparentButton from '../controls/TransparentButton';
import View from '../common/View';
import TouchableOpacity from '../common/TouchableOpacity';


export class ProgressItem {
    step: number;
    key: string;
    name?: string;
    isSuccess?: boolean;
    isWarning?: boolean;
    isError?: boolean;
    isInfo?: boolean;
}

export interface IProgressProps {
    classes: any;
    theme: any;
    value: number;
    items: ProgressItem[];
    showStep?: boolean;
    onClick?: Function;
    isError?: boolean;
    isWarning?: boolean;
}

class Progress extends React.PureComponent<IProgressProps, any> {
    constructor(props: IProgressProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(step: ProgressItem) {
        if (this.props.onClick) {
            this.props.onClick(step);
        }
    }

    render() {
        const { classes, theme } = this.props;

        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                {this.props.items.map((item) => {
                    let color = '#666666';
                    if (item.isError) {
                        color = '#d2242b';
                    } else if (item.isWarning) {
                        color = '#FF6721';
                    } else if (item.isInfo) {
                        color = '#FF6721';
                    } else if (item.isSuccess ) {
                        color = '#42c23b';
                    } else if (this.props.value == item.step || this.props.value > item.step) {
                        if (this.props.isError) {
                            color = '#d2242b';
                        } else if (this.props.isWarning) {
                            color = '#FF6721';
                        } else {
                            color = '#42c23b';
                        }
                    }
                    return <TouchableOpacity
                        style={{ flexGrow: 1 }}
                        key={item.key + "_step_button"}
                        onPress={() => this.onClick(item)}
                    ><div style={{
                        flexGrow: 1,
                        display: 'table',
                        margin: '2px',
                        marginBottom: '16px'
                    }} key={item.key + "_step"}>

                            <div style={{
                                flex: 1,
                                borderRadius: '8px',
                                padding: '1px',
                                height: '10px',
                                marginTop: '5px',
                                backgroundColor: color
                            }}></div>
                            {item.name && (<p style={{
                                textAlign: 'center',
                                fontSize: '7px',
                                textTransform: 'uppercase',
                                marginTop: '3px',
                                color: color
                            }}>{item.name} </p>)}
                        </div>
                    {
                        (this.props.value == item.step) && (
                            <>
                                <div
                                    style={{borderRadius: '8px',
                                    backgroundColor: '#fff',
                                    height: '16px',
                                    width: '16px',
                                    padding: '0px',
                                    position: 'absolute',
                                    top: '5px',
                                    right: '0px'}}>
                                </div>
                                <div
                                    style={{
                                        borderRadius: '5px',
                                        backgroundColor: '#555',
                                        height: '8px',
                                        width: '8px',
                                        padding: '0px',
                                        position: 'absolute',
                                        top: '9px',
                                        right: '4px'
                                    }}>
                                </div>
                            </>
                        )
                    }
                    </TouchableOpacity>
                })}
            </div>
        );
  
    }
}

export default withStyles(styles, { withTheme: true })(Progress);