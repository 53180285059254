import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewPersonnelProps {
    incident: IncidentDTO;
}

export default class IncidentViewPersonnel extends Component<IIncidentViewPersonnelProps, any> {
    render() {
        if (!this.props.incident.incidentUsers || this.props.incident.incidentUsers.length === 0) {
            return (<></>);
        }
        let personnel = this.props.incident.incidentUsers.map((item, index) => <span key={index}>{index > 0 && (<br />)}{item.display}</span>);
        return (
            <ControlGroup text="Staff Involved">
                <TextDisplay>{personnel}</TextDisplay>
            </ControlGroup>
        );
    }
}