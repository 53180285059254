import React from 'react';
import { UserSignOnStatus } from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import LayoutForm from '../layout/LayoutForm'
import PageTitle from '../common/PageTitle'
import TextControlGroup from '../controls/TextControlGroup'
import ShiftDurationControlGroup from '../controls/ShiftDurationControlGroup'
import PasswordControlGroup from '../controls/PasswordControlGroup'
import { withRouter } from "react-router";
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';


export interface ISignOnExtendProps {
    userSignOnStatusId: number,
    isLoading: boolean,
    isSaving: boolean,
    saveError: string,
    userSignOnStatus: UserSignOnStatus;
    onRefresh: Function;
    onSignOnExtend: Function;
    history: any;
    location: any;
    canViewSignOnRegister: boolean;
}

export interface ISignOnExtendState {
    error: string;
    errors: any;
    password: string;
    hours: number;
    minutes: number;
    isAcknowledged: boolean;
}

class SignOnExtend extends React.PureComponent<ISignOnExtendProps, ISignOnExtendState> {
    constructor(props: ISignOnExtendProps) {
        super(props);

        this.onRefresh = this.onRefresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onReturn = this.onReturn.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onDurationChanged = this.onDurationChanged.bind(this);

        this.state = {
            error: "",
            errors: {},
            password: "",
            hours: 1,
            minutes: 0,
            isAcknowledged: false
        }
    }

    componentDidMount() {
        if (!this.props.isLoading) {
            this.onRefresh();
        }
        this.setState({
            hours: 1,
            minutes: 0,
            password: ''
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // save complete
        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.saveError) {
                this.setState({
                    errors: {},
                    error: this.props.saveError
                });
            } else {
                // save was successful - redirect back
                this.onReturn();
            }
        }


    }

    onRefresh() {
        this.props.onRefresh(this.props.userSignOnStatusId);
    }

    onPasswordChanged(value) {
        if (value != null) {
            this.setState({ password: value });
        }
    }

    onDurationChanged(value) {
        if (value != null) {
            this.setState({
                hours: value.hours,
                minutes: value.minutes
            });
        }
    }

    onSave() {

        // Validate
        let errors = {}
        let error = "";

        if (!this.state.password || this.state.password.length === 0) {
            errors["password"] = "Password is required";
        }

        if (this.state.hours === 0 && this.state.minutes === 0) {
            errors["shiftLength"] = "Extend by is required";
        }

        if (Object.keys(errors).length > 0) {
            if (Object.keys(errors).length > 1) {
                error = "Please fix the highlighted fields and try again";
            } else if (Object.keys(errors).length === 1) {
                error = errors[Object.keys(errors)[0]];
            }
            this.setState({ errors: errors, error: error });
            return;
        }

        this.setState({
            errors: {},
            error: ""
        });

        // saving
        var signOnStatus = this.props.userSignOnStatus;
        signOnStatus.password = this.state.password;
        signOnStatus.extendHours = this.state.hours;
        signOnStatus.extendMinutes = this.state.minutes;

        this.props.onSignOnExtend(signOnStatus);
    }

    onReturn() {
        if (this.props.canViewSignOnRegister) {
            this.props.history.push('/signonoffregister');
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        let locationRefresh;
        let signOnExtendForm;
        let loading = this.props.isLoading;

        let name = this.props.userSignOnStatus.givenName + " " + this.props.userSignOnStatus.surname;

        let alternateMessage;
        if (!this.props.userSignOnStatus.isSignedOn) {
            alternateMessage = (
                <span>The sign on was not found or it has already been signed off</span>
            );
        }

        if (this.props.userSignOnStatus) {
            signOnExtendForm = (
                <>
                    <TextControlGroup text="Venue"
                        defaultValue={this.props.userSignOnStatus.venue}
                        readonly={true}></TextControlGroup>
                    <TextControlGroup text="Signed on user"
                        defaultValue={name}
                        readonly={true} />
                    <TextControlGroup text="Rostered start"
                        defaultValue={this.props.userSignOnStatus.rosteredStartFormatted}
                        readonly={true} />
                    <TextControlGroup text="Original Shift Length"
                        defaultValue={this.props.userSignOnStatus.durationFormatted}
                        readonly={true} />
                    <ShiftDurationControlGroup text="Extend by"
                        hours={this.state.hours}
                        minutes={this.state.minutes}
                        error={this.state.errors["shiftLength"]}
                        onChanged={this.onDurationChanged} />
                    {/* decoy control for autofill */}
                    <input style={{ border: 'none', fontSize: '1px', color: 'transparent', backgroundColor: 'transparent' }} name="username" id="username" />
                    <PasswordControlGroup
                        text="Password"
                        error={this.state.errors["password"]}
                        onChange={this.onPasswordChanged}
                    />
                </>
            );
        }


        return (
            <PageLayout
                headerText={"Extend Sign On"}
                loading={loading}
                saving={this.props.isSaving}
                error={this.state.error}
                notFullWidth={true}
                hideQuickActions={true}
                alternateMessage={alternateMessage}
                extraMessages={locationRefresh}
                footerLeftContent={<TransparentButton text="Cancel" onClick={this.onReturn} />}
                footerRightContent={<PrimaryButton text="Extend" onClick={this.onSave} />}
            >
                {signOnExtendForm}
            </PageLayout>
        );

    }
}

export default withRouter(SignOnExtend)