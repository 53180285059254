import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmSubAssociation from '../components/securityFirm/securityFirmSubAssociation/SecurityFirmSubAssociation';
import { 
    securityFirmSubAssociationReset,
    securityFirmSubAssociationSet,
    securityFirmSubAssociationInsertRequest
 } from '../common/actions/securityFirmAssociations';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.securityFirmAssociations.securityFirmAssociation.isLoading,
        isSaving: state.securityFirmAssociations.securityFirmAssociation.isSaving,
        securityFirmAssociation: state.securityFirmAssociations.securityFirmAssociation.securityFirmAssociation,
        securityFirms: state.securityFirmAssociations.securityFirmAssociation.securityFirms,
        open: props.open,
        onClosed: props.onClosed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(securityFirmSubAssociationReset())
        },
        onUpdate: (securityFirmAssociation) => {
            dispatch(securityFirmSubAssociationSet(securityFirmAssociation))
        },
        onSave: () => {
            dispatch(securityFirmSubAssociationInsertRequest())
        }
    }
}

const SecurityFirmSubAssociationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmSubAssociation))

export default SecurityFirmSubAssociationContainer