import { connect } from 'react-redux'
import CommsLogsSummary from '../components/incidents/commlogs/CommsLogsSummary';
import { withRouter } from "react-router";
import * as incidentCategoryTypeActions from '../common/actions/incidentCategoryType';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const getReportingPeriodId = (state) => {
    if (state.user && state.user.details && state.user.details.userSignOnSummary) {
        return state.user.details.userSignOnSummary.currentVenueReportingPeriodId;
    }
    return 0;
}

const mapStateToProps = state => {
    return {
        isLoading: state.incidentCategoryTypes.usage.commsLogs.isLoading,
        items: state.incidentCategoryTypes.usage.commsLogs.incidentCategoryTypes,
        reportingPeriodId: getReportingPeriodId(state),
        canView: permissionsLogic.hasPermissionForState(state, "CommsLogsView")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (reportingPeriodId) => {
            dispatch(incidentCategoryTypeActions.incidentCategoryTypesUsageRequest(0, "CommsLog", reportingPeriodId))
        }
    }
}

const CommsLogsSummaryContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CommsLogsSummary))

export default CommsLogsSummaryContainer