
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { securityFirmAssociationServiceApi } from '../services/securityFirmAssociation';

import {
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST,
    SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH,
    securityFirmSubAssociationsRequestSuccess,
    securityFirmSubAssociationsRequestFailure,
    securityFirmSubAssociationsRefresh,
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST,
    securityFirmSubAssociationCreateRequestFailure,
    securityFirmSubAssociationCreateRequestSuccess,
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST,
    securityFirmSubAssociationInsertRequestFailure,
    securityFirmSubAssociationInsertRequestSuccess,
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS,
    securityFirmSubAssociationReset,
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST,
    securityFirmSubAssociationRemoveRequestFailure,
    securityFirmSubAssociationRemoveRequestSuccess,
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS

} from "../actions/securityFirmAssociations";

import {
    securityFirmAssociationsRefresh
} from '../actions/securityFirmAssociations';

import {
    notifyError, notifyErrorMessage
} from './common';

// SECURITY_FIRM_ASSOCIATIONS

const securityFirmSubAssociationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST, SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmAssociationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirmAssociations.securityFirmAssociations.securityFirmId, //action['forSecurityFirmId'],
                state.securityFirmAssociations.securityFirmAssociations.paging.page, //action['page'],
                state.securityFirmAssociations.securityFirmAssociations.paging.pageSize //action['pageSize']
            ).pipe(
                map(response => securityFirmSubAssociationsRequestSuccess(response.data, response.paging)),
                catchError(error => notifyError(error, "securityFirmSubAssociationsRequestEpic.find", securityFirmSubAssociationsRequestFailure))
            )
        ),
        catchError(error => notifyError(error, "securityFirmSubAssociationsRequestEpic", securityFirmSubAssociationsRequestFailure))
    );

const securityFirmSubAssociationCreateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmAssociationServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'])
                .pipe(
                    map(response => securityFirmSubAssociationCreateRequestSuccess(response.securityFirmAssociation, response.securityFirms)),
                    catchError(error => notifyError(error, "securityFirmSubAssociationCreateRequestEpic.create", securityFirmSubAssociationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmSubAssociationCreateRequestEpic", securityFirmSubAssociationCreateRequestFailure))
    );

const securityFirmSubAssociationInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmAssociationServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirmAssociations.securityFirmAssociation.securityFirmAssociation)
                .pipe(
                    map(response => securityFirmSubAssociationInsertRequestSuccess()),
                    catchError(error => notifyError(error, "securityFirmSubAssociationInsertRequestEpic.save", securityFirmSubAssociationInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmSubAssociationInsertRequestEpic", securityFirmSubAssociationInsertRequestFailure))
    );

const securityFirmSubAssociationInsertRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS),
        map((action: any) => securityFirmSubAssociationReset())
    );

const securityFirmSubAssociationSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS, SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS),
        map((action: any) => securityFirmSubAssociationsRefresh())
    );

const securityFirmAssociationSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS, SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS),
        map((action: any) => securityFirmAssociationsRefresh())
    );

const securityFirmSubAssociationRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmAssociationServiceApi.delete(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmAssociationId'],
                action['allAssociations'])
                .pipe(
                    map(response => securityFirmSubAssociationRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "securityFirmSubAssociationRemoveRequestEpic.delete", securityFirmSubAssociationRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmSubAssociationRemoveRequestEpic", securityFirmSubAssociationRemoveRequestFailure))
    );

export const securityFirmAssociationEpics = combineEpics(
    securityFirmSubAssociationsRequestEpic,
    securityFirmSubAssociationCreateRequestEpic,
    securityFirmSubAssociationInsertRequestEpic,
    securityFirmSubAssociationInsertRequestSuccessEpic,
    securityFirmSubAssociationSuccessRefreshEpic,
    securityFirmSubAssociationRemoveRequestEpic,
    securityFirmAssociationSuccessRefreshEpic
);