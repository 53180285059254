import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueReportingPeriodCurrent from '../components/venue/reportingPeriods/VenueReportingPeriodCurrent';
import { 
    venueReportingPeriodRequest, 
    venueReportingPeriodCurrentRequest,
    venueReportingPeriodCreateRequest,
    venueReportingPeriodCloseRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        isLoading: state.venue.reportingPeriodCurrent.isLoading,
        isSaving: state.venue.reportingPeriodCurrent.isSaving,
        venueReportingPeriod: state.venue.reportingPeriodCurrent.reportingPeriod,
        venueEventReportingPeriods: state.venue.reportingPeriodCurrent.eventReportingPeriods
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueReportingPeriodCurrentRequest(venueId))
        },
        onLoadItem: (venueReportingPeriodId) => {
            dispatch(venueReportingPeriodRequest(venueReportingPeriodId))
        },
        onCreate: (venueId) => {
            dispatch(venueReportingPeriodCreateRequest(venueId))
        },
        onClose: (venueReportingPeriodId) => {
            dispatch(venueReportingPeriodCloseRequest(venueReportingPeriodId))
        }
    }
}

const VenueReportingPeriodCurrentContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueReportingPeriodCurrent))

export default VenueReportingPeriodCurrentContainer