import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const functionalAreaServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFunctionalAreas();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forGroupId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFunctionalAreaNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forGroupId = forGroupId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, functionalArea) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFunctionalArea();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.functionalArea = functionalArea;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, functionalAreaId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFunctionalArea();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.functionalAreaId = functionalAreaId;
    const request = client.get(query);
    return from(request);
  }
};
