import React from "react";

const PersonIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 62 62"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="m44.1,18.8c0,-7.7 -6.2,-13.9 -13.9,-13.9c-7.7,0 -13.9,6.2 -13.9,13.9c0,7.7 6.2,13.9 13.9,13.9c7.7,0 13.9,-6.2 13.9,-13.9m11.1,26.9c-2.8,-7 -9.2,-11.4 -15.9,-11.7c-2.5,2.2 -5.7,3.5 -9.2,3.5c-3.5,0 -6.7,-1.3 -9.2,-3.5c-6.7,0.4 -13,4.8 -15.8,11.7c-0.2,0.5 -2.2,6.7 -2.4,9.4l54.6,0c-0.1,-2.7 -1.9,-8.9 -2.1,-9.4" />
	        </g>
        </svg>
    );

export default PersonIcon;
