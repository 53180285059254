import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import AdminFacialRecognitionNotificationFilterSummary from './AdminFacialRecognitionNotificationFilterSummary';
import AdminFacialRecognitionNotificationFilter from './AdminFacialRecognitionNotificationFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import AdminFacialRecognitionNotificationCard from './AdminFacialRecognitionNotificationCard';
import AdminFacialRecognitionNotificationHeader from './AdminFacialRecognitionNotificationHeader';
import AdminFacialRecognitionNotificationRow from './AdminFacialRecognitionNotificationRow';

export interface IAdminFacialRecognitionNotificationsProps {
    isLoading: boolean;
    facialRecognitionNotifications: AusComplyDtos.FacialRecognitionNotification[];
    facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.FacialRecognitionNotificationFilter;
    error: string;
    isLoadingItem: boolean;
    onLoadItem: Function;
    onSetItem: Function;
    onLoadFilter: Function;
    onSetFilter: Function;
    onSetPaging: Function;
    classes: any;
    theme: any;
}

interface IAdminFacialRecognitionNotificationsState {
    filterExpanded: boolean;
}

class AdminFacialRecognitionNotifications extends Component<IAdminFacialRecognitionNotificationsProps, IAdminFacialRecognitionNotificationsState> {
    constructor(props: IAdminFacialRecognitionNotificationsProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoadFilter();
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        let paging = { ...this.props.paging };
        paging.page = page;
        this.props.onSetPaging(paging);
    }

    onSearch(filter: AusComplyDtos.FacialRecognitionNotificationFilter) {
        this.props.onSetFilter(filter);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoadFilter();
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onEdit(facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification) {
        this.props.onLoadItem(facialRecognitionNotification.facialRecognitionNotificationId);
    }

    onCancelEdit() {
        let facialRecognitionNotification = new AusComplyDtos.FacialRecognitionNotification();
        facialRecognitionNotification.facialRecognitionNotificationId = -1;
        this.props.onSetItem(facialRecognitionNotification);
    }

    onUpdateFilter(filter: AusComplyDtos.FacialRecognitionNotificationFilter) {

    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.facialRecognitionNotifications && this.props.facialRecognitionNotifications.length > 0) {
            cardList = this.props.facialRecognitionNotifications.map((item, index) => {
                return (
                    <AdminFacialRecognitionNotificationCard
                        onEdit={this.onEdit}
                        key={"card-" + item.facialRecognitionNotificationId.toString()}
                        facialRecognitionNotification={item}
                    />
                );
            });

            rows = this.props.facialRecognitionNotifications.map((item, index) => {
                return (
                    <AdminFacialRecognitionNotificationRow
                        onEdit={this.onEdit}
                        key={"row-" + item.facialRecognitionNotificationId.toString()}
                        facialRecognitionNotification={item} />
                );
            });
        }

        let filter;
        let filterSummary;

        if (this.state.filterExpanded) {
            filter = <AdminFacialRecognitionNotificationFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let facialRecognitionNotificationFilter = new AusComplyDtos.FacialRecognitionNotificationFilter();
            facialRecognitionNotificationFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <AdminFacialRecognitionNotificationFilterSummary filter={facialRecognitionNotificationFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <AdminFacialRecognitionNotificationFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        rowList = (
            <TableContainer
                header={<AdminFacialRecognitionNotificationHeader />}
                rows={rows}
            />
        );

        let recievedNotification = this.props.facialRecognitionNotification.recievedNotification;
        let facialRecognitionNotification;
        if (this.props.facialRecognitionNotification && this.props.facialRecognitionNotification.facialRecognitionNotificationId > -1) {
            facialRecognitionNotification = this.props.isLoadingItem ? (<Loading />) : (
                <LayoutForm>
                    <ControlGroup text="Created At">
                        <TextDisplay>{this.props.facialRecognitionNotification.dateEntered}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Event Type">
                        <TextDisplay>{this.props.facialRecognitionNotification.recievedNotification.event_type}</TextDisplay>
                    </ControlGroup>
                    {(recievedNotification && recievedNotification.camera_data) && (
                        <>
                            <SubTitle text={"Camera Data"} />
                            <ControlGroup text="Camera Description">
                                <TextDisplay>{recievedNotification.camera_data.camera_description}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Camera ID">
                                <TextDisplay>{recievedNotification.camera_data.camera_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Stream ID">
                                <TextDisplay>{recievedNotification.camera_data.stream_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.frame_data) && (
                        <>
                            <SubTitle text={"Frame Data"} />
                            <ControlGroup text="UTC Time Recorded">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_recorded}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Zone">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_zone}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Frame ID">
                                <TextDisplay>{recievedNotification.frame_data.frame_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Bounded Box">
                                {recievedNotification.frame_data.bounding_box && (
                                    <TextDisplay>x1: {recievedNotification.frame_data.bounding_box.x1} y1: {recievedNotification.frame_data.bounding_box.y1} x2: {recievedNotification.frame_data.bounding_box.x2} y2: {recievedNotification.frame_data.bounding_box.y2}</TextDisplay>
                                )}
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.appearance_data) && (
                        <>
                            <SubTitle text={"Appearance Data"} />
                            <ControlGroup text="Appearance ID">
                                <TextDisplay>{recievedNotification.appearance_data.appearance_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Started">
                                <TextDisplay>{recievedNotification.appearance_data.utc_time_started}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="First frame ID">
                                <TextDisplay>{recievedNotification.appearance_data.first_frame_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.crop_data) && (
                        <>
                            <SubTitle text={"Crop Data"} />
                            <ControlGroup text="Face Crop Image">
                                {(recievedNotification.crop_data.face_crop_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.crop_data.face_crop_img}`} alt="photo" />
                                )}
                            </ControlGroup>
                            <ControlGroup text="Face Score">
                                <TextDisplay>{recievedNotification.crop_data.face_score}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Pitch">
                                <TextDisplay>{recievedNotification.crop_data.pitch}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Yaw">
                                <TextDisplay>{recievedNotification.crop_data.yaw}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Masked Score">
                                <TextDisplay>{recievedNotification.crop_data.masked_score}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.face_features_data) && (
                        <>
                            <SubTitle text={"Face Features Data"} />
                            <ControlGroup text="Mask Outcome">
                                <TextDisplay>{recievedNotification.face_features_data.mask_outcome}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data) && (
                        <>
                            <SubTitle text={"Match Data"} />
                            <ControlGroup text="UTC Time matched">
                                <TextDisplay>{recievedNotification.match_data.utc_time_matched}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI ID">
                                <TextDisplay>{recievedNotification.match_data.poi_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Coonfidence">
                                <TextDisplay>{recievedNotification.match_data.poi_confidence}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Display Image">
                                {(recievedNotification.match_data.poi_display_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.match_data.poi_display_img}`} alt="photo" />
                                )}
                                {(!recievedNotification.match_data.poi_display_img) && (
                                    <TextDisplay>Empty</TextDisplay>
                                 )}
                            </ControlGroup>
                            <ControlGroup text="POI Display Name">
                                <TextDisplay>{recievedNotification.match_data.poi_display_name}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data && recievedNotification.match_data.watchlists) && (
                        <>
                            <SubTitle text={"Watchlists"} />
                            {recievedNotification.match_data.watchlists.map((item, index) => (<View key={"watchlist-" + item.watchlist_id}>
                                <ControlGroup text="Watchlist ID">
                                    <TextDisplay>{item.watchlist_id}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Watchlist Type">
                                    <TextDisplay>{item.watchlist_type}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="match Outcome">
                                    <TextDisplay>{item.match_outcome}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Name">
                                    <TextDisplay>{item.display_name}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Colour">
                                    <TextDisplay style={{ color: item.display_color }}>{item.display_color}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text=" ">
                                    <hr />
                                </ControlGroup>
                            </View>))}
                        </>
                    )}

                    <TextAreaControlGroup text={"Raw Json Data"} defaultValue={this.props.facialRecognitionNotification.jsonString} />
                </LayoutForm>
            );
        }

        return (
            <>
                <PageTitle title="Facial Recognition Notifications"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog open={this.props.facialRecognitionNotification.facialRecognitionNotificationId != -1} title="Facial Recognition" onDismissed={() => this.onCancelEdit()}>
                    {facialRecognitionNotification}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AdminFacialRecognitionNotifications);