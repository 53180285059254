import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewNarrativeProps {
    incident: IncidentDTO;
}

export default class IncidentViewNarrative extends Component<IIncidentViewNarrativeProps, any> {
    render() {
        if (!this.props.incident.summary || this.props.incident.summary.length === 0) {
            return (<></>);
        }
        var categories = ""
        var name = "";
        var exp = "";
        var notes = "";
        if(this.props.incident.summary.includes("Fullname:")) {
            const [first, ...rest] = this.props.incident.summary.split("Fullname:");
            categories = first;
            name = "Fullname:" + rest.join("Fullname:");
        } else {
            categories = this.props.incident.summary
        }
        if(name.includes("- exp")) {
            const [first, ...rest] = name.split("- exp");
            name = first;
            exp = rest.join("- exp");
        }
        if(exp.includes("Previous Notes:")) {
            const [first, ...rest] = exp.split("Previous Notes:");
            exp = " - exp " + first;
            notes = "Previous Notes: " + rest.join("Previous Notes:");
        }
        
        return (
            <ControlGroup text="Narrative">
                <TextDisplay>{categories + "\n" + name + exp + "\n" + notes}</TextDisplay>
            </ControlGroup>
        );
    }
}