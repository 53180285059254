import React from 'react';
import View from '../../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../../common/dto/AusComply.dtos";
import CheckboxControl from '../../../controls/CheckboxControl'
import TextControlGroup from '../../../controls/TextControlGroup';
import TransparentButton from '../../../controls/TransparentButton';
import LayoutForm from '../../../layout/LayoutForm';
import DefaultButton from '../../../controls/DefaultButton';
import SearchDateControlGroup from '../../../controls/SearchDateControlGroup';
import MultiSelectControl from '../../../controls/MutiSelectControl';
import { MulitSelectOption as MultiSelectOption } from '../../../../common/dto/common';

export interface IGroupDashboardFilterProps {
    filter: AusComplyDTOs.DashboardFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IGroupDashboardFilterState {
    venues: MultiSelectOption[];
    filter: AusComplyDTOs.DashboardFilter;
}

export default class GroupDashboardFilter extends React.Component<IGroupDashboardFilterProps, IGroupDashboardFilterState> {
    constructor(props: IGroupDashboardFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.includeIDChecksChanged = this.includeIDChecksChanged.bind(this);
        this.onVenuesChanged = this.onVenuesChanged.bind(this);

        let venues: MultiSelectOption[] = [];
        try {
            if (this.props.filter.venues) {
                this.props.filter.venues.forEach((item, index) => {
                    venues.push({
                        id: item.venueId,
                        name: item.name,
                        isCommon: false,
                        selected: item.selected
                    });
                });
            }
        } catch {

        }
        this.state = {
            filter: new AusComplyDTOs.DashboardFilter(),
            venues,
        };
    }

    componentDidMount() {
        let venues: MultiSelectOption[] = [];
        try {
            if (this.props.filter.venues) {
                this.props.filter.venues.forEach((item, index) => {
                    venues.push({
                        id: item.venueId,
                        name: item.name,
                        isCommon: false,
                        selected: item.selected
                    });
                });
            }
        } catch {

        }
        this.setState({ filter: this.props.filter, venues });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    includeIDChecksChanged(value) {
        let filter = { ...this.state.filter };
        filter.includeIDChecks = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onVenuesChanged(items) {
        let venues = [...this.state.venues];
        let venueIds = items.map(item => item.id);
        if (venues) {
            venues.forEach(p => {
                p.selected = venueIds.indexOf(p.id) !== -1;
            });
        }
        let filter = { ...this.state.filter };
        filter.venues.forEach(v => {
            v.selected = venueIds.indexOf(v.venueId) !== -1;
        });
        this.setState({ venues, filter });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                </Grid>
            </Grid>
        );


        return (
            <LayoutForm>
                <SearchDateControlGroup
                    text="Time Period"
                    rightText={"From"}
                    labelAbove={true}
                    date={this.state.filter.from}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("from", value)}
                    defaultTime={"00:00"} />
                <SearchDateControlGroup
                    rightText="To"
                    labelAbove={true}
                    date={this.state.filter.to}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("to", value)}
                    defaultTime={"23:59"} />
                <View style={{ margin: '20px 0' }}>
                    {(this.state.filter && this.state.filter.canViewIDChecks) && (
                        <CheckboxControl text="Include ID Checks" defaultValue={this.state.filter.includeIDChecks} onChanged={this.includeIDChecksChanged} />
                    )}
                </View>

                <MultiSelectControl
                    label={"Venues"}
                    items={this.state.venues}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onVenuesChanged}
                    canShowAll={true} />

                {resetButton}
            </LayoutForm>
        );
    }
}