export const
    EDIT_INCIDENT_SET_STEP = "EDIT_INCIDENT_SET_STEP",
    EDIT_INCIDENT_LOAD_REQUEST = "EDIT_INCIDENT_LOAD_REQUEST",
    EDIT_INCIDENT_LOAD_REQUEST_SUCCESS = "EDIT_INCIDENT_LOAD_REQUEST_SUCCESS",
    EDIT_INCIDENT_LOAD_REQUEST_FAILURE = "EDIT_INCIDENT_LOAD_REQUEST_FAILURE",
    EDIT_INCIDENT_CLEAR = "EDIT_INCIDENT_CLEAR",
    EDIT_INCIDENT_UPDATE = "EDIT_INCIDENT_UPDATE",
    EDIT_INCIDENT_CLEAR_ERROR = "EDIT_INCIDENT_CLEAR_ERROR",
    EDIT_INCIDENT_SAVE_REQUEST = "EDIT_INCIDENT_SAVE_REQUEST",
    EDIT_INCIDENT_SAVE_REQUEST_SUCCESS = "EDIT_INCIDENT_SAVE_REQUEST_SUCCESS",
    EDIT_INCIDENT_SAVE_REQUEST_FAILURE = "EDIT_INCIDENT_SAVE_REQUEST_FAILURE",
    EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST = "EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST",
    EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS = "EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS",
    EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE = "EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE",
    EDIT_INCIDENT_PATRON_UPLOAD_REQUEST = "EDIT_INCIDENT_PATRON_UPLOAD_REQUEST",
    EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS = "EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS",
    EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE = "EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE",
    EDIT_INCIDENT_UPLOAD_REQUEST = "EDIT_INCIDENT_UPLOAD_REQUEST",
    EDIT_INCIDENT_UPLOAD_REQUEST_SUCCESS = "EDIT_INCIDENT_UPLOAD_REQUEST_SUCCESS",
    EDIT_INCIDENT_UPLOAD_REQUEST_FAILURE = "EDIT_INCIDENT_UPLOAD_REQUEST_FAILURE",
    EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST = "EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST",
    EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS = "EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS",
    EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE = "EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE",
    EDIT_INCIDENT_CHECKLISTS_REQUEST = "EDIT_INCIDENT_CHECKLISTS_REQUEST",
    EDIT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS = "EDIT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS",
    EDIT_INCIDENT_CHECKLISTS_REQUEST_FAILURE = "EDIT_INCIDENT_CHECKLISTS_REQUEST_FAILURE";

export function editIncidentSetStep(step) {
    return {
        type: EDIT_INCIDENT_SET_STEP,
        step
    };
}

export function editIncidentLoad(incidentId) {
    return {
        type: EDIT_INCIDENT_LOAD_REQUEST,
        incidentId
    };
}

export function editIncidentLoadSuccess(incident, incidentOptions) {
    return {
        type: EDIT_INCIDENT_LOAD_REQUEST_SUCCESS,
        incident,
        incidentOptions
    };
}

export function editIncidentLoadFailure(error) {
    return {
        type: EDIT_INCIDENT_LOAD_REQUEST_FAILURE,
        error
    };
}

export function editIncidentClear() {
    return {
        type: EDIT_INCIDENT_CLEAR
    };
}

export function editIncidentUpdate(incident) {
    return {
        type: EDIT_INCIDENT_UPDATE,
        incident
    };
}

export function editIncidentSave(isDraft, approve) {
    return {
        type: EDIT_INCIDENT_SAVE_REQUEST,
        isDraft,
        approve
    };
}

export function editIncidentSaveSuccess(incident) {
    return {
        type: EDIT_INCIDENT_SAVE_REQUEST_SUCCESS,
        incident
    };
}

export function editIncidentSaveFailure(error) {
    return {
        type: EDIT_INCIDENT_SAVE_REQUEST_FAILURE,
        error
    };
}

export function editIncidentClearError() {
    return {
        type: EDIT_INCIDENT_CLEAR_ERROR
    }
}

export function editIncidentCheckReportingPeriodDate(incidentDate) {
    return {
        type: EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST,
        incidentDate
    };
}

export function editIncidentCheckReportingPeriodDateSuccess(isCurrentReportingPeriod) {
    return {
        type: EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS,
        isCurrentReportingPeriod
    };
}

export function editIncidentCheckReportingPeriodDateFailure(error) {
    return {
        type: EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE,
        error
    };
}

export function editIncidentUploadRequest(files) {
    return {
        type: EDIT_INCIDENT_UPLOAD_REQUEST,
        files
    }
}

export function editIncidentUploadRequestSuccess(temporaryFiles) {
    return {
        type: EDIT_INCIDENT_UPLOAD_REQUEST_SUCCESS,
        temporaryFiles
    }
}

export function editIncidentUploadRequestFailure(error) {
    return {
        type: EDIT_INCIDENT_UPLOAD_REQUEST_FAILURE,
        error
    }
}

export function editIncidentPatronUploadRequest(patronIndex, files) {
    return {
        type: EDIT_INCIDENT_PATRON_UPLOAD_REQUEST,
        patronIndex,
        files
    }
}

export function editIncidentPatronUploadRequestSuccess(patronIndex, temporaryFiles) {
    return {
        type: EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS,
        patronIndex,
        temporaryFiles
    }
}

export function editIncidentPatronUploadRequestFailure(error) {
    return {
        type: EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE,
        error
    }
}

export function editIncidentChecklistItemUploadRequest(files, checklistTemplateItemId) {
    return {
        type: EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST,
        files,
        checklistTemplateItemId
    }
  }
  
  export function editIncidentChecklistItemUploadRequestSuccess(temporaryFiles, checklistTemplateItemId) {
    return {
        type: EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
        temporaryFiles,
        checklistTemplateItemId
    }
  }
  
  export function editIncidentChecklistItemUploadRequestFailure(error) {
    return {
        type: EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE,
        error
    }
  }

  export function editIncidentChecklistsRequest() {
      return {
          type: EDIT_INCIDENT_CHECKLISTS_REQUEST
      }
    }
    
    export function editIncidentChecklistsRequestSuccess(incident) {
      return {
          type: EDIT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS,
          incident
      }
    }
    
    export function editIncidentChecklistsRequestFailure(error) {
      return {
          type: EDIT_INCIDENT_CHECKLISTS_REQUEST_FAILURE,
          error
      }
    }