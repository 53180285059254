import { connect } from 'react-redux'
import LeaveRequest from '../components/roster/LeaveRequest'
import * as rosterActions from '../common/actions/roster'

const mapStateToProps = (state, props) => {
    return {
        open: props.open,
        onCancel: props.onCancel,
        leaveRequest: state.rosters.leaveRequest.leaveRequest,
        isLoading: state.rosters.leaveRequest.isLoading,
        isSaving: state.rosters.leaveRequest.isSaving,
        error: state.rosters.leaveRequest.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNew: () => {
            dispatch(rosterActions.rosterNewLeaveRequest())
        },
        onSave: (leaveRequest) => {
            dispatch(rosterActions.rosterSaveLeaveRequest(leaveRequest))
        }, 
        onUpdate: (leaveRequest) => {
            dispatch(rosterActions.rosterSetLeaveRequest(leaveRequest))
        }
    }
}

const RosterLeaveRequestContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaveRequest)

export default RosterLeaveRequestContainer