import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TableRow from '../../common/TableRow';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { venueReportingPeriodScheduleLogic } from '../../../common/logic/venueReportingPeriodScheduleLogic';

export interface IVenueReportingPeriodScheduleRowProps {
    venueReportingPeriodSchedule: AusComplyDtos.VenueReportingPeriodSchedule;
    onCommand?: Function;
}

export default class VenueReportingPeriodScheduleRow extends React.Component<IVenueReportingPeriodScheduleRowProps, any> {
    constructor(props: IVenueReportingPeriodScheduleRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueReportingPeriodSchedule);
        }
    }

    render() {
        if (!this.props.venueReportingPeriodSchedule) {
            return (<></>);
        }

        var commands = venueReportingPeriodScheduleLogic.getCommands(this.props.venueReportingPeriodSchedule);
        var item = this.props.venueReportingPeriodSchedule;

        let codeRequired;
        if (!item.securityCodeNotRequired) {
            codeRequired = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        if (item.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff) {
            return (
                <TableRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <td>
                        <p>
                            {item.name}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startDate.displayLocal}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endDate.displayLocal}
                        </p>
                    </td>
                    <td>
                        {codeRequired}
                    </td>
                </TableRow>
            );
        } else if (item.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday) {
            return (
                <TableRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <td>
                        <p>
                            {item.name}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startMonthDisplay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startDay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endMonthDisplay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endDay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startDisplayZ}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endDisplayZ}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.expiresDate.displayLocalDate}
                        </p>
                    </td>
                    <td>
                        {codeRequired}
                    </td>
                </TableRow>
            );
        } else {
            return (
                <TableRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <td>
                        <p>
                            {item.name}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startWeekDayDisplay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.startDisplayZ}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endWeekDayDisplay}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.endDisplayZ}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.expiresDate.displayLocalDate}
                        </p>
                    </td>
                    <td>
                        {codeRequired}
                    </td>
                </TableRow>
            );
        }
    }
}