import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, VenueLocation } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../../controls/MutiSelectControl';
import FormWarningMessage from '../../alerts/FormWarningMessage'
import FormErrorMessage from '../../alerts/FormErrorMessage'
import { MulitSelectOption } from '../../../common/dto/common';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Map from '../../common/Map'
import Loading from '../../common/Loading';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import DefaultButton from '../../controls/DefaultButton';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';

export interface ICashTraxLocationProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onUpdateLocation: Function;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    theme: any;
    classes: any;
}

interface ICashTraxLocationState {
    selected: number[];
    items: MulitSelectOption[];
    subLocationItems: MulitSelectOption[];
    showLocation: boolean;
}

class CashTraxLocation extends Component<ICashTraxLocationProps, ICashTraxLocationState> {
    constructor(props: ICashTraxLocationProps) {
        super(props)
        this.state = {
            selected: [],
            items: [],
            subLocationItems: [],
            showLocation: false
        }
        this.onLoad = this.onLoad.bind(this);
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.toggleGPSLocation = this.toggleGPSLocation.bind(this);
        this.onMapRefresh = this.onMapRefresh.bind(this);
        this.onSelectedChanged = this.onSelectedChanged.bind(this);
        this.onSelectedSubLocationsChanged = this.onSelectedSubLocationsChanged.bind(this);
        this.onSelectSubItemsChanged = this.onSelectSubItemsChanged.bind(this);
        this.onLoad()
    }

    componentDidMount() {
        this.onLoad()
    }

    componentDidUpdate(prevProps: Readonly<ICashTraxLocationProps>, prevState: Readonly<ICashTraxLocationState>, snapshot?: any): void {
        if (JSON.stringify(this.props.incident.incidentLocation) !== JSON.stringify(prevProps.incident.incidentLocation)
            || JSON.stringify(this.props.incident.selectedSubLocations) !== JSON.stringify(prevProps.incident.selectedSubLocations)) {
            this.onLoad();
        }
    }

    onLoad() {
        try {
            let items: MulitSelectOption[] = [];
            let venueLocationsSelected: number[] = [];

            let subLocationsSelected: number[] = [];
            let subLocationItems: MulitSelectOption[] = [];
            let availableIds: number[] = [];

            // selected venue locations
            if (this.props.incident && this.props.incident.incidentLocation) {
                venueLocationsSelected = this.props.incident.incidentLocation.map(item => item.venueLocationId);
            }
            // selected sub locations
            if (this.props.incident && this.props.incident.selectedSubLocations) {
                subLocationsSelected = this.props.incident.selectedSubLocations.map(item => item);
            }

            if (this.props.incidentOptions && this.props.incidentOptions.venueLocations) {
                this.props.incidentOptions.venueLocations.forEach((item, index) => {
                    let isSelected = venueLocationsSelected.indexOf(item.venueLocationId) > -1;
                    items.push({
                        id: item.venueLocationId,
                        name: item.name,
                        isCommon: (item.usageCount > 0),
                        selected: isSelected
                    });
                    if (isSelected) {
                        item.subLocations.filter(f => item.selectedSubLocations.indexOf(f.subLocationId) > -1 && availableIds.indexOf(f.subLocationId) === -1)
                            .forEach(subLocation => {
                                let isSubLocationSelected = subLocationsSelected.indexOf(subLocation.subLocationId) > -1;
                                subLocationItems.push({
                                    id: subLocation.subLocationId,
                                    name: subLocation.name,
                                    isCommon: (subLocation.usageCount > 0),
                                    selected: isSubLocationSelected
                                });
                                availableIds.push(subLocation.subLocationId);
                            });
                    }
                });
            }




            subLocationItems = subLocationItems.sort((a, b) => {
                return ('' + a.name).localeCompare(b.name);
            });

            this.setState({
                selected: venueLocationsSelected,
                items,
                subLocationItems
            });
        } catch (err) {
            commonLogic.trackException(err, "CashTraxLocation.componentDidMount", {})
        }
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousVenueLocationIds = this.props.incident.incidentLocation.map(item => item.venueLocationId);
        let currentVenueLocationIds = items.map(item => item.id);

        // remove
        let newVenueLocations = this.props.incident.incidentLocation.filter(item => currentVenueLocationIds.indexOf(item.venueLocationId) > -1);

        // add
        items.filter(item => previousVenueLocationIds.indexOf(item.id) === -1).forEach(item => {
            newVenueLocations.push({
                incidentLocationId: 0,
                incidentId: 0,
                venueLocationId: item.id,
                venueLocation: new VenueLocation()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentLocation = newVenueLocations;
        this.props.onUpdateIncident(newIncident)
    }

    onSelectedChanged(ids: number[]) {
        // get the old and new incidentCategoryIds
        let previousVenueLocationIds = this.props.incident.incidentLocation.map(item => item.venueLocationId);

        // remove
        let newVenueLocations = this.props.incident.incidentLocation.filter(item => ids.indexOf(item.venueLocationId) > -1);

        // add
        ids.filter(id => previousVenueLocationIds.indexOf(id) === -1).forEach(id => {
            newVenueLocations.push({
                incidentLocationId: 0,
                incidentId: 0,
                venueLocationId: id,
                venueLocation: new VenueLocation()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentLocation = newVenueLocations;
        this.props.onUpdateIncident(newIncident)
    }

    onSelectSubItemsChanged(items: MulitSelectOption[]) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedSubLocations = items.map(item => item.id);
        this.props.onUpdateIncident(newIncident);
    }

    onSelectedSubLocationsChanged(values: number[]) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedSubLocations = values;
        this.props.onUpdateIncident(newIncident);
    }

    toggleGPSLocation() {
        this.setState({
            showLocation: !this.state.showLocation
        });
    }

    onMapRefresh() {
        this.props.onUpdateLocation();
    }

    render() {
        const { classes } = this.props;

        let locationDetails;
        if (this.props.isLocationLoading) {
            locationDetails = (
                <Loading />
            );
        } else if (this.props.locationError) {
            locationDetails = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormErrorMessage error={this.props.locationError || "error"}></FormErrorMessage>
                    </Grid>
                </Grid>

            );
        } else if (!this.props.locationFound) {
            locationDetails = (
                <Grid container spacing={1}>
                    <Grid item xs={12}><FormWarningMessage warning={"We could not determine your location. Please ensure location services are enabled on your device and try again or speak to your supervisor."}></FormWarningMessage>
                    </Grid>
                </Grid>

            );
        }
        else {
            locationDetails = (
                <Map latitude={this.props.incident.latitude} longitude={this.props.incident.longitude} />
            );
        }

        
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>

                <MultiSelectControl
                    wrapControlInCard={true}
                    cardTitle={"Locations"}
                    label={""}
                    items={this.state.items}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onSelectItemsChanged}
                    canShowAll={true} />

                <MultiSelectControl
                    wrapControlInCard={true}
                    cardTitle={"Sub Locations"}
                    hideIfNoOptions={true}
                    label={""}
                    items={this.state.subLocationItems}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onSelectSubItemsChanged}
                    canShowAll={true} />

                

                {false && (
                    <CardContainer
                        title={"GPS"}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {!this.state.showLocation && (
                                                <TransparentButton text={"Show GPS Location"} onClick={this.toggleGPSLocation} />

                                            )}
                                            {this.state.showLocation && (
                                                <>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <TransparentButton text={"Hide GPS Location"} onClick={this.toggleGPSLocation} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DefaultButton text={"Refresh Location"} onClick={this.onMapRefresh} />
                                                        </Grid>
                                                    </Grid>
                                                    {locationDetails}
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>

                )}
            </SwipeContainer>
        );

        /*
        <CardContainer
                    title={"Location"}
                    style={{ paddingTop: 0 }}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <MultiSelectControl
                                    label={""}
                                    items={this.state.items}
                                    searchText="Type at least three letters..."
                                    minimumCharactersRequired={3}
                                    onSelectItemsChanged={this.onSelectItemsChanged}
                                    canShowAll={true} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
        */
    }
}

export default withStyles(styles, { withTheme: true })(CashTraxLocation);