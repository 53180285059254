import React, { Component } from 'react';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewRelatedProps {
    incident: IncidentDTO;
    canView?: boolean;
    history: any;
    location: any;
    classes: any;
    theme: any;
}

class IncidentViewRelated extends Component<IIncidentViewRelatedProps, any> {
    constructor(props: IIncidentViewRelatedProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(incidentId: number){
        this.props.history.push('/incidentview/' + incidentId);
    }

    render() {
        if (!this.props.incident.relatedIncidents || this.props.incident.relatedIncidents.length === 0) {
            return (<></>);
        }
        let incidents;
        if (this.props.canView) {
            incidents = this.props.incident.relatedIncidents.map((item, index) => <span  key={index}>{index > 0 && (<br />)}<a href="#" onClick={e=> {this.onClick(item.incidentId); e.preventDefault(); } } style={{ color: '#03a9f4', textDecoration: 'underline'}}>{item.details}</a></span>);
        } else {
            incidents = this.props.incident.relatedIncidents.map((item, index) => <span key={index}>{index > 0 && (<br />)}{item.details}</span>);
        }
        return (
            <ControlGroup text="Related Incidents">
                <TextDisplay>{incidents}</TextDisplay>
            </ControlGroup>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(IncidentViewRelated));