import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { securityFirmContactLogic } from '../../../common/logic/securityFirmContactLogic';

export interface ISecurityFirmContactRowProps {
    securityFirmContact: AusComplyDtos.SecurityFirmContact;
    canEdit: boolean;
    onCommand?: Function;
}

export default class SecurityFirmContactRow extends React.Component<ISecurityFirmContactRowProps, any> {
    constructor(props: ISecurityFirmContactRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.securityFirmContact);
        }
    }

    render() {
        if (!this.props.securityFirmContact) {
            return (<></>);
        }

        var commands = securityFirmContactLogic.getCommands(this.props.securityFirmContact, this.props.canEdit);
        var item = this.props.securityFirmContact;

        return (
            <TableRow isSuccess={item.primaryContact} isWarning={item.secondaryContact} isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.securityFirmContactType && item.securityFirmContactType.name}
                    </p>
                </td>
                <td>
                    <p>
                        {item.firstname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.lastname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td>
                    <p>
                        {item.email}
                    </p>
                </td>
            </TableRow>
        );
    }
}