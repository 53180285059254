import { connect } from 'react-redux'
import NewCheckListSelectTemplate from '../components/checklists/checklist/NewCheckListSelectTemplate'
import { withRouter } from "react-router";
import { setChecklistTemplatesPage, loadChecklistTemplatesRequest, loadChecklistTemplateCategoriesRequest } from '../common/actions/checklistTemplate';
import { loadNewChecklistRequest, createChecklistRequest } from '../common/actions/checklist';

const mapStateToProps = (state, props) => {
    return {
        open: props.open,
        onClose: props.onClose,
        isLoading: state.checklistTemplate.checklistTemplatesLoading,
        checklistTemplates: state.checklistTemplate.checklistTemplates,
        paging: state.checklistTemplate.checklistTemplatesPaging,
        checklistCategories: state.checklistTemplate.checklistTemplateCategories,
        checklistCategoriesLoading: state.checklistTemplate.checklistTemplateCategoryLoading,
        pagesize: state.checklistTemplate.checklistTemplatesPageSize,
        page: state.checklistTemplate.checklistTemplatesPage,
        search: state.checklistTemplate.checklistTemplateSearch && state.checklistTemplate.checklistTemplateSearch.searchText
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetChecklistTemplatesPage: (page) => {
            dispatch(setChecklistTemplatesPage(page))
        },
        onLoadCheckListTemplates: (searchText) => {
            dispatch(loadChecklistTemplatesRequest(searchText, [], [], [], [], []))
        },
        onLoadCheckListCategories: () => {
            dispatch(loadChecklistTemplateCategoriesRequest())
        },
        onNewCheckList: (checklistTemplateId) => {
            dispatch(createChecklistRequest(checklistTemplateId))
        }
    }
}

const NewCheckListSelectTemplateContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCheckListSelectTemplate))

export default NewCheckListSelectTemplateContainer