import React, { Component, forwardRef } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from "../../controls/ControlGroup";
import CheckboxControl from "../../controls/CheckboxControl";
import LayoutForm from '../../layout/LayoutForm';
import ButtonLink from '../../controls/ButtonLink';
import { Link } from 'react-router-dom';
import Media from "react-media";

export interface IRegisterUserAgreeProps {
    user: AusComplyDTOs.User;
    errors: any[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}
const BackLink = forwardRef((props, ref) => <Link to="/register/user/terms" {...props} />);
const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/register/user/privacypolicy" {...props} />);

export default class RegisterUserAgree extends Component<IRegisterUserAgreeProps, any> {
    constructor(props: IRegisterUserAgreeProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error){
                return error.error;
            }
        }
        return "";
    }

    onChange(value) {
        let user = { ...this.props.user };
        user.hasAcceptedTerms = value;
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <LayoutForm>
                    <ControlGroup text={" "}>
                        <CheckboxControl text={"I agree to the"}
                            link={BackLink}
                            linkText={"Terms and Conditions"}
                            defaultValue={this.props.user.hasAcceptedTerms}
                            onChanged={this.onChange} />
                        <Media query={{ maxWidth: 600 }}>
                            {matches =>
                                matches ? (
                                    <>
                                        <ButtonLink text="Privacy Policy" small={false} component={PrivacyPolicyLink} />
                                    </>
                                ) : (
                                        <>
                                            <ButtonLink text="|" small={false} style={{ textAlight: 'left' }} />
                                            <ButtonLink text="Privacy Policy" small={false} component={PrivacyPolicyLink} style={{ textAlight: 'left' }} />
                                        </>
                                    )
                            }
                        </Media>
                    </ControlGroup>
                </LayoutForm>
            </SwipeContainer>
        );
    }
}