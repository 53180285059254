import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_SERVERS_REQUEST = "FACIAL_RECOGNITION_SERVERS_REQUEST",
    FACIAL_RECOGNITION_SERVERS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVERS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVERS_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVERS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST = "FACIAL_RECOGNITION_SERVERS_TEST_REQUEST",
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_FAILURE",
    FACIAL_RECOGNITION_SERVER_RESET = "FACIAL_RECOGNITION_SERVER_RESET",
    FACIAL_RECOGNITION_SERVER_SET = "FACIAL_RECOGNITION_SERVER_SET",
    FACIAL_RECOGNITION_SERVER_REQUEST = "FACIAL_RECOGNITION_SERVER_REQUEST",
    FACIAL_RECOGNITION_SERVER_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVER_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVER_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVER_REQUEST_FAILURE",
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST = "FACIAL_RECOGNITION_SERVER_CREATE_REQUEST",
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST = "FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST",
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_SERVER_CLONE_REQUEST = "FACIAL_RECOGNITION_SERVER_CLONE_REQUEST",
    FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_FAILURE = "FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_FAILURE"
  
    ;

export const facialRecognitionServerCloneRequest = makeActionCreator(FACIAL_RECOGNITION_SERVER_CLONE_REQUEST, 'facialRecognitionServerId', 'toVenueId');
export const facialRecognitionServerCloneRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_SUCCESS);
export const facialRecognitionServerCloneRequestFailure = makeActionCreator(FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_FAILURE, 'error');
    

export function facialRecognitionServersRequest(forVenueId) {
    return {
        type: FACIAL_RECOGNITION_SERVERS_REQUEST,
        forVenueId
    };
}

export function facialRecognitionServersRequestSuccess(facialRecognitionServers) {
    return {
        type: FACIAL_RECOGNITION_SERVERS_REQUEST_SUCCESS,
        facialRecognitionServers
    };
}

export function facialRecognitionServersRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_SERVERS_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionServersTestRequest(facialRecognitionServerId) {
    return {
        type: FACIAL_RECOGNITION_SERVERS_TEST_REQUEST,
        facialRecognitionServerId
    };
}

export function facialRecognitionServersTestRequestSuccess() {
    return {
        type: FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS
    };
}

export function facialRecognitionServersTestRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionServerReset() {
    return {
        type: FACIAL_RECOGNITION_SERVER_RESET
    };
}

export function facialRecognitionServerSet(facialRecognitionServer) {
    return {
        type: FACIAL_RECOGNITION_SERVER_SET,
        facialRecognitionServer
    };
}

export function facialRecognitionServerRequest(facialRecognitionServerId) {
    return {
        type: FACIAL_RECOGNITION_SERVER_REQUEST,
        facialRecognitionServerId
    };
}

export function facialRecognitionServerRequestSuccess(facialRecognitionServer) {
    return {
        type: FACIAL_RECOGNITION_SERVER_REQUEST_SUCCESS,
        facialRecognitionServer
    };
}

export function facialRecognitionServerRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_SERVER_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionServerCreateRequest(forVenueId) {
    return {
        type: FACIAL_RECOGNITION_SERVER_CREATE_REQUEST,
        forVenueId
    };
}

export function facialRecognitionServerCreateRequestSuccess(facialRecognitionServer) {
    return {
        type: FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS,
        facialRecognitionServer
    };
}

export function facialRecognitionServerCreateRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionServerUpsertRequest() {
    return {
        type: FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST
    };
}

export function facialRecognitionServerUpsertRequestSuccess(facialRecognitionServer) {
    return {
        type: FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS,
        facialRecognitionServer
    };
}

export function facialRecognitionServerUpsertRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_FAILURE,
        error
    };
}