import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../../common/CardTypography';
import CardTitle from '../../../common/CardTitle';
import CardInformation from '../../../common/CardInformation';
import CardLabel from '../../../common/CardLabel';

import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";

export interface IUserActionAuditsCardProps {
    userActionAudit: AusComplyDtos.UserActionAudit;
    onClick: Function;
    classes: any;
    theme: any;
}

class UserActionAuditsCard extends React.Component<IUserActionAuditsCardProps, any> {
    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        return (
            <Card style={{ marginTop: '3px', marginBottom: '6px' }} className={className} onClick={() => this.props.onClick(this.props.userActionAudit)}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <CardTypography>{this.props.userActionAudit.dateEnteredDisplay}</CardTypography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CardTypography style={{textAlign: 'right'}}>{this.props.userActionAudit.actionType}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <CardTypography style={{color: '#aaa'}}>{this.props.userActionAudit.display}</CardTypography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {this.props.userActionAudit.locationFound && (
                                <CardTypography style={{textAlign: 'right', color: '#aaa'}}>({this.props.userActionAudit.latitude},{this.props.userActionAudit.longitude} )</CardTypography>
                            )}
                        </Grid>
                    </Grid>
                    {(this.props.userActionAudit.venue || this.props.userActionAudit.securityFirm) && (
                        <Grid container spacing={1}>
                            {this.props.userActionAudit.venue && (
                                <Grid item xs={6} sm={6}>
                                    <CardTitle>Venue</CardTitle>
                                    <CardTypography>{this.props.userActionAudit.venue}</CardTypography>
                                </Grid>
                            )}
                            {this.props.userActionAudit.securityFirm && (
                                <Grid item xs={6} sm={6}>
                                    <CardTitle>Security</CardTitle>
                                    <CardTypography>{this.props.userActionAudit.securityFirm}</CardTypography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserActionAuditsCard);