import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { venueReportingPeriodScheduleLogic } from '../../../common/logic/venueReportingPeriodScheduleLogic';

export interface IVenueReportingPeriodScheduleCardProps {
    venueReportingPeriodSchedule: AusComplyDtos.VenueReportingPeriodSchedule;
    onCommand?: Function;
}

export default class VenueReportingPeriodScheduleCard extends React.Component<IVenueReportingPeriodScheduleCardProps, any> {
    constructor(props: IVenueReportingPeriodScheduleCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.onToggleSecurityCode = this.onToggleSecurityCode.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueReportingPeriodSchedule);
        }
    }

    onToggleSecurityCode() {
        this.setState({ showSecurityCode: !this.state.showSecurityCode });
    }

    render() {
        if (!this.props.venueReportingPeriodSchedule) {
            return (<></>);
        }

        var commands = venueReportingPeriodScheduleLogic.getCommands(this.props.venueReportingPeriodSchedule);
        var item = this.props.venueReportingPeriodSchedule;

        let codeRequired;
        if (!item.securityCodeNotRequired) {
            codeRequired = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        if (item.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff) {
            return (
                <CardRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <Grid container spacing={1}>
                        <Grid item sm={5} xs={12}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography>{item.name}</CardTypography>
                        </Grid>
                        <Grid item sm={3} xs={4}>
                            <CardTitle>Start</CardTitle>
                            <CardTypography>{item.startDate.displayLocal}</CardTypography>
                        </Grid>
                        <Grid item sm={3} xs={4}>
                            <CardTitle>End</CardTitle>
                            <CardTypography>{item.endDate.displayLocal}</CardTypography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <CardTitle>Code</CardTitle>
                            {codeRequired}
                        </Grid>
                    </Grid>
                </CardRow>
            );
        } else if (item.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday) {
            let expires;
            if (!item.expiresDate.isEmpty && (item.name && item.name.length > 0)) {
                expires = (
                    <>
                        <Grid item sm={3} xs={8}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography>{item.name}</CardTypography>
                        </Grid>
                        <Grid item sm={2} xs={4}>
                            <CardTitle>Expires</CardTitle>
                            <CardTypography>{item.expiresDate.displayLocalDate}</CardTypography>
                        </Grid>
                    </>
                );
            } else if (!item.expiresDate.isEmpty) {
                expires = (
                    <>
                        <Grid item sm={5} xs={12}>
                            <CardTitle>Expires</CardTitle>
                            <CardTypography>{item.expiresDate.displayLocalDate}</CardTypography>
                        </Grid>
                    </>
                );
            } else if (item.name && item.name.length > 0) {
                expires = (
                    <>
                        <Grid item sm={5} xs={12}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography>{item.name}</CardTypography>
                        </Grid>
                    </>
                );
            }
            return (
                <CardRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <Grid container spacing={1}>
                        {expires}
                        <Grid item sm={3} xs={5}>
                            <CardTitle>Start</CardTitle>
                            <CardTypography>{item.startMonthDisplay} {item.startDay} {item.startDisplay}</CardTypography>
                        </Grid>
                        <Grid item sm={3} xs={5}>
                            <CardTitle>End</CardTitle>
                            <CardTypography>{item.endMonthDisplay} {item.endDay} {item.endDisplay}</CardTypography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <CardTitle>Code</CardTitle>
                            {codeRequired}
                        </Grid>
                    </Grid>
                </CardRow>
            );
        } else {
            let expires;
            if (!item.expiresDate.isEmpty && (item.name && item.name.length > 0)) {
                expires = (
                    <>
                        <Grid item sm={2} xs={8}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography>{item.name}</CardTypography>
                        </Grid>
                        <Grid item sm={1} xs={4}>
                            <CardTitle>Expires</CardTitle>
                            <CardTypography>{item.expiresDate.displayLocalDate}</CardTypography>
                        </Grid>
                    </>
                );
            } else if (!item.expiresDate.isEmpty) {
                expires = (
                    <>
                        <Grid item sm={3} xs={12}>
                            <CardTitle>Expires</CardTitle>
                            <CardTypography>{item.expiresDate.displayLocalDate}</CardTypography>
                        </Grid>
                    </>
                );
            } else if (item.name && item.name.length > 0) {
                expires = (
                    <>
                        <Grid item sm={3} xs={12}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography>{item.name}</CardTypography>
                        </Grid>
                    </>
                );
            }
            return (
                <CardRow isDanger={!item.active} commands={commands} onCommand={this.onCommand}>
                    <Grid container spacing={1}>
                        {expires}
                        <Grid item sm={3} xs={4}>
                            <CardTitle>Start</CardTitle>
                            <CardTypography>{item.startWeekDayDisplay} {item.startDisplay}</CardTypography>
                        </Grid>
                        <Grid item sm={3} xs={4}>
                            <CardTitle>End</CardTitle>
                            <CardTypography>{item.endWeekDayDisplay} {item.endDisplay}</CardTypography>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <CardTitle>Code</CardTitle>
                            {codeRequired}
                        </Grid>
                    </Grid>
                </CardRow>
            );
        }
    }
}