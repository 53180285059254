import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect'
import Input from '@material-ui/core/Input';
import { RadioOption } from '../../common/dto/common';
import RadioControl from './RadioControl';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IShiftDurationControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    error?: string;
    hours?: number;
    minutes?: number;
    readonly?: boolean;
    onChanged?: Function;
}

export interface IShiftDurationControlGroupState {
    selected: string;
    hours: number;
    minutes: number;
}

class HoursOption {
    value: number;
    label: string;
}

class ShiftDurationControlGroup extends React.PureComponent<IShiftDurationControlGroupProps, IShiftDurationControlGroupState> {

    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            hours: 8,
            minutes: 0
        };
        this.onChange = this.onChange.bind(this);
        this.onHoursChanged = this.onHoursChanged.bind(this);
        this.onMinutesChanged = this.onMinutesChanged.bind(this);
    }

    componentDidMount() {
        this.loadHoursAndMinutes();
    }

    componentDidUpdate(prevProps: Readonly<IShiftDurationControlGroupProps>, prevState: Readonly<IShiftDurationControlGroupState>, snapshot?: any): void {
        if (prevProps.hours != this.props.hours ||
            prevProps.minutes != this.props.minutes
        ) {
            this.loadHoursAndMinutes();
        }
    }

    loadHoursAndMinutes() {
        let selected = "0";
        let hours = this.props.hours || 0;
        if (!this.props.hours && !this.props.minutes) {
            hours = 8;
        }
        let minutes = this.props.minutes || 0;
        // convert minutes to a 15 minute interval
        if (minutes >= 45 && minutes <= 60) {
            minutes = 45;
        } else if (minutes >= 30 && minutes <= 44) {
            minutes = 30;
        } else if (minutes && minutes) {
            minutes = 15;
        } else {
            minutes = 0;
        }
        if (minutes == 0) {
            if (hours == 8) {
                selected = "8"
            } else if (hours == 6) {
                selected = "6"
            } else if (hours == 4) {
                selected = "4"
            }            
        }
        this.setState({
            selected: selected,
            hours: hours,
            minutes: minutes
        });
    }

    onChange(value) {
        let hours = this.state.hours || 0;
        let minutes = this.state.minutes || 0;
        if (value.value != "0") {
            hours = parseInt(value.value);
            minutes = 0;
        }
        this.setState({
            selected: value.value,
            hours: hours,
            minutes: minutes
        });
        if (this.props.onChanged) {
            this.props.onChanged({ hours: hours, minutes: minutes });
        }
    }

    onHoursChanged(event) {
        let value = parseInt(event.target.value);
        this.setState({
            hours: value
        });
        if (this.props.onChanged) {
            this.props.onChanged({ hours: value, minutes: this.state.minutes });
        }
    }

    onMinutesChanged(event) {
        let value = parseInt(event.target.value);
        this.setState({
            minutes: value
        });
        if (this.props.onChanged) {
            this.props.onChanged({ hours: this.state.hours, minutes: value });
        }
    }

    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;

        let options: RadioOption[] = [
            { id: "4", name: "4h" },
            { id: "6", name: "6h" },
            { id: "8", name: "8h" },
            { id: "0", name: "Other" }
        ];
        let custom = <div></div>;
        if (this.state.selected == "0") {
            let hours: HoursOption[] = [
                { value: 0, label: "0h" },
                { value: 1, label: "1h" },
                { value: 2, label: "2h" },
                { value: 3, label: "3h" },
                { value: 4, label: "4h" },
                { value: 5, label: "5h" },
                { value: 6, label: "6h" },
                { value: 7, label: "7h" },
                { value: 8, label: "8h" },
                { value: 9, label: "9h" },
                { value: 10, label: "10h" },
                { value: 11, label: "11h" },
                { value: 12, label: "12h" },
                { value: 13, label: "13h" },
                { value: 14, label: "14h" },
                { value: 15, label: "15h" },
                { value: 16, label: "16h" },
                { value: 17, label: "17h" },
                { value: 18, label: "18h" },
                { value: 19, label: "19h" },
                { value: 20, label: "20h" },
                { value: 21, label: "21h" },
                { value: 22, label: "22h" },
                { value: 23, label: "23h" },
                { value: 24, label: "24h" }
            ];
            let minutes: HoursOption[] = [
                { value: 0, label: "0m" },
                { value: 15, label: "15m" },
                { value: 30, label: "30m" },
                { value: 45, label: "45m" }
            ];
            custom = (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
                            <NativeSelect
                                value={this.state.hours}
                                onChange={this.onHoursChanged}
                                name="hours"
                                classes={{ icon: classes.backgroundColorDefault }}
                                input={<Input style={style} classes={{ root: classes.inputControl, input: classes.inputRoot }} />}
                            >
                                {hours.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
                            <NativeSelect
                                value={this.state.minutes}
                                onChange={this.onMinutesChanged}
                                name="minutes"
                                classes={{ icon: classes.backgroundColorDefault }}
                                input={<Input style={style} classes={{ root: classes.inputControl, input: classes.inputRoot }} />}
                            >
                                {minutes.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            );
        }

        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} errorText={this.props.error}>
                <RadioControl selected={this.state.selected} options={options} name={"shiftDuration"} onChange={(val) => this.onChange(val)} />
                {custom}
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ShiftDurationControlGroup);