import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IIncidentTypeCardProps {
    incidentType: AusComplyDtos.IncidentType;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IncidentTypeCard extends React.Component<IIncidentTypeCardProps, any> {
    constructor(props: IIncidentTypeCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.incidentType);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let incidentType = { ...this.props.incidentType };
                    incidentType.obsolete = true;
                    this.props.onUpdate(incidentType);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let incidentType = { ...this.props.incidentType };
                    incidentType.obsolete = false;
                    this.props.onUpdate(incidentType);
                }
                break;
        }
    }

    render() {
        if (!this.props.incidentType) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.incidentType.obsolete && this.props.onUpdate) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        let legislatedTick;
        if (this.props.incidentType.legislated) {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        var categories = this.props.incidentType.industryCategoriesDisplay;
        if (categories.length > 100) {
            categories = categories.substring(0, 100) + "... (" + this.props.incidentType.industryCategorySelections.filter(f => f.selected).length.toString() + ")";
        }
        
        var types = this.props.incidentType.incidentCategoryTypesDisplay;
        if (types.length > 100) {
            types = types.substring(0, 100) + "... (" + this.props.incidentType.incidentCategoryTypeSelections.filter(f => f.selected).length.toString() + ")";
        }

        return (
            <CardRow isDanger={this.props.incidentType.obsolete} isWarning={this.props.incidentType.apiOnlyCreation} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{this.props.incidentType.name}</CardTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <CardTitle>Legislated</CardTitle>
                        {legislatedTick}
                    </Grid>
                    <Grid item xs={2}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.incidentType.rank}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={10}>
                        <CardTitle>Industries</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{categories}</CardTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <CardTitle>Chart Colour</CardTitle>
                        <div style={{ borderRadius: '3px', border: '1px solid #444', margin: '0', width: '20px', height: '20px', backgroundColor: this.props.incidentType.color ? this.props.incidentType.color : 'transparent'}}>
                        
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={12}>
                        <CardTitle>Incident Types</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{types}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}