import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import IncidentFlagTypesFilterSummary from './IncidentFlagTypesFilterSummary';
import IncidentFlagTypesFilter from './IncidentFlagTypesFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import IncidentFlagTypeCard from './IncidentFlagTypeCard';
import IncidentFlagTypeHeader from './IncidentFlagTypeHeader';
import IncidentFlagTypeRow from './IncidentFlagTypeRow';
import IncidentFlagType from './IncidentFlagType';

export interface IIncidentFlagTypesProps {
    isLoading: boolean;
    isSaving: boolean;
    incidentFlagTypes: AusComplyDtos.IncidentFlagType[];
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentFlagTypeFilter;
    error: string;
    onRefresh: Function;
    onCreateNew: Function;
    onSet: Function;
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IIncidentFlagTypesState {
    filterExpanded: boolean;
}

class IncidentFlagTypes extends Component<IIncidentFlagTypesProps, IIncidentFlagTypesState> {
    constructor(props: IIncidentFlagTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page, this.props.paging.pageSize);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onSearch(filter: AusComplyDtos.IncidentFlagTypeFilter) {
        this.props.onRefresh(filter, this.props.paging.page, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onRefresh(new AusComplyDtos.IncidentFlagTypeFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onEdit(incidentFlagType: AusComplyDtos.IncidentFlagType) {
        this.props.onSet(incidentFlagType);
    }

    onCancelEdit() {
        let incidentFlagType = new AusComplyDtos.IncidentFlagType();
        incidentFlagType.incidentFlagTypeId = -1;
        this.props.onSet(incidentFlagType);
    }

    onUpdate(incidentFlagType: AusComplyDtos.IncidentFlagType) {
        this.props.onUpdate(incidentFlagType);
    }

    onUpdateFilter(filter: AusComplyDtos.IncidentFlagTypeFilter) {

    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.incidentFlagTypes && this.props.incidentFlagTypes.length > 0) {
            cardList = this.props.incidentFlagTypes.map((item, index) => {
                return (
                    <IncidentFlagTypeCard
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"card-" + item.incidentFlagTypeId.toString()}
                        incidentFlagType={item}
                    />
                );
            });

            rows = this.props.incidentFlagTypes.map((item, index) => {
                return (
                    <IncidentFlagTypeRow
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"row-" + item.incidentFlagTypeId.toString()}
                        incidentFlagType={item} />
                );
            });
        }

        let filter;
        let filterSummary;

        if (this.state.filterExpanded) {
            filter = <IncidentFlagTypesFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let incidentFlagTypeFilter = new AusComplyDtos.IncidentFlagTypeFilter();
            incidentFlagTypeFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentFlagTypesFilterSummary filter={incidentFlagTypeFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentFlagTypesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        rowList = (
            <TableContainer
                header={<IncidentFlagTypeHeader />}
                rows={rows}
            />
        );

        let incidentFlagType;
        if (this.props.incidentFlagType && this.props.incidentFlagType.incidentFlagTypeId > -1) {
            incidentFlagType = (
                <IncidentFlagType
                    incidentFlagType={this.props.incidentFlagType}
                    onSave={this.onUpdate}
                    onCancel={() => this.onCancelEdit()}
                    isSaving={this.props.isSaving}
                    error={this.props.error} />
            );
        }

        return (
            <>
                <PageTitle title="Incident Flags"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                    <DefaultFab onClick={() => this.props.onCreateNew()} isExtended={true}>Add Incident Flag</DefaultFab>
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog open={this.props.incidentFlagType.incidentFlagTypeId != -1} title="Incident Flag" onDismissed={() => this.onCancelEdit()}>
                    {incidentFlagType}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentFlagTypes);