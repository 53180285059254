import { connect } from 'react-redux'
import { withRouter } from "react-router";
import IncidentCategoryType from '../components/venue/incidentCategoryType/IncidentCategoryType'
import { 
    venueIncidentCategoryTypeReset,
    venueIncidentCategoryTypeSet,
    venueIncidentCategoryTypeUpsertRequest
 } from '../common/actions/venue';
 import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        incidentCategoryTypeId: state.venue.incidentCategoryType.incidentCategoryTypeId,
        isLoading: state.venue.incidentCategoryType.isLoading,
        isSaving: state.venue.incidentCategoryType.isSaving,
        incidentCategoryType: state.venue.incidentCategoryType.incidentCategoryType,
        canEditNotifications: permissionsLogic.hasPermissionForState(state, "MaintainUserRoleNotification"),
        onClose: props.onClose
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueIncidentCategoryTypeReset())
        },
        onUpdate: (incidentCategoryType) => {
            dispatch(venueIncidentCategoryTypeSet(incidentCategoryType))
        },
        onSave: () => {
            dispatch(venueIncidentCategoryTypeUpsertRequest())
        }
    }
}

const VenueIncidentCategoryTypeContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentCategoryType))

export default VenueIncidentCategoryTypeContainer