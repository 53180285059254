import React from "react";

const UserIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M25,0C11.21,0,0,11.21,0,25c0,13.79,11.21,25,25,25s25-11.21,25-25C50,11.21,38.79,0,25,0z M38.87,41.3l-0.13-0.1c-0.23-0.15-0.49-0.3-0.72-0.44l-6.87-3.42c-0.62-0.32-0.99-0.94-0.99-1.63v-2.4c0.1-0.12,0.32-0.42,0.5-0.65c0.87-1.26,1.59-2.65,2.11-4.16c1.02-0.49,1.69-1.51,1.69-2.66v-2.88c0-0.69-0.27-1.37-0.72-1.91v-3.79c0.87-0.67,0.18-2.8-1.52-4.76c-1.49-1.69-3.92-2.56-7.19-2.56c-2.48-0.28-5.7,0.87-7.19,2.56c-1.71,1.96-1.57,4.34-1.52,4.76v3.79c-0.45,0.54-0.72,1.22-0.72,1.91v2.88c0,0.89,0.4,1.73,1.09,2.28c0.65,2.58,2.01,4.52,2.51,5.18v2.35c0,0.67-0.37,1.27-0.96,1.59l-6.4,3.5c-0.2,0.1-0.4,0.23-0.62,0.39l-0.15,0.12C6.48,37.33,3.57,31.5,3.57,25C3.57,13.19,13.19,3.57,25,3.57S46.43,13.19,46.43,25C46.43,31.52,43.5,37.38,38.87,41.3z" />
        </g>
    </svg>
);

export default UserIcon;
