import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextControl from '../../controls/TextControl';
import TableRow from '../../common/TableRow';
import TransparentButton from '../../controls/TransparentButton';
import { ItemTypes } from './ItemTypes';
import { Palette } from '../../../common/constants/palette';


export interface IChecklistTemplateItemOptionProp {
    index: number;
    option: AusComplyDTOs.ChecklistTemplateItemOption;
    onUpdate: Function;
    onRemove: Function;
}

export default class ChecklistTemplateItemOption extends Component<IChecklistTemplateItemOptionProp, any> {
    constructor(props: IChecklistTemplateItemOptionProp) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let option = { ...this.props.option };
        option[fieldname] = value;
        this.props.onUpdate(option);
    }

    render() {
        if (this.props.option.obsolete) {
            return (
                <TableRow>
                    <td>
                        <p style={{padding: '5px 10px', margin: 0, textDecoration: 'line-through', color: Palette.PrimaryDisabledText }}>{this.props.option.value || ''}</p>
                    </td>
                    <td>
                        <p style={{padding: '10px 10px', margin: 0, textDecoration: 'line-through', color: Palette.PrimaryDisabledText }}>{this.props.option.name || ''}</p>
                    </td>
                    <td style={{ textAlign: 'right' }}>

                    </td>
                </TableRow>
            );
        }

        return (
            <TableRow>
                <td>
                    <TextControl
                        value={this.props.option.value || ''}
                        onChange={value => this.onChange("value", value)}
                        onBlur={value => this.onChange("value", value)} />
                </td>
                <td>
                    <TextControl
                        value={this.props.option.name || ''}
                        onChange={value => this.onChange("name", value)}
                        onBlur={value => this.onChange("name", value)} />
                </td>
                <td style={{ textAlign: 'right' }}>
                    <TransparentButton onClick={() => this.props.onRemove(this.props.index)} style={{ width: 'auto' }} text={"Remove"}></TransparentButton>
                </td>
            </TableRow>
        );
    }
}