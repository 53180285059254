import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import View from '../common/View';

export interface ISmallIconClearButtonProps {
    text?: string;
    icon?: any;
    classes: any;
    theme: any;
    className?: any;
    disabled?: boolean;
    onClick?: Function;
    style?: any;
}

class SmallIconClearButton extends React.PureComponent<ISmallIconClearButtonProps, any> {
    constructor(props: ISmallIconClearButtonProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                className={classNames(classes.buttonClear, classes.buttonState, this.props.className, this.props.disabled ? classes.smallIconClearButtonDisabed : classes.smallIconClearButton)}
                style={{ width: '100%', height: '100%', ...this.props.style }}>
                <View style={{
                    flex: 1,
                    display: 'flex', 
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <View style={{ flex: 1 }}></View>
                    {this.props.icon && (
                        <View style={{ flex: 0 }}>
                            <View style={{ width: '20px', height: '20px' }}>
                                {this.props.icon}
                            </View>
                            <View style={{ padding: '2px' }}></View>
                        </View>
                    )}
                    {this.props.text && (
                        <View style={{ flex: 0 }}>
                            <p className={this.props.disabled ? classes.smallIconClearButtonDisabed : classes.smallIconClearButtonText}>{this.props.text}</p>
                        </View>
                    )}
                    <View style={{ flex: 1 }}></View>
                </View>
            </Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SmallIconClearButton);