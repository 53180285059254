import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import FullPageDialog from '../../layout/FullPageDialog';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import Map from '../../common/Map'
import SelectControlGroup from '../../controls/SelectControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ReportingPeriod from '../reportingPeriod/ReportingPeriod';
import VenueReportingSecurityCode from '../../venue/reportingPeriods/VenueReportingSecurityCode';
import ResponsiveList from '../../common/ResponsiveList';
import { KeyName } from '../../../common/dto/common';

import Geocode from "react-geocode";
import Spacer from '../../common/Spacer';
import SubTitle from '../../common/SubTitle';
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB4D0gfDc7dpa248E0GmMar9U5fctLRL_k");
Geocode.setLanguage("en");
// Enable or disable logs. Its optional.
Geocode.enableDebug();

export interface IVenueEventProps {
    venueEvent: AusComplyDtos.VenueEvent;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
    onReset: Function;
    canEditReportingPeriods: boolean;
}

interface IVenueEventState {
    venueReportingPeriodIndex: number;
}

export default class VenueEvent extends Component<IVenueEventProps, IVenueEventState> {
    constructor(props: IVenueEventProps) {
        super(props)
        this.state = {
            venueReportingPeriodIndex: -1
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onMapRefresh = this.onMapRefresh.bind(this);
        this.onMapDragged = this.onMapDragged.bind(this);
        this.onChangedReportingPeriod = this.onChangedReportingPeriod.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    onMapRefresh() {
        // Get latidude & longitude from address.
        if (this.props.venueEvent &&
            this.props.venueEvent.address && this.props.venueEvent.address !== '' &&
            this.props.venueEvent.suburb && this.props.venueEvent.suburb !== '' &&
            this.props.venueEvent.stateId && this.props.venueEvent.stateId !== 0 &&
            this.props.venueEvent.postcode && this.props.venueEvent.postcode !== '') {

            let stateCode = '';
            if (this.props.venueEvent.states) {
                this.props.venueEvent.states.forEach(state => {
                    if (state.stateId == this.props.venueEvent.stateId) {
                        stateCode = state.name
                    }
                });
            }
            var search = this.props.venueEvent.address + ', ' +
                this.props.venueEvent.suburb + ', ' +
                stateCode + ', ' +
                this.props.venueEvent.postcode;

            let self = this;
            // console.log(search);
            Geocode.fromAddress(search).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    // console.log(lat, lng);
                    self.onMapDragged(lat, lng);
                    ;
                },
                error => {
                    console.error(error);
                }
            );
        }

    }

    onMapDragged(lat, long) {
        let venueEvent = { ...this.props.venueEvent };
        venueEvent.latitude = lat.toFixed(6);
        venueEvent.longitude = long.toFixed(6);
        this.props.onUpdate(venueEvent);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let venueEvent = { ...this.props.venueEvent };
        venueEvent[fieldName] = value;
        this.props.onUpdate(venueEvent)
    }

    onValueChangedReportingPeriod(index: number, fieldName: string, value: any) {
        let venueEvent = { ...this.props.venueEvent };
        venueEvent.venueReportingPeriods[index][fieldName] = value;
        venueEvent.venueReportingPeriods[index].modified = true;
        this.props.onUpdate(venueEvent)
    }

    onChangedReportingPeriod(index: number, venueReportingPeriods: AusComplyDtos.VenueReportingPeriod) {
        let venueEvent = { ...this.props.venueEvent };
        venueReportingPeriods.modified = true;
        venueEvent.venueReportingPeriods = [...venueEvent.venueReportingPeriods];
        venueEvent.venueReportingPeriods[index] = venueReportingPeriods;
        this.props.onUpdate(venueEvent)
    }

    getReportingPeriodCommands(reportingPeriod: AusComplyDtos.VenueReportingPeriod) {
        var commands: KeyName[] = [];
        if (reportingPeriod && !reportingPeriod.isOver) {
            commands.push({ key: "edit", name: "Edit" });
        }
        if (reportingPeriod && reportingPeriod.isCurrent) {
            commands.push({ key: "stop", name: "Stop" });
        }
        if (reportingPeriod && reportingPeriod.isFuture && !reportingPeriod.obsolete) {
            commands.push({ key: "delete", name: "Remove" });
        }
        if (reportingPeriod && reportingPeriod.isFuture && reportingPeriod.obsolete) {
            commands.push({ key: "undodelete", name: "Undo Remove" });
        }
        return commands;
    }

    onReportingPeriodCommand(command: string, index: number) {
        switch (command) {
            case "add":
                let venueEvent = { ...this.props.venueEvent };
                let newVenueReportingPeriod = { ...venueEvent.defaultVenueReportingPeriod };
                newVenueReportingPeriod.modified = true;
                newVenueReportingPeriod.isFuture = true;
                venueEvent.venueReportingPeriods = [newVenueReportingPeriod, ...venueEvent.venueReportingPeriods]
                this.props.onUpdate(venueEvent);
                this.setState({ venueReportingPeriodIndex: 0 });
                break;
            case "edit":
                this.setState({ venueReportingPeriodIndex: index });
                break;
            case "delete":
                if (!this.props.venueEvent.venueReportingPeriods[index].venueReportingPeriodId) {
                    let venueEvent = { ...this.props.venueEvent };
                    let venueReportingPeriods = [...venueEvent.venueReportingPeriods];
                    venueReportingPeriods.splice(index, 1);
                    venueEvent.venueReportingPeriods = venueReportingPeriods;
                    this.props.onUpdate(venueEvent);
                } else {
                    this.onValueChangedReportingPeriod(index, "obsolete", true);
                }
                break;
            case "undodelete":
                this.onValueChangedReportingPeriod(index, "obsolete", false);
                break;
            case "stop":

                break;
            default:
                break;
        }
    }

    render() {

        if (!this.props.venueEvent) {
            return (<></>);
        }

        let enableSetLocation = false;
        if (this.props.venueEvent &&
            this.props.venueEvent.address && this.props.venueEvent.address !== '' &&
            this.props.venueEvent.suburb && this.props.venueEvent.suburb !== '' &&
            this.props.venueEvent.stateId && this.props.venueEvent.stateId !== 0 &&
            this.props.venueEvent.postcode && this.props.venueEvent.postcode !== '') {
            enableSetLocation = true;
        }

        let locationMap;
        let latitude = '';
        let longitude = '';
        if (this.props.venueEvent.latitude && this.props.venueEvent.longitude) {
            latitude = this.props.venueEvent.latitude.toString();
            longitude = this.props.venueEvent.longitude.toString();
            if (!isNaN(this.props.venueEvent.latitude) && !isNaN(this.props.venueEvent.longitude)) {
                locationMap = (
                    <CardContainer
                        title={"Map"}>
                        <CardContent>
                            <LayoutForm>
                                <Map
                                    latitude={Number(this.props.venueEvent.latitude)}
                                    longitude={Number(this.props.venueEvent.longitude)}
                                    draggableMarker={true}
                                    fullscreenControl={true}
                                    zoomControl={true}
                                    onDragged={this.onMapDragged}
                                />
                            </LayoutForm>
                        </CardContent>
                    </CardContainer>
                );
            }
        }

        let venueEvent;
        let reportingPeriods;
        if (this.props.venueEvent) {
            if (this.props.venueEvent.venueReportingPeriods) {
                let addButton;
                if (this.props.canEditReportingPeriods) {
                    addButton = <><DefaultButton text='Add Reporting Period' onClick={() => this.onReportingPeriodCommand("add", 0)} style={{ width: 'auto' }} /></>
                }



                reportingPeriods = <>
                    <SubTitle text="Reporting Periods" />
                    <ResponsiveList
                        data={this.props.venueEvent.venueReportingPeriods}
                        headers={["Start", "End", "Security Code"]}
                        columns={["start", "end", "securityCode"]}
                        getCommands={item => this.getReportingPeriodCommands(item)}
                        onCommand={(command, item, index) => this.onReportingPeriodCommand(command, index)}
                        isObsolete={item => item.obsolete}
                        canEdit={true}
                        isInfo={item => item.isCurrent}
                        widths={[0, 0, 140]}
                        onFormatValue={(column, value, data, row: AusComplyDtos.VenueReportingPeriod) => {
                            if (column == "start") {
                                return row.startTime.displayLocal;
                            }
                            if (column == "end") {
                                return row.endTime.displayLocal;
                            }
                            if (column == "securityCode") {
                                return <VenueReportingSecurityCode venueReportingPeriod={row} />
                            }
                            return value;
                        }}
                    />
                    <div>{addButton}</div>
                </>;

            }

            venueEvent = <>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.props.venueEvent.name}
                    onBlur={(value) => this.onValueChanged("name", value)} />

                <TextAreaControlGroup
                    text={"Description"}
                    defaultValue={this.props.venueEvent.description}
                    onBlur={(value) => this.onValueChanged("description", value)} />


                <CheckboxControlGroup
                    text={"Use Venue Address"}
                    label={" "}
                    defaultValue={this.props.venueEvent.useVenueAddress}
                    onChanged={(value) => this.onValueChanged("useVenueAddress", value)} />

                {!this.props.venueEvent.useVenueAddress && <>
                    <TextControlGroup
                        text={"Address"}
                        defaultValue={this.props.venueEvent.address}
                        onBlur={(value) => this.onValueChanged("address", value)} />
                    <TextControlGroup
                        text={"Suburb"}
                        defaultValue={this.props.venueEvent.suburb}
                        onBlur={(value) => this.onValueChanged("suburb", value)} />
                    <SelectControlGroup
                        text="State"
                        onChange={(value) => this.onValueChanged("stateId", value)}
                        defaultValue={this.props.venueEvent.stateId}>
                        <option value={0} />
                        {this.props.venueEvent.states && this.props.venueEvent.states.map((item, index) => (
                            <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                        ))}
                    </SelectControlGroup>
                    <TextControlGroup
                        text={"Postcode"}
                        defaultValue={this.props.venueEvent.postcode}
                        onBlur={(value) => this.onValueChanged("postcode", value)} />
                    <ControlGroup text={" "} >
                        <DefaultButton disabled={!enableSetLocation} text={"Set location from address"} onClick={this.onMapRefresh} />
                    </ControlGroup>
                    <TextControlGroup
                        text={"Latitude"}
                        defaultValue={latitude}
                        onBlur={(value) => this.onValueChanged("latitude", value)} />
                    <TextControlGroup
                        text={"Longitude"}
                        defaultValue={longitude}
                        onBlur={(value) => this.onValueChanged("longitude", value)} />
                    <Spacer />
                    {locationMap}
                </>}

                {reportingPeriods}

                <CheckboxControlGroup
                    text={"Active"}
                    label={" "}
                    isReversed={true}
                    defaultValue={this.props.venueEvent.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />

            </>;
        }


        let open = false;
        if (this.props.venueEvent) {
            if (this.props.venueEvent.venueEventId && this.props.venueEvent.venueEventId > -1) {
                open = true;
            } else if (this.props.venueEvent.venueEventId == undefined) {
                open = true;
            }
        }

        let reportingPeriod;
        if (this.state.venueReportingPeriodIndex > -1) {
            let venueReportingPeriod = this.props.venueEvent.venueReportingPeriods[this.state.venueReportingPeriodIndex];
            reportingPeriod = <ReportingPeriod
                venueReportingPeriod={venueReportingPeriod}
                onUpdate={value => this.onChangedReportingPeriod(this.state.venueReportingPeriodIndex, value)}
            />;
        }

        return (<FullPageDialog
            open={open}
            title="Event"
            notFullWidth={true}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
            footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
            onDismissed={() => this.props.onReset()}>
            {venueEvent}
            <FullPageDialog
                title="Event Reporting Period"
                open={this.state.venueReportingPeriodIndex > -1}
                onDismissed={() => this.setState({ venueReportingPeriodIndex: -1 })}
            >
                {reportingPeriod}
            </FullPageDialog>
        </FullPageDialog>);
    }
}