
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { signOnServiceApi } from '../services/signOnService';

import * as signOnActions from "../actions/signOn";
import * as userLoginActions from "../actions/userLogin";
import { notificationShow, notificationSuccessShow } from "../actions/notification";
import {
    notifyError, notifyErrorMessage
} from './common';

const startSignOnEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.postSignOnStart(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.location.latitude,
                state.location.longitude,
                state.location.found)
                .pipe(
                    map(response => signOnActions.signOnRequestSuccess(response.userSignOnStart)),
                    catchError(error => notifyError(error, "startSignOnEpic.postSignOnStart", signOnActions.signOnRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "startSignOnEpic", signOnActions.signOnRequestFailure))
    );

const signOnUserCheckEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_CHECK_USER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.postSignOnToRegisterUserCheck(
                state.signOn.userCheck.venueId,
                state.user.details.userSession.user.venueEventId,
                state.signOn.userCheck.userRoleId)
                .pipe(
                    map(response => signOnActions.signOnCheckUserResult(response.userSignOnCheck.isSignedOnToADifferentVenue, response.userSignOnCheck.canSignOnToMultipleVenues)),
                    catchError(error => notifyError(error, "signOnUserCheckEpic.postSignOnToRegisterUserCheck", signOnActions.signOnCheckUserResult))
                )
        ),
        catchError(error => notifyError(error, "signOnUserCheckEpic", signOnActions.signOnCheckUserResult))
    );

const signOnEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.postSignOnToRegister(
                state.signOn.userSignOnStatus,
                state.location.latitude,
                state.location.longitude)
                .pipe(
                    map(response => signOnActions.signOnSaveRequestSuccess()),
                    catchError(error => notifyError(error, "signOnEpic.postSignOnToRegister", signOnActions.signOnSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnEpic", signOnActions.signOnSaveRequestFailure))
    );

const signOnSuccessEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_SAVE_REQUEST_SUCCESS),
        map(action => userLoginActions.userDetailsRefresh())
    );

const signOnRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_SAVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => state.signOn.filter),
        mergeMap(([action, state]) => of(
            signOnActions.signOnRegisterRequest(state.signOn.filter)
        ))
    );

const signOnSuccessMessageEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_SAVE_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Signed on to the register"))
    );

const signOffStatusRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_OFF_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.getUserSignOnRegisterStatusWithLocationAndIncidents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["userSignOnStatusId"],
                state.location.latitude,
                state.location.longitude,
                state.location.found)
                .pipe(
                    map(response => signOnActions.signOffRequestSuccess(
                        response.userSignOnStatus,
                        {
                            incidents: response.incidents,
                            allowOutOfRangeSignIn: response.allowOutOfRangeSignIn,
                            acknowledgmentRequired: response.acknowledgmentRequired,
                            isInRangeOfVenue: response.isInRangeOfVenue,
                            signOutDate: response.incidentsToUtc,
                            signOutDateDisplay: new Date(response.incidentsToUtc).toDateString() + " " + new Date(response.incidentsToUtc).toLocaleTimeString()
                        })),
                    catchError(error => notifyError(error, "signOffStatusRequestEpic.getUserSignOnRegisterStatusWithLocationAndIncidents", signOnActions.signOffRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOffStatusRequestEpic", signOnActions.signOffRequestFailure))
    );

const signOffEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_OFF_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.postSignOffRegister(
                state.signOn.userSignOnStatus.userId,
                state.signOn.userSignOnStatus.userRoleId,
                state.signOn.userSignOnStatus.password,
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.location.latitude,
                state.location.longitude,
                state.signOn.userSignOnStatus.signOnRegisterId,
                action["isAcknowledged"],
                state.signOn.userSignOnStatus.signOutDate)
                .pipe(
                    map(response => signOnActions.signOffSaveRequestSuccess()),
                    catchError(error => notifyError(error, "signOffEpic.postSignOffRegister", signOnActions.signOffSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOffEpic", signOnActions.signOffSaveRequestFailure))
    );

const signOffSuccessEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_OFF_SAVE_REQUEST_SUCCESS),
        map(action => userLoginActions.userDetailsRefresh())
    );

const signOffRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_OFF_SAVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => state.signOn.filter),
        mergeMap(([action, state]) => of(
            signOnActions.signOnRegisterRequest(state.signOn.filter)
        ))
    );

const signOffSuccessMessageEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_OFF_SAVE_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Signed off the register"))
    );

const signOnExtendStatusRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_EXTEND_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.getUserSignOnRegisterStatus(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["userSignOnStatusId"])
                .pipe(
                    map(response => signOnActions.signOnExtendRequestSuccess(response.userSignOnStatus)),
                    catchError(error => notifyError(error, "signOnExtendStatusRequestEpic.getUserSignOnRegisterStatus", signOnActions.signOnExtendRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnExtendStatusRequestEpic", signOnActions.signOnExtendRequestFailure))
    );

const signOnExtendEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.postSignOnRegisterExtend(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.signOn.userSignOnStatus.signOnRegisterId,
                state.signOn.userSignOnStatus.password,
                state.signOn.userSignOnStatus.extendHours,
                state.signOn.userSignOnStatus.extendMinutes)
                .pipe(
                    map(response => signOnActions.signOnExtendSaveRequestSuccess()),
                    catchError(error => notifyError(error, "signOnExtendEpic.postSignOnRegisterExtend", signOnActions.signOnExtendSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnExtendEpic", signOnActions.signOnExtendSaveRequestFailure))
    );

const signOnExtendRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => state.signOn.filter),
        mergeMap(([action, state]) => of(
            signOnActions.signOnRegisterRequest(state.signOn.filter)
        ))
    );

const signOnExtendSuccessEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS),
        map(action => userLoginActions.userDetailsRefresh())
    );

const signOnExtendSuccessMessageEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Sign on extended"))
    );

const signOnRegisterEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_REGISTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.getSignOnRegister(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["filter"]
            )
                .pipe(
                    map(response => signOnActions.signOnRegisterRequestSuccess(response.signOns, response.signOnRegisterFilter.paging, response.signOnRegisterFilter)),
                    catchError(error => notifyError(error, "signOnRegisterEpic.getSignOnRegister", signOnActions.signOnRegisterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnRegisterEpic", signOnActions.signOnRegisterRequestFailure))
    );

const getSignOnRegisterFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_REGISTER_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.getSignOnRegisterFilter(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId, 
                action["reset"])
                .pipe(
                    map(response => signOnActions.signOnRegisterFilterSuccess(response.signOnRegisterFilter)),
                    catchError(error => notifyError(error, "getSignOnRegisterFilterEpic.getSignOnRegisterFilter", signOnActions.signOnRegisterFilterFailure))
                )
        ),
        catchError(error => notifyError(error, "getSignOnRegisterFilterEpic", signOnActions.signOnRegisterFilterFailure))
    );


const getSignOnRegisterFilterSuccessEpic = action$ =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_REGISTER_FILTER_REQUEST_SUCCESS),
        map((action: any) =>
            signOnActions.signOnRegisterRequest(action['signOnRegisterFilter'])
        )
    );



const editSignOnResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_CHANGED),
        map(action => signOnActions.signOnReset())
    );

const signOnBreakRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_BREAK_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.break(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["signOnRegisterId"]
            )
                .pipe(
                    map(response => signOnActions.signOnBreakRequestSuccess(response.signOnRegisterBreak)),
                    catchError(error => notifyError(error, "signOnBreakRequestEpic.breakBegin", signOnActions.signOnBreakRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnBreakBeginRequestEpic", signOnActions.signOnBreakRequestFailure))
    );

const signOnBreakUpsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_BREAK_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            signOnServiceApi.breakUpsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["signOnRegisterBreak"]
            )
                .pipe(
                    map(response => signOnActions.signOnBreakUpsertRequestSuccess(response.userSignOnStatus)),
                    catchError(error => notifyError(error, "signOnBreakUpsertRequestEpic.breakUpsert", signOnActions.signOnBreakUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "signOnBreakUpsertRequestEpic", signOnActions.signOnBreakUpsertRequestFailure))
    );

const signOnBreakUpsertRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(signOnActions.SIGN_ON_BREAK_UPSERT_REQUEST_SUCCESS),
        map((action: any) => userLoginActions.userDetailsRefresh())
    );

export const signOnEpics = combineEpics(
    startSignOnEpic,
    signOnUserCheckEpic,
    signOnEpic,
    signOnSuccessEpic,
    signOnSuccessMessageEpic,
    signOffStatusRequestEpic,
    signOffEpic,
    signOffSuccessEpic,
    signOffSuccessMessageEpic,
    signOnExtendStatusRequestEpic,
    signOnExtendEpic,
    signOnExtendSuccessEpic,
    signOnExtendSuccessMessageEpic,
    signOnRegisterEpic,
    getSignOnRegisterFilterEpic,
    editSignOnResetOnUserChangeEpic,
    getSignOnRegisterFilterSuccessEpic,
    signOnExtendRefreshEpic,
    signOffRefreshEpic,
    signOnRefreshEpic,
    signOnBreakRequestEpic,
    signOnBreakUpsertRequestEpic,
    signOnBreakUpsertRequestSuccessEpic
);