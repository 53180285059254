import { connect } from 'react-redux'
import { withRouter } from "react-router";
import CashTraxEvent from '../components/externalEvents/cashTrax/CashTraxEvent';
import * as externalEventActions from '../common/actions/externalEvent';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.externalEvents.externalEvent.isLoading,
        isSaving: state.externalEvents.externalEvent.isSaving,
        externalEvent: state.externalEvents.externalEvent.externalEvent,
        externalEventId: state.externalEvents.externalEvent.externalEventId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(externalEventActions.externalEventReset())
        },
        onUpdate: (externalEvent) => {
            dispatch(externalEventActions.externalEventUpdate(externalEvent))
        },
        onSave: () => {
            dispatch(externalEventActions.externalEventSaveRequest())
        }
    }
}

const CashTraxEventContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CashTraxEvent))

export default CashTraxEventContainer