import React, { Component } from 'react';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../common/SecondaryFab';
import PageTitle from '../common/PageTitle';
import Information from '../common/Information';
import DefaultButton from '../controls/DefaultButton';
import Box from '@material-ui/core/Box';

export interface IWelcomeProps {
    hasUserRoleRequests: boolean;
    verified: boolean;
    onRefresh: Function;
    onRequestEmailVerification: Function;
    theme: any;
    classes: any;
}


class Welcome extends Component<IWelcomeProps, any> {
    constructor(props: IWelcomeProps) {
        super(props)
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <PageTitle title={"Welcome"}></PageTitle>
                {!this.props.verified && (
                    <Information>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Box p={2}>
                                <p style={{ textAlign: 'center', padding: '10px' }}>
                                    <span>Please verify your email address by using the verification link sent to you. Check your junk folder if you don't see the email arrive within the next few minutes</span>
                                </p>
                            </Box>
                            <Box p={2}>
                                <DefaultButton text={"Request a new verification link"} onClick={this.props.onRequestEmailVerification} />
                            </Box>
                        </Box>
                    </Information>
                )}
                {this.props.hasUserRoleRequests && (
                    <Information>
                        <p style={{ textAlign: 'center', padding: '10px' }}>
                            <span>Your role requests are pending. You can request more roles on the My Profile page.</span>
                            <br />
                            <br />
                            <span>Your user account will become fully active once the venue or security firm has approved your role request.</span>
                        </p>
                    </Information>
                )}
                {!this.props.hasUserRoleRequests && (
                    <Information>
                        <p style={{ textAlign: 'center', padding: '10px' }}>
                            <span>You can request venue or security firm roles on the My Profile page.</span>
                        </p>
                    </Information>
                )}

                <VenueQuickActionsContainer>
                    <SecondaryFab onClick={this.props.onRefresh}><RefreshIcon style={{ marginTop: '5px' }} /></SecondaryFab>
                </VenueQuickActionsContainer>
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(Welcome))