import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import { IncidentPatron as IncidentPatronDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import IncidentPatron from './patron/IncidentPatron';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';

export interface IIncidentWizardPersonProps {
    incident: IncidentDTO;
    patron: IncidentPatronDTO;
    incidentOptions: IncidentOptionsDTO;
    onPatronUpdated: Function;
    onUploadPatronFiles: Function;
    contactTracing: boolean;
    isUploading: boolean;
    onRemove: Function;
    onDone: Function;
    theme: any;
    classes: any;
}

interface IIncidentWizardPersonState {
    confirm: boolean;
}

class IncidentWizardPerson extends Component<IIncidentWizardPersonProps, IIncidentWizardPersonState> {

    constructor(props: IIncidentWizardPersonProps){
        super(props)
        this.state = {
            confirm: false
        };
        this.confirm = this.confirm.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleNotConfirm = this.handleNotConfirm.bind(this);
    }

    confirm(){
        this.setState({confirm: true});
    }

    handleConfirm(){
        this.setState({confirm: false});
        this.props.onRemove();
    }

    handleNotConfirm(){
        this.setState({confirm: false});
    }

    render() {
        const { classes, theme } = this.props;
        if (!this.props.patron) {
            return (<div></div>);
        }
        let patron = this.props.patron;
        let incident = this.props.incident;

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <IncidentPatron
                                incident={incident}
                                contactTracing={this.props.contactTracing}
                                incidentPatron={patron}
                                incidentOptions={this.props.incidentOptions}
                                onUploadPatronFiles={this.props.onUploadPatronFiles}
                                isUploading={this.props.isUploading}
                                onUpdated={this.props.onPatronUpdated}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.confirm}
                    onClose={this.handleNotConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this person from the incident?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DefaultButton onClick={this.handleNotConfirm} style={{ width: 'auto', marginRight: '10px' }}>No</DefaultButton>
                        <DefaultButton text={"Yes"} onClick={this.handleConfirm} style={{ width: 'auto', marginRight: '10px' }} autoFocus></DefaultButton>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentWizardPerson);