import { connect } from 'react-redux'
import SecurityFirms from '../components/admin/securityFirms/SecurityFirms'
import { withRouter } from "react-router";
import * as adminActions from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.securityFirms.isLoading,
        securityFirms: state.admin.securityFirms.securityFirms,
        paging: state.admin.securityFirms.paging,
        filter: state.admin.securityFirms.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (filter, page, pageSize) => {
            dispatch(adminActions.adminSecurityFirmsRequest(filter, page, pageSize))
        },
        onFilterUpdate: (filter) => {
            dispatch(adminActions.adminSecurityFirmsFilterSet(filter))
        },
        onObsolete: (venueToRemoveId) => {
            dispatch(adminActions.adminSecurityFirmObsoleteRequest(venueToRemoveId))
        },
        onDelete: (venueToDeleteId) => {
            dispatch(adminActions.adminSecurityFirmDeleteRequest(venueToDeleteId))
        },
        onExport: () => {
            dispatch(adminActions.adminSecurityFirmExportRequest())
        },
        onDismissRegistration: (securityFirmId) => {
            dispatch(adminActions.adminSecurityFirmRegistratoinDismissRequest(securityFirmId))
        }
    }
}

const AdminSecurityFirmsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirms))

export default AdminSecurityFirmsContainer