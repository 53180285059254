import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueUser from '../components/venue/VenueUser'

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        userId: props.match.params.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueUserContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueUser))

export default VenueUserContainer