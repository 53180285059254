import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface IInfoButtonProps {
    theme: any;
    classes: any;
    className?: any;
    text?: string;
    onClick?: Function;
    component?: any;
    style?: any;
    disabled? :boolean;
}

class InfoButton extends React.PureComponent<IInfoButtonProps, any> {
    constructor(props: IInfoButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { classes, theme} = this.props;
        let style = this.props.style;
        if(!style) {
            style = {
                width: '100%', height: '100%'
            };
        }
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                component={this.props.component}
                disabled={this.props.disabled}
                className={classNames(classes.buttonInfo, classes.buttonState, this.props.className)}
                style={{ color: theme.palette.text.secondary, ...style }}>{this.props.text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(InfoButton);