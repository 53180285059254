import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as signOnRegisterTypeActions from "../actions/signOnRegisterType";

const initialState = {
    error: null,
    errors: {},
    signOnRegisterTypes: {
        isLoading: false,
        filter: {},
        signOnRegisterTypes: [],
        paging: { pageSize: 10, page: 1 }
    },
    signOnRegisterType: {
        signOnRegisterTypeId: -1,
        isLoading: false,
        isSaving: false,
        signOnRegisterType: { signOnRegisterTypeId: 0 },
    },
};


function loadingSignOnRegisterTypes(state, isLoading) {
    let newState = update(state, {
        signOnRegisterTypes: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSignOnRegisterTypes(state, signOnRegisterTypes, filter, paging) {
    let newState = update(state, {
        signOnRegisterTypes: {
            signOnRegisterTypes: { $set: signOnRegisterTypes },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingSignOnRegisterType(state, isLoading) {
    let newState = update(state, {
        signOnRegisterType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSignOnRegisterType(state, signOnRegisterType) {
    let newState = update(state, {
        signOnRegisterType: {
            signOnRegisterType: { $set: signOnRegisterType }
        }
    });
    return newState;
}

function savingSignOnRegisterType(state, isSaving) {
    let newState = update(state, {
        signOnRegisterType: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setSignOnRegisterTypeId(state, signOnRegisterTypeId) {
    let newState = update(state, {
        signOnRegisterType: {
            signOnRegisterTypeId: { $set: signOnRegisterTypeId }
        }
    });
    return newState;
}

export default function signOnRegisterTypes(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case signOnRegisterTypeActions.SIGNONREGISTERTYPES_REFRESH:
            return loadingSignOnRegisterTypes(setSignOnRegisterTypeId(state, -1), true);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPES_REQUEST:
            return setSignOnRegisterTypes(loadingSignOnRegisterTypes(setSignOnRegisterTypeId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case signOnRegisterTypeActions.SIGNONREGISTERTYPES_REQUEST_SUCCESS:
            return setSignOnRegisterTypes(loadingSignOnRegisterTypes(state, false), action.data, action.filter, action.paging);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPES_REQUEST_FAILURE:
            return loadingSignOnRegisterTypes(state, false);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_RESET:
            return setSignOnRegisterTypeId(state, -1);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_REQUEST:
            return setSignOnRegisterType(loadingSignOnRegisterType(setSignOnRegisterTypeId(state, action.signOnRegisterTypeId), true), {});
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_REQUEST_SUCCESS:
            return setSignOnRegisterType(loadingSignOnRegisterType(state, false), action.signOnRegisterType);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_REQUEST_FAILURE:
            return loadingSignOnRegisterType(setSignOnRegisterTypeId(state, -1), false);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_CREATE_REQUEST:
            return setSignOnRegisterType(loadingSignOnRegisterType(setSignOnRegisterTypeId(state, 0), true), {});
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_CREATE_REQUEST_SUCCESS:
            return setSignOnRegisterType(loadingSignOnRegisterType(state, false), action.signOnRegisterType);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_CREATE_REQUEST_FAILURE:
            return loadingSignOnRegisterType(setSignOnRegisterTypeId(state, -1), false);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST:
            return savingSignOnRegisterType(state, true);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST_SUCCESS:
            return setSignOnRegisterType(savingSignOnRegisterType(setSignOnRegisterTypeId(state, -1), false), action.signOnRegisterType);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_SAVE_REQUEST_FAILURE:
            return savingSignOnRegisterType(state, false);
        case signOnRegisterTypeActions.SIGNONREGISTERTYPE_UPDATE:
            return setSignOnRegisterType(state, action.signOnRegisterType);

    }

    return state;
}