import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import BadgeWrapper from '../../common/BadgeWrapper';

export interface IFeedImageProps {
    feedEvent: AusComplyDtos.FeedEvent;
    onUseImage?: Function;
}

interface IFeedImageState {
    selectedFilePath: string;
    selectedFileName: string;
}

export default class FeedImage extends Component<IFeedImageProps, IFeedImageState> {
    constructor(props: IFeedImageProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps) {

    }

    onSelectFilePath(filePath, text) {
        // console.log(filePath, text);
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    render() {
        let feedEvent = this.props.feedEvent;
        if (!feedEvent) return <></>;

        let image;
        
        if (feedEvent.poi_display_img && feedEvent.poi_display_img != '') {
            // there are multiple images
            image = (
                <BadgeWrapper count={feedEvent.crop_datas.length} bottomRight={true}>
                    <Thumbnail
                        key={"file-face-" + feedEvent.event_id}
                        previewPath={""}
                        displayPath={""}
                        base64={feedEvent.poi_display_img}
                        base64Type={"image/jpeg"}
                        isImage={true}
                        text={"Detected face"}
                        fullText={""}
                        title={""}
                        //fullText={feedEvent.watchlistNames}
                        //title={feedEvent.watchlistNames}
                        borderColor={feedEvent.displayColor}
                        style={{ borderColor: feedEvent.displayColor }}
                        onPreview={(path, text) => this.onSelectFilePath(path, text)}
                    />
                </BadgeWrapper>
            )
        } else if (feedEvent.crop_datas.length > 2) {
            image = (
                <BadgeWrapper count={feedEvent.crop_datas.length -1} bottomRight={true}>
                    <Thumbnail
                        key={"file-face-" + feedEvent.event_id}
                        previewPath={""}
                        displayPath={""}
                        base64={feedEvent.crop_data.face_crop_img}
                        base64Type={"image/jpeg"}
                        isImage={true}
                        text={"Detected face"}
                        fullText={""}
                        title={""}
                        borderColor={feedEvent.displayColor}
                        style={{ borderColor: feedEvent.displayColor }}
                        onPreview={(path, text) => this.onSelectFilePath(path, text)}
                    />
                    </BadgeWrapper>
            )
        } else {
            image = (
                <>
                    <Thumbnail
                        key={"file-face-" + feedEvent.event_id}
                        previewPath={""}
                        displayPath={""}
                        base64={feedEvent.crop_data.face_crop_img}
                        base64Type={"image/jpeg"}
                        isImage={true}
                        text={"Detected face"}
                        fullText={""}
                        title={""}
                        borderColor={feedEvent.displayColor}
                        style={{ borderColor: feedEvent.displayColor }}
                        onPreview={(path, text) => this.onSelectFilePath(path, text)}
                    />
                </>
            )
        }

        let otherImages: string[] = [];
        if (this.props.feedEvent.crop_datas && this.props.feedEvent.crop_datas.length > 1) {
            otherImages = this.props.feedEvent.crop_datas.map((item) => item.face_crop_img);
            otherImages = otherImages.splice(1);
        }

        let watchlistContent;
        if (this.props.feedEvent && this.props.feedEvent.match_data && this.props.feedEvent.match_data.watchlists) {
            watchlistContent = <div style={{ position: 'absolute', top: '55px', left: '15px', overflow: 'hidden', pointerEvents: 'none' }}>
                {this.props.feedEvent.match_data.watchlists.map((item, index) => <p
                    key={item.watchlist_id + index}
                    style={{
                        backgroundColor: 'black', opacity: 0.7,
                        fontSize: '14px', lineHeight: '16px',
                        borderLeftWidth: '3px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: item.display_color,
                        padding: '4px', margin: '0 0 4px 0',
                        textOverflow: 'ellipsis',
                        color: 'white'
                    }}
                >{item.display_name}</p>
                )}
            </div>
        }


        return (
            <div style={{ position: 'relative' }}>
                {image}
                <ImagePreview
                    filePath={this.state.selectedFilePath}
                    fileName={this.state.selectedFileName}
                    filePaths={otherImages}
                    extraContent={watchlistContent}
                    onDismissed={() => this.onSelectFilePath("", "")} />
                <div style={{ position: 'absolute', top: '15px', left: '15px', overflow: 'hidden', pointerEvents: 'none' }}>
                    {(this.props.feedEvent && this.props.feedEvent.match_data && this.props.feedEvent.match_data.watchlists)
                        && this.props.feedEvent.match_data.watchlists.map((item, index) => <p
                            key={item.watchlist_id + index}
                            style={{
                                backgroundColor: 'black', opacity: 0.7, fontSize: '8px', lineHeight: '11px',
                                borderLeftWidth: '3px', borderLeftStyle: 'solid', borderLeftColor: item.display_color,
                                padding: '2px', margin: '0 0 2px 0',
                                textOverflow: 'ellipsis',
                                color: 'white'
                            }}
                        >{item.display_name}</p>
                        )}
                </div>

            </div>
        );

    }
}