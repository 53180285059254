import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';


export interface IRegisterSecurityFirmDetailsProps {
    securityFirm: AusComplyDTOs.SecurityFirm;
    states: AusComplyDTOs.State[];
    canEditMasterLicence: boolean;
    securityLicenceClasses: AusComplyDTOs.SecurityLicenceClass[];
    errors: any;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterSecurityFirmDetails extends Component<IRegisterSecurityFirmDetailsProps, any> {
    constructor(props: IRegisterSecurityFirmDetailsProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let securityFirm = { ...this.props.securityFirm };
        securityFirm[fieldname] = value;
        this.props.onUpdate(securityFirm);
    }

    render() {
        if (!this.props.securityFirm) {
            return <></>;
        }

        let idDisplay;
        let updateName;
        let newName;
        if (this.props.securityFirm.securityFirmId > 0) {
            idDisplay = (
                <ControlGroup text="ID">
                    <TextDisplay>{this.props.securityFirm.securityFirmId}</TextDisplay>
                </ControlGroup>
            );
            updateName = (
                <TextControlGroup
                    text={"Company Name"}
                    defaultValue={this.props.securityFirm.name}
                    error={this.props.errors["name"]}
                    onBlur={(value) => this.onChange("name", value)} />
            );
        } else {
            newName = (
                <TextControlGroup
                    text={"Company Name"}
                    defaultValue={this.props.securityFirm.name}
                    error={this.props.errors["name"]}
                    onBlur={(value) => this.onChange("name", value)} />
            );
        }


        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Details"}>
                    <CardContent>
                        <LayoutForm>
                            {idDisplay}
                            {updateName}
                            <SelectControlGroup
                                text="State Licensed"
                                error={this.props.errors["stateLicensedId"]}
                                onChange={(value) => this.onChange("stateLicensedId", value)}
                                defaultValue={this.props.securityFirm.stateLicensedId}>
                                <option value={0} />
                                {this.props.states && this.props.states.map((item, index) => (
                                    <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                ))}
                            </SelectControlGroup>
                            <TextControlGroup
                                text={"Master Licence #"}
                                readonly={!this.props.canEditMasterLicence}
                                defaultValue={this.props.securityFirm.masterLicenceNumber}
                                error={this.props.errors["masterLicenceNumber"]}
                                onBlur={(value) => this.onChange("masterLicenceNumber", value)} />
                            {newName}
                            <SelectControlGroup
                                text="MLH Licence Class"
                                error={this.props.errors["securityLicenceClassId"]}
                                onChange={(value) => this.onChange("securityLicenceClassId", value)}
                                defaultValue={this.props.securityFirm.securityLicenceClassId}>
                                <option value={0} />
                                {this.props.securityLicenceClasses && this.props.securityLicenceClasses.map((item, index) => (
                                    <option key={item.securityLicenceClassId} value={item.securityLicenceClassId}>{item.code} = {item.name}</option>
                                ))}
                            </SelectControlGroup>
                            <TextControlGroup
                                text={"MLH Nominated Person"}
                                defaultValue={this.props.securityFirm.mlhNominatedPerson}
                                error={this.props.errors["mlhNominatedPerson"]}
                                onBlur={(value) => this.onChange("mlhNominatedPerson", value)} />
                            <SimpleDateControlGroup
                                text={"MLH Expiry"}
                                date={this.props.securityFirm.masterLicenceExpiryDate}
                                error={this.props.errors["masterLicenceExpiryDate"]}
                                onChange={(value) => this.onChange("masterLicenceExpiryDate", value)}
                            />
                            <TextControlGroup
                                text={"ABN"}
                                defaultValue={this.props.securityFirm.abn}
                                error={this.props.errors["abn"]}
                                onBlur={(value) => this.onChange("abn", value)}
                            />
                            <TextControlGroup
                                text={"Phone"}
                                defaultValue={this.props.securityFirm.phone}
                                error={this.props.errors["phone"]}
                                onBlur={(value) => this.onChange("phone", value)} />
                            <TextControlGroup
                                text={"Website"}
                                defaultValue={this.props.securityFirm.website}
                                error={this.props.errors["website"]}
                                onBlur={(value) => this.onChange("website", value)} />
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}