import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IChecklistTemplatesProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface IChecklistTemplatesState {
}

export default class ChecklistTemplates extends Component<IChecklistTemplatesProps, IChecklistTemplatesState> {
    constructor(props: IChecklistTemplatesProps) {
        super(props)
        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        const { externalEvent } = this.props;

        if (!externalEvent) return <></>;

        return (
            <SelectControlGroup
                        text="Checklist Template"
                        onChange={(value) => this.onValueChanged("checklistTemplateId", Number(value))}
                        defaultValue={this.props.externalEvent.checklistTemplateId}>
                        <option key={0} value={0}>Select...</option>
                        {this.props.externalEvent.checklistTemplates && this.props.externalEvent.checklistTemplates.map((item, index) => (
                            <option key={item.checklistTemplateId} value={item.checklistTemplateId}>{item.name}</option>
                        ))}
            </SelectControlGroup>
        );
    }
}