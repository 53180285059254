import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import ResponsiveList from '../../common/ResponsiveList';
import PageLayout from '../../layout/PageLayout';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import { KeyName } from '../../../common/dto/common';
import PlayTraxTabs from './PlayTraxTabs';

export interface IPlayTraxEventMessageAuditsProps {
    isLoading: boolean;
    isSaving: boolean;
    venueId: number;
    externalEventMessageAudits: AusComplyDtos.ExternalEvent[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    onLoad: Function;
    onReProcess: Function;
    history: any;
}

export default class PlayTraxEventMessageAudits extends Component<IPlayTraxEventMessageAuditsProps, any> {
    constructor(props: IPlayTraxEventMessageAuditsProps) {
        super(props)

        this.state = {
            rawMessage: ""
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.setState({rawMessage: ""});
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
        } 
        preparedFilter.entityType = AusComplyDtos.ngtEntityType.PlayTrax;
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(externalEventMessageAudit: AusComplyDtos.ExternalEventMessageAudit) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "reprocess", name: "Re-process" });
        return commands;
    }

    onCommand(command: string, externalEventMessageAudit: AusComplyDtos.ExternalEventMessageAudit) {
        switch (command) {
            case "edit":
               this.setState({rawMessage: externalEventMessageAudit.rawMessage})
                break;
            case "reprocess":
                this.props.onReProcess(externalEventMessageAudit);
            default:
                break;
        }
    }

    render() {
        let self = this;

        return (<PageLayout
            headerText={"PlayTrax"}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            tabContent={<PlayTraxTabs selectedTab='Audits' history={this.props.history} />}
        >
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.externalEventMessageAudits}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Date", "Message", "Created", "Created Item"]}
                columns={["dateEnteredDisplay", "display", "createdEntityTypeDisplay", "createdEntityDisplay"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                onGetLink={(columnName, rowData) => {
                    if (columnName == "createdEntityDisplay" && rowData["createdEntityType"] == "Incident") {
                        return "/incidentview/" + rowData["createdEntityId"];
                    }
                    return "";
                }}
                canEdit={true}
            />
            <FullPageDialog open={this.state.rawMessage !== ""} 
                title="PlayTrax Audit" onDismissed={() => this.setState({rawMessage: ""})}>
                <TextDisplayControlGroup
                    text='Raw Message'
                    value={this.state.rawMessage}
                 />
            </FullPageDialog>
        </PageLayout>);
    }
}