import React, { Component } from 'react';
import View from './View';

export interface ISpacerProps {
    vertical?: number;
    horizontal?: number;
    repeat?: number;
}

export default class Spacer extends React.PureComponent<ISpacerProps, any> {
    constructor(props: ISpacerProps) {
        super(props)
    }
    render() {
        const { vertical, horizontal, repeat } = this.props;
        const times = repeat == undefined ? 1 : repeat;
        const height = vertical == undefined? (20 * times).toString() + 'px' : (vertical* times).toString() + "px"
        const width = horizontal == undefined? (20 * times).toString() + 'px' : (horizontal* times).toString() + "px"
        return (
            <View style={{height: height, width: width}}>
            </View>
        );
    }
}
