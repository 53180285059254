import { connect } from 'react-redux'
import Users from '../components/admin/users/Users'
import { withRouter } from "react-router";
import { adminUsersRequest, adminUsersFilterSet } from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.users.isLoading,
        users: state.admin.users.users,
        paging: state.admin.users.paging,
        filter: state.admin.users.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(adminUsersRequest(filter, page, pageSize))
        },
        onFilterUpdate:(filter) => {
            dispatch(adminUsersFilterSet(filter))
        }
    }
}

const AdminUsersContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Users))

export default AdminUsersContainer