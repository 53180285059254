import React from 'react';
import PageMessage from './PageMessage';
import { Link } from 'react-router-dom';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IInformationProps {
    message?: string;
    newItemLink?: string;
    newItemText?: string;
    newItemLinkNoBreak?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    isWarning?: boolean;
    isInfo?: boolean;
    style?: any;
    textStyle?: any;
    classes: any;
    theme: any;
}

class Information extends React.PureComponent<IInformationProps, any> {
    render() {
        const { theme } = this.props;
        let style = { ...theme.custom.label };
        let messageStyle = { };
        if (this.props.isError) {
            style = { ...theme.custom.errorColor, ...theme.custom.errorBorder, ...this.props.style };
            messageStyle = { borderColor: theme.custom.errorBorder.borderColor };
        } else if (this.props.isWarning) {
            style = { ...this.props.style, color: theme.custom.colors.warning, borderColor: theme.custom.colors.warning };
            messageStyle = { borderColor: theme.custom.colors.warning };
        } else if (this.props.isInfo) {
            style = { ...this.props.style, color: theme.custom.colors.info, borderColor: theme.custom.colors.info };
            messageStyle = { borderColor: theme.custom.colors.info };
        } else if (this.props.isSuccess) {
            style = { ...this.props.style, color: theme.custom.colors.success, borderColor: theme.custom.colors.success };
            messageStyle = { borderColor: theme.custom.colors.success };
        } else {
            style = { ...theme.custom.label, ...this.props.style };
        }
        return (
            <PageMessage containerStyle={{ ...messageStyle}} >
                {this.props.children}
                <p style={{ textAlign: 'center', ...style }}>
                    {this.props.message}
                    {this.props.newItemLink && (
                        " "
                    )}
                    {this.props.newItemLink && (
                        <span>
                            {!this.props.newItemLinkNoBreak && (<br />)}
                            <Link to={"/" + this.props.newItemLink}>{this.props.newItemText}</Link>
                        </span>
                    )}
                </p>
            </PageMessage>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Information);