export const
    DISPLAY_INCIDENT_LOAD_REQUEST = "DISPLAY_INCIDENT_LOAD_REQUEST",
    DISPLAY_INCIDENT_LOAD_REQUEST_SUCCESS = "DISPLAY_INCIDENT_LOAD_REQUEST_SUCCESS",
    DISPLAY_INCIDENT_LOAD_REQUEST_FAILURE = "DISPLAY_INCIDENT_LOAD_REQUEST_FAILURE",
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST = "DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST",
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_SUCCESS = "DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_SUCCESS",
    DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_FAILURE = "DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_FAILURE",
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST = "DISPLAY_INCIDENT_LOAD_VERSION_REQUEST",
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_SUCCESS = "DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_SUCCESS",
    DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_FAILURE = "DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_FAILURE";

export function displayIncidentLoad(incidentId) {
    return {
        type: DISPLAY_INCIDENT_LOAD_REQUEST,
        incidentId
    };
}

export function displayIncidentLoadSuccess(incident) {
    return {
        type: DISPLAY_INCIDENT_LOAD_REQUEST_SUCCESS,
        incident
    };
}

export function displayIncidentLoadFailure(error) {
    return {
        type: DISPLAY_INCIDENT_LOAD_REQUEST_FAILURE,
        error
    };
}

export function displayIncidentVersionsLoad(incidentId) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST,
        incidentId
    };
}

export function displayIncidentVersionsLoadSuccess(incidentVersions) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_SUCCESS,
        incidentVersions
    };
}

export function displayIncidentVersionsLoadFailure(error) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSIONS_REQUEST_FAILURE,
        error
    };
}

export function displayIncidentVersionLoad(securityFirmId, incidentId, incidentRelaventId, version) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSION_REQUEST,
        securityFirmId,
        incidentId,
        incidentRelaventId,
        version
    };
}

export function displayIncidentVersionLoadSuccess(incidentVersion) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_SUCCESS,
        incidentVersion
    };
}

export function displayIncidentVersionLoadFailure(error) {
    return {
        type: DISPLAY_INCIDENT_LOAD_VERSION_REQUEST_FAILURE,
        error
    };
}