import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import View from '../../common/View';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import IncidentCategoryTypeCard from './IncidentCategoryTypeCard';
import IncidentCategoryTypeHeader from './IncidentCategoryTypeHeader';
import IncidentCategoryTypeRow from './IncidentCategoryTypeRow';
import VenueIncidentCategoryTypeContainer from '../../../containers/VenueIncidentCategoryTypeContainer';
import IncidentCategoryTypeFilterSummary from './IncidentCategoryTypeFilterSummary';
import IncidentCategoryTypeFilter from './IncidentCategoryTypeFilter';
import IncidentCategoryTypeLegend from './IncidentCategoryTypeLegend';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IIncidentCategoryTypesProps {
    isLoading: boolean;
    venueId?: number;
    securityFirmId?: number;
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[];
    incidentCategoryTypeId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentCategoryTypeFilter;
    canCreate: boolean;
    canEditNotifications?: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onFilterUpdate: Function;
    onHide: Function;
}

export default class IncidentCategoryTypes extends Component<IIncidentCategoryTypesProps, any> {
    constructor(props: IIncidentCategoryTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    prepareFilter(filter: AusComplyDtos.IncidentCategoryTypeFilter) {
        let preparedFilter = { ...filter };
        preparedFilter.specific = true;
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.venueId = this.props.venueId;
        } else {
            preparedFilter.venueId = 0;
        }
        if (this.props.securityFirmId && this.props.securityFirmId > 0) {
            preparedFilter.securityFirmId = this.props.securityFirmId;
        } else {
            preparedFilter.securityFirmId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, incidentCategoryType: AusComplyDtos.IncidentCategoryType) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId, this.props.securityFirmId);
                break;
            case "edit":
                this.props.onLoadItem(incidentCategoryType.incidentCategoryTypeId);
                break;
            case "delete":
                var updated = { ...incidentCategoryType };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "undelete":
                var updated = { ...incidentCategoryType };
                updated.obsolete = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "hide":
                this.props.onHide(incidentCategoryType.incidentCategoryTypeId, true, this.props.venueId, this.props.securityFirmId);
                break;
            case "unhide":
                this.props.onHide(incidentCategoryType.incidentCategoryTypeId, false, this.props.venueId, this.props.securityFirmId);
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(this.props.filter));
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.IncidentCategoryTypeFilter) {
        this.props.onFilterUpdate(this.prepareFilter(filter));
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let filter = new AusComplyDtos.IncidentCategoryTypeFilter();
            this.props.onFilterUpdate(this.prepareFilter(filter));
            this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(filter));
        });
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.incidentCategoryTypes && this.props.incidentCategoryTypes.length > 0) {
            cardList = this.props.incidentCategoryTypes.map((item, index) => {
                return (
                    <IncidentCategoryTypeCard
                        onCommand={this.onCommand}
                        canEditNotifications={this.props.canEditNotifications}
                        key={"card-" + item.incidentCategoryTypeId.toString()}
                        incidentCategoryType={item}
                    />
                );
            });
            rows = this.props.incidentCategoryTypes.map((item, index) => {
                return (
                    <IncidentCategoryTypeRow
                        onCommand={this.onCommand}
                        canEditNotifications={this.props.canEditNotifications}
                        key={"row-" + item.incidentCategoryTypeId.toString()}
                        incidentCategoryType={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<IncidentCategoryTypeHeader canEditNotifications={this.props.canEditNotifications} />}
                    rows={rows}
                />
            );
        }

        /*
        let incidentCategoryType;
        if (this.props.incidentCategoryTypeId != -1) {
            incidentCategoryType = (
                <VenueIncidentCategoryTypeContainer onClose={() => this.props.onResetItem()} />
            );
        }
            */

        let create;
        if (this.props.canCreate) {
            create = (
                <TransparentButton onClick={() => this.props.onCreate(this.props.venueId, this.props.securityFirmId)} text={"Add Incident Category Type"} />
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <IncidentCategoryTypeFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let filter = new AusComplyDtos.IncidentCategoryTypeFilter();
            filter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentCategoryTypeFilterSummary filter={filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentCategoryTypeFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<PageLayout
            headerText={"Incident Category Types"}
            footerLeftContent={create}
        >
            {filterSummary}
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                footer={<IncidentCategoryTypeLegend />}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <FullPageDialog 
                open={this.state.filterExpanded} 
                title="Search" 
                notFullWidth={true}
                onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <VenueIncidentCategoryTypeContainer onClose={() => this.props.onResetItem()} />
        </PageLayout>);
    }
}