import { connect } from 'react-redux'
import { withRouter } from "react-router";
import UserRoleUser from '../components/users/UserRoleUser'

const mapStateToProps = (state, props) => {
    return {
        userId: props.match.params.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const UserRoleUserContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRoleUser))

export default UserRoleUserContainer