import { connect } from 'react-redux'
import NotificationTriggerEdit from '../components/notifications/NotificationTriggerEdit'
import * as notificationTriggerActions from '../common/actions/notificationTrigger'

const mapStateToProps = (state, props) => {
    return {
        notificationTriggerParentType: props.notificationTriggerParentType,
        parentId: props.parentId,
        groupId: props.groupId,
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        onClose: props.onClose,
        isLoading: state.notificationTrigger.isLoading,
        isSaving: state.notificationTrigger.isSaving,
        notificationTrigger: state.notificationTrigger.notificationTrigger,
        error: state.notificationTrigger.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (notificationTriggerParentType, parentId, forGroupId, forVenueId, forSecurityFirmId) => {
            dispatch(notificationTriggerActions.notificationTriggerRequest(notificationTriggerParentType, parentId, forGroupId, forVenueId, forSecurityFirmId))
        },
        onUpdate: (notificationTrigger) => {
            dispatch(notificationTriggerActions.notificationTriggerSet(notificationTrigger))
        },
        onSave: (notificationTrigger) => {
            dispatch(notificationTriggerActions.notificationTriggerUpsertRequest(notificationTrigger))
        }
    }
}

const NotificationTriggerEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationTriggerEdit)

export default NotificationTriggerEditContainer