import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { rosterServiceApi } from '../services/rosterService';
import * as rosterActions from '../actions/roster';
import {
    notifyError
} from './common';

const rosterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(rosterActions.ROSTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            rosterServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['filter'])
                .pipe(
                    map(response => rosterActions.rosterRequestSuccess(response.filter, response.rosters)),
                    catchError(error => notifyError(error, "rosterRequestEpic.find", rosterActions.rosterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "rosterRequestEpic", rosterActions.rosterRequestFailure))
    );

const rosterNewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(rosterActions.ROSTER_NEW_LEAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            rosterServiceApi.newLeaveRequest(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => rosterActions.rosterNewLeaveRequestSuccess(response.leaveRequest)),
                    catchError(error => notifyError(error, "rosterNewLeaveRequestEpic.find", rosterActions.rosterNewLeaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "rosterNewLeaveRequestEpic", rosterActions.rosterNewLeaveRequestFailure))
    );
const rosterSaveLeaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(rosterActions.ROSTER_SAVE_LEAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            rosterServiceApi.saveLeaveRequest(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['leaveRequest'])
                .pipe(
                    map(response => rosterActions.rosterSaveLeaveRequestSuccess(response.leaveRequest)),
                    catchError(error => notifyError(error, "rosterSaveLeaveRequestEpic.find", rosterActions.rosterSaveLeaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "rosterSaveLeaveRequestEpic", rosterActions.rosterSaveLeaveRequestFailure))
    );

export const rosterEpics = combineEpics(
    rosterRequestEpic,
    rosterNewRequestEpic,
    rosterSaveLeaveRequestEpic
);