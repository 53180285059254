const fallback = {};

export const LocalStorage = {
    get(key) {
        /*return new Promise((resolve, reject) => {
            if (typeof (Storage) !== "undefined") {
                resolve(localStorage.getItem(key));
                //return localStorage.getItem(key)
            } else {
                resolve(fallback[key]);
                //return fallback[key];
            }
        });*/
        if (typeof (Storage) !== "undefined") {
            return localStorage.getItem(key)
        } else {
            return fallback[key];
        }
    },
    set(key, value) {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem(key, value);
        } else {
            fallback[key] = value;
        }
    },
    clear(key) {
        if (typeof (Storage) !== "undefined") {
            localStorage.removeItem(key);
        } else {
            fallback[key] = "";
        }
    },
    clearStartWithExcept(key, except) {
        try {
            for (let index = localStorage.length - 1; index > -1 ; index--) {
                const element = localStorage.key(index ) ;
                if (element != except && element.startsWith(key)) {
                    // remove the key
                    this.clear(element);
                }
            }
        } catch {}
        
    }
};