import React, { Component } from 'react';
import { styles } from '../../styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import PageTitle from '../common/PageTitle';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import PagedList from '../common/PagedList';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import Box from '@material-ui/core/Box';
import ProfileAvatar from '../profile/ProfileAvatar';

export interface ILoggedInUsersProps {
    isLoading: boolean;
    loggedInUsers: AusComplyDtos.LoggedInUser[];
    onRefresh: Function;
}


class LoggedInUsers extends Component<ILoggedInUsersProps, any> {

    componentDidMount() {
        this.props.onRefresh();
    }

    render() {

        let paging = new AusComplyDtos.Paging();
        if (this.props.loggedInUsers && !this.props.isLoading) {
            paging.page = 1;
            paging.pageSize = this.props.loggedInUsers.length;
            paging.pages = 1;
            paging.total = this.props.loggedInUsers.length;
        }

        let cardList;
        if (this.props.loggedInUsers && this.props.loggedInUsers.length > 0) {
            cardList = this.props.loggedInUsers.map((item, index) => {
                return (
                    <CardContainer key={"logged-in-user" + item.userRoleId} style={{ marginTop: '6px', marginBottom: '0', padding: '0' }}>
                        <CardContent style={{ padding: '5px' }}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                <Box style={{ flex: 0, padding: '10px' }}>
                                    <ProfileAvatar avatar={item.avatar} />
                                </Box>
                                <Box style={{ flex: 1 }}>
                                    <CardTypography>{item.displayName}</CardTypography>
                                    <CardTypography style={{ fontStyle: 'italic' }}>{item.userRole}</CardTypography>
                                    {item.device && (
                                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', fontSize: '10px' }}>{item.device}</CardTypography>
                                    )}
                                </Box>
                            </Box>
                        </CardContent>
                    </CardContainer>
                );
            });
        }

        return (
            <>
                <PageTitle title="Logged in users" />
                <PagedList
                    loading={this.props.isLoading}
                    paging={paging}
                    cards={cardList}
                    rows={cardList}
                />
                {!this.props.isLoading && (
                    <VenueQuickActionsContainer />
                )}
            </>
        );

    }
}


export default withStyles(styles, { withTheme: true })(withRouter(LoggedInUsers));