import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import YesNoControlGroup from '../../controls/YesNoControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import { securityFirmLogic } from '../../../common/logic/securityFirmLogic';


export interface IRegisterSecurityFirmContactProps {
    securityFirm: AusComplyDTOs.SecurityFirm;
    primaryContact: AusComplyDTOs.SecurityFirmContact;
    managerContact: AusComplyDTOs.SecurityFirmContact;
    errors: any;
    onUpdate: Function;
    onUpdatePrimaryContact: Function;
    onUpdateManagerContact: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterSecurityFirmContact extends Component<IRegisterSecurityFirmContactProps, any> {
    constructor(props: IRegisterSecurityFirmContactProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let securityFirm = { ...this.props.securityFirm };
        securityFirm[fieldname] = value;
        this.props.onUpdate(securityFirm);
    }

    onChangePrimaryContact(fieldname, value) {
        if (fieldname === "obsolete" && value) {
            let managerContact = { ...this.props.managerContact };
            managerContact.primaryContact = false;
            this.props.onUpdateManagerContact(managerContact);
        }
        let primaryContact = { ...this.props.primaryContact };
        primaryContact[fieldname] = value;
        this.props.onUpdatePrimaryContact(primaryContact);
    }

    onChangeManagerContact(fieldname, value) {
        if (fieldname === "primaryContact" && value) {
            let primaryContact = { ...this.props.primaryContact };
            primaryContact.obsolete = true;
            this.props.onUpdatePrimaryContact(primaryContact);
        }
        let managerContact = { ...this.props.managerContact };
        managerContact[fieldname] = value;
        this.props.onUpdateManagerContact(managerContact);
    }

    render() {
        if (!this.props.managerContact || !this.props.primaryContact) {
            return <></>;
        }

        let managerContact;
        let managerContactDetails;

        managerContact = (
            <YesNoControlGroup
                name="obsolete"
                text={"Are you the Decision Maker / General Manager?"}
                value={this.props.managerContact.obsolete}
                onChanged={(value) => this.onChangeManagerContact("obsolete", value)}
            />
        );
        if (securityFirmLogic.showLicenseeContactDetails(this.props.primaryContact, this.props.managerContact)) { 
            managerContactDetails = (
                <>
                    <TextControlGroup
                        text={"Manager First Name"}
                        defaultValue={this.props.managerContact.firstname}
                        error={this.props.errors["managerContact.firstname"]}
                        onBlur={(value) => this.onChangeManagerContact("firstname", value)}
                    />
                    <TextControlGroup
                        text={"Manager Last Name"}
                        defaultValue={this.props.managerContact.lastname}
                        error={this.props.errors["managerContact.lastname"]}
                        onBlur={(value) => this.onChangeManagerContact("lastname", value)}
                    />
                    <TextControlGroup
                        text={"Manager Phone"}
                        defaultValue={this.props.managerContact.phone}
                        error={this.props.errors["managerContact.phone"]}
                        onBlur={(value) => this.onChangeManagerContact("phone", value)}
                    />
                    <TextControlGroup
                        text={"Manager Email Address"}
                        defaultValue={this.props.managerContact.email}
                        error={this.props.errors["managerContact.email"]}
                        onBlur={(value) => this.onChangeManagerContact("email", value)}
                    />
                </>
            );
        }

        let primaryContact;
        let primaryContactIsLicencee;
        let primaryContactDetails;
        primaryContact = (
            <YesNoControlGroup
                name="obsolete"
                text={"Are you the primary contact?"}
                value={this.props.primaryContact.obsolete}
                //reverse={true}
                onChanged={(value) => this.onChangePrimaryContact("obsolete", value)}
            />
        );
        if (securityFirmLogic.showLicenseeIsPrimaryContactDetails(this.props.primaryContact, this.props.managerContact)) { //(!this.props.managerContact.obsolete && !this.props.primaryContact.obsolete) {
            primaryContactIsLicencee = (
                <YesNoControlGroup
                    name="primaryContact"
                    text={"Is the Manager the Primary Contact?"}
                    value={this.props.managerContact.primaryContact}
                    //reverse={true}
                    onChanged={(value) => this.onChangeManagerContact("primaryContact", value)}
                />
            );
        }

        if (securityFirmLogic.showPrimaryContactDetails(this.props.primaryContact, this.props.managerContact)) { //
            primaryContactDetails = (
                <>
                    <TextControlGroup
                        text={"Primary Contact First Name"}
                        defaultValue={this.props.primaryContact.firstname}
                        error={this.props.errors["primaryContact.firstname"]}
                        onBlur={(value) => this.onChangePrimaryContact("firstname", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Last Name"}
                        defaultValue={this.props.primaryContact.lastname}
                        error={this.props.errors["primaryContact.lastname"]}
                        onBlur={(value) => this.onChangePrimaryContact("lastname", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Phone"}
                        defaultValue={this.props.primaryContact.phone}
                        error={this.props.errors["primaryContact.phone"]}
                        onBlur={(value) => this.onChangePrimaryContact("phone", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Email Address"}
                        defaultValue={this.props.primaryContact.email}
                        error={this.props.errors["primaryContact.email"]}
                        onBlur={(value) => this.onChangePrimaryContact("email", value)}
                    />
                </>
            );
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Venue Contacts"}>
                    <CardContent>
                        <LayoutForm>
                            {managerContact}
                            {managerContactDetails}
                            {primaryContact}
                            {primaryContactIsLicencee}
                            {primaryContactDetails}
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}