import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueReportingPeriod from '../components/venue/reportingPeriod/VenueReportingPeriod'
import { 
    venueReportingPeriodReset,
    venueReportingPeriodSet,
    venueReportingPeriodUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.reportingPeriod.isLoading,
        isSaving: state.venue.reportingPeriod.isSaving,
        venueReportingPeriod: state.venue.reportingPeriod.reportingPeriod
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueReportingPeriodReset())
        },
        onUpdate: (venueReportingPeriod) => {
            dispatch(venueReportingPeriodSet(venueReportingPeriod))
        },
        onSave: () => {
            dispatch(venueReportingPeriodUpsertRequest())
        }
    }
}

const VenueReportingPeriodContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueReportingPeriod))

export default VenueReportingPeriodContainer