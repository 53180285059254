import React from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUserComplianceDocumentExpiredHeaderProps {
    classes: any;
}

class UserComplianceDocumentExpiredHeader extends React.Component<IUserComplianceDocumentExpiredHeaderProps, any> {
    render() {
        const { classes } = this.props;
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Type</th>
                <th className={classes.tableHr}>Expires (days)</th>
                <th className={classes.tableHr}>First name</th>
                <th className={classes.tableHr}>Last name</th>
                <th className={classes.tableHr}>Nickname</th>
                <th className={classes.tableHr}>Venue</th>
                <th className={classes.tableHr}>Security firm(s)</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserComplianceDocumentExpiredHeader);