import React from 'react';
import { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import DefaultButton from '../../../controls/DefaultButton';
import LayoutForm from '../../../layout/LayoutForm';
import SelectControlGroup from '../../../controls/SelectControlGroup';
import FullPageDialog from '../../../layout/FullPageDialog';
import ControlGroup from '../../../controls/ControlGroup';

export interface IGroupRequestAccessProps {
    show: boolean;
    groups: AusComplyDtos.Group[];
    isLoading: boolean;
    isSaving: boolean;
    onLoad: Function;
    onSave: Function;
    onClose: Function;
}

interface IGroupRequestAccessState {
    groupId: number;
}

class GroupRequestAccess extends React.Component<IGroupRequestAccessProps, IGroupRequestAccessState> {

    constructor(props: IGroupRequestAccessProps) {
        super(props)
        this.state = {
            groupId: 0
        }
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoading && this.props.show) {
            this.props.onLoad();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isLoading && this.props.show && !prevProps.show) {
            this.props.onLoad();
        } else if (!this.props.isLoading && prevProps.isLoading) {
            this.setState({ groupId: 0 });
        } else if (!this.props.isSaving && prevProps.isSaving) {
            this.setState({ groupId: 0 });
            this.onClose();
        }
    }

    onSave() {
        this.props.onSave(this.state.groupId);
    }

    onClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        let self = this;

        return (
            <FullPageDialog
                open={this.props.show}
                title={"Request Group Access"}
                onDismissed={() => this.onClose()}
                style={{ paddingTop: '0', paddingBottom: '80px' }}>
                {this.props.show && (
                    <LayoutForm
                        loading={this.props.isLoading}
                        saving={this.props.isSaving}>
                        <SelectControlGroup
                            text="Group"
                            defaultValue={this.state.groupId}
                            readonly={false}
                            onChange={(value) => self.setState({ groupId: value })}
                            labelAbove={false}>
                            <option value={undefined} />
                            {this.props.groups && this.props.groups.map((item) => (
                                <option key={"groups" + item.groupId}
                                    value={item.groupId}>{item.name}</option>
                            ))}
                        </SelectControlGroup>
                        <ControlGroup text={" "}>
                            <DefaultButton onClick={() => this.onSave()}>Request</DefaultButton>
                        </ControlGroup>
                    </LayoutForm>
                )}
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(GroupRequestAccess);