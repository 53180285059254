import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { MulitSelectOption } from '../../../common/dto/common';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CheckboxControl from '../../controls/CheckboxControl';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';


export interface IChecklistTemplateDetailProps {
    venueId: number;
    securityFirmId: number;
    checklistTemplate: AusComplyDTOs.ChecklistTemplate;
    errors: any;
    onUpdate: Function;
    canEditVenues: boolean;
    canEditSecurityFirms: boolean;
    canEditIndustryCategories: boolean;
    canEditStates: boolean;
    canEditCategories: boolean;
    venues: AusComplyDTOs.NamedValue[],
    securityFirms: AusComplyDTOs.NamedValue[],
    industryCategories: AusComplyDTOs.IndustryCategory[],
    states: AusComplyDTOs.State[],
    checklistTemplateCategories: AusComplyDTOs.ChecklistTemplateCategory[]
}

export default class ChecklistTemplateDetail extends Component<IChecklistTemplateDetailProps, any> {
    constructor(props: IChecklistTemplateDetailProps) {
        super(props)
        this.state = {
        }
        this.onChange = this.onChange.bind(this);
        this.onVenuesChanged = this.onVenuesChanged.bind(this);
        this.onSecurityFirmChanged = this.onSecurityFirmChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.onStatesChanged = this.onStatesChanged.bind(this);
        this.onChecklistTemplateCategoriesChanged = this.onChecklistTemplateCategoriesChanged.bind(this);
        this.isVenueLocked = this.isVenueLocked.bind(this);
        this.isSecurityFirmLocked = this.isSecurityFirmLocked.bind(this);
    }

    onChange(fieldname, value) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate[fieldname] = value;
        this.props.onUpdate(checklistTemplate);
    }

    getVenueOptions(): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.venues && this.props.checklistTemplate.venues && Array.isArray(this.props.venues)) {
                this.props.venues.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.venues.indexOf(item.id) > -1;
                    items.push({
                        id: item.id,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected,
                        locked: this.props.venueId !== undefined && this.props.venueId === item.id
                    });
                });
            }
        } catch {

        }
        return items;
    }

    isVenueLocked(): boolean {
        let result: boolean = false;
        try {
            if (this.props.venues && this.props.checklistTemplate.venues && Array.isArray(this.props.venues)) {
                this.props.venues.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.venues.indexOf(item.id) > -1;
                    if (isSelected && this.props.venueId !== undefined && this.props.venueId === item.id) {
                        result = true;
                    }
                });
            }
        } catch {

        }
        return result;
    }

    onVenuesChanged(items) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate.venues = items.map(item => item.id);;
        this.props.onUpdate(checklistTemplate);
    }

    onSecurityFirmChanged(items) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate.securityFirms = items.map(item => item.id);;
        this.props.onUpdate(checklistTemplate);
    }

    getSecurityFirmOptions(): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.securityFirms && this.props.checklistTemplate.venues) {
                this.props.securityFirms.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.securityFirms.indexOf(item.id) > -1;
                    items.push({
                        id: item.id,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected,
                        locked: this.props.securityFirmId !== undefined && this.props.securityFirmId === item.id
                    });
                });
            }
        } catch {

        }
        return items;
    }

    isSecurityFirmLocked(): boolean {
        let result: boolean = false;
        try {
            if (this.props.securityFirms && this.props.checklistTemplate.securityFirms) {
                this.props.securityFirms.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.securityFirms.indexOf(item.id) > -1;
                    if (isSelected && this.props.securityFirmId !== undefined && this.props.securityFirmId === item.id) {
                        result = true;
                    }
                });
            }
        } catch {

        }
        return result;
    }

    getIndustryCategoriesOptions(): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];

        try {
            if (this.props.industryCategories && this.props.checklistTemplate.industryCategories) {
                this.props.industryCategories.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.industryCategories.indexOf(item.industryCategoryId) > -1;
                    items.push({
                        id: item.industryCategoryId,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    onIndustryCategoriesChanged(items) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate.industryCategories = items.map(item => item.id);
        this.props.onUpdate(checklistTemplate);
    }

    getStatesOptions(): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.states && this.props.checklistTemplate.states) {
                this.props.states.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.states.indexOf(item.stateId) > -1;
                    items.push({
                        id: item.stateId,
                        name: item.displayName,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    onStatesChanged(items) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate.states = items.map(item => item.id);;
        this.props.onUpdate(checklistTemplate);
    }

    getChecklistTemplateCategoriesOptions(): MulitSelectOption[] {
        let items: MulitSelectOption[] = [];
        try {
            if (this.props.checklistTemplateCategories && this.props.checklistTemplate.checklistTemplateCategories) {
                this.props.checklistTemplateCategories.forEach((item, index) => {
                    let isSelected = this.props.checklistTemplate.checklistTemplateCategories.indexOf(item.checklistTemplateCategoryId) > -1;
                    items.push({
                        id: item.checklistTemplateCategoryId,
                        name: item.name,
                        isCommon: false,
                        selected: isSelected
                    });
                });
            }
        } catch {

        }
        return items;
    }

    onChecklistTemplateCategoriesChanged(items) {
        let checklistTemplate = { ...this.props.checklistTemplate };
        checklistTemplate.checklistTemplateCategories = items.map(item => item.id);
        this.props.onUpdate(checklistTemplate);
    }

    render() {
        let isGroup: boolean = this.props.checklistTemplate.groupId != null && this.props.checklistTemplate.groupId > 0;

        return (
            <CardContainer
                title={"Details"}>
                <CardContent>
                    <LayoutForm>
                        <TextControlGroup
                            text={"Name"}
                            defaultValue={this.props.checklistTemplate.name}
                            error={this.props.errors["name"]}
                            onBlur={(value) => this.onChange("name", value)} />
                        <TextAreaControlGroup
                            text={"Description"}
                            defaultValue={this.props.checklistTemplate.description}
                            error={this.props.errors["description"]}
                            onBlur={(value) => this.onChange("description", value)} />

                        <Grid container style={{ marginTop: '10px' }}>
                            <Grid item xs={12}>
                                {this.props.canEditCategories && (
                                    <>
                                        <MultiSelectControl
                                            label={"Template Categories"}
                                            items={this.getChecklistTemplateCategoriesOptions()}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onChecklistTemplateCategoriesChanged}
                                            canShowAll={true} />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {isGroup ? <>
                            {this.props.checklistTemplate.checklistTemplateId > 0 &&
                                <CheckboxControlGroup
                                    text={"Active"}
                                    label={" "}
                                    isReversed={true}
                                    defaultValue={this.props.checklistTemplate.obsolete}
                                    onChanged={(value) => this.onChange("obsolete", value)} />}
                        </>
                            : <>
                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item xs={5}>
                                        {this.props.canEditVenues && (
                                            <>
                                                <CheckboxControl
                                                    text={"Enable Checklist for Venues"}
                                                    defaultValue={this.props.checklistTemplate.enabledVenues}
                                                    readonly={this.isVenueLocked()}
                                                    onChanged={(value) => this.onChange("enabledVenues", value)} />
                                                <MultiSelectControl
                                                    label={"Venues"}
                                                    items={this.getVenueOptions()}
                                                    searchText="Type at least three letters..."
                                                    minimumCharactersRequired={3}
                                                    isReadonly={!this.props.checklistTemplate.enabledVenues}
                                                    onSelectItemsChanged={this.onVenuesChanged}
                                                    canShowAll={true} />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={5}>
                                        {this.props.canEditSecurityFirms && (
                                            <>
                                                <CheckboxControl
                                                    text={"Enable Checklist for Security Firms"}
                                                    defaultValue={this.props.checklistTemplate.enabledSecurityFirms}
                                                    readonly={this.isSecurityFirmLocked()}
                                                    onChanged={(value) => this.onChange("enabledSecurityFirms", value)} />
                                                <MultiSelectControl
                                                    label={"Security Firms"}
                                                    items={this.getSecurityFirmOptions()}
                                                    isReadonly={!this.props.checklistTemplate.enabledSecurityFirms}
                                                    searchText="Type at least three letters..."
                                                    minimumCharactersRequired={3}
                                                    onSelectItemsChanged={this.onSecurityFirmChanged}
                                                    canShowAll={true} />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginTop: '10px' }}>
                                    <Grid item xs={5}>
                                        {this.props.canEditIndustryCategories && (
                                            <>
                                                <MultiSelectControl
                                                    label={"Industry Categories"}
                                                    items={this.getIndustryCategoriesOptions()}
                                                    searchText="Type at least three letters..."
                                                    minimumCharactersRequired={3}
                                                    onSelectItemsChanged={this.onIndustryCategoriesChanged}
                                                    canShowAll={true} />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={5}>
                                        {this.props.canEditStates && (
                                            <>
                                                <MultiSelectControl
                                                    label={"States"}
                                                    items={this.getStatesOptions()}
                                                    searchText="Type at least three letters..."
                                                    minimumCharactersRequired={3}
                                                    onSelectItemsChanged={this.onStatesChanged}
                                                    canShowAll={true} />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </>}
                    </LayoutForm>
                </CardContent>
            </CardContainer>

        );
    }
}