import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import TextDisplay from '../../controls/TextDisplay';
import ControlGroup from '../../controls/ControlGroup';

export interface IIncidentViewSnapshotProps {
    incident: IncidentDTO;
}

export default class IncidentViewSnapshot extends Component<IIncidentViewSnapshotProps, any> {
    render() {
        if (!this.props.incident.regulatedWalkthroughSummary) {
            return <></>;
        }
        return (
            <ControlGroup text="Snapshot Summary">
                <TextDisplay>{this.props.incident.regulatedWalkthroughSummary}</TextDisplay>
            </ControlGroup>
        );
    }
}