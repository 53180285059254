import { connect } from 'react-redux'
import UserActionAudits from '../components/admin/user/userActionAudits/UserActionAudits'
import { withRouter } from "react-router";
import { adminUserActionAuditRequest } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        userId: props.userId,
        isLoading: state.admin.userActionAudits.isLoading,
        userActionAudits: state.admin.userActionAudits.userActionAudits,
        paging: state.admin.userActionAudits.paging
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(userId, page, pageSize) => {
            dispatch(adminUserActionAuditRequest(userId, page, pageSize))
        }
    }
}

const AdminUserActionAuditContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserActionAudits))

export default AdminUserActionAuditContainer