import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface ISavingProps {
    message?: string;
}

export default class Saving extends Component<ISavingProps, any> {

    render() {
        return (
            <div style={{ display: 'block', padding: '10px', textAlign: "center", color: 'white' }}>
               <CircularProgress style={{ color:"green" }} />
               <h6>{this.props.message || "Saving..."}</h6>
            </div>
        );
    }
}