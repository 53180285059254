import React, { Component } from 'react';
import NavigationContainer from '../../containers/NavigationContainer';
import AuthRoute from '../routes/AuthRoute';
import { BrowserRouter, Router } from 'react-router-dom';
import { appSource } from '../../utilities/constants';
import Loading from '../../components/common/Loading';


export interface IMasterPageProps {
    isLoggedIn: boolean,
    isUserLoading: boolean,
    awaitingInit: boolean
}


class MasterPage extends Component<IMasterPageProps, any> {
    render() {
        let display;
        if (this.props.awaitingInit) {
            return (
                <Loading />
            );
        }
        if (this.props.isLoggedIn) {
            display = (
                <NavigationContainer />
            );
        } else {
            display = (
                <BrowserRouter basename={appSource.getBasePath()}>
                    <AuthRoute />
                </BrowserRouter>
            );
        }
        return display;
    }
}

export default MasterPage;