import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ErrorButton from '../../controls/ErrorButton';
import Grid from '@material-ui/core/Grid';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';

export interface ISecurityFirmUtilitiesProps {
    securityFirmId: number;
    onNotificationClear: Function;
}

export default class SecurityFirmUtilities extends Component<ISecurityFirmUtilitiesProps, any> {
    constructor(props: ISecurityFirmUtilitiesProps) {
        super(props)
        let date = new AusComplyDtos.SimpleDate();
        date.isEmpty = true;
        this.state = {
            date
        };
        this.onNotificationClear = this.onNotificationClear.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let state = { ...this.state };
        state[fieldName] = value;
        this.setState(state);
    }

    onNotificationClear() {
        if (!this.state.date.isEmpty) {
            this.props.onNotificationClear(this.props.securityFirmId, this.state.date);
        }
    }

    render() {
        let self = this;

        return (
            <>
                <LayoutForm
                    fullWidth={true}
                >
                    <form>
                        <SimpleDateControlGroup text="Clear notifications on or earlier than" date={this.state.date} onChange={(value) => this.onValueChanged("date", value)} />
                        <ControlGroup text={" "}>
                            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <ErrorButton disabled={this.state.date.isEmpty} onClick={() => this.onNotificationClear()}>Clear</ErrorButton>
                                </Grid>
                            </Grid>
                        </ControlGroup>
                    </form>
                </LayoutForm>
                <VenueQuickActionsContainer>
                </VenueQuickActionsContainer>
            </>
        );
    }
}