import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import CardContainer from '../../common/CardContainer';
import Grid from '@material-ui/core/Grid';
import CardTitle from '../../common/CardTitle';
import CardTypography from '../../common/CardTypography';
import CheckboxControl from '../../controls/CheckboxControl';
import FormErrorMessage from '../../alerts/FormErrorMessage';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TransparentButton from '../../controls/TransparentButton';

export interface IFacialRecognitionCameraImportProps {
    venueId: number;
    buttonMode?: boolean;
    facialRecognitionCameraImports: AusComplyDtos.FacialRecognitionCameraImport[];
    isLoading: boolean;
    isSaving: boolean;
    isSavedSuccessful: boolean
    onLoad: Function;
    onLoadCameras: Function;
    onSet: Function;
    onSave: Function;
}

interface IFacialRecognitionCameraImportState {
    show: boolean;
}

export default class FacialRecognitionCameraImport extends Component<IFacialRecognitionCameraImportProps, IFacialRecognitionCameraImportState> {
    constructor(props: IFacialRecognitionCameraImportProps) {
        super(props)
        this.state = {
            show: false
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onLoad = this.onLoad.bind(this);
    }

    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.isSavedSuccessful) {
                this.setState({ show: false }, () => {
                    self.props.onLoadCameras(this.props.venueId);
                });
            }
        }
    }

    onValueChanged(index: number, fieldName: string, value: any) {
        let facialRecognitionCameraImports = [...this.props.facialRecognitionCameraImports];
        facialRecognitionCameraImports[index][fieldName] = value;
        this.props.onSet(facialRecognitionCameraImports)
    }

    onCancel() {
        this.setState({ show: false });
    }

    onLoad() {
        this.setState({ show: true }, () => {
            this.props.onLoad(this.props.venueId);
        });
    }

    render() {
        let self = this;
        let importCameras;
        if (this.props.isSaving) {
            importCameras = <Saving />
        } else if (this.props.isLoading) {
            importCameras = <Loading />
        } else if (!this.props.isLoading && this.props.facialRecognitionCameraImports) {
            const data = this.props.facialRecognitionCameraImports;
            importCameras = <>
                {data.map((item, index) => <div key={item.cameraGuid + " " + item.error}>
                    <CardContainer style={{ padding: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item sm={1} xs={1}>
                                <CheckboxControl defaultValue={item.selected} readonly={item.success} onChanged={(value) => this.onValueChanged(index, "selected", value)} />
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <CardTitle>Camera</CardTitle>
                                <CardTypography>{item.name}<br /><small>{item.cameraGuid}</small></CardTypography>
                            </Grid>
                            <Grid item sm={5} xs={5}>
                                <CardTitle>From Server</CardTitle>
                                <CardTypography>{item.facialRecognitionServer.name}</CardTypography>
                            </Grid>
                        </Grid>
                        <FormErrorMessage error={item.error} />
                    </CardContainer>
                </div>)}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <PrimaryButton text={"Save"} onClick={() => self.props.onSave()}></PrimaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultButton text={"Cancel"} onClick={() => self.onCancel()}></DefaultButton>
                    </Grid>
                </Grid>
            </>
        }

        if (this.props.buttonMode) {
            return (
                <>
                    <TransparentButton
                        onClick={() => { if (!self.props.isLoading) { self.onLoad() } }} text={"Import Cameras"} />
                    <div style={{ display: 'inline-table' }}>
                        <FullPageDialog open={this.state.show || this.props.isLoading} title={"Import Cameras"} onDismissed={() => this.onCancel()}>
                            {importCameras}
                        </FullPageDialog>
                    </div>
                </>
            );
        }
        return (
            <>
                <SecondaryFab
                    isExtended={true}
                    onClick={() => { if (!self.props.isLoading) { self.onLoad() } }}>Import Cameras</SecondaryFab>
                <div style={{ display: 'inline-table' }}>
                    <FullPageDialog open={this.state.show || this.props.isLoading} title={"Import Cameras"} onDismissed={() => this.onCancel()}>
                        {importCameras}
                    </FullPageDialog>
                </div>
            </>
        );
    }
}