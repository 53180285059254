import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption } from '../../../common/dto/common';
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import RadioControl from '../../controls/RadioControl';

export interface IRegisterUserAsProps {
    user: AusComplyDTOs.User;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserAs extends Component<IRegisterUserAsProps, any> {
    constructor(props: IRegisterUserAsProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldName, value) {
        let user = { ...this.props.user };
        user[fieldName] = value;
        this.props.onUpdate(user);
    }

    render() {
        let staffLabel = "Staff";
        if(this.props.user && this.props.user.registerUserTo == AusComplyDTOs.ngtRegisterUserTo.Security){
            staffLabel = "Security";
        }
        let options: RadioOption[] = [
            { id: AusComplyDTOs.ngtRegisterUserAs.Staff, name: staffLabel },
            { id: AusComplyDTOs.ngtRegisterUserAs.Other, name: "Other / Admin" },
            { id: AusComplyDTOs.ngtRegisterUserAs.RSAMarshal, name: "RSA Marshal" }
        ];

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Register as"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <RadioControl
                                        selected={this.props.user.registerUserAs}
                                        options={options}
                                        name={"registerUserAs"}
                                        onChange={(value) => this.onChange("registerUserAs", value.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}