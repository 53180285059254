import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueBroadcastNotifications from '../components/venue/broadcastNotifications/VenueBroadcastNotifications'

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueBroadcastNotificationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueBroadcastNotifications))

export default VenueBroadcastNotificationContainer