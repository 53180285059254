import React, { Component } from 'react';

export interface FormWarningMessageProps {
    warning?: string;
}
class FormWarningMessage extends React.PureComponent<FormWarningMessageProps, any> {
    render() {
        if (!this.props.warning && !this.props.children) {
            return <div></div>;
        }
        return (
            <div style={{
                padding: '10px',
                textAlign: 'center',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '8px',
                background: '#feffc6',
                borderColor: '#d4d17d',
                color: '#5d5c1d',
                marginBottom: '10px'
                }}>
                <p style={{margin: 0}}>
                    {this.props.warning}{this.props.children}
                </p>
            </div>
        );
    }
}

export default FormWarningMessage;