import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { broadcastNotificationServiceApi } from '../services/broadcastNotification';
import { notificationSuccessShow } from "../actions/notification";
import * as broadcastNotificationActions from '../actions/broadcastNotification';
import { notifyError } from './common';

const broadcastNotificationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationsRequestSuccess(response.broadcastNotifications, response.paging, response.filter)),
                    catchError(error => notifyError(error, "broadcastNotificationsRequestEpic.find", broadcastNotificationActions.broadcastNotificationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationsRequestEpic", broadcastNotificationActions.broadcastNotificationsRequestFailure))
    );

const broadcastNotificationsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.broadcastNotification.broadcastNotifications.paging.page,
                state.broadcastNotification.broadcastNotifications.paging.pageSize,
                state.broadcastNotification.broadcastNotifications.filter)
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationsRequestSuccess(response.broadcastNotifications, response.paging, response.filter)),
                    catchError(error => notifyError(error, "broadcastNotificationsRefreshEpic.find", broadcastNotificationActions.broadcastNotificationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationsRefreshEpic", broadcastNotificationActions.broadcastNotificationsRequestFailure))
    );

const broadcastNotificationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['broadcastNotificationId'])
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationRequestSuccess(response.broadcastNotification)),
                    catchError(error => notifyError(error, "broadcastNotificationRequestEpic.get", broadcastNotificationActions.broadcastNotificationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationRequestEpic", broadcastNotificationActions.broadcastNotificationRequestFailure))
    );

const broadcastNotificationCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'],
                action['securityFirmId'],
                action['cloneId'])
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationCreateRequestSuccess(response.broadcastNotification)),
                    catchError(error => notifyError(error, "broadcastNotificationCreateEpic.create", broadcastNotificationActions.broadcastNotificationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationCreateEpic", broadcastNotificationActions.broadcastNotificationCreateRequestFailure))
    );

const broadcastNotificationUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.broadcastNotification.broadcastNotification.broadcastNotification)
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationUpsertRequestSuccess(response.broadcastNotification)),
                    catchError(error => notifyError(error, "broadcastNotificationUpsertEpic.upsert", broadcastNotificationActions.broadcastNotificationUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationUpsertEpic", broadcastNotificationActions.broadcastNotificationUpsertRequestFailure))
    );

const broadcastNotificationUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => broadcastNotificationActions.broadcastNotificationReset())
    );

const broadcastNotificationUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => broadcastNotificationActions.broadcastNotificationsRefresh())
    );

const broadcastNotificationTestEpic = (action$, state$) =>
    action$.pipe(
        ofType(broadcastNotificationActions.BROADCAST_NOTIFICATION_TEST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            broadcastNotificationServiceApi.test(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['broadcastNotificationId'])
                .pipe(
                    map(response => broadcastNotificationActions.broadcastNotificationsTestRequestSuccess()),
                    catchError(error => notifyError(error, "broadcastNotificationTestEpic.test", broadcastNotificationActions.broadcastNotificationsTestRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "broadcastNotificationTestEpic", broadcastNotificationActions.broadcastNotificationsTestRequestFailure))
    );

export const broadcastNotificationEpics = combineEpics(
    broadcastNotificationsRefreshEpic,
    broadcastNotificationsRequestEpic,
    broadcastNotificationRequestEpic,
    broadcastNotificationCreateEpic,
    broadcastNotificationUpsertEpic,
    broadcastNotificationUpsertSuccessEpic,
    broadcastNotificationUpsertSuccessRefreshEpic,
    broadcastNotificationTestEpic
);