import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardTypography from '../common/CardTypography';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardRow from '../common/CardRow';


export interface ICheckListTemplateSummaryCardProps {
    checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary;
    onClick?: Function;
    classes: any;
    theme: any;
}

class CheckListTemplateSummaryCard extends React.Component<ICheckListTemplateSummaryCardProps, any> {

    constructor(props: ICheckListTemplateSummaryCardProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { classes, theme } = this.props;
        let className = classes.cardChecklistInClosed;

        return (
            <CardRow isSuccess={this.props.checklistTemplateSummary.isGlobal} isInfo={this.props.checklistTemplateSummary.isGroup}>
                <Grid container spacing={1} onClick={this.onClick}>
                    <Grid item xs={12}>
                        <CardTypography><b>{this.props.checklistTemplateSummary.name}</b></CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={this.onClick}>
                    <Grid item xs={6}>
                        <CardTypography style={{ fontStyle: 'italic', textAlign: 'center' }}>
                            Item count: {this.props.checklistTemplateSummary.itemCount}
                        </CardTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <CardTypography style={{ fontStyle: 'italic', textAlign: 'center' }}>
                            Checklist count: {this.props.checklistTemplateSummary.instanceCount}
                        </CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListTemplateSummaryCard);