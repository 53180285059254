import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { documentTypeLogic } from '../../../common/logic/documentTypeLogic';

export interface IDocumentTypeRowProps {
    documentType: AusComplyDtos.DocumentType;
    onCommand?: Function;
}

export default class DocumentTypeRow extends React.Component<IDocumentTypeRowProps, any> {
    constructor(props: IDocumentTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.documentType);
        }
    }

    render() {
        if (!this.props.documentType) {
            return (<></>);
        }

        var commands = documentTypeLogic.getCommands(this.props.documentType);
        var item = this.props.documentType;

        return (
            <TableRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.documentCategory}
                    </p>
                </td>
                <td>
                    <p>
                        /
                    </p>
                </td>
                <td>
                    <p>
                        <b>{item.name}</b>
                    </p>
                </td>
                <td>
                    <p>
                        {item.entityType}
                    </p>
                </td>
            </TableRow>
        );
    }
}