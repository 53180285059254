import { connect } from 'react-redux'
import SignOff from '../components/signon/SignOff'
import { signOffRequest, signOffSaveRequest } from '../common/actions/signOn';
import { withRouter } from "react-router";
import { locationRequest } from '../common/actions/location';

const hasPermission = (permissions: string[], permission: string) => {
    if (permissions){
        return permissions.indexOf(permission) > -1;
    }
    return false;
}

const mapStateToProps = (state, props) => {
    return {
        userSignOnStatusId: props.match.params.usersignoffstatusid,
        userSignOnStatus: state.signOn.userSignOnStatus,
        signOffDetails: state.signOn.signOffDetails,
        isLoading: state.signOn.isLoading,
        isSaving: state.signOn.isSaving,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        saveError: state.signOn.error,
        canViewSignOnRegister: hasPermission(state.user.details.userSession.permissions, "SignOnOffRegisterView")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (userSignOnStatusId) => {
            dispatch(signOffRequest(userSignOnStatusId))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onSignOff: (userSignOnStatus, isAcknowledged) => {
            dispatch(signOffSaveRequest(userSignOnStatus, isAcknowledged))
        }
    }
}

const SignOffContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOff))

export default SignOffContainer