import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueReportingPeriodSchedules from '../components/venue/reportingPeriodSchedules/VenueReportingPeriodSchedules'
import { 
    venueReportingPeriodSchedulesRequest, 
    venueReportingPeriodScheduleCreateRequest, 
    venueReportingPeriodScheduleRequest, 
    venueReportingPeriodScheduleReset,
    venueReportingPeriodScheduleSet,
    venueReportingPeriodScheduleUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        isLoading: state.venue.venueReportingPeriodSchedules.isLoading,
        venueReportingPeriodSchedules: state.venue.venueReportingPeriodSchedules.venueReportingPeriodSchedules,
        venueReportingPeriodScheduleId: state.venue.venueReportingPeriodSchedules.venueReportingPeriodScheduleId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueReportingPeriodSchedulesRequest(venueId))
        },
        onLoadItem: (venueReportingPeriodScheduleId) => {
            dispatch(venueReportingPeriodScheduleRequest(venueReportingPeriodScheduleId))
        },
        onCreate: (venueId) => {
            dispatch(venueReportingPeriodScheduleCreateRequest(venueId))
        },
        onResetItem: () => {
            dispatch(venueReportingPeriodScheduleReset())
        },
        onUpdate: (venueReportingPeriodSchedule) => {
            dispatch(venueReportingPeriodScheduleSet(venueReportingPeriodSchedule))
        },
        onSave: () => {
            dispatch(venueReportingPeriodScheduleUpsertRequest())
        }
    }
}

const VenueReportingPeriodSchedulesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueReportingPeriodSchedules))

export default VenueReportingPeriodSchedulesContainer