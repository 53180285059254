import { connect } from 'react-redux' 
import VenueQuickActions from '../components/home/VenueQuickActions'
import { draftIncidentCreate} from '../common/actions/draftIncident'
import { withRouter } from "react-router";
import { incidentLogic } from '../common/logic/incidentLogic';

const mapStateToProps = (state, props) => {
    return {
        compact: props.compact,
        venueIsSelected: state.user.details.venueIsSelected && !state.user.details.userSession.user.pendingInvalidSLEDAccess && state.user.details.userSession.venueId && state.user.details.userSession.venueId > 0,
        requiresSecurityCode: state.user.details.userSession.user.requiresSecurityCode,
        isStatisticsLoading: state.userVenueSecurityFirm.isLoading,
        statistics: state.userVenueSecurityFirm.statistics,
        hasDraftIncident: state.draftIncident.hasDraftIncident,
        entryPage: incidentLogic.incidentTypeEntryPage(state.draftIncident.incident, state.draftIncidentOptions.incidentOptions),
        draftIncidentTypeId: state.draftIncident.incident && state.draftIncident.incident.incidentTypeId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateDraftIncident: (incidentTypeId) =>{
            dispatch(draftIncidentCreate(incidentTypeId, ""))
        }
    }
}

const VenueQuickActionsContainer =  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueQuickActions))

export default VenueQuickActionsContainer
