import { connect } from 'react-redux'
import VenueGroups from '../components/venue/venueGroups/VenueGroups';
import {
    venueRequest
} from '../common/actions/venue';

import {
    groupAccessRequest
} from '../common/actions/groups';

const mapStateToProps = state => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        isLoading: state.venue.isLoading,
        venue: state.venue.venue,
        isSaving: state.groups.group.isSavingUserRequest
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueRequest(venueId, false))
        },
        onRequestAccess: (groupId) => {
            dispatch(groupAccessRequest(groupId))
        }
    }
}

const VenueGroupsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueGroups)

export default VenueGroupsContainer