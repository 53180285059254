import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FacialRecognitionWatchlistPois from '../components/facialRecognition/facialRecognitionWatchlists/FacialRecognitionWatchlistPois'
import {
    facialRecognitionWatchlistPoiSaveRequest,
    facialRecognitionWatchlistPoiSet,
    facialRecognitionWatchlistPoisReset,
    facialRecognitionWatchlistPoisRequest,
    facialRecognitionWatchlistPoisRemoveRequest,
    facialRecognitionWatchlistPoisThumbnails
} from '../common/actions/facialRecognitionWatchlist';

import {
    facialRecognitionPoiGenerateAppearanceRequest
} from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        isSaving: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.isSaving,
        venueId: props.venueId,
        thumbnails: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.thumbnails,
        facialRecognitionWatchlistId: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistId,
        isLoading: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.isLoading,
        data: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistPois,
        facialRecognitionWatchlistPoi: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistPoi,
        filter: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.filter,
        paging: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.paging,
        canGenerateAppearance: permissionsLogic.isLevelSix(state),
        cameras: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.cameras
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (facialRecognitionWatchlistPoi) => {
            dispatch(facialRecognitionWatchlistPoiSet(facialRecognitionWatchlistPoi))
        },
        onSave: (page, pageSize, filter) => {
            dispatch(facialRecognitionWatchlistPoiSaveRequest(page, pageSize, filter));
        },
        onReset: () => {
            dispatch(facialRecognitionWatchlistPoisReset())
        },
        onLoad:(facialRecognitionWatchlistId, page, pageSize, filter) => {
            dispatch(facialRecognitionWatchlistPoisRequest(facialRecognitionWatchlistId, page, pageSize, filter))
        },
        onRemove: (facialRecognitionWatchlistId, poiGuid) => {
            dispatch(facialRecognitionWatchlistPoisRemoveRequest(facialRecognitionWatchlistId, poiGuid))
        },
        onGenerateAppearance: (poiGuid, facialRecognitionWatchlistId, facialRecognitionCameraId) => {
            dispatch(facialRecognitionPoiGenerateAppearanceRequest(poiGuid, facialRecognitionWatchlistId, facialRecognitionCameraId, 0))
        },
        onThumbnails: (thumbnails) => {
            dispatch(facialRecognitionWatchlistPoisThumbnails(thumbnails))
        }
    }
}

const FacialRecognitionWatchlistPoisContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionWatchlistPois))

export default FacialRecognitionWatchlistPoisContainer