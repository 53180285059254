import { connect } from 'react-redux'
import { withRouter } from "react-router";
import ScheduleLogs from '../components/schedule/ScheduleLogs'
import * as scheduleActions from '../common/actions/schedule';

const mapStateToProps = (state, props) => {
    return {
        scheduleId: props.match.params.scheduleId,
        schedule: state.schedules.schedule.schedule,
        scheduleLogs:  state.schedules.schedule.scheduleLogs.data,
        paging:  state.schedules.schedule.scheduleLogs.paging,
        isLoading: state.schedules.schedule.isLoading || state.schedules.schedule.scheduleLogs.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (scheduleId, paging) => {
            dispatch(scheduleActions.scheduleLogsRequest(scheduleId, paging))
        },
        onDelete: (scheduleLogId, scheduleId, paging) => {
            dispatch(scheduleActions.scheduleLogDeleteRequest(scheduleLogId, scheduleId, paging))
        },
        onLoadSchedule: (scheduleId) => {
            dispatch(scheduleActions.scheduleRequest(scheduleId))
        }
    }
}

const SecurityFirmScheduleLogsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ScheduleLogs))

export default SecurityFirmScheduleLogsContainer