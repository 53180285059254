import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionNotificationEvents from '../components/venue/facialRecognitionNotifications/FacialRecognitionNotificationEvents'
import { 
    facialRecognitionNotificationEventRequest, 
    facialRecognitionNotificationEventSet,
    facialRecognitionNotificationEventSaveRequest
 } from '../common/actions/facialRecognitionNotification';

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        isSaving: state.facialRecognitionNotification.facialRecognitionEvents.isSaving,
        isLoading: state.facialRecognitionNotification.facialRecognitionEvents.isLoading,
        facialRecognitionEvents: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvents,
        facialRecognitionEvent: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvent,
        facialRecognitionCameras: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionCameras,
        facialRecognitionWatchlists: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionWatchlists,
        incidentTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentTypes,
        incidentTypeCategoryTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentTypeCategoryTypes,
        incidentFlagTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentFlagTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (forVenueId) => {
            dispatch(facialRecognitionNotificationEventRequest(forVenueId, 0))
        },
        onSet: (facialRecognitionEvent) => {
            dispatch(facialRecognitionNotificationEventSet(facialRecognitionEvent))
        },
        onSave: () => {
            dispatch(facialRecognitionNotificationEventSaveRequest())
        }
    }
}

const VenueFacialRecognitionNotificationEventsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionNotificationEvents))

export default VenueFacialRecognitionNotificationEventsContainer