import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardRow from '../../common/CardRow';
import { useDrag, DragSourceMonitor, DragSourceConnector, ConnectDragSource, DragSource } from 'react-dnd'
import { ItemTypes } from './ItemTypes';

export interface IChecklistItemTypeProps {
    title: string;
    description: string;
    checklistItemType: AusComplyDtos.ChecklistItemType;
    onAdd: Function;
    // Collected Props
    connectDragSource: ConnectDragSource;
    isDragging?: boolean;
    isDropped?: boolean;
    style?: any;
}


class ChecklistItemType extends React.Component<IChecklistItemTypeProps, any> {

    constructor(props: IChecklistItemTypeProps) {
        super(props)
    }

    componentDidMount() {
    }

    render() {
        const opacity = this.props.isDragging ? 0.8 : 1
        return this.props.connectDragSource(
            <div>
                <CardRow style={{ ...this.props.style, opacity, cursor: 'move' }} cardContentStyle={{padding: '10px'}}>
                    <CardTitle>{this.props.title}</CardTitle>
                    <CardTypography style={{ whiteSpace: 'none' }}>{this.props.description}</CardTypography>
                </CardRow>
            </div>
        );
    }
}


export default DragSource((props: IChecklistItemTypeProps) => 
    ItemTypes.BOX,
    {
        beginDrag: (props: IChecklistItemTypeProps) => ({ name: props.title }),
        endDrag(props: IChecklistItemTypeProps, monitor: DragSourceMonitor) {
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult()
      
            if (dropResult) {
              let alertMessage = '';
              const isDropAllowed = dropResult.allowedDropEffect === 'any';
      
              if (isDropAllowed) {
                alertMessage = `You dropped ${item.name} into ${dropResult.name}!`;
                if(props.onAdd) {
                    props.onAdd(props.checklistItemType, dropResult.parentId, dropResult.position);
                }
              } 
            }
          },
    },
    (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(ChecklistItemType)