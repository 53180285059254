import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { broadcastNotificationLogic } from '../../../common/logic/broadcastNotificationLogic';

export interface IBroadcastNotificationRowProps {
    broadcastNotification: AusComplyDtos.BroadcastNotification;
    canEdit: boolean;
    onCommand?: Function;
}

export default class BroadcastNotificationRow extends React.Component<IBroadcastNotificationRowProps, any> {
    constructor(props: IBroadcastNotificationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.broadcastNotification);
        }
    }

    render() {
        if (!this.props.broadcastNotification) {
            return (<></>);
        }

        var commands = broadcastNotificationLogic.getCommands(this.props.broadcastNotification, this.props.canEdit);
        var item = this.props.broadcastNotification;

        var mode = "Email and SMS";
        let email = "";
        let shortMessage = "";
        if(item.sendMode == AusComplyDtos.ngtBroadcastOption.EmailAndSms) {
            mode ="Email and SMS";
            email = (item.subject ? item.subject + ", " : "") + (item.message ? item.message : "");
            shortMessage = (item.shortMessage ? item.shortMessage : "");
        }
        if(item.sendMode == AusComplyDtos.ngtBroadcastOption.Email) {
            mode ="Email";
            email = (item.subject ? item.subject + ", " : "") + (item.message ? item.message : "");
        }
        if(item.sendMode == AusComplyDtos.ngtBroadcastOption.Sms) {
            mode ="SMS";
            shortMessage = (item.shortMessage ? item.shortMessage : "");
        }
        if (email.length > 50) {
            email = email.substring(0, 50) + "...";
        }
        if (shortMessage.length > 50) {
            shortMessage = shortMessage.substring(0, 50) + "...";
        }

        return (
            <TableRow 
                isSuccess={item.isSent} 
                isWarning={!item.isSent && !item.obsolete} 
                isDanger={item.obsolete} 
                
                commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {mode}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {email}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {shortMessage}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.userRoleTypesDisplay}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.dateEnteredDisplay}
                    </p>
                </td>
            </TableRow>
        );
    }
}