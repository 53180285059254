import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import TransparentButton from '../../controls/TransparentButton';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import IncidentViewDates from '../view/IncidentViewDates';
import IncidentViewSnapshot from '../view/IncidentViewSnapshot';
import IncidentViewType from '../view/IncidentViewType';
import IncidentViewCategories from '../view/IncidentViewCategories';
import IncidentViewLocations from '../view/IncidentViewLocations';
import IncidentViewPersonnel from '../view/IncidentViewPersonnel';
import IncidentViewNarrative from '../view/IncidentViewNarrative';
import IncidentViewNumber from '../view/IncidentViewNumber';
import IncidentViewRelated from '../view/IncidentViewRelated';
import IncidentViewFlags from '../view/IncidentViewFlags';
import IncidentViewDocuments from '../view/IncidentViewDocuments';
import IncidentViewPeopleAndGroups from '../view/IncidentViewPeopleAndGroups';
import IncidentViewChecklists from '../view/IncidentViewChecklists';
import IncidentViewRadioChannelFunctionalArea from '../view/IncidentViewRadioChannelFunctionalArea';
import ErrorBoundary from '../../common/ErrorBoundary';
import PageLayout from '../../layout/PageLayout';

export interface IIncidentVersionProps {
    incidentId: number;
    securityFirmId: number;
    incidentRelevantId: number;
    version: number;
    incident: AusComplyDtos.Incident;
    isLoading: boolean;
    onLoadVersion: Function;
    history: any;
}

interface IIncidentVersionState {

}

class IncidentVersion extends Component<IIncidentVersionProps, IIncidentVersionState> {

    constructor(props: IIncidentVersionProps) {
        super(props)

        this.state = {
        }
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line
        this.props.onLoadVersion(this.props.securityFirmId, this.props.incidentId, this.props.incidentRelevantId, this.props.version);
    }

    componentDidUpdate(preProps) {
    }

    onCancel() {
        this.props.history.push('/incidentview/' + this.props.incidentId + "/versions");
    }

    render() {
        return <PageLayout
            headerText={"Incident Version"}
            footerLeftContent={<TransparentButton text={"Close"} onClick={() => this.onCancel()} />}
            loading={this.props.isLoading}
            hideQuickActions={true}
            >
            {(!this.props.isLoading && this.props.incident) && (
                <ErrorBoundary>
                    <IncidentViewNumber incident={this.props.incident} />
                    <IncidentViewRadioChannelFunctionalArea incident={this.props.incident} />
                    <IncidentViewDates incident={this.props.incident} />
                    <IncidentViewType incident={this.props.incident} />
                    <IncidentViewDocuments incident={this.props.incident} />
                    <IncidentViewCategories incident={this.props.incident} />
                    <IncidentViewSnapshot incident={this.props.incident} />
                    <IncidentViewLocations incident={this.props.incident} />
                    <IncidentViewPeopleAndGroups incident={this.props.incident} />
                    <IncidentViewPersonnel incident={this.props.incident} />
                    <IncidentViewNarrative incident={this.props.incident} />
                    <IncidentViewFlags incident={this.props.incident} />
                    <IncidentViewRelated incident={this.props.incident} />
                    <IncidentViewChecklists incident={this.props.incident} />
                </ErrorBoundary>
            )}
        </PageLayout>;

    }
}


export default withStyles(styles, { withTheme: true })(withRouter(IncidentVersion));