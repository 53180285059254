import React from "react";

const GroupAddIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 118.6 57"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M50.8,25.6c-5.6,0.3-11,4-13.3,9.9c-0.2,0.4-1.8,5.6-2.1,8h15.8c3.4-5.7,9.1-9.6,15.5-10.2 c-1.8-1.4-3.3-3.3-4.3-5.3c-1.2,0.4-2.5,0.7-3.9,0.7C55.6,28.5,52.9,27.4,50.8,25.6 M68.5,6.5C66.4,3.2,62.7,1,58.6,1 c-6.5,0-11.7,5.3-11.7,11.7c0,6.5,5.3,11.7,11.7,11.7c0.9,0,1.7-0.1,2.6-0.3c-0.3-1.2-0.4-2.5-0.4-3.8C60.7,14.5,63.8,9.4,68.5,6.5 M107.3,11.7C107.3,5.3,102,0,95.6,0c-4.5,0-8.4,2.5-10.3,6.2c4.9,2.8,8.3,8.1,8.3,14.2c0,1-0.1,1.9-0.3,2.9 c0.8,0.2,1.6,0.2,2.4,0.2C102,23.5,107.3,18.2,107.3,11.7 M118.6,42.4c-0.2-2.3-1.7-7.5-1.9-8c-2.4-5.9-7.7-9.6-13.4-9.9 c-2.1,1.8-4.8,2.9-7.8,2.9c-1.2,0-2.4-0.2-3.5-0.5c-1.1,2.4-2.7,4.5-4.7,6.2c6.1,0.6,11.5,4.1,15,9.2H118.6z" />
                <path fill={fill} d="M91,20.7C91,13,84.7,6.8,77.1,6.8c-7.7,0-13.9,6.2-13.9,13.9c0,7.7,6.2,13.9,13.9,13.9 C84.7,34.6,91,28.4,91,20.7 M102.1,47.6c-2.8-7-9.2-11.4-15.9-11.7c-2.5,2.2-5.7,3.5-9.2,3.5c-3.5,0-6.7-1.3-9.2-3.5 c-6.7,0.4-13,4.8-15.8,11.7c-0.2,0.5-2.2,6.7-2.4,9.4h54.6C104.1,54.3,102.3,48.1,102.1,47.6" />
            </g>
            <polygon fill={fill} points="13,6.8 13,19.8 0,19.8 0,22.8 13,22.8 13,35.8 16,35.8 16,22.8 29,22.8 29,19.8 16,19.8 16,6.8 " />
        </svg>
    );

export default GroupAddIcon;
