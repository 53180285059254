export const
    VENUE_REQUEST = "VENUE_REQUEST",
    VENUE_REQUEST_SUCCESS = "VENUE_REQUEST_SUCCESS",
    VENUE_REQUEST_FAILURE = "VENUE_REQUEST_FAILURE",
    VENUE_UPDATE = "VENUE_UPDATE",
    VENUE_SAVE_REQUEST = "VENUE_SAVE_REQUEST",
    VENUE_SAVE_REQUEST_SUCCESS = "VENUE_SAVE_REQUEST_SUCCESS",
    VENUE_SAVE_REQUEST_FAILURE = "VENUE_SAVE_REQUEST_FAILURE",
    VENUE_REPORTING_PERIODS_REQUEST = "VENUE_REPORTING_PERIODS_REQUEST",
    VENUE_REPORTING_PERIODS_REQUEST_SUCCESS = "VENUE_REPORTING_PERIODS_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIODS_REQUEST_FAILURE = "VENUE_REPORTING_PERIODS_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST = "VENUE_REPORTING_PERIOD_CURRENT_REQUEST",
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_CURRENT_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_CURRENT_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_CURRENT_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_RESET = "VENUE_REPORTING_PERIOD_RESET",
    VENUE_REPORTING_PERIOD_SET = "VENUE_REPORTING_PERIOD_SET",
    VENUE_REPORTING_PERIOD_REQUEST = "VENUE_REPORTING_PERIOD_REQUEST",
    VENUE_REPORTING_PERIOD_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_CREATE_REQUEST = "VENUE_REPORTING_PERIOD_CREATE_REQUEST",
    VENUE_REPORTING_PERIOD_CREATE_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_CREATE_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_CREATE_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_CREATE_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST = "VENUE_REPORTING_PERIOD_UPSERT_REQUEST",
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_UPSERT_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_UPSERT_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST = "VENUE_REPORTING_PERIOD_CLOSE_REQUEST",
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_CLOSE_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_CLOSE_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST = "VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST",
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_SCHEDULE_RESET = "VENUE_REPORTING_PERIOD_SCHEDULE_RESET",
    VENUE_REPORTING_PERIOD_SCHEDULE_SET = "VENUE_REPORTING_PERIOD_SCHEDULE_SET",
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST = "VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST",
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST = "VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST",
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_FAILURE",
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST = "VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST",
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS = "VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS",
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_FAILURE = "VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_FAILURE",
    VENUE_LOCATIONS_REQUEST = "VENUE_LOCATIONS_REQUEST",
    VENUE_LOCATIONS_REFRESH = "VENUE_LOCATIONS_REFRESH",
    VENUE_LOCATIONS_REQUEST_SUCCESS = "VENUE_LOCATIONS_REQUEST_SUCCESS",
    VENUE_LOCATIONS_REQUEST_FAILURE = "VENUE_LOCATIONS_REQUEST_FAILURE",
    VENUE_LOCATION_RESET = "VENUE_LOCATION_RESET",
    VENUE_LOCATION_SET = "VENUE_LOCATION_SET",
    VENUE_LOCATION_REQUEST = "VENUE_LOCATION_REQUEST",
    VENUE_LOCATION_REQUEST_SUCCESS = "VENUE_LOCATION_REQUEST_SUCCESS",
    VENUE_LOCATION_REQUEST_FAILURE = "VENUE_LOCATION_REQUEST_FAILURE",
    VENUE_LOCATION_CREATE_REQUEST = "VENUE_LOCATION_CREATE_REQUEST",
    VENUE_LOCATION_CREATE_REQUEST_SUCCESS = "VENUE_LOCATION_CREATE_REQUEST_SUCCESS",
    VENUE_LOCATION_CREATE_REQUEST_FAILURE = "VENUE_LOCATION_CREATE_REQUEST_FAILURE",
    VENUE_LOCATION_UPSERT_REQUEST = "VENUE_LOCATION_UPSERT_REQUEST",
    VENUE_LOCATION_UPSERT_REQUEST_SUCCESS = "VENUE_LOCATION_UPSERT_REQUEST_SUCCESS",
    VENUE_LOCATION_UPSERT_REQUEST_FAILURE = "VENUE_LOCATION_UPSERT_REQUEST_FAILURE",
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST = "VENUE_INCIDENT_CATEGORY_TYPES_REQUEST",
    VENUE_INCIDENT_CATEGORY_TYPES_REFRESH = "VENUE_INCIDENT_CATEGORY_TYPES_REFRESH",
    VENUE_INCIDENT_CATEGORY_TYPES_FILTER_SET = "VENUE_INCIDENT_CATEGORY_TYPES_FILTER_SET",
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS = "VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS",
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE = "VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE",
    VENUE_INCIDENT_CATEGORY_TYPE_RESET = "VENUE_INCIDENT_CATEGORY_TYPE_RESET",
    VENUE_INCIDENT_CATEGORY_TYPE_SET = "VENUE_INCIDENT_CATEGORY_TYPE_SET",
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST = "VENUE_INCIDENT_CATEGORY_TYPE_REQUEST",
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS = "VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS",
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE = "VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE",
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST = "VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST",
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS = "VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS",
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE = "VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE",
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST = "VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST",
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS = "VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS",
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE = "VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE",
    VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST = "VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST",
    VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS = "VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS",
    VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_FAILURE = "VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_FAILURE",
    VENUE_CONTACTS_REQUEST = "VENUE_CONTACTS_REQUEST",
    VENUE_CONTACTS_REFRESH = "VENUE_CONTACTS_REFRESH",
    VENUE_CONTACTS_REQUEST_SUCCESS = "VENUE_CONTACTS_REQUEST_SUCCESS",
    VENUE_CONTACTS_REQUEST_FAILURE = "VENUE_CONTACTS_REQUEST_FAILURE",
    VENUE_CONTACT_RESET = "VENUE_CONTACT_RESET",
    VENUE_CONTACT_SET = "VENUE_CONTACT_SET",
    VENUE_CONTACT_REQUEST = "VENUE_CONTACT_REQUEST",
    VENUE_CONTACT_REQUEST_SUCCESS = "VENUE_CONTACT_REQUEST_SUCCESS",
    VENUE_CONTACT_REQUEST_FAILURE = "VENUE_CONTACT_REQUEST_FAILURE",
    VENUE_CONTACT_CREATE_REQUEST = "VENUE_CONTACT_CREATE_REQUEST",
    VENUE_CONTACT_CREATE_REQUEST_SUCCESS = "VENUE_CONTACT_CREATE_REQUEST_SUCCESS",
    VENUE_CONTACT_CREATE_REQUEST_FAILURE = "VENUE_CONTACT_CREATE_REQUEST_FAILURE",
    VENUE_CONTACT_UPSERT_REQUEST = "VENUE_CONTACT_UPSERT_REQUEST",
    VENUE_CONTACT_UPSERT_REQUEST_SUCCESS = "VENUE_CONTACT_UPSERT_REQUEST_SUCCESS",
    VENUE_CONTACT_UPSERT_REQUEST_FAILURE = "VENUE_CONTACT_UPSERT_REQUEST_FAILURE",

    VENUE_SUBSCRIPTION_SUMMARY_REQUEST = "VENUE_SUBSCRIPTION_SUMMARY_REQUEST",
    VENUE_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS = "VENUE_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS",
    VENUE_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE = "VENUE_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE",

    VENUE_DOCUMENTS_REQUEST = "VENUE_DOCUMENTS_REQUEST",
    VENUE_DOCUMENTS_REQUEST_SUCCESS = "VENUE_DOCUMENTS_REQUEST_SUCCESS",
    VENUE_DOCUMENTS_REQUEST_FAILURE = "VENUE_DOCUMENTS_REQUEST_FAILURE",
    VENUE_OTHER_DOCUMENTS_SAVE = "VENUE_OTHER_DOCUMENTS_SAVE",
    VENUE_OTHER_DOCUMENTS_SAVE_SUCCESS = "VENUE_OTHER_DOCUMENTS_SAVE_SUCCESS",
    VENUE_OTHER_DOCUMENTS_SAVE_FAILURE = "VENUE_OTHER_DOCUMENTS_SAVE_FAILURE",
    VENUE_OTHER_DOCUMENT_REMOVE = "VENUE_OTHER_DOCUMENT_REMOVE",
    VENUE_OTHER_DOCUMENT_REMOVE_SUCCESS = "VENUE_OTHER_DOCUMENT_REMOVE_SUCCESS",
    VENUE_OTHER_DOCUMENT_REMOVE_FAILURE = "VENUE_OTHER_DOCUMENT_REMOVE_FAILURE",
    VENUE_COMPLIANCE_DOCUMENTS_SAVE = "VENUE_COMPLIANCE_DOCUMENTS_SAVE",
    VENUE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS = "VENUE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS",
    VENUE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE = "VENUE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE",
    VENUE_COMPLIANCE_DOCUMENT_REMOVE = "VENUE_COMPLIANCE_DOCUMENT_REMOVE",
    VENUE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS = "VENUE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS",
    VENUE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE = "VENUE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE",

    VENUE_SECURITYFIRM_ASSOCIATIONS_REFRESH="VENUE_SECURITYFIRM_ASSOCIATIONS_REFRESH",
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST = "VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST",
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_SUCCESS",
    VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_FAILURE = "VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_FAILURE",
    VENUE_SECURITYFIRM_ASSOCIATION_RESET = "VENUE_SECURITYFIRM_ASSOCIATION_RESET",
    VENUE_SECURITYFIRM_ASSOCIATION_SET = "VENUE_SECURITYFIRM_ASSOCIATION_SET",
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST = "VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST",
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS",
    VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_FAILURE = "VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_FAILURE",
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST = "VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST",
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS",
    VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_FAILURE = "VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_FAILURE",
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST = "VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST",
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS",
    VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE = "VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE",
    VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST = "VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST",
    VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS = "VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS",
    VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE = "VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE"
    ;

export function venueSecurityAssociationReset() {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_RESET
    };
}

export function venueSecurityAssociationSet(venueSecurityFirm) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_SET,
        venueSecurityFirm
    };
}

export function venueSecurityAssociationCreateRequest(forVenueId) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST,
        forVenueId
    };
}

export function venueSecurityAssociationCreateRequestSuccess(venueSecurityFirm, securityFirms) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS,
        venueSecurityFirm,
        securityFirms
    };
}

export function venueSecurityAssociationCreateRequestFailure(error) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueSecurityAssociationInsertRequest() {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST
    };
}

export function venueSecurityAssociationInsertRequestSuccess() {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS
    };
}

export function venueSecurityAssociationInsertRequestFailure(error) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_INSERT_REQUEST_FAILURE,
        error
    };
}

export function venueSecurityAssociationRemoveRequest(venueSecurityFirmId) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST,
        venueSecurityFirmId
    };
}

export function venueSecurityAssociationRemoveRequestSuccess() {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS
    };
}

export function venueSecurityAssociationRemoveRequestFailure(error) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function venueSecurityAssociationRestoreRequest(venueSecurityFirmId) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST,
        venueSecurityFirmId
    };
}

export function venueSecurityAssociationRestoreRequestSuccess() {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS
    };
}

export function venueSecurityAssociationRestoreRequestFailure(error) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE,
        error
    };
}

export function venueSecurityAssociationsRefresh() {
    return  {
        type: VENUE_SECURITYFIRM_ASSOCIATIONS_REFRESH
    }
}

export function venueSecurityAssociationsRequest(forVenueId, page, pageSize) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST,
        forVenueId,
        page,
        pageSize
    };
}

export function venueSecurityAssociationsRequestSuccess(venueSecurityFirmAssociations, paging) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_SUCCESS,
        venueSecurityFirmAssociations,
        paging
    };
}

export function venueSecurityAssociationsRequestFailure(error) {
    return {
        type: VENUE_SECURITYFIRM_ASSOCIATIONS_REQUEST_FAILURE,
        error
    };
}

export function venueDocumentsRequest(venueId, showObsoleteDocuments) {
    return {
        type: VENUE_DOCUMENTS_REQUEST,
        venueId,
        showObsoleteDocuments
    };
}

export function venueDocumentsRequestSuccess(
    venueOtherDocuments, 
    venueOtherDocumentTypes, 
    venueComplianceDocuments, 
    venueComplianceDocumentTypes,
    associatedSecurityComplianceDocuments,
    associatedSecurityComplianceDocumentTypes) {
    return {
        type: VENUE_DOCUMENTS_REQUEST_SUCCESS,
        venueOtherDocuments,
        venueOtherDocumentTypes,
        venueComplianceDocuments,
        venueComplianceDocumentTypes,
        associatedSecurityComplianceDocuments,
        associatedSecurityComplianceDocumentTypes
    };
}

export function venueDocumentsRequestFailure(error) {
    return {
        type: VENUE_DOCUMENTS_REQUEST_FAILURE,
        error
    };
}

export function venueSaveOtherDocuments(forVenueId, documentTypeId, files) {
    return {
        type: VENUE_OTHER_DOCUMENTS_SAVE,
        forVenueId,
        documentTypeId,
        files
    };
}

export function venueSaveOtherDocumentsSuccess(venueOtherDocuments) {
    return {
        type: VENUE_OTHER_DOCUMENTS_SAVE_SUCCESS,
        venueOtherDocuments
    };
}

export function venueSaveOtherDocumentsFailure(error) {
    return {
        type: VENUE_OTHER_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function venueRemoveOtherDocument(forVenueId, otherDocument) {
    return {
        type: VENUE_OTHER_DOCUMENT_REMOVE,
        forVenueId,
        otherDocument
    };
}

export function venueRemoveOtherDocumentSuccess(venueOtherDocuments) {
    return {
        type: VENUE_OTHER_DOCUMENT_REMOVE_SUCCESS,
        venueOtherDocuments
    };
}

export function venueRemoveOtherDocumentFailure(error) {
    return {
        type: VENUE_OTHER_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function venueSaveComplianceDocuments(forVenueId, documentTypeId, expiryDate, files) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENTS_SAVE,
        forVenueId,
        documentTypeId,
        expiryDate,
        files
    };
}

export function venueSaveComplianceDocumentsSuccess(venueComplianceDocuments) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
        venueComplianceDocuments
    };
}

export function venueSaveComplianceDocumentsFailure(error) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function venueRemoveComplianceDocument(forVenueId, complianceDocument) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENT_REMOVE,
        forVenueId,
        complianceDocument
    };
}

export function venueRemoveComplianceDocumentSuccess(venueComplianceDocuments) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
        venueComplianceDocuments
    };
}

export function venueRemoveComplianceDocumentFailure(error) {
    return {
        type: VENUE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function venueRequest(venueId, includeOptions) {
    return {
        type: VENUE_REQUEST,
        venueId,
        includeOptions
    };
}

export function venueRequestSuccess(venue, includesOptions, states, industryCategories, precincts, licenceTypes, tradingHours) {
    return {
        type: VENUE_REQUEST_SUCCESS,
        venue,
        includesOptions,
        states,
        industryCategories,
        precincts,
        licenceTypes,
        tradingHours
    };
}

export function venueRequestFailure(error) {
    return {
        type: VENUE_REQUEST_FAILURE,
        error
    };
}

export function venueUpdate(venue) {
    return {
        type: VENUE_UPDATE,
        venue
    }
}

export function venueReportingPeriodsRequest(venueId, page, pageSize) {
    return {
        type: VENUE_REPORTING_PERIODS_REQUEST,
        venueId,
        page,
        pageSize
    };
}

export function venueReportingPeriodsRequestSuccess(reportingPeriods, paging) {
    return {
        type: VENUE_REPORTING_PERIODS_REQUEST_SUCCESS,
        reportingPeriods,
        paging
    };
}

export function venueReportingPeriodsRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIODS_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodReset() {
    return {
        type: VENUE_REPORTING_PERIOD_RESET
    };
}

export function venueReportingPeriodSet(venueReportingPeriod) {
    return {
        type: VENUE_REPORTING_PERIOD_SET,
        venueReportingPeriod
    };
}

export function venueReportingPeriodRequest(venueReportingPeriodId) {
    return {
        type: VENUE_REPORTING_PERIOD_REQUEST,
        venueReportingPeriodId
    };
}

export function venueReportingPeriodRequestSuccess(reportingPeriod) {
    return {
        type: VENUE_REPORTING_PERIOD_REQUEST_SUCCESS,
        reportingPeriod
    };
}

export function venueReportingPeriodRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodCreateRequest(venueId) {
    return {
        type: VENUE_REPORTING_PERIOD_CREATE_REQUEST,
        venueId
    };
}

export function venueReportingPeriodCreateRequestSuccess(venueReportingPeriod) {
    return {
        type: VENUE_REPORTING_PERIOD_CREATE_REQUEST_SUCCESS,
        venueReportingPeriod
    };
}

export function venueReportingPeriodCreateRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodUpsertRequest() {
    return {
        type: VENUE_REPORTING_PERIOD_UPSERT_REQUEST
    };
}

export function venueReportingPeriodUpsertRequestSuccess(venueReportingPeriod) {
    return {
        type: VENUE_REPORTING_PERIOD_UPSERT_REQUEST_SUCCESS,
        venueReportingPeriod
    };
}

export function venueReportingPeriodUpsertRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodCurrentRequest(venueId) {
    return {
        type: VENUE_REPORTING_PERIOD_CURRENT_REQUEST,
        venueId
    };
}

export function venueReportingPeriodCurrentRequestSuccess(venueReportingPeriod, venueEventReportingPeriods) {
    return {
        type: VENUE_REPORTING_PERIOD_CURRENT_REQUEST_SUCCESS,
        venueReportingPeriod,
        venueEventReportingPeriods
    };
}

export function venueReportingPeriodCurrentRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_CURRENT_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodCloseRequest(venueReportingPeriodId) {
    return {
        type: VENUE_REPORTING_PERIOD_CLOSE_REQUEST,
        venueReportingPeriodId
    };
}

export function venueReportingPeriodCloseRequestSuccess(venueReportingPeriod) {
    return {
        type: VENUE_REPORTING_PERIOD_CLOSE_REQUEST_SUCCESS,
        venueReportingPeriod
    };
}

export function venueReportingPeriodCloseRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_CLOSE_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodSchedulesRequest(venueId) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST,
        venueId
    };
}

export function venueReportingPeriodSchedulesRequestSuccess(venueReportingPeriodSchedules) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_SUCCESS,
        venueReportingPeriodSchedules
    };
}

export function venueReportingPeriodSchedulesRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodScheduleReset() {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_RESET
    };
}

export function venueReportingPeriodScheduleSet(venueReportingPeriodSchedule) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_SET,
        venueReportingPeriodSchedule
    };
}

export function venueReportingPeriodScheduleRequest(venueReportingPeriodScheduleId) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST,
        venueReportingPeriodScheduleId
    };
}

export function venueReportingPeriodScheduleRequestSuccess(venueReportingPeriodSchedule) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_SUCCESS,
        venueReportingPeriodSchedule
    };
}

export function venueReportingPeriodScheduleRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodScheduleCreateRequest(venueId) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST,
        venueId
    };
}

export function venueReportingPeriodScheduleCreateRequestSuccess(venueReportingPeriodSchedule) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_SUCCESS,
        venueReportingPeriodSchedule
    };
}

export function venueReportingPeriodScheduleCreateRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueReportingPeriodScheduleUpsertRequest() {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST
    };
}

export function venueReportingPeriodScheduleUpsertRequestSuccess(venueReportingPeriodSchedule) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS,
        venueReportingPeriodSchedule
    };
}

export function venueReportingPeriodScheduleUpsertRequestFailure(error) {
    return {
        type: VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function venueLocationsRefresh() {
    return {
        type: VENUE_LOCATIONS_REFRESH
    }
}

export function venueLocationsRequest(page, pageSize, filter) {
    return {
        type: VENUE_LOCATIONS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function venueLocationsRequestSuccess(venueLocations, paging, filter) {
    return {
        type: VENUE_LOCATIONS_REQUEST_SUCCESS,
        venueLocations,
        paging,
        filter
    };
}

export function venueLocationsRequestFailure(error) {
    return {
        type: VENUE_LOCATIONS_REQUEST_FAILURE,
        error
    };
}

export function venueLocationReset() {
    return {
        type: VENUE_LOCATION_RESET
    };
}

export function venueLocationSet(venueLocation) {
    return {
        type: VENUE_LOCATION_SET,
        venueLocation
    };
}

export function venueLocationRequest(venueLocationId) {
    return {
        type: VENUE_LOCATION_REQUEST,
        venueLocationId
    };
}

export function venueLocationRequestSuccess(venueLocation) {
    return {
        type: VENUE_LOCATION_REQUEST_SUCCESS,
        venueLocation
    };
}

export function venueLocationRequestFailure(error) {
    return {
        type: VENUE_LOCATION_REQUEST_FAILURE,
        error
    };
}

export function venueLocationCreateRequest(venueId) {
    return {
        type: VENUE_LOCATION_CREATE_REQUEST,
        venueId
    };
}

export function venueLocationCreateRequestSuccess(venueLocation) {
    return {
        type: VENUE_LOCATION_CREATE_REQUEST_SUCCESS,
        venueLocation
    };
}

export function venueLocationCreateRequestFailure(error) {
    return {
        type: VENUE_LOCATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueLocationUpsertRequest() {
    return {
        type: VENUE_LOCATION_UPSERT_REQUEST
    };
}

export function venueLocationUpsertRequestSuccess(venueLocation) {
    return {
        type: VENUE_LOCATION_UPSERT_REQUEST_SUCCESS,
        venueLocation
    };
}

export function venueLocationUpsertRequestFailure(error) {
    return {
        type: VENUE_LOCATION_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function venueIncidentCategoryTypesRefresh() {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPES_REFRESH
    }
}

export function venueIncidentCategoryTypesFilterSet(filter) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPES_FILTER_SET,
        filter
    }
}

export function venueIncidentCategoryTypesRequest(page, pageSize, filter) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function venueIncidentCategoryTypesRequestSuccess(incidentCategoryTypes, paging, filter) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS,
        incidentCategoryTypes,
        paging,
        filter
    };
}

export function venueIncidentCategoryTypesRequestFailure(error) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE,
        error
    };
}

export function venueIncidentCategoryTypeReset() {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_RESET
    };
}

export function venueIncidentCategoryTypeSet(incidentCategoryType) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_SET,
        incidentCategoryType
    };
}

export function venueIncidentCategoryTypeRequest(incidentCategoryTypeId) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_REQUEST,
        incidentCategoryTypeId
    };
}

export function venueIncidentCategoryTypeRequestSuccess(incidentCategoryType) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function venueIncidentCategoryTypeRequestFailure(error) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_REQUEST_FAILURE,
        error
    };
}

export function venueIncidentCategoryTypeCreateRequest(venueId, securityFirmId) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST,
        venueId, 
        securityFirmId
    };
}

export function venueIncidentCategoryTypeCreateRequestSuccess(incidentCategoryType) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function venueIncidentCategoryTypeCreateRequestFailure(error) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueIncidentCategoryTypeUpsertRequest() {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST
    };
}

export function venueIncidentCategoryTypeUpsertRequestSuccess(incidentCategoryType) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function venueIncidentCategoryTypeUpsertRequestFailure(error) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function venueIncidentCategoryTypeHideRequest(incidentCategoryTypeId, hide, forVenueId, forSecurityFirmId) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST,
        incidentCategoryTypeId,
        hide,
        forVenueId,
        forSecurityFirmId
    };
}

export function venueIncidentCategoryTypeHideRequestSuccess(incidentCategoryType) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function venueIncidentCategoryTypeHideRequestFailure(error) {
    return {
        type: VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_FAILURE,
        error
    };
}


export function venueContactsRefresh() {
    return {
        type: VENUE_CONTACTS_REFRESH
    }
}

export function venueContactsRequest(page, pageSize, filter) {
    return {
        type: VENUE_CONTACTS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function venueContactsRequestSuccess(venueContacts, paging, filter) {
    return {
        type: VENUE_CONTACTS_REQUEST_SUCCESS,
        venueContacts,
        paging,
        filter
    };
}

export function venueContactsRequestFailure(error) {
    return {
        type: VENUE_CONTACTS_REQUEST_FAILURE,
        error
    };
}

export function venueContactReset() {
    return {
        type: VENUE_CONTACT_RESET
    };
}

export function venueContactSet(venueContact) {
    return {
        type: VENUE_CONTACT_SET,
        venueContact
    };
}

export function venueContactRequest(venueContactId) {
    return {
        type: VENUE_CONTACT_REQUEST,
        venueContactId
    };
}

export function venueContactRequestSuccess(venueContact, venueContactTypes, venueUsers) {
    return {
        type: VENUE_CONTACT_REQUEST_SUCCESS,
        venueContact,
        venueContactTypes,
        venueUsers
    };
}

export function venueContactRequestFailure(error) {
    return {
        type: VENUE_CONTACT_REQUEST_FAILURE,
        error
    };
}

export function venueContactCreateRequest(venueId) {
    return {
        type: VENUE_CONTACT_CREATE_REQUEST,
        venueId
    };
}

export function venueContactCreateRequestSuccess(venueContact, venueContactTypes, venueUsers) {
    return {
        type: VENUE_CONTACT_CREATE_REQUEST_SUCCESS,
        venueContact,
        venueContactTypes,
        venueUsers
    };
}

export function venueContactCreateRequestFailure(error) {
    return {
        type: VENUE_CONTACT_CREATE_REQUEST_FAILURE,
        error
    };
}

export function venueContactUpsertRequest() {
    return {
        type: VENUE_CONTACT_UPSERT_REQUEST
    };
}

export function venueContactUpsertRequestSuccess(venueContact) {
    return {
        type: VENUE_CONTACT_UPSERT_REQUEST_SUCCESS,
        venueContact
    };
}

export function venueContactUpsertRequestFailure(error) {
    return {
        type: VENUE_CONTACT_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function venueSaveRequest() {
    return {
        type: VENUE_SAVE_REQUEST
    };
}

export function venueSaveRequestSuccess(venue) {
    return {
        type: VENUE_SAVE_REQUEST_SUCCESS,
        venue
    };
}

export function venueSaveRequestFailure(error) {
    return {
        type: VENUE_SAVE_REQUEST_FAILURE,
        error
    };
}

export function venueSubscriptionSummaryRequest() {
    return {
        type: VENUE_SUBSCRIPTION_SUMMARY_REQUEST
    };
}

export function venueSubscriptionSummaryRequestSuccess(venueSubscriptionSummary) {
    return {
        type: VENUE_SUBSCRIPTION_SUMMARY_REQUEST_SUCCESS,
        venueSubscriptionSummary
    };
}

export function venueSubscriptionSummaryRequestFailure(error) {
    return {
        type: VENUE_SUBSCRIPTION_SUMMARY_REQUEST_FAILURE,
        error
    };
}