import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import MultiSelectControl from '../../controls/MutiSelectControl';
import SelectControlGroup from '../../controls/SelectControlGroup';
import { PickerItem } from '../../common/PickerItem';
import Spacer from '../../common/Spacer';

export interface IEventsCopyProps {
    open: boolean;
    isLoading: boolean;
    isSaving: boolean;
    canSave: boolean;
    filter: AusComplyDtos.SimpleFilter;
    toVenueId: number;
    venues: AusComplyDtos.VenueSelection[];
    externalEventSelections: AusComplyDtos.MultiSelectOption[];
    errorMessage: string;
    onLoad: Function;
    onSave: Function;
    onSetVenue: Function;
    onSetSelections: Function;
    onClose: Function;
}

interface IEventsCopyState {
}

export default class EventsCopy extends Component<IEventsCopyProps, IEventsCopyState> {
    constructor(props: IEventsCopyProps) {
        super(props)

        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.open) {
            this.props.onLoad(this.props.filter);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && !this.props.canSave) {
            this.props.onClose();
        }
        if (!prevProps.open && this.props.open) {
            this.props.onLoad(this.props.filter);
        }
    }

    render() {
        let self = this;
        let venueSelector;
        let eventSelector;

        if (this.props.venues) {
            let venues = [{ venueId: 0, name: "" }, ...this.props.venues].map((item, index) => (
                <PickerItem key={item.venueId} label={item.name} value={item.venueId} />
            ));
            venueSelector = (
                <SelectControlGroup
                    text="To Venue"
                    labelAbove={true}
                    onChange={value => self.props.onSetVenue(value)}
                    defaultValue={this.props.toVenueId}
                    items={venues} />
            );
        }
        if (this.props.externalEventSelections) {
            eventSelector = <MultiSelectControl
                cardTitle={"Events"}
                wrapControlInCard={true}
                current={this.props.externalEventSelections}
                label={""}
                searchText="Type at least three letters..."
                minimumCharactersRequired={3}
                onUpdated={items => self.props.onSetSelections(items)}
                canShowAll={true} />
        }

        return (
            <FullPageDialog
                open={this.props.open}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                notFullWidth={true}
                error={this.props.errorMessage}
                title="Copy To" onDismissed={() => this.props.onClose()}
                footerRightContent={<PrimaryButton text={"Save"} disabled={!this.props.canSave} onClick={this.props.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.props.onClose}></DefaultButton>}
            >
                {venueSelector}
                <Spacer />
                {eventSelector}
            </FullPageDialog>);
    }
}