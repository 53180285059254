import { connect } from 'react-redux';
import Navigation from '../components/layout/Navigation';
import { userLogoutRequest } from '../common/actions/userLogin';
import { incidentsFilterRequest } from '../common/actions/incidents';
import { userDetailsVenueSecurityFirmChange } from '../common/actions/userLogin';
import { venueSecurityFirmAvailableClearVenue } from '../common/actions/venueSecurityFirm';
import { signOnReset, signOnRegisterFilterRequest } from '../common/actions/signOn';
import { checklistFilterReset } from '../common/actions/checklist';
import { adminUserImpersonate } from '../common/actions/admin';
import { withRouter } from "react-router";


const userName = (state) =>
{
    var result = "";
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        if (state.user.details.userSession.user.firstName) {
            result += state.user.details.userSession.user.firstName
        }
        result += " ";
        if (state.user.details.userSession.user.lastName) {
            result += state.user.details.userSession.user.lastName
        }
        result = result.trim();
    }
    return result;
}

const userId = (state) => {
    var result = "";
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        try {
            result = state.user.details.userSession.user.userID.toString();
        } catch (error) {
            
        }
    }
    return result;
}

const mapStateToProps = state => {
    return {
        avatar: state.user.details.avatar,
        isUserLoading: state.user.isLoading,
        name: userName(state),
        userId: userId(state),
        trainingWebsite: state.user.details && state.user.details.userSession && state.user.details.userSession.user.trainingWebsite,
        permissions: state.user.details.userSession.permissions,
        profileisNotValid: !state.user.isLoading && state.user.details && state.user.details.userSession && (state.user.details.userSession.user.requiresUpdate || !state.user.details.userSession.user.hasAcceptedTerms),
        venueIsSelected: state.user.details.venueIsSelected && state.user.details.userSession.venueId && state.user.details.userSession.venueId > 0,
        requiresSecurityCode: state.user.details && state.user.details.userSession && state.user.details.userSession.user.requiresSecurityCode,
        pendingInvalidSLEDAccess: state.user.details && state.user.details.userSession && state.user.details.userSession.user.pendingInvalidSLEDAccess,
        isStatisticsLoading: state.userVenueSecurityFirm.isLoading,
        statistics: state.userVenueSecurityFirm.statistics,
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        isImpersonating: state.user.details.userSession && state.user.details.userSession.isImpersonating,
        groups: state.user.details && state.user.details.userSession && state.user.details.userSession.user.groups,
        venueHasGroups: state.user.details && state.user.details.userSession && state.user.details.userSession.user.venueHasGroups,
        showGamblingIncidentRegister: state.user.details && state.user.details.showGamblingIncidentRegister
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (username, password) =>{
            dispatch(userLogoutRequest())
        },
        onResetFilter:() => {
            dispatch(incidentsFilterRequest(false ,""))
        },
        onChangeVenue: () =>{
            dispatch(userDetailsVenueSecurityFirmChange())
        },
        onClearVenue: () => {
            dispatch(venueSecurityFirmAvailableClearVenue())
        },
        onResetSignOnRegisterFilter: () => {
            dispatch(signOnRegisterFilterRequest(true))
        },
        onResetChecklistFilter:() => {
            dispatch(checklistFilterReset())
        },
        onStopImpersonating:() => {
            dispatch(adminUserImpersonate(-1))
        }
    }
}

const NavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation)

export default NavigationContainer