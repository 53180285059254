import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Error from '../../common/Error'
import Grid from '@material-ui/core/Grid';
import { RegexConstants } from '../../../common/constants/regex';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import VenueSecurityCodeContainer from '../../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../../containers/SignOnOffSummaryContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControl from '../../controls/TextControl';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../../common/SecondaryFab';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import Information from '../../common/Information';
import WelcomeContainer from '../../../containers/WelcomeContainer';
import TransparentButton from '../../controls/TransparentButton';
import HomeStatistics from '../../home/HomeStatistics';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../common/NoRowsMessage';
import TableContainer from '../../layout/TableContainer';
import FormControl from '@material-ui/core/FormControl';
import TableRow from '../../common/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import Chip from '@material-ui/core/Chip';

export interface IDashboardPostcodesProps {
    items: string[];
    onUpdate: Function;
    theme: any;
    classes: any;
}

class DashboardPostcodes extends Component<IDashboardPostcodesProps, any> {
    constructor(props: IDashboardPostcodesProps) {
        super(props)
        this.state = {
            value: "",
            valid: true
        };
        this.onAdd = this.onAdd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDeSelected = this.onDeSelected.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onAdd() {
        if (this.state.valid && this.state.value !== "") {
            let items: string[] = [];
            if (this.props.items) {
                items = [...this.props.items];
            }
            if (items.indexOf(this.state.value) === -1) {
                items.push(this.state.value);
                this.setState({
                    value: "",
                    valid: true
                }, () => {
                    this.props.onUpdate(items);
                });
            }
        }
    }

    onChange(value) {
        let valid = true;
        if (value != "") {
            valid = RegexConstants.POST_CODE.test(value);
        }
        this.setState({ value, valid });
    }

    onDeSelected(value) {
        var items = [...this.props.items].filter(s => s != value);
        this.props.onUpdate(items);
    }

    render() {
        const { classes, theme } = this.props;
        let selectedDisplay;
        if (this.props.items) {
            selectedDisplay = this.props.items.map((item, index) => {
                return (
                    <div key={"chip-" + item.toString()}>
                        <Chip key={"postcode-" + item.toString()} label={item}
                            style={{
                                marginLeft: 0,
                                marginTop: 0,
                                marginRight: theme.spacing(1),
                                marginBottom: theme.spacing(1),
                                color: theme.custom.colors.controlTextLight,
                                backgroundColor: theme.custom.colors.controlBackgroundLight,
                                borderColor: theme.custom.colors.boundary,
                                borderRadius: '6px',
                                width: 'auto'
                            }} variant="outlined" deleteIcon={<div style={{
                                backgroundColor: theme.custom.colors.controlContrastText,
                                fontSize: '10px',
                                height: '16px',
                                width: '16px',
                                borderRadius: '9px',
                                padding: 0,
                                position: 'absolute',
                                top: '-6px',
                                right: '-6px'
                            }} ><CancelIcon style={{
                                fontSize: '10px',
                                height: '18px',
                                width: '18px',
                                margin: '-1px'
                            }} /></div>} onDelete={() => this.onDeSelected(item)} />
                    </div>
                );
            });
        }
        return (
            <>
                <Grid container>
                    <Grid item xs={6}>
                        <TextControl
                            value={this.state.value || ''}
                            onChange={value => this.onChange(value)}
                            onSubmit={() => this.onAdd()}
                            error={!this.state.valid} />
                    </Grid>
                    <Grid item xs={6}>
                        <TransparentButton text={"Add postcode"} onClick={() => this.onAdd()} />
                    </Grid>
                </Grid>
                <FormControl>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        {selectedDisplay}
                    </div>
                </FormControl>
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(DashboardPostcodes)