import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';
import classNames from 'classnames';

const PoiPerson = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    height = "100%",
    classes: any
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 80 80"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M25.8,69.4l-13.5,7.4c-0.4,0.2-0.9,0.5-1.3,0.8l-2.6,2.2L8.2,80h63.6l0,0l-2.7-2.2c-0.5-0.3-1-0.7-1.5-0.9L53,69.6c-1.3-0.7-2.1-2-2.1-3.4v-5.1c0.3-0.4,0.7-0.9,1.1-1.4c1.9-2.6,3.4-5.6,4.5-8.8c2.2-1,3.6-3.2,3.6-5.6v-6.1c0-1.5-0.5-2.9-1.5-4.1v-8c0.1-0.9,0.4-5.9-3.2-10c-3.1-3.6-8.3-5.4-15.2-5.4c-6.9,0-12,1.8-15.2,5.4c-3.6,4.1-3.3,9.2-3.2,10v8c-1,1.1-1.5,2.6-1.5,4.1v6.1c0,1.9,0.8,3.6,2.3,4.8c1.4,5.4,4.2,9.5,5.3,10.9v5C27.8,67.5,27,68.8,25.8,69.4z" />
            </g>
        </svg>
    );

export default withStyles(styles, { withTheme: true })(PoiPerson);
