import React from "react";

const SubmitIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M66.3,44.6c-1.2,0.1-2.1,1.2-2.1,2.5v15c0,0,0,0,0,0H15.9c0,0,0,0,0,0v-15c0-1.2-0.9-2.4-2.1-2.5c-1.4-0.1-2.5,1-2.5,2.3v19.8c0,0,0,0,0,0h57.5c0,0,0,0,0,0V47C68.8,45.6,67.7,44.5,66.3,44.6z M24.3,28.9c-1,1-0.9,2.5,0.2,3.4c0.9,0.8,2.4,0.6,3.2-0.3l9.9-9.9c0,0,0.1,0,0.1,0v28.1c0,1.2,0.9,2.4,2.1,2.5c1.4,0.1,2.5-1,2.5-2.3V22.1c0,0,0-0.1,0.1,0l9.9,9.9c0.9,0.9,2.3,1,3.2,0.3c1.1-0.9,1.1-2.5,0.2-3.4L40,13.2c0,0,0,0-0.1,0L24.3,28.9z" />
        </g>
    </svg>
);

export default SubmitIcon;
