
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const roleTypeLogic = {
    getCommands(roleType: AusComplyDtos.UserRoleTypeSummary, isAdmin?: boolean) {
        var commands: KeyName[] = [];
        if(!roleType.isGlobalRole || isAdmin){
            commands.push({ key: "edit", name: "Edit Role" });
            commands.push({ key: "clone", name: "Clone Role" });
            commands.push({ key: "delete", name: "Delete Role" });
        } else {
            commands.push({ key: "view", name: "Edit Alias" });
        }
        return commands;
    }
}