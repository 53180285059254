import { connect } from 'react-redux'
import SignOn from '../components/signon/SignOn'
import { signOnRequest, signOnCheckUserRequest, signOnCheckUserResult, signOnSaveRequest } from '../common/actions/signOn'
import { locationRequest } from '../common/actions/location';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        userSignOnStart: state.signOn.userSignOnStart,
        isLoading: state.signOn.isLoading,
        isSaving: state.signOn.isSaving,
        saveError: state.signOn.error,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        isValidating: state.signOn.userCheck.isValidating,
        isSignedOnToOtherVenueBlocked: state.signOn.userCheck.isSignedOnToOtherVenueBlocked,
        isSignedOnToOtherVenueWarning: state.signOn.userCheck.isSignedOnToOtherVenueWarning,
        canViewSignOnRegister: permissionsLogic.hasPermissionForState(state, "SignOnOffRegisterView"),
        userId: state.user.details.userSession.user.userID
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () => {
            dispatch(signOnRequest())
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onCheckSignOnUser: (venueId, userRoleId) => {
            dispatch(signOnCheckUserRequest(venueId, userRoleId))
        },
        onCheckSignOnUserReset: () => {
            dispatch(signOnCheckUserResult(false, false))
        },
        onSignOn: (userSignOnStatus) => {
            dispatch(signOnSaveRequest(userSignOnStatus))
        }
    }
}

const SignOnContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOn)

export default SignOnContainer