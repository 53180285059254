import { connect } from 'react-redux'
import LoggedInUsers from '../components/home/LoggedInUsers'
import { 
    usersLoggedInRequest
 } from '../common/actions/userLogin'

const mapStateToProps = state => {
    return {
        loggedInUsers: state.user.loggedInUsers,
        isLoading: state.user.isLoadingLoggedInUsers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () => {
            dispatch(usersLoggedInRequest())
        }
    }
}

const LoggedInUsersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoggedInUsers)

export default LoggedInUsersContainer