import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueLocations from '../components/venue/venueLocations/VenueLocations'
import { 
    adminVenueLocationsRequest, 
    adminVenueLocationCreateRequest, 
    adminVenueLocationRequest, 
    adminVenueLocationReset,
    adminVenueLocationSet,
    adminVenueLocationUpsertRequest
 } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        venueId:  null,
        paging: state.admin.venueLocations.paging,
        filter: state.admin.venueLocations.filter,
        isLoading: state.admin.venueLocations.isLoading,
        venueLocations: state.admin.venueLocations.venueLocations,
        venueLocationId: state.admin.venueLocation.venueLocationId,
        canCreate: permissionsLogic.hasPermissionForState(state, "EditReferenceData")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(adminVenueLocationsRequest(page, pageSize, filter))
        },
        onLoadItem: (venueLocationId) => {
            dispatch(adminVenueLocationRequest(venueLocationId))
        },
        onCreate: (venueId) => {
            dispatch(adminVenueLocationCreateRequest())
        },
        onResetItem: () => {
            dispatch(adminVenueLocationReset())
        },
        onUpdate: (venueLocation) => {
            dispatch(adminVenueLocationSet(venueLocation))
        },
        onSave: () => {
            dispatch(adminVenueLocationUpsertRequest())
        }
    }
}

const AdminVenueLocationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueLocations))

export default AdminVenueLocationsContainer