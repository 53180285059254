import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import FullPageDialog from '../layout/FullPageDialog';
import ResponsiveList from '../common/ResponsiveList';
import ConfirmDialog from '../controls/ConfirmDialog';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import FunctionalAreaContainer from '../../containers/FunctionalAreaContainer';
import { KeyName } from '../../common/dto/common';
import SimpleFilter from '../common/SimpleFilter';
import SimpleFilterSummary from '../common/SimpleFilterSummary';
import View from '../common/View';

export interface IFunctionalAreasProps {
    isLoading: boolean;
    groupId: number;
    venueId: number;
    functionalAreas: AusComplyDtos.FunctionalArea[];
    functionalAreaId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class FunctionalAreas extends Component<IFunctionalAreasProps, any> {
    constructor(props: IFunctionalAreasProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            contactToRemove: {},
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
            preparedFilter.forGroupId = 0;
        } else if (this.props.groupId && this.props.groupId > 0) {
            preparedFilter.forGroupId = this.props.groupId;
            preparedFilter.forVenueId = 0;
        } else {
            preparedFilter.forGroupId = 0;
            preparedFilter.forVenueId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(functionalArea: AusComplyDtos.FunctionalArea) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        return commands;
    }

    onCommand(command: string, functionalArea: AusComplyDtos.FunctionalArea) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.groupId, this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(functionalArea.functionalAreaId);
                break;
            case "delete":
                var updated = { ...functionalArea };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    contactToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let functionalArea = { ...this.state.contactToRemove };
            this.props.onUpdate(functionalArea);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                contactToRemove: {}
            });
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.paging.pageSize, 1, this.props.filter);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {  
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter({...filter}));     
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(new AusComplyDtos.SimpleFilter()));
        this.setState({ filterExpanded: false });
    }

    render() {
        let self = this;

        let functionalArea;
        if (this.props.functionalAreaId != -1) {
            functionalArea = (
                <FunctionalAreaContainer />
            );
        }

        let create;
        if (this.props.canEdit) {
            create = (
                <TransparentButton
                    onClick={() => this.props.onCreate(null, this.props.venueId)}
                    text={"Add"} />
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onReset={() => self.onResetSearch()}
                onSearch={(filter) => self.onSearch(filter)}
            />;
        } else if (this.props.isLoading) {
            let simpleFilter = new AusComplyDtos.SimpleFilter();
            simpleFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={simpleFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<PageLayout
            headerText={"Functional Areas"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            {filterSummary}
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.functionalAreas}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Name"]}
                columns={["name"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                isSuccess={item => item.groupId !== undefined && item.groupId > 0}
                isSuccessLabel={"Group"}
                isInfo={item => item.groupId !== undefined && item.groupId == 0 && item.venueId !== undefined && item.venueId == 0}
                isInfoLabel={"Global"}
                canEdit={true}
            />
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <FullPageDialog
                open={this.props.functionalAreaId != -1}
                title="Functional Area"
                notFullWidth={true}
                onDismissed={() => this.props.onResetItem()}>
                {functionalArea}
            </FullPageDialog>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this functional area?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </PageLayout>);
    }
}