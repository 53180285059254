import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';

export interface ISaveCancelProps {
    isValid?: boolean;
    isSaving: boolean;
    onCancel: Function;
    onSave: Function;
}

export default class SaveCancel extends Component<ISaveCancelProps, any> {
    constructor(props: ISaveCancelProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PrimaryButton text={"Save"} onClick={this.props.onSave} disabled={!this.props.isValid}></PrimaryButton>
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Cancel"} onClick={this.props.onCancel}></DefaultButton>
                </Grid>
            </Grid>
        );
    }
}