import * as AusComplyDtos from "../dto/AusComply.dtos";
import { makePost, makeGet, makeDelete } from './common';

export const scheduleServiceApi = {
  find: makeGet(
    AusComplyDtos.GetSchedules,
    'venueId', 'securityFirmId'
  ),
  create: makePost(
    AusComplyDtos.PostScheduleNew,
    'venueId', 'securityFirmId'
  ),
  upsert: makePost(
    AusComplyDtos.PostSchedule,
    'venueId', 'securityFirmId', 'schedule'
  ),
  get: makeGet(
    AusComplyDtos.GetSchedule,
    'venueId', 'securityFirmId', 'scheduleId'
  ),
  process: makePost(
    AusComplyDtos.PostScheduleProcess,
    'venueId', 'securityFirmId', 'scheduleId'
  ),
  scheduleLogs: makePost(
    AusComplyDtos.PostScheduleLogs,
    'venueId', 'securityFirmId', 'scheduleId', 'paging'
  ),
  deleteScheduleLog: makePost(
    AusComplyDtos.PostScheduleLogDelete,
    'venueId', 'securityFirmId', 'scheduleLogId', 'scheduleId', 'paging'
  ),
  copy: makePost(
    AusComplyDtos.PostScheduleCopy,
    'venueId', 'securityFirmId', 'scheduleId'
  ),
};
