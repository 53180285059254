import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IVenueReportingPeriodScheduleHeaderProps {
    scheduleType: AusComplyDtos.ngtVenueReportingPeriodScheduleType;
    classes: any;
}

class VenueReportingPeriodScheduleHeader extends React.Component<IVenueReportingPeriodScheduleHeaderProps, any> {
    render() {
        const { classes } = this.props;
        if (this.props.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.OneOff) {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Start</th>
                    <th className={classes.tableHr}>End</th>
                    <th className={classes.tableHrCentered}>Code</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        } else if (this.props.scheduleType == AusComplyDtos.ngtVenueReportingPeriodScheduleType.Holiday) {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Start Month</th>
                    <th className={classes.tableHr}>Start Day</th>
                    <th className={classes.tableHr}>End Month</th>
                    <th className={classes.tableHr}>End Day</th>
                    <th className={classes.tableHr}>Start Time</th>
                    <th className={classes.tableHr}>End Time</th>
                    <th className={classes.tableHr}>Expires</th>
                    <th className={classes.tableHrCentered}>Code</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        } else {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Start Day</th>
                    <th className={classes.tableHr}>Start Time</th>
                    <th className={classes.tableHr}>End Day</th>
                    <th className={classes.tableHr}>End Time</th>
                    <th className={classes.tableHr}>Expires</th>
                    <th className={classes.tableHrCentered}>Code</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        
    }
}
export default withStyles(styles, { withTheme: true })(VenueReportingPeriodScheduleHeader);