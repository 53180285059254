
import { of, timer } from 'rxjs';
import 'rxjs/add/operator/map';
import { map, catchError, mergeMap, withLatestFrom, filter, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { loginServiceApi } from '../services/loginService';
import { userServiceApi } from '../services/userService';
import {
    userLoginRequestSuccess,
    userLoginRequestFailure,
    userDetailsRequest,
    userDetailsRequestSuccess,
    userLogoutRequest,
    userLogoutRequestSuccess,
    userLogoutRequestFailure,
    userReset,
    USER_LOGIN_REQUEST,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_REQUEST_SUCCESS,
    USER_DETAILS_REQUEST,
    USER_DETAILS_REQUEST_SUCCESS,
    USER_LOGIN_REQUEST_SUCCESS,
    USER_DETAILS_VENUE_SECURITYFIRM_CHANGE,
    USER_DETAILS_REFRESH,
    USER_ACTIVE,
    USERS_LOGGED_IN_REQUEST,
    usersLoggedInRequestSuccess,
    usersLoggedInRequestFailure,
    userActive
} from "../actions/userLogin";
import {
    venueSecurityFirmLoadAvailable,
} from "../actions/venueSecurityFirm";
import {
    userVenueSecurityFirmResetStatistics
} from "../actions/userVenueSecurityFirm";
import {
    notificationShow
} from "../actions/notification";
import {
    notifyError, notifyErrorMessage
} from './common';
import {
    PRINT_RESET,
    PRINT_QUEUE_REQUEST,
    PRINT_QUEUE_REQUEST_SUCCESS
} from "../actions/print";

import {
    LOCATION_REQUEST,
    LOCATION_CHANGED
} from "../actions/location";

import * as draftIncidentActions from '../actions/draftIncident';
import * as editIncidentActions from '../actions/editIncident';
import * as checklistActions from '../actions/checklist';

const userActiveEpic = (action$, state$) =>
    action$.pipe(
        withLatestFrom(state$),
        filter(([action, state]) => {
            if ([USER_ACTIVE, 
                PRINT_RESET,
                PRINT_QUEUE_REQUEST,
                PRINT_QUEUE_REQUEST_SUCCESS, 
                LOCATION_CHANGED,
                LOCATION_REQUEST,
                USER_LOGOUT_REQUEST,
                USER_LOGOUT_REQUEST_SUCCESS,
                USER_LOGIN_REQUEST_SUCCESS,
                draftIncidentActions.DRAFT_INCIDENT_CREATE_REQUEST,
                editIncidentActions.EDIT_INCIDENT_LOAD_REQUEST,
                checklistActions.LOAD_CHECKLIST_REQUEST
            ].indexOf(action.type) > -1 ) {
                return false;
            }
            return true;
        }),
        debounce(() => timer(10000)),
        map(() => userActive())
    );

const userLoginEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_LOGIN_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            loginServiceApi.postLogin(action['username'], action['password'], state.location.found, state.location.latitude, state.location.longitude)
                .pipe(
                    map(response => userLoginRequestSuccess(response.bearerToken, response.meta, response.refreshToken)),
                    catchError(error => of(
                        userLoginRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow(userLoginErrorMessage(error.responseStatus ? error.responseStatus.message : error.message))
                    ))
                )
        ),
        catchError(error => of(
            userLoginRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow(userLoginErrorMessage(error.responseStatus ? error.responseStatus.message : error.message))
        ))
    );

const userLoginErrorMessage = (message)  => {
    if (message.indexOf("update your app") != 0) {
        return message;
    }
    if (message.indexOf("invalid") == -1) {
        return message;
    }
    return "Username or password is invalid";
}

const userLoginSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_LOGIN_REQUEST_SUCCESS),
        map((action: any) => userDetailsRequest(action['meta']["venueid"], action['meta']["securityfirmid"], action['meta']["venueEventId"]))
    );

const userLoginSuccessResetStatisticsEpic = action$ =>
    action$.pipe(
        ofType(USER_LOGIN_REQUEST_SUCCESS),
        map((action: any) => userVenueSecurityFirmResetStatistics())
    );


const userLogoutEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_LOGOUT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            loginServiceApi.postLogout(state.user.details.userSession.user.userID)
                .pipe(
                    map(response => userLogoutRequestSuccess()),
                    catchError(error => of(
                        userLogoutRequestFailure(),
                        userReset()
                    ))
                )
        )
    );

const userLogoutSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_LOGOUT_REQUEST_SUCCESS),
        map(action => userReset())
    );


const userDetailsEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_DETAILS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            loginServiceApi.getUserDetails(state.location.found, state.location.latitude, state.location.longitude, 
                action['venueId'], action['securityFirmId'], action['venueEventId'])
                .pipe(
                    map(response => userDetailsRequestSuccess(response.userDetailSession)),
                    catchError(error => of(
                        userLogoutRequest(),
                        notificationShow("User session has ended")
                    ))
                )
        ),
        catchError(error => of(
            userLogoutRequest(),
            notificationShow("User session has ended")
        ))
    );

const userDetailsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_DETAILS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            loginServiceApi.getUserDetails(state.location.found, state.location.latitude, state.location.longitude, 
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId, 
                state.user.details.userSession.user.venueEventId)
                .pipe(
                    map(response => userDetailsRequestSuccess(response.userDetailSession)),
                    catchError(error => of(
                        userLogoutRequest(),
                        notificationShow("User session has ended")
                    ))
                )
        ),
        catchError(error => of(
            userLogoutRequest(),
            notificationShow("User session has ended")
        ))
    );

const userDetailsSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_DETAILS_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => state.user.details.venueIsSelected == false && state.user.details.securityFirmIsSelected == false && state.user.details.isAndHasNothingSelected == false),
        map(action => venueSecurityFirmLoadAvailable())
    );

const userChangeVenueEpic = action$ =>
    action$.pipe(
        ofType(USER_DETAILS_VENUE_SECURITYFIRM_CHANGE),
        map(action => venueSecurityFirmLoadAvailable())
    );

const usersLoggedInEpic = (action$, state$) =>
    action$.pipe(
        ofType(USERS_LOGGED_IN_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getLoggedInUsers(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => usersLoggedInRequestSuccess(response.loggedInUsers)),
                    catchError(error => notifyErrorMessage(error, "Failed to load logged in users", usersLoggedInRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load logged in users", usersLoggedInRequestFailure))
    );

export const userSessionEpics = combineEpics(
    userLoginEpic,
    userLoginSuccessEpic,
    userDetailsEpic,
    userChangeVenueEpic,
    userDetailsSuccessEpic,
    userDetailsRefreshEpic,
    userLogoutEpic,
    userLogoutSuccessEpic,
    userActiveEpic,
    usersLoggedInEpic,
    userLoginSuccessResetStatisticsEpic
);