export const
    ADMIN_DASHBOARD_RESET = "ADMIN_DASHBOARD_RESET",
    ADMIN_DASHBOARD_FILTER_REQUEST = "ADMIN_DASHBOARD_FILTER_REQUEST",
    ADMIN_DASHBOARD_FILTER_REQUEST_FAILURE = "ADMIN_DASHBOARD_FILTER_REQUEST_FAILURE",
    ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS = "ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_SET_FILTER = "ADMIN_DASHBOARD_SET_FILTER",
    ADMIN_DASHBOARD_REQUEST = "ADMIN_DASHBOARD_REQUEST",
    ADMIN_DASHBOARD_REQUEST_FAILURE = "ADMIN_DASHBOARD_REQUEST_FAILURE",
    ADMIN_DASHBOARD_REQUEST_SUCCESS = "ADMIN_DASHBOARD_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_INCIDENTS_REQUEST = "ADMIN_DASHBOARD_INCIDENTS_REQUEST",
    ADMIN_DASHBOARD_INCIDENTS_REQUEST_FAILURE = "ADMIN_DASHBOARD_INCIDENTS_REQUEST_FAILURE",
    ADMIN_DASHBOARD_INCIDENTS_REQUEST_SUCCESS = "ADMIN_DASHBOARD_INCIDENTS_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_SET_OPTIONS = "ADMIN_DASHBOARD_SET_OPTIONS",
    ADMIN_DASHBOARD_TOTALS_REQUEST = "ADMIN_DASHBOARD_TOTALS_REQUEST",
    ADMIN_DASHBOARD_TOTALS_REQUEST_FAILURE = "ADMIN_DASHBOARD_TOTALS_REQUEST_FAILURE",
    ADMIN_DASHBOARD_TOTALS_REQUEST_SUCCESS = "ADMIN_DASHBOARD_TOTALS_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST = "ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST",
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_FAILURE = "ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_FAILURE",
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_SUCCESS = "ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST = "ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST",
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_FAILURE = "ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_FAILURE",
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_SUCCESS = "ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_SUCCESS",
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST = "ADMIN_DASHBOARD_USER_TOTALS_REQUEST",
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST_FAILURE = "ADMIN_DASHBOARD_USER_TOTALS_REQUEST_FAILURE",
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST_SUCCESS = "ADMIN_DASHBOARD_USER_TOTALS_REQUEST_SUCCESS";

export function adminDashboardIncidentTotalsRequest() {
    return {
        type: ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST
    };
}

export function adminDashboardIncidentTotalsRequestSuccess(incidents, venueIncidents, securityFirmIncidents) {
    return {
        type: ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_SUCCESS,
        incidents,
        venueIncidents,
        securityFirmIncidents
    };
}

export function adminDashboardIncidentTotalsRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardUserTotalsRequest() {
    return {
        type: ADMIN_DASHBOARD_USER_TOTALS_REQUEST
    };
}

export function adminDashboardUserTotalsRequestSuccess(venueUsers, securityFirmUsers) {
    return {
        type: ADMIN_DASHBOARD_USER_TOTALS_REQUEST_SUCCESS,
        venueUsers,
        securityFirmUsers
    };
}

export function adminDashboardUserTotalsRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_USER_TOTALS_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardOverviewQuickRequest() {
    return {
        type: ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST
    };
}

export function adminDashboardOverviewQuickRequestSuccess(data) {
    return {
        type: ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_SUCCESS,
        data
    };
}

export function adminDashboardOverviewQuickRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardTotalsRequest() {
    return {
        type: ADMIN_DASHBOARD_TOTALS_REQUEST
    };
}

export function adminDashboardTotalsRequestSuccess(filter, totalVenues, totalSecurityFirms, totalIncidents, totalUsers) {
    return {
        type: ADMIN_DASHBOARD_TOTALS_REQUEST_SUCCESS,
        filter,
        totalVenues,
        totalSecurityFirms,
        totalIncidents,
        totalUsers
    };
}

export function adminDashboardTotalsRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_TOTALS_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardSetOptions(options) {
    return {
        type: ADMIN_DASHBOARD_SET_OPTIONS,
        options
    };
}

export function adminDashboardReset() {
    return {
        type: ADMIN_DASHBOARD_RESET
    };
}

export function adminDashboardSetFilter(filter) {
    return {
        type: ADMIN_DASHBOARD_SET_FILTER,
        filter
    }
}

export function adminDashboardFilterRequest() {
    return {
        type: ADMIN_DASHBOARD_FILTER_REQUEST
    };
}

export function adminDashboardFilterRequestSuccess(filter) {
    return {
        type: ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function adminDashboardFilterRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_FILTER_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardRequest() {
    return {
        type: ADMIN_DASHBOARD_REQUEST
    };
}

export function adminDashboardRequestSuccess(filter, incidents, venueMarkers) {
    return {
        type: ADMIN_DASHBOARD_REQUEST_SUCCESS,
        filter,
        incidents,
        venueMarkers
    };
}

export function adminDashboardRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_REQUEST_FAILURE,
        error
    };
}

export function adminDashboardIncidentsRequest() {
    return {
        type: ADMIN_DASHBOARD_INCIDENTS_REQUEST
    };
}

export function adminDashboardIncidentsRequestSuccess(incidents) {
    return {
        type: ADMIN_DASHBOARD_INCIDENTS_REQUEST_SUCCESS,
        incidents
    };
}

export function adminDashboardIncidentsRequestFailure(error) {
    return {
        type: ADMIN_DASHBOARD_INCIDENTS_REQUEST_FAILURE,
        error
    };
}