import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import Error from '../common/Error';
import FormAlternateMessage from '../alerts/FormAlternateMessage';
import Grid from '@material-ui/core/Grid';
import PageTitle from '../common/PageTitle';
import Information from '../common/Information';
import Media from "react-media";
import { KeyName } from '../../common/dto/common';
import { Display } from '../../common/constants/display';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import PrintQueueButtonContainer from '../../containers/PrintQueueButtonContainer';
import Spacer from '../common/Spacer';

export interface IPageLayoutProps {
    loading?: boolean;
    loadingText?: string;
    saving?: boolean;
    savingMessage?: string;
    aboveFooterContent?: any;
    error?: string;
    onClearError?: Function;
    header?: any;
    headerText?: any;
    headerContent?: any;
    tabContent?: any;
    alternateMessage?: any;
    warningMessage?: string;
    extraMessages?: any;
    footerContent?: any;
    footerLeftContent?: any;
    footerCenterContent?: any;
    footerRightContent?: any;
    moreCommands?: KeyName[];
    onCommand?: Function;
    hideQuickActions?: boolean;
    notFullWidth?: boolean;
    noDrawer?: boolean;
    theme?: any;
    classes?: any;
}

class PageLayout extends React.PureComponent<IPageLayoutProps, any> {
    render() {
        const { theme, classes } = this.props;
        let content;
        let warning;
        let tabContent = this.props.tabContent;
        let footerRightContent;

        if (this.props.saving) {
            content = <Saving message={this.props.savingMessage} />;
        } else if (this.props.loading) {
            content = <Loading message={this.props.loadingText} />;
        } else if (this.props.alternateMessage) {
            content = <FormAlternateMessage>{this.props.alternateMessage}</FormAlternateMessage>;
        } else {
            content = this.props.children;
        }
        if (this.props.warningMessage && this.props.warningMessage.length > 0) {
            warning = <Information isWarning={true} message={this.props.warningMessage} />;
        }
        let header = this.props.headerText ? <PageTitle title={this.props.headerText} /> : this.props.header;
        if (this.props.loadingText && this.props.loadingText != "" && this.props.loading) {
            // clean loading page
            header = <Spacer repeat={5} />;
            tabContent = <></>;
        }
        footerRightContent = this.props.footerRightContent ?
            this.props.footerRightContent : <PrintQueueButtonContainer
                moreCommands={this.props.moreCommands}
                onCommand={this.props.onCommand} />;

        let details = (
            <div>
                {header}
                {this.props.tabContent}
                <div>
                    {(this.props.error && this.props.error.length > 0) && (
                        <Error message={this.props.error} onClear={this.props.onClearError} />
                    )}
                    {this.props.extraMessages}
                    {warning}
                    {content}
                </div>
            </div>
        );
        let pageContent;
        if (!this.props.notFullWidth) {
            pageContent = (<>
                {details}
            </>);
        } else {
            pageContent = (
                <Media query="(max-width: 799px)">
                    {matches =>
                        matches ? details : (
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                                    {details}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                            </Grid>
                        )
                    }
                </Media>
            );
        }

        let footerContent = this.props.footerContent;
        if (footerContent === undefined) {
            footerContent = <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly'
            }}>
                <div style={{ flexGrow: 0, minWidth: '120px', minHeight: '36px', textAlign: 'center' }}>
                    {this.props.footerLeftContent}
                </div>
                {this.props.footerCenterContent && (
                    <div style={{ flexGrow: 0, minWidth: '120px', minHeight: '36px', textAlign: 'center' }}>
                        {this.props.footerCenterContent}
                    </div>
                )}
                {!this.props.footerCenterContent && (
                    <div style={{ flexGrow: 0, minWidth: '140px', margin: '-6px 0 -20px' }}>
                        {!this.props.hideQuickActions && (
                            <VenueQuickActionsContainer compact={true} />
                        )}
                    </div>
                )}
                <div style={{ flexGrow: 0, minWidth: '120px', minHeight: '36px', textAlign: 'center' }}>
                    {footerRightContent}
                </div>
            </div>
        }


        let footer = this.props.notFullWidth ? <Media query="(max-width: 799px)">
            {matches =>
                matches ? footerContent : (
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                            {footerContent}
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                    </Grid>
                )
            }
        </Media> : <>{footerContent}</>;

        return <div style={{ padding: '10px 10px 160px 10px' }}>
            {pageContent}
            {this.props.aboveFooterContent && (
                <div className={classes.aboveFooterPage}>
                    {this.props.aboveFooterContent}
                </div>
            )}
            <div className={this.props.noDrawer ? classes.footerPageNoDrawer : classes.footerPage}>
                {footer}
            </div>
        </div>
    }
}

export default withStyles(styles, { withTheme: true })(PageLayout);