import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueSubscription from '../components/admin/venue/VenueSubscription'
import {
    adminVenueSubscriptionRequest,
    adminVenueSubscriptionUpdate,
    adminVenueSubscriptionSaveRequest
} from '../common/actions/admin'
import { 
    userRolesSuspend
 } from '../common/actions/userRole';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        isLoading: state.admin.venueSubscription.isLoading,
        isSaving: state.admin.venueSubscription.isSaving || state.userRole.userRoles.isSaving,
        venueSubscription: state.admin.venueSubscription.venueSubscription,
        industryCategories: state.admin.venueSubscription.industryCategories,
        error: state.admin.venueSubscription.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(adminVenueSubscriptionRequest(venueId))
        },
        onUpdate: (venueSubscription) => {
            dispatch(adminVenueSubscriptionUpdate(venueSubscription))
        },
        onSave: () => {
            dispatch(adminVenueSubscriptionSaveRequest())
        },
        onSaveUserRole: (venueId, securityFirmId, undo) => {
            dispatch(userRolesSuspend(venueId, securityFirmId, undo))
        }
    }
}

const AdminVenueSubscriptionContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueSubscription))

export default AdminVenueSubscriptionContainer