import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { venueLocationLogic } from '../../../common/logic/venueLocationLogic';

export interface IVenueLocationCardProps {
    venueLocation: AusComplyDtos.VenueLocation;
    onCommand?: Function;
}

export default class VenueLocationCard extends React.Component<IVenueLocationCardProps, any> {
    constructor(props: IVenueLocationCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueLocation);
        }
    }

    render() {
        if (!this.props.venueLocation) {
            return (<></>);
        }

        var commands = venueLocationLogic.getCommands(this.props.venueLocation);
        var item = this.props.venueLocation;

        let industryCategories;
        var industries = this.props.venueLocation.industryCategoriesDisplay;
        if (item.venueId == 0) {
            if (industries.length > 100) {
                industries = industries.substring(0, 100) + "... (" + this.props.venueLocation.industryCategorySelections.filter(f => f.selected).length.toString() + ")";
            }
            if (industries.length > 0) {
                industryCategories = (
                    <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                        <Grid item xs={12}>
                            <CardTitle>Industries</CardTitle>
                            <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{industries}</CardTypography>
                        </Grid>
                    </Grid>
                );
            }
        }

        let subLocations;
        var selectedSubLocationsDisplay = this.props.venueLocation.selectedSubLocationsDisplay;
        if (item.venueId > 0) {
            if (selectedSubLocationsDisplay.length > 100) {
                selectedSubLocationsDisplay = selectedSubLocationsDisplay.substring(0, 100) + "... (" + this.props.venueLocation.selectedSubLocations.length.toString() + ")";
            }
            if (selectedSubLocationsDisplay.length > 0) {
                subLocations = (
                    <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                        <Grid item xs={12}>
                            <CardTitle>Sub Locations</CardTitle>
                            <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{selectedSubLocationsDisplay}</CardTypography>
                        </Grid>
                    </Grid>
                );
            }
        }

        return (
            <CardRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.name}</CardTypography>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                        <CardTitle>Code</CardTitle>
                        <CardTypography>{item.code}</CardTypography>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography>{item.rank}</CardTypography>
                    </Grid>
                </Grid>
                {industryCategories}
                {subLocations}
                {(item.facialRecognitionCameraSummary && item.facialRecognitionCameraSummary != '') &&
                    (
                        <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                            <Grid item xs={12}>
                                <CardTitle>Cameras</CardTitle>
                                <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{item.facialRecognitionCameraSummary}</CardTypography>
                            </Grid>
                        </Grid>
                    )}
            </CardRow>
        );
    }
}