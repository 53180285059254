import { connect } from 'react-redux'
import { withRouter } from "react-router";
import UserRolesSuspend from '../components/users/UserRolesSuspend'
import { 
    userRolesSuspend
 } from '../common/actions/userRole';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        undo: props.undo,
        isSaving: state.userRole.userRoles.isSaving,
        onSaved: props.onSaved
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSave: (venueId, securityFirmId, undo) => {
            dispatch(userRolesSuspend(venueId, securityFirmId, undo))
        }
    }
}

const UserRolesSuspendContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRolesSuspend))

export default UserRolesSuspendContainer