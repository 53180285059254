export const
    PRINT_RESET = "PRINT_RESET",
    PRINT_OPTIONS_REQUEST = "PRINT_OPTIONS_REQUEST",
    PRINT_OPTIONS_REQUEST_SUCCESS = "PRINT_OPTIONS_REQUEST_SUCCESS",
    PRINT_OPTIONS_REQUEST_FAILED = "PRINT_OPTIONS_REQUEST_FAILED",
    PRINT_SHOW = "PRINT_SHOW",
    PRINT_HIDE = "PRINT_HIDE",
    PRINT_UPDATE_OPTIONS = "PRINT_UPDATE_OPTIONS",
    PRINT_INCIDENT_REQUEST = "PRINT_INCIDENT_REQUEST",
    PRINT_INCIDENT_REQUEST_SUCCESS = "PRINT_INCIDENT_REQUEST_SUCCESS",
    PRINT_INCIDENT_REQUEST_FAILED = "PRINT_INCIDENT_REQUEST_FAILED",
    PRINT_INCIDENTS_EMAIL_REQUEST = "PRINT_EMAIL_REQUEST",
    PRINT_INCIDENTS_EMAIL_REQUEST_SUCCESS = "PRINT_EMAIL_REQUEST_SUCCESS",
    PRINT_INCIDENTS_EMAIL_REQUEST_FAILED = "PRINT_EMAIL_REQUEST_FAILED",
    PRINT_INCIDENTS_PDF_REQUEST = "PRINT_INCIDENTS_PDF_REQUEST",
    PRINT_INCIDENTS_PDF_REQUEST_SUCCESS = "PRINT_INCIDENTS_PDF_REQUEST_SUCCESS",
    PRINT_INCIDENTS_PDF_REQUEST_FAILED = "PRINT_INCIDENTS_PDF_REQUEST_FAILED",
    PRINT_INCIDENTS_CSV_REQUEST = "PRINT_INCIDENTS_CSV_REQUEST",
    PRINT_INCIDENTS_CSV_REQUEST_SUCCESS = "PRINT_INCIDENTS_CSV_REQUEST_SUCCESS",
    PRINT_INCIDENTS_CSV_REQUEST_FAILED = "PRINT_INCIDENTS_CSV_REQUEST_FAILED",
    PRINT_CHECKLIST_REQUEST = "PRINT_CHECKLIST_REQUEST",
    PRINT_CHECKLIST_REQUEST_SUCCESS = "PRINT_CHECKLIST_REQUEST_SUCCESS",
    PRINT_CHECKLIST_REQUEST_FAILED = "PRINT_CHECKLIST_REQUEST_FAILED",
    PRINT_QUEUE_REQUEST = "PRINT_QUEUE_REQUEST",
    PRINT_QUEUE_REQUEST_SUCCESS = "PRINT_QUEUE_REQUEST_SUCCESS",
    PRINT_QUEUE_REQUEST_FAILED = "PRINT_QUEUE_REQUEST_FAILED",
    PRINT_QUEUE_DOWNLOADED_REQUEST = "PRINT_QUEUE_DOWNLOADED_REQUEST",
    PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS = "PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS",
    PRINT_QUEUE_DOWNLOADED_REQUEST_FAILED = "PRINT_QUEUE_DOWNLOADED_REQUEST_FAILED",
    PRINT_QUEUE_DISMISS_REQUEST = "PRINT_QUEUE_DISMISS_REQUEST",
    PRINT_QUEUE_DISMISS_REQUEST_SUCCESS = "PRINT_QUEUE_DISMISS_REQUEST_SUCCESS",
    PRINT_QUEUE_DISMISS_REQUEST_FAILED = "PRINT_QUEUE_DISMISS_REQUEST_FAILED",
    PRINT_QUEUE_DISMISS_ALL_REQUEST = "PRINT_QUEUE_DISMISS_ALL_REQUEST",
    PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS = "PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS",
    PRINT_QUEUE_DISMISS_ALL_REQUEST_FAILED = "PRINT_QUEUE_DISMISS_ALL_REQUEST_FAILED",
    PRINT_SIGNONOFFREGISTERS_REQUEST = "PRINT_SIGNONOFFREGISTERS_REQUEST",
    PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS = "PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS",
    PRINT_SIGNONOFFREGISTERS_REQUEST_FAILED = "PRINT_SIGNONOFFREGISTERS_REQUEST_FAILED",
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST = "PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST",
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS = "PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS",
    PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_FAILED = "PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_FAILED";

export function printReset() {
    return {
        type: PRINT_RESET
    };
}

export function printOptionsRequest() {
    return {
        type: PRINT_OPTIONS_REQUEST
    };
}

export function printOptionsRequestSuccess(printOptions) {
    return {
        type: PRINT_OPTIONS_REQUEST_SUCCESS,
        printOptions
    };
}

export function printOptionsRequestFailed(error) {
    return {
        type: PRINT_OPTIONS_REQUEST_FAILED,
        error
    };
}

export function printOptionsUpdate(printOptions) {
    return {
        type: PRINT_UPDATE_OPTIONS,
        printOptions
    }
}

export function printShow() {
    return {
        type: PRINT_SHOW
    };
}

export function printHide() {
    return {
        type: PRINT_HIDE
    };
}

export function printIncidentsEmailRequest(filter, send, imageOption) {
    return {
        type: PRINT_INCIDENTS_EMAIL_REQUEST,
        filter,
        send,
        imageOption
    };
}

export function printIncidentsEmailRequestSuccess() {
    return {
        type: PRINT_INCIDENTS_EMAIL_REQUEST_SUCCESS
    };
}

export function printIncidentsEmailRequestFailed(error) {
    return {
        type: PRINT_INCIDENTS_EMAIL_REQUEST_FAILED,
        error
    };
}

export function printIncidentsPdfRequest(reportType, filter, imageOption) {
    return {
        type: PRINT_INCIDENTS_PDF_REQUEST,
        reportType,
        filter,
        imageOption
    };
}

export function printIncidentsPdfRequestSuccess() {
    return {
        type: PRINT_INCIDENTS_PDF_REQUEST_SUCCESS
    };
}

export function printIncidentsPdfRequestFailed(error) {
    return {
        type: PRINT_INCIDENTS_PDF_REQUEST_FAILED,
        error
    };
}

export function printIncidentsCsvRequest(filter) {
    return {
        type: PRINT_INCIDENTS_CSV_REQUEST,
        filter
    };
}

export function printIncidentsCsvRequestSuccess() {
    return {
        type: PRINT_INCIDENTS_CSV_REQUEST_SUCCESS
    };
}

export function printIncidentsCsvRequestFailed(error) {
    return {
        type: PRINT_INCIDENTS_CSV_REQUEST_FAILED,
        error
    };
}

export function printQueueRequest() {
    return {
        type: PRINT_QUEUE_REQUEST
    };
}

export function printQueueRequestSuccess(items) {
    return {
        type: PRINT_QUEUE_REQUEST_SUCCESS,
        items
    };
}

export function printQueueRequestFailed(error) {
    return {
        type: PRINT_QUEUE_REQUEST_FAILED,
        error
    };
}

export function printQueueDismissRequest(reportGenerationId) {
    return {
        type: PRINT_QUEUE_DISMISS_REQUEST,
        reportGenerationId
    };
}

export function printQueueDismissSuccess() {
    return {
        type: PRINT_QUEUE_DISMISS_REQUEST_SUCCESS
    };
}

export function printQueueDismissFailed(error) {
    return {
        type: PRINT_QUEUE_DISMISS_REQUEST_FAILED,
        error
    };
}

export function printQueueDownloadRequest(reportGenerationId) {
    return {
        type: PRINT_QUEUE_DOWNLOADED_REQUEST,
        reportGenerationId
    };
}

export function printQueueDownloadSuccess() {
    return {
        type: PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS
    };
}

export function printQueueDownloadFailed(error) {
    return {
        type: PRINT_QUEUE_DOWNLOADED_REQUEST_FAILED,
        error
    };
}

export function printQueueDismissAllRequest() {
    return {
        type: PRINT_QUEUE_DISMISS_ALL_REQUEST
    };
}

export function printQueueDismissAllSuccess() {
    return {
        type: PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS
    };
}

export function printQueueDismissAllFailed(error) {
    return {
        type: PRINT_QUEUE_DISMISS_ALL_REQUEST_FAILED,
        error
    };
}
     
export function printIncidentRequest(option, id, includeVersions, to, cc, bcc, imageOption) {
    return {
        type: PRINT_INCIDENT_REQUEST,
        option, 
        id,
        includeVersions, 
        to, 
        cc, 
        bcc,
        imageOption
    };
}

export function printIncidentRequestSuccess(message) {
    return {
        type: PRINT_INCIDENT_REQUEST_SUCCESS,
        message
    };
}

export function printIncidentRequestFailed(error) {
    return {
        type: PRINT_INCIDENT_REQUEST_FAILED,
        error
    };
} 

export function printChecklistRequest(option, filter, to, cc, bcc, imageOption) {
    return {
        type: PRINT_CHECKLIST_REQUEST,
        filter,
        to, 
        cc, 
        bcc,
        option,
        imageOption
    };
}

export function printChecklistRequestSuccess(message) {
    return {
        type: PRINT_CHECKLIST_REQUEST_SUCCESS,
        message
    };
}

export function printChecklistRequestFailed(error) {
    return {
        type: PRINT_CHECKLIST_REQUEST_FAILED,
        error
    };
}

export function printSignOnOffRegistersRequest(option, filter, to, cc, bcc) {
    return {
        type: PRINT_SIGNONOFFREGISTERS_REQUEST,
        option,
        filter,
        to, 
        cc, 
        bcc
    };
}

export function printSignOnOffRegistersRequestSuccess(message) {
    return {
        type: PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS,
        message
    };
}

export function printSignOnOffRegistersRequestFailed(error) {
    return {
        type: PRINT_SIGNONOFFREGISTERS_REQUEST_FAILED,
        error
    };
}
     
export function printIncidentCategoryTypeDocumentRequest(option, id, incidentCategoryTypeDocument, to, cc, bcc) {
    return {
        type: PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST,
        option, 
        id,
        incidentCategoryTypeDocument, 
        to, 
        cc, 
        bcc
    };
}

export function printIncidentCategoryTypeDocumentRequestSuccess(message) {
    return {
        type: PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS,
        message
    };
}

export function printIncidentCategoryTypeDocumentRequestFailed(error) {
    return {
        type: PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_FAILED,
        error
    };
}    