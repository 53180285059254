import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionEvents from '../components/facialRecognition/facialRecognitionEvents/FacialRecognitionEvents'
import { 
    facialRecognitionNotificationEventRequest, 
    facialRecognitionNotificationEventSet,
    facialRecognitionNotificationEventSaveRequest,
    facialRecognitionNotificationEventMoveItem,
 } from '../common/actions/facialRecognitionNotification';
 import * as facialRecognitionPoiActions from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        groupId: props.groupId,
        venueMode: props.venueMode,
        groupMode: props.groupMode,
        adminMode: props.adminMode,
        accessLevel: state.user.details.userSession.accessLevel,
        isSaving: state.facialRecognitionNotification.facialRecognitionEvents.isSaving,
        isLoading: state.facialRecognitionNotification.facialRecognitionEvents.isLoading,
        facialRecognitionEvents: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvents,
        facialRecognitionEvent: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvent,
        facialRecognitionCameras: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionCameras,
        facialRecognitionWatchlists: state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionWatchlists,
        incidentTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentTypes,
        incidentTypeCategoryTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentTypeCategoryTypes,
        incidentFlagTypes: state.facialRecognitionNotification.facialRecognitionEvents.incidentFlagTypes,
        userRoleTypes: state.facialRecognitionNotification.facialRecognitionEvents.userRoleTypes,
        defaultNotificationTrigger: state.facialRecognitionNotification.facialRecognitionEvents.defaultNotificationTrigger,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (forVenueId, forGroupId) => {
            dispatch(facialRecognitionNotificationEventRequest(forVenueId, forGroupId))
        },
        onSet: (facialRecognitionEvent) => {
            dispatch(facialRecognitionNotificationEventSet(facialRecognitionEvent))
        },
        onSave: () => {
            dispatch(facialRecognitionNotificationEventSaveRequest())
        },
        onMoveItem: (facialRecognitionEvent, position) => {
            dispatch(facialRecognitionNotificationEventMoveItem(facialRecognitionEvent, position))
        },
        onGenerateAgeAppearance: (facialRecognitionEventId) => {
            dispatch(facialRecognitionPoiActions.facialRecognitionPoiGenerateAppearanceRequest("", 0, 0,facialRecognitionEventId))
        },
    }
}

const FacialRecognitionEventsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionEvents))

export default FacialRecognitionEventsContainer