import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionPOIServiceApi } from '../services/facialRecognitionPOIService';
import * as facialRecognitionPOIActions from "../actions/facialRecognitionPOI";
import * as editIncidentActions from "../actions/editIncident";
import * as epicCommon from './common';
import { notificationSuccessShow } from "../actions/notification";

const facialRecognitionPoiRegistrationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.createRegistration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId, action['incidentId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationRequestEpic.createRegistration", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
    );

const facialRecognitionPoiVenueRegistrationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_VENUE_REGISTRATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.createVenueRegistration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiVenueRegistrationRequestEpic.createVenueRegistration", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiVenueRegistrationRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
    );

const facialRecognitionPoiGroupRegistrationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_GROUP_REGISTRATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.createGroupRegistration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forGroupId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiGroupRegistrationRequestEpic.createGroupRegistration", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiGroupRegistrationRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationRequestFailure))
    );

const facialRecognitionPoiRegistrationScanFacesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.scanFaces(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionPOI.poiRegistration.poiRegistration)
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationScanFacesRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationScanFacesRequestEpic.scanFaces", facialRecognitionPOIActions.facialRecognitionPoiRegistrationScanFacesRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationScanFacesRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationScanFacesRequestFailure))
    );

const facialRecognitionPoiRegistrationSearchFaceRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.searchFace(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionPOI.poiRegistration.poiRegistration)
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationSearchFaceRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationSearchFaceRequestEpic.searchFace", facialRecognitionPOIActions.facialRecognitionPoiRegistrationSearchFaceRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationSearchFaceRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationSearchFaceRequestFailure))
    );

const facialRecognitionPoiRegistrationSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionPOI.poiRegistration.poiRegistration)
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiRegistrationSaveRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationSaveRequestEpic.save", facialRecognitionPOIActions.facialRecognitionPoiRegistrationSaveRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiRegistrationSaveRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiRegistrationSaveRequestFailure))
    );

const facialRecognitionPoiRegistrationSaveRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => action.poiRegistration.incidentId !== undefined && action.poiRegistration.incidentId > 0),
        //map((action: any) => editIncidentActions.editIncidentLoad(action['poiRegistration']['incidentId']))
        mergeMap(([action, state]) => of(editIncidentActions.editIncidentLoad(action['poiRegistration']['incidentId'])))
    );

const facialRecognitionPoiIncidentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.poiIncidents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionPOIIncidentId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiIncidentsRequestSuccess(response.data, response.facialRecognitionPOIIncidentId)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiIncidentsRequestEpic.poiIncidents", facialRecognitionPOIActions.facialRecognitionPoiIncidentsRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiIncidentsRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiIncidentsRequestFailure))
    );

const facialRecognitionPoiGenerateAppearanceRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.generateAppearance(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['poiGuid'],
                action['facialRecognitionWatchlistId'],
                action['facialRecognitionCameraId'],
                action['facialRecognitionEventId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiGenerateAppearanceRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiGenerateAppearanceRequestFailure.generateAppearance", facialRecognitionPOIActions.facialRecognitionPoiGenerateAppearanceRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiGenerateAppearanceRequestFailure", facialRecognitionPOIActions.facialRecognitionPoiGenerateAppearanceRequestFailure))
    );

const facialRecognitionPoiGenerateAppearanceRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        map((action: any) => notificationSuccessShow("Appearance generated"))
    );

const facialRecognitionPoisncidentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.poisIncidents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueId,
                action['poiGuids'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoisIncidentsRequestSuccess(response.data)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoisIncidentsRequestEpic.poiIncidents", facialRecognitionPOIActions.facialRecognitionPoisIncidentsRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoisIncidentsRequestEpic", facialRecognitionPOIActions.facialRecognitionPoisIncidentsRequestFailure))
    );

const facialRecognitionPoiSearchRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SEARCH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.search(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentId'],
                action['poiScan'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiSearchRequestSuccess(response.poiScan)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiSearchRequestEpic.search", facialRecognitionPOIActions.facialRecognitionPoiSearchRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiSearchRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiSearchRequestFailure))
    );

const facialRecognitionPoiExpiryFailuresRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.expiryFailures(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiExpiryFailureRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryFailuresRequestEpic.expiryFailures", facialRecognitionPOIActions.facialRecognitionPoiExpiryFailureRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryFailuresRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiExpiryFailureRequestFailure))
    );

const facialRecognitionPoiExpiryRetryRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.expiryRetry(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['filter'],
                action['facialRecognitionPOIIncidentId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiExpiryRetryRequestSuccess()),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryRetryRequestEpic.expiryRetry", facialRecognitionPOIActions.facialRecognitionPoiExpiryRetryRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryRetryRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiExpiryRetryRequestFailure))
    );

const facialRecognitionPoiExpiryDismissRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionPOIServiceApi.expiryDismiss(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionPOIIncidentId'])
                .pipe(
                    map(response => facialRecognitionPOIActions.facialRecognitionPoiExpiryDismissRequestSuccess()),
                    catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryDismissRequestEpic.expiryDismiss", facialRecognitionPOIActions.facialRecognitionPoiExpiryDismissRequestFailure))
                )
        ),
        catchError(error => epicCommon.notifyError(error, "facialRecognitionPoiExpiryDismissRequestEpic", facialRecognitionPOIActions.facialRecognitionPoiExpiryDismissRequestFailure))
    );

export const facialRecognitionPOIEpics = combineEpics(
    facialRecognitionPoiRegistrationRequestEpic,
    facialRecognitionPoiVenueRegistrationRequestEpic,
    facialRecognitionPoiGroupRegistrationRequestEpic,
    facialRecognitionPoiRegistrationScanFacesRequestEpic,
    facialRecognitionPoiRegistrationSearchFaceRequestEpic,
    facialRecognitionPoiRegistrationSaveRequestEpic,
    facialRecognitionPoiRegistrationSaveRequestSuccessEpic,
    facialRecognitionPoiIncidentsRequestEpic,
    facialRecognitionPoiGenerateAppearanceRequestEpic,
    facialRecognitionPoiGenerateAppearanceRequestSuccessEpic,
    facialRecognitionPoisncidentsRequestEpic,
    facialRecognitionPoiSearchRequestEpic,
    facialRecognitionPoiExpiryFailuresRequestEpic,
    facialRecognitionPoiExpiryRetryRequestEpic,
    facialRecognitionPoiExpiryDismissRequestEpic
);