import React from 'react';

const MapLocationIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M36.44,3.93C33.28,1.47,29.31,0,25,0C14.72,0,6.35,8.37,6.35,18.65C6.35,31.57,25.02,50,25.02,50s18.63-19.06,18.63-31.35c0-4.7-1.75-9.01-4.63-12.29L25.09,28.03L25,27.86l-3.77-5.47l2.25-3.9l1.61,2.39L28,16.55l-2.94-5.1l-8.74,15.14c2.13,2.45,5.27,4,8.77,4c3.48,0,6.61-1.54,8.74-3.96l-2.54-4.4l2.32-3.59l2.44,4.22l1.31,2.28c-2.27,4.48-6.91,7.55-12.27,7.55c-7.59,0-13.74-6.15-13.74-13.74S17.5,5.21,25.09,5.21c3.23,0,6.19,1.12,8.54,2.98L36.44,3.93zM27.17,7.5l3.2,5.54l2.08-3.09C30.95,8.72,29.15,7.86,27.17,7.5 M14.12,22.81l8.83-15.3c-5.4,1.01-9.5,5.74-9.5,11.44C13.45,20.31,13.69,21.6,14.12,22.81" />
        </g>
    </svg>
);

export default MapLocationIcon;

