import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import FullPageDialog from '../layout/FullPageDialog';
import SubTitle from '../common/SubTitle';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import ResponsiveList from '../common/ResponsiveList';

export interface INotificationTriggerUsersSummaryProps {
    notificationTriggerId: number;
    notificationTriggerUsersSummary: AusComplyDTOs.NotificationTriggerUsersSummary;
    isLoading: boolean;
    onLoad: Function;
    onClose: Function;
}

interface INotificationTriggerUsersSummaryState {
    open: boolean;
    showAllUsers: boolean;
}

export default class NotificationTriggerUsersSummary extends React.Component<INotificationTriggerUsersSummaryProps, INotificationTriggerUsersSummaryState> {

    constructor(props: INotificationTriggerUsersSummaryProps) {
        super(props);
        this.state = {
            open: false,
            showAllUsers: false
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({
                showAllUsers: false
            })
        } else if (prevProps.notificationTriggerId != this.props.notificationTriggerId && this.props.notificationTriggerId > 0) {
            this.setState({ open: true }, () => {
                self.props.onLoad(this.props.notificationTriggerId);
            });
        } else if (this.state.open && this.props.notificationTriggerId == 0) {
            this.setState({ open: false });
        }
    }


    render() {
        const { notificationTriggerUsersSummary, isLoading, onLoad, onClose, notificationTriggerId } = this.props;
        let self = this;
        let content;

        if (!isLoading && notificationTriggerUsersSummary) {

            let userRoles: AusComplyDTOs.NotificationTriggerUserSummary[] = [];
            let escalateUserRoles: AusComplyDTOs.NotificationTriggerUserSummary[] = [];
            if (notificationTriggerUsersSummary && notificationTriggerUsersSummary.userRoles && notificationTriggerUsersSummary.fallbackUserRoles) {
                userRoles = [...notificationTriggerUsersSummary.userRoles];
                escalateUserRoles = [...notificationTriggerUsersSummary.escalateUserRoles];
                if (!this.state.showAllUsers) {
                    if (notificationTriggerUsersSummary.showFallbackUserRole) {
                        userRoles = userRoles.filter(f => f.isOnline);
                    }
                    if (notificationTriggerUsersSummary.escalateShowFallbackUserRole) {
                        escalateUserRoles = escalateUserRoles.filter(f => f.isOnline);
                    }
                }
            }

            content = <>
                {notificationTriggerUsersSummary.showOnlineOnly && (
                    <CheckboxControlGroup
                        text={"Show all users"}
                        label={" "}
                        labelAbove={true}
                        defaultValue={this.state.showAllUsers}
                        onChanged={(value) => self.setState({ showAllUsers: !self.state.showAllUsers })} />
                )}
                <SubTitle text='Users' />
                <ResponsiveList
                    data={userRoles}
                    headers={["User", "Roles", "Groups"]}
                    columns={["userDisplay", "roles", "groups"]}
                    getCommands={item => []}
                    onCommand={(command, item) => { }}
                    isSuccess={item => item.isOnline}
                    isSuccessLabel='Online'
                />
                {notificationTriggerUsersSummary.showFallbackUserRole && <>
                    <SubTitle text='Fallback Users' />
                    <ResponsiveList
                        data={notificationTriggerUsersSummary.fallbackUserRoles}
                        headers={["User", "Roles", "Groups"]}
                        columns={["userDisplay", "roles", "groups"]}
                        getCommands={item => []}
                        onCommand={(command, item) => { }}
                        isSuccess={item => item.isOnline}
                        isSuccessLabel='Online'
                    />
                </>}
                {notificationTriggerUsersSummary.escalate && <>
                    <SubTitle text='Escalate Users' />
                    <ResponsiveList
                        data={escalateUserRoles}
                        headers={["User", "Roles", "Groups"]}
                        columns={["userDisplay", "roles", "groups"]}
                        getCommands={item => []}
                        onCommand={(command, item) => { }}
                        isSuccess={item => item.isOnline}
                        isSuccessLabel='Online'
                    />
                    {notificationTriggerUsersSummary.escalateShowFallbackUserRole && <>
                        <SubTitle text='Escalate Fallback Users' />
                        <ResponsiveList
                            data={notificationTriggerUsersSummary.escalateFallbackUserRoles}
                            headers={["User", "Roles", "Groups"]}
                            columns={["userDisplay", "roles", "groups"]}
                            getCommands={item => []}
                            onCommand={(command, item) => { }}
                            isSuccess={item => item.isOnline}
                            isSuccessLabel='Online'
                        />
                    </>
                    }
                </>
                }
            </>
        }

        return <FullPageDialog
            open={this.state.open}
            notFullWidth={true}
            title="Notification Trigger User Summary"
            loading={isLoading}
            footerLeftContent={<TransparentButton text={"Cancel"} onClick={onClose} />}
            footerRightContent={<PrimaryButton text={"Refresh"} onClick={() => onLoad(notificationTriggerId)} />}
            onDismissed={onClose}>
            {content}
        </FullPageDialog>;
    }
}

