import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../common/Loading';
import NoRowsMessage from '../common/NoRowsMessage';
import CardRow from '../common/CardRow';
import CardContent from '@material-ui/core/CardContent';
import CardContainer from '../common/CardContainer';
import CheckboxControl from "../controls/CheckboxControl";
import Box from '@material-ui/core/Box';

export interface ICardListProps {
    loading?: boolean;
    data: any[];
    card: Function;
    getCommands: Function;
    onCommand: Function;
    isSuccess?: Function;
    isSuccessLabel?: string;
    isInfo?: Function;
    isInfoLabel?: string;
    isWarning?: Function;
    isWarningLabel?: string;
    isDanger?: Function;
    isDangerLabel?: string;
    isDefaultLabel?: string;
    toggleDanger?: boolean;
    canEdit: boolean;
    footer?: any;
    classes: any;
}

interface ICardListState {
    showDanger: boolean;
    data: any[];
}

class CardList extends React.Component<ICardListProps, ICardListState> {
    constructor(props: ICardListProps) {
        super(props)
        this.state = {
            showDanger: false,
            data: []
        }
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.filter();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.filter();
        }
    }

    getValue(data: any[], index: number, column: string) {
        if (column.indexOf('.') > -1) {
            const fields = column.split('.');
            let value = data[index];
            if (value != undefined) {
                fields.forEach(field => {
                    if (value != undefined) {
                        value = value[field];
                    }
                });
            }
            return value;
        }
        return data[index][column];
    }

    filter() {
        if (!this.props.data) {
            this.setState({ data: [] });
            return;
        }
        const data = this.props.data.filter(d =>
            (!this.props.toggleDanger || this.state.showDanger || !this.props.isDanger || !this.props.isDanger(d))
        );
        this.setState({ data });
    }

    render() {
        const { classes } = this.props;
        let self = this;
        let content = (<></>);
        if (this.props.loading) {
            content = (<Loading />);
        } else if (this.props.data) {
            let cards;
            let hasSuccess = false;
            let hasDanger = false;
            let hasWarning = false;
            let hasInfo = false;
            const dangerLabel = this.props.isDangerLabel || "Removed"

            let filter;
            if (this.props.toggleDanger) {
                filter = (<CardContainer title={"Filter"}>
                    <CardContent>
                        <CheckboxControl
                            text={"Show " + dangerLabel.toLocaleLowerCase()}
                            defaultValue={this.state.showDanger}
                            onChanged={value => self.setState({ showDanger: value }, () => { self.filter(); })} />
                    </CardContent>
                </CardContainer>);
            }

            const data = this.state.data;
            const count = data.length;

            cards = data.map((item, index) => {
                let isDanger = this.props.isDanger != null ? this.props.isDanger(item) : false;
                let isWarning = this.props.isWarning != null ? this.props.isWarning(item) : false;
                let isInfo = this.props.isInfo != null ? this.props.isInfo(item) : false;
                let isSuccess = this.props.isSuccess != null ? this.props.isSuccess(item) : false;
                if (isDanger) {
                    hasDanger = true;
                }
                if (isWarning) {
                    hasWarning = true;
                }
                if (isSuccess) {
                    hasSuccess = true;
                }
                if (isInfo) {
                    hasInfo = true;
                }
                let commands = this.props.getCommands ? this.props.getCommands(item) : null;
                let cardContent = this.props.card(item, index);
                return (
                    <CardRow key={"card-row-" + index.toString() } isDanger={isDanger} isInfo={isInfo} isWarning={isWarning} isSuccess={isSuccess}
                        commands={commands} onCommand={(command) => this.props.onCommand(command, item)}>
                        {cardContent}
                    </CardRow>
                );
            });
            let footer = this.props.footer;
            if (!footer && (hasSuccess || hasDanger || hasWarning || hasInfo)) {
                footer = (
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                        {hasInfo && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentCurrent}></div>
                                <div className={classes.colorSwatchText}>{this.props.isInfoLabel || "Global"}</div>
                            </Box>
                        )}
                        {hasSuccess && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentApproved}></div>
                                <div className={classes.colorSwatchText}>{this.props.isSuccessLabel || "Success"}</div>
                            </Box>
                        )}
                        {hasWarning && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDraft}></div>
                                <div className={classes.colorSwatchText}>{this.props.isWarningLabel || "Warning"}</div>
                            </Box>
                        )}
                        {hasDanger && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDeleted}></div>
                                <div className={classes.colorSwatchText}>{dangerLabel}</div>
                            </Box>
                        )}
                        {this.props.isDefaultLabel && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentPending}></div>
                                <div className={classes.colorSwatchText}>{this.props.isDefaultLabel}</div>
                            </Box>
                        )}
                    </Box>
                );
            }
            if (count === 0) {
                content = (
                    <>
                        {filter}
                        <NoRowsMessage></NoRowsMessage>
                    </>
                );
            } else {
                content = (
                    <>
                        {filter}
                        <div className="paged-list">
                            <div>{cards}</div>
                            {this.props.footer}
                        </div>
                    </>
                );
            }
        }
        return content;
    }
}
export default withStyles(styles, { withTheme: true })(CardList);
