export const
    LOCATION_REQUEST = "LOCATION_REQUEST",
    LOCATION_CHANGED = "LOCATION_CHANGED";

export function locationRequest() {
    return {
        type: LOCATION_REQUEST
    };
}

export function locationChanged(found, latitude, longitude, error) {
    return {
        type: LOCATION_CHANGED,
        found,
        latitude,
        longitude,
        error
    };
}
