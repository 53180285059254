import { Theme, StyleRules } from '@material-ui/core/styles';
import { Palette } from './common/constants/palette';
import { Display } from './common/constants/display';

// classnames are not theme based, for any styles that require changes on light or dark mode use the themes (see the common\SubTitle.tsx)
export const styles = (theme: Theme): StyleRules => ({
    pageBackground: {
        backgroundColor: theme.palette.background.default
    },
    title: {
        textAlign: 'center',
        fontSize: '20px',
        color: Palette.Primary,
        padding: 0,
        margin: '10px',
        textTransform: 'uppercase'
    },
    loginTitle: {
        color: Palette.Primary,
        padding: '0',
        margin: '10px',
        textAlign: 'center',
        marginBottom: '10px',
        textShadow: '0 0 5px #000000',
        textTransform: 'uppercase',
        fontSize: '22px'
    },
    loginSubTitle: {
        color: Palette.SubTitle,
        padding: '0',
        margin: '0',
        textAlign: 'center',
        textShadow: '0 0 5px #000000',
        fontStyle: 'italic',
        fontSize: '12px'
    },
    cardContainer: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Palette.Primary,
        borderRadius: '8px'
    },
    cardContainerHeader: {
        margin: '0',
        padding: '2px',
        backgroundColor: Palette.Primary,
        fontSize: '1em',
        color: Palette.PrimaryText,
        textTransform: "uppercase",
        textAlign: "center"
    },
    cardContainerHeaderText: {
        margin: '0',
        padding: '2px',
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: '14px',

    },
    cardTitle: {
        color: Palette.Primary,
        margin: '0px 0px 10px',
        fontWeight: 500,
        textTransform: 'none',
        fontSize: '12px'
    },
    cardTitleObsolete: {
        color: Palette.Obsolete,
        margin: '0px 0px 10px',
        fontWeight: 500,
        textTransform: 'none',
        fontSize: '12px'
    },
    cardInformation: {
        color: 'rgba(255, 255, 255, 0.87)',
        margin: '1px 0px',
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '16px',
        padding: '0'
    },
    buttonPrimary: {
        backgroundColor: Palette.Primary,
        color: '#000',
        borderRadius: '15px',
        border: '1px solid ' + Palette.SubPage,
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '7px 7px',
        minWidth: '40px',
        fontWeight: 'bold',
        fontSize: '11px'
    },
    buttonPrimaryState: {
        '&:hover': {
            backgroundColor: Palette.PrimaryDark,
            color: Palette.PrimaryDarkText
        },
        '&:focus': {
            border: '1px solid rgb(47,47,47)',
        }
    },
    buttonPrimaryDisabled: {
        backgroundColor: Palette.PrimaryDisabled,
        color: Palette.PrimaryDisabledText
    },
    buttonPrimaryDisabledState: {
        '&:hover': {
            backgroundColor: Palette.PrimaryDisabledLighter,
            color: Palette.PrimaryDisabledText
        },
        '&:focus': {
            border: '1px solid ' + Palette.PrimaryDisabledLighter
        }
    },
    buttonLink: {
        backgroundColor: 'transparent',
        color: '#221e1f',
        border: 'none',
        textTransform: 'none',
        boxShadow: 'none',
        padding: '8px 8px',
        minWidth: '40px',
        fontWeight: 500,
        fontSize: '14px'
    },
    buttonLinkSmall: {
        fontWeight: 400,
        fontSize: '12px'
    },
    buttonTransparent: {
        backgroundColor: 'transparent',
        color: Palette.AlternativeText  + "!important",
        borderRadius: '15px',
        border: '1px solid ' + Palette.AlternativeText,
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '7px 7px',
        minWidth: '40px',
        fontWeight: 'normal',
        fontSize: '11px'
    },
    buttonTransparentDisabled: {
        backgroundColor: 'transparent',
        color: Palette.ControlBorder + "!important",
        borderRadius: '15px',
        border: '1px solid ' + Palette.ControlBorder,
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '7px 7px',
        minWidth: '40px',
        fontWeight: 'normal',
        fontSize: '11px'
    },
    buttonTransparentState: {
        '&:hover': {
            backgroundColor: '#555',
            opacity: 0.5,
            color: '#fefbf7'
        },
        '&:focus': {
            border: '1px solid #636364',
        }
    },
    buttonTransparentDisabledState: {
        '&:hover': {
            backgroundColor: '#555',
            opacity: 0.5,
            color: Palette.Disabled
        },
        '&:focus': {
            border: '1px solid #636364',
        }
    },
    dialogCenterHelper: {
        marginLeft: '0px',
        [theme.breakpoints.up('lg')]: {
            marginLeft: Display.DrawerWidth,
        },
    },
    fullScreenDialogLogo: {
        backgroundColor: Palette.Header,
        boxShadow: '0px 13px 25px 1px rgba(0,0,0,0.66)',
        '-webkit-box-shadow': '0px 13px 25px 1px rgba(0,0,0,0.66)'
    },
    // Navigation Specific Classes
    navRoot: {
        display: 'flex',
    },
    navDrawer: {
        [theme.breakpoints.up('lg')]: {
            width: Display.DrawerWidth,
            flexShrink: 0,
        },
    },
    navAppBar: {
        marginLeft: Display.DrawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${Display.DrawerWidth}px)`,
        },
    },
    navListStyle: {
        padding: 0
    },
    navListItemStyle: {
        '&:hover .MuiTypography-root ': {
            fontWeight: 'bold !important'
        }
    },
    navMenuButton: {
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    navToolbarLogo: {
        ...theme.mixins.toolbar,
        //position: 'absolute',
        backgroundColor: Palette.Header,
        position: 'fixed',
        top: 0,
        left: 0,
        width: Display.DrawerWidth,
        boxShadow: '0px 13px 25px 1px rgba(0,0,0,0.66)'
    },
    navDesktopPageTitle: {
        //margin: '-16px -16px 10px -16px', 
        ...theme.mixins.toolbar,
        backgroundColor: Palette.Header,
        textAlign: 'center',
        position: 'fixed',
        padding: '15px 0 0 15px',
        left: Display.DrawerWidth,
        top: 0,
        height: '64px',
        right: 0,
        boxShadow: '0px 13px 25px 1px rgba(0,0,0,0.66)'
    },
    navToolbar: {
        ...theme.mixins.toolbar
    },
    navDrawerPaper: {
        width: Display.DrawerWidth,
    },
    navDrawerContainer: {
        margin: '0'
    },
    navDrawerContainerItems: {
        padding: '80px 15px 80px 15px'
    },
    navContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    navSwitchPrimaryText: {
        color: Palette.Primary,
        fontSize: '12px'
    },
    navPrimaryText: {
        color: Palette.SecondaryText,
        fontSize: '12px'
    },
    navSubText: {
        color: Palette.SecondaryText,
        fontSize: '12px'
    },
    navSubSecondaryText: {
        color: Palette.SecondaryText,
        fontSize: '10px'
    },
    navSubSecondaryPrimaryText: {
        color: Palette.Primary,
        fontSize: '10px'
    },
    navListItem: {
        paddingTop: '6px',
        paddingBottom: '6px'
    },
    navListItemLink: {
        paddingTop: '6px',
        paddingBottom: '6px',
        borderBottom: '1px solid ' + Palette.Border,
    },
    navListItemPrimary: {
        paddingTop: '6px',
        paddingBottom: '6px',
        borderBottom: '1px solid ' + Palette.Primary,
    },
    // ~ end navigation styles


    headerMobile: {
        backgroundColor: '#1C1D23',
        borderBottom: 'none',
        marginLeft: Display.DrawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${Display.DrawerWidth}px)`,
        },
        boxShadow: '0px 13px 25px 1px rgba(0,0,0,0.66)'
    },
    footer: {
        backgroundColor: Palette.FooterBackground,
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        height: '70px',
        '-webkit-box-shadow': '0px -13px 25px 1px rgba(0,0,0,0.66)',
        boxShadow: '0px -13px 25px 1px rgba(0,0,0,0.66)'
    },
    footerPrimaryButton: {
        display: 'inline-table',
        position: 'relative',
        marginLeft: '0px',
        zIndex: 2,
        color: theme.palette.common.white,
        backgroundColor: 'transparent',
        border: 'none',
        top: '0',
        padding: '0',
        width: '90px',
        height: '80px',
        borderRadius: '0'
    },
    footerPrimaryButtonIcon: {
        marginTop: '0',
        fontSize: '2.6em',
        position: 'absolute',
        top: '0px',
        left: '0px',
        color: '#1C1D23'
    },
    footerPage: {
        backgroundColor: Palette.FooterBackground,
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        height: '70px',
        zIndex: 100,
        '-webkit-box-shadow': '0px -13px 25px 1px rgba(0,0,0,0.66)',
        boxShadow: '0px -13px 25px 1px rgba(0,0,0,0.66)',
        [theme.breakpoints.up('lg')]: {
            left: `${Display.DrawerWidth}px`,
        }
    },
    footerPageNoDrawer: {
        backgroundColor: Palette.FooterBackground,
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        flexDirection: 'column',
        justifyContent: 'center',
        height: '70px',
        '-webkit-box-shadow': '0px -13px 25px 1px rgba(0,0,0,0.66)',
        boxShadow: '0px -13px 25px 1px rgba(0,0,0,0.66)',
    },
    aboveFooterPage: {
        display: 'flex',
        position: 'fixed',
        bottom: '90px',
        left: '20px',
        right: '20px',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            left: `${Display.DrawerWidth}px`,
        }
    },
    menuFooterButtonLeftContainer: {
        paddingLeft: '20px',
        paddingRight: '10px'
    },
    menuFooterButtonMiddleContainer: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    menuFooterButtonCloseContainer: {
        paddingLeft: '10px',
        paddingRight: '20px'
    },
    buttonDefault: {
        backgroundColor: Palette.ControlBackgroundLight,
        color: Palette.Page,
        borderRadius: '15px',
        border: '1px solid ' + Palette.ControlBackgroundLight,
        textTransform: 'uppercase',
        boxShadow: 'none',
        minWidth: '40px',
        padding: '7px 7px',
        fontWeight: 'bold',
        fontSize: '11px',
        '&:hover': {
            backgroundColor: Palette.ControlBackgroundDark,
            color: Palette.ControlBackgroundLight
        },
        '&:focus': {
            border: '2px solid ' + Palette.Primary,
            // backgroundColor: Palette.ControlBackgroundLight,
            backgroundColor: Palette.ControlBackgroundDark,
            color: Palette.Primary,
            // backgroundColor: Palette.Primary,
            // color: Palette.PrimaryText,
        }
    },
    buttonDefaultDisabled: {
        backgroundColor: '#717171',
        color: '#444'
    },
    buttonDefaultDisabledState: {
        '&:hover': {
            backgroundColor: '#717171',
            color: '#444'
        },
        '&:focus': {
            border: 'none' //'1px solid #636364'
        }
    },
    homeStatisticsButton: {
        padding: '10px 0',
        borderColor: '#42c23b',
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px'
    },
    homeStatisticsLinkCount: {
        color: '#fff', textAlign: 'center', fontSize: '22px', padding: 0, margin: 0
    },
    homeStatisticsLinkCountZero: {
        color: '#808080', textAlign: 'center', fontSize: '22px', padding: 0, margin: 0
    },
    homeStatisticsLinkLabel: {
        color: '#fff', textAlign: 'center', fontSize: '9px', padding: 0, margin: 0, textOverflow: 'none'
    },
    homeStatisticsLinkLabelZero: {
        color: '#808080', textAlign: 'center', fontSize: '9px', padding: 0, margin: 0, textOverflow: 'none'
    },
    horizontalDivider: {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderLeftColor: Palette.ControlBorder
    },
    largeIconClearButton: {
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        minHeight: 100,
        color: Palette.ControlBorder
    },
    largeIconClearButtonDisabed: {
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        minHeight: 100,
        color: Palette.ControlBorder
    },
    largeIconClearButtonText: {
        whiteSpace: 'nowrap',
        color: '#FFF',
        fontSize: 12,
        padding: 0,
        margin: 0,
        textTransform: 'uppercase'
    },
    largeIconClearButtonTextDisabed: {
        whiteSpace: 'nowrap',
        color: '#636364',
        fontSize: 12,
        padding: 0,
        margin: 0,
        textTransform: 'uppercase'
    },
    smallIconClearButton: {
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        minHeight: 30,
        color: Palette.ControlBorder
    },
    smallIconClearButtonDisabed: {
        whiteSpace: 'nowrap',
        backgroundColor: 'transparent',
        minHeight: 30,
        color: Palette.ControlBorder
    },
    smallIconClearButtonText: {
        whiteSpace: 'nowrap',
        color: '#FFF',
        fontSize: 12,
        padding: 0,
        margin: 0,
        paddingLeft: 10,
        textTransform: 'uppercase'
    },
    smallIconClearButtonTextDisabed: {
        whiteSpace: 'nowrap',
        color: '#636364',
        fontSize: 12,
        padding: 0,
        margin: 0,
        paddingLeft: 10,
        textTransform: 'uppercase'
    },
    clearSelectionButton: {
        backgroundColor: '#28292F',
        minHeight: '40px',
        color: '#DE0000'
    },
    clearSelectionButtonText: {
        color: '#FFF',
        fontSize: '12px',
        padding: '4px',
        textAlign: 'left',
        margin: 0
    },
    labelNormal: {
      color: Palette.Primary,
      margin: 0,
      marginBottom: 0,
      marginTop: '2px',
      padding: 0,
      fontSize: '12px'
    },
    labelError: {
      margin: 0,
      marginBottom: 0,
      marginTop: '2px',
      padding: 0,
      color: Palette.Error,
      fontSize: '12px'
    },
    labelDescriptionError: {
        color: Palette.Error,
        fontSize: '19px'
    },
    controlText: {
        padding: 0,
        fontSize: '16px',
        margin: 0
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    // Not confirmed used
    ///////////////////////////////////////////////////////////////////////////////////////////////////////

    primaryColor: {
        color: Palette.Primary
    },
    warningColor: {
        color: '#FF6721'
    },
    dangerColor: {
        color: '#DE0000'
    },
    infoColor: {
        color: '#FF6721'
    },
    activeColor: {
        color: '#FFF'
    },
    deselectedColor: {
        color: Palette.Obsolete
    },
    controlColor: {
        color: '#737373'
    },
    marginTopAndBottom: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    fab: {
        display: 'inline-table',
        position: 'relative',
        marginLeft: '10px',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 2
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: '#42c23b',
    },
    fabSecondary: {
        color: theme.palette.common.black,
        backgroundColor: '#f99a1d',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    fabDefault: {
        color: theme.palette.common.black,
        backgroundColor: '#cfd0d2',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    fab_MenuItem: {
        padding: '30px 50px'
    },
    linearColorPrimary: {
        backgroundColor: '#2c2c2c',
    },
    linearBarColorPrimary: {
        backgroundColor: '#42c23b',
    },
    linearBarColorError: {
        backgroundColor: '#d2242b',
    },
    linearBarColorWarning: {
        backgroundColor: '#f99a1d',
    },
    linearOutlineColorPrimary: {
        color: '#42c23b',
        borderColor: '#42c23b'
    },
    linearOutlineColorError: {
        color: '#d2242b',
        borderColor: '#d2242b'
    },
    linearOutlineColorDisabled: {
        color: '#cfd0d2',
        borderColor: '#cfd0d2'
    },
    linearOutlineColorDefault: {
        color: '#cfd0d2',
        borderColor: '#cfd0d2'
    },
    backgroundColorPrimary: {
        color: '#42c23b'
    },
    backgroundColorSecondary: {
        color: '#f99a1d'
    },
    backgroundColorError: {
        color: '#d2242b'
    },
    backgroundColorDefault: {
        color: '#cfd0d2'
    },
    buttonClear: {
        backgroundColor: 'transparent',
        color: '#ffffff',
        border: 'none',
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '8px 8px',
        minWidth: '40px',
        fontWeight: 500,
        fontSize: '14px'
    },
    buttonDark: {
        backgroundColor: '#1f1f1f',
        color: '#ffffff',
        borderRadius: '5px',
        border: '1px solid #424242',
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '8px 8px',
        minWidth: '40px',
        fontWeight: 500,
        fontSize: '14px'
    },
    buttonSecondary: {
        backgroundColor: '#f99a1d',
        color: '#fefbf7',
        borderRadius: '5px',
        border: '1px solid #636364',
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '8px 8px',
        minWidth: '40px',
        fontWeight: 500,
        fontSize: '14px'
    },
    buttonError: {
        backgroundColor: Palette.Error,
        color: Palette.Page,
        borderRadius: '15px',
        border: '1px solid ' + Palette.SubPage,
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '7px 7px',
        minWidth: '40px',
        fontWeight: 'bold',
        fontSize: '11px'
    },
    buttonInfo: {
        backgroundColor: '#17a2b8',
        color: '#fefbf7',
        borderRadius: '5px',
        border: '1px solid #086f80',
        textTransform: 'uppercase',
        boxShadow: 'none',
        padding: '8px 8px',
        minWidth: '40px',
        fontWeight: 500,
        fontSize: '14px'
    },
    buttonCompact: {
        boxShadow: 'none',
        padding: '4px 4px',
        minWidth: '20px',
        fontWeight: 500,
        fontSize: '10px'
    },
    buttonNoWrap: {
        whiteSpace: 'nowrap'
    },
    buttonState: {
        '&:hover': {
            backgroundColor: '#222',
            color: '#fefbf7'
        },
        '&:focus': {
            backgroundColor: Palette.ControlBackgroundDark,
            color: Palette.ControlBackgroundLight,
            //border: '1px dotted ' + Palette.Info
        }
    },
    doubleHeightButton: {
        paddingTop: '16px',
        paddingBottom: '16px'
    },
    card: {
        borderRadius: 0,
        border: '1px solid #4a4a4a',
        backgroundColor: '#342f30',
        color: '#edeced'
    },
    footerBar: {
        minHeight: '0',
        padding: '10px'
    },
    inputRoot: {
        padding: '10px !important',
        margin: '0px !important',
        textAlign: 'left',
        backgroundColor: 'transparent'
    },
    inputRootTextArea: {
        padding: '10px !important',
        margin: '0px !important',
        textAlign: 'left',
        backgroundColor: 'transparent'
    },
    inputControl: {
        padding: '0 !important'
    },
    inputCenter: {
        textAlign: 'center'
    },
    inputAreaControl: {
        padding: '10px !important'
    },
    searchRoot: {
        padding: '8px 0 4px !important'
    },
    searchRootCompact: {
        padding: '2px 0 2px !important'
    },
    searchInput: {
        textAlign: 'left'
    },
    tabsRoot: {
        color: theme.palette.text.secondary,
        backgroundColor: 'rgba(33, 33, 33, 1)'
    },
    tabsIndicator: {
        backgroundColor: '#42c23b',
    },
    tabRoot: {
        '&$tabSelected': {
            color: '#42c23b',
            fontWeight: theme.typography.fontWeightMedium,
        }
    },
    tabRootSecondary: {
        textTransform: 'none',
        '&$tabSelected': {
            color: '#42c23b',
            fontWeight: theme.typography.fontWeightMedium,
        }
    },
    tabSelected: {},
    colorSwatchIncidentDraft: {
        background: '#f99a1d',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    colorSwatchIncidentPending: {
        background: '#342f30',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    colorSwatchIncidentApproved: {
        background: '#42c23b',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    colorSwatchIncidentCurrent: {
        background: '#03a9f4',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    colorSwatchIncidentDeleted: {
        background: '#b22222',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    colorSwatchText: {
        float: 'left',
        margin: '1px 1px 1px 0',
        color: '#888'
    },
    colorSwatchObsolete: {
        background: Palette.Obsolete,
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardIncidentCurrent: {
        border: '1px solid #03a9f4',
        borderLeftWidth: '5px'
    },
    cardObsolete: {
        border: '1px solid ' + Palette.Obsolete,
        borderLeftWidth: '5px'
    },
    cardIncidentPending: {
        border: '1px solid ' + Palette.Disabled,
        borderLeftWidth: '5px'
    },
    cardIncidentDraft: {
        border: '1px solid #f99a1d',
        borderLeftWidth: '5px'
    },
    cardIncidentApproved: {
        border: '1px solid #42c23b',
        borderLeftWidth: '5px'
    },
    cardIncidentRejected: {
        border: '1px solid #b22222',
        borderLeftWidth: '5px'
    },
    cardStatusRejected: {
        border: '1px solid #800080',
        borderLeftWidth: '5px'
    },
    colorSwatchChecklistSubmitted: {
        background: '#17a2b8',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardChecklistSubmitted: {
        border: '1px solid #17a2b8',
        borderLeftWidth: '5px'
    },
    cardChecklistRowSubmitted: {
        borderLeft: '5px solid #17a2b8'
    },
    colorSwatchChecklistApproved: {
        background: '#5cb85c',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardChecklistApproved: {
        border: '1px solid #5cb85c',
        borderLeftWidth: '5px'
    },
    cardChecklistRowApproved: {
        borderLeft: '5px solid #5cb85c'
    },
    colorSwatchChecklistInProgress: {
        background: '#f99a1d',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardChecklistInProgress: {
        border: '1px solid #f99a1d',
        borderLeftWidth: '5px'
    },
    cardChecklistRowInProgress: {
        borderLeft: '5px solid #f99a1d'
    },
    colorSwatchChecklistClosed: {
        background: '#342f30',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardChecklistClosed: {
        border: '1px solid #342f30',
        borderLeftWidth: '5px'
    },
    cardChecklistRowClosed: {
        borderLeft: '5px solid #342f30'
    },
    colorSwatchChecklistRejected: {
        background: Palette.Rejected,
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0',
    },
    cardChecklistRejected: {
        border: '1px solid purple',
        borderLeftWidth: '5px'
    },
    cardChecklistRowRejected: {
        borderLeft: '5px solid purple'
    },
    colorSwatchChecklistArchived: {
        background: '#b22222',
        height: '13px',
        width: '13px',
        float: 'left',
        margin: '4px 5px 1px 0'
    },
    cardChecklistArchived: {
        border: '1px solid #b22222',
        borderLeftWidth: '5px'
    },
    cardChecklistRowArchived: {
        borderLeft: '5px solid #b22222'
    },
    badge: {
        top: '50%',
        right: 3
    },
    badgeWithShadow: {
        top: '50%',
        right: 3,
        boxShadow: "0 0 5px 5px #1c1d23"
    },
    badgeTop: {
        top: '20%',
        right: 5
    },
    badgeTopRight: {
        top: 0,
        right: 0
    },
    badgeTopRightInside: {
        top: '10px',
        right: '10px'
    },
    badgeBottomRightInside: {
        top: '80%',
        right: '23px'
    },
    badgeBottomRightInsideWithShadow: {
        top: '80%',
        right: '23px',        
        boxShadow: "0 0 3px 3px #1c1d23"
    },
    tableContainer: {
        display: 'table',
        margin: 0,
        color: '#fff',
        boxSizing: 'border-box',
        padding: 0,
        lineHeight: '1.3',
        width: '100%',
        borderCollapse: "separate",
        borderSpacing: '0 4px'
    },
    tableHr: {
        fontSize: '12px',
        textAlign: 'left',
        display: 'tabel-cell',
        border: '1px solid transparent',
        borderLeftWidth: '5px',
        overflow: 'hidden'
    },
    tableHrCentered: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'tabel-cell',
        border: '1px solid transparent',
        borderLeftWidth: '5px',
        overflow: 'hidden'
    },
    tableHrRight: {
        fontSize: '12px',
        textAlign: 'right',
        display: 'tabel-cell',
        border: '1px solid transparent',
        borderLeftWidth: '5px',
        overflow: 'hidden'
    },
    tableHrCommands: {
        width: '30px'
    },
    tableHrIndicator: {
        width: '10px'
    },
    tableTr: {
        fontSize: '14px',
        backgroundColor: '#262626'
    },
    tableCellLink: {
        fontSize: '14px',
        color: '#0094ff !important'
    },
    rowFields: {
        color: '#FFF'
    },
    rowFieldsObsolete: {
        color: Palette.Obsolete
    },
    rowStatusPending: {
        backgroundColor: '#342f30'
    },
    rowStatusObsolete: {
        backgroundColor: Palette.Obsolete
    },
    rowStatusDraft: {
        backgroundColor: '#f99a1d'
    },
    rowStatusDanger: {
        backgroundColor: '#d2242b'
    },
    rowStatusInfo: {
        backgroundColor: '#03a9f4'
    },
    rowStatusApproved: {
        backgroundColor: '#42c23b'
    },
    rowStatusRejected: {
        backgroundColor: '#800080'
    },
    incidentPersonBoxList: {
        listStyle: 'none',
        position: 'relative',
        padding: 0,
        margin: 0
    },
    incidentPersonBoxListItem: {
        color: 'white',
        border: 'none',
        display: 'inline-block',
        margin: '10px 10px 0 0',
        width: '80px',
        height: '80px'
    },
    incidentPersonBoxImageContainer: {
        textAlign: 'center',
        display: 'block',
        margin: 0
    },
    incidentPersonBoxImageSVG: {
        width: '1.7em',
        height: '1.7em'
    },
    incidentPersonBoxImage: {
        maxHeight: '50px',
        maxWidth: '70px'
    },
    incidentPersonBoxLabel: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        fontSize: '10px',
        margin: '2px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    incidentPersonBoxAlert: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        background: 'black',
        margin: '0',
        padding: '0',
        lineHeight: '1em'
    },
    progressItem: {
        borderRadius: '8px',
        padding: '1px',
        height: '10px',
        marginTop: '5px',
        width: '100%'
    },
    progressItemText: {
        textAlign: 'center',
        fontSize: '7px',
        textTransform: 'uppercase',
        marginTop: '3px',
        fontFamily: 'Montserrat-Medium'
    },
    progressStep: {
        borderRadius: '8px',
        backgroundColor: '#fff',
        height: '16px',
        width: '16px',
        padding: '0px',
        position: 'absolute',
        top: '2px',
        right: '0px'
    },
    progressStepInner: {
        borderRadius: '5px',
        backgroundColor: '#555',
        height: '8px',
        width: '8px',
        padding: '0px',
        position: 'absolute',
        top: '6px',
        right: '4px'
    },
    typography: {
        color: Palette.Text,
        margin: "1px 0 1px 0"
    },
    typographyObsolete: {
        color: Palette.Obsolete,
        margin: "1px 0 1px 0"
    }
});

