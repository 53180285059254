import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IDocumentTypeHeaderProps {
    classes: any;
}

class DocumentTypeHeader extends React.Component<IDocumentTypeHeaderProps, any> {
    render() {
        const { classes } = this.props;
        
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Category</th>
                <th className={classes.tableHr}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Entity</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(DocumentTypeHeader);