import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { checklistTemplatesLogic } from '../../../common/logic/checklistTemplatesLogic';

export interface IChecklistTemplateCardProps {
    checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary;
    canEdit?: boolean;
    canEditGlobal?: boolean;
    canClone?: boolean;
    onCommand?: Function;
}

export default class ChecklistTemplateCard extends React.Component<IChecklistTemplateCardProps, any> {
    constructor(props: IChecklistTemplateCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.checklistTemplateSummary);
        }
    }

    render() {
        if (!this.props.checklistTemplateSummary) {
            return (<></>);
        }

        var commands = checklistTemplatesLogic.getCommands(this.props.checklistTemplateSummary,
            this.props.canEdit,
            this.props.canEditGlobal,
            this.props.canClone);

        var item = this.props.checklistTemplateSummary;

        return (
            <CardRow
                commands={commands}
                isSuccess={this.props.checklistTemplateSummary.isGlobal}
                isInfo={this.props.checklistTemplateSummary.isGroup}
                isDanger={this.props.checklistTemplateSummary.obsolete}
                onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CardTypography><b>{this.props.checklistTemplateSummary.name}</b></CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <CardTypography style={{ fontStyle: 'italic', textAlign: 'center' }}>
                                Item count: {this.props.checklistTemplateSummary.itemCount}
                            </CardTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <CardTypography style={{ fontStyle: 'italic', textAlign: 'center' }}>
                                Checklist count: {this.props.checklistTemplateSummary.instanceCount}
                            </CardTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}