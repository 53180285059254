import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { checklistTemplatesServiceApi } from "../services/checklistTemplates";
import { checklistTemplateApi } from "../services/checklistTemplate";
import { notificationShow } from "../actions/notification";
import {
  notifyError, notifyErrorMessage, notifyErrorResponseStatus
} from './common';
/*
import {
  checklistTemplatesReset,
  CHECKLIST_TEMPLATES_FILTER_REQUEST,
  CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS,
  checklistTemplatesFilterRequestSuccess,
  checklistTemplatesFilterRequestFailure,
  CHECKLIST_TEMPLATES_REQUEST,
  checklistTemplatesRequest,
  checklistTemplatesRequestSuccess,
  checklistTemplatesRequestFailure,
  CHECKLIST_TEMPLATES_SET_FILTER,
  CHECKLIST_TEMPLATES_PAGE,
  CHECKLIST_TEMPLATES_ARCHIVE_REQUEST,
  checklistTemplatesArchiveRequestSuccess,
  checklistTemplatesArchiveRequestFailure,
  CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS
} from "../actions/checklistTemplates";
*/
import * as checklistTemplatesActions from "../actions/checklistTemplates";

import {
  LOAD_CHECKLIST_TEMPLATE_REQUEST,
  loadChecklistTemplateRequest,
  loadChecklistTemplateSuccess,
  loadChecklistTemplateFailure,
  SAVE_CHECKLIST_TEMPLATE_REQUEST,
  saveChecklistTemplateSuccess,
  saveChecklistTemplateFailure
} from "../actions/checklistTemplate";

import {
  USER_CHANGED, USER_RESET
} from '../actions/userLogin';

import {
  VENUE_SECURITYFIRM_SELECT
} from '../actions/venueSecurityFirm';

const checklistTemplatesResetOnUserChangeEpic = (action$, state$) =>
  action$.pipe(
    ofType(USER_CHANGED, USER_RESET, VENUE_SECURITYFIRM_SELECT),
    map(action => checklistTemplatesActions.checklistTemplatesReset())
  );

const checklistTemplatesFilterRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_FILTER_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplatesServiceApi.newFilter(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['isAdmin']
      )
        .pipe(
          map(response => checklistTemplatesActions.checklistTemplatesFilterRequestSuccess(response.filter)),
          catchError(error => notifyError(error, "checklistTemplatesFilterRequestEpic.newFilter", checklistTemplatesActions.checklistTemplatesFilterRequestFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplatesFilterRequestEpic", checklistTemplatesActions.checklistTemplatesFilterRequestFailure))
  );

const checklistTemplatesFilterRequestSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS),
    map((action: any) => checklistTemplatesActions.checklistTemplatesRequest())
  );

const checklistTemplatesSetFilterEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_SET_FILTER),
    map((action: any) => checklistTemplatesActions.checklistTemplatesRequest())
  );

const checklistTemplatesRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_REQUEST, checklistTemplatesActions.CHECKLIST_TEMPLATES_PAGE),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplatesServiceApi.get(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        state.checklistTemplates.paging.page,
        state.checklistTemplates.paging.pageSize,
        state.checklistTemplates.filter)
        .pipe(
          map(response => checklistTemplatesActions.checklistTemplatesRequestSuccess(response.filter, response.paging, response.items)),
          catchError(error => notifyError(error, "checklistTemplatesRequestEpic.get", checklistTemplatesActions.checklistTemplatesRequestFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplatesRequestEpic", checklistTemplatesActions.checklistTemplatesRequestFailure))
  );

const checklistTemplateRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_CHECKLIST_TEMPLATE_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplateApi.getChecklistTemplate(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['checklistTemplateId']
      )
        .pipe(
          map(response => loadChecklistTemplateSuccess(response.checklistTemplate)),
          catchError(error => notifyError(error, "checklistTemplateRequestEpic.getChecklistTemplate", loadChecklistTemplateFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplateRequestEpic", loadChecklistTemplateFailure))
  );

const checklistTemplateSaveRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CHECKLIST_TEMPLATE_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplateApi.saveChecklistTemplate(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['checklistTemplate']
      )
        .pipe(
          map(response => saveChecklistTemplateSuccess(response.checklistTemplate)),
          catchError(error => notifyError(error, "checklistTemplateSaveRequestEpic.saveChecklistTemplate", saveChecklistTemplateFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplateSaveRequestEpic", saveChecklistTemplateFailure))
  );

const checklistTemplatesArchiveRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_ARCHIVE_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplatesServiceApi.archive(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['checklistTemplateIds'],
        action['restore']
      )
        .pipe(
          map(response => checklistTemplatesActions.checklistTemplatesArchiveRequestSuccess()),
          catchError(error => notifyError(error, "checklistTemplatesArchiveRequestEpic.archive", checklistTemplatesActions.checklistTemplatesArchiveRequestFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplatesArchiveRequestEpic", checklistTemplatesActions.checklistTemplatesArchiveRequestFailure))
  );

const checklistTemplatesArchiveRequestSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS),
    map((action: any) => checklistTemplatesActions.checklistTemplatesRequest())
  );

const checklistTemplateArchiveRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATE_ARCHIVE_REQUEST),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      checklistTemplatesServiceApi.archiveTemplate(
        state.user.details.userSession.user.venueId,
        state.user.details.userSession.user.securityFirmId,
        action['checklistTemplateId'],
        action['isAdmin'],
        action['restore']
      )
        .pipe(
          map(response => checklistTemplatesActions.checklistTemplateArchiveRequestSuccess()),
          catchError(error => notifyError(error, "checklistTemplateArchiveRequestEpic.archiveTemplate", checklistTemplatesActions.checklistTemplateArchiveRequestFailure))
        )
    ),
    catchError(error => notifyError(error, "checklistTemplateArchiveRequestEpic", checklistTemplatesActions.checklistTemplateArchiveRequestFailure))
  );


const checklistTemplateArchiveRequestSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(checklistTemplatesActions.CHECKLIST_TEMPLATE_ARCHIVE_REQUEST_SUCCESS),
    map((action: any) => checklistTemplatesActions.checklistTemplatesRequest())
  );

export const checklistTemplateEpics = combineEpics(
  checklistTemplatesResetOnUserChangeEpic,
  checklistTemplatesFilterRequestEpic,
  checklistTemplatesFilterRequestSuccessEpic,
  checklistTemplatesRequestEpic,
  checklistTemplatesSetFilterEpic,
  checklistTemplateRequestEpic,
  checklistTemplateSaveRequestEpic,
  checklistTemplatesArchiveRequestEpic,
  checklistTemplatesArchiveRequestSuccessEpic,
  checklistTemplateArchiveRequestEpic,
  checklistTemplateArchiveRequestSuccessEpic
);