import { makeActionCreator } from './helpers';

export const
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST = "CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST",
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_INMATE_CELL_SET_SEARCH = "CORRECTIONAL_FACILITY_INMATE_CELL_SET_SEARCH",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_CELL = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_CELL",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE_VENUELOCATION = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE_VENUELOCATION",
    CORRECTIONAL_FACILITY_INMATE_CELL_RESET = "CORRECTIONAL_FACILITY_INMATE_CELL_RESET",
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST = "CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST",
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST = "CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST",
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST = "CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST",
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_SET = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_SET",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST = "CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST",
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_FAILURE",
    CORRECTIONAL_FACILITY_RISK_STATUS_SET = 'CORRECTIONAL_FACILITY_RISK_STATUS_SET',
    CORRECTIONAL_FACILITY_RISK_STATUS_RESET = 'CORRECTIONAL_FACILITY_RISK_STATUS_RESET',
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST = 'CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST',
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_SUCCESS = 'CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_SUCCESS',
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_FAILURE = 'CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_FAILURE',
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST = "CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST",
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_SUCCESS = "CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_SUCCESS",
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_FAILURE = "CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_FAILURE";

export const correctionalFacilityInMatesCellsRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST);
export const correctionalFacilityInMatesCellsRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_SUCCESS, 'data', 'cells', 'risks', 'genders', 'nationalities');
export const correctionalFacilityInMatesCellsRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_FAILURE, 'error');
export const correctionalFacilityInMateCellReset = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_RESET);
export const correctionalFacilityInMateCellSetSearch = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_SET_SEARCH, 'search');
export const correctionalFacilityInMateCellUpdateInmate = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE, 'correctionalFacilityInMate');
export const correctionalFacilityInMateCellUpdateCell = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_CELL, 'correctionalFacilityInMateCell');
export const correctionalFacilityInMateCellUpdateVenueLocation = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE_VENUELOCATION, 'correctionalFacilityInMateVenueLocation');
export const correctionalFacilityInMateCellCreateRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST, 'search');
export const correctionalFacilityInMateCellCreateRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_SUCCESS, 'correctionalFacilityInMateCell', 'inmates', 'cells', 'risks', 'genders', 'nationalities');
export const correctionalFacilityInMateCellCreateRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_FAILURE, 'error');
export const correctionalFacilityInMateCellSaveRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST);
export const correctionalFacilityInMateCellSaveRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_SUCCESS, 'correctionalFacilityInMateCell');
export const correctionalFacilityInMateCellSaveRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_FAILURE, 'error');
export const correctionalFacilityInMateCellRemoveRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST, 'correctionalFacilityInMateVenueLocationId');
export const correctionalFacilityInMateCellRemoveRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS);
export const correctionalFacilityInMateCellRemoveRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_FAILURE, 'error');
export const correctionalFacilityInMateCellUpdateSet = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_SET, 'correctionalFacilityInMateCell');
export const correctionalFacilityInMateCellUpdateRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST);
export const correctionalFacilityInMateCellUpdateRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS);
export const correctionalFacilityInMateCellUpdateRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_FAILURE, 'error');

export const correctionalFacilityRiskStatusesRequest = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST, 'pageSize', 'page');
export const correctionalFacilityRiskStatusesRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_SUCCESS, 'paging', 'correctionalFacilityRiskStatuses');
export const correctionalFacilityRiskStatusesRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_FAILURE, 'error');
export const correctionalFacilityRiskStatusSet = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUS_SET, 'correctionalFacilityRiskStatus');
export const correctionalFacilityRiskStatusReset = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUS_RESET);
export const correctionalFacilityRiskStatusSaveRequest = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST);
export const correctionalFacilityRiskStatusSaveRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_SUCCESS, 'paging', 'correctionalFacilityRiskStatuses');
export const correctionalFacilityRiskStatusSaveRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_FAILURE, 'error');

export const correctionalFacilityInMateCellInspectRequest = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST, 'correctionalFacilityInMateVenueLocationId');
export const correctionalFacilityInMateCellInspectRequestSuccess = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_SUCCESS, 'draftIncidentId');
export const correctionalFacilityInMateCellInspectRequestFailure = makeActionCreator(CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_FAILURE, 'error');
