import { connect } from 'react-redux'
import AdminDirectory from '../components/directory/AdminDirectory'
import * as directoryActions from '../common/actions/directory'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        isLoading: state.directories.isLoading,
        isSaving: state.directories.isSaving,
        directory: state.directories.directory,
        showObsolete: state.directories.showObsolete
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (entityType) => {
            dispatch(directoryActions.directoryAdminRequest(entityType))
        },
        onUpsertFolder: (entityType, folder) => {
            dispatch(directoryActions.directoryAdminUpsertFolderRequest(entityType, folder))
        }
    }
}

const AdminDirectoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminDirectory)

export default AdminDirectoryContainer