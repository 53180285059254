import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../common/dto/AusComply.dtos';
import { checklistLogic } from '../../../common/logic/checklistLogic';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import FixedFooter from '../../layout/FixedFooter';
import Loading from '../../common/Loading';
import PageMessage from '../../common/PageMessage';
import CheckListItem from './CheckListItem';
import CheckListNavigation from './CheckListNavigation';
import View from '../../common/View';
import moment from "moment";
import CheckListPrintContainer from '../../../containers/CheckListPrintContainer';
import PageLayout from '../../layout/PageLayout';

export interface ICheckListProps {
    checklistId?: number,
    checklist: AusComplyDtos.Checklist,
    checklistLoading: boolean,
    checklistSaving: boolean,
    checklistIsModified: boolean,
    checklistTemplate: AusComplyDtos.ChecklistTemplate,
    checklistTemplateLoading: boolean,
    items: CheckListDisplayItem[],
    checklistUserSelections: AusComplyDtos.ChecklistUserSelection[],
    onLoadCheckList: Function;
    onUploadAttachment: Function;
    onErrorNotification: Function;
    onUpdate: Function;
    onSubmit: Function;
    onSave: Function;
    onPrint: Function;
    canApprove: boolean;
    canReject: boolean;
    canClose: boolean;
    canPrint: boolean;
    canViewList: boolean;
    isUploading: boolean;
    isReadonly: boolean;
    hasErrors: boolean;
    history: any;
    location: any;
}

interface ICheckListState {
    templateIsMissing: boolean;
}

class CheckList extends React.Component<ICheckListProps, ICheckListState> {

    constructor(props: ICheckListProps) {
        super(props)

        this.state = {
            templateIsMissing: false
        }
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onVoid = this.onVoid.bind(this);
        this.onReject = this.onReject.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onChecklistItemUpdateByIndex = this.onChecklistItemUpdateByIndex.bind(this);
    }

    componentDidMount(): void {
        if(this.props.checklistId !== undefined && !this.props.checklistLoading) {
            this.props.onLoadCheckList(this.props.checklistId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.checklistSaving && prevProps.checklistSaving) {
            if (this.props.checklist.checklistStatus != AusComplyDtos.ChecklistStatuses.InProgress &&
                this.props.checklist.checklistStatus != AusComplyDtos.ChecklistStatuses.Rejected) {
                // navigate away
                this.onCancel();
            }
        }
    }

    onCancel() {
        if (this.props.canViewList) {
            this.props.history.push('/checklists');
        } else {
            this.props.history.push('/');
        }
    }

    onSave() {
        this.props.onUpdate({ ...this.props.checklist })
    }

    onApprove() {
        if (this.props.checklistSaving) {
            this.props.onErrorNotification("Please wait until save has completed");
            return;
        }

        let checklist = { ...this.props.checklist };
        if (checklistLogic.canApprove(checklist.checklistStatus, this.props.canApprove)) {
            checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.Approved;
            this.props.onUpdate(checklist);
            this.props.onSave(checklist);
        }
    }

    onVoid() {
        let checklist = { ...this.props.checklist };
        if (checklistLogic.canClose(checklist.checklistStatus, this.props.canClose)) {
            checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.Closed;
            this.props.onUpdate(checklist);
            this.props.onSave(checklist);
        }
    }

    onReject() {
        let checklist = { ...this.props.checklist };
        if (checklistLogic.canReject(checklist.checklistStatus, this.props.canReject)) {
            checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.Rejected;
            this.props.onUpdate(checklist);
            this.props.onSave(checklist);
        }
    }

    onSubmit() {
        if (this.props.checklistSaving) {
            this.props.onErrorNotification("Please wait until save has completed");
            return;
        }
        if (this.props.hasErrors) {
            this.props.onErrorNotification("Please complete all required items");
            return;
        }
        this.props.onSubmit();
    }

    onPrint() {
        this.props.onPrint();
    }

    onChecklistItemUpdateByIndex(checkListItem: AusComplyDtos.ChecklistItem, index: number) {
        let checklist = { ...this.props.checklist };
        if (index < checklist.checklistItems.length) {
            if (checklist.checklistItems[index].checklistItemId == checkListItem.checklistItemId) {
                checklist.checklistItems[index] = { ...checkListItem };
                this.props.onUpdate(checklist);
            }
        }
    }

    renderDisplayItem(checkListDisplayItem: CheckListDisplayItem, index: number, isReadonly: boolean) {
        // find the template
        if (checkListDisplayItem.isTemplateMissing) {
            return (<View key={"checklistitem-" + index.toString()}>
                <PageMessage text={"Item template is missing"}></PageMessage>
            </View>);
        }

        if (!checkListDisplayItem.template || !checkListDisplayItem.item) {
            return (<View key={"checklistitem-" + index.toString()}></View>);
        }

        let key = "checklistitem-" + index.toString();
        if (checkListDisplayItem.item.checklistItemId) {
            key = key + "_" + checkListDisplayItem.item.checklistItemId.toString();
        }
        if (checkListDisplayItem.template.checklistTemplateItemId) {
            key = key + "_" + checkListDisplayItem.template.checklistTemplateItemId.toString();
        }

        if (this.props.checklistUserSelections && this.props.checklistUserSelections.length > 0) {
            key = key + "_" + this.props.checklistUserSelections.length.toString()
        }

        return (
            <CheckListItem key={key}
                checkListDisplayItem={checkListDisplayItem}
                checklistUserSelections={this.props.checklistUserSelections}
                onUploadAttachment={this.props.onUploadAttachment}
                onUpdate={(i) => this.onChecklistItemUpdateByIndex(i, index)}
                isReadonly={isReadonly}
                isUploading={this.props.isUploading} />
        )
    }

    render() {

        if (this.props.checklistLoading || this.props.checklistTemplateLoading || !this.props.checklist) {
            return (
                <>
                    <PageTitle title={"Checklist"} />
                    <Loading />
                </>
            );
        }

        if (this.state.templateIsMissing) {
            return (
                <Grid container style={{ marginTop: '20px' }}>
                    <Grid item xs={12}>
                        <PageMessage text={"Template is missing"}></PageMessage>
                    </Grid>
                </Grid>
            );
        }

        let items;
        if (this.props.items) {
            items = this.props.items.map((item, index) => {
                return this.renderDisplayItem(item, index, this.props.isReadonly);
            });
        }
        let canApprove = !this.props.checklist.obsolete && checklistLogic.canApprove(this.props.checklist.checklistStatus, this.props.canApprove);
        let canReject = !this.props.checklist.obsolete && checklistLogic.canReject(this.props.checklist.checklistStatus, this.props.canReject);
        let canClose = !this.props.checklist.obsolete && checklistLogic.canClose(this.props.checklist.checklistStatus, this.props.canClose);

        return (
            <PageLayout
                headerText={this.props.checklist.name}
                loading={this.props.checklistLoading}
                footerContent={
                    <CheckListNavigation
                        onCancel={this.onCancel}
                        onSave={this.onSave}
                        onSubmit={this.onSubmit}
                        canSubmit={!this.props.checklist.obsolete && !this.props.hasErrors && !this.props.isUploading && !this.props.checklistSaving && !this.props.checklistIsModified}
                        isReadonly={this.props.isReadonly}
                        isSaving={this.props.checklistSaving}
                        canApprove={canApprove}
                        canReject={canReject}
                        canClose={canClose}
                        onApprove={this.onApprove}
                        onReject={this.onReject}
                        onClose={this.onVoid}
                        canPrint={this.props.canPrint}
                        onPrint={this.onPrint}
                    />
                }
            >
                {items}
                {(this.props.checklist.enteredByUserDisplayName && this.props.checklist.dateEntered && this.props.checklist.dateModified) && (
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item xs={12}>
                            <p style={{ fontStyle: 'italic', textAlign: 'center', color: "#888" }}>
                                Created by {this.props.checklist.enteredByUserDisplayName}{" "}
                                {moment(this.props.checklist.dateEntered).fromNow()}, last
                                modified {moment(this.props.checklist.dateModified).fromNow()}
                            </p>
                        </Grid>
                    </Grid>
                )}
                <CheckListPrintContainer />
            </PageLayout>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(CheckList));