import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import SearchControl from './SearchControl';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Label from '../common/Label';
import PageMessage from '../common/PageMessage';
import { SingleSelectList } from '../../common/dto/common';
import TickIcon from '../../resources/TickIcon';
import { Palette } from '../../common/constants/palette';

export interface ISingleSelectListControlProps {
    classes: any;
    theme: any;
    canShowAll?: boolean;
    clearSearchOnSelect?: boolean;
    emptyMessage?: string;
    selectedTitle?: string;
    availableTitle?: string;
    searchText?: string;
    commonTitle?: string;
    items?: SingleSelectList[];
    onSelectedItemChanged?: Function;
    minimumCharactersRequired?: number;
    autoHideOnSelected?: boolean;
    hideEmptyMessage?: boolean;
    hideAvailableText?: boolean;
    hideSelectedTitle?: boolean;
}

class SingleSelectListControl extends React.PureComponent<ISingleSelectListControlProps, any> {
    constructor(props: ISingleSelectListControlProps) {
        super(props)
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
        this.onShowAll = this.onShowAll.bind(this);

        this.state = {
            items: [],
            search: "",
            showAll: false
        };
    }

    componentDidMount() {
        if (this.props.items) {
            const theItems = this.props.items.map(l => Object.assign({}, l));
            this.setState({ items: theItems, showAll: false });
        } else {
            this.setState({ items: [], showAll: false })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.items != prevProps.items) {
            if (this.props.items) {
                const theItems = this.props.items.map(l => Object.assign({}, l));
                this.setState({ items: theItems });
            }
        }
    }

    onSelect(item) {
        const theItems = this.state.items.map(l => Object.assign({}, l));
        theItems.forEach(i => {
            if (i.id == item.id) {
                i.selected = true;
            }
        });
        
        let search = this.state.search;
        let showAll = this.state.showAll;
        if (this.props.clearSearchOnSelect) {
            search = "";
            showAll = false;
        }
        this.setState({ items: theItems, search, showAll }, () => {
            this.onSelectedItemChanged();
        });
    }

    onRemove(item) {
        const theItems = this.state.items.map(l => Object.assign({}, l));
        theItems.forEach(i => {
            if (i.id == item.id) {
                i.selected = false;
            }
        });
        this.setState({ items: theItems }, () => {
            this.onSelectedItemChanged();
        });
    }

    onSearchChanged(text) {
        this.setState({ search: text, showAll: false });
    }

    onSelectedItemChanged() {
        let selectedItem;
        this.state.items.forEach(i => {
            if (i.selected) {
                selectedItem = i;
            }
        });
        if (this.props.onSelectedItemChanged) {
            this.props.onSelectedItemChanged(selectedItem);
        }
    }
    
    onShowAll() {
        this.setState({ showAll: !this.state.showAll });
    }

    render() {
        const { classes, theme } = this.props;
        if (!this.state.items) {

            return (
                <PageMessage text={"Items are missing"} />
            );
        }

        let selectedItems = this.state.items.filter(item => { return item.selected; }).map((item, index) => (
            <div key={"selected-sli-" + item.id}
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    borderBottomColor: Palette.ControlBorder,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderRadius: 0,
                    padding: 0,
                    margin: '4px 0'
                }}>
                <p style={{
                    margin: 8,
                    flex: 1,
                    fontSize: '14px',
                    color: Palette.Text
                }}>
                    {item.name}
                </p>
                <Divider style={{
                    width: 1,
                    height: 28,
                    margin: 4,
                    color: Palette.ControlBorder
                }} />
                <IconButton style={{ padding: 10, color: Palette.Error }}
                    onClick={() => this.onRemove(item)}
                    aria-label="Remove">
                    <ClearIcon />
                </IconButton>
            </div>
        ))

        let selected;
        let isSelected = selectedItems.length > 0;

        if (!selectedItems || selectedItems.length == 0) {
            if (this.props.hideEmptyMessage) {
                selected = (<></>);
            } else {
                selected = (
                    <PageMessage text={this.props.emptyMessage || "Nothing selected"} />
                )
            }
        } else {
            selected = this.props.hideSelectedTitle ? <>
                {selectedItems}
            </> : <>
                <Label text={this.props.selectedTitle || "Selected"} style={{
                    display: "block",
                    margin: '10px 0',
                    //textAlign: 'center', 
                    //color: 'white', 
                    textTransform: 'uppercase'
                }} />

                <>
                    {selectedItems}
                </>
            </>
        }
        let comonItemsFiltered = this.state.items.filter(item => { return !item.selected && item.isCommon; });
        let commonItems = comonItemsFiltered.map((item, index) => (
            <div key={"common-sli-" + item.id}
                onClick={() => this.onSelect(item)}
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    borderBottomColor: Palette.ControlBorder,
                    borderBottomStyle: (index === comonItemsFiltered.length - 1) ? 'none' : 'solid',
                    borderBottomWidth: '1px',
                    borderRadius: 0,
                    padding: 0,
                    margin: '4px 0'
                }}>
                {item.info && item.infoColor == 'prefix' && (
                    <p style={{
                        margin: 8,
                        color: Palette.SecondaryText,
                        width: '100px'
                    }}>
                        {item.info}
                    </p>
                )}
                <p style={{
                    margin: 8,
                    flex: 1,
                    fontSize: '14px',
                    color: Palette.SecondaryText
                }}>
                    {item.name}
                </p>
                {item.info && item.infoColor == 'success' && (
                    <Chip style={{
                        backgroundColor: Palette.Primary,
                        color: 'white',
                        fontSize: '10px'
                    }}
                        label={item.info} />
                )}
                {item.info && item.infoColor == 'error' && (
                    <Chip style={{
                        backgroundColor: Palette.Error,
                        color: 'white',
                        fontSize: '10px'
                    }}
                        label={item.info} />
                )}
                <Divider style={{
                    width: 1,
                    height: 28,
                    margin: 4
                }} />
                <IconButton style={{ padding: 10, color: Palette.Primary }} aria-label="Select">
                    <TickIcon style={{ width: '20px', height: '20px' }} fill={Palette.Primary} />
                </IconButton>
            </div>
        ))

        let common;
        if (commonItems && commonItems.length > 0 && !isSelected) {
            common = (
                <>
                    <Label text={this.props.commonTitle || "Common"} style={{
                        display: "block",
                        margin: '10px 0',
                        //textAlign: 'center',
                        //color: 'white',
                        textTransform: 'uppercase'
                    }} />
                    <>
                        {commonItems}
                    </>
                </>
            )
        }
        let minSearchLength = this.props.minimumCharactersRequired || -1;
        let totalAvailable = this.state.items.filter(item => { return !item.selected && !item.isCommon; }).length;
        let availableItemsfiltered = this.state.items.filter(item => {

            if (this.state.showAll) {
                return !item.selected;
            }
            
            if (!this.state.search && minSearchLength > -1) {
                return false;
            }

            if (this.state.search && this.state.search.length < minSearchLength) {
                return false;
            }
            if (this.state.search) {
                if (item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) < 0) {
                    return false;
                }
            }
            return !item.selected && !item.isCommon;
        });

        let availableItems = availableItemsfiltered.map((item, index) => (
            <div key={"available-sli-" + item.id}
                onClick={() => this.onSelect(item)}
                style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    borderBottomColor: Palette.ControlBorder,
                    borderBottomStyle: (index === availableItemsfiltered.length - 1) ? 'none' : 'solid',
                    borderBottomWidth: '1px',
                    borderRadius: 0,
                    padding: 0,
                    margin: '4px 0'
                }}>
                <p style={{
                    margin: 8,
                    flex: 1,
                    fontSize: '14px',
                    color: Palette.SecondaryText
                }}>
                    {item.name}
                </p>
                {item.info && item.infoColor == 'success' && (
                    <Chip style={{
                        backgroundColor: Palette.Primary,
                        color: 'white',
                        fontSize: '10px'
                    }}
                        label={item.info} />
                )}
                {item.info && item.infoColor == 'error' && (
                    <Chip style={{
                        backgroundColor: Palette.Error,
                        color: 'white',
                        fontSize: '10px'
                    }}
                        label={item.info} />
                )}
                <Divider style={{
                    width: 1,
                    height: 28,
                    margin: 4
                }} />
                <IconButton style={{ padding: 10, color: Palette.Primary }} aria-label="Select" onClick={() => this.onSelect(item)}>
                    <TickIcon style={{ width: '20px', height: '20px' }} fill={Palette.Primary} />
                </IconButton>
            </div>
        ))

        let available;
        if (availableItems && !isSelected && (availableItems.length > 0 || totalAvailable > 0)) {
            available = (
                <>
                    {!this.props.hideAvailableText && (
                        <Label text={this.props.availableTitle || "Available"} style={{
                            display: "block",
                            margin: '10px 0',
                            //textAlign: 'center',
                            //color: 'white',
                            textTransform: 'uppercase'
                        }} />
                    )}
                    <SearchControl
                        value={this.state.search}
                        searchText={this.props.searchText}
                        showAll={this.props.canShowAll}
                        onChange={this.onSearchChanged}
                        onShowAll={this.onShowAll} />
                    {(totalAvailable > 0 && availableItems.length == 0 && this.state.search && this.state.search.length >= minSearchLength) && (
                        <div style={{ marginTop: '10px' }}>
                            <PageMessage text={"Nothing found"} />
                        </div>
                    )}
                    {availableItems.length > 0 && (
                        <>
                            {availableItems}
                        </>
                    )}

                </>
            )
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {selected}
                    {common}
                    {available}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SingleSelectListControl);