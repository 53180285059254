import React from 'react';

const NavigateNextIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M0,40c0,22.1,17.9,40,40,40c22.1,0,40-17.9,40-40S62.1,0,40,0C17.9,0,0,17.9,0,40z M5.7,40C5.7,21.1,21.1,5.7,40,5.7S74.3,21.1,74.3,40c0,18.9-15.4,34.3-34.3,34.3S5.7,58.9,5.7,40z M34.2,26.3L47.9,40L34.2,53.7c-1.1,1.1-1.1,2.9,0,4c0.6,0.6,1.3,0.8,2,0.8c0.7,0,1.5-0.3,2-0.8L54,42c1.1-1.1,1.1-2.9,0-4L38.2,22.2c-1.1-1.1-2.9-1.1-4,0C33.1,23.3,33.1,25.1,34.2,26.3z" />
        </g>
    </svg>
);

export default NavigateNextIcon;

