
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const userRoleLogic = {
    getViewOrEditCommand(userRoleSummary: AusComplyDtos.UserRoleSummary, excludeViewEditCommands?: boolean){
        if (userRoleSummary.editUserAllowed && !excludeViewEditCommands) {
            return "editUser";
        } else if (userRoleSummary.viewUserAllowed && !excludeViewEditCommands) {
            return "view";
        }
        return "";
    },
    getCommands(userRoleSummary: AusComplyDtos.UserRoleSummary, excludeViewEditCommands?: boolean, isAdmin?: boolean) {
        var commands: KeyName[] = [];
        if (userRoleSummary.userRoleObsolete) {
            if (userRoleSummary.editAllowed) {
                commands.push({ key: "enable", name: "Re-enable Role" });
            }
        } else {
            if (userRoleSummary.editAllowed) {
                commands.push({ key: "edit", name: "Edit Role" });
            }
            if (userRoleSummary.deleteAllowed) {
                commands.push({ key: "delete", name: "Remove Role" });
            }
        }
        if (userRoleSummary.editUserAllowed && !excludeViewEditCommands) {
            commands.push({ key: "editUser", name: "Edit User" });
        } else if (userRoleSummary.viewUserAllowed && !excludeViewEditCommands) {
            commands.push({ key: "view", name: "View User" });
        }
        if (isAdmin) {
            commands.push({ key: "admin", name: "Edit User (Admin)" });
        }
        return commands;
    },
    canEditOrView(userRoleSummary: AusComplyDtos.UserRoleSummary) {
        if (userRoleSummary.editUserAllowed) {
            return true;
        } else if (userRoleSummary.viewUserAllowed) {
            return true;
        }
        return false;
    }
}