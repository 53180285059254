import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentCategoryTypeApi } from '../services/incidentCategoryType';
import * as incidentCategoryTypeActions from '../actions/incidentCategoryType';
import {
    notifyError
} from './common';

const incidentCategoryTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentCategoryTypeActions.INCIDENTCATEGORYTYPES_USAGE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.usage(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentTypeId'],
                action['entryPage'],
                action['reportingPeriodId'])
                .pipe(
                    map(response => incidentCategoryTypeActions.incidentCategoryTypesUsageRequestSuccess(response.venueIncidentCategoryTypeUsages)),
                    catchError(error => notifyError(error, "incidentCategoryTypesRequestEpic.usage", incidentCategoryTypeActions.incidentCategoryTypesUsageRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentCategoryTypesRequestEpic", incidentCategoryTypeActions.incidentCategoryTypesUsageRequestFailure))
    );


export const incidentCategoryTypeEpics = combineEpics(
    incidentCategoryTypesRequestEpic
);