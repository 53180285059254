import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueReportingPeriods from '../components/venue/reportingPeriods/VenueReportingPeriods'
import { 
    venueReportingPeriodScheduleCreateRequest, 
    venueReportingPeriodReset,
    venueReportingPeriodScheduleReset,
    venueReportingPeriodSchedulesRequest,
    venueReportingPeriodCurrentRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        venueReportingPeriodScheduleId: state.venue.venueReportingPeriodSchedule.venueReportingPeriodScheduleId,
        venueReportingPeriodId: state.venue.reportingPeriod.venueReportingPeriodId,
        canCreateSchedule: permissionsLogic.hasPermissionForState(state, "ManageReportingPeriods")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateSchedule: (venueId) => {
            dispatch(venueReportingPeriodScheduleCreateRequest(venueId))
        },
        onResetItem: () => {
            dispatch(venueReportingPeriodReset())
        },
        onResetScheduleItem: () => {
            dispatch(venueReportingPeriodScheduleReset())
        },
        onLoadCurrent: (venueId) => {
            dispatch(venueReportingPeriodCurrentRequest(venueId))
        },
        onLoadSchedule: (venueId) => {
            dispatch(venueReportingPeriodSchedulesRequest(venueId))
        }
    }
}

const VenueReportingPeriodsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueReportingPeriods))

export default VenueReportingPeriodsContainer