import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultButton from './DefaultButton';
import TransparentButton from './TransparentButton';

export interface IConfirmDialogProps {
    show: boolean;
    title: string;
    text?: string;
    dialogContent?: any;
    theme: any;
    classes: any;
    onCancel: Function;
    onConfirm: Function;
}

class ConfirmDialog extends Component<IConfirmDialogProps, any> {

    render() {
        let content;
        if (this.props.dialogContent){
            content = (
                this.props.dialogContent
            );
        } else {
            content = (
                <DialogContentText id="alert-dialog-description">
                    {this.props.text}
                </DialogContentText>
            );
        }
        return (
            <Dialog
                open={this.props.show}
                onClose={() => this.props.onCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    { content }
                </DialogContent>
                <DialogActions>
                    <DefaultButton onClick={this.props.onCancel} style={{ width: 'auto', marginRight: '10px' }}>No</DefaultButton>
                    <DefaultButton text={"Yes"} onClick={this.props.onConfirm} style={{ width: 'auto', marginRight: '10px' }} autoFocus></DefaultButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ConfirmDialog);