import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import Map from '../../common/Map'

import Geocode from "react-geocode";
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB4D0gfDc7dpa248E0GmMar9U5fctLRL_k");
Geocode.setLanguage("en");
// Enable or disable logs. Its optional.
Geocode.enableDebug();

export interface IRegisterVenueAddressProps {
    venue: AusComplyDTOs.Venue;
    errors: any;
    states: AusComplyDTOs.State[];
    onUpdate: Function;
    canMoveMarker: boolean;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterVenueAddress extends Component<IRegisterVenueAddressProps, any> {
    constructor(props: IRegisterVenueAddressProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.onMapRefresh = this.onMapRefresh.bind(this);
        this.onMapDragged = this.onMapDragged.bind(this);
    }


    onChange(fieldname, value) {
        let venue = { ...this.props.venue };
        venue[fieldname] = value;
        this.props.onUpdate(venue);
    }

    onMapRefresh() {
        // Get latidude & longitude from address.
        if (this.props.venue &&
            this.props.venue.address && this.props.venue.address !== '' &&
            this.props.venue.suburb && this.props.venue.suburb !== '' &&
            this.props.venue.stateId && this.props.venue.stateId !== 0 &&
            this.props.venue.postcode && this.props.venue.postcode !== '') {

            let stateCode = '';
            this.props.states.forEach(state => {
                if (state.stateId == this.props.venue.stateId) {
                    stateCode = state.name
                }
            });
            var search = this.props.venue.address + ', ' +
                this.props.venue.suburb + ', ' +
                stateCode + ', ' +
                this.props.venue.postcode;

            let self = this;
            // console.log(search);
            Geocode.fromAddress(search).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    // console.log(lat, lng);
                    self.onMapDragged(lat, lng);
                    ;
                },
                error => {
                    console.error(error);
                }
            );
        }

    }

    onMapDragged(lat, long) {
        let venue = { ...this.props.venue };
        venue.latitude = lat.toFixed(6);
        venue.longitude = long.toFixed(6);
        this.props.onUpdate(venue);
    }

    render() {
        let locationMap;
        let latitude = '';
        let longitude = '';
        if (this.props.venue && this.props.venue.latitude && this.props.venue.longitude) {
            latitude = this.props.venue.latitude.toString();
            longitude = this.props.venue.longitude.toString();
            if (!isNaN(this.props.venue.latitude) && !isNaN(this.props.venue.longitude)) {
                locationMap = (
                    <CardContainer
                        title={"Map"}>
                        <CardContent>
                            <LayoutForm>
                                <Map
                                    latitude={Number(this.props.venue.latitude)}
                                    longitude={Number(this.props.venue.longitude)}
                                    draggableMarker={this.props.canMoveMarker}
                                    fullscreenControl={true}
                                    zoomControl={true}
                                    onDragged={this.onMapDragged}
                                />
                            </LayoutForm>
                        </CardContent>
                    </CardContainer>
                );
            }
        }
        return (
            <>
                <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                    <CardContainer
                        title={"Address"}>
                        <CardContent>
                            <LayoutForm>
                                <TextControlGroup
                                    text={"Address"}
                                    error={this.props.errors["address"]}
                                    defaultValue={this.props.venue.address}
                                    onBlur={(value) => this.onChange("address", value)} />
                                <TextControlGroup
                                    text={"Suburb"}
                                    error={this.props.errors["suburb"]}
                                    defaultValue={this.props.venue.suburb}
                                    onBlur={(value) => this.onChange("suburb", value)} />
                                <SelectControlGroup
                                    text="State"
                                    error={this.props.errors["stateId"]}
                                    onChange={(value) => this.onChange("stateId", value)}
                                    readonly={this.props.venue.venueId > 0}
                                    defaultValue={this.props.venue.stateId}>
                                    <option value={0} />
                                    {this.props.states && this.props.states.map((item, index) => (
                                        <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                    ))}
                                </SelectControlGroup>
                                <TextControlGroup
                                    text={"postcode"}
                                    error={this.props.errors["postcode"]}
                                    defaultValue={this.props.venue.postcode}
                                    onBlur={(value) => this.onChange("postcode", value)} />
                                {this.props.canMoveMarker && (
                                    <>
                                        <ControlGroup text={" "} >
                                            <DefaultButton text={"Set location from address"} onClick={this.onMapRefresh} />
                                        </ControlGroup>
                                        <TextControlGroup
                                            text={"Latitude"}
                                            error={this.props.errors["latitude"]}
                                            defaultValue={latitude}
                                            onBlur={(value) => this.onChange("latitude", value)} />
                                        <TextControlGroup
                                            text={"Longitude"}
                                            error={this.props.errors["longitude"]}
                                            defaultValue={longitude}
                                            onBlur={(value) => this.onChange("longitude", value)} />
                                    </>
                                )}
                            </LayoutForm>
                        </CardContent>
                    </CardContainer>
                </SwipeContainer>
                {locationMap}
            </>
        );
    }
}