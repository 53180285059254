import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import FilterDisplay from '../common/FilterDisplay';

export interface ISimpleFilterSummaryProps {
    filter: AusComplyDTOs.SimpleFilter;
    onClick: Function;
}

export default class SimpleFilterSummary extends React.Component<ISimpleFilterSummaryProps, any> {
    render() {
        if (!this.props.filter) {
            return (<></>);
        }
        return (
            <FilterDisplay text={this.props.filter.display} onClick={this.props.onClick} />
        );
    }
}