import update from "immutability-helper";
import {
    NOTIFICATION_SHOW,
    NOTIFICATION_HIDE
} from "../actions/notification";

const initialState = {
    message: "",
    messageType: ""
};

export default function notification(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_SHOW:
            return update(state, {
                message: { $set: action.message },
                messageType: { $set: action.messageType || "" }
            });
        case NOTIFICATION_HIDE:
            return update(state, {
                message: { $set: "" },
                messageType: { $set: "" }
            });
    }
    return state;
}
