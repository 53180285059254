import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IIncidentTypeProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface IIncidentTypeState {
}

export default class IncidentType extends Component<IIncidentTypeProps, IIncidentTypeState> {
    constructor(props: IIncidentTypeProps) {
        super(props)
        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        const { externalEvent } = this.props;

        if (!externalEvent) return <></>;

        return (
            <SelectControlGroup
                        text="Incident Type"
                        onChange={(value) => this.onValueChanged("incidentTypeId", Number(value))}
                        defaultValue={this.props.externalEvent.incidentTypeId}>
                        <option key={0} value={0}>Select...</option>
                        {this.props.externalEvent.incidentTypes.map((item, index) => (
                            <option key={item.incidentTypeId} value={item.incidentTypeId}>{item.name}</option>
                        ))}
            </SelectControlGroup>
        );
    }
}