import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import RadioChannels from '../components/radioChannels/RadioChannels'
import * as radioChannelActions from '../common/actions/radioChannel';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.radioChannels.radioChannels.paging,
        filter: state.radioChannels.radioChannels.filter,
        isLoading: state.radioChannels.radioChannels.isLoading,
        radioChannels: state.radioChannels.radioChannels.radioChannels,
        radioChannelId: state.radioChannels.radioChannel.radioChannelId,
        canEdit: permissionsLogic.hasPermissionForState(state, "RadioChannelsEdit")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(radioChannelActions.radioChannelsRequest(page, pageSize, filter))
        },
        onLoadItem: (radioChannelId) => {
            dispatch(radioChannelActions.radioChannelRequest(radioChannelId))
        },
        onCreate: (forGroupId, forVenueId) => {
            dispatch(radioChannelActions.radioChannelCreateRequest(forGroupId, forVenueId))
        },
        onResetItem: () => {
            dispatch(radioChannelActions.radioChannelReset())
        },
        onUpdate: (radioChannel) => {
            dispatch(radioChannelActions.radioChannelUpdate(radioChannel))
        },
        onSave: () => {
            dispatch(radioChannelActions.radioChannelSaveRequest())
        }
    }
}

const VenueRadioChannelsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RadioChannels))

export default VenueRadioChannelsContainer