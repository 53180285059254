import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import FullPageDialog from '../../layout/FullPageDialog';
import InmateSummary from '../inmate/InmateSummary';
import Inmate from '../inmate/Inmate';
import InmateCell from '../inmate/InmateCell';
import InmateNotes from '../inmate/InmateNotes';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SaveCancel from '../../common/SaveCancel';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import InmateCellIncidents from '../inmate/InmateCellIncidents';

export interface IEditInmatesCellsProps {
    isSaving: boolean;
    isValid: boolean;
    correctionalFacilityInMateCell: AusComplyDtos.CorrectionalFacilityInMateCell;
    correctionalFacilityInMate: AusComplyDtos.CorrectionalFacilityInMate;
    correctionalFacilityInMateVenueLocation: AusComplyDtos.CorrectionalFacilityInMateVenueLocation;
    cells: AusComplyDtos.VenueLocation[];
    risks: AusComplyDtos.NamedValue[];
    genders: AusComplyDtos.NamedValue[];
    nationalities: AusComplyDtos.Nationality[];
    onSet: Function;
    onSave: Function;
    history: any;
    location: any;
}

export default class EditInmatesCells extends Component<IEditInmatesCellsProps, any> {
    constructor(props: IEditInmatesCellsProps) {
        super(props)

        this.state = {
            inmateEdit: false
        };

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        this.setState({ inmateEdit: false });
    }

    componentDidUpdate(prevProps) {
        if (
            !this.props.correctionalFacilityInMate &&
            this.state.inmateEdit
        ) {
            this.setState({ inmateEdit: false });
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let correctionalFacilityInMateCell = { ...this.props.correctionalFacilityInMateCell };
        correctionalFacilityInMateCell[fieldName] = value;
        this.props.onSet(correctionalFacilityInMateCell);
    }

    render() {
        let self = this;
        let correctionalFacilityInMateCell;
        if (this.props.correctionalFacilityInMateCell && this.props.correctionalFacilityInMateVenueLocation && this.props.correctionalFacilityInMate) {
            correctionalFacilityInMateCell = <LayoutForm saving={this.props.isSaving}>
                <CardContainer style={{ position: 'relative' }} title={"Inmate"}>
                    <CardContent>
                        {!this.state.inmateEdit && (
                            <>
                                <Fab color={'inherit'}
                                    style={{ position: 'absolute', top: '2px', right: '2px' }}
                                    size="small"
                                    onClick={() => self.setState({ inmateEdit: true })} >
                                    <div style={{ width: '24px', height: '24px' }}>
                                        <EditIcon style={{ color: '#000' }} />
                                    </div>
                                </Fab>
                                <InmateSummary
                                    correctionalFacilityInMate={this.props.correctionalFacilityInMate}
                                    onUpdate={(item) => { this.onValueChanged('correctionalFacilityInMate', item); }} />
                            </>
                        )}
                        {this.state.inmateEdit && (
                            <Inmate
                                correctionalFacilityInMate={this.props.correctionalFacilityInMate}
                                genders={this.props.genders}
                                nationalities={this.props.nationalities}
                                onUpdate={(value) => this.onValueChanged("correctionalFacilityInMate", value)}
                            />)}
                    </CardContent>
                </CardContainer>
                <CardContainer style={{ position: 'relative' }} title={"Add Note"}>
                    <CardContent>
                        <TextAreaControlGroup text="Note"
                            defaultValue={this.props.correctionalFacilityInMateCell.notes}
                            readonly={false}
                            defaultRows={2}
                            onBlur={(value) => this.onValueChanged("notes", value)} />
                        <CheckboxControlGroup
                            label={" "}
                            text={"Important"}
                            defaultValue={this.props.correctionalFacilityInMateCell.noteIsImportant}
                            onChanged={(value) => this.onValueChanged("noteIsImportant", value)} />
                    </CardContent>
                </CardContainer>
                <InmateNotes correctionalFacilityInMateNotes={this.props.correctionalFacilityInMateCell.correctionalFacilityInMateNotes} />
                <InmateCell
                    correctionalFacilityInMateVenueLocation={this.props.correctionalFacilityInMateVenueLocation}
                    risks={this.props.risks}
                    cells={this.props.cells}
                    onUpdate={(item) => { this.onValueChanged('correctionalFacilityInMateVenueLocation', item); }} />
                <InmateCellIncidents incidents={this.props.correctionalFacilityInMateCell.inspections} history={this.props.history} />
                <SaveCancel
                    isValid={this.props.isValid}
                    onCancel={() => { self.props.onSet(undefined) }}
                    onSave={() => { self.props.onSave() }}
                    isSaving={false} />
            </LayoutForm>;

        }

        return (<>
            <FullPageDialog
                open={this.props.correctionalFacilityInMateCell != undefined}
                title="Cell"
                onDismissed={() => this.props.onSet(undefined)}>
                {correctionalFacilityInMateCell}
            </FullPageDialog>
        </>);
    }
}