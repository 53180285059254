import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PageLayout from '../layout/PageLayout';
import NotesContainer from '../../containers/NotesContainer';

export interface INotebookProps {
    userId: number;
    firstName: string;
}

interface INotebookState {
}

export default class Notebook extends Component<INotebookProps, INotebookState> {
    constructor(props: INotebookProps) {
        super(props)

        this.state = {
        };
    }


    render() {
        let self = this;
        let title = this.props.firstName && this.props.firstName.length > 0 ? this.props.firstName + "'s Notebook": "Notebook";
        return <PageLayout headerText={title} >
            <NotesContainer entityType={AusComplyDtos.ngtEntityType.Notebook} entityId={this.props.userId} />
        </PageLayout>
    }
}