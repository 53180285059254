import React from "react";

const DocumentIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 37.7 47.5"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <polygon fill={'none'} stroke={fill} strokeWidth={2} points="27,1 1,1 1,46.5 36.7,46.5 36.7,10.7 	"/>
            </g>
            <polygon fill={fill} points="26.4,11.5 37.5,11.5 26.4,0.4 "/>
            <line fill={'none'} stroke={fill} strokeWidth={1.4} x1="7.5" y1="18.1" x2="30.2" y2="18.1"/>
            <line fill={'none'} stroke={fill} strokeWidth={1.4} x1="7.5" y1="22.9" x2="30.2" y2="22.9"/>
            <line fill={'none'} stroke={fill} strokeWidth={1.4} x1="7.5" y1="27.8" x2="30.2" y2="27.8"/>
            <line fill={'none'} stroke={fill} strokeWidth={1.4} x1="7.5" y1="32.7" x2="30.2" y2="32.7"/>
            <line fill={'none'} stroke={fill} strokeWidth={1.4} x1="7.5" y1="37.6" x2="20.5" y2="37.6"/>
        </svg>
    );

export default DocumentIcon;
