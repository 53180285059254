import { makeActionCreator } from './helpers';

export const
    INCIDENT_DOCUMENT_BUILD_REQUEST = "INCIDENT_DOCUMENT_BUILD_REQUEST",
    INCIDENT_DOCUMENT_BUILD_REQUEST_SUCCESS = "INCIDENT_DOCUMENT_BUILD_REQUEST_SUCCESS",
    INCIDENT_DOCUMENT_BUILD_REQUEST_FAILURE = "INCIDENT_DOCUMENT_BUILD_REQUEST_FAILURE"
    ;

export const incidentDocumentBuildRequest = makeActionCreator(INCIDENT_DOCUMENT_BUILD_REQUEST, 'incidentId', 'incidentCategoryTypeDocumentId');
export const incidentDocumentBuildRequestSuccess = makeActionCreator(INCIDENT_DOCUMENT_BUILD_REQUEST_SUCCESS, 'incidentDocument');
export const incidentDocumentBuildRequestFailure = makeActionCreator(INCIDENT_DOCUMENT_BUILD_REQUEST_FAILURE, 'error');