import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as incidentCategoryTypeActions from "../actions/incidentCategoryType";

const initialState = {
    error: null,
    usage: {
        commsLogs: {
            isLoading: false,
            incidentCategoryTypes: [],
        }
    }
};


function loadingIncidentCategoryTypes(state, isLoading, entryPage, incidentTypeId) {
    if (entryPage === "CommsLog") {
        return update(state, {
            usage: {
                commsLogs: {
                    isLoading: { $set: isLoading }
                }
            }
        });
    }
    // default - reset all
    let newState = update(state, {
        usage: {
            commsLogs: {
                isLoading: { $set: isLoading }
            }
        }
    });
    return newState;
}

function setIncidentCategoryTypes(state, incidentCategoryTypes, entryPage, incidentTypeId) {
    if (entryPage === "CommsLog") {
        return update(state, {
            usage: {
                commsLogs: {
                    incidentCategoryTypes: { $set: incidentCategoryTypes }
                }
            }
        });
    }
    // default - reset all
    let newState = update(state, {
        usage: {
            commsLogs: {
                incidentCategoryTypes: { $set: incidentCategoryTypes }
            }
        }
    });
    return newState;
}

export default function incidentCategoryTypes(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case incidentCategoryTypeActions.INCIDENTCATEGORYTYPES_USAGE_REQUEST:
            return loadingIncidentCategoryTypes(setIncidentCategoryTypes(state, [], action.entryPage, action.incidentTypeId), true, action.entryPage, action.incidentTypeId);
        case incidentCategoryTypeActions.INCIDENTCATEGORYTYPES_USAGE_REQUEST_SUCCESS:
            return loadingIncidentCategoryTypes(setIncidentCategoryTypes(state, action.data, action.entryPage, action.incidentTypeId), false, action.entryPage, action.incidentTypeId);
        case incidentCategoryTypeActions.INCIDENTCATEGORYTYPES_USAGE_REQUEST_FAILURE:
            return loadingIncidentCategoryTypes(setIncidentCategoryTypes(state, [], "", 0), false, "", 0);
    }

    return state;
}