import { connect } from 'react-redux'
import NotificationTriggerUsersSummary from '../components/notifications/NotificationTriggerUsersSummary'
import * as notificationTriggerActions from '../common/actions/notificationTrigger'

const mapStateToProps = (state, props) => {
    return {
        notificationTriggerId: props.notificationTriggerId,
        onClose: props.onClose,
        isLoading: state.notificationTrigger.notificationTriggerUsersSummary.isLoading,
        notificationTriggerUsersSummary: state.notificationTrigger.notificationTriggerUsersSummary.notificationTriggerUsersSummary,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (notificationTriggerId) => {
            dispatch(notificationTriggerActions.notificationTriggerUsersSummaryRequest(notificationTriggerId))
        }
    }
}

const NotificationTriggerUsersSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationTriggerUsersSummary)

export default NotificationTriggerUsersSummaryContainer