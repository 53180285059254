import React, { Component } from 'react';
import { styles } from '../../../styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import CheckListTemplateSummaryCard from '../CheckListTemplateSummaryCard';
import CheckListTemplateSummaryRow from '../CheckListTemplateSummaryRow';
import CheckListTemplateSummaryHeader from '../CheckListTemplateSummaryHeader';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import Box from '@material-ui/core/Box';
import SearchControl from '../../controls/SearchControl';
import FullPageDialog from '../../layout/FullPageDialog';
import ErrorBoundary from '../../common/ErrorBoundary';
import PrimaryButton from '../../controls/PrimaryButton';
import TransparentButton from '../../controls/TransparentButton';

export interface INewCheckListSelectTemplateProps {
    isLoading: boolean;
    checklistTemplates: AusComplyDtos.ChecklistTemplateSummary[];
    paging: AusComplyDtos.Paging;
    checklistCategories: AusComplyDtos.ChecklistTemplateCategory[];
    checklistCategoriesLoading: boolean;
    pagesize: number;
    page: number;
    search: any;
    onSetChecklistTemplatesPage: Function;
    onLoadCheckListTemplates: Function;
    onLoadCheckListCategories: Function;
    onNewCheckList: Function;
    open: boolean;
    onClose: Function;
    history: any;
    classes: any;
    theme: any;
}

interface INewCheckListSelectTemplateState {
    currentSearch: string;
}

class NewCheckListSelectTemplate extends Component<INewCheckListSelectTemplateProps, INewCheckListSelectTemplateState> {

    constructor(props: INewCheckListSelectTemplateProps) {
        super(props)

        this.state = {
            currentSearch: ""
        }
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onNewChecklist = this.onNewChecklist.bind(this);
    }

    componentDidMount() {
        this.setState({currentSearch: this.props.search});
        this.props.onSetChecklistTemplatesPage(1);
        this.props.onLoadCheckListTemplates(this.props.search);
    }

    componentDidUpdate(preProps) {
    }

    handlePageSelected(page) {
        this.props.onSetChecklistTemplatesPage(page);
        this.props.onLoadCheckListTemplates(this.props.search);
        //let filter = { ...this.state.filter };
        //filter.paging.page = page;
        //this.props.onRefresh(filter);
    }

    onNewChecklist(item: AusComplyDtos.ChecklistTemplateSummary) {
        this.props.onNewCheckList(item.checklistTemplateId);
        this.props.history.push('/checklist');
    }

    render() {
        const { classes } = this.props;
        let self = this;

        let paging: AusComplyDtos.Paging = this.props.paging;
        if (!paging) {
            paging = new AusComplyDtos.Paging();
            paging.page = this.props.page;
            paging.pageSize = this.props.pagesize;
            paging.pages = 1;
            paging.total = 1;
        }

        let cardList;
        let rowList;
        let rows;
        if (this.props.checklistTemplates && this.props.checklistTemplates.length > 0) {
            cardList = this.props.checklistTemplates.map((item, index) => {
                return (
                    <CheckListTemplateSummaryCard
                        key={"card-" + item.checklistTemplateId}
                        onClick={() => this.onNewChecklist(item)}
                        checklistTemplateSummary={item} />
                );
            });

            rows = this.props.checklistTemplates.map((item, index) => {
                return (
                    <CheckListTemplateSummaryRow
                        onClick={() => this.onNewChecklist(item)}
                        key={"row-" + item.checklistTemplateId.toString()}
                        checklistTemplateSummary={item} />
                );
            });
        }
        rowList = (
            <TableContainer
                header={<CheckListTemplateSummaryHeader />}
                rows={rows}
            />
        );

        return <FullPageDialog title={"New checklist"}
            open={this.props.open} 
            footerLeftContent={<PrimaryButton text='Search' onClick={() => this.props.onLoadCheckListTemplates(self.state.currentSearch)} />}
            footerRightContent={<TransparentButton text='Cancel' onClick={() => this.props.onClose()} />}
            onDismissed={() => this.props.onClose()} >
            {this.props.open && (
                <ErrorBoundary>
                    <SearchControl
                        value={this.props.search}
                        onChange={value => self.setState({currentSearch: value})}
                        onSearch={value => this.props.onLoadCheckListTemplates(value)}
                    />
                    <PagedList
                        loading={this.props.isLoading}
                        paging={paging}
                        cards={cardList}
                        rows={rowList}
                        onPageSelected={i => this.handlePageSelected(i)}
                        footer={
                            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                                <Box p={1} >
                                    <div className={classes.colorSwatchChecklistClosed}></div>
                                    <div className={classes.colorSwatchText}>Local</div>
                                </Box>
                                <Box p={1} >
                                    <div className={classes.colorSwatchIncidentCurrent}></div>
                                    <div className={classes.colorSwatchText}>Group</div>
                                </Box>
                                <Box p={1} >
                                    <div className={classes.colorSwatchChecklistApproved}></div>
                                    <div className={classes.colorSwatchText}>Global</div>
                                </Box>
                            </Box>
                        }
                    />
                </ErrorBoundary>
            )}
        </FullPageDialog>


    }
}


export default withStyles(styles, { withTheme: true })(withRouter(NewCheckListSelectTemplate));