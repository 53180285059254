import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import SwipeContainer from '../layout/SwipeContainer';
import CrossIcon from '../../resources/CrossIcon';
import LogoLight from '../common/LogoLight';
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import PageTitle from '../common/PageTitle';
import TransparentButton from '../controls/TransparentButton';
import Media from "react-media";
import Grid from '@material-ui/core/Grid';
import FormErrorMessage from '../alerts/FormErrorMessage';
import Box from '@material-ui/core/Box';

export interface IFullPageDialogProps {
    theme: any;
    classes: any;
    open: boolean;
    title: string;
    closeText?: any;
    onOpened?: Function;
    onDismissed?: Function;
    onAdd?: Function;
    style?: any;
    hideCloseButton?: boolean;
    showAddButton?: boolean;
    addText?: string;
    containerStyle?: any;
    footerLeftContent?: any;
    footerCenterContent?: any;
    footerRightContent?: any;
    notFullWidth?: boolean;
    error?: string;
    loading?: boolean;
    saving?: boolean;
    savingMessage?: string;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore 
    return <Slide direction="up" ref={ref} {...props} />;
  });

class FullPageDialog extends React.PureComponent<IFullPageDialogProps, any> {

    constructor(props: IFullPageDialogProps) {
        super(props);
        this.onDismissed = this.onDismissed.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    onDismissed() {
        if (this.props.onDismissed) {
            this.props.onDismissed();
        }
    }

    onAdd() {
        if (this.props.onAdd) {
            this.props.onAdd();
        }
    }

    render() {
        const { theme, classes } = this.props;
        const closeText = this.props.closeText;
        let closeIcon;
        if (!closeText) {
            closeIcon = (<CrossIcon style={{ fontSize: '1.5em' }} />)
        }

        let footerRightContent = this.props.footerRightContent;
        if (this.props.footerRightContent === undefined && this.props.showAddButton) {
            footerRightContent = <TransparentButton text={this.props.addText || "Add"} onClick={this.onAdd} />
        }

        let footerLeftContent = this.props.footerLeftContent;
        if (this.props.footerLeftContent === undefined) {
            footerLeftContent = <TransparentButton text={"Close"} onClick={this.onDismissed} />
        }

        let pageContent;
        if (this.props.saving) {
            pageContent = <>
                <PageTitle title={this.props.title}></PageTitle>
                <Saving message={this.props.savingMessage} />
            </>;
        } else if (this.props.loading) {
            pageContent = <>
                <PageTitle title={this.props.title}></PageTitle>
                <Loading />
            </>;
        } else {
            pageContent = (<Box display="flex" flexDirection="column" style={{ height: '100%', position: 'relative' }}>
                <Box p={0} flex={0}>
                    <PageTitle title={this.props.title}></PageTitle>
                </Box>
                <Box p={0} flex={0}>
                    <FormErrorMessage error={this.props.error} />
                </Box>
                <Box p={0} flex={1}>
                    {this.props.children}
                </Box>
            </Box>);
        } 
        
        let pageQueryContent;
        if (this.props.notFullWidth) {
            pageQueryContent = <Media query="(max-width: 799px)">
                {matches =>
                    matches ? <>
                        {pageContent}
                    </> : (
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                                {pageContent}
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={3} xl={3}></Grid>
                        </Grid>
                    )
                }
            </Media>
        } else {
            pageQueryContent = <>{pageContent}</>
        }

        let content = <div id="contents" style={{
            margin: '66px 10px 80px 10px',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflowY: 'auto'
        }}>{pageQueryContent}</div>;

        
        return (
            <div style={this.props.containerStyle}>
                <Dialog fullScreen
                    open={this.props.open}
                    onClose={this.onDismissed}
                    transitionDuration={700}
                    // @ts-ignore 
                    TransitionComponent={Transition}>
                    <SwipeContainer onSwipedDown={this.onDismissed} delta={350}>
                        <div style={{ position: 'relative' }}>
                            <AppBar style={{ position: 'fixed', top: 0, left: 0, right: 0 }} className={classes.fullScreenDialogLogo}>
                                <Toolbar>
                                    {!this.props.hideCloseButton && (
                                        <Button color="inherit" onClick={this.onDismissed} style={{ width: '51px', minWidth: '51px' }}>
                                            {closeText}{closeIcon}
                                        </Button>
                                    )}
                                    <div style={{ flex: 2, textAlign: 'center' }} >
                                        <Button color="inherit"
                                            aria-label="Logo"
                                            onClick={this.onDismissed}
                                            style={{ marginRight: '0', flexGrow: 1, textAlign: "center" }}
                                        >
                                            <LogoLight style={{ height: '30px' }} />
                                        </Button>
                                    </div>

                                    {(!this.props.hideCloseButton && !this.props.showAddButton) && (
                                        <Typography variant="h6" style={{ width: '51px', minWidth: '51px' }}>

                                        </Typography>
                                    )}
                                </Toolbar>
                            </AppBar>
                            <div>
                                {content}
                            </div>
                            <div className={classes.footer} style={{

                                display: 'flex',
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly'
                                }}>

                                    {footerLeftContent && (
                                        <div style={{ flexGrow: 0, minWidth: '100px' }}>
                                            {footerLeftContent}
                                        </div>
                                    )}

                                    {this.props.footerCenterContent && (
                                        <div style={{ flexGrow: 0, minWidth: '100px' }}>
                                            {this.props.footerCenterContent}
                                        </div>
                                    )}
                                    {footerRightContent && (
                                        <div style={{ flexGrow: 0, minWidth: '100px' }}>
                                            {footerRightContent}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwipeContainer>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FullPageDialog);