import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewNumberProps {
    incident: IncidentDTO;
}

export default class IncidentViewNumber extends Component<IIncidentViewNumberProps, any> {
    render() {
        return (
            <>
                <ControlGroup text="ID">
                    <TextDisplay>{this.props.incident.incidentSerialNumber}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Report Status">
                    <TextDisplay>{this.props.incident.incidentStatusDisplay}</TextDisplay>
                </ControlGroup>
                {this.props.incident.venueEvent && (
                <ControlGroup text="Event">
                    <TextDisplay>{this.props.incident.venueEvent}</TextDisplay>
                </ControlGroup>)}
            </>
        );
    }
}