import React from 'react';
import {
    useDrag, DragSourceMonitor, DragSourceConnector, ConnectDragSource, DragSource,
    ConnectDropTarget, DropTargetMonitor,
    DropTargetConnector,
    DropTarget
} from 'react-dnd';
import { ItemTypes } from '../../../checklists/checklistTemplate/ItemTypes';

export interface IFacialRecognitionEventsListDropProps {
    position: number,
    canDrop?: boolean,
    isOver?: boolean,
    connectDropTarget: ConnectDropTarget;
}

class FacialRecognitionEventsListDrop extends React.Component<IFacialRecognitionEventsListDropProps, any> {
    constructor(props: IFacialRecognitionEventsListDropProps) {
        super(props);
    }

    componentDidMount() {}

    componentDidUpdate() {}

    public render() {
        let content;
        if (false && !this.props.canDrop && !this.props.isOver) {
            content = (<div></div>);
        }
        else {
            const isActive = this.props.canDrop && this.props.isOver;
            let style: any = {
                color: '#333',
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: '#333',
                borderStyle: 'dotted',
                padding: '4px',
                textAlign: 'center',
                marginLeft: '10px',
                marginRight: '10px'
            };
            if (this.props.canDrop) {
                style = { ...style, borderColor: '#666', color: '#666' };
            }
            if (isActive) {
                style = { ...style, backgroundColor: 'green', color: '#fff' };
            }
            content = (
                <div style={{ ...style }}>
                    {isActive ? 'Release to position here' : 'Drag to position here'}
                </div>
            );
        }
        
        return (
            <div key={"CardDropTarget" + "-" + this.props.position} ref={this.props.connectDropTarget} >
                {content}
            </div>
        );
    }
}

export default DropTarget(
    [ItemTypes.BOX, ItemTypes.ITEM],
    {
        drop: (props: any) => ({
            name: 'Position',
            position: props.position,
            parentId: props.parentId,
            allowedDropEffect: 'any'
        }),
    },
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    })
)(FacialRecognitionEventsListDrop)
