import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { IncidentPatron as IncidentPatronDTO, IncidentOptions as IncidentOptionsDTO } from "../../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../../controls/TextControlGroup';
import RadioChipControlGroup from '../../../controls/RadioChipControlGroup';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IIncidentPatronAppearanceAltProps {
    incidentPatron: IncidentPatronDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdated: Function;
    theme: any;
    classes: any;
}

class IncidentPatronAppearanceAlt extends Component<IIncidentPatronAppearanceAltProps, any> {
    constructor(props: IIncidentPatronAppearanceAltProps) {
        super(props)
        this.state = {
            error: ""
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(fieldName: string, value: any) {
        let patron = { ...this.props.incidentPatron };
        patron[fieldName] = value;
        this.props.onUpdated(patron);
    }

    render() {
        const { classes } = this.props;
        let patron = this.props.incidentPatron;
        return (
            <>
                <CardContainer
                    title={"Physical Description"}>
                    <CardContent>
                        <RadioChipControlGroup text="Build"
                            name="build"
                            selected={patron.incidentPatronBuildId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronBuildId", value)}
                            list={this.props.incidentOptions.incidentPatronBuilds}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Hair Colour"
                            name="haircolour"
                            selected={patron.incidentPatronHairColourId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronHairColourId", value)}
                            list={this.props.incidentOptions.incidentPatronHairColours}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Complexion"
                            name="complexion"
                            selected={patron.incidentPatronComplexionId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronComplexionId", value)}
                            list={this.props.incidentOptions.incidentPatronComplexions}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Facial Hair"
                            name="facialhair"
                            selected={patron.incidentPatronFacialHairId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronFacialHairId", value)}
                            list={this.props.incidentOptions.incidentPatronFacialHairs}
                            labelAbove={true}>
                            <option value={0} />
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Eye Colour"
                            name="eyecolour"
                            selected={patron.incidentPatronEyeColourId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronEyeColourId", value)}
                            list={this.props.incidentOptions.incidentPatronEyeColours}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Height Range"
                            name="height"
                            selected={patron.incidentPatronHeightId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronHeightId", value)}
                            list={this.props.incidentOptions.incidentPatronHeights}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Weight Range"
                            name="weight"
                            selected={patron.incidentPatronWeightId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronWeightId", value)}
                            list={this.props.incidentOptions.incidentPatronWeights}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                    </CardContent>
                </CardContainer>
                <CardContainer
                    title={"Clothing"}>
                    <CardContent>
                        <RadioChipControlGroup text="Top"
                            name="clothingtop"
                            selected={patron.incidentPatronClothingTopId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronClothingTopId", value)}
                            list={this.props.incidentOptions.incidentPatronClothingTops}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Top Colour"
                            name="clothingcolourstop"
                            selected={patron.incidentPatronClothingTopColourId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronClothingTopColourId", value)}
                            list={this.props.incidentOptions.incidentPatronClothingTopColours}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Bottom"
                            name="clothingbottom"
                            selected={patron.incidentPatronClothingBottomId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronClothingBottomId", value)}
                            list={this.props.incidentOptions.incidentPatronClothingBottoms}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <RadioChipControlGroup text="Bottom Colour"
                            name="clothingbottomcolour"
                            selected={patron.incidentPatronClothingBottomColourId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronClothingBottomColourId", value)}
                            list={this.props.incidentOptions.incidentPatronClothingBottomColours}
                            labelAbove={true}>
                        </RadioChipControlGroup>
                        <TextControlGroup text=""
                            defaultValue={patron.clothing}
                            placeholder={"Description..."}
                            onChange={(value) => this.onSelectionChanged("clothing", value)}
                            labelAbove={true} />
                    </CardContent>
                </CardContainer>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPatronAppearanceAlt);