import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import IndustryCategoriesFilterSummary from './IndustryCategoriesFilterSummary';
import IndustryCategoriesFilter from './IndustryCategoriesFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import IndustryCategoryCard from './IndustryCategoryCard';
import IndustryCategoryAdd from './IndustryCategoryAdd';
import IndustryCategoryEdit from './IndustryCategoryEdit';
import IndustryCategoryHeader from './IndustryCategoryHeader';
import IndustryCategoryRow from './IndustryCategoryRow';

export interface IIndustryCategoriesProps {
    isLoading: boolean;
    isSaving: boolean;
    industryCategories: AusComplyDtos.IndustryCategory[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IndustryCategoriesFilter;
    error: string;
    onRefresh: Function;
    onCreate: Function;
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IIndustryCategoriesState {
    filterExpanded: boolean;
    createExpanded: boolean;
    editIndustryCategory?: AusComplyDtos.IndustryCategory;
}

class IndustryCategories extends Component<IIndustryCategoriesProps, IIndustryCategoriesState> {
    constructor(props: IIndustryCategoriesProps) {
        super(props)

        this.state = {
            filterExpanded: false,
            createExpanded: false,
            editIndustryCategory: undefined
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page, this.props.paging.pageSize);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onSearch(filter: AusComplyDtos.IndustryCategoriesFilter) {
        this.props.onRefresh(filter, this.props.paging.page, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onRefresh(new AusComplyDtos.IndustryCategoriesFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    createExpanded(value: boolean) {
        if (this.state.createExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            createExpanded: value
        })
    }

    onAdd(industryCategory: AusComplyDtos.IndustryCategory) {
        this.props.onCreate(industryCategory)
    }

    onEdit(industryCategory?: AusComplyDtos.IndustryCategory) {
        this.setState({ editIndustryCategory: industryCategory })
    }

    onUpdate(industryCategory: AusComplyDtos.IndustryCategory) {
        this.props.onUpdate(industryCategory);
        this.onEdit(undefined);
    }

    onUpdateFilter(filter: AusComplyDtos.IndustryCategoriesFilter) {

    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.industryCategories && this.props.industryCategories.length > 0) {
            cardList = this.props.industryCategories.map((item, index) => {
                return (
                    <IndustryCategoryCard
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"card-" + item.industryCategoryId}
                        industryCategory={item}
                    />
                );
            });

            rows = this.props.industryCategories.map((item, index) => {
                return (
                    <IndustryCategoryRow
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"row-" + item.industryCategoryId.toString()}
                        industryCategory={item} />
                );
            });
        }

        rowList = (
            <TableContainer
                header={<IndustryCategoryHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <IndustryCategoriesFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let industryCategoriesFilter = new AusComplyDtos.IndustryCategoriesFilter();
            industryCategoriesFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IndustryCategoriesFilterSummary filter={industryCategoriesFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IndustryCategoriesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (
            <>
                <PageTitle title="Industries"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                    <DefaultFab onClick={() => this.createExpanded(true)} isExtended={true}>Add Industry</DefaultFab>
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                {this.state.createExpanded && (<IndustryCategoryAdd
                    open={this.state.createExpanded}
                    defaultRank={9999}
                    onSave={this.onAdd}
                    onCancel={() => this.createExpanded(false)}
                    isSaving={this.props.isSaving}
                    error={this.props.error} />)}
                {this.state.editIndustryCategory && (<IndustryCategoryEdit
                    open={this.state.editIndustryCategory != undefined}
                    industryCategory={this.state.editIndustryCategory}
                    onSave={this.onUpdate}
                    onCancel={() => this.onEdit(undefined)}
                    isSaving={this.props.isSaving}
                    error={this.props.error} />
                )}

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IndustryCategories);