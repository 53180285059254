import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { systemLogApi } from '../services/systemLog';

import {
    SYSTEM_LOG_REQUEST,
    systemLogRequestSuccess,
    systemLogRequestFailure
} from "../actions/systemLog";

import { 
    notifyError, notifyErrorMessage
  } from './common'; 

const systemLogRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SYSTEM_LOG_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        systemLogApi.get(action['venueId'], action['securityFirmId'])
                .pipe(
                    map(response => systemLogRequestSuccess(response.notifications)),
                    catchError(error => notifyErrorMessage(error, "Failed to load system log", systemLogRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load system log", systemLogRequestFailure))
    );

export const systemLogEpics = combineEpics(
    systemLogRequestEpic
);