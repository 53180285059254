import { connect } from 'react-redux'
import GroupUsers from '../components/groups/group/GroupUsers'

const mapStateToProps = (state, props) => {
    return {
        groupId: props.groupId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const GroupUsersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupUsers)

export default GroupUsersContainer