import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import FilterDisplay from '../common/FilterDisplay';

export interface IRosterFilterSummaryProps {
    filter: AusComplyDTOs.RosterFilter;
    onClick: Function;
}

export default class RosterFilterSummary extends React.Component<IRosterFilterSummaryProps, any> {
    render() {
        if (!this.props.filter) {
            return (<></>);
        }
        return (
            <FilterDisplay text={this.props.filter.display} onClick={this.props.onClick} />
        );
    }
}