import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SecondaryFab from '../../common/SecondaryFab';
import List from '../../common/List';
import { KeyName } from '../../../common/dto/common';
import TableContainer from '../../layout/TableContainer';
import CorrectionalFacilityInMateCellCard from './CorrectionalFacilityInMateCellCard';
import CorrectionalFacilityInMateCellHeader from './CorrectionalFacilityInMateCellHeader';
import CorrectionalFacilityInMateCellRow from './CorrectionalFacilityInMateCellRow';
import RefreshIcon from '@material-ui/icons/Refresh';
import CorrectionalFacilityEditInMateCellContainer from '../../../containers/CorrectionalFacilityEditInMateCellContainer';

export interface ICorrectionalFacilityInmatesCellsProps {
    isLoading: boolean;
    isSaving: boolean;
    correctionalFacilityInMatesCells: AusComplyDtos.CorrectionalFacilityInMateCell[];
    draftIncidentId: number;
    canAdd: boolean;
    canEdit: boolean;
    onLoad: Function;
    onRemove: Function;
    onEdit: Function;
    onInspect: Function;
    history: any;
    location: any;
}

export default class CorrectionalFacilityInmatesCells extends Component<ICorrectionalFacilityInmatesCellsProps, any> {
    constructor(props: ICorrectionalFacilityInmatesCellsProps) {
        super(props)

        this.state = {
        };

        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.draftIncidentId == 0 && this.props.draftIncidentId > 0) {
            this.props.history.push('/incident/' + this.props.draftIncidentId.toString());
        }
    }

    onCommand(command: string, correctionalFacilityInMateCell: AusComplyDtos.CorrectionalFacilityInMateCell) {
        switch (command) {
            case "add":
                this.props.history.push('/inmatescells/add');
                break;
            case "edit":
                this.props.onEdit(correctionalFacilityInMateCell);
                break;
            case "remove":
                this.props.onRemove(correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.correctionalFacilityInMateVenueLocationId);
                break;
            case "inspection":
                if (correctionalFacilityInMateCell.nextInspectionIncident) {
                    this.props.history.push('/incident/' + correctionalFacilityInMateCell.nextInspectionIncident.incidentId.toString());
                } else {
                    this.props.onInspect(correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.correctionalFacilityInMateVenueLocationId);
                }
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;
        let count = 0;

        var commands: KeyName[] = [];
        commands.push({ key: "inspection", name: "Inspect Now" });
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "remove", name: "Remove" });


        if (this.props.correctionalFacilityInMatesCells && this.props.correctionalFacilityInMatesCells.length > 0) {
            count = this.props.correctionalFacilityInMatesCells.length;
            cardList = this.props.correctionalFacilityInMatesCells.map((item, index) => {
                return (
                    <CorrectionalFacilityInMateCellCard
                        onCommand={this.onCommand}
                        key={"card-" + item.correctionalFacilityInMateVenueLocation.correctionalFacilityInMateVenueLocationId.toString()}
                        data={item}
                        commands={commands}
                    />
                );
            });
            rows = this.props.correctionalFacilityInMatesCells.map((item, index) => {
                return (
                    <CorrectionalFacilityInMateCellRow
                        onCommand={this.onCommand}
                        key={"row-" + item.correctionalFacilityInMateVenueLocation.correctionalFacilityInMateVenueLocationId.toString()}
                        data={item}
                        commands={commands}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<CorrectionalFacilityInMateCellHeader />}
                    rows={rows}
                />
            );
        }

        let createFab;
        if (this.props.canAdd) {
            createFab = (
                <DefaultFab onClick={() => { this.onCommand("add", new AusComplyDtos.CorrectionalFacilityInMateCell()) }} isExtended={true}>Add New Inmate</DefaultFab>
            );
        }
        let refreshFab;
        if (!this.props.isLoading) {
            refreshFab = (
                <SecondaryFab onClick={() => { this.props.onLoad(); }} ><RefreshIcon style={{ marginTop: '5px' }} /></SecondaryFab>
            )
        }

        return (<>
            <PageTitle title="Inmates / Cells"></PageTitle>
            <List
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                count={count}
                cards={cardList}
                rows={rowList}
            />
            <VenueQuickActionsContainer>
                {createFab}{refreshFab}
            </VenueQuickActionsContainer>
            <CorrectionalFacilityEditInMateCellContainer />
        </>);
    }
}