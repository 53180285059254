import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewRadioChannelFunctionalAreaProps {
    incident: IncidentDTO;
}

export default class IncidentViewRadioChannelFunctionalArea extends Component<IIncidentViewRadioChannelFunctionalAreaProps, any> {
    render() {
        return (
            <>
                {this.props.incident.selectedRadioChannelsDisplay &&
                    <ControlGroup text="Radio Channels">
                        <TextDisplay>{this.props.incident.selectedRadioChannelsDisplay}</TextDisplay>
                    </ControlGroup>
                }
                {this.props.incident.selectedFunctionalAreasDisplay &&
                    <ControlGroup text="Functional Areas">
                        <TextDisplay>{this.props.incident.selectedFunctionalAreasDisplay}</TextDisplay>
                    </ControlGroup>
                }
            </>
        );
    }
}