import React, { Component } from 'react';
import ErrorButton from '../controls/ErrorButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


export interface IErrorProps {
    message: string;
    onClear?: Function;
    style?: any;
}

export default class Error extends Component<IErrorProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{ display: 'block', padding: '0px', margin: '0px', textAlign: "center", position: 'relative' }}>
                <p style={{ background: 'red', borderRadius: '8px', color: 'white', padding: '15px', ...this.props.style }}>{this.props.message}</p>
                {this.props.onClear && (
                    <ErrorButton onClick={this.props.onClear} style={{
                        position: 'absolute',
                        top: '2px',
                        display: 'inline-block',
                        width: 'auto',
                        bottom: '2px',
                        right: '2px',
                        height: 'auto'
                    }}><HighlightOffIcon /></ErrorButton>
                )}
            </div>
        );
    }
}