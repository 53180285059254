import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../../controls/PrimaryButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import SwipeContainer from '../../layout/SwipeContainer';

export interface ICommLogPostSaveProps {
    closeAfterSave: boolean;
    onChange: Function;
}

export default class CommLogPostSave extends Component<ICommLogPostSaveProps, any> {
    constructor(props: ICommLogPostSaveProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    onChange(closeAfterSave) {
        this.props.onChange(closeAfterSave);
    }

    render() {
        return (
            <CardContainer
                title={"After save"}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {this.props.closeAfterSave && (
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <PrimaryButton text={"Close"} onClick={() => this.onChange(true)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TransparentButton text={"New Comms Log"} onClick={() => this.onChange(false)} />
                                    </Grid>
                                </Grid>
                            )}
                            {!this.props.closeAfterSave && (
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TransparentButton text={"Close"} onClick={() => this.onChange(true)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PrimaryButton text={"New Comms Log"} onClick={() => this.onChange(false)} />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </CardContainer>
        );
    }
}