import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  correctionalFacilityRiskStatusesRequest,
  correctionalFacilityRiskStatusSaveRequest,
  correctionalFacilityRiskStatusReset,
  correctionalFacilityRiskStatusSet
} from '../common/actions/correctionalFacility';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import CorrectionalFacilityRiskStatus from '../components/facility/correctionalFacilityRiskStatuses/CorrectionalFacilityRiskStatus';

const mapStateToProps = state => {
  return {
    venueId: state.user.details.userSession.user.venueId,
    paging: state.correctionalFacility.riskStatuses.paging,
    filter: state.venue.venueLocations.filter,
    isLoading: state.correctionalFacility.riskStatuses.isLoading,
    riskStatuses: state.correctionalFacility.riskStatuses.riskStatuses,
    riskStatus: state.correctionalFacility.riskStatus.riskStatus,
    canCreate: true,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (pageSize, page) => {
      dispatch(correctionalFacilityRiskStatusesRequest(pageSize, page));
    },
    onLoadItem: (riskStatus) => {
      dispatch(correctionalFacilityRiskStatusSet(riskStatus))
    },
    onResetItem: () => {
      dispatch(correctionalFacilityRiskStatusReset())
    },
    onSave: () => {
      dispatch(correctionalFacilityRiskStatusSaveRequest());
    },
    onSet: (correctionalFacilityRiskStatus) => {
      dispatch(correctionalFacilityRiskStatusSet(correctionalFacilityRiskStatus));
    }
  }
}

const CorrectionalFacilityRiskStatusContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrectionalFacilityRiskStatus))
export default CorrectionalFacilityRiskStatusContainer