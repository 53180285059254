import React from 'react';
import TransparentButton from './TransparentButton';
import FullPageDialog from '../layout/FullPageDialog';
import TextDisplay from './TextDisplay';
import View from '../common/View';

export interface ITextDisplayExpandedProps {
    title: string;
    text: string;
}

export default class TextDisplayExpanded extends React.PureComponent<ITextDisplayExpandedProps, any> {

    constructor(props: ITextDisplayExpandedProps) {
        super(props);
        this.state = {
            open: false
        };
    }

    render() {
        const { title, text } = this.props;
        let self = this;

        if (text == undefined || text == null || text == "") {
            return <></>;
        }

        return (<View style={{marginBottom: '10px'}}>
            <TransparentButton text={title} onClick={() => self.setState({ open: !this.state.open })} />
            <FullPageDialog
                title={title}
                open={this.state.open} onDismissed={() => self.setState({ open: false })}
            >
                <TextDisplay>{text}</TextDisplay>
            </FullPageDialog>
        </View>);
    }
}
