import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ISecurityFirmAssociationHeaderProps {
    classes: any;
}

class SecurityFirmAssociationHeader extends React.Component<ISecurityFirmAssociationHeaderProps, any> {
    render() {
        const { classes } = this.props;
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Venue</th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Master Licence</th>
                <th className={classes.tableHr}>Suburb</th>
                <th className={classes.tableHr}>State</th>
                <th className={classes.tableHr}>Postcode</th>
                <th className={classes.tableHr}>Phone</th>
                <th className={classes.tableHr}>Manager(s)</th>
                <th className={classes.tableHr}>Website</th>
                <th className={classes.tableHr}>Created</th>
                <th className={classes.tableHr}>Modified</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(SecurityFirmAssociationHeader);