import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { venueReportingPeriodLogic } from '../../../common/logic/venueReportingPeriodLogic';
import VenueReportingSecurityCode from './VenueReportingSecurityCode';

export interface IVenueEventReportingPeriodCardProps {
    venueReportingPeriod: AusComplyDtos.VenueReportingPeriod;
    onCommand?: Function;
}

export default class VenueEventReportingPeriodCard extends React.Component<IVenueEventReportingPeriodCardProps, any> {
    constructor(props: IVenueEventReportingPeriodCardProps) {
        super(props)
        
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueReportingPeriod);
        }
    }

    render() {
        if (!this.props.venueReportingPeriod) {
            return (<></>);
        }

        var commands = venueReportingPeriodLogic.getCommands(this.props.venueReportingPeriod);
        var item = this.props.venueReportingPeriod;

        return (
            <CardRow isInfo={this.props.venueReportingPeriod.isCurrent} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <CardTitle>Event</CardTitle>
                        <CardTypography>{item.venueEventName}</CardTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardTitle>Start</CardTitle>
                        <CardTypography>{item.startTime.displayLocal} {item.startTimeTimezone}</CardTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardTitle>End</CardTitle>
                        <CardTypography>{item.endTime.displayLocal} {item.endTimeTimezone}</CardTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <CardTitle style={{ textAlign: 'center' }}>Security Code</CardTitle>
                        <VenueReportingSecurityCode venueReportingPeriod={this.props.venueReportingPeriod} />
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}