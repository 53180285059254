import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface ICreateEntitySelectionProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface ICreateEntitySelectionState {
}

export default class CreateEntitySelection extends Component<ICreateEntitySelectionProps, ICreateEntitySelectionState> {
    constructor(props: ICreateEntitySelectionProps) {
        super(props)
        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {
        const { externalEvent } = this.props;

        if(!externalEvent) return <></>;

        return (
            <SelectControlGroup
                text="Create a new"
                onChange={(value) => this.onValueChanged("createEntityType", value)}
                defaultValue={externalEvent.createEntityType}>
                {externalEvent.entityTypes && externalEvent.entityTypes.map((item, index) => <option key={item} value={item}>{item.toString()}</option>)}
            </SelectControlGroup>
        );
    }
}