import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BaseRoute from '../routes/BaseRoute';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import View from '../common/View';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import LogoLight from '../common/LogoLight';
import { appSource } from '../../utilities/constants';
import BadgeWrapper from '../common/BadgeWrapper';
import DotBadge from '../common/DotBadge';
import HamburgerIcon from '../../resources/HamburgerIcon';
import ProfileAvatar from '../profile/ProfileAvatar';
import ErrorBoundary from '../common/ErrorBoundary';
import ActiveSessionMonitorContainer from '../../containers/ActiveSessionMonitorContainer';
import { APPLICATION_BUILD_VERSION } from '../../utilities/version';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VenueIcon from '../../resources/VenueIcon';
import HomeIcon from '../../resources/HomeIcon';
import NotificationIcon from '../../resources/NotificationIcon';
import IncidentIcon from '../../resources/IncidentIcon';
import ChecklistIcon from '../../resources/ChecklistIcon';
import SignOnRegisterIcon from '../../resources/SignOnRegisterIcon';
import FolderIcon from '../../resources/FolderIcon';
import DashboardIcon from '../../resources/DashboardIcon';
import UserIcon from '../../resources/UserIcon';
import SecurityIcon from '../../resources/SecurityIcon';
import SettingsIcon from '../../resources/SettingsIcon';
import GroupIcon from '../../resources/GroupIcon';
import ChatIcon from '../../resources/ChatIcon';
import NotebookIcon from '../../resources/NotebookIcon';
import RosterIcon from '../../resources/RosterIcon';
import FacialRecognitionIcon from '../../resources/FacialRecognitionIcon';
import { Palette } from './../../common/constants/palette';
import SearchControl from '../controls/SearchControl';

//import React GA from 'react-ga';
import ReactGA from 'react-ga4';
import TransparentButton from '../controls/TransparentButton';

interface INavigationProps {
  theme: any;
  classes: any;
  container?: any;
  isUserLoading: boolean;
  name: string;
  userId: string;
  trainingWebsite: string;
  avatar: AusComplyDtos.File;
  onLogout: Function;
  onResetFilter: Function;
  onResetSignOnRegisterFilter: Function;
  onResetChecklistFilter: Function;
  onChangeVenue: Function;
  onClearVenue: Function;
  permissions: string[];
  profileisNotValid: boolean;
  pendingInvalidSLEDAccess: boolean;
  venueIsSelected: boolean;
  requiresSecurityCode: boolean;
  isStatisticsLoading: boolean;
  statistics: AusComplyDtos.HomeStatistics;
  venueId?: number;
  securityFirmId?: number;
  isImpersonating: boolean;
  onStopImpersonating: Function;
  groups: AusComplyDtos.GroupUserSummary[],
  venueHasGroups: boolean;
  showGamblingIncidentRegister: boolean;
}

interface INavigationState {
  mobileOpen: boolean;
  venueOpen: boolean;
  securityFirmOpen: boolean;
  adminOpen: boolean;
  signOnOffRegisterOpen: boolean;
  venueName: string;
  securityFirmName: string;
  groupsOpen: boolean;
  items: NavigationItem[];
  filteredItems: NavigationItem[];
  incidentCount: number;
  rgoCount: number;
  commsLogCount: number;
  checklistCount: number;
  notificationCount: number;
  loggedInUsersCount: number;
  totalBadges: number;
  openMenuItems: string[];
  search: string;
}

class NavigationItem {
  public name: string;
  public description?: string;
  public url: string;
  public permission?: AusComplyDtos.ngtUserPermission;
  public anyPermission?: AusComplyDtos.ngtUserPermission[];
  public allPermissions?: AusComplyDtos.ngtUserPermission[];
  public items?: NavigationItem[];
  public borderTop?: boolean;
  public badgeCount?: number;
  public show: boolean;
  public isExternalLink?: boolean;
  public optionName?: string;
  public options?: NavigationItem[];
  public icon?: any;
}

class Navigation extends React.Component<INavigationProps, INavigationState> {


  constructor(props: any) {
    super(props)
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleStopImpersonation = this.handleStopImpersonation.bind(this);
    this.buildMenu = this.buildMenu.bind(this);

    this.state = {
      mobileOpen: false,
      venueOpen: false,
      securityFirmOpen: false,
      signOnOffRegisterOpen: false,
      adminOpen: false,
      venueName: "",
      securityFirmName: "",
      groupsOpen: false,
      items: [],
      filteredItems: [],
      incidentCount: 0,
      rgoCount: 0,
      commsLogCount: 0,
      checklistCount: 0,
      notificationCount: 0,
      loggedInUsersCount: 0,
      totalBadges: 0,
      openMenuItems: [],
      search: ""
    };
  }

  componentDidMount() {
    this.buildStatistics();
    ReactGA.set({
      userId: this.props.userId.toString()
    });
  }

  componentDidUpdate(prevProps: INavigationProps) {
    if (
      prevProps.isStatisticsLoading && !this.props.isStatisticsLoading ||
      JSON.stringify(prevProps.permissions ? prevProps.permissions : "") != JSON.stringify(this.props.permissions ? this.props.permissions : "") ||
      JSON.stringify(prevProps.groups ? prevProps.groups : "") != JSON.stringify(this.props.groups ? this.props.groups : "")
    ) {
      this.buildStatistics()
    }
    else if (prevProps.statistics.incidentCount != this.props.statistics.incidentCount ||
      prevProps.statistics.rgoCount != this.props.statistics.rgoCount ||
      prevProps.statistics.commsLogCount != this.props.statistics.commsLogCount ||
      prevProps.statistics.checklistCount != this.props.statistics.checklistCount ||
      prevProps.statistics.notificationCount != this.props.statistics.notificationCount
    ) {
      this.buildStatistics()
    }
    ReactGA.set({
      userId: this.props.userId.toString()
    });
  }

  buildStatistics() {
    let incidentCount = 0;
    let rgoCount = 0;
    let commsLogCount = 0;
    let checklistCount = 0;
    let notificationCount = 0;
    let loggedInUsersCount = 0;
    let totalBadges = 0;
    let venueName = "";
    let securityFirmName = "";
    if (this.props.statistics) {
      if (this.props.statistics && this.props.statistics.incidentCount) {
        incidentCount = this.props.statistics.incidentCount;
      }
      if (this.props.statistics && this.props.statistics.rgoCount) {
        rgoCount = this.props.statistics.rgoCount;
      }
      if (this.props.statistics && this.props.statistics.commsLogCount) {
        commsLogCount = this.props.statistics.commsLogCount;
      }
      if (this.props.statistics && this.props.statistics.userCount) {
        loggedInUsersCount = this.props.statistics.userCount;
      }
      if (this.props.statistics && this.props.statistics.checklistCount) {
        checklistCount = this.props.statistics.checklistCount;
      }

      if (this.props.statistics && this.props.statistics.notificationCount) {
        notificationCount = this.props.statistics.notificationCount;
      }
      venueName = this.props.statistics.venueName ? this.props.statistics.venueName : "";
      if(this.props.statistics.venueEvent) {
        venueName += ": " + this.props.statistics.venueEvent;
      }
      securityFirmName = this.props.statistics.securityFirmName ? this.props.statistics.securityFirmName : "";
    }
    totalBadges = incidentCount + notificationCount + loggedInUsersCount + checklistCount + rgoCount + commsLogCount;

    this.setState({
      incidentCount,
      rgoCount,
      commsLogCount,
      loggedInUsersCount,
      checklistCount,
      notificationCount,
      totalBadges,
      venueName,
      securityFirmName
    }, () => {
      this.buildMenu();
    });
  }

  buildMenu() {
    let self = this;

    let baseURL = appSource.getBaseImagesPath();
    if (baseURL == '/') {
      baseURL = window.location.origin;
    }

    let groups: NavigationItem[] = [];
    if (this.props.groups && this.props.groups.length > 1) {
      this.props.groups.forEach(group => {
        groups.push(
          { name: group.group, url: "/groups/" + group.groupId, show: true }
        )
      });
    }
    

    let items: NavigationItem[] = [
      {
        name: "Group", description: this.props.groups && this.props.groups.length == 1 ? this.props.groups[0].group : "",
        icon: <GroupIcon fill={Palette.Primary} />, url: "/groups/" + (this.props.groups && this.props.groups.length > 0 ? this.props.groups[0].groupId : "0"),
        borderTop: false, show: this.showIfOneGroup()
      },
      { name: "Groups", url: "Groups", icon: <GroupIcon fill={Palette.Primary} />, borderTop: false, show: this.showMoreThanOneGroup(), items: groups },
      {
        name: this.state.venueName, description: this.state.securityFirmName, url: "ChangeVenue",
        borderTop: false, show: this.showIfVenueOrSecurityName(),
        icon: (<VenueIcon fill={Palette.Primary} />)
      },
      { name: "Home", url: "/home", borderTop: false, show: true, icon: (<HomeIcon fill={Palette.Primary} />) },
      { name: "My Notebook", url: "/notebook", permission: AusComplyDtos.ngtUserPermission.Notebook, show: true, icon: (<NotebookIcon fill={Palette.Primary} />) },
      { name: "My Roster", url: "/roster", permission: AusComplyDtos.ngtUserPermission.RoublerRoster, show: true, icon: (<RosterIcon fill={Palette.Primary} />) },
      { name: "Notifications", url: "/notifications", show: this.showNotifications(), badgeCount: this.state.notificationCount, icon: (<NotificationIcon fill={Palette.Primary} />) },
      { name: "Incidents", url: "/incidents", permission: AusComplyDtos.ngtUserPermission.ViewIncidentList, show: this.showIfVenueSelectedAndNoCodeRequired(), badgeCount: this.state.incidentCount, icon: (<IncidentIcon fill={Palette.Primary} />) },
      { name: "Comms Logs", url: "/commslogs", permission: AusComplyDtos.ngtUserPermission.CommsLogsView, show: this.showIfVenueSelectedAndNoCodeRequired(), badgeCount: this.state.commsLogCount, icon: (<ChatIcon fill={Palette.Primary} />) },
      { name: "Gambling Incident Register", url: "/gamblingincidentregister", permission: AusComplyDtos.ngtUserPermission.ViewIncidentList, show: this.props.showGamblingIncidentRegister && this.showIfVenueSelectedAndNoCodeRequired(), badgeCount: this.state.rgoCount, icon: (<ChatIcon fill={Palette.Primary} />) },
      { name: "CashTrax", url: "/cashtrax", permission: AusComplyDtos.ngtUserPermission.CashTraxIncidentView, show: false && this.showIfVenueSelectedAndNoCodeRequired(), badgeCount: 0, icon: (<ChatIcon fill={Palette.Primary} />) },
      { name: "PlayTrax", url: "/playtrax", permission: AusComplyDtos.ngtUserPermission.PlayTraxIncidentView, show: false && this.showIfVenueSelectedAndNoCodeRequired(), badgeCount: 0, icon: (<ChatIcon fill={Palette.Primary} />) },
      { name: "Inmates / Cells", url: "/inmatescells", permission: AusComplyDtos.ngtUserPermission.CorrectionalFacilityInmateView, show: this.showIfVenueSelectedAndNoCodeRequired() },
      { name: "Checklists", url: "/checklists", permission: AusComplyDtos.ngtUserPermission.ChecklistListView, show: this.showChecklists(), badgeCount: this.state.checklistCount, icon: (<ChecklistIcon fill={Palette.Primary} />) },
      { name: "Sign on register", url: "/signonoffregister", permission: AusComplyDtos.ngtUserPermission.SignOnOffRegisterView, show: this.showIfVenueSelectedAndNoCodeRequired(), icon: (<SignOnRegisterIcon fill={Palette.Primary} />) },
      { name: "Venue Documents", url: "/venue/directory", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue, icon: <FolderIcon fill={Palette.Primary} /> },
      { name: "Security Documents", url: "/securityfirm/directory", show: this.showIfSecurityFirm(), permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm, icon: <FolderIcon fill={Palette.Primary} /> },
      {
        name: "Dashboard", description: "", url: "Dashboard", show: true, borderTop: false, options: [
          { name: "Dashboard", optionName: "Venue", url: "/dashboard", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.Dashboard, icon: <DashboardIcon fill={Palette.Primary} /> },
          { name: "Dashboard", optionName: "Admin", url: "/admin/dashboard", show: true, permission: AusComplyDtos.ngtUserPermission.AdminDashboard, anyPermission: undefined, icon: <DashboardIcon fill={Palette.Primary} /> },
        ], icon: <DashboardIcon fill={Palette.Primary} />
      },
      {
        name: this.venueMenuTitle(), url: "/venue", show: this.showIfVenueSelectedAndNoCodeRequired(),
        icon: <VenueIcon fill={Palette.Primary} />,
        anyPermission: [AusComplyDtos.ngtUserPermission.ManageVenue, AusComplyDtos.ngtUserPermission.ViewVenue, AusComplyDtos.ngtUserPermission.ViewUserRoles, AusComplyDtos.ngtUserPermission.ManageEvent],
        items: [
          { name: "Details", url: "/venue", show: true, anyPermission: [AusComplyDtos.ngtUserPermission.ManageVenue, AusComplyDtos.ngtUserPermission.ViewVenue, AusComplyDtos.ngtUserPermission.ManageSecurityFirm] },
          { name: "Contacts", url: "/venue/contacts", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "Subscription", url: "/venue/subscription", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "Locations", url: "/venue/locations", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "Sub Locations", url: "/venue/subLocations", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.SubLocationsEdit},
          { name: "Radio Channels", url: "/venue/radioChannels", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.RadioChannelsEdit},
          { name: "Functional Areas", url: "/venue/functionalAreas", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.FunctionalAreasEdit},
          { name: "Security Firm Associations", url: "/venue/associations", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "Groups", url: "/venue/groups", show: this.showGroups(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "Events", url: "/venue/events", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageEvent },
          { name: "Reporting Periods", url: "/venue/venuereportingperiods", show: this.showReportingPeriods() },
          { name: "Scheduled Tasks", url: "/venue/schedules", show: true, permission: AusComplyDtos.ngtUserPermission.ManageVenueSchedule},
          { name: "All Users", description: this.usersDescription(), url: "/users", show: true, permission: AusComplyDtos.ngtUserPermission.ViewUserRoles },
        ]
      },
      {
        name: "Security Admin", url: "/securityFirm", icon: <SecurityIcon fill={Palette.Primary} />, show: this.showIfSecurityFirm(), 
          anyPermission: [AusComplyDtos.ngtUserPermission.ManageSecurityFirm, AusComplyDtos.ngtUserPermission.ManageSecurityFirmSchedule],
        items: [
          { name: "Details", url: "/securityfirm", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "Contacts", description: "", url: "/securityfirm/contacts", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "Subscription", description: "", url: "/securityfirm/subscription", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "Subcontractors", description: "", url: "/securityfirm/associations", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "Venue Association", description: "", url: "/securityfirm/venues", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "Scheduled Tasks", url: "/securityfirm/schedules", show: true, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirmSchedule},
        ]
      },
      {
        name: "User Admin", url: "UserAdmin", show: true, anyPermission: [
          AusComplyDtos.ngtUserPermission.ManageVenue,
          AusComplyDtos.ngtUserPermission.CreateBroadcastNotification,
          AusComplyDtos.ngtUserPermission.ViewVenue,
          AusComplyDtos.ngtUserPermission.CreateGlobalBroadcastNotification,
          AusComplyDtos.ngtUserPermission.RoublerRosterMapping
        ], icon: <UserIcon fill={Palette.Primary} />,
        items: [
          {
            name: "Broadcast Notifications", description: "", url: "BroadcastNotification", show: true, options: [
              { name: "Broadcast Notifications", optionName: "Venue", url: "/venue/broadcastnotifications", show: this.showIfVenueSelectedAndNoCodeRequired(), allPermissions: [AusComplyDtos.ngtUserPermission.ManageVenue, AusComplyDtos.ngtUserPermission.CreateBroadcastNotification] },
              { name: "Broadcast Notifications", optionName: "Security", url: "/securityfirm/broadcastnotifications", show: this.showIfSecurityFirm(), allPermissions: [AusComplyDtos.ngtUserPermission.ManageSecurityFirm, AusComplyDtos.ngtUserPermission.CreateBroadcastNotification] },
              { name: "Broadcast Notifications", optionName: "Admin", url: "/admin/broadcastnotifications", show: true, allPermissions: [AusComplyDtos.ngtUserPermission.CreateGlobalBroadcastNotification] },
            ]
          },
          {
            name: "Expired/Expiring", description: "Compliance Documents", url: "Expired", show: true, options: [
              { name: "Expired/Expiring", description: "Compliance Documents", optionName: "Venue", url: "/venue/expiringcompliancedocuments", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenueUserComplianceDocuments },
              { name: "Expired/Expiring", description: "Compliance Documents", optionName: "Security", url: "/securityfirm/expiringcompliancedocuments", show: this.showIfSecurityFirm(), permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirmUserComplianceDocuments },
            ]
          },
          {
            name: "Missing", description: "Compliance Documents", url: "Missing", show: true, options: [
              { name: "Missing", description: "Compliance Documents", optionName: "Venue", url: "/venue/missingcompliancedocuments", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenueUserComplianceDocuments },
              { name: "Missing", description: "Compliance Documents", optionName: "Security", url: "/securityfirm/missingcompliancedocuments", show: this.showIfSecurityFirm(), permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirmUserComplianceDocuments },
            ]
          },
          {
            name: "User Roles", description: "", url: "UserRoles", show: true, options: [
              { name: "User Roles", description: "", optionName: "Venue", url: "/venue/users", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
              { name: "User Roles", description: "", optionName: "Security", url: "/securityfirm/users", show: this.showIfSecurityFirm(), permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
            ]
          },
          {
            name: "Role Types", description: "", url: "RoleTypes", show: true, options: [
              { name: "Role Types", description: "", optionName: "Venue", url: "/venue/roles", show: this.showIfVenueSelectedAndNoCodeRequired(), anyPermission: [AusComplyDtos.ngtUserPermission.ManageVenue, AusComplyDtos.ngtUserPermission.ManageL0UserAccessRoleTypes, AusComplyDtos.ngtUserPermission.ViewRoleType] },
              { name: "Role Types", description: "", optionName: "Security", url: "/securityfirm/roles", show: this.showIfSecurityFirm(), anyPermission: [AusComplyDtos.ngtUserPermission.ManageSecurityFirm, AusComplyDtos.ngtUserPermission.ManageL0UserAccessRoleTypes, AusComplyDtos.ngtUserPermission.ViewRoleType] },
            ]
          },
          { name: "Roubler Employee", description: "Mapping", url: "/roubleremployeemapping", show: true, permission: AusComplyDtos.ngtUserPermission.RoublerRosterMapping},
        ]
      },
      {
        name: "System Settings", url: "SystemSettings", show: true,
        icon: <SettingsIcon fill={Palette.Primary} />,
        anyPermission: [
          AusComplyDtos.ngtUserPermission.ManageVenue,
          AusComplyDtos.ngtUserPermission.ViewVenue,
          AusComplyDtos.ngtUserPermission.ViewReferenceData,
          AusComplyDtos.ngtUserPermission.EditWebsiteProperties,
          AusComplyDtos.ngtUserPermission.MaintainFacialRecognitionNotification,
          AusComplyDtos.ngtUserPermission.ManageSecurityFirm,
          AusComplyDtos.ngtUserPermission.ChecklistTemplateEdit,
          AusComplyDtos.ngtUserPermission.MaintainUserRoleNotification,
          AusComplyDtos.ngtUserPermission.MaintainIncidentActions,
          AusComplyDtos.ngtUserPermission.MaintainGlobalIncidentActions,
          AusComplyDtos.ngtUserPermission.CashPlayTraxManager,
          AusComplyDtos.ngtUserPermission.ManageRollerIntegration
        ],
        items: [
          { name: "Facial Recognition", url: "/venue/facialRecognitionNotification", show: true, permission: AusComplyDtos.ngtUserPermission.MaintainFacialRecognitionNotification },
          { name: "CashTrax Events", url: "/venue/cashtraxevents", show: true, permission: AusComplyDtos.ngtUserPermission.CashPlayTraxManager },
          { name: "PlayTrax Events", url: "/venue/playtraxevents", show: true, permission: AusComplyDtos.ngtUserPermission.CashPlayTraxManager },
          {
            name: "Checklist Templates", description: "", url: "ChecklistTemplates", show: true, options: [
              { name: "Checklist Templates", description: "", optionName: "Venue", url: "/venue/checklistTemplates", show: this.showIfVenueSelectedAndNoCodeRequired(), allPermissions: [AusComplyDtos.ngtUserPermission.ManageVenue, AusComplyDtos.ngtUserPermission.ChecklistTemplateEdit] },
              { name: "Checklist Templates", description: "", optionName: "Security", url: "/securityfirm/checklistTemplates", show: this.showIfSecurityFirm(), allPermissions: [AusComplyDtos.ngtUserPermission.ManageSecurityFirm, AusComplyDtos.ngtUserPermission.ChecklistTemplateEdit] },
              { name: "Checklist Templates", description: "", optionName: "Admin", url: "/admin/checklistTemplates", show: true, allPermissions: [AusComplyDtos.ngtUserPermission.ViewReferenceData, AusComplyDtos.ngtUserPermission.ChecklistTemplateEdit] },
            ]
          },
          {
            name: "Incident Category Types", description: "", url: "IncidentCategoryTypes", show: true, options: [
              { name: "Incident Category Types", description: "", optionName: "Venue", url: "/venue/incidentcategorytypes", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.ManageVenue },
              { name: "Incident Category Types", description: "", optionName: "Security", url: "/securityfirm/incidentcategorytypes", show: this.showIfSecurityFirm(), permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
              { name: "Incident Category Types", description: "", optionName: "Admin", url: "/admin/incidentcategorytypes", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
            ]
          },
          { name: "Incident Types", description: "", url: "/admin/incidenttypes", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          {
            name: "Incident Flags", description: "", url: "Flags", show: true, options: [
              { name: "Incident Flags", description: "", optionName: "Venue", url: "/venue/incidentflags", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.MaintainUserRoleNotification },
              { name: "Incident Flags", description: "", optionName: "Admin", url: "/admin/incidentflags", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
            ]
          },
          { name: "Industries", description: "", url: "/admin/industryCategories", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          { name: "Sign On Register Types", description: "", url: "/admin/signonregistertypes", show: true, permission: AusComplyDtos.ngtUserPermission.SignOnRegisterTypes, anyPermission: undefined },
          { name: "Locations", description: "Default", url: "/admin/venuelocations", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          {
            name: "Incident Actions", description: "Templates", url: "IncidentActions", show: true, options: [
              { name: "Incident Actions", description: "Templates", optionName: "Venue", url: "/venue/incidentActionTemplates", show: this.showIfVenueSelectedAndNoCodeRequired(), permission: AusComplyDtos.ngtUserPermission.MaintainIncidentActions },
              { name: "Incident Actions", description: "Templates", optionName: "Admin", url: "/admin/incidentActionTemplates", show: true, permission: AusComplyDtos.ngtUserPermission.MaintainGlobalIncidentActions, anyPermission: undefined },
            ]
          },
          { name: "Document Folders", description: "", url: "/admin/documentTypes", show: false, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          { name: "Document Folders", description: "", url: "/admin/directories", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          { name: "Roller Integration", description: "", url: "/rollerintegration", show: true, permission: AusComplyDtos.ngtUserPermission.ManageRollerIntegration, anyPermission: undefined },
          { name: "Website Settings", description: "", url: "/admin/settings", show: true, permission: AusComplyDtos.ngtUserPermission.EditWebsiteProperties, anyPermission: undefined },
        ]
      },
      {
        name: "General", url: "General", show: true, anyPermission: [
          AusComplyDtos.ngtUserPermission.ViewVenuesAdmin,
          AusComplyDtos.ngtUserPermission.ViewSecurityFirmsAdmin,
          AusComplyDtos.ngtUserPermission.ViewUserProfilesAdmin,
          AusComplyDtos.ngtUserPermission.CreateGroup,
          AusComplyDtos.ngtUserPermission.AdminMaintainGroup
        ],
        icon: <SettingsIcon fill={Palette.Primary} />,
        items: [
          { name: "Venues", description: "", url: "/admin/venues", show: true, permission: AusComplyDtos.ngtUserPermission.ViewVenuesAdmin, anyPermission: undefined },
          { name: "Security Firms", description: "", url: "/admin/securityfirms", show: true, permission: AusComplyDtos.ngtUserPermission.ViewSecurityFirmsAdmin, anyPermission: undefined },
          { name: "User Profiles", description: "", url: "/admin/users", show: true, permission: AusComplyDtos.ngtUserPermission.ViewUserProfilesAdmin, anyPermission: undefined },
          { name: "Groups", description: "", url: "/admin/groups", show: true, anyPermission: [AusComplyDtos.ngtUserPermission.CreateGroup, AusComplyDtos.ngtUserPermission.AdminMaintainGroup] },
        ]
      },
      {
        name: "User Settings", url: "UserSettings", show: true, anyPermission: [
          AusComplyDtos.ngtUserPermission.ViewUserRolesAdmin,
          AusComplyDtos.ngtUserPermission.ViewUserDuplicateMobiles
        ],
        icon: <UserIcon fill={Palette.Primary} />,
        items: [
          { name: "User Roles", description: "", url: "/admin/userroles", show: true, permission: AusComplyDtos.ngtUserPermission.ViewUserRolesAdmin, anyPermission: undefined },
          { name: "Role Types", description: "", url: "/admin/roles", show: true, permission: AusComplyDtos.ngtUserPermission.ViewUserRolesAdmin, anyPermission: undefined },
          { name: "Duplicate User Mobile #", description: "", url: "/admin/duplicatemobilenumbers", show: true, permission: AusComplyDtos.ngtUserPermission.ViewUserDuplicateMobiles, anyPermission: undefined },
        ]
      },
      {
        name: "Facial Recognition", url: "FacialRecognition", show: true, anyPermission: [
          AusComplyDtos.ngtUserPermission.ViewReferenceData
        ],
        icon: <FacialRecognitionIcon fill={Palette.Primary} />,
        items: [
          { name: "Cloud Servers", description: "", url: "/admin/facialrecognitionservers", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          //{ name: "Global Watchlists", description: "", url: "/admin/facialrecognitionwatchlists", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
          { name: "Watchlist Types", description: "", url: "/admin/watchlisttypes", show: true, permission: AusComplyDtos.ngtUserPermission.ViewReferenceData, anyPermission: undefined },
        ]
      },
      {
        name: "System Log", description: "", url: "SystemLog", show: true, borderTop: false, options: [
          { name: "System Log", optionName: "Venue", url: "/venue/systemlog", show: this.showIfVenueSelectedAndNoCodeRequired(), borderTop: false, permission: AusComplyDtos.ngtUserPermission.ManageVenue },
          { name: "System Log", optionName: "Security", url: "/securityfirm/systemlog", show: this.showIfSecurityFirm(), borderTop: false, permission: AusComplyDtos.ngtUserPermission.ManageSecurityFirm },
          { name: "System Log", optionName: "Admin", url: "/admin/systemlog", show: true, borderTop: false, permission: AusComplyDtos.ngtUserPermission.AdminDashboard, anyPermission: undefined },
        ]
      },
      { name: "Training", url: this.props.trainingWebsite, show: this.showTraining(), isExternalLink: true },
      { name: "Register Venue", url: "/register/venue", show: true, borderTop: false },
      { name: "Register Security Firm", url: "/register/securityFirm", show: true },
      { name: "Terms", url: "/terms", show: true, borderTop: false },
      { name: "Privacy Policy", url: "/privacypolicy", show: true },
      { name: "My Profile", description: this.props.name, url: "/profile", borderTop: false, show: true },
      // { name: "Logout", url: "/logout", show: true }
    ];
    this.setState({ items }, () => {
      self.filterMenu()
    });
  }

  filterMenu() {
    let filteredItems:NavigationItem[] = [];
    let items = [...this.state.items];
    items.forEach(item => {
      if (item.items == undefined || item.items == null || item.items.length == 0){
        if (item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) {
          filteredItems.push({...item});
        }
      } else if (item.items != undefined && item.items != null && item.items.length > 0) {
        // only show if sub items exist
        let itemGroup = {...item};
        itemGroup.items = item.items.filter(f => f.name.toLowerCase().indexOf(this.state.search.toLowerCase())> -1);
        if (itemGroup.items && itemGroup.items.length > 0) {
          filteredItems.push({...itemGroup});
        }
      }
    });

    this.setState({filteredItems});
  }

  handleMenuClick(item: NavigationItem) {
    if (item.url) {
      switch (item.url) {
        case "/logout":
          this.props.onLogout();
          break;
        case "/signonoffregister":
          this.props.onResetSignOnRegisterFilter();
          break;
        case "/incidents":
          this.props.onResetFilter();
          break;
        case "/checklists":
          this.props.onResetChecklistFilter();
          break;
        case "ChangeVenue":
          this.props.onClearVenue();
          this.props.onChangeVenue();
          break;
        default:
          break;
      }
    }
    this.setState(state => ({ mobileOpen: false }));
  }

  venueMenuTitle() {
    if (this.hasPermission(AusComplyDtos.ngtUserPermission.ManageVenue)) return "Venue Admin";
    return "Venue";
  }

  usersDescription() {
    if (this.hasPermission(AusComplyDtos.ngtUserPermission.ManageVenue)) return "Including Security";
    if (!this.hasPermission(AusComplyDtos.ngtUserPermission.ManageVenue) && this.hasPermission(AusComplyDtos.ngtUserPermission.ManageSecurityFirm)) return "Including Venue Staff";
    return "";
  }

  isSubMenuOpen(key: string) {
    if (this.state.openMenuItems.indexOf(key) !== -1) return true;
    return false;
  }

  subMenuOpen(key: string, value: boolean) {
    let openMenuItems = [...this.state.openMenuItems];
    if (value) {
      if (this.state.openMenuItems.indexOf(key) == -1) {
        openMenuItems.push(key);
      }
    } else {
      if (this.state.openMenuItems.indexOf(key) !== -1) {
        openMenuItems.splice(this.state.openMenuItems.indexOf(key), 1);
      }
    }
    this.setState({ openMenuItems });
  }

  checkItemVisible(item: NavigationItem) {
    if (!item.show) return false;
    if (item.permission && !this.hasPermission(item.permission)) return false;
    if (item.anyPermission && !this.hasAnyPermission(item.anyPermission)) return false;
    if (item.allPermissions && !this.hasAllPermissions(item.allPermissions)) return false;

    return true;
  }

  checkAllItemsVisible(item: NavigationItem) {
    let visible = false;
    if (item.items && item.items.length > 0) {
      item.items.forEach(subItem => {
        if (this.checkItemVisible(subItem)) {
          visible = true;
        }
      });
    };
    return visible;
  }

  showReportingPeriods() {
    if (this.hasPermission("ManageVenue") && this.showIfVenueSelectedAndNoCodeRequired()) {
      if (this.hasPermission("ViewReportingPeriods")) {
        return true;
      }
    } else if (this.hasPermission("ViewVenue") && this.showIfVenueSelectedAndNoCodeRequired()) {
      if (this.hasPermission("ChecklistTemplateEdit")) {
        return true;
      }
      if (this.hasPermission("MaintainFacialRecognitionNotification")) {
        return true;
      }
    }
    return false;
  }

  showTraining() {
    return this.props.trainingWebsite != undefined && this.props.trainingWebsite != "";
  }

  showIfSecurityFirm() {
    return this.props.securityFirmId != undefined && this.props.securityFirmId > 0;
  }

  showIfVenueOrSecurityName() {
    return (this.state.venueName != "" || this.state.securityFirmName != "");
  }

  showIfOneGroup() {
    return this.props.groups && this.props.groups.length == 1;
  }

  showMoreThanOneGroup() {
    return this.props.groups && this.props.groups.length > 1;
  }

  showIfVenueSelectedAndNoCodeRequired() {
    return !this.props.requiresSecurityCode && this.props.venueIsSelected;
  }

  showNotifications() {
    return this.props.venueIsSelected || (this.props.securityFirmId && this.props.securityFirmId > 0) || this.state.notificationCount > 0 || (this.props.groups && this.props.groups.length > 0);
  }

  showChecklists() {
    return !this.props.requiresSecurityCode && (this.props.venueIsSelected || (!this.props.venueIsSelected && this.props.securityFirmId != undefined && this.props.securityFirmId > 0));
  }

  showGroups() {
    return this.props.venueHasGroups;
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDrawerClose = () => {
    this.setState(state => ({ mobileOpen: false }));
  };

  handleStopImpersonation = () => {
    this.setState(state => ({ mobileOpen: false, adminOpen: false, venueName: "", securityFirmName: "", venueOpen: false, securityFirmOpen: false }));
    this.props.onStopImpersonating();
  }

  hasPermission(permission: string) {
    if (this.props.permissions) {
      if (this.props.permissions.indexOf(permission) > -1) {
        return true;
      }
    }
    return false;
  }

  hasAnyPermission(anyPermissions: string[]) {
    let hasPermission: boolean = false;
    if (this.props.permissions) {
      anyPermissions.forEach(permission => {
        if (this.props.permissions.indexOf(permission) > -1) {
          hasPermission = true;
        }
      })

    }
    return hasPermission;
  }

  hasAllPermissions(allPermissions: string[]) {
    let result: boolean = true;
    if (this.props.permissions) {
      allPermissions.forEach(permission => {
        if (this.props.permissions.indexOf(permission) == -1) {
          result = false;
        }
      })

    }
    return result;
  }

  renderVenueSwitch(item: NavigationItem) {

  }

  renderSubMenu(items: NavigationItem[]) {
    const { classes, theme } = this.props;
    const dividerStyle = {
      backgroundColor: theme.palette.text.disabled, margin: '11px 0 12px 0'
    }
    const listStyle = {
      padding: 0
    };

    return <>
      {items.map((navigationItem, index) => {
        let item: NavigationItem = { ...navigationItem };

        if (item.options) {
          let activeOptions = item.options.filter(f => this.checkItemVisible(f));
          if (activeOptions.length == 0) return <></>;
          if (activeOptions.length == 1) {
            item = { ...activeOptions[0] };
          }
          if (activeOptions.length > 1) {
            // show a menu item that pops up to show options
            return <View key={"nav-submenu-with-options" + item.url + "-" + index.toString()}>
              {navigationItem.borderTop && <Divider variant="inset" style={{ backgroundColor: theme.palette.text.disabled }} />}
              <NavigationOptions item={navigationItem} options={activeOptions} onClick={this.handleMenuClick} inset={false} classes={classes} subMenu={true} />
            </View>
          }
        }

        const key = item.name + "_" + (item.description ? item.description : "");
        const url = item.url.startsWith("/") ? item.url : "/";
        if (!this.checkItemVisible(item)) return <></>;
        let active = false;
        if (window.location) {
          active = window.location.pathname.toLowerCase() === url.toLowerCase();
        }

        return <View key={"nav-submenu-" + item.url + "-" + index.toString()}>
          {item.borderTop && <Divider variant="inset" style={{ backgroundColor: theme.palette.text.disabled }} />}
          {(item.badgeCount && item.badgeCount > 0) ? (
            <ListItem to={url} onClick={() => this.handleMenuClick(item)} component={Link} button key={key}>
              <BadgeWrapper count={item.badgeCount} shadow={true}>
                <ListItemText primary={item.name} secondary={item.description}
                  className={classes.navListItemStyle}
                  primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
                  secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '12px' } }} />
              </BadgeWrapper>
            </ListItem>
          ) : (
            <ListItem to={url} onClick={() => this.handleMenuClick(item)} component={Link} button key={key}>
              <ListItemText primary={item.name} secondary={item.description}
                className={classes.navListItemStyle}
                primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
                secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '12px' } }} />
            </ListItem>
          )}
        </View>
      })}
    </>;
  }

  renderMenu(items: NavigationItem[]) {

    const { classes, theme } = this.props;
    const dividerStyle = {
      backgroundColor: theme.palette.text.disabled, margin: '11px 0 12px 0'
    }
    const listStyle = {
      padding: 0,
      borderBottom: '1px solid ' + Palette.ControlBorderDisabled
    };

    if (this.props.isUserLoading === undefined || this.props.isUserLoading) {
      return <div style={{ height: '280px', paddingTop: '30px' }}>
        <div className="navigation-loading" style={{ backgroundColor: Palette.FooterBackground }}></div>
        <div className="navigation-loading" style={{ backgroundColor: Palette.FooterBackground }}></div>
        <div className="navigation-loading" style={{ backgroundColor: Palette.FooterBackground }}></div>
        <div className="navigation-loading" style={{ backgroundColor: Palette.FooterBackground }}></div>
        <div className="navigation-loading" style={{ backgroundColor: Palette.FooterBackground }}></div>
      </div>
    }

    return <>
      {items.map((navigationItem, index) => {
        let item: NavigationItem = { ...navigationItem };

        if (item.url === "ChangeVenue" && this.checkItemVisible(item)) {
          let iconDisplay = <View style={{ flexDirection: 'column', height: '24px', width: '24px', marginRight: '10px' }}>
            <View style={{ flex: 1 }}></View>
            <View style={{ flex: 0, width: '24px' }}>
              {item.icon}
            </View>
            <View style={{ flex: 1 }}></View>
          </View>;
          return <List key={"nav-change-venue-" + item.url + "-" + index.toString()} style={{ ...listStyle, borderBottomColor: Palette.Primary }}>
            <ListItem to={"/home"} onClick={() => this.handleMenuClick(item)} component={Link} button >
              {iconDisplay}
              <ListItemText primary={item.name} secondary={item.description}
                primaryTypographyProps={{ style: { color: Palette.Primary, fontSize: '14px' } }}
                secondaryTypographyProps={{ style: { color: Palette.Active, fontSize: '12px' } }}
              />
            </ListItem>
          </List>
        }
        if (item.url === "/profile" && this.checkItemVisible(item)) {
          let iconDisplay;
          if (this.props.avatar && this.props.avatar.isImage) {
            let base = appSource.getBaseImagesPath();
            if (base === '/') {
              base = "";
            }
            var path = base + this.props.avatar.filePreviewPath;
            iconDisplay = <View style={{ flex: 0 }}>
              <View style={{ flexDirection: 'column', height: '30px', width: '30px', marginRight: '10px' }}>
                <View style={{ flex: 1 }}></View>
                <View style={{ height: '30px', width: '30px', marginRight: '10px', borderStyle: 'solid', borderWidth: '1px', borderColor: '#fff', borderRadius: '15px', overflow: 'hidden' }}>
                  <img src={path}
                    style={{ objectFit: 'cover', width: '30px', height: '30px' }}
                    alt="avatar" />
                </View>
                <View style={{ flex: 1 }}></View>
              </View>
            </View>;
          } else {
            iconDisplay = <View style={{ flex: 0 }} key={"nav-" + navigationItem.url + "-" + index.toString()}>
              <View style={{ flexDirection: 'column', height: '30px', width: '30px', marginRight: '10px' }}>
                <View style={{ flex: 1 }}></View>
                <View style={{ flex: 0, width: '30px' }}>
                  <UserIcon fill={Palette.Primary} />
                </View>
                <View style={{ flex: 1 }}></View>
              </View>
            </View>;
          }

          return <List style={{ ...listStyle, borderBottomColor: Palette.ControlBorderDisabled }} key={"nav-profile-" + item.url + "-" + index.toString()}>
            <ListItem to={item.url} onClick={() => this.handleMenuClick(item)} component={Link} button >
              {iconDisplay}
              <ListItemText primary={"My Profile"} secondary={this.props.name}
                className={classes.navListItemStyle}
                primaryTypographyProps={{ style: { color: Palette.Active, fontSize: '14px' } }}
                secondaryTypographyProps={{ style: { color: Palette.Primary, fontSize: '14px' } }}
              />
            </ListItem>
          </List>
        }
        if (item.options) {
          let activeOptions = item.options.filter(f => this.checkItemVisible(f));
          if (activeOptions.length == 0) return <></>;
          if (activeOptions.length == 1) {
            item = { ...activeOptions[0] };
          }
          if (activeOptions.length > 1) {
            // show a menu item that pops up to show options
            return <View key={"nav-active-options-" + item.url + "-" + index.toString()}>
              {navigationItem.borderTop && <Divider style={dividerStyle} />}
              <NavigationOptions item={navigationItem} options={activeOptions} onClick={this.handleMenuClick} classes={classes} subMenu={false} />
            </View>
          }
        }

        const key = item.name + "_" + (item.description ? item.description : "");
        const url = item.url != undefined ? item.url.startsWith("/") ? item.url : "/" : "/";

        if (!this.checkItemVisible(item)) return <View key={"nav-external-" + item.url + "-" + index.toString()}></View>;
        if (item.isExternalLink) {
          return <a key={"externallink-training"} href={this.props.trainingWebsite}
            className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button"
            style={{ borderBottom: '1px solid ' + Palette.ControlBorderDisabled }}
            target="_blank"><div className="MuiListItemText-root">
              <span style={{ color: Palette.Active, fontSize: '14px' }}
                className="MuiTypography-root MuiListItemText-primary MuiTypography-body1">Training</span>
            </div>
          </a>;
        }
        let hasSubItems: boolean = false;
        let isExpanded: boolean = false;
        if (item.items) {
          // check all the permissions
          hasSubItems = this.checkAllItemsVisible(item);
          isExpanded = this.isSubMenuOpen(item.url) || this.state.search.length > 0;
        }
        if (hasSubItems) {
          if (url == "/") {
            return <View key={"nav-has-sub-items-home-" + item.url + "-" + index.toString()}>
              {item.borderTop && <Divider style={dividerStyle} />}
              <List key={key} style={{ padding: 0, borderBottom: '1px solid ' + Palette.Primary }}>
                <ListItem onClick={() => this.subMenuOpen(item.url, !isExpanded)} button >
                  {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
                    {item.icon}
                  </View>}
                  <ListItemText primary={item.name}
                    className={classes.navListItemStyle}
                    primaryTypographyProps={{ style: { color: Palette.Active, fontSize: '14px' } }}
                    secondaryTypographyProps={{ style: { color: Palette.Active, fontSize: '14px' } }} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => this.subMenuOpen(item.url, !isExpanded)} style={{ color: Palette.Primary }}>
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <List disablePadding style={{ marginLeft: '32px' }}>
                    {item.items && this.renderSubMenu(item.items)}
                  </List>
                </Collapse>
              </List>
            </View>
          }
          return <View key={"nav-has-sub-items-" + item.url + "-" + index.toString()}>
            {item.borderTop && <Divider style={dividerStyle} />}
            <List key={key} style={{ padding: 0, borderBottom: '1px solid ' + Palette.Primary }}>
              <ListItem to={url} onClick={() => this.handleMenuClick(item)} component={Link} button  >
                {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
                  {item.icon}
                </View>}
                <ListItemText primary={item.name}
                  className={classes.navListItemStyle}
                  primaryTypographyProps={{ style: { color: Palette.Active, fontSize: '14px' } }}
                  secondaryTypographyProps={{ style: { color: Palette.Active, fontSize: '14px' } }} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => this.subMenuOpen(item.url, !isExpanded)} style={{ color: Palette.Primary }}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <List disablePadding style={{ marginLeft: '32px' }}>
                  {item.items && this.renderSubMenu(item.items)}
                </List>
              </Collapse>
            </List>
          </View>
        }
        let active = false;
        if (window.location) {
          active = window.location.pathname.toLowerCase() === url.toLowerCase();
        }
        return <View key={"nav-default-" + item.url + "-" + index.toString()}>
          {item.borderTop && <Divider style={dividerStyle} />}
          {(item.badgeCount && item.badgeCount > 0) ? (
            <List style={listStyle} key={key}>
              <ListItem to={url} onClick={() => this.handleMenuClick(item)} component={Link} button >
                <BadgeWrapper count={item.badgeCount} shadow={true}>
                  {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
                    {item.icon}
                  </View>}
                  <ListItemText primary={item.name} secondary={item.description}
                    className={classes.navListItemStyle}
                    primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
                    secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }} />
                </BadgeWrapper>
              </ListItem>
            </List>
          ) : (
            <List style={listStyle} key={key}>
              <ListItem to={url} onClick={() => this.handleMenuClick(item)} component={Link} button >
                {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
                  {item.icon}
                </View>}
                <ListItemText
                  className={classes.navListItemStyle}
                  primary={item.name}
                  secondary={item.description}
                  primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
                  secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }} />
              </ListItem>
            </List>
          )}
        </View>
      })}
    </>;
  }

  render() {
    const { classes, theme } = this.props;
    let self = this;

    let listStyle = {
      padding: 0
    };
    let dividerStyle = {
      backgroundColor: theme.palette.text.disabled, margin: '11px 0 12px 0'
    }

    const drawer = (
      <div className={classes.navDrawerContainer}>
        <div className={classes.navDrawerContainerItems}>
          {this.props.isImpersonating && (
            <List style={listStyle}>
              <ListItem to={"/admin/userrole/" + this.props.userId} onClick={this.handleStopImpersonation} component={Link} button >
                <ListItemText primary={"Stop Impersonation"} style={{ textAlign: 'center', color: 'white', backgroundColor: 'red' }} />
              </ListItem>
            </List>
          )}
          {!this.props.isUserLoading &&
            <SearchControl value={this.state.search} onChange={value => self.setState({search: value}, () => self.filterMenu())} searchText='Filter menu...' compact={true} />
          } 
          {this.renderMenu(this.state.filteredItems)}
          <div style={{ height: '20px' }} />
          <TransparentButton text={"Logout"} onClick={() => this.props.onLogout()} />
          <div className={classes.toolbar}>
            <p style={{ color: theme.palette.text.disabled, fontSize: '10px', margin: '24px 0 12px', padding: 0, display: 'block', textAlign: 'center' }}>
              Electronic Transactions Act Compliant
            </p>
            <p style={{ color: theme.palette.text.disabled, fontSize: '10px', margin: '12px 0', padding: 0, display: 'block', textAlign: 'center' }}>
              Application version / {APPLICATION_BUILD_VERSION}
            </p>
          </div>
        </div>
        <Hidden mdDown implementation="css">
          <div className={classes.navToolbarLogo}>
            <IconButton
              color="inherit"
              aria-owns={this.state.mobileOpen ? 'menu-appbar' : undefined}
              aria-label="Logo"
              onClick={this.handleDrawerClose}
              to="/"
              component={Link}
              style={{ marginTop: '4px', width: '100%', textAlign: "center" }}
            >
              <LogoLight style={{ height: '30px' }} />
            </IconButton>
          </div>
        </Hidden>
      </div>
    );

    return (
      <BrowserRouter basename={appSource.getBasePath()}>
        <div className={classes.navRoot}>
          <CssBaseline />
          <Hidden lgUp implementation="css">
            <AppBar position="fixed" className={classes.headerMobile}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.navMenuButton}
                  style={{ marginRight: '0' }}
                >
                  <DotBadge count={this.state.totalBadges}>
                    <HamburgerIcon fill={theme.custom.colors.border} style={{ width: '24px', height: '24px' }} />
                  </DotBadge>
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-owns={this.state.mobileOpen ? 'menu-appbar' : undefined}
                  aria-label="Logo"
                  onClick={this.handleDrawerClose}
                  to="/"
                  component={Link}
                  style={{ marginRight: '0', flexGrow: 1, textAlign: "center" }}
                >
                  <LogoLight style={{ height: '30px' }} />
                </IconButton>
                <IconButton
                  aria-owns={this.state.mobileOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  to="/profile"
                  onClick={this.handleDrawerClose}
                  component={Link}
                  color="inherit"
                >
                  <ProfileAvatar avatar={this.props.avatar} />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Hidden>
          <nav className={classes.navDrawer}>
            {/* The implementation can be swap with js to avoid SEO duplication of links. */}
            <Hidden lgUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                //onClick={this.handleDrawerToggle} // support for parent items later
                classes={{
                  paper: classes.navDrawerPaper,
                }}
                className="blackscroll"
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.navDrawerPaper,
                }}
                className="blackscroll"
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.navContent} style={{ padding: '16px 16px 80px 16px', overflow: 'hidden' }}>
            <Hidden lgUp implementation="css">
              <div className={classes.navToolbar} />
            </Hidden>
            <ErrorBoundary>
              <BaseRoute
                profileisNotValid={this.props.profileisNotValid}
                venueIsSelected={this.props.venueIsSelected}
                requiresSecurityCode={this.props.requiresSecurityCode}
                pendingInvalidSLEDAccess={this.props.pendingInvalidSLEDAccess} />
            </ErrorBoundary>
            <ActiveSessionMonitorContainer />
          </main>
        </div>
      </BrowserRouter>
    );
  }

}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #444444',
    backgroundColor: '#262626'
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

type NavigationOptionsProps = {
  item: NavigationItem;
  options: NavigationItem[];
  onClick: Function;
  inset?: boolean;
  subMenu: boolean;
  classes: any;
}

export function NavigationOptions({ item, options, onClick, inset, classes, subMenu }: NavigationOptionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    onClick(option);
    setAnchorEl(null);
  };

  const key = item.name + "_" + (item.description ? item.description : "");

  let active = false;
  if (window.location) {
    active = window.location.pathname.toLowerCase() === item.url.toLowerCase();
    if (!active) {
      active = options.filter(f => window.location.pathname.toLowerCase() === f.url.toLowerCase()).length > 0;
    }
  }
  if (inset) {
    return <>
      <ListItem key={key} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} button >
        {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
          {item.icon}
        </View>}
        <ListItemText primary={item.name} inset
          className={classes.navListItemStyle}
          primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
          secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }} />
      </ListItem>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) =>
          <MenuItem to={option.url} key={"nav-menu-item" + option.optionName + "-" + index} component={Link} onClick={() => handleOptionClick(option)}>{option.optionName}</MenuItem>
        )}
      </StyledMenu>
    </>
  }
  return (
    <div>
      <List style={{
        padding: 0,
        borderBottom: subMenu ? 'none' : '1px solid ' + Palette.ControlBorderDisabled
      }} key={key}>
        <ListItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} button >
          {item.icon && <View style={{ width: '24px', height: '24px', marginRight: '10px' }}>
            {item.icon}
          </View>}
          <ListItemText primary={item.name}
            className={classes.navListItemStyle}
            primaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }}
            secondaryTypographyProps={{ style: { color: active ? Palette.Primary : Palette.Active, fontSize: '14px' } }} />
        </ListItem>
      </List>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) =>
          <MenuItem key={"menu-item-option" + index.toString() + option.optionName} to={option.url}component={Link} onClick={() => handleOptionClick(option)}>{option.optionName}</MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(Navigation);