
export const RegexConstants = {
    POST_CODE: /^[0-9]{4}$/,
    POST_CODE_DESCRIPTION: "Postcode must be 4 numbers only",
    PHONE: /^\s*([0-9][\s-]*){10}$/,
    PHONE_DESCRIPTION: "Phone must be 10 numbers only",
    ABN: /^\s*([0-9][\s-]*){11}$/,
    ABN_DESCRIPTION: 'ABN must be 11 numbers only',
    EMAIL: /^[\w\.=-]+@[\w\.-]+\.[\w]{2,3}$/,
    EMAIL_DESCRIPTION: 'A valid email address is required',
    LIQUOR_LICENCE: /^\s*([A-Za-z0-9][\s-]*){5,15}$/,
    LIQUOR_LICENCE_DESCRIPTION: 'Liquor Licence # must be 5 to 15 alphanumeric characters',
    MASTER_LICENCE_NUMBER: /^\s*([0-9][\s-]*){9}$/,
    MASTER_LICENCE_NUMBER_DESCRIPTION: 'Master Licence # must be 9 numbers only'
}

