
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userRoleServiceApi } from '../services/userRoleService';
import { userServiceApi } from '../services/userService';

import {
    ROLE_TYPES_REQUEST,
    roleTypesRequestSuccess,
    roleTypesRequestFailure,
    ROLE_TYPE_LOAD_REQUEST,
    roleTypeLoadRequestSuccess,
    roleTypeLoadRequestFailure,
    ROLE_TYPE_UPDATE_SAVE,
    roleTypeUpdateSaveSuccess,
    roleTypeUpdateSaveFailure,

    USER_ROLE_ADD_REQUEST,
    userRoleAddRequestSuccess,
    userRoleAddRequestFailure,
    USER_ROLE_ADD_SAVE,
    userRoleAddSaveSuccess,
    userRoleAddSaveFailure,
    USER_ROLE_LOAD_REQUEST,
    userRoleLoadRequestSuccess,
    userRoleLoadRequestFailure,
    USER_ROLE_UPDATE_SAVE,
    userRoleUpdateSaveSuccess,
    userRoleUpdateSaveFailure,
    USER_ROLE_REMOVE_REQUEST,
    userRoleRemoveRequestSuccess,
    userRoleRemoveRequestFailure,
    USER_ROLE_REQUEST_DISMISS_REQUEST,
    userRoleRequestDismissRequestSuccess,
    userRoleRequestDismissRequestFailure,
    USER_ROLE_ENABLE_REQUEST,
    userRoleEnableRequestSuccess,
    userRoleEnableRequestFailure,
    USER_ROLES_REQUEST,
    userRolesRequestSuccess,
    userRolesRequestFailure,
    userRolesRefresh,
    USER_ROLE_ENABLE_REQUEST_SUCCESS,
    USER_ROLES_REFRESH,
    USER_ROLE_USER_VIEW_REQUEST,
    userRolesUserViewRequestSuccess,
    userRolesUserViewRequestFailure,
    USER_ROLE_USER_REQUEST,
    userRolesUserRequestSuccess,
    userRolesUserRequestFailure,
    USER_ROLE_USER_REFRESH,
    userRoleUserRefresh,
    USER_ROLES_EXPORT_REQUEST,
    USER_ROLES_EXPORT_REQUEST_SUCCESS,
    userRolesExportRequestSuccess,
    userRolesExportRequestFailure,

    USER_ROLE_OTHER_DOCUMENTS_SAVE,
    userRoleSaveOtherDocumentsSuccess,
    userRoleSaveOtherDocumentsFailure,
    USER_ROLE_OTHER_DOCUMENT_REMOVE,
    userRoleRemoveOtherDocumentFailure,
    userRoleRemoveOtherDocumentSuccess,
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE,
    userRoleSaveComplianceDocumentsSuccess,
    userRoleSaveComplianceDocumentsFailure,
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE,
    userRoleRemoveComplianceDocumentFailure,
    userRoleRemoveComplianceDocumentSuccess,
    ROLE_TYPE_ADD_REQUEST,
    roleTypeAddSuccess,
    roleTypeAddFailure,
    roleTypeAddSaveSuccess,
    roleTypeAddSaveFailure,
    ROLE_TYPE_ADD_SAVE_REQUEST,
    ROLE_TYPE_DELETE_REQUEST,
    roleTypeDeleteRequestSuccess,
    roleTypeDeleteRequestFailure,
    USER_ROLES_SUSPEND_SAVE,
    userRolesSuspendSuccess,
    userRolesSuspendFailure,
    USER_ROLES_SUSPEND_SAVE_SUCCESS

} from "../actions/userRole";

import {
    printQueueRequest
} from '../actions/print';

import {
    notifyError, notifyErrorMessage
} from './common';
import { notificationSuccessShow, notificationShow } from "../actions/notification";
import { industryCategoryApi } from '../services/industryCategory';

const roleTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.getRoleTypes(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['page'], action['pageSize'], action['filter'])
                .pipe(
                    map(response => roleTypesRequestSuccess(response.userRoleTypes, response.paging, response.filter)),
                    catchError(error => notifyError(error, "roleTypesRequestEpic.getRoleTypes", roleTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "roleTypesRequestEpic", roleTypesRequestFailure))
    );

const loadRoleTypeEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPE_LOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.getRoleType(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRoleTypeId'])
                .pipe(
                    map(response => roleTypeLoadRequestSuccess(response.userRoleType, response.availableUserPermissions, response.availableIndustryCategories, response.availableStates)),
                    catchError(error => notifyErrorMessage(error, "Failed to load user role type", roleTypeLoadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load user role type", roleTypeLoadRequestFailure))
    );

const newRoleTypeEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPE_ADD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.newRoleType(action.cloneRoleTypeId)
                .pipe(
                    map(response => roleTypeAddSuccess(response.userRoleType, response.availableUserPermissions, response.availableIndustryCategories, response.availableStates)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise new user role type", roleTypeAddFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise new user role type", roleTypeAddFailure))
    );

const saveNewRoleTypeEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPE_ADD_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.postRoleType(action['venueId'], action['securityFirmId'], action['roleType'], action['localRoleEditMode'])
                .pipe(
                    map(response => roleTypeAddSaveSuccess(response.userRoleType)),
                    catchError(error => notifyErrorMessage(error, "Failed to save new user role type", roleTypeAddSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save new user role type", roleTypeAddSaveFailure))
    );

const saveUpdateRoleTypeEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPE_UPDATE_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.updateRoleType(action['venueId'], action['securityFirmId'], action['roleType'], action['localRoleEditMode'])
                .pipe(
                    map(response => roleTypeUpdateSaveSuccess(response.userRoleType)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user role type", roleTypeUpdateSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user role type", roleTypeUpdateSaveFailure))
    );

const deleteRoleTypeEpic = (action$, state$) =>
    action$.pipe(
        ofType(ROLE_TYPE_DELETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.deleteRoleType(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['roleTypeId'])
                .pipe(
                    map(response => roleTypeDeleteRequestSuccess()),
                    catchError(error => notifyErrorMessage(error, "Failed to delete user role type", roleTypeDeleteRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to delete user role type", roleTypeDeleteRequestFailure))
    );

const userRoleEnableRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ENABLE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.userRoleEnable(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRoleId'])
                .pipe(
                    map(response => userRoleEnableRequestSuccess(response.userRole)),
                    catchError(error => notifyError(error, "userRoleEnableRequestEpic.userRoleEnable", userRoleEnableRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleEnableRequestEpic", userRoleEnableRequestFailure))
    );

const userRoleRequestDismissRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_REQUEST_DISMISS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.userRoleRequestDismiss(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['notificationId'])
                .pipe(
                    map(response => userRoleRequestDismissRequestSuccess()),
                    catchError(error => notifyError(error, "userRoleRequestDismissRequestEpic.userRoleRequestDismiss", userRoleRequestDismissRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleRequestDismissRequestEpic", userRoleRequestDismissRequestFailure))
    );


const newUserRoleEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ADD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.newUserRole(
                action['userId'],
                action['venueId'],
                action['securityFirmId'],
                action['forSecurityFirm'])
                .pipe(
                    map(response => userRoleAddRequestSuccess(response.userRole, response.availableVenues, response.availableSecurityFirms, response.userRoleTypes)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise new user role", userRoleAddRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise new user role", userRoleAddRequestFailure))
    );

const saveNewUserRoleEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ADD_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.postUserRole(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRole'], action['notificationId'])
                .pipe(
                    map(response => userRoleAddSaveSuccess(response.userRole)),
                    catchError(error => notifyErrorMessage(error, "Failed to save new user role", userRoleAddSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save new user role", userRoleAddSaveFailure))
    );

const loadUserRoleEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_LOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.getUserRole(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRoleId'])
                .pipe(
                    map(response => userRoleLoadRequestSuccess(response.userRole, response.userRoleTypes, response.userRoleStatus)),
                    catchError(error => notifyErrorMessage(error, "Failed to load user role", userRoleLoadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load user role", userRoleLoadRequestFailure))
    );

const saveUpdateUserRoleEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_UPDATE_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.updateUserRole(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRole'])
                .pipe(
                    map(response => userRoleUpdateSaveSuccess(response.userRole)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user role", userRoleUpdateSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user role", userRoleUpdateSaveFailure))
    );

const userRoleRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.removeUserRole(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userRoleId'], action['adminNotes'])
                .pipe(
                    map(response => userRoleRemoveRequestSuccess(response.userRole)),
                    catchError(error => notifyError(error, "userRoleRemoveRequestEpic.removeUserRole", userRoleRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleRemoveRequestEpic", userRoleRemoveRequestFailure))
    );

const userRolesEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.getUserRoles(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['page'], action['pageSize'], action['filter'])
                .pipe(
                    map(response => userRolesRequestSuccess(response.userRoles, response.paging, response.filter)),
                    catchError(error => notifyError(error, "userRolesEpic.getUserRoles", userRolesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRolesEpic", userRolesRequestFailure))
    );

const userRoleEnableSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ENABLE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.userRole.userRoles.userRoles.length > 0) {
                return true;
            }
            return false;
        }),
        map((action: any) => userRolesRefresh())
    );

const userRoleUserEnableSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ENABLE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.userRole.user.user.userId > 0) {
                return true;
            }
            return false;
        }),
        map((action: any) => userRoleUserRefresh())
    );

const userRoleRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.getUserRoles(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.userRole.userRoles.paging.page,
                state.userRole.userRoles.paging.pageSize,
                state.userRole.userRoles.filter)
                .pipe(
                    map(response => userRolesRequestSuccess(response.userRoles, response.paging, response.filter)),
                    catchError(error => notifyError(error, "userRoleRefreshEpic.getUserRoles", userRolesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleRefreshEpic", userRolesRequestFailure))
    );

const userRoleUserViewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_USER_VIEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getUserView(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userId'])
                .pipe(
                    map(response => userRolesUserViewRequestSuccess(response.userView)),
                    catchError(error => notifyError(error, "userRoleUserViewRequestEpic.getUserView", userRolesUserViewRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleUserViewRequestEpic", userRolesUserViewRequestFailure))
    );

const userRoleUserRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_USER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getUserDetails(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userId'])
                .pipe(
                    map(response => userRolesUserRequestSuccess(response.user,
                        response.avatar,
                        response.userOtherDocuments,
                        response.userOtherDocumentTypes,
                        response.userComplianceDocuments,
                        response.userComplianceDocumentTypes)),
                    catchError(error => notifyError(error, "userRoleUserRequestEpic.getUserDetails", userRolesUserRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleUserRequestEpic", userRolesUserRequestFailure))
    );

const userRoleUserRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_USER_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getUserDetails(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, state.userRole.user.user.userId)
                .pipe(
                    map(response => userRolesUserRequestSuccess(response.user,
                        response.avatar,
                        response.userOtherDocuments,
                        response.userOtherDocumentTypes,
                        response.userComplianceDocuments,
                        response.userComplianceDocumentTypes)),
                    catchError(error => notifyError(error, "userRoleUserRefreshEpic.getUserDetails", userRolesUserRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRoleUserRefreshEpic", userRolesUserRequestFailure))
    );


const userRolesExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLES_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.export(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.userRole.userRoles.filter)
                .pipe(
                    map(response => userRolesExportRequestSuccess()),
                    catchError(error => notifyError(error, "userRolesExportRequestEpic.export", userRolesExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userRolesExportRequestEpic", userRolesExportRequestFailure))
    );

const userRolesExportRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_ROLES_EXPORT_REQUEST_SUCCESS),
        map(action => printQueueRequest())
    );

const userRoleOtherDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_OTHER_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveForUserOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'], action['documentTypeId'], action['files'])
                .pipe(
                    map(response => userRoleSaveOtherDocumentsSuccess(response.userOtherDocuments)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user other documents", userRoleSaveOtherDocumentsFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user other documents", userRoleSaveOtherDocumentsFailure))
    );

const userRoleOtherDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_OTHER_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeForUserOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'], action['otherDocument'])
                .pipe(
                    map(response => userRoleRemoveOtherDocumentSuccess(response.userOtherDocuments)),
                    catchError(error => notifyErrorMessage(error, "Failed to remove user other document", userRoleRemoveOtherDocumentFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to remove user other document", userRoleRemoveOtherDocumentFailure))
    );

const userRoleComplianceDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveForUserComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'], action['documentTypeId'], action['expiryDate'], action['files'])
                .pipe(
                    map(response => userRoleSaveComplianceDocumentsSuccess(response.userComplianceDocuments)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user compliance documents", userRoleSaveComplianceDocumentsFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user compliance documents", userRoleSaveComplianceDocumentsFailure))
    );

const userRoleComplianceDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeForUserComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'], action['complianceDocument'])
                .pipe(
                    map(response => userRoleRemoveComplianceDocumentSuccess(response.userComplianceDocuments)),
                    catchError(error => notifyErrorMessage(error, "Failed to remove user compliance document", userRoleRemoveComplianceDocumentFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to remove user compliance document", userRoleRemoveComplianceDocumentFailure))
    );

const userRoleSuspendEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLES_SUSPEND_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userRoleServiceApi.userRoleSuspend(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forSecurityFirmId'],
                action['undo'])
                .pipe(
                    map(response => userRolesSuspendSuccess()),
                    catchError(error => notifyErrorMessage(error, "Failed to process user roles", userRolesSuspendFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to process user roles", userRolesSuspendFailure))
    );

const userRoleSuspendSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLES_SUSPEND_SAVE_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) => of(
            notificationSuccessShow("User roles updated")
        ))
    );

export const userRoleEpics = combineEpics(
    roleTypesRequestEpic,
    loadRoleTypeEpic,
    newRoleTypeEpic,
    deleteRoleTypeEpic,
    saveNewRoleTypeEpic,
    saveUpdateRoleTypeEpic,
    newUserRoleEpic,
    saveNewUserRoleEpic,
    loadUserRoleEpic,
    saveUpdateUserRoleEpic,
    userRoleRemoveRequestEpic,
    userRoleRequestDismissRequestEpic,
    userRoleEnableRequestEpic,
    userRolesEpic,
    userRoleEnableSuccessEpic,
    userRoleRefreshEpic,
    userRoleUserViewRequestEpic,
    userRoleUserRequestEpic,
    userRoleUserEnableSuccessEpic,
    userRoleUserRefreshEpic,
    userRolesExportRequestEpic,
    userRolesExportRequestSuccessEpic,
    userRoleOtherDocumentsSaveEpic,
    userRoleOtherDocumentRemoveEpic,
    userRoleComplianceDocumentsSaveEpic,
    userRoleComplianceDocumentRemoveEpic,
    userRoleSuspendEpic,
    userRoleSuspendSuccessEpic
);