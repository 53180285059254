import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as rosterActions from "../actions/roster";

const initialState = {
    rosters: {
        isLoading: false,
        filter: undefined,
        rosters: [],
        error: ""
    },
    leaveRequest: {
        isLoading: false,
        isSaving: false,
        error: "",
        leaveRequest: {}
    }
};


function loadingRosters(state, isLoading) {
    let newState = update(state, {
        rosters: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRosters(state, rosters) {
    let newState = update(state, {
        rosters: {
            rosters: { $set: rosters }
        }
    });
    return newState;
}

function setFilter(state, filter) {
    let newState = update(state, {
        rosters: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        rosters: {
            error: { $set: error }
        }
    });
    return newState;
}

function loadingLeaveRequest(state, isLoading) {
    let newState = update(state, {
        leaveRequest: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingLeaveRequest(state, isSaving) {
    let newState = update(state, {
        leaveRequest: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setLeaveRequest(state, leaveRequest) {
    let newState = update(state, {
        leaveRequest: {
            leaveRequest: { $set: leaveRequest }
        }
    });
    return newState;
}

function setLeaveRequestError(state, error) {
    let newState = update(state, {
        leaveRequest: {
            error: { $set: error }
        }
    });
    return newState;
}

export default function rosters(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case rosterActions.ROSTER_REQUEST:
            return setRosters(setError(loadingRosters(state, true), ""), []);
        case rosterActions.ROSTER_REQUEST_SUCCESS:
            return setRosters(setFilter(loadingRosters(state, false), action.filter), action.rosters);
        case rosterActions.ROSTER_REQUEST_FAILURE:
            return setError(loadingRosters(state, false), action.error);

        case rosterActions.ROSTER_SET_FILTER:
            return setFilter(state, action.filter);

        case rosterActions.ROSTER_NEW_LEAVE_REQUEST:
            return setLeaveRequest(setLeaveRequestError(loadingLeaveRequest(state, true), ""), {});
        case rosterActions.ROSTER_NEW_LEAVE_REQUEST_SUCCESS:
            return setLeaveRequest(loadingLeaveRequest(state, false), action.leaveRequest);
        case rosterActions.ROSTER_NEW_LEAVE_REQUEST_FAILURE:
            return setLeaveRequestError(loadingLeaveRequest(state, false), action.error);

        case rosterActions.ROSTER_SET_LEAVE_REQUEST:
            return setLeaveRequest(state, action.leaveRequest);

        case rosterActions.ROSTER_SAVE_LEAVE_REQUEST:
            return setLeaveRequestError(savingLeaveRequest(state, true), "");
        case rosterActions.ROSTER_SAVE_LEAVE_REQUEST_SUCCESS:
            return setLeaveRequest(savingLeaveRequest(state, false), action.leaveRequest);
        case rosterActions.ROSTER_SAVE_LEAVE_REQUEST_FAILURE:
            return setLeaveRequestError(savingLeaveRequest(state, false), action.error);
    }

    return state;
}