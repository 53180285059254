import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionNotificationCameras from '../components/venue/facialRecognitionNotifications/FacialRecognitionNotificationCameras'
import { 
    facialRecognitionNotificationCamerasRequest, 
    facialRecognitionNotificationCamerasSet,
    facialRecognitionNotificationCamerasSaveRequest,
    facialRecognitionNotificationCameraCreate,
    facialRecognitionNotificationCameraReset,
    facialRecognitionNotificationCameraSet,
    facialRecognitionNotificationCameraSaveRequest,
    facialRecognitionNotificationCameraStatusSaveRequest,
    facialRecognitionNotificationCameraSyncRequest
 } from '../common/actions/facialRecognitionNotification';

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        isSaving: state.facialRecognitionNotification.facialRecognitionCameras.isSaving,
        isLoading: state.facialRecognitionNotification.facialRecognitionCameras.isLoading,
        facialRecognitionCameras: state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionCameras,
        facialRecognitionCamera: state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionCamera,
        facialRecognitionWatchlists: state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionWatchlists,
        venueLocations: state.facialRecognitionNotification.facialRecognitionCameras.venueLocations,
        facialRecognitionServers: state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionServers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (forVenueId) => {
            dispatch(facialRecognitionNotificationCamerasRequest(forVenueId))
        },
        onSet: (facialRecognitionCameras) => {
            dispatch(facialRecognitionNotificationCamerasSet(facialRecognitionCameras))
        },
        onSave: () => {
            dispatch(facialRecognitionNotificationCamerasSaveRequest())
        },
        onCreateCamera: (forVenueId) => {
            dispatch(facialRecognitionNotificationCameraCreate(forVenueId))
        },
        onSetCamera: (facialRecognitionCamera) => {
            dispatch(facialRecognitionNotificationCameraSet(facialRecognitionCamera))
        },
        onResetCamera: () => {
            dispatch(facialRecognitionNotificationCameraReset())
        },
        onSaveCamera: () => {
            dispatch(facialRecognitionNotificationCameraSaveRequest())
        },
        onSaveCameraStatus: (facialRecognitionCameraId, cameraStatus, venueId) => {
            dispatch(facialRecognitionNotificationCameraStatusSaveRequest(facialRecognitionCameraId, cameraStatus, venueId))
        },
        onSyncCameras: (forVenueId) => {
            dispatch(facialRecognitionNotificationCameraSyncRequest(forVenueId))
        }
    }
}

const VenueFacialRecognitionNotificationCamerasContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionNotificationCameras))

export default VenueFacialRecognitionNotificationCamerasContainer