import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITableContainerProps {
    classes: any;
    tableStyle?: any;
    theme: any;
    header: any;
    rows: any;
}

class TableContainer extends React.Component<ITableContainerProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <div style={{width: '100%'}}>
                <table style={this.props.tableStyle} className={classes.tableContainer}>
                    <thead>
                        {this.props.header}
                    </thead>
                    <tbody className={"table-container-body"}>
                        {this.props.rows}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(TableContainer);