import { APPLICATION_BUILD_VERSION } from './version';

export const deviceInfo = {
    get: () => {
        let result = {
            deviceId: "browser",
            os: "pwa",
            model: "",
            make: "",
            version: deviceInfo.sayswho(),
            appVersion: APPLICATION_BUILD_VERSION.toString()
        };
        return result;
    },
    sayswho: () => {
        try {
            var ua = navigator.userAgent;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return M.join(' ');
        } catch (error) {
            return "";
        }
    }
};