import React from 'react';
import { styles } from '../../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../../common/dto/AusComply.dtos";
import ErrorButton from '../../../../controls/ErrorButton';

export interface IVenueSecurityFirmRowProps {
    venueSecurityFirm: AusComplyDtos.VenueSecurityFirm;
    onClick?: Function;
    onDelete?: Function;
    classes: any;
    theme: any;
}

class VenueSecurityFirmRow extends React.Component<IVenueSecurityFirmRowProps, any> {
    constructor(props: IVenueSecurityFirmRowProps) {
        super(props)
        this.onDelete = this.onDelete.bind(this);
    }


    onDelete() {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.venueSecurityFirm)
        }
    }

    render() {
        const { classes } = this.props;
        let item = this.props.venueSecurityFirm;
        let className = classes.rowStatusPending;

        return (
            <tr className={classes.tableTr} key={"row-" + item.venueSecurityFirmId.toString()} >
                <td className={className} ></td>
                <td>
                    <p>
                        <b>{item.securityFirmName}</b>
                    </p>
                </td>
                <td>
                    <p>
                        {item.parentSecurityFirmName}
                    </p>
                </td>
                <td>
                    <p style={{ color: '#aaa' }}>
                        {item.enteredDisplay}
                    </p>
                </td>
                <td>
                    {this.props.onDelete && (
                        <ErrorButton text={"Remove"} onClick={this.onDelete} />
                    )}
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenueSecurityFirmRow);