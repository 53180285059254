export const
  CHECKLIST_RESET = "CHECKLIST_RESET",
  CHECKLIST_FILTER_RESET = "CHECKLIST_FILTER_RESET",
  CHECKLIST_FILTER_SET = "CHECKLIST_FILTER_SET",
  AUTO_SAVE_CURRENT_CHECKLIST_REQUEST = "AUTO_SAVE_CURRENT_CHECKLIST_REQUEST",
  SAVE_CURRENT_CHECKLIST_REQUEST = "SAVE_CURRENT_CHECKLIST_REQUEST",
  UPDATE_CHECKLIST = "UPDATE_CHECKLIST",
  UPDATE_CHECKLIST_DISPLAY_ITEM = "UPDATE_CHECKLIST_DISPLAY_ITEM",
  SUBMIT_CHECKLIST = "SUBMIT_CHECKLIST",
  CREATE_CHECKLIST_REQUEST = "CREATE_CHECKLIST_REQUEST",
  CREATE_CHECKLIST_SUCCESS = "CREATE_CHECKLIST_SUCCESS",
  CREATE_CHECKLIST_FAILURE = "CREATE_CHECKLIST_FAILURE",
  SAVE_CHECKLIST_REQUEST = "SAVE_CHECKLIST_REQUEST",
  SAVE_CHECKLIST_SUCCESS = "SAVE_CHECKLIST_SUCCESS",
  SAVE_CHECKLIST_FAILURE = "SAVE_CHECKLIST_FAILURE",
  LOAD_CHECKLIST_REQUEST = "LOAD_CHECKLIST_REQUEST",
  LOAD_CHECKLIST_SUCCESS = "LOAD_CHECKLIST_SUCCESS",
  LOAD_CHECKLIST_FAILURE = "LOAD_CHECKLIST_FAILURE",
  LOAD_CHECKLISTS_REQUEST = "LOAD_CHECKLISTS_REQUEST",
  LOAD_CHECKLISTS_SUCCESS = "LOAD_CHECKLISTS_SUCCESS",
  LOAD_CHECKLISTS_FAILURE = "LOAD_CHECKLISTS_FAILURE",
  LOAD_NEW_CHECKLIST_REQUEST = "LOAD_NEW_CHECKLIST_REQUEST",
  LOAD_NEW_CHECKLIST_REQUEST_SUCCESS = "LOAD_NEW_CHECKLIST_REQUEST_SUCCESS",
  SET_NEW_CHECKLIST = "SET_NEW_CHECKLIST",
  TOGGLE_CHECKLIST_SELECTED = "TOGGLE_CHECKLIST_SELECTED",
  CLEAR_CHECKLIST_SELECTION = "CLEAR_CHECKLIST_SELECTION",
  SET_CHECKLISTS_PAGE_SIZE = "SET_CHECKLISTS_PAGE_SIZE",
  SET_CHECKLISTS_PAGE = "SET_CHECKLISTS_PAGE",
  SET_CHECKLISTS_SORT_PARAMETERS = "SET_CHECKLISTS_SORT_PARAMETERS",
  ARCHIVE_CHECKLISTS_REQUEST = "ARCHIVE_CHECKLISTS_REQUEST",
  ARCHIVE_CHECKLISTS_SUCCESS = "ARCHIVE_CHECKLISTS_SUCCESS",
  ARCHIVE_CHECKLISTS_FAILURE = "ARCHIVE_CHECKLISTS_FAILURE",
  RESTORE_CHECKLISTS_REQUEST = "RESTORE_CHECKLISTS_REQUEST",
  RESTORE_CHECKLISTS_SUCCESS = "RESTORE_CHECKLISTS_SUCCESS",
  RESTORE_CHECKLISTS_FAILURE = "RESTORE_CHECKLISTS_FAILURE",
  APPROVE_CHECKLISTS_REQUEST = "APPROVE_CHECKLISTS_REQUEST",
  APPROVE_CHECKLISTS_SUCCESS = "APPROVE_CHECKLISTS_SUCCESS",
  APPROVE_CHECKLISTS_FAILURE = "APPROVE_CHECKLISTS_FAILURE",
  CLOSE_CHECKLISTS_REQUEST = "CLOSE_CHECKLISTS_REQUEST",
  CLOSE_CHECKLISTS_SUCCESS = "CLOSE_CHECKLISTS_SUCCESS",
  CLOSE_CHECKLISTS_FAILURE = "CLOSE_CHECKLISTS_FAILURE",
  SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST = "SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST",
  SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS = "SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS",
  SAVE_CHECKLIST_ITEM_ATTACHMENT_FAILURE = "SAVE_CHECKLIST_ITEM_ATTACHMENT_FAILURE",
  DELETE_CHECKLIST_ITEM_ATTACHMENT_REQUEST = "DELETE_CHECKLIST_ITEM_ATTACHMENT_REQUEST",
  UPDATE_CHECKLIST_STATUS_REQUEST = "UPDATE_CHECKLIST_STATUS_REQUEST",
  UPDATE_CHECKLIST_STATUS_SUCCESS = "UPDATE_CHECKLIST_STATUS_SUCCESS",
  UPDATE_CHECKLIST_STATUS_FAILURE = "UPDATE_CHECKLIST_STATUS_FAILURE",
  CHECKLIST_ITEM_UPLOAD_REQUEST = "CHECKLIST_ITEM_UPLOAD_REQUEST",
  CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS = "CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS",
  CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE = "CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE";

export function checklistsReset() {
  return {
    type: CHECKLIST_RESET
  }
}

export function checklistFilterReset() {
  return {
    type: CHECKLIST_FILTER_RESET
  }
}

export function checklistFilterSet(filter) {
  return {
    type: CHECKLIST_FILTER_SET,
    filter
  }
}

export function submitChecklist() {
  return {
    type: SUBMIT_CHECKLIST
  }
}

export function autoSaveCurrentChecklistRequest() {
  return {
    type: AUTO_SAVE_CURRENT_CHECKLIST_REQUEST
  };
}

export function saveCurrentChecklistRequest() {
  return {
    type: SAVE_CURRENT_CHECKLIST_REQUEST
  };
}

export function updateChecklist(checklist) {
  return {
    type: UPDATE_CHECKLIST,
    checklist
  }
}

export function updateChecklistDisplayItem(item) {
  return {
    type: UPDATE_CHECKLIST_DISPLAY_ITEM,
    item
  }
}

export function setNewChecklist(checklist) {
  return {
    type: SET_NEW_CHECKLIST,
    checklist
  }
}

export function saveChecklistRequest(checklist) {
  return {
    type: SAVE_CHECKLIST_REQUEST,
    checklist
  };
}

export function saveChecklistSuccess(checklist, attachmentsChanged) {
  return {
    type: SAVE_CHECKLIST_SUCCESS,
    checklist,
    attachmentsChanged
  };
}

export function saveChecklistFailure(responseStatus) {
  return {
    type: SAVE_CHECKLIST_FAILURE,
    responseStatus
  };
}

export function createChecklistRequest(checklistTemplateId) {
  return {
    type: CREATE_CHECKLIST_REQUEST,
    checklistTemplateId
  }
}

export function createChecklistSuccess(checklist, checklistTemplate) {
  return {
    type: CREATE_CHECKLIST_SUCCESS,
    checklist, 
    checklistTemplate
  };
}

export function createChecklistFailure(responseStatus) {
  return {
    type: CREATE_CHECKLIST_FAILURE,
    responseStatus
  };
}

export function loadChecklistRequest(checklistId) {
  return {
    type: LOAD_CHECKLIST_REQUEST,
    checklistId
  };
}

export function loadChecklistSuccess(checklist, checklistTemplate) {
  return {
    type: LOAD_CHECKLIST_SUCCESS,
    checklist, 
    checklistTemplate
  };
}

export function loadChecklistFailure(responseStatus) {
  return {
    type: LOAD_CHECKLIST_FAILURE,
    responseStatus
  };
}

export function loadChecklistsRequest(
  searchText,
  checklistStatuses,
  startDate,
  endDate
) {
  return {
    type: LOAD_CHECKLISTS_REQUEST,
    searchText,
    checklistStatuses,
    startDate,
    endDate
  };
}

export function loadChecklistsSuccess(checklists, paging, filter) {
  return {
    type: LOAD_CHECKLISTS_SUCCESS,
    checklists,
    paging,
    filter
  };
}

export function loadChecklistsFailure(responseStatus) {
  return {
    type: LOAD_CHECKLISTS_FAILURE,
    responseStatus
  };
}

export function loadNewChecklistRequest(checklistTemplateId) {
  return {
    type: LOAD_NEW_CHECKLIST_REQUEST,
    checklistTemplateId
  };
}

export function loadNewChecklistRequestSuccess(checklistTemplate) {
  return {
    type: LOAD_NEW_CHECKLIST_REQUEST_SUCCESS,
    checklistTemplate
  };
}

export function toggleChecklistSelected(checklistId) {
  return {
    type: TOGGLE_CHECKLIST_SELECTED,
    checklistId
  };
}
export function clearChecklistSelection() {
  return {
    type: CLEAR_CHECKLIST_SELECTION
  };
}

export function setChecklistsPageSize(pageSize) {
  return {
    type: SET_CHECKLISTS_PAGE_SIZE,
    pageSize
  };
}

export function setChecklistsPage(page) {
  return {
    type: SET_CHECKLISTS_PAGE,
    page
  };
}

export function setChecklistsSortParameters(sortBy, sortDirection) {
  return {
    type: SET_CHECKLISTS_SORT_PARAMETERS,
    sortBy,
    sortDirection
  };
}

export function archiveChecklistsRequest(checklistIds) {
  return {
    type: ARCHIVE_CHECKLISTS_REQUEST,
    checklistIds
  };
}

export function archiveChecklistsSuccess() {
  return {
    type: ARCHIVE_CHECKLISTS_SUCCESS
  };
}

export function archiveChecklistsFailure(responseStatus) {
  return {
    type: ARCHIVE_CHECKLISTS_FAILURE,
    responseStatus
  };
}

export function restoreChecklistsRequest(checklistIds) {
  return {
    type: RESTORE_CHECKLISTS_REQUEST,
    checklistIds
  };
}

export function restoreChecklistsSuccess() {
  return {
    type: RESTORE_CHECKLISTS_SUCCESS
  };
}

export function restoreChecklistsFailure(responseStatus) {
  return {
    type: RESTORE_CHECKLISTS_FAILURE,
    responseStatus
  };
}

export function approveChecklistsRequest(checklistIds) {
  return {
    type: APPROVE_CHECKLISTS_REQUEST,
    checklistIds
  };
}

export function approveChecklistsSuccess() {
  return {
    type: APPROVE_CHECKLISTS_SUCCESS
  };
}

export function approveChecklistsFailure(responseStatus) {
  return {
    type: APPROVE_CHECKLISTS_FAILURE,
    responseStatus
  };
}

export function updateChecklistStatusRequest(checklist, status) {
  return {
    type: UPDATE_CHECKLIST_STATUS_REQUEST,
    checklist,
    status
  };
}

export function updateChecklistStatusSuccess() {
  return {
    type: UPDATE_CHECKLIST_STATUS_SUCCESS
  };
}

export function updateChecklistStatusFailure(responseStatus) {
  return {
    type: UPDATE_CHECKLIST_STATUS_FAILURE,
    responseStatus
  };
}

export function closeChecklistsRequest(checklistIds) {
  return {
    type: CLOSE_CHECKLISTS_REQUEST,
    checklistIds
  };
}

export function closeChecklistsSuccess() {
  return {
    type: CLOSE_CHECKLISTS_SUCCESS
  };
}

export function closeChecklistsFailure(responseStatus) {
  return {
    type: CLOSE_CHECKLISTS_FAILURE,
    responseStatus
  };
}

export function saveChecklistItemAttachmentRequest(
  checklistTemplateItemId,
  attachment,
  file
) {
  return {
    type: SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST,
    checklistTemplateItemId,
    attachment,
    file
  };
}
export function saveChecklistItemAttachmentSuccess(
  checklistTemplateItemId,
  attachment,
  file
) {
  return {
    type: SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS,
    checklistTemplateItemId,
    attachment,
    file
  };
}
export function saveChecklistItemAttachmentFailure(
  responseStatus,
  checklistTemplateItemId,
  file
) {
  return {
    type: SAVE_CHECKLIST_ITEM_ATTACHMENT_FAILURE,
    responseStatus,
    checklistTemplateItemId,
    file
  };
}

export function deleteChecklistItemAttachment(
  checklistTemplateItemId,
  attachmentId
) {
  return {
    type: DELETE_CHECKLIST_ITEM_ATTACHMENT_REQUEST,
    checklistTemplateItemId,
    attachmentId
  };
}

export function checklistItemUploadRequest(files, checklistTemplateItemId) {
  return {
      type: CHECKLIST_ITEM_UPLOAD_REQUEST,
      files,
      checklistTemplateItemId
  }
}

export function checklistItemUploadRequestSuccess(temporaryFiles, checklistTemplateItemId) {
  return {
      type: CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
      temporaryFiles,
      checklistTemplateItemId
  }
}

export function checklistItemUploadRequestFailure(error) {
  return {
      type: CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE,
      error
  }
}