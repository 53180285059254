import { connect } from 'react-redux'
import MasterPage from '../components/layout/MasterPage'

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        isUserLoading: state.user.isLoading,
        awaitingInit: state.adminDashboard === undefined || state.dashboard == undefined // wait for the merge of re-hydration of state
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const MasterPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterPage)

export default MasterPageContainer