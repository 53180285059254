
import { logging } from '../../utilities/logging';

export const commonLogic = {
    trackException(error: any, location: string, props: any) {
        logging.trackError(error, location);
    },
    uniqueNumber(seed: number) {
        let now = new Date();
        let timestamp = 
            now.getHours().toString().padStart(2, "0") +
            now.getMinutes().toString().padStart(2, "0") +
            now.getSeconds().toString().padStart(2, "0") +
            now.getMilliseconds().toString().padEnd(4,"0")
        return Number(timestamp) + seed;
        //return Number((new Date()).toISOString().replace(/\D/g,'')) + seed;
    }
};