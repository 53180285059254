import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueContactServiceApi } from '../services/venueContact';

import {
    VENUE_CONTACTS_REQUEST,
    venueContactsRequest,
    venueContactsRequestSuccess,
    venueContactsRequestFailure,
    VENUE_CONTACT_REQUEST,
    venueContactRequestSuccess,
    venueContactRequestFailure,
    VENUE_CONTACT_CREATE_REQUEST,
    venueContactCreateRequestSuccess,
    venueContactCreateRequestFailure,
    VENUE_CONTACT_UPSERT_REQUEST,
    venueContactUpsertRequestSuccess,
    venueContactUpsertRequestFailure,
    VENUE_CONTACT_UPSERT_REQUEST_SUCCESS,
    venueContactReset,
    VENUE_CONTACTS_REFRESH,
    venueContactsRefresh
} from '../actions/venue';
import {
    notifyError
} from './common';

const venueContactsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueContactServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => venueContactsRequestSuccess(response.venueContacts, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueContactsRequestEpic.find", venueContactsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueContactsRequestEpic", venueContactsRequestFailure))
    );

const venueContactsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACTS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueContactServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueContacts.paging.page,
                state.venue.venueContacts.paging.pageSize,
                state.venue.venueContacts.filter)
                .pipe(
                    map(response => venueContactsRequestSuccess(response.venueContacts, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueContactsRefreshEpic.find", venueContactsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueContactsRefreshEpic", venueContactsRequestFailure))
    );

const venueContactRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueContactServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueContactId'])
                .pipe(
                    map(response => venueContactRequestSuccess(response.venueContact, response.venueContactTypes, response.availableUsers)),
                    catchError(error => notifyError(error, "venueContactRequestEpic.get", venueContactRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueContactRequestEpic", venueContactRequestFailure))
    );

const venueContactCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueContactServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueContactCreateRequestSuccess(response.venueContact, response.venueContactTypes, response.availableUsers)),
                    catchError(error => notifyError(error, "venueContactCreateEpic.create", venueContactCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueContactCreateEpic", venueContactCreateRequestFailure))
    );

const venueContactUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACT_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueContactServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueContact.venueContact)
                .pipe(
                    map(response => venueContactUpsertRequestSuccess(response.venueContact)),
                    catchError(error => notifyError(error, "venueContactUpsertEpic.upsert", venueContactUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueContactUpsertEpic", venueContactUpsertRequestFailure))
    );

const venueContactUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueContactReset())
    );

const venueContactUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_CONTACT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueContactsRefresh())
    );

export const venueContactEpics = combineEpics(
    venueContactsRefreshEpic,
    venueContactsRequestEpic,
    venueContactRequestEpic,
    venueContactCreateEpic,
    venueContactUpsertEpic,
    venueContactUpsertSuccessEpic,
    venueContactUpsertSuccessRefreshEpic
);