
import React from 'react';

const ChecklistIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M13.57,18.11l-6.96,9.13l-2.94-3.05c-0.81-0.86-2.17-0.91-3.05-0.11c-0.84,0.88-0.84,2.27,0,3.15l4.46,4.57c0.26,0.38,0.94,0.67,1.52,0.65c0.57-0.01,1.12-0.24,1.52-0.65l8.48-10.54c0.84-0.77,0.9-2.08,0.13-2.92c-0.03-0.03-0.08-0.09-0.13-0.13C15.8,17.36,14.45,17.31,13.57,18.11" />
            <path d="M47.83,25.39H22.82c-1.2,0-2.17,0.97-2.17,2.17c0,1.2,0.97,2.17,2.17,2.17h25.01c1.2,0,2.17-0.97,2.17-2.17C50,26.36,49.03,25.39,47.83,25.39" />
            <path d="M13.57,0.71L6.61,9.84L3.68,6.8C2.87,5.94,1.51,5.89,0.63,6.69c-0.84,0.88-0.84,2.27,0,3.15l4.46,4.57c0.26,0.38,0.94,0.67,1.52,0.65c0.57-0.01,1.12-0.24,1.52-0.65l8.48-10.54c0.84-0.77,0.9-2.08,0.13-2.92c-0.03-0.03-0.08-0.09-0.13-0.13C15.8-0.04,14.45-0.09,13.57,0.71" />
            <path d="M47.83,7.99H22.82c-1.2,0-2.17,0.97-2.17,2.17s0.97,2.17,2.17,2.17h25.01c1.2,0,2.17-0.97,2.17-2.17S49.03,7.99,47.83,7.99" />
            <path d="M13.57,35.51l-6.96,9.13l-2.94-3.05c-0.81-0.86-2.17-0.91-3.05-0.11c-0.84,0.88-0.84,2.27,0,3.15l4.46,4.57c0.26,0.38,0.94,0.67,1.52,0.65c0.57-0.01,1.12-0.24,1.52-0.65l8.48-10.54c0.84-0.77,0.9-2.08,0.13-2.92c-0.03-0.03-0.08-0.09-0.13-0.13C15.8,34.76,14.45,34.71,13.57,35.51" />
            <path d="M47.83,42.79H22.82c-1.2,0-2.17,0.97-2.17,2.17s0.97,2.17,2.17,2.17h25.01c1.2,0,2.17-0.97,2.17-2.17S49.03,42.79,47.83,42.79" />
        </g>
    </svg>
);

export default ChecklistIcon;
