

import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface IControlTextProps {
    theme: any;
    classes: any;
    text?: string;
}

class ControlText extends React.PureComponent<IControlTextProps, any> {
    render() {
        const { theme, classes } = this.props;
        return <Box p={0} display="flex" flexDirection={'column'} style={{height: "100%"}}>
            <Box flexGrow={1} p={0}>
            </Box>
            <Box flexGrow={0} p={0}>
            <label className={classes.controlText} style={{padding: 0}}>{this.props.text}</label>
            </Box>
            <Box flexGrow={1} p={0}>
            </Box>
        </Box>;
    }
}

export default withStyles(styles, { withTheme: true })(ControlText);