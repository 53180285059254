import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import DefaultButton from '../../controls/DefaultButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '../../layout/TableContainer';
import TableRow from '../../common/TableRow';
import SelectControl from '../../controls/SelectControl';
import Box from '@material-ui/core/Box';
import TextControl from '../../controls/TextControl';
import PrimaryButton from '../../controls/PrimaryButton';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import NoRowsMessage from '../../common/NoRowsMessage';
import TransparentButton from '../../controls/TransparentButton';
import View from '../../common/View';
import { commonLogic } from '../../../common/logic/common';

export interface IChecklistTemplateItemFiltersProps {
    checklistTemplateItem: AusComplyDTOs.ChecklistTemplateItem;
    checklistTemplateItems: AusComplyDTOs.ChecklistTemplateItem[],
    onUpdate: Function;
    theme: any;
    classes: any;
}

interface IChecklistTemplateItemFiltersState {
    filters: AusComplyDTOs.ChecklistTemplateItemFilter[];
    available: AusComplyDTOs.ChecklistTemplateItem[];
}

class ChecklistTemplateItemFilters extends Component<IChecklistTemplateItemFiltersProps, IChecklistTemplateItemFiltersState> {
    constructor(props: IChecklistTemplateItemFiltersProps) {
        super(props)
        this.state = {
            filters: [],
            available: []
        }
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onItemValueChanged = this.onItemValueChanged.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(): void {
        this.build();
    }

    componentDidUpdate(prevProps: Readonly<IChecklistTemplateItemFiltersProps>, prevState: Readonly<IChecklistTemplateItemFiltersState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.checklistTemplateItems) !== JSON.stringify(this.props.checklistTemplateItems)) {
            this.build();
        } else if (JSON.stringify(prevProps.checklistTemplateItem) !== JSON.stringify(this.props.checklistTemplateItem)) {
            this.build();
        }
    }

    build() {
        const { checklistTemplateItems, checklistTemplateItem } = this.props;

        let id: number = 0;
        let filters: AusComplyDTOs.ChecklistTemplateItemFilter[] = [];
        if (checklistTemplateItem) {
            id = checklistTemplateItem.checklistTemplateItemId;
            if (checklistTemplateItem.checklistTemplateItemFilters) {
                filters = [...checklistTemplateItem.checklistTemplateItemFilters];
            }
        }
        // get the available items that can be made dependant on
        let available: AusComplyDTOs.ChecklistTemplateItem[] = [];
        if (checklistTemplateItems && checklistTemplateItems.length > 0) {
            available = checklistTemplateItems.filter(f =>
                (f.checklistTemplateItemId !== id) && (
                    f.checklistItemType == AusComplyDTOs.ChecklistItemType.TextBox ||
                    f.checklistItemType == AusComplyDTOs.ChecklistItemType.Checkbox ||
                    f.checklistItemType == AusComplyDTOs.ChecklistItemType.Question ||
                    f.checklistItemType == AusComplyDTOs.ChecklistItemType.Selection ||
                    f.checklistItemType == AusComplyDTOs.ChecklistItemType.MultipleChoice));
        }

        this.setState({ available, filters });
    }

    /*
     seed: number) {
        let date = new Date();
        let dateString = moment(date).format("DDHHmmss");
        let dateNumber = Number(dateString);
        let offset = (new Date().getUTCMilliseconds()) * 10;
        return dateNumber + offset + seed;
    }
    */

    onAdd() {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        var filter = new AusComplyDTOs.ChecklistTemplateItemFilter();
        filter.operator = AusComplyDTOs.ngtOperator.Equal;
        checklistTemplateItem.checklistTemplateItemFilters = [
            ...checklistTemplateItem.checklistTemplateItemFilters,
            filter
        ];
        this.props.onUpdate(checklistTemplateItem);
    }

    onRemove(index) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        let filters = [...checklistTemplateItem.checklistTemplateItemFilters];
        filters.splice(index, 1);
        checklistTemplateItem.checklistTemplateItemFilters = [...filters];
        this.props.onUpdate(checklistTemplateItem);
    }

    onItemValueChanged(index: number, fieldName: string, value) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        checklistTemplateItem.checklistTemplateItemFilters[index][fieldName] = value;
        this.props.onUpdate(checklistTemplateItem);
    }

    handleChange = (index, value, isSelected) => {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        let currentValue = checklistTemplateItem.checklistTemplateItemFilters[index].value;
        if (!currentValue) currentValue = "";
        let filterValues = currentValue.split(",");

        if (isSelected) {
            filterValues.push(value);
        } else {
            filterValues = filterValues.filter(f => f !== value);
        }

        checklistTemplateItem.checklistTemplateItemFilters[index].value = filterValues.join(",");
        this.props.onUpdate(checklistTemplateItem);
    };

    checkboxOptions() {
        let option1 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option1.value = "Checked";
        option1.name = "Checked";
        option1.rank = 1;
        option1.temporaryId = 1;

        let option2 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option2.value = "UnChecked";
        option2.name = "UnChecked";
        option2.rank = 2;
        option2.temporaryId = 2;

        let options: AusComplyDTOs.ChecklistTemplateItemOption[] = [option1, option2];
        return options;
    }

    renderOptions(index: number, checklistTemplateItemFilter: AusComplyDTOs.ChecklistTemplateItemFilter) {
        const { available } = this.state;
        if (checklistTemplateItemFilter && checklistTemplateItemFilter.parentChecklistTemplateItemId != 0) {
            // find the item
            var parentChecklistTemplateItem = available.find(f => f.checklistTemplateItemId == checklistTemplateItemFilter.parentChecklistTemplateItemId);
            if (parentChecklistTemplateItem) {
                if (parentChecklistTemplateItem.checklistItemType == AusComplyDTOs.ChecklistItemType.TextBox) {
                    return <TextControl
                        value={checklistTemplateItemFilter.value || ''}
                        onChange={value => this.onItemValueChanged(index, "value", value)}
                        onBlur={value => this.onItemValueChanged(index, "value", value)} />
                } else {
                    let options = parentChecklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Checkbox ?
                        this.checkboxOptions() : parentChecklistTemplateItem.checklistTemplateItemOptions;

                    if (!options || options.length === 0) {
                        return (<></>);
                    }

                    let optionButtons = options.map((item, indexOption) => {
                        let selected: boolean = false;
                        let value = checklistTemplateItemFilter.value ? checklistTemplateItemFilter.value : "";
                        if (value.indexOf(item.value) > -1) {
                            selected = true;
                        }

                        return (
                            <View key={item.value} style={{ paddingRight: "5px", paddingBottom: "5px", minWidth: '140px' }}>
                                {selected && (
                                    <PrimaryButton noWrap={true} text={item.name} onClick={() => this.handleChange(index, item.value, false)} />
                                )}
                                {!selected && (
                                    <TransparentButton noWrap={true} text={item.name} onClick={() => this.handleChange(index, item.value, true)} />
                                )}
                            </View>
                        )
                    });
                    return <div
                        style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', flexWrap: 'wrap' }}
                    >
                        {optionButtons}
                    </div>
                }

            }
        }
        return <></>
    }

    render() {
        const { checklistTemplateItem, classes } = this.props;
        const { available, filters } = this.state;

        if (!checklistTemplateItem) {
            return (<></>);
        }
        /*
        if (checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Group &&
            checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.TextBox &&
            checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Checkbox &&
            checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Question &&
            checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Selection &&
            checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.MultipleChoice) {
            return (<></>);
        }*/

        let actions;
        let content;
        if (checklistTemplateItem) {
            actions = <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}>
                </Box>
                <Box p={0}>
                    <DefaultButton onClick={this.onAdd} text="Add" />
                </Box>
            </Box>

            if (filters && filters.filter(f => !f.obsolete).length > 0) {
                let rows = filters.map((item, index) => {
                    if (item.obsolete) return <></>;
                    
                    return (
                        <TableRow>
                            <td>
                                <SelectControl
                                    onChange={(value) => this.onItemValueChanged(index, "parentChecklistTemplateItemId", value)}
                                    defaultValue={item.parentChecklistTemplateItemId}>
                                    <option ></option>
                                    {available.map((availableItem, availableItemIndex) =>
                                        <option key={availableItem.checklistTemplateItemId} value={availableItem.checklistTemplateItemId}>{availableItem.name}</option>
                                    )}
                                </SelectControl>
                            </td>
                            <td>
                                {this.renderOptions(index, item)}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <TransparentButton onClick={() => this.onRemove(index)} style={{ width: 'auto' }} text={"Remove"}></TransparentButton>
                            </td>
                        </TableRow>
                    );
                });
                content = (
                    <TableContainer
                        header={
                            <tr>
                                <th className={classes.tableHrIndicator}></th>
                                <th className={classes.tableHr}>When Item</th>
                                <th className={classes.tableHr}>Has Value</th>
                                <th className={classes.tableHr}></th>
                            </tr>
                        }
                        rows={rows}
                    />
                );
            } else {
                content = <NoRowsMessage message={"Always"} />;
            }
        }

        return (
            <CardContainer
                title={"Show"}>
                <CardContent>
                    {content}
                    {actions}
                </CardContent>
            </CardContainer>

        );
    }
}

export default withStyles(styles, { withTheme: true })(ChecklistTemplateItemFilters)