
import { connect } from 'react-redux'
import ProfileEmailConfirmation from '../components/profile/ProfileEmailConfirmation'
import { userEmailConfirmRequest } from '../common/actions/userLogin'
import { userDetailsRefresh } from '../common/actions/userLogin';

const mapStateToProps = (state, props) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        userId: props.match.params.userId, 
        emailConfirmationGuid: props.match.params.emailConfirmationGuid,
        isConfirming: state.user.isConfirming,
        error: state.user.confirmationError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEmailConfirmation: (userId, emailConfirmationGuid, password) => {
            dispatch(userEmailConfirmRequest(userId, emailConfirmationGuid, password))
        },
        onUserDetailsRefresh: () => {
            dispatch(userDetailsRefresh())
        }
    }
}

const ProfileEmailConfirmationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileEmailConfirmation)

export default ProfileEmailConfirmationContainer