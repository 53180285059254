import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICardLabelProps {
    theme: any;
    text?: string;
    style?: any;
}

class CardLabel extends React.PureComponent<ICardLabelProps, any> {
    render() {
        return (
            <p style={{ color: this.props.theme.custom.label,
             margin: '1px 0px', textAlign: 'center', 
             fontSize: '12px', padding: '0', ...this.props.style }}>
                {this.props.text}{this.props.children}
            </p>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardLabel);