import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUserRoleRequestHeaderProps {
    classes: any;
}

class UserRoleRequestHeader extends React.Component<IUserRoleRequestHeaderProps, any> {
    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr} style={{width: '180px'}}>Type</th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr} style={{width: '100px'}}>Request Date</th>
                <th className={classes.tableHr} style={{width: '180px'}}>Status</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRequestHeader);