import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import ResponsiveList from '../../common/ResponsiveList';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import CashTraxEventContainer from '../../../containers/CashTraxEventContainer';
import { KeyName } from '../../../common/dto/common';
import SimpleFilter from '../../common/SimpleFilter';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import View from '../../common/View';

export interface IExternalApiConnectionWebhookMessagesProps {
    entityType: AusComplyDtos.ngtEntityType;
    isLoading: boolean;
    isSaving: boolean;
    venueId: number;
    externalApiConnectionWebhookMessages: AusComplyDtos.ExternalApiConnectionWebhookMessage[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    onLoad: Function;
    onReProcess: Function;
    history: any;
}

export default class ExternalApiConnectionWebhookMessages extends Component<IExternalApiConnectionWebhookMessagesProps, any> {
    constructor(props: IExternalApiConnectionWebhookMessagesProps) {
        super(props)

        this.state = {
            apiMessage: "",
            rawMessage: ""
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.setState({ rawMessage: "" });
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps: Readonly<IExternalApiConnectionWebhookMessagesProps>, prevState: Readonly<any>, snapshot?: any): void {
        if(prevProps.isSaving && !this.props.isSaving) {
            this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
        }
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
        }
        preparedFilter.entityType = this.props.entityType;
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(externalEventMessageAudit: AusComplyDtos.ExternalEventMessageAudit) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "reprocess", name: "Re-process" });
        return commands;
    }

    onCommand(command: string, externalApiConnectionWebhookMessage: AusComplyDtos.ExternalApiConnectionWebhookMessage) {
        switch (command) {
            case "edit":
                this.setState({ rawMessage: externalApiConnectionWebhookMessage.rawMessage, apiMessage: externalApiConnectionWebhookMessage.apiMessage })
                break;
            case "reprocess":
                this.props.onReProcess(this.props.entityType, externalApiConnectionWebhookMessage.externalApiConnectionWebhookMessageId);
            default:
                break;
        }
    }

    render() {
        let self = this;

        return (<PageLayout
            headerText={""}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
        >
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.externalApiConnectionWebhookMessages}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Date", "Message", "Data"]}
                columns={["dateEnteredDisplay", "display", "rawDisplay"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                onGetLink={(columnName, rowData) => {
                    if (columnName == "createdEntityDisplay" && rowData["createdEntityType"] == "Incident") {
                        return "/incidentview/" + rowData["createdEntityId"];
                    }
                    return "";
                }}
                canEdit={true}
            />
            <FullPageDialog open={this.state.rawMessage !== ""}
                title="Edit" onDismissed={() => this.setState({ rawMessage: "" })}>
                <TextDisplayControlGroup
                    text='Process Message'
                    value={this.state.apiMessage}
                />
                <TextDisplayControlGroup
                    text='Raw Message'
                    value={this.state.rawMessage}
                />
            </FullPageDialog>
        </PageLayout>);
    }
}