import React, { Component, forwardRef } from 'react';
import PrivacyPolicyContainer from '../../containers/PrivacyPolicyContainer';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import TransparentButton from '../controls/TransparentButton';

const BackLink = forwardRef((props, ref) => <Link to="/" {...props} />);

export default class LoginPrivacyPolicy extends Component<any, any> {

    render() {
        return (
            <div style={{ padding: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{display: 'inline-block'}}>
                            <TransparentButton component={BackLink} text="Back" />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <PrivacyPolicyContainer style={{ margin: '10px' }} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}