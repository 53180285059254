import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption } from '../../../common/dto/common';
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import RadioControl from '../../controls/RadioControl';

export interface IRegisterUserToProps {
    user: AusComplyDTOs.User;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserTo extends Component<IRegisterUserToProps, any> {
    constructor(props: IRegisterUserToProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldName, value) {
        let user = { ...this.props.user };
        user[fieldName] = value;
        this.props.onUpdate(user);
    }

    render() {
        let options: RadioOption[] = [
            { id: AusComplyDTOs.ngtRegisterUserTo.Venue, name: "Venue" },
            { id: AusComplyDTOs.ngtRegisterUserTo.Security, name: "Security Firm" },
            { id: AusComplyDTOs.ngtRegisterUserTo.None, name: "None"}
        ];

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Register to"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <RadioControl
                                        selected={this.props.user.registerUserTo}
                                        options={options}
                                        name={"registerUserTo"}
                                        onChange={(value) => this.onChange("registerUserTo", value.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}