import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from "../../controls/ControlGroup";
import { RadioOption } from '../../../common/dto/common';
import RadioControl from '../../controls/RadioControl';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IFacialRecognitionEventTypeProps {
    theme: any;
    classes: any;
    value: AusComplyDtos.ngtFacialRecognitionEventType;
    readonly?: boolean;
    onChanged: Function;
}

class FacialRecognitionEventType extends React.PureComponent<IFacialRecognitionEventTypeProps, any> {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (value.value == "1") {
            this.props.onChanged(AusComplyDtos.ngtFacialRecognitionEventType.Age);
        }
        else {
            this.props.onChanged(AusComplyDtos.ngtFacialRecognitionEventType.Watchlist);
        }
    }

    render() {
        let options: RadioOption[] = [
            { id: "0", name: "On a Watchlist" },
            { id: "1", name: "Between an age range" }
        ];
        const selected = this.props.value === AusComplyDtos.ngtFacialRecognitionEventType.Age ? "1" : "0";


        return (
            <ControlGroup text={"Appearance is"}>
                <RadioControl readonly={this.props.readonly} selected={selected} options={options} name={"appearanceon"} onChange={(val) => this.onChange(val)} />
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionEventType);