import React from "react";

const UploadIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 58 58"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <circle fill={'none'} stroke={fill} strokeWidth={3} cx="29" cy="29" r="27.5" />
            </g>
            <polygon fill={fill} points="31.1,12.8 29,10.7 26.9,12.8 17.7,22 19.8,24.1 29,14.9 38.2,24.1 40.3,22 " />
            <rect x="27" y="14.9" fill={fill} width="4" height="21.4" />
            <rect x="18.3" y="42" fill={fill} width="21.4" height="3" />
        </svg>
    );

export default UploadIcon;
