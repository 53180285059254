import React from 'react';
import { Incident } from '../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';


export interface IIncidentSummaryCardProps {
    incident: Incident
}

export default class IncidentSummaryCard extends React.Component<IIncidentSummaryCardProps, any> {

    render() {
        return (
            <Card style={{marginTop: '6px'}}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <CardTypography><b>{this.props.incident.incidentType}</b></CardTypography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <CardTypography>{this.props.incident.incidentDateTimeShortString}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CardTypography style={{ fontStyle: 'italic' }}>{this.props.incident.locations}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CardTypography style={{ fontStyle: 'italic' }}>{this.props.incident.categories}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CardTypography >{this.props.incident.summary}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <CardTypography>{this.props.incident.lastModifiedByUser}</CardTypography>
                        </Grid>
                    </Grid>
                </CardContent>
                
            </Card>
        );
    }
}