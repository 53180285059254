import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MultiSelectControl from './MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';

export interface IMultiSelectControlGroupProps {
    text?: string;
    error?: string;
    items?: MulitSelectOption[];
    label?: string;
    searchText?: string;
    minimumCharactersRequired?: number;
    onSelectItemsChanged?: Function
    readonly?: boolean;
    labelAbove?: boolean;
    canShowAll?: boolean;
}
class MultiSelectControlGroup extends React.PureComponent<IMultiSelectControlGroupProps, any> {

    constructor(props: IMultiSelectControlGroupProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: ""
        }
    }

    componentDidMount() {
        this.setState({
            
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    onChange(event) {
        
    }

    render() {
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <MultiSelectControl 
                        items={this.props.items}
                        label={this.props.label}
                        searchText={this.props.searchText}
                        minimumCharactersRequired={this.props.minimumCharactersRequired}
                        onSelectItemsChanged={this.props.onSelectItemsChanged} 
                        canShowAll={this.props.canShowAll}
                        />
                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default MultiSelectControlGroup;