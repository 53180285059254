import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import PageTitle from '../common/PageTitle';
import ControlGroup from '../controls/ControlGroup';
import TextDisplay from '../controls/TextDisplay';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import Map from '../common/Map';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import DefaultFab from '../common/DefaultFab';
import RegisterVenueDetails from '../register/venue/RegisterVenueDetails';
import RegisterVenueAddress from '../register/venue/RegisterVenueAddress';
import RegisterVenueType from '../register/venue/RegisterVenueType';
import DefaultButton from '../controls/DefaultButton';
import TransparentButton from '../controls/TransparentButton';
import Grid from '@material-ui/core/Grid';

export interface IVenueEditProps {
    venueId: number;
    isLoading: boolean;
    isSaving: boolean;
    canEdit: boolean;
    canEditVenueIndustryCategory: boolean;
    venue: AusComplyDtos.Venue;
    states: AusComplyDtos.State[],
    industryCategories: AusComplyDtos.IndustryCategory[],
    precincts: AusComplyDtos.VenuePrecinct[],
    licenceTypes: AusComplyDtos.VenueLicenceType[],
    tradingHours: AusComplyDtos.VenueTradingHours[],
    error: string;
    errors: any;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class VenueEdit extends Component<IVenueEditProps, any> {
    constructor(props: IVenueEditProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.venueId);
                this.props.history.push('/venue');
            }
        }
    }
    
    onValueChanged(fieldName: string, value: any) {
        let venue = { ...this.props.venue };
        venue[fieldName] = value;
        this.props.onUpdate(venue);
    }

    onUpdate(venue) {
        this.props.onUpdate(venue);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onLoad(this.props.venueId);
        this.props.history.push('/venue');
    }

    render() {
        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    fullWidth={true}
                    header={<PageTitle title={"Edit Venue"}></PageTitle>}
                >
                    <form>
                        <RegisterVenueDetails venue={this.props.venue} errors={this.props.errors} onUpdate={this.onUpdate}  />
                        <RegisterVenueAddress venue={this.props.venue} canMoveMarker={false} states={this.props.states} errors={this.props.errors} onUpdate={this.onUpdate}  />
                        <RegisterVenueType 
                            venue={this.props.venue} 
                            industryCategories={this.props.industryCategories}
                            precincts={this.props.precincts}
                            licenceTypes={this.props.licenceTypes}
                            tradingHours={this.props.tradingHours}
                            canEditVenueIndustryCategory={this.props.canEditVenueIndustryCategory}
                            errors={this.props.errors} 
                            onUpdate={this.onUpdate}   />
                        
                        
                        <ControlGroup text={" "}>
                            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <TransparentButton text={"Cancel"} onClick={this.onCancel} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</DefaultButton>
                                </Grid>
                            </Grid>
                            
                        </ControlGroup>
                    </form>
                </LayoutForm>
                <VenueQuickActionsContainer>
                </VenueQuickActionsContainer>
            </>
        );
    }
}