import React, { Component } from 'react';
import TransparentButton from '../controls/TransparentButton';
import View from '../common/View';

export interface IDirectoryShowEmptyToggleProps {
    show: boolean;
    onChange: Function;
}

export default class DirectoryShowEmptyToggle extends Component<IDirectoryShowEmptyToggleProps, any> {
    constructor(props: IDirectoryShowEmptyToggleProps) {
        super(props)
/*
        let options: RadioOption[] = [
            { id: "show", name: "Show Empty" },
            { id: "hide", name: "Hide Empty" }
        ];

        this.state = {
            current: "show",
            options
        }
        this.onChange = this.onChange.bind(this);
        */
    }
    /*
    componentDidMount() {
        this.setState({
            current: this.props.show ? "show" : "hide"
        });
    }

    componentDidUpdate(prevProps, preState, snapshot) {
        if (this.props.show != prevProps.show) {
            this.setState({
                current: this.props.show ? "show" : "hide"
            });
        }
    }

    onChange(value) {
        this.setState({ current: value }, () => {
            let show = value == "show";
            this.props.onChange(show)
        });
    }*/

    render() {
        return <>
            <View style={{ marginBottom: "10px" }}>
                <TransparentButton
                    compact={true}
                    onClick={() => this.props.onChange(!this.props.show)}
                    text={this.props.show ? "Hide empty folders" : "Show empty folders"} />
            </View>
        </>
/*
        return (
            <RadioButtonControl
                selected={this.state.current}
                options={this.state.options}
                name={"show"}
                onChange={(value) => this.onChange(value.value)} />
        );
        */
    }
}