import React from "react";

const HamburgerIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 30 23"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path fill={fill} d="M28.14,3.81H1.86C0.83,3.81,0,2.95,0,1.9S0.83,0,1.86,0h26.28C29.17,0,30,0.85,30,1.9S29.17,3.81,28.14,3.81z" />
        <path fill={fill} d="M28.14,13.41H1.86C0.83,13.41,0,12.56,0,11.5s0.83-1.9,1.86-1.9h26.28c1.03,0,1.86,0.85,1.86,1.9S29.17,13.41,28.14,13.41z" />
        <path fill={fill} d="M28.14,23H1.86C0.83,23,0,22.15,0,21.1c0-1.05,0.83-1.9,1.86-1.9h26.28c1.03,0,1.86,0.85,1.86,1.9C30,22.15,29.17,23,28.14,23z" />
    </svg>
);

export default HamburgerIcon;
