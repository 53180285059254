import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FileUpload from '../../common/FileUpload';
import TransparentButton from '../../controls/TransparentButton';
import ProfileAvatar from '../../profile/ProfileAvatar';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

export interface IRegisterUserAvatarProps {
    avatar: AusComplyDtos.File;
    onUpdate: Function;
    classes: any;
    theme: any;
}

class RegisterUserAvatar extends React.Component<IRegisterUserAvatarProps, any> {
    constructor(props: IRegisterUserAvatarProps) {
        super(props)
        this.remove = this.remove.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    remove() {
        this.props.onUpdate(new AusComplyDtos.File());
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        let images = temporaryFiles.filter(t => t.isImage);
        if (images.length > 0) {
            let file = images[0];
            this.props.onUpdate(file);
        }
    }

    render() {
        const { classes, theme } = this.props;

        return (
            <>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">

                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box p={1}>
                        <Box display="flex" flexDirection="column" justifyContent="center" style={{height: '100%', position: 'relative'}}>
                            <Box p={1}  style={{position: 'relative'}}>
                                <FileUpload onFileWasUploaded={this.fileWasUploaded} buttonModeIcon={
                                    <CameraAltIcon style={{ fontSize: '1.5em', color: '#888' }} />
                                } />
                            </Box>
                        </Box>
                    </Box>
                    <Box p={1}>
                        <ProfileAvatar avatar={this.props.avatar} style={{ width: '70px', height: '70px' }} />
                    </Box>
                    <Box p={1}>
                        <Box display="flex" flexDirection="column" justifyContent="center" flexWrap="wrap" style={{height: '100%'}}>
                            <Box p={1}>
                                <TransparentButton style={{ width: '100%', height: '100%' }} onClick={this.remove}>
                                    <DeleteOutlinedIcon style={{ fontSize: '1.5em', color: '#888' }} />
                                </TransparentButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(RegisterUserAvatar);