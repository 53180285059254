import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const venueSubscriptionServiceApi = {
  get: (venueId: number, securityFirmId: number, forVenueId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueSubscription();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.get(query);
    return from(request);
  },
  getSummary: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueSubscriptionSummary();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  save:(venueId: number, securityFirmId: number, venueSubscription: AusComplyDtos.VenueSubscription) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostVenueSubscription();
      query.venueId = venueId;
      query.securityFirmId = securityFirmId;
      query.venueSubscription = venueSubscription;
      const request = client.post(query);
      return from(request);
  }
};
