import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const incidentCategoryTypeApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentCategoryTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  newEmpty: (venueId, securityFirmId, forVenueId, forSecurityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentCategoryTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  addOrUpdate: (venueId, securityFirmId, incidentCategoryType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentCategoryType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentCategoryTypeId = incidentCategoryType.incidentCategoryTypeId;
    query.incidentCategoryType = incidentCategoryType;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, incidentCategoryTypeId, specific) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentCategoryTypeById();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentCategoryTypeId = incidentCategoryTypeId;
    query.specific = specific;
    const request = client.get(query);
    return from(request);
  },
  hide: (venueId, securityFirmId, incidentCategoryTypeId, hide, forVenueId, forSecurityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentCategoryTypeHide();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentCategoryTypeId = incidentCategoryTypeId;
    query.hide = hide;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  usage: (venueId, securityFirmId, incidentTypeId, entryPage, reportingPeriodId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentCategoryTypeUsageByReportingPeriod();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentTypeId = incidentTypeId;
    query.entryPage = entryPage;
    query.reportingPeriodId = reportingPeriodId;
    const request = client.get(query);
    return from(request);
  }
};
