import React, { Component } from 'react';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import PageTitle from '../common/PageTitle';
import Loading from '../common/Loading';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BusinessIcon from '@material-ui/icons/Business';
import Box from '@material-ui/core/Box';
import NoRowsMessage from '../common/NoRowsMessage';
import PageLayout from '../layout/PageLayout';

export interface ISystemLogsProps {
    notifications: AusComplyDtos.Notification[];
    isLoading: boolean;
    onLoad: Function;
    venueId?: number;
    securityFirmId?: number;
}

export default class SystemLog extends Component<ISystemLogsProps, any> {
    constructor(props: ISystemLogsProps) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, this.props.securityFirmId);
    }

    render() {
        let title = "System Log";
        if (this.props.securityFirmId) {
            title = "Security System Log";
        } else if (this.props.venueId) {
            title = "Venue System Log";
        }
        return (
            <PageLayout
                headerText={title}
                loading={this.props.isLoading}
            >
                {(!this.props.isLoading && this.props.notifications.length == 0) && (
                    <NoRowsMessage message={"No notifications"} />
                )}
                {(!this.props.isLoading && this.props.notifications.length > 0) && (
                    <>
                        <div style={{ display: "block", width: '100%', position: 'relative' }}>
                            {this.props.notifications.map((notification, index) =>
                                <Box
                                    key={"notification-" + notification.notificationId.toString()}
                                    display="flex" flexDirection="row" justifyContent="center" style={{ borderRadius: '5px', backgroundColor: '#061570', width: '100%', marginBottom: '10px' }}>
                                    <Box p={2}>
                                        {notification.notificationCategoryId == 1 && (
                                            <PermIdentityIcon style={{ color: '#888' }} />
                                        )}
                                        {notification.notificationCategoryId == 2 && (
                                            <BusinessIcon style={{ color: '#888' }} />
                                        )}
                                        {notification.notificationCategoryId == 3 && (
                                            <BusinessIcon style={{ color: '#888' }} />
                                        )}
                                        {notification.notificationCategoryId == 4 && (
                                            <AssignmentIcon style={{ color: '#888' }} />
                                        )}
                                    </Box>
                                    <Box p={2} flexGrow={1}>
                                        <Box display="flex" flexDirection="column">
                                            <Box p={0}>
                                                <p style={{ margin: '2px 0', color: 'white' }}>
                                                    <span style={{ color: '#888', marginRight: '10px' }}>{notification.dateTimeFormatted}</span>
                                                    {notification.message}
                                                </p>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </div>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" style={{ marginTop: '10px' }}>
                            <Box p={1} >
                                <PermIdentityIcon style={{ color: '#888' }} />
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>User</p>
                            </Box>
                            <Box p={1} >
                                <AssignmentIcon style={{ color: '#888' }} />
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>Incident</p>
                            </Box>
                            <Box p={1} >
                                <BusinessIcon style={{ color: '#888' }} />
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>Other</p>
                            </Box>
                        </Box>
                    </>
                )}
            </PageLayout>
        );
    }
}