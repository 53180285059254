import { connect } from 'react-redux'
import VenueSecurityFirmSelection from '../components/home/VenueSecurityFirmSelection'
import { userDetailsVenueSecurityFirmCancelChange } from '../common/actions/userLogin'
import { venueSecurityFirmLoadAvailable, venueSecurityFirmAvailableSelectVenue, venueSecurityFirmSelect, venueSecurityFirmAvailableClearVenue } from '../common/actions/venueSecurityFirm'
import { locationRequest } from '../common/actions/location';

const mapStateToProps = state => {
    return {
        venueId: state.venueSecurityFirm.venueId,
        venueEventId: state.venueSecurityFirm.venueEventId,
        canSelectNoVenue: state.venueSecurityFirm.canSelectNoVenue,
        isLoading: state.venueSecurityFirm.isLoading,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        venues: state.venueSecurityFirm.venues,
        securityFirms: state.venueSecurityFirm.securityFirms
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancelChangeVenue: () =>{
            dispatch(userDetailsVenueSecurityFirmCancelChange())
        },
        onClearVenue: () => {
            dispatch(venueSecurityFirmAvailableClearVenue())
        },
        onRefresh: () => {
            dispatch(venueSecurityFirmLoadAvailable())
        },
        onSelectVenue: venue => {
            dispatch(venueSecurityFirmAvailableSelectVenue(venue))
        },
        onSelectVenueSecurityFirm: (venueId, securityFirmId, venueEventId) => {
            dispatch(venueSecurityFirmSelect(venueId, securityFirmId, venueEventId))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
    }
}

const VenueSecurityFirmSelectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueSecurityFirmSelection)

export default VenueSecurityFirmSelectionContainer