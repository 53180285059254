import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import PageTitle from '../common/PageTitle';
import ControlGroup from '../controls/ControlGroup';
import TextDisplay from '../controls/TextDisplay';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import Map from '../common/Map';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import DefaultFab from '../common/DefaultFab';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';

export interface IVenueDetailsProps {
    venueId: number;
    isLoading: boolean;
    canEdit: boolean;
    canAdminEdit: boolean;
    venue: AusComplyDtos.Venue;
    onLoad: Function;
    history: any;
}

export default class VenueDetails extends Component<IVenueDetailsProps, any> {
    constructor(props: IVenueDetailsProps) {
        super(props)
        this.state = {
        };
        this.onEdit = this.onEdit.bind(this);
        this.onAdminEdit = this.onAdminEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    onEdit() {
        this.props.history.push('/venue/edit');
    }

    onAdminEdit() {
        this.props.history.push('/admin/venue/' + this.props.venueId);
    }

    render() {
        var editVenueLink;
        if (this.props.canEdit) {
            editVenueLink =(
                <TransparentButton onClick={() => this.onEdit()} text={"Edit Venue"} />
            );
        }
        var adminVenueLink;
        if (this.props.canAdminEdit) {
            adminVenueLink =(
                <TransparentButton onClick={() => this.onAdminEdit()} text={"Admin"} />
            );
        }
        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={false}
                    error={""}
                    footerLeftContent={editVenueLink}
                    footerRightContent={adminVenueLink}
                    headerText={"Venue"}
                >
                        <ControlGroup text="Name">
                            <TextDisplay>{this.props.venue.name}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="ID">
                            <TextDisplay>{this.props.venue.venueId}</TextDisplay>
                        </ControlGroup>
                        {this.props.venue.address && (
                            <ControlGroup text="Address">
                                <TextDisplay>{this.props.venue.address}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.suburb && (
                            <ControlGroup text="Suburb">
                                <TextDisplay>{this.props.venue.suburb}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.stateDisplayName && (
                            <ControlGroup text="State">
                                <TextDisplay>{this.props.venue.stateDisplayName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.postcode && (
                            <ControlGroup text="Postcode">
                                <TextDisplay>{this.props.venue.postcode}</TextDisplay>
                            </ControlGroup>
                        )}
                        {(this.props.venue.latitude && this.props.venue.longitude) && (
                            <ControlGroup text=" ">
                                <Map hideByDefault={true} latitude={this.props.venue.latitude} longitude={this.props.venue.longitude} />
                            </ControlGroup>
                        )}
                        {this.props.venue.phone && (
                            <ControlGroup text="Phone">
                                <TextDisplay>{this.props.venue.phone}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.website && (
                            <ControlGroup text="Website">
                                <TextDisplay>{this.props.venue.website}</TextDisplay>
                            </ControlGroup>
                        )}
                        <CheckboxControlGroup label=" " text={"Sole Trader"} defaultValue={this.props.venue.soleTrader} readonly={true} />
                        {this.props.venue.liquorLicenceNumber && (
                            <ControlGroup text="Liquor Licence #">
                                <TextDisplay>{this.props.venue.liquorLicenceNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.liquorLicenceReviewDateDisplay && (
                            <ControlGroup text="Licence Review Date">
                                <TextDisplay>{this.props.venue.liquorLicenceReviewDateDisplay}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.venueLicenceTypeName && (
                            <ControlGroup text="Licence Type">
                                <TextDisplay>{this.props.venue.venueLicenceTypeName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.patronCapacity && (
                            <ControlGroup text="Average Patronage">
                                <TextDisplay>{this.props.venue.patronCapacity}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.venuePrecinctName && (
                            <ControlGroup text="Precinct">
                                <TextDisplay>{this.props.venue.venuePrecinctName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.venue.venueTradingHoursName && (
                            <ControlGroup text="End of Trading Hours">
                                <TextDisplay>{this.props.venue.venueTradingHoursName}</TextDisplay>
                            </ControlGroup>
                        )}
                </PageLayout>
            </>
        );
    }
}