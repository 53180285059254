import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { groupLogic } from '../../../common/logic/groupLogic';
import LayoutForm from '../../layout/LayoutForm';
import SubTitle from '../../common/SubTitle';
import PageTitle from '../../common/PageTitle';
import ResponsiveList from '../../common/ResponsiveList';
import PageLayout from '../../layout/PageLayout';

export interface IVenueGroupsProps {
    venueId: number;
    isLoading: boolean;
    venue: AusComplyDtos.Venue;
    onLoad: Function;
    onRequestAccess: Function;
    isSaving: boolean;
    history: any;
}

export default class VenueGroups extends Component<IVenueGroupsProps, any> {
    constructor(props: IVenueGroupsProps) {
        super(props)
        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            this.props.onLoad(this.props.venueId);
        }
    }

    onCommand(command: string, groupVenue: AusComplyDtos.GroupVenue) {
        switch (command) {
            case "dashboard":
                this.props.history.push('/groups/' + groupVenue.groupId);
                break;
            case "request":
                this.props.onRequestAccess(groupVenue.groupId);
                break;
            default:
                break;
        }
    }

    render() {

        let venueGroups;

        if (this.props.venue.industryGroups && this.props.venue.industryGroups.length > 0) {
            const industryGroups = this.props.venue.industryGroups;
            venueGroups = (
                <>
                    <SubTitle text={"Industry Groups"} />
                    <ResponsiveList
                        data={industryGroups}
                        headers={["Name"]}
                        columns={["name"]}
                        getCommands={item => []}
                        onCommand={command => { }}
                        isDanger={item => item.obsolete}
                        canEdit={false}
                    />
                </>
            );
        }

        return (
            <PageLayout
                loading={this.props.isLoading}
                saving={false}
                error={""}
                headerText={"Venue Groups"}
            >
                {this.props.venue.groups && (
                    <ResponsiveList
                        loading={this.props.isLoading}
                        data={this.props.venue.groups}
                        headers={["Name", "Status"]}
                        columns={["group.name", "groupVenueStatus.name"]}
                        getCommands={item => groupLogic.getVenueGroupCommands(item)}
                        onCommand={this.onCommand}
                        isDanger={item => item.obsolete}
                        canEdit={true}
                    />
                )}
                {venueGroups}
            </PageLayout>
        );
    }
}