import { connect } from 'react-redux' ;
import PrintQueueButton from '../components/print/PrintQueueButton';
import * as printActions from '../common/actions/print';

const mapStateToProps = (state, props) => {
    return {
        moreCommands: props.moreCommands,
        onCommand: props.onCommand,
        queue: state.print.queue
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRefresh:() => {
            dispatch(printActions.printQueueRequest())
        },
        onDownloaded:(reportGenerationId) => {
            dispatch(printActions.printQueueDownloadRequest(reportGenerationId))
        },
        onDismissed:(reportGenerationId) => {
            dispatch(printActions.printQueueDismissRequest(reportGenerationId))
        },
        onDismissAll:() => {
            dispatch(printActions.printQueueDismissAllRequest())
        }
    }
}

const PrintQueueButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintQueueButton)

export default PrintQueueButtonContainer