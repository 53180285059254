import { connect } from 'react-redux' ;
import Welcome from '../components/home/Welcome';
import {  userDetailsRefresh } from '../common/actions/userLogin';
import { profileResendVerificationEmailRequest } from '../common/actions/profile';

const mapStateToProps = state => {
    return {
        hasUserRoleRequests: state.user.details && state.user.details.userRoleRequests && state.user.details.userRoleRequests.length > 0,
        verified: state.user.details && state.user.details.userSession && state.user.details.userSession.user.verified
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (venueId, securityFirmId) =>{
            dispatch(userDetailsRefresh())
        },
        onRequestEmailVerification: () => {
            dispatch(profileResendVerificationEmailRequest())
        }
    }
}

const WelcomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Welcome)

export default WelcomeContainer