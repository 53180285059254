import React from "react";

const DashboardIcon = ({
    name = "",
    style = {},
    fill = "#808080",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 50 50"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M19.9,17.6H6.1c-1.8,0-3.2-1.4-3.2-3.2V6.1c0-1.8,1.4-3.2,3.2-3.2h13.8c1.8,0,3.2,1.4,3.2,3.2v8.3
                    C23.2,16.2,21.7,17.6,19.9,17.6L19.9,17.6z M6.1,5.6c-0.3,0-0.5,0.2-0.5,0.5v8.3c0,0.3,0.2,0.5,0.5,0.5h13.8c0.3,0,0.5-0.2,0.5-0.5
                    V6.1c0-0.3-0.2-0.5-0.5-0.5H6.1z M6.1,5.6"/>
                <path fill={fill} d="M19.9,47.1H6.1c-1.8,0-3.2-1.4-3.2-3.2V24.5c0-1.8,1.4-3.2,3.2-3.2h13.8c1.8,0,3.2,1.4,3.2,3.2v19.4
                    C23.2,45.7,21.7,47.1,19.9,47.1L19.9,47.1z M6.1,24.1c-0.3,0-0.5,0.2-0.5,0.5v19.4c0,0.3,0.2,0.5,0.5,0.5h13.8
                    c0.3,0,0.5-0.2,0.5-0.5V24.5c0-0.3-0.2-0.5-0.5-0.5H6.1z M6.1,24.1"/>
                <path fill={fill} d="M43.9,47.1H30.1c-1.8,0-3.2-1.4-3.2-3.2v-8.3c0-1.8,1.4-3.2,3.2-3.2h13.8c1.8,0,3.2,1.4,3.2,3.2v8.3
                    C47.1,45.7,45.7,47.1,43.9,47.1L43.9,47.1z M30.1,35.1c-0.3,0-0.5,0.2-0.5,0.5v8.3c0,0.3,0.2,0.5,0.5,0.5h13.8
                    c0.3,0,0.5-0.2,0.5-0.5v-8.3c0-0.3-0.2-0.5-0.5-0.5H30.1z M30.1,35.1"/>
                <path fill={fill} d="M43.9,28.7H30.1c-1.8,0-3.2-1.4-3.2-3.2V6.1c0-1.8,1.4-3.2,3.2-3.2h13.8c1.8,0,3.2,1.4,3.2,3.2v19.4
                    C47.1,27.2,45.7,28.7,43.9,28.7L43.9,28.7z M30.1,5.6c-0.3,0-0.5,0.2-0.5,0.5v19.4c0,0.3,0.2,0.5,0.5,0.5h13.8
                    c0.3,0,0.5-0.2,0.5-0.5V6.1c0-0.3-0.2-0.5-0.5-0.5H30.1z M30.1,5.6"/>
            </g>
        </svg>
    );

export default DashboardIcon;
