import { connect } from 'react-redux'
import LoginForgotPassword from '../components/login/LoginForgotPassword'
import { userLoginForgotPasswordRequest, userLoginResetCode, userLoginPasswordResetCodeRequest, userLoginValidatePasswordResetCodeRequest, userLoginSetMobile, userLoginSetCode } from '../common/actions/userLogin'

const mapStateToProps = state => {
    return {
        resetMobile: state.user.resetMobile,
        resetCode: state.user.resetCode,
        resetLoading: state.user.resetLoading,
        isCodeValid: state.user.isCodeValid
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onForgotPassword: (username) =>{
            dispatch(userLoginForgotPasswordRequest(username))
        },
        onRequestCode: (mobile) => {
            dispatch(userLoginPasswordResetCodeRequest(mobile))
        },
        onValidateCode: (mobile, code) => {
            dispatch(userLoginValidatePasswordResetCodeRequest(mobile, code))
        },
        onSetCodeInvalid: () => {
            dispatch(userLoginResetCode())
        },
        onSetMobile: (mobile) => {
            dispatch(userLoginSetMobile(mobile))
        },
        onSetCode: (code) => {
            dispatch(userLoginSetCode(code))
        }
    }
}

const LoginForgotPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForgotPassword)

export default LoginForgotPasswordContainer