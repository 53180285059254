import React from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import CardRow from '../common/CardRow';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { KeyName } from '../../common/dto/common';
import { Palette } from '../../common/constants/palette';

export interface IRosterItemProps {
    rosterItem: AusComplyDtos.RosterItem;
    commands?: KeyName[];
    onCommand?: Function;
    style?: any;
    cardContentStyle?: any;
    classes: any;
    theme: any;
}

class RosterItem extends React.Component<IRosterItemProps, any> {
    constructor(props: IRosterItemProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command);
        }
    }

    render() {
        const { classes } = this.props;
        const textColor = this.props.rosterItem.isCurrentSelection ? Palette.Text : Palette.ControlBorder;

        return (
            <CardRow
                //isDanger={this.props.rosterItem.rosterType === AusComplyDtos.ngtRosterItemType.PendingLeave}
                //isInfo={this.props.rosterItem.rosterType === AusComplyDtos.ngtRosterItemType.Leave}
                //isObsolete={this.props.isObsolete}
                //isSuccess={this.props.isSuccess}
                //isWarning={this.props.isWarning}
                commands={this.props.commands}
                onCommand={this.props.onCommand}
                cardContentStyle={this.props.cardContentStyle}
            >
                <p style={{padding: '5px', margin: 0, color: textColor}}>{this.props.rosterItem.description}</p>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RosterItem);