import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const notificationTriggerServiceApi = {
    get: request.makeGet(
        AusComplyDtos.GetNotificationTrigger,
        'venueId', 'securityFirmId', 'notificationTriggerParentType', 'parentId', 'forGroupId', 'forVenueId', 'forSecurityFirmId')
    ,
    upsert: request.makePost(
        AusComplyDtos.PostNotificationTrigger,
        'venueId', 'securityFirmId', 'notificationTrigger')
};
