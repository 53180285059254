import { connect } from 'react-redux'
import RoleTypes from '../components/admin/roleTypes/RoleTypes'
import { withRouter } from "react-router";
 import { 
    roleTypesRequest, 
    roleTypesFilterSet
 } from '../common/actions/userRole';
import { loadIndustryCategoriesRequest } from '../common/actions/referenceData';

const mapStateToProps = state => {
    return {
        isLoading: state.userRole.roleTypes.isLoading,
        roleTypes: state.userRole.roleTypes.roleTypes,
        paging: state.userRole.roleTypes.paging,
        filter: state.userRole.roleTypes.filter,
        industryCategories: state.referenceData.industryCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(roleTypesRequest(filter, page, pageSize))
        },
        onFilterUpdate:(filter) => {
            dispatch(roleTypesFilterSet(filter))
        },
        onLoadReferenceData:() => {
            dispatch(loadIndustryCategoriesRequest());
        }
    }
}

const AdminRoleTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleTypes))

export default AdminRoleTypesContainer