import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SubTitle from '../../common/SubTitle';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import VenueSecurityFirmAssociationContainer from '../../../containers/VenueSecurityFirmAssociationContainer';
import ResponsiveList from '../../common/ResponsiveList';
import { KeyName } from '../../../common/dto/common';

export interface ISecurityFirmAssociationsProps {
    isLoading: boolean;
    venueId: number;
    venueSecurityFirmId: number;
    VenueSecurityFirmSummaries: AusComplyDtos.VenueSecurityFirmSummary[];
    paging: AusComplyDtos.Paging;
    canCreate: boolean;
    canRemove: boolean;
    onLoad: Function;
    onCreate: Function;
    onReset: Function;
    onRemove: Function;
    onRestore: Function;
    canRestore: boolean;
}

export default class SecurityFirmAssociations extends Component<ISecurityFirmAssociationsProps, any> {
    constructor(props: ISecurityFirmAssociationsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            itemToRemove: {},
            confirmRestore: false,
            itemToRestore: {}
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.restoreCancel = this.restoreCancel.bind(this);
        this.restoreConfirmed = this.restoreConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, -1, this.props.paging.page);
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.venueId, this.props.paging.pageSize, page);
    }

    getCommands(venueSecurityFirm: AusComplyDtos.VenueSecurityFirm) {
        var commands: KeyName[] = [];
        if (this.props.canRemove && !venueSecurityFirm.obsolete) {
            commands.push({ key: "delete", name: "Remove" });
        }
        if (this.props.canRestore && venueSecurityFirm.obsolete) {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, venueSecurityFirm: AusComplyDtos.VenueSecurityFirm) {
        switch (command) {
            case "delete":
                var updated = { ...venueSecurityFirm };
                this.setState({
                    confirmRemove: true,
                    itemToRemove: venueSecurityFirm
                });
                break;
            case "restore":
                var updated = { ...venueSecurityFirm };
                this.setState({
                    confirmRestore: true,
                    itemToRestore: updated
                });
                break;
            default:
                break;
        }
    }

    onCreate() {
        if (this.props.onCreate) {
            this.props.onCreate(this.props.venueId);
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            itemToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onRemove) {
            let itemToRemove = { ...this.state.itemToRemove };
            this.props.onRemove(itemToRemove.venueSecurityFirmId);
            this.setState({
                confirmRemove: false,
                itemToRemove: {}
            });
        }
    }

    restoreCancel() {
        this.setState({
            confirmRestore: false,
            itemToRestore: {}
        });
    }

    restoreConfirmed() {
        if (this.props.onRestore) {
            let itemToRestore = { ...this.state.itemToRestore };
            this.props.onRestore(itemToRestore.venueSecurityFirmId);
            this.setState({
                confirmRestore: false,
                itemToRestore: {}
            });
        }
    }

    render() {
        
        let create;
        if (this.props.canCreate) {
            create = (
                <TransparentButton
                    onClick={() => this.onCreate()}
                    text={"Add Security Firm Association"} />
            );
        }

        let content = <ResponsiveList
            loading={this.props.isLoading}
            data={this.props.VenueSecurityFirmSummaries}
            headers={["Name", "Master Licence", "Suburb", "State", "Postcode", "Phone", "Manager(s)", "Website", "Created", "Modified"]}
            columns={["securityFirm", "masterLicenceNumber", "suburb", "stateDisplayName", "postcode", "phone", "managerName", "website", "dateEnteredDisplay", "dateModifiedDisplay"]}
            getCommands={item => this.getCommands(item)}
            onCommand={(command, item) => this.onCommand(command, item)}
            isObsolete={item => item.obsolete}
            toggleObsolete={this.props.canRestore}
        />

        return (<PageLayout
            headerText={"Security Firm Associations"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            <SubTitle text="Register Specific" />
            {content}
            <VenueSecurityFirmAssociationContainer open={this.props.venueSecurityFirmId > -1} onClosed={() => this.props.onReset()} />
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this Security Firm assocation?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
            <ConfirmDialog
                title={"Confirm restore"}
                text={"Are you sure you wish to restore this Security Firm assocation?"}
                show={this.state.confirmRestore}
                onCancel={this.restoreCancel}
                onConfirm={this.restoreConfirmed} />
        </PageLayout>);
    }
}