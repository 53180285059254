import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const securityFirmSubscriptionServiceApi = {
  get: (venueId: number, securityFirmId: number, forSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmSubscription();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.get(query);
    return from(request);
  },
  getSummary: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmSubscriptionSummary();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  save:(venueId: number, securityFirmId: number, securityFirmSubscription: AusComplyDtos.SecurityFirmSubscription) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostSecurityFirmSubscription();
      query.venueId = venueId;
      query.securityFirmId = securityFirmId;
      query.securityFirmSubscription = securityFirmSubscription;
      const request = client.post(query);
      return from(request);
  }
};
