import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface IVenueContactProps {
    venueContact: AusComplyDtos.VenueContact;
    venueContactTypes: AusComplyDtos.VenueContactType[];
    venueUsers: AusComplyDtos.UserSelection[];
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IVenueContactState {
}

export default class VenueContact extends Component<IVenueContactProps, IVenueContactState> {
    constructor(props: IVenueContactProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {

    }
    
    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let venueContact = { ...this.props.venueContact };
        venueContact[fieldName] = value;
        this.props.onUpdate(venueContact)
    }

    render() {

        if (!this.props.venueContact) {
            return (<></>);
        }

        let venueContactTypes;
        if (this.props.venueContactTypes && this.props.venueContactTypes.length > 1) {
            venueContactTypes = (
                <SelectControlGroup
                    text="Contact Type"
                    onChange={(value) => this.onValueChanged("venueContactTypeId", Number(value))}
                    defaultValue={this.props.venueContact.venueContactTypeId}>
                    <option key={0} value={0}>Select...</option>
                    {this.props.venueContactTypes.map((item, index) => (
                        <option key={item.venueContactTypeId} value={item.venueContactTypeId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let venueUsers;
        if (this.props.venueUsers && this.props.venueUsers.length > 1) {
            venueUsers = (
                <SelectControlGroup
                    text="User"
                    onChange={(value) => this.onValueChanged("userId", Number(value))}
                    defaultValue={this.props.venueContact.userId}>
                    <option key={0} value={0}>Other (specify)</option>
                    {this.props.venueUsers.map((item, index) => (
                        <option key={item.userId} value={item.userId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let secondaryContact;
        if(!this.props.venueContact.primaryContact) {
            secondaryContact = (
                <CheckboxControlGroup
                    text={"Secondary Contact"}
                    defaultValue={this.props.venueContact.secondaryContact}
                    onChanged={(value) => this.onValueChanged("secondaryContact", value)} />
            );
        }

        let details;
        if (!this.props.venueContact.userId || this.props.venueContact.userId === 0)
        {
            details = (
                <>
                    <TextControlGroup
                        text={"First name"}
                        defaultValue={this.props.venueContact.firstname}
                        onBlur={(value) => this.onValueChanged("firstname", value)} />

                    <TextControlGroup
                        text={"Last name"}
                        defaultValue={this.props.venueContact.lastname}
                        onBlur={(value) => this.onValueChanged("lastname", value)} />
                    
                    <TextControlGroup
                        text={"Phone"}
                        defaultValue={this.props.venueContact.phone}
                        onBlur={(value) => this.onValueChanged("phone", value)} />

                    <TextControlGroup
                        text={"Email"}
                        defaultValue={this.props.venueContact.email}
                        onBlur={(value) => this.onValueChanged("email", value)} />
                </>
            );
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    { venueContactTypes }
                    { venueUsers }
                    { details }
                    { secondaryContact }
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}