import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import YesNoControlGroup from '../../controls/YesNoControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import { venueLogic } from '../../../common/logic/venueLogic';


export interface IRegisterVenueContactProps {
    venue: AusComplyDTOs.Venue;
    venueContact: AusComplyDTOs.VenueContact;
    licenseContact: AusComplyDTOs.VenueContact;
    errors: any;
    onUpdate: Function;
    onUpdateVenueContact: Function;
    onUpdateLicenseContact: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterVenueContact extends Component<IRegisterVenueContactProps, any> {
    constructor(props: IRegisterVenueContactProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let venue = { ...this.props.venue };
        venue[fieldname] = value;
        this.props.onUpdate(venue);
    }

    onChangeVenueContact(fieldname, value) {
        if (fieldname === "obsolete" && value) {
            let licenseContact = { ...this.props.licenseContact };
            licenseContact.primaryContact = false;
            this.props.onUpdateLicenseContact(licenseContact);
        }
        let venueContact = { ...this.props.venueContact };
        venueContact[fieldname] = value;
        this.props.onUpdateVenueContact(venueContact);
    }

    onChangeLicenseContact(fieldname, value) {
        if (fieldname === "primaryContact" && value) {
            let venueContact = { ...this.props.venueContact };
            venueContact.obsolete = true;
            this.props.onUpdateVenueContact(venueContact);
        }
        let licenseContact = { ...this.props.licenseContact };
        licenseContact[fieldname] = value;
        this.props.onUpdateLicenseContact(licenseContact);
    }

    render() {
        if (!this.props.licenseContact || !this.props.venueContact) {
            return <></>;
        }

        let licenseContact;
        let licenseContactDetails;

        licenseContact = (
            <YesNoControlGroup
                name="obsolete"
                text={"Are you the licensee?"}
                value={this.props.licenseContact.obsolete}
                //reverse={true}
                onChanged={(value) => this.onChangeLicenseContact("obsolete", value)}
            />
        );
        if (venueLogic.registerVenueShowLicenseeContactDetails(this.props.venueContact, this.props.licenseContact)) { //if (!this.props.licenseContact.obsolete) {
            licenseContactDetails = (
                <>
                    <TextControlGroup
                        text={"Licensee First Name"}
                        defaultValue={this.props.licenseContact.firstname}
                        error={this.props.errors["licenseContact.firstname"]}
                        onBlur={(value) => this.onChangeLicenseContact("firstname", value)}
                    />
                    <TextControlGroup
                        text={"Licensee Last Name"}
                        defaultValue={this.props.licenseContact.lastname}
                        error={this.props.errors["licenseContact.lastname"]}
                        onBlur={(value) => this.onChangeLicenseContact("lastname", value)}
                    />
                    <TextControlGroup
                        text={"Licensee Phone"}
                        defaultValue={this.props.licenseContact.phone}
                        error={this.props.errors["licenseContact.phone"]}
                        onBlur={(value) => this.onChangeLicenseContact("phone", value)}
                    />
                    <TextControlGroup
                        text={"Licensee Email Address"}
                        defaultValue={this.props.licenseContact.email}
                        error={this.props.errors["licenseContact.email"]}
                        onBlur={(value) => this.onChangeLicenseContact("email", value)}
                    />
                </>
            );
        }

        let venueContact;
        let venueContactIsLicencee;
        let venueContactDetails;
        venueContact = (
            <YesNoControlGroup
                name="obsolete"
                text={"Are you the primary contact?"}
                value={this.props.venueContact.obsolete}
                //reverse={true}
                onChanged={(value) => this.onChangeVenueContact("obsolete", value)}
            />
        );
        if (venueLogic.registerVenueShowLicenseeIsPrimaryContactDetails(this.props.venueContact, this.props.licenseContact)) { //(!this.props.licenseContact.obsolete && !this.props.venueContact.obsolete) {
            venueContactIsLicencee = (
                <YesNoControlGroup
                    name="primaryContact"
                    text={"Is the Licensee the Primary Contact?"}
                    value={this.props.licenseContact.primaryContact}
                    //reverse={true}
                    onChanged={(value) => this.onChangeLicenseContact("primaryContact", value)}
                />
            );
        }

        if (venueLogic.registerVenueShowPrimaryContactDetails(this.props.venueContact, this.props.licenseContact)) { //
            venueContactDetails = (
                <>
                    <TextControlGroup
                        text={"Primary Contact First Name"}
                        defaultValue={this.props.venueContact.firstname}
                        error={this.props.errors["venueContact.firstname"]}
                        onBlur={(value) => this.onChangeVenueContact("firstname", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Last Name"}
                        defaultValue={this.props.venueContact.lastname}
                        error={this.props.errors["venueContact.lastname"]}
                        onBlur={(value) => this.onChangeVenueContact("lastname", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Phone"}
                        defaultValue={this.props.venueContact.phone}
                        error={this.props.errors["venueContact.phone"]}
                        onBlur={(value) => this.onChangeVenueContact("phone", value)}
                    />
                    <TextControlGroup
                        text={"Primary Contact Email Address"}
                        defaultValue={this.props.venueContact.email}
                        error={this.props.errors["venueContact.email"]}
                        onBlur={(value) => this.onChangeVenueContact("email", value)}
                    />
                </>
            );
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Venue Contacts"}>
                    <CardContent>
                        <LayoutForm>
                            {licenseContact}
                            {licenseContactDetails}
                            {venueContact}
                            {venueContactIsLicencee}
                            {venueContactDetails}
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}