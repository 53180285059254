import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';


export interface IRegisterVenueDetailsProps {
    venue: AusComplyDTOs.Venue;
    errors: any;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterVenueDetails extends Component<IRegisterVenueDetailsProps, any> {
    constructor(props: IRegisterVenueDetailsProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(fieldname, value) {
        let venue = { ...this.props.venue };
        venue[fieldname] = value;
        this.props.onUpdate(venue);
    }

    render() {

        let idDisplay;
        if (this.props.venue.venueId > 0) {
            idDisplay = (
                <ControlGroup text="ID">
                    <TextDisplay>{this.props.venue.venueId}</TextDisplay>
                </ControlGroup>
            );
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Details"}>
                    <CardContent>
                        <LayoutForm>
                            <TextControlGroup
                                text={"Venue Name"}
                                defaultValue={this.props.venue.name}
                                error={this.props.errors["name"]}
                                onBlur={(value) => this.onChange("name", value)} />
                            {idDisplay}
                            <TextControlGroup
                                text={"Phone"}
                                defaultValue={this.props.venue.phone}
                                error={this.props.errors["phone"]}
                                onBlur={(value) => this.onChange("phone", value)} />
                            <TextControlGroup
                                text={"Website"}
                                defaultValue={this.props.venue.website}
                                error={this.props.errors["website"]}
                                onBlur={(value) => this.onChange("website", value)} />
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}