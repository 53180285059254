
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { commonLogic } from './common';
import { checklistLogic } from './checklistLogic';
import { RadioOption } from '../dto/common';

export const incidentLogic = {
    validateIncident(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let errors = incidentLogic.validateIncident2(incident, incidentOptions);
        if (errors.length == 0) {
            return { error: "", page: "", draftOk: true, submitOk: true, idCheckOk: true };
        }
        return errors[0];
    },
    validateIncident2(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let errors: any[] = []
        let step: number = 0;
        try {
            if (incident && incidentOptions) {
                step = 1;
                // validate the incident before sending to the server
                if (incident.incidentTypeId === 0) {
                    step = 2;
                    errors.push({ error: "Incident type is required", page: "INCIDENT_TYPE", draftOk: false, submitOk: false, idCheckOk: false });
                }
                step = 3;
                if (!incident.incidentDate || incident.incidentDate.isEmpty) {
                    step = 4;
                    errors.push({ error: "Incident date is required", page: "INCIDENT_DATE", draftOk: false, submitOk: false, idCheckOk: false });
                }

                step = 5;
                let reportingPeriod = incident.isCurrentReportingPeriodConfirm ? 1 : 2;
                step = 6;
                let reportingPeriodRequired = incident.isCurrentReportingPeriod ? 1 : 2;
                step = 7;
                if (reportingPeriod === 1 && reportingPeriodRequired === 2) {
                    step = 8;
                    errors.push({ error: "Confirm that the incident date is outside the current reporting period", page: "INCIDENT_DATE", draftOk: false, submitOk: false, idCheckOk: false });
                }
                step = 9;
                if (reportingPeriod === 2 && reportingPeriodRequired === 1) {
                    step = 10;
                    errors.push({ error: "Confirm that the incident date is inside the current reporting period", page: "INCIDENT_DATE", draftOk: false, submitOk: false, idCheckOk: false });
                }
                step = 11;

                // check categories
                if (!incident.incidentCategory || incident.incidentCategory.length === 0) {
                    step = 12;
                    errors.push({ error: "Please select at least one category", page: "INCIDENT_CATEGORY", draftOk: true, submitOk: false, idCheckOk: false });
                }
                step = 13;

                if (this.isOtherTextRequired(incident, incidentOptions) && (!incident.other || incident.other === "")) {
                    step = 14;
                    errors.push({ error: "Other (specify) is required", page: "INCIDENT_CATEGORY", draftOk: true, submitOk: false, idCheckOk: false });
                }
                step = 15;

                if (this.isSeriousOtherTextRequired(incident, incidentOptions) && (!incident.seriousOther || incident.seriousOther === "")) {
                    step = 16;
                    errors.push({ error: "Serious Other (specify) is required", page: "INCIDENT_CATEGORY", draftOk: true, submitOk: false, idCheckOk: false });
                }

                // check locations
                step = 17;
                if (!incident.incidentLocation || incident.incidentLocation.length === 0) {
                    step = 18;
                    errors.push({ error: "Please select at least one location", page: "INCIDENT_LOCATION", draftOk: true, submitOk: false, idCheckOk: false });
                }
                step = 19;

                // validate checklists
                if (this.hasChecklists(incident, incidentOptions) && incident.checklists) {
                    step = 20;
                    let checklistsRequired = false;
                    step = 21;
                    incident.checklists.forEach(c => {
                        step = 22;
                        if (c.checkListDisplayItems) {
                            step = 24;
                            let errors: any = checklistLogic.validateChecklistDisplay(c.checkListDisplayItems);
                            step = 25;
                            if (errors.count > 0) {
                                step = 26;
                                checklistsRequired = true;
                            }
                        }
                    })
                    step = 27;
                    if (checklistsRequired) {
                        step = 28;
                        errors.push({ error: "Please complete all required incident actions", page: "INCIDENT_ACTIONS", draftOk: true, submitOk: false, idCheckOk: false });
                    }
                }

                // check involved people
                step = 29;
                //debugger;
                const dateOfBirthCheck = this.isDateOfBirthRequired(incident, incidentOptions);
                if (this.isPersonDescriptionRequired(incident, incidentOptions) ||
                    dateOfBirthCheck) {
                    step = 30;
                    let patronsOk = false;
                    step = 31;
                    if (incident.incidentPatrons) {
                        step = 32;
                        if (incident.incidentPatrons.filter(p => !p.obsolete).length > 0) {
                            step = 33;
                            patronsOk = true;
                        }
                    }
                    step = 34;
                    let numberInvolvedOk = false;
                    step = 35;
                    if (incident.numberInvolved && incident.numberInvolved > 0) {
                        step = 36;
                        if (incident.involvedDescription && incident.involvedDescription !== "") {
                            step = 37;
                            numberInvolvedOk = true;
                        }
                    }
                    step = 38;
                    if (!numberInvolvedOk && !patronsOk) {
                        step = 39;
                        errors.push({ error: "The involved patron details is required", page: "INCIDENT_PEOPLE", draftOk: true, submitOk: false, idCheckOk: false });
                    }
                }

                // validate patrons
                step = 41;
                if (incident.incidentPatrons) {
                    step = 42;
                    incident.incidentPatrons.filter(p => !p.obsolete).forEach(item => {
                        if (dateOfBirthCheck) {
                            step = 43;
                            let message = this.validateIncidentPatron(item, incident, incidentOptions);
                            step = 44;
                            if (message.length > 0) {
                                step = 45;
                                errors.push({ error: message, page: "INCIDENT_PEOPLE", draftOk: true, submitOk: false, idCheckOk: false });
                            }
                        } else if (item.hasChanged || item.lastModifiedBy == -1) {
                            step = 43;
                            let message = this.validateIncidentPatron(item, incident, incidentOptions);
                            step = 44;
                            if (message.length > 0) {
                                step = 45;
                                errors.push({ error: message, page: "INCIDENT_PEOPLE", draftOk: true, submitOk: false, idCheckOk: false });
                            }
                        }
                    });
                }

                // validate for id checks
                step = 46;
                if (this.isIDCheckIncidentType(incident, incidentOptions) &&
                    (!incident.incidentDocuments || incident.incidentDocuments.filter(item => !item.obsolete).length < 1)) {
                    step = 47;
                    errors.push({ error: "Photos are required", page: "IDCHECK", draftOk: true, submitOk: false, idCheckOk: false });
                }

                // validate comms log
                step = 48;
                if (this.incidentTypeEntryPage(incident, incidentOptions).toLowerCase() == "commslog") {
                    step = 49;
                    if (!incident.selectedRadioChannels || incident.selectedRadioChannels.length === 0) {
                        step = 50;
                        errors.push({ error: "Please select at least one radio channel", page: "COMMSLOG", draftOk: true, submitOk: false, idCheckOk: false });
                    }
                    step = 51;
                    if ((!incident.selectedFunctionalAreas || incident.selectedFunctionalAreas.length === 0)) {
                        step = 52;
                        errors.push({ error: "Please select at least functional area", page: "COMMSLOG", draftOk: true, submitOk: false, idCheckOk: false });
                    }
                }
            }
            step = 99;
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateIncident|" + step.toString(), {})
        }
        // console.log('validateIncident2', errors);
        return errors;
    },
    validateIncidentPatron(patron: AusComplyDtos.IncidentPatron, incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            if (patron.obsolete) {
                return "";
            }
            if (!patron.incidentPatronAgeRangeId || patron.incidentPatronAgeRangeId === 0) {
                return "Age range is required for involved patron"
            }
            if (!patron.genderId || patron.genderId === 0) {
                return "Gender is required for involved patron"
            }
            if (!patron.incidentPatronRacialAppearanceId || patron.incidentPatronRacialAppearanceId === 0) {
                return "Racial Appearance is required for involved patron"
            }
            if (this.isIncidentContactTracing(incident, incidentOptions)) {
                // For contact tracing name and phone are required
                if (!patron.name || patron.name.length === 0) {
                    return "Name is required for contact tracing for involved patron";
                }
                if ((!patron.phone || patron.phone.length === 0) && (!patron.email || patron.email.length === 0)) {
                    return "Phone or Email is required for contact tracing for involved patron";
                }
            }
            if (this.isDateOfBirthRequired(incident, incidentOptions)) {
                if (!patron.dobDate || patron.dobDate.isEmpty) {
                    return "Date of birth is required";
                }
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateIncidentPatron", {})
        }
        return "";
    },
    validateIncidentPatronDOB(patron: AusComplyDtos.IncidentPatron, incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            if (this.isDateOfBirthRequired(incident, incidentOptions)) {
                if (!patron.dobDate || patron.dobDate.isEmpty) {
                    return "Required";
                }
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateIncidentPatronDOB", {})
        }
        return "";
    },
    validateIncidentCategoriesMatchIncidentType(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId).forEach(incidentType => {
                    var incidentTypeCategoryTypesId = incidentType.incidentCategoryTypes.map(item => item.incidentCategoryTypeId);
                    incident.incidentCategory = incident.incidentCategory.filter(item => incidentTypeCategoryTypesId.indexOf(item.incidentCategoryTypeId) > -1);
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateIncidentCategoriesMatchIncidentType", {})
        }
        return incident;
    },
    setFirstCategoryAsDefault(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        if (incident.incidentCategory.length == 0) {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId).forEach(incidentType => {
                    var incidentTypeCategoryTypesId: number[] = incidentType.incidentCategoryTypes.map(item => item.incidentCategoryTypeId);
                    if (incidentTypeCategoryTypesId.length > 0) {
                        incident.incidentCategory = [];
                        incident.incidentCategory.push({
                            incidentCategoryId: 0,
                            incidentId: 0,
                            incidentCategoryTypeId: incidentTypeCategoryTypesId[0],
                            incidentCategoryType: new AusComplyDtos.IncidentCategoryType()
                        });
                        return true;
                    }
                });
            }
        }
        return false;
    },
    selectFirstIncidentCategoryTypeByName(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions, name: string) {
        if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
            incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId).forEach(incidentType => {
                var incidentTypeCategoryTypeIds: number[] = incidentType.incidentCategoryTypes.filter(f => f.name.toLowerCase().indexOf(name.toLowerCase()) > -1).map(item => item.incidentCategoryTypeId);
                if (incidentTypeCategoryTypeIds.length > 0) {
                    incident.incidentCategory = [];
                    incident.incidentCategory.push({
                        incidentCategoryId: 0,
                        incidentId: 0,
                        incidentCategoryTypeId: incidentTypeCategoryTypeIds[0],
                        incidentCategoryType: new AusComplyDtos.IncidentCategoryType()
                    });
                    return true;
                }
            });
        }
        return false;
    },
    setVenueLocation(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions, venueLocationId: number) {
        if (venueLocationId && venueLocationId > 0 && incident.incidentLocation.length == 0) {
            if (incidentOptions.venueLocations.filter(f => f.venueLocationId == venueLocationId).length > 0) {
                incident.incidentLocation.push({
                    incidentLocationId: 0,
                    incidentId: 0,
                    venueLocationId: venueLocationId,
                    venueLocation: new AusComplyDtos.VenueLocation()
                });
                return true;
            }
        }
        return false;
    },
    ensureOnePOIRecordExists(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        if (incident) {
            if (incident.incidentPatrons.length > 1) {
                incident.incidentPatrons = [incident.incidentPatrons[0]];
                return true;
            } else if (incident.incidentPatrons.length == 0) {
                let incidentPatron = new AusComplyDtos.IncidentPatron();
                incidentPatron.incidentPatronId = 0;
                incidentPatron.name = "";
                incidentPatron.obsolete = false;
                incidentPatron.hasChanged = true;
                incidentPatron.countryId = 1;
                incident.incidentPatrons.push(incidentPatron);
                return true;
            }
        }
        return false;
    },
    ensureUnder18IsSelected(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        if (incident) {
            if (incident.incidentPatrons.length > 0) {
                // find the under 18 option
                var ageRange = incidentOptions.incidentPatronAgeRanges.find(f => f.name == "Under 18");
                if (ageRange && !incident.incidentPatrons[0].incidentPatronAgeRangeId) {
                    incident.incidentPatrons[0].incidentPatronAgeRangeId = ageRange.id;
                }

                return true;
            }
        }
        return false;
    },
    removeUnder18IsSelected(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        if (incident) {
            if (incident.incidentPatrons.length > 0) {
                incident.incidentPatrons[0].incidentPatronAgeRangeId = 0;
                return true;
            }
        }
        return false;
    },
    isIDCheckIncidentType(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            let incidentTypeId = 0;
            if (incidentOptions && incidentOptions.incidentTypes) {
                incidentOptions.incidentTypes
                    .filter(item =>
                        item.entryPage && item.entryPage.toLowerCase() == "idscan")
                    .forEach(item => {
                        incidentTypeId = item.incidentTypeId;
                    });
                return incident.incidentTypeId == incidentTypeId;
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isIDCheckIncidentType", {})
        }
        return false;
    },
    incidentTypeName(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let result = "Incident";
        if (incidentOptions && incidentOptions.incidentTypes) {
            incidentOptions.incidentTypes
                .filter(item =>
                    item.incidentTypeId == incident.incidentTypeId)
                .forEach(item => {
                    result = item.name;
                });

        }
        return result;
    },
    incidentTypeEntryPage(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let result = "";
        if (incident && incidentOptions && incidentOptions.incidentTypes) {
            incidentOptions.incidentTypes
                .filter(item =>
                    item.incidentTypeId == incident.incidentTypeId)
                .forEach(item => {
                    result = item.entryPage;
                });

        }
        if (result == undefined || result == null) {
            result = "";
        }
        return result;
    },
    findIDCheckIncidentType(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let incidentTypeId = incident.incidentTypeId;
        incidentOptions.incidentTypes
            .filter(item =>
                item.entryPage && item.entryPage.toLowerCase() == "idscan")
            .forEach(item => {
                incidentTypeId = item.incidentTypeId;
            });
        return incidentTypeId;
    },
    findCommsLogIncidentType(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let incidentTypeId = incident.incidentTypeId;
        incidentOptions.incidentTypes
            .filter(item =>
                item.entryPage && item.entryPage.toLowerCase() == "commslog")
            .forEach(item => {
                incidentTypeId = item.incidentTypeId;
            });
        return incidentTypeId;
    },
    findRefuseIncidentType(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let incidentTypeId = incident.incidentTypeId;
        incidentOptions.incidentTypes
            .filter(item =>
                item.incidentTypeId == 2)
            .forEach(item => {
                incidentTypeId = item.incidentTypeId;
            });
        return incidentTypeId;
    },
    isIncidentRegulatedWalkthrough(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {

        let isRegulated = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isRegulated;
                let categoryTypes = incidentTypes[0].incidentCategoryTypes;

                incident.incidentCategory.map(incidentCategory => {
                    categoryTypes.filter(categoryType => this.categoryIsRegulated(incidentCategory.incidentCategoryTypeId, categoryType))
                        .forEach(categoryType => {
                            isRegulated = true;
                        });
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isIncidentRegulatedWalkthrough", {})
        }
        return isRegulated;
    },
    isIncidentContactTracing(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {

        let isContactTracing = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isContactTracing;
                let categoryTypes = incidentTypes[0].incidentCategoryTypes;

                incident.incidentCategory.map(incidentCategory => {
                    categoryTypes.filter(categoryType => this.categoryIsContactTracing(incidentCategory.incidentCategoryTypeId, categoryType))
                        .forEach(categoryType => {
                            isContactTracing = true;
                        });
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isIncidentContactTracing", {})
        }
        return isContactTracing;
    },
    categoryIsRegulated(id, categoryType) {
        let result = false;
        try {
            if (categoryType && categoryType.incidentCategoryTypeId === id) {
                result = categoryType.regulatedWalkthrough;
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.categoryIsRegulated", {})
        }
        return result;
    },
    categoryIsContactTracing(id, categoryType) {
        let result = false;
        try {
            if (categoryType.incidentCategoryTypeId === id) {
                result = categoryType.contactTracing;
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.categoryIsContactTracing", {})
        }
        return result;
    },
    categoryPersonIsRequired(id, categoryType: AusComplyDtos.IncidentCategoryType) {
        let result = false;
        try {
            if (categoryType.incidentCategoryTypeId === id) {
                result = categoryType.requiresPersonDescription;
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.categoryPersonIsRequired", {})
        }
        return result;
    },
    isPersonDescriptionRequired(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let isPersonRequired = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isPersonRequired;
                let categoryTypes = incidentTypes[0].incidentCategoryTypes;

                incident.incidentCategory.map(incidentCategory => {
                    categoryTypes.filter(categoryType => this.categoryPersonIsRequired(incidentCategory.incidentCategoryTypeId, categoryType))
                        .forEach(categoryType => {
                            isPersonRequired = true;
                        });
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isPersonDescriptionRequired", {})
        }
        return isPersonRequired;
    },
    isDateOfBirthRequired(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let isRequired = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                // get the incident type
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isRequired;
                // get all the incident category types that required a dob
                let incidentCategoryTypeIds = incidentTypes[0].incidentCategoryTypes.filter(i => i.requiresPersonDob == true).map(i => i.incidentCategoryTypeId);
                if (incidentCategoryTypeIds.length == 0) return isRequired;
                // are any of these categories linked
                if (incident.incidentCategory.filter(i => incidentCategoryTypeIds.indexOf(i.incidentCategoryTypeId) > -1).length > 0) {
                    isRequired = true;
                }
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isDateOfBirthRequired", {})
        }
        return isRequired;
    },
    isOtherTextRequired(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let isRequired = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isRequired;
                let categoryTypes = incidentTypes[0].incidentCategoryTypes;

                incident.incidentCategory.map(incidentCategory => {
                    categoryTypes.filter(categoryType => categoryType.incidentCategoryTypeId === incidentCategory.incidentCategoryTypeId && categoryType.isOtherTextRequired)
                        .forEach(categoryType => {
                            isRequired = true;
                        });
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isOtherTextRequired", {})
        }
        return isRequired;
    },
    isSeriousOtherTextRequired(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let isRequired = false;
        try {
            if (incidentOptions && incidentOptions.incidentTypeCategoryTypes) {
                let incidentTypes = incidentOptions.incidentTypeCategoryTypes.filter(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentTypes.length === 0) return isRequired;
                let categoryTypes = incidentTypes[0].incidentCategoryTypes;

                incident.incidentCategory.map(incidentCategory => {
                    categoryTypes.filter(categoryType => categoryType.incidentCategoryTypeId === incidentCategory.incidentCategoryTypeId && categoryType.isSeriousOtherTextRequired)
                        .forEach(categoryType => {
                            isRequired = true;
                        });
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isSeriousOtherTextRequired", {})
        }
        return isRequired;
    },
    hasChecklists(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let result = false;
        try {
            if (incident && incident.checklists && incident.checklists.length > 0) {
                incident.checklists.forEach(checklist => {
                    if (checklist.checklistItems && checklist.checklistItems.length > 0) {
                        result = true;
                    }
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.hasChecklists", {})
        }
        return result;
    },
    requiresPOI(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        if (incidentLogic.isDateOfBirthRequired(incident, incidentOptions)) {
            return true;
        }
        if (incidentLogic.isPersonDescriptionRequired(incident, incidentOptions)) {
            return true;
        }
        if (incidentLogic.isIncidentContactTracing(incident, incidentOptions)) {
            return true;
        }
        return false;
    },
    validateFunctionalAreasMatchRadioChannels(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            if (incidentOptions && incidentOptions.radioChannels && incident && incident.selectedFunctionalAreas && incident.selectedRadioChannels) {
                let valid: number[] = []
                incidentOptions.radioChannels.filter(f => incident.selectedRadioChannels.indexOf(f.radioChannelId) > -1)
                    .forEach((radioChannel, radioChannelIndex) => {
                        radioChannel.selectedFunctionalAreas.forEach((item, index) => {
                            if (incident.selectedFunctionalAreas.indexOf(item) > -1) {
                                valid.push(item);
                            }
                        });
                    });
                incident.selectedFunctionalAreas = [...valid];
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateFunctionalAreasMatchRadioChannels", {})
        }
        return incident;
    },
    validateSubLocationsMatchVenueLocations(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        try {
            if (incidentOptions && incidentOptions.venueLocations && incident
                && incident.incidentLocation && incident.selectedSubLocations) {
                let valid: number[] = []
                let venueLocationsSelected: number[] = incident.incidentLocation.map(item => item.venueLocationId);
                incidentOptions.venueLocations.filter(f => venueLocationsSelected.indexOf(f.venueLocationId) > -1)
                    .forEach((venueLocation, venueLocationIndex) => {
                        venueLocation.selectedSubLocations.forEach((subLocationId, index) => {
                            if (incident.selectedSubLocations.indexOf(subLocationId) > -1) {
                                valid.push(subLocationId);
                            }
                        });
                    });
                incident.selectedSubLocations = [...valid];
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.validateFunctionalAreasMatchRadioChannels", {})
        }
        return incident;
    },
    isRGO(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let isRGO = false;
        try {
            if (incident && incidentOptions && incidentOptions.incidentTypes) {
                let incidentType = incidentOptions.incidentTypes.find(i => i.incidentTypeId === incident.incidentTypeId);
                if (incidentType) {
                    isRGO = incidentType.isRGO;
                }
            }
        }
        catch (err) {
            commonLogic.trackException(err, "incidentLogic.isRGO", {})
        }
        return isRGO;
    },
    availableIncidentTypesAsRadioOptions(incident: AusComplyDtos.Incident, incidentOptions: AusComplyDtos.IncidentOptions) {
        let options: RadioOption[] = [];
        if (incidentOptions.incidentTypes) {
            incidentOptions.incidentTypes.forEach(item => {
                if (item.apiOnlyCreation === undefined || !item.apiOnlyCreation) {
                    options.push({ id: item.incidentTypeId.toString(), name: item.name });
                } else if (incident && incident.originalIncidentId) {
                    // show the original incident type if viewing the full incident details from a api only incident
                    if (item.incidentTypeId == incident.originalIncidentTypeId) {
                        options.push({ id: item.incidentTypeId.toString(), name: item.name });
                    }
                }
            });
        };
        return options;
    }
};