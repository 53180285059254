import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import DefaultButton from '../../controls/DefaultButton';
import Chip from '@material-ui/core/Chip';
import PageMessage from '../../common/PageMessage';
import View from '../../common/View';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import ChecklistTemplateDetail from './ChecklistTemplateDetail';
import ChecklistTemplateNavigation from './ChecklistTemplateNavigation';
import TransparentButton from '../../controls/TransparentButton';
import CardRow from '../../common/CardRow';
import PaperclipIcon from '../../../resources/PaperclipIcon';
import TickIcon from '../../../resources/TickIcon';
import ChecklistTemplateItem from './ChecklistTemplateItem'
import FullPageDialog from '../../layout/FullPageDialog';
import { CheckListDisplayItem } from '../../../common/dto/AusComply.dtos';
import CheckListItem from '../checklist/CheckListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ChecklistItemType from './ChecklistItemType';
import ChecklistTemplateItemDrop from './ChecklistTemplateItemDrop';
import ChecklistTemplateItemCards from './ChecklistTemplateItemCards';
import Tabs from '../../common/Tabs';
import PageLayout from '../../layout/PageLayout';
import NotificationTrigger from '../../notifications/NotificationTrigger';

export interface ICheckListTemplateProps {
    groupId: number;
    venueId: number;
    securityFirmId: number;
    checklistTemplateId: number;
    checklistTemplate: AusComplyDtos.ChecklistTemplate,
    groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[],
    checklistTemplateItemsVersion: number,
    isLoading: boolean,
    isSaving: boolean,
    onLoad: Function;
    onCreate: Function;
    onLoadReferenceData: Function;
    onUpdate: Function;
    onUpdateItem: Function;
    onAddItem: Function;
    onMoveItem: Function;
    onRemoveItem: Function;
    onSave: Function;
    onReloadGroup?: Function;
    canEditVenues: boolean;
    canEditSecurityFirms: boolean;
    canEditIndustryCategories: boolean;
    canEditStates: boolean;
    canEditCategories: boolean;
    venues: AusComplyDtos.NamedValue[],
    securityFirms: AusComplyDtos.NamedValue[],
    industryCategories: AusComplyDtos.IndustryCategory[],
    states: AusComplyDtos.State[],
    checklistTemplateCategories: AusComplyDtos.ChecklistTemplateCategory[],
    history: any;
    location: any;
}

interface ICheckListTemplateState {
    editItem: AusComplyDtos.ChecklistTemplateItem;
    notificationTrigger?: AusComplyDtos.NotificationTrigger;
    preview: CheckListDisplayItem[];
    selectedTab: number;
}


class CheckListTemplate extends React.Component<ICheckListTemplateProps, ICheckListTemplateState> {

    constructor(props: ICheckListTemplateProps) {
        super(props)
        let editItem = new AusComplyDtos.ChecklistTemplateItem();
        editItem.temporaryId = 0;
        this.state = {
            editItem,
            notificationTrigger: undefined,
            preview: [],
            selectedTab: 0
        }
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onPreview = this.onPreview.bind(this);
        this.onPreviewClose = this.onPreviewClose.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onCancelEditItem = this.onCancelEditItem.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.isDropped = this.isDropped.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.onEnsureLoaded();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isSaving && prevProps.isSaving
            && this.props.groupId && this.props.groupId > 0
            && this.props.onReloadGroup) {
            this.props.onReloadGroup(this.props.groupId);
        }
        // console. log(Number(this.props.checklistTemplateId), prevProps.isSaving, this.props.isSaving, Number(this.props.checklistTemplate.checklistTemplateId), this.props.groupedChecklistTemplateItems);
        if (Number(this.props.checklistTemplateId) === 0 && prevProps.isSaving && !this.props.isSaving && Number(this.props.checklistTemplate.checklistTemplateId) > 0) {
            // switch to editor
            var path = this.props.location.pathname.split('/');
            var updatedPath = path.pop();
            var url = path.join('/') + "/" + this.props.checklistTemplate.checklistTemplateId;
            this.props.history.push(url);
        } else {
            this.onEnsureLoaded();
        }
    }

    onEnsureLoaded() {
        if (!this.props.isLoading && !this.props.isSaving) {
            let id = -1;
            if (this.props.checklistTemplate && this.props.checklistTemplate.checklistTemplateId !== undefined) {
                id = this.props.checklistTemplate.checklistTemplateId;
            }
            // ('onEnsureLoaded', id, this.props.checklistTemplateId);
            if (id.toString() !== this.props.checklistTemplateId.toString()) {
                if (this.props.checklistTemplateId.toString() === "0") {
                    let venues: any[] = [];
                    let securityFirms: any[] = [];
                    let groupId: number | undefined;

                    if (this.props.groupId && this.props.groupId > 0) {
                        groupId = this.props.groupId;
                    } else {
                        if (this.props.venueId) {
                            venues = [this.props.venueId];
                        }

                        if (this.props.securityFirmId) {
                            securityFirms = [this.props.securityFirmId];
                        }
                    }

                    let checklistTemplate = {
                        checklistTemplateId: 0,
                        checklistTemplateItems: [],
                        venues,
                        securityFirms,
                        enabledVenues: true,
                        enabledSecurityFirms: true,
                        isGlobal: false,
                        groupId,
                        checklistTemplateCategories: [],
                        industryCategories: [],
                        states: []
                    };

                    this.props.onUpdate(checklistTemplate);
                } else {
                    this.props.onLoad(this.props.checklistTemplateId);
                }
                this.props.onLoadReferenceData();
            }
        }
    }

    handleTabChange = (event, value) => {
        var self = this;
        this.setState({ selectedTab: value }, () => {
        });
    };

    onUpdate(checklistTemplate: AusComplyDtos.ChecklistTemplate) {
        this.props.onUpdate(checklistTemplate);
    }

    onClose() {
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        if (this.props.groupId && this.props.groupId > 0) {
            updatedPath = path.pop();
        }
        var url = path.join('/');
        this.props.history.push(url);
    }

    onSave() {
        this.props.onSave(this.props.checklistTemplate);
    }

    flattenForPreview(preview: CheckListDisplayItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[], offset: number) {
        groupedItems.forEach(item => {
            var display = new CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });
    }

    onPreview() {
        let preview: CheckListDisplayItem[] = [];
        let offset: number = 0;

        this.props.groupedChecklistTemplateItems.forEach(item => {
            var display = new CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });

        this.setState({
            preview
        });
    }

    onPreviewClose() {
        this.setState({
            preview: []
        });
    }

    onEditItem(checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {

        // is there a trigger defined for this item
        let id = checklistTemplateItem.checklistTemplateItemId;

        // is there a default trigger?
        let notificationTrigger = this.props.checklistTemplate.notificationTriggers.find(f => f.parentId == id);
        if (!notificationTrigger) {
            if (this.props.checklistTemplate.defaultNotificationTrigger) {
                notificationTrigger = { ...this.props.checklistTemplate.defaultNotificationTrigger };
                notificationTrigger.parentId = id;
            }
        }


        this.setState({
            editItem: { ...checklistTemplateItem },
            notificationTrigger
        });
    }

    onRemoveItem(checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
        this.props.onRemoveItem(checklistTemplateItem);
    }

    onMoveItem(checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {

    }

    onCancelEditItem() {
        let updatedItem = { ...this.state.editItem };
        if (this.state.notificationTrigger) {
            const notificationTrigger = { ...this.state.notificationTrigger };
            let notificationTriggers = [...this.props.checklistTemplate.notificationTriggers].filter(f => f.parentId != notificationTrigger.parentId);
            notificationTriggers.push(notificationTrigger);
            let checklistTemplate = { ...this.props.checklistTemplate };
            checklistTemplate.notificationTriggers = notificationTriggers;
            this.props.onUpdate(checklistTemplate);
        }
        this.props.onUpdateItem(updatedItem);
        let editItem = new AusComplyDtos.ChecklistTemplateItem();
        editItem.temporaryId = 0;
        this.setState({
            editItem,
            notificationTrigger: undefined
        });
    }

    onUpdateItem(checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
        this.setState({ editItem: checklistTemplateItem })
    }

    onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // console .log('onDragEnd', source, destination);

    }

    renderItems(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[], temporaryId?: number) {
        let items;
        if (checklistTemplateItems) {
            let length = checklistTemplateItems.length;
            if (length === 0) {
                return (
                    <div key={"checklistTemplateItems-empty-0"}>
                        <ChecklistTemplateItemDrop position={0} parentId={temporaryId} />
                    </div>
                );
            }
            items = checklistTemplateItems.map((item, index) => {
                return (
                    <div key={"checklistTemplateItems-" + item.temporaryId + "-" + index}>
                        <ChecklistTemplateItemDrop position={index} parentId={item.temporaryId} />
                        <CardRow>
                            <CardTitle>{item.checklistItemType}</CardTitle>
                            {item.description && (
                                <CardTypography style={{ whiteSpace: 'none' }}>{item.description}</CardTypography>
                            )}
                            <CardTypography style={{ whiteSpace: 'none', color: '#999' }}>
                                {item.attachment && item.attachment.fileName && (
                                    <>
                                        <PaperclipIcon fill={"#999"} style={{ width: '10px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                                        {item.attachment.fileName}&nbsp;&nbsp;&nbsp;
                                    </>
                                )}
                                {item.file && item.file.fileName && (
                                    <>
                                        <PaperclipIcon fill={"#999"} style={{ width: '10px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                                        {item.file.fileName}&nbsp;&nbsp;&nbsp;
                                    </>
                                )}
                                {item.mandatory && (
                                    <>
                                        <TickIcon fill={"#999"} style={{ width: '10px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                                        Mandatory&nbsp;&nbsp;&nbsp;
                                    </>
                                )}
                                {item.enableInstanceAttachments && (
                                    <>
                                        <TickIcon fill={"#999"} style={{ width: '10px', height: '10px', marginBottom: '1px', marginRight: '5px' }} />
                                        Attachments enabled&nbsp;&nbsp;&nbsp;
                                    </>
                                )}
                            </CardTypography>
                            <Grid>
                                <Grid item xs={12}>
                                    {item.checklistTemplateItemOptions.map((option, index) => {
                                        return (
                                            <Chip key={"checklistTemplateItems-option-" + option.rank + "-" + index} label={option.name} style={{ marginRight: '5px' }}></Chip>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <TransparentButton text={"Remove"} onClick={() => this.onRemoveItem(item)} style={{ width: 'auto', marginRight: '10px' }} />
                                    <DefaultButton text={"Edit"} onClick={() => this.onEditItem(item)} style={{ width: 'auto', marginRight: '10px' }} />
                                </Grid>
                            </Grid>
                            {item.checklistItemType === AusComplyDtos.ChecklistItemType.Group && item.groupedItems && (
                                <>
                                    {this.renderItems(item.groupedItems, item.temporaryId)}
                                </>
                            )}
                        </CardRow>
                        {(index === length - 1) && (
                            <ChecklistTemplateItemDrop position={index + 1} parentId={item.temporaryId} />
                        )}
                    </div>

                );
            })
        }

        return items;
    }

    renderDisplayItem(checkListDisplayItem: CheckListDisplayItem, index: number) {

        let key = "checklistitem-" + index.toString();

        return (
            <CheckListItem key={key}
                checkListDisplayItem={checkListDisplayItem}
                checklistUserSelections={this.props.checklistTemplate.checklistUserSelections}
                onUploadAttachment={() => { }}
                onUpdate={(i) => { }}
                isReadonly={false}
                isUploading={false} />
        )
    }

    isDropped(name: string) {

    }

    render() {
        let self = this;
        let items;
        let isGroup: boolean = this.props.groupId != undefined && this.props.groupId > 0;
        let tabs: string[] = ["Details", "Items"];
        if (!this.props.isLoading && !this.props.isSaving) {
            if (this.props.checklistTemplateId == 0) {
                items = <PageMessage text={"Designer is available after save"} />
            } else {
                tabs = [...tabs, "Notify"]
                items = (
                    <Grid container style={{ marginTop: '20px' }}>
                        <Grid item xs={3} style={{ position: 'relative' }}>
                            <div className="checklist-toolbox">
                                <SubTitle text={"Toolbox"} />
                                <ChecklistItemType
                                    title="Group"
                                    description="Group a set of fields together"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Group}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Information"
                                    description="Add extra information or instructions"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Information}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Text Field"
                                    description="Input additional notes"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.TextBox}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Question"
                                    description="Yes/No or custom responses"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Question}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Selection"
                                    description="Select one option from custom responses"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Selection}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Multi-Select"
                                    description="Custom responses, select single or multiple answers"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.MultipleChoice}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Checkbox"
                                    description="A checkbox to tick"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.Checkbox}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Date"
                                    description="Input a date"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.DateTime}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Staff (single)"
                                    description="Select one staff member"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.StaffSingleSelection}
                                    onAdd={this.props.onAddItem}
                                />
                                <ChecklistItemType
                                    title="Staff (multiple)"
                                    description="Select multiple staff members"
                                    checklistItemType={AusComplyDtos.ChecklistItemType.StaffMultipleSelection}
                                    onAdd={this.props.onAddItem}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={8}>
                            <SubTitle text={"Template Designer"} />
                            <ChecklistTemplateItemCards
                                key={"ChecklistTemplateItemCards--" + this.props.checklistTemplateItemsVersion}
                                checklistTemplateItems={this.props.groupedChecklistTemplateItems}
                                notificationTriggerParentIds={this.props.checklistTemplate.notificationTriggers ? this.props.checklistTemplate.notificationTriggers.filter(f => f.notify).map((value) => value.parentId) : []}
                                parentId={0}
                                onEdit={this.onEditItem}
                                onRemove={this.onRemoveItem}
                                onMove={this.props.onMoveItem} />
                        </Grid>
                    </Grid>
                );
            }
        }
        let editItem;
        if (this.state.editItem.temporaryId !== 0) {
            editItem = (
                <ChecklistTemplateItem
                    checklistTemplateItem={this.state.editItem}
                    checklistTemplateItems={this.props.checklistTemplate.checklistTemplateItems}
                    notificationTrigger={this.state.notificationTrigger}
                    options={this.state.editItem.checklistTemplateItemOptions}
                    onUpdate={value => this.onUpdateItem(value)}
                    onUpdateNotificationTrigger={value => self.setState({ notificationTrigger: value })}
                />
            );
        }
        let preview;
        if (this.state.preview.length > 0) {
            preview = this.state.preview.map((item, index) => {
                return this.renderDisplayItem(item, index);
            });
        }
        let venueId: any = undefined;
        let securityFirmId: any = undefined;
        if (this.props.location.pathname.split('/').indexOf('admin') == -1) {
            venueId = this.props.venueId;
            securityFirmId = this.props.securityFirmId;
        }
        return (
            <DndProvider backend={HTML5Backend}>
                <PageLayout
                    headerText={"Checklist Template"}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    footerContent={
                        <ChecklistTemplateNavigation
                            onCancel={this.onClose}
                            onSave={this.onSave}
                            onPreview={this.onPreview}
                            isSaving={this.props.isSaving}
                        />
                    }
                >
                    <View style={{ marginTop: '10px' }}>
                        <Tabs selected={this.state.selectedTab} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
                    </View>
                    {this.state.selectedTab === 0 && (
                        <ChecklistTemplateDetail
                            venueId={venueId}
                            securityFirmId={securityFirmId}
                            checklistTemplate={this.props.checklistTemplate}
                            onUpdate={checklistTemplate => this.onUpdate(checklistTemplate)}
                            errors={{}}
                            canEditVenues={this.props.canEditVenues}
                            canEditSecurityFirms={this.props.canEditSecurityFirms}
                            canEditIndustryCategories={this.props.canEditIndustryCategories}
                            canEditStates={this.props.canEditStates}
                            canEditCategories={this.props.canEditCategories}
                            venues={this.props.venues}
                            securityFirms={this.props.securityFirms}
                            industryCategories={this.props.industryCategories}
                            states={this.props.states}
                            checklistTemplateCategories={this.props.checklistTemplateCategories}
                        />
                    )}
                    {this.state.selectedTab === 1 && (
                        <>
                            {items}
                        </>
                    )}
                    {this.state.selectedTab === 2 && (
                        <>
                            {
                                (this.props.checklistTemplate.notificationTriggerOnSubmit) && <NotificationTrigger
                                    title="On Submit"
                                    notificationTrigger={this.props.checklistTemplate.notificationTriggerOnSubmit}
                                    onUpdate={(value) => {
                                        let checklistTemplate = {...self.props.checklistTemplate};
                                        checklistTemplate.notificationTriggerOnSubmit = value;
                                        self.onUpdate(checklistTemplate);
                                    }}
                                />
                            }
                        </>
                    )}
                    <FullPageDialog open={this.state.preview.length > 0} title="Preview" onDismissed={() => this.onPreviewClose()}>
                        {preview}
                    </FullPageDialog>
                    <FullPageDialog open={this.state.editItem.temporaryId !== 0} title="Edit" onDismissed={() => this.onCancelEditItem()}>
                        {editItem}
                    </FullPageDialog>
                </PageLayout>
            </DndProvider>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(CheckListTemplate));