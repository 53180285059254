import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const groupServiceApi = {
  find: (venueId, securityFirmId, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetGroups();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, group) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroup();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.group = group;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, groupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetGroup();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    const request = client.get(query);
    return from(request);
  },
  getAvailableVenues: (venueId, securityFirmId, groupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetGroupAvailableVenues();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    const request = client.get(query);
    return from(request);
  },
  insertVenues: (venueId, securityFirmId, groupId, venues, autoApproval) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupVenues();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    query.venues = venues;
    query.autoApproval = autoApproval;
    const request = client.post(query);
    return from(request);
  },
  updateGroupVenue: (venueId, securityFirmId, groupId, groupVenue) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupVenue();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    query.groupVenue = groupVenue;
    const request = client.post(query);
    return from(request);
  },
  getAvailableUsers: (venueId, securityFirmId, groupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetGroupAvailableUsers();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    const request = client.get(query);
    return from(request);
  },
  insertUsers: (venueId, securityFirmId, groupId, users, autoApproval, userRoleTypeId, isTemporary, expiryDate) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupUsers();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    query.users = users;
    query.autoApproval = autoApproval;
    query.userRoleTypeId = userRoleTypeId;
    query.isTemporary = isTemporary;
    query.expiryDate = expiryDate;
    const request = client.post(query);
    return from(request);
  },
  updateGroupUser: (venueId, securityFirmId, groupId, groupUser) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupUser();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    query.groupUser = groupUser;
    const request = client.post(query);
    return from(request);
  },
  findForSelection: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetGroupForSelection();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  requestAccess: (venueId, securityFirmId, groupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupRequestAccess();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    const request = client.post(query);
    return from(request);
  },
  requestAccessReject: (venueId, securityFirmId, groupId, notificationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostGroupRequestAccessReject();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.groupId = groupId;
    query.notificationId = notificationId;
    const request = client.post(query);
    return from(request);
  }
};
