import React, { Component } from 'react';
import Loading from '../../../common/Loading';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { withRouter } from "react-router";
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PageTitle from '../../../common/PageTitle';
import SubTitle from '../../../common/SubTitle';
import FullPageDialog from '../../../layout/FullPageDialog';
import Information from '../../../common/Information';
import WelcomeContainer from '../../../../containers/WelcomeContainer';
import TransparentButton from '../../../controls/TransparentButton';
import CheckboxControlGroup from '../../../controls/CheckboxControlGroup';
import HomeStatistics from '../../../home/HomeStatistics';
import DefaultButton from '../../../controls/DefaultButton';
import SearchDateControlGroup from '../../../controls/SearchDateControlGroup';
import GroupDashboardFilter from './GroupDashboardFilter';
import GroupDashboardFilterSummary from './GroupDashboardFilterSummary';
import View from '../../../common/View';
import Tabs from '../../../common/Tabs';
import { render } from "react-dom";
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../../common/NoRowsMessage';
import LayoutForm from '../../../layout/LayoutForm';
import DashboardPostcodes from '../../../admin/dashboard/DashboardPostcodes';
import { AdminDashboardOptions } from '../../../../common/dto/common';
import Map from './Map';
import { getColors } from '../../../../common/reducers/dashboard';
import PageLayout from '../../../layout/PageLayout';


export interface IGroupDashboardProps {
    groupId: number,
    isLoading: boolean,
    isLoadingIncidents: boolean;
    filter: AusComplyDtos.DashboardFilter;
    incidents: AusComplyDtos.IncidentHeatmap[];
    venueMarkers: AusComplyDtos.VenueMarker[];
    heatmapData: any;
    data: AusComplyDtos.ChartDetails[];
    options: AdminDashboardOptions;
    incidentTotals: any;
    canFilter: boolean;
    onSetFilter: Function;
    onResetFilter: Function;
    onLoad: Function;
    onExport: Function;
    onSetOptions: Function;
}

interface IGroupDashboardState {
    selectedTab: number;
    filterExpanded: boolean;
    incidentTypesExpanded: boolean;
    incidentTypes: any[];
    incidentTypeColors: string[]
    incidentTypesByVenue: any[];
    incidentRefuseEntryReasonsExpanded: boolean;
    incidentRefuseEntryReasons: any[];
    incidentRefuseEntryReasonsByVenue: any[];
    incidentRefuseEntryReasonsGridLinesCount: number;
    incidentAskedToLeaveExpanded: boolean;
    incidentAskedToLeave: any[];
    incidentAskedToLeaveByVenue: any[];
    incidentAskedToLeaveGridLinesCount: number;
    checklistsExpanded: boolean;
    checklists: any[];
    checklistsGridLinesCount: number;
    checklistsByVenue: any[];
}


class GroupDashboard extends Component<IGroupDashboardProps, IGroupDashboardState> {
    constructor(props: IGroupDashboardProps) {
        super(props)

        this.state = {
            selectedTab: 0,
            filterExpanded: false,
            incidentTypesExpanded: false,
            incidentTypes: [['Incident Type', 'Count']],
            incidentTypeColors: [],
            incidentTypesByVenue: [],
            incidentRefuseEntryReasonsExpanded: false,
            incidentRefuseEntryReasons: [['Reason', 'Count']],
            incidentRefuseEntryReasonsByVenue: [],
            incidentRefuseEntryReasonsGridLinesCount: 5,
            incidentAskedToLeaveExpanded: false,
            incidentAskedToLeave: [['Reason', 'Count']],
            incidentAskedToLeaveByVenue: [],
            incidentAskedToLeaveGridLinesCount: 5,
            checklistsExpanded: false,
            checklists: [['Checklists', 'Count']],
            checklistsGridLinesCount: 5,
            checklistsByVenue: []
        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onChangeOptions = this.onChangeOptions.bind(this);
        this.onChangePostcodes = this.onChangePostcodes.bind(this);

    }

    componentDidMount() {
        if (this.props.filter.groupId == -1 || (this.props.filter.groupId != this.props.groupId)) {
            if (this.props.onResetFilter) {
                this.props.onResetFilter(this.props.groupId);
            }
        } else if (this.props.data) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.isLoading && !this.props.isLoading) || (!prevProps.data && this.props.data)) {
            this.loadData();
        } else if (!this.props.isLoading && (this.props.filter.groupId == -1 || (this.props.filter.groupId != this.props.groupId))) {
            if (this.props.onResetFilter) {
                this.props.onResetFilter(this.props.groupId);
            }
        }
    }

    onChangeOptions(fieldName, value) {
        let options = { ... this.props.options };
        options[fieldName] = value;
        this.props.onSetOptions(options);
    }

    onChangePostcodes(value) {
        let options = { ... this.props.options };
        options.postcodes = value;
        this.props.onSetOptions(options);
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    loadData() {
        // incident types
        const venues = [...this.props.filter.venues.filter(f => f.selected)];
        let filteredData = this.props.data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentType);
        let combinedData: AusComplyDtos.ChartDetails[] = [];
        filteredData.forEach(d => {
            let data = combinedData.find(i => {
                return i.name == d.name;
            });
            if (data) {
                data.total += d.total;
            } else {
                combinedData.push({ ...d });
            }
        });
        let incidentTypes: any[] = [['Incident Type', 'Count']];
        let incidentTypeColors: string[] = getColors(combinedData);
        let incidentTypesByVenue: any[] = [];
        combinedData.forEach(d => {
            let incidentType = incidentTypes.find(i => {
                return i[0] == d.name;
            });
            if (incidentType) {
                incidentType.total += d.total;
            } else {
                incidentTypes.push([d.name, d.total]);
            }
        });

        // by venue
        venues.forEach(v => {
            const filteredVenueData = filteredData.filter(f => f.subCategory == v.name);
            let incidentTypesVenue: any[] = [['Incident Type', 'Count']];
            filteredVenueData.forEach(d => incidentTypesVenue.push([d.name, d.total]));
            if (v.isActive) {
                incidentTypesByVenue.push({
                    name: v.name,
                    id: v.venueId,
                    data: incidentTypesVenue,
                    colors: getColors(filteredVenueData)
                });
            }
        });

        // incident refuse entry reasons
        filteredData = this.props.data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentRefusalReason);
        let incidentRefuseEntryReasonsGridLinesCount = this.getGridlinesCount(filteredData);
        let incidentRefuseEntryReasonsByVenue: any[] = [];
        let incidentRefuseEntryReasons: any[] = [
            [
                { type: 'string', label: 'Reason' },
                { type: 'number', label: 'Total' },
                { type: 'string', label: 'Annotation', role: 'annotation' },
            ]
        ];
        //filteredData.forEach(d => incidentRefuseEntryReasons.push([d.name, d.total, d.annotation]));
        filteredData.forEach(d => {
            let item = incidentRefuseEntryReasons.find(i => {
                return i[0] == d.name;
            });
            if (item) {
                item[1] += d.total;
            } else {
                incidentRefuseEntryReasons.push([d.name, d.total, d.total.toString()]);
            }
        });
        // by venue
        venues.forEach(v => {
            const filteredVenueData = filteredData.filter(f => f.subCategory == v.name);
            let venueData: any[] = [[
                { type: 'string', label: 'Reason' },
                { type: 'number', label: 'Total' },
                { type: 'string', label: 'Annotation', role: 'annotation' },
            ]];
            filteredVenueData.forEach(d => venueData.push([d.name, d.total, d.total.toString()]));
            incidentRefuseEntryReasonsByVenue.push({
                name: v.name,
                id: v.venueId,
                data: venueData
            });
        });

        // incident asked to leave
        filteredData = this.props.data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentRemovalReason);
        let incidentAskedToLeaveGridLinesCount = this.getGridlinesCount(filteredData);
        let incidentAskedToLeaveByVenue: any[] = [];
        let incidentAskedToLeave: any[] = [
            [
                { type: 'string', label: 'Reason' },
                { type: 'number', label: 'Total' },
                { type: 'string', label: 'Annotation', role: 'annotation' },
            ]
        ];
        //filteredData.forEach(d => incidentAskedToLeave.push([d.name, d.total, d.annotation]));
        filteredData.forEach(d => {
            let item = incidentAskedToLeave.find(i => {
                return i[0] == d.name;
            });
            if (item) {
                item[1] += d.total;
            } else {
                incidentAskedToLeave.push([d.name, d.total, d.total.toString()]);
            }
        });
        // by venue
        venues.forEach(v => {
            const filteredVenueData = filteredData.filter(f => f.subCategory == v.name);
            let venueData: any[] = [[
                { type: 'string', label: 'Reason' },
                { type: 'number', label: 'Total' },
                { type: 'string', label: 'Annotation', role: 'annotation' },
            ]];
            filteredVenueData.forEach(d => venueData.push([d.name, d.total, d.total.toString()]));
            incidentAskedToLeaveByVenue.push({
                name: v.name,
                id: v.venueId,
                data: venueData
            });
        });

        // checklists
        let checklists: any[] = [['Checklist', 'Count']];
        filteredData = this.props.data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.ChecklistTemplate);
        let checklistsGridLinesCount = this.getGridlinesCount(filteredData);
        filteredData.forEach(d => {
            let data = checklists.find(i => {
                return i.name == d.name;
            });
            if (data) {
                data.total += d.total;
            } else {
                checklists.push([d.name, d.total]);
            }
        });
        let checklistsByVenue: any[] = [];

        // by venue
        venues.forEach(v => {
            const filteredVenueData = filteredData.filter(f => f.subCategory == v.name);
            let checklistsVenue: any[] = [['Checklist', 'Count']];
            filteredVenueData.forEach(d => checklistsVenue.push([d.name, d.total]));
            if (v.isActive) {
                checklistsByVenue.push({
                    name: v.name,
                    id: v.venueId,
                    data: checklistsVenue,
                    colors: getColors(filteredVenueData)
                });
            }
        });

        this.setState({
            incidentTypes,
            incidentTypeColors,
            incidentTypesByVenue,
            incidentRefuseEntryReasons,
            incidentRefuseEntryReasonsByVenue,
            incidentRefuseEntryReasonsGridLinesCount,
            incidentAskedToLeave,
            incidentAskedToLeaveByVenue,
            incidentAskedToLeaveGridLinesCount,
            checklists,
            checklistsGridLinesCount,
            checklistsByVenue
        });
    }

    onSearch(filter: AusComplyDtos.DashboardFilter) {
        this.props.onSetFilter(filter);
        //this.props.onLoad();
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onResetFilter(this.props.groupId);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            //this.props.onLoad();
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.DashboardFilter) {

    }

    getMaxValue(dataValues) {
        var _iMaxValue = -1;
        for (var i = 0; i < dataValues.length; i++) {
            if (dataValues[i].Total > _iMaxValue) {
                _iMaxValue = dataValues[i].Total;
            }
        }
        return _iMaxValue;
    }

    getGridlinesCount(dataValues) {
        var _iMaxValue = this.getMaxValue(dataValues);

        if (_iMaxValue <= 5) {
            return _iMaxValue + 1;
        } else {
            return 5;
        }
    }

    pieChartOptions(title: string, colors?: string[]) {
        let options: any = {
            //title: title,
            backgroundColor: '#2E2C2D',
            fontSize: '14',
            chartArea: {
                backgroundColor: '#000000'
            },
            legend: {
                position: 'labeled',
                labeledValueText: 'both',
                textStyle: {
                    color: '#ffffff'
                }
            },
            sliceVisibilityThreshold: 0,
            titleTextStyle: {
                color: '#ffffff'
            }
        };

        if (colors && colors.length > 0) {
            options = { ...options, colors: colors };
        }

        return options;
    }

    barChartOptions(title: string, gridLinesCount: number, count: number) {

        var chartAreaHeight = count * 30;
        var chartHeight = chartAreaHeight;

        return {
            //title: title,
            legend: { position: 'none' },
            isStacked: true,
            annotations: {
                alwaysOutside: true,
            },
            backgroundColor: '#2E2C2D',
            chartArea: {
                left: '40%',
                width: '55%',
                backgroundColor: '#000000',
                height: chartAreaHeight
            },
            colors: ['#818183'],
            fontSize: '14',
            height: chartHeight,
            hAxis: {
                format: '#',
                minValue: 0,
                gridlines: {
                    count: gridLinesCount,
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            titleTextStyle: {
                color: '#ffffff'
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff',
                    fontSize: '14'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            animation: {
                duration: 1200,
                easing: 'out',
                startup: true
            }
        };
    }

    renderBarChart(name: string, data: any[], gridLinesCount: number) {
        let chart;
        if (!this.props.isLoading && data.length > 1) {
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                    <SubTitle text={name} />
                    <div className={name.replace(" ", "") + "Chart-container"}>
                        <Chart
                            options={this.barChartOptions(name, gridLinesCount, data.length)}
                            chartType="BarChart"
                            data={data}
                            legendToggle
                        />
                    </div>
                </View>
            );
        }
        return chart;
    }

    renderPieChart(name: string, data: any[], colors?: string[]) {
        let chart;
        if (!this.props.isLoading && data.length > 1) {
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                    <SubTitle text={name} />
                    <div className={name.replace(" ", "") + "Chart-container"}>
                        <Chart
                            options={this.pieChartOptions(name, colors)}
                            chartType="PieChart"
                            data={data}
                            width="100%"
                            height="320px"
                            legendToggle
                        />
                    </div>
                </View>
            );
        }
        return chart;
    }

    onExport() {
        if (this.props.onExport) {
            this.props.onExport();
        }
    }

    render() {
        let self = this;
        let venueTitle: any = { color: 'white', textAlign: 'center', fontWeight: 300, padding: '2px', margin: '2px' };
        let noDataElement = <p style={{ color: 'black', textAlign: 'center' }} >No data</p>;
        let filter;
        let filterSummary;
        if (!this.props.canFilter) {
            filterSummary = <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}></Box>
                <Box p={0}>
                    <DefaultButton
                        onClick={() => this.props.onResetFilter(this.props.groupId)}
                        text={"Refresh"} />
                </Box>
            </Box>
        } else if (this.state.filterExpanded) {
            filter = <GroupDashboardFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let tempFilter = new AusComplyDtos.DashboardFilter();
            tempFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <GroupDashboardFilterSummary filter={tempFilter} onClick={() => { }} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <GroupDashboardFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let incidentChart;
        let incidentRefuseEntryReasonsChart;
        let incidentAskedToLeaveChart;
        let noData;

        if (this.state.selectedTab === 0 && !this.props.isLoading) {
            if (!this.props.isLoading && this.state.incidentTypes.length > 1) {
                incidentChart = (
                    <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}>
                                <SubTitle text={"Incident Type"} />
                            </Box>
                            <Box p={0}>
                                <TransparentButton
                                    style={{ padding: '4px', marginBottom: '4px' }}
                                    onClick={() => self.setState({ incidentTypesExpanded: !this.state.incidentTypesExpanded })}
                                    text={this.state.incidentTypesExpanded ? "Show Combined" : "Show By Venue"} />
                            </Box>
                        </Box>
                        {!this.state.incidentTypesExpanded && (
                            <div className={"incidentChart-container"}>
                                <Chart
                                    options={this.pieChartOptions("Incident Type", this.state.incidentTypeColors)}
                                    chartType="PieChart"
                                    data={this.state.incidentTypes}
                                    width="100%"
                                    height="320px"
                                    legendToggle
                                />
                            </div>
                        )}
                        {this.state.incidentTypesExpanded && (
                            <div className={"incidentChart"}>
                                {this.state.incidentTypesByVenue.map((venue) => <div key={"it-" + venue.id}>
                                    <h4 style={venueTitle}>{venue.name}</h4>
                                    {venue.data.length < 2 && (
                                        <>{noDataElement}</>
                                    )}
                                    {(venue.data.length > 1) && (
                                        <Chart
                                            options={this.pieChartOptions("Incident Type [" + venue.name + "]", venue.colors)}
                                            chartType="PieChart"
                                            data={venue.data}
                                            width="100%"
                                            height={"320px"}
                                            legendToggle
                                        />
                                    )}
                                </div>)}
                            </div>
                        )}
                    </View>
                );
            }

            if (!this.props.isLoading && this.state.incidentRefuseEntryReasons.length > 1) {
                incidentRefuseEntryReasonsChart = (
                    <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}>
                                <SubTitle text={"Refuse Entry - Reasons"} />
                            </Box>
                            <Box p={0}>
                                <TransparentButton
                                    style={{ padding: '4px', marginBottom: '4px' }}
                                    onClick={() => self.setState({ incidentRefuseEntryReasonsExpanded: !this.state.incidentRefuseEntryReasonsExpanded })}
                                    text={this.state.incidentTypesExpanded ? "Show Combined" : "Show By Venue"} />
                            </Box>
                        </Box>
                        {!this.state.incidentRefuseEntryReasonsExpanded && (
                            <div className={"incidentRefuseEntryReasonsChart-container"}>
                                <Chart
                                    options={this.barChartOptions("Refuse Entry - Reasons", this.state.incidentRefuseEntryReasonsGridLinesCount, this.state.incidentRefuseEntryReasons.length)}
                                    chartType="BarChart"
                                    data={this.state.incidentRefuseEntryReasons}
                                    legendToggle
                                />
                            </div>
                        )}
                        {this.state.incidentRefuseEntryReasonsExpanded && (
                            <div className={"incidentRefuseEntryReasonsChart"}>
                                {this.state.incidentRefuseEntryReasonsByVenue.map((venue) => <div key={"it-" + venue.id}>
                                    <h4 style={venueTitle}>{venue.name}</h4>
                                    {venue.data.length < 2 && (
                                        <>{noDataElement}</>
                                    )}
                                    {(venue.data.length > 1) && (
                                        <Chart
                                            options={this.barChartOptions("Refuse Entry - Reasons", this.state.incidentRefuseEntryReasonsGridLinesCount, venue.data.length)}
                                            chartType="BarChart"
                                            data={venue.data}
                                            width="100%"
                                            legendToggle
                                        />
                                    )}
                                </div>)}
                            </div>
                        )}
                    </View>
                );
            }

            if (!this.props.isLoading && this.state.incidentAskedToLeave.length > 1) {
                incidentAskedToLeaveChart = (
                    <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}>
                                <SubTitle text={"Asked to leave - Reasons"} />
                            </Box>
                            <Box p={0}>
                                <TransparentButton
                                    style={{ padding: '4px', marginBottom: '4px' }}
                                    onClick={() => self.setState({ incidentAskedToLeaveExpanded: !this.state.incidentAskedToLeaveExpanded })}
                                    text={this.state.incidentTypesExpanded ? "Show Combined" : "Show By Venue"} />
                            </Box>
                        </Box>
                        {!this.state.incidentAskedToLeaveExpanded && (
                            <div className={"incidentAskedToLeaveChart-container"}>
                                <Chart
                                    options={this.barChartOptions("Asked to leave - Reasons", this.state.incidentAskedToLeaveGridLinesCount, this.state.incidentAskedToLeave.length)}
                                    chartType="BarChart"
                                    data={this.state.incidentAskedToLeave}
                                    legendToggle
                                />
                            </div>
                        )}
                        {this.state.incidentAskedToLeaveExpanded && (
                            <div className={"incidentAskedToLeaveChart"}>
                                {this.state.incidentAskedToLeaveByVenue.map((venue) => <div key={"it-" + venue.id}>
                                    <h4 style={venueTitle}>{venue.name}</h4>
                                    {venue.data.length < 2 && (
                                        <>{noDataElement}</>
                                    )}
                                    {(venue.data.length > 1) && (
                                        <Chart
                                            options={this.barChartOptions("Asked to leave - Reasons", this.state.incidentAskedToLeaveGridLinesCount, venue.data.length)}
                                            chartType="BarChart"
                                            data={venue.data}
                                            width="100%"
                                            legendToggle
                                        />
                                    )}
                                </div>)}
                            </div>
                        )}
                    </View>
                );
            }

            if (!this.props.isLoading && this.props.data && this.props.data.length === 0) {
                noData = (
                    <NoRowsMessage message={"No data found"} />
                );
            }
        }

        let map;
        let options;
        let content;
        let incidentCount = "0";
        if (this.state.selectedTab === 1 && !this.props.isLoading) {
            if (this.props.isLoadingIncidents) {
                incidentCount = "...";
            } else if (this.props.incidents) {
                incidentCount = this.props.incidents.length.toString();
            }

            let postcodes: string[] = [];
            if (this.props.options && this.props.options.postcodes && this.props.options.postcodes.length > 0 && this.props.options.showPostcodeBoundaries) {
                postcodes = [...this.props.options.postcodes];
            }
            map = (<Map
                filter={this.props.filter}
                venueMarkers={this.props.venueMarkers}
                postcodeBoundaries={postcodes}
                heatmapData={this.props.heatmapData}
            />);

            content = (
                <>
                    <SubTitle text={"Incidents and Venues"} />
                    {map}
                </>
            );
            options = (
                <LayoutForm fullWidth={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show venue markers"}
                                defaultValue={this.props.options.showVenueMarkers}
                                onChanged={(value) => this.onChangeOptions("showVenueMarkers", value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show postcode boundaries"}
                                defaultValue={this.props.options.showPostcodeBoundaries}
                                onChanged={(value) => this.onChangeOptions("showPostcodeBoundaries", value)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <CheckboxControlGroup
                                text={"Show heatmap for " + incidentCount + " incidents"}
                                defaultValue={this.props.options.showHeatmap}
                                onChanged={(value) => this.onChangeOptions("showHeatmap", value)} />

                        </Grid>
                        <Grid item xs={4}>
                            <DashboardPostcodes
                                items={this.props.options.postcodes}
                                onUpdate={postcodes => this.onChangePostcodes(postcodes)}
                            />
                        </Grid>
                    </Grid>
                </LayoutForm>
            );
        }

        let checklistChart;
        if (this.state.selectedTab === 2 && !this.props.isLoading) {
            if (!this.props.isLoading && this.state.checklists.length > 1) {
                checklistChart = (
                    <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}>
                                <SubTitle text={"Checklist"} />
                            </Box>
                            <Box p={0}>
                                <TransparentButton
                                    style={{ padding: '4px', marginBottom: '4px' }}
                                    onClick={() => self.setState({ checklistsExpanded: !this.state.checklistsExpanded })}
                                    text={this.state.checklistsExpanded ? "Show Combined" : "Show By Venue"} />
                            </Box>
                        </Box>
                        {!this.state.checklistsExpanded && (
                            <div className={"checklistChart-container"}>
                                <Chart
                                    options={this.pieChartOptions("Checklists")}
                                    chartType="PieChart"
                                    data={this.state.checklists}
                                    width="100%"
                                    height="320px"
                                    legendToggle
                                />
                                <Chart
                                    options={this.barChartOptions("Checklists", this.state.checklistsGridLinesCount, this.state.checklists.length)}
                                    chartType="BarChart"
                                    data={this.state.checklists}
                                    width="100%"
                                    legendToggle
                                />
                            </div>
                        )}
                        {this.state.checklistsExpanded && (
                            <div className={"checklistChart"}>
                                {this.state.checklistsByVenue.map((venue) => <div key={"checklist-" + venue.id}>
                                    <h4 style={venueTitle}>{venue.name}</h4>
                                    {venue.data.length < 2 && (
                                        <>{noDataElement}</>
                                    )}
                                    {(venue.data.length > 1) && (
                                        <>
                                            <Chart
                                                options={this.pieChartOptions("Checklist [" + venue.name + "]", venue.colors)}
                                                chartType="PieChart"
                                                data={venue.data}
                                                width="100%"
                                                height={"320px"}
                                                legendToggle
                                            />
                                            <Chart
                                                options={this.barChartOptions("Checklists [" + venue.name + "]", this.state.checklistsGridLinesCount, venue.data.length)}
                                                chartType="BarChart"
                                                data={venue.data}
                                                width="100%"
                                                legendToggle
                                            />
                                        </>
                                    )}
                                </div>)}
                            </div>
                        )}
                    </View>
                );
            }
        }

        let loading;
        let exportData;
        if (this.props.isLoading) {
            loading = <Loading />;
        }

        if (!this.props.isLoading && this.props.data && this.props.data.length > 0) {
            exportData = <TransparentButton onClick={() => this.onExport()} text={"Export"} />
        }

        let dashboardTabs = ["Incident Analysis", "Map"];
        if (true) {
            dashboardTabs.push("Checklist Analysis");
        }


        return (
            <PageLayout
                footerLeftContent={exportData}
            >
                {filterSummary}
                <View style={{ marginTop: '10px' }}>
                    <Tabs selected={this.state.selectedTab} labels={dashboardTabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
                </View>
                {this.state.selectedTab === 0 && (
                    <>
                        {incidentChart}
                        {incidentRefuseEntryReasonsChart}
                        {incidentAskedToLeaveChart}
                    </>
                )}
                {this.state.selectedTab === 1 && (
                    <>
                        {options}
                        {content}
                    </>
                )}
                {this.state.selectedTab === 2 && (
                    <>
                        {checklistChart}
                    </>
                )}
                {loading}
                {noData}
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
            </PageLayout>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(GroupDashboard))