import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

export interface IUserDisplayViewProps {
    user: AusComplyDtos.User;
    classes: any;
    theme: any;
}

class UserDisplayView extends Component<IUserDisplayViewProps, any> {
    constructor(props: IUserDisplayViewProps) {
        super(props)
        this.state = {
            selectedFilePath: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    render() {
        const { classes, theme } = this.props;

        return (
            <>
                <LayoutForm
                    loading={false}
                    saving={false}
                    error={""}
                    fullWidth={true}
                >
                    <form>
                        <ControlGroup text="Name">
                            <TextDisplay>{this.props.user.displayName}</TextDisplay>
                        </ControlGroup>
                        {this.props.user.email && (
                            <ControlGroup text="Email">
                                <TextDisplay>{this.props.user.email}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.mobileNo && (
                            <ControlGroup text="Mobile">
                                <TextDisplay>{this.props.user.mobileNo}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.rsaNumber && (
                            <ControlGroup text="RSA Number">
                                <TextDisplay>{this.props.user.rsaNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.stateLicensed && (
                            <ControlGroup text="State Licensed">
                                <TextDisplay>{this.props.user.stateLicensed}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.licenceNumber && (
                            <ControlGroup text="Licence Number">
                                <TextDisplay>{this.props.user.licenceNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.dateOfBirth && (
                            <ControlGroup text="DOB">
                                <TextDisplay>{this.props.user.dateOfBirth.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.emergencyContactName && (
                            <ControlGroup text="Emergency Contact Name">
                                <TextDisplay>{this.props.user.emergencyContactName}</TextDisplay>
                            </ControlGroup>
                        )}
                        {this.props.user.emergencyContactNumber && (
                            <ControlGroup text="Emergency Contact Mobile">
                                <TextDisplay>{this.props.user.emergencyContactNumber}</TextDisplay>
                            </ControlGroup>
                        )}
                        {(this.props.user.userComplianceDocuments && this.props.user.userComplianceDocuments.length) && (
                            <ControlGroup text="Compliance Documents" labelAbove={true}>
                                <Grid container spacing={1}>
                                    {this.props.user.userComplianceDocuments.filter(item => !item.obsolete).map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={"file-poic-item-" + item.originalFileName + "_" + index.toString()}>
                                                <div key={"file-poi-" + item.originalFileName + "_" + index.toString()}
                                                    style={{
                                                        backgroundColor: '#151515',
                                                        borderRadius: '5px',
                                                        borderColor: item.hasExpired ? theme.custom.colors.error : "#151515",
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        margin: '0 10px 10px 0',
                                                        padding: '10px',
                                                        minWidth: '180px',
                                                        overflow: 'hidden'
                                                    }}>
                                                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                                        <Box p={0}>
                                                            <Thumbnail
                                                                key={"file-" + item.originalFileName + "_" + index.toString()}
                                                                previewPath={item.filePreviewPath}
                                                                displayPath={item.fileDisplayPath}
                                                                isImage={item.isImage}
                                                                text={""}
                                                                onPreview={path => this.onSelectFilePath(path)}
                                                                onDownload={path => this.onDownload(path)}
                                                            />
                                                        </Box>
                                                        <Box p={0} flexGrow={1}>
                                                            <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                                            <p style={{ borderRadius: '3px', backgroundColor: item.hasExpired ? theme.custom.colors.error : "#151515", display: 'inline-block', padding: '3px', margin: '3px 0' }}>{item.dateOfExpiry.display}</p>
                                                            <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </ControlGroup>
                        )}
                        {(this.props.user.userOtherDocuments && this.props.user.userOtherDocuments.length) && (
                            <ControlGroup text="Other Documents" labelAbove={true}>
                                <Grid container spacing={1}>
                                    {this.props.user.userOtherDocuments.filter(item => !item.obsolete).map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={3} key={"file-poi-item-" + item.originalFileName + "_" + index.toString()}>
                                                <div key={"file-poi-" + item.originalFileName + "_" + index.toString()}
                                                    style={{
                                                        backgroundColor: '#151515',
                                                        borderRadius: '5px',
                                                        margin: '0 10px 10px 0',
                                                        padding: '10px',
                                                        minWidth: '180px',
                                                        overflow: 'hidden'
                                                    }}>
                                                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                                        <Box p={0}>
                                                            <Thumbnail
                                                                key={"file-" + item.originalFileName + "_" + index.toString()}
                                                                previewPath={item.filePreviewPath}
                                                                displayPath={item.fileDisplayPath}
                                                                isImage={item.isImage}
                                                                text={""}
                                                                onPreview={path => this.onSelectFilePath(path)}
                                                                onDownload={path => this.onDownload(path)}
                                                            />
                                                        </Box>
                                                        <Box p={0} flexGrow={1}>
                                                            <p style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{item.documentType}</p>
                                                            <p style={{ wordBreak: 'break-all' }}>{item.originalFileName}</p>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </ControlGroup>
                        )}
                        <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
                    </form>
                </LayoutForm>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserDisplayView);