import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import ControlGroup from "./ControlGroup";
import TextDisplay from './TextDisplay'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export interface ITextDisplayControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    labelAbove?: boolean;
    value?: string;
    error?: string;
    style?: any;
}
class TextDisplayControlGroup extends React.PureComponent<ITextDisplayControlGroupProps, any> {

    constructor(props: ITextDisplayControlGroupProps) {
        super(props)
        this.state = {
        }
    }


    render() {
        const { theme, classes } = this.props;
        
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <TextDisplay style={this.props.style}>{this.props.value}</TextDisplay>
                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextDisplayControlGroup);