import React, { Component } from 'react';
import * as AuscomplyDtos from "../../common/dto/AusComply.dtos";
import ControlGroup from '../controls/ControlGroup';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import RadioButtonControl from '../controls/RadioButtonControl';
import { RadioOption } from '../../common/dto/common';
import View from '../common/View';

export interface IRosterFilterSourcesProps {
    filter: AuscomplyDtos.RosterFilter;
    onUpdate: Function;
}

interface IRosterFilterSourcesState {
    selections: MulitSelectOption[];
}

export default class RosterFilterSources extends Component<IRosterFilterSourcesProps, IRosterFilterSourcesState> {
    constructor(props: IRosterFilterSourcesProps) {
        super(props)
        this.state = {
            selections: []
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectionsChanged = this.onSelectionsChanged.bind(this);
        this.setSelections = this.setSelections.bind(this);
    }

    componentDidMount(): void {
        if (this.props.filter) {
            this.setSelections();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.filter &&
            (this.hasChangedHash(this.props.filter) != this.hasChangedHash(prevProps.filter))) {
            this.setSelections();
        }
    }

    hasChangedHash(filter: AuscomplyDtos.RosterFilter) {
        let hash = "";
        if (filter && filter.sources) {
            filter.sources.forEach((item, index) => {
                hash += "#src-" + item.id.toString() + item.selected ? "-selected" : "";
            });
        }
        return hash;
    }

    onValueChanged(fieldName: string, value: any) {
        let filter = { ...this.props.filter };
        filter[fieldName] = value;
        this.props.onUpdate(filter);
    }

    setSelections() {
        let selections: MulitSelectOption[] = [];
        if (this.props.filter.sources) {
            this.props.filter.sources.forEach((item, index) => {

                let selection = new MulitSelectOption();
                selection.id = item.id;
                selection.name = item.name;
                selection.selected = item.selected;
                selection.isCommon = true;
                selections.push(selection);

            });
        }
        this.setState({
            selections
        });
    }

    onSelectionsChanged(value) {
        let selections = [...this.state.selections];
        selections.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            selections
        });

        let filter = { ...this.props.filter };
        if (filter.sources) {
            filter.sources.forEach((item, index) => {
                item.selected = this.state.selections.filter(s => s.id === item.id && s.selected).length > 0;
            });
        }
        this.props.onUpdate(filter)
    }

    render() {

        let selections;
        if (this.state.selections) {
            selections = (
                <MultiSelectControl
                    items={this.state.selections}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    searchText={"Type at least three letters..."}
                    onSelectItemsChanged={this.onSelectionsChanged} />
            );
        }

        return (
            <ControlGroup text={"Roster Source"} labelAbove={true}>
                {selections}
            </ControlGroup>
        );
    }
}