import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { securityFirmServiceApi } from '../services/securityFirmService';

import {
    SECURITY_FIRM_DOCUMENTS_REQUEST,
    securityFirmDocumentsRequestSuccess,
    securityFirmDocumentsRequestFailure,
    SECURITY_FIRM_OTHER_DOCUMENTS_SAVE,
    securityFirmSaveOtherDocumentsSuccess,
    securityFirmSaveOtherDocumentsFailure,
    SECURITY_FIRM_OTHER_DOCUMENT_REMOVE,
    securityFirmRemoveOtherDocumentFailure,
    securityFirmRemoveOtherDocumentSuccess,
    SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE,
    securityFirmSaveComplianceDocumentsSuccess,
    securityFirmSaveComplianceDocumentsFailure,
    SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE,
    securityFirmRemoveComplianceDocumentFailure,
    securityFirmRemoveComplianceDocumentSuccess
} from "../actions/securityFirm";

import {
    notifyError, notifyErrorMessage
} from './common';

import { notificationShow, notificationSuccessShow } from "../actions/notification";

const securityFirmDocumentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_DOCUMENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        securityFirmServiceApi.getDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'], 
                action['showObsoleteDocuments']
            )
                .pipe(
                    map(response => securityFirmDocumentsRequestSuccess(
                        response.securityFirmOtherDocuments, 
                        response.securityFirmOtherDocumentTypes, 
                        response.securityFirmComplianceDocuments, 
                        response.securityFirmComplianceDocumentTypes)),
                    catchError(error => notifyError(error, "securityFirmDocumentsRequestEpic.getDocuments", securityFirmDocumentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmDocumentsRequestEpic", securityFirmDocumentsRequestFailure))
    );


const securityFirmOtherDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_OTHER_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        securityFirmServiceApi.saveOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'], 
                action['documentTypeId'], 
                action['files'])
                .pipe(
                    map(response => securityFirmSaveOtherDocumentsSuccess(response.securityFirmOtherDocuments)),
                    catchError(error => of(
                        securityFirmSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save security firm other documents")
                    ))
                )
        ),
        catchError(error => of(
            securityFirmSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save security firm other documents")
        ))
    );

const securityFirmOtherDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_OTHER_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.removeOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'], 
                action['otherDocument'])
                .pipe(
                    map(response => securityFirmRemoveOtherDocumentSuccess(response.securityFirmOtherDocuments)),
                    catchError(error => of(
                        securityFirmRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove security firm other document")
                    ))
                )
        ),
        catchError(error => of(
            securityFirmRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove security firm other document")
        ))
    );

const securityFirmComplianceDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_COMPLIANCE_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        securityFirmServiceApi.saveComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'], 
                action['documentTypeId'], 
                action['visibleToVenue'],
                action['expiryDate'], 
                action['files'])
                .pipe(
                    map(response => securityFirmSaveComplianceDocumentsSuccess(response.securityFirmComplianceDocuments)),
                    catchError(error => of(
                        securityFirmSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save security firm compliance documents")
                    ))
                )
        ),
        catchError(error => of(
            securityFirmSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save security firm compliance documents")
        ))
    );

const securityFirmComplianceDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_COMPLIANCE_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.removeComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'], 
                action['complianceDocument'])
                .pipe(
                    map(response => securityFirmRemoveComplianceDocumentSuccess(response.securityFirmComplianceDocuments)),
                    catchError(error => of(
                        securityFirmRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove security firm compliance document")
                    ))
                )
        ),
        catchError(error => of(
            securityFirmRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove security firm compliance document")
        ))
    );

export const securityFirmDocumentEpics = combineEpics(
    securityFirmDocumentsRequestEpic,
    securityFirmOtherDocumentsSaveEpic,
    securityFirmOtherDocumentRemoveEpic,
    securityFirmComplianceDocumentsSaveEpic,
    securityFirmComplianceDocumentRemoveEpic
);