import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { externalApiConnectionServiceApi } from '../services/externalApiConnectionService';
import * as externalApiConnectionActions from '../actions/externalApiConnection';
import * as common from './common';
import * as notificationActions from "../actions/notification";
import * as epicCommon from './common';

const externalApiConnectionRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.EXTERNAL_API_CONNECTION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['entityType'],
                action['forVenueId'],
                action['forSecurityFirmId'])
                .pipe(
                    map(response => externalApiConnectionActions.externalApiConnectionRequestSuccess(response.externalApiConnection)),
                    catchError(error => common.notifyError(error, "externalApiConnectionRequestEpic.get", externalApiConnectionActions.externalApiConnectionRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "externalApiConnectionRequestEpic", externalApiConnectionActions.externalApiConnectionRequestFailure))
    );

const externalApiConnectionRefreshRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.EXTERNAL_API_CONNECTION_REFRESH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.refreshToken(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['externalApiConnectionId'])
                .pipe(
                    map(response => externalApiConnectionActions.externalApiConnectionRefreshRequestSuccess(response.externalApiConnection)),
                    catchError(error => common.notifyError(error, "externalApiConnectionRefreshRequestEpic.find", externalApiConnectionActions.externalApiConnectionRefreshRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "externalApiConnectionRefreshRequestEpic", externalApiConnectionActions.externalApiConnectionRefreshRequestFailure))
    );

const externalApiConnectionRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.EXTERNAL_API_CONNECTION_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.remove(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['externalApiConnectionId'])
                .pipe(
                    map(response => externalApiConnectionActions.externalApiConnectionRemoveRequestSuccess(response.externalApiConnection)),
                    catchError(error => common.notifyError(error, "externalApiConnectionRemoveRequestEpic.find", externalApiConnectionActions.externalApiConnectionRemoveRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "externalApiConnectionRemoveRequestEpic", externalApiConnectionActions.externalApiConnectionRemoveRequestFailure))
    );

const externalApiConnectionUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.EXTERNAL_API_CONNECTION_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.update(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['externalApiConnection'])
                .pipe(
                    map(response => externalApiConnectionActions.externalApiConnectionUpdateRequestSuccess(response.externalApiConnection)),
                    catchError(error => common.notifyError(error, "externalApiConnectionUpdateRequestEpic.Update", externalApiConnectionActions.externalApiConnectionUpdateRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "externalApiConnectionUpdateRequestEpic", externalApiConnectionActions.externalApiConnectionUpdateRequestFailure))
    );

const roublerEmployeeMappingRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.roublerEmployeeMappings(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => externalApiConnectionActions.roublerEmployeeMappingRequestSuccess(response.roublerEmployeeMapping)),
                    catchError(error => common.notifyError(error, "roublerEmployeeMappingRequestEpic.roublerEmployeeMappings", externalApiConnectionActions.roublerEmployeeMappingRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "roublerEmployeeMappingRequestEpic", externalApiConnectionActions.roublerEmployeeMappingRequestFailure))
    );

const roublerEmployeeMappingUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.setRoublerEmployeeMappings(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['entityMapping'])
                .pipe(
                    map(response => externalApiConnectionActions.roublerEmployeeMappingUpdateRequestSuccess(response.roublerEmployeeMapping)),
                    catchError(error => common.notifyError(error, "roublerEmployeeMappingUpdateRequestEpic.setRoublerEmployeeMappings", externalApiConnectionActions.roublerEmployeeMappingUpdateRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "roublerEmployeeMappingUpdateRequestEpic", externalApiConnectionActions.roublerEmployeeMappingUpdateRequestFailure))
    );

const roublerEmployeeExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.ROUBLER_EMPLOYEE_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            externalApiConnectionServiceApi.exportUserToRoubler(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'])
                .pipe(
                    map(response => externalApiConnectionActions.roublerEmployeeExportRequestSuccess()),
                    catchError(error => common.notifyError(error, "roublerEmployeeExportRequestEpic.setRoublerEmployeeMappings", externalApiConnectionActions.roublerEmployeeExportRequestFailure))
                )
        ),
        catchError(error => common.notifyError(error, "roublerEmployeeExportRequestEpic", externalApiConnectionActions.roublerEmployeeExportRequestFailure))
    );

const roublerEmployeeExportRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.ROUBLER_EMPLOYEE_EXPORT_REQUEST_SUCCESS),
        withLatestFrom(state$),
        map((action: any) => notificationActions.notificationSuccessShow("Open Roubler to complete importing the user"))
    );

const externalApiConnectionUpdateRequestEpicSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(externalApiConnectionActions.EXTERNAL_API_CONNECTION_UPDATE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        map((action: any) => externalApiConnectionActions.roublerEmployeeMappingRequest())
    );

const rollerConfigurationRequestEpic = epicCommon.epicApiGet(
    externalApiConnectionActions.ROLLER_CONFIGURATION_REQUEST,
    AusComplyDtos.GetRollerConfiguration,
    [],
    response => externalApiConnectionActions.rollerConfigurationRequestSuccess(response.rollerConfiguration),
    externalApiConnectionActions.rollerConfigurationRequestFailure);

const rollerConfigurationUpdateRequestEpic = epicCommon.epicApiPost(
    externalApiConnectionActions.ROLLER_CONFIGURATION_UPDATE_REQUEST,
    AusComplyDtos.PostRollerConfiguration,
    ['rollerConfiguration'],
    response => externalApiConnectionActions.rollerConfigurationUpdateRequestSuccess(response.rollerConfiguration),
    externalApiConnectionActions.rollerConfigurationUpdateRequestFailure);

const externalApiConnectionWebhookMessagesRequestEpic = epicCommon.epicApiPost(
    externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REQUEST,
    AusComplyDtos.PostExternalApiConnectionWebhookMessages,
    ['page', 'pageSize', 'filter'],
    response => externalApiConnectionActions.externalApiConnectionWebhookMessagesRequestSuccess(response.data, response.paging, response.filter),
    externalApiConnectionActions.externalApiConnectionWebhookMessagesRequestFailure);

const externalApiConnectionWebhookMessageReprocessRequestEpic = epicCommon.epicApiPost(
    externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_REPROCESS_REQUEST,
    AusComplyDtos.PostExternalApiConnectionWebhookMessageReProcess,
    ['entityType', 'externalApiConnectionWebhookMessageId'],
    response => externalApiConnectionActions.externalApiConnectionWebhookMessageReProcessRequestSuccess(),
    externalApiConnectionActions.externalApiConnectionWebhookMessageReProcessRequestFailure);

const externalApiConnectionWebhookMessagesReprocessRequestEpic = epicCommon.epicApiPost(
    externalApiConnectionActions.EXTERNAL_API_CONNECTION_WEBHOOKMESSAGES_BATCH_REPROCESS_REQUEST,
    AusComplyDtos.PostExternalApiConnectionWebhookMessagesReProcess,
    ['entityType', 'fromDate', 'tickets'],
    response => externalApiConnectionActions.externalApiConnectionWebhookMessageReProcessRequestSuccess(),
    externalApiConnectionActions.externalApiConnectionWebhookMessageReProcessRequestFailure);

export const externalApiConnectionEpics = combineEpics(
    externalApiConnectionRefreshRequestEpic,
    externalApiConnectionRemoveRequestEpic,
    externalApiConnectionRequestEpic,
    externalApiConnectionUpdateRequestEpic,
    roublerEmployeeMappingRequestEpic,
    roublerEmployeeMappingUpdateRequestEpic,
    roublerEmployeeExportRequestEpic,
    roublerEmployeeExportRequestSuccessEpic,
    externalApiConnectionUpdateRequestEpicSuccessEpic,
    rollerConfigurationRequestEpic,
    rollerConfigurationUpdateRequestEpic,
    externalApiConnectionWebhookMessagesRequestEpic,
    externalApiConnectionWebhookMessageReprocessRequestEpic,
    externalApiConnectionWebhookMessagesReprocessRequestEpic
);