import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import NoRowsMessage from '../../common/NoRowsMessage';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import FileUpload from '../../common/FileUpload';
import Information from '../../common/Information';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { KeyName } from '../../../common/dto/common';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardLabel from '../../common/CardLabel';
import CardList from '../../common/CardList';
import SubTitle from '../../common/SubTitle';
import ResponsiveList from '../../common/ResponsiveList';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IFacialRecognitionWatchlistMigrationProps {
    isLoading: boolean;
    isSaving: boolean;
    migration: AusComplyDtos.FacialRecognitionWatchlistMigration;
    onSave: Function;
    onSet: Function;
}

interface IFacialRecognitionWatchlistMigrationState {
}

export default class FacialRecognitionWatchlistMigration extends Component<IFacialRecognitionWatchlistMigrationProps, IFacialRecognitionWatchlistMigrationState> {
    constructor(props: IFacialRecognitionWatchlistMigrationProps) {
        super(props)
        this.state = {
        };
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isSaving && !this.props.isSaving && !this.props.migration.error) {
            this.onCancel();
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let migration = { ...this.props.migration };
        migration[fieldName] = value;
        this.props.onSet(migration)
    }

    onCancel() {
        this.props.onSet({ facialRecognitionWatchlistId: -1 });
    }

    render() {
        let self = this;
        let show = this.props.migration && this.props.migration.facialRecognitionWatchlistId > -1;
        let content;

        if (this.props.isLoading) {
            content = (
                <ControlGroup labelAbove={true}>
                    <Loading />
                </ControlGroup>
            )
        } else if (this.props.isSaving) {
            content = (
                <ControlGroup labelAbove={true}>
                    <Saving />
                </ControlGroup>
            )
        } else if (show && !this.props.isLoading && this.props.migration) {
            content = <LayoutForm>
                <ControlGroup text="Migrate Watchlist">
                    <TextDisplay>{this.props.migration.facialRecognitionWatchlist.displayName}</TextDisplay>
                </ControlGroup>
                <SelectControlGroup
                    text="Migrate to server"
                    onChange={(value) => this.onValueChanged("facialRecognitionServerId", value)}
                    defaultValue={self.props.migration.facialRecognitionServerId}>
                    <option value={0} />
                    {this.props.migration.facialRecognitionServers.map((facialRecognitionServer, index) => (
                        <option key={facialRecognitionServer.facialRecognitionServerId} value={facialRecognitionServer.facialRecognitionServerId}>{facialRecognitionServer.name}</option>
                    ))}
                </SelectControlGroup>
                <ControlGroup text=" ">
                    <Grid container spacing={2} style={{ marginTop: '40px' }}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={() => this.onCancel()}></DefaultButton>
                        </Grid>
                    </Grid>
                </ControlGroup>
            </LayoutForm>
        }

        return (
            <div style={{ display: 'inline-table' }}>
                <FullPageDialog open={show} title={"Migrate watchlist"} onDismissed={() => this.onCancel()}>
                    {content}
                </FullPageDialog>
            </div>
        );

    }
}