import { connect } from 'react-redux'
import CheckListTemplate from '../components/checklists/checklistTemplate/ChecklistTemplate'
import { withRouter } from "react-router";
import * as checklistTemplateActions from '../common/actions/checklistTemplate';
import { loadAllReferenceData } from '../common/actions/referenceData';
import * as groupActions from '../common/actions/groups';


const isLoading = (state) =>
    {
        if (state.checklistTemplate.checklistTemplateLoading) {
            return true;
        }
        if (state.referenceData.checklistTemplateCategoriesLoading) {
            return true;
        }
        return false;
    }

    
const mapStateToProps = (state, props) => {
    return {
        venueId: undefined,
        groupId: props.match.params.groupId,
        checklistTemplateId: props.match.params.checklistTemplateId,
        checklistTemplate: state.checklistTemplate.checklistTemplate,
        groupedChecklistTemplateItems: state.checklistTemplate.checklistTemplate.groupedChecklistTemplateItems,
        checklistTemplateItemsVersion: state.checklistTemplate.checklistTemplateItemsVersion,
        isLoading: isLoading(state),
        isSaving: state.checklistTemplate.checklistTemplateSaving,
        canEditVenues: false,
        canEditSecurityFirms: false,
        canEditIndustryCategories: false,
        canEditStates: false,
        canEditCategories: true,
        venues: [],
        securityFirms: [],
        industryCategories: [],
        states: [],
        checklistTemplateCategories: state.referenceData.checklistTemplateCategories
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (checklistTemplateId) => {
            dispatch(checklistTemplateActions.loadChecklistTemplateRequest(checklistTemplateId))
        },
        onCreate: () => {
            dispatch(checklistTemplateActions.newChecklistTemplateRequest())
        },
        onLoadReferenceData: () => {
            dispatch(loadAllReferenceData());
        },
        onUpdate: (checklistTemplate) => {
            dispatch(checklistTemplateActions.checklistTemplateUpdate(checklistTemplate));
        },
        onSave: (checklistTemplate) => {
            dispatch(checklistTemplateActions.saveChecklistTemplateRequest(checklistTemplate));
        },
        onUpdateItem: (checklistTemplateItem) => {
            dispatch(checklistTemplateActions.setChecklistTemplateItem(checklistTemplateItem));
        },
        onAddItem: (checklistItemType, parentId, position) => {
            dispatch(checklistTemplateActions.addChecklistTemplateItem(checklistItemType, parentId, position))
        },
        onMoveItem: (checklistTemplateItemId, parentId, position) => {
            dispatch(checklistTemplateActions.moveChecklistTemplateItem(checklistTemplateItemId, parentId, position))
        },
        onRemoveItem: (checklistTemplateItem) => {
            dispatch(checklistTemplateActions.removeChecklistTemplateItem(checklistTemplateItem))
        },
        onReloadGroup: (groupId) => {
            dispatch(groupActions.groupRequest(groupId))
        }
    }
}

const AdminGroupChecklistTemplateContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckListTemplate))

export default AdminGroupChecklistTemplateContainer