import { connect } from 'react-redux'
import DeleteRoleType from '../components/admin/roleTypes/deleteRoleType/DeleteRoleType'
import { roleTypeLoadRequest, roleTypeUpdate, roleTypeUpdateSave, roleTypeDeleteRequest  } from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        roleTypeId: props.roleTypeId,
        isLoading: state.userRole.roleType.isLoading,
        roleType: state.userRole.roleType.roleType,
        isDeleting: state.userRole.isSaving,
        error: state.userRole.error,
        onSuccess: props.onSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (roleTypeId) => {
            dispatch(roleTypeLoadRequest(roleTypeId))
        },
        onDelete: (roleTypeId) => {
            dispatch(roleTypeDeleteRequest(roleTypeId))
        }
    }
}

const AdminDeleteRoleTypeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteRoleType)

export default AdminDeleteRoleTypeContainer