import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITextDisplayProps {
    theme: any;
    classes: any;
    style?: any;
}

class TextDisplay extends React.PureComponent<ITextDisplayProps, any> {
    render() {
        const { theme, classes } = this.props;
        return (
            <pre style={{...theme.custom.textDisplay, ...this.props.style}}>{this.props.children}</pre>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextDisplay);