import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICardPrintProps {
    theme: any;
    text?: string;
    style?: any;
}

class CardPrint extends React.PureComponent<ICardPrintProps, any> {
    constructor(props: ICardPrintProps) {
        super(props);
    }

    render() {
        const { theme } = this.props;
        return (
            <p
                style={{
                    ...theme.custom.card.typography,
                    overflowWrap: 'anywhere',
                    minWidth: 0, ...this.props.style
                }}
            >{this.props.text}{this.props.children}</p>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardPrint);
