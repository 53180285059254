import { makeActionCreator } from './helpers';

export const
    SUBLOCATIONS_REQUEST = "SUBLOCATIONS_REQUEST",
    SUBLOCATIONS_REQUEST_SUCCESS = "SUBLOCATIONS_REQUEST_SUCCESS",
    SUBLOCATIONS_REQUEST_FAILURE = "SUBLOCATIONS_REQUEST_FAILURE",
    SUBLOCATIONS_REFRESH = "SUBLOCATIONS_REFRESH",
    SUBLOCATION_CREATE_REQUEST = "SUBLOCATION_CREATE_REQUEST",
    SUBLOCATION_CREATE_REQUEST_SUCCESS = "SUBLOCATION_CREATE_REQUEST_SUCCESS",
    SUBLOCATION_CREATE_REQUEST_FAILURE = "SUBLOCATION_CREATE_REQUEST_FAILURE",
    SUBLOCATION_REQUEST = "SUBLOCATION_REQUEST",
    SUBLOCATION_REQUEST_SUCCESS = "SUBLOCATION_REQUEST_SUCCESS",
    SUBLOCATION_REQUEST_FAILURE = "SUBLOCATION_REQUEST_FAILURE",
    SUBLOCATION_UPDATE = "SUBLOCATION_UPDATE",
    SUBLOCATION_SAVE_REQUEST = "SUBLOCATION_SAVE_REQUEST",
    SUBLOCATION_SAVE_REQUEST_SUCCESS = "SUBLOCATION_SAVE_REQUEST_SUCCESS",
    SUBLOCATION_SAVE_REQUEST_FAILURE = "SUBLOCATION_SAVE_REQUEST_FAILURE",
    SUBLOCATION_RESET = "SUBLOCATION_RESET";


export const subLocationsRequest = makeActionCreator(SUBLOCATIONS_REQUEST, 'page', 'pageSize', 'filter');
export const subLocationsRequestSuccess = makeActionCreator(SUBLOCATIONS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const subLocationsRequestFailure = makeActionCreator(SUBLOCATIONS_REQUEST_FAILURE, 'error');
export const subLocationCreateRequest = makeActionCreator(SUBLOCATION_CREATE_REQUEST, 'forGroupId', 'forVenueId');
export const subLocationCreateRequestSuccess = makeActionCreator(SUBLOCATION_CREATE_REQUEST_SUCCESS, 'subLocation');
export const subLocationCreateRequestFailure = makeActionCreator(SUBLOCATION_CREATE_REQUEST_FAILURE, 'error');
export const subLocationRequest = makeActionCreator(SUBLOCATION_REQUEST, 'subLocationId');
export const subLocationRequestSuccess = makeActionCreator(SUBLOCATION_REQUEST_SUCCESS, 'subLocation');
export const subLocationRequestFailure = makeActionCreator(SUBLOCATION_REQUEST_FAILURE, 'error');
export const subLocationSaveRequest = makeActionCreator(SUBLOCATION_SAVE_REQUEST, 'subLocation');
export const subLocationSaveRequestSuccess = makeActionCreator(SUBLOCATION_SAVE_REQUEST_SUCCESS, 'subLocation');
export const subLocationSaveRequestFailure = makeActionCreator(SUBLOCATION_SAVE_REQUEST_FAILURE, 'error');
export const subLocationUpdate = makeActionCreator(SUBLOCATION_UPDATE, 'subLocation');
export const subLocationsRefresh = makeActionCreator(SUBLOCATIONS_REFRESH);
export const subLocationReset = makeActionCreator(SUBLOCATION_RESET);