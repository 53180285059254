import React from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";

export interface IUserActionAuditsRowProps {
    userActionAudit: AusComplyDtos.UserActionAudit;
    onClick: Function;
    classes: any;
    theme: any;
}

class UserActionAuditsRow extends React.Component<IUserActionAuditsRowProps, any> {

    render() {
        const { classes } = this.props;
        let item = this.props.userActionAudit;
        let className = classes.rowStatusPending;

        return (
            <tr className={classes.tableTr} key={"row-" + item.userActionAuditId.toString()} onClick={() => this.props.onClick(this.props.userActionAudit)} >
                <td className={className} ></td>
                <td>
                    <p>
                        {item.dateEnteredDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {item.actionTypeDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {item.display}
                    </p>
                </td>
                <td>
                    {item.locationFound && (
                        <p>
                            ({item.latitude},{item.longitude} )
                        </p>
                    )}
                </td>
                <td>
                    <p>
                        {item.venue}
                    </p>
                </td>
                <td>
                    <p>
                        {item.securityFirm}
                    </p>
                </td>
                <td>
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserActionAuditsRow);