import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import { MulitSelectOption } from '../../../common/dto/common';
import Tabs from '../../common/Tabs';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import DefaultButton from '../../controls/DefaultButton';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SingleSelectListControl from '../../controls/SingleSelectListControl';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import PrimaryButton from '../../controls/PrimaryButton';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardList from '../../common/CardList';
import FullPageDialog from '../../layout/FullPageDialog';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { SingleSelectList } from '../../../common/dto/common';
import { KeyName } from '../../../common/dto/common';

export interface IFacialRecognitionNotificationEventsProps {
    venueId: number;
    isLoading: boolean;
    isSaving: boolean;
    facialRecognitionEvents: AusComplyDtos.FacialRecognitionEvent[];
    facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent;
    facialRecognitionCameras: AusComplyDtos.FacialRecognitionCamera[],
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[],
    incidentTypes: AusComplyDtos.IncidentType[],
    incidentTypeCategoryTypes: AusComplyDtos.IncidentTypeCategoryTypes[],
    incidentFlagTypes: AusComplyDtos.IncidentFlagType[],
    onLoad: Function;
    onSet: Function;
    onSave: Function;
    classes: any;
    theme: any;
    history: any;
    location: any;
}

interface IFacialRecognitionNotificationEventsState {
    cameras: MulitSelectOption[];
    categories: MulitSelectOption[];
    flags: MulitSelectOption[];
}

class FacialRecognitionNotificationEvents extends Component<IFacialRecognitionNotificationEventsProps, IFacialRecognitionNotificationEventsState> {
    constructor(props: IFacialRecognitionNotificationEventsProps) {
        super(props)

        this.state = {
            cameras: [],
            categories: [],
            flags: []
        };
        this.onReset = this.onReset.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onSelectCameraChanged = this.onSelectCameraChanged.bind(this);
        this.onSelectIncidentFlagTypeChanged = this.onSelectIncidentFlagTypeChanged.bind(this);
        this.onSelectIncidentCategoryTypesChanged = this.onSelectIncidentCategoryTypesChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
    }

    handleTabChange = (event, value) => {
        var location = this.props.location.pathname || "";
        switch (value) {
            case 0:
                this.props.history.push(location.replace("/watchlistevents", ""));
                break;
            case 1:
                this.props.history.push(location.replace("/watchlistevents", "/cameras"));
                break;
        }
    };

    onCancelEdit() {
        var facialRecognitionEvent = new AusComplyDtos.FacialRecognitionEvent();
        facialRecognitionEvent.facialRecognitionEventId = -1;
        this.props.onSet(facialRecognitionEvent);
    }

    loadSelections(facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        let cameras: MulitSelectOption[] = [];
        if (this.props.facialRecognitionCameras) {
            this.props.facialRecognitionCameras.forEach((item, index) => {
                let isSelected = false;
                if (facialRecognitionEvent.facialRecognitionEventCameras) {
                    isSelected = facialRecognitionEvent.facialRecognitionEventCameras.filter(f => f.facialRecognitionCameraId == item.facialRecognitionCameraId).length > 0;
                }
                cameras.push({
                    id: item.facialRecognitionCameraId,
                    name: item.displayName,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }

        let categories: MulitSelectOption[] = [];
        if (this.props.incidentTypeCategoryTypes) {
            this.props.incidentTypeCategoryTypes.forEach((incidentType, index) => {
                if (incidentType.incidentTypeId.toString() === (facialRecognitionEvent.incidentTypeId || 0).toString()) {
                    incidentType.incidentCategoryTypes.forEach((item, index) => {
                        let isSelected = false;
                        if (facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes) {
                            isSelected = facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes.filter(f => f.incidentCategoryTypeId == item.incidentCategoryTypeId).length > 0;
                        }
                        categories.push({
                            id: item.incidentCategoryTypeId,
                            name: item.name,
                            isCommon: false,
                            selected: isSelected
                        });
                    });
                }
            });
        }

        let flags: MulitSelectOption[] = [];
        if (this.props.incidentFlagTypes) {
            this.props.incidentFlagTypes.forEach((item, index) => {
                let isSelected = false;
                if (facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes) {
                    isSelected = facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes.filter(f => f.incidentFlagTypeId == item.incidentFlagTypeId).length > 0;
                }
                flags.push({
                    id: item.incidentFlagTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }

        this.setState({
            cameras,
            categories,
            flags
        }, () => {
        });
    }

    onCreate() {
        var facialRecognitionEvent = new AusComplyDtos.FacialRecognitionEvent();
        facialRecognitionEvent.venueId = this.props.venueId;
        this.loadSelections(facialRecognitionEvent);
        this.props.onSet(facialRecognitionEvent);
    }

    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        var reloadSelections = false;
        if (fieldName === "incidentTypeId" && facialRecognitionEvent[fieldName] != value) {
            reloadSelections = true;
        }
        facialRecognitionEvent[fieldName] = value;
        if (reloadSelections) {
            this.loadSelections(facialRecognitionEvent);

        }
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectCameraChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventCameras = [];
        items.forEach(item => {
            var facialRecognitionEventCamera = new AusComplyDtos.FacialRecognitionEventCamera();
            facialRecognitionEventCamera.facialRecognitionCameraId = item.id;
            facialRecognitionEvent.facialRecognitionEventCameras.push(facialRecognitionEventCamera);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectIncidentFlagTypeChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes = [];
        items.forEach(item => {
            var facialRecognitionEventIncidentFlagType = new AusComplyDtos.FacialRecognitionEventIncidentFlagType();
            facialRecognitionEventIncidentFlagType.incidentFlagTypeId = item.id;
            facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes.push(facialRecognitionEventIncidentFlagType);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectIncidentCategoryTypesChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes = [];
        items.forEach(item => {
            var facialRecognitionEventIncidentCategoryType = new AusComplyDtos.FacialRecognitionEventIncidentCategoryType();
            facialRecognitionEventIncidentCategoryType.incidentCategoryTypeId = item.id;
            facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes.push(facialRecognitionEventIncidentCategoryType);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    getCommands(facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        var commands: KeyName[] = [];
        if (!facialRecognitionEvent.obsolete) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });
        } else {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        switch (command) {
            case "edit":
                this.loadSelections(facialRecognitionEvent);
                this.props.onSet(facialRecognitionEvent);
                break;
            case "delete":
                var facialRecognitionEvent = { ...facialRecognitionEvent };
                facialRecognitionEvent.obsolete = true;
                this.props.onSet(facialRecognitionEvent);
                this.props.onSave();
                break;
            case "restore":
                var updated = { ...facialRecognitionEvent };
                updated.obsolete = false;
                this.props.onSet(updated);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    onReset() {
        this.props.onLoad(this.props.venueId);
    }

    onSave() {
        this.props.onSave();
    }

    render() {


        let createFab;
        createFab = (
            <DefaultFab onClick={() => this.onCreate()} isExtended={true}>Add Watchlist Event</DefaultFab>
        );

        let showItem = false;
        let facialRecognitionEvent;
        if (this.props.facialRecognitionEvent != null && this.props.facialRecognitionEvent.facialRecognitionEventId != -1) {
            let item = this.props.facialRecognitionEvent;
            showItem = true;
            facialRecognitionEvent = (
                <LayoutForm saving={this.props.isSaving}>
                    <SubTitle text={"For Events on"} />
                    <SelectControlGroup
                        text="Watchlist"
                        onChange={(value) => this.onValueChanged("facialRecognitionWatchlistId", value)}
                        defaultValue={item.facialRecognitionWatchlistId}>
                        <option value={0} />
                        {this.props.facialRecognitionWatchlists.map((option, index) => (
                            <option key={option.facialRecognitionWatchlistId} value={option.facialRecognitionWatchlistId}>{option.displayName}</option>
                        ))}
                    </SelectControlGroup>
                    <CheckboxControlGroup
                        text={"Any Camera"}
                        label={" "}
                        defaultValue={item.anyCamera}
                        onChanged={(value) => this.onValueChanged("anyCamera", value)} />
                    {!item.anyCamera && (
                        <ControlGroup text={" "}>
                            <CardContainer
                                title={"Cameras"}
                                style={{ paddingTop: 0 }}>
                                <CardContent style={{ paddingTop: 0 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <MultiSelectControl
                                                label={""}
                                                items={this.state.cameras}
                                                searchText="Type at least three letters..."
                                                minimumCharactersRequired={3}
                                                onSelectItemsChanged={this.onSelectCameraChanged}
                                                canShowAll={true} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardContainer>
                        </ControlGroup>
                    )}
                    <SubTitle text={"Create a new Incident"} />
                    <SelectControlGroup
                        text="Incident Type"
                        onChange={(value) => this.onValueChanged("incidentTypeId", value)}
                        defaultValue={item.incidentTypeId}>
                        <option value={0} />
                        {this.props.incidentTypes.map((option, index) => (
                            <option key={option.incidentTypeId} value={option.incidentTypeId}>{option.name}</option>
                        ))}
                    </SelectControlGroup>
                    <ControlGroup text={" "}>
                        <CardContainer
                            title={"Incident Categories"}
                            style={{ paddingTop: 0 }}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <MultiSelectControl
                                            label={""}
                                            items={this.state.categories}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectIncidentCategoryTypesChanged}
                                            canShowAll={true} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardContainer>
                    </ControlGroup>
                    <ControlGroup text={" "}>
                        <CardContainer
                            title={"Incident Flags"}
                            style={{ paddingTop: 0 }}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <MultiSelectControl
                                            label={""}
                                            items={this.state.flags}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectIncidentFlagTypeChanged}
                                            canShowAll={true} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardContainer>
                    </ControlGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancelEdit}></DefaultButton>
                        </Grid>
                    </Grid>
                </LayoutForm>
            );
        }

        let events;
        if (!this.props.isLoading && !this.props.isSaving && this.props.facialRecognitionEvents) {
            events = (<CardList
                loading={this.props.isLoading}
                data={this.props.facialRecognitionEvents}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isDanger={item => item.obsolete}
                toggleDanger={true}
                canEdit={true}
                card={(item: AusComplyDtos.FacialRecognitionEvent) => <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>Watchlist</CardTitle>
                            <CardTypography>{item.watchlistDisplay}</CardTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>For Camera(s)</CardTitle>
                            <CardTypography>{item.cameraDisplay}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>Creates an incident type</CardTitle>
                            <CardTypography>{item.incidentTypeDisplay}</CardTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>With incident categories</CardTitle>
                            <CardTypography>{item.incidentCategoryTypesDisplay}</CardTypography>
                        </Grid>
                        {item.incidentFlagTypesDisplay !== "" && (
                            <Grid item xs={12} sm={6}>
                                <CardTitle>With incident flags</CardTitle>
                                <CardTypography>{item.incidentFlagTypesDisplay}</CardTypography>
                            </Grid>
                        )}
                    </Grid>
                </>}
            />);

            /*
            var commands: KeyName[] = [];
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });


            events = this.props.facialRecognitionEvents.map((event, index) => {
                return (
                    <CardRow key={"facialRecognitionEventId-" + event.facialRecognitionEventId} commands={commands} onCommand={(command) => this.onCommand(command, event)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CardTitle>Watchlist</CardTitle>
                                <CardTypography>{event.watchlistDisplay}</CardTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CardTitle>For Camera(s)</CardTitle>
                                <CardTypography>{event.cameraDisplay}</CardTypography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CardTitle>Creates an incident type</CardTitle>
                                <CardTypography>{event.incidentTypeDisplay}</CardTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CardTitle>With incident categories</CardTitle>
                                <CardTypography>{event.incidentCategoryTypesDisplay}</CardTypography>
                            </Grid>
                            {event.incidentFlagTypesDisplay !== "" && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>With incident flags</CardTitle>
                                    <CardTypography>{event.incidentFlagTypesDisplay}</CardTypography>
                                </Grid>
                            )}
                        </Grid>
                    </CardRow>
                );
            }); */
        }

        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    fullWidth={true}
                    header={<PageTitle title="Facial Recognition Notification"></PageTitle>}
                >
                    <div style={{ display: 'display-block', marginTop: '10px' }}>
                        <Tabs selected={2} labels={["History", "Cameras", "Watchlist Events"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
                    </div>

                    {events}


                </LayoutForm>
                <FullPageDialog open={showItem} title="Watchlist Event" onDismissed={() => this.onCancelEdit()}>
                    {facialRecognitionEvent}
                </FullPageDialog>
                <VenueQuickActionsContainer>
                    {createFab}
                </VenueQuickActionsContainer>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionNotificationEvents);