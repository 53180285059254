import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmUtilities from '../components/admin/securityFirm/SecurityFirmUtilities'
import * as adminActions from '../common/actions/admin'

const mapStateToProps = (state, props) => {
    return {
        securityFirmId: props.securityFirmId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNotificationClear: (securityFirmId, simpleDate) => {
            dispatch(adminActions.adminNotificationsSecurityFirmClearRequest(securityFirmId, simpleDate))
        }
    }
}

const AdminSecurityFirmUtilitiesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmUtilities))

export default AdminSecurityFirmUtilitiesContainer