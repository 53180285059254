import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SignOnRegisterBreak from '../components/signon/SignOnRegisterBreak'
import * as signOnRegisterActions from '../common/actions/signOn';

const mapStateToProps = (state, props) => {
    return {
        signOnRegisterId: state && state.signOn && state.signOn.signOnRegisterBreak.signOnRegisterId,
        signOnRegisterBreak: state && state.signOn && state.signOn.signOnRegisterBreak.signOnRegisterBreak,
        isLoading: state && state.signOn && state.signOn.signOnRegisterBreak.isLoading,
        isSaving: state && state.signOn && state.signOn.signOnRegisterBreak.isSaving,
        error: state && state.signOn && state.signOn.signOnRegisterBreak.error,
        onClose: props.onClose
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (signOnRegisterBreak) => {
            dispatch(signOnRegisterActions.signOnBreakSet(signOnRegisterBreak))
        },
        onSave: (signOnRegisterBreak) => {
            dispatch(signOnRegisterActions.signOnBreakUpsertRequest(signOnRegisterBreak))
        },
        onCancel: () => {
            dispatch(signOnRegisterActions.signOnBreakClear())
        }
    }
}

const SignOnRegisterBreakContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnRegisterBreak))

export default SignOnRegisterBreakContainer