import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface IIncidentActionTemplateLegendProps {
    classes: any;
    theme: any;
}

class IncidentActionTemplateLegend extends React.Component<IIncidentActionTemplateLegendProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentCurrent}></div>
                    <div className={classes.colorSwatchText}>Global</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentApproved}></div>
                    <div className={classes.colorSwatchText}>Group</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentPending}></div>
                    <div className={classes.colorSwatchText}>Venue</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentDeleted}></div>
                    <div className={classes.colorSwatchText}>Removed</div>
                </Box>
            </Box>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentActionTemplateLegend);