import update from "immutability-helper";
import {
    FACIAL_RECOGNITION_SERVERS_REQUEST,
    FACIAL_RECOGNITION_SERVERS_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_SERVERS_REQUEST_FAILURE,
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST,
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_FAILURE,
    FACIAL_RECOGNITION_SERVER_RESET,
    FACIAL_RECOGNITION_SERVER_SET,
    FACIAL_RECOGNITION_SERVER_REQUEST,
    FACIAL_RECOGNITION_SERVER_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_SERVER_REQUEST_FAILURE,
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST,
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE,
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST,
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS,
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_FAILURE

} from "../actions/facialRecognitionServer";

import * as facialRecognitionServerActions from "../actions/facialRecognitionServer";

const initialState = {
    facialRecognitionServers: {
        venueId: -1,
        isLoading: false,
        facialRecognitionServers: [],
    },
    facialRecognitionServer: {
        facialRecognitionServerId: -1,
        isLoading: false,
        isSaving: false,
        facialRecognitionServer: { facialRecognitionServerId: 0 },
    }
};

function setIsLoading(state, isLoading, venueId) {
    let newState = update(state, {
        facialRecognitionServers: {
            isLoading: { $set: isLoading },
            venueId: { $set: venueId }
        }
    });
    return newState;
}

function setIsSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionServer: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setServers(state, Servers) {
    let newState = update(state, {
        facialRecognitionServers: {
            facialRecognitionServers: { $set: Servers }
        }
    });
    return newState;
}

function loadingFacialRecognitionServer(state, isLoading) {
    let newState = update(state, {
        facialRecognitionServer: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionServer(state, facialRecognitionServer) {
    let newState = update(state, {
        facialRecognitionServer: {
            facialRecognitionServer: { $set: facialRecognitionServer }
        }
    });
    return newState;
}

function savingFacialRecognitionServer(state, isSaving) {
    let newState = update(state, {
        facialRecognitionServer: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFacialRecognitionServerId(state, facialRecognitionServerId) {
    let newState = update(state, {
        facialRecognitionServer: {
            facialRecognitionServerId: { $set: facialRecognitionServerId }
        }
    });
    return newState;
}



export default function facialRecognitionServer(state = initialState, action) {
    switch (action.type) {
        case FACIAL_RECOGNITION_SERVERS_REQUEST:
            return setIsLoading(state, true, action.venueId);
        case FACIAL_RECOGNITION_SERVERS_REQUEST_SUCCESS:
            return setIsLoading(setServers(state, action.facialRecognitionServers), false, state.facialRecognitionServers.venueId);
        case FACIAL_RECOGNITION_SERVERS_REQUEST_FAILURE:
            return setIsLoading(state, false, state.facialRecognitionServers.venueId);
        case FACIAL_RECOGNITION_SERVERS_TEST_REQUEST:
            return setIsSaving(state, true);
        case FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS:
        case FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_FAILURE:
            return setIsSaving(state, false);
        case FACIAL_RECOGNITION_SERVER_RESET:
            return setFacialRecognitionServerId(state, -1);
        case FACIAL_RECOGNITION_SERVER_REQUEST:
            return setFacialRecognitionServer(loadingFacialRecognitionServer(setFacialRecognitionServerId(state, action.facialRecognitionServerId), true), {});
        case FACIAL_RECOGNITION_SERVER_REQUEST_SUCCESS:
            return setFacialRecognitionServer(loadingFacialRecognitionServer(state, false), action.facialRecognitionServer);
        case FACIAL_RECOGNITION_SERVER_REQUEST_FAILURE:
            return loadingFacialRecognitionServer(setFacialRecognitionServerId(state, -1), false);
        case FACIAL_RECOGNITION_SERVER_CREATE_REQUEST:
            return setFacialRecognitionServer(loadingFacialRecognitionServer(setFacialRecognitionServerId(state, 0), true), {});;
        case FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS:
            return setFacialRecognitionServer(loadingFacialRecognitionServer(state, false), action.facialRecognitionServer);;
        case FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE:
            return loadingFacialRecognitionServer(setFacialRecognitionServerId(state, -1), false);
        case FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST:
            return savingFacialRecognitionServer(state, true);
        case FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS:
            return setFacialRecognitionServer(savingFacialRecognitionServer(setFacialRecognitionServerId(state, -1), false), action.facialRecognitionServer);
        case FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_FAILURE:
            return savingFacialRecognitionServer(state, false);
        case FACIAL_RECOGNITION_SERVER_SET:
            return setFacialRecognitionServer(state, action.facialRecognitionServer);
        case facialRecognitionServerActions.FACIAL_RECOGNITION_SERVER_CREATE_REQUEST:
            return savingFacialRecognitionServer(state, true);
        case facialRecognitionServerActions.FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_SUCCESS:
        case facialRecognitionServerActions.FACIAL_RECOGNITION_SERVER_CREATE_REQUEST_FAILURE:
            return savingFacialRecognitionServer(state, false);

    }
    return state;
}