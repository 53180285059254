import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import UserRoleRequests from '../userRoles/userRoleRequests/UserRoleRequests';
import UserRoles from '../userRoles/UserRoles';
import FullPageDialog from '../layout/FullPageDialog';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';
import RequestUserRoleContainer from '../../containers/RequestUserRoleContainer';
import GroupRequestAccessContainer from '../../containers/GroupRequestAccessContainer';
import ControlGroup from "../controls/ControlGroup";
import CheckboxControl from "../controls/CheckboxControl";
import TextControlGroup from '../controls/TextControlGroup';
import PasswordControlGroup from '../controls/PasswordControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import TextDisplay from '../controls/TextDisplay';
import DefaultButton from '../controls/DefaultButton';
import Tabs from '../common/Tabs';
import FormErrorMessage from '../alerts/FormErrorMessage';
import AddUserRoleContainer from '../../containers/AddUserRoleContainer';
import EditUserRoleContainer from '../../containers/EditUserRoleContainer';
import ResponsiveList from '../common/ResponsiveList';
import SubTitle from '../common/SubTitle';
import ProfileAvatarEdit from './ProfileAvatarEdit';
import Saving from '../common/Saving';
import { Link } from 'react-router-dom';
import PageLayout from '../layout/PageLayout';
import PrimaryButton from '../controls/PrimaryButton';
import UserDirectoryContainer from '../../containers/UserDirectoryContainer';
import PageInfo from '../common/PageInfo';

export interface IProfileProps {
    isDark: boolean;
    profile: AusComplyDtos.User,
    avatar: AusComplyDtos.File,
    isLoading: boolean,
    isSaving: boolean,
    isCheckingLicense: boolean,
    error: string,
    changePasswordError: string,
    errors: any;
    isCheckingSled: boolean,
    onSetTheme: Function;
    onLoadProfile: Function;
    onProfileReset: Function;
    userRoleRequests: AusComplyDtos.UserRoleRequest[],
    userRoleSummaries: AusComplyDtos.UserRoleSummary[],
    states: AusComplyDtos.State[],
    genders: AusComplyDtos.Gender[],
    otherDocuments: AusComplyDtos.UserOtherDocument[],
    otherDocumentTypes: AusComplyDtos.DocumentType[],
    onSaveOtherDocuments: Function,
    onRemoveOtherDocument: Function,
    complianceDocuments: AusComplyDtos.UserComplianceDocument[],
    complianceDocumentTypes: AusComplyDtos.DocumentType[],
    groupAccessRequests: AusComplyDtos.GroupUser[],
    onSaveComplianceDocuments: Function,
    onRemoveComplianceDocument: Function,
    onUpdateAvatar: Function,
    onChangePassword: Function,
    user: AusComplyDtos.ngtUser,
    hasEditPermission: boolean,
    isVenueUserRoleType: boolean,
    isSecurityFirmRoleType: boolean,
    onUpdateProfile: Function,
    onSaveProfile: Function,
    onCheckLicense: Function,
    onRemoveGroupRequest: Function,
    history: any;
    location: any;
}

interface IProfileState {
    showRequestUserRole: boolean;
    showAddVenueUserRole: boolean;
    showAddSecurityFirmUserRole: boolean;
    selectedTab: number;
    hasAcceptedTerms: boolean;
    editUserRoleId: number;
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    showGroupAccessRequest: boolean;
}

const TermsLink = forwardRef((props, ref) => <Link to="/terms" {...props} />);

class Profile extends Component<IProfileProps, IProfileState> {
    constructor(props: IProfileProps) {
        super(props)
        this.state = {
            showRequestUserRole: false,
            selectedTab: 0,
            hasAcceptedTerms: false,
            showAddVenueUserRole: false,
            showAddSecurityFirmUserRole: false,
            editUserRoleId: 0,
            oldPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            showGroupAccessRequest: false
        }
        this.onToggleTheme = this.onToggleTheme.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.onToggleShowRequestUserRole = this.onToggleShowRequestUserRole.bind(this);
        this.onVenueStaffChanged = this.onVenueStaffChanged.bind(this);
        this.onSecurityStaffChanged = this.onSecurityStaffChanged.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onSaveOtherDocuments = this.onSaveOtherDocuments.bind(this);
        this.onSaveComplianceDocuments = this.onSaveComplianceDocuments.bind(this);
        this.onAcceptedTermsChanged = this.onAcceptedTermsChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onToggleShowAddVenueUserRole = this.onToggleShowAddVenueUserRole.bind(this);
        this.onToggleShowAddSecurityFirmUserRole = this.onToggleShowAddSecurityFirmUserRole.bind(this);
        this.onEditUserRole = this.onEditUserRole.bind(this);
        this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);
        this.onNewPasswordChanged = this.onNewPasswordChanged.bind(this);
        this.onNewPasswordConfirmChanged = this.onNewPasswordConfirmChanged.bind(this);
        this.onSubmitNewPassword = this.onSubmitNewPassword.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let profile = { ...this.props.profile };
        profile[fieldName] = value;
        this.props.onUpdateProfile(profile);
    }

    componentDidMount() {
        this.props.onLoadProfile();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.setState({
                hasAcceptedTerms: this.props.profile.hasAcceptedTerms
            });
        }
        if (!this.props.isSaving && prevProps.isSaving) {
            this.setState({
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm: ""
            });
        }
    }

    onToggleTheme(value) {
        this.props.onSetTheme(value);
    }

    onToggleShowRequestUserRole() {
        this.setState({ showRequestUserRole: !this.state.showRequestUserRole });
    }

    onToggleShowAddVenueUserRole(reload: boolean) {
        this.setState({ showAddVenueUserRole: !this.state.showAddVenueUserRole }, () => {
            if (reload) {
                this.props.onLoadProfile();
            }
        });
    }

    onToggleShowAddSecurityFirmUserRole(reload: boolean) {
        this.setState({ showAddSecurityFirmUserRole: !this.state.showAddSecurityFirmUserRole }, () => {
            if (reload) {
                this.props.onLoadProfile();
            }
        });
    }

    onReset() {
        this.props.onProfileReset();
    }

    onCommand(command) {

        switch (command) {
            case "requestUserRole":
                this.onToggleShowRequestUserRole();
                break;
            case "addVenueUserRole":
                this.onToggleShowAddVenueUserRole(false);
                break;
            case "addSecurityFirmUserRole":
                this.onToggleShowAddSecurityFirmUserRole(false);
                break;
            case "requestGroupAccess":
                this.setState({ showGroupAccessRequest: true });
                break;
            case "accountRemove":
                this.props.history.push('/profile/delete');
                break;
        }
    }

    onVenueStaffChanged(value) {
        let profile = { ...this.props.profile };
        profile.isVenueStaff = value;
        this.props.onUpdateProfile(profile);
    }

    onSecurityStaffChanged(value) {
        let profile = { ...this.props.profile };
        profile.isSecurityStaff = value;
        this.props.onUpdateProfile(profile);
    }

    onValidate() {
        // stateLicensed is required
        // license number is required and 9 characters
        // rsa is required
    }

    onCheckSled() {
        this.props.onCheckLicense(this.props.profile);
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onSaveOtherDocuments(documentTypeId, files) {
        this.props.onSaveOtherDocuments(documentTypeId, files);
    }

    onSaveComplianceDocuments(documentTypeId, expiryDate, files) {
        this.props.onSaveComplianceDocuments(documentTypeId, expiryDate, files);
    }

    onAcceptedTermsChanged(value) {
        this.setState({
            hasAcceptedTerms: value
        });
    }

    onSave() {
        var profile = { ...this.props.profile };
        profile.hasAcceptedTerms = this.state.hasAcceptedTerms;
        this.props.onSaveProfile(profile);
    }

    onEditUserRole(userRoleId: number) {
        this.setState({ editUserRoleId: userRoleId });
    }

    onOldPasswordChanged(value) {
        this.setState({ oldPassword: value });
    }

    onNewPasswordChanged(value) {
        this.setState({ newPassword: value });
    }

    onNewPasswordConfirmChanged(value) {
        this.setState({ newPasswordConfirm: value });
    }

    onSubmitNewPassword() {
        this.props.onChangePassword(this.props.profile.userId, this.state.oldPassword, this.state.newPassword, this.state.newPasswordConfirm);
    }

    getCommands(): KeyName[] {
        let moreCommands: KeyName[] = [];
        if (this.props.user && this.props.user.accessLevel < 5) {
            moreCommands.push({ key: "requestUserRole", name: "Request User Role(s)" });
        }
        if (this.props.hasEditPermission && this.props.isVenueUserRoleType) {
            moreCommands.push({ key: "addVenueUserRole", name: "Add Venue User Role" });
        }
        if (this.props.hasEditPermission && this.props.isSecurityFirmRoleType) {
            moreCommands.push({ key: "addSecurityFirmUserRole", name: "Add Security Firm User Role" });
        }
        moreCommands.push({ key: "requestGroupAccess", name: "Request Group Access" });
        moreCommands.push({ key: "accountRemove", name: "Delete My Account" });
        return moreCommands;
    }

    render() {
        let self = this;
        let moreCommands: KeyName[] = this.getCommands();

        let error;
        if (!this.props.profile.hasAcceptedTerms || this.props.profile.requiresUpdate) {
            let message: string[] = [];
            message.push("Please");
            if (this.props.profile.requiresUpdate) {
                if (!this.props.profile.hasAcceptedTerms) {
                    message.push("review your details,");
                } else {
                    message.push("review your details");
                }
            }
            if (!this.props.profile.hasAcceptedTerms) {
                message.push("agree to the terms and conditions");
            }
            message.push("and click save to continue using AusComply Mobile Compliance Solution.")
            error = (
                <FormErrorMessage error={message.join(" ")} />
            );
        }

        let groupAccessRequests;
        if (this.props.groupAccessRequests && this.props.groupAccessRequests.length > 0) {
            groupAccessRequests = (
                <>
                    <SubTitle text={"Group Requests"}></SubTitle>
                    <ResponsiveList
                        loading={false}
                        data={this.props.groupAccessRequests}
                        headers={["Group", "Date Requested"]}
                        columns={["groupName", "notification.dateTimeFormatted"]}
                        getCommands={item => [{ key: "delete", name: "Remove" }]}
                        onCommand={(command, item) => { self.props.onRemoveGroupRequest(item.notificationId, item.groupId) }}
                        canEdit={true}
                    />
                </>
            );
        }
        let content;
        let footerLeftContent;
        let tabs = <div style={{ display: 'display-block', marginTop: '10px' }}>
            <Tabs selected={this.state.selectedTab} labels={["Roles", "General", "Documents", "Notifications", "Password"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </div>;

        if (this.state.selectedTab === 0) {
            content = (<>
                <UserRoles userRoleSummaries={this.props.userRoleSummaries} onEdit={(value) => this.onEditUserRole(value)} />
                <UserRoleRequests userRoleRequests={this.props.userRoleRequests} />
                {groupAccessRequests}
            </>
            )
        }
        if (this.state.selectedTab === 1) {
            content = (<>
                {error}
                <ProfileAvatarEdit avatar={this.props.avatar} onUpdate={this.props.onUpdateAvatar} />
                <ControlGroup text={"User Type(s)"}>
                    <CheckboxControl
                        text={"Venue Staff"}
                        defaultValue={this.props.profile.isVenueStaff}
                        readonly={!this.props.profile.canEditIsVenueStaff}
                        onChanged={this.onVenueStaffChanged} />
                    <CheckboxControl
                        text={"Security Staff"}
                        defaultValue={this.props.profile.isSecurityStaff}
                        readonly={!this.props.profile.canEditIsSecurityStaff}
                        onChanged={this.onSecurityStaffChanged} />
                </ControlGroup>
                {this.props.profile.isVenueStaff && (
                    <TextControlGroup
                        text={"RSA Number"}
                        error={this.props.errors["rsaNumber"]}
                        defaultValue={this.props.profile.rsaNumber}
                        onBlur={(value) => this.onValueChanged("rsaNumber", value)} />
                )}
                {this.props.profile.isSecurityStaff && (
                    <>
                        <SelectControlGroup
                            text="State Licensed"
                            error={this.props.errors["stateLicensedId"]}
                            onChange={(value) => this.onValueChanged("stateLicensedId", value)}
                            defaultValue={this.props.profile.stateLicensedId}>
                            <option value={0} />
                            {this.props.states && this.props.states.map((item, index) => (
                                <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                            ))}
                        </SelectControlGroup>
                        <TextControlGroup
                            text={"License Number"}
                            error={this.props.errors["licenceNumber"]}
                            defaultValue={this.props.profile.licenceNumber}
                            onBlur={(value) => this.onValueChanged("licenceNumber", value)}
                            readonly={!this.props.profile.canEditLicenceNumber} />
                        {this.props.profile.sledEnabled && (
                            <>
                                <TextControlGroup text={"Last Licence Check"} defaultValue={this.props.profile.dateLastSledCheckDisplay} readonly={true} />
                                {(this.props.profile.stateId == 2) && (
                                    <TextControlGroup text={"Licence Status"} defaultValue={this.props.profile.sledStatusDisplay} readonly={true} />
                                )}
                                {(this.props.isCheckingLicense) && (
                                    <ControlGroup text={" "}>
                                        <Saving message={"Checking License"} />
                                    </ControlGroup>
                                )}
                                {(!this.props.isCheckingLicense && this.props.profile.canCheckSled) && (
                                    <ControlGroup>
                                        <DefaultButton text={"Check License"} onClick={() => this.onCheckSled()} />
                                    </ControlGroup>
                                )}
                            </>
                        )}

                    </>
                )}
                <TextControlGroup
                    text={"First Name"}
                    error={this.props.errors["givenName"]}
                    defaultValue={this.props.profile.givenName}
                    readonly={!this.props.profile.canEditGivenName}
                    onBlur={(value) => this.onValueChanged("givenName", value)} />
                <TextControlGroup
                    text={"Last Name"}
                    error={this.props.errors["surname"]}
                    defaultValue={this.props.profile.surname}
                    readonly={!this.props.profile.canEditSurname}
                    onBlur={(value) => this.onValueChanged("surname", value)} />
                <TextControlGroup
                    text={"Nickname"}
                    error={this.props.errors["nickname"]}
                    defaultValue={this.props.profile.nickname}
                    onBlur={(value) => this.onValueChanged("nickname", value)} />
                <SelectControlGroup
                    text="Gender"
                    error={this.props.errors["genderId"]}
                    onChange={(value) => this.onValueChanged("genderId", value)}
                    defaultValue={this.props.profile.genderId}>
                    <option value={0} />
                    {this.props.genders.map((item, index) => (
                        <option key={item.genderId} value={item.genderId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <TextControlGroup
                    text={"Address"}
                    error={this.props.errors["address"]}
                    defaultValue={this.props.profile.address}
                    onBlur={(value) => this.onValueChanged("address", value)} />
                <TextControlGroup
                    text={"Suburb"}
                    error={this.props.errors["suburb"]}
                    defaultValue={this.props.profile.suburb}
                    onBlur={(value) => this.onValueChanged("suburb", value)} />
                <SelectControlGroup
                    text="State"
                    error={this.props.errors["stateId"]}
                    onChange={(value) => this.onValueChanged("stateId", value)}
                    defaultValue={this.props.profile.stateId}>
                    <option value={0} />
                    {this.props.states.map((item, index) => (
                        <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                    ))}
                </SelectControlGroup>
                <TextControlGroup
                    text={"Postcode"}
                    error={this.props.errors["postcode"]}
                    defaultValue={this.props.profile.postcode}
                    onBlur={(value) => this.onValueChanged("postcode", value)} />
                <TextControlGroup
                    text={"Email Address"}
                    error={this.props.errors["email"]}
                    defaultValue={this.props.profile.email}
                    onBlur={(value) => this.onValueChanged("email", value)} />
                <TextControlGroup
                    text={"Mobile"}
                    error={this.props.errors["mobileNo"]}
                    defaultValue={this.props.profile.mobileNo}
                    onBlur={(value) => this.onValueChanged("mobileNo", value)} />
                <TextControlGroup
                    text={"Emergency Contact Name"}
                    error={this.props.errors["emergencyContactName"]}
                    defaultValue={this.props.profile.emergencyContactName}
                    onBlur={(value) => this.onValueChanged("emergencyContactName", value)} />
                <TextControlGroup
                    text={"Emergency Contact Mobile"}
                    error={this.props.errors["emergencyContactNumber"]}
                    defaultValue={this.props.profile.emergencyContactNumber}
                    onBlur={(value) => this.onValueChanged("emergencyContactNumber", value)} />
                {!this.props.profile.hasAcceptedTerms && (
                    <ControlGroup text={" "}>
                        <CheckboxControl text={"I agree to the"}
                            link={TermsLink}
                            linkText={"Terms and Conditions"}
                            defaultValue={this.state.hasAcceptedTerms}
                            onChanged={this.onAcceptedTermsChanged} />
                    </ControlGroup>
                )}
            </>
            );
            footerLeftContent = (
                <PrimaryButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</PrimaryButton>
            );
        }
        if (this.state.selectedTab === 2) {
            return <UserDirectoryContainer title={"My Profile"} userId={this.props.user.userID} tabs={tabs} />
        }
        if (this.state.selectedTab === 3) {
            content = (<>
                {error}
                <ControlGroup text="External Notification Options">
                    <TextDisplay>All notifications will also appear on notifications page on the website or app</TextDisplay>
                </ControlGroup>
                <ControlGroup text={" "}>
                    <CheckboxControl
                        text={"Push Notifications (iOS/Android)"}
                        label={" "}
                        readonly={true}
                        defaultValue={this.props.profile.inAppNotification}
                        onChanged={(value) => this.onValueChanged("inAppNotification", value)} />
                </ControlGroup>
                <ControlGroup text={"Incident / Checklist / Facial Recognition Notifications"}>
                    <CheckboxControl text={"Email"}
                        label={" "}
                        defaultValue={this.props.profile.emailNotification}
                        onChanged={(value) => this.onValueChanged("emailNotification", value)} />
                    <br />
                    <CheckboxControl
                        text={"SMS"}
                        label={" "}
                        defaultValue={this.props.profile.smsNotification}
                        onChanged={(value) => this.onValueChanged("smsNotification", value)} />
                </ControlGroup>
                <ControlGroup text={"AusComply / Administrator Notifications"}>
                    <CheckboxControl text={"Email"}
                        label={" "}
                        defaultValue={this.props.profile.globalEmailBroadcastNotification}
                        onChanged={(value) => this.onValueChanged("globalEmailBroadcastNotification", value)} />
                    <br />
                    <CheckboxControl
                        text={"SMS"}
                        label={" "}
                        defaultValue={this.props.profile.globalSmsBroadcastNotification}
                        onChanged={(value) => this.onValueChanged("globalSmsBroadcastNotification", value)} />
                </ControlGroup>
                <ControlGroup text={"Venue / Security Firm Specific Broadcast Notification"}>
                    <CheckboxControl text={"Email"}
                        label={" "}
                        defaultValue={this.props.profile.venueSecurityFirmEmailBroadcastNotification}
                        onChanged={(value) => this.onValueChanged("venueSecurityFirmEmailBroadcastNotification", value)} />
                    <br />
                    <CheckboxControl
                        text={"SMS"}
                        label={" "}
                        defaultValue={this.props.profile.venueSecurityFirmSmsBroadcastNotification}
                        onChanged={(value) => this.onValueChanged("venueSecurityFirmSmsBroadcastNotification", value)} />
                </ControlGroup>
            </>
            );
            footerLeftContent = (
                <PrimaryButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</PrimaryButton>
            );
        }
        if (this.state.selectedTab === 4) {
            content = (<>
                <FormErrorMessage error={this.props.changePasswordError} />
                <PasswordControlGroup
                    text={"Old Password"}
                    defaultValue={this.state.oldPassword}
                    onChange={(value) => this.onOldPasswordChanged(value)} />
                <PasswordControlGroup
                    text={"New Password"}
                    defaultValue={this.state.newPassword}
                    onChange={(value) => this.onNewPasswordChanged(value)} />
                <PasswordControlGroup
                    text={"Confirm New Password"}
                    defaultValue={this.state.newPasswordConfirm}
                    onChange={(value) => this.onNewPasswordConfirmChanged(value)} />
                <ControlGroup text={" "}>
                    <PageInfo
                        text={"Password must be over 8 characters and include at least one uppercase letter, lowercase letter, number and special character"}
                    />
                </ControlGroup>
            </>
            );
            footerLeftContent = (
                <PrimaryButton disabled={this.props.errors.count > 0} onClick={() => this.onSubmitNewPassword()}>Change Password</PrimaryButton>
            );
        }

        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    moreCommands={moreCommands}
                    onCommand={this.onCommand}
                    headerText={"My Profile"}
                    footerLeftContent={footerLeftContent}
                >
                    <form>
                        {tabs}
                        {content}
                    </form>
                </PageLayout>

                <FullPageDialog
                    open={this.state.showRequestUserRole}
                    title={"Request user role"}
                    onDismissed={this.onToggleShowRequestUserRole}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showRequestUserRole && (
                        <RequestUserRoleContainer onRequested={this.onToggleShowRequestUserRole} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.showAddVenueUserRole}
                    title={"Add Venue User Role(s)"}
                    onDismissed={() => this.onToggleShowAddVenueUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddVenueUserRole && (
                        <AddUserRoleContainer userId={this.props.profile.userId} onSuccess={() => this.onToggleShowAddVenueUserRole(false)} forSecurityFirm={false} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.showAddSecurityFirmUserRole}
                    title={"Add Security Firm User Role(s)"}
                    onDismissed={() => this.onToggleShowAddSecurityFirmUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddSecurityFirmUserRole && (
                        <AddUserRoleContainer userId={this.props.profile.userId} onSuccess={() => this.onToggleShowAddSecurityFirmUserRole(false)} forSecurityFirm={true} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editUserRoleId != 0}
                    title={"Edit User Role"}
                    onDismissed={() => this.onEditUserRole(0)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditUserRoleContainer userRoleId={this.state.editUserRoleId} onSuccess={() => this.onEditUserRole(0)} />
                    )}
                </FullPageDialog>
                <GroupRequestAccessContainer show={this.state.showGroupAccessRequest} onClose={() => { self.setState({ showGroupAccessRequest: false }, () => { self.props.onLoadProfile() }) }} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Profile));