import update from "immutability-helper";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import {
    DRAFT_INCIDENT_OPTIONS_REQUEST,
    DRAFT_INCIDENT_OPTIONS_REQUEST_SUCCESS,
    DRAFT_INCIDENT_OPTIONS_REQUEST_FAILURE,
    DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS,
    DRAFT_INCIDENT_CREATE_REQUEST_FAILURE,
    DRAFT_INCIDENT_CLEAR
} from "../actions/draftIncident";

const initialState = {
    isLoading: false,
    incidentOptions: {},
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setIncidentOptions(state, incidentOptions) {
    let newState = update(state, {
        incidentOptions: { $set: incidentOptions }
    });
    return newState;
}

export default function draftIncidentOptions(state = initialState, action) {
    switch (action.type) {
        case DRAFT_INCIDENT_OPTIONS_REQUEST:
            return loading(setIncidentOptions(state, {}), true);
        case DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS:
        case DRAFT_INCIDENT_OPTIONS_REQUEST_SUCCESS:
            return loading(setIncidentOptions(state, action.incidentOptions), false);
        case DRAFT_INCIDENT_CREATE_REQUEST_FAILURE:
        case DRAFT_INCIDENT_OPTIONS_REQUEST_FAILURE:
            return loading(state, false);
        case DRAFT_INCIDENT_CLEAR:
            return setIncidentOptions(state, {});
    }
    return state;
}