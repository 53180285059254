import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Error from '../../common/Error'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import VenueSecurityCodeContainer from '../../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../../containers/SignOnOffSummaryContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../../common/SecondaryFab';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import Information from '../../common/Information';
import WelcomeContainer from '../../../containers/WelcomeContainer';
import TransparentButton from '../../controls/TransparentButton';
import HomeStatistics from '../../home/HomeStatistics';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../common/NoRowsMessage';

export interface IDashboardUserTotalsProps {
    isLoading: boolean;
    venueUsers: any[];
    venueUsersGridLines: number;
    securityFirmUsers: any[];
    securityFirmUsersGridLines: number;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

class DashboardUserTotals extends Component<IDashboardUserTotalsProps, any> {
    constructor(props: IDashboardUserTotalsProps) {
        super(props)
        this.state = {
            hideZeros: true
        };
        this.onHideZeros = this.onHideZeros.bind(this);
    }

    componentDidMount() {
    }


    onHideZeros(hideZeros) {
        this.setState({ hideZeros });
    }

    barChartOptions(title: string, gridLinesCount: number, count: number) {

        var chartAreaHeight = count * 30;
        var chartHeight = chartAreaHeight;

        return {
            //title: title,
            legend: { position: 'none' },
            isStacked: true,
            annotations: {
                alwaysOutside: true
            },
            backgroundColor: '#2E2C2D',
            chartArea: {
                left: '40%',
                width: '55%',
                backgroundColor: '#000000',
                height: chartAreaHeight
            },
            colors: ['#818183'],
            fontSize: '14',
            height: chartHeight,
            hAxis: {
                format: '#',
                minValue: 0,
                gridlines: {
                    count: gridLinesCount,
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            titleTextStyle: {
                color: '#ffffff'
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff',
                    fontSize: '14'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            animation: {
                duration: 1200,
                easing: 'out',
                startup: true
            }
        };
    }

    renderBarChart(name: string, data: any[], gridLinesCount: number) {
        let chart;
        let filteredData = [...data];
        if (this.state.hideZeros) {
            filteredData = filteredData.filter((f, index) => index === 0 || f[1] > 0);
        }
        if (!this.props.isLoading && data.length > 1) {
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                    <SubTitle text={name} />
                    <div className={name.replace(" ", "") + "Chart-container"}>
                        <Chart
                            options={this.barChartOptions(name, gridLinesCount, filteredData.length)}
                            chartType="BarChart"
                            data={filteredData}
                            legendToggle
                        />
                    </div>
                </View>
            );
        }
        return chart;
    }

    render() {

        if (this.props.isLoading) {
            return (<Loading />);
        }


        return (
            <LayoutForm fullWidth={true}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CheckboxControlGroup
                            text={"Hide records with zero values"}
                            defaultValue={this.state.hideZeros}
                            onChanged={(value) => this.onHideZeros(value)} />
                    </Grid>
                </Grid>
                {this.renderBarChart("Users per venue", this.props.venueUsers, this.props.venueUsersGridLines)}
                {this.renderBarChart("Users per security firm", this.props.securityFirmUsers, this.props.securityFirmUsersGridLines)}
            </LayoutForm>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(DashboardUserTotals))