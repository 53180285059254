import { connect } from 'react-redux'
import DuplicateMobileNumbers from '../components/admin/DuplicateMobileNumbers'
import { adminDuplicateUserMobilesRequest, adminDuplicateUserMobileUpdateRequest } from '../common/actions/admin'

const mapStateToProps = state => {
    return {
        isLoading: state.admin.duplicateUserMobiles.isLoading,
        isSaving: state.admin.duplicateUserMobiles.isSaving,
        users: state.admin.duplicateUserMobiles.users,
        paging: state.admin.duplicateUserMobiles.paging,
        search: state.admin.duplicateUserMobiles.search
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(search, page, pageSize) => {
            dispatch(adminDuplicateUserMobilesRequest(search, page, pageSize))
        },
        onUpdate:(userId, mobileNo) => {
            dispatch(adminDuplicateUserMobileUpdateRequest(userId, mobileNo))
        }
    }
}

const DuplicateMobileNumbersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DuplicateMobileNumbers)

export default DuplicateMobileNumbersContainer;