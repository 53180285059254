import React from 'react';
import View from '../../common/View';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import FullPageDialog from '../../layout/FullPageDialog';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IVenuesFilterProps {
    open: boolean;
    filter: AusComplyDTOs.VenueFilter;
    onReset: Function;
    onSearch: Function;
    onClose: Function;
}

interface IVenuesFilterState {
    filter: AusComplyDTOs.VenueFilter;
}

export default class VenuesFilter extends React.Component<IVenuesFilterProps, IVenuesFilterState> {
    constructor(props: IVenuesFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            filter: new AusComplyDTOs.VenueFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value, submit) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            if (submit) {
                this.props.onSearch(this.state.filter);
            }
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let content = <>
            <TextControlGroup text={"Search"}
                labelAbove={true}
                defaultValue={this.state.filter.search}
                onSubmit={value => {
                    this.onChange('search', value, true);
                }}
                onChange={value => this.onChange('search', value, false)} />
        </>

        return <FullPageDialog
            open={this.props.open}
            footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />}
            footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
            notFullWidth={true}
            title="Search" onDismissed={() => this.props.onClose()}>
            <View>
                {content}
            </View>
        </FullPageDialog>
    }
}