import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { deviceInfo } from '../../utilities/device';

export const securityFirmAssociationServiceApi = {
  
  find: (venueId: number, securityFirmId: number, forSecurityFirmId: number, page: number, pageSize: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmAssociationSummary();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId: number, securityFirmId: number, forSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmAssociationCreate();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  save: (venueId: number, securityFirmId: number, securityFirmAssociation: AusComplyDtos.SecurityFirmAssociation ) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmAssociation();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.securityFirmAssociation = securityFirmAssociation;
    const request = client.post(query);
    return from(request);
  },
  delete: (venueId: number, securityFirmId: number, securityFirmAssociationId: number, allAssociations: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.DeleteSecurityFirmAssociation();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.securityFirmAssociationId = securityFirmAssociationId;
    query.removeAll = allAssociations;
    const request = client.delete(query);
    return from(request);
  }
};
