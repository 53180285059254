import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { functionalAreaServiceApi } from '../services/functionalAreaService';
import * as functionalAreaActions from '../actions/functionalArea';
import {
    notifyError
} from './common';

const functionalAreasRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREAS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        functionalAreaServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => functionalAreaActions.functionalAreasRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "functionalAreasRequestEpic.find", functionalAreaActions.functionalAreasRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "functionalAreasRequestEpic", functionalAreaActions.functionalAreasRequestFailure))
    );


const functionalAreasRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREAS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            functionalAreaServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.functionalAreas.functionalAreas.paging.page,
                state.functionalAreas.functionalAreas.paging.pageSize,
                state.functionalAreas.functionalAreas.filter)
                .pipe(
                    map(response => functionalAreaActions.functionalAreasRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "functionalAreasRefreshEpic.find", functionalAreaActions.functionalAreasRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "functionalAreasRefreshEpic", functionalAreaActions.functionalAreasRequestFailure))
    );

const functionalAreaRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREA_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            functionalAreaServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['functionalAreaId'])
                .pipe(
                    map(response => functionalAreaActions.functionalAreaRequestSuccess(response.functionalArea)),
                    catchError(error => notifyError(error, "functionalAreaRequestEpic.get", functionalAreaActions.functionalAreaRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "functionalAreaRequestEpic", functionalAreaActions.functionalAreaRequestFailure))
    );

const functionalAreaCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREA_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            functionalAreaServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forGroupId'],
                action['forVenueId'])
                .pipe(
                    map(response => functionalAreaActions.functionalAreaCreateRequestSuccess(response.functionalArea)),
                    catchError(error => notifyError(error, "functionalAreaCreateEpic.create", functionalAreaActions.functionalAreaCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "functionalAreaCreateEpic", functionalAreaActions.functionalAreaCreateRequestFailure))
    );

const functionalAreaSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            functionalAreaServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.functionalAreas.functionalArea.functionalArea)
                .pipe(
                    map(response => functionalAreaActions.functionalAreaSaveRequestSuccess(response.functionalArea)),
                    catchError(error => notifyError(error, "functionalAreaUpsertEpic.upsert", functionalAreaActions.functionalAreaSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "functionalAreaUpsertEpic", functionalAreaActions.functionalAreaSaveRequestFailure))
    );

const functionalAreaSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST_SUCCESS),
        map((action: any) => functionalAreaActions.functionalAreaReset())
    );

const functionalAreaSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST_SUCCESS),
        map((action: any) => functionalAreaActions.functionalAreasRefresh())
    );

export const functionalAreaEpics = combineEpics(
    functionalAreasRefreshEpic,
    functionalAreasRequestEpic,
    functionalAreaRequestEpic,
    functionalAreaCreateEpic,
    functionalAreaSaveEpic,
    functionalAreaSaveSuccessEpic,
    functionalAreaSaveSuccessRefreshEpic
);