import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUsersHeaderProps {
    classes: any;
    theme: any;
}

class UsersHeader extends React.Component<IUsersHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr}>First Name</th>
                <th className={classes.tableHr}>Last Name</th>
                <th className={classes.tableHr}>Nickname</th>
                <th className={classes.tableHr}>Mobile</th>
                <th className={classes.tableHr}>Email</th>
                <th className={classes.tableHr}>Verified</th>
                <th className={classes.tableHr}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UsersHeader);