import React from 'react';
import ControlGroup from "./ControlGroup";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITextAreaControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    error?: string;
    defaultValue?: string;
    readonly?: boolean;
    onChange?: Function;
    onBlur?: Function;
    labelAbove?: boolean;
    defaultRows?: number;
    maxLength?: number;
}

interface ITextAreaControlGroupState {
    value: string;
    changed: boolean;
}

class TextAreaControlGroup extends React.PureComponent<ITextAreaControlGroupProps, ITextAreaControlGroupState> {

    constructor(props: ITextAreaControlGroupProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.state = {
            value: "",
            changed: false
        }
    }

    componentDidMount() {
        let value: string = "";
        if (this.props.defaultValue || this.props.defaultValue === "") {
            value = this.props.defaultValue.toString(); //.replace(/[^\x00-\x7F]/g, "");
        } else {
            value = "";
        }

        this.setState({
            value,
            changed: false
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultValue != this.props.defaultValue) {
            let value: string = this.state.value;
            if (this.props.defaultValue || this.props.defaultValue === "") {
                value = this.props.defaultValue.toString(); //.replace(/[^\x00-\x7F]/g, "");
            } else {
                value = "";
            }
            this.setState({
                value,
                changed: false
            })
        }
    }

    onChange(event) {
        try {
            if (event != null) {
                let val = event.target.value; //.replace(/[^\x00-\x7F]/g, "");
                if(this.props.maxLength && this.props.maxLength > 0) {
                    if (val.length > this.props.maxLength) {
                        val = val.substring(0, this.props.maxLength);
                    }
                }
                this.setState({
                    value: val,
                    changed: true
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(val);
                    }
                });
            }
        } catch (err) {

        }
    }

    onBlur() {
        if (this.state.changed) {
            this.setState({
                changed: false
            }, () => {
                if (this.props.onBlur) {
                    this.props.onBlur(this.state.value);
                }
            })
        }
    }

    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        let rows = this.props.defaultRows || 1;
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    <InputBase
                        multiline
                        maxRows="20"
                        minRows={rows}
                        error={this.props.error ? true : false}
                        value={(this.state.value || '').toString()}
                        style={{ ...style }}
                        classes={{ root: classes.inputAreaControl, input: classes.inputRootTextArea }}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        disabled={this.props.readonly}
                    />

                    {this.props.error && (
                        <FormHelperText style={{ color: "#d2242b" }}>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextAreaControlGroup);