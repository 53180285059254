import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { map, catchError, mergeMap, withLatestFrom, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { groupServiceApi } from '../services/group';
import { dashboardServiceApi } from '../services/dashboard';
import { checklistTemplateApi } from "../services/checklistTemplate";
import { notificationShow, notificationSuccessShow } from "../actions/notification";
import * as directoryActions from "../actions/directory";

import * as groupActions from '../actions/groups';

import {
    USER_CHANGED, USER_RESET
} from '../actions/userLogin';

import {
    profileRequest
} from '../actions/profile'

import {
    printQueueRequest
} from '../actions/print';

import {
    VENUE_SECURITYFIRM_SELECT

} from "../actions/venueSecurityFirm";

import {
    notifyError
} from './common';
import AusComplyIcon from '../../resources/AusComplyIcon';

const groupsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUPS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.groups.groups.filter)
                .pipe(
                    map(response => groupActions.groupsRequestSuccess(response.filter, response.groups)),
                    catchError(error => notifyError(error, "groupsRequestEpic.find", groupActions.groupsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupsRequestEpic", groupActions.groupsRequestFailure))
    );

const groupRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'])
                .pipe(
                    map(response => groupActions.groupRequestSuccess(response.group, response.userRoleStatuses, response.userRoleTypes, response.timeZones)),
                    catchError(error => notifyError(error, "groupRequestEpic.get", groupActions.groupRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupRequestEpic", groupActions.groupRequestFailure))
    );

const groupCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => groupActions.groupCreateRequestSuccess(response.group, response.timeZones)),
                    catchError(error => notifyError(error, "groupCreateEpic.create", groupActions.groupCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupCreateEpic", groupActions.groupCreateRequestFailure))
    );

const groupUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.groups.group.group)
                .pipe(
                    map(response => groupActions.groupUpsertRequestSuccess(response.group)),
                    catchError(error => notifyError(error, "groupUpsertEpic.upsert", groupActions.groupUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupUpsertEpic", groupActions.groupUpsertRequestFailure))
    );

const groupUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_UPSERT_REQUEST_SUCCESS),
        map((action: any) => groupActions.groupsRequest())
    );

const groupUpsertSuccessRefreshDirectoryEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_UPSERT_REQUEST_SUCCESS),
        map((action: any) => directoryActions.directoryRequest("Group", action["group"]["groupId"]))
    );

const groupSetFilter = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUPS_SET_FILTER),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => groupActions.groupsRequest())
    );

const groupVenuesAvailableRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_VENUES_AVAILABLE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.getAvailableVenues(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'])
                .pipe(
                    map(response => groupActions.groupVenuesAvailableRequestSuccess(response.venues)),
                    catchError(error => notifyError(error, "groupVenuesAvailableRequestEpic.getAvailableVenues", groupActions.groupVenuesAvailableRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupVenuesAvailableRequestEpic", groupActions.groupVenuesAvailableRequestFailure))
    );

const groupVenuesInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_VENUES_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.insertVenues(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'],
                action['venues'],
                action['autoApproval'])
                .pipe(
                    map(response => groupActions.groupVenuesInsertRequestSuccess(response.venues, response.group)),
                    catchError(error => notifyError(error, "groupVenuesInsertRequestEpic.insertVenues", groupActions.groupVenuesInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupVenuesInsertRequestEpic", groupActions.groupVenuesInsertRequestFailure))
    );

const groupVenueUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_VENUE_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.updateGroupVenue(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'],
                action['groupVenue'])
                .pipe(
                    map(response => groupActions.groupVenueUpdateRequestSuccess(response.group)),
                    catchError(error => notifyError(error, "groupVenueUpdateRequestEpic.updateGroupVenue", groupActions.groupVenueUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupVenueUpdateRequestEpic", groupActions.groupVenueUpdateRequestFailure))
    );

const groupUsersAvailableRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_USERS_AVAILABLE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.getAvailableUsers(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'])
                .pipe(
                    map(response => groupActions.groupUsersAvailableRequestSuccess(response.users, response.userRoleTypes)),
                    catchError(error => notifyError(error, "groupUsersAvailableRequestEpic.getAvailableUsers", groupActions.groupUsersAvailableRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupUsersAvailableRequestEpic", groupActions.groupUsersAvailableRequestFailure))
    );

const groupUsersInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_USERS_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.insertUsers(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'],
                action['users'],
                action['autoApproval'],
                action['userRoleTypeId'],
                action['isTemporary'],
                action['expiryDate'])
                .pipe(
                    map(response => groupActions.groupUsersInsertRequestSuccess(response.users, response.group)),
                    catchError(error => notifyError(error, "groupUsersInsertRequestEpic.insertUsers", groupActions.groupUsersInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupUsersInsertRequestEpic", groupActions.groupUsersInsertRequestFailure))
    );

const groupUserUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_USER_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.updateGroupUser(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'],
                action['groupUser'])
                .pipe(
                    map(response => groupActions.groupUserUpdateRequestSuccess(response.group)),
                    catchError(error => notifyError(error, "groupUserUpdateRequestEpic.updateGroupUser", groupActions.groupUserUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupUserUpdateRequestEpic", groupActions.groupUserUpdateRequestFailure))
    );

const groupDashboardResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED, USER_RESET, VENUE_SECURITYFIRM_SELECT),
        map(action => groupActions.groupDashboardReset())
    );

const groupDashboardFilterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.newFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                undefined,
                undefined,
                action['groupId']
            )
                .pipe(
                    map(response => groupActions.groupDashboardFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "groupDashboardFilterRequestEpic.newFilter", groupActions.groupDashboardFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupDashboardFilterRequestEpic", groupActions.groupDashboardFilterRequestFailure))
    );

const groupDashboardFilterRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_FILTER_REQUEST_SUCCESS),
        map((action: any) => groupActions.groupDashboardRequest())
    );

const groupDashboardSetFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_SET_FILTER),
        map((action: any) => groupActions.groupDashboardRequest())
    );

const groupDashboardRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                undefined,
                undefined,
                state.groups.dashboard.filter)
                .pipe(
                    map(response => groupActions.groupDashboardRequestSuccess(response.filter, response.data)),
                    catchError(error => notifyError(error, "groupDashboardRequestEpic.get", groupActions.groupDashboardRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupDashboardRequestEpic", groupActions.groupDashboardRequestFailure))
    );

const groupDashboardExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.export(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                undefined,
                undefined,
                {...state.groups.dashboard.filter, mode: AusComplyDtos.ngtStatsMode.Group})
                .pipe(
                    map(response => groupActions.groupDashboardExportRequestSuccess()),
                    catchError(error => notifyError(error, "groupDashboardExportRequestEpic.export", groupActions.groupDashboardExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupDashboardExportRequestEpic", groupActions.groupDashboardExportRequestFailure))
    );

const groupDashboardExportRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_EXPORT_REQUEST_SUCCESS),
        map((action: any) => printQueueRequest())
    );

const groupsSelectionRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUPS_SELECTION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.findForSelection(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => groupActions.groupsSelectionRequestSuccess(response.groups)),
                    catchError(error => notifyError(error, "groupsSelectionRequestEpic.findForSelection", groupActions.groupsSelectionRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupsSelectionRequestEpic", groupActions.groupsSelectionRequestFailure))
    );

const groupAccessRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_ACCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.requestAccess(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'])
                .pipe(
                    map(response => groupActions.groupAccessRequestSuccess()),
                    catchError(error => notifyError(error, "groupAccessRequestEpic.requestAccess", groupActions.groupAccessRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupAccessRequestEpic", groupActions.groupAccessRequestFailure))
    );

const groupAccessRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(groupActions.GROUP_ACCESS_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Request sent"))
    );

const groupAccessRequestRejectEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_ACCESS_REQUEST_REJECT),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            groupServiceApi.requestAccessReject(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['groupId'],
                action['notificationId'])
                .pipe(
                    map(response => groupActions.groupAccessRequestRejectSuccess(response.group)),
                    catchError(error => notifyError(error, "groupAccessRequestRejectEpic.requestAccessReject", groupActions.groupAccessRequestRejectFailure))
                )
        ),
        catchError(error => notifyError(error, "groupAccessRequestRejectEpic", groupActions.groupAccessRequestRejectFailure))
    );

const groupAccessRequestRejectSuccessEpic = action$ =>
    action$.pipe(
        ofType(groupActions.GROUP_ACCESS_REQUEST_REJECT_SUCCESS),
        map((action: any) => profileRequest())
    );

const groupDashboardRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_REQUEST_SUCCESS),
        map((action: any) => groupActions.groupDashboardIncidentsRequest())
    );

const groupDashboardIncidentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(groupActions.GROUP_DASHBOARD_INCIDENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.getIncidents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.groups.dashboard.filter)
                .pipe(
                    map(response => groupActions.groupDashboardIncidentsRequestSuccess(response.incidents, response.venueMarkers)),
                    catchError(error => notifyError(error, "groupDashboardIncidentsRequestEpic.getIncidents", groupActions.groupDashboardIncidentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "groupDashboardIncidentsRequestEpic", groupActions.groupDashboardIncidentsRequestFailure))
    );

const cloneGroupChecklistTemplate = (action$, state$) =>
        action$.pipe(
            ofType(groupActions.GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
            checklistTemplateApi.copyChecklistTemplates(
              state.user.details.userSession.user.venueId,
              state.user.details.userSession.user.securityFirmId,
              [action['checklistTemplateId']],
              false,
              action['groupId'])
              .pipe(
                  map(response => groupActions.groupChecklistTemplateCloneRequestSuccess(response.checklistTemplate.checklistTemplateId)),
                  catchError(error => notifyError(error, "copyChecklistTemplate.copyChecklistTemplates", groupActions.groupChecklistTemplateCloneRequestFailure))
              )
            ),
            catchError(error => notifyError(error, "copyChecklistTemplate", groupActions.groupChecklistTemplateCloneRequestFailure))
        );

export const groupEpics = combineEpics(
    groupsRequestEpic,
    groupRequestEpic,
    groupCreateEpic,
    groupUpsertEpic,
    groupUpsertSuccessRefreshEpic,
    groupSetFilter,
    groupVenuesAvailableRequestEpic,
    groupVenuesInsertRequestEpic,
    groupVenueUpdateRequestEpic,
    groupUsersAvailableRequestEpic,
    groupUsersInsertRequestEpic,
    groupUserUpdateRequestEpic,
    groupDashboardResetOnUserChangeEpic,
    groupDashboardFilterRequestEpic,
    groupDashboardFilterRequestSuccessEpic,
    groupDashboardRequestEpic,
    groupDashboardSetFilterEpic,
    groupDashboardExportRequestEpic,
    groupDashboardExportRequestSuccessEpic,
    groupsSelectionRequestEpic,
    groupAccessRequestEpic,
    groupAccessRequestRejectEpic,
    groupAccessRequestRejectSuccessEpic,
    groupAccessRequestSuccessEpic,
    groupDashboardRequestSuccessEpic,
    groupDashboardIncidentsRequestEpic,
    groupUpsertSuccessRefreshDirectoryEpic,
    cloneGroupChecklistTemplate
);