import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CardRow from '../../common/CardRow';
import { roleTypeLogic } from '../../../common/logic/roleTypeLogic';

export interface IRoleTypeCardProps {
    roleType: AusComplyDtos.UserRoleTypeSummary;
    onCommand: Function;
}

class RoleTypeCard extends React.Component<IRoleTypeCardProps, any> {
    constructor(props: IRoleTypeCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command: string) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.roleType);
        }
    }

    render() {
        var commands = roleTypeLogic.getCommands(this.props.roleType, true);

        return (
            <CardRow commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Role Type</CardTitle>
                        <CardTypography>
                        {this.props.roleType.venue && this.props.roleType.security 
                            ? 'Both' 
                            : this.props.roleType.venue ? 'Venue'
                            : this.props.roleType.security ? 'Security Firm'
                            : ''
                        }</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography><b>{this.props.roleType.name}</b></CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Access Level</CardTitle>
                        <CardTypography>{this.props.roleType.accessLevel}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RoleTypeCard);