import React, { Component } from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../controls/ControlGroup';
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import TextControlGroup from '../controls/TextControlGroup';
import { MulitSelectOption } from '../../common/dto/common';
import Information from '../common/Information';
import SelectControlGroup from '../controls/SelectControlGroup';
import PageLayout from '../layout/PageLayout';
import NotificationTrigger from './NotificationTrigger';
import { SingleSelectList } from '../../common/dto/common';


export interface INotificationTriggerEditProps {
    notificationTriggerParentType: AusComplyDTOs.ngtNotificationTriggerParentType,
    parentId: number,
    groupId?: number,
    venueId?: number,
    securityFirmId?: number,
    notificationTrigger: AusComplyDTOs.NotificationTrigger;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    onLoad: Function;
    onSave: Function;
    onUpdate: Function;
    onClose?: Function;
}

export default class NotificationTriggerEdit extends Component<INotificationTriggerEditProps, any> {
    constructor(props: INotificationTriggerEditProps) {
        super(props)
    }

    componentDidMount() {
        if(this.props.parentId && this.props.parentId > 0) {
            this.props.onLoad(
                this.props.notificationTriggerParentType,
                this.props.parentId,
                this.props.groupId,
                this.props.venueId,
                this.props.securityFirmId
            );
        }
    }

    componentDidUpdate(prevProps: Readonly<INotificationTriggerEditProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (!this.props.isLoading ) {
            if(prevProps.notificationTriggerParentType != this.props.notificationTriggerParentType
                || prevProps.parentId != this.props.parentId
                || prevProps.groupId != this.props.groupId
                || prevProps.venueId != this.props.venueId
                || prevProps.securityFirmId != this.props.securityFirmId
            ) {
                if(this.props.parentId && this.props.parentId > 0) {
                    this.props.onLoad(
                        this.props.notificationTriggerParentType,
                        this.props.parentId,
                        this.props.groupId,
                        this.props.venueId,
                        this.props.securityFirmId
                    );
                }
            }
        }
        if (prevProps.isSaving && !this.props.isSaving && this.props.error == "") {
            if(this.props.onClose) {
                this.props.onClose();
            }
        }
    }


    render() {

        return <PageLayout
            headerText={""}
            loading={this.props.isLoading}
            saving={this.props.isSaving}>
            <NotificationTrigger 
                notificationTrigger={this.props.notificationTrigger}
                onUpdate={value => this.props.onUpdate(value)} />
        </PageLayout>;
    }
}