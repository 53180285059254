import { makeActionCreator } from './helpers';

export const
    BROADCAST_NOTIFICATIONS_REQUEST = "BROADCAST_NOTIFICATIONS_REQUEST",
    BROADCAST_NOTIFICATIONS_REFRESH = "BROADCAST_NOTIFICATIONS_REFRESH",
    BROADCAST_NOTIFICATIONS_REQUEST_SUCCESS = "BROADCAST_NOTIFICATIONS_REQUEST_SUCCESS",
    BROADCAST_NOTIFICATIONS_REQUEST_FAILURE = "BROADCAST_NOTIFICATIONS_REQUEST_FAILURE",
    BROADCAST_NOTIFICATION_RESET = "BROADCAST_NOTIFICATION_RESET",
    BROADCAST_NOTIFICATION_SET = "BROADCAST_NOTIFICATION_SET",
    BROADCAST_NOTIFICATION_REQUEST = "BROADCAST_NOTIFICATION_REQUEST",
    BROADCAST_NOTIFICATION_REQUEST_SUCCESS = "BROADCAST_NOTIFICATION_REQUEST_SUCCESS",
    BROADCAST_NOTIFICATION_REQUEST_FAILURE = "BROADCAST_NOTIFICATION_REQUEST_FAILURE",
    BROADCAST_NOTIFICATION_CREATE_REQUEST = "BROADCAST_NOTIFICATION_CREATE_REQUEST",
    BROADCAST_NOTIFICATION_CREATE_REQUEST_SUCCESS = "BROADCAST_NOTIFICATION_CREATE_REQUEST_SUCCESS",
    BROADCAST_NOTIFICATION_CREATE_REQUEST_FAILURE = "BROADCAST_NOTIFICATION_CREATE_REQUEST_FAILURE",
    BROADCAST_NOTIFICATION_UPSERT_REQUEST = "BROADCAST_NOTIFICATION_UPSERT_REQUEST",
    BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS = "BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS",
    BROADCAST_NOTIFICATION_UPSERT_REQUEST_FAILURE = "BROADCAST_NOTIFICATION_UPSERT_REQUEST_FAILURE",
    BROADCAST_NOTIFICATION_TEST_REQUEST = "BROADCAST_NOTIFICATION_TEST_REQUEST",
    BROADCAST_NOTIFICATION_TEST_REQUEST_SUCCESS = "BROADCAST_NOTIFICATION_TEST_REQUEST_SUCCESS",
    BROADCAST_NOTIFICATION_TEST_REQUEST_FAILURE = "BROADCAST_NOTIFICATION_TEST_REQUEST_FAILURE";

export const broadcastNotificationsTestRequest = makeActionCreator(BROADCAST_NOTIFICATION_TEST_REQUEST, 'broadcastNotificationId');
export const broadcastNotificationsTestRequestSuccess = makeActionCreator(BROADCAST_NOTIFICATION_TEST_REQUEST_SUCCESS);
export const broadcastNotificationsTestRequestFailure = makeActionCreator(BROADCAST_NOTIFICATION_TEST_REQUEST_FAILURE, 'error');

export function broadcastNotificationsRefresh() {
    return {
        type: BROADCAST_NOTIFICATIONS_REFRESH
    }
}

export function broadcastNotificationsRequest(page, pageSize, filter) {
    return {
        type: BROADCAST_NOTIFICATIONS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function broadcastNotificationsRequestSuccess(broadcastNotifications, paging, filter) {
    return {
        type: BROADCAST_NOTIFICATIONS_REQUEST_SUCCESS,
        broadcastNotifications,
        paging,
        filter
    };
}

export function broadcastNotificationsRequestFailure(error) {
    return {
        type: BROADCAST_NOTIFICATIONS_REQUEST_FAILURE,
        error
    };
}

export function broadcastNotificationReset() {
    return {
        type: BROADCAST_NOTIFICATION_RESET
    };
}

export function broadcastNotificationSet(broadcastNotification) {
    return {
        type: BROADCAST_NOTIFICATION_SET,
        broadcastNotification
    };
}

export function broadcastNotificationRequest(broadcastNotificationId) {
    return {
        type: BROADCAST_NOTIFICATION_REQUEST,
        broadcastNotificationId
    };
}

export function broadcastNotificationRequestSuccess(broadcastNotification) {
    return {
        type: BROADCAST_NOTIFICATION_REQUEST_SUCCESS,
        broadcastNotification
    };
}

export function broadcastNotificationRequestFailure(error) {
    return {
        type: BROADCAST_NOTIFICATION_REQUEST_FAILURE,
        error
    };
}

export function broadcastNotificationCreateRequest(venueId, securityFirmId, cloneId) {
    return {
        type: BROADCAST_NOTIFICATION_CREATE_REQUEST,
        venueId,
        securityFirmId,
        cloneId
    };
}

export function broadcastNotificationCreateRequestSuccess(broadcastNotification) {
    return {
        type: BROADCAST_NOTIFICATION_CREATE_REQUEST_SUCCESS,
        broadcastNotification
    };
}

export function broadcastNotificationCreateRequestFailure(error) {
    return {
        type: BROADCAST_NOTIFICATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function broadcastNotificationUpsertRequest() {
    return {
        type: BROADCAST_NOTIFICATION_UPSERT_REQUEST
    };
}

export function broadcastNotificationUpsertRequestSuccess(broadcastNotification) {
    return {
        type: BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS,
        broadcastNotification
    };
}

export function broadcastNotificationUpsertRequestFailure(error) {
    return {
        type: BROADCAST_NOTIFICATION_UPSERT_REQUEST_FAILURE,
        error
    };
}