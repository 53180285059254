
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { geoLocationApi } from '../../utilities/geoLocation';

import {
    LOCATION_REQUEST,
    locationChanged
} from "../actions/location";

const locationRequestEpic = (action$) =>
    action$.pipe(
        ofType(LOCATION_REQUEST),
        mergeMap(action =>
            geoLocationApi.getCurrentPositionObservable()
                .pipe(
                    map((response: any) => locationChanged(true, response["coords"].latitude, response["coords"].longitude, '')),
                    catchError(error =>
                        of(
                            locationChanged(false, 0, 0, error.message)
                        )
                    )
                )
        ),
        catchError(error =>
            of(
                locationChanged(false, 0, 0, error.message)
            )
        )
    );


export const locationEpics = combineEpics(
    locationRequestEpic
);