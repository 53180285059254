import { connect } from 'react-redux'
import Profile from '../components/profile/Profile'
import {
    profileSetTheme,
    profileReset,
    profileRequest,
    profileSaveOtherDocuments,
    profileRemoveOtherDocument,
    profileSaveComplianceDocuments,
    profileRemoveComplianceDocument,
    profileUpdate,
    profileSaveRequest,
    profileSledCheckRequest,
    profileUpdateAvatarRequest,
    profileChangePasswordRequest
} from '../common/actions/profile';
import { 
    groupAccessRequestReject
} from '../common/actions/groups';
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const isVenueUserRoleType = (userRoleType: number) => {
    return userRoleType == 1 || userRoleType == 3;
}

const isSecurityFirmRoleType = (userRoleType: number) => {
    return userRoleType == 2 || userRoleType == 3;
}

const mapStateToProps = state => {
    return {
        isDark: state.profile.isDark,
        profile: state.profile.profile,
        changePasswordError: state.profile.changePasswordError,
        errors: state.profile.errors,
        avatar: state.profile.avatar,
        isLoading: state.profile.isProfileLoading || state.user.isLoading,
        isSaving: state.profile.isSaving,
        isCheckingLicense: state.profile.isCheckingLicense,
        error: state.profile.error,
        userRoleRequests: state.profile.profile.userRoleRequests,
        userRoleSummaries: state.profile.profile.userRoleSummaries,
        user: state.user.details.userSession.user,
        states: state.profile.states,
        genders: state.profile.genders,
        otherDocuments: state.profile.otherDocuments,
        otherDocumentTypes: state.profile.otherDocumentTypes,
        complianceDocuments: state.profile.complianceDocuments,
        groupAccessRequests: state.profile.groupAccessRequests,
        complianceDocumentTypes: state.profile.complianceDocumentTypes,
        hasEditPermission: permissionsLogic.hasAnyPermissionForState(state, ["EditL1UserAccess", "EditTempL1UserAccess", "EditTempL2UserAccess", "EditL2UserAccess", "EditTempL3UserAccess", "EditL3UserAccess", "EditL4UserAccess", "EditTempL4UserAccess"]),
        isVenueUserRoleType: isVenueUserRoleType(state.user.details.userSession.user.userRoleTypes),
        isSecurityFirmRoleType: isSecurityFirmRoleType(state.user.details.userSession.user.userRoleTypes)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetTheme: (isDark) => {
            dispatch(profileSetTheme(isDark))
        },
        onProfileReset: () => {
            dispatch(profileReset())
        },
        onLoadProfile: () => {
            dispatch(profileRequest())
        },
        onSaveOtherDocuments: (documentTypeId, files) => {
            dispatch(profileSaveOtherDocuments(documentTypeId, files))
        },
        onRemoveOtherDocument: (otherDocument) => {
            dispatch(profileRemoveOtherDocument(otherDocument))
        },
        onSaveComplianceDocuments: (documentTypeId, expiryDate, files) => {
            dispatch(profileSaveComplianceDocuments(documentTypeId, expiryDate, files))
        },
        onRemoveComplianceDocument: (complianceDocument) => {
            dispatch(profileRemoveComplianceDocument(complianceDocument))
        },
        onUpdateProfile: (profile) => {
            dispatch(profileUpdate(profile))
        },
        onSaveProfile: (profile) => {
            dispatch(profileSaveRequest(profile))
        },
        onCheckLicense: (profile) => {
            dispatch(profileSledCheckRequest(profile))
        },
        onUpdateAvatar: (file) => {
            dispatch(profileUpdateAvatarRequest(file))
        },
        onChangePassword: (userId, oldPassword, newPassword, newPasswordConfirm) => {
            dispatch(profileChangePasswordRequest(userId, oldPassword, newPassword, newPasswordConfirm))
        },
        onRemoveGroupRequest: (notificationId, groupId) => {
            dispatch(groupAccessRequestReject(notificationId, groupId))
        }
    }
}

const ProfileContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile)

export default ProfileContainer