
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { notificationServiceApi } from '../services/notificationService';

import {
    NOTIFICATIONS_REQUEST,
    notificationsRequest,
    notificationsRequestSuccess,
    notificationsRequestFailure,
    NOTIFICATIONS_ACTION_REQUEST,
    notificationsActionRequestSuccess,
    notificationsActionRequestFailure,
    NOTIFICATIONS_ACTION_REQUEST_SUCCESS,
    NOTIFICATIONS_ACTIONS_REQUEST,
    notificationsActionsRequestSuccess,
    notificationsActionsRequestFailure,
    NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS
} from "../actions/notifications";
import {
    userVenueSecurityFirmQuietLoadStatistics
} from "../actions/userVenueSecurityFirm";
import {
    USER_ROLE_ADD_SAVE_SUCCESS
} from "../actions/userRole";
import {
    notifyError, notifyErrorMessage
} from './common';

const notificationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(NOTIFICATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.getNotifications(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => notificationsRequestSuccess(response.groupedNotifications, response.alerts, response.groupNotifications)),
                    catchError(error => notifyErrorMessage(error, "Failed to load notifications", notificationsRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load notifications", notificationsRequestFailure))
    );

const notificationsActionRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(NOTIFICATIONS_ACTION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.actionNotification(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['notificationId'], action['action'])
                .pipe(
                    map(response => notificationsActionRequestSuccess()),
                    catchError(error => notifyError(error, "notificationsActionRequestEpic.actionNotification", notificationsActionRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "notificationsActionRequestEpic", notificationsActionRequestFailure))
    );

const notificationsActionRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(NOTIFICATIONS_ACTION_REQUEST_SUCCESS),
        map((action: any) => notificationsRequest())
    );


const notificationsUserRoleAssSaveSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_ROLE_ADD_SAVE_SUCCESS),
        map((action: any) => notificationsRequest())
    );

const notificationsActionsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(NOTIFICATIONS_ACTIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.actionNotifications(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['notificationIds'], action['action'])
                .pipe(
                    map(response => notificationsActionsRequestSuccess()),
                    catchError(error => notifyError(error, "notificationsActionsRequestEpic.actionNotifications", notificationsActionsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "notificationsActionsRequestEpic", notificationsActionsRequestFailure))
    );

const notificationsActionsRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS),
        map((action: any) => notificationsRequest())
    );

const notificationsActionsRequestSuccessRefreshUserEpic = action$ =>
    action$.pipe(
        ofType(NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS),
        map((action: any) => userVenueSecurityFirmQuietLoadStatistics())
    );

export const notificationsEpics = combineEpics(
    notificationsRequestEpic,
    notificationsActionRequestEpic,
    notificationsActionRequestSuccessEpic,
    notificationsUserRoleAssSaveSuccessEpic,
    notificationsActionsRequestEpic,
    notificationsActionsRequestSuccessEpic,
    notificationsActionsRequestSuccessRefreshUserEpic
);