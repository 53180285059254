import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IIncidentCategoryTypeCardProps {
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    canEdit: boolean;
    canDelete: boolean;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IncidentCategoryTypeCard extends React.Component<IIncidentCategoryTypeCardProps, any> {
    constructor(props: IIncidentCategoryTypeCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit && this.props.canEdit && !this.props.incidentCategoryType.obsolete) {
                    this.props.onEdit(this.props.incidentCategoryType);
                }
                break;
            case "remove":
                if (this.props.onUpdate && this.props.canDelete) {
                    let incidentCategoryType = { ...this.props.incidentCategoryType };
                    incidentCategoryType.obsolete = true;
                    this.props.onUpdate(incidentCategoryType);
                }
                break;
            case "enable":
                if (this.props.onUpdate && this.props.canDelete) {
                    let incidentCategoryType = { ...this.props.incidentCategoryType };
                    incidentCategoryType.obsolete = false;
                    this.props.onUpdate(incidentCategoryType);
                }
                break;
        }
    }

    render() {
        if (!this.props.incidentCategoryType) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.incidentCategoryType.obsolete && this.props.onUpdate && this.props.canDelete) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit && this.props.canEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate && this.props.canDelete) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        let legislatedTick;
        if (this.props.incidentCategoryType.legislated) {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let narrativeTick;
        if (this.props.incidentCategoryType.narrativeRequired) {
            narrativeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let approvalTick;
        if (this.props.incidentCategoryType.allowBulkApproval) {
            approvalTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let regulatedWalkthrough;
        if (this.props.incidentCategoryType.regulatedWalkthrough) {
            regulatedWalkthrough = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let poiTick;
        if (this.props.incidentCategoryType.requiresPersonDescription) {
            poiTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let contactTracingTick;
        if (this.props.incidentCategoryType.contactTracing) {
            contactTracingTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <CardRow isDanger={this.props.incidentCategoryType.obsolete}
                isWarning={this.props.incidentCategoryType.apiOnlyCreation}
                 commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={9}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.name}</CardTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.incidentCategoryType.rank}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Code</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.code}</CardTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>State</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.stateName}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Narrative</CardTitle>
                        {narrativeTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Legislated</CardTitle>
                        {legislatedTick}
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Bulk Approval</CardTitle>
                        {approvalTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Snapshot</CardTitle>
                        {regulatedWalkthrough}
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>POI Required</CardTitle>
                        {poiTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Contact Tracing</CardTitle>
                        {contactTracingTick}
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={12}>
                        <CardTitle>Incident Types</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{this.props.incidentCategoryType.incidentTypesDisplay}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={12}>
                        <CardTitle>Industries</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{this.props.incidentCategoryType.industryCategoriesDisplay}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}