import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FacialRecognitionWatchlistsContainer from '../../../containers/FacialRecognitionWatchlistsContainer';

export interface IAAdminFacialRecognitionWatchlistsProps {
}

interface IAdminFacialRecognitionWatchlistsState {
}

export default class AdminFacialRecognitionWatchlists extends Component<IAAdminFacialRecognitionWatchlistsProps, IAdminFacialRecognitionWatchlistsState> {
    constructor(props: IAAdminFacialRecognitionWatchlistsProps) {
        super(props)

        this.state = {
        };
    }

    render() {
        
        return (<>
            <PageTitle title="Global Watchlists"></PageTitle>
            <FacialRecognitionWatchlistsContainer venueId={0} groupId={0} />
        </>);
    }
}