import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import CellCard from './CellCard';
import CellHeader from './CellHeader';
import CellRow from './CellRow';
import FacilityCellContainer from '../../../containers/FacilityCellContainer';

export interface ICellsProps {
    isLoading: boolean;
    venueId: number;
    venueLocations: AusComplyDtos.VenueLocation[];
    venueLocationId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.VenueLocationFilter;
    canCreate: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class Cells extends Component<ICellsProps, any> {
    constructor(props: ICellsProps) {
        super(props)

        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.VenueLocationFilter) {
        let preparedFilter = { ...filter };
        preparedFilter.venueId = this.props.venueId;
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, venueLocation: AusComplyDtos.VenueLocation) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(venueLocation.venueLocationId);
                break;
            case "delete":
                var updated = { ...venueLocation };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.venueLocations && this.props.venueLocations.length > 0) {
            cardList = this.props.venueLocations.map((item, index) => {
                return (
                    <CellCard
                        onCommand={this.onCommand}
                        key={"card-" + item.venueLocationId.toString()}
                        venueLocation={item}
                    />
                );
            });
            rows = this.props.venueLocations.map((item, index) => {
                return (
                    <CellRow
                        onCommand={this.onCommand}
                        key={"row-" + item.venueLocationId.toString()}
                        venueLocation={item}
                        showCameras={this.props.filter.showCameras}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<CellHeader
                        showCameras={this.props.filter.showCameras} />}
                    rows={rows}
                />
            );
        }

        let venueLocation;
        if (this.props.venueLocationId != -1) {
            venueLocation = (
                <FacilityCellContainer />
            );
        }

        let createFab;
        if (this.props.canCreate) {
            createFab = (
                <DefaultFab onClick={() => this.props.onCreate(this.props.venueId)} isExtended={true}>Add Cell</DefaultFab>
            );
        }

        return (<>
            <PageTitle title="Cells"></PageTitle>

            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />

            <VenueQuickActionsContainer>
                {createFab}
            </VenueQuickActionsContainer>
            <FullPageDialog open={this.props.venueLocationId != -1} title="Cell" onDismissed={() => this.props.onResetItem()}>
                {venueLocation}
            </FullPageDialog>
        </>);
    }
}