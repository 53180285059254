export const
    USER_RESET = "USER_RESET",
    USER_CHANGED = "USER_CHANGED",
    USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST",
    USER_LOGOUT_REQUEST_SUCCESS = "USER_LOGOUT_REQUEST_SUCCESS",
    USER_LOGOUT_REQUEST_FAILURE = "USER_LOGOUT_REQUEST_FAILURE",
    USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST",
    USER_LOGIN_REQUEST_SUCCESS = "USER_LOGIN_REQUEST_SUCCESS",
    USER_LOGIN_REQUEST_FAILURE = "USER_LOGIN_REQUEST_FAILURE",
    USER_DETAILS_REFRESH = "USER_DETAILS_REFRESH",
    USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST",
    USER_DETAILS_REQUEST_SUCCESS = "USER_DETAILS_REQUEST_SUCCESS",
    USER_DETAILS_REQUEST_FAILURE = "USER_DETAILS_REQUEST_FAILURE",
    USER_DETAILS_VENUE_SECURITYFIRM_CHANGE = "USER_DETAILS_VENUE_SECURITYFIRM_CHANGE",
    USER_DETAILS_VENUE_SECURITYFIRM_CANCEL_CHANGE = "USER_DETAILS_VENUE_SECURITYFIRM_CANCEL_CHANGE",
    USER_LOG_INTO_VENUE_REQUEST = "USER_LOG_INTO_VENUE_REQUEST",
    USER_LOG_INTO_VENUE_REQUEST_SUCCESS = "USER_LOG_INTO_VENUE_REQUEST_SUCCESS",
    USER_LOG_INTO_VENUE_REQUEST_FAILURE = "USER_LOG_INTO_VENUE_REQUEST_FAILURE",
    USER_LOGIN_FORGOT_PASSWORD_REQUEST = "USER_LOGIN_FORGOT_PASSWORD_REQUEST",
    USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST = "USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST",
    USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_SUCCESS = "USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_SUCCESS",
    USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_FAILURE = "USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_FAILURE",
    USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST = "USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST",
    USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_SUCCESS = "USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_SUCCESS",
    USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_FAILURE = "USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_FAILURE",
    USER_EMAIL_CONFIRM_REQUEST = "USER_EMAIL_CONFIRM_REQUEST",
    USER_EMAIL_CONFIRM_REQUEST_SUCCESS = "USER_EMAIL_CONFIRM_REQUEST_SUCCESS",
    USER_EMAIL_CONFIRM_REQUEST_FAILURE = "USER_EMAIL_CONFIRM_REQUEST_FAILURE",
    USER_ACTIVE = "USER_ACTIVE",
    USER_ACTIVE_ENABLED = "USER_ACTIVE_ENABLED",
    USERS_LOGGED_IN_REQUEST = "USERS_LOGGED_IN_REQUEST",
    USERS_LOGGED_IN_REQUEST_SUCCESS = "USERS_LOGGED_IN_REQUEST_SUCCESS",
    USERS_LOGGED_IN_REQUEST_FAILURE = "USERS_LOGGED_IN_REQUEST_FAILURE",
    USER_LOGIN_PASSWORD_RESET_CODE_REQUEST = "USER_LOGIN_PASSWORD_RESET_CODE_REQUEST",
    USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_SUCCESS = "USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_SUCCESS",
    USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_FAILURE = "USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_FAILURE",
    USER_LOGIN_RESET_CODE = "USER_LOGIN_RESET_CODE",
    USER_LOGIN_SET_MOBILE = "USER_LOGIN_SET_MOBILE",
    USER_LOGIN_SET_CODE = "USER_LOGIN_SET_CODE",
    USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST = "USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST",
    USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_SUCCESS = "USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_SUCCESS",
    USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_FAILURE = "USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_FAILURE",
    USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST = "USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST",
    USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_SUCCESS = "USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_SUCCESS",
    USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_FAILURE = "USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_FAILURE",
    USER_CLEAR_VENUE_MESSAGE = "USER_CLEAR_VENUE_MESSAGE",
    BETA_MODE = "BETA_MODE";


export function clearUserVenueMessage() {
    return {
        type: USER_CLEAR_VENUE_MESSAGE
    }
}
export function userReset() {
    return {
        type: USER_RESET
    };
}

export function userChanged(username) {
    return {
        type: USER_CHANGED,
        username
    };
}

export function userLogoutRequest() {
    return {
        type: USER_LOGOUT_REQUEST
    };
}

export function userLogoutRequestSuccess() {
    return {
        type: USER_LOGOUT_REQUEST_SUCCESS
    };
}

export function userLogoutRequestFailure() {
    return {
        type: USER_LOGOUT_REQUEST_FAILURE
    };
}

export function userDetailsRefresh() {
    return {
        type: USER_DETAILS_REFRESH
    };
}

export function userLoginRequest(username, password) {
    return {
        type: USER_LOGIN_REQUEST,
        username,
        password
    };
}

export function userLoginRequestSuccess(bearerToken, meta, refreshToken) {
    return {
        type: USER_LOGIN_REQUEST_SUCCESS,
        bearerToken,
        meta,
        refreshToken
    };
}

export function userLoginRequestFailure(error) {
    return {
        type: USER_LOGIN_REQUEST_FAILURE,
        error
    };
}

export function userDetailsRequest(venueId, securityFirmId, venueEventId) {
    return {
        type: USER_DETAILS_REQUEST,
        venueId,
        securityFirmId,
        venueEventId
    };
}

export function userDetailsRequestSuccess(details) {
    return {
        type: USER_DETAILS_REQUEST_SUCCESS,
        details
    };
}

export function userDetailsRequestFailure(error) {
    return {
        type: USER_DETAILS_REQUEST_FAILURE,
        error
    };
}

export function userDetailsVenueSecurityFirmChange() {
    return {
        type: USER_DETAILS_VENUE_SECURITYFIRM_CHANGE
    }
}

export function userDetailsVenueSecurityFirmCancelChange() {
    return {
        type: USER_DETAILS_VENUE_SECURITYFIRM_CANCEL_CHANGE
    }
}

export function userLogIntoVenueRequest(code) {
    return {
        type: USER_LOG_INTO_VENUE_REQUEST,
        code
    };
}

export function userLogIntoVenueRequestSuccess() {
    return {
        type: USER_LOG_INTO_VENUE_REQUEST_SUCCESS
    };
}

export function userLogIntoVenueRequestFailure(error) {
    return {
        type: USER_LOG_INTO_VENUE_REQUEST_FAILURE,
        error
    };
}

export function userLoginForgotPasswordRequest(username) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_REQUEST,
        username
    };
}

export function userLoginForgotPasswordValidateRequest(userId, userForgotPasswordId, forgotPasswordGuid) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST,
        userId,
        userForgotPasswordId,
        forgotPasswordGuid
    };
}

export function userLoginForgotPasswordValidateRequestSuccess(isValid) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_SUCCESS,
        isValid
    };
}

export function userLoginForgotPasswordValidateRequestFailure(error) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_FAILURE,
        error
    };
}

export function userLoginPasswordResetRequest(userId, userForgotPasswordId, forgotPasswordGuid, newPassword, newPasswordConfirm) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST,
        userId,
        userForgotPasswordId,
        forgotPasswordGuid,
        newPassword,
        newPasswordConfirm
    };
}

export function userLoginPasswordResetRequestSuccess() {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_SUCCESS
    };
}

export function userLoginPasswordResetRequestFailure(error) {
    return {
        type: USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_FAILURE,
        error
    };
}

export function userEmailConfirmRequest(userId, emailConfirmationGuid, password) {
    return {
        type: USER_EMAIL_CONFIRM_REQUEST,
        userId,
        emailConfirmationGuid,
        password
    }
}

export function userEmailConfirmRequestSuccess() {
    return {
        type: USER_EMAIL_CONFIRM_REQUEST_SUCCESS
    };
}

export function userEmailConfirmRequestFailure(error) {
    return {
        type: USER_EMAIL_CONFIRM_REQUEST_FAILURE,
        error
    };
}

export function userActive() {
    return {
        type: USER_ACTIVE
    }
}

export function userActionEnable(enable) {
    return {
        type: USER_ACTIVE_ENABLED,
        enable
    }
}

export function usersLoggedInRequest() {
    return {
        type: USERS_LOGGED_IN_REQUEST
    };
}

export function usersLoggedInRequestSuccess(loggedInUsers) {
    return {
        type: USERS_LOGGED_IN_REQUEST_SUCCESS,
        loggedInUsers
    };
}

export function usersLoggedInRequestFailure(error) {
    return {
        type: USERS_LOGGED_IN_REQUEST_FAILURE,
        error
    };
}

export function userLoginPasswordResetCodeRequest(mobile) {
    return {
        type: USER_LOGIN_PASSWORD_RESET_CODE_REQUEST,
        mobile
    };
}

export function userLoginPasswordResetCodeSuccess() {
    return {
        type: USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_SUCCESS
    };
}

export function userLoginPasswordResetCodeFailure(error) {
    return {
        type: USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_FAILURE,
        error
    };
}

export function userLoginValidatePasswordResetCodeRequest(mobile, code) {
    return {
        type: USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST,
        mobile,
        code
    };
}

export function userLoginValidatePasswordResetCodeRequestSuccess(isValid) {
    return {
        type: USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_SUCCESS,
        isValid
    };
}

export function userLoginValidatePasswordResetCodeRequestFailure(error) {
    return {
        type: USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_FAILURE,
        error
    };
}

export function userLoginResetPasswordWithCodeRequest(mobile, code, newPassword, newPasswordConfirm) {
    return {
        type: USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST,
        mobile,
        code,
        newPassword,
        newPasswordConfirm
    };
}

export function userLoginResetPasswordWithCodeRequestSuccess() {
    return {
        type: USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_SUCCESS
    };
}

export function userLoginResetPasswordWithCodeRequestFailure(error) {
    return {
        type: USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_FAILURE,
        error
    };
}

export function userLoginResetCode() {
    return {
        type: USER_LOGIN_RESET_CODE
    }
}

export function userLoginSetMobile(mobile) {
    return {
        type: USER_LOGIN_SET_MOBILE,
        mobile
    }
}

export function userLoginSetCode(code) {
    return {
        type: USER_LOGIN_SET_CODE,
        code
    }
}

export function betaMode(isBeta) {
    return {
        type: BETA_MODE,
        isBeta
    }
}