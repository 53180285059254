import { connect } from 'react-redux'
import FacialRecognitionAutomaticEnrolments from '../components/facialRecognition/facialRecognitionAutomaticEnrolments/FacialRecognitionAutomaticEnrolments'
import {
    facialRecognitionAutomaticEnrolmentsRequest,
    facialRecognitionAutomaticEnrolmentRequest, 
    facialRecognitionAutomaticEnrolmentReset,
    facialRecognitionAutomaticEnrolmentSet,
    facialRecognitionAutomaticEnrolmentCreateRequest,
    facialRecognitionAutomaticEnrolmentUpsertRequest,
    facialRecognitionAutomaticEnrolmentProcessRequest
} from '../common/actions/facialRecognitionAutomaticEnrolment';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        venueMode: props.venueMode,
        adminMode: props.adminMode,
        isLoading: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolments.isLoading,
        isSaving: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.isSaving,
        facialRecognitionAutomaticEnrolments: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolments,
        facialRecognitionAutomaticEnrolment: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolment,
        facialRecognitionAutomaticEnrolmentId: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentId,
        isLoadingItem: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.isLoading,
        incidentTypes: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.incidentTypes,
        incidentCategoryTypes: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.incidentCategoryTypes,
        watchlists: state.facialRecognitionAutomaticEnrolments.facialRecognitionAutomaticEnrolment.watchlists
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(facialRecognitionAutomaticEnrolmentsRequest(venueId))
        },
        onLoadItem: (facialRecognitionAutomaticEnrolmentId) => {
            dispatch(facialRecognitionAutomaticEnrolmentRequest(facialRecognitionAutomaticEnrolmentId))
        },
        onCreate: (forVenueId) => {
            dispatch(facialRecognitionAutomaticEnrolmentCreateRequest(forVenueId))
        },
        onResetItem: () => {
            dispatch(facialRecognitionAutomaticEnrolmentReset())
        },
        onUpdate: (facialRecognitionAutomaticEnrolment) => {
            dispatch(facialRecognitionAutomaticEnrolmentSet(facialRecognitionAutomaticEnrolment))
        },
        onSave: () => {
            dispatch(facialRecognitionAutomaticEnrolmentUpsertRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionAutomaticEnrolmentReset())
        },
        onProcess: (facialRecognitionAutomaticEnrolmentId, date) => {
            dispatch(facialRecognitionAutomaticEnrolmentProcessRequest(facialRecognitionAutomaticEnrolmentId, date))
        }
    }
}

const AdminFacialRecognitionAutomaticEnrolmentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionAutomaticEnrolments)

export default AdminFacialRecognitionAutomaticEnrolmentsContainer