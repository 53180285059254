import { combineEpics } from "redux-observable";
import { userSessionEpics } from "./userSession";
import { venueSecurityFirmEpics } from "./venueSecurityFirm";
import { userVenueSecurityFirmEpics } from './userVenueSecurityFirm';
import { contentEpics } from './content';
import { locationEpics } from './location';
import { venueEpics } from './venue';
import { securityFirmEpics } from './securityFirm';
import { signOnEpics } from './signOn';
import { incidentEpics } from './incidents';
import { printEpics } from './print';
import { incidentFindEpics } from './incidentFind';
import { incidentDisplayEpics } from './incidentDisplay';
import { profileEpics } from './profile';
import { adminEpics } from './admin';
import { userRoleEpics } from './userRole';
import { registerUserEpics } from './registerUser';
import { notificationsEpics } from './notifications';
import { checklistEpics } from './checklist';
import { checklistTemplatesEpics } from './checklistTemplates';
import { checklistTemplateEpics } from './checklistTemplate';
import { referenceDataEpics } from './referenceData';
import { venueReportingPeriodScheduleEpics } from './venueReportingPeriodSchedule';
import { venueLocationEpics } from './venueLocation';
import { venueIncidentCategoryTypeEpics } from './venueIncidentCategoryType';
import { userComplianceDocumentEpics } from './userComplianceDocument';
import { venueContactEpics } from './venueContact';
import { securityFirmContactEpics } from './securityFirmContact';
import { venueDocumentEpics } from './venueDocuments';
import { systemLogEpics } from './systemLog';
import { registerSecurityFirmEpics } from './registerSecurityFirm';
import { dashboardEpics } from './dashboard';
import { adminDashboardEpics } from './adminDashboard';
import { securityFirmDocumentEpics } from './securityFirmDocuments';
import { securityFirmAssociationEpics } from './securityFirmAssociation';
import { broadcastNotificationEpics } from './broadcastNotification';
import { facialRecognitionNotificationEpics } from './facialRecognitionNotification';
import { incidentFlagTypeNotificationEpics } from './incidentFlagTypeNotification';
import { incidentActionTemplateEpics } from './incidentActionTemplate';
import { groupEpics } from './group';
import { facialRecognitionServerEpics } from './facialRecognitionServer';
import { facialRecognitionWatchlistEpics } from './facialRecognitionWatchlist';
import { facialRecognitionPOIEpics } from './facialRecognitionPOI';
import { facialRecognitionFeedEpics } from './facialRecognitionFeed';
import { facialRecognitionAutomaticEnrolmentEpics } from './facialRecognitionAutomaticEnrolment';
import { correctionalFacilityEpics } from './correctionalFacility';
import { directoryEpics } from './directory';
import { scheduleEpics } from './schedule';
import { userActionAuditInsertEpics } from './userActionAudit';
import { imageProcessingEpics } from './imageProcessing';
import { radioChannelEpics } from './radioChannels';
import { functionalAreaEpics } from './functionalAreas';
import { subLocationEpics } from './subLocations';
import { incidentCategoryTypeEpics } from './incidentCategoryTypes';
import { noteEpics } from './notes';
import { rosterEpics } from './rosters';
import { externalApiConnectionEpics } from './externalApiConnection';
import { signOnRegisterTypeEpics } from './signOnRegisterTypes';
import { externalEventEpics } from './externalEvents';
import { notificationTriggerEpics } from './notificationTrigger';
import { venueEventEpics } from './venueEvents';

export const rootEpic = combineEpics(
    userSessionEpics,
    venueSecurityFirmEpics,
    userVenueSecurityFirmEpics,
    contentEpics,
    locationEpics,
    venueEpics,
    securityFirmEpics,
    signOnEpics,
    incidentEpics,
    printEpics,
    incidentFindEpics,
    incidentDisplayEpics,
    profileEpics,
    adminEpics,
    userRoleEpics,
    registerUserEpics,
    notificationsEpics,
    checklistEpics,
    checklistTemplateEpics,
    checklistTemplatesEpics,
    referenceDataEpics,
    venueReportingPeriodScheduleEpics,
    venueLocationEpics,
    venueIncidentCategoryTypeEpics,
    userComplianceDocumentEpics,
    venueContactEpics,
    securityFirmContactEpics,
    venueDocumentEpics,
    systemLogEpics,
    registerSecurityFirmEpics,
    dashboardEpics,
    adminDashboardEpics,
    securityFirmDocumentEpics,
    securityFirmAssociationEpics,
    broadcastNotificationEpics,
    facialRecognitionNotificationEpics,
    incidentFlagTypeNotificationEpics,
    incidentActionTemplateEpics,
    groupEpics,
    facialRecognitionServerEpics,
    facialRecognitionWatchlistEpics,
    facialRecognitionPOIEpics,
    facialRecognitionFeedEpics,
    facialRecognitionAutomaticEnrolmentEpics,
    correctionalFacilityEpics,
    directoryEpics,
    scheduleEpics,
    userActionAuditInsertEpics,
    imageProcessingEpics,
    radioChannelEpics,
    functionalAreaEpics,
    subLocationEpics,
    incidentCategoryTypeEpics,
    noteEpics,
    rosterEpics,
    externalApiConnectionEpics,
    signOnRegisterTypeEpics,
    externalEventEpics,
    notificationTriggerEpics,
    venueEventEpics
);
