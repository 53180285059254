import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmContact from '../components/securityFirm/securityFirmContact/SecurityFirmContact'
import { 
    securityFirmContactReset,
    securityFirmContactSet,
    securityFirmContactUpsertRequest
 } from '../common/actions/securityFirm';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.securityFirm.securityFirmContact.isLoading,
        isSaving: state.securityFirm.securityFirmContact.isSaving,
        securityFirmContact: state.securityFirm.securityFirmContact.securityFirmContact,
        securityFirmContactTypes: state.securityFirm.securityFirmContact.securityFirmContactTypes,
        securityFirmUsers: state.securityFirm.securityFirmContact.securityFirmUsers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(securityFirmContactReset())
        },
        onUpdate: (securityFirmContact) => {
            dispatch(securityFirmContactSet(securityFirmContact))
        },
        onSave: () => {
            dispatch(securityFirmContactUpsertRequest())
        }
    }
}

const SecurityFirmContactContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmContact))

export default SecurityFirmContactContainer