import React, { Component } from 'react';
import UserRolesContainer from '../../containers/UserRolesContainer'

export interface ISecurityFirmUsers {
    securityFirmId: number;
}

export default class SecurityFirmUsers extends Component<ISecurityFirmUsers, any> {
    render() {
        return (
            <UserRolesContainer securityFirmId={this.props.securityFirmId}/>
        );
    }
}