
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { securityFirmServiceApi } from '../services/securityFirmService';
import { securityFirmSubscriptionServiceApi } from '../services/securityFirmSubscriptionService';

import {
    SECURITY_FIRM_REQUEST,
    securityFirmRequestSuccess,
    securityFirmRequestFailure,

    SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST,
    securityFirmSubscriptionSummaryRequestSuccess,
    securityFirmSubscriptionSummaryRequestFailure,

    SECURITY_FIRM_SAVE_REQUEST,
    securityFirmSaveRequestSuccess,
    securityFirmSaveRequestFailure

} from '../actions/securityFirm';
import {
    notifyError, notifyErrorMessage
} from './common';

const getSecurityFirmEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.get(
                state.user.details.userSession.user.venueId
                , state.user.details.userSession.user.securityFirmId
                , action['securityFirmId']
                , action['includeOptions'])
                .pipe(
                    map(response => securityFirmRequestSuccess(response.securityFirm, response.includesOptions, response.states, response.securityLicenceClasses)),
                    catchError(error => notifyError(error, "getSecurityFirmEpic.get", securityFirmRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getSecurityFirmEpic", securityFirmRequestFailure))
    );

const securityFirmSubscriptionSummaryRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SUBSCRIPTION_SUMMARY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmSubscriptionServiceApi.getSummary(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => securityFirmSubscriptionSummaryRequestSuccess(
                        response.securityFirmSubscriptionSummary)),
                    catchError(error => notifyError(error, "securityFirmSubscriptionSummaryRequestEpic.getSummary", securityFirmSubscriptionSummaryRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmSubscriptionSummaryRequestEpic", securityFirmSubscriptionSummaryRequestFailure))
    );

const securityFirmSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirm.securityFirm)
                .pipe(
                    map(response => securityFirmSaveRequestSuccess(response.securityFirm)),
                    catchError(error => notifyError(error, "securityFirmSaveNewEpic.save", securityFirmSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmSaveNewEpic", securityFirmSaveRequestFailure))
    );

export const securityFirmEpics = combineEpics(
    getSecurityFirmEpic,
    securityFirmSubscriptionSummaryRequestEpic,
    securityFirmSaveNewEpic
);