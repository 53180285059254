import React from 'react';
import { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { KeyName } from '../../common/dto/common';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Palette } from './../../common/constants/palette';

export interface IPopupModalProps {
    actions: KeyName[];
    onAction: Function;
    open: boolean;
    classes: any;
    theme: any;
}


class PopupModal extends Component<IPopupModalProps, any> {
    constructor(props: IPopupModalProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick = action => {
        this.props.onAction(action);
    }

    render() {
        const { theme, classes } = this.props;

        return (
            <Dialog onClose={() => this.onClick("")} aria-labelledby="simple-dialog-title" open={this.props.open} className={classes.dialogCenterHelper} style={{ }}>
                <List component="nav" aria-label="quick-actions" style={{ padding: 0, minWidth: '300px' }}>
                    {this.props.actions.map((item, index) => {
                        return (
                            <div key={"action-div-" + item.key} >
                                {index > 0 && (
                                    <hr key={"action-divider-" + item.key} style={{ margin: '0 10px', borderColor: Palette.Primary, borderStyle: 'solid' }} />
                                )}
                                <ListItem style={{ padding: 0 }} button key={"action-" + item.key} onClick={() => this.onClick(item.key)}>
                                    <ListItemText primary={item.name} style={{ padding: theme.spacing(1), textAlign: 'center' }} 
                                        primaryTypographyProps={{ style: { color: Palette.Text, fontSize: '14px' } }} />
                                </ListItem>
                            </div>
                        )
                    })}
                </List>
            </Dialog>
        );

        return (
            <>
                <Modal
                    open={this.props.open}
                    onClose={() => this.onClick("")}>
                    <Paper style={{
                        position: 'absolute',
                        width: 'auto',
                        bottom: '18px',
                        right: '18px',
                        boxShadow: theme.shadows[5],
                        padding: 0,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: theme.custom.colors.popupListBorder,
                        backgroundColor: theme.custom.colors.popupListBackground,
                        borderRadius: '5px',
                        outline: 'none'
                    }}>
                        <List component="nav" aria-label="quick-actions" style={{ padding: 0, minWidth: '220px' }}>
                            {this.props.actions.map((item, index) => {
                                return (
                                    <div key={"action-div-" + item.key} >
                                        {index > 0 && (
                                            <hr key={"action-divider-" + item.key} style={{ margin: '0 10px', borderColor: theme.custom.colors.popupListBorder, borderStyle: 'solid' }} />
                                        )}
                                        <ListItem style={{ padding: 0 }} button key={"action-" + item.key} onClick={() => this.onClick(item.key)}>
                                            <ListItemText primary={item.name} style={{ padding: theme.spacing(3) }} />
                                        </ListItem>
                                    </div>
                                )
                            })}
                        </List>
                    </Paper>
                </Modal>
            </>
        );
    }
}


export default withStyles(styles, { withTheme: true })(PopupModal);