import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentCategoryType, VenueLocation } from "../../../common/dto/AusComply.dtos";
import { incidentLogic } from '../../../common/logic/incidentLogic';
import ButtonLink from '../../controls/ButtonLink';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import SubmitIcon from '../../../resources/SubmitIcon';
import IncidentWizardChecklists from '../wizard/IncidentWizardChecklists';
import ErrorBoundary from '../../common/ErrorBoundary';
import Loading from '../../common/Loading';
import DeleteOutlinedIcon from '../../../resources/DeleteOutlinedIcon';
import NavigationButton from '../../controls/NavigationButton';
import PageLayout from '../../layout/PageLayout';
import CommLogRadioChannel from './CommLogRadioChannel';
import CommLogFunctionalArea from './CommLogFunctionalArea';
import CommLogIncidentCategory from './CommLogIncidentCategory';
import CommLogNarrative from './CommLogNarrative';
import CommLogLocation from './CommLogLocation';
import CommLogDocuments from './CommLogDocuments';
import CommLogPatron from './CommLogPatron';
import CommLogPostSave from './CommLogPostSave';
import CommLogFlags from './CommLogFlags';

export interface IDraftCommsLogIncidentProps {
    incidentTypeId: number;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    hasDraftIncident: boolean;
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onCreateIncident: Function;
    onUpdateIncident: Function;
    onClearError: Function;
    onClear: Function;
    onSaveDraft: Function;
    onSaveFinal: Function;
    onCheckIncidentDateReportingPeriod: Function;
    onErrorNotification: Function;
    onResetFilter: Function;
    history: any;
    location: any;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onRefreshLocation: Function;
    isUploading: boolean;
    onUploadFiles: Function;
    onUploadBase64File: Function;
    onUploadPatronFiles: Function;
    closeAfterSave: boolean;
    setDefaults: Function;
    action: string;
    setAction: Function;
    hasChecklists: boolean;
    onUploadAttachment: Function;
    onLoadChecklists: Function;
    isLoadingChecklists: boolean;
    canViewCommsLogs: boolean;
    onLoadIncidentOptions: Function;
}

interface IDraftCommsLogIncidentState {
    closeAfterSave: boolean;
    validDraft: boolean;
    validSubmit: boolean;
    incidentTypeName: string;
}

class DraftCommsLogIncident extends Component<IDraftCommsLogIncidentProps, IDraftCommsLogIncidentState> {

    constructor(props: IDraftCommsLogIncidentProps) {
        super(props)

        this.state = {
            validDraft: false,
            validSubmit: false,
            closeAfterSave: true,
            incidentTypeName: "Comms Log"
        }

        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onClearError = this.onClearError.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
        this.onUpdateLocation = this.onUpdateLocation.bind(this);
        this.onChangeCloseAfterSave = this.onChangeCloseAfterSave.bind(this);
        this.onOpenIncidient = this.onOpenIncidient.bind(this);
        this.onPatronUpdated = this.onPatronUpdated.bind(this);
        this.onUpdateAndRefreshChecklists = this.onUpdateAndRefreshChecklists.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        if (!this.props.hasDraftIncident || (this.props.hasDraftIncident && !this.props.incident)) {
            this.props.onCreateIncident();
            if (!this.props.locationFound) {
                this.props.onRefreshLocation();
            }
        }

        if (this.props.hasDraftIncident && !this.props.incidentOptions.incidentTypes){
            this.props.onLoadIncidentOptions();
        } else if (this.props.hasDraftIncident && this.props.incidentTypeId == 4) {
            this.onDraft();
        }
        this.setState({
            closeAfterSave: this.props.closeAfterSave
        });
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error && this.props.incidentTypeId == 4) {
                this.props.history.push('/draftincident/0');
            } else if (!this.props.error) {
                // Saved successfully
                if (this.props.closeAfterSave) {
                    this.clearAndClose();
                } else {
                    this.props.onCreateIncident();
                    return;
                }
            }
        }

        if (!this.props.isLoading && preProps.isLoading && this.props.incidentTypeId == 4) {
            this.onDraft();
        } else if (!this.props.isLoading && preProps.isLoading && this.props.error != "") {
            this.clearAndClose();
            return;
        }

        // eslint-disable-next-line
        if (!this.props.isLoading && preProps.isLoading && (!this.props.incident.incidentId || this.props.incident.incidentId == 0)) {
            this.onUpdateLocation();
            // set the default venue location to be the same as the last one
            //if (incidentLogic.setVenueLocation(this.props.incident, this.props.incidentOptions, this.props.venueLocationId)) {
            //    this.onUpdate({ ...this.props.incident });
            //}
        } else if (!this.props.isLoading && preProps.isLocationLoading && !this.props.isLocationLoading) {
            this.onUpdateLocation();
        }

        // eslint-disable-next-line
        if (this.props.incident && this.props.incident != preProps.incident) {
            var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            this.setState({
                validDraft: validationResult.draftOk,
                validSubmit: validationResult.idCheckOk,
                incidentTypeName: incidentLogic.incidentTypeName(this.props.incident, this.props.incidentOptions)
            });
        }
    }

    onClearError() {
        this.props.onClearError();
    }

    onCancel() {
        // cancel and navigate to home
        // if never saved - clear, otherwise delete
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        if (this.props.canViewCommsLogs) {
            this.props.history.push('/commslogs');
        } else {
            this.props.history.push('/home');
        }
    }

    onClose() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        if (this.props.canViewCommsLogs) {
            this.props.history.push('/commslogs');
        } else {
            this.props.history.push('/home');
        }
    }

    clearAndClose() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        if (this.props.canViewCommsLogs) {
            this.props.history.push('/commslogs');
        } else {
            this.props.history.push('/home');
        }
    }

    onUpdateLocation() {
        let incident = { ...this.props.incident };
        incident.locationFound = this.props.locationFound;
        incident.latitude = this.props.latitude;
        incident.longitude = this.props.longitude;
        this.props.onUpdateIncident(incident);
    }

    onUpdateAndRefreshChecklists(incident) {
        if (incidentLogic.requiresPOI(incident, this.props.incidentOptions)) {
            incidentLogic.ensureOnePOIRecordExists(incident, this.props.incidentOptions);
        } else {
            incident.incidentPatrons = [];
        }
        incident = incidentLogic.validateFunctionalAreasMatchRadioChannels(incident, this.props.incidentOptions);
        incident = incidentLogic.validateSubLocationsMatchVenueLocations(incident, this.props.incidentOptions);
        this.props.onUpdateIncident(incident);
        this.props.onLoadChecklists();
    }

    onDraft() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.draftOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveDraft();
    }

    onSubmit() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.idCheckOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        // save the defaults for the next ID Check
        this.props.setDefaults(this.props.incident.incidentLocation[0]["venueLocationId"], this.state.closeAfterSave);
        // Save
        this.props.onSaveFinal();
    }

    onUpdate(incident) {
        incident = incidentLogic.validateFunctionalAreasMatchRadioChannels(incident, this.props.incidentOptions);
        incident = incidentLogic.validateSubLocationsMatchVenueLocations(incident, this.props.incidentOptions);
        this.props.onUpdateIncident(incident);
    }

    onPatronUpdated(patron) {
        let incident = { ...this.props.incident };
        patron.hasChanged = true;
        incident.incidentPatrons = [patron];
        this.onUpdate(incident);
    }

    onChangeCloseAfterSave(closeAfterSave: boolean) {
        this.setState({ closeAfterSave });
    }

    onOpenIncidient() {
        this.props.history.push('/draftincident/0');
    }

    render() {
        let patron;
        if (this.props.incident && this.props.incident.incidentPatrons && this.props.incident.incidentPatrons.length > 0 && this.props.incident.incidentPatrons[0]) {
            patron = <CommLogPatron
                incident={this.props.incident}
                incidentOptions={this.props.incidentOptions}
                incidentPatron={this.props.incident.incidentPatrons[0]}
                onUpdated={updatedPatron => this.onPatronUpdated(updatedPatron)}
            />;
        }

        let checklists;
        if (this.props.hasChecklists && !this.props.isLoadingChecklists) {
            checklists = <IncidentWizardChecklists onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={() => { }} onSwipedRight={() => { }} />;
        }

        return (
            <PageLayout
                header={<PageTitle title={this.props.isLoading ? "Comms Log" : "New " + this.state.incidentTypeName} headerStyle={{ textAlign: 'center' }} />}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                notFullWidth={true}
                footerContent={<Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            padding: '0 20px'
                        }}>
                            <div style={{ flex: 0 }}>
                                <NavigationButton
                                    onClick={this.onCancel}
                                    text={"Discard"}
                                    isError={true}
                                    Icon={DeleteOutlinedIcon} />
                            </div>
                            <div style={{ flex: 0 }}>
                                <NavigationButton
                                    onClick={this.onSubmit}
                                    text={"Submit"}
                                    active={true}
                                    isSuccess={this.state.validSubmit && !this.props.isLoadingChecklists}
                                    Icon={SubmitIcon} />
                            </div>
                        </div>
                    </Grid>
                </Grid>}
            >
                <ErrorBoundary>
                    <CommLogRadioChannel incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateAndRefreshChecklists} />
                    <CommLogFunctionalArea incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateAndRefreshChecklists} />
                    <CommLogLocation incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate}
                        isLocationLoading={this.props.isLocationLoading}
                        locationError={this.props.locationError}
                        locationFound={this.props.locationFound}
                        latitude={this.props.latitude}
                        longitude={this.props.longitude}
                        onUpdateLocation={this.props.onRefreshLocation} />
                    <CommLogIncidentCategory incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateAndRefreshChecklists} />
                    <CommLogNarrative incident={this.props.incident} onUpdateIncident={this.onUpdate} />
                    <CommLogFlags incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} />
                    <>
                        {patron}
                    </>
                    {this.props.isLoadingChecklists && (
                        <Loading />
                    )}
                    {checklists}
                    <CommLogDocuments incident={this.props.incident} isUploading={this.props.isUploading} onUploadFiles={this.props.onUploadFiles} onUpdateIncident={this.onUpdate} />
                    <CommLogPostSave 
                        closeAfterSave={this.state.closeAfterSave}
                        onChange={value => this.onChangeCloseAfterSave(value)}/>
                    <ButtonLink onClick={this.onOpenIncidient}>
                        <span>Open as a regular incident</span>
                    </ButtonLink>
                </ErrorBoundary>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(DraftCommsLogIncident));