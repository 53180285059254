import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import FileUpload from '../../common/FileUpload';
import SelectControlGroup from '../../controls/SelectControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import Grid from '@material-ui/core/Grid';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface IRegisterSecurityFirmComplianceDocumentsProps {
    securityFirm: AusComplyDTOs.SecurityFirm;
    documentTypes: AusComplyDTOs.DocumentType[];
    documents: AusComplyDTOs.File[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    classes: any;
    theme: any;
}

interface IRegisterSecurityFirmComplianceDocumentsState {
    selectedFilePath: string;
    documentTypeId: number;
    documentVisibleToVenue: boolean;
    expiryDate: AusComplyDTOs.SimpleDate;
}

class RegisterSecurityFirmComplianceDocuments extends Component<IRegisterSecurityFirmComplianceDocumentsProps, IRegisterSecurityFirmComplianceDocumentsState> {
    constructor(props: IRegisterSecurityFirmComplianceDocumentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            documentTypeId: 0,
            documentVisibleToVenue: false,
            expiryDate: new AusComplyDTOs.SimpleDate()
        };
        this.onChange = this.onChange.bind(this);
        this.onDocumentTypeChanged = this.onDocumentTypeChanged.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeTemporary = this.removeTemporary.bind(this);
        this.onExpiryDateChanged = this.onExpiryDateChanged.bind(this);
        this.documentTypeDescription = this.documentTypeDescription.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    onChange(value) {
        /*
        let venue = { ...this.props.venue };
        venue. = value;
        this.props.onUpdate(venue);
        */
    }

    onExpiryDateChanged(value: AusComplyDTOs.SimpleDate) {
        this.setState({ expiryDate: value });
    }

    fileWasUploaded(temporaryFiles: AusComplyDTOs.File[]) {
        temporaryFiles.forEach(t => {
            t.documentTypeId = this.state.documentTypeId;
            t.visibleToVenue = this.state.documentVisibleToVenue;
            t.expiryDate = {...this.state.expiryDate};
        });
        let documents = [...this.props.documents, ...temporaryFiles];
        this.props.onUpdate(documents);

    }

    documentTypeDescription(documentTypeId) {
        let value = "";
        this.props.documentTypes.forEach(dt => {
            if (dt.documentTypeId == documentTypeId) {
                value = dt.name;
            }
        });
        return value;
    }

    removeTemporary(index: number) {
        let documents = [...this.props.documents];
        documents.splice(index, 1);
        this.props.onUpdate(documents);
    }

    onDocumentTypeChanged(value) {
        this.setState({ documentTypeId: value });
    }

    onVisibleToVenueChange(value) {
        this.setState({
            documentVisibleToVenue: value
        });
    }

    render() {
        let files = this.props.documents.map((item, index) => {
            let description = this.documentTypeDescription(item.documentTypeId);
            return (
                <Box p={0} key={"file-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        title={description}
                        onPreview={path => this.onSelectFilePath(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporary(index)}
                    />
                </Box >
            );
        })

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Compliance Documents"}>
                    <CardContent>
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                            {files}
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <SelectControlGroup text={"Document Folder"} onChange={this.onDocumentTypeChanged}>
                                    <option value={0} />
                                    {this.props.documentTypes.map((item, index) => (
                                        <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CheckboxControlGroup
                                    text={"Make Visible to Associated Venues"}
                                    label={" "}
                                    defaultValue={false}
                                    onChanged={(value) => this.onVisibleToVenueChange(value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SimpleDateControlGroup text="Expiry Date"
                                    date={this.state.expiryDate}
                                    onChange={(value) => this.onExpiryDateChanged(value)} />
                            </Grid>
                        </Grid>

                        {this.state.documentTypeId != 0 && (
                            <>
                                <FileUpload onFileWasUploaded={this.fileWasUploaded} alternateIcons={true} />
                            </>
                        )}
                    </CardContent>
                </CardContainer>
                <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
            </SwipeContainer>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RegisterSecurityFirmComplianceDocuments);