import { connect } from 'react-redux' ;
import PrintNavigationButton from '../components/print/PrintNavigationButton';
import * as printActions from '../common/actions/print';

const mapStateToProps = (state, props) => {
    return {
        hideQueue: props.hideQueue,
        moreCommands: props.moreCommands,
        onCommand: props.onCommand,
        queue: state.print.queue,
        onPrint: props.onPrint,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRefresh:() => {
            dispatch(printActions.printQueueRequest())
        },
        onDownloaded:(reportGenerationId) => {
            dispatch(printActions.printQueueDownloadRequest(reportGenerationId))
        },
        onDismissed:(reportGenerationId) => {
            dispatch(printActions.printQueueDismissRequest(reportGenerationId))
        },
        onDismissAll:() => {
            dispatch(printActions.printQueueDismissAllRequest())
        }
    }
}

const PrintNavigationButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintNavigationButton)

export default PrintNavigationButtonContainer