import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IIncidentFlagTypeCardProps {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IncidentFlagTypeCard extends React.Component<IIncidentFlagTypeCardProps, any> {
    constructor(props: IIncidentFlagTypeCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.incidentFlagType);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let incidentFlagType = { ...this.props.incidentFlagType };
                    incidentFlagType.obsolete = true;
                    this.props.onUpdate(incidentFlagType);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let incidentFlagType = { ...this.props.incidentFlagType };
                    incidentFlagType.obsolete = false;
                    this.props.onUpdate(incidentFlagType);
                }
                break;
        }
    }

    render() {
        if (!this.props.incidentFlagType) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.incidentFlagType.obsolete && this.props.onUpdate) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        return (
            <CardRow isDanger={this.props.incidentFlagType.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{this.props.incidentFlagType.name}</CardTypography>
                    </Grid>
                    <Grid item xs={4}>
                        {this.props.incidentFlagType.additionalField && (
                            <>
                                <CardTitle>Additional Field</CardTitle>
                                <CardTypography>{this.props.incidentFlagType.fieldLabel}</CardTypography>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.incidentFlagType.rank}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={12}>
                        <CardTitle>Industries</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{this.props.incidentFlagType.industryCategoriesDisplay}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}