import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ISubTitleProps {
    theme: any;
    style?: any;
    text: string;
}

class SubTitle extends React.PureComponent<ISubTitleProps, any> {
    constructor(props: ISubTitleProps){
        super(props)
    }
    render() {
        const { theme } = this.props;
        return (
            <h2
                style={{ ...theme.custom.subTitle, ...this.props.style}}
                >{this.props.text}</h2>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SubTitle);