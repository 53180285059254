import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../common/View';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import UsersCard from './UsersCard';
import UsersHeader from './UsersHeader';
import UsersRow from './UsersRow';
import UsersFilterSummary from './UsersFilterSummary';
import UsersFilter from './UsersFilter';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IUsersProps {
    isLoading: boolean;
    users: AusComplyDtos.User[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.UserFilter;
    onRefresh: Function;
    onFilterUpdate: Function;
    history: any;
}

interface IUsersState {
    filterExpanded: boolean;
}

class Users extends Component<IUsersProps, IUsersState> {
    constructor(props: IUsersProps) {
        super(props)

        this.state = {
            filterExpanded: false,
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onEdit(user: AusComplyDtos.User) {
        this.props.history.push('/admin/user/' + user.userId);
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(userFilter: AusComplyDtos.UserFilter) {
        this.props.onFilterUpdate(userFilter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let userFilter = new AusComplyDtos.UserFilter();
            this.props.onFilterUpdate(userFilter);
            this.props.onRefresh(userFilter, 1, this.props.paging.pageSize);
        });
    }

    render() {
        let cardList;
        let rowList;
        let rows;

        if (this.props.users && this.props.users.length > 0) {
            cardList = this.props.users.map((item, index) => {
                return (
                    <UsersCard
                        onClick={this.onEdit}
                        key={"card-" + item.userId.toString()}
                        user={item}
                    />
                );
            });

            rows = this.props.users.map((item, index) => {
                return (
                    <UsersRow
                        onClick={this.onEdit}
                        key={"row-" + item.userId.toString()}
                        user={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<UsersHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <UsersFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading )    {
            let userFilter = new AusComplyDtos.UserFilter();
            userFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UsersFilterSummary filter={userFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UsersFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (
            <>
                <PageTitle title="User Profiles"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Users);