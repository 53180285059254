import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TransparentButton from '../../controls/TransparentButton';
import View from '../../common/View';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import GroupCard from './GroupCard';
import GroupHeader from './GroupHeader';
import GroupRow from './GroupRow';
import ConfirmDialog from '../../controls/ConfirmDialog';
import GroupFilterSummary from './GroupFilterSummary';
import GroupFilter from './GroupFilter';
import PageLayout from '../../layout/PageLayout';

export interface IGroupsProps {
    isAdmin: boolean;
    isLoading: boolean;
    isSaving: boolean;
    groups: AusComplyDtos.Group[];
    filter: AusComplyDtos.GroupFilter;
    onUpdate: Function;
    onSetFilter: Function;
    onSet: Function;
    onReset: Function;
    canCreate: boolean;
    history: any;
    location: any;
}

export default class Groups extends Component<IGroupsProps, any> {
    constructor(props: IGroupsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            confirmUndoRemove: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.removeUndoConfirmed = this.removeUndoConfirmed.bind(this);
        this.prepareFilter = this.prepareFilter.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
    }

    componentDidMount() {
        this.props.onSetFilter(this.prepareFilter(this.props.filter));
        this.props.onReset();
    }

    componentDidUpdate(prevProps) {
        
    }

    prepareFilter(filter: AusComplyDtos.GroupFilter) {
        let preparedFilter = { ...filter };
        preparedFilter.isAdmin = this.props.isAdmin;
        return preparedFilter;
    }

    handlePageSelected(page) {
        let preparedFilter = this.prepareFilter(this.props.filter);
        preparedFilter.paging.page = page;
        this.props.onSetFilter(preparedFilter);
    }

    onCommand(command: string, group: AusComplyDtos.Group) {
        switch (command) {
            case "add":
                var url = this.props.location.pathname + "/0";
                this.props.history.push(url);
                break;
            case "edit":
                var url = this.props.location.pathname + "/" + group.groupId;
                this.props.history.push(url);
                break;
            case "delete":
                var updated = { ...group };
                updated.obsolete = true;
                this.props.onSet(updated);
                this.setState({
                    confirmRemove: true
                });
                break;
            case "undodelete":
                var updated = { ...group };
                updated.obsolete = false;
                this.props.onSet(updated);
                this.setState({
                    confirmUndoRemove: true
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            confirmUndoRemove: false
        });
    }

    removeConfirmed() {
        if (this.props.onUpdate && this.props.onSet) {
            this.setState({
                confirmRemove: false
            }, () => {
                this.props.onUpdate();
            });
        }
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.GroupFilter) {
        let preparedFilter = this.prepareFilter(filter);
        let self = this;
        preparedFilter.paging.page = 1;
        this.setState({ filterExpanded: false }, () => {
            self.props.onSetFilter(preparedFilter);
        })
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let filter = new AusComplyDtos.GroupFilter();
            filter.paging = this.props.filter.paging;
            filter.paging.page = 1;
            this.props.onSetFilter(this.prepareFilter(filter));
        });
    }

    removeUndoConfirmed() {
        if (this.props.onUpdate) {
            this.setState({
                confirmUndoRemove: false
            }, () => {
                this.props.onUpdate();
            });
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.groups && this.props.groups.length > 0) {
            cardList = this.props.groups.map((item, index) => {
                return (
                    <GroupCard
                        onCommand={this.onCommand}
                        canEdit={true}
                        key={"card-" + item.groupId.toString()}
                        group={item}
                    />
                );
            });
            rows = this.props.groups.map((item, index) => {
                return (
                    <GroupRow
                        onCommand={this.onCommand}
                        canEdit={true}
                        key={"row-" + item.groupId.toString()}
                        group={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<GroupHeader />}
                    rows={rows}
                />
            );
        }

        let createButton;
        if (this.props.canCreate) {
            createButton = <TransparentButton onClick={() => this.onCommand("add", new AusComplyDtos.Group())} text={"Add Group"} />
        }

        let filterSummary;
        if (this.props.isLoading) {
            let filter = new AusComplyDtos.GroupFilter();
            filter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <GroupFilterSummary filter={filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <GroupFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return <PageLayout 
            headerText={"Groups"} 
            loading={this.props.isLoading} 
            saving={this.props.isSaving}
            footerLeftContent={createButton}
            >
            {filterSummary}
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.filter.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <GroupFilter
                    open={this.state.filterExpanded}
                    filter={this.props.filter}
                    onSearch={(filter) => {
                        this.onUpdateFilter(filter);
                    }}
                    onReset={() => this.onResetSearch()}
                    onClose={() => this.filterExpanded(false)}
                     />
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this group"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
            <ConfirmDialog
                title={"Confirm undo remove"}
                text={"Are you sure you wish to re-enable this group"}
                show={this.state.confirmUndoRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeUndoConfirmed} />
        </PageLayout>;
    }
}