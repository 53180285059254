import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';
import LayoutForm from '../layout/LayoutForm';
import TextControlGroup from '../controls/TextControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import { MulitSelectOption } from '../../common/dto/common';
import MultiSelectControl from '../controls/MutiSelectControl';

export interface IRadioChannelProps {
    radioChannel: AusComplyDtos.RadioChannel;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IRadioChannelState {
    functionalAreas: MulitSelectOption[];
}

export default class RadioChannel extends Component<IRadioChannelProps, IRadioChannelState> {
    constructor(props: IRadioChannelProps) {
        super(props)
        this.state = {
            functionalAreas: []
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onSelectFunctionalAreasChanged = this.onSelectFunctionalAreasChanged.bind(this);
    }

    componentDidMount() {
        this.loadSelections(this.props.radioChannel);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.loadSelections(this.props.radioChannel);
        }
    }

    loadSelections(radioChannel: AusComplyDtos.RadioChannel) {
        let functionalAreas: MulitSelectOption[] = [];
        if (radioChannel && radioChannel.functionalAreas) {
            radioChannel.functionalAreas.forEach((functionalArea, index) => {
                let isSelected = false;
                let info = "";
                isSelected = radioChannel.selectedFunctionalAreas.indexOf(functionalArea.functionalAreaId) > -1;
                functionalAreas.push({
                    id: functionalArea.functionalAreaId,
                    name: functionalArea.name,
                    isCommon: false,
                    selected: isSelected,
                    info
                });
            });
        }
        this.setState({
            functionalAreas
        }, () => { });
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let radioChannel = { ...this.props.radioChannel };
        radioChannel[fieldName] = value;
        this.props.onUpdate(radioChannel)
    }

    onSelectFunctionalAreasChanged(items) {
        let radioChannel = { ...this.props.radioChannel };
        radioChannel.selectedFunctionalAreas = items.map(item => item.id);
        this.props.onUpdate(radioChannel);
    }

    render() {

        if (!this.props.radioChannel) {
            return (<></>);
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                fullWidth={true}
            >
                <form>
                    <TextControlGroup
                        text={"First name"}
                        defaultValue={this.props.radioChannel.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />


                    <MultiSelectControl
                        cardTitle={"Functional Areas"}
                        wrapControlInCard={true}
                        wrapInControlGroup={true}
                        items={this.state.functionalAreas}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectFunctionalAreasChanged}
                        canShowAll={true} />

                    <CheckboxControlGroup
                        text={"Active"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.radioChannel.obsolete}
                        onChanged={(value) => this.onValueChanged("obsolete", value)} />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}