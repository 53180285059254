import React from 'react';
import { RadioOption } from '../../common/dto/common';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';

export interface IRadioVerticalControlProps {
    name: string;
    selected?: string;
    options?: RadioOption[];
    onChange?: Function;
    readonly?: boolean;
}

export interface IRadioVerticalControlState {
    selected: string;
    options: RadioOption[];
    displayOptions: RadioOption[];
}

class RadioVerticalControl extends React.PureComponent<IRadioVerticalControlProps, IRadioVerticalControlState> {

    constructor(props: IRadioVerticalControlProps) {
        super(props);

        this.state = {
            selected: "",
            options: [],
            displayOptions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.optionsIdCSV = this.optionsIdCSV.bind(this);
    }

    async componentDidMount() {
        var selected = this.props.selected || "";
        var options = this.props.options || [];
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        this.setState({
            selected,
            options,
            displayOptions
        });
    }

    componentDidUpdate() {
        var selected = this.props.selected || this.state.selected;
        var options = this.props.options || this.state.options;
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        if (selected != this.state.selected ||
            this.optionsIdCSV(options) != this.optionsIdCSV(this.state.options) ||
            this.optionsIdCSV(displayOptions) != this.optionsIdCSV(this.state.displayOptions)) {
            this.setState({
                selected,
                options,
                displayOptions
            });
        }
    }

    optionsIdCSV(value: RadioOption[]) {
        if (value) {
            return value.map((item, index) => item.id).join(",");
        }
        return "";
    }

    handleChange = event => {
        let val = event.target.value;
        if (val != null) {
            this.setState({ selected: val });
            if (this.props.onChange) {
                this.props.onChange({ value: val });
            }
        }
    };

    render() {

        let radioOptions = this.state.displayOptions.map((item, index) => {
            return (
                <Box p={0} display="flex" flexDirection={'row'}>
                    <Box flexGrow={0} p={0}>
                        <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} style={{ marginRight: "10px" }} />
                    </Box>
                    <Box flexGrow={1} p={0}>
                        {item.content}
                    </Box>
                </Box>
            )
        });

        return (
            <FormControl style={{ width: '100%'}}>
                <RadioGroup
                    value={this.state.selected}
                    style={{ flexDirection: "column" }}
                    onChange={this.handleChange}>
                    {radioOptions}
                </RadioGroup>
            </FormControl>
        );
    }
}

export default RadioVerticalControl;