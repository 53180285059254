import { connect } from 'react-redux'
import EditUserRole from '../components/userRoles/editUserRole/EditUserRole'
import { userRoleLoadRequest, userRoleUpdate, userRoleUpdateSave  } from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        userRoleId: props.userRoleId,
        isLoading: state.userRole.isLoading,
        userRole: state.userRole.userRole,
        userRoleTypes: state.userRole.userRoleTypes,
        userRoleStatuses: state.userRole.userRoleStatus,
        isSaving: state.userRole.isSaving,
        error: state.userRole.error,
        onSuccess: props.onSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (userRoleId) => {
            dispatch(userRoleLoadRequest(userRoleId))
        },
        onChange: (userRole) => {
            dispatch(userRoleUpdate(userRole))
        },
        onSave: (userRole) => {
            dispatch(userRoleUpdateSave(userRole))
        }
    }
}

const AddUserRoleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserRole)

export default AddUserRoleContainer