import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import {
    BROADCAST_NOTIFICATIONS_REFRESH,
    BROADCAST_NOTIFICATIONS_REQUEST,
    BROADCAST_NOTIFICATIONS_REQUEST_SUCCESS,
    BROADCAST_NOTIFICATIONS_REQUEST_FAILURE,
    BROADCAST_NOTIFICATION_RESET,
    BROADCAST_NOTIFICATION_SET,
    BROADCAST_NOTIFICATION_REQUEST,
    BROADCAST_NOTIFICATION_REQUEST_SUCCESS,
    BROADCAST_NOTIFICATION_REQUEST_FAILURE,
    BROADCAST_NOTIFICATION_CREATE_REQUEST,
    BROADCAST_NOTIFICATION_CREATE_REQUEST_SUCCESS,
    BROADCAST_NOTIFICATION_CREATE_REQUEST_FAILURE,
    BROADCAST_NOTIFICATION_UPSERT_REQUEST,
    BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS,
    BROADCAST_NOTIFICATION_UPSERT_REQUEST_FAILURE
} from "../actions/broadcastNotification";

const initialState = {
    broadcastNotifications: {
        isLoading: false,
        filter: {},
        broadcastNotifications: [],
        paging: { pageSize: 0, page: 1 }
    },
    broadcastNotification: {
        broadcastNotificationId: -1,
        isLoading: false,
        isSaving: false,
        broadcastNotificationTypes: [],
        venueUsers: [],
        error: "",
        broadcastNotification: { broadcastNotificationId: 0 }
    }
};

function loadingBroadcastNotifications(state, isLoading) {
    let newState = update(state, {
        broadcastNotifications: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setBroadcastNotifications(state, broadcastNotifications, filter, paging) {
    let newState = update(state, {
        broadcastNotifications: {
            broadcastNotifications: { $set: broadcastNotifications },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingBroadcastNotification(state, isLoading) {
    let newState = update(state, {
        broadcastNotification: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setBroadcastNotification(state, broadcastNotification) {
    let newState = update(state, {
        broadcastNotification: {
            broadcastNotification: { $set: broadcastNotification }
        }
    });
    return newState;
}

function savingBroadcastNotification(state, isSaving) {
    let newState = update(state, {
        broadcastNotification: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setBroadcastNotificationId(state, broadcastNotificationId) {
    let newState = update(state, {
        broadcastNotification: {
            broadcastNotificationId: { $set: broadcastNotificationId }
        }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        broadcastNotification: {
            error: { $set: error }
        }
    });
    return newState;
}

export default function broadcastNotification(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case BROADCAST_NOTIFICATIONS_REFRESH:
            return loadingBroadcastNotifications(setBroadcastNotificationId(state, -1), true);
        case BROADCAST_NOTIFICATIONS_REQUEST:
            return setBroadcastNotifications(loadingBroadcastNotifications(setBroadcastNotificationId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case BROADCAST_NOTIFICATIONS_REQUEST_SUCCESS:
            return setBroadcastNotifications(loadingBroadcastNotifications(state, false), action.broadcastNotifications, action.filter, action.paging);
        case BROADCAST_NOTIFICATIONS_REQUEST_FAILURE:
            return loadingBroadcastNotifications(state, false);
        case BROADCAST_NOTIFICATION_RESET:
            return setBroadcastNotificationId(setError(state,""), -1);
        case BROADCAST_NOTIFICATION_REQUEST:
            return setBroadcastNotification(loadingBroadcastNotification(setBroadcastNotificationId(state, action.broadcastNotificationId), true), {});
        case BROADCAST_NOTIFICATION_REQUEST_SUCCESS:
            return setBroadcastNotification(loadingBroadcastNotification(state, false), action.broadcastNotification);
        case BROADCAST_NOTIFICATION_REQUEST_FAILURE:
            return loadingBroadcastNotification(setBroadcastNotificationId(state, -1), false);
        case BROADCAST_NOTIFICATION_CREATE_REQUEST:
            return setBroadcastNotification(loadingBroadcastNotification(setBroadcastNotificationId(setError(state,""), 0), true), {});
        case BROADCAST_NOTIFICATION_CREATE_REQUEST_SUCCESS:
            return setBroadcastNotification(loadingBroadcastNotification(state, false), action.broadcastNotification);
        case BROADCAST_NOTIFICATION_CREATE_REQUEST_FAILURE:
            return loadingBroadcastNotification(setBroadcastNotificationId(setError(state,action.error), -1), false);
        case BROADCAST_NOTIFICATION_UPSERT_REQUEST:
            return savingBroadcastNotification(setError(state,""), true);
        case BROADCAST_NOTIFICATION_UPSERT_REQUEST_SUCCESS:
            return setBroadcastNotification(savingBroadcastNotification(setBroadcastNotificationId(state, -1), false), action.broadcastNotification);
        case BROADCAST_NOTIFICATION_UPSERT_REQUEST_FAILURE:
            return savingBroadcastNotification(setError(state,action.error), false);
        case BROADCAST_NOTIFICATION_SET:
            return setBroadcastNotification(state, action.broadcastNotification);

    }

    return state;
}