import { makeActionCreator } from './helpers';

export const
    ROSTER_REQUEST = "ROSTER_REQUEST",
    ROSTER_REQUEST_SUCCESS = "ROSTER_REQUEST_SUCCESS",
    ROSTER_REQUEST_FAILURE = "ROSTER_REQUEST_FAILURE",
    ROSTER_SET_FILTER = "ROSTER_SET_FILTER",
    ROSTER_NEW_LEAVE_REQUEST = "ROSTER_NEW_LEAVE_REQUEST",
    ROSTER_NEW_LEAVE_REQUEST_SUCCESS = "ROSTER_NEW_LEAVE_REQUEST_SUCCESS",
    ROSTER_NEW_LEAVE_REQUEST_FAILURE = "ROSTER_NEW_LEAVE_REQUEST_FAILURE",
    ROSTER_SET_LEAVE_REQUEST = "ROSTER_SET_LEAVE_REQUEST",
    ROSTER_SAVE_LEAVE_REQUEST = "ROSTER_SAVE_LEAVE_REQUEST",
    ROSTER_SAVE_LEAVE_REQUEST_SUCCESS = "ROSTER_SAVE_LEAVE_REQUEST_SUCCESS",
    ROSTER_SAVE_LEAVE_REQUEST_FAILURE = "ROSTER_SAVE_LEAVE_REQUEST_FAILURE";

export const rosterRequest = makeActionCreator(ROSTER_REQUEST, 'filter');
export const rosterRequestSuccess = makeActionCreator(ROSTER_REQUEST_SUCCESS, 'filter', 'rosters');
export const rosterRequestFailure = makeActionCreator(ROSTER_REQUEST_FAILURE, 'error');
export const rosterFilterSet = makeActionCreator(ROSTER_SET_FILTER, 'filter');
export const rosterNewLeaveRequest = makeActionCreator(ROSTER_NEW_LEAVE_REQUEST);
export const rosterNewLeaveRequestSuccess = makeActionCreator(ROSTER_NEW_LEAVE_REQUEST_SUCCESS, 'leaveRequest');
export const rosterNewLeaveRequestFailure = makeActionCreator(ROSTER_NEW_LEAVE_REQUEST_FAILURE, 'error');
export const rosterSetLeaveRequest = makeActionCreator(ROSTER_SET_LEAVE_REQUEST, 'leaveRequest');
export const rosterSaveLeaveRequest = makeActionCreator(ROSTER_SAVE_LEAVE_REQUEST, 'leaveRequest');
export const rosterSaveLeaveRequestSuccess = makeActionCreator(ROSTER_SAVE_LEAVE_REQUEST_SUCCESS, 'leaveRequest');
export const rosterSaveLeaveRequestFailure = makeActionCreator(ROSTER_SAVE_LEAVE_REQUEST_FAILURE, 'error');