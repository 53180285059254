import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../../common/View';
import Tabs from '../../../common/Tabs';

export interface IFacialRecognitionTabsProps {
    venueId: number;
    selectedTab: string;
    history: any;
}

class FacialRecognitionTabs extends Component<IFacialRecognitionTabsProps, any> {
    constructor(props: IFacialRecognitionTabsProps) {
        super(props)

        this.state = {
        };
        this.tabs = this.tabs.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (event, value) => {
        let tabName = this.tabs()[value];
        switch (tabName) {
            case "Events":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/events');
                break;
            case "Watchlists":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/watchlists');
                break;
            case "Enrol":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/enrol');
                break;
            case "Cameras":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/cameras');
                break;
            case "Watchlist Removal Failures":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/expiryfailures');
                break;

            default:
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + '/facialrecognition/servers');
                break;
        }
    };

    tabs() {
        let tabs: string[] = [];
        // the tabs are dymanic based on permissions
        tabs.push('Servers');
        tabs.push('Cameras');
        tabs.push('Watchlists');
        tabs.push('Events');
        tabs.push('Enrol');
        tabs.push('Watchlist Removal Failures');
        return tabs;
    }

    selected(tabs: string[]) {
        let index = tabs.indexOf(this.props.selectedTab);
        if (index < 0) index = 0;
        return index;
    }

    render() {
        const tabs = this.tabs();

        return (<View style={{ marginTop: '10px' }}>
            <Tabs selected={this.selected(tabs)} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </View>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionTabs);