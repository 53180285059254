import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { RadioOption } from '../../../../common/dto/common';
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RadioControlGroup from '../../../controls/RadioControlGroup';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckListItemAttachments from './CheckListItemAttachments';
import CheckListItemAttachment from './CheckListItemAttachment';
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';

export interface ICheckListItemQuestionProps {
    checkListDisplayItem: CheckListDisplayItem;
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemQuestionState {
    radioOptions: RadioOption[];
    radioOption: string;
}

class CheckListItemQuestion extends Component<ICheckListItemQuestionProps, ICheckListItemQuestionState> {
    constructor(props: ICheckListItemQuestionProps) {
        super(props)
        this.state = {
            radioOptions: [],
            radioOption: ""
        }
        this.onUpdate = this.onUpdate.bind(this)
    }

    componentDidMount() {
        this.buildOptions();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.checkListDisplayItem.item) != JSON.stringify(preProps.checkListDisplayItem.item)) {
            this.buildOptions();
        } else if (JSON.stringify(this.props.checkListDisplayItem.template) != JSON.stringify(preProps.checkListDisplayItem.template)) {
            this.buildOptions();
        }
    }

    buildOptions() {
        let radioOptions: RadioOption[] = [];
        if (this.props.checkListDisplayItem.template.checklistTemplateItemOptions) {
            this.props.checkListDisplayItem.template.checklistTemplateItemOptions.forEach(option => {
                if ((this.props.checkListDisplayItem.item.value && option.value == this.props.checkListDisplayItem.item.value) || !option.obsolete) {
                    radioOptions.push({ id: option.value, name: option.name });
                }
            });
        }
        let radioOption = "";
        if (this.props.checkListDisplayItem.item.value) {
            radioOptions.forEach(item => {
                if (item.id == this.props.checkListDisplayItem.item.value) {
                    radioOption = item.id;
                }
            });
        }
        this.setState({ radioOptions, radioOption });
    }

    onUpdate(value) {
        let checklistItem = { ...this.props.checkListDisplayItem.item };
        checklistItem.value = value.value;
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
    }

    render() {
        const { theme } = this.props;

        let cardStyle;
        if (this.props.checkListDisplayItem.isRequired) {
            cardStyle = { borderColor: theme.custom.colors.error };
        }

        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplay>{this.props.checkListDisplayItem.item.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <RadioControlGroup
                                text={this.props.checkListDisplayItem.template.name}
                                name={this.props.checkListDisplayItem.template.checklistTemplateItemId.toString()}
                                selected={this.state.radioOption}
                                options={this.state.radioOptions}
                                readonly={this.props.isReadonly}
                                onChanged={this.onUpdate}
                            />
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemQuestion);