import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FunctionalArea from '../components/functionalAreas/FunctionalArea'
import * as functionalAreaActions from '../common/actions/functionalArea';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.functionalAreas.functionalArea.isLoading,
        isSaving: state.functionalAreas.functionalArea.isSaving,
        functionalArea: state.functionalAreas.functionalArea.functionalArea
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(functionalAreaActions.functionalAreaReset())
        },
        onUpdate: (functionalArea) => {
            dispatch(functionalAreaActions.functionalAreaUpdate(functionalArea))
        },
        onSave: () => {
            dispatch(functionalAreaActions.functionalAreaSaveRequest())
        }
    }
}

const FunctionalAreaContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionalArea))

export default FunctionalAreaContainer