import React from 'react';

const NavigatePreviousIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M40,0C17.9,0,0,17.9,0,40s17.9,40,40,40c22.1,0,40-17.9,40-40S62.1,0,40,0z M40,74.3C21.1,74.3,5.7,58.9,5.7,40C5.7,21.1,21.1,5.7,40,5.7S74.3,21.1,74.3,40C74.3,58.9,58.9,74.3,40,74.3z M45.8,22.2c-1.1-1.1-2.9-1.1-4,0L26,38c-1.1,1.1-1.1,2.9,0,4l15.8,15.8c0.6,0.6,1.3,0.8,2,0.8c0.7,0,1.5-0.3,2-0.8c1.1-1.1,1.1-2.9,0-4L32.1,40l13.7-13.7C46.9,25.1,46.9,23.3,45.8,22.2z" />
        </g>
    </svg>
);

export default NavigatePreviousIcon;

