import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import Schedules from '../components/schedule/Schedules'
import * as scheduleActions from '../common/actions/schedule';

const mapStateToProps = (state, props) => {
    return {
        schedules:  state.schedules.schedules.schedules,
        isLoading: state.schedules.schedules.isLoading,
        isProcessing: state.schedules.schedules.isProcessing,
        processingId: state.schedules.schedules.processingId,
        showEvents: permissionsLogic.hasPermissionForState(state, "ViewEvent")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(scheduleActions.schedulesRequest())
        },
        onProcess: (scheduleId) => {
            dispatch(scheduleActions.scheduleProcessRequest(scheduleId))
        }
    }
}

const VenueSchedulesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedules))

export default VenueSchedulesContainer