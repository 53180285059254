import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { venueContactLogic } from '../../../common/logic/venueContactLogic';

export interface IVenueContactRowProps {
    venueContact: AusComplyDtos.VenueContact;
    canEdit: boolean;
    onCommand?: Function;
}

export default class VenueContactRow extends React.Component<IVenueContactRowProps, any> {
    constructor(props: IVenueContactRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueContact);
        }
    }

    render() {
        if (!this.props.venueContact) {
            return (<></>);
        }

        var commands = venueContactLogic.getCommands(this.props.venueContact, this.props.canEdit);
        var item = this.props.venueContact;

        return (
            <TableRow isSuccess={item.primaryContact} isWarning={item.secondaryContact} isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.venueContactType && item.venueContactType.name}
                    </p>
                </td>
                <td>
                    <p>
                        {item.firstname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.lastname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td>
                    <p>
                        {item.email}
                    </p>
                </td>
            </TableRow>
        );
    }
}