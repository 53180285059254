import { connect } from 'react-redux'
import ProfileDelete from '../components/profile/ProfileDelete'
import * as profileActions from '../common/actions/profile';

const mapStateToProps = state => {
    return {
        profile: state.profile.profile,
        isLoading: state.profile.isProfileLoading || state.user.isLoading,
        isSaving: state.profile.isSaving,
        error: state.profile.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(profileActions.profileRequest())
        },
        onRemove: (reconfirmedEmail) => {
            dispatch(profileActions.profileDeleteRequest(reconfirmedEmail))
        }
    }
}

const ProfileDeleteContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDelete)

export default ProfileDeleteContainer