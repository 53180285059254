import { connect } from 'react-redux';
import {
    dashboardFilterRequest,
    dashboardRequest,
    dashboardSetFilter,
    dashboardExportRequest

} from '../common/actions/dashboard';
import Dashboard from '../components/dashboard/Dashboard';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        isLoading: state.dashboard.isLoading,
        filter: state.dashboard.filter,
        data: state.dashboard.data,
        isLoadingIncidentType: state.dashboard.dataIncidentType.isLoading,
        incidentTypes: state.dashboard.dataIncidentType.data,
        incidentTypesColors: state.dashboard.dataIncidentType.colors,
        isLoadingIncidentCategories: state.dashboard.dataIncidentCategories.isLoading,
        incidentCategories: state.dashboard.dataIncidentCategories.data,
        incidentCategoriesGridLinesCount: state.dashboard.dataIncidentCategories.gridLinesCount,
        isLoadingIncidentLocations: state.dashboard.dataIncidentLocations.isLoading,
        incidentLocations: state.dashboard.dataIncidentLocations.data,
        incidentLocationsGridLinesCount: state.dashboard.dataIncidentLocations.gridLinesCount,
        isLoadingIncidentRefuseEntryReasons: state.dashboard.dataIncidentRefuseEntryReasons.isLoading,
        incidentRefuseEntryReasons: state.dashboard.dataIncidentRefuseEntryReasons.data,
        incidentRefuseEntryReasonsGridLinesCount: state.dashboard.dataIncidentRefuseEntryReasons.gridLinesCount,
        isLoadingIncidentAskedToLeave: state.dashboard.dataIncidentAskedToLeave.isLoading,
        incidentAskedToLeave: state.dashboard.dataIncidentAskedToLeave.data,
        incidentAskedToLeaveGridLinesCount: state.dashboard.dataIncidentAskedToLeave.gridLinesCount,
        isLoadingIncidentWatchlists: state.dashboard.dataIncidentWatchlists.isLoading,
        incidentWatchlists: state.dashboard.dataIncidentWatchlists.data,
        incidentWatchlistsGridLinesCount: state.dashboard.dataIncidentWatchlists.gridLinesCount,
        isLoadingPatronDemographics: state.dashboard.dataPatronDemographics.isLoading,
        patronDemographics: state.dashboard.dataPatronDemographics.data,
        isLoadingPatronGender: state.dashboard.dataPatronGender.isLoading,
        patronGender: state.dashboard.dataPatronGender.data,
        isLoadingPatronRace: state.dashboard.dataPatronRace.isLoading,
        patronRace: state.dashboard.dataPatronRace.data,
        isLoadingStaffEntered: state.dashboard.dataStaffEntered.isLoading,
        staffEntered: state.dashboard.dataStaffEntered.data,
        staffEnteredGridLinesCount: state.dashboard.dataStaffEntered.gridLinesCount,
        isLoadingManagersApproved: state.dashboard.dataManagersApproved.isLoading,
        managersApproved: state.dashboard.dataManagersApproved.data,
        managersApprovedGridLinesCount: state.dashboard.dataManagersApproved.gridLinesCount,
        canViewChecklists: permissionsLogic.hasPermissionForState(state, "ChecklistSearch"),
        isLoadingChecklistTemplate: state.dashboard.dataChecklist.isLoading,
        checklistTemplates: state.dashboard.dataChecklist.data,
        checklistTemplatesGridLinesCount: state.dashboard.dataChecklist.gridLinesCount,
        canViewGamblingIncidents: permissionsLogic.hasAnyPermissionForState(state, ["CashTraxIncidentView","PlayTraxIncidentView"]),
        isLoadingRGOIncidentType: state.dashboard.dataRGOIncidentType.isLoading,
        rgoIncidentTypes: state.dashboard.dataRGOIncidentType.data,
        rgoIncidentTypesColors: state.dashboard.dataRGOIncidentType.colors,
        isLoadingRGOIncidentCategories: state.dashboard.dataRGOIncidentCategories.isLoading,
        rgoIncidentCategories: state.dashboard.dataRGOIncidentCategories.data,
        rgoIncidentCategoriesGridLinesCount: state.dashboard.dataRGOIncidentCategories.gridLinesCount,
        isLoadingRGOIncidentLocations: state.dashboard.dataRGOIncidentLocations.isLoading,
        rgoIncidentLocations: state.dashboard.dataRGOIncidentLocations.data,
        rgoIncidentLocationsGridLinesCount: state.dashboard.dataRGOIncidentLocations.gridLinesCount,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(dashboardSetFilter(filter))
        },
        onResetFilter: (forVenueId, forSecurityFirmId) => {
            dispatch(dashboardFilterRequest(forVenueId, forSecurityFirmId))
        },
        onLoad: () => {
            dispatch(dashboardRequest())
        },
        onExport: () => {
            dispatch(dashboardExportRequest())
        }
    }
}

const DashboardContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)

export default DashboardContainer