import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardContainer from './CardContainer';
import { Palette } from '../../common/constants/palette';

export interface IPageMessageProps {
    theme: any;
    containerStyle?: any;
    style?: any;
    text?: string;
}
class PageMessage extends React.PureComponent<IPageMessageProps, any> {
    render() {
        const { theme } = this.props;
        return (
            <CardContainer style={{ borderColor: Palette.ControlBorder, ...this.props.containerStyle }} >
                {this.props.text && (
                    <p style={{ textAlign: 'center', margin: '5px', color: theme.palette.text.secondary, ...this.props.style }}>{this.props.text}</p>
                )}
                {this.props.children}
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PageMessage);