import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const signOnRegisterTypeServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSignOnRegisterTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forGroupId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSignOnRegisterTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forGroupId = forGroupId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, signOnRegisterType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSignOnRegisterType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.signOnRegisterType = signOnRegisterType;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, signOnRegisterTypeId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSignOnRegisterType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.signOnRegisterTypeId = signOnRegisterTypeId;
    const request = client.get(query);
    return from(request);
  }
};
