import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionNotificationServiceApi } from '../services/facialRecognitionNotification';
import { facialRecognitionWatchlistTypeApi } from '../services/facialRecognitionWatchlistType';

import {

    FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST,
    facialRecognitionNotificationFilterRequestSuccess,
    facialRecognitionNotificationFilterRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS,
    facialRecognitionNotificationRequest,
    facialRecognitionNotificationRequestSuccess,
    facialRecognitionNotificationRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_REQUEST,
    FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER,
    FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING,
    FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST,
    facialRecognitionNotificationGetRequestSuccess,
    facialRecognitionNotificationGetRequestFailure,

    FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST,
    facialRecognitionNotificationEventRequestSuccess,
    facialRecognitionNotificationEventRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST,
    facialRecognitionNotificationEventSaveRequestSuccess,
    facialRecognitionNotificationEventSaveRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM,
    facialRecognitionNotificationEventsSaveRequestSuccess,
    facialRecognitionNotificationEventsSaveRequestFailure,

    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST,
    facialRecognitionNotificationCamerasRequestSuccess,
    facialRecognitionNotificationCamerasRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST,
    facialRecognitionNotificationCamerasSaveRequestSuccess,
    facialRecognitionNotificationCamerasSaveRequestFailure,

    FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST,
    facialRecognitionNotificationProcessRequestSuccess,
    facialRecognitionNotificationProcessRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_SUCCESS,

    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST,
    facialRecognitionNotificationCameraSaveRequestSuccess,
    facialRecognitionNotificationCameraSaveRequestFailure,

    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST,
    facialRecognitionNotificationCameraSyncRequestSuccess,
    facialRecognitionNotificationCameraSyncRequestFailure,

    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST,
    facialRecognitionNotificationCameraStatusSaveRequestFailure,
    facialRecognitionNotificationCameraStatusSaveRequestSuccess,

    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST,
    facialRecognitionNotificationWatchlistTypesRequest,
    facialRecognitionNotificationWatchlistTypesRequestSuccess,
    facialRecognitionNotificationWatchlistTypesRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST,
    facialRecognitionNotificationWatchlistTypeRequestSuccess,
    facialRecognitionNotificationWatchlistTypeRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST,
    facialRecognitionNotificationWatchlistTypeCreateRequestSuccess,
    facialRecognitionNotificationWatchlistTypeCreateRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST,
    facialRecognitionNotificationWatchlistTypeUpsertRequestSuccess,
    facialRecognitionNotificationWatchlistTypeUpsertRequestFailure,
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS,
    facialRecognitionNotificationWatchlistTypeReset,
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH,
    facialRecognitionNotificationWatchlistTypesRefresh,

} from '../actions/facialRecognitionNotification';

import * as facialRecognitionNotificationActions from '../actions/facialRecognitionNotification';

import {
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST,
    adminFacialRecognitionNotificationFilterRequestSuccess,
    adminFacialRecognitionNotificationFilterRequestFailure,
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS,
    adminFacialRecognitionNotificationRequest,
    adminFacialRecognitionNotificationRequestSuccess,
    adminFacialRecognitionNotificationRequestFailure,
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST,
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER,
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING,
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST,
    adminFacialRecognitionNotificationGetRequestSuccess,
    adminFacialRecognitionNotificationGetRequestFailure,

} from '../actions/admin';
import {
    notifyError
} from './common';
import { notificationSuccessShow } from "../actions/notification";

const facialRecognitionNotificationFilterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.defaultFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
            )
                .pipe(
                    map(response => adminFacialRecognitionNotificationFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationFilterRequestEpic.defaultFilter", adminFacialRecognitionNotificationFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationFilterRequestEpic", adminFacialRecognitionNotificationFilterRequestFailure))
    );

const facialRecognitionNotificationSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS, ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER, ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => adminFacialRecognitionNotificationRequest())
    );

const facialRecognitionNotificationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.facialRecognitionNotifications.paging.page,
                state.admin.facialRecognitionNotifications.paging.pageSize,
                state.admin.facialRecognitionNotifications.filter
            )
                .pipe(
                    map(response => adminFacialRecognitionNotificationRequestSuccess(response.filter, response.paging, response.items)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationRequestEpic.find", adminFacialRecognitionNotificationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationRequestEpic", adminFacialRecognitionNotificationRequestFailure))
    );

const facialRecognitionNotificationGetRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionNotificationId']
            )
                .pipe(
                    map(response => adminFacialRecognitionNotificationGetRequestSuccess(response.facialRecognitionNotification)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationGetRequestEpic.get", adminFacialRecognitionNotificationGetRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationGetRequestEpic", adminFacialRecognitionNotificationRequestFailure))
    );

const venueFacialRecognitionNotificationFilterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.defaultFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
            )
                .pipe(
                    map(response => facialRecognitionNotificationFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationFilterRequestEpic.defaultFilter", facialRecognitionNotificationFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationFilterRequestEpic", facialRecognitionNotificationFilterRequestFailure))
    );

const venueFacialRecognitionNotificationSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS, FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER, FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => facialRecognitionNotificationRequest())
    );

const venueFacialRecognitionNotificationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.paging.page,
                state.facialRecognitionNotification.paging.pageSize,
                state.facialRecognitionNotification.filter
            )
                .pipe(
                    map(response => facialRecognitionNotificationRequestSuccess(response.filter, response.paging, response.items)),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationRequestEpic.find", facialRecognitionNotificationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationRequestEpic", facialRecognitionNotificationRequestFailure))
    );

const venueFacialRecognitionNotificationGetRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionNotificationId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationGetRequestSuccess(response.facialRecognitionNotification)),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationGetRequestEpic.get", facialRecognitionNotificationGetRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationGetRequestEpic", facialRecognitionNotificationGetRequestFailure))
    );



const venueFacialRecognitionNotificationEventRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.getEvents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forGroupId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationEventRequestSuccess(
                        response.facialRecognitionEvents,
                        response.facialRecognitionCameras,
                        response.facialRecognitionWatchlists,
                        response.incidentTypes,
                        response.incidentTypeCategoryTypes,
                        response.incidentFlagTypes,
                        response.userRoleTypes,
                        response.defaultNotificationTrigger)),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventRequestEpic.getEvents", facialRecognitionNotificationEventRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventRequestEpic", facialRecognitionNotificationEventRequestFailure))
    );

const venueFacialRecognitionNotificationEventSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.saveEvents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvent
            )
                .pipe(
                    map(response => facialRecognitionNotificationEventSaveRequestSuccess(response.facialRecognitionEvents)),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventSaveRequestEpic.saveEvents", facialRecognitionNotificationEventSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventSaveRequestEpic", facialRecognitionNotificationEventSaveRequestFailure))
    );

const venueFacialRecognitionNotificationEventsSaveRequestEpic = (action$, state$) => 
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM),
        withLatestFrom(state$),
        mergeMap(([action, state]) => 
            facialRecognitionNotificationServiceApi.updateEvents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionEvents.facialRecognitionEvents
            )
            .pipe(
                map(response => facialRecognitionNotificationEventsSaveRequestSuccess(response.facialRecognitionEvents)),
                catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventsSaveRequestEpic.saveEvents", facialRecognitionNotificationEventsSaveRequestFailure))
            )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationEventsSaveRequestEpic", facialRecognitionNotificationEventsSaveRequestFailure))
    );


const venueFacialRecognitionCamerasRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.getCameras(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationCamerasRequestSuccess(
                        response.facialRecognitionCameras,
                        response.venueLocations,
                        response.facialRecognitionWatchlists,
                        response.facialRecognitionServers)),
                    catchError(error => notifyError(error, "venueFacialRecognitionCamerasRequestEpic.getCameras", facialRecognitionNotificationCamerasRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionCamerasRequestEpic", facialRecognitionNotificationCamerasRequestFailure))
    );

const venueFacialRecognitionCamerasSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.saveCameras(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionCameras
            )
                .pipe(
                    map(response => facialRecognitionNotificationCamerasSaveRequestSuccess(response.facialRecognitionCameras)),
                    catchError(error => notifyError(error, "venueFacialRecognitionCamerasSaveRequestEpic.saveCameras", facialRecognitionNotificationCamerasSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionCamerasSaveRequestEpic", facialRecognitionNotificationCamerasSaveRequestFailure))
    );

const venueFacialRecognitionNotificationProcessRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.process(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionNotificationId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationProcessRequestSuccess()),
                    catchError(error => notifyError(error, "venueFacialRecognitionNotificationProcessRequestEpic.process", facialRecognitionNotificationProcessRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionNotificationProcessRequestEpic", facialRecognitionNotificationProcessRequestFailure))
    );

const venueFacialRecognitionNotificationProcessRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_SUCCESS),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => facialRecognitionNotificationRequest())
    );

const venueFacialRecognitionCameraSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.saveCamera(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionCameras.facialRecognitionCamera
            )
                .pipe(
                    map(response => facialRecognitionNotificationCameraSaveRequestSuccess(response.facialRecognitionCameras)),
                    catchError(error => notifyError(error, "venueFacialRecognitionCameraSaveRequestEpic.saveCamera", facialRecognitionNotificationCameraSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionCameraSaveRequestEpic", facialRecognitionNotificationCameraSaveRequestFailure))
    );

const venueFacialRecognitionCameraStatusSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.saveCameraStatus(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionCameraId'],
                action['cameraStatus'],
                action['forVenueId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationCameraStatusSaveRequestSuccess(response.facialRecognitionCameras)),
                    catchError(error => notifyError(error, "venueFacialRecognitionCameraStatusSaveRequestEpic.saveCameraStatus", facialRecognitionNotificationCameraStatusSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionCameraStatusSaveRequestEpic", facialRecognitionNotificationCameraStatusSaveRequestFailure))
    );

const venueFacialRecognitionCameraSyncRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.syncCameras(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId']
            )
                .pipe(
                    map(response => facialRecognitionNotificationCameraSyncRequestSuccess(response.facialRecognitionCameras)),
                    catchError(error => notifyError(error, "venueFacialRecognitionCameraSyncRequestEpic.syncCameras", facialRecognitionNotificationCameraSyncRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueFacialRecognitionCameraSyncRequestEpic", facialRecognitionNotificationCameraSyncRequestFailure))
    );


const facialRecognitionNotificationWatchlistTypesRequestAndRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST, FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionWatchlistTypes.paging.page,
                state.facialRecognitionNotification.facialRecognitionWatchlistTypes.paging.pageSize,
                state.facialRecognitionNotification.facialRecognitionWatchlistTypes.filter)
                .pipe(
                    map(response => facialRecognitionNotificationWatchlistTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypesRequestAndRefreshEpic.find", facialRecognitionNotificationWatchlistTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypesRequestAndRefreshEpic", facialRecognitionNotificationWatchlistTypesRequestFailure))
    );

const facialRecognitionNotificationWatchlistTypeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistTypeApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionNotificationWatchlistTypeId'])
                .pipe(
                    map(response => facialRecognitionNotificationWatchlistTypeRequestSuccess(response.facialRecognitionWatchlistType)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeRequestEpic.get", facialRecognitionNotificationWatchlistTypeRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeRequestEpic", facialRecognitionNotificationWatchlistTypeRequestFailure))
    );

const facialRecognitionNotificationWatchlistTypeCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistTypeApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => facialRecognitionNotificationWatchlistTypeCreateRequestSuccess(response.facialRecognitionWatchlistType)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeCreateEpic.create", facialRecognitionNotificationWatchlistTypeCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeCreateEpic", facialRecognitionNotificationWatchlistTypeCreateRequestFailure))
    );

const facialRecognitionNotificationWatchlistTypeUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistTypeApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionNotification.facialRecognitionWatchlistType.facialRecognitionWatchlistType)
                .pipe(
                    map(response => facialRecognitionNotificationWatchlistTypeUpsertRequestSuccess(response.facialRecognitionWatchlistType)),
                    catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeUpsertEpic.upsert", facialRecognitionNotificationWatchlistTypeUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionNotificationWatchlistTypeUpsertEpic", facialRecognitionNotificationWatchlistTypeUpsertRequestFailure))
    );

const facialRecognitionNotificationWatchlistTypeUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionNotificationWatchlistTypeReset())
    );

const facialRecognitionNotificationWatchlistTypeUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionNotificationWatchlistTypesRefresh())
    );

const facialRecognitionCameraNewImportEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        facialRecognitionNotificationServiceApi.newCameraImport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionNotificationActions.facialRecognitionCameraNewImportRequestSuccess(response.facialRecognitionCameraImports)),
                    catchError(error => notifyError(error, "facialRecognitionCameraNewImportEpic.newCameraImport", facialRecognitionNotificationActions.facialRecognitionCameraNewImportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionCameraNewImportEpic", facialRecognitionNotificationActions.facialRecognitionCameraNewImportRequestFailure))
    );

const facialRecognitionCameraImportEpic = (action$, state$) =>
        action$.pipe(
            ofType(facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
            facialRecognitionNotificationServiceApi.cameraImport(
                    state.user.details.userSession.user.venueId,
                    state.user.details.userSession.user.securityFirmId,
                    state.facialRecognitionNotification.facialRecognitionCameraImports.facialRecognitionCameraImports)
                    .pipe(
                        map(response => facialRecognitionNotificationActions.facialRecognitionCameraImportRequestSuccess(response.facialRecognitionCameraImports)),
                        catchError(error => notifyError(error, "facialRecognitionCameraImportEpic.cameraImport", facialRecognitionNotificationActions.facialRecognitionCameraImportRequestFailure))
                    )
            ),
            catchError(error => notifyError(error, "facialRecognitionCameraImportEpic", facialRecognitionNotificationActions.facialRecognitionCameraImportRequestFailure))
        );

export const facialRecognitionNotificationEpics = combineEpics(
    facialRecognitionNotificationFilterRequestEpic,
    facialRecognitionNotificationSuccessEpic,
    facialRecognitionNotificationRequestEpic,
    facialRecognitionNotificationGetRequestEpic,
    venueFacialRecognitionNotificationFilterRequestEpic,
    venueFacialRecognitionNotificationSuccessEpic,
    venueFacialRecognitionNotificationRequestEpic,
    venueFacialRecognitionNotificationGetRequestEpic,
    venueFacialRecognitionNotificationEventRequestEpic,
    venueFacialRecognitionNotificationEventSaveRequestEpic,
    venueFacialRecognitionNotificationEventsSaveRequestEpic,
    venueFacialRecognitionCamerasRequestEpic,
    venueFacialRecognitionCamerasSaveRequestEpic,
    venueFacialRecognitionNotificationProcessRequestEpic,
    venueFacialRecognitionNotificationProcessRequestSuccessEpic,
    venueFacialRecognitionCameraSaveRequestEpic,
    venueFacialRecognitionCameraStatusSaveRequestEpic,
    facialRecognitionNotificationWatchlistTypesRequestAndRefreshEpic,
    facialRecognitionNotificationWatchlistTypeRequestEpic,
    facialRecognitionNotificationWatchlistTypeCreateEpic,
    facialRecognitionNotificationWatchlistTypeUpsertEpic,
    facialRecognitionNotificationWatchlistTypeUpsertSuccessEpic,
    facialRecognitionNotificationWatchlistTypeUpsertSuccessRefreshEpic,
    venueFacialRecognitionCameraSyncRequestEpic,
    facialRecognitionCameraNewImportEpic,
    facialRecognitionCameraImportEpic
);