export const
    REGISTER_SECURITY_FIRM_RESET = "REGISTER_SECURITYFIRM_RESET",
    REGISTER_SECURITY_FIRM_NEW_REQUEST = "REGISTER_SECURITYFIRM_NEW_REQUEST",
    REGISTER_SECURITY_FIRM_NEW_REQUEST_SUCCESS = "REGISTER_SECURITYFIRM_NEW_REQUEST_SUCCESS",
    REGISTER_SECURITY_FIRM_NEW_REQUEST_FAILURE = "REGISTER_SECURITYFIRM_NEW_REQUEST_FAILURE",
    REGISTER_SECURITY_FIRM_SET_STEP = "REGISTER_SECURITYFIRM_SET_STEP",
    REGISTER_SECURITY_FIRM_UPDATE = "REGISTER_SECURITYFIRM_UPDATE",
    REGISTER_SECURITY_FIRM_UPDATE_OTHER_DOCUMENTS = "REGISTER_SECURITYFIRM_UPDATE_OTHER_DOCUMENTS",
    REGISTER_SECURITY_FIRM_UPDATE_COMPLIANCE_DOCUMENTS = "REGISTER_SECURITYFIRM_UPDATE_COMPLIANCE_DOCUMENTS",
    REGISTER_SECURITY_FIRM_SAVE_REQUEST = "REGISTER_SECURITYFIRM_SAVE_REQUEST",
    REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS = "REGISTER_SECURITYFIRM_SAVE_REQUEST_SUCCESS",
    REGISTER_SECURITY_FIRM_SAVE_REQUEST_FAILURE = "REGISTER_SECURITYFIRM_SAVE_REQUEST_FAILURE";

export function registerSecurityFirmReset() {
    return {
        type: REGISTER_SECURITY_FIRM_RESET
    };
}

export function registerSecurityFirmNew() {
    return {
        type: REGISTER_SECURITY_FIRM_NEW_REQUEST
    };
}

export function registerSecurityFirmNewSuccess(
    securityFirm, 
    managerContact, 
    primaryContact, 
    states, 
    securityLicenceClasses,
    otherDocuments, 
    otherDocumentTypes, 
    complianceDocuments, 
    complianceDocumentTypes) {
    return {
        type: REGISTER_SECURITY_FIRM_NEW_REQUEST_SUCCESS,
        securityFirm,
        managerContact,
        primaryContact,
        states,
        securityLicenceClasses,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes
    };
}

export function registerSecurityFirmNewFailure(error) {
    return {
        type: REGISTER_SECURITY_FIRM_NEW_REQUEST_FAILURE,
        error
    };
}

export function registerSecurityFirmSetStep(step) {
    return {
        type: REGISTER_SECURITY_FIRM_SET_STEP,
        step
    };
}

export function registerSecurityFirmUpdate(securityFirm, managerContact, primaryContact) {
    return {
        type: REGISTER_SECURITY_FIRM_UPDATE,
        securityFirm,
        managerContact,
        primaryContact
    };
}

export function registerSecurityFirmUpdateOtherDocuments(otherDocuments) {
    return {
        type: REGISTER_SECURITY_FIRM_UPDATE_OTHER_DOCUMENTS,
        otherDocuments
    }
}

export function registerSecurityFirmUpdateComplianceDocuments(complianceDocuments) {
    return {
        type: REGISTER_SECURITY_FIRM_UPDATE_COMPLIANCE_DOCUMENTS,
        complianceDocuments
    }

}

export function registerSecurityFirmSaveRequest() {
    return {
        type: REGISTER_SECURITY_FIRM_SAVE_REQUEST
    };
}

export function registerSecurityFirmSaveRequestSuccess(securityFirm) {
    return {
        type: REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS,
        securityFirm
    };
}

export function registerSecurityFirmSaveRequestFailure(error) {
    return {
        type: REGISTER_SECURITY_FIRM_SAVE_REQUEST_FAILURE,
        error
    };
}