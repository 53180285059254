
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userActionAuditServiceApi } from '../services/userActionAuditService';
import * as AusComplyDtos from "../dto/AusComply.dtos";

import * as userActionAuditActions from '../actions/userActionAudit';
import * as venueSecurityFirmActions from '../actions/venueSecurityFirm';

import {
    notifyError, notifyErrorMessage
} from './common';

const userActionAuditInsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(userActionAuditActions.USER_ACTION_AUDIT_INSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userActionAuditServiceApi.postActionAudit(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["actionType"],
                action["locationFound"],
                action["latitude"],
                action["longitude"])
                .pipe(
                    map(response => userActionAuditActions.userActionAuditInsertRequestSuccess()),
                    catchError(error => notifyError(error, "userActionAuditInsertRequestEpic.postActionAudit", userActionAuditActions.userActionAuditInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userActionAuditInsertRequestEpic", userActionAuditActions.userActionAuditInsertRequestFailure))
    );

const userActionAuditVenueSecurityFirmSelectedRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueSecurityFirmActions.VENUE_SECURITYFIRM_SELECT),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userActionAuditServiceApi.postActionAudit(
                action["venueId"],
                action["securityFirmId"],
                action["venueEventId"],
                AusComplyDtos.ngtUserActionType.VenueSecurityFirmSelection,
                undefined,
                undefined,
                undefined)
                .pipe(
                    map(response => userActionAuditActions.userActionAuditInsertRequestSuccess()),
                    catchError(error => notifyError(error, "userActionAuditInsertRequestEpic.postActionAudit", userActionAuditActions.userActionAuditInsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userActionAuditInsertRequestEpic", userActionAuditActions.userActionAuditInsertRequestFailure))
    );

export const userActionAuditInsertEpics = combineEpics(
    userActionAuditInsertRequestEpic,
    userActionAuditVenueSecurityFirmSelectedRequestEpic
);