import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import LocalDateTimeControlGroup from '../../controls/LocalDateTimeControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IReportingPeriodProps {
    venueReportingPeriod: AusComplyDtos.VenueReportingPeriod;
    onUpdate: Function;
}

interface IReportingPeriodState {
}

export default class ReportingPeriod extends Component<IReportingPeriodProps, IReportingPeriodState> {
    constructor(props: IReportingPeriodProps) {
        super(props)
        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSubValueChanged = this.onSubValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriod };
        venueReportingPeriod[fieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }

    onSubValueChanged(fieldName: string, subFieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriod };
        venueReportingPeriod[fieldName][subFieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }

    render() {

        if (!this.props.venueReportingPeriod) {
            return (<></>);
        }

        let readonlyStart: boolean = false;
        let readonlyEnd: boolean = false;
        let readonlySecurityCodeRequired: boolean = false;
        if (this.props.venueReportingPeriod.venueReportingPeriodId && this.props.venueReportingPeriod.venueReportingPeriodId > 0) {
            if (this.props.venueReportingPeriod.isOver) {
                readonlyStart = true;
                readonlyEnd = true;
                readonlySecurityCodeRequired = true;
            } else if (this.props.venueReportingPeriod.isCurrent) {
                readonlyStart = true;
            }
        }

        let startTimezone;
        if (this.props.venueReportingPeriod && this.props.venueReportingPeriod.timezones && this.props.venueReportingPeriod.timezones.length > 1) {
            startTimezone = (
                <SelectControlGroup
                    text="Start Timezone"
                    onChange={(value) => this.onSubValueChanged("startTime", "timezoneId", value)}
                    defaultValue={this.props.venueReportingPeriod.startTime.timezoneId}>
                    {this.props.venueReportingPeriod.timezones.map((item, index) => (
                        <option key={item.timezoneId} value={item.timezoneId}>{item.timezoneAbbreviation}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let endTimezone;
        if (this.props.venueReportingPeriod && this.props.venueReportingPeriod.timezones && this.props.venueReportingPeriod.timezones.length > 1) {
            endTimezone = (
                <SelectControlGroup
                    text="End Timezone"
                    onChange={(value) => this.onSubValueChanged("endTime", "timezoneId", value)}
                    defaultValue={this.props.venueReportingPeriod.endTime.timezoneId}>
                    {this.props.venueReportingPeriod.timezones.map((item, index) => (
                        <option key={item.timezoneId} value={item.timezoneId}>{item.timezoneAbbreviation}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let start = readonlyStart ?
            <TextControlGroup
                text={"Start"}
                readonly={true}
                defaultValue={this.props.venueReportingPeriod.startTime.displayLocal + " " + this.props.venueReportingPeriod.startTimeTimezone} /> :
            <>
                <LocalDateTimeControlGroup
                    text="Start"
                    date={this.props.venueReportingPeriod.startTime}
                    onChange={(value) => this.onValueChanged("startTime", value)} />
                {startTimezone}
            </>;

        let end = readonlyEnd ?
            <TextControlGroup
                text={"End"}
                readonly={true}
                defaultValue={this.props.venueReportingPeriod.endTime.displayLocal + " " + this.props.venueReportingPeriod.endTimeTimezone} /> :
            <>
                <LocalDateTimeControlGroup
                    text="End"
                    date={this.props.venueReportingPeriod.endTime}
                    onChange={(value) => this.onValueChanged("endTime", value)} />
                {endTimezone}
            </>;


        return (
            <>
                {start}
                {end}
                {this.props.venueReportingPeriod.securityCode && (
                    <TextControlGroup
                        text={"Security Code"}
                        readonly={true}
                        defaultValue={this.props.venueReportingPeriod.securityCode} />

                )}
                {!readonlySecurityCodeRequired && (
                    <CheckboxControlGroup
                        text={"Security Code Required"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.venueReportingPeriod.securityCodeNotRequired}
                        onChanged={(value) => this.onValueChanged("securityCodeNotRequired", value)} />
                )}
            </>
        );
    }
}