import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueReportingPeriodScheduleServiceApi } from '../services/venueReportingPeriodSchedule';

import {
    VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST,
    venueReportingPeriodSchedulesRequest,
    venueReportingPeriodSchedulesRequestSuccess,
    venueReportingPeriodSchedulesRequestFailure,
    VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST,
    venueReportingPeriodScheduleRequestSuccess,
    venueReportingPeriodScheduleRequestFailure,
    VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST,
    venueReportingPeriodScheduleCreateRequestSuccess,
    venueReportingPeriodScheduleCreateRequestFailure,
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST,
    venueReportingPeriodScheduleUpsertRequestSuccess,
    venueReportingPeriodScheduleUpsertRequestFailure,
    VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS,
    venueReportingPeriodScheduleReset
} from '../actions/venue';
import {
    notifyError
} from './common';

const venueReportingPeriodSchedulesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodScheduleServiceApi.list(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueReportingPeriodSchedulesRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "venueReportingPeriodSchedulesRequestEpic.list", venueReportingPeriodSchedulesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodSchedulesRequestEpic", venueReportingPeriodSchedulesRequestFailure))
    );

const venueReportingPeriodScheduleRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodScheduleServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueReportingPeriodScheduleId'])
                .pipe(
                    map(response => venueReportingPeriodScheduleRequestSuccess(response.venueReportingPeriodSchedule)),
                    catchError(error => notifyError(error, "venueReportingPeriodScheduleRequestEpic.get", venueReportingPeriodScheduleRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodScheduleRequestEpic", venueReportingPeriodScheduleRequestFailure))
    );

const venueReportingPeriodScheduleCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodScheduleServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => venueReportingPeriodScheduleCreateRequestSuccess(response.venueReportingPeriodSchedule)),
                    catchError(error => notifyError(error, "venueReportingPeriodScheduleCreateEpic.create", venueReportingPeriodScheduleCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodScheduleCreateEpic", venueReportingPeriodScheduleCreateRequestFailure))
    );

const venueReportingPeriodScheduleUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodScheduleServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.venueReportingPeriodSchedule.venueReportingPeriodSchedule)
                .pipe(
                    map(response => venueReportingPeriodScheduleUpsertRequestSuccess(response.venueReportingPeriodSchedule)),
                    catchError(error => notifyError(error, "venueReportingPeriodScheduleUpsertEpic.upsert", venueReportingPeriodScheduleUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueReportingPeriodScheduleUpsertEpic", venueReportingPeriodScheduleUpsertRequestFailure))
    );

const venueReportingPeriodScheduleUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueReportingPeriodScheduleReset())
    );

const venueReportingPeriodScheduleUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_REPORTING_PERIOD_SCHEDULE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueReportingPeriodSchedulesRequest(action['venueReportingPeriodSchedule']['venueId']))
    );

export const venueReportingPeriodScheduleEpics = combineEpics(
    venueReportingPeriodSchedulesRequestEpic,
    venueReportingPeriodScheduleRequestEpic,
    venueReportingPeriodScheduleCreateEpic,
    venueReportingPeriodScheduleUpsertEpic,
    venueReportingPeriodScheduleUpsertSuccessEpic,
    venueReportingPeriodScheduleUpsertSuccessRefreshEpic
);