
import React from 'react';

const HomeIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M46.58,26.18c-0.32,0.32-0.74,0.49-1.18,0.49h-2.22c-0.81,0-1.47,0.66-1.47,1.47v16.47c0,1.35-1.1,2.46-2.46,2.46h-4.74c-0.98,0-1.78-0.8-1.78-1.78v-8.52c0-2.97-2.42-5.39-5.39-5.39h-4.71c-2.97,0-5.39,2.42-5.39,5.39v10.3h-6.51c-1.35,0-2.46-1.1-2.46-2.46V28.14c0-0.81-0.66-1.47-1.47-1.47H4.64c-0.01,0-0.05,0-0.07,0c-0.33,0.04-0.85-0.18-1.15-0.49c-0.65-0.65-0.65-1.71,0-2.37c0,0,0,0,0,0l20.4-20.39C24.13,3.1,24.55,2.93,25,2.93c0.45,0,0.87,0.17,1.18,0.49l20.39,20.39c0,0,0.01,0.01,0.01,0.01C47.23,24.47,47.23,25.53,46.58,26.18 M48.66,21.75C48.65,21.75,48.65,21.74,48.66,21.75l-20.4-20.4C27.38,0.48,26.23,0,25,0c-1.23,0-2.39,0.48-3.26,1.35L1.36,21.73c0,0.01-0.01,0.01-0.02,0.02c-1.79,1.8-1.78,4.71,0.01,6.5c0.53,0.69,1.9,1.29,3.05,1.34C4.45,29.6,4.5,29.6,4.54,29.6h0.41c0.22,0,0.41,0.85,0.41,1.9v13.11c0,2.97,2.42,5.39,5.39,5.39h7.98c0.81,0,1.46-0.66,1.46-1.47V36.77c0-1.36,1.1-2.46,2.46-2.46h4.71c1.36,0,2.46,1.1,2.46,2.46v11.77c0,0.81,0.66,1.47,1.46,1.47h7.98c2.97,0,5.39-2.42,5.39-5.39V29.6h0.75c0.93,0.12,2.38-0.48,3.25-1.35C50.44,26.46,50.45,23.54,48.66,21.75" />
        </g>
    </svg>
);

export default HomeIcon;


