import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentCategoryTypeApi } from '../services/incidentCategoryType';

import {
    VENUE_INCIDENT_CATEGORY_TYPES_REQUEST,
    venueIncidentCategoryTypesRequest,
    venueIncidentCategoryTypesRequestSuccess,
    venueIncidentCategoryTypesRequestFailure,
    VENUE_INCIDENT_CATEGORY_TYPE_REQUEST,
    venueIncidentCategoryTypeRequestSuccess,
    venueIncidentCategoryTypeRequestFailure,
    VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST,
    venueIncidentCategoryTypeCreateRequestSuccess,
    venueIncidentCategoryTypeCreateRequestFailure,
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST,
    venueIncidentCategoryTypeUpsertRequestSuccess,
    venueIncidentCategoryTypeUpsertRequestFailure,
    VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS,
    venueIncidentCategoryTypeReset,
    VENUE_INCIDENT_CATEGORY_TYPES_REFRESH,
    venueIncidentCategoryTypesRefresh,
    VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST,
    VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS,
    venueIncidentCategoryTypeHideRequestSuccess,
    venueIncidentCategoryTypeHideRequestFailure
} from '../actions/venue';
import {
    notifyError
} from './common';

const venueIncidentCategoryTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => venueIncidentCategoryTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypesRequestEpic.find", venueIncidentCategoryTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypesRequestEpic", venueIncidentCategoryTypesRequestFailure))
    );

const venueIncidentCategoryTypesRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.incidentCategoryTypes.paging.page,
                state.venue.incidentCategoryTypes.paging.pageSize,
                state.venue.incidentCategoryTypes.filter)
                .pipe(
                    map(response => venueIncidentCategoryTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypesRefreshEpic.find", venueIncidentCategoryTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypesRefreshEpic", venueIncidentCategoryTypesRequestFailure))
    );

const venueIncidentCategoryTypeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentCategoryTypeId'],
                true)
                .pipe(
                    map(response => venueIncidentCategoryTypeRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypeRequestEpic.get", venueIncidentCategoryTypeRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypeRequestEpic", venueIncidentCategoryTypeRequestFailure))
    );

const venueIncidentCategoryTypeCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.newEmpty(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'],
                action['securityFirmId'])
                .pipe(
                    map(response => venueIncidentCategoryTypeCreateRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypeCreateEpic.newEmpty", venueIncidentCategoryTypeCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypeCreateEpic", venueIncidentCategoryTypeCreateRequestFailure))
    );

const venueIncidentCategoryTypeUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.addOrUpdate(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.venue.incidentCategoryType.incidentCategoryType)
                .pipe(
                    map(response => venueIncidentCategoryTypeUpsertRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypeUpsertEpic.addOrUpdate", venueIncidentCategoryTypeUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypeUpsertEpic", venueIncidentCategoryTypeUpsertRequestFailure))
    );

const venueIncidentCategoryTypeUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueIncidentCategoryTypeReset())
    );

const venueIncidentCategoryTypeUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => venueIncidentCategoryTypesRefresh())
    );

const venueIncidentCategoryTypeHideEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.hide(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentCategoryTypeId'],
                action['hide'],
                action['forVenueId'],
                action['forSecurityFirmId']
                )
                .pipe(
                    map(response => venueIncidentCategoryTypeHideRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "venueIncidentCategoryTypeHideEpic.hide", venueIncidentCategoryTypeHideRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueIncidentCategoryTypeHideEpic", venueIncidentCategoryTypeHideRequestFailure))
    );

const venueIncidentCategoryTypeHideSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS),
        map((action: any) => venueIncidentCategoryTypeReset())
    );

const venueIncidentCategoryTypeHideSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_INCIDENT_CATEGORY_TYPE_HIDE_REQUEST_SUCCESS),
        map((action: any) => venueIncidentCategoryTypesRefresh())
    );

export const venueIncidentCategoryTypeEpics = combineEpics(
    venueIncidentCategoryTypesRefreshEpic,
    venueIncidentCategoryTypesRequestEpic,
    venueIncidentCategoryTypeRequestEpic,
    venueIncidentCategoryTypeCreateEpic,
    venueIncidentCategoryTypeUpsertEpic,
    venueIncidentCategoryTypeUpsertSuccessEpic,
    venueIncidentCategoryTypeUpsertSuccessRefreshEpic,
    venueIncidentCategoryTypeHideEpic,
    venueIncidentCategoryTypeHideSuccessEpic,
    venueIncidentCategoryTypeHideSuccessRefreshEpic
);