import { connect } from 'react-redux'
import { withRouter } from "react-router";
import Schedule from '../components/schedule/Schedule'
import * as scheduleActions from '../common/actions/schedule';

const mapStateToProps = (state, props) => {
    return {
        scheduleId: props.match.params.scheduleId,
        copyScheduleId: props.match.params.copyScheduleId,
        schedule: state.schedules.schedule.schedule,
        checklistTemplates: state.schedules.schedule.checklistTemplates,
        venues: state.schedules.schedule.venues,
        incidentTypes: state.schedules.schedule.scheduleData.incidentTypes,
        incidentFlagTypes: state.schedules.schedule.scheduleData.incidentFlagTypes,
        incidentCategoryTypes: state.schedules.schedule.incidentCategoryTypes,
        venueLocations: state.schedules.schedule.scheduleData.venueLocations,
        timeZones:state.schedules.schedule.scheduleData.timeZones,
        userRoleTypes: state.schedules.schedule.userRoleTypes,
        isLoading: state.schedules.schedule.isLoading,
        isSaving: state.schedules.schedule.isSaving,
        error: state.schedules.schedule.error,
        showEvents: false,
        venueEvents: [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad:(scheduleId) => {
            dispatch(scheduleActions.scheduleRequest(scheduleId))
        },
        onCreate:() => {
            dispatch(scheduleActions.scheduleCreateSecurityFirmRequest())
        },
        onUpdate:(schedule) => {
            dispatch(scheduleActions.scheduleSet(schedule))
        },
        onSave:(schedule) => {
            dispatch(scheduleActions.scheduleUpsertRequest(schedule))
        },
        onCopy:(scheduleId) => {
            dispatch(scheduleActions.scheduleCopyRequest(scheduleId))
        },
    }
}

const SecurityFirmScheduleContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedule))

export default SecurityFirmScheduleContainer