import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentCategoryType } from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import MultiRadioChipControl from '../../controls/MultiRadioChipControl';
import TextControlGroup from '../../controls/TextControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';

export interface ICommLogIncidentCategoryProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    showGPSLocation?: boolean;
    theme: any;
    classes: any;
}

interface ICommLogIncidentCategoryState {
    selected: number[];
    items: MulitSelectOption[];
    showOther: boolean;
    showSeriousOther: boolean;
}

class CommLogIncidentCategory extends Component<ICommLogIncidentCategoryProps, ICommLogIncidentCategoryState> {
    constructor(props: ICommLogIncidentCategoryProps) {
        super(props)
        this.state = {
            selected: [],
            items: [],
            showOther: false,
            showSeriousOther: false
        }
        this.onLoadItems = this.onLoadItems.bind(this);
        this.onSelectedChanged = this.onSelectedChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
    }

    componentDidMount() {
        this.onLoadItems();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.incident.incidentTypeId) !== JSON.stringify(preProps.incident.incidentTypeId)
            || JSON.stringify(this.props.incident.incidentCategory) !== JSON.stringify(preProps.incident.incidentCategory)) {
            this.onLoadItems();
        }
    }

    onLoadItems() {
        try {
            let items: MulitSelectOption[] = [];
            let selected: number[] = [];
            if (this.props.incident && this.props.incident.incidentCategory) {
                selected = this.props.incident.incidentCategory.map(item => item.incidentCategoryTypeId);
            }
            if (this.props.incidentOptions && this.props.incidentOptions.incidentTypeCategoryTypes) {
                this.props.incidentOptions.incidentTypeCategoryTypes.map(incidentType => {
                    if (incidentType.incidentTypeId == this.props.incident.incidentTypeId) {
                        incidentType.incidentCategoryTypes.map(item => {
                            items.push({
                                id: item.incidentCategoryTypeId,
                                name: item.display,
                                isCommon: (item.usageCount > 0),
                                selected: selected.indexOf(item.incidentCategoryTypeId) > -1
                            });
                        });
                    }
                })
            }
            let showOther: boolean = incidentLogic.isOtherTextRequired(this.props.incident, this.props.incidentOptions);
            let showSeriousOther: boolean = incidentLogic.isSeriousOtherTextRequired(this.props.incident, this.props.incidentOptions);
            this.setState({
                selected,
                items,
                showOther,
                showSeriousOther
            });
        } catch (err) {
            commonLogic.trackException(err, "CommLogIncidentCategory.componentDidMount", {})
        }
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.incident.incidentCategory.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = items.map(item => item.id);

        // remove
        let newIncidentCategories = this.props.incident.incidentCategory.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        items.filter(item => previousIncidentCategoryTypeIds.indexOf(item.id) == -1).forEach(item => {
            newIncidentCategories.push({
                incidentCategoryId: 0,
                incidentId: 0,
                incidentCategoryTypeId: item.id,
                incidentCategoryType: new IncidentCategoryType()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentCategory = newIncidentCategories;
        let showOther: boolean = incidentLogic.isOtherTextRequired(newIncident, this.props.incidentOptions);
        let showSeriousOther: boolean = incidentLogic.isSeriousOtherTextRequired(newIncident, this.props.incidentOptions);
        this.setState({
            showOther,
            showSeriousOther
        });
        this.props.onUpdateIncident(newIncident);
    }

    onSelectedChanged(values: number[]) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.incident.incidentCategory.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = values;

        // remove
        let newIncidentCategories = this.props.incident.incidentCategory.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        values.filter(item => previousIncidentCategoryTypeIds.indexOf(item) == -1).forEach(item => {
            newIncidentCategories.push({
                incidentCategoryId: 0,
                incidentId: 0,
                incidentCategoryTypeId: item,
                incidentCategoryType: new IncidentCategoryType()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentCategory = newIncidentCategories;
        //let showOther: boolean = incidentLogic.isOtherTextRequired(newIncident, this.props.incidentOptions);
        //let showSeriousOther: boolean = incidentLogic.isSeriousOtherTextRequired(newIncident, this.props.incidentOptions);
        //this.setState({
        //    showOther,
        //    showSeriousOther
        //});
        this.props.onUpdateIncident(newIncident);
    }

    onChanged(fieldName: string, value) {
        let newIncident = { ...this.props.incident };
        newIncident[fieldName] = value;
        this.props.onUpdateIncident(newIncident)
    }

    render() {

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                
                <MultiSelectControl
                    wrapControlInCard={true}
                    cardTitle={"Categories"}
                    items={this.state.items}
                    label={""}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onSelectItemsChanged}
                    canShowAll={true} />

                {this.state.showOther && (
                    <TextControlGroup text="Other (specify)"
                        defaultValue={this.props.incident.other}
                        onChange={(value) => this.onChanged("other", value)}
                        labelAbove={true} />
                )}
                {this.state.showSeriousOther && (
                    <TextControlGroup text="Serious Other (specify)"
                        defaultValue={this.props.incident.seriousOther}
                        onChange={(value) => this.onChanged("seriousOther", value)}
                        labelAbove={true} />
                )}
                {(this.state.showOther || this.state.showSeriousOther) && (<div style={{ marginTop: '10px' }}></div>)}
            </SwipeContainer>
        );

        /*
        <MultiRadioChipControl
                    wrapInCard={true}
                    cardTitle={"Categories"}
                    selected={this.state.selected}
                    options={this.state.items}
                    onChange={this.onSelectedChanged}
                />
                */
    }
}

export default withStyles(styles, { withTheme: true })(CommLogIncidentCategory);