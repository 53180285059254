import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import FullPageDialog from '../layout/FullPageDialog';
import PrimaryButton from '../controls/PrimaryButton';
import TextControlGroup from '../controls/TextControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import Box from '@material-ui/core/Box';
import ClearButton from '../controls/ClearButton';
import CardContent from '@material-ui/core/CardContent';
import CardContainer from '../common/CardContainer';
import FolderOpenIcon from '../../resources/FolderOpenIcon';
import FolderClosedIcon from '../../resources/FolderClosedIcon';
import EditIcon from '../../resources/EditIcon';
import PopupModal from '../layout/PopupModal';
import View from '../common/View';


export interface IGroupDirectoryProps {
    groupId: number;
    title?: string;
    tabs?: any;
    backButton?: any;
    directory?: AusComplyDtos.Directory;
    isLoading: boolean;
    isSaving: boolean;
    onLoad: Function;
    onUpsertFolder: Function;
    history: any;
    location: any;
    classes: any;
}

interface IGroupDirectoryState {
    uniqueId: string;
    show: boolean;
    addFolders: AusComplyDtos.Folder[];
    addFolder?: AusComplyDtos.Folder;
    addExpiresDate: AusComplyDtos.SimpleDate;
    parentFolders: AusComplyDtos.Folder[];
    upsertFolder: AusComplyDtos.Folder;
    selectAddOption: boolean;
}

class GroupDirectory extends Component<IGroupDirectoryProps, IGroupDirectoryState> {
    constructor(props: IGroupDirectoryProps) {
        super(props)
        let upsertFolder = new AusComplyDtos.Folder();
        upsertFolder.folderId = -1;
        upsertFolder.name = "";
        this.state = {
            uniqueId: "",
            show: false,
            addFolders: [],
            addFolder: undefined,
            addExpiresDate: new AusComplyDtos.SimpleDate(),
            upsertFolder,
            parentFolders: [],
            selectAddOption: false,
        };
        this.add = this.add.bind(this);
        this.onAddPopupCommand = this.onAddPopupCommand.bind(this);
        this.cancelFolderUpsert = this.cancelFolderUpsert.bind(this);
        this.onUpsertFolderValueChanged = this.onUpsertFolderValueChanged.bind(this);
        this.onSaveFolder = this.onSaveFolder.bind(this);
        this.editFolder = this.editFolder.bind(this);
        this.addFolder = this.addFolder.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(AusComplyDtos.ngtEntityType.Group, this.props.groupId);
    }

    componentDidUpdate(prevProps: Readonly<IGroupDirectoryProps>, prevState: Readonly<IGroupDirectoryState>, snapshot?: any): void {
        if (!this.props.isLoading && this.props.groupId !== undefined && prevProps.groupId != this.props.groupId) {
            this.props.onLoad(AusComplyDtos.ngtEntityType.Group, this.props.groupId);
        }
    }

    onAddPopupCommand(command: string) {
        switch (command) {
            case "add_folder":
                let parentFolders: AusComplyDtos.Folder[] = []
                if (this.props.directory && this.props.directory.allFolders) {
                    parentFolders = this.props.directory.allFolders.filter(f => f.canAddSubFolders)
                }
                let upsertFolder = new AusComplyDtos.Folder();
                upsertFolder.folderId = 0;
                upsertFolder.name = "";
                let parentFolder = parentFolders.find(f => f.uniqueId == this.state.uniqueId);
                if (parentFolder) {
                    upsertFolder.parentFolderId = parentFolder.folderId;
                }
                this.setState({
                    upsertFolder,
                    //parentFolder,
                    parentFolders,
                    selectAddOption: false,
                });
                break;

            default:
                this.setState({
                    selectAddOption: false,
                });
                break;
        }
    }

    cancelFolderUpsert() {
        let upsertFolder = new AusComplyDtos.Folder();
        upsertFolder.folderId = -1;
        upsertFolder.name = "";
        this.setState({ upsertFolder });
    }

    onUpsertFolderValueChanged(fieldName: string, value: any) {
        let upsertFolder = { ...this.state.upsertFolder };
        upsertFolder[fieldName] = value;
        this.setState({ upsertFolder });
    }

    onSaveFolder() {
        this.props.onUpsertFolder(AusComplyDtos.ngtEntityType.Group, this.props.groupId, { ...this.state.upsertFolder });
        this.cancelFolderUpsert();
    }

    addFolder() {
        let parentFolders: AusComplyDtos.Folder[] = []
        if (this.props.directory && this.props.directory.allFolders) {
            parentFolders = this.props.directory.allFolders.filter(f => f.canAddSubFolders)
        }
        let upsertFolder = new AusComplyDtos.Folder();
        upsertFolder.folderId = 0;
        upsertFolder.name = "";
        let parentFolder = parentFolders.find(f => f.uniqueId == this.state.uniqueId);
        if (parentFolder) {
            upsertFolder.parentFolderId = parentFolder.folderId;
        }
        this.setState({
            upsertFolder,
            //parentFolder,
            parentFolders,
            selectAddOption: false,
        });
    }

    add() {
        // reset the add details
        let addFolders: AusComplyDtos.Folder[] = []
        if (this.props.directory && this.props.directory.allFolders) {
            addFolders = this.props.directory.allFolders.filter(f => !f.locked)
        }
        let addExpiresDate = new AusComplyDtos.SimpleDate();
        addExpiresDate.isEmpty = true;
        let addFolder = addFolders.find(f => f.uniqueId == this.state.uniqueId && !f.locked);

        this.setState({
            show: true,
            addFolder,
            addFolders,
            addExpiresDate
        });
    }

    editFolder(folder: AusComplyDtos.Folder) {
        let parentFolders: AusComplyDtos.Folder[] = []
        if (this.props.directory && this.props.directory.allFolders) {
            // Add the 
            parentFolders = this.props.directory.allFolders.filter(f => f.canAddSubFolders && (f.folderId < 0 || !f.path.startsWith(folder.path)))
        }
        let upsertFolder = { ...folder };
        if (upsertFolder && upsertFolder.parentFolderId == 0) {
            upsertFolder.parentFolderId = upsertFolder.compliance ? -1 : -2;
        }
        this.setState({
            upsertFolder,
            parentFolders,
        });
    }

    renderSubTree(folders: AusComplyDtos.Folder[], level: number) {
        let self = this;
        return <>
            {folders.map(folder => {
                const defaultColor: string = folder.obsolete ? "red" : "#888";
                return <>
                <Box
                    key={"folder-tree-" + folder.folderId}
                    display="flex"
                    flexDirection="row"
                    style={{ marginLeft: (level * 20).toString() + "px", marginTop: '5px', color: this.state.uniqueId === folder.uniqueId ? "#FFF" : defaultColor }}
                >
                    <Box p={0} onClick={() => self.setState({ uniqueId: folder.uniqueId })}>
                        <div style={{ height: '20px', width: '20px', display: 'block' }}>
                            {this.state.uniqueId == folder.uniqueId ? (<FolderOpenIcon fill={"#FFF"} />) : (<FolderClosedIcon fill={ folder.canEdit ? "#FFF" : defaultColor} />)}
                        </div>
                    </Box>
                    <Box p={0} flex={1} onClick={() => self.setState({ uniqueId: folder.uniqueId })}>
                        <span style={{ color: folder.canEdit ? "#FFF" : defaultColor}}>&nbsp;{folder.name}</span>
                    </Box>
                    <Box p={0}>
                        {(folder.canEdit) && (
                            <ClearButton style={{ padding: '2px', minWidth: 0, height: '20px', width: '20px' }} onClick={() => this.editFolder(folder)}>
                                <EditIcon fill={defaultColor} />
                            </ClearButton>
                        )}
                    </Box>
                </Box>
                {this.renderSubTree(folder.folders, level + 1)}
            </>}
            )}
        </>
    }

    render() {
        let self = this;
        let subFolders: any[] = [];
        if (this.props.directory && this.props.directory.allFolders) {
            if (this.state.uniqueId === "") {
                subFolders = this.props.directory.allFolders.filter(f => f.folderId < 0);
            } else {
                let folder = this.props.directory.allFolders.find(f => f.uniqueId === this.state.uniqueId);
                if (folder) {
                    subFolders = folder.folders;
                }
            }
        }

        let addFolderId: number = 0;
        let missingExpiry: boolean = false;
        if (this.state.addFolder) {
            addFolderId = this.state.addFolder.folderId;
            missingExpiry = this.state.addFolder.compliance && this.state.addExpiresDate.isEmpty;
        }

        let parentFolderId: number = 0;
        if (this.state.upsertFolder) {
            // console.log(this.state.upsertFolder, this.state.upsertFolder.compliance, this.state.upsertFolder.parentFolderId);
            parentFolderId = this.state.upsertFolder.compliance === true ? -1 : -2;
        }
        if (this.state.upsertFolder && this.state.upsertFolder.parentFolderId != 0) {
            parentFolderId = this.state.upsertFolder.parentFolderId;
        }
        //console.log(parentFolderId)

        let leftButton;
        let rightButton;
        if (this.props.backButton) {
            leftButton = this.props.backButton;
            rightButton = <TransparentButton
                text={"Add Folder"}
                onClick={() => self.addFolder()}
            />
        } else {
            leftButton = <TransparentButton
                text={"Add Folder"}
                onClick={() => self.addFolder()}
            />
        }


        return <PageLayout
            headerText={this.props.title ? this.props.title : "Group Document Folders"}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            footerLeftContent={leftButton}
            footerRightContent={rightButton}
        >
            {this.props.tabs}
            <Box p={0} display="flex" flexDirection={'row'} alignItems={"center"} justifyContent={"center"}>
                <Box flexGrow={1} p={1} style={{maxWidth: "700px"}}>
                    <CardContainer title={"Folders"}>
                        <CardContent>
                            {(this.props.directory && this.props.directory.folders) && this.renderSubTree(this.props.directory.folders, 0)}
                        </CardContent>
                    </CardContainer>
                </Box>
            </Box>
            <FullPageDialog
                open={this.state.upsertFolder.folderId > -1}
                notFullWidth={true}
                title={this.state.upsertFolder.folderId == 0 ? "Add Folder" : "Update Folder"}
                footerRightContent={<PrimaryButton text="Save" onClick={() => self.onSaveFolder()} disabled={this.state.upsertFolder.parentFolderId == 0 || this.state.upsertFolder.name.length == 0} />}
                onDismissed={() => this.cancelFolderUpsert()}>
                <SelectControlGroup
                    text="Parent Folder"
                    onChange={(value) => self.onUpsertFolderValueChanged("parentFolderId", Number(value))}
                    defaultValue={parentFolderId}>
                    <option key={0} value={0}>Select...</option>
                    {this.state.parentFolders.map((item, index) => (
                        <option key={"parent-folder-" + item.folderId} value={item.folderId}>{item.path}</option>
                    ))}
                </SelectControlGroup>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.state.upsertFolder.name}
                    onChange={(value) => self.onUpsertFolderValueChanged("name", value)}
                />
                {this.state.upsertFolder.folderId > 0 && (<>
                    <CheckboxControlGroup
                        text={"Active"}
                        label={" "}
                        isReversed={true}
                        readonly={!this.state.upsertFolder.canDelete}
                        defaultValue={this.state.upsertFolder.obsolete}
                        onChanged={(value) => self.onUpsertFolderValueChanged("obsolete", value)} />
                </>)}
            </FullPageDialog>
            <PopupModal open={this.state.selectAddOption} onAction={action => this.onAddPopupCommand(action)} actions={[{ key: "add_folder", name: "Add Folder" }]} />
        </PageLayout>
    }
}
export default withStyles(styles, { withTheme: true })(GroupDirectory);