import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import * as scheduleActions from "../actions/schedule";

const initialState = {
    schedules: {
        isLoading: false,
        processingId: 0,
        isProcessing: false,
        schedules: [],
    },
    schedule: {
        isLoading: false,
        isSaving: false,
        schedule: { scheduleId: 0 },
        error: "",
        checklistTemplates: [],
        scheduleData: {},
        scheduleDatas: [],
        venues: [],
        incidentCategoryTypes: [],
        //incidentTypes: [],
        //incidentFlagTypes: [],
        //incidentTypeCategoryTypes: [],
        //venueLocations: [],
        //securityFirmVenueSummaries: [],
        //timeZones: [],
        userRoleTypes: [],
        scheduleLogs: {
            isLoading: false,
            paging: {},
            data: []
        }
    }
};

function setIsLoading(state, isLoading) {
    let newState = update(state, {
        schedules: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setIsProcessing(state, isProcessing) {
    let newState = update(state, {
        schedules: {
            isProcessing: { $set: isProcessing }
        }
    });
    return newState;
}

function setSchedules(state, schedules) {
    let newState = update(state, {
        schedules: {
            schedules: { $set: schedules },
            processingId: { $set: 0 }
        }
    });
    return newState;
}

function loadingSchedule(state, isLoading) {
    let newState = update(state, {
        schedule: {
            isLoading: { $set: isLoading },
            error: { $set: "" }
        }
    });
    return newState;
}

function setSchedule(state, schedule: AusComplyDtos.Schedule) {
    // set the available incident category types based on the current selected incident type
    let incidentCategoryTypes: any[] = [];

    let newState = setCurrentVenueData(update(state, {
        schedule: {
            schedule: { $set: schedule },
        }
    }));

    if (schedule && schedule.incidentTypeId > 0 && newState.schedule.scheduleData.incidentTypeCategoryTypes) {
        let incidentTypeCategoryType = newState.schedule.scheduleData.incidentTypeCategoryTypes.find(f => f.incidentTypeId == schedule.incidentTypeId);
        if (incidentTypeCategoryType) {
            incidentCategoryTypes = [...incidentTypeCategoryType.incidentCategoryTypes];
        }
    }

    let newPostState = update(newState, {
        schedule: {
            incidentCategoryTypes: { $set: incidentCategoryTypes }
        }
    });
    return newPostState;
}

function setScheduleData(state, checklistTemplates, scheduleDatas) {
    let newState = update(state, {
        schedule: {
            checklistTemplates: { $set: checklistTemplates },
            scheduleDatas: { $set: scheduleDatas },
        }
    });
    return setCurrentVenueData(newState);
}

function setCurrentVenueData(state) {
    let scheduleData: AusComplyDtos.ScheduleData = new AusComplyDtos.ScheduleData();
    let venues = [];
    let userRoleTypes: any[] = [];

    if (state.schedule.schedule && state.schedule.schedule.venueId > 0) {
        const venueId = state.schedule.schedule.venueId;
        const venueScheduleData = state.schedule.scheduleDatas.find(f => f.venueId == venueId);
        if (venueScheduleData) {
            scheduleData = { ...venueScheduleData };
            userRoleTypes = [...scheduleData.userRoleTypes];
            if (!state.schedule.schedule.includeAssociatedUsers) {
                userRoleTypes = userRoleTypes.filter(f => f.venue == true);
            }
        }
    } else if (state.schedule.schedule && state.schedule.schedule.securityFirmId > 0) {
        // the first selected venue
        if (state.schedule.schedule && state.schedule.schedule.scheduleVenues && state.schedule.schedule.scheduleVenues.length > 0) {
            const venueId = state.schedule.schedule.scheduleVenues[0].venueId;
            const venueScheduleData = state.schedule.scheduleDatas.find(f => f.venueId == venueId);
            if (venueScheduleData) {
                scheduleData = { ...venueScheduleData };
                userRoleTypes = [...scheduleData.userRoleTypes];
            }
        }
        else {
            // find the zero security firm record
            const securityFirmScheduleData = state.schedule.scheduleDatas.find(f => f.venueId == 0);
            if (securityFirmScheduleData) {
                scheduleData = { ...securityFirmScheduleData };
                userRoleTypes = [...scheduleData.userRoleTypes];
            }
        }
        if (state.schedule.scheduleDatas && state.schedule.scheduleDatas.length > 0) {
            venues = state.schedule.scheduleDatas
                .filter(f => f.venueId > 0)
                .map((item, index) => {
                    return { id: item.venueId, name: item.venueName };
                })
        }
    }

    let newState = update(state, {
        schedule: {
            scheduleData: { $set: scheduleData },
            venues: { $set: venues },
            userRoleTypes: { $set: userRoleTypes }
        }
    });
    return newState;
}

function savingSchedule(state, isSaving, error) {
    let newState = update(state, {
        schedule: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setProcessing(state, id) {
    let newState = update(state, {
        schedules: {
            processingId: { $set: id },
        }
    });
    return newState;
}

function loadingScheduleLogs(state, isLoading) {
    let newState = update(state, {
        schedule: {
            scheduleLogs: {
                isLoading: { $set: isLoading }
            }
        }
    });
    return newState;
}

function setScheduleLogs(state, data) {

    let newState = update(state, {
        schedule: {
            scheduleLogs: {
                paging: { $set: data.paging },
                data: { $set: data.data }
            }
        }
    });
    return newState;
}

export default function schedules(state = initialState, action) {
    switch (action.type) {
        case scheduleActions.SCHEDULES_SECURITYFIRMS_REQUEST:
        case scheduleActions.SCHEDULES_REQUEST:
            return setIsProcessing(setIsLoading(state, true), false);
        case scheduleActions.SCHEDULES_REQUEST_SUCCESS:
            return setIsLoading(setSchedules(state, action.schedules), false);
        case scheduleActions.SCHEDULES_REQUEST_FAILURE:
            return setIsLoading(state, false);
        case scheduleActions.SCHEDULE_RESET:
            return setSchedule(state, new AusComplyDtos.Schedule());
        case scheduleActions.SCHEDULE_REQUEST:
            return setSchedule(loadingSchedule(state, true), new AusComplyDtos.Schedule());
        case scheduleActions.SCHEDULE_REQUEST_SUCCESS:
            return setSchedule(setScheduleData(
                loadingSchedule(state, false),
                action.checklistTemplates,
                action.scheduleDatas
            ), action.schedule);
        case scheduleActions.SCHEDULE_REQUEST_FAILURE:
            return loadingSchedule(state, false);
        case scheduleActions.SCHEDULE_COPY_REQUEST:
        case scheduleActions.SCHEDULE_CREATE_SECURITYFIRM_REQUEST:
        case scheduleActions.SCHEDULE_CREATE_REQUEST:
            return setSchedule(loadingSchedule(state, true), new AusComplyDtos.Schedule());;
        case scheduleActions.SCHEDULE_CREATE_REQUEST_SUCCESS:
            return setSchedule(setScheduleData(
                loadingSchedule(state, false),
                action.checklistTemplates,
                action.scheduleDatas
            ), action.schedule);
        case scheduleActions.SCHEDULE_CREATE_REQUEST_FAILURE:
            return loadingSchedule(state, false);
        case scheduleActions.SCHEDULE_UPSERT_REQUEST:
            return savingSchedule(state, true, "");
        case scheduleActions.SCHEDULE_UPSERT_REQUEST_SUCCESS:
            return setSchedule(savingSchedule(state, false, ""), action.schedule);
        case scheduleActions.SCHEDULE_UPSERT_REQUEST_FAILURE:
            return savingSchedule(state, false, action.error);
        case scheduleActions.SCHEDULE_SET:
            return setSchedule(state, action.schedule);
        case scheduleActions.SCHEDULE_PROCESS_REQUEST:
            return setIsProcessing(setProcessing(state, action.scheduleId), true);
        case scheduleActions.SCHEDULE_PROCESS_REQUEST_SUCCESS:
        case scheduleActions.SCHEDULE_PROCESS_REQUEST_FAILURE:
            return setIsProcessing(setProcessing(state, 0), false);;
        case scheduleActions.SCHEDULE_LOGS_REQUEST:
        case scheduleActions.SCHEDULE_LOG_DELETE_REQUEST:
            return loadingScheduleLogs(state, true);
        case scheduleActions.SCHEDULE_LOGS_REQUEST_SUCCESS:
        case scheduleActions.SCHEDULE_LOG_DELETE_REQUEST_SUCCESS:
            return loadingScheduleLogs(setScheduleLogs(state, action.data), false);
        case scheduleActions.SCHEDULE_LOGS_REQUEST_FAILURE:
        case scheduleActions.SCHEDULE_LOG_DELETE_REQUEST_FAILURE:
            return loadingScheduleLogs(state, false);

    }
    return state;
}