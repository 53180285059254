import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface ITransparentButtonProps {
    theme: any;
    classes: any;
    text?: string;
    className?: any;
    onClick?: Function;
    component?: any;
    style?: any;
    disabled?: boolean;
    small?: boolean;
    compact?: boolean;
    noWrap?: boolean;
    autoFocus?: boolean;
    to?: any;
    icon?: any;
}

class TransparentButton extends React.PureComponent<ITransparentButtonProps, any> {
    constructor(props: ITransparentButtonProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }
    render() {
        const { classes, theme } = this.props;
        let style = this.props.style;
        if (!style) {
            style = {
                width: '100%', height: '100%'
            };
        }  
        let compactClass: any;
        if (this.props.compact) {
            compactClass = classes.buttonCompact;
        }
        let noWrapClass: any;
        if (this.props.noWrap) {
            noWrapClass = classes.buttonNoWrap;
        }
        let className=classNames(classes.buttonTransparent, classes.buttonTransparentState, this.props.className, compactClass, noWrapClass);
        if(this.props.disabled) {
            className=classNames(classes.buttonTransparentDisabled, classes.buttonTransparentDisabledState, this.props.className, compactClass, noWrapClass);
        } 
        if (this.props.component) {
            return (
                <Button
                    variant="contained"
                    onClick={e => this.onClick(e)}
                    color="default"
                    autofocus={this.props.autoFocus}
                    size={(this.props.small || this.props.compact) ? "small" : "medium"}
                    component={this.props.component}
                    disabled={this.props.disabled}
                    className={className}
                    style={{ color: theme.palette.text.secondary, ...style }}>
                    {this.props.icon && (
                        <div style={{ width: '30px', height: '30px', margin: '-20px 0' }}>
                            {this.props.icon}
                        </div>
                    )}
                    {this.props.text}
                    {this.props.children}
                </Button>
            );
        }
        return (
            <Button
                variant="contained"
                onClick={e => this.onClick(e)}
                color="default"
                size={(this.props.small || this.props.compact) ? "small" : "medium"}
                disabled={this.props.disabled}
                className={className}
                style={{ color: theme.palette.text.secondary, ...style }}>
                {this.props.icon && (
                    <div style={{ width: '30px', height: '30px', margin: '-20px 0' }}>
                        {this.props.icon}
                    </div>
                )}
                {this.props.text}
                {this.props.children}
            </Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TransparentButton);