import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IRoleTypeHeaderProps {
    classes: any;
}

class RoleTypeHeader extends React.Component<IRoleTypeHeaderProps, any> {
    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Role Type</th>
                <th className={classes.tableHr}>Base Name</th>
                <th className={classes.tableHr}>Alias</th>
                <th className={classes.tableHr}>Access level</th>
                <th className={classes.tableHr}># Assigned</th>
                <th className={classes.tableHr}>New assignments allowed</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RoleTypeHeader);