
export const logging = {
    trackError: (error: any, location: string) => {
        let message = error;
        try {
            message = JSON.stringify(error);
            if (message = "{}") {
                message = error;
            }
        } catch (err) { }
        try {
            if (message.indexOf('ValidationException') > -1) {
                return;
            }
            if ((<any>window).appInsights) {
                (<any>window).appInsights.trackException("PWA: " + message, location, {});
            } else {
            }
        } catch (err) {
        }
    },
    trackTrace: (message: string) => {
        try {
            if ((<any>window).appInsights) {
                (<any>window).appInsights.trackTrace(message);
            } else {
            }
        } catch (err) {
        }
    }
}