import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { radioChannelServiceApi } from '../services/radioChannelService';

import * as radioChannelActions from '../actions/radioChannel';
import {
    notifyError
} from './common';

const radioChannelsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNELS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        radioChannelServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => radioChannelActions.radioChannelsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "radioChannelsRequestEpic.find", radioChannelActions.radioChannelsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "radioChannelsRequestEpic", radioChannelActions.radioChannelsRequestFailure))
    );


const radioChannelsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNELS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            radioChannelServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.radioChannels.radioChannels.paging.page,
                state.radioChannels.radioChannels.paging.pageSize,
                state.radioChannels.radioChannels.filter)
                .pipe(
                    map(response => radioChannelActions.radioChannelsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "radioChannelsRefreshEpic.find", radioChannelActions.radioChannelsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "radioChannelsRefreshEpic", radioChannelActions.radioChannelsRequestFailure))
    );

const radioChannelRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNEL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            radioChannelServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['radioChannelId'])
                .pipe(
                    map(response => radioChannelActions.radioChannelRequestSuccess(response.radioChannel)),
                    catchError(error => notifyError(error, "radioChannelRequestEpic.get", radioChannelActions.radioChannelRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "radioChannelRequestEpic", radioChannelActions.radioChannelRequestFailure))
    );

const radioChannelCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNEL_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            radioChannelServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forGroupId'],
                action['forVenueId'])
                .pipe(
                    map(response => radioChannelActions.radioChannelCreateRequestSuccess(response.radioChannel)),
                    catchError(error => notifyError(error, "radioChannelCreateEpic.create", radioChannelActions.radioChannelCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "radioChannelCreateEpic", radioChannelActions.radioChannelCreateRequestFailure))
    );

const radioChannelSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNEL_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            radioChannelServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.radioChannels.radioChannel.radioChannel)
                .pipe(
                    map(response => radioChannelActions.radioChannelSaveRequestSuccess(response.radioChannel)),
                    catchError(error => notifyError(error, "radioChannelUpsertEpic.upsert", radioChannelActions.radioChannelSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "radioChannelUpsertEpic", radioChannelActions.radioChannelSaveRequestFailure))
    );

const radioChannelSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNEL_SAVE_REQUEST_SUCCESS),
        map((action: any) => radioChannelActions.radioChannelReset())
    );

const radioChannelSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(radioChannelActions.RADIOCHANNEL_SAVE_REQUEST_SUCCESS),
        map((action: any) => radioChannelActions.radioChannelsRefresh())
    );

export const radioChannelEpics = combineEpics(
    radioChannelsRefreshEpic,
    radioChannelsRequestEpic,
    radioChannelRequestEpic,
    radioChannelCreateEpic,
    radioChannelSaveEpic,
    radioChannelSaveSuccessEpic,
    radioChannelSaveSuccessRefreshEpic
);