import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface IPrimaryButtonProps {
    classes: any;
    text?: string;
    className?: any;
    disabled? :boolean;
    onClick?: Function;
    component?: any;
    style?: any;
    noWrap?: boolean;
}

class PrimaryButton extends React.PureComponent<IPrimaryButtonProps, any> {
    constructor(props: IPrimaryButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;

        let style = this.props.style;
        if(!style) {
            style = {
                width: '100%', height: '100%'
            };
        }
        let className=classNames(classes.buttonPrimary, classes.buttonPrimaryState, this.props.className);
        if(this.props.disabled) {
            className=classNames(classes.buttonPrimary, classes.buttonPrimaryDisabledState, classes.buttonPrimaryDisabled, this.props.className);
        }         
        let text = this.props.noWrap ? <span style={{ whiteSpace: 'nowrap'}}>{this.props.text}</span>  : <span>{this.props.text}</span>;
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                component={this.props.component}
                className={className}
                style={style}>{text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrimaryButton);