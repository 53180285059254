import update from "immutability-helper";
import * as dashboardActions from "../actions/dashboard";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";

const initialState = {
    isLoading: false,
    forVenueId: null,
    forSecurityFirmId: null,
    filter: { dashboardId: -1 },
    data: [],
    dataIncidentType: {
        isLoading: false,
        gridLinesCount: 0,
        colors: [],
        data: []
    },
    dataIncidentCategories: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataIncidentLocations: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataIncidentRefuseEntryReasons: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataIncidentAskedToLeave: {
        isLoading: false,
        data: []
    },
    dataIncidentWatchlists: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataPatronDemographics: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataPatronGender: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataPatronRace: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataStaffEntered: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataManagersApproved: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataChecklist: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataRGOIncidentType: {
        isLoading: false,
        gridLinesCount: 0,
        colors: [],
        data: []
    },
    dataRGOIncidentCategories: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
    dataRGOIncidentLocations: {
        isLoading: false,
        gridLinesCount: 0,
        data: []
    },
};

const DEFAULT_CHART_COLORS: string[] = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC'
];

function loading(state, isLoading) {

    let newState = update(state, {
        //isLoading: { $set: isLoading },
        dataIncidentType: {
            isLoading: { $set: isLoading }
        },
        dataIncidentCategories: {
            isLoading: { $set: isLoading }
        },
        dataIncidentLocations: {
            isLoading: { $set: isLoading }
        },
        dataIncidentRefuseEntryReasons: {
            isLoading: { $set: isLoading }
        },
        dataIncidentAskedToLeave: {
            isLoading: { $set: isLoading }
        },
        dataIncidentWatchlists: {
            isLoading: { $set: isLoading }
        },
        dataPatronDemographics: {
            isLoading: { $set: isLoading }
        },
        dataPatronGender: {
            isLoading: { $set: isLoading }
        },
        dataPatronRace: {
            isLoading: { $set: isLoading }
        },
        dataStaffEntered: {
            isLoading: { $set: isLoading }
        },
        dataManagersApproved: {
            isLoading: { $set: isLoading }
        },
        dataChecklist: {
            isLoading: { $set: isLoading }
        },
        dataRGOIncidentType: {
            isLoading: { $set: isLoading }
        },
        dataRGOIncidentCategories: {
            isLoading: { $set: isLoading }
        },
        dataRGOIncidentLocations: {
            isLoading: { $set: isLoading }
        },
    });
    return newState;
}

function updateFilter(state, filter) {
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setVenueSecurityFirm(state, forVenueId, forSecurityFirmId) {
    let newState = update(state, {
        forVenueId: { $set: forVenueId },
        forSecurityFirmId: { $set: forSecurityFirmId }
    });
    return newState;
}

function setData(state, data) {
    let newState = update(state, {
        data: { $set: data }
    });
    return newState;
}

function getMaxValue(dataValues) {
    var _iMaxValue = -1;
    for (var i = 0; i < dataValues.length; i++) {
        if (dataValues[i].Total > _iMaxValue) {
            _iMaxValue = dataValues[i].Total;
        }
    }
    return _iMaxValue;
}

function getGridlinesCount(dataValues) {
    var _iMaxValue = getMaxValue(dataValues);

    if (_iMaxValue <= 5) {
        return _iMaxValue + 1;
    } else {
        return 5;
    }
}

export function getColors(dataValues) {
    var result: string[] = [];

    if (!dataValues || dataValues.length == 0) return result;

    var defaultColors = [...DEFAULT_CHART_COLORS].reverse();

    var definedColors = dataValues.filter(f => f.color && f.color !== "").map((item, index) => item.color);

    if (definedColors.length === 0) return result;

    // remove any specified colors for the first round of colors assignment
    defaultColors = defaultColors.filter(f => definedColors.indexOf(f) === -1);

    dataValues.forEach(dataValue => {
        if (defaultColors.length === 0) {
            defaultColors = [...DEFAULT_CHART_COLORS].reverse(); // reset the list, we will just cycle around the defaul 20 colors
        }
        if (dataValue.color && dataValue.color !== '') {
            result.push(dataValue.color)
        } else {
            let color = defaultColors.pop()
            if (color) {
                result.push(color)
            }
        }
    });

    return result;
}

function loadingIncidentType(state, isLoading) {
    let newState = update(state, {
        dataIncidentType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentType(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentType);
    let incidentTypes: any[] = [['Incident Type', 'Count', { type: 'string', role: 'annotation' }]];
    let colors = getColors(filteredData);
    filteredData.forEach(d => incidentTypes.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentType: {
            data: { $set: incidentTypes },
            colors: { $set: colors }
        }
    });
    return newState;
}

function loadingIncidentCategories(state, isLoading) {
    let newState = update(state, {
        dataIncidentCategories: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentCategories(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentCategoryType);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Category' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentCategories: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingIncidentLocations(state, isLoading) {
    let newState = update(state, {
        dataIncidentLocations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentLocations(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentLocation);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Location' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentLocations: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingIncidentRefuse(state, isLoading) {
    let newState = update(state, {
        dataIncidentRefuseEntryReasons: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentRefuse(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentRefusalReason);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Reason' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentRefuseEntryReasons: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingIncidentRemove(state, isLoading) {
    let newState = update(state, {
        dataIncidentAskedToLeave: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentRemove(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentRemovalReason);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Reason' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentAskedToLeave: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingIncidentWatchlists(state, isLoading) {
    let newState = update(state, {
        dataIncidentWatchlists: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataIncidentWatchlists(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentWatchlist);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Watchlist' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataIncidentWatchlists: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingPatronDemographics(state, isLoading) {
    let newState = update(state, {
        dataPatronDemographics: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataPatronDemographics(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedDemographic);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [['Demographic', 'Count']];
    filteredData.forEach(d => chartData.push([d.name, d.total]));
    let newState = update(state, {
        dataPatronDemographics: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingPatronGender(state, isLoading) {
    let newState = update(state, {
        dataPatronGender: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataPatronGender(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedGender);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [['Demographic', 'Count']];
    filteredData.forEach(d => chartData.push([d.name, d.total]));
    let newState = update(state, {
        dataPatronGender: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingPatronRace(state, isLoading) {
    let newState = update(state, {
        dataPatronRace: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataPatronRace(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedRacialAppearance);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [['Demographic', 'Count']];
    filteredData.forEach(d => chartData.push([d.name, d.total]));
    let newState = update(state, {
        dataPatronRace: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingEntered(state, isLoading) {
    let newState = update(state, {
        dataStaffEntered: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataEntered(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentEnteredBy);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Entered By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataStaffEntered: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingApproved(state, isLoading) {
    let newState = update(state, {
        dataManagersApproved: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataApproved(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.IncidentApprovedBy);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Approved By' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataManagersApproved: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function setDataChecklist(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.ChecklistTemplate);
    let checklists: any[] = [['Checklist', 'Count', { type: 'string', role: 'annotation' }]];
    filteredData.forEach(d => checklists.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataChecklist: {
            data: { $set: checklists }
        }
    });
    return newState;
}

function loadingChecklist(state, isLoading) {
    let newState = update(state, {
        dataChecklist: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function loadingRGOIncidentType(state, isLoading) {
    let newState = update(state, {
        dataRGOIncidentType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataRGOIncidentType(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.RGOIncidentType);
    let incidentTypes: any[] = [['Incident Type', 'Count', { type: 'string', role: 'annotation' }]];
    let colors = getColors(filteredData);
    filteredData.forEach(d => incidentTypes.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataRGOIncidentType: {
            data: { $set: incidentTypes },
            colors: { $set: colors }
        }
    });
    return newState;
}

function loadingRGOIncidentCategories(state, isLoading) {
    let newState = update(state, {
        dataRGOIncidentCategories: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataRGOIncidentCategories(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.RGOIncidentCategoryType);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Category' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataRGOIncidentCategories: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

function loadingRGOIncidentLocations(state, isLoading) {
    let newState = update(state, {
        dataRGOIncidentLocations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDataRGOIncidentLocations(state, data) {
    let filteredData = data.filter(d => d.mode === AusComplyDtos.ngtStatsMode.RGOIncidentLocation);
    let gridLinesCount = getGridlinesCount(filteredData);
    let chartData: any[] = [
        [
            { type: 'string', label: 'Location' },
            { type: 'number', label: 'Total' },
            { type: 'string', label: 'Annotation', role: 'annotation' },
        ]
    ];
    filteredData.forEach(d => chartData.push([d.name, d.total, d.total.toString()]));
    let newState = update(state, {
        dataRGOIncidentLocations: {
            data: { $set: chartData },
            gridLinesCount: { $set: gridLinesCount }
        }
    });
    return newState;
}

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardActions.DASHBOARD_RESET:
            return { ...initialState };
        case dashboardActions.DASHBOARD_SET_FILTER:
            return updateFilter(state, action.filter);
        case dashboardActions.DASHBOARD_FILTER_REQUEST:
            return loading(setVenueSecurityFirm(state, action.forVenueId, action.forSecurityFirmId), true);
        case dashboardActions.DASHBOARD_FILTER_REQUEST_SUCCESS:
            return loading(updateFilter(state, action.filter), false);
        case dashboardActions.DASHBOARD_FILTER_REQUEST_FAILURE:
            return loading(state, false);
        case dashboardActions.DASHBOARD_REQUEST:
            return setData(loading(state, true), []);
        case dashboardActions.DASHBOARD_REQUEST_SUCCESS:
            return setData(updateFilter(loading(state, false), action.filter), action.data);
        case dashboardActions.DASHBOARD_REQUEST_FAILURE:
            return loading(state, false);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_TYPE_SUCCESS:
            return setDataIncidentType(updateFilter(loadingIncidentType(state, false), action.filter), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_CATEGORIES_SUCCESS:
            return setDataIncidentCategories(loadingIncidentCategories(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_LOCATIONS_SUCCESS:
            return setDataIncidentLocations(loadingIncidentLocations(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_REFUSE_SUCCESS:
            return setDataIncidentRefuse(loadingIncidentRefuse(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_ASKED_SUCCESS:
            return setDataIncidentRemove(loadingIncidentRemove(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_WATCHLISTS_SUCCESS:
            return setDataIncidentWatchlists(loadingIncidentWatchlists(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_DEMOGRAPHICS_SUCCESS:
            return setDataPatronDemographics(loadingPatronDemographics(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_GENDER_SUCCESS:
            return setDataPatronGender(loadingPatronGender(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_RACE_SUCCESS:
            return setDataPatronRace(loadingPatronRace(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_ENTERED_SUCCESS:
            return setDataEntered(loadingEntered(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_INCIDENT_APPROVED_SUCCESS:
            return setDataApproved(loadingApproved(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_CHECKLIST_SUCCESS:
            return setDataChecklist(loadingChecklist(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_RGO_INCIDENT_TYPE_SUCCESS:
            return setDataRGOIncidentType(loadingRGOIncidentType(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_RGO_INCIDENT_CATEGORIES_SUCCESS:
            return setDataRGOIncidentCategories(loadingRGOIncidentCategories(state, false), action.data);
        case dashboardActions.DASHBOARD_REQUEST_RGO_INCIDENT_LOCATIONS_SUCCESS:
            return setDataRGOIncidentLocations(loadingRGOIncidentLocations(state, false), action.data);

    }
    return state;
}