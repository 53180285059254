import React from "react";

const DraftSaveIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M66.5,44.6c-1.3,0-2.3,1-2.3,2.3v15.2H15.8V47c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3v19.9h57.6V47C68.8,45.7,67.8,44.6,66.5,44.6z M40,52.7L55.7,37c0.9-0.9,0.9-2.4,0-3.3s-2.4-0.9-3.3,0L42.3,43.8V15.5c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3v28.3L27.6,33.7c-0.9-0.9-2.4-0.9-3.3,0c-0.9,0.9-0.9,2.4,0,3.3L40,52.7z" />
        </g>
    </svg>
);

export default DraftSaveIcon;


