import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import SectionDivider from '../../common/SectionDivider';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Spacer from '../../common/Spacer';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import { MulitSelectOption } from '../../../common/dto/common';
import VerticalCollapsedSpacer from '../../common/VerticalCollapsedSpacer';
import MultiSelectControl from '../../controls/MutiSelectControl';
import ExternalApiConnectionContainer from '../../../containers/ExternalApiConnectionContainer';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import SubTitle from '../../common/SubTitle';
import Box from '@material-ui/core/Box';
import PrimaryButton from '../../controls/PrimaryButton';
import SelectControlGroup from '../../controls/SelectControlGroup';
import ExternalApiConnectionWebhookMessagesContainer from '../../../containers/ExternalApiConnectionWebhookMessagesContainer';

export interface IRollerProps {
    venueId: number;
    externalApiConnection: AusComplyDtos.ExternalApiConnection;
    isReady: boolean;
    isLoading: boolean;
    isSaving: boolean;
    isReprocessing: boolean;
    rollerConfiguration: AusComplyDtos.RollerConfiguration;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    onLoadAudit: Function;
    onLoad: Function;
    onSave: Function;
    onSet: Function;
    onReprocess: Function;
}

interface IRollerState {
    tab: number,
    tickets: string,
    fromDate: AusComplyDtos.SimpleDate
}

export default class Roster extends Component<IRollerProps, IRollerState> {
    constructor(props: IRollerProps) {
        super(props)

        let fromDate = new AusComplyDtos.SimpleDate();
        fromDate.isEmpty = true;

        this.state = {
            tab: 0,
            fromDate,
            tickets: ""
        };

        this.onValueChanged = this.onValueChanged.bind(this);
        this.onBookingValueChanged = this.onBookingValueChanged.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IRollerProps>, prevState: Readonly<IRollerState>, snapshot?: any): void {
        if (!prevProps.isReady && this.props.isReady) {
            this.props.onLoad();
        } else if (prevProps.isReprocessing && !this.props.isReprocessing && this.state.tab == 2) {
            let fromDate = new AusComplyDtos.SimpleDate();
            fromDate.isEmpty = true;

            this.setState({tickets: "", fromDate})
        }
    }

    getBookingEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.events) {
            current = rollerConfiguration.webHooks.booking.events;
        }
        let options: MulitSelectOption[] = [
            {
                id: 1,
                name: "New booking created",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Booking updated",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            }
        ];

        return options;
    }

    getBookingChannelsEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.filter &&
            rollerConfiguration.webHooks.booking.filter.channels
        ) {
            current = rollerConfiguration.webHooks.booking.filter.channels;
        }
        let options: MulitSelectOption[] = [
            {
                id: 0,
                name: "Venue Manager",
                isCommon: false,
                selected: current.indexOf(0) > -1,
                info: ""
            },
            {
                id: 1,
                name: "POS",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Online Checkout",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            },
            {
                id: 3,
                name: "Forms",
                isCommon: false,
                selected: current.indexOf(3) > -1,
                info: ""
            },
            {
                id: 4,
                name: "Self Serve Kiosk",
                isCommon: false,
                selected: current.indexOf(4) > -1,
                info: ""
            },
            {
                id: 5,
                name: "Data Import",
                isCommon: false,
                selected: current.indexOf(5) > -1,
                info: ""
            },
            {
                id: 7,
                name: "API",
                isCommon: false,
                selected: current.indexOf(7) > -1,
                info: ""
            }
        ];

        return options;
    }

    getBookingProductTypesEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.filter &&
            rollerConfiguration.webHooks.booking.filter.productTypes
        ) {
            current = rollerConfiguration.webHooks.booking.filter.productTypes;
        }
        let options: MulitSelectOption[] = [
            {
                id: 1,
                name: "Pass",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Session Pass",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            },
            {
                id: 3,
                name: "Add On",
                isCommon: false,
                selected: current.indexOf(3) > -1,
                info: ""
            },
            {
                id: 4,
                name: "Stock",
                isCommon: false,
                selected: current.indexOf(4) > -1,
                info: ""
            },
            {
                id: 5,
                name: "Package",
                isCommon: false,
                selected: current.indexOf(5) > -1,
                info: ""
            },
            {
                id: 6,
                name: "Gift Card",
                isCommon: false,
                selected: current.indexOf(6) > -1,
                info: ""
            },
            {
                id: 7,
                name: "Wallet",
                isCommon: false,
                selected: current.indexOf(7) > -1,
                info: ""
            },
            {
                id: 8,
                name: "Membership",
                isCommon: false,
                selected: current.indexOf(8) > -1,
                info: ""
            }
        ];

        return options;
    }

    onValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    onBookingValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration.webHooks.booking[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    onBookingFilterValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration.webHooks.booking.filter[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    onRefresh() {
        if (this.state.tab == 1) {
            if (this.props.isReady) {
                this.props.onLoad();
            }
        }
        if (this.state.tab == 0) {
            this.props.onLoadAudit(this.props.paging.pageSize, this.props.paging.page, this.props.filter);
        }
    }

    render() {
        let self = this;

        let content;
        let booking;
        let audit;
        let connection;
        let utilities;
        if (this.state.tab == 1) {
            connection = <>
                <ExternalApiConnectionContainer
                    entityType={AusComplyDtos.ngtEntityType.Roller}
                    venueId={this.props.venueId} />
                <Spacer />
            </>
            if (this.props.isReady &&
                this.props.rollerConfiguration &&
                this.props.externalApiConnection &&
                this.props.externalApiConnection.isConnected) {

                if (this.props.rollerConfiguration.enabled) {
                    let watchlists = this.props.rollerConfiguration.facialRecognitionWatchlists;
                    if (this.props.rollerConfiguration.facialRecognitionWatchlistId) {
                        watchlists = watchlists.filter(f => !f.obsolete || f.facialRecognitionWatchlistId == this.props.rollerConfiguration.facialRecognitionWatchlistId);
                    } else {
                        watchlists = watchlists.filter(f => !f.obsolete);
                    }

                    booking = <>
                        <SubTitle text='Booking' />
                        <MultiSelectControl
                            cardTitle={"Events"}
                            wrapControlInCard={true}
                            wrapInControlGroup={false}
                            items={self.getBookingEvents()}
                            searchText="Type at least three letters..."
                            minimumCharactersRequired={0}
                            onSelectItemsChanged={items => self.onBookingValueChanged("events", items.map(item => item.id))}
                            canShowAll={true} />
                        <MultiSelectControl
                            cardTitle={"Channels"}
                            wrapControlInCard={true}
                            wrapInControlGroup={false}
                            items={self.getBookingChannelsEvents()}
                            searchText="Type at least three letters..."
                            minimumCharactersRequired={0}
                            onSelectItemsChanged={items => self.onBookingFilterValueChanged("channels", items.map(item => item.id))}
                            canShowAll={true} />
                        <MultiSelectControl
                            cardTitle={"Product Types"}
                            wrapControlInCard={true}
                            wrapInControlGroup={false}
                            items={self.getBookingProductTypesEvents()}
                            searchText="Type at least three letters..."
                            minimumCharactersRequired={0}
                            onSelectItemsChanged={items => self.onBookingFilterValueChanged("productTypes", items.map(item => item.id))}
                            canShowAll={true} />
                        <TextControlGroup
                            text={"Specific Product Ids"}
                            defaultValue={self.props.rollerConfiguration.webHooks.booking.filter.productIds.join(",")}
                            onBlur={value => {
                                let convertedValue = [];
                                if (value && value !== "") {
                                    convertedValue = value.split(',').map((item, index) => Number(item));
                                }
                                self.onBookingFilterValueChanged("productIds", convertedValue);
                            }}
                        />
                        <TextControlGroup
                            text={"Product Category Match"}
                            defaultValue={self.props.rollerConfiguration.webHooks.booking.filter.productCategory}
                            onBlur={value => this.onBookingFilterValueChanged("productCategory", value)}
                        />
                        <SelectControlGroup
                            text="Watchlist"
                            onChange={(value) => this.onValueChanged("facialRecognitionWatchlistId", value)}
                            defaultValue={self.props.rollerConfiguration.facialRecognitionWatchlistId}>
                            <option value={0} />
                            {watchlists.map((option, index) => {
                                let info = "";
                                if (option.venueId == 0 && option.groupId == 0) {
                                    info = "[global]";
                                }
                                if (option.venueId == 0 && option.groupId > 0) {
                                    info = "[group]";
                                }
                                return (
                                    <option key={option.facialRecognitionWatchlistId} value={option.facialRecognitionWatchlistId}>{option.displayName}      {info}</option>
                                )
                            })}
                        </SelectControlGroup>
                    </>
                }

                content = <CardContainer
                    title={"Configuration"}
                    isSaving={this.props.isSaving}
                    isLoading={this.props.isLoading}
                    style={{ paddingTop: 0 }}>
                    <CardContent>
                        <CheckboxControlGroup
                            text='Webhooks enabled'
                            defaultValue={this.props.rollerConfiguration.enabled}
                            onChanged={value => this.onValueChanged("enabled", value)}
                        />
                        {booking}
                        <Box display="flex" flexDirection="row" justifyContent="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <Box flex={1}>
                            </Box>
                            <Box>
                                <View style={{ paddingRight: '10px' }}>
                                    <PrimaryButton text='Save' onClick={() => self.props.onSave()} />
                                </View>
                            </Box>
                        </Box>
                    </CardContent>
                </CardContainer>
            }
        }

        if (this.state.tab == 0) {
            audit = <ExternalApiConnectionWebhookMessagesContainer entityType={AusComplyDtos.ngtEntityType.Roller} />
        }

        if (this.state.tab == 2) {
            utilities = <CardContainer
                title={"Reprocess messages"}
                isSaving={this.props.isReprocessing}
                isLoading={this.props.isLoading}
                style={{ paddingTop: 0 }}>
                <CardContent>
                    <TextAreaControlGroup
                        text='Reprocess bookings found in Tickets (CSV)'
                        defaultValue={this.state.tickets}
                        onChange={value => self.setState({ tickets: value })}
                    />
                    <SimpleDateControlGroup
                        text="OR re-process 'added to watchlist' messages from date"
                        date={this.state.fromDate}
                        onChange={value => self.setState({ fromDate: value })}
                    />
                    <Box display="flex" flexDirection="row" justifyContent="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Box flex={1}>
                        </Box>
                        <Box>
                            <View style={{ paddingRight: '10px' }}>
                                <PrimaryButton text='Re-process' onClick={() => 
                                        self.props.onReprocess(AusComplyDtos.ngtEntityType.Roller, self.state.fromDate, self.state.tickets)
                                        } />
                            </View>
                        </Box>
                    </Box>
                </CardContent>
            </CardContainer>
        }

        return <PageLayout
            headerText={"Roller Integration"}
            footerLeftContent={<TransparentButton text={"Refresh"} onClick={() => this.onRefresh()} />}
        >
            <View style={{ marginTop: '10px' }}>
                <Tabs selected={this.state.tab} labels={["Audit", "Settings", "Utilities"]} onClick={index => this.setState({ tab: index })}></Tabs>
            </View>
            {connection}
            {content}
            {audit}
            {utilities}
        </PageLayout>
    }
}