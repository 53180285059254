import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, VenueLocation } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import MultiSelectControl from '../../controls/MutiSelectControl';
import SelectControlGroup from '../../controls/SelectControlGroup';
import Button from '@material-ui/core/Button';
import FormWarningMessage from '../../alerts/FormWarningMessage'
import FormErrorMessage from '../../alerts/FormErrorMessage'
import { MulitSelectOption } from '../../../common/dto/common';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Map from '../../common/Map'
import Loading from '../../common/Loading';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import DefaultButton from '../../controls/DefaultButton';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';

export interface IIDScanLocationProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onUpdateLocation: Function;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    showGPSLocation?: boolean;
    theme: any;
    classes: any;
}

interface IIDScanLocationState {
    selectedId: number;
    items: MulitSelectOption[];
    selectedVenueLocationIds: number[];
    showLocation: boolean;
}

class IDScanLocation extends Component<IIDScanLocationProps, IIDScanLocationState> {
    constructor(props: IIDScanLocationProps) {
        super(props)
        this.state = {
            selectedId: 0,
            items: [],
            selectedVenueLocationIds: [],
            showLocation: false
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.toggleGPSLocation = this.toggleGPSLocation.bind(this);
        this.onMapRefresh = this.onMapRefresh.bind(this);
    }

    componentDidMount() {
        try {
            let items: MulitSelectOption[] = [];
            let selectedId: number = 0;
            let selectedVenueLocationIds: number[] = [];
            if (this.props.incident && this.props.incident.incidentLocation) {
                selectedVenueLocationIds = this.props.incident.incidentLocation.map(item => item.venueLocationId);
                if (selectedVenueLocationIds && selectedVenueLocationIds.length > 0) {
                    selectedId = selectedVenueLocationIds[0];
                }
            }
            if (this.props.incidentOptions && this.props.incidentOptions.venueLocations) {
                this.props.incidentOptions.venueLocations.forEach((item, index) => {
                    let isSelected = selectedVenueLocationIds.indexOf(item.venueLocationId) > -1;
                    items.push({
                        id: item.venueLocationId,
                        name: item.name,
                        isCommon: (item.usageCount > 0),
                        selected: isSelected
                    });
                });
            }
            this.setState({
                items: items,
                selectedVenueLocationIds,
                selectedId
            });
        } catch (err) {
            commonLogic.trackException(err, "IDScanLocation.componentDidMount", {})
        }
    }

    componentDidUpdate(preProps) {
        let selectedId: number = 0;
        let selectedVenueLocationIds: number[] = [];
        if (this.props.incident && this.props.incident.incidentLocation) {
            selectedVenueLocationIds = this.props.incident.incidentLocation.map(item => item.venueLocationId);
            if (selectedVenueLocationIds && selectedVenueLocationIds.length > 0) {
                selectedId = selectedVenueLocationIds[0];
            }
        }
        if (selectedId != this.state.selectedId) {
            this.setState({
                selectedVenueLocationIds,
                selectedId
            });
        }
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousVenueLocationIds = this.props.incident.incidentLocation.map(item => item.venueLocationId);
        let currentVenueLocationIds = items.map(item => item.id);

        // remove
        let newVenueLocations = this.props.incident.incidentLocation.filter(item => currentVenueLocationIds.indexOf(item.venueLocationId) > -1);

        // add
        items.filter(item => previousVenueLocationIds.indexOf(item.id) === -1).forEach(item => {
            newVenueLocations.push({
                incidentLocationId: 0,
                incidentId: 0,
                venueLocationId: item.id,
                venueLocation: new VenueLocation()
            });
        });
        let newIncident = { ...this.props.incident };
        newIncident.incidentLocation = newVenueLocations;
        this.props.onUpdateIncident(newIncident)
    }

    toggleGPSLocation() {
        this.setState({
            showLocation: !this.state.showLocation
        });
    }

    onMapRefresh() {
        this.props.onUpdateLocation();
    }

    render() {
        const { classes } = this.props;

        let locationDetails;
        if (this.props.isLocationLoading) {
            locationDetails = (
                <Loading />
            );
        } else if (this.props.locationError) {
            locationDetails = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormErrorMessage error={this.props.locationError || "error"}></FormErrorMessage>
                    </Grid>
                </Grid>

            );
        } else if (!this.props.locationFound) {
            locationDetails = (
                <Grid container spacing={1}>
                    <Grid item xs={12}><FormWarningMessage warning={"We could not determine your location. Please ensure location services are enabled on your device and try again or speak to your supervisor."}></FormWarningMessage>
                    </Grid>
                </Grid>

            );
        }
        else {
            locationDetails = (
                <Map latitude={this.props.incident.latitude} longitude={this.props.incident.longitude} />
            );
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Location"}
                    style={{ paddingTop: 0 }}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <SelectControlGroup text="Venue Location"
                                    defaultValue={this.state.selectedId}
                                    onChange={(value) => this.onSelectItemsChanged([{ id: value }])}
                                    labelAbove={true}>
                                    <option value={0} />
                                    {this.state.items && this.state.items.map((item) => (
                                        <option key={"location" + item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
                { this.props.showGPSLocation && (
                    <CardContainer
                        title={"GPS"}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {!this.state.showLocation && (
                                                <TransparentButton text={"Show GPS Location"} onClick={this.toggleGPSLocation} />

                                            )}
                                            {this.state.showLocation && (
                                                <>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <TransparentButton text={"Hide GPS Location"} onClick={this.toggleGPSLocation} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DefaultButton text={"Refresh Location"} onClick={this.onMapRefresh} />
                                                        </Grid>
                                                    </Grid>
                                                    {locationDetails}
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardContainer>
                )}
            </SwipeContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IDScanLocation);