import { makeActionCreator } from './helpers';

export const
    SCHEDULES_REQUEST = "SCHEDULES_REQUEST",
    SCHEDULES_SECURITYFIRMS_REQUEST = "SCHEDULES_SECURITYFIRMS_REQUEST",
    SCHEDULES_REQUEST_SUCCESS = "SCHEDULES_REQUEST_SUCCESS",
    SCHEDULES_REQUEST_FAILURE = "SCHEDULES_REQUEST_FAILURE",
    SCHEDULE_RESET = "SCHEDULE_RESET",
    SCHEDULE_SET = "SCHEDULE_SET",
    SCHEDULE_REQUEST = "SCHEDULE_REQUEST",
    SCHEDULE_REQUEST_SUCCESS = "SCHEDULE_REQUEST_SUCCESS",
    SCHEDULE_REQUEST_FAILURE = "SCHEDULE_REQUEST_FAILURE",
    SCHEDULE_CREATE_REQUEST = "SCHEDULE_CREATE_REQUEST",
    SCHEDULE_CREATE_SECURITYFIRM_REQUEST = "SCHEDULE_CREATE_SECURITYFIRM_REQUEST",
    SCHEDULE_COPY_REQUEST = "SCHEDULE_COPY_REQUEST",
    SCHEDULE_CREATE_REQUEST_SUCCESS = "SCHEDULE_CREATE_REQUEST_SUCCESS",
    SCHEDULE_CREATE_REQUEST_FAILURE = "SCHEDULE_CREATE_REQUEST_FAILURE",
    SCHEDULE_UPSERT_REQUEST = "SCHEDULE_UPSERT_REQUEST",
    SCHEDULE_UPSERT_REQUEST_SUCCESS = "SCHEDULE_UPSERT_REQUEST_SUCCESS",
    SCHEDULE_UPSERT_REQUEST_FAILURE = "SCHEDULE_UPSERT_REQUEST_FAILURE",
    SCHEDULE_PROCESS_REQUEST = "SCHEDULE_PROCESS_REQUEST",
    SCHEDULE_PROCESS_REQUEST_SUCCESS = "SCHEDULE_PROCESS_REQUEST_SUCCESS",
    SCHEDULE_PROCESS_REQUEST_FAILURE = "SCHEDULE_PROCESS_REQUEST_FAILURE",
    SCHEDULE_LOGS_REQUEST = "SCHEDULE_LOGS_REQUEST",
    SCHEDULE_LOGS_REQUEST_SUCCESS = "SCHEDULE_LOGS_REQUEST_SUCCESS",
    SCHEDULE_LOGS_REQUEST_FAILURE = "SCHEDULE_LOGS_REQUEST_FAILURE",
    SCHEDULE_LOG_DELETE_REQUEST = "SCHEDULE_LOG_DELETE_REQUEST",
    SCHEDULE_LOG_DELETE_REQUEST_SUCCESS = "SCHEDULE_LOG_DELETE_REQUEST_SUCCESS",
    SCHEDULE_LOG_DELETE_REQUEST_FAILURE = "SCHEDULE_LOG_DELETE_REQUEST_FAILURE";

export const schedulesRequest = makeActionCreator(SCHEDULES_REQUEST);
export const schedulesSecurityFirmRequest = makeActionCreator(SCHEDULES_SECURITYFIRMS_REQUEST);
export const schedulesRequestSuccess = makeActionCreator(SCHEDULES_REQUEST_SUCCESS, 'schedules');
export const schedulesRequestFailure = makeActionCreator(SCHEDULES_REQUEST_FAILURE, 'error');

    
export const scheduleReset = makeActionCreator(SCHEDULE_RESET);
export const scheduleSet = makeActionCreator(SCHEDULE_SET, 'schedule');

export const scheduleRequest = makeActionCreator(SCHEDULE_REQUEST, 'scheduleId');
export const scheduleRequestSuccess = makeActionCreator(SCHEDULE_REQUEST_SUCCESS, 'schedule', 'checklistTemplates', 'scheduleDatas');
export const scheduleRequestFailure = makeActionCreator(SCHEDULE_REQUEST_FAILURE, 'error');

export const scheduleCreateRequest = makeActionCreator(SCHEDULE_CREATE_REQUEST);
export const scheduleCreateSecurityFirmRequest = makeActionCreator(SCHEDULE_CREATE_SECURITYFIRM_REQUEST);
export const scheduleCopyRequest = makeActionCreator(SCHEDULE_COPY_REQUEST, 'scheduleId');
export const scheduleCreateRequestSuccess = makeActionCreator(SCHEDULE_CREATE_REQUEST_SUCCESS, 'schedule', 'checklistTemplates', 'scheduleDatas');
export const scheduleCreateRequestFailure = makeActionCreator(SCHEDULE_CREATE_REQUEST_FAILURE, 'error');

export const scheduleUpsertRequest = makeActionCreator(SCHEDULE_UPSERT_REQUEST, 'schedule');
export const scheduleUpsertRequestSuccess = makeActionCreator(SCHEDULE_UPSERT_REQUEST_SUCCESS, 'schedule');
export const scheduleUpsertRequestFailure = makeActionCreator(SCHEDULE_UPSERT_REQUEST_FAILURE, 'error');

export const scheduleProcessRequest = makeActionCreator(SCHEDULE_PROCESS_REQUEST, 'scheduleId');
export const scheduleProcessRequestSuccess = makeActionCreator(SCHEDULE_PROCESS_REQUEST_SUCCESS);
export const scheduleProcessRequestFailure = makeActionCreator(SCHEDULE_PROCESS_REQUEST_FAILURE, 'error');

export const scheduleLogsRequest = makeActionCreator(SCHEDULE_LOGS_REQUEST, 'scheduleId', 'paging');
export const scheduleLogsRequestSuccess = makeActionCreator(SCHEDULE_LOGS_REQUEST_SUCCESS, 'data');
export const scheduleLogsRequestFailure = makeActionCreator(SCHEDULE_LOGS_REQUEST_FAILURE, 'error');

export const scheduleLogDeleteRequest = makeActionCreator(SCHEDULE_LOG_DELETE_REQUEST, 'scheduleLogId', 'scheduleId', 'paging');
export const scheduleLogDeleteRequestSuccess = makeActionCreator(SCHEDULE_LOG_DELETE_REQUEST_SUCCESS, 'data');
export const scheduleLogDeleteRequestFailure = makeActionCreator(SCHEDULE_LOG_DELETE_REQUEST_FAILURE, 'error');