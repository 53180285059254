import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../common/PageTitle';
import PagedList from '../common/PagedList';
import DefaultButton from '../controls/DefaultButton';
import CardContent from '@material-ui/core/CardContent';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import TableContainer from '../layout/TableContainer';
import Saving from '../common/Saving';
import DuplicateMobileNumbersCard from './DuplicateMobileNumbersCard';
import DuplicateMobileNumbersHeader from './DuplicateMobileNumbersHeader';
import DuplicateMobileNumbersRow from './DuplicateMobileNumbersRow';

export interface IDuplicateMobileNumbersProps {
    isLoading: boolean;
    isSaving: boolean;
    users: AusComplyDtos.User[];
    paging: AusComplyDtos.Paging;
    search: String;
    onRefresh: Function;
    onUpdate: Function;
    classes: any;
    theme: any;
    history: any;
}

class DuplicateMobileNumbers extends Component<IDuplicateMobileNumbersProps, any> {
    constructor(props: IDuplicateMobileNumbersProps) {
        super(props)

        this.state = {
            search: ""
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onResetUserMobile = this.onResetUserMobile.bind(this);
        this.onOpen = this.onOpen.bind(this);
    }

    componentDidMount() {
        this.setState({
            search: this.props.search
        }, () => {
            this.props.onRefresh(this.props.search, this.props.paging.page, this.props.paging.pageSize);
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.setState({
                search: this.props.search
            });
        }
        if (!this.props.isSaving && prevProps.isSaving) {
            this.props.onRefresh(this.props.search, this.props.paging.page, this.props.paging.pageSize);
        }
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.search, page, this.props.paging.pageSize);
    }

    onSearchChange(value) {
        this.setState({ search: value });
    }

    onSearch() {
        this.props.onRefresh(this.state.search, 1, this.props.paging.pageSize);
    }

    onResetSearch() {
        this.props.onRefresh("", 1, this.props.paging.pageSize);
    }

    onResetUserMobile(user: AusComplyDtos.User) {
        this.props.onUpdate(user.userId, "9999999999");
    }

    onOpen(user: AusComplyDtos.User) {
        this.props.history.push('/admin/duplicatemobilenumber/' + user.userId);
    }

    render() {
        let cardList;
        let rowList;
        let rows;

        let filters;
        let lockSearchControls = this.props.isLoading;

        if (this.props.users && this.props.users.length > 0) {
            cardList = this.props.users.map((item, index) => {
                return (
                    <DuplicateMobileNumbersCard
                        onOpen={this.onOpen}
                        onUpdate={this.onResetUserMobile}
                        key={"card-" + item.userId}
                        user={item}
                    />
                );
            });

            rows = this.props.users.map((item, index) => {
                return (
                    <DuplicateMobileNumbersRow
                        onOpen={this.onOpen}
                        onUpdate={this.onResetUserMobile}
                        key={"row-" + item.userId.toString()}
                        user={item} />
                );
            });
        }

        filters = (
            <CardContainer style={{ position: 'relative' }} title={"Search"}>
                <CardContent>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <TextControlGroup text={"Search"}
                                defaultValue={this.state.search}
                                readonly={lockSearchControls}
                                onSubmit={this.onSearch}
                                onChange={this.onSearchChange}
                            ></TextControlGroup>
                        </Grid>
                        <Grid item xs={12} lg={6} style={{ textAlign: 'right' }}>
                            <DefaultButton
                                style={{ width: 'auto', height: 'auto', marginRight: '10px' }}
                                disabled={lockSearchControls}
                                onClick={this.onSearch}
                            >Search</DefaultButton>
                            <DefaultButton
                                style={{ width: 'auto', height: 'auto' }}
                                disabled={lockSearchControls}
                                onClick={this.onResetSearch}
                            >Reset</DefaultButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardContainer>
        );


        rowList = (
            <TableContainer
                header={<DuplicateMobileNumbersHeader />}
                rows={rows}
            />
        );

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title="Duplicate Users"></PageTitle>
                    <Saving />
                </>
            );
        }

        return (
            <>
                <PageTitle title="Duplicate Users"></PageTitle>
                {filters}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DuplicateMobileNumbers);