import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../common/View';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PagedList from '../../common/PagedList';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import TableContainer from '../../layout/TableContainer';
import VenuesCard from './VenuesCard';
import VenuesHeader from './VenuesHeader';
import VenuesRow from './VenuesRow';
import VenuesFilterSummary from './VenuesFilterSummary';
import VenuesFilter from './VenuesFilter';
import ConfirmDialog from '../../controls/ConfirmDialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IVenuesProps {
    isLoading: boolean;
    venues: AusComplyDtos.Venue[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.VenueFilter;
    onRefresh: Function;
    onFilterUpdate: Function;
    onObsolete: Function;
    onDelete: Function;
    onExport: Function;
    onDismissRegistration: Function;
    history: any;
}

interface IVenuesState {
    filterExpanded: boolean;
    venueToObsolete: AusComplyDtos.Venue;
    venueToDelete: AusComplyDtos.Venue;
    venueToDismiss: AusComplyDtos.Venue;
}

class Venues extends Component<IVenuesProps, IVenuesState> {
    constructor(props: IVenuesProps) {
        super(props)

        var emptyVenue = new AusComplyDtos.Venue();
        emptyVenue.venueId = 0;

        this.state = {
            filterExpanded: false,
            venueToObsolete: { ...emptyVenue },
            venueToDelete: { ...emptyVenue },
            venueToDismiss: { ...emptyVenue }
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.deleteCancel = this.deleteCancel.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.obsoleteCancel = this.obsoleteCancel.bind(this);
        this.obsoleteConfirmed = this.obsoleteConfirmed.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.dismissCancel = this.dismissCancel.bind(this);
        this.dismissConfirmed = this.dismissConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onEdit(venue: AusComplyDtos.Venue) {
        this.props.history.push('/admin/venue/' + venue.venueId);
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(venueFilter: AusComplyDtos.VenueFilter) {
        this.props.onFilterUpdate(venueFilter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let venueFilter = new AusComplyDtos.VenueFilter();
            this.props.onFilterUpdate(venueFilter);
            this.props.onRefresh(venueFilter, 1, this.props.paging.pageSize);
        });
    }

    deleteCancel() {
        var emptyVenue = new AusComplyDtos.Venue();
        emptyVenue.venueId = 0;
        this.setState({
            venueToDelete: emptyVenue
        });
    }

    deleteConfirmed() {
        if (this.props.onDelete) {
            let venueId = this.state.venueToDelete.venueId;
            this.props.onDelete(venueId);

            var emptyVenue = new AusComplyDtos.Venue();
            emptyVenue.venueId = 0;
            this.setState({
                venueToDelete: emptyVenue
            });
        }
    }

    obsoleteCancel() {
        var emptyVenue = new AusComplyDtos.Venue();
        emptyVenue.venueId = 0;
        this.setState({
            venueToObsolete: emptyVenue
        });
    }

    obsoleteConfirmed() {
        if (this.props.onDelete) {
            let venueId = this.state.venueToObsolete.venueId;
            this.props.onObsolete(venueId);

            var emptyVenue = new AusComplyDtos.Venue();
            emptyVenue.venueId = 0;
            this.setState({
                venueToObsolete: emptyVenue
            });
        }
    }

    dismissCancel() {
        var emptyVenue = new AusComplyDtos.Venue();
        emptyVenue.venueId = 0;
        this.setState({
            venueToDismiss: emptyVenue
        });
    }

    dismissConfirmed() {
        if (this.props.onDismissRegistration) {
            let venueId = this.state.venueToDismiss.venueId;
            this.props.onDismissRegistration(venueId);

            var emptyVenue = new AusComplyDtos.Venue();
            emptyVenue.venueId = 0;
            this.setState({
                venueToDismiss: emptyVenue
            });
        }
    }

    onCommand(command: string, venue: AusComplyDtos.Venue) {
        switch (command) {
            case "add":
                this.props.history.push('/register/venue/');
                break;
            case "edit":
                this.props.history.push('/admin/venue/' + venue.venueId);
                break;
            case "export":
                this.props.onExport();
                break;
            case "delete":
                var venueToDelete = { ...venue };
                this.setState({
                    venueToDelete
                });
                break;
            case "remove":
                var venueToObsolete = { ...venue };
                this.setState({
                    venueToObsolete
                });
                break;
            case "dismiss":
                var venueToDismiss = { ...venue };
                this.setState({
                    venueToDismiss
                });
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rowList;
        let rows;

        if (this.props.venues && this.props.venues.length > 0) {
            cardList = this.props.venues.map((item, index) => {
                return (
                    <VenuesCard
                        onClick={this.onEdit}
                        onCommand={this.onCommand}
                        key={"card-" + item.venueId.toString()}
                        venue={item}
                    />
                );
            });

            rows = this.props.venues.map((item, index) => {
                return (
                    <VenuesRow
                        onClick={this.onEdit}
                        onCommand={this.onCommand}
                        key={"row-" + item.venueId.toString()}
                        venue={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<VenuesHeader />}
                rows={rows}
            />
        );

        let filterSummary;
        if (this.props.isLoading) {
            let venueFilter = new AusComplyDtos.VenueFilter();
            venueFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <VenuesFilterSummary filter={venueFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <VenuesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let obsoleteVenueDetails;
        if (this.state.venueToObsolete && this.state.venueToObsolete.venueId != 0) {
            obsoleteVenueDetails = (
                <>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you wish to remove this venue"}
                    </DialogContentText>
                    <ControlGroup text="Name">
                        <TextDisplay>{this.state.venueToObsolete.name}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Address">
                        <TextDisplay>{this.state.venueToObsolete.address}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Suburb">
                        <TextDisplay>{this.state.venueToObsolete.suburb}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Postcode">
                        <TextDisplay>{this.state.venueToObsolete.postcode}</TextDisplay>
                    </ControlGroup>
                </>
            );
        }

        let dismissVenueDetails;
        if (this.state.venueToDismiss && this.state.venueToDismiss.venueId != 0) {
            dismissVenueDetails = (
                <>
                    <DialogContentText id="alert-dialog-description">
                        {"Are you sure you wish to dismiss this venue registration"}
                    </DialogContentText>
                    <ControlGroup text="Name">
                        <TextDisplay>{this.state.venueToDismiss.name}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Address">
                        <TextDisplay>{this.state.venueToDismiss.address}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Suburb">
                        <TextDisplay>{this.state.venueToDismiss.suburb}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Postcode">
                        <TextDisplay>{this.state.venueToDismiss.postcode}</TextDisplay>
                    </ControlGroup>
                </>
            );
        }

        return (
            <PageLayout
                headerText={"Venues"}
                footerLeftContent={<TransparentButton text='Add Venue' onClick={() => this.onCommand("add", new AusComplyDtos.Venue())} />}
                moreCommands={[{ key: "export", name: "Export" }]}
                onCommand={command => this.onCommand(command, new AusComplyDtos.Venue())}
            >
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenuesFilter
                    open={this.state.filterExpanded}
                    filter={this.props.filter}
                    onSearch={(filter) => {
                        this.filterExpanded(false);
                        this.props.onRefresh(filter, 1, this.props.paging.pageSize);
                    }}
                    onReset={() => this.onResetSearch()}
                    onClose={() => this.filterExpanded(false)}
                     />
                <ConfirmDialog
                    title={"Confirm delete"}
                    text={"Are you sure you wish to PERMANENTLY DELETE this venue"}
                    show={this.state.venueToDelete && this.state.venueToDelete.venueId != 0}
                    onCancel={this.deleteCancel}
                    onConfirm={this.deleteConfirmed} />
                <ConfirmDialog
                    title={"Confirm remove"}
                    dialogContent={obsoleteVenueDetails}
                    show={this.state.venueToObsolete && this.state.venueToObsolete.venueId != 0}
                    onCancel={this.obsoleteCancel}
                    onConfirm={this.obsoleteConfirmed} />
                    <ConfirmDialog
                        title={"Confirm dismiss registration"}
                        dialogContent={dismissVenueDetails}
                        show={this.state.venueToDismiss && this.state.venueToDismiss.venueId != 0}
                        onCancel={this.dismissCancel}
                        onConfirm={this.dismissConfirmed} />
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Venues);