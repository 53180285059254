import React from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';

export interface IRiskStatusProps {
    riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

export default class RiskStatus extends React.Component<IRiskStatusProps, any> {
    constructor(props: IRiskStatusProps) {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let riskStatus = { ...this.props.riskStatus };
        riskStatus[fieldName] = value;
        this.props.onUpdate(riskStatus);
    }

    render() {

        if (!this.props.riskStatus) {
            return (<></>);
        }

        let inspectionFrequency = "5";
        if (this.props.riskStatus.inspectionFrequency) {
            inspectionFrequency = this.props.riskStatus.inspectionFrequency.toString();
        }

        let rank = "0";
        if (this.props.riskStatus.rank) {
            rank = this.props.riskStatus.rank.toString();
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.riskStatus.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />

                    <TextControlGroup
                        text={"Inspection Frequency (minutes)"}
                        numericOnly={true}
                        defaultValue={inspectionFrequency}
                        onChange={value => this.onValueChanged("inspectionFrequency", value)} />

                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}
