import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import VenueLocationCard from './VenueLocationCard';
import VenueLocationHeader from './VenueLocationHeader';
import VenueLocationRow from './VenueLocationRow';
import PageMessage from '../../common/PageMessage';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import VenueLocationContainer from '../../../containers/VenueLocationContainer';
import AdminVenueLocationContainer from '../../../containers/AdminVenueLocationContainer';

export interface IVenueLocationsProps {
    isLoading: boolean;
    venueId: number;
    venueLocations: AusComplyDtos.VenueLocation[];
    venueLocationId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.VenueLocationFilter;
    canCreate: boolean;
    canViewSubLocations: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class VenueLocations extends Component<IVenueLocationsProps, any> {
    constructor(props: IVenueLocationsProps) {
        super(props)

        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    prepareFilter(filter: AusComplyDtos.VenueLocationFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.venueId = this.props.venueId;
        } else {
            preparedFilter.venueId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, venueLocation: AusComplyDtos.VenueLocation) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(venueLocation.venueLocationId);
                break;
            case "delete":
                var updated = { ...venueLocation };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.venueLocations && this.props.venueLocations.length > 0) {
            cardList = this.props.venueLocations.map((item, index) => {
                return (
                    <VenueLocationCard
                        onCommand={this.onCommand}
                        key={"card-" + item.venueLocationId.toString()}
                        venueLocation={item}
                    />
                );
            });
            rows = this.props.venueLocations.map((item, index) => {
                return (
                    <VenueLocationRow
                        onCommand={this.onCommand}
                        showIndustyCagetories={!this.props.venueId || this.props.venueId == 0}
                        key={"row-" + item.venueLocationId.toString()}
                        venueLocation={item}
                        showCameras={this.props.filter.showCameras}
                        showSubLocations={this.props.canViewSubLocations}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<VenueLocationHeader
                        showCameras={this.props.filter.showCameras}
                        showSubLocations={this.props.canViewSubLocations}
                        showIndustyCagetories={!this.props.venueId || this.props.venueId == 0} />}
                    rows={rows}
                />
            );
        }

        let venueLocation;
        //if (this.props.venueLocationId != -1) {
        if (!this.props.venueId || this.props.venueId == 0) {
            venueLocation = (
                <AdminVenueLocationContainer />
            );
        } else {
            venueLocation = (
                <VenueLocationContainer />
            );
        }
        //}

        let create;
        if (this.props.canCreate) {
            create = (
                <TransparentButton
                    onClick={() => this.props.onCreate(this.props.venueId)}
                    text={"Add Location"} />
            );
        }

        return (<PageLayout
            headerText={"Locations"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />

            {venueLocation}

        </PageLayout>);
    }
}