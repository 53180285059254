import React from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { checklistLogic } from '../../../common/logic/checklistLogic';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FixedFooter from '../../layout/FixedFooter';
import DefaultButton from '../../controls/DefaultButton';
import InfoButton from '../../controls/InfoButton';
import Chip from '@material-ui/core/Chip';
import Loading from '../../common/Loading';
import PageMessage from '../../common/PageMessage';
import Saving from '../../common/Saving';
import View from '../../common/View';
import moment from "moment";
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import ChecklistTemplateDetail from './ChecklistTemplateDetail';
import ChecklistTemplateNavigation from './ChecklistTemplateNavigation';
import TransparentButton from '../../controls/TransparentButton';
import ThemeSwitcher from '../../general/ThemeSwitcher';
import CardRow from '../../common/CardRow';
import PaperclipIcon from '../../../resources/PaperclipIcon';
import TickIcon from '../../../resources/TickIcon';
import ChecklistTemplateItem from './ChecklistTemplateItem'
import FullPageDialog from '../../layout/FullPageDialog';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    useDrag, DragSourceMonitor, DragSourceConnector, ConnectDragSource, DragSource,
    ConnectDropTarget, DropTargetMonitor,
    DropTargetConnector,
    DropTarget
} from 'react-dnd';
import { ItemTypes } from './ItemTypes';


export interface IChecklistTemplateItemDropProps {
    position: number;
    parentId?: number;
    // Collected Props
    canDrop?: boolean,
    isOver?: boolean,
    connectDropTarget: ConnectDropTarget;
    style?: any;
}


class ChecklistTemplateItemDrop extends React.Component<IChecklistTemplateItemDropProps, any> {

    constructor(props: IChecklistTemplateItemDropProps) {
        super(props)
        // console.log('ChecklistTemplateItemDrop:constructor')
    }

    componentDidMount() {
        // console.log('ChecklistTemplateItemDrop:componentDidMount')
    }

    render() {
        let content;
        if (false && !this.props.canDrop && !this.props.isOver) {
            content = (<div></div>);
        }
        else {
            const isActive = this.props.canDrop && this.props.isOver;
            let style: any = {
                color: '#333',
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: '#333',
                borderStyle: 'dotted',
                padding: '4px',
                textAlign: 'center',
                marginLeft: '10px',
                marginRight: '10px'
            };
            if (this.props.canDrop) {
                style = { ...style, borderColor: '#666', color: '#666' };
            }
            if (isActive) {
                style = { ...style, backgroundColor: 'green', color: '#fff' };
            }
            content = (
                <div style={{ ...this.props.style, ...style }}>
                    {isActive ? 'Release to position here' : 'Drag to position here'}
                </div>
            );
        }
        
        return (
            <div key={"CardDropTarget" + "-" + this.props.parentId + "-" + this.props.position} ref={this.props.connectDropTarget} >
                {content}
            </div>
        );
        /*return (
            <div ref={this.props.connectDropTarget} style={{ ...this.props.style, ...style }}>
                {isActive ? 'Release to position here' : 'Drag a to position here'}
            </div>
        );*/
    }
}

export default DropTarget(
    [ItemTypes.BOX, ItemTypes.ITEM],
    {
        drop: (props: any) => ({
            name: 'Position',
            position: props.position,
            parentId: props.parentId,
            allowedDropEffect: "any"
        }),
    },
    (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(ChecklistTemplateItemDrop)