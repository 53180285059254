import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmSubscription from '../components/admin/securityFirm/SecurityFirmSubscription'
import {
    adminSecurityFirmSubscriptionRequest,
    adminSecurityFirmSubscriptionUpdate,
    adminSecurityFirmSubscriptionSaveRequest
} from '../common/actions/admin'

const mapStateToProps = (state, props) => {
    return {
        securityFirmId: props.securityFirmId,
        isLoading: state.admin.securityFirmSubscription.isLoading,
        isSaving: state.admin.securityFirmSubscription.isSaving,
        securityFirmSubscription: state.admin.securityFirmSubscription.securityFirmSubscription,
        error: state.admin.securityFirmSubscription.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (securityFirmId) => {
            dispatch(adminSecurityFirmSubscriptionRequest(securityFirmId))
        },
        onUpdate: (securityFirmSubscription) => {
            dispatch(adminSecurityFirmSubscriptionUpdate(securityFirmSubscription))
        },
        onSave: () => {
            dispatch(adminSecurityFirmSubscriptionSaveRequest())
        }
    }
}

const AdminSecurityFirmSubscriptionContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmSubscription))

export default AdminSecurityFirmSubscriptionContainer