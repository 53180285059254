import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { incidentActionTemplateLogic } from '../../../common/logic/incidentActionTemplateLogic';

export interface IIncidentActionTemplateRowProps {
    incidentActionTemplate: AusComplyDtos.IncidentActionTemplate;
    showFunctionalAreas: boolean;
    canEdit: boolean;
    onCommand?: Function;
}

export default class IncidentActionTemplateRow extends React.Component<IIncidentActionTemplateRowProps, any> {
    constructor(props: IIncidentActionTemplateRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.incidentActionTemplate);
        }
    }

    render() {
        if (!this.props.incidentActionTemplate) {
            return (<></>);
        }

        var commands = incidentActionTemplateLogic.getCommands(this.props.incidentActionTemplate, this.props.canEdit);
        var item = this.props.incidentActionTemplate;

        return (
            <TableRow isInfo={!item.groupId && !item.venueId} isSuccess={(item.groupId != null && item.groupId > 0)} isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td>
                    <p>
                        {item.incidentCategoryTypeDisplay}
                    </p>
                </td>
                {this.props.showFunctionalAreas && <td>
                    <p>
                        {item.selectedFunctionalAreasDisplay}
                    </p>
                </td>}
            </TableRow>
        );
    }
}