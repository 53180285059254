import { makeActionCreator } from './helpers';

export const
    RADIOCHANNELS_REQUEST = "RADIOCHANNELS_REQUEST",
    RADIOCHANNELS_REQUEST_SUCCESS = "RADIOCHANNELS_REQUEST_SUCCESS",
    RADIOCHANNELS_REQUEST_FAILURE = "RADIOCHANNELS_REQUEST_FAILURE",
    RADIOCHANNELS_REFRESH = "RADIOCHANNELS_REFRESH",
    RADIOCHANNEL_CREATE_REQUEST = "RADIOCHANNEL_CREATE_REQUEST",
    RADIOCHANNEL_CREATE_REQUEST_SUCCESS = "RADIOCHANNEL_CREATE_REQUEST_SUCCESS",
    RADIOCHANNEL_CREATE_REQUEST_FAILURE = "RADIOCHANNEL_CREATE_REQUEST_FAILURE",
    RADIOCHANNEL_REQUEST = "RADIOCHANNEL_REQUEST",
    RADIOCHANNEL_REQUEST_SUCCESS = "RADIOCHANNEL_REQUEST_SUCCESS",
    RADIOCHANNEL_REQUEST_FAILURE = "RADIOCHANNEL_REQUEST_FAILURE",
    RADIOCHANNEL_UPDATE = "RADIOCHANNEL_UPDATE",
    RADIOCHANNEL_SAVE_REQUEST = "RADIOCHANNEL_SAVE_REQUEST",
    RADIOCHANNEL_SAVE_REQUEST_SUCCESS = "RADIOCHANNEL_SAVE_REQUEST_SUCCESS",
    RADIOCHANNEL_SAVE_REQUEST_FAILURE = "RADIOCHANNEL_SAVE_REQUEST_FAILURE",
    RADIOCHANNEL_RESET = "RADIOCHANNEL_RESET";


export const radioChannelsRequest = makeActionCreator(RADIOCHANNELS_REQUEST, 'page', 'pageSize', 'filter');
export const radioChannelsRequestSuccess = makeActionCreator(RADIOCHANNELS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const radioChannelsRequestFailure = makeActionCreator(RADIOCHANNELS_REQUEST_FAILURE, 'error');
export const radioChannelCreateRequest = makeActionCreator(RADIOCHANNEL_CREATE_REQUEST, 'forGroupId', 'forVenueId');
export const radioChannelCreateRequestSuccess = makeActionCreator(RADIOCHANNEL_CREATE_REQUEST_SUCCESS, 'radioChannel');
export const radioChannelCreateRequestFailure = makeActionCreator(RADIOCHANNEL_CREATE_REQUEST_FAILURE, 'error');
export const radioChannelRequest = makeActionCreator(RADIOCHANNEL_REQUEST, 'radioChannelId');
export const radioChannelRequestSuccess = makeActionCreator(RADIOCHANNEL_REQUEST_SUCCESS, 'radioChannel');
export const radioChannelRequestFailure = makeActionCreator(RADIOCHANNEL_REQUEST_FAILURE, 'error');
export const radioChannelSaveRequest = makeActionCreator(RADIOCHANNEL_SAVE_REQUEST, 'radioChannel');
export const radioChannelSaveRequestSuccess = makeActionCreator(RADIOCHANNEL_SAVE_REQUEST_SUCCESS, 'radioChannel');
export const radioChannelSaveRequestFailure = makeActionCreator(RADIOCHANNEL_SAVE_REQUEST_FAILURE, 'error');
export const radioChannelUpdate = makeActionCreator(RADIOCHANNEL_UPDATE, 'radioChannel');
export const radioChannelsRefresh = makeActionCreator(RADIOCHANNELS_REFRESH);
export const radioChannelReset = makeActionCreator(RADIOCHANNEL_RESET);