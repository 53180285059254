import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Error from '../../common/Error'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import VenueSecurityCodeContainer from '../../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../../containers/SignOnOffSummaryContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../../common/SecondaryFab';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import Information from '../../common/Information';
import WelcomeContainer from '../../../containers/WelcomeContainer';
import TransparentButton from '../../controls/TransparentButton';
import HomeStatistics from '../../home/HomeStatistics';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../common/NoRowsMessage';
import TableContainer from '../../layout/TableContainer';
import TableRow from '../../common/TableRow';

export interface IDashboardOverviewProps {
    isLoading: boolean;
    data: AusComplyDtos.ChartDetails[];
    onRefresh: Function;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

class DashboardOverview extends Component<IDashboardOverviewProps, any> {
    constructor(props: IDashboardOverviewProps) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const { classes } = this.props;

        if (this.props.isLoading) {
            return (<Loading />);
        }

        if(!this.props.data) {
            return <></>;
        }

        let rows = this.props.data.map((item, index) => {
            return (
                <TableRow key={"data_" + index.toString()}>
                    <td>
                        <p>
                            {item.name}
                        </p>
                    </td>
                    <td>
                        <p style={{ textAlign: 'right' }}>
                            {item.total}
                        </p>
                    </td>
                </TableRow>
            );
        });
        let table = (
            <TableContainer
                header={
                    <tr>
                        <th className={classes.tableHrIndicator}></th>
                        <th className={classes.tableHr}>Statistic</th>
                        <th className={classes.tableHrRight}>Total</th>
                    </tr>
                }
                rows={rows}
            />
        );

        return (
            <>
                {table}
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(DashboardOverview))