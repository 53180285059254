import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewFlagsProps {
    incident: IncidentDTO;
}

export default class IncidentViewFlags extends Component<IIncidentViewFlagsProps, any> {
    render() {
        if (!this.props.incident.incidentFlags || this.props.incident.incidentFlags.length == 0) {
            return (<></>);
        }
        let flags = this.props.incident.incidentFlags.map((item, index) => {
            return (
                <span key={index}>
                    {index > 0 && (<><br /></>)}
                    {item.incidentFlagType.name}
                    {item.fieldValue && (
                        <>
                            <br /><small>{item.incidentFlagType.fieldLabel}: {item.fieldValue}</small>
                        </>
                    )}
                </span>
            );
        }
        );
        return (
            <ControlGroup text="Flags">
                <TextDisplay>{flags}</TextDisplay>
            </ControlGroup>
        );
    }
}