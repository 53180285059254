import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TableRow from '../../common/TableRow';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IIncidentFlagTypeRowProps {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IncidentFlagTypeRow extends React.Component<IIncidentFlagTypeRowProps, any> {
    constructor(props: IIncidentFlagTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.incidentFlagType);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let incidentFlagType = { ...this.props.incidentFlagType };
                    incidentFlagType.obsolete = true;
                    this.props.onUpdate(incidentFlagType);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let incidentFlagType = { ...this.props.incidentFlagType };
                    incidentFlagType.obsolete = false;
                    this.props.onUpdate(incidentFlagType);
                }
                break;
        }
    }

    render() {
        if (!this.props.incidentFlagType) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.incidentFlagType.obsolete && this.props.onUpdate) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        return (
            <TableRow isDanger={this.props.incidentFlagType.obsolete} commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.incidentFlagType.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    {this.props.incidentFlagType.additionalField && (
                        <p>
                            {this.props.incidentFlagType.fieldLabel}
                        </p>

                    )}
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p style={{ fontStyle: 'italic', color: '#aaa' }}>
                        {this.props.incidentFlagType.industryCategoriesDisplay}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.incidentFlagType.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}