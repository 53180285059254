import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardRow from '../../common/CardRow';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IUsersCardProps {
    user: AusComplyDtos.User;
    onClick: Function;
    classes: any;
    theme: any;
}

class UsersCard extends React.Component<IUsersCardProps, any> {
    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        let verifiedTick;
        if (this.props.user.isVerified) {
            verifiedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }
        return (
            <CardRow isDanger={this.props.user.obsolete}  >
                <Grid container spacing={1} onClick={() => this.props.onClick(this.props.user)}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography><b>{this.props.user.givenName} {this.props.user.surname}</b></CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Verified</CardTitle>
                        <CardTypography>{verifiedTick}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.props.onClick(this.props.user)}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Mobile</CardTitle>
                        <CardTypography>{this.props.user.mobileNo}</CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Email</CardTitle>
                        <CardTypography>{this.props.user.email}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UsersCard);