import update from "immutability-helper";
import * as directoryActions from "../actions/directory";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";

const initialState = {
    entityType: -1,
    id: -1,
    isLoading: false,
    isSaving: false,
    showObsolete: false,
    directory: {}
};

function setIsLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading },
    });
    return newState;
}

function setIsSaving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving },
    });
    return newState;
}

function setType(state, entityType, id) {
    let newState = update(state, {
        entityType: { $set: entityType },
        id: { $set: id }
    });
    return newState;
}

function setShowObsolete(state, showObsolete) {
    let newState = update(state, {
        showObsolete: { $set: showObsolete }
    });
    return newState;
}

function setDirectory(state, directory) {
    let newState = update(state, {
        directory: { $set: directory }
    });
    return newState;
}

export default function directories(state = initialState, action) {
    switch (action.type) {
        case directoryActions.DIRECTORY_REQUEST:
            return setDirectory(setType(setIsLoading(setShowObsolete(state, action.showObsolete), true), action.entityType, action.id), {});
        case directoryActions.DIRECTORY_REQUEST_SUCCESS:
            return setIsLoading(setDirectory(state, action.directory), false);
        case directoryActions.DIRECTORY_REQUEST_FAILURE:
            return setIsLoading(state, false);
        case directoryActions.DIRECTORY_UPLOAD_REQUEST:
        case directoryActions.DIRECTORY_REMOVE_REQUEST:
        case directoryActions.DIRECTORY_UPDATE_REQUEST:
        case directoryActions.DIRECTORY_FOLDER_REQUEST:
            return setIsSaving(state, true);
        case directoryActions.DIRECTORY_UPLOAD_REQUEST_SUCCESS:
        case directoryActions.DIRECTORY_REMOVE_REQUEST_SUCCESS:
        case directoryActions.DIRECTORY_UPDATE_REQUEST_SUCCESS:
        case directoryActions.DIRECTORY_FOLDER_REQUEST_SUCCESS:
            return setIsSaving(setDirectory(state, action.directory), false);
        case directoryActions.DIRECTORY_UPLOAD_REQUEST_FAILURE:
        case directoryActions.DIRECTORY_REMOVE_REQUEST_FAILURE:
        case directoryActions.DIRECTORY_UPDATE_REQUEST_FAILURE:
        case directoryActions.DIRECTORY_FOLDER_REQUEST_FAILURE:
            return setIsSaving(state, false);
        case directoryActions.DIRECTORY_ADMIN_REQUEST:
            return setDirectory(setType(setIsLoading(setShowObsolete(state, false), true), action.entityType, 0), {});
        case directoryActions.DIRECTORY_ADMIN_REQUEST_SUCCESS:
            return setIsLoading(setDirectory(state, action.directory), false);
        case directoryActions.DIRECTORY_ADMIN_REQUEST_FAILURE:
            return setIsLoading(state, false);
        case directoryActions.DIRECTORY_ADMIN_FOLDER_REQUEST:
            return setIsSaving(state, true);
        case directoryActions.DIRECTORY_ADMIN_FOLDER_REQUEST_SUCCESS:
            return setIsSaving(setDirectory(state, action.directory), false);
        case directoryActions.DIRECTORY_ADMIN_FOLDER_REQUEST_FAILURE:
            return setIsSaving(state, false);
    }
    return state;
}