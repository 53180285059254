import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import TableContainer from '../layout/TableContainer';
import RoleTypeCard from './RoleTypeCard';
import RoleTypeRow from './RoleTypeRow';
import RoleTypeHeader from './RoleTypeHeader';
import RoleTypesFilter from './RoleTypesFilter';
import RoleTypesFilterSummary from './RoleTypesFilterSummary';
import PageTitle from '../common/PageTitle';
import FullPageDialog from '../layout/FullPageDialog';
import View from '../common/View';
import PagedList from '../common/PagedList';
import EditRoleTypeContainer from '../../containers/EditRoleTypeContainer';
import FabContainer from '../layout/FabContainer';
import DefaultFab from '../common/DefaultFab';
import AddRoleTypeContainer from '../../containers/AddRoleTypeContainer';
import DeleteRoleTypeContainer from '../../containers/AdminDeleteRoleTypeContainer';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';

export interface IRoleTypesProps {
    isLoading: boolean;
    roleTypes: AusComplyDtos.UserRoleTypeSummary[];
    canAdminEdit?: boolean;
    venueId?: number;
    securityFirmId?: number;
    filter: AusComplyDtos.UserRoleTypeFilter;
    paging: AusComplyDtos.Paging;
    onEdit?: Function;
    onRemove?: Function;
    onEnable?: Function;
    onRefresh: Function;
    onFilterUpdate: Function;
    history: any;
}

export default class RoleTypes extends Component<IRoleTypesProps, any> {

    constructor(props: IRoleTypesProps) {
        super(props)

        this.state = {
            createRoleType: false,
            aliasEditOnly: false,
            editRoleTypeId: 0,
            removeRoleTypeId: 0,
            cloneRoleTypeId: 0,
            filterExpanded: false,
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        let filter = { ... this.props.filter };

        filter['GlobalRoles'] = false;

        if (this.props.venueId) {
            filter.forVenueId = this.props.venueId;
        } else {
            filter.forVenueId = 0;
        }

        if (this.props.securityFirmId) {
            filter.forSecurityFirmId = this.props.securityFirmId;
        } else {
            filter.forSecurityFirmId = 0;
        }

        this.onUpdateFilter(filter);

        this.props.onRefresh(filter, this.props.paging.page);
    }

    onCommand(command: string, userRoleType: AusComplyDtos.UserRoleTypeSummary) {
        switch (command) {
            case "add":
                this.setState({ createRoleType: true, aliasEditOnly: false, editRoleTypeId: 0, removeRoleTypeId: 0, cloneRoleTypeId: 0 });
                break;
            case "clone":
                this.setState({ createRoleType: true, aliasEditOnly: false, editRoleTypeId: 0, removeRoleTypeId: 0, cloneRoleTypeId: userRoleType.userRoleTypeId });
                break;
            case "edit":
                this.setState({ createRoleType: false, aliasEditOnly: false, editRoleTypeId: userRoleType.userRoleTypeId, removeRoleTypeId: 0, cloneRoleTypeId: 0 });
                break;
            case "view":
                this.setState({ createRoleType: false, aliasEditOnly: true, editRoleTypeId: userRoleType.userRoleTypeId, removeRoleTypeId: 0, cloneRoleTypeId: 0 });
                break;
            case "delete":
                this.setState({ createRoleType: false, aliasEditOnly: false, editRoleTypeId: 0, removeRoleTypeId: userRoleType.userRoleTypeId, cloneRoleTypeId: 0 });
                break;
            case "close":
                this.setState({ createRoleType: false, aliasEditOnly: false, editRoleTypeId: 0, removeRoleTypeId: 0, cloneRoleTypeId: 0 });
                break;
            case "refresh":
                this.setState({ createRoleType: false, aliasEditOnly: false, editRoleTypeId: 0, removeRoleTypeId: 0, cloneRoleTypeId: 0 });
                this.props.onRefresh(this.props.filter, this.props.paging.page);
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(roleTypeFilter: AusComplyDtos.UserRoleTypeFilter) {
        roleTypeFilter['GlobalRoles'] = false;

        if (this.props.venueId) {
            roleTypeFilter.forVenueId = this.props.venueId;
        } else {
            roleTypeFilter.forVenueId = 0;
        }

        if (this.props.securityFirmId) {
            roleTypeFilter.forSecurityFirmId = this.props.securityFirmId;
        } else {
            roleTypeFilter.forSecurityFirmId = 0;
        }

        this.props.onFilterUpdate(roleTypeFilter);
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let roleTypeFilter = new AusComplyDtos.UserRoleTypeFilter();
            roleTypeFilter['GlobalRoles'] = false;

            if (this.props.venueId) {
                roleTypeFilter.forVenueId = this.props.venueId;
            } else {
                roleTypeFilter.forVenueId = 0;
            }

            if (this.props.securityFirmId) {
                roleTypeFilter.forSecurityFirmId = this.props.securityFirmId;
            } else {
                roleTypeFilter.forSecurityFirmId = 0;
            }
            this.props.onFilterUpdate(roleTypeFilter);
            this.props.onRefresh(roleTypeFilter, 1, this.props.paging.pageSize);
        });
    }

    render() {
        let cardList;
        let rows;

        if (this.props.roleTypes && this.props.roleTypes.length > 0) {
            cardList = this.props.roleTypes.map((item) => {
                return (
                    <RoleTypeCard
                        onCommand={this.onCommand}
                        key={"role-type-card-" + item.userRoleTypeId.toString()}
                        roleType={item}
                    />
                );
            });

            rows = this.props.roleTypes.map((item) => {
                return (
                    <RoleTypeRow
                        onCommand={this.onCommand}
                        key={"role-type-row-" + item.userRoleTypeId.toString()}
                        roleType={item}
                    />
                );
            });
        }

        let rowList = (
            <TableContainer
                header={<RoleTypeHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <RoleTypesFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let userFilter = new AusComplyDtos.UserRoleTypeFilter();
            userFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <RoleTypesFilterSummary filter={userFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <RoleTypesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (
            <PageLayout
                headerText={"Role Types"}
                footerLeftContent={<TransparentButton text="Add Role Type" onClick={() => this.onCommand("add", new AusComplyDtos.UserRoleTypeSummary())} />}
            >
                {filterSummary}
                <PagedList
                    loading={false}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <FullPageDialog 
                    open={this.state.filterExpanded} 
                    title="Search"
                    notFullWidth={true}
                    onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editRoleTypeId != 0}
                    title={"Edit Role Type"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleTypeSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditRoleTypeContainer
                            roleTypeId={this.state.editRoleTypeId}
                            venueId={this.props.venueId}
                            securityFirmId={this.props.securityFirmId}
                            aliasEditOnly={this.state.aliasEditOnly}
                            onClose={() => this.onCommand("refresh", new AusComplyDtos.UserRoleTypeSummary())}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleTypeSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.createRoleType != 0}
                    title={"Add Role Type"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleTypeSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <AddRoleTypeContainer
                            cloneRoleTypeId={this.state.cloneRoleTypeId}
                            venueId={this.props.venueId}
                            securityFirmId={this.props.securityFirmId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleTypeSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeRoleTypeId != 0}
                    title={"Remove Role Type"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleTypeSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.removeUserRoleId != 0) && (
                        <DeleteRoleTypeContainer
                            roleTypeId={this.state.removeRoleTypeId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleTypeSummary())} />
                    )}
                </FullPageDialog>
            </PageLayout>
        );

        return (<></>);
    }
}