import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import LabelAlert from '../../common/LabelAlert';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import Thumbnail from '../../common/Thumbnail';
import InmatePhoto from './InmatePhoto';
import Box from '@material-ui/core/Box';

export interface IInmateNotesProps {
    correctionalFacilityInMateNotes: AusComplyDtos.CorrectionalFacilityInMateNote[];
    theme: any;
}

class InmateNotes extends Component<IInmateNotesProps, any> {
    constructor(props: IInmateNotesProps) {
        super(props)
    }

    render() {
        const { theme } = this.props;

        if (!this.props.correctionalFacilityInMateNotes ||
            this.props.correctionalFacilityInMateNotes.length == 0) {
            return <></>;
        }

        return (
            <CardContainer style={{ position: 'relative' }} title={"Notes"}>
                <CardContent style={{padding: '16px'}}>
                    {this.props.correctionalFacilityInMateNotes.map((note, index) =>
                        <Box key={"note-" + note.correctionalFacilityInMateNoteId} p={0} display="flex" flexDirection={'row'} style={{ borderTop: index == 0 ? 'none' : '1px dotted #545454' }}>
                            <Box flexGrow={1} p={0}>
                                <p style={{ margin: '0', padding: '10px' }}>{note.note}</p>
                            </Box>
                            <Box p={0}>
                                {note.important && (
                                    <LabelAlert text={"Important"} />
                                )}
                            </Box>
                        </Box>
                    )}
                </CardContent>
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(InmateNotes);