import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../../controls/PrimaryButton';
import DefaultButton from '../../../controls/DefaultButton';
import LayoutForm from '../../../layout/LayoutForm';
import SelectControlGroup from '../../../controls/SelectControlGroup';
import TextControlGroup from '../../../controls/TextControlGroup';
import CheckboxControlGroup from '../../../controls/CheckboxControlGroup';

export interface IDeleteRoleTypeProps {
    roleTypeId: number,
    isLoading: boolean;
    roleType: AusComplyDtos.UserRoleType;
    isDeleting: boolean;
    error: string;
    onSuccess: Function;
    onLoad: Function;
    onDelete: Function;
    theme: any;
    classes: any;
}

export default class DeleteRoleType extends Component<IDeleteRoleTypeProps, any> {
    constructor(props: IDeleteRoleTypeProps) {
        super(props)
        this.state = {
            error: {}
        }
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.roleTypeId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            
            this.setState({
                error: {},
            });
        }
        if (!this.props.isDeleting && prevProps.isDeleting && !this.props.error && this.props.onSuccess) {
            this.setState({ error: {} });
            this.props.onSuccess();
        }
    }

    onDelete() {
        this.props.onDelete(this.props.roleType.userRoleTypeId);
    }

    render() {
        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isDeleting}
                savingMessage={'Deleting...'}
                error={this.props.error}
            >
                <form>
                    {this.props.roleType && (
                        <>
                            <TextControlGroup 
                                text={"Name"} 
                                defaultValue={this.props.roleType.name}
                                readonly={true} />
                            <SelectControlGroup
                                text={"Access Level"}
                                readonly={true}
                                defaultValue={this.props.roleType.accessLevel}>
                                    <option value={'1'}>{"Level 1 (staff)"}</option>
                                    <option value={'2'}>{"Level 2 (manager)"}</option>
                                    <option value={'3'}>{"Level 3 (head manager)"}</option>
                                    <option value={'4'}>{"Level 4 (owner)"}</option>
                                    <option value={'5'}>{"Level 5 (admin/group)"}</option>
                                    <option value={'6'}>{"Level 6 (central admin)"}</option>
                            </SelectControlGroup>
                            <CheckboxControlGroup
                                text={"Security Firm Role"}
                                defaultValue={this.props.roleType.security}
                                readonly={true} />
                            <CheckboxControlGroup
                                text={"Venue Role"}
                                defaultValue={this.props.roleType.venue}
                                readonly={true} />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"Delete"} onClick={this.onDelete}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onSuccess()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>
            </LayoutForm>
        );
    }
}