import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IVenueLocationsProps {
    externalEvent: AusComplyDtos.ExternalEvent;
    onUpdate: Function;
}

interface IVenueLocationsState {
    items: MulitSelectOption[];
}

export default class VenueLocations extends Component<IVenueLocationsProps, IVenueLocationsState> {
    constructor(props: IVenueLocationsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.externalEvent) !== JSON.stringify(this.props.externalEvent)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let venueLocationIds: number[] = [];
        if (this.props.externalEvent && this.props.externalEvent.externalEventVenueLocations) {
            venueLocationIds = this.props.externalEvent.externalEventVenueLocations.filter(f => !f.obsolete).map(item => item.venueLocationId);
        }
        if (this.props.externalEvent.venueLocations) {
            this.props.externalEvent.venueLocations.map(item => {
                let isSelected = venueLocationIds.indexOf(item.venueLocationId) > -1;
                items.push({
                    id: item.venueLocationId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.externalEvent.externalEventVenueLocations.map(item => item.venueLocationId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.externalEvent.externalEventVenueLocations.filter(item => currentIds.indexOf(item.venueLocationId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ExternalEventVenueLocation = new AusComplyDtos.ExternalEventVenueLocation();
            newItem.venueLocationId = item.id;
            newItems.push(newItem);
        });
        let externalEvent = { ...this.props.externalEvent };
        externalEvent.externalEventVenueLocations = newItems;
        this.props.onUpdate(externalEvent);
    }

    onChanged(fieldName: string, value) {
        let externalEvent = { ...this.props.externalEvent };
        externalEvent[fieldName] = value;
        this.props.onUpdate(externalEvent)
    }

    render() {

        return (
            <MultiSelectControl
                cardTitle={"Locations"}
                wrapControlInCard={true}
                items={this.state.items}
                label={""}
                searchText="Type at least three letters..."
                minimumCharactersRequired={3}
                onSelectItemsChanged={this.onSelectItemsChanged}
                canShowAll={true} />
        );
    }
}