import React, { Component } from 'react';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IInmateCellIncidentsProps {
    incidents: IncidentDTO[];
    history: any;
    classes: any;
    theme: any;
}

class InmateCellIncidents extends Component<IInmateCellIncidentsProps, any> {
    constructor(props: IInmateCellIncidentsProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(incident: IncidentDTO){
        if(incident.completed) {
            this.props.history.push('/incidentview/' + incident.incidentId);
        } else {
            this.props.history.push('/incident/' + incident.incidentId);
        }
    }

    render() {
        if (!this.props.incidents || this.props.incidents.length === 0) {
            return (<></>);
        }
        let incidents = this.props.incidents.map((item, index) => <span  key={index}>{index > 0 && (<br />)}<a href="#" onClick={e=> {this.onClick(item); e.preventDefault(); } } style={{ color: '#03a9f4', textDecoration: 'underline'}}>{item.details}</a></span>);
        
        return (
            <ControlGroup text="Inspections">
                <TextDisplay>{incidents}</TextDisplay>
            </ControlGroup>
        );
    }
}


export default withStyles(styles, { withTheme: true })(InmateCellIncidents);