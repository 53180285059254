import { connect } from 'react-redux'
import Venue from '../components/admin/venue/Venue'
import { withRouter } from "react-router";

const mapStateToProps = (state, props) => {
    return {
        venueId: props.match.params.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const AdminVenueContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Venue))

export default AdminVenueContainer