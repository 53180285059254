import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const incidentServiceApi = {
  createIncident: (venueId: number, securityFirmId: number, venueEventId: number, incidentTypeId: number, entryPage: string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncident();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.includeOptions = true;
    query.incidentTypeId = incidentTypeId;
    query.entryPage = entryPage;
    query.id = 0
    const request = client.get(query);
    return from(request);
  },
  getIncidentHistoryFilter: (venueId: number, securityFirmId: number, venueEventId: number, reset: boolean, requiringApprovals: boolean, forEntryPage: string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentHistoryFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.reset = reset;
    query.requiringApprovals = requiringApprovals;
    query.forEntryPage = forEntryPage;
    const request = client.get(query);
    return from(request);
  },
  getIncidentHistory: (venueId: number, securityFirmId: number, venueEventId: number, filter: AusComplyDtos.IncidentHistoryFilter, saveFilter: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentHistory();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.filter = filter;
    query.saveFilter = saveFilter;
    const request = client.post(query);
    return from(request);
  },
  approveIncidents: (venueId: number, securityFirmId: number, venueEventId: number, incidents: AusComplyDtos.Incident[]) => {
    let incidentIds: number[] = [];
    incidents.forEach(incident => {
      if (incident.selected) {
        incidentIds.push(incident.incidentId);
      }
    });
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.ApproveIncidents();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.incidentIds = incidentIds;
    const request = client.post(query);
    return from(request);
  },
  saveIncident: (venueId: number, securityFirmId: number, venueEventId: number, incident: AusComplyDtos.Incident, isDraft: boolean, approve: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SaveIncident();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.incident = incident;
    query.isDraft = isDraft;
    query.approve = approve;
    const request = client.post(query);
    return from(request);
  },
  loadIncident: (venueId: number, securityFirmId: number, venueEventId: number, incidentId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncident();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.includeOptions = true;
    query.id = incidentId;
    query.incidentTypeId = 0;
    const request = client.get(query);
    return from(request);
  },
  loadIncidentChecklists: (venueId: number, securityFirmId: number, venueEventId: number, incident: AusComplyDtos.Incident) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentChecklists();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueEventId = venueEventId;
    query.incident = incident;
    const request = client.post(query);
    return from(request);
  },
  getIncidentVersions: (venueId: number, securityFirmId: number, venueEventId: number, incidentId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentVersions();
    query.venueId = venueId;
    if (securityFirmId && securityFirmId  != 0) {
      query.securityFirmId = securityFirmId;
    }
    query.venueEventId = venueEventId;
    query.incidentId = incidentId;
    const request = client.get(query);
    return from(request);
  },
  getIncidentVersion: (venueId: number, securityFirmId: number, venueEventId: number, incidentId: number, incidentRelaventId: number, version: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetIncidentVersion();
    query.venueId = venueId;
    if (securityFirmId && securityFirmId  != 0) {
      query.securityFirmId = securityFirmId;
    }
    query.venueEventId = venueEventId;
    if (!isNaN(incidentId)) {
      query.incidentId = incidentId;
    }
    query.incidentRelaventId = incidentRelaventId;
    query.version = version;
    const request = client.get(query);
    return from(request);
  },
  incidentsClearApprovals: request.makePost(
      AusComplyDtos.PostClearIncidentApprovals,
      'venueId', 'securityFirmId', 'venueEventId', 'forVenueId', 'simpleDate')
  ,
  getSnapshotSummary: request.makeGet(
    AusComplyDtos.GetSnapshotSummary,
    'venueId', 'securityFirmId', 'venueEventId'
  ),
  buildDocument: request.makePost(
    AusComplyDtos.PostIncidentBuildDocument,
    'venueId', 'securityFirmId', 'venueEventId', 'incidentId', 'incidentCategoryTypeId'
  )
};
