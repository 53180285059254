import React, { Component } from 'react';
import UserContainer from '../../containers/UserContainer'

export interface IVenueUser {
    venueId: number;
    userId: number;
}

export default class VenueUser extends Component<any, any> {
    render() {
        return (
            <UserContainer venueId={this.props.venueId} userId={this.props.userId} />
        );
    }
}