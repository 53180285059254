import { connect } from 'react-redux'
import RoleTypes from '../components/roleTypes/RoleTypes'
import { withRouter } from "react-router";
 import { 
    roleTypesRequest, 
    roleTypesFilterSet
 } from '../common/actions/userRole';

const mapStateToProps = state => {
    return {
        isLoading: state.userRole.roleTypes.isLoading,
        roleTypes: state.userRole.roleTypes.roleTypes,
        venueId: state.user.details.userSession.user.venueId,
        paging: state.userRole.roleTypes.paging,
        filter: state.userRole.roleTypes.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(roleTypesRequest(filter, page, pageSize))
        },
        onFilterUpdate:(filter) => {
            dispatch(roleTypesFilterSet(filter))
        }
    }
}

const VenueRoleTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleTypes))

export default VenueRoleTypesContainer