import React, { Component } from 'react';

export interface ILogoLight {
    style?: any;
}

export default class LogoLight extends Component<ILogoLight, any> {
    render() {
        return (
            <img src={require('../../resources/LogoLight.png')} style={this.props.style} alt="logo" />
        );
    }
} 