import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmAssociation from '../components/venue/securityFirmAssociation/SecurityFirmAssociation';
import { 
    venueSecurityAssociationReset,
    venueSecurityAssociationSet,
    venueSecurityAssociationInsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.venueSecurityFirmAssociation.isLoading,
        isSaving: state.venue.venueSecurityFirmAssociation.isSaving,
        venueSecurityFirm: state.venue.venueSecurityFirmAssociation.venueSecurityFirm,
        securityFirms: state.venue.venueSecurityFirmAssociation.securityFirms,
        open: props.open,
        onClosed: props.onClosed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueSecurityAssociationReset())
        },
        onUpdate: (venueSecurityFirm) => {
            dispatch(venueSecurityAssociationSet(venueSecurityFirm))
        },
        onSave: () => {
            dispatch(venueSecurityAssociationInsertRequest())
        }
    }
}

const VenueSecurityFirmAssociationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmAssociation))

export default VenueSecurityFirmAssociationContainer