import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import * as venueEventActions from '../actions/venueEvent';
import { notifyError } from './common';
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as apiRequest from "../services/common";

const venueEventsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            apiRequest.post(AusComplyDtos.PostVenueEvents, 'page', 'pageSize', 'filter')(state, action)
                .pipe(
                    map(response => venueEventActions.venueEventsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueEventsRequestEpic.find", venueEventActions.venueEventsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueEventsRequestEpic", venueEventActions.venueEventsRequestFailure))
    );

const venueEventsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENTS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            apiRequest.post(AusComplyDtos.PostVenueEvents, 'page', 'pageSize', 'filter')
            (
                state,
                {
                    "page": state.venueEvent.venueEvents.paging.page,
                    "pageSize": state.venueEvent.venueEvents.paging.pageSize,
                    "filter": state.venueEvent.venueEvents.filter
                })
                .pipe(
                    map(response => venueEventActions.venueEventsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "venueEventsRefreshEpic.find", venueEventActions.venueEventsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueEventsRefreshEpic", venueEventActions.venueEventsRequestFailure))
    );

const venueEventRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            apiRequest.get(AusComplyDtos.GetVenueEvent, 'id')(state, { "id": action['venueEventId'] })
                .pipe(
                    map(response => venueEventActions.venueEventRequestSuccess(response.venueEvent)),
                    catchError(error => notifyError(error, "venueEventRequestEpic.get", venueEventActions.venueEventRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueEventRequestEpic", venueEventActions.venueEventRequestFailure))
    );

const venueEventCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            apiRequest.post(AusComplyDtos.PostVenueEventNew)(state, action)
                .pipe(
                    map(response => venueEventActions.venueEventCreateRequestSuccess(response.venueEvent)),
                    catchError(error => notifyError(error, "venueEventCreateEpic.create", venueEventActions.venueEventCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueEventCreateEpic", venueEventActions.venueEventCreateRequestFailure))
    );

const venueEventSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENT_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            apiRequest.post(AusComplyDtos.PostVenueEvent, 'venueEvent')
            (state,{ "venueEvent": state.venueEvent.venueEvent.venueEvent })
                .pipe(
                    map(response => venueEventActions.venueEventSaveRequestSuccess(response.venueEvent)),
                    catchError(error => notifyError(error, "venueEventUpsertEpic.upsert", venueEventActions.venueEventSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueEventUpsertEpic", venueEventActions.venueEventSaveRequestFailure))
    );

const venueEventSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => venueEventActions.venueEventReset())
    );

const venueEventSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(venueEventActions.VENUEEVENT_SAVE_REQUEST_SUCCESS),
        map((action: any) => venueEventActions.venueEventsRefresh())
    );

export const venueEventEpics = combineEpics(
    venueEventsRefreshEpic,
    venueEventsRequestEpic,
    venueEventRequestEpic,
    venueEventCreateEpic,
    venueEventSaveEpic,
    venueEventSaveSuccessEpic,
    venueEventSaveSuccessRefreshEpic
);