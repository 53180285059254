import { connect } from 'react-redux'
import EditRoleType from '../components/roleTypes/editRoleType/EditRoleType'
import { roleTypeUpdate, roleTypeAdd, roleTypeAddSave  } from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        roleTypeId: props.cloneRoleTypeId,
        isLoading: state.userRole.roleType.isLoading,
        isNewRoleType: true,
        roleType: state.userRole.roleType.roleType,
        availableUserPermissions: state.userRole.roleType.availableUserPermissions,
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        isSaving: state.userRole.isSaving,
        error: state.userRole.error,
        onSuccess: props.onSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (cloneRoleTypeId) => {
            dispatch(roleTypeAdd(cloneRoleTypeId))
        },
        onChange: (roleType) => {
            dispatch(roleTypeUpdate(roleType))
        },
        onSave: (roleType, venueId, securityFirmId) => {
            dispatch(roleTypeAddSave(roleType, true, venueId, securityFirmId))
        }
    }
}

const AddRoleTypeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditRoleType)

export default AddRoleTypeContainer