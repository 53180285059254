import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../common/Loading';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PrimaryButton from '../controls/PrimaryButton';
import ButtonLink from '../controls/ButtonLink';
import TextControl from '../controls/TextControl';
import PasswordControl from '../controls/PasswordControl';
import CardContainer from '../common/CardContainer';
import Box from '@material-ui/core/Box';
import Logo from '../../resources/Logo';
import AppStoreIcon from '../../resources/AppStoreIcon';
import { appSource } from '../../utilities/constants';
import { Palette } from '../../common/constants/palette';

export interface ILoginProps {
  username: string;
  userIsLoading: boolean;
  onAttemptLogin: Function;
  onUserChanged: Function;
  theme: any;
  classes: any;
}

const TermsLink = forwardRef((props, ref) => <Link to="/terms" {...props} />);
const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/privacypolicy" {...props} />);
const ForgotPasswordLink = forwardRef((props, ref) => <Link to="/forgotpassword" {...props} />);
const RegisterUserLink = forwardRef((props, ref) => <Link to="/register/user" {...props} />);
const RegisterVenueLink = forwardRef((props, ref) => <Link to="/register/user?for=venue" {...props} />);
const RegisterSecurityFirmLink = forwardRef((props, ref) => <Link to="/register/user?for=securityfirm" {...props} />);

class Login extends Component<ILoginProps, any> {
  constructor(props: ILoginProps) {
    super(props)

    this.state = {
      username: props.username,
      password: ""
    }
    this.onLogin = this.onLogin.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onLogin() {
    // if the username has changes, then reset the user
    if (this.props.username !== this.state.username) {
      this.props.onUserChanged(this.state.username);
    }
    this.props.onAttemptLogin(this.state.username, this.state.password);
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.onLogin();
      event.preventDefault();
    }
  }

  render() {
    let display;
    const { classes } = this.props;
    let baseURL = appSource.getBaseImagesPath();
    if (baseURL == '/') {
      baseURL = window.location.origin;
    }

    if (this.props.userIsLoading) {
      display = (
        <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Box p={0} flexGrow={1}></Box>
          <Box p={0}>
            <Loading />
          </Box>
          <Box p={0} flexGrow={1}></Box>
        </Box>
      );
    } else {
      display = (
        <>
          <Box display="flex" flexDirection="row" justifyContent="center" style={{ position: 'absolute', top: '30px', left: 0, width: '100%', zIndex: -1 }}>
            <Box p={0} flexGrow={1}></Box>
            <Box p={0}>
              <img src={require('../../resources/Background.png')} style={{ width: '340px' }} alt="backdrop" />
            </Box>
            <Box p={0} flexGrow={1}></Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <Box p={0} flexGrow={1}></Box>
            <Box p={0}>
              <form noValidate autoComplete="off" style={{ margin: '20px' }} >

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={1} md={2} lg={4}></Grid>
                  <Grid item xs={12} sm={10} md={8} lg={4} style={{ textAlign: 'center' }}>
                    <Logo fill={'#fff'} style={{ width: '80%', height: 'auto' }} />
                    <h3 className={classes.loginTitle}>Digital Incident Register</h3>
                    <h5 className={classes.loginSubTitle}>for the Web</h5>
                    <CardContainer style={{ padding: '10px 20px 10px 20px', margin: '10px 0 10px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextControl
                            value={this.state.username}
                            placeholder="USERNAME OR MOBILE #"
                            center={true}
                            onChange={(value) => this.setState({ username: value })}
                          //id={"e" + Math.random().toString(36).slice(2)}
                          //autoComplete={"a" + Math.random().toString(36).slice(2)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PasswordControl
                            value={this.state.password}
                            placeholder="PASSWORD"
                            onSubmit={this.onLogin}
                            center={true}
                            onChange={(value) => this.setState({ password: value })}
                          //id={"p" + Math.random().toString(36).slice(2)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <PrimaryButton text="Login" onClick={this.onLogin} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                          <ButtonLink style={{ color: Palette.SecondaryText }} text="Forgot Password?" small={true} component={ForgotPasswordLink} />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                          <ButtonLink style={{ color: Palette.SecondaryText }} text="Register User" small={true} component={RegisterUserLink} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                          <ButtonLink style={{ color: Palette.SecondaryText }} text="Register Venue" small={true} component={RegisterVenueLink} />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                          <ButtonLink style={{ color: Palette.SecondaryText }} text="Register Security Firm" small={true} component={RegisterSecurityFirmLink} />
                        </Grid>
                      </Grid>
                    </CardContainer>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <Box p={0} flexGrow={1}></Box>
                      <Box p={0}>
                        <div style={{ width: '156px', textAlign: 'left' }}>
                          <div style={{ height: '40px', margin: '10px' }}>
                            <a target="_blank" href='https://apps.apple.com/au/app/id1477790057'>
                              <AppStoreIcon />
                            </a>
                          </div>
                        </div>
                      </Box>
                      <Box p={0} flexGrow={1}></Box>
                      <Box p={0}>
                        <div style={{ width: '156px', textAlign: 'right' }}>
                          <a target="_blank" href='https://play.google.com/store/apps/details?id=com.auscomplynative&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img height="60px" alt='Get it on Google Play' src={require('../../resources/google-play-badge.png')} />
                          </a>
                        </div>
                      </Box>
                      <Box p={0} flexGrow={1}></Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <Box p={0}>
                        <ButtonLink text="Terms of Use" small={true} component={TermsLink} />
                      </Box>
                      <Box p={1}>
                        <span style={{ color: Palette.Border, textShadow: '#fff' }}>|</span></Box>
                      <Box p={0}>
                        <ButtonLink text="Privacy Policy" small={true} component={PrivacyPolicyLink} />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

              </form>
            </Box>
            <Box p={0} flexGrow={0}></Box>
          </Box>

        </>
      );
    }
    return display;
  }
}

export default withStyles(styles, { withTheme: true })(Login);