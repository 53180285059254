import React from "react";

const CameraIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 33 28.7"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M30.9,24.2c0,1.2-1,2.3-2.3,2.3H4.4c-1.2,0-2.3-1.1-2.3-2.3V9.9c0-1.2,1-2.3,2.3-2.3h3.8 c0.4,0,0.7-0.2,0.9-0.5l3.4-5h7.8l3.3,4.9c0.2,0.3,0.5,0.5,0.9,0.5l4.1,0c1.2,0,2.3,1,2.3,2.3L30.9,24.2L30.9,24.2z M28.6,5.5 l-3.5,0l-3.3-5C21.6,0.2,21.3,0,20.9,0h-9c-0.4,0-0.7,0.2-0.9,0.5l-3.4,5l-3.2,0C2,5.5,0,7.5,0,9.9v14.3c0,2.4,2,4.5,4.4,4.5h24.2 c2.4,0,4.4-2,4.4-4.5V9.9C33,7.5,31.1,5.5,28.6,5.5" />
                <path fill={fill} d="M16.6,21.4c-3.1,0-5.5-2.5-5.5-5.5c0-3.1,2.5-5.5,5.5-5.5s5.5,2.5,5.5,5.5C22.2,18.9,19.7,21.4,16.6,21.4 M16.6,8.1c-4.3,0-7.7,3.5-7.7,7.7c0,4.2,3.5,7.7,7.7,7.7s7.7-3.5,7.7-7.7C24.3,11.6,20.9,8.1,16.6,8.1" />
                <path fill={fill} d="M26.9,9.8c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C28.5,10.5,27.8,9.8,26.9,9.8" />
            </g>
        </svg>
    );

export default CameraIcon;
