import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';
import LayoutForm from '../layout/LayoutForm';
import TextControlGroup from '../controls/TextControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import { MulitSelectOption } from '../../common/dto/common';
import MultiSelectControl from '../controls/MutiSelectControl';
import FullPageDialog from '../layout/FullPageDialog';

export interface ISignOnRegisterTypeProps {
    signOnRegisterType: AusComplyDtos.SignOnRegisterType;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ISignOnRegisterTypeState {
    industryCategories: MulitSelectOption[];
}

export default class SignOnRegisterType extends Component<ISignOnRegisterTypeProps, ISignOnRegisterTypeState> {
    constructor(props: ISignOnRegisterTypeProps) {
        super(props)
        this.state = {
            industryCategories: []
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onSelectIndustryCategoriesChanged = this.onSelectIndustryCategoriesChanged.bind(this);
    }

    componentDidMount() {
        this.loadSelections(this.props.signOnRegisterType);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.loadSelections(this.props.signOnRegisterType);
        }
    }

    loadSelections(signOnRegisterType: AusComplyDtos.SignOnRegisterType) {
        let industryCategories: MulitSelectOption[] = [];
        if (signOnRegisterType && signOnRegisterType.industryCategories) {
            signOnRegisterType.industryCategories.forEach((industryCategory, index) => {
                let isSelected = false;
                let info = "";
                isSelected = signOnRegisterType.selectedSignOnRegisterTypeIndustryCategories.indexOf(industryCategory.industryCategoryId) > -1;
                industryCategories.push({
                    id: industryCategory.industryCategoryId,
                    name: industryCategory.name,
                    isCommon: false,
                    selected: isSelected,
                    info
                });
            });
        }
        this.setState({
            industryCategories
        }, () => { });
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let signOnRegisterType = { ...this.props.signOnRegisterType };
        signOnRegisterType[fieldName] = value;
        this.props.onUpdate(signOnRegisterType)
    }

    onSelectIndustryCategoriesChanged(items) {
        let signOnRegisterType = { ...this.props.signOnRegisterType };
        signOnRegisterType.selectedSignOnRegisterTypeIndustryCategories = items.map(item => item.id);
        this.props.onUpdate(signOnRegisterType);
    }

    render() {

        if (!this.props.signOnRegisterType) {
            return (<></>);
        }
        const show: boolean = this.props.signOnRegisterType ? this.props.signOnRegisterType.signOnRegisterTypeId != -1 : false;

        return <FullPageDialog
            open={show}
            title="Sign On Register Type"
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
            footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
            notFullWidth={true}
            onDismissed={() => this.onCancel()}>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.signOnRegisterType.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />
                    <MultiSelectControl
                        cardTitle={"Industry Categories"}
                        wrapControlInCard={true}
                        wrapInControlGroup={true}
                        items={this.state.industryCategories}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectIndustryCategoriesChanged}
                        canShowAll={true} />
                    <CheckboxControlGroup
                        text={"Active"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.signOnRegisterType.obsolete}
                        onChanged={(value) => this.onValueChanged("obsolete", value)} />
        </FullPageDialog>

    }
}