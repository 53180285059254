import React from 'react';
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import FormErrorMessage from '../alerts/FormErrorMessage';
import FormAlternateMessage from '../alerts/FormAlternateMessage';
import Grid from '@material-ui/core/Grid';
import Media from "react-media";

export interface ILayoutFormProps {
    loading?: boolean;
    saving?: boolean;
    savingMessage?: string;
    error?: string;
    header?: any;
    alternateMessage?: any;
    extraMessages?: any;
    fullWidth?: boolean;
}

class LayoutForm extends React.PureComponent<ILayoutFormProps, any> {
    render() {
        let content;
        if (this.props.saving) {
            content = <Saving message={this.props.savingMessage} />;
        } else if (this.props.loading) {
            content = <Loading />;
        } else if (this.props.alternateMessage) {
            content = <FormAlternateMessage>{this.props.alternateMessage}</FormAlternateMessage>;
        } else {
            content = this.props.children;
        }
        let details = (
            <div>
                {this.props.header}
                <div>
                    <FormErrorMessage error={this.props.error} />
                    {this.props.extraMessages}
                    {content}
                </div>
            </div>
        );
        if (this.props.fullWidth) {
            return (<>
                {details}
            </>);
        }
        return (
            <Media query="(max-width: 799px)">
                {matches =>
                    matches ? details : (
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={3}></Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                {details}
                            </Grid>
                            <Grid item xs={12} sm={12} md={1} lg={12} xl={3}></Grid>
                        </Grid>
                    )
                }
            </Media>
        );
    }
}

export default LayoutForm