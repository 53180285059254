import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import { PickerItem } from '../../common/PickerItem';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import Paragraph from '../../common/Paragraph';
import RadioControl from '../../controls/RadioControl';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup'
import SelectControlGroup from '../../controls/SelectControlGroup';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface ICheckListsFilterProps {
    filter: AusComplyDTOs.ChecklistFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface ICheckListsFilterState {
    filter: AusComplyDTOs.ChecklistFilter;
}

export default class CheckListsFilter extends React.Component<ICheckListsFilterProps, ICheckListsFilterState> {
    constructor(props: ICheckListsFilterProps) {
        super(props);
        this.onFilterByOptionChanged = this.onFilterByOptionChanged.bind(this);
        this.onSelectedReportingPeriodChanged = this.onSelectedReportingPeriodChanged.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onStatusIdsChanged = this.onStatusIdsChanged.bind(this);
        this.removedChanged = this.removedChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.ChecklistFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onFilterByOptionChanged(value) {
        let filter = { ... this.state.filter };
        filter.byReportingPeriod = value.value == "1";
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onSelectedReportingPeriodChanged(value: any) {
        let filter = { ... this.state.filter };
        filter.reportingPeriodId = parseInt(value.toString());
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onFromChanged(value) {
        let filter = { ...this.state.filter };
        filter.from = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onToChanged(value) {
        let filter = { ...this.state.filter };
        filter.to = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onSearchChange(value) {
        let filter = { ... this.state.filter };
        filter.search = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onStatusIdsChanged(value) {
        var selected: number[] = [];
        value.forEach(item => {
            selected.push(item.id);
        });
        
        let filter = { ... this.state.filter };
        filter.checkListStatusIds = selected;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    removedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let reportingPeriods;
        if (this.state.filter.reportingPeriods) {
            reportingPeriods = [{ venueReportingPeriodID: 0, reportingPeriodDisplayString: "" }, ...this.state.filter.reportingPeriods].map((item, index) => (
                <PickerItem key={item.venueReportingPeriodID} label={item.reportingPeriodDisplayString} value={item.venueReportingPeriodID} />
            ));
        }

        let reportingPeriodSelector = (
            <SelectControlGroup
                text="Reporting period"
                labelAbove={true}
                onChange={this.onSelectedReportingPeriodChanged}
                defaultValue={this.state.filter.reportingPeriodId}
                items={reportingPeriods} />
        );

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch()} />
                </Grid>
            </Grid>
        );

        let filterByOptions: RadioOption[] = [
            { id: "1", name: "Reporting Period" },
            { id: "2", name: "History" }
        ];
        let filterByOption = this.state.filter.byReportingPeriod ? "1" : "2";
        let filterOptionDisplay = (
            <RadioControl
                selected={filterByOption}
                options={filterByOptions}
                name={"filterByOptions"}
                onChange={this.onFilterByOptionChanged} />
        );

        let statusControl;
        if (this.props.filter.checkListStatuses) {
            let items: MulitSelectOption[] = [];
            this.props.filter.checkListStatuses.forEach(item => {
                items.push({
                    id: item.checklistStatusId,
                    name: item.name,
                    isCommon: true,
                    selected: this.props.filter.checkListStatusIds && this.props.filter.checkListStatusIds.indexOf(item.checklistStatusId) > -1
                });
            });
            statusControl = (
                <CardContainer style={{ marginTop: '10px' }}>
                    <CardContent style={{ padding: '10px', paddingTop: 0 }}>
                        <MultiSelectControl
                            items={items}
                            hideTitles={true}
                            label={"Status"}
                            minimumCharactersRequired={3}
                            onSelectItemsChanged={this.onStatusIdsChanged}
                            canShowAll={true} />
                    </CardContent>
                </CardContainer>
            );
        }

        let showArchivedButton = <CheckboxControlGroup 
            text="Show Archived" 
            defaultValue={this.state.filter.showObsolete} 
            onChanged={this.removedChanged} />;
                

        // advanced filters
        if (filterByOption == "1") {
            return (
                <View>
                    {filterOptionDisplay}
                    {reportingPeriodSelector}
                    {showArchivedButton}
                </View>
            );
        } else {
            return (
                <View>
                    {filterOptionDisplay}
                    <SearchDateControlGroup
                        text="Time Period"
                        rightText={"From"}
                        labelAbove={true}
                        date={this.state.filter.from}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onFromChanged}
                        defaultTime={"00:00"} />
                    <SearchDateControlGroup
                        rightText="To"
                        labelAbove={true}
                        date={this.state.filter.to}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onToChanged}
                        defaultTime={"23:59"} />
                    <TextControlGroup text={"Search"}
                        labelAbove={true}
                        defaultValue={this.state.filter.search}
                        onSubmit={value => {
                            this.onSearchChange(value);
                            this.props.onSearch();
                        }}
                        onChange={this.onSearchChange} />
                    {statusControl}
                    {showArchivedButton}
                </View>
            );
        }
    }
}