import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import { incidentLogic } from '../../common/logic/incidentLogic';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IScheduleEscalateUserRoleTypesProps {
    schedule: AusComplyDtos.Schedule;
    userRoleTypes: AusComplyDtos.UserRoleType[];
    onUpdate: Function;
}

interface IScheduleEscalateUserRoleTypesState {
    items: MulitSelectOption[];
}

export default class ScheduleEscalateUserRoleTypes extends Component<IScheduleEscalateUserRoleTypesProps, IScheduleEscalateUserRoleTypesState> {
    constructor(props: IScheduleEscalateUserRoleTypesProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.userRoleTypes) !== JSON.stringify(this.props.userRoleTypes)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleEscalationUserRoleTypes) !== JSON.stringify(this.props.schedule.scheduleEscalationUserRoleTypes)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let ids: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleEscalationUserRoleTypes) {
            ids = this.props.schedule.scheduleEscalationUserRoleTypes.filter(f => !f.obsolete).map(item => item.userRoleTypeId);
        }
        if (this.props.userRoleTypes) {
            this.props.userRoleTypes.map(item => {
                let isSelected = ids.indexOf(item.userRoleTypeId) > -1;
                items.push({
                    id: item.userRoleTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.schedule.scheduleEscalationUserRoleTypes.map(item => item.userRoleTypeId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.schedule.scheduleEscalationUserRoleTypes.filter(item => currentIds.indexOf(item.userRoleTypeId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ScheduleEscalationUserRoleType = new AusComplyDtos.ScheduleEscalationUserRoleType();
            newItem.userRoleTypeId = item.id;
            newItems.push(newItem);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleEscalationUserRoleTypes = newItems;
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {

        return (
            <CardContainer title={"Escalate to User Role Types"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} /></CardContent>
            </CardContainer>
        );
    }
}