import React, { Component } from 'react';
import Paging from './Paging';
import Media from "react-media";
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import { Paging as PagingDto } from '../../common/dto/AusComply.dtos';
import NoRowsMessage from '../common/NoRowsMessage';
import PageSize from './PageSize';

export interface IPagedListProps {
    paging: PagingDto;
    onPageSelected?: Function;
    onPageSizeSelected?: Function;
    loading?: boolean;
    saving?: boolean;
    cards?: any;
    rows?: any;
    footer?: any;
    label?: string;
}

export default class PagedList extends React.Component<IPagedListProps, any> {

    render() {
        let content = (<Loading />);
        if (this.props.saving) {
            content = (<Saving />);
        }
        if (!this.props.loading && !this.props.saving) {
            if (this.props.paging.pages > 1) {
                let recordCount;
                if (this.props.paging.total && this.props.paging.page && this.props.paging.pageSize) {
                    let from = (1 + ((this.props.paging.page - 1) * this.props.paging.pageSize));
                    let to = from + this.props.paging.pageSize - 1;
                    if (to > this.props.paging.total) {
                        to = this.props.paging.total;
                    }
                    recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center'}}><b>{from}</b> to <b>{ to }</b> of <b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""}</p>
                    if (this.props.paging.unFilteredTotal && this.props.paging.unFilteredTotal > 0 && this.props.paging.unFilteredTotal != this.props.paging.total) {
                        recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center'}}><b>{from}</b> to <b>{ to }</b> of <b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""} from a total of {this.props.paging.unFilteredTotal} </p>
                    }
                }
                content = (
                    <div className="paged-list">
                        <Paging paging={this.props.paging} onPageSelected={this.props.onPageSelected}  />
                        <Media query={{ maxWidth: 1279 }}>
                            {matches =>
                                matches ? (
                                    <div>{this.props.cards}</div>
                                ) : (
                                        <div>{this.props.rows}</div>
                                    )
                            }
                        </Media>
                        <Paging paging={this.props.paging} onPageSelected={this.props.onPageSelected} onPageSizeSelected={this.props.onPageSizeSelected} label={this.props.label} />
                        { recordCount }
                        { this.props.footer }
                    </div>
                );
            }
            if (this.props.paging.pages === 1) {
                let recordCount;
                if (this.props.paging.total) {
                    recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center'}}><b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""}</p>
                    if (this.props.paging.unFilteredTotal && this.props.paging.unFilteredTotal > 0 && this.props.paging.unFilteredTotal != this.props.paging.total) {
                        recordCount =  <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center'}}><b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""} from a total of {this.props.paging.unFilteredTotal} </p>
                    }
                }
                content = (
                    <div className="paged-list">
                        <Media query={{ maxWidth: 1279 }}>
                            {matches =>
                                matches ? (
                                    <div>{this.props.cards}</div>
                                ) : (
                                        <div>{this.props.rows}</div>
                                    )
                            }
                        </Media>
                        {this.props.onPageSizeSelected && (<div style={{ display: "block"}}>
                            <PageSize paging={this.props.paging} onPageSizeSelected={this.props.onPageSizeSelected} label={this.props.label} />
                        </div>)}
                        { recordCount }
                        { this.props.footer }
                    </div>
                );
            }
            if (!this.props.paging.total || this.props.paging.total === 0) {
                content = (
                    <NoRowsMessage></NoRowsMessage>
                );
            }
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}
