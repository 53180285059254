import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SubLocation from '../components/subLocations/SubLocation'
import * as subLocationActions from '../common/actions/subLocation';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.subLocations.subLocation.isLoading,
        isSaving: state.subLocations.subLocation.isSaving,
        subLocation: state.subLocations.subLocation.subLocation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(subLocationActions.subLocationReset())
        },
        onUpdate: (subLocation) => {
            dispatch(subLocationActions.subLocationUpdate(subLocation))
        },
        onSave: () => {
            dispatch(subLocationActions.subLocationSaveRequest())
        }
    }
}

const SubLocationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubLocation))

export default SubLocationContainer