import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICorrectionalFacilityRiskStatusHeaderProps {
    showCameras?: boolean;
    classes: any;
}

class CorrectionalFacilityRiskStatusHeader extends React.Component<ICorrectionalFacilityRiskStatusHeaderProps, any> {
    render() {
        const { classes } = this.props;
        // if (this.props.showCameras) {
        //     return (
        //         <tr>
        //             <th className={classes.tableHrIndicator}></th>
        //             <th className={classes.tableHr}>Name</th>
        //             <th className={classes.tableHr}>Cameras</th>
        //             <th className={classes.tableHr}>Code</th>
        //             <th className={classes.tableHr}>Rank</th>
        //             <th className={classes.tableHrCommands}></th>
        //         </tr>
        //     );
        // }
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Risk Status</th>
                <th className={classes.tableHr}>Include Cell Status</th>
                <th className={classes.tableHr}>Exclude Cell Status</th>
                <th className={classes.tableHr}>Default Inspection Frequency</th>
                <th className={classes.tableHr}>Rank</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CorrectionalFacilityRiskStatusHeader)
