import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { securityFirmContactServiceApi } from '../services/securityFirmContacts';

import {
    SECURITY_FIRM_CONTACTS_REQUEST,
    securityFirmContactsRequest,
    securityFirmContactsRequestSuccess,
    securityFirmContactsRequestFailure,
    SECURITY_FIRM_CONTACT_REQUEST,
    securityFirmContactRequestSuccess,
    securityFirmContactRequestFailure,
    SECURITY_FIRM_CONTACT_CREATE_REQUEST,
    securityFirmContactCreateRequestSuccess,
    securityFirmContactCreateRequestFailure,
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST,
    securityFirmContactUpsertRequestSuccess,
    securityFirmContactUpsertRequestFailure,
    SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS,
    securityFirmContactReset,
    SECURITY_FIRM_CONTACTS_REFRESH,
    securityFirmContactsRefresh
} from '../actions/securityFirm';
import {
    notifyError
} from './common';

const securityFirmContactsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmContactServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => securityFirmContactsRequestSuccess(response.securityFirmContacts, response.paging, response.filter)),
                    catchError(error => notifyError(error, "securityFirmContactsRequestEpic.find", securityFirmContactsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmContactsRequestEpic", securityFirmContactsRequestFailure))
    );

const securityFirmContactsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACTS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmContactServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirm.securityFirmContacts.paging.page,
                state.securityFirm.securityFirmContacts.paging.pageSize,
                state.securityFirm.securityFirmContacts.filter)
                .pipe(
                    map(response => securityFirmContactsRequestSuccess(response.securityFirmContacts, response.paging, response.filter)),
                    catchError(error => notifyError(error, "securityFirmContactsRefreshEpic.find", securityFirmContactsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmContactsRefreshEpic", securityFirmContactsRequestFailure))
    );

const securityFirmContactRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmContactServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmContactId'])
                .pipe(
                    map(response => securityFirmContactRequestSuccess(response.securityFirmContact, response.securityFirmContactTypes, response.availableUsers)),
                    catchError(error => notifyError(error, "securityFirmContactRequestEpic.get", securityFirmContactRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmContactRequestEpic", securityFirmContactRequestFailure))
    );

const securityFirmContactCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmContactServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmId'])
                .pipe(
                    map(response => securityFirmContactCreateRequestSuccess(response.securityFirmContact, response.securityFirmContactTypes, response.availableUsers)),
                    catchError(error => notifyError(error, "securityFirmContactCreateEpic.create", securityFirmContactCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmContactCreateEpic", securityFirmContactCreateRequestFailure))
    );

const securityFirmContactUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACT_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmContactServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.securityFirm.securityFirmContact.securityFirmContact)
                .pipe(
                    map(response => securityFirmContactUpsertRequestSuccess(response.securityFirmContact)),
                    catchError(error => notifyError(error, "securityFirmContactUpsertEpic.upsert", securityFirmContactUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "securityFirmContactUpsertEpic", securityFirmContactUpsertRequestFailure))
    );

const securityFirmContactUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => securityFirmContactReset())
    );

const securityFirmContactUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(SECURITY_FIRM_CONTACT_UPSERT_REQUEST_SUCCESS),
        map((action: any) => securityFirmContactsRefresh())
    );

export const securityFirmContactEpics = combineEpics(
    securityFirmContactsRefreshEpic,
    securityFirmContactsRequestEpic,
    securityFirmContactRequestEpic,
    securityFirmContactCreateEpic,
    securityFirmContactUpsertEpic,
    securityFirmContactUpsertSuccessEpic,
    securityFirmContactUpsertSuccessRefreshEpic
);