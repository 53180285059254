import { connect } from 'react-redux'
import RegisterUser from '../components/register/profile/RegisterUser'
import {
    registerUserNew,
    registerUserSetStep,
    registerUserReset,
    registerUserUpdate,
    registerUserSledCheckRequest,
    registerUserUpdateOtherDocuments,
    registerUserUpdateComplianceDocuments,
    registerUserSaveRequest,
    registerUserUpdateAvatar,
    registerUserPostSave
} from '../common/actions/registerUser'

import { userLoginRequest } from '../common/actions/userLogin'

const isLoggedIn = (state) =>
{
    let result:boolean = false;
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        if (state.user.isLoggedIn) {
            result = true;
        } 
        /*if (state.user.details.userSession.user.userID > 0) {
            result = true;
        }*/
    }
    return result;
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
        user: state.registerUser.user,
        avatar: state.registerUser.avatar,
        registerAs: state.registerUser.registerAs,
        isLicensedVenue: state.registerUser.isLicenseVenue,
        venues: state.registerUser.venues,
        securityFirms: state.registerUser.securityFirms,
        venueIds: state.registerUser.venueIds,
        securityFirmIds: state.registerUser.securityFirmIds,
        states: state.registerUser.states,
        genders: state.registerUser.genders,
        otherDocuments: state.registerUser.otherDocuments,
        otherDocumentTypes: state.registerUser.otherDocumentTypes,
        complianceDocuments: state.registerUser.complianceDocuments,
        complianceDocumentTypes: state.registerUser.complianceDocumentTypes,
        step: state.registerUser.step,
        isLoading: state.registerUser.isLoading,
        isSaving: state.registerUser.isSaving,
        isCheckingSled: state.registerUser.isCheckingSled,
        error: state.registerUser.error,
        errors: state.registerUser.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitUser: () => {
            dispatch(registerUserNew())
        },
        onSetStep: (step) => {
            dispatch(registerUserSetStep(step))
        },
        onUpdate: (user, venueIds, securityFirmIds) => {
            dispatch(registerUserUpdate(user, venueIds, securityFirmIds))
        },
        onReset: () => {
            dispatch(registerUserReset())
        },
        onCheckLicense: (user) => {
            dispatch(registerUserSledCheckRequest(user))
        },
        onUpdateOtherDocuments: (otherDocuments) => {
            dispatch(registerUserUpdateOtherDocuments(otherDocuments))
        },
        onUpdateComplianceDocuments: (complianceDocuments) => {
            dispatch(registerUserUpdateComplianceDocuments(complianceDocuments))
        },
        onAttemptLogin: (username, password) => {
            dispatch(userLoginRequest(username, password))
        },
        onSave: () => {
            dispatch(registerUserSaveRequest())
        },
        onUpdateAvatar: (avatar) => {
            dispatch(registerUserUpdateAvatar(avatar))
        },
        onRegisterUserPostSave: (postSaveAction) => {
            dispatch(registerUserPostSave(postSaveAction))
        }
    }
}

const RegisterUserContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterUser)

export default RegisterUserContainer