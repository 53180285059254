import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TableRow from '../../common/TableRow';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IFacialRecognitionNotificationRowProps {
    facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification;
    onEdit?: Function;
    onProcess?: Function;
}

export default class FacialRecognitionNotificationRow extends React.Component<IFacialRecognitionNotificationRowProps, any> {
    constructor(props: IFacialRecognitionNotificationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.facialRecognitionNotification);
                }
                break;
            case "process":
                if (this.props.onProcess) {
                    this.props.onProcess(this.props.facialRecognitionNotification);
                }
                break;
        }
    }

    render() {
        if (!this.props.facialRecognitionNotification) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "process", name: "Re-process" });


        let incidentsTick;
        if (this.props.facialRecognitionNotification.hasIncidents) {
            incidentsTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <TableRow commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.facialRecognitionNotification.dateEntered}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.facialRecognitionNotification.eventType}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.facialRecognitionNotification.matchOutcomes}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.facialRecognitionNotification.camera}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.facialRecognitionNotification.watchLists}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    {incidentsTick}
                </td>
            </TableRow>
        );
    }
}