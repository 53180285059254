import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import PagedList from '../../common/PagedList';
import { KeyName } from '../../../common/dto/common';
import DialogContentText from '@material-ui/core/DialogContentText';
import TableContainer from '../../layout/TableContainer';
import ConfirmDialog from '../../controls/ConfirmDialog';
import CheckboxControl from '../../controls/CheckboxControl';
import SecurityFirmAssociationCard from './SecurityFirmAssociationCard';
import SecurityFirmAssociationHeader from './SecurityFirmAssociationHeader';
import SecurityFirmAssociationRow from './SecurityFirmAssociationRow';
import SecurityFirmSubAssociationCard from './SecurityFirmSubAssociationCard';
import SecurityFirmSubAssociationHeader from './SecurityFirmSubAssociationHeader';
import SecurityFirmSubAssociationRow from './SecurityFirmSubAssociationRow';
import SecurityFirmAssociationContainer from '../../../containers/SecurityFirmAssociationContainer';
import SecurityFirmSubAssociationContainer from '../../../containers/SecurityFirmSubAssociationContainer';

import PageLayout from '../../layout/PageLayout';
import TransparentCommandsButton from '../../controls/TransparentCommandsButton';

export interface ISecurityFirmAssociationsProps {
    isLoading: boolean;
    isSaving: boolean;
    venueId: number;
    securityFirmId: number;
    venueSecurityFirmId: number;
    venueSecurityFirmAssociations: AusComplyDtos.VenueSecurityFirmSummary[];
    paging: AusComplyDtos.Paging;
    canCreate: boolean;
    canRemove: boolean;
    onLoad: Function;
    onCreate: Function;
    onReset: Function;
    onRemove: Function;

    isLoadingAssociations: boolean;
    isSavingAssociations: boolean;
    securityFirmAssociationId: number;
    securityFirmAssociations: AusComplyDtos.SecurityFirmAssociation[];
    pagingAssociations: AusComplyDtos.Paging;
    onLoadAssociations: Function;
    onCreateAssociation: Function;
    onResetAssociation: Function;
    onRemoveAssociation: Function;
}

export default class SecurityFirmAssociations extends Component<ISecurityFirmAssociationsProps, any> {
    constructor(props: ISecurityFirmAssociationsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            itemToRemove: {},
            subConfirmRemove: false,
            subItemToRemove: {},
            allAssociations: true
        };
        this.onCommand = this.onCommand.bind(this);
        this.onCommandAssociation = this.onCommandAssociation.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.handlePageSelectedAssociation = this.handlePageSelectedAssociation.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.removeCancelAssociation = this.removeCancelAssociation.bind(this);
        this.removeConfirmedAssociation = this.removeConfirmedAssociation.bind(this);
        this.onAllAssociationsChanged = this.onAllAssociationsChanged.bind(this);
        this.onPageCommand = this.onPageCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, this.props.securityFirmId, this.props.paging.pageSize, this.props.paging.page);
        this.props.onLoadAssociations(this.props.securityFirmId, this.props.pagingAssociations.pageSize, this.props.pagingAssociations.page);
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.venueId, this.props.securityFirmId, this.props.paging.pageSize, page);
    }

    handlePageSelectedAssociation(page) {
        this.props.onLoadAssociations(this.props.securityFirmId, this.props.pagingAssociations.pageSize, page);
    }

    onCommand(command: string, venueSecurityFirm: AusComplyDtos.VenueSecurityFirm) {
        switch (command) {
            case "delete":
                var updated = { ...venueSecurityFirm };
                this.setState({
                    confirmRemove: true,
                    itemToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    onCommandAssociation(command: string, securityFirmAssociation: AusComplyDtos.SecurityFirmAssociation) {
        switch (command) {
            case "delete":
                var updated = { ...securityFirmAssociation };
                this.setState({
                    subConfirmRemove: true,
                    subItemToRemove: updated
                });
                break;
            default:
                break;
        }
    }

    onPageCommand(command: string) {
        switch (command) {
            case "register":
                this.onCreate();
                break;
            case "global":
                this.onCreateAssociation()
                break;
            default:
                break;
        }
    }

    onAllAssociationsChanged(allAssociations: any) {
        this.setState({ allAssociations });
    }

    onCreate() {
        if (this.props.onCreate) {
            this.props.onCreate(this.props.venueId, this.props.securityFirmId);
        }
    }

    onCreateAssociation() {
        if (this.props.onCreateAssociation) {
            this.props.onCreateAssociation(this.props.securityFirmId);
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            itemToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onRemove) {
            let itemToRemove = { ...this.state.itemToRemove };
            this.props.onRemove(itemToRemove.venueSecurityFirmId);
            this.setState({
                confirmRemove: false,
                itemToRemove: {}
            });
        }
    }

    removeCancelAssociation() {
        this.setState({
            subConfirmRemove: false,
            subItemToRemove: {}
        });
    }

    removeConfirmedAssociation() {
        if (this.props.onRemoveAssociation) {
            let subItemToRemove = { ...this.state.subItemToRemove };
            this.props.onRemoveAssociation(subItemToRemove.securityFirmAssociationId, this.state.allAssociations);
            this.setState({
                subConfirmRemove: false,
                subItemToRemove: {}
            });
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.venueSecurityFirmAssociations && this.props.venueSecurityFirmAssociations.length > 0) {
            cardList = this.props.venueSecurityFirmAssociations.map((item, index) => {
                return (
                    <SecurityFirmAssociationCard
                        onCommand={this.onCommand}
                        canRemove={this.props.canRemove}
                        key={"card-" + item.venueSecurityFirmId.toString()}
                        venueSecurityFirmSummary={item}
                    />
                );
            });
            rows = this.props.venueSecurityFirmAssociations.map((item, index) => {
                return (
                    <SecurityFirmAssociationRow
                        onCommand={this.onCommand}
                        canRemove={this.props.canRemove}
                        key={"row-" + item.venueSecurityFirmId.toString()}
                        venueSecurityFirmSummary={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<SecurityFirmAssociationHeader />}
                    rows={rows}
                />
            );
        }

        let cardListGlobal;
        let rowsGlobal;
        let rowListGlobal;

        if (this.props.securityFirmAssociations && this.props.securityFirmAssociations.length > 0) {
            cardListGlobal = this.props.securityFirmAssociations.map((item, index) => {
                return (
                    <SecurityFirmSubAssociationCard
                        onCommand={this.onCommandAssociation}
                        canRemove={this.props.canRemove}
                        key={"card-" + item.securityFirmAssociationId.toString()}
                        securityFirmAssociation={item}
                    />
                );
            });
            rowsGlobal = this.props.securityFirmAssociations.map((item, index) => {
                return (
                    <SecurityFirmSubAssociationRow
                        onCommand={this.onCommandAssociation}
                        canRemove={this.props.canRemove}
                        key={"row-" + item.securityFirmAssociationId.toString()}
                        securityFirmAssociation={item}
                    />
                );
            });
            rowListGlobal = (
                <TableContainer
                    header={<SecurityFirmSubAssociationHeader />}
                    rows={rowsGlobal}
                />
            );
        }

        let pageCommands: KeyName[] = [];
        if (this.props.canCreate) {
            if (this.props.venueId && this.props.venueId > 0) {
                pageCommands.push({key: "register", name: "Add Register Specific Association"})
            }
            pageCommands.push({key: "global", name: "Add Global Association"});
        }
        let footerLeftContent=<TransparentCommandsButton text={"Add"} commands={pageCommands} onCommand={this.onPageCommand} />

        let allAssociations;
        if (this.state.subConfirmRemove) {
            allAssociations = (
                <>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to remove this Security Firm assocation?
                    </DialogContentText>
                    <CheckboxControl text="Delete all current associations" defaultValue={this.state.allAssociations} onChanged={(value) => this.onAllAssociationsChanged(value)} />
                </>
            );
        }

        return (<PageLayout
            headerText={"Security Firm Associations"}
            footerLeftContent={footerLeftContent}
        >
            <SubTitle text="Global" />
            <PagedList
                loading={this.props.isLoadingAssociations}
                saving={this.props.isSavingAssociations}
                paging={this.props.pagingAssociations}
                cards={cardListGlobal}
                rows={rowListGlobal}
                onPageSelected={i => this.handlePageSelectedAssociation(i)}
            />

            <SubTitle text="Register Specific" />
            <PagedList
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <SecurityFirmAssociationContainer open={this.props.venueSecurityFirmId > -1} onClosed={() => this.props.onReset()} />
            <SecurityFirmSubAssociationContainer open={this.props.securityFirmAssociationId != -1} onClosed={() => this.props.onResetAssociation()}/>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this Security Firm assocation?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
            <ConfirmDialog
                title={"Confirm remove"}
                dialogContent={allAssociations}
                show={this.state.subConfirmRemove}
                onCancel={this.removeCancelAssociation}
                onConfirm={this.removeConfirmedAssociation} />
        </PageLayout>);
    }
}