import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from '../../themes/darkTheme';
import { lightTheme } from '../../themes/lightTheme';
import NotificationContainer from '../../containers/NotificationContainer';
import MasterPageContainer from '../../containers/MasterPageContainer';

export interface IThemeSwitcherProps {
    isDark: Boolean;
}

export default class ThemeSwitcher extends Component<IThemeSwitcherProps, any> {
    render() {
        let themeToUse = this.props.isDark ? darkTheme : lightTheme;
        let backgroundColor = this.props.isDark ? '#000000' : '#FFFFFF';
        let backgroundStyle = `
        background: rgb(14,14,20);
background: -moz-linear-gradient(0deg, rgba(14,14,20,1) 0%, rgba(63,65,70,1) 72%, rgba(40,40,44,1) 82%, rgba(14,14,20,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(14,14,20,1) 0%, rgba(63,65,70,1) 72%, rgba(40,40,44,1) 82%, rgba(14,14,20,1) 100%);
background: linear-gradient(0deg, rgba(14,14,20,1) 0%, rgba(63,65,70,1) 72%, rgba(40,40,44,1) 82%, rgba(14,14,20,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e0e14",endColorstr="#0e0e14",GradientType=1);
        `;
        return (
            <MuiThemeProvider theme={themeToUse} >
            <style>{'x-body { ' + backgroundStyle + ' }'}</style>
                <MasterPageContainer />
                <NotificationContainer />
            </MuiThemeProvider>
        );
    }
}