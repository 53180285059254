import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CheckboxControl from '../../controls/CheckboxControl'
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import SingleSelectListControl from '../../controls/SingleSelectListControl';
import ControlGroup from '../../controls/ControlGroup';
import { SingleSelectList } from '../../../common/dto/common';

export interface IFacialRecognitionNotificationFilterProps {
    filter: AusComplyDTOs.FacialRecognitionNotificationFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IFacialRecognitionNotificationFilterState {
    filter: AusComplyDTOs.FacialRecognitionNotificationFilter;
}

export default class FacialRecognitionNotificationFilter extends React.Component<IFacialRecognitionNotificationFilterProps, IFacialRecognitionNotificationFilterState> {
    constructor(props: IFacialRecognitionNotificationFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showRemovedChanged = this.showRemovedChanged.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.FacialRecognitionNotificationFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onFromChanged(value) {
        this.onChange("from", value);
    }

    onToChanged(value) {
        this.onChange("to", value);
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    showRemovedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                </Grid>
            </Grid>
        );

        let filter;
        let unmatched;
        let watchlists;
        let cameras;
        if (this.state.filter) {
            filter = (
                <>
                    <SearchDateControlGroup
                        text="Time Period"
                        rightText={"From"}
                        labelAbove={true}
                        date={this.state.filter.from}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onFromChanged}
                        defaultTime={"00:00"} />

                    <SearchDateControlGroup
                        rightText="To"
                        labelAbove={true}
                        date={this.state.filter.to}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onToChanged}
                        defaultTime={"23:59"} />



                </>);

            /*
            unmatched = (
                <CheckboxControlGroup
                    text="Show Unmatched"
                    defaultValue={this.state.filter.showUnmatched}
                    onChanged={value => this.onChange("showUnmatched", value)} />
            );
            */

            if (this.state.filter.watchlists && this.state.filter.watchlists.length > 0) {
                watchlists = (
                    <SelectControlGroup
                        text="Watchlist"
                        onChange={(value) => this.onChange("watchlistId", Number(value))}
                        defaultValue={this.state.filter.watchlistId}>
                        <option key={0} value={0}>Select...</option>
                        {this.state.filter.watchlists.filter(f => !f.obsolete).map((item, index) => (
                            <option key={item.facialRecognitionWatchlistId} value={item.facialRecognitionWatchlistId}>{item.displayName}</option>
                        ))}
                    </SelectControlGroup>
                );
            }

            if (this.state.filter.cameras && this.state.filter.cameras.length > 0) {
                cameras = (
                    <SelectControlGroup
                        text="Camera"
                        onChange={(value) => this.onChange("cameraId", Number(value))}
                        defaultValue={this.state.filter.cameraId}>
                        <option key={0} value={0}>Select...</option>
                        {this.state.filter.cameras.filter(f => !f.obsolete).map((item, index) => (
                            <option key={item.facialRecognitionCameraId} value={item.facialRecognitionCameraId}>{item.displayName}</option>
                        ))}
                    </SelectControlGroup>
                );
            }
        }

        return (
            <LayoutForm>
                { filter }
                { watchlists }
                { cameras}
                { unmatched}
                { resetButton }
            </LayoutForm>
        );
    }
}