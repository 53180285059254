import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface IDocumentTypeProps {
    documentType: AusComplyDtos.DocumentType;
    documentCategories: AusComplyDtos.DocumentCategory[];
    entityTypes: AusComplyDtos.EntityType[];
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IDocumentTypeState {
}

export default class DocumentType extends Component<IDocumentTypeProps, IDocumentTypeState> {
    constructor(props: IDocumentTypeProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let documentType = { ...this.props.documentType };
        documentType[fieldName] = value;
        this.props.onUpdate(documentType)
    }

    render() {

        if (!this.props.documentType) {
            return (<></>);
        }

        let rank = "0";
        if (this.props.documentType.rank) {
            rank = this.props.documentType.rank.toString();
        }

        let documentCategories;
        if (this.props.documentCategories && this.props.documentCategories.length > 1) {
            documentCategories = (
                <SelectControlGroup
                    text="Category"
                    readonly={this.props.documentType.documentTypeId > 0}
                    onChange={(value) => this.onValueChanged("documentCategoryId", Number(value))}
                    defaultValue={this.props.documentType.documentCategoryId}>
                    <option key={0} value={0}>Select...</option>
                    {this.props.documentCategories.map((item, index) => (
                        <option key={item.documentCategoryId} value={item.documentCategoryId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let entityTypes;
        if (this.props.entityTypes && this.props.entityTypes.length > 1) {
            entityTypes = (
                <SelectControlGroup
                    text="Entity Type"
                    readonly={this.props.documentType.documentTypeId > 0}
                    onChange={(value) => this.onValueChanged("entityTypeId", Number(value))}
                    defaultValue={this.props.documentType.entityTypeId}>
                    <option key={0} value={0}>Select...</option>
                    {this.props.entityTypes.filter(f => f.entityTypeId != 1).map((item, index) => (
                        <option key={item.entityTypeId} value={item.entityTypeId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        var obsolete = false;
        if (this.props.documentType.obsolete != undefined) {
            obsolete = this.props.documentType.obsolete;
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.documentType.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />
                    {documentCategories}
                    {entityTypes}
                    <CheckboxControlGroup
                        label={" "}
                        text={"Active"}
                        isReversed={true}
                        defaultValue={obsolete}
                        onChanged={(value) => this.onValueChanged("obsolete", value)} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}