import React, { Component } from 'react';

export interface IActiveSessionMonitorProps {
    lastActionDate: any;
    lastActionEnabled: boolean;
    duration?: number;
    onUserActive: Function;
    onLogout: Function;
}

export default class ActiveSessionMonitor extends Component<IActiveSessionMonitorProps, any> {
    constructor(props: IActiveSessionMonitorProps) {
        super(props)
    }

    timer;

    componentDidMount() {
        this.timer = setInterval(() => this.onRefresh(), 10000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    onRefresh() {
        if (!this.props.lastActionDate) {
            this.props.onUserActive();
            return;
        }
        let date1: Date = new Date();
        let date2 = new Date(this.props.lastActionDate);
        // get the duration as minutes
        let duration = (date1.getTime() - date2.getTime()) / 1000 / 60;

        let maxDuration: number = this.props.duration && this.props.duration > 0 ? this.props.duration : 120;

        // console.log('ActiveSessionMonitor', duration, maxDuraction)

        if (duration > maxDuration) {
            // log out due to inactivity
            this.props.onLogout();
        }
    }

    render() {
        return <></>;
    }
}

