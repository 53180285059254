import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface ICheckListLegendProps {
    classes: any;
    theme: any;
}

class CheckListLegend extends React.Component<ICheckListLegendProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistInProgress}></div>
                    <div className={classes.colorSwatchText}>In Progress</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistApproved}></div>
                    <div className={classes.colorSwatchText}>Approved</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistClosed}></div>
                    <div className={classes.colorSwatchText}>Void</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistSubmitted}></div>
                    <div className={classes.colorSwatchText}>Submitted</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistRejected}></div>
                    <div className={classes.colorSwatchText}>Resubmit</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchChecklistArchived}></div>
                    <div className={classes.colorSwatchText}>Archived</div>
                </Box>
            </Box>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CheckListLegend);