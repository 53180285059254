import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import { SketchPicker, CompactPicker } from 'react-color';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IIncidentTypeProps {
    open: boolean;
    incidentType: AusComplyDtos.IncidentType;
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IIncidentTypeState {
    incidentType: AusComplyDtos.IncidentType;
    industryCategories: MulitSelectOption[];
    incidentCategoryTypes: MulitSelectOption[];
    error: string;
}

export default class IncidentType extends Component<IIncidentTypeProps, IIncidentTypeState> {
    constructor(props: IIncidentTypeProps) {
        super(props)
        let incidentType = new AusComplyDtos.IncidentType();
        incidentType.industryCategorySelections = [];
        let error = "";
        this.state = {
            incidentType,
            industryCategories: [],
            incidentCategoryTypes: [],
            error
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.onIncidentCategoryTypesChanged = this.onIncidentCategoryTypesChanged.bind(this);
    }

    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.error) {
                this.setState({ error: this.props.error });
            } else {
                this.props.onCancel();
            }
        } else if (this.props.open && !prevProps.open) {
            this.onLoad();
        }
    }

    onLoad() {
        let incidentType = { ...this.props.incidentType };
        let industryCategories: MulitSelectOption[] = [];
        if (this.props.incidentType.industryCategorySelections) {
            this.props.incidentType.industryCategorySelections.forEach((item, index) => {
                let industryCategory = new MulitSelectOption();
                industryCategory.id = item.industryCategoryId;
                industryCategory.name = item.name;
                industryCategory.selected = item.selected;
                industryCategory.isCommon = false;
                industryCategories.push(industryCategory);
            });
        }


        let incidentCategoryTypes: MulitSelectOption[] = [];
        if (this.props.incidentType.incidentCategoryTypeSelections) {
            this.props.incidentType.incidentCategoryTypeSelections.forEach((item, index) => {
                let incidentCategoryType = new MulitSelectOption();
                incidentCategoryType.id = item.incidentCategoryTypeId;
                incidentCategoryType.name = item.name;
                incidentCategoryType.selected = item.selected;
                incidentCategoryType.isCommon = false;
                incidentCategoryTypes.push(incidentCategoryType);
            });
        }

        this.setState({
            incidentType,
            industryCategories,
            incidentCategoryTypes,
            error: ""
        });
    }

    onSave() {
        let incidentType = { ...this.state.incidentType };
        if (incidentType.industryCategorySelections) {
            incidentType.industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
        }
        if (incidentType.incidentCategoryTypeSelections) {
            incidentType.incidentCategoryTypeSelections.forEach((item, index) => {
                item.selected = this.state.incidentCategoryTypes.filter(s => s.id === item.incidentCategoryTypeId && s.selected).length > 0;
            });
        }
        this.props.onSave(incidentType);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentType = { ...this.state.incidentType };
        incidentType[fieldName] = value;
        this.setState({ incidentType });
    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        });
    }

    onIncidentCategoryTypesChanged(value) {
        let incidentCategoryTypes = [...this.state.incidentCategoryTypes];
        incidentCategoryTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            incidentCategoryTypes
        });
    }

    render() {
        let rank = "0";
        if (this.state.incidentType.rank) {
            rank = this.state.incidentType.rank.toString();
        }

        return (
            <FullPageDialog
                open={this.props.open}
                footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                error={this.props.error}
                title="Incident Type" onDismissed={() => this.onCancel()}>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.state.incidentType.name}
                    onChange={value => this.onValueChanged("name", value)} />

                <TextControlGroup
                    text={"Rank"}
                    numericOnly={true}
                    defaultValue={rank}
                    onChange={value => this.onValueChanged("rank", value)} />

                <ControlGroup text="Chart Color">
                    <CompactPicker
                        color={this.state.incidentType.color}
                        onChangeComplete={(color) => this.onValueChanged("color", color.hex)}
                    />
                </ControlGroup>
                <CheckboxControlGroup
                    text={"Legislated"}
                    defaultValue={this.state.incidentType.legislated}
                    onChanged={(value) => this.onValueChanged("legislated", value)} />
                <CheckboxControlGroup
                    text={"Internal Only"}
                    defaultValue={this.state.incidentType.apiOnlyCreation}
                    onChanged={(value) => this.onValueChanged("apiOnlyCreation", value)} />
                <CheckboxControlGroup
                    text={"Gambling Incident Register"}
                    defaultValue={this.state.incidentType.isRGO}
                    onChanged={(value) => this.onValueChanged("isRGO", value)} />
                <CheckboxControlGroup
                    text={"Active"}
                    isReversed={true}
                    defaultValue={this.state.incidentType.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
                <CardContainer
                    title={"Industries"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <div style={{ marginTop: '-4px' }}>
                            <MultiSelectControl
                                items={this.state.industryCategories}
                                minimumCharactersRequired={3}
                                canShowAll={true}
                                searchText={"Type at least three letters..."}
                                onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                        </div>
                    </CardContent>
                </CardContainer>
                <CardContainer
                    title={"Incident Category Types"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <div style={{ marginTop: '-4px' }}>
                            <MultiSelectControl
                                items={this.state.incidentCategoryTypes}
                                minimumCharactersRequired={3}
                                canShowAll={true}
                                searchText={"Type at least three letters..."}
                                onSelectItemsChanged={this.onIncidentCategoryTypesChanged} />
                        </div>
                    </CardContent>
                </CardContainer>
            </FullPageDialog >
        );
    }
}