import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import View from '../../common/View';
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import SimpleFilter from '../../common/SimpleFilter';
import ResponsiveList from '../../common/ResponsiveList';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import Grid from '@material-ui/core/Grid';
import { KeyName } from '../../../common/dto/common';

export interface IAdminFacialRecognitionWatchlistTypesProps {
    isLoading: boolean;
    facialRecognitionWatchlistTypes: AusComplyDtos.FacialRecognitionWatchlistType[];
    facialRecognitionWatchlistType: AusComplyDtos.FacialRecognitionWatchlistType;
    facialRecognitionWatchlistTypeId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    canCreate: boolean;
    isLoadingItem: boolean;
    isSaving: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onCancel: Function;
}

interface IAdminFacialRecognitionWatchlistTypesState {
    filterExpanded: boolean;
}

export default class AdminFacialRecognitionWatchlistTypes extends Component<IAdminFacialRecognitionWatchlistTypesProps, IAdminFacialRecognitionWatchlistTypesState> {
    constructor(props: IAdminFacialRecognitionWatchlistTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {

    }

    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionWatchlistType = { ...this.props.facialRecognitionWatchlistType };
        facialRecognitionWatchlistType[fieldName] = value;
        this.props.onUpdate(facialRecognitionWatchlistType)
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {
        this.props.onLoad({ ...filter }, 1, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(new AusComplyDtos.SimpleFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    getCommands(camera: AusComplyDtos.FacialRecognitionWatchlistType) {
        var commands: KeyName[] = [];
        if (!camera.obsolete) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });
        } else {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionWatchlistType: AusComplyDtos.FacialRecognitionWatchlistType) {
        switch (command) {
            case "add":
                this.props.onCreate();
                break;
            case "edit":
                this.props.onLoadItem(facialRecognitionWatchlistType.facialRecognitionWatchlistTypeId);
                break;
            case "restore":
                var updated = { ...facialRecognitionWatchlistType };
                updated.obsolete = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...facialRecognitionWatchlistType };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            default:
                break;
        }

    }

    render() {
        let facialRecognitionWatchlistTypes;

        let facialRecognitionWatchlistType;
        if (this.props.facialRecognitionWatchlistTypeId != -1) {
            let rank = "0";
            if (this.props.facialRecognitionWatchlistType && this.props.facialRecognitionWatchlistType.rank) {
                rank = this.props.facialRecognitionWatchlistType.rank.toString();
            }

            var obsolete = false;
            if (this.props.facialRecognitionWatchlistType && this.props.facialRecognitionWatchlistType.obsolete != undefined) {
                obsolete = this.props.facialRecognitionWatchlistType.obsolete;
            }

            facialRecognitionWatchlistType = (
                <LayoutForm
                    loading={this.props.isLoadingItem}
                    saving={this.props.isSaving}
                >
                    {this.props.facialRecognitionWatchlistType && (
                        <form>
                            <TextControlGroup
                                text={"Name"}
                                defaultValue={this.props.facialRecognitionWatchlistType.name}
                                onBlur={(value) => this.onValueChanged("name", value)} />
                            <TextControlGroup
                                text={"Rank"}
                                numericOnly={true}
                                defaultValue={rank}
                                onChange={value => this.onValueChanged("rank", value)} />
                            <CheckboxControlGroup
                                label={" "}
                                text={"Active"}
                                isReversed={true}
                                defaultValue={obsolete}
                                onChanged={(value) => this.onValueChanged("obsolete", value)} />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onCancel()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </LayoutForm >
            );
        }

        if (!this.props.isLoading && !this.props.isSaving && this.props.facialRecognitionWatchlistTypes) {
            facialRecognitionWatchlistTypes = <ResponsiveList
                loading={this.props.isLoading || this.props.isSaving}
                data={this.props.facialRecognitionWatchlistTypes}
                headers={["Name", "Rank"]}
                columns={["name", "rank"]}
                getCommands={item => this.getCommands(item)}
                onCommand={this.onCommand}
                isDanger={item => item.obsolete}
                canEdit={true}
                toggleDanger={false}
            />;
        }

        let createFab;
        if (this.props.canCreate) {
            createFab = (
                <DefaultFab onClick={() => this.props.onCreate()} isExtended={true}>Add Watchlist Type</DefaultFab>
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onUpdateFilter={filter => { }}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let industryCategoriesFilter = new AusComplyDtos.SimpleFilter();
            industryCategoriesFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={industryCategoriesFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<>
            <PageTitle title="Watchlist Types"></PageTitle>
            {filterSummary}
            {facialRecognitionWatchlistTypes}
            <VenueQuickActionsContainer>
                {createFab}
            </VenueQuickActionsContainer>
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <FullPageDialog open={this.props.facialRecognitionWatchlistTypeId != -1} title="Watchlist Type" onDismissed={() => this.props.onResetItem()}>
                {facialRecognitionWatchlistType}
            </FullPageDialog>
        </>);
    }
}