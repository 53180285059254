
import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter, debounce, debounceTime, throttle } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentServiceApi } from '../services/incidentService';
import { venueReportingPeriodServiceApi } from '../services/venueReportingPeriod';

import * as draftIncidentActions from "../actions/draftIncident";

import {
    EDIT_INCIDENT_LOAD_REQUEST,
    editIncidentLoadSuccess,
    editIncidentLoadFailure,
    editIncidentClear,
    EDIT_INCIDENT_SAVE_REQUEST,
    EDIT_INCIDENT_SAVE_REQUEST_SUCCESS,
    editIncidentSaveSuccess,
    editIncidentSaveFailure,
    EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST,
    editIncidentCheckReportingPeriodDateSuccess,
    editIncidentCheckReportingPeriodDateFailure,
    EDIT_INCIDENT_UPLOAD_REQUEST,
    editIncidentUploadRequestSuccess,
    editIncidentUploadRequestFailure,
    EDIT_INCIDENT_PATRON_UPLOAD_REQUEST,
    editIncidentPatronUploadRequestSuccess,
    editIncidentPatronUploadRequestFailure,
    EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST,
    EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
    editIncidentChecklistItemUploadRequestSuccess,
    editIncidentChecklistItemUploadRequestFailure,
    EDIT_INCIDENT_CHECKLISTS_REQUEST,
    editIncidentChecklistsRequestSuccess,
    editIncidentChecklistsRequestFailure
} from "../actions/editIncident";

import * as incidentActions from "../actions/incidents";
import * as incidentDocumentActions from "../actions/incidentDocument";
import * as userVenueSecurityFirmActions from "../actions/userVenueSecurityFirm";

import {
    USER_CHANGED,
    userLogoutRequest,
    USER_DETAILS_REQUEST_SUCCESS
} from '../actions/userLogin';
import {
    notifyError, notifyErrorMessage, notifyErrorNoTrack
} from './common';
import { notificationShow, notificationSuccessShow } from "../actions/notification";
import {
    VENUE_SECURITYFIRM_SELECT
} from "../actions/venueSecurityFirm";
import { fileServiceApi } from '../services/fileService';

const incidentResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => incidentActions.incidentsReset())
    );

const draftIncidentResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => draftIncidentActions.draftIncidentClear())
    );

const editIncidentResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => editIncidentClear())
    );

const incidentResetOnVenueChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_SELECT),
        map(action => incidentActions.incidentsReset())
    );

const draftIncidentResetOnVenueChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_SECURITYFIRM_SELECT),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.draftIncident.incident) {
                let expectedVenueId = action['venueId'] || 0;
                let expectedSecurityFirmId = action['securityFirmId'] || 0;
                let draftVenueId = state.draftIncident.incident.venueId || 0;
                let draftSecurityFirmId = state.draftIncident.incident.securityFirmId || 0;
                if (expectedVenueId == draftVenueId && expectedSecurityFirmId == draftSecurityFirmId) {
                    return false;
                }
            }
            return true;
        }),
        map(action => draftIncidentActions.draftIncidentClear())
    );

const createIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.createIncident(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["incidentTypeId"],
                action["entryPage"]
            )
                .pipe(
                    map(response => draftIncidentActions.draftIncidentCreateSuccess(response.incident, response.incidentOptions)),
                    catchError(error => notifyErrorMessage(error, "Failed to create a new incident", draftIncidentActions.draftIncidentCreateFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to create a new incident", draftIncidentActions.draftIncidentCreateFailure))
    );

const incidentOptionsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_OPTIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.createIncident(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["incidentTypeId"],
                action["entryPage"]
            )
                .pipe(
                    map(response => draftIncidentActions.draftIncidentOptionsRequestSuccess(response.incidentOptions)),
                    catchError(error => notifyErrorMessage(error, "Failed to get incident options", draftIncidentActions.draftIncidentOptionsRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to get incident options", draftIncidentActions.draftIncidentOptionsRequestFailure))
    );

const getIncidentFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistoryFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["reset"], false, action["forEntryPage"])
                .pipe(
                    map(response => incidentActions.incidentsFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "getIncidentFilterEpic.getIncidentHistoryFilter", incidentActions.incidentsFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentFilterEpic", incidentActions.incidentsFilterRequestFailure))
    );

const getIncidentsEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistory(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["incidentFilter"], true)
                .pipe(
                    map(response => incidentActions.incidentsRequestSuccess(response.incidents, response.paging, response.filter)),
                    catchError(error => notifyError(error, "getIncidentsEpic.getIncidentHistory", incidentActions.incidentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentsEpic", incidentActions.incidentsRequestFailure))
    );

const getIncidentFilterSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_FILTER_REQUEST_SUCCESS),
        // debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) =>
            incidentActions.incidentsRefreshRequest()
        )
    );

const getIncidentsApproveSelectedEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_APPROVE_SELECTED_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.approveIncidents(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.incidents.items)
                .pipe(
                    map(response => incidentActions.incidentsApproveSelectedRequestSuccess()),
                    catchError(error => notifyError(error, "getIncidentsApproveSelectedEpic.approveIncidents", incidentActions.incidentsApproveSelectedRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentsApproveSelectedEpic", incidentActions.incidentsApproveSelectedRequestFailure))
    );

const getIncidentsApproveSelectedSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_APPROVE_SELECTED_REQUEST_SUCCESS),
        map(action => incidentActions.incidentsRefreshRequest())
    );

const getIncidentsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_REFRESH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistory(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.incidents.filter, true)
                .pipe(
                    map(response => incidentActions.incidentsRequestSuccess(response.incidents, response.paging, response.filter)),
                    catchError(error => notifyErrorNoTrack(error, "getIncidentsApproveSelectedSuccessEpic.getIncidentHistory", incidentActions.incidentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentsApproveSelectedSuccessEpic", incidentActions.incidentsRequestFailure))
    );

const saveDraftIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_SAVE_REQUEST),
        withLatestFrom(state$),
        throttle(() => timer(3000)),
        mergeMap(([action, state]) =>
            incidentServiceApi.saveIncident(state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.draftIncident.incident,
                action["isDraft"],
                action["approve"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentSaveSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to save incident", draftIncidentActions.draftIncidentSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save incident", draftIncidentActions.draftIncidentSaveFailure))
    );

const saveDraftIncidentCheckReportingPeriodCurrentDateEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.venueCurrentReportingPeriodCheckDate(state.user.details.userSession.user.venueId, action["incidentDate"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentCheckReportingPeriodDateSuccess(response.isCurrentReportingPeriod)),
                    catchError(error => notifyError(error, "saveDraftIncidentCheckReportingPeriodCurrentDateEpic.venueCurrentReportingPeriodCheckDate", draftIncidentActions.draftIncidentCheckReportingPeriodDateFailure))
                )
        ),
        catchError(error => notifyError(error, "saveDraftIncidentCheckReportingPeriodCurrentDateEpic", draftIncidentActions.draftIncidentCheckReportingPeriodDateFailure))
    );

const saveDraftIncidentSuccessMessageEpic = action$ =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Incident saved"))
    );

const saveDraftIncidentSuccessStatisticsRefreshEpic = action$ =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map(action => userVenueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );


const loadIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_LOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.loadIncident(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action["incidentId"])
                .pipe(
                    map(response => editIncidentLoadSuccess(response.incident, response.incidentOptions)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident", editIncidentLoadFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident", editIncidentLoadFailure))
    );

const saveEditIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_SAVE_REQUEST),
        throttle(() => timer(3000)),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.saveIncident(state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.editIncident.incident,
                action["isDraft"],
                action["approve"])
                .pipe(
                    map(response => editIncidentSaveSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to save incident", editIncidentSaveFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save incident", editIncidentSaveFailure))
    );

const saveEditIncidentCheckReportingPeriodCurrentDateEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueReportingPeriodServiceApi.venueCurrentReportingPeriodCheckDate(state.user.details.userSession.user.venueId, action["incidentDate"])
                .pipe(
                    map(response => editIncidentCheckReportingPeriodDateSuccess(response.isCurrentReportingPeriod)),
                    catchError(error => notifyError(error, "saveEditIncidentCheckReportingPeriodCurrentDateEpic.venueCurrentReportingPeriodCheckDate", editIncidentCheckReportingPeriodDateFailure))
                )
        ),
        catchError(error => notifyError(error, "saveEditIncidentCheckReportingPeriodCurrentDateEpic", editIncidentCheckReportingPeriodDateFailure))
    );

const saveEditIncidentSuccessReloadIncidentsEpic = action$ =>
    action$.pipe(
        ofType(EDIT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map(action => incidentActions.incidentsRefreshRequest())
    );

const saveIncidentSuccessStatisticsRefreshEpic = action$ =>
    action$.pipe(
        ofType(EDIT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map(action => userVenueSecurityFirmActions.userVenueSecurityFirmQuietLoadStatistics())
    );

const saveEditIncidentSuccessMessageEpic = action$ =>
    action$.pipe(
        ofType(EDIT_INCIDENT_SAVE_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Incident saved"))
    );

const getIncidentApprovalFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_REQUIRING_APPROVAL),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistoryFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                true, true, "")
                .pipe(
                    map(response => incidentActions.incidentsFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "getIncidentApprovalFilterEpic.getIncidentHistoryFilter", incidentActions.incidentsFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentApprovalFilterEpic", incidentActions.incidentsFilterRequestFailure))
    );

const draftIncidentUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentUploadRequestSuccess(response.files)),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentUploadRequestFailure))
    );

const draftIncidentUploadBase64Epic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadBase64File(action["file"], action["fileType"], action["fileName"], action["poiGuid"], action["appearanceGuid"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentUploadRequestSuccess(response.files)),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentUploadRequestFailure))
    );


const editIncidentUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => editIncidentUploadRequestSuccess(response.files)),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentUploadRequestFailure))
    );

const editIncidentPatronUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_PATRON_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => editIncidentPatronUploadRequestSuccess(action['patronIndex'], response.files)),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentPatronUploadRequestFailure))
    );

const draftIncidentPatronUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentPatronUploadRequestSuccess(action['patronIndex'], response.files)),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentPatronUploadRequestFailure))
    );

const editIncidentchecklistItemUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => editIncidentChecklistItemUploadRequestSuccess(response.files, action['checklistTemplateItemId'])),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentChecklistItemUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", editIncidentChecklistItemUploadRequestFailure))
    );

const editIncidentchecklistItemUploadSuccessEpic = action$ =>
    action$.pipe(
        ofType(EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS),
        debounce(() => timer(1000)),
        map((action: any) => notificationSuccessShow("Attachment Uploaded"))
    );

const loadIncidentChecklistsEpic = (action$, state$) =>
    action$.pipe(
        ofType(EDIT_INCIDENT_CHECKLISTS_REQUEST),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.loadIncidentChecklists(state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.editIncident.incident)
                .pipe(
                    map(response => editIncidentChecklistsRequestSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident checklists", editIncidentChecklistsRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident checklists", editIncidentChecklistsRequestFailure))
    );


const draftIncidentchecklistItemUploadEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fileServiceApi.uploadFiles(action["files"])
                .pipe(
                    map(response => draftIncidentActions.draftIncidentChecklistItemUploadRequestSuccess(response.files, action['checklistTemplateItemId'])),
                    catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentChecklistItemUploadRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to upload files", draftIncidentActions.draftIncidentChecklistItemUploadRequestFailure))
    );

const draftIncidentchecklistItemUploadSuccessEpic = action$ =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS),
        debounce(() => timer(1000)),
        map((action: any) => notificationSuccessShow("Attachment Uploaded"))
    );

const draftLoadIncidentChecklistsEpic = (action$, state$) =>
    action$.pipe(
        ofType(draftIncidentActions.DRAFT_INCIDENT_CHECKLISTS_REQUEST),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.loadIncidentChecklists(state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                state.draftIncident.incident)
                .pipe(
                    map(response => draftIncidentActions.draftIncidentChecklistsRequestSuccess(response.incident)),
                    catchError(error => notifyErrorMessage(error, "Failed to load incident checklists", draftIncidentActions.draftIncidentChecklistsRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load incident checklists", draftIncidentActions.draftIncidentChecklistsRequestFailure))
    );

const getIncidentSnapshotSummaryRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentActions.INCIDENTS_SNAPSHOT_SUMMARY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getSnapshotSummary(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId
            )
                .pipe(
                    map(response => incidentActions.incidentsSnapshotSummaryRequestSuccess(response.snapshotSummary)),
                    catchError(error => notifyError(error, "getIncidentSnapshotSummaryRequestEpic.approveIncidents", incidentActions.incidentsSnapshotSummaryRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentSnapshotSummaryRequestEpic", incidentActions.incidentsSnapshotSummaryRequestFailure))
    );

const userDetailsRequestSuccessSnapshotSummaryRequestEpic = action$ =>
    action$.pipe(
        ofType(USER_DETAILS_REQUEST_SUCCESS),
        map((action: any) => incidentActions.incidentsSnapshotSummaryRequest())
    );

const buildDocumentRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(incidentDocumentActions.INCIDENT_DOCUMENT_BUILD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.buildDocument(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueEventId,
                action['incidentId'],
                action['incidentCategoryTypeDocumentId']
            )
                .pipe(
                    map(response => incidentDocumentActions.incidentDocumentBuildRequestSuccess(response.incidentDocument)),
                    catchError(error => notifyError(error, "buildDocumentRequestEpic.approveIncidents", incidentDocumentActions.incidentDocumentBuildRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "buildDocumentRequestEpic", incidentDocumentActions.incidentDocumentBuildRequestFailure))
    );

export const incidentEpics = combineEpics(
    createIncidentEpic,
    incidentOptionsRequestEpic,
    getIncidentFilterEpic,
    getIncidentFilterSuccessEpic,
    getIncidentsEpic,
    getIncidentsApproveSelectedEpic,
    getIncidentsApproveSelectedSuccessEpic,
    getIncidentsRefreshEpic,
    incidentResetOnUserChangeEpic,
    draftIncidentResetOnUserChangeEpic,
    saveDraftIncidentEpic,
    saveDraftIncidentCheckReportingPeriodCurrentDateEpic,
    saveDraftIncidentSuccessMessageEpic,
    loadIncidentEpic,
    editIncidentResetOnUserChangeEpic,
    saveEditIncidentEpic,
    saveEditIncidentCheckReportingPeriodCurrentDateEpic,
    saveEditIncidentSuccessMessageEpic,
    saveEditIncidentSuccessReloadIncidentsEpic,
    getIncidentApprovalFilterEpic,
    draftIncidentUploadEpic,
    editIncidentUploadEpic,
    incidentResetOnVenueChangeEpic,
    draftIncidentResetOnVenueChangeEpic,
    editIncidentPatronUploadEpic,
    draftIncidentPatronUploadEpic,
    editIncidentchecklistItemUploadEpic,
    editIncidentchecklistItemUploadSuccessEpic,
    loadIncidentChecklistsEpic,
    draftIncidentchecklistItemUploadEpic,
    draftIncidentchecklistItemUploadSuccessEpic,
    draftLoadIncidentChecklistsEpic,
    draftIncidentUploadBase64Epic,
    getIncidentSnapshotSummaryRequestEpic,
    userDetailsRequestSuccessSnapshotSummaryRequestEpic,
    buildDocumentRequestEpic,
    saveDraftIncidentSuccessStatisticsRefreshEpic,
    saveIncidentSuccessStatisticsRefreshEpic
);