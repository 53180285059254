import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../../../common/CardTypography';
import CardTitle from '../../../../common/CardTitle';
import ErrorButton from '../../../../controls/ErrorButton';
import { styles } from '../../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../../common/dto/AusComply.dtos";

export interface ISecurityFirmDescendantCardProps {
    securityFirmDescendant: AusComplyDtos.SecurityFirmDescendant;
    onClick?: Function;
    onDelete?: Function;
    classes: any;
    theme: any;
}

class SecurityFirmDescendantCard extends React.Component<ISecurityFirmDescendantCardProps, any> {
    constructor(props: ISecurityFirmDescendantCardProps) {
        super(props)
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete() {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.securityFirmDescendant)
        }
    }

    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        return (
            <Card style={{ marginTop: '3px', marginBottom: '6px' }} className={className}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Security Firm</CardTitle>
                            <CardTypography><b>{this.props.securityFirmDescendant.descendantSecurityFirmName}</b></CardTypography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Descendant of</CardTitle>
                            <CardTypography>{this.props.securityFirmDescendant.securityFirmName}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Created</CardTitle>
                            <CardTypography>{this.props.securityFirmDescendant.enteredDisplay}</CardTypography>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                        {this.props.onDelete && (
                                <ErrorButton text={"Remove"} onClick={this.onDelete} />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(SecurityFirmDescendantCard);