import { connect } from 'react-redux'
import PlayTraxIncidents from '../components/incidents/playtrax/PlayTraxIncidents'
import { withRouter } from "react-router";
import { incidentsFilterRequest, incidentsRequest, incidentsSelectForApproval, incidentsSelectAllForApproval, incidentsApproveSelectedRequest} from '../common/actions/incidents'
import { editIncidentClear } from '../common/actions/editIncident'
import { printShow } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = state => {
    return {
        isLoading: state.incidents.isLoading,
        isApproving: state.incidents.isApproving,
        items: state.incidents.items,
        paging: state.incidents.paging,
        filter: state.incidents.filter,
        initialFilterLoaded: state.incidents.initialFilterLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetFilter:(forEntryPage) => {
            dispatch(incidentsFilterRequest(true, forEntryPage))
        },
        onRefresh:(incidentFilter) => {
            dispatch(incidentsRequest(incidentFilter))
        },
        onIncidentsSelectForApproval:(incidentId, isSelected) => {
            dispatch(incidentsSelectForApproval(incidentId, isSelected))
        },
        onIncidentsSelectAllForApproval:(isSelected) => {
            dispatch(incidentsSelectAllForApproval(isSelected))
        },
        onIncidentsApproveSelectedRequest:() => {
            dispatch(incidentsApproveSelectedRequest())
        },
        onIncidentClear:() => {
            dispatch(editIncidentClear())
        },
        onPrint: () => {
            dispatch(printShow())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message))
        }
    }
}

const PlayTraxIncidentsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayTraxIncidents))

export default PlayTraxIncidentsContainer