import { connect } from 'react-redux' ;
import PrintQueueEmbedded from '../components/print/PrintQueueEmbedded';
import * as printActions from '../common/actions/print';

const mapStateToProps = (state, props) => {
    return {
        queue: state.print.queue
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRefresh:() => {
            dispatch(printActions.printQueueRequest())
        },
        onDownloaded:(reportGenerationId) => {
            dispatch(printActions.printQueueDownloadRequest(reportGenerationId))
        },
        onDismissed:(reportGenerationId) => {
            dispatch(printActions.printQueueDismissRequest(reportGenerationId))
        },
        onDismissAll:() => {
            dispatch(printActions.printQueueDismissAllRequest())
        }
    }
}

const PrintQueueEmbeddedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintQueueEmbedded)

export default PrintQueueEmbeddedContainer