import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as functionalAreaActions from "../actions/functionalArea";

const initialState = {
    error: null,
    errors: {},
    functionalAreas: {
        isLoading: false,
        filter: {},
        functionalAreas: [],
        paging: { pageSize: 10, page: 1 }
    },
    functionalArea: {
        functionalAreaId: -1,
        isLoading: false,
        isSaving: false,
        functionalArea: { functionalAreaId: 0 }
    },
};


function loadingFunctionalAreas(state, isLoading) {
    let newState = update(state, {
        functionalAreas: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFunctionalAreas(state, functionalAreas, filter, paging) {
    let newState = update(state, {
        functionalAreas: {
            functionalAreas: { $set: functionalAreas },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingFunctionalArea(state, isLoading) {
    let newState = update(state, {
        functionalArea: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFunctionalArea(state, functionalArea) {
    let newState = update(state, {
        functionalArea: {
            functionalArea: { $set: functionalArea }
        }
    });
    return newState;
}

function savingFunctionalArea(state, isSaving) {
    let newState = update(state, {
        functionalArea: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFunctionalAreaId(state, functionalAreaId) {
    let newState = update(state, {
        functionalArea: {
            functionalAreaId: { $set: functionalAreaId }
        }
    });
    return newState;
}

export default function functionalAreas(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case functionalAreaActions.FUNCTIONALAREAS_REFRESH:
            return loadingFunctionalAreas(setFunctionalAreaId(state, -1), true);
        case functionalAreaActions.FUNCTIONALAREAS_REQUEST:
            return setFunctionalAreas(loadingFunctionalAreas(setFunctionalAreaId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case functionalAreaActions.FUNCTIONALAREAS_REQUEST_SUCCESS:
            return setFunctionalAreas(loadingFunctionalAreas(state, false), action.data, action.filter, action.paging);
        case functionalAreaActions.FUNCTIONALAREAS_REQUEST_FAILURE:
            return loadingFunctionalAreas(state, false);
        case functionalAreaActions.FUNCTIONALAREA_RESET:
            return setFunctionalAreaId(state, -1);
        case functionalAreaActions.FUNCTIONALAREA_REQUEST:
            return setFunctionalArea(loadingFunctionalArea(setFunctionalAreaId(state, action.functionalAreaId), true), {});
        case functionalAreaActions.FUNCTIONALAREA_REQUEST_SUCCESS:
            return setFunctionalArea(loadingFunctionalArea(state, false), action.functionalArea);
        case functionalAreaActions.FUNCTIONALAREA_REQUEST_FAILURE:
            return loadingFunctionalArea(setFunctionalAreaId(state, -1), false);
        case functionalAreaActions.FUNCTIONALAREA_CREATE_REQUEST:
            return setFunctionalArea(loadingFunctionalArea(setFunctionalAreaId(state, 0), true), {});
        case functionalAreaActions.FUNCTIONALAREA_CREATE_REQUEST_SUCCESS:
            return setFunctionalArea(loadingFunctionalArea(state, false), action.functionalArea);
        case functionalAreaActions.FUNCTIONALAREA_CREATE_REQUEST_FAILURE:
            return loadingFunctionalArea(setFunctionalAreaId(state, -1), false);
        case functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST:
            return savingFunctionalArea(state, true);
        case functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST_SUCCESS:
            return setFunctionalArea(savingFunctionalArea(setFunctionalAreaId(state, -1), false), action.functionalArea);
        case functionalAreaActions.FUNCTIONALAREA_SAVE_REQUEST_FAILURE:
            return savingFunctionalArea(state, false);
        case functionalAreaActions.FUNCTIONALAREA_UPDATE:
            return setFunctionalArea(state, action.functionalArea);

    }

    return state;
}