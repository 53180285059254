import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultButton from '../../controls/DefaultButton';

export interface IIncidentWizardPeopleGroupProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onRemove: Function;
    onDone: Function;
    theme: any;
    classes: any;
}

interface IIncidentWizardPeopleGroupState {
    confirm: boolean;
}

class IncidentWizardPeopleGroup extends Component<IIncidentWizardPeopleGroupProps, IIncidentWizardPeopleGroupState> {
    constructor(props: IIncidentWizardPeopleGroupProps) {
        super(props)
        this.state = {
            confirm: false
        };
        this.confirm = this.confirm.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleNotConfirm = this.handleNotConfirm.bind(this);
        this.onPeopleChanged = this.onPeopleChanged.bind(this);
        this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
    }

    componentDidMount() {
        this.setState({ confirm: false });
    }

    onDescriptionChanged(value) {
        let incident = { ...this.props.incident };
        incident.involvedDescription = value;
        this.props.onUpdateIncident(incident);
    }

    onPeopleChanged(value) {
        let incident = { ...this.props.incident };
        incident.numberInvolved = value;
        this.props.onUpdateIncident(incident);
    }

    confirm() {
        this.setState({ confirm: true });
    }

    handleConfirm() {
        this.setState({ confirm: false });
        this.props.onRemove();
    }

    handleNotConfirm() {
        this.setState({ confirm: false });
    }

    render() {
        const { classes } = this.props;

        let numberInvolved = this.props.incident.numberInvolved;
        if (!numberInvolved) {
            numberInvolved = 0;
        }
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <NumericUpDownControlGroup
                                text="Number of People Involved"
                                value={numberInvolved}
                                onChange={this.onPeopleChanged}
                                editable={true} />
                            <TextAreaControlGroup text="Person(s) Descriptions"
                                defaultValue={this.props.incident.involvedDescription}
                                onChange={this.onDescriptionChanged}
                                readonly={false} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={3} sm={4}></Grid>
                        <Grid item xs={6} sm={4}>
                            {false && (
                                <Button variant="outlined" size="small" color="secondary"
                                    style={{ width: '100%', marginTop: '40px' }}
                                    className={classes.linearOutlineColorError}
                                    onClick={() => this.confirm()} >
                                    Remove
                            </Button>
                            )}
                        </Grid>
                        <Grid item xs={3} sm={4}></Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.confirm}
                    onClose={this.handleNotConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this group from the incident?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DefaultButton onClick={this.handleNotConfirm} style={{ width: 'auto', marginRight: '10px' }}>No</DefaultButton>
                        <DefaultButton text={"Yes"} onClick={this.handleConfirm} style={{ width: 'auto', marginRight: '10px' }} autoFocus></DefaultButton>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentWizardPeopleGroup);