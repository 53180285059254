import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import { RadioOption } from '../../common/dto/common';
import RadioControl from './RadioControl';

export interface IYesNoControlGroupProps {
    text?: string;
    name: string;
    error?: string;
    value?: boolean;
    reverse?: boolean;
    readonly?: boolean;
    onChanged?: Function;
    labelAbove?: boolean;
}

class YesNoControlGroup extends React.PureComponent<IYesNoControlGroupProps, any> {

    constructor(props: IYesNoControlGroupProps) {
        super(props);
        this.state = {
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChanged) {
            if (this.props.reverse) {
                if (value.value === "no") {
                    this.props.onChanged(true);
                } else {
                    this.props.onChanged(false);
                }
            } else {
                if (value.value === "yes") {
                    this.props.onChanged(true);
                } else {
                    this.props.onChanged(false);
                }
            }
        }
    }

    render() {

        let options: RadioOption[] = [
            { id: "yes", name: "Yes" },
            { id: "no", name: "No" }
        ];

        let selected = this.props.value ? "yes" : "no";
        if (this.props.reverse) {
            selected = this.props.value ? "no" : "yes";
        }

        return (
            <ControlGroup 
                text={this.props.text} 
                error={this.props.error ? true : false} 
                errorText={this.props.error} 
                labelAbove={this.props.labelAbove}>
                <RadioControl 
                    selected={selected} 
                    options={options} 
                    readonly={this.props.readonly} 
                    name={this.props.name} 
                    onChange={(val) => this.onChange(val)} />
            </ControlGroup>
        );
    }
}

export default YesNoControlGroup;