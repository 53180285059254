import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageLayout from '../../layout/PageLayout';
import ControlGroup from '../../controls/ControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import RegisterVenueDetails from '../../register/venue/RegisterVenueDetails';
import RegisterVenueAddress from '../../register/venue/RegisterVenueAddress';
import RegisterVenueType from '../../register/venue/RegisterVenueType';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';
import VenueTabs from './VenueTabs';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IVenueEditProps {
    venueId: number;
    isLoading: boolean;
    isSaving: boolean;
    canEdit: boolean;
    venue: AusComplyDtos.Venue;
    states: AusComplyDtos.State[],
    industryCategories: AusComplyDtos.IndustryCategory[],
    precincts: AusComplyDtos.VenuePrecinct[],
    licenceTypes: AusComplyDtos.VenueLicenceType[],
    tradingHours: AusComplyDtos.VenueTradingHours[],
    error: string;
    errors: any;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class VenueEdit extends Component<IVenueEditProps, any> {
    constructor(props: IVenueEditProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.venueId);
                //this.props.history.push('/admin/venues');
            }
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let venue = { ...this.props.venue };
        venue[fieldName] = value;
        this.props.onUpdate(venue);
    }

    onUpdate(venue) {
        this.props.onUpdate(venue);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        //this.props.onLoad(this.props.venueId);
        this.props.history.push('/admin/venues');
    }

    render() {
        return (
            <PageLayout
                headerText={"Venue"}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                footerCenterContent={<PrimaryButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</PrimaryButton>}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Details"} history={this.props.history} />
                <RegisterVenueDetails venue={this.props.venue} errors={this.props.errors} onUpdate={this.onUpdate} />
                <RegisterVenueAddress venue={this.props.venue} states={this.props.states} canMoveMarker={true} errors={this.props.errors} onUpdate={this.onUpdate} />
                <RegisterVenueType
                    venue={this.props.venue}
                    industryCategories={this.props.industryCategories}
                    precincts={this.props.precincts}
                    licenceTypes={this.props.licenceTypes}
                    tradingHours={this.props.tradingHours}
                    canEditVenueIndustryCategory={true}
                    errors={this.props.errors}
                    onUpdate={this.onUpdate} />

                {!this.props.venue.originalRegistrationComplete && (
                    <CheckboxControlGroup
                        label={" "}
                        text={"Registration Complete"}
                        defaultValue={this.props.venue.registrationComplete}
                        onChanged={(value) => this.onValueChanged("registrationComplete", value)} />
                )}
            </PageLayout>
        );
    }
}