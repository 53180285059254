
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionServerServiceApi } from '../services/facialRecognitionServerService';

import {
    facialRecognitionServersRequest,
    FACIAL_RECOGNITION_SERVERS_REQUEST,
    facialRecognitionServersRequestSuccess,
    facialRecognitionServersRequestFailure,
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST,
    FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS,
    facialRecognitionServersTestRequestSuccess,
    facialRecognitionServersTestRequestFailure,
    FACIAL_RECOGNITION_SERVER_REQUEST,
    facialRecognitionServerRequestSuccess,
    facialRecognitionServerRequestFailure,
    FACIAL_RECOGNITION_SERVER_CREATE_REQUEST,
    facialRecognitionServerCreateRequestSuccess,
    facialRecognitionServerCreateRequestFailure,
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST,
    facialRecognitionServerUpsertRequestSuccess,
    facialRecognitionServerUpsertRequestFailure,
    FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS,
    facialRecognitionServerReset,
} from "../actions/facialRecognitionServer";
import * as facialRecognitionServerActions from "../actions/facialRecognitionServer";

import {
    notifyError, notifyErrorMessage
} from './common'; 
import { notificationSuccessShow, notificationShow } from "../actions/notification";


const facialRecognitionServersRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVERS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.getServers(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionServersRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "facialRecognitionServersRequestEpic.getServers", facialRecognitionServersRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServersRequestEpic", facialRecognitionServersRequestFailure))
    );

const facialRecognitionServersTestRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVERS_TEST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.postServersTest(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['facialRecognitionServerId'])
                .pipe(
                    map(response => facialRecognitionServersTestRequestSuccess()),
                    catchError(error => notifyError(error, "facialRecognitionServersTestRequestEpic.postServersTest", facialRecognitionServersTestRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServersTestRequestEpic", facialRecognitionServersTestRequestFailure))
    );

const facialRecognitionServersTestRequestSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(FACIAL_RECOGNITION_SERVERS_TEST_REQUEST_SUCCESS),
    map((action: any) => notificationSuccessShow("Connected to Server Successfully"))
  );

const facialRecognitionServerRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionServerId'])
                .pipe(
                    map(response => facialRecognitionServerRequestSuccess(response.facialRecognitionServer)),
                    catchError(error => notifyError(error, "facialRecognitionServerRequestEpic.get", facialRecognitionServerRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerRequestEpic", facialRecognitionServerRequestFailure))
    );

const facialRecognitionServerCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVER_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'])
                .pipe(
                    map(response => facialRecognitionServerCreateRequestSuccess(response.facialRecognitionServer)),
                    catchError(error => notifyError(error, "facialRecognitionServerCreateEpic.create", facialRecognitionServerCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerCreateEpic", facialRecognitionServerCreateRequestFailure))
    );

const facialRecognitionServerUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionServer.facialRecognitionServer.facialRecognitionServer)
                .pipe(
                    map(response => facialRecognitionServerUpsertRequestSuccess(response.facialRecognitionServer)),
                    catchError(error => notifyError(error, "facialRecognitionServerUpsertEpic.upsert", facialRecognitionServerUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerUpsertEpic", facialRecognitionServerUpsertRequestFailure))
    );

const facialRecognitionServerUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionServerReset())
    );

const facialRecognitionServerUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(FACIAL_RECOGNITION_SERVER_UPSERT_REQUEST_SUCCESS),
        map((action: any) => facialRecognitionServersRequest(action['facialRecognitionServer']['venueId']))
    );

const facialRecognitionServerCloneEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionServerActions.FACIAL_RECOGNITION_SERVER_CLONE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionServerServiceApi.clone(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionServerId'],
                action['toVenueId'])
                .pipe(
                    map(response => facialRecognitionServerActions.facialRecognitionServerCloneRequestSuccess()),
                    catchError(error => notifyError(error, "facialRecognitionServerCloneEpic.clone", facialRecognitionServerActions.facialRecognitionServerCloneRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerCloneEpic", facialRecognitionServerActions.facialRecognitionServerCloneRequestFailure))
    );

const facialRecognitionServerCloneSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionServerActions.FACIAL_RECOGNITION_SERVER_CLONE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow('Server cloned'))
    );

export const facialRecognitionServerEpics = combineEpics(
    facialRecognitionServersRequestEpic,
    facialRecognitionServersTestRequestEpic,
    facialRecognitionServersTestRequestSuccessEpic,
    facialRecognitionServerRequestEpic,
    facialRecognitionServerCreateEpic,
    facialRecognitionServerUpsertEpic,
    facialRecognitionServerUpsertSuccessEpic,
    facialRecognitionServerUpsertSuccessRefreshEpic,
    facialRecognitionServerCloneEpic,
    facialRecognitionServerCloneSuccessEpic
);