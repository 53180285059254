import { makeActionCreator } from './helpers';

export const
NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST",
NOTIFICATIONS_REQUEST_SUCCESS = "NOTIFICATIONS_REQUEST_SUCCESS",
NOTIFICATIONS_REQUEST_FAILURE = "NOTIFICATIONS_REQUEST_FAILURE",
NOTIFICATIONS_ACTION_REQUEST = "NOTIFICATIONS_ACTION_REQUEST",
NOTIFICATIONS_ACTION_REQUEST_SUCCESS = "NOTIFICATIONS_ACTION_REQUEST_SUCCESS",
NOTIFICATIONS_ACTION_REQUEST_FAILURE = "NOTIFICATIONS_ACTION_REQUEST_FAILURE",
NOTIFICATIONS_ACTIONS_REQUEST = "NOTIFICATIONS_ACTIONS_REQUEST",
NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS = "NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS",
NOTIFICATIONS_ACTIONS_REQUEST_FAILURE = "NOTIFICATIONS_ACTIONS_REQUEST_FAILURE";


export function notificationsRequest() {
    return {
        type: NOTIFICATIONS_REQUEST
    };
}

export function notificationsRequestSuccess(groupedNotifications, alerts, groupNotifications) {
    return {
        type: NOTIFICATIONS_REQUEST_SUCCESS,
        groupedNotifications,
        alerts,
        groupNotifications
    };
}

export function notificationsRequestFailure(error) {
    return {
        type: NOTIFICATIONS_REQUEST_FAILURE,
        error
    };
}

export function notificationsActionRequest(notificationId, action) {
    return {
        type: NOTIFICATIONS_ACTION_REQUEST,
        notificationId,
        action
    };
}

export function notificationsActionRequestSuccess() {
    return {
        type: NOTIFICATIONS_ACTION_REQUEST_SUCCESS
    };
}

export function notificationsActionRequestFailure(error) {
    return {
        type: NOTIFICATIONS_ACTION_REQUEST_FAILURE,
        error
    };
}

export function notificationsActionsRequest(notificationIds, action) {
    return {
        type: NOTIFICATIONS_ACTIONS_REQUEST,
        notificationIds,
        action
    };
}

export function notificationsActionsRequestSuccess() {
    return {
        type: NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS
    };
}

export function notificationsActionsRequestFailure(error) {
    return {
        type: NOTIFICATIONS_ACTIONS_REQUEST_FAILURE,
        error
    };
}