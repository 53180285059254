import React from "react";

const DeleteOutlinedIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M59,19.5H48.7V15c0-0.9-0.8-1.6-1.9-1.6H33.1c-1,0-1.9,0.7-1.9,1.6v4.5H21c-1,0-1.9,0.7-1.9,1.6v7.6c0,0.9,0.8,1.6,1.9,1.6h0.6l1.7,34.8c0,0.9,0.9,1.5,1.9,1.5h29.6c1,0,1.8-0.7,1.9-1.5l1.7-34.8H59c1,0,1.9-0.7,1.9-1.6v-7.6C60.8,20.2,60,19.5,59,19.5zM35,16.6h10v2.9H35V16.6z M53,63.4H27l-1.6-33.1h29.3L53,63.4z M57.1,27H22.9v-4.3h34.2V27z M32.5,58.5c1,0,1.9-0.7,1.9-1.6v-20c0-0.9-0.8-1.6-1.9-1.6c-1,0-1.9,0.7-1.9,1.6v20C30.6,57.7,31.4,58.5,32.5,58.5z M40,58.5c1,0,1.9-0.7,1.9-1.6v-20c0-0.9-0.8-1.6-1.9-1.6c-1,0-1.9,0.7-1.9,1.6v20C38.1,57.7,39,58.5,40,58.5z M47.5,58.5c1,0,1.9-0.7,1.9-1.6v-20c0-0.9-0.8-1.6-1.9-1.6c-1,0-1.9,0.7-1.9,1.6v20C45.7,57.7,46.5,58.5,47.5,58.5z" />
        </g>
    </svg>
);

export default DeleteOutlinedIcon;
