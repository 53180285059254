
import React from 'react';

const CameraAltIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 80 80"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M65.3,22H54.6l-0.3-1.1c-1-4.5-5-7.6-9.6-7.6h-9.5c-4.6,0-8.5,3.1-9.6,7.6L25.4,22H14.7c-4.2,0-7.6,3.4-7.6,7.6v29c0,4.4,3.6,8,8,8h49.8c4.4,0,8-3.6,8-8v-29C72.9,25.4,69.5,22,65.3,22z M69.6,58.7c0,2.6-2.1,4.7-4.7,4.7H15.1c-2.6,0-4.7-2.1-4.7-4.7v-29c0-2.4,1.9-4.4,4.4-4.4h12c0.8,0,1.4-0.5,1.6-1.3l0.5-2.4c0.7-3,3.3-5,6.4-5h9.5c3,0,5.7,2.1,6.4,5l0.6,2.4c0.2,0.7,0.8,1.3,1.6,1.3h12c2.4,0,4.4,1.9,4.4,4.4V58.7z M18.2,30.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2S19.4,30.1,18.2,30.1z M40,30.6c-7.6,0-13.8,6.2-13.8,13.8c0,7.6,6.2,13.8,13.8,13.8c7.6,0,13.8-6.2,13.8-13.8C53.8,36.8,47.6,30.6,40,30.6z M40,55c-5.8,0-10.5-4.7-10.5-10.5c0-5.8,4.7-10.5,10.5-10.5c5.8,0,10.5,4.7,10.5,10.5C50.5,50.2,45.8,55,40,55z" />
        </g>
    </svg>
);

export default CameraAltIcon;


