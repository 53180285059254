
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentServiceApi } from '../services/incidentService';

import {
    INCIDENT_FIND_FILTER_REQUEST,
    incidentFindFilterRequestSuccess,
    incidentFindFilterRequestFailure,
    INCIDENT_FIND_REQUEST,
    INCIDENT_FIND_FILTER_REQUEST_SUCCESS,
    INCIDENT_FIND_REFRESH_REQUEST,
    incidentFindRequest,
    incidentFindRequestSuccess,
    incidentFindRequestFailure,
    incidentFindReset
} from "../actions/incidentFind";

import { USER_CHANGED } from '../actions/userLogin';
import { 
    notifyError, notifyErrorMessage
  } from './common'; 
import { notificationShow } from "../actions/notification";


const incidentFindResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => incidentFindReset())
    );

const getIncidentFindFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FIND_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistoryFilter(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,  
                state.user.details.userSession.user.venueEventId, 
                action["reset"] ,false, "")
                .pipe(
                    map(response => incidentFindFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "getIncidentFindFilterEpic.getIncidentHistoryFilter", incidentFindFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentFindFilterEpic", incidentFindFilterRequestFailure))
    );

const getIncidentFindEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FIND_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistory(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,  
                state.user.details.userSession.user.venueEventId, 
                action["incidentFilter"], false)
                .pipe(
                    map(response => incidentFindRequestSuccess(response.incidents, response.paging, response.filter)),
                    catchError(error => notifyError(error, "getIncidentFindEpic.getIncidentHistory", incidentFindRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentFindEpic", incidentFindRequestFailure))
    );

const getIncidentFindFilterSuccessEpic = action$ =>
    action$.pipe(
        ofType(INCIDENT_FIND_FILTER_REQUEST_SUCCESS),
        map((action: any) =>
            incidentFindRequest(action['incidentFilter'])
        )
    );

const getIncidentFindRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_FIND_REFRESH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.getIncidentHistory(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,  
                state.user.details.userSession.user.venueEventId, 
                state.incidents.filter, false)
                .pipe(
                    map(response => incidentFindRequestSuccess(response.incidents, response.paging, response.filter)),
                    catchError(error => notifyError(error, "getIncidentFindRefreshEpic.getIncidentHistory", incidentFindRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "getIncidentFindRefreshEpic", incidentFindRequestFailure))
    );

export const incidentFindEpics = combineEpics(
    incidentFindResetOnUserChangeEpic,
    getIncidentFindFilterEpic,
    getIncidentFindEpic,
    getIncidentFindFilterSuccessEpic,
    getIncidentFindRefreshEpic
);