import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentFlagType } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CheckboxControl from '../../controls/CheckboxControl';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup'
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import Box from '@material-ui/core/Box';


export interface ICommLogFlagsProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class CommLogFlags extends Component<ICommLogFlagsProps, any> {
    constructor(props: ICommLogFlagsProps) {
        super(props)
        this.state = {
            incidentFlags: [],
            expanded: false
        };
        this.flagChanged = this.flagChanged.bind(this);
        this.onFlagsChanged = this.onFlagsChanged.bind(this);
        this.textChanged = this.textChanged.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoad()
    }

    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.incident.incidentFlags) !== JSON.stringify(preProps.incident.incidentFlags)) {
            this.onLoad();
        }
    }

    onLoad() {
        try {
            let incidentFlags: any[] = [];
            if (this.props.incidentOptions && this.props.incidentOptions.flags) {
                this.props.incidentOptions.flags.map(incidentFlagType => {

                    let selected = false;
                    let text = "";

                    if (this.props.incident.incidentFlags) {
                        let selectedIncidentFlag = this.props.incident.incidentFlags.find(i => i.incidentFlagTypeId === incidentFlagType.incidentFlagTypeId);
                        if (selectedIncidentFlag) {
                            selected = true;
                            text = selectedIncidentFlag.fieldValue;
                        }
                    }

                    let incidentFlag = {
                        incidentFlagTypeId: incidentFlagType.incidentFlagTypeId,
                        selected: selected,
                        text: text,
                        name: incidentFlagType.name,
                        additionalField: incidentFlagType.additionalField,
                        fieldLabel: incidentFlagType.fieldLabel,
                        parentIncidentFlagTypeId: incidentFlagType.parentIncidentFlagTypeId
                    };
                    incidentFlags.push(incidentFlag);
                });
            }

            this.setState({
                incidentFlags
            });
        } catch (err) {
            commonLogic.trackException(err, "CommLogFlags.componentDidMount", {})
        }
    }

    flagChanged(item, value) {
        const updatedFlags = this.state.incidentFlags.map(l => Object.assign({}, l));
        updatedFlags.forEach(i => {
            if (i.incidentFlagTypeId === item.incidentFlagTypeId) {
                i.selected = value;
            }
        });
        this.setChildItems(updatedFlags);
        this.setState({ incidentFlags: updatedFlags }, () => this.onFlagsChanged());
    }

    setChildItems(items) {
        items.forEach(i => {
            if (i.parentIncidentFlagTypeId > 0 && !this.isParentEnabled(i, items)) {
                i.selected = false;
                i.text = "";
            }
        });
    }

    isParentEnabled(item, items) {
        let parentIsEnabled = false;
        items.forEach(i => {
            if (i.incidentFlagTypeId === item.parentIncidentFlagTypeId) {
                parentIsEnabled = i.selected;
            }
        });
        return parentIsEnabled;
    }

    onFlagsChanged() {
        // get the old and new incidentCategoryIds
        let previousIds = this.props.incident.incidentFlags.map(item => item.incidentFlagTypeId);
        let currentIds = this.state.incidentFlags.filter(item => item.selected).map(item => item.incidentFlagTypeId);

        // remove
        let newIncidentFlags = this.props.incident.incidentFlags.filter(item => currentIds.indexOf(item.incidentFlagTypeId) > -1);

        // update
        newIncidentFlags.forEach(incidentFlag => {
            this.state.incidentFlags.filter(item => item.incidentFlagTypeId === incidentFlag.incidentFlagTypeId).forEach(item => {
                incidentFlag.fieldValue = item.text;
            });
        });

        // add
        this.state.incidentFlags.filter(item => item.selected && previousIds.indexOf(item.incidentFlagTypeId) === -1).forEach(item => {
            newIncidentFlags.push({
                incidentFlagId: 0,
                incidentId: 0,
                incidentFlagTypeId: item.incidentFlagTypeId,
                fieldValue: item.text,
                incidentFlagType: new IncidentFlagType()
            });
        });

        let newIncident = this.props.incident;
        newIncident.incidentFlags = newIncidentFlags;
        this.props.onUpdateIncident(newIncident)
    }

    textChanged(item, value) {
        let updatedFlags = [...this.state.incidentFlags];

        // update
        let flag = updatedFlags.find(flag => item.incidentFlagTypeId === flag.incidentFlagTypeId)
        if (flag) {
            flag.text = value;
        }

        this.setState({ incidentFlags: updatedFlags }, () => this.onFlagsChanged());

    }

    render() {
        let self = this;
        const incidentFlags = this.state.incidentFlags;
        let flags = incidentFlags.map((item, index) => {
            let enabled = true;
            let selected = item.selected;
            let text = item.text;
            if (item.parentIncidentFlagTypeId > 0) {
                enabled = this.isParentEnabled(item, incidentFlags);
            }
            return (
                <div key={"incidentFlag-" + item.incidentFlagTypeId}>
                    <CheckboxControl text={item.name} defaultValue={selected} onChanged={value => this.flagChanged(item, value)} readonly={!enabled} />
                    {selected && item.additionalField && (
                        <TextAreaControlGroup text={item.fieldLabel} defaultValue={text} labelAbove={true} onChange={value => this.textChanged(item, value)} />
                    )}
                </div>
            )
        });
        let selectedFlags = incidentFlags.filter(item => item.selected).map((item, index) => {
            let enabled = true;
            let selected = item.selected;
            let text = item.text;
            if (item.parentIncidentFlagTypeId > 0) {
                enabled = this.isParentEnabled(item, incidentFlags);
            }
            return (
                <div key={"incidentFlag-" + item.incidentFlagTypeId}>
                    <CheckboxControl text={item.name} defaultValue={selected} onChanged={value => this.flagChanged(item, value)} readonly={!enabled} />
                    {selected && item.additionalField && (
                        <TextAreaControlGroup text={item.fieldLabel} defaultValue={text} labelAbove={true} onChange={value => this.textChanged(item, value)} />
                    )}
                </div>
            )
        });

        if (selectedFlags.length === 0) {
            selectedFlags = (<Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap"><p>Expand to select flags</p></Box>)
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Flags"}
                    isExpanded={this.state.expanded}
                    hideLabel={"Collapse"}
                    showLabel={"Expand"}
                    onExpanded={isExpanded => { self.setState({ expanded: isExpanded }); }}
                    expanded={
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {flags}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>}
                    collapsed={
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {selectedFlags}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>}
                />
            </SwipeContainer>
        );
    }
}