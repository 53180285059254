import React, { Component } from 'react';
import UserRolesContainer from '../../containers/UserRolesContainer'

export interface IVenueUsers {
    venueId: number;
}

export default class VenueUsers extends Component<any, any> {
    render() {
        return (
            <UserRolesContainer venueId={this.props.venueId}/>
        );
    }
}