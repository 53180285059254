import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as noteActions from "../actions/notes";

const initialState = {
    error: null,
    isLoading: false,
    isSaving: false,
    filter: {
        entityType: 0,
        entityId: 0
    },
    notes: [],
    paging: { pageSize: 10, page: 1 },
    note: { noteId: 0 },
    newNote: { noteId: 0 }
};


function loadingNotes(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setNotes(state, notes, filter, paging) {
    let newState = update(state, {
        notes: { $set: notes },
        filter: { $set: filter },
        paging: { $set: paging }
    });
    return newState;
}

function setNote(state, note) {
    let newState = update(state, {
        note: { $set: note }
    });
    return newState;
}

function resetNote(state) {
    let newState = update(state, {
        note: { $set: { noteId: 0, entityType: state.filter.entityType, entityId: state.filter.entityId, obsolete: false } },
        newNote: { $set: { noteId: 0, entityType: state.filter.entityType, entityId: state.filter.entityId, obsolete: false } }
    });
    return newState;
}

function resetExistingNote(state) {
    let newState = update(state, {
        note: { $set: { noteId: 0, entityType: state.filter.entityType, entityId: state.filter.entityId, obsolete: false } }
    });
    return newState;
}

function setNewNote(state, note) {
    let newState = update(state, {
        newNote: { $set: note }
    });
    return newState;
}

function savingNote(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

export default function notes(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case noteActions.NOTES_REFRESH:
            return loadingNotes(resetNote(state), true);
        case noteActions.NOTES_REQUEST:
            return setNotes(loadingNotes(resetNote(state), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case noteActions.NOTES_REQUEST_SUCCESS:
            return resetNote(setNotes(loadingNotes(state, false), action.data, action.filter, action.paging));
        case noteActions.NOTES_REQUEST_FAILURE:
            return loadingNotes(state, false);
        case noteActions.NOTE_SAVE_NEW_REQUEST:
        case noteActions.NOTE_SAVE_EXISTING_REQUEST:
            return savingNote(state, true);
        case noteActions.NOTE_SAVE_NEW_REQUEST_SUCCESS:
        case noteActions.NOTE_SAVE_EXISTING_REQUEST_SUCCESS:
            return savingNote(resetNote(state), false);
        case noteActions.NOTE_SAVE_EXISTING_REQUEST_FAILURE:
        case noteActions.NOTE_SAVE_NEW_REQUEST_FAILURE:
            return savingNote(state, false);
        case noteActions.NOTE_UPDATE_EXISTING:
            return setNote(state, action.note);
        case noteActions.NOTE_UPDATE_NEW:
            return setNewNote(state, action.note);
        case noteActions.NOTE_RESET_EXISTING:
            return resetExistingNote(state);

    }

    return state;
}