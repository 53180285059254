import { connect } from 'react-redux'
import VenueDocuments from '../components/venue/venueDocuments/VenueDocuments'
import {
    venueDocumentsRequest,
    venueSaveOtherDocuments,
    venueRemoveOtherDocument,
    venueSaveComplianceDocuments,
    venueRemoveComplianceDocument
} from '../common/actions/venue'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        error: state.venue.documents.error,
        isLoading: state.venue.documents.isLoading,
        isSaving: state.venue.documents.isSaving,
        otherDocuments: state.venue.documents.otherDocuments,
        otherDocumentTypes: state.venue.documents.otherDocumentTypes,
        complianceDocuments: state.venue.documents.complianceDocuments,
        complianceDocumentTypes: state.venue.documents.complianceDocumentTypes,
        associatedComplianceDocuments: state.venue.documents.associatedComplianceDocuments,
        associatedComplianceDocumentTypes: state.venue.documents.associatedComplianceDocumentTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueDocumentsRequest(venueId, false))
        },
        onSaveOther: (venueId, documentTypeId, files) => {
            dispatch(venueSaveOtherDocuments(venueId, documentTypeId, files))
        },
        onRemoveOther: (venueId, otherDocument) => {
            dispatch(venueRemoveOtherDocument(venueId, otherDocument))
        },
        onSaveCompliance: (venueId, documentTypeId, expiryDate, files) => {
            dispatch(venueSaveComplianceDocuments(venueId, documentTypeId, expiryDate, files))
        },
        onRemoveCompliance: (venueId, complianceDocument) => {
            dispatch(venueRemoveComplianceDocument(venueId, complianceDocument))
        }
    }
}

const VenueDocumentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueDocuments)

export default VenueDocumentsContainer