import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import moment from "moment";
import { USER_RESET } from '../actions/userLogin';
import * as incidentActionTemplateActions from "../actions/incidentActionTemplates";
import { commonLogic } from '../logic/common';

const initialState = {
    incidentActionTemplates: {
        isLoading: false,
        filter: {
            search: "",
            paging: {
                pageSize: 0,
                page: 1
            },
            display: "all"
        },
        incidentActionTemplates: [],
    },
    incidentActionTemplate: {
        isLoading: false,
        isSaving: false,
        incidentActionTemplate: {
            incidentActionTemplateId: -1,
            checklistTemplateItems: [],
            groupedChecklistTemplateItems: []
        },
        incidentCategoryTypes: [],
        checklistTemplateItemsVersion: 1
    }
};

function loadingIncidentActionTemplates(state, isLoading) {
    let newState = update(state, {
        incidentActionTemplates: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFilter(state, filter) {
    let newState = update(state, {
        incidentActionTemplates: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setIncidentActionTemplates(state, incidentActionTemplates, filter) {
    let newState = update(state, {
        incidentActionTemplates: {
            incidentActionTemplates: { $set: incidentActionTemplates },
            filter: { $set: filter }
        }
    });
    return newState;
}

function loadingIncidentActionTemplate(state, isLoading) {
    let newState = update(state, {
        incidentActionTemplate: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function resetIncidentActionTemplate(state) {
    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: { $set: { incidentActionTemplateId: -1 } },
        }
    });
    return newState;
}

function setIncidentActionTemplate(state, incidentActionTemplate) {
    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: { $set: incidentActionTemplate },
        }
    });
    return groupChecklistItems(newState, incidentActionTemplate);
}

function setIncidentActionTemplateObsolete(state, incidentActionTemplate) {
    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: { $set: incidentActionTemplate },
        }
    });
    return newState;
}

function setIncidentCategoryTypes(state, incidentCategoryTypes) {
    let newState = update(state, {
        incidentActionTemplate: {
            incidentCategoryTypes: { $set: incidentCategoryTypes },
        }
    });
    return newState;
}

function savingIncidentActionTemplate(state, isSaving) {
    let newState = update(state, {
        incidentActionTemplate: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function addGroupedChecklistTemplateItem(state, checklistItemType: AusComplyDtos.ChecklistItemType, position: number, parentId: number) {
    let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.incidentActionTemplate.incidentActionTemplate.groupedChecklistTemplateItems];


    let newItem: AusComplyDtos.ChecklistTemplateItem = new AusComplyDtos.ChecklistTemplateItem();
    newItem.temporaryParentId = parentId;
    newItem.checklistItemType = checklistItemType;
    newItem.groupedItems = [];
    newItem.temporaryId = commonLogic.uniqueNumber(1);
    newItem.checklistTemplateItemId = -1 * newItem.temporaryId;
    newItem.checklistTemplateId = state.incidentActionTemplate.incidentActionTemplate.incidentActionTemplateId;
    newItem.checklistTemplateItemOptions = [];
    newItem.checklistTemplateItemFilters = [];
    newItem.checklistTemplateItemNotificationUserRoleTypes = [];
    newItem.rank = position;
    newItem.onCreate = true;
    newItem.onEdit = true;
    newItem.userRoleTypeSelections = [...state.incidentActionTemplate.incidentActionTemplate.userRoleTypeSelections];

    switch (checklistItemType) {
        case AusComplyDtos.ChecklistItemType.Group:
            newItem.name = "New Group Text";
            newItem.description = "New Group Description";
            break;
        case AusComplyDtos.ChecklistItemType.Information:
            newItem.name = "New Information Text";
            newItem.description = "New Information Description";
            break;
        case AusComplyDtos.ChecklistItemType.TextBox:
            newItem.name = "New Text Field Text";
            newItem.description = "New Text Field Description";
            break;
        case AusComplyDtos.ChecklistItemType.Question:
            newItem.name = "New Question Text";
            newItem.description = "New Question Description";
            break;
        case AusComplyDtos.ChecklistItemType.Selection:
            newItem.name = "New Selection";
            newItem.description = "New Selection Description";
            break;
        case AusComplyDtos.ChecklistItemType.MultipleChoice:
            newItem.name = "New Multi-Select Text";
            newItem.description = "New Multi-Select Description";
            break;
        case AusComplyDtos.ChecklistItemType.Checkbox:
            newItem.name = "New Checkbox Text";
            newItem.description = "New Checkbox Description";
            break;
        case AusComplyDtos.ChecklistItemType.DateTime:
            newItem.name = "New Date Text";
            newItem.description = "New Date Description";
            break;
        case AusComplyDtos.ChecklistItemType.StaffSingleSelection:
            newItem.name = "New Single Staff Selection";
            newItem.description = "New Single Staff Selection Description";
            break;
        case AusComplyDtos.ChecklistItemType.StaffMultipleSelection:
            newItem.name = "New Multi Staff Selection";
            newItem.description = "New Multi Staff Selection Description";
            break;
        default:
            break;
    }
    if (parentId === 0) {
        groupedChecklistTemplateItems.splice(position, 0, newItem);
    } else {
        // find the parent and add it
        addGroupedToParent(groupedChecklistTemplateItems, newItem, position, parentId);
    }

    // update the flast list checklistTemplateItems
    let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
    groupedChecklistTemplateItems.forEach(item => {
        checklistTemplateItems.push(item);
        flatten(checklistTemplateItems, item.groupedItems);
    });

    let checklistTemplateItemsVersion = state.incidentActionTemplate.checklistTemplateItemsVersion + 1;

    updateGroupRanks(groupedChecklistTemplateItems);

    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: {
                checklistTemplateItems: { $set: [...checklistTemplateItems] },
                groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
            },
            checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
        }
    });
    return newState;
}

function addGroupedToParent(items: AusComplyDtos.ChecklistTemplateItem[], newItem: AusComplyDtos.ChecklistTemplateItem, position: number, parentId: number) {
    items.forEach(item => {
        if (item.checklistItemType === AusComplyDtos.ChecklistItemType.Group) {
            if (Math.abs(item.temporaryId) == Math.abs(parentId)) {
                newItem.temporaryParentId = item.temporaryId;
                newItem.parentChecklistTemplateItemId = item.checklistTemplateItemId;
                newItem.checklistTemplateId = item.checklistTemplateId;
                item.groupedItems.splice(position, 0, newItem);
            } else {
                addGroupedToParent(item.groupedItems, newItem, position, parentId);
            }
        }
    });
}

function updateGroupRanks(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[]) {
    checklistTemplateItems.forEach((item, index) => {
        item.rank = index;
        updateGroupRanks(item.groupedItems);
    });
}

/*
function  seed: number) {
    let date = new Date();
    let dateString = moment(date).format("DDHHmmss");
    let dateNumber = Number(dateString);
    let offset = (new Date().getUTCMilliseconds()) * 10;
    return dateNumber + offset + seed;
}
    */

function flatten(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[]) {
    groupedItems.forEach(item => {
        checklistTemplateItems.push(item);
        flatten(checklistTemplateItems, item.groupedItems);
    });
}

function groupChecklistItems(state, incidentActionTemplate: AusComplyDtos.IncidentActionTemplate) {
    // group the items

    let groupedChecklistTemplateItems = groupChecklistItemList(incidentActionTemplate.checklistTemplateItems);

    let checklistTemplateItemsVersion = state.incidentActionTemplate.checklistTemplateItemsVersion + 1;

    updateGroupRanks(groupedChecklistTemplateItems);

    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: {
                groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
            },
            checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
        }
    });
    return newState;
}

function groupChecklistItemList(checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[]) {
    if (!checklistTemplateItems) {
        return [];
    }
    // group the items
    let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
    checklistTemplateItems.forEach(item => {
        if (item.temporaryParentId > 0) {
            // search for the parent
            let group = findGroup(item.temporaryParentId, groupedChecklistTemplateItems);
            if (group != undefined) {
                group.groupedItems.push(item);
            } else {
                groupedChecklistTemplateItems.push(item);
            }
        } else {
            groupedChecklistTemplateItems.push(item);
        }
    });
    return groupedChecklistTemplateItems;
}

function findGroup(findId: number, groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[]): AusComplyDtos.ChecklistTemplateItem | undefined {
    if (groupedChecklistTemplateItems.length > 0) {
        for (let index = 0; index < groupedChecklistTemplateItems.length; index++) {
            var item = groupedChecklistTemplateItems[index];
            if (item.checklistItemType === AusComplyDtos.ChecklistItemType.Group) {
                if (item.checklistTemplateItemId === findId) {
                    if (item.groupedItems === undefined) {
                        item.groupedItems = []
                    }
                    return item;
                } else {
                    let subItem = findGroup(findId, item.groupedItems);
                    if (subItem !== undefined) {
                        if (subItem.groupedItems === undefined) {
                            subItem.groupedItems = []
                        }
                        return subItem;
                    }
                }
            }
        }
    }
    return undefined;
}

function removeGroupedChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
    let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.incidentActionTemplate.incidentActionTemplate.groupedChecklistTemplateItems];
    removeItem(groupedChecklistTemplateItems, checklistTemplateItem);

    // update the flast list checklistTemplateItems
    let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
    groupedChecklistTemplateItems.forEach(item => {
        checklistTemplateItems.push(item);
        flatten(checklistTemplateItems, item.groupedItems);
    });

    let checklistTemplateItemsVersion = state.incidentActionTemplate.checklistTemplateItemsVersion + 1;

    updateGroupRanks(groupedChecklistTemplateItems);

    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: {
                checklistTemplateItems: { $set: [...checklistTemplateItems] },
                groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
            },
            checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
        }
    });
    return newState;
}

function moveGroupedChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem, position: number, parentId: number) {
    let itemToInsert = { ...checklistTemplateItem };
    let groupedChecklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.incidentActionTemplate.incidentActionTemplate.groupedChecklistTemplateItems];
    removeItem(groupedChecklistTemplateItems, checklistTemplateItem);

    if (checklistTemplateItem.temporaryParentId === parentId && position > checklistTemplateItem.rank) {
        position = position - 1;
    }
    if (parentId === 0) {
        itemToInsert.temporaryParentId = 0;
        itemToInsert.parentChecklistTemplateItemId = 0;
        groupedChecklistTemplateItems.splice(position, 0, itemToInsert);
    } else {
        // find the parent and add it
        addGroupedToParent(groupedChecklistTemplateItems, itemToInsert, position, parentId);
    }

    // update the flast list checklistTemplateItems
    let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [];
    groupedChecklistTemplateItems.forEach(item => {
        checklistTemplateItems.push(item);
        flatten(checklistTemplateItems, item.groupedItems);
    });

    let checklistTemplateItemsVersion = state.incidentActionTemplate.checklistTemplateItemsVersion + 1;

    updateGroupRanks(groupedChecklistTemplateItems);

    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: {
                checklistTemplateItems: { $set: [...checklistTemplateItems] },
                groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
            },
            checklistTemplateItemsVersion: { $set: checklistTemplateItemsVersion }
        }
    });
    return newState;
}

function removeItem(items: AusComplyDtos.ChecklistTemplateItem[], item: AusComplyDtos.ChecklistTemplateItem) {
    for (let index = 0; index < items.length; index++) {
        if (items[index].temporaryId == item.temporaryId) {
            items.splice(index, 1);
            return;
        } else {
            removeItem(items[index].groupedItems, item);
        }
    }
}


function setChecklistTemplateItem(state, checklistTemplateItem: AusComplyDtos.ChecklistTemplateItem) {
    let checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[] = [...state.incidentActionTemplate.incidentActionTemplate.checklistTemplateItems];
    let replaceIndex = -1;
    checklistTemplateItems.forEach((item, index) => {
        item.groupedItems = [];
        if (item.temporaryId === checklistTemplateItem.temporaryId) {
            replaceIndex = index;
        }
    });
    // remove any grouping as we are adding this to the flat list version
    checklistTemplateItem.groupedItems = [];
    if (replaceIndex > -1) {
        checklistTemplateItems.splice(replaceIndex, 1, checklistTemplateItem);
    }
    let groupedChecklistTemplateItems = groupChecklistItemList(checklistTemplateItems);

    updateGroupRanks(groupedChecklistTemplateItems);

    let newState = update(state, {
        incidentActionTemplate: {
            incidentActionTemplate: {
                checklistTemplateItems: { $set: [...checklistTemplateItems] },
                groupedChecklistTemplateItems: { $set: [...groupedChecklistTemplateItems] }
            }
        }
    });
    return newState;
}

export default function incidentActionTemplates(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_SET_FILTER:
            return setFilter(state, action.filter);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_REQUEST:
            return setIncidentActionTemplates(loadingIncidentActionTemplates(state, true), [], state.incidentActionTemplates.filter);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS:
            return setIncidentActionTemplates(loadingIncidentActionTemplates(state, false), action.incidentActionTemplates, action.filter);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE:
            return loadingIncidentActionTemplates(state, false);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_REQUEST:
            return loadingIncidentActionTemplate(state, true);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS:
            return setIncidentCategoryTypes(setIncidentActionTemplate(loadingIncidentActionTemplate(state, false), action.incidentActionTemplate), action.incidentCategoryTypes);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE:
            return loadingIncidentActionTemplate(resetIncidentActionTemplate(state), false);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST:
            return loadingIncidentActionTemplate(state, true);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS:
            return setIncidentCategoryTypes(setIncidentActionTemplate(loadingIncidentActionTemplate(state, false), action.incidentActionTemplate), action.incidentCategoryTypes);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE:
            return loadingIncidentActionTemplate(resetIncidentActionTemplate(state), false);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST:
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST:
            return savingIncidentActionTemplate(state, true);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS:
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_SUCCESS:
            return setIncidentActionTemplate(savingIncidentActionTemplate(state, false), action.incidentActionTemplate);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE:
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_CLONE_REQUEST_FAILURE:
            return savingIncidentActionTemplate(state, false);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_SET:
            return setIncidentActionTemplate(state, action.incidentActionTemplate);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE:
            return setIncidentActionTemplateObsolete(state, action.incidentActionTemplate);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATES_RESET:
            return resetIncidentActionTemplate(state);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM:
            return addGroupedChecklistTemplateItem(state, action.checklistItemType, action.position, action.parentId);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM:
            return removeGroupedChecklistTemplateItem(state, action.checklistTemplateItem);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM:
            return moveGroupedChecklistTemplateItem(state, action.checklistTemplateItem, action.position, action.parentId);
        case incidentActionTemplateActions.INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM:
            return setChecklistTemplateItem(state, action.checklistTemplateItem);

    }

    return state;
}