import { connect } from 'react-redux'
import LoginResetPassword from '../components/login/LoginResetPassword'
import { userLoginForgotPasswordValidateRequest, userLoginPasswordResetRequest } from '../common/actions/userLogin'

const mapStateToProps = (state, props) => {
    return {
        userId: props.match.params.userId, 
        userForgotPasswordId: props.match.params.userForgotPasswordId, 
        forgotPasswordGuid: props.match.params.forgotPasswordGuid,
        isValid: state.user.resetPasswordIsValid,
        isValidating: state.user.resetPasswordValidating,
        isSaving: state.user.resetPasswordSaving,
        error: state.user.resetPasswordError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onValidate: (userId, userForgotPasswordId, forgotPasswordGuid) => {
            dispatch(userLoginForgotPasswordValidateRequest(userId, userForgotPasswordId, forgotPasswordGuid))
        },
        onResetPassword: (userId, userForgotPasswordId, forgotPasswordGuid, newPassword, newPasswordConfirm) => {
            dispatch(userLoginPasswordResetRequest(userId, userForgotPasswordId, forgotPasswordGuid, newPassword, newPasswordConfirm))
        }
    }
}

const LoginResetPasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginResetPassword)

export default LoginResetPasswordContainer