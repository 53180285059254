import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import FacialRecognitionEventsContainer from '../../../containers/FacialRecognitionEventsContainer';

export interface IVenueFacialRecognitionNotificationsEnrolProps {
    venueId: number;
    venueMode: boolean;
    history: any;
    location: any;
}

class VenueFacialRecognitionNotificationsEnrol extends Component<IVenueFacialRecognitionNotificationsEnrolProps, any> {
    
    render() {
        return <FacialRecognitionEventsContainer venueId={this.props.venueId} venueMode={true} />
    }
}

export default withStyles(styles, { withTheme: true })(VenueFacialRecognitionNotificationsEnrol);