import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import View from '../common/View';
import CrossIcon from '../../resources/CrossIcon';
import { Palette } from '../../common/constants/palette';

export interface IClearSelectionButtonProps {
    text?: string;
    classes: any;
    theme: any;
    onClick?: Function;
}

class ClearSelectionButton extends React.PureComponent<IClearSelectionButtonProps, any> {
    constructor(props: IClearSelectionButtonProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                className={classNames(classes.buttonClear, classes.buttonState, classes.clearSelectionButton)}
                style={{ width: '100%', height: '100%' }}>
                <View style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <View style={{ flex: 1, margin: '0 10px' }}>
                        <p className={classes.clearSelectionButtonText}>{this.props.text}</p>
                    </View>
                    <View style={{ flex: 0 }}>
                        <View style={{ width: '20px', height: '20px', margin: '0 10px' }}>
                            <CrossIcon fill={Palette.Error} />
                        </View>
                    </View>
                </View>
            </Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ClearSelectionButton);