import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import NoRowsMessage from '../common/NoRowsMessage';
import SectionDivider from '../common/SectionDivider';
import View from '../common/View';
import VerticalCollapsedSpacer from '../common/VerticalCollapsedSpacer';
import RosterItem from './RosterItem';
import RosterEmpty from './RosterEmpty';
import RosterLeaveRequestContainer from '../../containers/RosterLeaveRequestContainer';
import RosterFilter from './RosterFilter';
import RosterFilterSummary from './RosterFilterSummary';

export interface IRosterProps {
    rosters: AusComplyDtos.Roster[],
    filter: AusComplyDtos.RosterFilter,
    error: string,
    isLoading: boolean,
    onRefresh: Function,
    onSetFilter: Function
}

interface IRosterState {
    showLeaveRequest: boolean;
    filterExpanded: boolean;
}

export default class Roster extends Component<IRosterProps, IRosterState> {
    constructor(props: IRosterProps) {
        super(props)

        this.state = {
            showLeaveRequest: false,
            filterExpanded: false
        };
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount(): void {
        this.props.onRefresh(this.props.filter);
    }

    onSearch(filter: AusComplyDtos.RosterFilter) {
        this.props.onRefresh(filter);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onRefresh(null);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            //this.props.onLoad();
        }
        this.setState({
            filterExpanded: value
        })
    }

    render() {
        let self = this;
        let rosters;

        if (!this.props.isLoading) {
            if (!this.props.rosters || !Array.isArray(this.props.rosters) || this.props.rosters.length === 0) {
                rosters = <NoRowsMessage message='No roster found' />
            } else {
                let previousRosterWasEmpty: boolean = false;
                let total = this.props.rosters.length - 1;
                rosters = this.props.rosters.map((item, index) => {
                    if (index > 0 && index < total && item.rosterItems.length === 0) {
                        if (previousRosterWasEmpty) return <></>
                        previousRosterWasEmpty = true;
                        return <View key={item.id}>
                            <VerticalCollapsedSpacer />
                        </View>
                    }
                    previousRosterWasEmpty = false;
                    return <View key={item.id}>
                        <SectionDivider text={item.dateDisplay} primary={!item.inThePast} />
                        {item.rosterItems.length === 0 && <RosterEmpty />}
                        {item.rosterItems.map((rosterItem, rosterIndex) => <View key={"rosteritem-" + rosterItem.id + "-" + rosterIndex}>
                            <RosterItem rosterItem={rosterItem} />
                        </View>)}
                    </View>
                })
            }
        }
        
        let filterSummary;
        if (this.props.isLoading) {
            let tempFilter = new AusComplyDtos.RosterFilter();
            tempFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <RosterFilterSummary filter={tempFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <RosterFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return <PageLayout
            headerText={"Roster"}
            error={this.props.error}
            loading={this.props.isLoading}
            footerLeftContent={<TransparentButton text={"Refresh"} onClick={() => self.props.onRefresh(self.props.filter)} />}
            footerRightContent={<TransparentButton text={"Leave Request"} onClick={() => { self.setState({ showLeaveRequest: true }); }} />}
        >
            {filterSummary}
            {rosters}
            <RosterFilter
                    open={this.state.filterExpanded}
                    filter={this.props.filter}
                    onUpdateFilter={filter => {}}
                    onSearch={this.onSearch}
                    onClose={() => this.setState({filterExpanded: false})}
                    onReset={() => this.onResetSearch()} />
            <RosterLeaveRequestContainer open={this.state.showLeaveRequest} onCancel={() => { self.setState({ showLeaveRequest: false }); self.props.onRefresh(self.props.filter); }} />
        </PageLayout>
    }
}