import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const userRoleServiceApi = {
  getRoleType: (venueId: number, securityFirmId: number, userRoleTypeId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUserRoleType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.id = userRoleTypeId;
    const request = client.get(query);
    return from(request);
  },
  newRoleType: (cloneRoleTypeId?: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.NewUserRoleType();
    if(cloneRoleTypeId){
      query.cloneUserRoleTypeId = cloneRoleTypeId;
    }
    const request = client.post(query);
    return from(request);
  },
  postRoleType: (venueId: number, securityFirmId: number, userRoleType: AusComplyDtos.UserRoleType, localRoleEditMode: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserRoleType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRoleType = userRoleType;
    query.localRoleEditMode = localRoleEditMode;
    const request = client.post(query);
    return from(request);
  },
  updateRoleType: (venueId: number, securityFirmId: number, userRoleType: AusComplyDtos.UserRoleType, localRoleEditMode: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.UpdateUserRoleType();
    query.userRoleType = userRoleType;
    query.localRoleEditMode = localRoleEditMode;
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.put(query);
    return from(request);
  },
  deleteRoleType: (venueId: number, securityFirmId: number, userRoleTypeId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.DeleteUserRoleType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRoleTypeId = userRoleTypeId;
    const request = client.delete(query);
    return from(request);
  },
  getRoleTypes: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.UserRoleTypeFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserRoleTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  newUserRole: (userId: number, venueId: number, securityFirmId: number, forSecurirtyFirm: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.NewUserRole();
    query.userId = userId;
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.addForSecurityFirm = forSecurirtyFirm;
    const request = client.post(query);
    return from(request);
  },
  postUserRole: (venueId: number, securityFirmId: number, userRole: AusComplyDtos.UserRole, notificationId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserRole();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRole = userRole;
    query.notificationId = notificationId;
    const request = client.post(query);
    return from(request);
  },
  getUserRole: (venueId: number, securityFirmId: number, userRoleId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUserRole();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRoleId = userRoleId;
    const request = client.get(query);
    return from(request);
  },
  updateUserRole: (venueId: number, securityFirmId: number, userRole: AusComplyDtos.UserRole) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.UpdateUserRole();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRole = userRole;
    const request = client.put(query);
    return from(request);
  },
  removeUserRole: (venueId: number, securityFirmId: number, userRoleId: number, adminNotes: string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDeleteUserRole();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRoleId = userRoleId;
    query.adminNotes = adminNotes;
    const request = client.post(query);
    return from(request);
  },
  userRoleRequestDismiss: (venueId: number, securityFirmId: number, notificationId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.SendUserRoleRequestDismiss();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.notificationId = notificationId;
    const request = client.post(query);
    return from(request);
  },
  userRoleEnable: (venueId: number, securityFirmId: number, userRoleId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostReEnableUserRole();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userRoleId = userRoleId;
    const request = client.post(query);
    return from(request);
  },
  getUserRoles: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.UserRoleFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserRoles();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  userRoleSuspend: (venueId: number, securityFirmId: number, forVenueId: number, forSecurityFirmId: number, undo: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserRolesSuspend();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.undo = undo;
    const request = client.post(query);
    return from(request);
  }
};
