import React from 'react';
import TransparentButton from './TransparentButton';
import MoreIcon from '../../resources/MoreIcon';
import { KeyName } from '../../common/dto/common';
import { Palette } from '../../common/constants/palette';
import PopupModal from '../layout/PopupModal';

export interface ITransparentCommandsButtonProps {
    text?: any;
    commands: KeyName[];
    onCommand: Function;
}

export default class TransparentCommandsButton extends React.PureComponent<ITransparentCommandsButtonProps, any> {
    constructor(props: ITransparentCommandsButtonProps) {
        super(props);
        this.state = {
            open: false
        };
    }

    render() {
        const { commands, text } = this.props;
        let self = this;
        let content;

        if (commands.length === 0) {
            content = <></>
        } else if (commands.length === 1) {
            content = <TransparentButton text={commands[0].name} onClick={() => self.props.onCommand(commands[0].key)} />
        } else {
            if (text !== undefined && text.length > 0) {
                content = <TransparentButton text={text} onClick={() => self.setState({ open: true })} />
            } else {
                content = <TransparentButton icon={<MoreIcon fill={Palette.AlternativeText} />} onClick={() => self.setState({ open: true })} />
            }
        }

        return <>
            {content}
            <PopupModal open={this.state.open} onAction={action => self.setState({open: false}, () => {self.props.onCommand(action);})} actions={self.props.commands} />
        </>
    }
}