import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const dashboardServiceApi = {
  newFilter: (venueId, securityFirmId, forVenueId, forSecurityFirmId, forGroupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDashboardFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.forGroupId = forGroupId;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, forVenueId, forSecurityFirmId, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDashboard();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  export: (venueId: number, securityFirmId: number, forVenueId, forSecurityFirmId, filter: AusComplyDtos.DashboardFilter) => {
    var client = new AusComplyJsonServiceClient();
    var parameters = new AusComplyDtos.PostDashboardExport();
    parameters.venueId = venueId;
    parameters.securityFirmId = securityFirmId;
    parameters.forVenueId = forVenueId;
    parameters.forSecurityFirmId = forSecurityFirmId;
    parameters.filter = filter;
    const request = client.post(parameters);
    return from(request);
  },
  getIncidents: (venueId: number, securityFirmId: number,filter: AusComplyDtos.DashboardFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDashboardIncidents();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  }
};
