
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const incidentFlagTypeNotificationLogic = {

    getCommands(incidentFlagTypeNotification: AusComplyDtos.IncidentFlagTypeNotification, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            commands.push({ key: "edit", name: "Edit" });
        }
        return commands;
    }
}