

import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
}

class Label extends React.PureComponent<IControlGroupProps, any> {
    render() {
        const { theme, classes } = this.props;
        
        let labelClass = classes.labelNormal;
        return <label className={labelClass} >{this.props.text}</label>
    }
}

export default withStyles(styles, { withTheme: true })(Label);