import update from "immutability-helper";
import {
    REGISTER_VENUE_RESET,
    REGISTER_VENUE_NEW_REQUEST,
    REGISTER_VENUE_NEW_REQUEST_SUCCESS,
    REGISTER_VENUE_NEW_REQUEST_FAILURE,
    REGISTER_VENUE_SET_STEP,
    REGISTER_VENUE_UPDATE,
    REGISTER_VENUE_UPDATE_OTHER_DOCUMENTS,
    REGISTER_VENUE_UPDATE_COMPLIANCE_DOCUMENTS,
    REGISTER_VENUE_SAVE_REQUEST,
    REGISTER_VENUE_SAVE_REQUEST_SUCCESS,
    REGISTER_VENUE_SAVE_REQUEST_FAILURE
} from "../actions/registerVenue";
import { venueLogic } from '../logic/venueLogic';

const initialState = {
    venue: { venueId: -1 },
    venueContact: {},
    licenseContact: {},
    industryCategories: [],
    precincts: [],
    licenceTypes: [],
    tradingHours: [],
    states: [],
    otherDocuments: [],
    otherDocumentTypes: [],
    complianceDocuments: [],
    complianceDocumentTypes: [],
    isLoading: false,
    isSaving: false,
    error: null,
    errors: {},
    step: 1
};

function updateVenue(state, venue, venueContact, licenseContact) {
    let newState = update(state, {
        venue: { $set: venue },
        venueContact: { $set: venueContact },
        licenseContact: {$set : licenseContact}
    });
    return newState;
}


function setOptions(state, states, industryCategories, precincts, licenceTypes, tradingHours, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    let newState = update(state, {
        states: { $set: states },
        industryCategories: { $set: industryCategories },
        precincts: { $set: precincts },
        licenceTypes: { $set: licenceTypes },
        tradingHours: { $set: tradingHours },
        otherDocuments: { $set: otherDocuments },
        otherDocumentTypes: { $set: otherDocumentTypes },
        complianceDocuments: { $set: complianceDocuments },
        complianceDocumentTypes: { $set: complianceDocumentTypes }
    });
    return newState;
}

function setStep(state, step) {
    let newState = update(state, {
        step: { $set: step }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: venueLogic.validateRegisterVenue(state.venue, state.venueContact, state.licenseContact) }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        otherDocuments: { $set: otherDocuments },
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        complianceDocuments: { $set: complianceDocuments },
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

export default function content(state = initialState, action) {
    switch (action.type) {
        case REGISTER_VENUE_NEW_REQUEST:
            return loading(updateVenue(setStep(state, 1), { venueId: 0 }, {}, {}), true);
        case REGISTER_VENUE_NEW_REQUEST_SUCCESS:
            return setErrors(loading(
                    updateVenue(
                        setOptions(
                            setError(state, "")
                            , action.states, action.industryCategories, action.precincts, action.licenceTypes, action.tradingHours, action.otherDocuments, action.otherDocumentTypes, action.complianceDocuments, action.complianceDocumentTypes)
                            , action.venue, action.venueContact, action.licenseContact)
                            , false));
        case REGISTER_VENUE_NEW_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case REGISTER_VENUE_RESET:
            return setErrors(updateVenue(state, { venueId: -1 }, {}, {}));
        case REGISTER_VENUE_SET_STEP:
            return setStep(state, action.step);
        case REGISTER_VENUE_UPDATE:
            return setErrors(updateVenue(state, action.venue, action.venueContact, action.licenseContact));
        case REGISTER_VENUE_UPDATE_OTHER_DOCUMENTS:
            return setOtherDocuments(state, action.otherDocuments);
        case REGISTER_VENUE_UPDATE_COMPLIANCE_DOCUMENTS:
            return setComplianceDocuments(state, action.complianceDocuments);
        case REGISTER_VENUE_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case REGISTER_VENUE_SAVE_REQUEST_SUCCESS:
            return saving(state, false);
        case REGISTER_VENUE_SAVE_REQUEST_FAILURE:
            return setErrors(saving(setError(state, action.error), false));
    }
    return state;
}