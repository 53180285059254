import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import SubTitle from '../../../common/SubTitle';
import List from '../../../common/List';
import TableContainer from '../../../layout/TableContainer';
import VenueSecurityFirmCard from './venueSecurityFirm/VenueSecurityFirmCard';
import VenueSecurityFirmHeader from './venueSecurityFirm/VenueSecurityFirmHeader';
import VenueSecurityFirmRow from './venueSecurityFirm/VenueSecurityFirmRow';
import SecurityFirmDescendantCard from './securityFirmDescendant/SecurityFirmDescendantCard';
import SecurityFirmDescendantHeader from './securityFirmDescendant/SecurityFirmDescendantHeader';
import SecurityFirmDescendantRow from './securityFirmDescendant/SecurityFirmDescendantRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TransparentButton from '../../../controls/TransparentButton';
import DefaultButton from '../../../controls/DefaultButton';
import VenueTabs from '../VenueTabs';
import PageLayout from '../../../layout/PageLayout';

export interface IVenueAssociationsProps {
    venueId: number;
    isLoading: boolean;
    venueSecurityFirms: AusComplyDtos.VenueSecurityFirm[];
    securityFirmDescendants: AusComplyDtos.SecurityFirmDescendant[];
    onRefresh: Function;
    onRemoveVenueSecurityFirm: Function;
    onRemoveSecurityFirmDescendant: Function;
    history: any;
}

interface IVenueAssociationsState {
    removeAlert: boolean;
    venueSecurityFirmId: number;
    securityFirmDescendantId: number;
}

class VenueAssociations extends Component<IVenueAssociationsProps, IVenueAssociationsState> {
    constructor(props: IVenueAssociationsProps) {
        super(props)

        this.state = {
            removeAlert: false,
            venueSecurityFirmId: 0,
            securityFirmDescendantId: 0
        };
        this.onRemoveVenueSecurityFirm = this.onRemoveVenueSecurityFirm.bind(this);
        this.onRemoveSecurityFirmDescendant = this.onRemoveSecurityFirmDescendant.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onRemoveCancel = this.onRemoveCancel.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
    }

    onRemoveVenueSecurityFirm(venueSecurityFirm: AusComplyDtos.VenueSecurityFirm) {
        this.setState({
            removeAlert: true,
            venueSecurityFirmId: venueSecurityFirm.venueSecurityFirmId,
            securityFirmDescendantId: 0
        });
    }

    onRemoveSecurityFirmDescendant(securityFirmDescendant: AusComplyDtos.SecurityFirmDescendant) {
        this.setState({
            removeAlert: true,
            venueSecurityFirmId: 0,
            securityFirmDescendantId: securityFirmDescendant.securityFirmDescendantID
        });
    }

    onRemove() {
        this.setState({
            removeAlert: false
        }, () => {
            if (this.state.venueSecurityFirmId != 0 && this.props.onRemoveVenueSecurityFirm) {
                this.props.onRemoveVenueSecurityFirm(this.state.venueSecurityFirmId);
            }
            if (this.state.securityFirmDescendantId != 0 && this.props.onRemoveSecurityFirmDescendant) {
                this.props.onRemoveSecurityFirmDescendant(this.state.securityFirmDescendantId)
            }
        });
    }

    onRemoveCancel() {
        this.setState({
            removeAlert: false
        }, () => {

        });
    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }

    render() {
        let cardListVenueSecurityFirms;
        let rowListVenueSecurityFirms;
        let rowsVenueSecurityFirms;
        let venueSecurityFirmCount = 0;

        let cardListSecurityFirmsDescendants;
        let rowListSecurityFirmsDescendants;
        let rowsSecurityFirmsDescendants;
        let securityFirmsDescendantsCount = 0;


        if (this.props.venueSecurityFirms && this.props.venueSecurityFirms.length > 0) {
            venueSecurityFirmCount = this.props.venueSecurityFirms.length;
            cardListVenueSecurityFirms = this.props.venueSecurityFirms.map((item, index) => {
                return (
                    <VenueSecurityFirmCard
                        onDelete={this.onRemoveVenueSecurityFirm}
                        key={"card-venueSecurityFirmId-" + item.venueSecurityFirmId.toString()}
                        venueSecurityFirm={item}
                    />
                );
            });

            rowsVenueSecurityFirms = this.props.venueSecurityFirms.map((item, index) => {
                return (
                    <VenueSecurityFirmRow
                        onDelete={this.onRemoveVenueSecurityFirm}
                        key={"row-venueSecurityFirmId-" + item.venueSecurityFirmId.toString()}
                        venueSecurityFirm={item} />
                );
            });

        }

        if (this.props.securityFirmDescendants && this.props.securityFirmDescendants.length > 0) {
            securityFirmsDescendantsCount = this.props.securityFirmDescendants.length;
            cardListSecurityFirmsDescendants = this.props.securityFirmDescendants.map((item, index) => {
                return (
                    <SecurityFirmDescendantCard
                        onDelete={this.onRemoveSecurityFirmDescendant}
                        key={"card-SecurityFirmDescendantCard-" + item.securityFirmDescendantID.toString()}
                        securityFirmDescendant={item}
                    />
                );
            });

            rowsSecurityFirmsDescendants = this.props.securityFirmDescendants.map((item, index) => {
                return (
                    <SecurityFirmDescendantRow
                        onDelete={this.onRemoveSecurityFirmDescendant}
                        key={"row-securityFirmDescendantIDId-" + item.securityFirmDescendantID.toString()}
                        securityFirmDescendant={item} />
                );
            });
        }


        rowListVenueSecurityFirms = (
            <TableContainer
                header={<VenueSecurityFirmHeader />}
                rows={rowsVenueSecurityFirms}
            />
        );


        rowListSecurityFirmsDescendants = (
            <TableContainer
                header={<SecurityFirmDescendantHeader />}
                rows={rowsSecurityFirmsDescendants}
            />
        );


        return (
            <PageLayout
                headerText={"Venue"}
                loading={this.props.isLoading}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                footerCenterContent={<></>}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Security Firms"} history={this.props.history} />
                <SubTitle text={"Venue Security Firms"} />
                <List
                    loading={this.props.isLoading}
                    count={venueSecurityFirmCount}
                    cards={cardListVenueSecurityFirms}
                    rows={rowListVenueSecurityFirms}
                />
                <br />
                <SubTitle text={"Security Firms Descendants"} />
                <List
                    loading={this.props.isLoading}
                    count={securityFirmsDescendantsCount}
                    cards={cardListSecurityFirmsDescendants}
                    rows={rowListSecurityFirmsDescendants}
                />
                <Dialog
                    open={this.state.removeAlert}
                    onClose={this.onRemoveCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DefaultButton onClick={this.onRemoveCancel} style={{ width: 'auto', marginRight: '10px' }}>No</DefaultButton>
                        <DefaultButton onClick={this.onRemove} style={{ width: 'auto', marginRight: '10px' }} autoFocus>Yes</DefaultButton>
                    </DialogActions>
                </Dialog>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(VenueAssociations);