import { connect } from 'react-redux'
import VenueDetails from '../components/venue/VenueDetails'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    venueRequest
} from '../common/actions/venue'

const mapStateToProps = state => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        isLoading: state.venue.isLoading,
        canEdit: permissionsLogic.hasPermissionForState(state, "ManageVenue"),
        canAdminEdit: permissionsLogic.isLevelSix(state),
        venue: state.venue.venue
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId) => {
            dispatch(venueRequest(venueId, false))
        }
    }
}

const VenueContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueDetails)

export default VenueContainer