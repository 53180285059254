import { connect } from 'react-redux'
import SecurityFirmDetails from '../components/securityFirm/SecurityFirmDetails'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    securityFirmRequest
} from '../common/actions/securityFirm'

const mapStateToProps = state => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        isLoading: state.securityFirm.isLoading,
        canEdit: permissionsLogic.hasPermissionForState(state, "EditSecurityFirm"),
        canAdminEdit: permissionsLogic.isLevelSix(state),
        securityFirm: state.securityFirm.securityFirm
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (securityFirmId) => {
            dispatch(securityFirmRequest(securityFirmId, false))
        }
    }
}

const SecurityFirmContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmDetails)

export default SecurityFirmContainer