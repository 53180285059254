import React from 'react';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import TableRow from '../../common/TableRow'
import { correctionalFacilityRiskStatusLogic } from '../../../common/logic/correctionalFacilityRiskStatusLogic';

export interface ICorrectionalFacilityRiskStatusRowProps {
    riskStatus: AusComplyDtos.CorrectionalFacilityRiskStatus;
    showCameras?: boolean;
    onCommand?: Function;
}

export default class CorrectionalFacilityRiskStatusRow extends React.Component<ICorrectionalFacilityRiskStatusRowProps, any> {
    constructor(props: ICorrectionalFacilityRiskStatusRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.riskStatus);
        }
    }

    render() {
        if (!this.props.riskStatus) {
            return (<></>);
        }

        var commands = correctionalFacilityRiskStatusLogic.getCommands(this.props.riskStatus);
        var item = this.props.riskStatus;

        return (
            <TableRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td>
                    <p>
                        In Cell
                    </p>
                </td>
                <td>
                    <p>

                    </p>
                </td>
                <td>
                    <p>
                        {item.inspectionFrequency} minutes
                    </p>
                </td>
                <td>
                    <p>
                        {item.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}
