import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { venueSecurityFirmLogic } from '../../../common/logic/venueSecurityFirmLogic';

export interface ISecurityFirmAssociationRowProps {
    venueSecurityFirmSummary: AusComplyDtos.VenueSecurityFirmSummary;
    canRemove: boolean;
    onCommand?: Function;
}

export default class SecurityFirmAssociationRow extends React.Component<ISecurityFirmAssociationRowProps, any> {
    constructor(props: ISecurityFirmAssociationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueSecurityFirmSummary);
        }
    }

    render() {
        if (!this.props.venueSecurityFirmSummary) {
            return (<></>);
        }

        var commands = venueSecurityFirmLogic.getCommands(this.props.venueSecurityFirmSummary, this.props.canRemove);
        var item = this.props.venueSecurityFirmSummary;

        return (
            <TableRow commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        <b>{item.venue}</b>
                    </p>
                </td>
                <td>
                    <p>
                        <b>{item.securityFirm}</b>
                    </p>
                </td>
                <td>
                    <p>
                        {item.masterLicenceNumber}
                    </p>
                </td>
                <td>
                    <p>
                        {item.suburb}
                    </p>
                </td>
                <td>
                    <p>
                        {item.stateDisplayName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.postcode}
                    </p>
                </td>
                <td>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td>
                    <p>
                        {item.managerName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.website}
                    </p>
                </td>
                <td>
                    <p>
                        {item.dateEnteredDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {item.dateModifiedDisplay}
                    </p>
                </td>
            </TableRow>
        );
    }
}