export const
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_REFRESH = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_REFRESH",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_SUCCESS = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_SUCCESS",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_FAILURE = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_FAILURE",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_SUCCESS = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_SUCCESS",
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_FAILURE = "USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_FAILURE",
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST = "USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST",
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_REFRESH = "USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_REFRESH",
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_SUCCESS = "USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_SUCCESS",
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_FAILURE = "USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_FAILURE",
    USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST = "USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST",
    USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_SUCCESS = "USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_SUCCESS",
    USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_FAILURE = "USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_FAILURE";

export function userComplianceDocumentsExpiringRefresh() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_REFRESH
    }
}

export function userComplianceDocumentsExpiringRequest(page, pageSize, filter) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function userComplianceDocumentsExpiringRequestSuccess(userComplianceDocuments, paging, filter) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_SUCCESS,
        userComplianceDocuments,
        paging,
        filter
    };
}

export function userComplianceDocumentsExpiringRequestFailure(error) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_FAILURE,
        error
    };
}

export function userComplianceDocumentsExpiringExportRequest() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST
    };
}

export function userComplianceDocumentsExpiringExportRequestSuccess() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_SUCCESS
    };
}

export function userComplianceDocumentsExpiringExportRequestFailure(error) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_FAILURE,
        error
    };
}

export function userComplianceDocumentsMissingRefresh() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_REFRESH
    }
}

export function userComplianceDocumentsMissingRequest(page, pageSize, filter) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function userComplianceDocumentsMissingRequestSuccess(userComplianceDocuments, paging, filter) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_SUCCESS,
        userComplianceDocuments,
        paging,
        filter
    };
}

export function userComplianceDocumentsMissingRequestFailure(error) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST,
        error
    };
}

export function userComplianceDocumentsMissingExportRequest() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST
    };
}

export function userComplianceDocumentsMissingExportRequestSuccess() {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_SUCCESS
    };
}

export function userComplianceDocumentsMissingExportRequestFailure(error) {
    return {
        type: USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_FAILURE,
        error
    };
}