import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TimeZoneDateControlGroup from '../../controls/TimeZoneDateControlGroup';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IRoublerEmployeeMappingAddProps {
    open: boolean;
    users: AusComplyDtos.NamedValue[],
    employees: AusComplyDtos.NamedValue[],
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IRoublerEmployeeMappingAddState {
    userId: number;
    employeeId: number;
}

export default class RoublerEmployeeMappingAdd extends Component<IRoublerEmployeeMappingAddProps, IRoublerEmployeeMappingAddState> {
    constructor(props: IRoublerEmployeeMappingAddProps) {
        super(props)
        this.state = {
            userId: 0,
            employeeId: 0
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSendToRoubler = this.onSendToRoubler.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving) {
            if (this.props.open && !prevProps.open) {
                this.setState({
                    userId: 0,
                    employeeId: 0
                });
            }
            if (prevProps.isSaving) {
                if (!this.props.error) {
                    this.onCancel();
                }
            }
        }
    }

    onSave() {
        this.props.onSave(this.state.userId, this.state.employeeId);
    }

    onSendToRoubler() {
        this.props.onSave(this.state.userId, 0);
    }

    onCancel() {
        this.props.onCancel();
    }

    render() {
        let self = this;
        return (
            <FullPageDialog
                open={this.props.open}
                footerRightContent={<PrimaryButton text={"Save"} disabled={this.state.userId == 0 || this.state.employeeId == 0 || this.props.isSaving} onClick={this.onSave}></PrimaryButton>}
                footerCenterContent={<DefaultButton text={"Send to Roubler"} disabled={this.state.userId == 0 || this.props.isSaving} onClick={this.onSendToRoubler}></DefaultButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                error={this.props.error}
                title="Add Mapping" onDismissed={() => this.onCancel()}>
                <SelectControlGroup
                    text="AusComply User"
                    labelAbove={true}
                    defaultValue={this.state.userId}
                    onChange={(value) => { self.setState({ userId: Number(value) }) }}>
                    <option value={0} />
                    {this.props.users && this.props.users.map((item) => (
                        <option key={"user-" + item.id} value={item.id}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <SelectControlGroup
                    text="Rouble Employee"
                    labelAbove={true}
                    defaultValue={this.state.employeeId}
                    onChange={(value) => { self.setState({ employeeId: Number(value) }) }}>
                    <option value={0} />
                    {this.props.employees && this.props.employees.map((item) => (
                        <option key={"employee-" + item.id} value={item.id}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            </FullPageDialog >
        );
    }
}