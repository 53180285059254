import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { correctionalFacilityServiceApi } from '../services/correctionalFacilityService';

import {
    correctionalFacilityInMatesCellsRequest,
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST,
    correctionalFacilityInMatesCellsRequestSuccess,
    correctionalFacilityInMatesCellsRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST,
    correctionalFacilityInMateCellCreateRequestSuccess,
    correctionalFacilityInMateCellCreateRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST,
    correctionalFacilityInMateCellSaveRequestSuccess,
    correctionalFacilityInMateCellSaveRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST,
    correctionalFacilityInMateCellRemoveRequestSuccess,
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS,
    correctionalFacilityInMateCellRemoveRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST,
    correctionalFacilityInMateCellUpdateRequestSuccess,
    correctionalFacilityInMateCellUpdateRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS,
    correctionalFacilityRiskStatusesRequest,
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST,
    correctionalFacilityRiskStatusesRequestSuccess,
    correctionalFacilityRiskStatusesRequestFailure,
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST,
    correctionalFacilityRiskStatusSaveRequestSuccess,
    correctionalFacilityRiskStatusSaveRequestFailure,
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST,
    correctionalFacilityInMateCellInspectRequestSuccess,
    correctionalFacilityInMateCellInspectRequestFailure

} from "../actions/correctionalFacility";

import {
    notifyError, notifyErrorMessage
} from './common'; 
import { notificationSuccessShow, notificationShow } from "../actions/notification";

const correctionalFacilityInMatesCellsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueId)
                .pipe(
                    map(response => correctionalFacilityInMatesCellsRequestSuccess(response.data, response.availableCells, response.risks, response.genders, response.nationalities)),
                    catchError(error => notifyError(error, "correctionalFacilityInMatesCellsRequestEpic.get", correctionalFacilityInMatesCellsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMatesCellsRequestEpic", correctionalFacilityInMatesCellsRequestFailure))
    );

const correctionalFacilityInMateCellCreateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.user.details.userSession.user.venueId,
                action['search'])
                .pipe(
                    map(response => correctionalFacilityInMateCellCreateRequestSuccess(response.correctionalFacilityInMateCell, response.inmates, response.availableCells, response.risks, response.genders, response.nationalities)),
                    catchError(error => notifyError(error, "correctionalFacilityInMateCellCreateRequestEpic.create", correctionalFacilityInMateCellCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMateCellCreateRequestEpic", correctionalFacilityInMateCellCreateRequestFailure))
    );

const correctionalFacilityInMateCellSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.correctionalFacility.createInMateCell.correctionalFacilityInMateCell)
                .pipe(
                    map(response => correctionalFacilityInMateCellSaveRequestSuccess(response.correctionalFacilityInMateCell)),
                    catchError(error => notifyError(error, "correctionalFacilityInMateCellSaveRequestEpic.save", correctionalFacilityInMateCellSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMateCellSaveRequestEpic", correctionalFacilityInMateCellSaveRequestFailure))
    );

const correctionalFacilityInMateCellRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.removeInmateFromCell(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['correctionalFacilityInMateVenueLocationId'])
                .pipe(
                    map(response => correctionalFacilityInMateCellRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "correctionalFacilityInMateCellRemoveRequestEpic.removeInmateFromCell", correctionalFacilityInMateCellRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMateCellRemoveRequestEpic", correctionalFacilityInMateCellRemoveRequestFailure))
    );

const correctionalFacilityInMateCellRemoveRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS),
        map(action => correctionalFacilityInMatesCellsRequest())
    );

const correctionalFacilityInMateCellUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell)
                .pipe(
                    map(response => correctionalFacilityInMateCellUpdateRequestSuccess()),
                    catchError(error => notifyError(error, "correctionalFacilityInMateCellUpdateRequestEpic.save", correctionalFacilityInMateCellUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMateCellUpdateRequestEpic", correctionalFacilityInMateCellUpdateRequestFailure))
    );

const correctionalFacilityInMateCellUpdateRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS),
        map(action => correctionalFacilityInMatesCellsRequest())
    );
    
    
const correctionalFacilityInMateCellInspectRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.inspect(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['correctionalFacilityInMateVenueLocationId'])
                .pipe(
                    map(response => correctionalFacilityInMateCellInspectRequestSuccess(response.draftIncidentId)),
                    catchError(error => notifyError(error, "correctionalFacilityInMateCellInspectRequestSuccess.inspect", correctionalFacilityInMateCellInspectRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityInMateCellInspectRequestSuccess", correctionalFacilityInMateCellInspectRequestFailure))
    );

const correctionalFacilityRiskStatusesRequestEpic = (action$, state$) => 
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            correctionalFacilityServiceApi.getRisks(
                action['pageSize'],
                action['page'],
                state.user.details.userSession.user.venueId)
                .pipe(
                    map(response => correctionalFacilityRiskStatusesRequestSuccess(response.paging, response.riskStatuses)),
                    catchError(error => notifyError(error, "correctionalFacilityRiskStatusesRequestEpic.getRisks", correctionalFacilityRiskStatusesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "correctionalFacilityRiskStatusesRequestEpic", correctionalFacilityRiskStatusesRequestFailure))
    );

const correctionalFacilityRiskStatusSaveRequestEpic = (action$, state$) => 
    action$.pipe(
        ofType(CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            //  (state.correctionalFacility.riskStatus.riskStatus);
            return correctionalFacilityServiceApi.saveRisk(
                state.user.details.userSession.user.venueId,
                state.correctionalFacility.riskStatus.riskStatus)
                .pipe(
                    map(response => correctionalFacilityRiskStatusSaveRequestSuccess(response.paging, response.riskStatuses)),
                    catchError(error => notifyError(error, "correctionalFacilityRiskStatusSaveRequestEpic.saveRisk", correctionalFacilityRiskStatusSaveRequestFailure))
                )
            }
        ),
        catchError(error => notifyError(error, "correctionalFacilityRiskStatusSaveRequestEpic", correctionalFacilityRiskStatusSaveRequestFailure))
    );


export const correctionalFacilityEpics = combineEpics(
    correctionalFacilityInMatesCellsRequestEpic,
    correctionalFacilityInMateCellCreateRequestEpic,
    correctionalFacilityInMateCellSaveRequestEpic,
    correctionalFacilityInMateCellRemoveRequestEpic,
    correctionalFacilityInMateCellRemoveRequestSuccessEpic,
    correctionalFacilityInMateCellUpdateRequestEpic,
    correctionalFacilityInMateCellUpdateRequestSuccessEpic,
    correctionalFacilityRiskStatusesRequestEpic,
    correctionalFacilityRiskStatusSaveRequestEpic,
    correctionalFacilityInMateCellInspectRequestEpic
);