import React, { Component } from 'react';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import DefaultButton from '../controls/DefaultButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BusinessIcon from '@material-ui/icons/Business';
import IncidentIcon from '../../resources/IncidentIcon';
import ChecklistIcon from '../../resources/ChecklistIcon';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import SubTitle from '../common/SubTitle';
import NoRowsMessage from '../common/NoRowsMessage';
import FullPageDialog from '../layout/FullPageDialog';
import AddUserRoleContainer from '../../containers/AddUserRoleContainer';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';
import { Palette } from '../../common/constants/palette';
import { notificationLogic } from '../../common/logic/notificationLogic';

export interface INotificationsProps {
    userId: number;
    groupedNotifications: AusComplyDtos.NotificationGroup[];
    alerts: AusComplyDtos.Notification[];
    groupNotifications: AusComplyDtos.Notification[];
    isLoading: boolean;
    onRefresh: Function;
    onIncidentsRequiringApprovals: Function;
    onDismiss: Function;
    onDismissAll: Function;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

class Notifications extends Component<INotificationsProps, any> {
    constructor(props: INotificationsProps) {
        super(props)
        this.state = {
            expanded: "9",
            roleRequestNotification: { notificationId: 0 }
        }
        this.onExpand = this.onExpand.bind(this);
        this.onRoleRequest = this.onRoleRequest.bind(this);
        this.onCancelRoleRequest = this.onCancelRoleRequest.bind(this);
        this.onShowApproval = this.onShowApproval.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.onDismissAllIncidentCategoryAndFlags = this.onDismissAllIncidentCategoryAndFlags.bind(this);
        this.onNavigateTo = this.onNavigateTo.bind(this);
        this.onDismissAllForGroup = this.onDismissAllForGroup.bind(this);
    }

    componentDidMount() {
        //if (!this.props.isLoading) {
        this.props.onRefresh();
        //}
    }

    onExpand(expanded: string) {
        if (this.state.expanded == expanded) {
            this.setState({ expanded: "" });
        } else {
            this.setState({ expanded });
        }
    }

    onRoleRequest(notification: AusComplyDtos.Notification) {
        this.setState({ roleRequestNotification: notification });
    }

    onCancelRoleRequest() {
        this.setState({ roleRequestNotification: { notificationId: 0 } });
    }

    onShowApproval() {
        this.props.onIncidentsRequiringApprovals();
        this.props.history.push('/incidents');
    }

    onNavigateTo(url: string) {
        this.props.history.push(url);
    }

    onDismiss(notification: AusComplyDtos.Notification) {
        this.props.onDismiss(notification.notificationId, false);
    }

    onDismissAllIncidentCategoryAndFlags(notificationGroup: AusComplyDtos.NotificationGroup) {
        let ids = notificationGroup.notifications
            .map((notification, index) => { return notification.notificationId });
        this.props.onDismissAll(ids, false);
    }

    onDismissAllForGroup(notificationGroup: AusComplyDtos.NotificationGroup) {
        let ids = notificationGroup.notifications
            .filter(f => notificationLogic.dismissable(f))
            .map((notification, index) => { return notification.notificationId });
        this.props.onDismissAll(ids, false);
    }

    onCreateActions(notification: AusComplyDtos.Notification) {
        // build this list from addNotificationActions in controls/notifications.ascx.cs
        if (notification.notificationTypeCode == 'UserRequestsVenueAccess' ||
            notification.notificationTypeCode == 'UserRequestsSecurityFirmAccess' ||
            notification.notificationTypeCode == 'RegisterSecurityFirmUser' ||
            notification.notificationTypeCode == 'RegisterVenueUser' ||
            notification.notificationTypeCode == 'RegisterOtherUser') {
            // show the modal to confirm or deny a user request
            return (
                <Box p={0} style={{ marginTop: '10px' }}>
                    <DefaultButton text={"Approve or Deny"} onClick={() => this.onRoleRequest(notification)} />
                </Box>
            )
        }
        if (notification.notificationTypeId == 'SuspendL1VenueUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1VenueUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1VenueUserReinstate_Command)
        }
        if (notification.notificationTypeId == 'SuspendL1SecurityFirmUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1SecurityFirmUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1SecurityFirmUserReinstate_Command)
        }
        if (notification.notificationTypeId == 'RecommendVenueIncidentDeletion' ||
            notification.notificationTypeId == 'RecommendSecurityFirmIncidentDeletion') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentRequiresSubmission' ||
            notification.notificationTypeId == 'SecurityFirmIncidentRequiresApproval') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'RequestInvalidSLEDAccess') {
            var url = "/profile";
            if (this.props.userId !== notification.userId) {
                url = "/user/" + notification.userId;
            }
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"Approve"} onClick={() => this.props.onDismiss(notification.notificationId, true)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Deny"} onClick={() => this.onDismiss(notification)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"View User"} onClick={() => this.onNavigateTo(url)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry2Weeks' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeId == 'UserDocumentMissing') {
            var url = "/profile";
            if (this.props.userId !== notification.userId) {
                url = "/user/" + notification.userId;
            }
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Update Documents"} onClick={() => this.onNavigateTo(url)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'L4VenueRegisterUserComplianceDocumentExpiring') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Review Compliance Documents"} onClick={() => this.onNavigateTo("/venue/expiringcompliancedocuments")} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'L3SecurityFirmRegisterUserComplianceDocumentExpiring' ||
            notification.notificationTypeId == 'L4SecurityFirmRegisterUserComplianceDocumentExpiring') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Review Compliance Documents"} onClick={() => this.onNavigateTo("/securityfirm/expiringcompliancedocuments")} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentBulkApproval') {
            //onIncidentsRequiringApprovals
            // show the modal to confirm or deny a user request
            return (
                <Box p={0} style={{ marginTop: '10px' }}>
                    <DefaultButton text={"View"} onClick={() => this.onShowApproval()} />
                </Box>
            );
        }
        if (notification.notificationTypeId == 'Broadcast') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'FacialRecognition') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentFlagNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentCategoryNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentActionNotification' ||
            notification.notificationTypeId == 'IncidentActionValueNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'GroupUserAccessRequestNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Group"} onClick={() => this.onNavigateTo("/groups/" + notification.groupId.toString())} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }

        if (notification.notificationTypeId == 'GroupVenueInvitationNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"Accept"} onClick={() => this.props.onDismiss(notification.notificationId, true)} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentView/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo("/checklist/" + notification.checklistId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'WatchlistEventNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {notification.incidentId && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentview/" + notification.incidentId.toString())} /></Box>
                        )}
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'ChecklistSubmitted' ||
            notification.notificationTypeId == 'ChecklistValueSubmitted') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo("/checklist/" + notification.checklistId.toString())} /></Box>
                        <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmIncident) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo("/incidentView/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmChecklist) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo("/checklist/" + notification.checklistId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenue ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirm) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.onDismiss(notification)} /></Box>
                    </Box>
                </Box>
            )
        }
        return (
            <>
            </>
        );
    }

    notificationBackgroundColor(notification: AusComplyDtos.Notification) {
        if (notification.isEscalation ) {
            return Palette.Error;
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#C62828";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#FEFFC6";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist) {
            return notification.isOverDue ? Palette.Error : Palette.Warning;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation ) {
            return Palette.Error;
        }
        return "#061570";
    }

    notificationColor(notification: AusComplyDtos.Notification) {
        if (notification.isEscalation ) {
            return "white";
        }

        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#5d5c1d";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist) {
            return notification.isOverDue ? "white" : Palette.WarningText;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation ) {
            return "white";
        }
        return "white";
    }

    notificationDateColor(notification: AusComplyDtos.Notification) {
        if (notification.isEscalation ) {
            return Palette.ErrorTextAlt;
        }

        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#bdbdbd";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#5d5c1d";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist ) {
            return notification.isOverDue ? Palette.ErrorTextAlt : Palette.WarningTextAlt;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation ) {
            return Palette.ErrorTextAlt;
        }
        return "#888";
    }

    notificationBorderColor(notification: AusComplyDtos.Notification) {
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#d32f2f";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#FEFFC6";
        }
        return "#061570";
    }

    renderIcon(notification: AusComplyDtos.Notification) {
        let icon;
        if (notification.notificationCategoryId == 1) {
            icon = <PermIdentityIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 2) {
            icon = <BusinessIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 3) {
            icon = <BusinessIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 4) {
            icon = <div style={{ height: '20px' }}>
                <IncidentIcon fill={this.notificationDateColor(notification)} />
            </div>
        }
        if (notification.notificationCategoryId == 5) {
            icon = <div style={{ height: '20px' }}>
                <ChecklistIcon fill={this.notificationDateColor(notification)} />
            </div>
        }
        return icon;
    }

    render() {
        const { classes } = this.props;
        const EXPANDED_LIMIT = 40;
        let groupCount = 0;
        let alertCount = 0;
        let groupNotificationCount = 0;
        if (this.props.groupedNotifications && this.props.groupedNotifications.length > 0) {
            groupCount = this.props.groupedNotifications.length;
        }
        if (this.props.alerts && this.props.alerts.length > 0) {
            alertCount = this.props.alerts.length;
        }
        if (this.props.groupNotifications && this.props.groupNotifications.length > 0) {
            groupNotificationCount = this.props.groupNotifications.length;
        }
        return (
            <PageLayout
                headerText={"Notifications"}
                loading={this.props.isLoading}
                footerLeftContent={<TransparentButton text={"Refresh"} onClick={() => this.props.onRefresh()} />}
            >
                {(!this.props.isLoading && groupCount == 0 && alertCount == 0 && groupNotificationCount == 0) && (
                    <NoRowsMessage message={"No notifications"} />
                )}
                {(!this.props.isLoading && (groupCount > 0 || alertCount > 0 || groupNotificationCount > 0)) && (
                    <>
                        {this.props.groupNotifications && this.props.groupNotifications.length > 0 && (
                            <>
                                <SubTitle text={"Group Notifications"} />
                                {this.props.groupNotifications.map((notification, index) =>
                                    <Box
                                        key={"notification-group-" + notification.notificationId.toString()}
                                        display="flex" flexDirection="row" justifyContent="center" style={{
                                            borderRadius: '5px',
                                            backgroundColor: this.notificationBackgroundColor(notification),
                                            borderColor: this.notificationBorderColor(notification),
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            width: '100%', marginBottom: '10px'
                                        }}>
                                        <Box p={2}>
                                            {this.renderIcon(notification)}
                                        </Box>
                                        <Box p={2} flexGrow={1}>
                                            <Box display="flex" flexDirection="column">
                                                <Box p={0}>
                                                    <p style={{ margin: '2px 0', color: 'white', whiteSpace: 'pre-line' }}>
                                                        <span style={{
                                                            color: this.notificationDateColor(notification),
                                                            marginRight: '10px'
                                                        }}>{notification.dateTimeFormatted}</span>
                                                        {notification.message}
                                                    </p>
                                                </Box>
                                                {this.onCreateActions(notification)}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )}
                        {this.props.alerts && this.props.alerts.map((notification, index) =>
                            <Box
                                key={"notification-" + notification.notificationId.toString()}
                                display="flex" flexDirection="row" justifyContent="center" style={{
                                    borderRadius: '5px',
                                    backgroundColor: this.notificationBackgroundColor(notification),
                                    borderColor: this.notificationBorderColor(notification),
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    width: '100%', marginBottom: '10px'
                                }}>
                                <Box p={2}>
                                    {this.renderIcon(notification)}
                                </Box>
                                <Box p={2} flexGrow={1}>
                                    <Box display="flex" flexDirection="column">
                                        <Box p={0}>
                                            <p style={{ margin: '2px 0', color: this.notificationColor(notification), whiteSpace: 'pre-line' }}>
                                                <span style={{
                                                    color: this.notificationDateColor(notification),
                                                    marginRight: '10px'
                                                }}>{notification.dateTimeFormatted}</span>
                                                {notification.message}
                                            </p>
                                        </Box>
                                        {this.onCreateActions(notification)}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {this.props.groupedNotifications && this.props.groupedNotifications.length > 0 && (
                            <SubTitle text={"Require Action"} />
                        )}
                        <div style={{ width: '100%' }}>
                            {this.props.groupedNotifications.map((item, index) => {
                                const expanded = this.state.expanded === item.notificationGroupId.toString();
                                const limited = item.notifications.length > EXPANDED_LIMIT;
                                const countMore = item.notifications.length - EXPANDED_LIMIT;
                                return (
                                    <ExpansionPanel
                                        key={"notification-group-x-" + index.toString() + "-" + item.notificationGroupId.toString()}
                                        expanded={expanded}
                                        onChange={() => this.onExpand(item.notificationGroupId.toString())}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                                            aria-controls={item.notificationGroupId.toString() + "-content"}
                                            id={item.notificationGroupId.toString() + "-header"}
                                        >
                                            <Box display="flex" flexDirection="row" style={{ width: "100%" }}>
                                                <Box p={0} flexGrow={0}><Typography>{item.title}</Typography></Box>
                                                <Box p={0} flexGrow={0}><Chip label={item.count} size="small" style={{ marginLeft: '30px' }}></Chip></Box>
                                                <Box p={0} flexGrow={1}></Box>
                                                <Box p={0} flexGrow={0}>
                                                    {(item.notificationGroupId > 0 && notificationLogic.groupHasDismissable(item)) && (
                                                        <TransparentButton text={"Dismiss All"}
                                                            small={true} style={{ fontSize: '10px', padding: '5px' }}
                                                            onClick={(e) => {
                                                                this.onDismissAllForGroup(item);
                                                                e.stopPropagation();
                                                            }} />
                                                    )}
                                                </Box>
                                            </Box>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <div style={{ display: "block", width: '100%', position: 'relative' }}>
                                                {item.notifications.map((notification, index) => <>
                                                    {(index < EXPANDED_LIMIT) && (
                                                        <>
                                                            <Box
                                                                key={"notification-" + index.toString() + "-" + notification.notificationId.toString()}
                                                                display="flex" flexDirection="row" justifyContent="center"
                                                                style={{
                                                                    borderRadius: '5px',
                                                                    backgroundColor: this.notificationBackgroundColor(notification),
                                                                    borderColor: this.notificationBorderColor(notification),
                                                                    width: '100%',
                                                                    marginBottom: '10px'
                                                                }}>
                                                                <Box p={2}>
                                                                    {this.renderIcon(notification)}
                                                                </Box>
                                                                <Box p={2} flexGrow={1}>
                                                                    <Box display="flex" flexDirection="column">
                                                                        <Box p={0}>
                                                                            <p style={{ margin: '2px 0', color: this.notificationColor(notification), whiteSpace: 'pre-line' }}>
                                                                                <span style={{ color: this.notificationDateColor(notification), marginRight: '10px' }}>{notification.dateTimeFormatted}</span>
                                                                                {notification.message}
                                                                            </p>
                                                                        </Box>
                                                                        {this.onCreateActions(notification)}
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    )}
                                                </>
                                                )}
                                                {limited && (
                                                    <p style={{ margin: '10px', color: '#888', textAlign: 'center' }}>{countMore} more</p>
                                                )}
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                );
                            }
                            )}
                        </div>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" style={{ marginTop: '10px' }}>
                            <Box p={1} >
                                <PermIdentityIcon style={{ color: '#888' }} />
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>User</p>
                            </Box>
                            <Box p={1} >
                                <div style={{ margin: '2px 10px 0 0', height: '20px' }}>
                                    <IncidentIcon fill={'#888'} />
                                </div>
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>Incident</p>
                            </Box>
                            <Box p={1} >
                                <div style={{ margin: '2px 10px 0 0', height: '20px' }}>
                                    <ChecklistIcon fill={'#888'} />
                                </div>
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>Checklist</p>
                            </Box>
                            <Box p={1} >
                                <BusinessIcon style={{ color: '#888' }} />
                            </Box>
                            <Box p={1} >
                                <p style={{ margin: '2px 10px 0 0', color: '#888' }}>Other</p>
                            </Box>
                        </Box>
                    </>
                )}
                <FullPageDialog
                    title={"Access Request"}
                    open={this.state.roleRequestNotification.notificationId != 0}
                    notFullWidth={true}
                    onDismissed={() => this.onCancelRoleRequest()}
                >
                    {this.state.roleRequestNotification.notificationId != 0 && (
                        <>
                            <AddUserRoleContainer
                                userId={this.state.roleRequestNotification.userId}
                                notificationId={this.state.roleRequestNotification.notificationId}
                                venueId={this.state.roleRequestNotification.venueId}
                                securityFirmId={this.state.roleRequestNotification.securityFirmId}
                                onSuccess={() => this.onCancelRoleRequest()}
                                forSecurityFirm={this.state.roleRequestNotification.securityFirmId && this.state.roleRequestNotification.securityFirmId != 0} />
                        </>
                    )}
                </FullPageDialog>
            </PageLayout>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(Notifications))