import React from 'react';
import Paging from './Paging';
import Media from "react-media";
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import NoRowsMessage from '../common/NoRowsMessage';

export interface IListProps {
    loading?: boolean;
    saving?: boolean;
    count: number;
    cards: any;
    rows: any;
    footer?: any;
    noRowsMessage?: string;
}

export default class List extends React.Component<IListProps, any> {

    render() {
        let content = (<Loading />);
        if (this.props.saving) {
            content = (<Saving />);
        }
        if (!this.props.loading && !this.props.saving) {
            
            if (this.props.count === 0) {
                content = (
                    <NoRowsMessage message={this.props.noRowsMessage}></NoRowsMessage>
                );
            } else {
                content = (
                    <div className="paged-list">
                        <Media query={{ maxWidth: 1279 }}>
                            {matches =>
                                matches ? (
                                    <div>{this.props.cards}</div>
                                ) : (
                                        <div>{this.props.rows}</div>
                                    )
                            }
                        </Media>
                        { this.props.footer }
                    </div>
                );
            }
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}
