
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const securityFirmAssociationLogic = {

    getCommands(item: AusComplyDtos.SecurityFirmAssociation, canRemove: boolean) {
        var commands: KeyName[] = [];
        if (canRemove) {
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}