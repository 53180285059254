import { connect } from 'react-redux'
import GroupDirectory from '../components/directory/GroupDirectory'
import * as directoryActions from '../common/actions/directory'
import * as AusComplyDtos from "../common/dto/AusComply.dtos";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props)=> {
    return {
        groupId: props.groupId,
        tabs: props.tabs,
        title: props.title,
        backButton: props.backButton,
        isLoading: state.directories.isLoading,
        isSaving: state.directories.isSaving,
        directory: state.directories.directory
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (entityType, id) => {
            dispatch(directoryActions.directoryRequest(entityType, id))
        },
        onUpsertFolder: (entityType, id, folder) => {
            dispatch(directoryActions.directoryUpsertFolderRequest(entityType, id, folder))
        }
    }
}

const GroupDirectoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupDirectory)

export default GroupDirectoryContainer