import { connect } from 'react-redux'
import { withRouter } from "react-router";
import DocumentType from '../components/admin/documentType/DocumentType'
import { 
    adminDocumentTypeReset,
    adminDocumentTypeSet,
    adminDocumentTypeUpsertRequest
 } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.admin.documentType.isLoading,
        isSaving: state.admin.documentType.isSaving,
        documentType: state.admin.documentType.documentType,
        documentCategories: state.admin.documentType.documentCategories,
        entityTypes: state.admin.documentType.entityTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(adminDocumentTypeReset())
        },
        onUpdate: (documentType) => {
            dispatch(adminDocumentTypeSet(documentType))
        },
        onSave: () => {
            dispatch(adminDocumentTypeUpsertRequest())
        }
    }
}

const AdminDocumentTypeContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentType))

export default AdminDocumentTypeContainer