import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueLocations from '../components/venue/venueLocations/VenueLocations'
import { 
    venueLocationsRequest, 
    venueLocationCreateRequest, 
    venueLocationRequest, 
    venueLocationReset,
    venueLocationSet,
    venueLocationUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.venue.venueLocations.paging,
        filter: state.venue.venueLocations.filter,
        isLoading: state.venue.venueLocations.isLoading,
        venueLocations: state.venue.venueLocations.venueLocations,
        venueLocationId: state.venue.venueLocation.venueLocationId,
        canViewSubLocations: permissionsLogic.hasPermissionForState(state, "SubLocations"),
        canCreate: permissionsLogic.hasPermissionForState(state, "ManageVenue")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(venueLocationsRequest(page, pageSize, filter))
        },
        onLoadItem: (venueLocationId) => {
            dispatch(venueLocationRequest(venueLocationId))
        },
        onCreate: (venueId) => {
            dispatch(venueLocationCreateRequest(venueId))
        },
        onResetItem: () => {
            dispatch(venueLocationReset())
        },
        onUpdate: (venueLocation) => {
            dispatch(venueLocationSet(venueLocation))
        },
        onSave: () => {
            dispatch(venueLocationUpsertRequest())
        }
    }
}

const VenueLocationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueLocations))

export default VenueLocationsContainer