import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import View from '../common/View';

export interface ISignOnRegisterTypesProps {
    error?: string;
    userSignOnStatus: AusComplyDtos.UserSignOnStatus;
    onUpdate: Function;
}

interface ISignOnRegisterTypesState {
    items: MulitSelectOption[];
}

export default class SignOnRegisterTypes extends Component<ISignOnRegisterTypesProps, ISignOnRegisterTypesState> {
    constructor(props: ISignOnRegisterTypesProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps: Readonly<ISignOnRegisterTypesProps>, prevState: Readonly<ISignOnRegisterTypesState>, snapshot?: any): void {
        if (prevProps.userSignOnStatus) {
            if (JSON.stringify(prevProps.userSignOnStatus.selectedSignOnRegisterTypes) !==
                JSON.stringify(this.props.userSignOnStatus.selectedSignOnRegisterTypes)) {
                this.onBuildItems();
            } else if (JSON.stringify(prevProps.userSignOnStatus.signOnRegisterTypes) !==
                JSON.stringify(this.props.userSignOnStatus.signOnRegisterTypes)) {
                this.onBuildItems();
            }
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let selectedIncidentCategoryTypeIds: number[] = [];
        if (this.props.userSignOnStatus && this.props.userSignOnStatus.signOnRegisterTypes) {
            selectedIncidentCategoryTypeIds = [...this.props.userSignOnStatus.selectedSignOnRegisterTypes];
        }
        if (this.props.userSignOnStatus && this.props.userSignOnStatus.signOnRegisterTypes) {
            this.props.userSignOnStatus.signOnRegisterTypes.map(item => {
                let isSelected = selectedIncidentCategoryTypeIds.indexOf(item.signOnRegisterTypeId) > -1;
                items.push({
                    id: item.signOnRegisterTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let userSignOnStatus = { ...this.props.userSignOnStatus };
        userSignOnStatus.selectedSignOnRegisterTypes = items.map(item => item.id);
        this.props.onUpdate(userSignOnStatus);
    }

    onChanged(fieldName: string, value) {
        let userSignOnStatus = { ...this.props.userSignOnStatus };
        userSignOnStatus[fieldName] = value;
        this.props.onUpdate(userSignOnStatus)
    }

    render() {
        if (!this.props.userSignOnStatus ||
            !this.props.userSignOnStatus.signOnRegisterTypes ||
            this.props.userSignOnStatus.signOnRegisterTypes.length < 2
        ) {
            return <></>;
        }

        return (
            <View style={{marginTop: "10px"}}>
                <MultiSelectControl
                    items={this.state.items}
                    label={""}
                    error={this.props.error}
                    cardTitle="Sign On Types"
                    wrapControlInCard={true}
                    searchText="Type at least three letters..."
                    minimumCharactersRequired={3}
                    onSelectItemsChanged={this.onSelectItemsChanged}
                    canShowAll={true} />
            </View>
        );
    }
}