import { connect } from 'react-redux'
import { withRouter } from "react-router";
import UserComplianceDocumentsMissing from '../components/venue/userComplianceDocuments/missingDocuments/UserComplianceDocumentsMissing';
import { 
    userComplianceDocumentsMissingRequest,
    userComplianceDocumentsMissingExportRequest
 } from '../common/actions/userComplianceDocument';

const mapStateToProps = (state, props) => {
    return {
        paging: state.userComplianceDocument.missing.paging,
        filter: state.userComplianceDocument.missing.filter,
        isLoading: state.userComplianceDocument.missing.isLoading,
        userComplianceDocuments: state.userComplianceDocument.missing.userComplianceDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(userComplianceDocumentsMissingRequest(page, pageSize, filter))
        },
        onExport: () => {
            dispatch(userComplianceDocumentsMissingExportRequest())
        }
    }
}

const VenueUserComplianceDocumentsMissingContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserComplianceDocumentsMissing))

export default VenueUserComplianceDocumentsMissingContainer