
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const securityFirmContactLogic = {

    getCommands(securityFirmContact: AusComplyDtos.SecurityFirmContact, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            if (!securityFirmContact.obsolete && !securityFirmContact.primaryContact) {
                commands.push({ key: "primary", name: "Make Primary" });
            }
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }
}