import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const imageProcessingServiceApi = {
  postHistoricalProcessing: request.makePost(
    AusComplyDtos.PostProcessImageTriggerHistoricalProcessing,
    'venueId', 'securityFirmId', 'forVenueId', 'fromDate'
  ),
  postTemporaryFile: request.makePost(
    AusComplyDtos.PostProcessImageTemporaryFile,
    'venueId', 'securityFirmId', 'filePath'
  ),
  postIncidentDocument: request.makePost(
    AusComplyDtos.PostProcessImageIncidentDocument,
    'venueId', 'securityFirmId', 'incidentDocumentId', 'saveToDocument'
  ),
  postIncidentPatronPhoto: request.makePost(
    AusComplyDtos.PostProcessImageIncidentPatronPhoto,
    'venueId', 'securityFirmId', 'incidentPatronPhotoId', 'saveToDocument'
  ),
};
