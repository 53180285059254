
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { commonLogic } from './common';
import { CheckListDisplayItem } from '../dto/AusComply.dtos';

export const checklistLogic = {
    validateChecklistDisplay(items: CheckListDisplayItem[]) {

        var errors = {};
        var count = 0;
        try {
            if (items) {
                items.forEach(item => {
                    // check if this is required
                    if (item.show && item.item && item.template) {
                        if (item.template.mandatory && !item.item.readonly) {

                            switch (item.template.checklistItemType) {
                                case AusComplyDtos.ChecklistItemType.TextBox:
                                    if (!item.item.value || item.item.value == "") {
                                        errors[item.item.checklistTemplateItemId.toString()] = "required";
                                        count++;
                                    }
                                    break;
                                case AusComplyDtos.ChecklistItemType.Checkbox:
                                    if (!item.item.value || item.item.value.toString() == "" || item.item.value.toString() == "false") {
                                        errors[item.item.checklistTemplateItemId.toString()] = "required";
                                        count++;
                                    }
                                    break;
                                case AusComplyDtos.ChecklistItemType.DateTime:
                                    if (!item.item.value || item.item.value.toString() == "") {
                                        errors[item.item.checklistTemplateItemId.toString()] = "required";
                                        count++;
                                    }
                                    break;
                                case AusComplyDtos.ChecklistItemType.Question:
                                    if (!item.item.value || item.item.value.toString() == "") {
                                        // validate that there are options available to select
                                        if (item.template.checklistTemplateItemOptions && item.template.checklistTemplateItemOptions.length > 0) {
                                            errors[item.item.checklistTemplateItemId.toString()] = "required";
                                            count++;
                                        }
                                    }
                                    break;
                                case AusComplyDtos.ChecklistItemType.MultipleChoice:
                                    if (!item.item.value || item.item.value.toString() == "" || item.item.value.toString() == "[]") {
                                        if (item.template.checklistTemplateItemOptions && item.template.checklistTemplateItemOptions.length > 0) {
                                            errors[item.item.checklistTemplateItemId.toString()] = "required";
                                            count++;
                                        }
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        if (item.template.requiredAttachmentCount > 0 && !item.item.readonly) {
                            let fileCount = 0;
                            if (item.item.files != undefined && item.item.files != null) {
                                fileCount = item.item.files.length;
                            }
                            if (fileCount < item.template.requiredAttachmentCount) {
                                errors[item.item.checklistTemplateItemId.toString()] = "required";
                                count++;
                            }
                        }
                    }
                });
            }
        }
        catch (err) {
            commonLogic.trackException(err, "checklistLogic.validateChecklist", {})
        }
        errors["count"] = count;
        return errors;
    },
    validateChecklistDisplayItemIsRequired(item: CheckListDisplayItem) {
        if (item.item && item.template) {
            if (item.template.mandatory) {

                switch (item.template.checklistItemType) {
                    case AusComplyDtos.ChecklistItemType.TextBox:
                        if (!item.item.value || item.item.value == "") {
                            return true;
                        }
                        break;
                    case AusComplyDtos.ChecklistItemType.Checkbox:
                        if (!item.item.value || item.item.value.toString() == "" || item.item.value.toString() == "false") {
                            return true;
                        }
                        break;
                    case AusComplyDtos.ChecklistItemType.DateTime:
                        if (!item.item.value || item.item.value.toString() == "") {
                            return true;
                        }
                        break;
                    case AusComplyDtos.ChecklistItemType.Question:
                        if (!item.item.value || item.item.value.toString() == "") {
                            // validate that there are options available to select
                            if (item.template.checklistTemplateItemOptions && item.template.checklistTemplateItemOptions.length > 0) {
                                return true;
                            }
                        }
                        break;
                    case AusComplyDtos.ChecklistItemType.Selection:
                        //console.log('ChecklistItemType.Selection',item.item.value, "0", item.item.value.toString() == "0", item.template.checklistTemplateItemOptions.filter(f => f.value == "0").length )
                        if (!item.item.value || item.item.value.toString() == "" ||
                            (item.item.value && item.item.value.toString() == "0" && item.template.checklistTemplateItemOptions.filter(f => f.value == "0").length == 0) ||
                            (item.item.value && item.item.value.toString() == "\"0\"" && item.template.checklistTemplateItemOptions.filter(f => f.value == "\"0\"").length == 0)
                            ) {
                            // validate that there are options available to select
                            if (item.template.checklistTemplateItemOptions && item.template.checklistTemplateItemOptions.length > 0) {
                                return true;
                            }
                        }
                        break;
                    case AusComplyDtos.ChecklistItemType.MultipleChoice:
                        if (!item.item.value || item.item.value.toString() == "" || item.item.value.toString() == "[]") {
                            // validate that there are options available to select
                            if (item.template.checklistTemplateItemOptions && item.template.checklistTemplateItemOptions.length > 0) {
                                return true;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
            if (item.template.requiredAttachmentCount > 0 && !item.item.readonly) {
                let fileCount = 0;
                if (item.item.files != undefined && item.item.files != null) {
                    fileCount = item.item.files.length;
                }
                if (fileCount < item.template.requiredAttachmentCount) {
                    return true;
                }
            }
        }
        return false;
    },
    validateChecklistDisplayItemsShown(items: AusComplyDtos.CheckListDisplayItem[]) {
        if (!items) return items;
        if (items.length === 0) return items;
        let result = [...items];
        result.forEach(item => {
            let show: boolean = true;
            if (item.template && item.template.checklistTemplateItemFilters) {
                const activeFilters = item.template.checklistTemplateItemFilters.filter(f => !f.obsolete);
                if (activeFilters.length > 0) {
                    // determine if any filter fails
                    activeFilters.forEach(filter => {
                        // find the dependant record
                        const parentItem = result.find(f => f.item.checklistTemplateItemId == filter.parentChecklistTemplateItemId);
                        if (parentItem && !parentItem.show) {
                            show = false;
                        } else if (parentItem) {
                            // is there a value
                            let value = parentItem.item.value;
                            const template = parentItem.template;
                            if (value == undefined || value == null) {
                                value = "";
                            }
                            if (template.checklistItemType == AusComplyDtos.ChecklistItemType.TextBox) {
                                if (value.split('"').join('').toLowerCase() !== filter.value.toLowerCase()) {
                                    show = false;
                                }
                            } else if (template.checklistItemType == AusComplyDtos.ChecklistItemType.Checkbox) {
                                if (value.toString() == "true" && filter.value.toLowerCase().indexOf(",checked") === -1) {
                                    show = false;
                                } else if (value.toString() !== "true" && filter.value.toLowerCase().indexOf(",unchecked") == -1) {
                                    show = false;
                                }
                            } else { // options based
                                let filterValue = filter.value;
                                if (filterValue == undefined || filterValue == null) {
                                    filterValue = "";
                                }
                                const values = this.buildSelectedStringList(filterValue);
                                const currentValues = this.buildSelectedStringList(value);

                                // look for an array intersection
                                const intersection = values.filter(valueItem => currentValues.includes(valueItem));
                                if (intersection.length == 0) {
                                    show = false;
                                }
                            }
                        }
                    });
                }
            }
            item.show = show;
            if (!item.show && item.isRequired) {
                item.isRequired = false;
            }
        });
        return result;
    },
    buildDisplayItems(checklist: AusComplyDtos.Checklist, checklistTemplate: AusComplyDtos.ChecklistTemplate) {
        let items: CheckListDisplayItem[] = [];
        if (checklist && checklist.checklistItems) {
            if (checklistTemplate && checklistTemplate.checklistTemplateItems) {
                checklist.checklistItems.forEach((item, index) => {
                    let displayItem = new CheckListDisplayItem();
                    displayItem.item = { ...item };
                    let checklistTemplateItem = checklistTemplate.checklistTemplateItems.find(cti => cti.checklistTemplateItemId == item.checklistTemplateItemId);
                    if (!checklistTemplateItem && checklist.checklistItems.length === checklistTemplate.checklistTemplateItems.length) {
                        try {
                            checklistTemplateItem = checklistTemplate.checklistTemplateItems[index];
                        } catch (error) {

                        }
                    }
                    if (checklistTemplateItem) {
                        displayItem.template = { ...checklistTemplateItem };
                        displayItem.offset = checklistTemplateItem.level;
                    } else {
                        displayItem.isTemplateMissing = true;
                    }
                    displayItem.isRequired = checklistLogic.validateChecklistDisplayItemIsRequired(displayItem);
                    items.push(displayItem);
                });
            }
        }
        items = this.validateChecklistDisplayItemsShown(items);
        return items;
    },
    isReadonly(checklistStatus: AusComplyDtos.ChecklistStatuses) {
        return checklistStatus != AusComplyDtos.ChecklistStatuses.InProgress && checklistStatus != AusComplyDtos.ChecklistStatuses.Rejected;
    },
    canApprove(checklistStatus: AusComplyDtos.ChecklistStatuses, canApprovePermission: boolean) {
        return checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted && canApprovePermission;
    },
    canReject(checklistStatus: AusComplyDtos.ChecklistStatuses, canRejectPermission: boolean) {
        return (checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted ||
            checklistStatus == AusComplyDtos.ChecklistStatuses.Approved
        ) && canRejectPermission;
    },
    canClose(checklistStatus: AusComplyDtos.ChecklistStatuses, canClosePermission: boolean) {
        return checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted && canClosePermission;
    },
    canArchive(checklistStatus: AusComplyDtos.ChecklistStatuses, canArchivePermission: boolean) {
        return canArchivePermission;
    },
    canUndoArchive(checklist: AusComplyDtos.ChecklistSummary, canArchivePermission: boolean) {
        return checklist.obsolete && canArchivePermission;
    },
    buildSelectedStringList(value: any) {
        let selected: string[] = [];
        if (value) {
            let currentSelected = (value as any) as string[];
            if (!(currentSelected instanceof Array)) {
                let raw = value;
                if (raw !== undefined && raw !== null && typeof raw === 'string') {
                    if (raw.startsWith("[")) {
                        raw = raw.substring(1);
                    }
                    if (raw.endsWith("]")) {
                        raw = raw.substring(0, raw.length - 1);
                    }
                    currentSelected = raw.split(",").map((val) => val.toString());
                }
            }
            if (!(currentSelected instanceof Array)) {
                currentSelected = [value.toString()];
            }
            selected = currentSelected.map((p) => p.toString().toLowerCase().split('"').join('')).filter(f => f != "");
        }
        selected = selected || [];
        return selected;
    }
};

/*
export enum ChecklistStatuses
{
    InProgress = 'InProgress',
    Approved = 'Approved',
    Closed = 'Closed',
    Submitted = 'Submitted',
    Rejected = 'Rejected',
}
*/