import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import { RadioOption } from '../../common/dto/common';
import { NamedValue } from '../../common/dto/AusComply.dtos';
import RadioChipControl from './RadioChipControl';

export interface IRadioChipControlGroupProps {
    text?: string;
    name: string;
    error?: string;
    selected?: any;
    required?: boolean;
    list?: NamedValue[];
    emptyText?: string;
    chipWidth?: string;
    readonly?: boolean;
    onChanged?: Function;
    labelAbove?: boolean;
}

class RadioChipControlGroup extends React.PureComponent<IRadioChipControlGroupProps, any> {

    constructor(props: IRadioChipControlGroupProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChanged) {
            this.props.onChanged(parseInt(value));
        }
    }

    render() {

        let options: RadioOption[] = [];

        if (this.props.list) {
            this.props.list.forEach(item => {
                options.push({ id: item.id.toString(), name: item.name });
            });
        }

        if (this.props.emptyText) {
            options.push({ id: "0", name: this.props.emptyText });
        }

        let selected = "";
        if (this.props.selected) {
            selected = this.props.selected.toString();
        } else if (!this.props.selected && this.props.emptyText) {
            selected = "0";
        }

        let text = this.props.text;
        let rightText = "";
        let error = this.props.error ? true : false;
        if (this.props.required && !this.props.selected) {
            error = true;
            rightText = "(required)";
        }

        // If selected, compact to one line if possible
        let inline = false;
        if (this.props.selected) {
            inline = true;
        }

        if (!this.props.text) {
            return (
                <RadioChipControl
                    selected={selected}
                    chipWidth={this.props.chipWidth}
                    required={this.props.required} 
                    options={options} 
                    readonly={this.props.readonly}
                    name={this.props.name} 
                    onChange={(val) => this.onChange(val.value)} />
            );
        }

        return (
            <ControlGroup text={text} error={error} errorText={this.props.error} labelAbove={this.props.labelAbove} rightText={rightText} inline={inline}>
                <RadioChipControl selected={selected}
                    chipWidth={this.props.chipWidth}
                    options={options} readonly={this.props.readonly} name={this.props.name} onChange={(val) => this.onChange(val.value)} />
            </ControlGroup>
        );
    }
}

export default RadioChipControlGroup;