import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import BadgeWrapper from '../../common/BadgeWrapper';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import DarkButton from '../../controls/DarkButton';
import NoRowsMessage from '../../common/NoRowsMessage';
import View from '../../common/View';
import Box from '@material-ui/core/Box';

export interface ICommsLogsSummaryProps {
    isLoading: boolean;
    reportingPeriodId: number;
    items: AusComplyDtos.VenueIncidentCategoryTypeUsage[];
    onRefresh: Function;
    canView: boolean;
    theme: any;
    classes: any;
    history: any;
}

class CommsLogsSummary extends Component<ICommsLogsSummaryProps, any> {

    componentDidMount(): void {
        this.props.onRefresh(this.props.reportingPeriodId);
    }

    componentDidUpdate(prevProps: Readonly<ICommsLogsSummaryProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.reportingPeriodId != this.props.reportingPeriodId) {
            this.props.onRefresh(this.props.reportingPeriodId);
        }
    }

    onNavigate(page) {
        this.props.history.push(page);
    }

    render() {
        const { theme, classes } = this.props;

        if (!this.props.canView || this.props.reportingPeriodId === 0) {
            return <></>
        }

        let content;
        if (!this.props.isLoading) {
            if (this.props.items == undefined || this.props.items.length == 0) {
                content = <Grid item xs={12} >
                    <NoRowsMessage message={"There are no records in this reporting period"} />
                </Grid>
            } else {
                content = this.props.items.map((item, index) => 
                    <Box flexGrow={1} p={0}>
                        <div onClick={() => this.onNavigate("/commslogs")} style={{ margin: '2px' }}>
                            <View className={classes.homeStatisticsButton}>
                                <p className={item.usageCount > 0 ? classes.homeStatisticsLinkCount : classes.homeStatisticsLinkCountZero}>{item.usageCount.toString()}</p>
                                <p className={item.usageCount > 0 ? classes.homeStatisticsLinkLabel : classes.homeStatisticsLinkLabelZero}>{item.name}</p>
                            </View>
                        </div>
                    </Box>
                );
            }
        }

        return (
            <CardContainer
                isLoading={this.props.isLoading}
                title={"Comms Logs Summary"}>
                <CardContent>
                    <View style={{ padding: 0 }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" style={{ width: '100%' }} >
                            {content}
                        </Box>
                    </View>
                </CardContent>
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CommsLogsSummary);