import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';

export interface IRegisterUserSecurityFirmsProps {
    securityFirmIds: number[];
    securityFirms: AusComplyDTOs.SecurityFirmSelection[],
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

interface IRegisterUserSecurityFirmsState {
    items: MulitSelectOption[];
}

export default class RegisterUserSecurityFirms extends Component<IRegisterUserSecurityFirmsProps, IRegisterUserSecurityFirmsState> {
    constructor(props: IRegisterUserSecurityFirmsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
    }

    componentDidMount() {
        let items: MulitSelectOption[] = [];
        if (this.props.securityFirms) {
            this.props.securityFirms.map((item, index) => {
                let isSelected =  this.props.securityFirmIds && this.props.securityFirmIds.indexOf(item.securityFirmId) > -1;
                items.push({
                    id: item.securityFirmId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items: items
        });
    }

    onSelectItemsChanged(items) {
        let currentIds = items.map(item => item.id);
        this.props.onUpdate(currentIds);
    }

    render() {
        
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Select security firm(s)"}>
                    <CardContent style={{paddingTop: 0}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                    <MultiSelectControl
                                            items={this.state.items}
                                            label={""}
                                            clearSearchOnSelect={true}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectItemsChanged}
                                            canShowAll={false} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}