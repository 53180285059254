import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import { incidentLogic } from '../../common/logic/incidentLogic';
import TextControlGroup from '../controls/TextControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IScheduleIncidentCategoryTypesProps {
    schedule: AusComplyDtos.Schedule;
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[];
    onUpdate: Function;
}

interface IScheduleIncidentCategoryTypesState {
    items: MulitSelectOption[];
}

export default class ScheduleIncidentCategoryTypes extends Component<IScheduleIncidentCategoryTypesProps, IScheduleIncidentCategoryTypesState> {
    constructor(props: IScheduleIncidentCategoryTypesProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps: Readonly<IScheduleIncidentCategoryTypesProps>, prevState: Readonly<IScheduleIncidentCategoryTypesState>, snapshot?: any): void {
        if (JSON.stringify(prevProps.incidentCategoryTypes) !== JSON.stringify(this.props.incidentCategoryTypes)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleIncidentCategoryTypes) !== JSON.stringify(this.props.schedule.scheduleIncidentCategoryTypes)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let selectedIncidentCategoryTypeIds: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleIncidentCategoryTypes) {
            selectedIncidentCategoryTypeIds = this.props.schedule.scheduleIncidentCategoryTypes.filter(f => !f.obsolete).map(item => item.incidentCategoryTypeId);
        }
        if (this.props.incidentCategoryTypes) {
            this.props.incidentCategoryTypes.map(item => {
                let isSelected = selectedIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1;
                items.push({
                    id: item.incidentCategoryTypeId,
                    name: item.display,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        // get the old and new incidentCategoryIds
        let previousIncidentCategoryTypeIds = this.props.schedule.scheduleIncidentCategoryTypes.map(item => item.incidentCategoryTypeId);
        let currentIncidentCategoryTypeIds = items.map(item => item.id);

        // remove
        let newIncidentCategories = this.props.schedule.scheduleIncidentCategoryTypes.filter(item => currentIncidentCategoryTypeIds.indexOf(item.incidentCategoryTypeId) > -1);

        // add
        items.filter(item => previousIncidentCategoryTypeIds.indexOf(item.id) == -1).forEach(item => {
            let scheduleIncidentCategoryType: AusComplyDtos.ScheduleIncidentCategoryType = new AusComplyDtos.ScheduleIncidentCategoryType();
            scheduleIncidentCategoryType.incidentCategoryTypeId = item.id;
            newIncidentCategories.push(scheduleIncidentCategoryType);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleIncidentCategoryTypes = newIncidentCategories;
        this.setState({
        });
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {

        return (
            <CardContainer title={"Categories"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} /></CardContent>
            </CardContainer>
        );
    }
}