import { connect } from 'react-redux'
import SignOnOffSummary from '../components/signon/SignOnOffSummary'
import * as signOnActions from '../common/actions/signOn';

const mapStateToProps = state => {
    return {
        userSignOnSummary: state.user.details.userSignOnSummary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetSignOnRegisterFilter: () => {
            dispatch(signOnActions.signOnRegisterFilterRequest(true))
        },
        onBreak: (signOnRegisterId) => {
            dispatch(signOnActions.signOnBreakRequest(signOnRegisterId))
        }
    }
}

const SignOnOffSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnOffSummary)

export default SignOnOffSummaryContainer