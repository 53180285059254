import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const securityFirmContactServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmContacts();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forSecurityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmContactNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, securityFirmContact) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmContact();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.securityFirmContact = securityFirmContact;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, securityFirmContactId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmContact();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.securityFirmContactId = securityFirmContactId;
    query.includeReferenceData = true;
    const request = client.get(query);
    return from(request);
  }
};
