import update from "immutability-helper";
import {
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_REQUEST_SUCCESS,
    NOTIFICATIONS_REQUEST_FAILURE,
    NOTIFICATIONS_ACTION_REQUEST,
    NOTIFICATIONS_ACTION_REQUEST_SUCCESS,
    NOTIFICATIONS_ACTION_REQUEST_FAILURE,
    NOTIFICATIONS_ACTIONS_REQUEST,
    NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS,
    NOTIFICATIONS_ACTIONS_REQUEST_FAILURE
} from "../actions/notifications";

const initialState = {
    isLoading: false,
    isSaving: false,
    groupedNotifications: [],
    alerts: [],
    groupNotifications: [],
    error: null
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setGroupedNotifications(state, groupedNotifications, alerts, groupNotifications) {
    let newState = update(state, {
        groupedNotifications: { $set: groupedNotifications },
        alerts: { $set: alerts },
        groupNotifications: { $set: groupNotifications}
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_REQUEST:
            return loading(setGroupedNotifications(setError(state, null), [], [], []), true);
        case NOTIFICATIONS_REQUEST_SUCCESS:
            return loading(setError(setGroupedNotifications(state, action.groupedNotifications, action.alerts, action.groupNotifications), ""), false);
        case NOTIFICATIONS_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case NOTIFICATIONS_ACTION_REQUEST:
        case NOTIFICATIONS_ACTIONS_REQUEST:
            return saving(state, true);
        case NOTIFICATIONS_ACTION_REQUEST_SUCCESS:
        case NOTIFICATIONS_ACTIONS_REQUEST_SUCCESS:
            return saving(state, false);
        case NOTIFICATIONS_ACTION_REQUEST_FAILURE:
        case NOTIFICATIONS_ACTIONS_REQUEST_FAILURE:
            return saving(state, false);
    }
    return state;
}