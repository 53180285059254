import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import DefaultButton from '../../controls/DefaultButton';
import AdminSecurityFirmEditContainer from '../../../containers/AdminSecurityFirmEditContainer';
import AdminSecurityFirmSubscriptionContainer from '../../../containers/AdminSecurityFirmSubscriptionContainer';
import AdminSecurityFirmUtilitiesContainer from '../.././../containers/AdminSecurityFirmUtilitiesContainer';
import Tabs from '../../common/Tabs';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';

export interface ISecurityFirmProps {
    securityFirmId: number;
    history: any;
    location: any;
}

interface ISecurityFirmState {
    selectedTab: number;
}

export default class SecurityFirm extends Component<ISecurityFirmProps, ISecurityFirmState> {
    constructor(props: ISecurityFirmProps) {
        super(props)
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            selectedTab: 0
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

    }

    onCancel() {
        this.props.history.push("/admin/securityfirms");
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    render() {

        return (
            <>
                <LayoutForm
                    loading={false}
                    saving={false}
                    error={""}
                    fullWidth={true}
                    header={<PageTitle title={"Security Firm"}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}></Box>
                            <Box p={0}>
                                <DefaultButton
                                    onClick={() => this.onCancel()}
                                    text={"Back"} />
                            </Box>
                        </Box>
                    </PageTitle>}
                >
                    <form>
                        <div style={{ display: 'display-block', marginTop: '10px' }}>
                            <Tabs selected={this.state.selectedTab} labels={["Details", "Subscription", "Utilities"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
                        </div>
                        {this.state.selectedTab === 0 && (
                            <>
                                <AdminSecurityFirmEditContainer securityFirmId={this.props.securityFirmId} />
                            </>
                        )}
                        {this.state.selectedTab === 1 && (
                            <>
                                <AdminSecurityFirmSubscriptionContainer securityFirmId={this.props.securityFirmId} />
                            </>
                        )}
                        {this.state.selectedTab === 2 && (
                            <>
                              <AdminSecurityFirmUtilitiesContainer  securityFirmId={this.props.securityFirmId}/>
                            </>
                        )}

                    </form>
                </LayoutForm>
            </>
        );
    }
}