import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import ChecklistTemplates from '../components/checklists/checklistTemplates/ChecklistTemplates'
import * as checklistTemplatesActions from '../common/actions/checklistTemplates';
import {
    checklistTemplatesReset,
    copyChecklistTemplatesRequest
} from '../common/actions/checklistTemplate';
import {
    loadChecklistTemplateCategoriesRequest,
    loadStatesRequest,
    loadIndustryCategoriesRequest,
    loadVenuesRequest,
    loadSecurityFirmsRequest
} from '../common/actions/referenceData';

const isLoading = (state) => {
    if (state.checklistTemplates.isLoading) {
        return true;
    }
    if (state.referenceData.checklistTemplateCategoriesLoading) {
        return true;
    }
    if (state.referenceData.industryCategoriesLoading) {
        return true;
    }
    if (state.referenceData.statesLoading) {
        return true;
    }
    if (state.referenceData.venuesLoading) {
        return true;
    }
    if (state.referenceData.securityFirmsLoading) {
        return true;
    }
    return false;
}

const mapStateToProps = (state, props) => {
    return {
        isLoading: isLoading(state),
        isSaving: state.checklistTemplates.isSaving,
        isCloning: state.checklistTemplates.isCloning,
        clonedId: state.checklistTemplates.clonedId,
        isAdmin: true,
        checklistTemplates: state.checklistTemplates.checklistTemplates,
        filter: state.checklistTemplates.filter,
        paging: state.checklistTemplates.paging,
        checklistTemplateCategories: state.referenceData.checklistTemplateCategories,
        industryCategories: state.referenceData.industryCategories,
        states: state.referenceData.states,
        venues: state.referenceData.venues,
        securityFirms: state.referenceData.securityFirms,
        canAdd: permissionsLogic.hasPermissionForState(state, "ChecklistTemplateCreate"),
        canEdit: permissionsLogic.hasPermissionForState(state, "ChecklistTemplateEdit"),
        canEditGlobal: permissionsLogic.hasPermissionForState(state, "ChecklistTemplateEditGlobal"),
        canClone: permissionsLogic.hasPermissionForState(state, "ChecklistTemplateClone")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFilterReset: () => {
            dispatch(checklistTemplatesActions.checklistTemplatesFilterRequest(true))
        },
        onPage: (page) => {
            dispatch(checklistTemplatesActions.checklistTemplatesPage(page))
        },
        onLoad: () => {
            dispatch(checklistTemplatesActions.checklistTemplatesRequest())
        },
        onFilterUpdate: (filter) => {
            dispatch(checklistTemplatesActions.checklistTemplatesSetFilter(filter))
        },
        onChecklistTemplateReset: () => {
            dispatch(checklistTemplatesReset())
        },
        onLoadChecklistTemplateCategories: () => {
            dispatch(loadChecklistTemplateCategoriesRequest());
            dispatch(loadStatesRequest());
            dispatch(loadIndustryCategoriesRequest());
            dispatch(loadVenuesRequest());
            dispatch(loadSecurityFirmsRequest());
        },
        onClone: (checklistTemplateId, isAdmin) => {
            dispatch(copyChecklistTemplatesRequest(checklistTemplateId, isAdmin))
        },
        onArchive: (checklistTemplateIds, restore) => {
            dispatch(checklistTemplatesActions.checklistTemplatesArchiveRequest(checklistTemplateIds, restore))
        },
        onArchiveTemplate: (checklistTemplateId, isAdmin, restore) => {
            dispatch(checklistTemplatesActions.checklistTemplateArchiveRequest(checklistTemplateId, isAdmin, restore))
        },
    }
}

const AdminChecklistTemplatesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistTemplates))

export default AdminChecklistTemplatesContainer