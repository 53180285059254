import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const venueReportingPeriodScheduleServiceApi = {
  list: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodSchedule();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, venueReportingPeriodScheduleId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriodSchedule();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueReportingPeriodScheduleId = venueReportingPeriodScheduleId;
    const request = client.get(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodScheduleCreate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, venueReportingPeriodSchedule) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodScheduleUpsert();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueReportingPeriodSchedule = venueReportingPeriodSchedule;
    const request = client.post(query);
    return from(request);
  }
};
