import React from "react";

const MagnifyingIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 19.6 20.4"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={'none'} stroke={fill} strokeWidth={2} d="M8.6,16.2c4.2,0,7.6-3.4,7.6-7.6c0-4.2-3.4-7.6-7.6-7.6S1,4.4,1,8.6C1,12.8,4.4,16.2,8.6,16.2z" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="13.9" y1="14.7" x2="18.9" y2="19.7" />
            </g>
        </svg>
    );

export default MagnifyingIcon;
