import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import View from '../../common/View';
import PrimaryButton from '../../controls/PrimaryButton';
import TransparentButton from '../../controls/TransparentButton';


export interface IChecklistTemplateItemNotificationFilterProps {
    checklistTemplateItem: AusComplyDTOs.ChecklistTemplateItem;
    filter: string; // csv list of string values that trigger
    options: AusComplyDTOs.ChecklistTemplateItemOption[];
    onUpdate: Function;
}

class ChecklistTemplateItemNotificationFilter extends Component<IChecklistTemplateItemNotificationFilterProps, any> {
    constructor(props: IChecklistTemplateItemNotificationFilterProps) {
        super(props)
        this.state = {
            filterValues: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let filterValues: string[] = [];
        if (this.props.filter) {
            filterValues = this.props.filter.split(",");
        }
        this.setState({ filterValues });
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter !== prevProps.filter) {
            let filterValues: string[] = [];
            if (this.props.filter) {
                filterValues = this.props.filter.split(",");
            }
            this.setState({ filterValues });
        }
    }

    handleChange = (value, isSelected) => {
        let filterValues = [...this.state.filterValues];
        let self = this;
        if (isSelected) {
            filterValues.push(value);
        } else {
            filterValues = filterValues.filter(f => f !== value);
        }

        this.setState({filterValues}, () => {
            self.props.onUpdate(filterValues.join(","))
        });
    };

    checkboxOptions() {
        let option1 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option1.value = "Checked";
        option1.name = "Checked";
        option1.rank = 1;
        option1.temporaryId = 1;

        let option2 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option2.value = "UnChecked";
        option2.name = "UnChecked";
        option2.rank = 2;
        option2.temporaryId = 2;

        let options: AusComplyDTOs.ChecklistTemplateItemOption[] = [option1, option2];
        return options;
    }

    render() {
        if (!this.props.checklistTemplateItem) {
            return (<></>);
        }

        if (this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Checkbox &&
            this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Question &&
            this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Selection &&
            this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.MultipleChoice) {
            return (<></>);
        }

        let options = this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Checkbox ?
            this.checkboxOptions() : this.props.options;

        if (!options || options.length === 0) {
            return (<></>);
        }

        let optionButtons = options.map((item, index) => {
            let selected: boolean = false;
            if (this.state.filterValues.indexOf(item.value) > -1) {
                selected = true;
            }

            return (
                <View key={item.value} style={{ paddingRight: "5px", paddingBottom: "5px", minWidth: '140px' }}>
                    {selected && (
                        <PrimaryButton noWrap={true} text={item.name} onClick={() => this.handleChange(item.value, false)} />
                    )}
                    {!selected && (
                        <TransparentButton noWrap={true} text={item.name} onClick={() => this.handleChange(item.value, true)} />
                    )}
                </View>
            )
        });

        return (
            <ControlGroup text={"When the item has the value(s), leave empty for any value"}>
                <div
                    style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', flexWrap: 'wrap' }}
                >
                    {optionButtons}
                </div>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ChecklistTemplateItemNotificationFilter)