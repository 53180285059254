import { connect } from 'react-redux'
import SnackbarNotification from '../components/notifications/SnackbarNotifications'
import { notificationHide } from '../common/actions/notification';

const getNotificationMessage = (notification) => {
    if (notification){
        return notification.message
    }
    return "";
}

const getNotificationMessageType = (notification) => {
    if (notification){
        return notification.messageType || "";
    }
    return "";
}

const mapStateToProps = state => {
    return {
        message: getNotificationMessage(state.notification),
        messageType: getNotificationMessageType(state.notification),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClearNotification: () => {
            dispatch(notificationHide())
        }
    }
}

const NotificationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SnackbarNotification)

export default NotificationContainer