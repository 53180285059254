import { connect } from 'react-redux'
import IncidentCategoryTypeDocuments from '../components/incidents/IncidentCategoryTypeDocuments'
import { withRouter } from "react-router";
import * as incidentDocumentActions from '../common/actions/incidentDocument';

const mapStateToProps = (state, props) => {
    return {
        wrapInCard: props.wrapInCard,
        incidentId: state.editIncident.incident.incidentId,
        isBuilding: state.incidentDocuments.isBuilding,
        buildingIncidentCategoryTypeDocumentId: state.incidentDocuments.incidentCategoryTypeDocumentId,
        buildingIncidentId: state.incidentDocuments.incidentId,
        incidentCategoryTypeDocuments: state.editIncident.incident.incidentCategoryTypeDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onBuild: (incidentId, incidentCategoryTypeDocumentId) => {
            dispatch(incidentDocumentActions.incidentDocumentBuildRequest(incidentId, incidentCategoryTypeDocumentId))
        }
    }
}

const IncidentCategoryTypeDocumentsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentCategoryTypeDocuments))

export default IncidentCategoryTypeDocumentsContainer