import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import View from '../common/View';
import Box from '@material-ui/core/Box';


export interface IHomeStatisticsProps {
    theme: any;
    classes: any;
    isStatisticsLoading: boolean;
    statistics: AusComplyDtos.HomeStatistics;
    showGamblingIncidentRegister: boolean;
    canViewIncidents: boolean;
    canViewCommsLogs: boolean;
    onResetIncidentFilter: Function;
    onResetChecklistFilter: Function;
    history: any;
}

class HomeStatistics extends Component<IHomeStatisticsProps, any> {
    constructor(props) {
        super(props)
        this.onNavigate = this.onNavigate.bind(this);
        this.linkButton = this.linkButton.bind(this);
    }

    onNavigate(to: string) {
        if (to != "") {
            if (to === "Incidents") {
                this.props.onResetIncidentFilter();
            }
            if (to === "CheckLists") {
                this.props.onResetChecklistFilter();
            }
            this.props.history.push('/' + to);
        }
    }

    linkButton(count: number, text: string, pageToOpen: string) {
        if (!count) count = 0;
        const { theme, classes } = this.props;
        return (
            <Box flexGrow={1} p={0}>
                <div onClick={() => this.onNavigate(pageToOpen)} style={{ margin: '2px' }}>
                    <View className={classes.homeStatisticsButton}>
                        <p className={count > 0 ? classes.homeStatisticsLinkCount : classes.homeStatisticsLinkCountZero}>{count.toString()}</p>
                        <p className={count > 0 ? classes.homeStatisticsLinkLabel : classes.homeStatisticsLinkLabelZero}>{text}</p>
                    </View>
                </div>
            </Box>
        );
    }

    render() {
        if (!this.props.statistics) {
            return <View></View>;
        }

        const { theme, classes } = this.props;

        let users;
        if (this.props.statistics.canViewUsers) {
            users = this.linkButton(this.props.statistics.userCount, "Users online", "LoggedInUsers");
        }
        let notifications = this.linkButton(this.props.statistics.notificationCount, "Notifications", "Notifications");
        let incidents;
        if (this.props.canViewIncidents) {
            incidents = this.linkButton(this.props.statistics.incidentCount, "Incidents", "Incidents");
        }
        let rgos;
        if (this.props.canViewIncidents && this.props.showGamblingIncidentRegister) {
            rgos = this.linkButton(this.props.statistics.rgoCount, "Gambling", "GamblingIncidentRegister");
        }
        let commsLogs;
        if (this.props.canViewCommsLogs) {
            commsLogs = this.linkButton(this.props.statistics.commsLogCount, "Comms Logs", "CommsLogs");
        }
        let checklists;
        if (this.props.statistics.canViewChecklistTemplates) {
            checklists = this.linkButton(this.props.statistics.checklistCount, "Checklists", "CheckLists");
        }

        return (
            <CardContainer isLoading={this.props.isStatisticsLoading}>
                <CardContent>
                    <View style={{ padding: 0 }}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap" style={{ width: '100%' }} >
                            {notifications}
                            {incidents}
                            {rgos}
                            {commsLogs}
                            {checklists}
                            {users}
                        </Box>
                    </View>
                </CardContent>
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(HomeStatistics);