import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import {
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH,
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST,
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_SUCCESS,
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_FAILURE,
    INCIDENT_FLAG_TYPE_NOTIFICATION_RESET,
    INCIDENT_FLAG_TYPE_NOTIFICATION_SET,
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST,
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_SUCCESS,
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_FAILURE,
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST,
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS,
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_FAILURE
} from "../actions/incidentFlagTypeNotification";

const initialState = {
    incidentFlagTypeNotifications: {
        isLoading: false,
        filter: {},
        incidentFlagTypeNotifications: [],
        paging: { pageSize: 0, page: 1 }
    },
    incidentFlagTypeNotification: {
        incidentFlagTypeId: -1,
        isLoading: false,
        isSaving: false,
        incidentFlagTypeNotification: { incidentFlagTypeId: 0 }
    }
};

function loadingIncidentFlagTypeNotifications(state, isLoading) {
    let newState = update(state, {
        incidentFlagTypeNotifications: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setIncidentFlagTypeNotifications(state, incidentFlagTypeNotifications, filter, paging) {
    let newState = update(state, {
        incidentFlagTypeNotifications: {
            incidentFlagTypeNotifications: { $set: incidentFlagTypeNotifications },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingIncidentFlagTypeNotification(state, isLoading) {
    let newState = update(state, {
        incidentFlagTypeNotification: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setIncidentFlagTypeNotification(state, incidentFlagTypeNotification) {
    let newState = update(state, {
        incidentFlagTypeNotification: {
            incidentFlagTypeNotification: { $set: incidentFlagTypeNotification }
        }
    });
    return newState;
}

function savingIncidentFlagTypeNotification(state, isSaving) {
    let newState = update(state, {
        incidentFlagTypeNotification: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setIncidentFlagTypeId(state, incidentFlagTypeId) {
    let newState = update(state, {
        incidentFlagTypeNotification: {
            incidentFlagTypeId: { $set: incidentFlagTypeId }
        }
    });
    return newState;
}

export default function incidentFlagTypeNotification(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH:
            return loadingIncidentFlagTypeNotifications(setIncidentFlagTypeId(state, -1), true);
        case INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST:
            return setIncidentFlagTypeNotifications(loadingIncidentFlagTypeNotifications(setIncidentFlagTypeId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_SUCCESS:
            return setIncidentFlagTypeNotifications(loadingIncidentFlagTypeNotifications(state, false), action.incidentFlagTypeNotifications, action.filter, action.paging);
        case INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_FAILURE:
            return loadingIncidentFlagTypeNotifications(state, false);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_RESET:
            return setIncidentFlagTypeId(state, -1);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST:
            return setIncidentFlagTypeNotification(loadingIncidentFlagTypeNotification(setIncidentFlagTypeId(state, action.incidentFlagTypeId), true), {});
        case INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_SUCCESS:
            return setIncidentFlagTypeNotification(loadingIncidentFlagTypeNotification(state, false), action.incidentFlagTypeNotification);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_FAILURE:
            return loadingIncidentFlagTypeNotification(setIncidentFlagTypeId(state, -1), false);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST:
            return savingIncidentFlagTypeNotification(state, true);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS:
            return setIncidentFlagTypeNotification(savingIncidentFlagTypeNotification(setIncidentFlagTypeId(state, -1), false), action.incidentFlagTypeNotification);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_FAILURE:
            return savingIncidentFlagTypeNotification(state, false);
        case INCIDENT_FLAG_TYPE_NOTIFICATION_SET:
            return setIncidentFlagTypeNotification(state, action.incidentFlagTypeNotification);

    }

    return state;
}