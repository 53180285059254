import React, { Component } from 'react';
import './App.css';
import { configureStore } from './common/store/configureStore';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import Loading from './components/common/Loading';
import ThemeContainer from './containers/ThemeContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
//import React GA from 'react-ga';
import ReactGA from 'react-ga4';

const trackingId = "G-7EP5ES36SR"; // "UA-18505631-16"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  code: 'pwa'
});

const persistedStore = configureStore();

class App extends Component {
  render() {
    return (
        <Provider store={persistedStore.store}>
          <PersistGate loading={<Loading />} persistor={persistedStore.persistor}>
            <ThemeContainer />
          </PersistGate>
        </Provider>
    );
  }
}

export default App;
