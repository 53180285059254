import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from "./common";

export const subLocationServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSubLocations();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forGroupId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSubLocationNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forGroupId = forGroupId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, subLocation) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSubLocation();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.subLocation = subLocation;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, subLocationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSubLocation();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.subLocationId = subLocationId;
    const request = client.get(query);
    return from(request);
  },
  get2: request.get(AusComplyDtos.GetSubLocation, 'subLocationId')
};
