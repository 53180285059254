
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const venueReportingPeriodLogic = {

    getCommands(venueReportingPeriod: AusComplyDtos.VenueReportingPeriod) {
        var commands: KeyName[] = [];
        if (venueReportingPeriod.isCurrent) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "stop", name: "Stop Reporting Period" });
        }
        return commands;
    }
}