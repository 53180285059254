import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName, PrintSend } from '../../common/dto/common';
import FullPageDialog from '../layout/FullPageDialog';
import PrintAndSend from '../print/PrintAndSend';
import LayoutForm from '../layout/LayoutForm';

export interface IIncidentsPrintProps {
    theme: any;
    classes: any;
    filter?: AusComplyDtos.IncidentHistoryFilter;
    printOptions?: AusComplyDtos.PrintOptions,
    show: boolean;
    isLoading: boolean;
    onHide: Function;
    onLoadPrintOptions: Function;
    onPrintPdf: Function;
    onExport: Function;
    onEmail: Function;
    onErrorNotification: Function;
}

export interface IIncidentsPrintState {
}

class IncidentsPrint extends React.PureComponent<IIncidentsPrintProps, any> {

    constructor(props: IIncidentsPrintProps) {
        super(props);
        this.state = {
        };
        this.onDismissed = this.onDismissed.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.props.onLoadPrintOptions();
        }
        if (!this.props.isLoading && prevProps.isLoading && this.props.printOptions) {

        }
    }

    onDismissed() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    handleExport(option: string) {
        if (this.props.onExport) {
            this.props.onExport(this.props.filter);
        }
    }

    handlePrint(option: string, imageOption: string) {
        if (option === "historydetailedreport" || option === "historydetailedversionsreport") {
            if (this.state.filter && this.state.filter.exportIncidentDetailedLimit > 0) {
                if (this.state.filter.paging.total > this.state.filter.exportIncidentDetailedLimit) {
                    // send error message notification
                    let error = "The maximum number of incidents allowed on a detailed report is limited to " + this.state.filter.exportIncidentDetailedLimit.toString() + " records, please reduce your result and try again.";
                    this.props.onErrorNotification(error);
                    return;
                }
            }
        }
        this.props.onPrintPdf(option, this.props.filter, imageOption);
    }

    handleEmail(option: string, send: PrintSend, imageOption: string) {
        this.props.onEmail(this.props.filter, send, imageOption);
    }

    render() {
        let pdfOptions: KeyName[] = [];
        if (this.props.filter != null) {
            if (this.props.filter.canPrint) {
                pdfOptions.push({ key: "historyfilterreport", name: "Summary" });
            }
            if (this.props.filter.canPrintSummaryAndDetails) {
                pdfOptions.push({ key: "historydetailedreport", name: "Incident detail" });
            }
            if (this.props.filter.canPrintSummaryAndDetailedVersion) {
                pdfOptions.push({ key: "historydetailedversionsreport", name: "Incident detail (Version History)" });
            }
        }

        let imageOptions: KeyName[] = [];
        imageOptions.push({ key: "LOW", name: "Include low-resolution" });
        imageOptions.push({ key: "NONE", name: "Excluded" });

        return (
            <FullPageDialog open={this.props.show}
                notFullWidth={true}
                onDismissed={this.onDismissed} title={"Print"}>
                <LayoutForm>
                    <PrintAndSend
                        isLoading={this.props.isLoading}
                        printOptions={this.props.printOptions}
                        onExport={this.handleExport}
                        onPrint={this.handlePrint}
                        onCancel={this.onDismissed}
                        onEmail={this.handleEmail}
                        pdfOptions={pdfOptions}
                        imageOptions={imageOptions} />
                </LayoutForm>
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentsPrint);