import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import TickIcon from '../../resources/TickIcon';
import View from '../common/View';
import TableRow from '../common/TableRow';
import { userRoleLogic } from '../../common/logic/userRoleLogic';

export interface IUserRoleRowProps {
    onCommand: Function;
    canAdminEdit?: boolean;
    excludeViewEditCommands?: boolean;
    userRole: AusComplyDtos.UserRoleSummary;
}

class UserRoleRow extends React.Component<IUserRoleRowProps, any> {
    constructor(props: IUserRoleRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.userRole);
        }
    }

    onClick() {
        this.onCommand(userRoleLogic.getViewOrEditCommand(this.props.userRole, this.props.excludeViewEditCommands));
    }

    render() {
        let item = this.props.userRole;
        var commands = userRoleLogic.getCommands(this.props.userRole, this.props.excludeViewEditCommands, this.props.canAdminEdit);
        let isTemporary;
        if (item.isTemporary) {
            isTemporary = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }
        return (
            <TableRow   
                isDanger={this.props.userRole.userRoleObsolete || this.props.userRole.userObsolete}
                isInfo={this.props.userRole.isLoggedIn}
                commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.venue}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.securityFirm}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.givenName}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.surname}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.nickname}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    {item.canViewDetails && (
                        <p>
                            {item.mobileNo}
                        </p>
                    )}
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.userRoleType}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.accessLevel}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    <p>
                        {item.userRoleStatus}
                    </p>
                </td>
                <td onClick={() => this.onClick()}>
                    {isTemporary}
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRow);