import React, { Component } from 'react';
import * as AuscomplyDtos from "../../common/dto/AusComply.dtos";
import ControlGroup from '../controls/ControlGroup';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import RadioButtonControl from '../controls/RadioButtonControl';
import { RadioOption } from '../../common/dto/common';
import View from '../common/View';

export interface IIncidentsFilterIncidentTypesProps {
    incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter;
    onUpdate: Function;
}

interface IIncidentsFilterIncidentTypesState {
    selections: MulitSelectOption[];
    hasIdCheck: boolean;
    idCheckLabel: string;
}

export default class IncidentsFilterIncidentTypes extends Component<IIncidentsFilterIncidentTypesProps, IIncidentsFilterIncidentTypesState> {
    constructor(props: IIncidentsFilterIncidentTypesProps) {
        super(props)
        this.state = {
            selections: [],
            hasIdCheck: false,
            idCheckLabel: "ID Checks"
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectionsChanged = this.onSelectionsChanged.bind(this);
        this.setSelections = this.setSelections.bind(this);
        this.onIdChecksOnlyChanged = this.onIdChecksOnlyChanged.bind(this);
    }

    componentDidMount(): void {
        if (this.props.incidentHistoryFilter) {
            this.setSelections();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.incidentHistoryFilter &&
            (this.hasChangedHash(this.props.incidentHistoryFilter) != this.hasChangedHash(prevProps.incidentHistoryFilter))) {
            this.setSelections();
        }
    }

    hasChangedHash(incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter) {
        let hash = "";
        if (incidentHistoryFilter && incidentHistoryFilter.incidentTypes) {
            incidentHistoryFilter.incidentTypes.forEach((item, index) => {
                hash += "#it-" + item.incidentTypeId.toString() + item.selected ? "-selected" : "";
            });
        }
        return hash;
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        incidentHistoryFilter[fieldName] = value;
        this.props.onUpdate(incidentHistoryFilter);
    }

    setSelections() {
        let selections: MulitSelectOption[] = [];
        let hasIdCheck: boolean = false;
        let idCheckLabel: string = "ID Check";
        if (this.props.incidentHistoryFilter.incidentTypes) {
            this.props.incidentHistoryFilter.incidentTypes.forEach((item, index) => {

                let selection = new MulitSelectOption();
                selection.id = item.incidentTypeId;
                selection.name = item.name;
                selection.selected = item.selected;
                selection.isCommon = true;
                selections.push(selection);

                if (item.isIdCheck) {
                    hasIdCheck = true;
                    idCheckLabel = item.name;
                }
            });
        }
        this.setState({
            selections,
            hasIdCheck,
            idCheckLabel
        });
    }

    onSelectionsChanged(value) {
        let selections = [...this.state.selections];
        selections.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            selections
        });

        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        if (incidentHistoryFilter.incidentTypes) {
            incidentHistoryFilter.incidentTypes.forEach((item, index) => {
                item.selected = this.state.selections.filter(s => s.id === item.incidentTypeId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(incidentHistoryFilter)
    }

    onIdChecksOnlyChanged(value) {
        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        incidentHistoryFilter.idChecksOnly = value == "IDCHECK";
        this.props.onUpdate(incidentHistoryFilter)
    }

    render() {

        let selections;
        if (this.state.selections && !(this.props.incidentHistoryFilter.idChecksOnly && this.state.hasIdCheck && this.props.incidentHistoryFilter.canViewIDChecks)) {
            selections = (
                <MultiSelectControl
                    items={this.state.selections}
                    minimumCharactersRequired={3}
                    canShowAll={true}
                    hideTitles={true}
                    searchText={"Type at least three letters..."}
                    onSelectItemsChanged={this.onSelectionsChanged} />
            );
        }

        let idCheck;
        let options: RadioOption[] = [];
        if (this.state.hasIdCheck && this.props.incidentHistoryFilter.canViewIDChecks) {
            options.push({ id: "SELECTED", name: "Selected incident types" });
            options.push({ id: "IDCHECK", name: "Only " + this.state.idCheckLabel + "s" });
            idCheck = <View style={{marginBottom: "20px"}}>
                <RadioButtonControl
                    selected={this.props.incidentHistoryFilter.idChecksOnly ? "IDCHECK" : "SELECTED"}
                    options={options}
                    name={"idChecksOnly"}
                    onChange={(value) => this.onIdChecksOnlyChanged(value.value)}
                />
            </View>
        };

        return (
            <ControlGroup text={"Incident Types"} labelAbove={true}>
                {idCheck}
                {selections}
            </ControlGroup>
        );
    }
}