import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';
import MoreCommands from '../common/MoreCommands';
import TickIcon from '../../resources/TickIcon';
import View from '../common/View';

export interface IUserRoleRowProps {
    onEdit?: Function;
    onRemove?: Function;
    onEnable?: Function;
    canAdminEdit?: boolean;
    onAdminEdit?: Function;
    hasGroups?: boolean;
    classes: any;
    theme: any;
    userRoleSummary: AusComplyDtos.UserRoleSummary;
}

class UserRoleRow extends React.Component<IUserRoleRowProps, any> {
    constructor(props: IUserRoleRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit && this.props.userRoleSummary.editAllowed && !this.props.userRoleSummary.userRoleObsolete) {
                    this.props.onEdit(this.props.userRoleSummary.userRoleId);
                }
                break;
            case "remove":
                if (this.props.onRemove && this.props.userRoleSummary.deleteAllowed && !this.props.userRoleSummary.userRoleObsolete) {
                    this.props.onRemove(this.props.userRoleSummary.userRoleId);
                }
                break;
            case "enable":
                if (this.props.onEnable && this.props.userRoleSummary.userRoleObsolete) {
                    this.props.onEnable(this.props.userRoleSummary.userRoleId);
                }
                break;
        }
    }

    render() {
        const { classes } = this.props;
        var commands: KeyName[] = [];
        let item = this.props.userRoleSummary;
        let className = classes.rowStatusPending;
        if (this.props.userRoleSummary.isLoggedIn) {
            className = classes.rowStatusInfo;
        }
        if (this.props.userRoleSummary.userRoleObsolete && this.props.onEnable) {
            className = classes.rowStatusDanger;
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.userRoleSummary.editAllowed && !this.props.userRoleSummary.userRoleObsolete) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.userRoleSummary.deleteAllowed) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        let isTemporary;
        if (item.isTemporary) {
            isTemporary = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <tr className={classes.tableTr} key={"user-role-row-" + item.userRoleId.toString()} >
                <td className={className} onClick={() => this.onCommand("edit")}></td>
                {this.props.hasGroups && (
                    <td onClick={() => this.onCommand("edit")}>
                        <p>
                            {item.group}
                        </p>
                    </td>
                )}
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.venue}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.securityFirm}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.userRoleType}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.accessLevel}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.userRoleStatus}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    {isTemporary}
                </td>
                <td>
                    {(commands.length > 0) && (
                        <MoreCommands
                            commands={commands}
                            onCommand={this.onCommand} />
                    )}
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRow);