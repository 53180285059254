import React, { Component } from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import LayoutForm from '../layout/LayoutForm';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../controls/ControlGroup';
import Grid from '@material-ui/core/Grid';
import MultiSelectControl from '../controls/MutiSelectControl';
import TextControlGroup from '../controls/TextControlGroup';
import { MulitSelectOption } from '../../common/dto/common';
import Information from '../common/Information';
import SelectControlGroup from '../controls/SelectControlGroup';
import SingleSelectListControl from '../controls/SingleSelectListControl';
import { SingleSelectList } from '../../common/dto/common';
import NotificationTriggerUsersSummaryContainer from '../../containers/NotificationTriggerUsersSummaryContainer';
import TransparentButton from '../controls/TransparentButton';


export interface INotificationTriggerProps {
    title?: string;
    notificationTrigger: AusComplyDTOs.NotificationTrigger;
    showEscalation?: boolean;
    hideSummary?: boolean;
    noControlGroup?: boolean;
    filter?: any;
    onUpdate: Function;
    lockNotify?: boolean;
}

export default class NotificationTrigger extends Component<INotificationTriggerProps, any> {
    constructor(props: INotificationTriggerProps) {
        super(props)
        this.state = {
            roles: [],
            fallbackRoles: [],
            escalateRoles: [],
            escalateFallbackRoles: [],
            previewNotificationTriggerId: 0,
            modified: false
        }
        this.onChange = this.onChange.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onSelectUserRoleChanged = this.onSelectUserRoleChanged.bind(this);
        this.onSelectFallbackUserRoleChanged = this.onSelectFallbackUserRoleChanged.bind(this);
        this.onSelectEscalateUserRoleChanged = this.onSelectEscalateUserRoleChanged.bind(this);
        this.onSelectEscalateFallbackUserRoleChanged = this.onSelectEscalateFallbackUserRoleChanged.bind(this);
    }

    componentDidMount() {
        this.loadSelections(this.props.notificationTrigger);
    }

    componentDidUpdate(prevProps: Readonly<INotificationTriggerProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.lockNotify && this.props.notificationTrigger && !this.props.notificationTrigger.notify) {
            this.onChange("notify", true);
        }
    }

    loadSelections(notificationTrigger: AusComplyDTOs.NotificationTrigger) {
        let roles: MulitSelectOption[] = [];
        let fallbackRoles: MulitSelectOption[] = [];
        let escalateRoles: MulitSelectOption[] = [];
        let escalateFallbackRoles: MulitSelectOption[] = [];
        if (notificationTrigger && notificationTrigger.userRoleTypes) {
            notificationTrigger.userRoleTypes.forEach((urt, index) => {
                if (urt.security && !notificationTrigger.includeAssociatedUsers && (notificationTrigger.isGroup || notificationTrigger.isVenue)) {
                    // dont include it
                } else if (urt.venue && !notificationTrigger.includeAssociatedUsers && !urt.security && notificationTrigger.isSecurity) {
                    // dont include it
                } else if (urt.group && !notificationTrigger.includeAssociatedUsers && !urt.security && notificationTrigger.isSecurity) {
                    // dont include it
                } else {
                    let isSelected = false;
                    let info = "Access level " + urt.accessLevel;
                    if (urt.venueId && urt.venueId > 0) {
                        info = "Venue Specific, " + info;
                    } else if (urt.securityFirmId && urt.securityFirmId > 0) {
                        info = "Security Specific, " + info;
                    }
                    isSelected = notificationTrigger.selectedUserRoleTypes.indexOf(urt.userRoleTypeId) > -1;
                    roles.push({
                        id: urt.userRoleTypeId,
                        name: urt.nameAlias != '' ? urt.nameAlias : urt.name,
                        isCommon: false,
                        selected: isSelected,
                        info
                    });
                }
            });

            notificationTrigger.userRoleTypes.forEach((urt, index) => {
                let includeThisRole: boolean = false;
                if (urt.security && notificationTrigger.isVenue && notificationTrigger.includeAssociatedUsers) {
                    // dont include it
                    includeThisRole = true;
                } else if (urt.security && notificationTrigger.isVenue) {
                    // dont include it
                } else if (urt.venue && notificationTrigger.isSecurity) {
                    // dont include it
                } else if (urt.group && notificationTrigger.isSecurity) {
                    // dont include it
                } else {
                    includeThisRole = true;
                }
                if (includeThisRole) {
                    let isSelected = false;
                    let info = "Access level " + urt.accessLevel;
                    if (urt.venueId && urt.venueId > 0) {
                        info = "Venue Specific, " + info;
                    } else if (urt.securityFirmId && urt.securityFirmId > 0) {
                        info = "Security Specific, " + info;
                    }
                    isSelected = notificationTrigger.selectedFallbackUserRoleTypes.indexOf(urt.userRoleTypeId) > -1;
                    if (isSelected || (urt.accessLevel > 3 && !urt.obsolete)) {
                        fallbackRoles.push({
                            id: urt.userRoleTypeId,
                            name: urt.nameAlias != '' ? urt.nameAlias : urt.name,
                            isCommon: false,
                            selected: isSelected,
                            info
                        });
                    }
                }
            });
        }

        if (notificationTrigger && notificationTrigger.escalateUserRoleTypes) {
            notificationTrigger.escalateUserRoleTypes.forEach((urt, index) => {
                if (urt.security && !notificationTrigger.escalateIncludeAssociatedUsers && (notificationTrigger.isGroup || notificationTrigger.isVenue)) {
                    // dont include it
                } else if (urt.venue && !notificationTrigger.escalateIncludeAssociatedUsers && !urt.security && notificationTrigger.isSecurity) {
                    // dont include it
                } else if (urt.group && !notificationTrigger.escalateIncludeAssociatedUsers && !urt.security && notificationTrigger.isSecurity) {
                    // dont include it
                } else {
                    let isSelected = false;
                    let info = "Access level " + urt.accessLevel;
                    if (urt.venueId && urt.venueId > 0) {
                        info = "Venue Specific, " + info;
                    } else if (urt.securityFirmId && urt.securityFirmId > 0) {
                        info = "Security Specific, " + info;
                    }
                    isSelected = notificationTrigger.selectedEscalateUserRoleTypes.indexOf(urt.userRoleTypeId) > -1;
                    escalateRoles.push({
                        id: urt.userRoleTypeId,
                        name: urt.nameAlias != '' ? urt.nameAlias : urt.name,
                        isCommon: false,
                        selected: isSelected,
                        info
                    });
                }
            });

            notificationTrigger.escalateUserRoleTypes.forEach((urt, index) => {
                let includeThisRole: boolean = false;
                if (urt.security && notificationTrigger.isVenue && notificationTrigger.escalateIncludeAssociatedUsers) {
                    // dont include it
                    includeThisRole = true;
                } else if (urt.security && notificationTrigger.isVenue) {
                    // dont include it
                } else if (urt.venue && notificationTrigger.isSecurity) {
                    // dont include it
                } else if (urt.group && notificationTrigger.isSecurity) {
                    // dont include it
                } else {
                    includeThisRole = true;
                }
                if (includeThisRole) {
                    let isSelected = false;
                    let info = "Access level " + urt.accessLevel;
                    if (urt.venueId && urt.venueId > 0) {
                        info = "Venue Specific, " + info;
                    } else if (urt.securityFirmId && urt.securityFirmId > 0) {
                        info = "Security Specific, " + info;
                    }
                    isSelected = notificationTrigger.selectedEscalateFallbackUserRoleTypes.indexOf(urt.userRoleTypeId) > -1;
                    if (isSelected || (urt.accessLevel > 3 && !urt.obsolete)) {
                        escalateFallbackRoles.push({
                            id: urt.userRoleTypeId,
                            name: urt.nameAlias != '' ? urt.nameAlias : urt.name,
                            isCommon: false,
                            selected: isSelected,
                            info
                        });
                    }
                }
            });
        }
        //fallbackRoles = fallbackRoles.sort((a, b) => {
        //    return ('' + a.name).localeCompare(b.name);
        //});
        this.setState({
            roles,
            fallbackRoles,
            escalateRoles,
            escalateFallbackRoles
        }, () => { });
    }

    onChange(fieldname, value) {
        let notificationTrigger = { ...this.props.notificationTrigger };
        notificationTrigger[fieldname] = value;
        this.props.onUpdate(notificationTrigger);
        if (fieldname == "includeAssociatedUsers" || fieldname == "escalateIncludeAssociatedUsers") {
            if (value && fieldname == "includeAssociatedUsers") {
                notificationTrigger["onlySignedOn"] = true;
            }
            if (value && fieldname == "escalateIncludeAssociatedUsers") {
                notificationTrigger["escalateOnlySignedOn"] = true;
            }
            this.loadSelections(notificationTrigger);
        }
        this.setState({ modified: true });
    }

    onSelectUserRoleChanged(items) {
        let notificationTrigger = { ...this.props.notificationTrigger };
        notificationTrigger.selectedUserRoleTypes = items.map(item => item.id);
        this.props.onUpdate(notificationTrigger);
    }

    onSelectFallbackUserRoleChanged(items) {
        let notificationTrigger = { ...this.props.notificationTrigger };
        notificationTrigger.selectedFallbackUserRoleTypes = items.map(item => item.id);
        this.props.onUpdate(notificationTrigger);
    }

    onSelectEscalateUserRoleChanged(items) {
        let notificationTrigger = { ...this.props.notificationTrigger };
        notificationTrigger.selectedEscalateUserRoleTypes = items.map(item => item.id);
        this.props.onUpdate(notificationTrigger);
    }

    onSelectEscalateFallbackUserRoleChanged(items) {
        let notificationTrigger = { ...this.props.notificationTrigger };
        notificationTrigger.selectedEscalateFallbackUserRoleTypes = items.map(item => item.id);
        this.props.onUpdate(notificationTrigger);
    }

    render() {
        let self = this;
        let item = this.props.notificationTrigger;

        let preview;
        let previewButton;
        if (item && item.notificationTriggerId && item.notificationTriggerId > 0) {
            preview = <NotificationTriggerUsersSummaryContainer notificationTriggerId={this.state.previewNotificationTriggerId} onClose={() => self.setState({ previewNotificationTriggerId: 0 })} />;
            previewButton = <ControlGroup
                labelAbove={true}
                text={" "}>
                <TransparentButton text={"Preview users"} disabled={self.state.modified} onClick={() => self.setState({ previewNotificationTriggerId: item.notificationTriggerId })} />
            </ControlGroup>;
        }

        let content = (<>
            {!this.props.lockNotify && <>
                <CheckboxControlGroup
                    text={"Create Notification"}
                    label={" "}
                    labelAbove={true}
                    defaultValue={item.notify}
                    onChanged={(value) => this.onChange("notify", value)} />
            </>}
            {item.notify &&
                <>
                    {this.props.filter && (<>
                        {this.props.filter}
                    </>)}
                    <CheckboxControlGroup
                        label={" "}
                        labelAbove={true}
                        text={"Attach PDF Report"}
                        defaultValue={item.attachReport}
                        onChanged={(value) => this.onChange("attachReport", value)} />
                    <CheckboxControlGroup
                        text={"Include associated security firm users"}
                        label={" "}
                        labelAbove={true}
                        defaultValue={item.includeAssociatedUsers}
                        onChanged={(value) => this.onChange("includeAssociatedUsers", value)} />
                    <CheckboxControlGroup
                        text={"Only users currently logged in"}
                        label={" "}
                        readonly={item.includeAssociatedUsers}
                        labelAbove={true}
                        defaultValue={item.onlySignedOn}
                        onChanged={(value) => this.onChange("onlySignedOn", value)} />
                    <ControlGroup
                        labelAbove={true}
                        text={" "}>
                        <CardContainer
                            title={"Notified Roles"}
                            style={{ paddingTop: 0 }}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <MultiSelectControl
                                            label={""}
                                            items={this.state.roles}
                                            searchText="Type at least three letters..."
                                            minimumCharactersRequired={3}
                                            onSelectItemsChanged={this.onSelectUserRoleChanged}
                                            canShowAll={true} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardContainer>
                    </ControlGroup>
                    {item.onlySignedOn && (
                        <ControlGroup
                            labelAbove={true}
                            text={" "}>
                            <CardContainer
                                title={"Notify roles when no user is logged in"}
                                style={{ paddingTop: 0 }}>
                                <CardContent style={{ paddingTop: 0 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <MultiSelectControl
                                                label={""}
                                                items={this.state.fallbackRoles}
                                                searchText="Type at least three letters..."
                                                minimumCharactersRequired={3}
                                                onSelectItemsChanged={this.onSelectFallbackUserRoleChanged}
                                                canShowAll={true} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardContainer>
                        </ControlGroup>
                    )}
                    {this.props.showEscalation && (
                        <>
                            <CheckboxControlGroup
                                text={"Escalate"}
                                label={" "}
                                labelAbove={true}
                                defaultValue={item.escalate}
                                onChanged={(value) => this.onChange("escalate", value)} />
                            {item.escalate && (<>
                                <TextControlGroup text={'Escalate after minutes'}
                                    numericOnly={true}
                                    defaultValue={(item.escalateAfterMinutes || "0").toString()}
                                    onChange={(value) => this.onChange("escalateAfterMinutes", Number(value))} />
                                <CheckboxControlGroup
                                    text={"Include associated security firm users"}
                                    label={" "}
                                    defaultValue={item.escalateIncludeAssociatedUsers}
                                    onChanged={(value) => this.onChange("escalateIncludeAssociatedUsers", value)} />
                                <CheckboxControlGroup
                                    text={"Only users currently logged in"}
                                    label={" "}
                                    readonly={item.escalateIncludeAssociatedUsers}
                                    defaultValue={item.escalateOnlySignedOn}
                                    onChanged={(value) => this.onChange("escalateOnlySignedOn", value)} />
                                <ControlGroup
                                    labelAbove={true}
                                    text={" "}>
                                    <CardContainer
                                        title={"Escalation Notified Roles"}
                                        style={{ paddingTop: 0 }}>
                                        <CardContent style={{ paddingTop: 0 }}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <MultiSelectControl
                                                        label={""}
                                                        items={this.state.escalateRoles}
                                                        searchText="Type at least three letters..."
                                                        minimumCharactersRequired={3}
                                                        onSelectItemsChanged={this.onSelectEscalateUserRoleChanged}
                                                        canShowAll={true} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardContainer>
                                </ControlGroup>
                                {item.escalateOnlySignedOn && (
                                    <>
                                        <ControlGroup
                                            labelAbove={true}
                                            text={" "}>
                                            <CardContainer
                                                title={"Notify roles when no user is logged in"}
                                                style={{ paddingTop: 0 }}>
                                                <CardContent style={{ paddingTop: 0 }}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <MultiSelectControl
                                                                label={""}
                                                                items={this.state.escalateFallbackRoles}
                                                                searchText="Type at least three letters..."
                                                                minimumCharactersRequired={3}
                                                                onSelectItemsChanged={this.onSelectEscalateFallbackUserRoleChanged}
                                                                canShowAll={true} />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </CardContainer>
                                        </ControlGroup>
                                    </>
                                )}
                            </>)}
                        </>
                    )}
                    {previewButton}
                </>}
            {!this.props.hideSummary && (
                <ControlGroup text={" "} labelAbove={true}>
                    <Information message={item.summary} />
                </ControlGroup>
            )}
        </>);


        if (this.props.noControlGroup) {
            return (
                <>
                    <CardContainer
                        title={this.props.title || "Notification"}>
                        <CardContent>
                            {content}
                        </CardContent>
                        {preview}
                    </CardContainer>
                </>
            );
        }

        return (
            <>
                <CardContainer
                    title={this.props.title || "Notification"}>
                    <CardContent>
                        <LayoutForm>
                            {content}
                        </LayoutForm>
                    </CardContent>
                    {preview}
                </CardContainer>
            </>
        );
    }
}