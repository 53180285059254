import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';

export interface ICellProps {
    venueLocation: AusComplyDtos.VenueLocation;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ICellState {
}

export default class Cell extends Component<ICellProps, ICellState> {
    constructor(props: ICellProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let venueLocation = { ...this.props.venueLocation };
        venueLocation[fieldName] = value;
        this.props.onUpdate(venueLocation)
    }

    render() {

        if (!this.props.venueLocation) {
            return (<></>);
        }

        let rank = "0";
        if (this.props.venueLocation.rank) {
            rank = this.props.venueLocation.rank.toString();
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.props.venueLocation.name}
                        onBlur={(value) => this.onValueChanged("name", value)} />

                    <TextControlGroup
                        text={"Code"}
                        defaultValue={this.props.venueLocation.code}
                        onBlur={(value) => this.onValueChanged("code", value)} />

                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}