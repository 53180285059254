import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../../controls/PrimaryButton';
import DefaultButton from '../../../controls/DefaultButton';
import LayoutForm from '../../../layout/LayoutForm';
import SelectControlGroup from '../../../controls/SelectControlGroup';
import TextControlGroup from '../../../controls/TextControlGroup';
import CheckboxControlGroup from '../../../controls/CheckboxControlGroup';
import { MulitSelectOption, RadioOption } from '../../../../common/dto/common';
import MultiSelectControl from '../../../controls/MutiSelectControl';
import CardContainer from '../../../common/CardContainer';
import { CardContent } from '@material-ui/core';
import RadioControlGroup from '../../../controls/RadioControlGroup';

export interface IEditRoleTypeProps {
    roleTypeId?: number,
    isNewRoleType: boolean,
    isLoading: boolean;
    roleType: AusComplyDtos.UserRoleType;
    availableUserPermissions: AusComplyDtos.Permission[];
    availableIndustryCategories: AusComplyDtos.IndustryCategory[];
    availableStates: AusComplyDtos.State[];
    isSaving: boolean;
    error: string;
    onSuccess: Function;
    onLoad: Function;
    onChange: Function;
    onSave: Function;
    onClose: Function,
    theme: any;
    classes: any;
}

export default class EditRoleType extends Component<IEditRoleTypeProps, any> {
    constructor(props: IEditRoleTypeProps) {
        super(props)
        this.state = {
            error: {},
            availableUserPermissions: [],
            availableIndustryCategories: []
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.validate = this.validate.bind(this);
        this.onPermissionsChanged = this.onPermissionsChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.onRoleForChanged = this.onRoleForChanged.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.roleTypeId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {

            let availableIndustryCategories: MulitSelectOption[] = [];
            if (this.props.availableIndustryCategories) {
                this.props.availableIndustryCategories.forEach((item, index) => {
                    let category = new MulitSelectOption();
                    category.id = item.industryCategoryId;
                    category.name = item.name;
                    category.selected = this.props.roleType.userRoleTypeIndustryCategories && this.props.roleType.userRoleTypeIndustryCategories.some(ic => ic.industryCategoryId == item.industryCategoryId);
                    availableIndustryCategories.push(category);
                });
            }

            let availableUserPermissions: MulitSelectOption[] = [];
            if (this.props.availableUserPermissions) {
                this.props.availableUserPermissions.forEach((item, index) => {
                    let permission = new MulitSelectOption();
                    permission.id = index;
                    permission.code = item.permissionId;
                    permission.name = item.name;
                    permission.selected = this.props.roleType.userRoleTypePermissions && this.props.roleType.userRoleTypePermissions.findIndex(utp => utp == item.permissionId) !== -1;
                    permission.info = "Access level " + item.accessLevelRange;
                    availableUserPermissions.push(permission);
                });
            }

            this.setState({
                error: {},
                availableUserPermissions,
                availableIndustryCategories
            });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error && this.props.onSuccess) {
            this.setState({ error: {} });
            this.props.onSuccess();
        }
    }

    onSubmit() {
        if (this.validate()) {
            this.props.onSave(this.props.roleType);
        }
    }

    onClose() {
        this.props.onClose();
    }

    onValueChanged(fieldName: string, value: any) {
        let roleType = { ...this.props.roleType };
        roleType[fieldName] = value;
        this.props.onChange(roleType);
    }

    onValueChangedMulti(fields: { fieldName: string, value: any }[]) {
        let roleType = { ...this.props.roleType };
        fields.forEach(field => {
            roleType[field.fieldName] = field.value;
        })
        this.props.onChange(roleType);
    }

    onPermissionsChanged(value) {
        let roleType = { ...this.props.roleType };
        roleType['userRoleTypePermissions'] = value.map(v => v.code);
        this.props.onChange(roleType);
    }

    onIndustryCategoriesChanged(value) {
        let roleType = { ...this.props.roleType };
        roleType['UserRoleTypeIndustryCategories'] = value.map(v => {
            return { industryCategoryId: v.id };
        });
        this.props.onChange(roleType);
    }

    onRoleForChanged(value) {
        if (value && value.value) {
            const fieldValue = value.value;

            if (fieldValue == 'VENUE') {
                this.onValueChangedMulti([
                    { fieldName: 'venue', value: true },
                    { fieldName: 'security', value: false }
                ]);
            } else if (fieldValue == 'SECURITY') {
                this.onValueChangedMulti([
                    { fieldName: 'venue', value: false },
                    { fieldName: 'security', value: true }
                ]);
            } else if (fieldValue == 'BOTH') {
                this.onValueChangedMulti([
                    { fieldName: 'venue', value: true },
                    { fieldName: 'security', value: true }
                ]);
            }
        }
    }

    validate() {
        let error = {};
        let isValid = true;
        this.setState({ error: error });
        return isValid;
    }

    render() {
        const permissions = this.props.roleType && (
            <CardContainer
                title={"Permissions"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <div style={{ marginTop: '-4px' }}>
                        <MultiSelectControl
                            items={this.state.availableUserPermissions}
                            isReadonly={this.props.roleType.defaultRole || this.props.roleType.obsolete}
                            minimumCharactersRequired={3}
                            canShowAll={true}
                            searchText={"Type at least three letters..."}
                            onSelectItemsChanged={this.onPermissionsChanged} />
                    </div>
                </CardContent>
            </CardContainer>
        );

        const industryCategories = this.props.roleType && (
            <>
                <CardContainer
                    title={"Industry Categories"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <div style={{ marginTop: '-4px' }}>
                            <MultiSelectControl
                                isReadonly={this.props.roleType.obsolete}
                                items={this.state.availableIndustryCategories}
                                minimumCharactersRequired={3}
                                canShowAll={true}
                                searchText={"Type at least three letters..."}
                                onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                        </div>
                    </CardContent>
                </CardContainer>
                <div style={{ marginTop: '8px', marginBottom: '8px' }}>
                    <SelectControlGroup
                        text="State"
                        readonly={this.props.roleType.obsolete}
                        onChange={(value) => this.onValueChanged("stateId", value)}
                        defaultValue={this.props.roleType.stateId}>
                        <option value={0} />
                        {this.props.availableStates && this.props.availableStates.map((item, index) => (
                            <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                        ))}
                    </SelectControlGroup>
                </div>
            </>
        );

        const radioOptions: RadioOption[] = [
            { id: "VENUE", name: "Venue" },
            { id: "SECURITY", name: "Security" }//,
            //{ id: "BOTH", name: "Both Venue/Security" }
        ];

        let selectedRoleRadio = '';

        if (this.props.roleType) {
            selectedRoleRadio = this.props.roleType.venue && this.props.roleType.security ? 'BOTH'
                : this.props.roleType.security ? 'SECURITY'
                    : this.props.roleType.venue ? 'VENUE' : '';
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    {this.props.roleType && (
                        <>
                            <TextControlGroup
                                text={"Name"}
                                defaultValue={this.props.roleType.name}
                                readonly={this.props.roleType.defaultRole || this.props.roleType.obsolete}
                                onChange={(value) => this.onValueChanged("name", value)} />
                            <SelectControlGroup
                                text={"Access Level"}
                                error={this.state.error["accessLevel"]}
                                readonly={true || this.props.roleType.defaultRole || this.props.roleType.obsolete}
                                onChange={(value) => this.onValueChanged("accessLevel", parseInt(value))}
                                defaultValue={this.props.roleType.accessLevel}>
                                <option value={'0'}>{"Level 0 (notifications)"}</option>
                                <option value={'1'}>{"Level 1 (staff)"}</option>
                                <option value={'2'}>{"Level 2 (manager)"}</option>
                                <option value={'3'}>{"Level 3 (head manager)"}</option>
                                <option value={'4'}>{"Level 4 (owner)"}</option>
                                <option value={'5'}>{"Level 5 (group)"}</option>
                            </SelectControlGroup>
                            <CheckboxControlGroup
                                text={"Allow New Assignments"}
                                defaultValue={this.props.roleType.canBeAssigned}
                                readonly={this.props.roleType.obsolete}
                                onChanged={(value) => this.onValueChanged("canBeAssigned", value)} />

                            <CardContainer
                                style={{ marginTop: '4px' }}
                                title={"Role Restrictions"}>
                                <CardContent style={{ paddingTop: 0 }}>
                                    <div style={{ marginTop: '4px' }}>
                                        <RadioControlGroup
                                            selected={selectedRoleRadio}
                                            text={"Venue or Security role"}
                                            name="roleFor"
                                            readonly={this.props.roleType.defaultRole || this.props.roleType.obsolete || this.props.roleType.userRoleTypeId > 0}
                                            options={radioOptions}
                                            onChanged={this.onRoleForChanged} />
                                        {this.props.roleType.venue && !this.props.roleType.security && industryCategories}
                                        {permissions}
                                    </div>
                                </CardContent>
                            </CardContainer>
                            <Grid container spacing={2}>
                                {
                                    this.props.roleType.obsolete ? <PrimaryButton text={"Close"} onClick={this.onClose}></PrimaryButton>
                                        : <>
                                            <Grid item xs={6}>
                                                <PrimaryButton text={"Save"} onClick={this.onSubmit}></PrimaryButton>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DefaultButton text={"Cancel"} onClick={() => this.props.onSuccess()}></DefaultButton>
                                            </Grid>
                                        </>
                                }
                            </Grid>
                        </>
                    )}
                </form>
            </LayoutForm>
        );
    }
}