import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import ReportingPeriod from './ReportingPeriod';

export interface IVenueReportingPeriodProps {
    venueReportingPeriod: AusComplyDtos.VenueReportingPeriod;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface IVenueReportingPeriodState {
}

export default class VenueReportingPeriod extends Component<IVenueReportingPeriodProps, IVenueReportingPeriodState> {
    constructor(props: IVenueReportingPeriodProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        //this.onValueChanged = this.onValueChanged.bind(this);
        //this.onSubValueChanged = this.onSubValueChanged.bind(this);
    }


    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }
/*
    onValueChanged(fieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriod };
        venueReportingPeriod[fieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }

    onSubValueChanged(fieldName: string, subFieldName: string, value: any) {
        let venueReportingPeriod = { ...this.props.venueReportingPeriod };
        venueReportingPeriod[fieldName][subFieldName] = value;
        this.props.onUpdate(venueReportingPeriod)
    }*/

    render() {

        if (!this.props.venueReportingPeriod) {
            return (<></>);
        }
/*
        let start;
        if (this.props.venueReportingPeriod.startTime) {
            start = this.props.venueReportingPeriod.startTime.displayLocal + " " + this.props.venueReportingPeriod.startTimeTimezone;
        }
        let timezones;
        if (this.props.venueReportingPeriod && this.props.venueReportingPeriod.timezones && this.props.venueReportingPeriod.timezones.length > 1) {
            timezones = (
                <SelectControlGroup
                    text="End Timezone"
                    onChange={(value) => this.onSubValueChanged("endTime", "timezoneId", value)}
                    defaultValue={this.props.venueReportingPeriod.endTime.timezoneId}>
                    {this.props.venueReportingPeriod.timezones.map((item, index) => (
                        <option key={item.timezoneId} value={item.timezoneId}>{item.timezoneAbbreviation}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        <TextControlGroup
                        text={"Start"}
                        readonly={true}
                        defaultValue={start} />

                    {this.props.venueReportingPeriod.endTime && (
                        <LocalDateTimeControlGroup
                            text="End"
                            date={this.props.venueReportingPeriod.endTime}
                            onChange={(value) => this.onValueChanged("endTime", value)} />
                    )}
                    {timezones}
                    {this.props.venueReportingPeriod.securityCode && (
                        <TextControlGroup
                            text={"Security Code"}
                            readonly={true}
                            defaultValue={this.props.venueReportingPeriod.securityCode} />

                    )}
                    <CheckboxControlGroup
                        text={"Security Code Required"}
                        label={" "}
                        isReversed={true}
                        defaultValue={this.props.venueReportingPeriod.securityCodeNotRequired}
                        onChanged={(value) => this.onValueChanged("securityCodeNotRequired", value)} />

                        */
        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    <ReportingPeriod 
                        venueReportingPeriod={this.props.venueReportingPeriod}
                        onUpdate={value => this.props.onUpdate(value)}
                    />
                    
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}