import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ErrorButton from '../controls/ErrorButton';

export interface IDuplicateMobileNumbersRowProps {
    onUpdate: Function;
    onOpen: Function;
    classes: any;
    theme: any;
    user: AusComplyDtos.User;
}

class DuplicateMobileNumbersRow extends React.Component<IDuplicateMobileNumbersRowProps, any> {


    render() {
        const { classes } = this.props;
        let item = this.props.user;
        let className = classes.rowStatusPending;

        return (
            <tr className={classes.tableTr} key={"row-" + item.userId.toString()} >
                <td className={className} ></td>
                <td onClick={() => this.props.onOpen(this.props.user)}>
                    <p style={{textDecoration: 'underline'}}>
                        {item.displayName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.email}
                    </p>
                </td>
                <td>
                    <p>
                        {item.mobileNo}
                    </p>
                </td>
                <td>
                    <p>
                        {this.props.user.mobileNo != "9999999999" && (
                            <ErrorButton text={"Reset"} style={{ width: 'auto', padding: '2px' }} onClick={() => this.props.onUpdate(this.props.user)} />
                        )}
                    </p>
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(DuplicateMobileNumbersRow);