import React from 'react';
import { RadioOption } from '../../common/dto/common';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import DefaultButton from '../controls/DefaultButton';
import Grid from '@material-ui/core/Grid';

export interface IRadioChipControlProps {
    name: string;
    selected?: string;
    options?: RadioOption[];
    onChange?: Function;
    readonly?: boolean;
    required?: boolean;
    chipWidth?: string;
    theme: any;
    classes: any;
}
export interface IRadioChipControlState {
    selected: string;
    options: RadioOption[];
}

class RadioChipControl extends React.PureComponent<IRadioChipControlProps, IRadioChipControlState> {

    constructor(props: IRadioChipControlProps) {
        super(props);

        this.state = {
            selected: "",
            options: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onDeSelected = this.onDeSelected.bind(this);
    }

    async componentDidMount() {
        this.setState({
            selected: this.props.selected || "",
            options: this.props.options || []
        });
    }

    componentDidUpdate() {
        this.setState({
            selected: this.props.selected || this.state.selected,
            options: this.props.options || this.state.options
        });
    }

    handleChange = event => {
        let val = event.target.value;
        if (val != null) {
            this.setState({ selected: val });
            if (this.props.onChange) {
                this.props.onChange({ value: val });
            }
        }
    };

    onSelected(item: RadioOption) {
        this.setState({ selected: item.id });
        if (this.props.onChange) {
            this.props.onChange({ value: item.id });
        }
    }

    onDeSelected() {
        this.setState({ selected: "" });
        if (this.props.onChange) {
            this.props.onChange({ value: "" });
        }
    }

    render() {
        const { classes, theme } = this.props;
        let chipWidth = this.props.chipWidth || 'auto';
        if (this.props.selected) {
            let selectedDisplay;
            this.state.options.forEach((item, index) => {
                if (this.state.selected == item.id) {
                    selectedDisplay = (<Chip key={item.id} label={item.name}
                        style={{
                            marginLeft: 0,
                            marginTop: 0,
                            marginRight: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            color: theme.custom.colors.controlTextLight,
                            backgroundColor: theme.custom.colors.controlBackgroundLight,
                            borderColor: theme.custom.colors.boundary,
                            borderRadius: '6px',
                            width: chipWidth
                        }} variant="outlined" deleteIcon={<div style={{
                            backgroundColor: theme.custom.colors.controlContrastText,
                            fontSize: '10px',
                            height: '16px',
                            width: '16px',
                            borderRadius: '9px',
                            padding: 0,
                            position: 'absolute',
                            top: '-6px',
                            right: '-6px'
                        }} ><CancelIcon style={{
                            fontSize: '10px',
                            height: '18px',
                            width: '18px',
                            margin: '-1px'
                        }} /></div>} onDelete={() => this.onDeSelected()} />);
                }
            });
            if (selectedDisplay) {
                return (
                    <FormControl>
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {selectedDisplay}
                        </div>
                    </FormControl>
                );
            }
        }

        let radioOptions = this.state.options.filter(item => !this.props.readonly || item.id === this.state.selected).map((item, index) => {
            return (
                <Grid item xs={6} sm={4} md={3} lg={2} key={item.id}>
                    <DefaultButton text={item.name} onClick={() => this.onSelected(item)} autoFocus={false}
                        style={{
                            width: '100%',
                            height: '100%',
                            fontSize: '12px',
                            textTransform: 'none',
                            padding: '4px'
                        }} />
                </Grid>
            )
        });

        return (
            <FormControl style={{ width: '100%' }}>
                <Grid container spacing={1} style={{ marginTop: '8px' }}>
                    {radioOptions}
                </Grid>
            </FormControl>
        );
    }
}

export default withStyles(styles, { withTheme: true })(RadioChipControl);