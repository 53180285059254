import { Observable } from 'rxjs';

const geoLocationOptions = {
    enableHighAccuracy: true,
    timeout: 15000,
    maximumAge: 0
};

export const geoLocationApi = {
    getCurrentPositionObservable: () => {
        return Observable.create(observer => {
            if (navigator.geolocation) {
                var geotimeout = setTimeout(function () {
                    observer.error({message:'Timeout'});
                }, 17500);
                navigator.geolocation.getCurrentPosition(
                    (p) => {
                        clearTimeout(geotimeout);
                        observer.next(p)
                    },
                    (error) => {
                        clearTimeout(geotimeout);
                        observer.error(error);
                    },
                    geoLocationOptions
                );
            } else {
                observer.error({message:'Location services appears to be disabled, please check your system settings'});
            }
        });
    }
};