import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { roleTypeLogic } from '../../../common/logic/roleTypeLogic';
import TableRow from '../../common/TableRow';

export interface IRoleTypeRowProps {
    onCommand: Function;
    roleType: AusComplyDtos.UserRoleTypeSummary;
}

class RoleTypeRow extends React.Component<IRoleTypeRowProps, any> {
    constructor(props: IRoleTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand){
            this.props.onCommand(command, this.props.roleType);
        }
    }

    render() {
        let item = this.props.roleType;
        var commands = roleTypeLogic.getCommands(item, true);
        
        return (
            <TableRow commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.defaultRole ? 'System' : 'Custom'}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.venue && item.security 
                            ? 'Both' 
                            : item.venue ? 'Venue'
                            : item.security ? 'Security Firm'
                            : ''
                        }
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.stateName && item.stateName}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.accessLevel}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.userRolesCount}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.canBeAssigned ? 'Yes' : 'No'}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {item.obsolete ? 'Yes' : 'No'}
                    </p>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RoleTypeRow);