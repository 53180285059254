import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import ResponsiveList from '../../common/ResponsiveList';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import { KeyName } from '../../../common/dto/common';
import Error from '../../common/Error';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import View from '../../common/View';
import { Palette } from '../../../common/constants/palette';
import Box from '@material-ui/core/Box';
import PrimaryButton from '../../controls/PrimaryButton';
import ErrorButton from '../../controls/ErrorButton';
import ButtonLink from '../../controls/ButtonLink';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import FormErrorMessage from '../../alerts/FormErrorMessage';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface IExternalApiConnectionProps {
    entityType: AusComplyDtos.ngtEntityType;
    venueId?: number;
    securityFirmId?: number;
    externalApiConnection: AusComplyDtos.ExternalApiConnection;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    onLoad: Function;
    onRefreshToken: Function;
    onRemove: Function;
    onSet: Function;
    onUpdate: Function;
}

interface IExternalApiConnectionState {
}

export default class ExternalApiConnection extends Component<IExternalApiConnectionProps, IExternalApiConnectionState> {
    constructor(props: IExternalApiConnectionProps) {
        super(props)

        this.state = {
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.entityType, this.props.venueId, this.props.securityFirmId);
    }

    componentDidUpdate(prevProps) {
    }

    onValueChanged(fieldName, value) {
        let externalApiConnection = { ...this.props.externalApiConnection };
        externalApiConnection[fieldName] = value;
        this.props.onSet(externalApiConnection);
    }

    render() {
        let self = this;
        let disabled = this.props.isSaving || this.props.isLoading || this.props.externalApiConnection == undefined || this.props.externalApiConnection == null;
        return <CardContainer
            title={"API Connection"}
            isSaving={this.props.isSaving}
            isLoading={this.props.isLoading}
            style={{ paddingTop: 0 }}>
            <CardContent style={{ paddingTop: 0 }}>
                {this.props.externalApiConnection.lastError && <Error message={this.props.externalApiConnection.lastError} />}
                {(this.props.externalApiConnection.availableParents && this.props.externalApiConnection.availableParents.length > 0) &&
                    <SelectControlGroup
                        text="Company"
                        defaultValue={this.props.externalApiConnection.parentId}
                        onChange={(value) => this.onValueChanged("parentId", value)}>
                        <option value={undefined} />
                        {this.props.externalApiConnection.availableParents.map((item) => (
                            <option key={"parent-" + item.id} value={item.id}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                }
                <TextControlGroup
                    text={"Status"}
                    readonly={true}
                    defaultValue={this.props.externalApiConnection.display}
                    onChange={value => { }} />
                {this.props.externalApiConnection.connectionType == AusComplyDtos.ngtExternalApiConnectionType.IdSecret && (
                    <>
                        <TextControlGroup
                            text={"Client ID"}
                            defaultValue={this.props.externalApiConnection.clientID}
                            onChange={value => self.onValueChanged("clientID", value)} />
                        <PasswordControlGroup
                            text={"Client Secret"}
                            defaultValue={this.props.externalApiConnection.clientSecret}
                            onChange={value => self.onValueChanged("clientSecret", value)} />
                    </>
                )}
                 {this.props.externalApiConnection.canConnectToSandpit && (
                    <CheckboxControlGroup 
                        text='Connect to Sandpit'
                        label=' '
                        defaultValue={this.props.externalApiConnection.sandpit}
                        onChanged={value => this.onValueChanged("sandpit", value)}
                    />
                 )}

                <Box display="flex" flexDirection="row" justifyContent="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Box flex={1}>
                    </Box>
                    {(this.props.externalApiConnection && this.props.externalApiConnection.connectionType == AusComplyDtos.ngtExternalApiConnectionType.IdSecret) && (
                        <Box>
                            <View style={{ paddingRight: '10px' }}>
                                <PrimaryButton text='Save' onClick={() => self.props.onUpdate(this.props.externalApiConnection)} />
                            </View>
                        </Box>
                    )}
                    {this.props.externalApiConnection && this.props.externalApiConnection.loginUrl && this.props.externalApiConnection.loginUrl !== "" && <>
                        <Box>
                            <View style={{ paddingTop: '6px', paddingRight: '10px' }}>
                                <a style={{ color: 'white' }} href={this.props.externalApiConnection.loginUrl}>{this.props.externalApiConnection.refreshToken ? "Re-connect" : "Connect"}</a>
                            </View>
                        </Box>
                    </>}
                    {(this.props.externalApiConnection && this.props.externalApiConnection.isConnected) && (
                        <Box>
                            <View style={{ paddingRight: '10px' }}>
                                <TransparentButton text='Refresh Token' onClick={() => self.props.onRefreshToken(this.props.externalApiConnection.externalApiConnectionId)} />
                            </View>
                        </Box>
                    )}
                    {(this.props.externalApiConnection && this.props.externalApiConnection.isConnected) && (
                        <Box>
                            <ErrorButton text='Remove' onClick={() => self.props.onRemove(this.props.externalApiConnection.externalApiConnectionId)} />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </CardContainer>

    }
}