import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import { PickerItem } from '../../common/PickerItem';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import { RadioOption, MulitSelectOption } from '../../../common/dto/common';
import Paragraph from '../../common/Paragraph';
import RadioControl from '../../controls/RadioControl';
import CheckboxControl from '../../controls/CheckboxControl'
import SelectControlGroup from '../../controls/SelectControlGroup';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IUserRolesFilterProps {
    filter: AusComplyDTOs.UserRoleFilter;
    userRoleTypes: AusComplyDTOs.UserRoleType[];
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IUserRolesFilterState {
    filter: AusComplyDTOs.UserRoleFilter;
}

export default class UserRolesFilter extends React.Component<IUserRolesFilterProps, IUserRolesFilterState> {
    constructor(props: IUserRolesFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loggedInChanged = this.loggedInChanged.bind(this);
        this.removedChanged = this.removedChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.UserRoleFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    loggedInChanged(value) {
        let filter = { ...this.state.filter };
        filter.loggedInOnly = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    removedChanged(value) {
        let filter = { ...this.state.filter };
        filter.removed = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch()} />
                </Grid>
            </Grid>
        );


        return (
            <LayoutForm>
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChange('search', value);
                        this.props.onSearch();
                    }}
                    onChange={value => this.onChange('search', value)} />
                <TextControlGroup text={"First Name"}
                    labelAbove={true}
                    defaultValue={this.state.filter.firstName}
                    onSubmit={value => {
                        this.onChange('firstName', value);
                        this.props.onSearch();
                    }}
                    onChange={value => this.onChange('firstName', value)} />
                <TextControlGroup text={"Last Name"}
                    labelAbove={true}
                    defaultValue={this.state.filter.lastName}
                    onSubmit={value => {
                        this.onChange('lastName', value);
                        this.props.onSearch();
                    }}
                    onChange={value => this.onChange('lastName', value)} />
                <CheckboxControl text="Logged In Only" defaultValue={this.state.filter.loggedInOnly} onChanged={this.loggedInChanged} />
                <CheckboxControl text="Show Removed" defaultValue={this.state.filter.removed} onChanged={this.removedChanged} />
                <SelectControlGroup 
                    text="Role Type"
                    defaultValue={this.state.filter.userRoleTypeId}
                    onChange={(value) => this.onChange("userRoleTypeId", value)}
                    labelAbove={true}>
                    <option value={undefined} />
                    {this.props.userRoleTypes && this.props.userRoleTypes.map((item) => (
                        <option key={"role-type-" + item.userRoleTypeId} value={item.userRoleTypeId}>{"Level " + item.accessLevel.toString() + " (" + item.name + ")"}</option>
                    ))}
                </SelectControlGroup>
                {resetButton}
            </LayoutForm>
        );
    }
}