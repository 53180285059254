import React from 'react';
import PageMessage from './PageMessage';
import { Link } from 'react-router-dom';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface INoRowsMessageProps {
    message?: string;
    newItemLink?: string;
    classes: any;
    theme: any;
}
class NoRowsMessage extends React.PureComponent<INoRowsMessageProps, any> {
    render() {
        const { theme } = this.props;
        return (
            <PageMessage>
                <p style={{ textAlign: 'center', ...theme.custom.label }}>
                    {this.props.message || "No rows found."}{" "}
                    {this.props.newItemLink && (
                        <span>
                            <br />
                            <Link to={"/"  + this.props.newItemLink}>Click here to create a new one.</Link>
                        </span>
                    )}
                </p>
                {this.props.children}
            </PageMessage>
        );
    }
}

export default withStyles(styles, { withTheme: true })(NoRowsMessage);