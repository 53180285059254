import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentCategoryType, VenueLocation } from "../../../common/dto/AusComply.dtos";
import { incidentLogic } from '../../../common/logic/incidentLogic';
import TransparentButton from '../../controls/TransparentButton';
import ButtonLink from '../../controls/ButtonLink';
import PrimaryButton from '../../controls/PrimaryButton';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../../common/PageTitle';
import FixedFooter from '../../layout/FixedFooter';
import SubmitIcon from '../../../resources/SubmitIcon';
import IncidentWizardCategory from '../wizard/IncidentWizardCategory';
import IncidentWizardChecklists from '../wizard/IncidentWizardChecklists';
import IDScanAction from './IDScanAction';
import IDScanPostSave from './IDScanPostSave';
import IDScanDocuments from './IDScanDocuments';
import IDScanLocation from './IDScanLocation';
import IDScanPatron from './IDScanPatron';
import Error from '../../common/Error';
import ErrorBoundary from '../../common/ErrorBoundary';
import LayoutForm from '../../layout/LayoutForm'
import Loading from '../../common/Loading';
import DeleteOutlinedIcon from '../../../resources/DeleteOutlinedIcon';
import NavigationButton from '../../controls/NavigationButton';
import FacialRecognitionFeedContainer from '../../../containers/FacialRecognitionFeedContainer';
import PageLayout from '../../layout/PageLayout';

export interface IDraftIDScanIncidentProps {
    incidentTypeId: number;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    hasDraftIncident: boolean;
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onCreateIncident: Function;
    onUpdateIncident: Function;
    onClearError: Function;
    onClear: Function;
    onSaveDraft: Function;
    onSaveFinal: Function;
    onCheckIncidentDateReportingPeriod: Function;
    onErrorNotification: Function;
    onResetFilter: Function;
    history: any;
    location: any;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onRefreshLocation: Function;
    isUploading: boolean;
    onUploadFiles: Function;
    onUploadBase64File: Function;
    onUploadPatronFiles: Function;
    venueLocationId: number;
    closeAfterSave: boolean;
    setDefaults: Function;
    action: string;
    setAction: Function;
    hasChecklists: boolean;
    onUploadAttachment: Function;
    onLoadChecklists: Function;
    isLoadingChecklists: boolean;
    onLoadIncidentOptions: Function;
}

interface IDraftIDScanIncidentState {
    closeAfterSave: boolean;
    validDraft: boolean;
    validSubmit: boolean;
    incidentTypeName: string;
}

class DraftIDScanIncident extends Component<IDraftIDScanIncidentProps, IDraftIDScanIncidentState> {

    constructor(props: IDraftIDScanIncidentProps) {
        super(props)

        this.state = {
            validDraft: false,
            validSubmit: false,
            closeAfterSave: true,
            incidentTypeName: "Incident"
        }

        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onClearError = this.onClearError.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
        this.onUpdateLocation = this.onUpdateLocation.bind(this);
        this.onChangeCloseAfterSave = this.onChangeCloseAfterSave.bind(this);
        this.onOpenIncidient = this.onOpenIncidient.bind(this);
        this.onUpdateAction = this.onUpdateAction.bind(this);
        this.onPatronUpdated = this.onPatronUpdated.bind(this);
        this.onUpdateAndRefreshChecklists = this.onUpdateAndRefreshChecklists.bind(this);
    }

    componentDidMount() {
        if (!this.props.hasDraftIncident || (this.props.hasDraftIncident && !this.props.incident)) {
            this.props.onCreateIncident(this.props.incidentTypeId);
            if (!this.props.locationFound) {
                this.props.onRefreshLocation();
            }
        }
        
        if (this.props.hasDraftIncident && !this.props.incidentOptions.incidentTypes){
            this.props.onLoadIncidentOptions();
        } else if (this.props.hasDraftIncident && this.props.incidentTypeId == 4) {
            this.onDraft();
        }
        this.setState({
            closeAfterSave: this.props.closeAfterSave
        });
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error && this.props.incidentTypeId == 4) {
                this.props.history.push('/draftincident/0');
            } else if (!this.props.error) {
                // Saved successfully
                if (this.props.closeAfterSave) {
                    this.clearAndClose();
                } else {
                    this.props.onCreateIncident(incidentLogic.findIDCheckIncidentType(this.props.incident, this.props.incidentOptions));
                    return;
                }
            }
        }

        if (!this.props.isLoading && preProps.isLoading  && this.props.incidentTypeId == 4) {
            this.onDraft();
        } else if (!this.props.isLoading && preProps.isLoading && this.props.error != "") {
            this.clearAndClose();
            return;
        }

        // eslint-disable-next-line
        if (!this.props.isLoading && preProps.isLoading && (!this.props.incident.incidentId || this.props.incident.incidentId == 0)) {
            this.onUpdateLocation();
            // set the default venue location to be the same as the last one
            if (incidentLogic.setVenueLocation(this.props.incident, this.props.incidentOptions, this.props.venueLocationId)) {
                this.onUpdate({ ...this.props.incident });
            }
        } else if (!this.props.isLoading && preProps.isLocationLoading && !this.props.isLocationLoading) {
            this.onUpdateLocation();
        }

        // eslint-disable-next-line
        if (this.props.incident && this.props.incident != preProps.incident) {
            var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            this.setState({
                validDraft: validationResult.draftOk,
                validSubmit: validationResult.idCheckOk,
                incidentTypeName: incidentLogic.incidentTypeName(this.props.incident, this.props.incidentOptions)
            });
        }
    }

    onClearError() {
        this.props.onClearError();
    }

    onCancel() {
        // cancel and navigate to home
        // if never saved - clear, otherwise delete
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        this.props.history.push('/incidents');
    }

    clearAndClose() {
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        this.props.history.push('/incidents');
    }

    onUpdateLocation() {
        let incident = { ...this.props.incident };
        incident.locationFound = this.props.locationFound;
        incident.latitude = this.props.latitude;
        incident.longitude = this.props.longitude;
        this.props.onUpdateIncident(incident);
    }

    onUpdateAndRefreshChecklists(incident) {
        if (incidentLogic.requiresPOI(incident, this.props.incidentOptions)) {
            incidentLogic.ensureOnePOIRecordExists(incident, this.props.incidentOptions);
        } else {
            incident.incidentPatrons = [];
        }
        this.props.onUpdateIncident(incident);
        this.props.onLoadChecklists();
    }

    onDraft() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.draftOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveDraft();
    }

    onSubmit() {
        if (this.props.isUploading || this.props.isLoadingChecklists) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.idCheckOk) {
            this.props.onErrorNotification(validationResult.error);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        // save the defaults for the next ID Check
        this.props.setDefaults(this.props.incident.incidentLocation[0]["venueLocationId"], this.state.closeAfterSave);
        // Save
        this.props.onSaveFinal();
    }

    onUpdate(incident) {
        this.props.onUpdateIncident(incident);
    }

    onPatronUpdated(patron) {
        let incident = { ...this.props.incident };
        patron.hasChanged = true;
        incident.incidentPatrons = [patron];
        this.onUpdate(incident);
    }

    onChangeCloseAfterSave(closeAfterSave: boolean) {
        this.setState({ closeAfterSave });
    }

    onOpenIncidient() {
        this.props.history.push('/draftincident/0');
    }

    onUpdateAction(action: string) {
        // change the action type
        let newIncident = { ...this.props.incident };
        let changed = (action != this.props.action);
        if (action == "REFUSE" && this.props.action != "REFUSE") {
            newIncident.incidentCategory = [];
        }
        if (this.props.action == "UNDERAGE" && action != "UNDERAGE") {
            // remove the under 18 tag
            incidentLogic.removeUnder18IsSelected(newIncident, this.props.incidentOptions);
        }

        newIncident.incidentTypeId = (action == "REFUSE" || action == "UNDERAGE") ?
            incidentLogic.findRefuseIncidentType(newIncident, this.props.incidentOptions) :
            incidentLogic.findIDCheckIncidentType(newIncident, this.props.incidentOptions);

        // remove all categories that are not part of this new incident
        newIncident = incidentLogic.validateIncidentCategoriesMatchIncidentType(newIncident, this.props.incidentOptions);

        // set the incident category for the allow action, take the top category
        if (action == "ALLOW") {
            newIncident.incidentPatrons = [];
            incidentLogic.setFirstCategoryAsDefault(newIncident, this.props.incidentOptions);
        } else if (action == "UNDERAGE") {
            incidentLogic.selectFirstIncidentCategoryTypeByName(newIncident, this.props.incidentOptions, "Underage");
            incidentLogic.ensureOnePOIRecordExists(newIncident, this.props.incidentOptions);
            // select the under 18 tag
            incidentLogic.ensureUnder18IsSelected(newIncident, this.props.incidentOptions);

        } else if (action == "REFUSE") {
            newIncident.incidentPatrons = [];

        }

        // set the updated incident
        this.props.onUpdateIncident(newIncident);
        if (changed) {
            this.props.onLoadChecklists();
        }
        this.setState({
            incidentTypeName: incidentLogic.incidentTypeName(this.props.incident, this.props.incidentOptions)
        }, () => {
            this.props.setAction(action)
        });
    }

    render() {

        let saveButton = this.state.validSubmit && !this.props.isLoadingChecklists ? <PrimaryButton text="Save" className={"IncidentWizardNavigation-buttons"} style={{ borderRadius: '5px', marginBottom: '6px', marginTop: '6px', width: '160px' }} onClick={this.onSubmit} />
            : <TransparentButton text="Save" className={"IncidentWizardNavigation-buttons"} style={{ borderRadius: '5px', marginBottom: '6px', marginTop: '6px', width: '160px' }} onClick={this.onSubmit} />;

        let patron;
        if (this.props.incident && this.props.incident.incidentPatrons && this.props.incident.incidentPatrons.length > 0 && this.props.incident.incidentPatrons[0]) {
            patron = <IDScanPatron
                incident={this.props.incident}
                incidentOptions={this.props.incidentOptions}
                incidentPatron={this.props.incident.incidentPatrons[0]}
                onUpdated={updatedPatron => this.onPatronUpdated(updatedPatron)}
            />;
        }

        let checklists;
        if (this.props.hasChecklists && !this.props.isLoadingChecklists) {
            checklists = <IncidentWizardChecklists onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={() => { }} onSwipedRight={() => { }} />;
        }

        let venueLocationId: number = 0
        if (this.props.incident && this.props.incident.incidentLocation && this.props.incident.incidentLocation.length > 0) {
            venueLocationId = this.props.incident.incidentLocation[0].venueLocationId;
        }


        return (
            <PageLayout
                header={<PageTitle title={"New " + this.state.incidentTypeName} headerStyle={{ textAlign: 'center' }} />}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                notFullWidth={true}
                footerContent={
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                padding: '0 20px'
                            }}>
                                <div style={{ flex: 0 }}>
                                    <NavigationButton
                                        onClick={this.onCancel}
                                        text={"Discard"}
                                        isError={true}
                                        Icon={DeleteOutlinedIcon} />
                                </div>
                                <div style={{ flex: 0 }}>
                                    <NavigationButton
                                        onClick={this.onSubmit}
                                        text={"Submit"}
                                        active={true}
                                        isSuccess={this.state.validSubmit && !this.props.isLoadingChecklists}
                                        Icon={SubmitIcon} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                }
            >
                <ErrorBoundary>
                    <IDScanDocuments
                        incident={this.props.incident}
                        isUploading={this.props.isUploading}
                        onUploadFiles={this.props.onUploadFiles}
                        onUpdateIncident={this.onUpdate} />
                    <IDScanLocation
                        incident={this.props.incident}
                        incidentOptions={this.props.incidentOptions}
                        onUpdateIncident={this.onUpdate}
                        isLocationLoading={this.props.isLocationLoading}
                        locationError={this.props.locationError}
                        locationFound={this.props.locationFound}
                        latitude={this.props.latitude}
                        longitude={this.props.longitude}
                        onUpdateLocation={this.props.onRefreshLocation}
                    />
                    {this.props.incident.hasLiveFeed && (
                        <FacialRecognitionFeedContainer
                            venueId={this.props.incident.venueId}
                            venueLocationId={venueLocationId}
                            onSelected={(file, fileType, fileName, poiGuid, faceGuid) => this.props.onUploadBase64File(file, fileType, "person_" + fileName, poiGuid, faceGuid)}
                        />
                    )}
                    <IDScanAction
                        action={this.props.action}
                        onChange={value => this.onUpdateAction(value)} />
                    {this.props.action == "REFUSE" && (
                        <IncidentWizardCategory
                            incident={this.props.incident}
                            incidentOptions={this.props.incidentOptions}
                            onUpdateIncident={this.onUpdateAndRefreshChecklists} />
                    )}
                    <>
                        {patron}
                    </>
                    {this.props.isLoadingChecklists && (
                        <Loading />
                    )}
                    {checklists}
                    <IDScanPostSave
                        closeAfterSave={this.state.closeAfterSave}
                        onChange={value => this.onChangeCloseAfterSave(value)} />
                    <ButtonLink onClick={this.onOpenIncidient}>
                        <span>Open as a regular incident</span>
                    </ButtonLink>
                </ErrorBoundary>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(DraftIDScanIncident));