import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import Map from '../../common/Map'

export interface IIncidentViewLocationsProps {
    incident: IncidentDTO;
}

interface IIncidentViewLocationsState {
}

class IncidentViewLocations extends Component<IIncidentViewLocationsProps, IIncidentViewLocationsState> {
    constructor(props: IIncidentViewLocationsProps) {
        super(props)
    }

    render() {
        if (!this.props.incident || !this.props.incident.incidentLocation) {
            return <></>;
        }
        let locations = this.props.incident.incidentLocation.map((item, index) => <span key={index}>{index > 0 && (<br />)}{item.venueLocation.name}</span>);

        return (
            <>
                <ControlGroup text="Locations">
                    <TextDisplay>{locations}</TextDisplay>
                    {(this.props.incident.locationFound) && (
                        <>
                            <br />
                            <Map hideByDefault={true} latitude={this.props.incident.latitude} longitude={this.props.incident.longitude} />
                        </>
                    )}
                </ControlGroup>
                {this.props.incident.selectedSubLocationsDisplay &&
                    <ControlGroup text="Sub Locations">
                        <TextDisplay>{this.props.incident.selectedSubLocationsDisplay}</TextDisplay>
                    </ControlGroup>
                }
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentViewLocations);