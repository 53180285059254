import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITitleProps {
    theme: any;
    text: string;
}

class Title extends React.PureComponent<ITitleProps, any> {
    constructor(props: ITitleProps){
        super(props)
    }
    render() {
        const { theme } = this.props;
        return (
            <h3
                style={{...theme.custom.title}}
                >{this.props.text}</h3>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Title);