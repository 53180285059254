import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import SubTitle from '../../../common/SubTitle';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import CheckListItemAttachment from './CheckListItemAttachment';

export interface ICheckListItemGroupProps {
    checkListDisplayItem: CheckListDisplayItem;
    onUpdate?: Function;
    theme: any;
}

interface ICheckListItemGroupState {
}

class CheckListItemGroup extends Component<ICheckListItemGroupProps, ICheckListItemGroupState> {
    constructor(props: ICheckListItemGroupProps) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { theme } = this.props;
        return (
            <div style={theme.custom.checklist.container}>
                <div style={{ marginBottom: '10px', display: 'block', borderBottomColor: theme.custom.colors.cardContainerBorder, borderBottom: '1px solid' }}>
                    <SubTitle text={this.props.checkListDisplayItem.template.name} />
                    {this.props.checkListDisplayItem.template.description && (
                        <Paragraph text={this.props.checkListDisplayItem.template.description} />
                    )}
                </div>
                <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemGroup);