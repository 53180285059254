import { connect } from 'react-redux';
import SignOnOffRegisterPrint from '../components/signon/SignOnOffRegisterPrint';
import { printOptionsRequest, printHide, printSignOnOffRegistersRequest } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = (state) => {
    return {
        filter: state.signOn.filter,
        isLoading: state.print.isLoading,
        show: state.print.show,
        printOptions: state.print.printOptions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadPrintOptions: () => {
            dispatch(printOptionsRequest())
        },
        onHide: () => {
            dispatch(printHide())
        },
        onPrint: (option, filter, to, cc, bcc) => {
            dispatch(printSignOnOffRegistersRequest(option, filter, to, cc, bcc))
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message))
        }
    }
}

const SignOnOffRegisterPrintContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnOffRegisterPrint)

export default SignOnOffRegisterPrintContainer