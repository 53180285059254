import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import MultiRadioChipControl from '../../controls/MultiRadioChipControl';

export interface ICommLogRadioChannelProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    showGPSLocation?: boolean;
    theme: any;
    classes: any;
}

interface ICommLogRadioChannelState {
    items: MulitSelectOption[];
}

class CommLogRadioChannel extends Component<ICommLogRadioChannelProps, ICommLogRadioChannelState> {
    constructor(props: ICommLogRadioChannelProps) {
        super(props)
        this.state = {
            items: [],
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onLoadItems = this.onLoadItems.bind(this);
        this.onSelectedChanged = this.onSelectedChanged.bind(this);
    }

    componentDidMount() {
        this.onLoadItems();
    }

    componentDidUpdate(preProps) {
        if(JSON.stringify(this.props.incident.selectedRadioChannels) !== JSON.stringify(preProps.incident.selectedRadioChannels)){
            this.onLoadItems();
        }
    }

    onLoadItems()
    {
        try {
            let items: MulitSelectOption[] = [];
            //let selected: number[] = [];
            //if (this.props.incident && this.props.incident.selectedFunctionalAreas) {
            //    selected = this.props.incident.selectedRadioChannels.map(item => item);
            //}
            if (this.props.incidentOptions && this.props.incidentOptions.radioChannels) {
                this.props.incidentOptions.radioChannels.forEach((item, index) => {
              //      let isSelected = selected.indexOf(item.radioChannelId) > -1;
                    items.push({
                        id: item.radioChannelId,
                        name: item.name,
                        isCommon: false,
                        selected: false //isSelected
                    });
                });
            }
            this.setState({
                items: items,
            });
        } catch (err) {
            commonLogic.trackException(err, "CommLogRadioChannel.componentDidMount", {})
        }
    }

    onSelectItemsChanged(items) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedRadioChannels = items.map(item => item.id);;
        this.props.onUpdateIncident(newIncident)
    }

    onSelectedChanged(values: number[]) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedRadioChannels = values;
        this.props.onUpdateIncident(newIncident)
    }

    render() {
        
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>                
                <MultiRadioChipControl 
                    wrapInCard={true}
                    cardTitle={"Radio Channels"}
                    selected={this.props.incident.selectedRadioChannels}
                    options={this.state.items}
                    onChange={this.onSelectedChanged}
                />
            </SwipeContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CommLogRadioChannel);