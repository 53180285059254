import React from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUserActionAuditsHeaderProps {
    classes: any;
    theme: any;
}

class UserActionAuditsHeader extends React.Component<IUserActionAuditsHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr}>Date</th>
                <th className={classes.tableHr}>Action</th>
                <th className={classes.tableHr}>Device</th>
                <th className={classes.tableHr}>Location</th>
                <th className={classes.tableHr}>Venue</th>
                <th className={classes.tableHr}>Security</th>
                <th className={classes.tableHr}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserActionAuditsHeader);