import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Media from "react-media";

export interface IControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    rightText?: string;
    error?: boolean;
    errorText?: string;
    labelAbove?: boolean;
    inline?: boolean;
    style?: any;
}

class ControlGroup extends React.PureComponent<IControlGroupProps, any> {
    render() {
        const { theme, classes } = this.props;
        
        // let labelClass = this.props.error ? classes.labelError : classes.labelNormal;
        let labelClass = classes.labelNormal;
        let labelInlineStyle = { minWidth: '120px', display: 'inline-block' };
        if (this.props.inline && this.props.labelAbove) {
            return (
                <Grid container spacing={1} style={{ marginTop: '8px', ...this.props.style }}>
                    {(this.props.rightText || this.props.text) && (
                        <Grid item xs={12}>
                            {(this.props.rightText && this.props.text) && (
                                <label className={labelClass}>{this.props.text} {this.props.rightText}</label>
                            )}
                            {(this.props.rightText && !this.props.text) && (
                                <label className={labelClass}>{this.props.rightText}</label>
                            )}
                            {(!this.props.rightText && this.props.text) && (
                                <label className={labelClass} style={{ ...labelInlineStyle }}>{this.props.text}</label>
                            )}
                            <div style={{ display: 'inline-block', marginLeft: '30px', ...this.props.style }}>
                                {this.props.children}
                            </div>
                        </Grid>
                    )}
                    {(this.props.errorText) && (
                        <Grid item xs={12}>
                            <span className={classes.labelDescriptionError}>{this.props.errorText}</span>
                        </Grid>
                    )}
                </Grid>
            );
        }
        if (this.props.labelAbove) {
            return (
                <Grid container spacing={1} style={{ marginTop: '8px', ...this.props.style }}>
                    {(this.props.rightText || this.props.text) && (
                        <Grid item xs={12}>
                            {(this.props.rightText && this.props.text) && (
                                <label className={labelClass} >{this.props.text} {this.props.rightText}</label>
                            )}
                            {(this.props.rightText && !this.props.text) && (
                                <label className={labelClass} >{this.props.rightText}</label>
                            )}
                            {(!this.props.rightText && this.props.text) && (
                                <label className={labelClass} >{this.props.text}</label>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {this.props.children}
                        {(this.props.errorText) && (
                            <span className={classes.labelDescriptionError}>{this.props.errorText}</span>
                        )}
                    </Grid>
                </Grid>
            );
        }
        if (!this.props.rightText && !this.props.text) {
            return (
                <Grid container spacing={1} style={{ marginTop: '0', ...this.props.style }}>
                    <Grid item xs={12}>
                        {this.props.children}
                        {(this.props.errorText) && (
                            <span className={classes.labelDescriptionError}>{this.props.errorText}</span>
                        )}
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container spacing={1} style={{ marginTop: '8px', ...this.props.style }}>
                {(this.props.rightText || this.props.text) && (
                    <Grid item xs={12} md={4}>
                        <Media query="(max-width: 960px)">
                            {matches =>
                                matches ? (
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            {(this.props.rightText && this.props.text) && (
                                                <label className={labelClass} >{this.props.text} {this.props.rightText}</label>
                                            )}
                                            {(this.props.rightText && !this.props.text) && (
                                                <label className={labelClass} >{this.props.rightText}</label>
                                            )}
                                            {(!this.props.rightText && this.props.text) && (
                                                <label className={labelClass} >{this.props.text}</label>
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container spacing={1}>
                                        {(this.props.text && this.props.rightText) && (
                                            <>
                                                <Grid item xs={8}>
                                                    <label className={labelClass} >{this.props.text}</label>
                                                </Grid>
                                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                                    <label className={labelClass} >{this.props.rightText}</label>
                                                </Grid>
                                            </>
                                        )}
                                        {(this.props.text && !this.props.rightText) && (
                                            <Grid item xs={12}>
                                                <label className={labelClass} >{this.props.text}</label>
                                            </Grid>
                                        )}
                                        {(!this.props.text && this.props.rightText) && (
                                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                                <label className={labelClass} >{this.props.rightText}</label>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            }
                        </Media>
                    </Grid>
                )}
                <Grid item xs={12} md={8}>
                    {this.props.children}
                    {(this.props.errorText) && (
                        <span className={classes.labelDescriptionError}>{this.props.errorText}</span>
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ControlGroup);