import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const directoryServiceApi = {
  get: request.makeGet(
    AusComplyDtos.GetDirectory,
    'venueId', 'securityFirmId', 'entityType', 'id', 'showObsolete'
  ),
  post: request.makePost(
    AusComplyDtos.PostDirectoryDocument,
    'venueId', 'securityFirmId', 'documentUpload', 'showObsolete'
  ),
  remove: request.makePost(
    AusComplyDtos.PostDirectoryDocumentRemove,
    'venueId', 'securityFirmId', 'document', 'showObsolete'
  ),
  update: request.makePost(
    AusComplyDtos.PostDirectoryDocumentUpdate,
    'venueId', 'securityFirmId', 'document', 'showObsolete'
  ),
  upsertFolder: request.makePost(
    AusComplyDtos.PostDirectoryFolder,
    'venueId', 'securityFirmId', 'entityType', 'id', 'folder', 'showObsolete'
  ),
  adminGet: request.makeGet(
    AusComplyDtos.GetAdminDirectory,
    'venueId', 'securityFirmId', 'entityType'
  ),
  adminUpsertFolder: request.makePost(
    AusComplyDtos.PostAdminDirectoryFolder,
    'venueId', 'securityFirmId', 'entityType', 'folder'
  ),
};
