
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { facialRecognitionWatchlistServiceApi } from '../services/facialRecognitionWatchlistService';
import * as facialRecognitionWatchlistActions from "../actions/facialRecognitionWatchlist";

import {
    notifyError, notifyErrorMessage
} from './common'; 
import { notificationSuccessShow, notificationShow } from "../actions/notification";


const facialRecognitionWatchlistsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLISTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forGroupId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistsRequestSuccess(response.facialRecognitionWatchlists)),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistsRequestEpic.find", facialRecognitionWatchlistActions.facialRecognitionWatchlistsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistsRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistsRequestFailure))
    );

const facialRecognitionWatchlistRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionWatchlistsId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistRequestSuccess(response.facialRecognitionWatchlist, response.facialRecognitionWatchlistTypes, response.facialRecognitionServers)),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistRequestEpic.get", facialRecognitionWatchlistActions.facialRecognitionWatchlistRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistRequestFailure))
    );

const facialRecognitionServerCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_CREATE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forGroupId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistCreateSuccess(response.facialRecognitionWatchlist, response.facialRecognitionWatchlistTypes, response.facialRecognitionServers)),
                    catchError(error => notifyError(error, "facialRecognitionServerCreateEpic.create", facialRecognitionWatchlistActions.facialRecognitionWatchlistCreateFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerCreateEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistCreateFailure))
    );

const facialRecognitionWatchlistSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionWatchlists.facialRecognitionWatchlist.facialRecognitionWatchlist)
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistSaveRequestSuccess(response.facialRecognitionWatchlists, response.facialRecognitionWatchlist)),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistSaveEpic.upsert", facialRecognitionWatchlistActions.facialRecognitionWatchlistSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistSaveEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistSaveRequestFailure))
    );

const facialRecognitionWatchlistSyncRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.sync(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forGroupId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistSyncRequestSuccess(response.facialRecognitionWatchlists)),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistSyncRequestEpic.sync", facialRecognitionWatchlistActions.facialRecognitionWatchlistSyncRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistSyncRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistSyncRequestFailure))
    );

const facialRecognitionWatchlistPoiSaveRequestEpic = (action$, state$) => 
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            return facialRecognitionWatchlistServiceApi.poiSave(
                state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistPoi,
                action['page'],
                action['pageSize'],
                action['filter']
            ).pipe(
                map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistPoiSaveRequestSuccess(response.data, response.paging, response.filter)),
                catchError(error => notifyError(error, "facialRecognitionWatchlistPoiSaveRequestEpic.poiSave", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoiSaveRequestFailure))
            )
        }
            
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistPoiSaveRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoiSaveRequestFailure)) 
    );

const facialRecognitionWatchlistPoisRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.pois(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionWatchlistId'],
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequestSuccess(response.data, response.paging, response.filter, response.cameras)),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistPoisRequestEpic.pois", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistPoisRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequestFailure))
    );

const facialRecognitionWatchlistPoisRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.poiRemove(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionWatchlistId'],
                action['poiGuid'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "facialRecognitionWatchlistPoisRemoveRequestEpic.poiRemove", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionWatchlistPoisRemoveRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRemoveRequestFailure))
    );

const facialRecognitionWatchlistPoisRemoveRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => true),
        mergeMap(([action, state]) => of(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequest(state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistId, state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.paging.page, state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.paging.pageSize, state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.filter)))
    );


const facialRecognitionServerNewImportEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.newImport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['forGroupId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistNewImportRequestSuccess(response.facialRecognitionWatchlistImports)),
                    catchError(error => notifyError(error, "facialRecognitionServerNewImportEpic.newImport", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewImportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerNewImportEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewImportRequestFailure))
    );

const facialRecognitionWatchlistImportEpic = (action$, state$) =>
        action$.pipe(
            ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                facialRecognitionWatchlistServiceApi.import(
                    state.user.details.userSession.user.venueId,
                    state.user.details.userSession.user.securityFirmId,
                    state.facialRecognitionWatchlists.facialRecognitionWatchlistImports.facialRecognitionWatchlistImports)
                    .pipe(
                        map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistImportRequestSuccess(response.facialRecognitionWatchlistImports)),
                        catchError(error => notifyError(error, "facialRecognitionWatchlistImportEpic.import", facialRecognitionWatchlistActions.facialRecognitionWatchlistImportRequestFailure))
                    )
            ),
            catchError(error => notifyError(error, "facialRecognitionWatchlistImportEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistImportRequestFailure))
        );


const facialRecognitionServerNewMigrationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.newMigration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['facialRecognitionWatchlistId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMigrationRequestSuccess(response.facialRecognitionWatchlistMigration)),
                    catchError(error => notifyError(error, "facialRecognitionServerNewMigrationRequestEpic.newMigration", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMigrationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerNewMigrationRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMigrationRequestFailure))
    );


const facialRecognitionServerMigrationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.migration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionWatchlists.facialRecognitionWatchlistMigration.facialRecognitionWatchlistMigration)
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationRequestSuccess(response.facialRecognitionWatchlistMigration)),
                    catchError(error => notifyError(error, "facialRecognitionServerMigrationRequestEpic.migration", facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerMigrationRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationRequestFailure))
    );


const facialRecognitionServerMigrationCancelRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.cancelMigration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['facialRecognitionWatchlistMigrationId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationCancelRequestSuccess(response.facialRecognitionWatchlistMigration)),
                    catchError(error => notifyError(error, "facialRecognitionServerMigrationRestartRequestEpic.restartMigration", facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationCancelRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerMigrationRestartRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationCancelRequestFailure))
    );

const facialRecognitionServerMigrationProcessRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MIGRATION_PROCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.processMigrations(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => notificationSuccessShow("Processing Ran")),
                    catchError(error => notifyError(error, "facialRecognitionServerMigrationRestartRequestEpic.restartMigration"))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerMigrationRestartRequestEpic"))
    );


const facialRecognitionServerNewMoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.newMove(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['facialRecognitionWatchlistId'])
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMoveRequestSuccess(response.facialRecognitionWatchlistMove)),
                    catchError(error => notifyError(error, "facialRecognitionServerNewMMoveRequestEpic.newMove", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerNewMoveRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMoveRequestFailure))
    );


const facialRecognitionServerMoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(facialRecognitionWatchlistActions.FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            facialRecognitionWatchlistServiceApi.move(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.facialRecognitionWatchlists.facialRecognitionWatchlistMove.facialRecognitionWatchlistMove)
                .pipe(
                    map(response => facialRecognitionWatchlistActions.facialRecognitionWatchlistMoveRequestSuccess(response.facialRecognitionWatchlistMove)),
                    catchError(error => notifyError(error, "facialRecognitionServerMoveRequestEpic.move", facialRecognitionWatchlistActions.facialRecognitionWatchlistMoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "facialRecognitionServerMoveRequestEpic", facialRecognitionWatchlistActions.facialRecognitionWatchlistMoveRequestFailure))
    );

export const facialRecognitionWatchlistEpics = combineEpics(
    facialRecognitionWatchlistsRequestEpic,
    facialRecognitionWatchlistRequestEpic,
    facialRecognitionServerCreateEpic,
    facialRecognitionWatchlistSaveEpic,
    facialRecognitionWatchlistSyncRequestEpic,
    facialRecognitionWatchlistPoiSaveRequestEpic,
    facialRecognitionWatchlistPoisRequestEpic,
    facialRecognitionWatchlistPoisRemoveRequestEpic,
    facialRecognitionWatchlistPoisRemoveRequestSuccessEpic,
    facialRecognitionServerNewImportEpic,
    facialRecognitionWatchlistImportEpic,
    facialRecognitionServerNewMigrationRequestEpic,
    facialRecognitionServerMigrationRequestEpic,
    facialRecognitionServerMigrationCancelRequestEpic,
    facialRecognitionServerMigrationProcessRequestEpic,
    facialRecognitionServerNewMoveRequestEpic,
    facialRecognitionServerMoveRequestEpic
);