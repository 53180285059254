import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const facialRecognitionWatchlistTypeApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionWatchlistTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionWatchlistTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, facialRecognitionWatchlistType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionWatchlistType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionWatchlistType = facialRecognitionWatchlistType;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, facialRecognitionWatchlistTypeId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionWatchlistType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionWatchlistTypeId = facialRecognitionWatchlistTypeId;
    const request = client.get(query);
    return from(request);
  }
};
