import { createTheme } from '@material-ui/core/styles';

export const lightTheme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily:[
      '"Montserrat"', 
      '"Helvetica Neue"', 
      'sans-serif'].join(','),
  },
  overrides:{
    MuiDialog: {
      paper: {
        border: '1px solid #333',
        borderRadius: '5px'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'transparent',
        padding: '10px !important',
        margin: '0px !important',
        textAlign: 'center'
      }
    },
    TextControl: {
      inputControl: {
        padding: '0 !important'
      }
    }
  },
  "palette":
  {
    "common":
    {
      "black": "#000",
      "white": "rgba(255, 255, 255, 1)"
    },
    "primary":
    {
      "light": "rgba(72, 72, 72, 1)",
      "main": "rgba(33, 33, 33, 1)", "dark": "rgba(0, 0, 0, 1)",
      "contrastText": "#fff"
    },
    "secondary":
    {
      "light": "rgba(239, 239, 239, 1)",
      "main": "rgba(189, 189, 189, 1)",
      "dark": "rgba(141, 141, 141, 1)",
      "contrastText": "#fff"
    },
    "background": {
      "paper": "#fafafa",
      "default": "#fff"
    },
    "text": {
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)",
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)"
    }
  },
  "custom": {
    "colors":{
      "primary": "#42c23b",
      "primaryDark": "#308a2b",
      "border":"#818183",
      "error":"#d2242b",
      "success":"#42c23b",
      "warning": "#f99a1d",
      "boundary": "rgb(76,76,76)",
      "controlBorder": "rgb(47,47,47)",
      "controlBorderDisabled": "rgb(38,38,38)",
      "cardContainerBorder": "#545454",
      "popupListBackground" :"#373737",
      "popupListBorder" :"#4e4e4e",
    },
    "primaryColor": {
      "color": '#42c23b'
    },
    "errorColor": {
      "color": '#d2242b'
    },
    "errorBorder": {
      "borderColor": '#d2242b'
    },
    "label": {
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "subTitle": {
      "color": 'black',
      "textTransform": 'uppercase',
      "fontSize": "16px",
      'fontWeight': 'normal',
      'margin': '6px 0'
    },
    "controlGroup": {
      "label": {
        "color": "black"
      },
      "labelError": {
        "color": "#d2242b"
      }
    },
    "textField":{
    },
    "textFieldError":{
      "height": "40", 
      "borderColor": "#d2242b", 
      "borderWidth": "1", 
      "width": "100%",
      "borderStyle": "solid",
      "backgroundColor": "rgba(255, 255, 255, 0.70)",
      "padding": "4px 10px 3px 10px"
    },
    "textFieldReadonly":{
      "height": "40", 
      "borderColor": "rgba(0, 0, 0, 0.20)", 
      "borderWidth": "1", 
      "width": "100%",
      "borderStyle": "solid",
      "backgroundColor": "#fafafa",
      "padding": "4px 10px 3px 10px",
      "color" : "rgba(0, 0, 0, 0.20)"
    },
    "card": {
      "title": {
        "color": "rgba(0, 0, 0, 0.54)",
        "margin": "6px 0 10px 0"
      },      
      "typography": {
        "color": "rgba(0, 0, 0, 0.87)",
        "margin": "1px 0 1px 0"
      }
    },
    "fileUploaderDragDrop": {
      "border": "1px dotted #818183",
      "display": "block",
      "cursor": "pointer",
      "padding": "20px",
      "backgroundColor": "#f0f0f0",
      "color": "#818183"
    },
    "fileUploaderDragDropActive": {
      "border": "1px dotted white",
      "display": "block",
      "cursor": "pointer",
      "padding": "20px",
      "backgroundColor": "#818183",
      "color": "white"
    },
    "fileUploaderContainer": {
      "background": "#f0f0f0",
      "border": "1px solid #b0b0b0",
      "padding": "0",
      "position": "relative",
      "display": "inline-block",
      "margin": "3px 6px 3px 0"
    },
    "fileUploaderContainerSelected": {
      "border": "1px solid #f99a1d"
    },
    "fileUploaderContainerUploading": {
      "border": "1px solid #42c23b"
    },
    "fileUploaderDisplay": {
      "objectFit": "contain",
      "height": "100%",
      "width": "100%",
      "padding": "0",
      "margin": "0px",
      "background": "#f0f0f0"
    },
    "fileUploaderImage": {
      "objectFit": "contain",
      "height": "80px",
      "width": "80px",
      "padding": "0",
      "margin": "0px",
      "background": "#f0f0f0"
    },
    "fileUploaderText": {
      "textOverflow": "ellipsis",
      "background": "#818183",
      "position": "absolute",
      "bottom": "0",
      "left": "0",
      "textAlign": "center",
      "width": "100%",
      "margin": "0",
      "padding": "3px 0",
      "lineHeight": "12px",
      "fontSize": "12px",
      "color": "white"
    },
    "fileUploaderRemoveButton": {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "margin": "3px",
      "padding": "0",
      "minWidth": "0",
      "minHeight": "0"
    },
    "textDisplay": {
      "padding": "6px",
      "display": "block",
      "width": "100%",
      "margin": "0",
      "color": "rgb(129, 129, 131)",
      "borderColor": "rgb(129, 129, 131)",
      "borderStyle": "dotted",
      "borderRadius": "4px",
      "borderWidth": "1px",
      "boxShadow": "none",
      "fontFamily": '"Montserrat", "Helvetica Neue", sans-serif'
    },
    "poiRemoveButton": {
      "position": "absolute",
      "top": "0",
      "left": "0",
      "margin": "3px",
      "padding": "0",
      "minWidth": "0",
      "minHeight": "0"
    }
  }
});
