import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import RegisterSecurityFirmDetails from '../../register/securityFirm/RegisterSecurityFirmDetails';
import RegisterSecurityFirmAddress from '../../register/securityFirm/RegisterSecurityFirmAddress';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';
import Grid from '@material-ui/core/Grid';

export interface ISecurityFirmEditProps {
    securityFirmId: number;
    isLoading: boolean;
    isSaving: boolean;
    canEdit: boolean;
    securityFirm: AusComplyDtos.SecurityFirm;
    states: AusComplyDtos.State[],
    securityLicenceClasses: AusComplyDtos.SecurityLicenceClass[],
    canEditMasterLicence: boolean;
    error: string;
    errors: any;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class SecurityFirmEdit extends Component<ISecurityFirmEditProps, any> {
    constructor(props: ISecurityFirmEditProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.securityFirmId);
                //this.props.history.push('/admin/securityFirms');
            }
        }
    }
    
    onValueChanged(fieldName: string, value: any) {
        let securityFirm = { ...this.props.securityFirm };
        securityFirm[fieldName] = value;
        this.props.onUpdate(securityFirm);
    }

    onUpdate(securityFirm) {
        this.props.onUpdate(securityFirm);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onLoad(this.props.securityFirmId);
        //this.props.history.push('/admin/securityFirms');
    }

    render() {
        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    fullWidth={true}
                >
                    <form>
                        <RegisterSecurityFirmDetails securityFirm={this.props.securityFirm} canEditMasterLicence={this.props.canEditMasterLicence} states={this.props.states} errors={this.props.errors} onUpdate={this.onUpdate} securityLicenceClasses={this.props.securityLicenceClasses} />
                        <RegisterSecurityFirmAddress securityFirm={this.props.securityFirm} states={this.props.states} errors={this.props.errors} onUpdate={this.onUpdate}  />
                        
                        {!this.props.securityFirm.originalRegistrationComplete && (
                            <CheckboxControlGroup
                            label={" "}
                            text={"Registration Complete"}
                            defaultValue={this.props.securityFirm.registrationComplete}
                            onChanged={(value) => this.onValueChanged("registrationComplete", value)} />
                        )}
                        
                        <ControlGroup text={" "}>
                            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <TransparentButton text={"Cancel"} onClick={this.onCancel} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</DefaultButton>
                                </Grid>
                            </Grid>
                            
                        </ControlGroup>
                    </form>
                </LayoutForm>
            </>
        );
    }
}