import React, { Component } from 'react';
import * as AuscomplyDtos from "../../common/dto/AusComply.dtos";
import ControlGroup from '../controls/ControlGroup';
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import RadioButtonControl from '../controls/RadioButtonControl';
import { RadioOption } from '../../common/dto/common';
import View from '../common/View';

export interface IIncidentsFilterFunctionalAreasProps {
    incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter;
    onUpdate: Function;
}

interface IIncidentsFilterFunctionalAreasState {
    selections: MulitSelectOption[];
}

export default class IncidentsFilterFunctionalAreas extends Component<IIncidentsFilterFunctionalAreasProps, IIncidentsFilterFunctionalAreasState> {
    constructor(props: IIncidentsFilterFunctionalAreasProps) {
        super(props)
        this.state = {
            selections: [],
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectionsChanged = this.onSelectionsChanged.bind(this);
        this.setSelections = this.setSelections.bind(this);
    }

    componentDidMount(): void {
        if (this.props.incidentHistoryFilter) {
            this.setSelections();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.incidentHistoryFilter &&
            (this.hasChangedHash(this.props.incidentHistoryFilter) != this.hasChangedHash(prevProps.incidentHistoryFilter))) {
            this.setSelections();
        }
    }

    hasChangedHash(incidentHistoryFilter: AuscomplyDtos.IncidentHistoryFilter) {
        let hash = "";
        if (incidentHistoryFilter && incidentHistoryFilter.functionalAreas) {
            incidentHistoryFilter.functionalAreas.forEach((item, index) => {
                hash += "#fa-" + item.functionalAreaId.toString() + item.selected ? "-selected" : "";
            });
        }
        return hash;
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        incidentHistoryFilter[fieldName] = value;
        this.props.onUpdate(incidentHistoryFilter);
    }

    setSelections() {
        let selections: MulitSelectOption[] = [];
        if (this.props.incidentHistoryFilter.functionalAreas) {
            this.props.incidentHistoryFilter.functionalAreas.forEach((item, index) => {
                let selection = new MulitSelectOption();
                selection.id = item.functionalAreaId;
                selection.name = item.name;
                selection.selected = item.selected;
                selection.isCommon = true;
                selections.push(selection);
            });
        }
        this.setState({
            selections
        });
    }

    onSelectionsChanged(value) {
        let selections = [...this.state.selections];
        selections.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            selections
        });

        let incidentHistoryFilter = { ...this.props.incidentHistoryFilter };
        if (incidentHistoryFilter.functionalAreas) {
            incidentHistoryFilter.functionalAreas.forEach((item, index) => {
                item.selected = this.state.selections.filter(s => s.id === item.functionalAreaId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(incidentHistoryFilter)
    }

    render() {

        if (!this.props.incidentHistoryFilter || this.props.incidentHistoryFilter.forEntryPage !== "CommsLog") {
            return <></>
        }

        return <View style={{marginTop: '10px'}}><MultiSelectControl
            wrapControlInCard={true}
            cardTitle='Function Areas'
            items={this.state.selections}
            minimumCharactersRequired={3}
            canShowAll={true}
            hideTitles={true}
            searchText={"Type at least three letters..."}
            onSelectItemsChanged={this.onSelectionsChanged} /></View>;
    }
}