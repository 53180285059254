import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { contentServiceApi } from '../services/contentService';

import {
    CONTENT_TERMS_REQUEST,
    contentTermsRequestFailure,
    contentTermsRequestSuccess,
    CONTENT_PRIVACYPOLICY_REQUEST,
    contentPrivacyPolicyRequestFailure,
    contentPrivacyPolicyRequestSuccess
} from "../actions/content";
import {
    notifyErrorMessageActionNoMessage
} from './common';


const loadTerms = (action$) =>
    action$.pipe(
        ofType(CONTENT_TERMS_REQUEST),
        mergeMap(action =>
            contentServiceApi.getTerms()
                .pipe(
                    map(response => contentTermsRequestSuccess({ "title": response.title, "text": response.content })),
                    catchError(error => notifyErrorMessageActionNoMessage(error, "Failed to load terms", contentTermsRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessageActionNoMessage(error, "Failed to load terms", contentTermsRequestFailure))
    );

const loadPrivacyPolicy = (action$, state$) =>
    action$.pipe(
        ofType(CONTENT_PRIVACYPOLICY_REQUEST),
        mergeMap(action =>
            contentServiceApi.getPrivacyPolicy()
                .pipe(
                    map(response => contentPrivacyPolicyRequestSuccess({ "title": response.title, "text": response.content })),
                    catchError(error => notifyErrorMessageActionNoMessage(error, "Failed to load privacy policy", contentPrivacyPolicyRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessageActionNoMessage(error, "Failed to load privacy policy", contentPrivacyPolicyRequestFailure))
    );

export const contentEpics = combineEpics(
    loadTerms,
    loadPrivacyPolicy
);