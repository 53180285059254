import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import {

    SECURITY_FIRM_ASSOCIATIONS_REFRESH,
    SECURITY_FIRM_ASSOCIATIONS_REQUEST,
    SECURITY_FIRM_ASSOCIATIONS_REQUEST_FAILURE,
    SECURITY_FIRM_ASSOCIATIONS_REQUEST_SUCCESS,
    SECURITY_FIRM_ASSOCIATION_RESET,
    SECURITY_FIRM_ASSOCIATION_SET,
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST,
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS,
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_FAILURE,
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST,
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS,
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_FAILURE,
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST,
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS,
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE,

    SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH,
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST,
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_FAILURE,
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_SUCCESS,
    SECURITY_FIRM_SUB_ASSOCIATION_RESET,
    SECURITY_FIRM_SUB_ASSOCIATION_SET,
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST,
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_SUCCESS,
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_FAILURE,
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST,
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS,
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_FAILURE,
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST,
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS,
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_FAILURE

} from "../actions/securityFirmAssociations";

const initialState = {
    venueSecurityFirms: {
        venueId: 0,
        securityFirmId: 0,
        isLoading: false,
        venueSecurityFirms: [],
        paging: { pageSize: 0, page: 1 }
    },
    venueSecurityFirm: {
        isLoading: false,
        isSaving: false,
        venueSecurityFirm: { venueSecurityFirmId: -1, securityFirmId: 0 },
        securityFirms: [],
        error: ""
    },
    securityFirmAssociations: {
        securityFirmId: 0,
        isLoading: false,
        securityFirmAssociations: [],
        paging: { pageSize: 0, page: 1 }
    },
    securityFirmAssociation: {
        isLoading: false,
        isSaving: false,
        securityFirmAssociation: { securityFirmAssociationId: -1, securityFirmId: 0 },
        securityFirms: [],
        error: ""
    }
};

// Venue Security Firms

function loadingVenueSecurityFirm(state, isLoading, error) {
    let newState = update(state, {
        venueSecurityFirm: {
            isLoading: { $set: isLoading },
            isSaving: { $set: false },
            error: { $set: error }
        }
    });
    return newState;
}

function savingVenueSecurityFirm(state, isSaving, error) {
    let newState = update(state, {
        venueSecurityFirm: {
            isLoading: { $set: false },
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setVenueSecurityFirm(state, venueSecurityFirm) {
    let newState = update(state, {
        venueSecurityFirm: {
            venueSecurityFirm: { $set: venueSecurityFirm }
        }
    });
    return newState;
}

function setVenueSecurityFirmOptions(state, securityFirms) {
    let newState = update(state, {
        venueSecurityFirm: {
            securityFirms: { $set: securityFirms }
        }
    });
    return newState;
}

function loadingVenueSecurityFirms(state, isLoading) {
    let newState = update(state, {
        venueSecurityFirms: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueSecurityFirms(state, venueSecurityFirms, paging) {
    let newState = update(state, {
        venueSecurityFirms: {
            venueSecurityFirms: { $set: venueSecurityFirms },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setVenueSecurityFirmIds(state, venueId, securityFirmId) {
    let newState = update(state, {
        venueSecurityFirms: {
            venueId: { $set: venueId },
            securityFirmId: { $set: securityFirmId }
        }
    });
    return newState;
}

// Sub security Firms
function loadingSecurityFirmAssociation(state, isLoading, error) {
    let newState = update(state, {
        securityFirmAssociation: {
            isLoading: { $set: isLoading },
            isSaving: { $set: false },
            error: { $set: error }
        }
    });
    return newState;
}

function savingSecurityFirmAssociation(state, isSaving, error) {
    let newState = update(state, {
        securityFirmAssociation: {
            isLoading: { $set: false },
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setSecurityFirmAssociation(state, securityFirmAssociation) {
    let newState = update(state, {
        securityFirmAssociation: {
            securityFirmAssociation: { $set: securityFirmAssociation }
        }
    });
    return newState;
}

function setSecurityFirmAssociationOptions(state, securityFirms) {
    let newState = update(state, {
        securityFirmAssociation: {
            securityFirms: { $set: securityFirms }
        }
    });
    return newState;
}

function loadingSecurityFirmAssociations(state, isLoading) {
    let newState = update(state, {
        securityFirmAssociations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSecurityFirmAssociations(state, securityFirmAssociations, paging) {
    let newState = update(state, {
        securityFirmAssociations: {
            securityFirmAssociations: { $set: securityFirmAssociations },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setSecurityFirmAssociationIds(state, securityFirmId) {
    let newState = update(state, {
        securityFirmAssociations: {
            securityFirmId: { $set: securityFirmId }
        }
    });
    return newState;
}

export default function securityFirmAssociations(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

            // venue associations

        case SECURITY_FIRM_ASSOCIATIONS_REQUEST:
            return setVenueSecurityFirms(
                setVenueSecurityFirmIds(
                    loadingVenueSecurityFirms(state, true),
                    action.forVenueId,
                    action.forSecurityFirmId
                ),
                [], { pageSize: action.pageSize, page: action.page });
        case SECURITY_FIRM_ASSOCIATIONS_REQUEST_SUCCESS:
            return setVenueSecurityFirms(loadingVenueSecurityFirms(state, false), action.venueSecurityFirms, action.paging);
        case SECURITY_FIRM_ASSOCIATIONS_REQUEST_FAILURE:
            return loadingVenueSecurityFirms(state, false);

        case SECURITY_FIRM_ASSOCIATION_RESET:
            return setVenueSecurityFirmOptions(setVenueSecurityFirm(state, { venueSecurityFirmId: -1, securityFirmId: 0 }), []);
        case SECURITY_FIRM_ASSOCIATION_SET:
            return setVenueSecurityFirm(state, action.venueSecurityFirm);
        case SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST:
            return loadingVenueSecurityFirm(state, true, "");
        case SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS:
            return setVenueSecurityFirm(
                setVenueSecurityFirmOptions(
                    loadingVenueSecurityFirm(state, false, "")
                    , action.securityFirms)
                , action.venueSecurityFirm);
        case SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_FAILURE:
            return loadingVenueSecurityFirm(state, false, action.error);
        case SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST:
            return savingVenueSecurityFirm(state, true, "");
        case SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS:
            return savingVenueSecurityFirm(state, false, "");
        case SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_FAILURE:
            return savingVenueSecurityFirm(state, false, action.error);
        case SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST:
            return savingVenueSecurityFirm(state, true, "");
        case SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS:
            return savingVenueSecurityFirm(state, false, "");
        case SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE:
            return savingVenueSecurityFirm(state, false, action.error);

            // security firm sub associations

        case SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST:
            return setSecurityFirmAssociations(
                setSecurityFirmAssociationIds(
                    loadingSecurityFirmAssociations(state, true),
                    action.forSecurityFirmId
                ),
                [], { pageSize: action.pageSize, page: action.page });
        case SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_SUCCESS:
            return setSecurityFirmAssociations(loadingSecurityFirmAssociations(state, false), action.securityFirmAssociations, action.paging);
        case SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_FAILURE:
            return loadingSecurityFirmAssociations(state, false);

        case SECURITY_FIRM_SUB_ASSOCIATION_RESET:
            return setSecurityFirmAssociationOptions(setSecurityFirmAssociation(state, { securityFirmAssociationId: -1, securityFirmId: 0 }), []);
        case SECURITY_FIRM_SUB_ASSOCIATION_SET:
            return setSecurityFirmAssociation(state, action.securityFirmAssociation);
        case SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST:
            return loadingSecurityFirmAssociation(state, true, "");
        case SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_SUCCESS:
            return setSecurityFirmAssociation(
                setSecurityFirmAssociationOptions(
                    loadingSecurityFirmAssociation(state, false, "")
                    , action.securityFirms)
                , action.securityFirmAssociation);
        case SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_FAILURE:
            return loadingSecurityFirmAssociation(state, false, action.error);
        case SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST:
            return savingSecurityFirmAssociation(state, true, "");
        case SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS:
            return savingSecurityFirmAssociation(state, false, "");
        case SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_FAILURE:
            return savingSecurityFirmAssociation(state, false, action.error);
        case SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST:
            return savingSecurityFirmAssociation(state, true, "");
        case SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS:
            return savingSecurityFirmAssociation(state, false, "");
        case SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_FAILURE:
            return savingSecurityFirmAssociation(state, false, action.error);
    }

    return state;
}