import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import MultiSelectControl from '../../controls/MutiSelectControl';
import { MulitSelectOption } from '../../../common/dto/common';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import MultiRadioChipControl from '../../controls/MultiRadioChipControl';

export interface ICommLogFunctionalAreaProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    showGPSLocation?: boolean;
    theme: any;
    classes: any;
}

interface ICommLogFunctionalAreaState {
    items: MulitSelectOption[];
    //selectedVenueLocationIds: number[];
}

class CommLogFunctionalArea extends Component<ICommLogFunctionalAreaProps, ICommLogFunctionalAreaState> {
    constructor(props: ICommLogFunctionalAreaProps) {
        super(props)
        this.state = {
            items: [],
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onLoadItems = this.onLoadItems.bind(this);
        this.onSelectedChanged = this.onSelectedChanged.bind(this);
    }

    componentDidMount() {
        this.onLoadItems();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.incident.selectedFunctionalAreas) !== JSON.stringify(preProps.incident.selectedFunctionalAreas) ||
            JSON.stringify(this.props.incident.selectedRadioChannels) !== JSON.stringify(preProps.incident.selectedRadioChannels)) {
            this.onLoadItems();
        }
    }

    onLoadItems() {
        try {
            let items: MulitSelectOption[] = [];
            let selected: number[] = [];
            let availableIds: number[] = [];
            if (this.props.incident && this.props.incident.selectedFunctionalAreas) {
                selected = this.props.incident.selectedFunctionalAreas.map(item => item);
            }
            if (this.props.incidentOptions && this.props.incidentOptions.radioChannels && this.props.incident.selectedRadioChannels) {
                this.props.incidentOptions.radioChannels.filter(f => this.props.incident.selectedRadioChannels.indexOf(f.radioChannelId) > -1)
                    .forEach((radioChannel, radioChannelIndex) => {
                        radioChannel.functionalAreas.filter(f => radioChannel.selectedFunctionalAreas.indexOf(f.functionalAreaId) > -1 && availableIds.indexOf(f.functionalAreaId) === -1).forEach((item, index) => {
                            let isSelected = selected.indexOf(item.functionalAreaId) > -1;
                            items.push({
                                id: item.functionalAreaId,
                                name: item.name,
                                isCommon: false,
                                selected: isSelected
                            });
                            availableIds.push(item.functionalAreaId);
                        });
                    });
            }
            items = items.sort((a, b) => {
                return ('' + a.name).localeCompare(b.name);
            });
            this.setState({
                items: items,
            });
        } catch (err) {
            commonLogic.trackException(err, "CommLogFunctionalArea.componentDidMount", {})
        }
    }

    onSelectItemsChanged(items) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedFunctionalAreas = items.map(item => item.id);;
        this.props.onUpdateIncident(newIncident)
    }

    onSelectedChanged(values: number[]) {
        let newIncident = { ...this.props.incident };
        newIncident.selectedFunctionalAreas = values;
        this.props.onUpdateIncident(newIncident)
    }

    render() {

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                
                <MultiRadioChipControl
                    wrapInCard={true}
                    cardTitle={"Functional Areas"}
                    selected={this.props.incident.selectedFunctionalAreas}
                    options={this.state.items}
                    hideIfNoOptions={true}
                    onChange={this.onSelectedChanged}
                />
            </SwipeContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CommLogFunctionalArea);