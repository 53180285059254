import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import IncidentActionTemplates from '../components/incidentActions/incidentActionTemplates/IncidentActionTemplates'
import * as incidentActionTemplateActions from '../common/actions/incidentActionTemplates';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        groupId: undefined,
        filter: state.incidentActionTemplates.incidentActionTemplates.filter,
        isLoading: state.incidentActionTemplates.incidentActionTemplates.isLoading,
        isSaving: state.incidentActionTemplates.incidentActionTemplate.isSaving,
        incidentActionTemplates: state.incidentActionTemplates.incidentActionTemplates.incidentActionTemplates,
        canEdit: permissionsLogic.hasPermissionForState(state, "ManageIncidentActions"),
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        isLoadingItem: state.incidentActionTemplates.incidentActionTemplate.isLoading,
        showFunctionalAreas: permissionsLogic.hasPermissionForState(state, "FunctionalAreas"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplatesSetFilter(filter))
        },
        onSet: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateSet(incidentActionTemplate))
        },
        onUpdate: () => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateUpsertRequest())
        },
        onReset: () => {
            dispatch(incidentActionTemplateActions.incidentActionTemplatesReset())
        },
        onLoadItem: (checklistTemplateId) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateRequest(checklistTemplateId))
        },
        onClone: (incidentActionTemplateId, forGroupId, forVenueId) => {
            dispatch(incidentActionTemplateActions.incidentActionTemplateCloneRequest(incidentActionTemplateId, forGroupId, forVenueId))
        }
    }
}

const IncidentActionTemplatesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentActionTemplates))

export default IncidentActionTemplatesContainer