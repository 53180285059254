import React from 'react';

const VenueIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path fill={fill} d="M29.71,39.87c-1.41,1.85-2.97,3.91-4.71,6.23c-1.76-2.33-3.32-4.4-4.74-6.27c-9.64-12.71-11.38-15-11.38-20.79c0-8.88,7.23-16.11,16.11-16.11s16.11,7.23,16.11,16.11C41.11,24.83,39.37,27.12,29.71,39.87 M25,0C14.5,0,5.96,8.54,5.96,19.04c0,6.78,2.06,9.5,11.97,22.56c1.72,2.27,3.67,4.84,5.9,7.81C24.1,49.78,24.54,50,25,50c0.46,0,0.9-0.22,1.17-0.59c2.22-2.96,4.16-5.52,5.87-7.78c9.93-13.1,12-15.83,12-22.59C44.04,8.54,35.5,0,25,0" />
            <path fill={fill} d="M25,26.37c-4.04,0-7.32-3.29-7.32-7.33c0-4.04,3.29-7.32,7.32-7.32s7.32,3.29,7.32,7.32C32.32,23.08,29.04,26.37,25,26.37 M25,8.79c-5.65,0-10.25,4.6-10.25,10.25c0,5.65,4.6,10.25,10.25,10.25s10.25-4.6,10.25-10.25C35.25,13.39,30.65,8.79,25,8.79" />
        </g>
    </svg>
);

export default VenueIcon;


