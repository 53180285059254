
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const userComplianceDocumentLogic = {
    getExpiredCommands(userComplianceDocumentSummary: AusComplyDtos.UserComplianceDocumentSummary){
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit User" });
        return commands;
    },
    getMissingCommands(userComplianceDocumentMissingSummary: AusComplyDtos.UserComplianceDocumentMissingSummary) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit User" });
        return commands;
    }
}