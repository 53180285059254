import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as facialRecognitionNotificationActions from "../actions/facialRecognitionNotification";

const initialState = {
    isLoading: false,
    error: "",
    facialRecognitionNotifications: [],
    facialRecognitionNotification: {
        isLoading: false,
        facialRecognitionNotification: { facialRecognitionNotificationId: -1 }
    },
    paging: { pageSize: 0, page: 1 },
    filter: { search: "", showObsolete: false },
    facialRecognitionEvents: {
        isLoading: false,
        isSaving: false,
        facialRecognitionEvents: [],
        facialRecognitionCameras: [],
        facialRecognitionWatchlists: [],
        incidentTypes: [],
        incidentTypeCategoryTypes: [],
        incidentFlagTypes: [],
        facialRecognitionEvent: { facialRecognitionEventId: -1 },
        defaultNotificationTrigger: [],
    },
    facialRecognitionCameras: {
        isLoading: false,
        isSaving: false,
        facialRecognitionCameras: [],
        facialRecognitionWatchlists: [],
        venueLocations: [],
        facialRecognitionServers: [],
        facialRecognitionCamera: {
            facialRecognitionCameraId: -1
        }
    },
    facialRecognitionWatchlistTypes: {
        isLoading: false,
        filter: {},
        facialRecognitionWatchlistTypes: [],
        paging: { pageSize: 0, page: 1 }
    },
    facialRecognitionWatchlistType: {
        facialRecognitionWatchlistTypeId: -1,
        isLoading: false,
        isSaving: false,
        facialRecognitionWatchlistType: { facialRecognitionWatchlistTypeId: 0 },
    },
    facialRecognitionCameraImports: {
        isLoading: false,
        isSaving: false,
        isSavedSuccessful: false,
        facialRecognitionCameraImports: []
    },
};


function loadingFacialRecognitionNotifications(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setFacialRecognitionNotificationFilter(state, filter) {
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setFacialRecognitionNotificationPaging(state, paging) {
    let newState = update(state, {
        paging: { $set: paging }
    });
    return newState;
}

function setFacialRecognitionNotificationError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setFacialRecognitionNotifications(state, facialRecognitionNotifications) {
    let newState = update(state, {
        facialRecognitionNotifications: { $set: facialRecognitionNotifications }
    });
    return newState;
}

function setFacialRecognitionNotification(state, facialRecognitionNotification) {
    let newState = update(state, {
        facialRecognitionNotification: {
            facialRecognitionNotification: { $set: facialRecognitionNotification }
        }
    });
    return newState;
}

function setFacialRecognitionNotificationIsLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionNotification: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function loadingFacialRecognitionEvents(state, isLoading) {
    let newState = update(state, {
        facialRecognitionEvents: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingFacialRecognitionEvents(state, isSaving) {
    let newState = update(state, {
        facialRecognitionEvents: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function movingFacialRecognitionEventItem(state, facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent, position: number, isSaving: boolean) {
    let itemToInsert = facialRecognitionEvent;
    let currentFacialRecognitionEvents: AusComplyDtos.FacialRecognitionEvent[] = [];
    currentFacialRecognitionEvents = [...state.facialRecognitionEvents.facialRecognitionEvents]

    // Moving towards the top
    if (itemToInsert.processRank > position) {
        currentFacialRecognitionEvents.map((e, idx) => {
            if (e.processRank < itemToInsert.processRank && e.processRank >= position) {
                e.processRank++;
            }
        });
        itemToInsert.processRank = position;
    // Moving towards the back
    } else if (itemToInsert.processRank < position) {
        currentFacialRecognitionEvents.map((e, idx) => {
            if (e.processRank > itemToInsert.processRank && e.processRank <= position) {
                e.processRank--;
            }
        });
        itemToInsert.processRank = position;
    }

    let eventsListState = update(state.facialRecognitionEvents, {
        facialRecognitionEvents: { $set: currentFacialRecognitionEvents },
        isSaving: { $set: isSaving } 
    });

    let newState = update(state, {
        facialRecognitionEvents: { $set: eventsListState },
    });
    return newState;
}

function removeItem(items: AusComplyDtos.FacialRecognitionEvent[], item: AusComplyDtos.FacialRecognitionEvent) {
    for (let index = 0; index < items.length; index++) {
        if (items[index].processRank == item.processRank) {
            items.splice(index, 1);
            return;
        }
    }
}

function setFacialRecognitionEvents(state, facialRecognitionEvents) {
    let newState = update(state, {
        facialRecognitionEvents: {
            facialRecognitionEvents: { $set: facialRecognitionEvents }
        }
    });
    return newState;
}

function setFacialRecognitionEventLists(state,
    facialRecognitionCameras,
    facialRecognitionWatchlists,
    incidentTypes,
    incidentTypeCategoryTypes,
    incidentFlagTypes,
    userRoleTypes,
    defaultNotificationTrigger) {
    let newState = update(state, {
        facialRecognitionEvents: {
            facialRecognitionCameras: { $set: facialRecognitionCameras },
            facialRecognitionWatchlists: { $set: facialRecognitionWatchlists },
            incidentTypes: { $set: incidentTypes },
            incidentTypeCategoryTypes: { $set: incidentTypeCategoryTypes },
            incidentFlagTypes: { $set: incidentFlagTypes },
            userRoleTypes: { $set: userRoleTypes },
            defaultNotificationTrigger: { $set: defaultNotificationTrigger}
        }
    });
    return newState;
}

function setFacialRecognitionEvent(state, facialRecognitionEvent) {
    let newState = update(state, {
        facialRecognitionEvents: {
            facialRecognitionEvent: { $set: facialRecognitionEvent }
        }
    });
    return newState;
}

function loadingFacialRecognitionCameras(state, isLoading) {
    let newState = update(state, {
        facialRecognitionCameras: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function savingFacialRecognitionCameras(state, isSaving) {
    let newState = update(state, {
        facialRecognitionCameras: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFacialRecognitionCameras(state, facialRecognitionCameras) {
    let newState = update(state, {
        facialRecognitionCameras: {
            facialRecognitionCameras: { $set: facialRecognitionCameras }
        }
    });
    return newState;
}

function setFacialRecognitionCameraWatchlists(state, facialRecognitionWatchlists) {
    let newState = update(state, {
        facialRecognitionCameras: {
            facialRecognitionWatchlists: { $set: facialRecognitionWatchlists }
        }
    });
    return newState;
}

function setFacialRecognitionVenueLocations(state, venueLocations) {
    let newState = update(state, {
        facialRecognitionCameras: {
            venueLocations: { $set: venueLocations }
        }
    });
    return newState;
}

function setFacialRecognitionFacialRecognitionServers(state, facialRecognitionServers) {
    let newState = update(state, {
        facialRecognitionCameras: {
            facialRecognitionServers: { $set: facialRecognitionServers }
        }
    });
    return newState;
}

function setFacialRecognitionCamera(state, facialRecognitionCamera) {
    let newState = update(state, {
        facialRecognitionCameras: {
            facialRecognitionCamera: { $set: facialRecognitionCamera }
        }
    });
    return newState;
}

function loadingFacialRecognitionWatchlistTypes(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistTypes: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistTypes(state, facialRecognitionWatchlistTypes, filter, paging) {
    let newState = update(state, {
        facialRecognitionWatchlistTypes: {
            facialRecognitionWatchlistTypes: { $set: facialRecognitionWatchlistTypes },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingFacialRecognitionWatchlistType(state, isLoading) {
    let newState = update(state, {
        facialRecognitionWatchlistType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistType(state, facialRecognitionWatchlistType) {
    let newState = update(state, {
        facialRecognitionWatchlistType: {
            facialRecognitionWatchlistType: { $set: facialRecognitionWatchlistType }
        }
    });
    return newState;
}

function savingFacialRecognitionWatchlistType(state, isSaving) {
    let newState = update(state, {
        facialRecognitionWatchlistType: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setFacialRecognitionWatchlistTypeId(state, facialRecognitionWatchlistTypeId) {
    let newState = update(state, {
        facialRecognitionWatchlistType: {
            facialRecognitionWatchlistTypeId: { $set: facialRecognitionWatchlistTypeId }
        }
    });
    return newState;
}

function setIsImportsLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionCameraImports: {
            isLoading: { $set: isLoading },
            isSavedSuccessful: { $set: false },
        }
    });
    return newState;
}

function setImports(state, facialRecognitionCameraImports) {
    let newState = update(state, {
        facialRecognitionCameraImports: {
            facialRecognitionCameraImports: { $set: facialRecognitionCameraImports }
        }
    });
    return newState;
}

function setIsImportsSaving(state, isSaving) {
    let newState = update(state, {
        facialRecognitionCameraImports: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setIsImportsSaveSuccess(state, facialRecognitionCameraImports: AusComplyDtos.FacialRecognitionCameraImport[]) {
    var isSavedSuccessful = facialRecognitionCameraImports.filter(f => f.selected && !f.success).length == 0;
    let newState = update(state, {
        facialRecognitionCameraImports: {
            isSavedSuccessful: { $set: isSavedSuccessful }
        }
    });
    return newState;
}

export default function facialRecognitionNotification(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST:
            return loadingFacialRecognitionNotifications(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE:
            return loadingFacialRecognitionNotifications(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS:
            return loadingFacialRecognitionNotifications(setFacialRecognitionNotificationFilter(state, action.filter), false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER:
            return setFacialRecognitionNotificationFilter(state, action.filter);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING:
            return setFacialRecognitionNotificationPaging(state, action.paging);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM:
            return setFacialRecognitionNotification(state, action.facialRecognitionNotification);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_REQUEST:
            return loadingFacialRecognitionNotifications(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE:
            return loadingFacialRecognitionNotifications(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS:
            return loadingFacialRecognitionNotifications(
                setFacialRecognitionNotifications(
                    setFacialRecognitionNotificationPaging(
                        setFacialRecognitionNotificationFilter(state, action.filter)
                        , action.paging),
                    action.facialRecognitionNotifications)
                , false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, true), { facialRecognitionNotificationId: 0 });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, false), action.facialRecognitionNotification);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, false), { facialRecognitionNotificationId: -1 });

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_SET:
            return setFacialRecognitionEvent(state, action.facialRecognitionEvent);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST:
            return setFacialRecognitionEvent(loadingFacialRecognitionEvents(state, true), { facialRecognitionEventId: -1 });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_SUCCESS:
            return setFacialRecognitionEventLists(
                setFacialRecognitionEvents(loadingFacialRecognitionEvents(state, false), action.facialRecognitionEvents),
                action.facialRecognitionCameras,
                action.facialRecognitionWatchlists,
                action.incidentTypes,
                action.incidentTypeCategoryTypes,
                action.incidentFlagTypes,
                action.userRoleTypes,
                action.defaultNotificationTrigger);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_FAILURE:
            return setFacialRecognitionEvent(loadingFacialRecognitionEvents(state, false), { facialRecognitionEventId: -1 });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST:
            return savingFacialRecognitionEvents(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_SUCCESS:
            return setFacialRecognitionEvent(setFacialRecognitionEvents(savingFacialRecognitionEvents(state, false), action.facialRecognitionEvents), { facialRecognitionEventId: -1 });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionEvents(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM:
            return movingFacialRecognitionEventItem(state, action.facialRecognitionEvent, action.position, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_SUCCESS:
            return savingFacialRecognitionEvents(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionEvents(state, false);

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SET:
            return setFacialRecognitionCameras(state, action.facialRecognitionCameras);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST:
            return setFacialRecognitionCameras(loadingFacialRecognitionCameras(state, true), []);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_SUCCESS:
            return setFacialRecognitionCameraWatchlists(
                setFacialRecognitionFacialRecognitionServers(
                    setFacialRecognitionVenueLocations(
                        setFacialRecognitionCameras(
                            loadingFacialRecognitionCameras(state, false),
                            action.facialRecognitionCameras),
                        action.venueLocations),
                    action.facialRecognitionServers),
                action.facialRecognitionWatchlists);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_FAILURE:
            return setFacialRecognitionCameras(loadingFacialRecognitionCameras(state, false), []);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST:
            return savingFacialRecognitionCameras(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_SUCCESS:
            return setFacialRecognitionCameras(savingFacialRecognitionCameras(state, false), action.facialRecognitionCameras);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionCameras(state, false);

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_CREATE:
            return setFacialRecognitionCamera(state, { facialRecognitionCameraId: 0, venueId: action.forVenueId });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SET:
            return setFacialRecognitionCamera(state, action.facialRecognitionCamera);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_RESET:
            return setFacialRecognitionCamera(state, { facialRecognitionCameraId: -1 });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST:
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST:
            return savingFacialRecognitionCameras(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_SUCCESS:
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_SUCCESS:
            return setFacialRecognitionCameras(setFacialRecognitionCamera(savingFacialRecognitionCameras(state, false), { facialRecognitionCameraId: -1 }), action.facialRecognitionCameras);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_FAILURE:
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_FAILURE:
            return savingFacialRecognitionCameras(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST:
            return savingFacialRecognitionCameras(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_FAILURE:
            return savingFacialRecognitionCameras(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_SUCCESS:
            return setFacialRecognitionCameras(savingFacialRecognitionCameras(state, false), action.facialRecognitionCameras);

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH:
            return loadingFacialRecognitionWatchlistTypes(setFacialRecognitionWatchlistTypeId(state, -1), true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST:
            return setFacialRecognitionWatchlistTypes(loadingFacialRecognitionWatchlistTypes(setFacialRecognitionWatchlistTypeId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlistTypes(loadingFacialRecognitionWatchlistTypes(state, false), action.facialRecognitionNotificationWatchlistTypes, action.filter, action.paging);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_FAILURE:
            return loadingFacialRecognitionWatchlistTypes(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_RESET:
            return setFacialRecognitionWatchlistTypeId(state, -1);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST:
            return setFacialRecognitionWatchlistType(loadingFacialRecognitionWatchlistType(setFacialRecognitionWatchlistTypeId(state, action.facialRecognitionNotificationWatchlistTypeId), true), {});
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlistType(loadingFacialRecognitionWatchlistType(state, false), action.facialRecognitionNotificationWatchlistType);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_FAILURE:
            return loadingFacialRecognitionWatchlistType(setFacialRecognitionWatchlistTypeId(state, -1), false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST:
            return setFacialRecognitionWatchlistType(loadingFacialRecognitionWatchlistType(setFacialRecognitionWatchlistTypeId(state, 0), true), {});;
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlistType(loadingFacialRecognitionWatchlistType(state, false), action.facialRecognitionNotificationWatchlistType);;
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_FAILURE:
            return loadingFacialRecognitionWatchlistType(setFacialRecognitionWatchlistTypeId(state, -1), false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST:
            return savingFacialRecognitionWatchlistType(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS:
            return setFacialRecognitionWatchlistType(savingFacialRecognitionWatchlistType(setFacialRecognitionWatchlistTypeId(state, -1), false), action.facialRecognitionNotificationWatchlistType);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_FAILURE:
            return savingFacialRecognitionWatchlistType(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_SET:
            return setFacialRecognitionWatchlistType(state, action.facialRecognitionNotificationWatchlistType);
            

        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST:
            return setImports(setIsImportsLoading(state, true), []);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_SUCCESS:
            return setImports(setIsImportsLoading(state, false), action.facialRecognitionCameraImports);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_FAILURE:
            return setImports(setIsImportsLoading(state, false), []);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST:
            return setIsImportsSaving(state, true);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_SUCCESS:
            return setIsImportsSaving(setIsImportsSaveSuccess(setImports(state, action.facialRecognitionCameraImports), action.facialRecognitionCameraImports), false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_FAILURE:
            return setIsImportsSaving(state, false);
        case facialRecognitionNotificationActions.FACIAL_RECOGNITION_CAMERA_IMPORT_SET:
            return setImports(state, action.facialRecognitionCameraImports);
    }

    return state;
}