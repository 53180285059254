import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IUserRoleHeaderProps {
    classes: any;
    theme: any;
}

class UserRoleHeader extends React.Component<IUserRoleHeaderProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr}>Venue</th>
                <th className={classes.tableHr}>Security Firm</th>
                <th className={classes.tableHr}>First Name</th>
                <th className={classes.tableHr}>Last Name</th>
                <th className={classes.tableHr}>Nickname</th>
                <th className={classes.tableHr}>Mobile</th>
                <th className={classes.tableHr}>Type</th>
                <th className={classes.tableHr}>Access Level</th>
                <th className={classes.tableHr}>Status</th>
                <th className={classes.tableHrCentered}>Temporary</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleHeader);