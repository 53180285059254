import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RiskStatus from '../components/facility/correctionalFacilityRiskStatus/RiskStatus';
import {
    correctionalFacilityRiskStatusReset,
    correctionalFacilityRiskStatusSet,
    correctionalFacilityRiskStatusSaveRequest
} from '../common/actions/correctionalFacility';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.correctionalFacility.riskStatus.isLoading,
        isSaving: state.correctionalFacility.riskStatus.isSaving,
        riskStatus: state.correctionalFacility.riskStatus.riskStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(correctionalFacilityRiskStatusReset());
        },
        onUpdate: (riskStatus) => {
            dispatch(correctionalFacilityRiskStatusSet(riskStatus));
        },
        onSave: () => {
            dispatch(correctionalFacilityRiskStatusSaveRequest());
        }
    }
}

const FacilityRiskStatusContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RiskStatus))

export default FacilityRiskStatusContainer;
