import update from "immutability-helper";
import { LocalStorage } from '../../utilities/storage';
import {
    VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST,
    VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_FAILURE,
    VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE,
    VENUE_SECURITYFIRM_AVAILABLE_CLEAR_VENUE,
    VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST,
    VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_SUCCESS,
    VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_FAILURE,
    VENUE_SECURITYFIRM_SELECT
} from "../actions/venueSecurityFirm";

const initialState = {
    venueId: 0,
    venueEventId: 0,
    securityFirmId: 0,
    inRange: true,
    isLoading: true,
    isSaving: true,
    canSelectNoVenue: false,
    venues: [],
    securityFirms: [],
    statistics: {},
};

function loadingVenues(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function updateVenueList(state, venues, canSelectNoVenue) {
    let newState = update(state, {
        venues: { $set: venues },
        venueId: { $set: 0 },
        venueEventId: { $set: 0},
        canSelectNoVenue: { $set: canSelectNoVenue }
    });
    return newState;
}

function updateSecurityFirmList(state, securityFirms) {
    let newState = update(state, {
        securityFirms: { $set: securityFirms },
        securityFirmId: { $set: 0 }
    });
    return newState;
}

function venueSelected(state, venue) {
    let newState = update(state, {
        venueId: { $set: venue.venueId },
        venueEventId: { $set: venue.venueEventId}
    });
    return newState;
}

function venueAndSecuirityFirmSelected(state, venueId, venueEventId, securityFirmId) {
    let meta = JSON.parse(LocalStorage.get("meta"));
    if (meta) {
        meta['venueid'] = venueId;
        meta['securityfirmid'] = securityFirmId;
        meta['venueEventId'] = venueEventId;
        try {
            LocalStorage.set("meta", JSON.stringify(meta));
        } catch (error) {
            
        }
    }
    return state;
}

export default function userSession(state = initialState, action) {
    switch (action.type) {
        case VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST:
            return loadingVenues(state, true);
        case VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_SUCCESS:
            return loadingVenues(updateVenueList(state, action.venues, action.canSelectNoVenue), false);
        case VENUE_SECURITYFIRM_AVAILABLE_VENUES_REQUEST_FAILURE:
            return loadingVenues(state, false);
        case VENUE_SECURITYFIRM_AVAILABLE_SELECT_VENUE:
            return venueSelected(state, action.venue);
        case VENUE_SECURITYFIRM_AVAILABLE_CLEAR_VENUE:
            return venueSelected(state, {venueId: 0});
        case VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST:
            return loadingVenues(state, true);
        case VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_SUCCESS:
            return loadingVenues(updateSecurityFirmList(state, action.securityFirms), false);
        case VENUE_SECURITYFIRM_AVAILABLE_SECURITY_FIRMS_REQUEST_FAILURE:
            return loadingVenues(state, false);
        case VENUE_SECURITYFIRM_SELECT:
            return venueAndSecuirityFirmSelected(state, action.venueId, action.venueEventId, action.securityFirmId);
    }

    return state;
}