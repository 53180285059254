import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import SubTitle from '../../common/SubTitle';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';
import PrimaryButton from '../../controls/PrimaryButton';
import Grid from '@material-ui/core/Grid';
import UserRolesSuspendContainer from '../../../containers/UserRolesSuspendContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import NotesContainer from '../../../containers/NotesContainer';

export interface ISecurityFirmSubscriptionProps {
    securityFirmId: number;
    isLoading: boolean;
    isSaving: boolean;
    securityFirmSubscription: AusComplyDtos.SecurityFirmSubscription;
    error: string;
    onLoad: Function;
    onUpdate: Function;
    onSave: Function;
    history: any;
}

export default class SecurityFirmSubscription extends Component<ISecurityFirmSubscriptionProps, any> {
    constructor(props: ISecurityFirmSubscriptionProps) {
        super(props)
        this.state = {
        };
        this.onUpdate = this.onUpdate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId);
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                this.props.onLoad(this.props.securityFirmId);
            }
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let securityFirmSubscription = { ...this.props.securityFirmSubscription };
        securityFirmSubscription[fieldName] = value;
        this.props.onUpdate(securityFirmSubscription);
    }

    onUpdate(securityFirmSubscription) {
        this.props.onUpdate(securityFirmSubscription);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onLoad(this.props.securityFirmId);
    }

    render() {
        let self = this;
        let userRolesSuspendFab;
        let userRolesUndoSuspendFab;
        if (this.props.securityFirmSubscription) {
            if (this.props.securityFirmSubscription.suspendUserRoles) {
                userRolesSuspendFab = <UserRolesSuspendContainer securityFirmId={this.props.securityFirmId} undo={false} onSaved={() => self.props.onLoad(self.props.securityFirmId)} />
            }
            if (this.props.securityFirmSubscription.unsuspendUserRoles) {
                userRolesUndoSuspendFab = <UserRolesSuspendContainer securityFirmId={this.props.securityFirmId} undo={true} onSaved={() => self.props.onLoad(self.props.securityFirmId)} />
            }
        }
        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    fullWidth={true}
                >
                    <form>
                        <ControlGroup text="ID">
                            <TextDisplay>{this.props.securityFirmSubscription.securityFirmId}</TextDisplay>
                        </ControlGroup>
                        {this.props.securityFirmSubscription.dateEntered && !this.props.securityFirmSubscription.dateEntered.isEmpty && (
                            <ControlGroup text="Creation Date">
                                <TextDisplay>{this.props.securityFirmSubscription.dateEntered.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        <ControlGroup text="Direct Debit">
                            <PrimaryButton
                                component={forwardRef((props, ref) => (
                                    <a
                                        href={this.props.securityFirmSubscription.paywayUrl}
                                        rel="noreferrer"
                                        target="_blank"
                                        {...props}
                                    />
                                ))}>Enter Details</PrimaryButton>
                        </ControlGroup>
                        <CheckboxControlGroup
                            text="Active"
                            defaultValue={this.props.securityFirmSubscription.active}
                            label={" "}
                            onChanged={(value) => this.onValueChanged("active", value)} />
                        {!this.props.securityFirmSubscription.active && (
                            <SimpleDateControlGroup
                                text="Cut Off Date"
                                date={this.props.securityFirmSubscription.cutOffDate}
                                onChange={(value) => this.onValueChanged("cutOffDate", value)} />
                        )}
                        <ControlGroup text=" ">
                            <NotesContainer entityType={AusComplyDtos.ngtEntityType.SecurityFirmSubscription} entityId={this.props.securityFirmSubscription.securityFirmId} wrapInCard={true} />
                        </ControlGroup>
                        <SubTitle text={"Subscription Features"} />
                        <CheckboxControlGroup text="Checklists"
                            defaultValue={this.props.securityFirmSubscription.checklists}
                            onChanged={(value) => this.onValueChanged("checklists", value)}
                            label={" "} />
                        <CheckboxControlGroup text="Can View Associated Sign-on Register"
                            defaultValue={this.props.securityFirmSubscription.canViewAssociatedSignOnRegister}
                            onChanged={(value) => this.onValueChanged("canViewAssociatedSignOnRegister", value)}
                            label={" "} />
                        {false &&
                            <CheckboxControlGroup text="Events"
                                defaultValue={this.props.securityFirmSubscription.eventVenues}
                                onChanged={(value) => this.onValueChanged("eventVenues", value)}
                                label={" "} />
                        }
                        <CheckboxControlGroup text="Role Type Notifications"
                            defaultValue={this.props.securityFirmSubscription.roleTypeNotifications}
                            onChanged={(value) => this.onValueChanged("roleTypeNotifications", value)}
                            label={" "} />
                        <CheckboxControlGroup text="Notebook"
                            defaultValue={this.props.securityFirmSubscription.notebook}
                            onChanged={(value) => this.onValueChanged("notebook", value)}
                            label={" "} />
                        <ControlGroup text={" "}>
                            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                                <Grid item xs={6}>
                                    <TransparentButton text={"Cancel"} onClick={this.onCancel} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton onClick={() => this.onSave()}>Save</DefaultButton>
                                </Grid>
                            </Grid>
                        </ControlGroup>
                    </form>
                </LayoutForm>
                <VenueQuickActionsContainer>
                    {userRolesSuspendFab}
                    {userRolesUndoSuspendFab}
                </VenueQuickActionsContainer>
            </>
        );
    }
}