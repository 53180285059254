import { connect } from 'react-redux'
import VenueSecurityCode from '../components/home/VenueSecurityCode'
import { userLogIntoVenueRequest } from '../common/actions/userLogin';

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoginToVenue: (code) => {
            dispatch(userLogIntoVenueRequest(code))
        },
    }
}

const VenueSecurityCodeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueSecurityCode)

export default VenueSecurityCodeContainer