import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IDotBadgeProps {
    count: number;
    theme: any;
    classes: any;
}

class DotBadge extends Component<IDotBadgeProps, any> {

    render() {
        const { classes } = this.props;
        if (this.props.count === 0) {
            return (<>
                {this.props.children}
            </>)
        }
        let badgeStyle = {
            margin: '0'//,
            //width: '100%'
        };
        return (
            <Badge color="error" variant="dot" style={badgeStyle} classes={{ badge: classes.badge }}>
                {this.props.children}
            </Badge>
        )
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(DotBadge))