import React, { Component } from 'react';
import { styles } from '../../../styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import View from '../../common/View';
import PageTitle from '../../common/PageTitle';
import NewCheckListSelectTemplateContainer from '../../../containers/NewCheckListSelectTemplateContainer';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import SecondaryFab from '../../common/SecondaryFab';
import ErrorBoundary from '../../common/ErrorBoundary';
import ChecklistTemplateCard from './ChecklistTemplateCard';
import ChecklistTemplateRow from './ChecklistTemplateRow';
import ChecklistTemplateHeader from './ChecklistTemplateHeader';
import CheckListLegend from '../CheckListLegend';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import ChecklistTemplateFilter from './ChecklistTemplateFilter';
import ChecklistTemplateFilterSummary from './ChecklistTemplateFilterSummary';
import ConfirmDialog from '../../controls/ConfirmDialog';
import ChecklistTemplatesLegend from './ChecklistTemplatesLegend';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';


export interface ICheckListTemplatesProps {
    isLoading: boolean;
    isSaving: boolean;
    isCloning: boolean,
    clonedId: number,
    isAdmin: boolean;
    checklistTemplates: AusComplyDtos.ChecklistTemplateSummary[];
    checklistTemplateCategories: AusComplyDtos.ChecklistTemplateCategory[];
    industryCategories: AusComplyDtos.IndustryCategory[];
    venues: AusComplyDtos.NamedValue[];
    securityFirms: AusComplyDtos.NamedValue[];
    states: AusComplyDtos.State[];
    filter: AusComplyDtos.ChecklistTemplateFilter;
    paging: AusComplyDtos.Paging;
    canAdd: boolean;
    canEdit: boolean;
    canEditGlobal: boolean;
    canClone: boolean;
    onLoad: Function;
    onPage: Function;
    onFilterUpdate: Function;
    onFilterReset: Function;
    onLoadChecklistTemplateCategories: Function;
    onChecklistTemplateReset: Function;
    onClone: Function;
    onArchive: Function;
    onArchiveTemplate: Function;
    history: any;
    location: any;
}

interface ICheckListTemplatesState {
    filterExpanded: boolean;
}

class CheckListTemplates extends Component<ICheckListTemplatesProps, ICheckListTemplatesState> {

    constructor(props: ICheckListTemplatesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        }
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.createChecklistTemplate = this.createChecklistTemplate.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        if (this.props.filter && (this.props.filter.checklistTemplateFilterId == -1 || this.props.filter.isAdmin != this.props.isAdmin)) {
            this.props.onFilterReset();
        } else {
            this.props.onLoad();
        }
        if (this.props.onChecklistTemplateReset) {
            this.props.onChecklistTemplateReset();
        }
        if (this.props.onLoadChecklistTemplateCategories) {
            this.props.onLoadChecklistTemplateCategories();
        }
    }

    componentDidUpdate(preProps) {
        if (preProps.isCloning && !this.props.isCloning && this.props.clonedId > 0) {
            this.props.history.push(this.props.location.pathname + "/" + this.props.clonedId);
        }
    }

    handlePageSelected(page) {
        this.props.onPage(page);
    }

    onCommand(command: string, checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary) {
        switch (command) {
            case "edit":
                this.props.history.push(this.props.location.pathname + "/" + checklistTemplateSummary.checklistTemplateId);
                break;
            case "clone":
                this.props.onClone(checklistTemplateSummary.checklistTemplateId, this.props.isAdmin);
                break;
            case "restore":
                this.props.onArchiveTemplate(checklistTemplateSummary.checklistTemplateId, this.props.isAdmin, true);
                break;
            case "remove":
                this.props.onArchiveTemplate(checklistTemplateSummary.checklistTemplateId, this.props.isAdmin, false);
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {

        }
        this.setState({
            filterExpanded: value
        })
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onFilterReset();
        });
    }

    filter(filter) {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onFilterUpdate(filter);
        });
    }

    createChecklistTemplate() {
        this.props.history.push(this.props.location.pathname + "/0");
    }

    render() {

        let cardList;
        let rowList;
        let rows;
        if (this.props.checklistTemplates && this.props.checklistTemplates.length > 0) {
            cardList = this.props.checklistTemplates.map((item, index) => {
                return (
                    <ChecklistTemplateCard
                        key={"card-" + item.checklistTemplateId}
                        checklistTemplateSummary={item}
                        canEdit={this.props.canEdit}
                        canEditGlobal={this.props.canEditGlobal}
                        canClone={this.props.canClone}
                        onCommand={this.onCommand}
                    />
                );
            });

            rows = this.props.checklistTemplates.map((item, index) => {
                return (
                    <ChecklistTemplateRow
                        key={"row-" + item.checklistTemplateId.toString()}
                        checklistTemplateSummary={item}
                        canEdit={this.props.canEdit}
                        canEditGlobal={this.props.canEditGlobal}
                        canClone={this.props.canClone}
                        onCommand={this.onCommand} />
                );
            });
        }
        rowList = (
            <TableContainer
                header={<ChecklistTemplateHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <ChecklistTemplateFilter
                filter={this.props.filter}
                open={this.state.filterExpanded}
                checklistTemplateCategories={this.props.checklistTemplateCategories}
                industryCategories={this.props.industryCategories}
                venues={this.props.venues}
                securityFirms={this.props.securityFirms}
                states={this.props.states}
                onUpdateFilter={filter => this.filter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onClose={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;

        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <ChecklistTemplateFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let create;
        if (this.props.canAdd) {
            create = <TransparentButton text={"New checklist template"}  onClick={this.createChecklistTemplate} />
        }

        return (
            <PageLayout
                headerText={"Checklist Templates"}
                footerLeftContent={create}
            >
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading || this.props.isCloning}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    footer={<ChecklistTemplatesLegend />}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                {filter}
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(CheckListTemplates));