import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SignOnRegisterType from '../components/signOnRegisterTypes/SignOnRegisterType'
import * as signOnRegisterTypeActions from '../common/actions/signOnRegisterType';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.signOnRegisterTypes.signOnRegisterType.isLoading,
        isSaving: state.signOnRegisterTypes.signOnRegisterType.isSaving,
        signOnRegisterType: state.signOnRegisterTypes.signOnRegisterType.signOnRegisterType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeReset())
        },
        onUpdate: (signOnRegisterType) => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeUpdate(signOnRegisterType))
        },
        onSave: () => {
            dispatch(signOnRegisterTypeActions.signOnRegisterTypeSaveRequest())
        }
    }
}

const SignOnRegisterTypeContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnRegisterType))

export default SignOnRegisterTypeContainer