import { connect } from 'react-redux'
import IncidentFlagTypeNotifications from '../components/venue/incidentFlagTypes/IncidentFlagTypeNotifications'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { withRouter } from "react-router";
import { 
    incidentFlagTypeNotificationsRequest, 
    incidentFlagTypeNotificationRequest, 
    incidentFlagTypeNotificationReset,
    incidentFlagTypeNotificationSet,
    incidentFlagTypeNotificationUpsertRequest
 } from '../common/actions/incidentFlagTypeNotification';
 import * as notificationTriggerActions from '../common/actions/notificationTrigger';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        currentVenueId: state.user.details.userSession.user.venueId,
        securityFirmId: props.securityFirmId,
        paging: state.incidentFlagTypeNotification.incidentFlagTypeNotifications.paging,
        filter: state.incidentFlagTypeNotification.incidentFlagTypeNotifications.filter,
        isLoading: state.incidentFlagTypeNotification.incidentFlagTypeNotifications.isLoading,
        incidentFlagTypeNotifications: state.incidentFlagTypeNotification.incidentFlagTypeNotifications.incidentFlagTypeNotifications,
        incidentFlagTypeId: state.incidentFlagTypeNotification.incidentFlagTypeNotification.incidentFlagTypeId,
        canEdit: permissionsLogic.hasPermissionForState(state, "MaintainUserRoleNotification"),
        notificationTrigger: state.notificationTrigger.notificationTrigger,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(incidentFlagTypeNotificationsRequest(page, pageSize, filter))
        },
        onLoadItem: (incidentFlagTypeId) => {
            dispatch(incidentFlagTypeNotificationRequest(incidentFlagTypeId))
        },
        onResetItem: () => {
            dispatch(incidentFlagTypeNotificationReset())
        },
        onUpdate: (incidentFlagTypeNotification) => {
            dispatch(incidentFlagTypeNotificationSet(incidentFlagTypeNotification))
        },
        onSave: () => {
            dispatch(incidentFlagTypeNotificationUpsertRequest())
        },
        onSaveNotification: (notificationTrigger) => {
            dispatch(notificationTriggerActions.notificationTriggerUpsertRequest(notificationTrigger))
        }
    }
}

const IncidentFlagTypeNotificationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentFlagTypeNotifications))

export default IncidentFlagTypeNotificationsContainer;