import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueContact from '../components/venue/venueContact/VenueContact'
import { 
    venueContactReset,
    venueContactSet,
    venueContactUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.venueContact.isLoading,
        isSaving: state.venue.venueContact.isSaving,
        venueContact: state.venue.venueContact.venueContact,
        venueContactTypes: state.venue.venueContact.venueContactTypes,
        venueUsers: state.venue.venueContact.venueUsers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueContactReset())
        },
        onUpdate: (venueContact) => {
            dispatch(venueContactSet(venueContact))
        },
        onSave: () => {
            dispatch(venueContactUpsertRequest())
        }
    }
}

const VenueContactContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueContact))

export default VenueContactContainer