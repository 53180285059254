
export const Palette = {
    Primary: "#42c23b",
    PrimaryText: "#0E0E14",
    PrimaryDark: "#308a2b",
    PrimaryDarkText: "#FFF",
    PrimaryDisabled: "#a0cf9d",
    PrimaryDisabledLighter: '#93ad92',
    PrimaryDisabledText: "#444",
    Obsolete: "#666",
    ObsoleteContrastText: "#AAA",
    Text: "#ffffffde",
    Disabled: "#342f30",
    Error: "#DE0000",
    ErrorText: "#000",
    ErrorTextAlt: "#DDD",
    Success: "#42c23b",
    Warning: "#FF6721",
    WarningText: "#FFF",
    WarningTextAlt: "#444",
    Info: "#16A3B8",
    Active: "#FFFFFF",
    Rejected: "#800080",
    Boundary: "rgb(76,76,76)",
    BontrolContrastText: "#919191",
    ControlBorder: "#737373",
    ControlBorderDisabled: "#535353",
    ControlBackgroundLight: "#cfd0d2",
    ControlBackgroundDark: "#313131",
    ControlTextLight: "#111",
    CardContainerBorder: "#545454",
    PopupListBackground: "#373737",
    PopupListBorder: "#4e4e4e",
    Page: "#0E0E14",
    Header: "#1C1D23",
    SubPage: "#1C1D23",
    Popout: "#28292F",
    FooterBackground: "#28282F",
    Border: "#737373",
    AlternativeText: "#E2E2E2",
    SecondaryText: "#D0D0D0",
    SubTitle: "#777777",
    LegendText: "#888"
}