export const
    CHECKLIST_TEMPLATE_RESET = "CHECKLIST_TEMPLATE_RESET",
    SAVE_CHECKLIST_TEMPLATE_REQUEST = "SAVE_CHECKLIST_TEMPLATE_REQUEST",
    SAVE_CHECKLIST_TEMPLATE_SUCCESS = "SAVE_CHECKLIST_TEMPLATE_SUCCESS",
    SAVE_CHECKLIST_TEMPLATE_FAILURE = "SAVE_CHECKLIST_TEMPLATE_FAILURE",
    SAVE_CHECKLIST_TEMPLATE_ITEMS = "SAVE_CHECKLIST_TEMPLATE_ITEMS",
    LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_REQUEST = "LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE = "LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE",
    LOAD_CHECKLIST_TEMPLATE_REQUEST = "LOAD_CHECKLIST_TEMPLATE_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_FAILURE = "LOAD_CHECKLIST_TEMPLATE_FAILURE",
    NEW_CHECKLIST_TEMPLATE_REQUEST = "NEW_CHECKLIST_TEMPLATE_REQUEST",
    LOAD_CHECKLIST_TEMPLATES_REQUEST = "LOAD_CHECKLIST_TEMPLATES_REQUEST",
    LOAD_CHECKLIST_TEMPLATES_SUCCESS = "LOAD_CHECKLIST_TEMPLATES_SUCCESS",
    LOAD_CHECKLIST_TEMPLATES_FAILURE = "LOAD_CHECKLIST_TEMPLATES_FAILURE",
    TOGGLE_CHECKLIST_TEMPLATE_SELECTED = "TOGGLE_CHECKLIST_TEMPLATE_SELECTED",
    CLEAR_CHECKLIST_TEMPLATE_SELECTION = "CLEAR_CHECKLIST_TEMPLATE_SELECTION",
    SET_CHECKLIST_TEMPLATES_PAGE_SIZE = "SET_CHECKLIST_TEMPLATES_PAGE_SIZE",
    SET_CHECKLIST_TEMPLATES_PAGE = "SET_CHECKLIST_TEMPLATES_PAGE",
    SET_CHECKLIST_TEMPLATES_SORT_PARAMETERS = "SET_CHECKLIST_TEMPLATES_SORT_PARAMETERS",
    ARCHIVE_CHECKLIST_TEMPLATES_REQUEST = "ARCHIVE_CHECKLIST_TEMPLATES_REQUEST",
    ARCHIVE_CHECKLIST_TEMPLATES_SUCCESS = "ARCHIVE_CHECKLIST_TEMPLATES_SUCCESS",
    ARCHIVE_CHECKLIST_TEMPLATES_FAILURE = "ARCHIVE_CHECKLIST_TEMPLATES_FAILURE",
    UPDATE_CHECKLIST_TEMPLATE = "UPDATE_CHECKLIST_TEMPLATE",
    UPDATE_CHECKLIST_TEMPLATE_ITEMS = "UPDATE_CHECKLIST_TEMPLATE_ITEMS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_REQUEST = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_FAILURE = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_FAILURE",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_REQUEST = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_REQUEST",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS",
    LOAD_CHECKLIST_TEMPLATE_CATEGORY_FAILURE = "LOAD_CHECKLIST_TEMPLATE_CATEGORY_FAILURE",
    ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST = "ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST",
    ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS = "ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS",
    ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE = "ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE",
    SAVE_CHECKLIST_TEMPLATE_CATEGORY_REQUEST = "SAVE_CHECKLIST_TEMPLATE_CATEGORY_REQUEST",
    SAVE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS = "SAVE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS",
    SAVE_CHECKLIST_TEMPLATE_CATEGORY_FAILURE = "SAVE_CHECKLIST_TEMPLATE_CATEGORY_FAILURE",
    TOGGLE_CHECKLIST_TEMPLATE_CATEGORY_SELECTED = "TOGGLE_CHECKLIST_TEMPLATE_CATEGORY_SELECTED",
    CLEAR_CHECKLIST_TEMPLATE_CATEGORY_SELECTION = "CLEAR_CHECKLIST_TEMPLATE_CATEGORY_SELECTION",
    SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE_SIZE = "SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE_SIZE",
    SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE = "SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE",
    SET_CHECKLIST_TEMPLATE_CATEGORIES_SORT_PARAMETERS = "SET_CHECKLIST_TEMPLATE_CATEGORIES_SORT_PARAMETERS",
    COPY_CHECKLIST_TEMPLATES_REQUEST = "COPY_CHECKLIST_TEMPLATES_REQUEST",
    COPY_CHECKLIST_TEMPLATES_SUCCESS = "COPY_CHECKLIST_TEMPLATES_SUCCESS",
    COPY_CHECKLIST_TEMPLATES_FAILURE = "COPY_CHECKLIST_TEMPLATES_FAILURE",
    LOAD_CHECKLIST_PREVIEW_SUCCESS = "LOAD_CHECKLIST_PREVIEW_SUCCESS",
    LOAD_CHECKLIST_PREVIEW_REQUEST = "LOAD_CHECKLIST_PREVIEW_REQUEST",
    SET_CHECKLIST_TEMPLATE_ITEM = "SET_CHECKLIST_TEMPLATE_ITEM",
    SET_CHECKLIST_TEMPLATE_GROUPED_ITEM = "SET_CHECKLIST_TEMPLATE_GROUPED_ITEM",
    ADD_CHECKLIST_TEMPLATE_ITEM = "ADD_CHECKLIST_TEMPLATE_ITEM",
    MOVE_CHECKLIST_TEMPLATE_ITEM = "MOVE_CHECKLIST_TEMPLATE_ITEM",
    REMOVE_CHECKLIST_TEMPLATE_ITEM = "REMOVE_CHECKLIST_TEMPLATE_ITEM";

export function removeChecklistTemplateItem(checklistTemplateItem) {
    return {
        type: REMOVE_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem
    }
}

export function moveChecklistTemplateItem(checklistTemplateItem, parentId, position) {
    return {
        type: MOVE_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem, 
        parentId, 
        position
    }
}

export function addChecklistTemplateItem(checklistItemType, parentId, position) {
    return {
        type: ADD_CHECKLIST_TEMPLATE_ITEM,
        checklistItemType,
        parentId, 
        position
    }
}

export function setChecklistTemplateGroupedItem(checklistTemplateItem) {
    return {
        type: SET_CHECKLIST_TEMPLATE_GROUPED_ITEM,
        checklistTemplateItem
    }
}

export function setChecklistTemplateItem(checklistTemplateItem) {
    return {
        type: SET_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem
    }
}

export function checklistTemplateUpdate(checklistTemplate) {
    return {
        type: UPDATE_CHECKLIST_TEMPLATE,
        checklistTemplate
    }

}

export function checklistTemplatesReset() {
    return {
        type: CHECKLIST_TEMPLATE_RESET
    };
}

export function updateChecklistTemplateItems(checklistTemplateItems) {
    return {
        type: UPDATE_CHECKLIST_TEMPLATE_ITEMS,
        checklistTemplateItems
    };
}

export function saveChecklistTemplateRequest(checklistTemplate) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_REQUEST,
        checklistTemplate
    };
}

export function saveChecklistTemplateSuccess(checklistTemplate) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_SUCCESS,
        checklistTemplate
    };
}
export function saveChecklistTemplateFailure(responseStatus) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_FAILURE,
        responseStatus
    };
}

export function saveChecklistTemplateItems(checklistTemplateItems) {
    return {
        type: "SAVE_CHECKLIST_TEMPLATE_ITEMS",
        checklistTemplateItems
    };
}

export function loadChecklistTemplateReferenceDataRequest() {
    return {
        type: "LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_REQUEST"
    };
}
export function loadChecklistTemplateReferenceDataSuccess() {
    return {
        type: "LOAD_CHECKLIST_TEMPLATE_REFERENCE_DATA_SUCCESS"
    };
}

export function loadChecklistTemplateCategoriesRequest() {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST
    };
}

export function loadChecklistTemplateCategoriesSuccess(
    checklistTemplateCategories
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS,
        checklistTemplateCategories
    };
}

export function loadChecklistTemplateCategoriesFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE,
        responseStatus
    };
}

export function loadChecklistTemplateRequest(checklistTemplateId) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_REQUEST,
        checklistTemplateId
    };
}

export function loadChecklistTemplateSuccess(checklistTemplate) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_SUCCESS,
        checklistTemplate
    };
}

export function loadChecklistTemplateFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_FAILURE,
        responseStatus
    };
}

export function newChecklistTemplateRequest() {
    return {
        type: NEW_CHECKLIST_TEMPLATE_REQUEST
    };
}

export function loadChecklistTemplatesRequest(
    searchText,
    venues,
    securityFirms,
    industryCategories,
    states,
    checklistTemplateCategories
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATES_REQUEST,
        searchText,
        venues,
        securityFirms,
        industryCategories,
        states,
        checklistTemplateCategories
    };
}

export function loadChecklistTemplatesSuccess(checklistTemplates, paging) {
    return {
        type: LOAD_CHECKLIST_TEMPLATES_SUCCESS,
        checklistTemplates,
        paging
    };
}

export function loadChecklistTemplatesFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATES_FAILURE,
        responseStatus
    };
}

export function toggleChecklistTemplateSelected(checklistTemplateId) {
    return {
        type: TOGGLE_CHECKLIST_TEMPLATE_SELECTED,
        checklistTemplateId
    };
}
export function clearChecklistTemplateSelection() {
    return {
        type: CLEAR_CHECKLIST_TEMPLATE_SELECTION
    };
}

export function setChecklistTemplatesPageSize(pageSize) {
    return {
        type: SET_CHECKLIST_TEMPLATES_PAGE_SIZE,
        pageSize
    };
}

export function setChecklistTemplatesPage(page) {
    return {
        type: SET_CHECKLIST_TEMPLATES_PAGE,
        page
    };
}

export function setChecklistTemplatesSortParameters(sortBy, sortDirection) {
    return {
        type: SET_CHECKLIST_TEMPLATES_SORT_PARAMETERS,
        sortBy,
        sortDirection
    };
}

export function archiveChecklistTemplatesRequest(checklistTemplateIds) {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATES_REQUEST,
        checklistTemplateIds
    };
}

export function archiveChecklistTemplatesSuccess() {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATES_SUCCESS
    };
}

export function archiveChecklistTemplatesFailure(responseStatus) {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATES_FAILURE,
        responseStatus
    };
}

export function saveChecklistTemplateCategoryRequest(
    checklistTemplateCategory
) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_CATEGORY_REQUEST,
        checklistTemplateCategory
    };
}
export function saveChecklistTemplateCategorySuccess(
    checklistTemplateCategory
) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS,
        checklistTemplateCategory
    };
}
export function saveChecklistTemplateCategoryFailure(responseStatus) {
    return {
        type: SAVE_CHECKLIST_TEMPLATE_CATEGORY_FAILURE,
        responseStatus
    };
}

export function archiveChecklistTemplateCategoriesRequest(
    checklistTemplateCategoryIds
) {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_REQUEST,
        checklistTemplateCategoryIds
    };
}

export function archiveChecklistTemplateCategoriesSuccess() {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_SUCCESS
    };
}

export function archiveChecklistTemplateCategoriesFailure(responseStatus) {
    return {
        type: ARCHIVE_CHECKLIST_TEMPLATE_CATEGORIES_FAILURE,
        responseStatus
    };
}

export function loadChecklistTemplateCategorySummariesRequest(
    searchText,
    venues,
    securityFirms,
    industryCategories,
    states
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_REQUEST,
        searchText,
        venues,
        securityFirms,
        industryCategories,
        states
    };
}

export function loadChecklistTemplateCategorySummariesSuccess(
    checklistTemplateCategories
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_SUCCESS,
        checklistTemplateCategories
    };
}

export function loadChecklistTemplateCategorySummariesFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUMMARIES_FAILURE,
        responseStatus
    };
}

export function loadChecklistTemplateCategoryRequest(
    checklistTemplateCategoryId
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_REQUEST,
        checklistTemplateCategoryId
    };
}

export function loadChecklistTemplateCategorySuccess(
    checklistTemplateCategory
) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_SUCCESS,
        checklistTemplateCategory
    };
}

export function loadChecklistTemplateCategoryFailure(responseStatus) {
    return {
        type: LOAD_CHECKLIST_TEMPLATE_CATEGORY_FAILURE,
        responseStatus
    };
}

export function toggleChecklistTemplateCategorySelected(
    checklistTemplateCategoryId
) {
    return {
        type: TOGGLE_CHECKLIST_TEMPLATE_CATEGORY_SELECTED,
        checklistTemplateCategoryId
    };
}
export function clearChecklistTemplateCategorySelection() {
    return {
        type: CLEAR_CHECKLIST_TEMPLATE_CATEGORY_SELECTION
    };
}

export function setChecklistTemplateCategoriesPageSize(pageSize) {
    return {
        type: SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE_SIZE,
        pageSize
    };
}

export function setChecklistTemplateCategoriesPage(page) {
    return {
        type: SET_CHECKLIST_TEMPLATE_CATEGORIES_PAGE,
        page
    };
}

export function setChecklistTemplateCategoriesSortParameters(
    sortBy,
    sortDirection
) {
    return {
        type: SET_CHECKLIST_TEMPLATE_CATEGORIES_SORT_PARAMETERS,
        sortBy,
        sortDirection
    };
}

export function copyChecklistTemplatesRequest(checklistTemplateIds, isAdmin) {
    return {
        type: COPY_CHECKLIST_TEMPLATES_REQUEST,
        checklistTemplateIds,
        isAdmin
    };
}
export function copyChecklistTemplatesSuccess(checklistTemplate) {
    return {
        type: COPY_CHECKLIST_TEMPLATES_SUCCESS,
        checklistTemplate
    };
}
export function copyChecklistTemplatesFailure(responseStatus) {
    return {
        type: COPY_CHECKLIST_TEMPLATES_FAILURE,
        responseStatus
    };
}

export function loadChecklistPreviewRequest() {
    return { type: LOAD_CHECKLIST_PREVIEW_REQUEST };
}
export function loadChecklistPreviewSuccess(checklist) {
    return { type: LOAD_CHECKLIST_PREVIEW_SUCCESS, checklist };
}
