import { connect } from 'react-redux'
import ExternalApiConnection from '../components/admin/externalApiConnection/ExternalApiConnection';
import * as externalApiConnectionActions from "../common/actions/externalApiConnection";
import { externalApiConnectionLogic } from "../common/logic/externalApiConnectionLogic";

/*
const findExternalApiConnection = (state, entityType, venueId, securityFirmId) => {
    let key = externalApiConnectionLogic.buildKey(entityType, venueId, securityFirmId);
    if (state.externalApiConnections && state.externalApiConnections.externalApiConnections) {
        if (state.externalApiConnections.externalApiConnections[key] != undefined && 
            state.externalApiConnections.externalApiConnections[key] != null) {
                return state.externalApiConnections.externalApiConnections[key].externalApiConnection;
        }
    }
    return {};
}
*/

const mapStateToProps = (state, props) => {
    return {
        entityType: props.entityType,
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        isLoading: state.externalApiConnections.isLoading,
        isSaving: state.externalApiConnections.isSaving,
        externalApiConnection: externalApiConnectionLogic.findExternalApiConnection(state, props.entityType, props.venueId, props.securityFirmId)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (entityType, forVenueId, forSecurityFirmId) => {
            dispatch(externalApiConnectionActions.externalApiConnectionRequest(entityType, forVenueId, forSecurityFirmId))
        },
        onSet: (externalApiConnection) => {
            dispatch(externalApiConnectionActions.externalApiConnectionSet(externalApiConnection))
        },
        onRefreshToken: (externalApiConnectionId) => {
            dispatch(externalApiConnectionActions.externalApiConnectionRefreshRequest(externalApiConnectionId))
        },
        onRemove: (externalApiConnectionId) => {
            dispatch(externalApiConnectionActions.externalApiConnectionRemoveRequest(externalApiConnectionId))
        },
        onUpdate: (externalApiConnection) => {
            dispatch(externalApiConnectionActions.externalApiConnectionUpdateRequest(externalApiConnection))
        }
    }
}

const ExternalApiConnectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalApiConnection)

export default ExternalApiConnectionContainer