import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import ControlGroup from "./ControlGroup";
import TextControl from './TextControl'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export interface ITextControlGroupProps {
    id?: string;
    autoComplete?: string;
    classes: any;
    theme: any;
    text?: string;
    error?: string;
    defaultValue?: string;
    placeholder?: string;
    readonly?: boolean;
    onChange?: Function;
    onBlur?: Function;
    onSubmit?: Function;
    labelAbove?: boolean;
    inputType?: string;
    numericOnly?: boolean;
    numberMustBePositive?: boolean;
    maxlength?: number;
}
class TextControlGroup extends React.PureComponent<ITextControlGroupProps, any> {

    constructor(props: ITextControlGroupProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    onBlur(value) {
        if (this.props.onBlur) {
            this.props.onBlur(value);
        }
    }

    onSubmit(value) {
        if (this.props.onSubmit) {
            this.props.onSubmit(value);
        }
    }

    render() {
        const { classes, theme } = this.props;

        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                    {this.props.readonly && (
                        <p style={{
                            textAlign: 'center',
                            borderBottom: "1px dotted",
                            borderBottomColor: theme.custom.colors.controlBorder,
                            color: theme.palette.text.disabled,
                            margin: 0,
                            padding: '10px'
                        }}>{this.props.defaultValue || this.props.placeholder}</p>
                    )}
                    {!this.props.readonly && (
                        <TextControl
                            id={this.props.id}
                            autoComplete={this.props.autoComplete}
                            error={this.props.error ? true : false}
                            value={this.props.defaultValue || ''}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            onSubmit={this.onSubmit}
                            placeholder={this.props.placeholder}
                            readonly={this.props.readonly}
                            inputType={this.props.inputType}
                            numericOnly={this.props.numericOnly}
                            numberMustBePositive={this.props.numberMustBePositive}
                            maxlength={this.props.maxlength}
                        />
                    )}

                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TextControlGroup);