import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IFacialRecognitionNotificationCardProps {
    facialRecognitionNotification: AusComplyDtos.FacialRecognitionNotification;
    onEdit?: Function;
    onProcess?: Function;
}

export default class FacialRecognitionNotificationCard extends React.Component<IFacialRecognitionNotificationCardProps, any> {
    constructor(props: IFacialRecognitionNotificationCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.facialRecognitionNotification);
                }
                break;
            case "process":
                if (this.props.onProcess) {
                    this.props.onProcess(this.props.facialRecognitionNotification);
                }
                break;
        }
    }

    render() {
        if (!this.props.facialRecognitionNotification) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "process", name: "Re-process" });

        let incidentsTick;
        if (this.props.facialRecognitionNotification.hasIncidents) {
            incidentsTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <CardRow commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={12}>
                        <CardTitle>Date</CardTitle>
                        <CardTypography>{this.props.facialRecognitionNotification.dateEntered}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Event Type</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.facialRecognitionNotification.eventType}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Match Outcome</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.facialRecognitionNotification.matchOutcomes}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Camera</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.facialRecognitionNotification.camera}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Watchlist</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.facialRecognitionNotification.watchLists}</CardTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Has Incidents</CardTitle>
                        {incidentsTick}
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}