import React from 'react';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from "react-google-maps"
import { createTrue } from 'typescript';
import LocatorIcon from '../../resources/LocatorIcon';

const styles = require('../../resources/GoogleMapStyles.json')



class MapWrapper extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            isFullScreen: false,
            lat: 0,
            lng: 0,
            changed: false
        }

        //this.googleMap = React.createRef();
        this.handleZoomChanged = this.handleZoomChanged.bind(this);
        this.handleBoundsChanged = this.handleBoundsChanged.bind(this);
    }

    //googleMap;

    onMarkerDragEnd = (e: google.maps.MouseEvent) => {
        // console.log(e);
        const lat = (e.latLng.lat());
        const lng = (e.latLng.lng());

        // console.log(lat, lng);

        if (this.state.isFullScreen) {
            this.setState({
                changed: true,
                lat,
                lng
            });
        } else {
            if (this.props.onDragged) {
                this.props.onDragged(lat, lng);
            }
        }
        e.stop();
        // @ts-ignore
        e.cancelBubble = true;
        // @ts-ignore
        if (e.stopPropagation) {
            // @ts-ignore
            e.stopPropagation();
        }
        // @ts-ignore
        if (e.preventDefault) {
            // @ts-ignore
            e.preventDefault();
        } else {
            // @ts-ignore
            e.returnValue = false;
        }
    };

    handleZoomChanged = () => {
        // @ts-ignore
        const zoomLevel = this.refs.map.getZoom();
        // console.log('zoom changed', zoomLevel) //this refers to Google Map instance
        if (this.props.onZoomChanged) {
            this.props.onZoomChanged(zoomLevel)
        }
    };

    handleBoundsChanged = () => {
        // @ts-ignore
        let map = this.refs.map;
        // @ts-ignore
        if (map.getDiv().firstChild.clientHeight === window.innerHeight && map.getDiv().firstChild.clientWidth == window.innerWidth) {
            this.setState ({
                isFullScreen: true
            });
        }
        else {
            if (this.state.changed == true) {
                if (this.props.onDragged) {
                    this.props.onDragged(this.state.lat, this.state.lng);
                }
            }
            this.setState ({
                isFullScreen: false,
                lat: 0,
                lng: 0,
                changed: false
            });
        }
    }

    render() {
        let zoomLevel = 15;
        if (this.props.zoomLevel) {
            zoomLevel = this.props.zoomLevel;
        }
        let lat = this.props.lat;
        let lng = this.props.lng;
        if (this.state.changed) {
            lat = this.state.lat;
            lng = this.state.lng;
        }

        return (
            <GoogleMap
                defaultZoom={zoomLevel}
                defaultCenter={{ lat: this.props.lat, lng: this.props.lng }}
                defaultOptions={{
                    disableDefaultUI: true, // disable default map UI
                    fullscreenControl: this.props.fullscreenControl ? true : false,
                    draggable: true, // make map draggable
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    zoomControl: this.props.zoomControl ? true : false,
                    scrollwheel: true, // allow scroll wheel
                    styles: styles // change default map styles
                }}
                ref="map"
                onZoomChanged={this.handleZoomChanged}
                onBoundsChanged={this.handleBoundsChanged}
            >
                {this.props.isMarkerShown && <Marker
                    icon={{
                        url: '/Locator.svg'
                    }}
                    draggable={this.props.draggable}
                    onDragEnd={(e) => this.onMarkerDragEnd(e)}
                    position={{ lat: lat, lng: lng }}
                />}
            </GoogleMap>
        );
    }
}

export default withScriptjs(withGoogleMap(MapWrapper))