import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const contentServiceApi = {
  getTerms: () => {
    var client = new AusComplyJsonServiceClient();
    var getTerms = new AusComplyDtos.GetTerms();
    const request = client.get(getTerms);
    return from(request);  
  },
  getPrivacyPolicy: () => {
    var client = new AusComplyJsonServiceClient();
    var getPrivacy = new AusComplyDtos.GetPrivacy();
    const request = client.get(getPrivacy);
    return from(request);   
  }
};
