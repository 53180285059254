import React from "react";

const AusComplyIcon = ({
    name = "",
    style = {},
    fill = "#fff",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 54.7 53.6"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M37.1,15.3L30.8,4.5c3.9,0.7,7.4,2.4,10.3,4.8L37.1,15.3z M22.6,4.5L5.4,34.3c-0.8-2.4-1.3-4.9-1.3-7.5
		C4.1,15.7,12.1,6.5,22.6,4.5 M26.8,44.5L54.6,1.1h-8l-3.2,4.7C38.9,2.2,33.1,0,26.8,0C12,0,0,12,0,26.8s12,26.8,26.8,26.8
		c10.4,0,19.5-6,23.9-14.7l-2.6-4.4l-4.7-8.2l-4.5,7l4.9,8.6c-4.2,4.7-10.2,7.7-17,7.7c-6.8,0-12.9-3-17.1-7.8l17-29.5l5.7,9.9
		l-5.7,8.4l-3.1-4.6l-4.4,7.6l7.3,10.7L26.8,44.5z" />
            </g>
        </svg>
    );

export default AusComplyIcon;
