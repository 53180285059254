import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import DefaultButton from '../../controls/DefaultButton';
import HistoryIcon from '@material-ui/icons/History';

export interface IIncidentViewDatesProps {
    incident: IncidentDTO;
    canShowVersions?: boolean;
    history?: any;
}

export default class IncidentViewDates extends Component<IIncidentViewDatesProps, any> {
    constructor(props: IIncidentViewDatesProps) {
        super(props)
        this.state = {
        }
        this.openVersions = this.openVersions.bind(this);
    }


    openVersions() {
        if (this.props.history) {
            this.props.history.push('/incidentview/' + this.props.incident.incidentId + "/versions");
        }
    }

    render() {
        return (
            <>
                <ControlGroup text="Created At">
                    <TextDisplay>{this.props.incident.createdDateString}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Submitted At">
                    <TextDisplay>{this.props.incident.completedDateString}</TextDisplay>
                </ControlGroup>
                <ControlGroup text="Modified At" >
                    <div style={{ position: 'relative' }}>
                        <TextDisplay>{this.props.incident.dateModifiedString}</TextDisplay>
                        {(this.props.history && this.props.canShowVersions) && (
                            <DefaultButton style={{ position: 'absolute', top: '5px', right: '5px', padding: '1px' }} onClick={this.openVersions}>
                                <HistoryIcon style={{fontSize: '1.3rem'}} />
                            </DefaultButton>
                        )}
                    </div>
                </ControlGroup>
                <ControlGroup text="Date">
                    <TextDisplay>{this.props.incident.incidentDateString}</TextDisplay>
                </ControlGroup>
            </>
        );
    }
}