import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POISearch from '../components/facialRecognition/facialRecognitionPOI/POISearch';
import * as facialRecognitionPOIActions from "../common/actions/facialRecognitionPOI";

const mapStateToProps = (state, props) => {
    return {
        incidentId: props.incidentId,
        isLoading: state.facialRecognitionPOI.poiSearch.isLoading,
        poiScan: state.facialRecognitionPOI.poiSearch.poiScan,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSearch: (incidentId, poiScan) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiSearchRequest(incidentId, poiScan))
        }
    }
}

const FacialRecognitionPOISearchContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POISearch))

export default FacialRecognitionPOISearchContainer