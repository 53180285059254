// This component is a wrapper so that UI components can be easily shared (copied) between react and react natve
import React from 'react';

export interface IPickerItemProps {
    label: string;
    value?: any;
}

export class PickerItem extends React.Component<IPickerItemProps, any> {
    render() {
        return (
            <option key={this.props.value} value={this.props.value}>{this.props.label}</option>
        );
    }
}