import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface IIncidentTypesLegendProps {
    classes: any;
    theme: any;
}

class IncidentTypesLegend extends React.Component<IIncidentTypesLegendProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentDraft}></div>
                    <div className={classes.colorSwatchText}>Internal</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentDeleted}></div>
                    <div className={classes.colorSwatchText}>Removed</div>
                </Box>
            </Box>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentTypesLegend);