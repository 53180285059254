import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IIndustryCategoryEditProps {
    open: boolean;
    industryCategory: AusComplyDtos.IndustryCategory;
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IIndustryCategoryEditState {
    industryCategory: AusComplyDtos.IndustryCategory;
    incidentCategoryTypes: MulitSelectOption[];
    error: string;
}

export default class IndustryCategoryEdit extends Component<IIndustryCategoryEditProps, IIndustryCategoryEditState> {
    constructor(props: IIndustryCategoryEditProps) {
        super(props)
        let industryCategory = new AusComplyDtos.IndustryCategory();
        let error = "";
        this.state = {
            industryCategory,
            incidentCategoryTypes: [],
            error
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIncidentCategoryTypesChanged = this.onIncidentCategoryTypesChanged.bind(this);
    }

    componentDidMount() {
        let industryCategory = { ...this.props.industryCategory };
        let incidentCategoryTypes: MulitSelectOption[] = [];
        if (this.props.industryCategory.incidentCategoryTypeSelections) {
            this.props.industryCategory.incidentCategoryTypeSelections.forEach((item, index) => {
                let incidentCategoryType = new MulitSelectOption();
                incidentCategoryType.id = item.incidentCategoryTypeId;
                incidentCategoryType.name = item.name;
                incidentCategoryType.selected = item.selected;
                incidentCategoryType.isCommon = false;
                incidentCategoryTypes.push(incidentCategoryType);
            });
        }
        this.setState({
            industryCategory,
            incidentCategoryTypes,
            error: ""
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.error) {
                this.setState({ error: this.props.error });
            } else {
                this.props.onCancel();
            }
        }
    }

    onSave() {
        let industryCategory = { ...this.state.industryCategory };
        if (industryCategory.incidentCategoryTypeSelections) {
            industryCategory.incidentCategoryTypeSelections.forEach((item, index) => {
                item.selected = this.state.incidentCategoryTypes.filter(s => s.id === item.incidentCategoryTypeId && s.selected).length > 0;
            });
        }
        this.props.onSave(industryCategory);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let industryCategory = { ...this.state.industryCategory };
        industryCategory[fieldName] = value;
        this.setState({ industryCategory });
    }

    onIncidentCategoryTypesChanged(value) {
        let incidentCategoryTypes = [...this.state.incidentCategoryTypes];
        incidentCategoryTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            incidentCategoryTypes
        });
    }

    render() {

        let rank = "0";
        if (this.state.industryCategory.rank) {
            rank = this.state.industryCategory.rank.toString();
        }
        let activityMonitorTimeout = "0";
        if (this.state.industryCategory.activityMonitorTimeout) {
            activityMonitorTimeout = this.state.industryCategory.activityMonitorTimeout.toString();
        }
        let activityNotificationTimeout = "0";
        if (this.state.industryCategory.activityNotificationTimeout) {
            activityNotificationTimeout = this.state.industryCategory.activityNotificationTimeout.toString();
        }

        return (
            <FullPageDialog
                open={this.props.open}
                footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                error={this.props.error}
                title="Edit Industry" onDismissed={() => this.onCancel()}>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.state.industryCategory.name}
                    onChange={value => this.onValueChanged("name", value)} />
                <TextControlGroup
                    text={"Rank"}
                    numericOnly={true}
                    defaultValue={rank}
                    onChange={value => this.onValueChanged("rank", value)} />
                <TextControlGroup
                    text={"Require re-login if in-active for minutes"}
                    numericOnly={true}
                    defaultValue={activityMonitorTimeout}
                    onChange={value => this.onValueChanged("activityMonitorTimeout", value)} />
                <TextControlGroup
                    text={"Considered logged-in even if in-active for minutes"}
                    numericOnly={true}
                    defaultValue={activityNotificationTimeout}
                    onChange={value => this.onValueChanged("activityNotificationTimeout", value)} />
                <CheckboxControlGroup
                    text={"Active"}
                    isReversed={true}
                    defaultValue={this.state.industryCategory.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
                <CardContainer
                    title={"Incident Category Types"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <div style={{ marginTop: '-4px' }}>
                            <MultiSelectControl
                                items={this.state.incidentCategoryTypes}
                                minimumCharactersRequired={3}
                                canShowAll={false}
                                searchText={"Type at least three letters..."}
                                onSelectItemsChanged={this.onIncidentCategoryTypesChanged} />
                        </div>
                    </CardContent>
                </CardContainer>
            </FullPageDialog>
        );
    }
}