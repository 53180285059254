import React, { forwardRef } from 'react';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import { UserSignOnSummary } from "../../common/dto/AusComply.dtos";
import { Link } from 'react-router-dom';
import CardInformation from '../common/CardInformation';
import DefaultButton from '../controls/DefaultButton';
import ErrorButton from '../controls/ErrorButton';
import Grid from '@material-ui/core/Grid';
import CardTitle from '../common/CardTitle';
import CardDivider from '../common/CardDivider';
import SignOnRegisterBreakContainer from '../../containers/SignOnRegisterBreakContainer';

export interface ISignOnOffSummaryProps {
    userSignOnSummary: UserSignOnSummary;
    onResetSignOnRegisterFilter: Function;
    onBreak: Function;
}

export default class SignOnOffSummary extends React.PureComponent<ISignOnOffSummaryProps, any> {
    constructor(props: ISignOnOffSummaryProps) {
        super(props);
        this.handleSignOnRegisterClick = this.handleSignOnRegisterClick.bind(this)
    }

    handleSignOnRegisterClick() {
        this.props.onResetSignOnRegisterFilter();
    }

    render() {
        let text;
        let breakDisplay;
        let showView = this.props.userSignOnSummary.canViewSignOnOffRegister;
        let showSignOn = !this.props.userSignOnSummary.isSignedOn && this.props.userSignOnSummary.canSignOn;
        let showBreak = this.props.userSignOnSummary.isSignedOn;
        let showSignOff = this.props.userSignOnSummary.isSignedOn;
        let showExtend = this.props.userSignOnSummary.isSignedOn;

        if (!showView && !showSignOn && !showSignOff && !showExtend) {
            return (<div></div>);
        }

        let signOffPath = "/signoffregister/" + this.props.userSignOnSummary.signOnRegisterId;

        if (!this.props.userSignOnSummary.isSignedOn) {
            text = (
                <CardInformation>Not signed on</CardInformation>
            );
        } else {
            if (this.props.userSignOnSummary.isOverdue) {
                text = (
                    <>
                        <CardTitle>Signed on to</CardTitle>
                        <CardInformation>{this.props.userSignOnSummary.venue}</CardInformation>
                        <CardDivider />
                        <CardTitle>Shift ends in</CardTitle>
                        <CardInformation>Shift has passed the expected sign off time</CardInformation>
                        <CardDivider />
                    </>
                );
            } else {
                text = (
                    <>
                        <CardTitle>Signed on to</CardTitle>
                        <CardInformation>{this.props.userSignOnSummary.venue}</CardInformation>
                        <CardDivider />
                        <CardTitle>Shift ends in</CardTitle>
                        <CardInformation>{this.props.userSignOnSummary.shiftEndsShortDisplay}</CardInformation>
                        <CardDivider />
                    </>
                );
            }
        }

        let actionButtons: JSX.Element[] = [];
        if (showView) {
            actionButtons.push((
                <DefaultButton
                    onClick={() => this.handleSignOnRegisterClick()}
                    component={forwardRef((props, ref) => (
                        <Link to="/signonoffregister/current" {...props} />
                    ))}>View</DefaultButton>
            ));
        }
        if (showSignOn) {
            actionButtons.push((
                <DefaultButton
                    component={forwardRef((props, ref) => (
                        <Link to="/signonregister" {...props} />
                    ))}>Sign on</DefaultButton>
            ));
        }
        if (showBreak) {
            if (this.props.userSignOnSummary.isOnBreak) {
                breakDisplay = (<CardInformation>{this.props.userSignOnSummary.breakDisplay}</CardInformation>);
                actionButtons.push((
                    <ErrorButton text='Stop Break' onClick={() => this.props.onBreak(this.props.userSignOnSummary.signOnRegisterId)} />
                ));
            }
            else {
                actionButtons.push((
                    <DefaultButton text='Start Break' onClick={() => this.props.onBreak(this.props.userSignOnSummary.signOnRegisterId)} />

                ));

            }
        }
        if (showExtend) {
            actionButtons.push((
                <DefaultButton
                    component={forwardRef((props, ref) => (
                        <Link to={"/signonextend/" + this.props.userSignOnSummary.signOnRegisterId}  {...props} />
                    ))}>Extend</DefaultButton>

            ));
        }
        if (showSignOff) {
            actionButtons.push((
                <DefaultButton
                    component={forwardRef((props, ref) => (
                        <Link to={signOffPath} {...props} />
                    ))}>Sign off</DefaultButton>
            ));
        }
        let actions;
        if (actionButtons.length == 1) {
            actions = (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                        {actionButtons[0]}
                    </Grid>
                    <Grid item xs={12} sm={2}></Grid>
                </Grid>
            );
        }
        if (actionButtons.length == 2) {
            actions = (
                <Grid container spacing={1}>
                    {actionButtons.map((item, index) => (
                        <Grid key={'so_' + index.toString()} item xs={12} sm={6}>{item}</Grid>
                    ))}
                </Grid>
            );
        }
        if (actionButtons.length == 3) {
            actions = (
                <Grid container spacing={1}>
                    {actionButtons.map((item, index) => (
                        <Grid key={'so_' + index.toString()} item xs={12} sm={4}>{item}</Grid>
                    ))}
                </Grid>
            );
        }
        if (actionButtons.length == 4) {
            actions = (
                <Grid container spacing={1}>
                    {actionButtons.map((item, index) => (
                        <Grid key={'so_' + index.toString()} item xs={12} sm={6} md={3}>{item}</Grid>
                    ))}
                </Grid>
            );
        }

        return (
            <>
                <CardContainer title={"Sign On Register"}
                    actions={actions}
                >
                    <CardContent>
                        {text}
                        {breakDisplay}
                    </CardContent>
                </CardContainer >
                <SignOnRegisterBreakContainer />
            </>
        );
    }
}