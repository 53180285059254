import { connect } from 'react-redux'
import SignOnExtend from '../components/signon/SignOnExtend'
import { signOnExtendRequest, signOnExtendSaveRequest } from '../common/actions/signOn';
import { withRouter } from "react-router";

const hasPermission = (permissions: string[], permission: string) => {
    if (permissions){
        return permissions.indexOf(permission) > -1;
    }
    return false;
}

const mapStateToProps = (state, props) => {
    return {
        userSignOnStatusId: props.match.params.usersignonstatusid,
        userSignOnStatus: state.signOn.userSignOnStatus,
        isLoading: state.signOn.isLoading,
        isSaving: state.signOn.isSaving,
        saveError: state.signOn.error,
        canViewSignOnRegister: hasPermission(state.user.details.userSession.permissions, "SignOnOffRegisterView")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (userSignOnStatusId) => {
            dispatch(signOnExtendRequest(userSignOnStatusId))
        },
        onSignOnExtend: (userSignOnStatus) => {
            dispatch(signOnExtendSaveRequest(userSignOnStatus))
        }
    }
}

const SignOnExtendContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SignOnExtend))

export default SignOnExtendContainer