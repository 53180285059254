import update from "immutability-helper";
import * as signOnActions from "../actions/signOn";
import { USER_RESET } from '../actions/userLogin';

const initialState = {
    userSignOnStatus: {},
    userCheck: {
        userRoleId: 0,
        venueId: 0,
        isValidating: false,
        isSignedOnToADifferentVenue: false,
        canSignOnToMultipleVenues: false
    },
    signOffDetails: {},
    userSignOnStart: {},
    signOnRegister: {
        paging: {},
        items: [],
        venueReportingPeriodId: 0,
        hasOpenReportingPeriod: false,
        canStartReportingPeriod: false,
        canViewSignOnRegister: false,
        canViewSignOnOffRegister: false,
        canPrint: false,
        includeUserSignOns: true,
        showAcknowledged: false,
        showSupervisor: false,
        showAssocatedSecurityFirms: false,
        showCurrent: true,
        isSecurityRole: false,
        canSignOn: false,
        showActuals: true,
        showPrint: false
    },
    isLoading: false,
    isSaving: false,
    filter: {},
    error: "",
    signOnRegisterBreak: {
        signOnRegisterId: 0,
        signOnRegisterBreak: {},
        isLoading: false,
        isSaving: false,
        error: "",
    }
};

function setLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setSaving(state, isSaving, error) {
    let newState = update(state, {
        isSaving: { $set: isSaving },
        error: { $set: error }
    });
    return newState;
}

function userSignOnStart(state, userSignOnStart) {
    let newState = update(state, {
        userSignOnStart: { $set: userSignOnStart }
    });
    return newState;
}

function setUserSignOnStatus(state, userSignOnStatus) {
    let newState = update(state, {
        userSignOnStatus: { $set: userSignOnStatus }
    });
    return newState;
}

function setSignOffDetails(state, signOffDetails) {
    let newState = update(state, {
        signOffDetails: { $set: signOffDetails }
    });
    return newState;
}

function checkUser(state, venueId, userRoleId) {
    let newState = update(state, {
        userCheck: {
            isValidating: { $set: true },
            venueId: { $set: venueId },
            userRoleId: { $set: userRoleId }
        }
    });
    return newState;
}

function checkUserResult(state, isSignedOnToADifferentVenue, canSignOnToMultipleVenues) {
    let newState = update(state, {
        userCheck: {
            isValidating: { $set: false },
            isSignedOnToADifferentVenue: { $set: isSignedOnToADifferentVenue },
            canSignOnToMultipleVenues: { $set: canSignOnToMultipleVenues }
        }
    });
    return newState;
}

function setSignOnRegisterItems(state, items) {
    let newState = update(state, {
        signOnRegister: {
            items: { $set: items }
        }
    });
    return newState;
}

function setSignOnRegister(state, items, paging) {
    let newState = update(state, {
        signOnRegister: {
            items: { $set: items },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setFilter(state, filter) {
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setBreakLoading(state, isLoading, error) {
    let newState = update(state, {
        signOnRegisterBreak: {
            isLoading: { $set: isLoading },
            error: { $set: error }
        }
    });
    return newState;
}

function setBreakSaving(state, isSaving, error) {
    let newState = update(state, {
        signOnRegisterBreak: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setBreak(state, signOnRegisterBreak) {
    let newState = update(state, {
        signOnRegisterBreak: {
            signOnRegisterBreak: { $set: signOnRegisterBreak }
        }
    });
    return newState;
}

function setBreakSignOnRegisterId(state, signOnRegisterId) {
    let newState = update(state, {
        signOnRegisterBreak: {
            signOnRegisterId: { $set: signOnRegisterId }
        }
    });
    return newState;
}

export default function signOn(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        case signOnActions.SIGN_ON_RESET:
            return { ...initialState };
        case signOnActions.SIGN_ON_REQUEST:
            return setLoading(userSignOnStart(state, {}), true);
        case signOnActions.SIGN_ON_REQUEST_FAILURE:
            return setLoading(state, false);
        case signOnActions.SIGN_ON_REQUEST_SUCCESS:
            return setLoading(checkUserResult(userSignOnStart(state, action.userSignOnStart), false, false), false);
        case signOnActions.SIGN_ON_CHECK_USER_REQUEST:
            return checkUser(state, action.venueId, action.userRoleId);
        case signOnActions.SIGN_ON_CHECK_USER_RESULT:
            return checkUserResult(state, action.isSignedOnToADifferentVenue, action.canSignOnToMultipleVenues);
        case signOnActions.SIGN_ON_SAVE_REQUEST:
            return setSaving(setUserSignOnStatus(state, action.userSignOnStatus), true, "");
        case signOnActions.SIGN_ON_SAVE_REQUEST_SUCCESS:
            return setSaving(state, false, "");
        case signOnActions.SIGN_ON_SAVE_REQUEST_FAILURE:
            return setSaving(state, false, action.error);
        case signOnActions.SIGN_OFF_REQUEST:
            return setLoading(setUserSignOnStatus(state, {}), true);
        case signOnActions.SIGN_OFF_REQUEST_FAILURE:
            return setLoading(state, false);
        case signOnActions.SIGN_OFF_REQUEST_SUCCESS:
            return setLoading(setSignOffDetails(setUserSignOnStatus(state, action.userSignOnStatus), action.signOffDetails), false);
        case signOnActions.SIGN_OFF_SAVE_REQUEST:
            return setSaving(setUserSignOnStatus(state, action.userSignOnStatus), true, "");
        case signOnActions.SIGN_OFF_SAVE_REQUEST_SUCCESS:
            return setSaving(state, false, "");
        case signOnActions.SIGN_OFF_SAVE_REQUEST_FAILURE:
            return setSaving(state, false, action.error);
        case signOnActions.SIGN_ON_EXTEND_REQUEST:
            return setLoading(setUserSignOnStatus(state, {}), true);
        case signOnActions.SIGN_ON_EXTEND_REQUEST_FAILURE:
            return setLoading(state, false);
        case signOnActions.SIGN_ON_EXTEND_REQUEST_SUCCESS:
            return setLoading(setSignOffDetails(setUserSignOnStatus(state, action.userSignOnStatus), {}), false);
        case signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST:
            return setSaving(setUserSignOnStatus(state, action.userSignOnStatus), true, "");
        case signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST_SUCCESS:
            return setSaving(state, false, "");
        case signOnActions.SIGN_ON_EXTEND_SAVE_REQUEST_FAILURE:
            return setSaving(state, false, action.error);
        case signOnActions.SIGN_ON_REGISTER_REQUEST:
            return setSignOnRegisterItems(setLoading(state, true), []);
        case signOnActions.SIGN_ON_REGISTER_REQUEST_SUCCESS:
            return setLoading(setSignOnRegister(setFilter(state, action.filter), action.items, action.paging), false);
        case signOnActions.SIGN_ON_REGISTER_REQUEST_FAILURE:
            return setLoading(state, false);
        case signOnActions.SIGN_ON_REGISTER_FILTER_REQUEST:
            return setLoading(state, true);
        case signOnActions.SIGN_ON_REGISTER_FILTER_REQUEST_FAILURE:
            return setLoading(state, false);
        case signOnActions.SIGN_ON_REGISTER_FILTER_REQUEST_SUCCESS:
            return setLoading(setFilter(state, action.signOnRegisterFilter), false);

        case signOnActions.SIGN_ON_BREAK_SET:
            return setBreak(state, action.signOnRegisterBreak);

        case signOnActions.SIGN_ON_BREAK_CLEAR:
            return setBreakSignOnRegisterId(state, 0);

        case signOnActions.SIGN_ON_BREAK_REQUEST:
            return setBreakLoading(setBreakSignOnRegisterId(state, action.signOnRegisterId), true, "");
        case signOnActions.SIGN_ON_BREAK_REQUEST_FAILURE:
            return setBreakLoading(state, false, action.error);
        case signOnActions.SIGN_ON_BREAK_REQUEST_SUCCESS:
            return setBreakLoading(setBreak(state, action.signOnRegisterBreak), false, "");

        case signOnActions.SIGN_ON_BREAK_UPSERT_REQUEST:
            return setBreakSaving(state, true, "");
        case signOnActions.SIGN_ON_BREAK_UPSERT_REQUEST_FAILURE:
            return setBreakSaving(state, false, action.error);
        case signOnActions.SIGN_ON_BREAK_UPSERT_REQUEST_SUCCESS:
            return setBreakSaving(setUserSignOnStatus(setBreak(state, {}), action.userSignOnStatus), false, "");

    }
    return state;
}
