import React, { Component } from 'react';
import MapWrapper from './MapWrapper'
import DarkButton from '../controls/DarkButton';
import CardContainer from './CardContainer';
import MapIcon from '@material-ui/icons/Map';
import Grid from '@material-ui/core/Grid';

export interface IMapProps {
    latitude: number;
    longitude: number;
    hideByDefault?: boolean;
    onRefreshLocation?: Function;
    height?: string;
    style?: any;
    draggableMarker?: boolean;
    onDragged?: Function;
    fullscreenControl?: boolean;
    zoomControl?: boolean;
    hideControls?: boolean;
}

interface IMapState {
    hidden: boolean;
    zoomLevel: number;
}


export default class Map extends React.Component<IMapProps, IMapState> {
    constructor(props: IMapProps) {
        super(props);
        this.state = {
            hidden: true,
            zoomLevel: 15
        }
        this.onShow = this.onShow.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onZoomChanged = this.onZoomChanged.bind(this);
    }

    onShow(show: boolean) {
        this.setState({
            hidden: !show
        });
    }

    onRefresh() {
        if (this.props.onRefreshLocation) {
            this.props.onRefreshLocation();
        }
    }

    onZoomChanged(zoomLevel) {
        this.setState({
            zoomLevel
        }, () => {
            //console.log('Map.onZoomChanged', this.state.zoomLevel)
        });
    }

    render() {
        let height = this.props.height || '220px';
        let map;
        if (!this.props.hideByDefault || !this.state.hidden) {
            let margin = this.props.hideControls ? '0px' : '10px';
            
            map = (
                <div key={this.props.longitude.toString() + "_" + this.props.longitude.toString()} style={{ textAlign: "center", display: 'block', margin: margin, ...this.props.style }}>
                    <MapWrapper
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB4D0gfDc7dpa248E0GmMar9U5fctLRL_k&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: height, width: `100%` }} />}
                        containerElement={<div style={{ height: height, width: `100%` }} />}
                        mapElement={<div style={{ height: height, width: `100%` }} />}
                        mapTypeControlOptions={{ mapTypeIds: [] }}
                        lat={this.props.latitude}
                        lng={this.props.longitude}
                        draggable={this.props.draggableMarker}
                        onDragged={this.props.onDragged}
                        zoomLevel={this.state.zoomLevel}
                        fullscreenControl={this.props.fullscreenControl}
                        zoomControl={this.props.zoomControl}
                        onZoomChanged={this.onZoomChanged}
                        isMarkerShown={true} />
                </div>
            );
        }

        if (!this.props.hideControls && this.props.onRefreshLocation && this.props.hideByDefault) {
            return (
                <Grid container spacing={1} >
                    <Grid item xs={6} >
                        <DarkButton text={this.state.hidden ? "Show location" : "Hide location"} onClick={() => this.onShow(this.state.hidden)} />
                    </Grid>
                    <Grid item xs={6} >
                        <DarkButton text={"Refresh Location"} onClick={() => this.onRefresh()} />
                    </Grid>
                    <Grid item xs={12} >
                        {map}
                    </Grid>
                </Grid>
            );
        } else if (!this.props.hideControls && this.props.onRefreshLocation) {
            return (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <DarkButton text={"Refresh Location"} onClick={() => this.onRefresh()} />
                    </Grid>
                    <Grid item xs={12} >
                        {map}
                    </Grid>
                </Grid>
            );
        } else if (!this.props.hideControls && this.props.hideByDefault) {
            return (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <DarkButton text={this.state.hidden ? "Show location" : "Hide location"} onClick={() => this.onShow(this.state.hidden)} />
                    </Grid>
                    <Grid item xs={12} >
                        {map}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        {map}
                    </Grid>
                </Grid>
            );
        }
    }
}

