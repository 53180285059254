import React, { Component } from 'react';
import * as AuscomplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import CheckboxControl from "../../controls/CheckboxControl";
import Information from '../../common/Information';

export interface IBroadcastNotificationAccessLevelsProps {
    broadcastNotification: AuscomplyDtos.BroadcastNotification;
    onUpdate: Function;
}

export default class BroadcastNotificationAccessLevels extends Component<IBroadcastNotificationAccessLevelsProps, any> {
    constructor(props: IBroadcastNotificationAccessLevelsProps) {
        super(props)
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let broadcastNotification = { ...this.props.broadcastNotification };
        broadcastNotification[fieldName] = value;
        this.props.onUpdate(broadcastNotification);
    }

    render() {

        return (
            <ControlGroup text={"Access Levels"}>
                <CheckboxControl text={"0"}
                    label={" "}
                    defaultValue={this.props.broadcastNotification.accessLevel0}
                    readonly={this.props.broadcastNotification.isSent}
                    onChanged={(value) => this.onValueChanged("accessLevel0", value)} />
                <CheckboxControl text={"1"}
                    label={" "}
                    defaultValue={this.props.broadcastNotification.accessLevel1}
                    readonly={this.props.broadcastNotification.isSent}
                    onChanged={(value) => this.onValueChanged("accessLevel1", value)} />
                <CheckboxControl text={"2"}
                    label={" "}
                    defaultValue={this.props.broadcastNotification.accessLevel2}
                    readonly={this.props.broadcastNotification.isSent}
                    onChanged={(value) => this.onValueChanged("accessLevel2", value)} />
                <CheckboxControl text={"3"}
                    label={" "}
                    defaultValue={this.props.broadcastNotification.accessLevel3}
                    readonly={this.props.broadcastNotification.isSent}
                    onChanged={(value) => this.onValueChanged("accessLevel3", value)} />
                <CheckboxControl text={"4"}
                    label={" "}
                    defaultValue={this.props.broadcastNotification.accessLevel4}
                    readonly={this.props.broadcastNotification.isSent}
                    onChanged={(value) => this.onValueChanged("accessLevel4", value)} />
            </ControlGroup>
        );
    }
}