import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import ErrorButton from '../../controls/ErrorButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import { RadioOption } from '../../../common/dto/common';
import LocalDateControlGroup from '../../controls/LocalDateControlGroup';

export interface IRemoveUserRoleProps {
    userRoleId: number,
    isLoading: boolean;
    userRole: AusComplyDtos.UserRole;
    userRoleTypes: AusComplyDtos.UserRoleType[];
    isSaving: boolean;
    error: string;
    onSuccess: Function;
    onLoad: Function;
    onChange: Function;
    onRemove: Function;
    theme: any;
    classes: any;
}

export default class RemoveUserRole extends Component<IRemoveUserRoleProps, any> {
    constructor(props: IRemoveUserRoleProps) {
        super(props)
        this.state = {
            error: {},
            adminNotes: ""
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onAdminNotesChanged = this.onAdminNotesChanged.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.userRoleId);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            this.setState({ error: {} });
        }
        if (!this.props.isSaving && prevProps.isSaving && !this.props.error && this.props.onSuccess) {
            this.setState({ error: {}, adminNotes: "" });
            this.props.onSuccess();
        }
    }

    onSubmit() {
        if (this.validate()) {
            this.props.onRemove(this.props.userRole.userRoleId, this.state.adminNotes);
        }
    }

    onAdminNotesChanged(value: any) {
        this.setState({ adminNotes: value });
    }

    validate() {
        let error = {};
        let isValid = true;
        if (this.state.adminNotes == "") {
            error["adminNotes"] = "Required";
            isValid = false;
        }
        this.setState({ error: error });
        return isValid;
    }


    render() {

        return (

            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    {this.props.userRole && (
                        <>
                            {(this.props.userRole.securityFirmID && this.props.userRole.securityFirmID != 0) && (
                                <TextControlGroup readonly={true} text={"Security Firm"} defaultValue={this.props.userRole.securityFirm} />
                            )}
                            {(this.props.userRole.venueID && this.props.userRole.venueID != 0) && (
                                <TextControlGroup readonly={true} text={"Venue"} defaultValue={this.props.userRole.venue} />
                            )}
                            {this.props.userRole.userRoleType && (
                                <TextControlGroup readonly={true} text={"Access Level"} defaultValue={this.props.userRole.userRoleType.name} />
                            )}
                            {this.props.userRole.userRoleStatus && (
                                <TextControlGroup readonly={true} text={"Status"} defaultValue={this.props.userRole.userRoleStatus.name} />
                            )}
                            <TextAreaControlGroup text={"Admin notes"}
                                defaultValue={this.state.adminNotes}
                                error={this.state.error["adminNotes"]}
                                onChange={value => this.onAdminNotesChanged(value)} />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ErrorButton text={"Remove"} onClick={this.onSubmit}></ErrorButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onSuccess()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>
            </LayoutForm>
        );
    }
}