import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import MoreCommands from '../../common/MoreCommands';

export interface IUserRoleRequestCardProps {
    userRoleRequest: AusComplyDtos.UserRoleRequest;
    onDismiss?: Function;
    classes: any;
    theme: any;
}

class UserRoleRequestCard extends React.Component<IUserRoleRequestCardProps, any> {
    constructor(props: IUserRoleRequestCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "dismiss":
                if (this.props.onDismiss) {
                    this.props.onDismiss(this.props.userRoleRequest.notificationID);
                }
                break;
        }
    }

    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        var commands: KeyName[] = [];
        if (this.props.onDismiss) {
            commands.push({ key: "dismiss", name: "Dismiss" });
        }
        return (
            <Card style={{ marginTop: '6px', marginBottom: '6px' }} className={className}>
                <CardContent>
                    <Box p={0} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <CardTitle>{this.props.userRoleRequest.type}</CardTitle>
                                    <CardTypography>{this.props.userRoleRequest.name}</CardTypography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <CardTitle>{this.props.userRoleRequest.dateFormatted}</CardTitle>
                                    <CardTypography style={{ fontStyle: 'italic' }}>{this.props.userRoleRequest.status}</CardTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={0}>
                            {(commands.length > 0) && (
                                <MoreCommands
                                    commands={commands}
                                    onCommand={this.onCommand}
                                    style={{ marginLeft: '20px' }} />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRequestCard);