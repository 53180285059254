import { makeActionCreator } from './helpers';

export const
    IMAGE_PROCESSING_VENUE_REQUEST = "IMAGE_PROCESSING_VENUE_REQUEST",
    IMAGE_PROCESSING_VENUE_REQUEST_SUCCESS = "IMAGE_PROCESSING_VENUE_REQUEST_SUCCESS",
    IMAGE_PROCESSING_VENUE_REQUEST_FAILURE = "IMAGE_PROCESSING_VENUE_REQUEST_FAILURE",
    IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST = "IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST",
    IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS = "IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS",
    IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_FAILURE = "IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_FAILURE",
    IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST = "IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST",
    IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_SUCCESS = "IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_SUCCESS",
    IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_FAILURE = "IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_FAILURE",
    IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST = "IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST",
    IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_SUCCESS = "IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_SUCCESS",
    IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_FAILURE = "IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_FAILURE"
    ;

export const imageProcessingVenueRequest = makeActionCreator(IMAGE_PROCESSING_VENUE_REQUEST, 'forVenueId', 'fromDate');
export const imageProcessingVenueRequestSuccess = makeActionCreator(IMAGE_PROCESSING_VENUE_REQUEST_SUCCESS, 'count');
export const imageProcessingVenueRequestFailure = makeActionCreator(IMAGE_PROCESSING_VENUE_REQUEST_FAILURE, 'error');
export const imageProcessingTemporaryFileRequest = makeActionCreator(IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST, 'filePath');
export const imageProcessingTemporaryFileRequestSuccess = makeActionCreator(IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS, 'imageText', 'filePath');
export const imageProcessingTemporaryFileRequestFailure = makeActionCreator(IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_FAILURE, 'error');
export const imageProcessingIncidentDocumentRequest = makeActionCreator(IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST, 'incidentDocumentId', 'saveToDocument');
export const imageProcessingIncidentDocumentRequestSuccess = makeActionCreator(IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_SUCCESS, 'imageText', 'incidentDocumentId', 'savedToDocument');
export const imageProcessingIncidentDocumentRequestFailure = makeActionCreator(IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_FAILURE, 'error');
export const imageProcessingIncidentPatronPhotoRequest = makeActionCreator(IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST, 'incidentPatronPhotoId', 'saveToDocument');
export const imageProcessingIncidentPatronPhotoRequestSuccess = makeActionCreator(IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_SUCCESS, 'imageText', 'incidentPatronPhotoId', 'savedToDocument');
export const imageProcessingIncidentPatronPhotoRequestFailure = makeActionCreator(IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_FAILURE, 'error');