import { makeActionCreator } from './helpers';

export const
    VENUEEVENTS_REQUEST = "VENUEEVENTS_REQUEST",
    VENUEEVENTS_REQUEST_SUCCESS = "VENUEEVENTS_REQUEST_SUCCESS",
    VENUEEVENTS_REQUEST_FAILURE = "VENUEEVENTS_REQUEST_FAILURE",
    VENUEEVENTS_REFRESH = "VENUEEVENTS_REFRESH",
    VENUEEVENT_CREATE_REQUEST = "VENUEEVENT_CREATE_REQUEST",
    VENUEEVENT_CREATE_REQUEST_SUCCESS = "VENUEEVENT_CREATE_REQUEST_SUCCESS",
    VENUEEVENT_CREATE_REQUEST_FAILURE = "VENUEEVENT_CREATE_REQUEST_FAILURE",
    VENUEEVENT_REQUEST = "VENUEEVENT_REQUEST",
    VENUEEVENT_REQUEST_SUCCESS = "VENUEEVENT_REQUEST_SUCCESS",
    VENUEEVENT_REQUEST_FAILURE = "VENUEEVENT_REQUEST_FAILURE",
    VENUEEVENT_UPDATE = "VENUEEVENT_UPDATE",
    VENUEEVENT_SAVE_REQUEST = "VENUEEVENT_SAVE_REQUEST",
    VENUEEVENT_SAVE_REQUEST_SUCCESS = "VENUEEVENT_SAVE_REQUEST_SUCCESS",
    VENUEEVENT_SAVE_REQUEST_FAILURE = "VENUEEVENT_SAVE_REQUEST_FAILURE",
    VENUEEVENT_RESET = "VENUEEVENT_RESET";


export const venueEventsRequest = makeActionCreator(VENUEEVENTS_REQUEST, 'page', 'pageSize', 'filter');
export const venueEventsRequestSuccess = makeActionCreator(VENUEEVENTS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const venueEventsRequestFailure = makeActionCreator(VENUEEVENTS_REQUEST_FAILURE, 'error');
export const venueEventCreateRequest = makeActionCreator(VENUEEVENT_CREATE_REQUEST);
export const venueEventCreateRequestSuccess = makeActionCreator(VENUEEVENT_CREATE_REQUEST_SUCCESS, 'venueEvent');
export const venueEventCreateRequestFailure = makeActionCreator(VENUEEVENT_CREATE_REQUEST_FAILURE, 'error');
export const venueEventRequest = makeActionCreator(VENUEEVENT_REQUEST, 'venueEventId');
export const venueEventRequestSuccess = makeActionCreator(VENUEEVENT_REQUEST_SUCCESS, 'venueEvent');
export const venueEventRequestFailure = makeActionCreator(VENUEEVENT_REQUEST_FAILURE, 'error');
export const venueEventSaveRequest = makeActionCreator(VENUEEVENT_SAVE_REQUEST, 'venueEvent');
export const venueEventSaveRequestSuccess = makeActionCreator(VENUEEVENT_SAVE_REQUEST_SUCCESS, 'venueEvent');
export const venueEventSaveRequestFailure = makeActionCreator(VENUEEVENT_SAVE_REQUEST_FAILURE, 'error');
export const venueEventUpdate = makeActionCreator(VENUEEVENT_UPDATE, 'venueEvent');
export const venueEventsRefresh = makeActionCreator(VENUEEVENTS_REFRESH);
export const venueEventReset = makeActionCreator(VENUEEVENT_RESET);