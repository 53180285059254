import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { venueLocationLogic } from '../../../common/logic/venueLocationLogic';

export interface ICellCardProps {
    venueLocation: AusComplyDtos.VenueLocation;
    onCommand?: Function;
}

export default class CellCard extends React.Component<ICellCardProps, any> {
    constructor(props: ICellCardProps) {
        super(props)
        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueLocation);
        }
    }

    render() {
        if (!this.props.venueLocation) {
            return (<></>);
        }

        var commands = venueLocationLogic.getCommands(this.props.venueLocation);
        var item = this.props.venueLocation;


        return (
            <CardRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.name}</CardTypography>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                        <CardTitle>Code</CardTitle>
                        <CardTypography>{item.code}</CardTypography>
                    </Grid>
                    <Grid item sm={3} xs={3}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography>{item.rank}</CardTypography>
                    </Grid>
                </Grid>
                {(item.facialRecognitionCameraSummary && item.facialRecognitionCameraSummary != '') &&
                    (
                        <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                            <Grid item xs={12}>
                                <CardTitle>Cameras</CardTitle>
                                <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{item.facialRecognitionCameraSummary}</CardTypography>
                            </Grid>
                        </Grid>
                    )}
            </CardRow>
        );
    }
}