import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export interface IProgressingProps {
    message?: string;
    isWarning?: boolean;
    classes: any;
    style?: any;
}

class Progressing extends React.PureComponent<IProgressingProps, any> {
    render() {
        const { classes } = this.props;
        let color = classes.linearBarColorPrimary;
        if (this.props.isWarning) {
            color = classes.linearBarColorWarning;
        }
        return (
            <div style={{ display: 'block', margin: '10px 0', ...this.props.style }}>
                <LinearProgress classes={{
                    colorPrimary: classes.linearColorPrimary,
                    barColorPrimary: color
                }} />
                {this.props.message && (
                    <h6 style={{textAlign: 'center', margin: '5px'}}>{this.props.message}...</h6>
                )}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Progressing);