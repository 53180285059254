import * as AusComplyDtos from "../dto/AusComply.dtos";

export const externalApiConnectionLogic = {
    buildKey(entityType: AusComplyDtos.ngtEntityType, venueId: number, securityFirmId: number) {
        let key = entityType.toString();
        if (venueId != undefined && venueId != null) {
            key = key + "_" + venueId.toString();
        } else {
            key = key + "_0";
        }
        if (securityFirmId != undefined && securityFirmId != null) {
            key = key + "_" + securityFirmId.toString();
        } else {
            key = key + "_0";
        }
        return key;
    },
    findExternalApiConnection(state, entityType: AusComplyDtos.ngtEntityType, venueId, securityFirmId) {
        let key = this.buildKey(entityType, venueId, securityFirmId);
        if (state.externalApiConnections && state.externalApiConnections.externalApiConnections) {
            if (state.externalApiConnections.externalApiConnections[key] != undefined && 
                state.externalApiConnections.externalApiConnections[key] != null) {
                    return state.externalApiConnections.externalApiConnections[key].externalApiConnection;
            }
        }
        return {
            isConnected: false
        };
    }
}