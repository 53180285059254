import { connect } from 'react-redux';
import * as incidentActions from '../common/actions/incidents';
import SnapshotSummary from '../components/home/SnapshotSummary';

const mapStateToProps = state => {
    return {
        isLoading: state.incidents.snapshotSummary.isLoading,
        snapshotSummary: state.incidents.snapshotSummary.snapshotSummary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () => {
            dispatch(incidentActions.incidentsSnapshotSummaryRequest())
        }
    }
}

const SnapshotSummaryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SnapshotSummary)

export default SnapshotSummaryContainer