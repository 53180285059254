import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import {
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST,
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_INMATE_CELL_RESET,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_CELL,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE_VENUELOCATION,
    CORRECTIONAL_FACILITY_INMATE_CELL_SET_SEARCH,
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST,
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST,
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST,
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_SET,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST,
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_RISK_STATUS_SET,
    CORRECTIONAL_FACILITY_RISK_STATUS_RESET,
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST,
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_FAILURE,
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST,
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_SUCCESS,
    CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_FAILURE,
} from "../actions/correctionalFacility";

const initialState = {
    inmatesCells: {
        isLoading: false,
        isSaving: false,
        correctionalFacilityInMateCells: [],
        cells: [],
        risks: [],
        genders: [],
        nationalities: [],
        draftIncidentId: 0
    },
    createInMateCell: {
        isLoading: false,
        isSaving: false,
        isValid: false,
        search: "",
        hasSearched: false,
        error: "",
        correctionalFacilityInMateCell: {},
        inmates: [],
        cells: [],
        risks: [],
        genders: [],
        nationalities: []
    },
    editInMateCell: {
        isSaving: false,
        isValid: false,
        error: "",
        correctionalFacilityInMateCell: undefined,
        cells: [],
        risks: []
    },
    riskStatuses: {
        isLoading: false,
        riskStatuses: [],
        paging: { pageSize: 0, page: 1 }
    },
    riskStatus: { 
        isLoading: false,
        isSaving: false,
        riskStatus: { correctionalFacilityRiskStatusId: -1 },
    }
};

function setInmatesCellsIsLoading(state, isLoading) {
    let newState = update(state, {
        inmatesCells: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setInmatesCellsIsSaving(state, isSaving) {
    let newState = update(state, {
        inmatesCells: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setInmatesCells(state, data, cells, risks, genders, nationalities) {
    let newState = update(state, {
        inmatesCells: {
            correctionalFacilityInMateCells: { $set: data },
            cells: { $set: cells },
            risks: { $set: risks },
            genders: { $set: genders },
            nationalities: { $set: nationalities}
        }
    });
    return newState;
}

function setInmateCellIsLoading(state, isLoading) {
    let newState = update(state, {
        createInMateCell: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setInmateCellIsSaving(state, isSaving) {
    let newState = update(state, {
        createInMateCell: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setInmateCellSearch(state, search) {
    let newState = update(state, {
        createInMateCell: {
            search: { $set: search }
        }
    });
    return newState;
}

function setInmateCellHasSearch(state, hasSearched) {
    let correctionalFacilityInMateCell = { ...state.createInMateCell.correctionalFacilityInMateCell };
    if (hasSearched) {
        // inmates found
        if (state.createInMateCell.inmates.length == 0) {
            // create
            correctionalFacilityInMateCell.correctionalFacilityInMate = new AusComplyDtos.CorrectionalFacilityInMate();
            correctionalFacilityInMateCell.correctionalFacilityInMate.correctionalFacilityInMateId = 0;
        } else if (state.createInMateCell.inmates.length == 1) {
            // use
            correctionalFacilityInMateCell.correctionalFacilityInMate = { ...state.createInMateCell.inmates[0] };
        } else {
            // selection required
            correctionalFacilityInMateCell.correctionalFacilityInMate = undefined;
        }
    }
    let newState = update(state, {
        createInMateCell: {
            hasSearched: { $set: hasSearched },
            correctionalFacilityInMateCell: { $set: correctionalFacilityInMateCell }
        }
    });
    return validateCreate(newState);
}

function setInmateCellInmate(state, correctionalFacilityInMate) {
    let newState = update(state, {
        createInMateCell: {
            correctionalFacilityInMateCell: {
                correctionalFacilityInMate: { $set: correctionalFacilityInMate }
            }
        }
    });
    return validateCreate(newState);
}

function setInmateCellVenueLocation(state, correctionalFacilityInMateVenueLocation) {
    let newState = update(state, {
        createInMateCell: {
            correctionalFacilityInMateCell: {
                correctionalFacilityInMateVenueLocation: { $set: correctionalFacilityInMateVenueLocation }
            }
        }
    });
    return validateCreate(newState);
}

function setInmateCell(state, data) {
    let newState = update(state, {
        createInMateCell: {
            correctionalFacilityInMateCell: { $set: data }
        }
    });
    return validateCreate(newState);
}

function setInmateCellOptions(state, inmates, cells, risks, genders, nationalities) {
    let newState = update(state, {
        createInMateCell: {
            inmates: { $set: inmates },
            cells: { $set: cells },
            risks: { $set: risks },
            genders: { $set: genders },
            nationalities: { $set: nationalities}
        }
    });
    return newState;
}

function validateCreate(state) {
    let isValid = checkValid(state.createInMateCell.correctionalFacilityInMateCell);
   
    let newState = update(state, {
        createInMateCell: {
            isValid: { $set: isValid }
        }
    });
    return newState;
}

function checkValid(correctionalFacilityInMateCell) {
    // check for an inmate
    if (correctionalFacilityInMateCell &&
        correctionalFacilityInMateCell.correctionalFacilityInMate &&
        correctionalFacilityInMateCell.correctionalFacilityInMate.correctionalFacilityInMateId > -1) {
        // check for a cell
        if (correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation &&
            correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.venueLocationId &&
            correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.venueLocationId > 0) {
            // check the risk
            if (correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.correctionalFacilityRiskStatusId &&
                correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.correctionalFacilityRiskStatusId > 0) {
                // check the interval
                if (correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation.inspectionInterval > 0) {
                    return true;
                }
            }
        }
    }
    return false;
}

function setInmateCellError(state, error) {
    let newState = update(state, {
        createInMateCell: {
            error: { $set: error }
        }
    });
    return validateCreate(newState);
}

function setEditInmateCell(state, correctionalFacilityInMateCell) {
    let newState = update(state, {
        editInMateCell: {
            correctionalFacilityInMateCell: { $set: correctionalFacilityInMateCell }
        }
    });
    return validateEdit(newState);
}

function setEditInmateCellIsSaving(state, isSaving) {
    let newState = update(state, {
        editInMateCell: {
            isSaving: { $set: isSaving }
        }
    });
    return validateEdit(newState);
}

function setInmatesCellsDraftIncidentId(state, draftIncidentId) {
    let newState = update(state, {
        inmatesCells: {
            draftIncidentId: { $set: draftIncidentId }
        }
    });
    return validateEdit(newState);
}

function validateEdit(state) {
    let isValid = checkValid(state.editInMateCell.correctionalFacilityInMateCell);
   
    let newState = update(state, {
        editInMateCell: {
            isValid: { $set: isValid }
        }
    });
    return newState;
}

function setRiskStatusesIsLoading(state, isLoading) {
    let newState = update(state, {
        riskStatuses: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRiskStatuses(state, riskStatuses) {
    let newState = update(state, {
        riskStatuses: {
            riskStatuses: { $set: riskStatuses }
        }
    });
    return newState;
}

function setRiskStatusesPaging(state, paging) {
    let newState = update(state, {
        riskStatuses: {
            paging: { $set: paging }
        }
    });
    return newState;
}

function setRiskStatusIsSaving(state, isSaving) {
    let newState = update(state, {
        riskStatus: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setRiskStatus(state, riskStatus) {
    let newState = update(state, {
        riskStatus: {
            riskStatus: { $set: riskStatus }
        }
    });
    return newState;
}

export default function correctionalFacility(state = initialState, action) {
    switch (action.type) {
        case CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST:
            return setInmatesCellsIsLoading(state, true);
        case CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_SUCCESS:
            return setInmatesCellsIsLoading(setInmatesCells(state, action.data, action.cells, action.risks, action.genders, action.nationalities), false);
        case CORRECTIONAL_FACILITY_INMATES_CELLS_REQUEST_FAILURE:
            return setInmatesCellsIsLoading(state, false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_RESET:
            return setInmateCellOptions(setInmateCell(setInmateCellHasSearch(state, false), {}), [], [], [], [], []);
        case CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST:
            return setInmateCellIsLoading(setInmateCellSearch(setInmateCellHasSearch(state, false), action.search), true);
        case CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_SUCCESS:
            return setInmateCellIsLoading(setInmateCellHasSearch(setInmateCellOptions(setInmateCell(state, action.correctionalFacilityInMateCell), action.inmates, action.cells, action.risks, action.genders, action.nationalities), true), false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_CREATE_REQUEST_FAILURE:
            return setInmateCellIsLoading(setInmateCellHasSearch(setInmateCellError(state, action.error), false), false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_SET_SEARCH:
            return setInmateCellSearch(setInmateCellHasSearch(state, false), action.search);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE:
            return setInmateCellInmate(state, action.correctionalFacilityInMate);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_INMATE_VENUELOCATION:
            return setInmateCellVenueLocation(state, action.correctionalFacilityInMateVenueLocation);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_CELL:
            return setInmateCell(state, action.correctionalFacilityInMateCell);
        case CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST:
            return setInmateCellIsSaving(state, true);
        case CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_SUCCESS:
            return setInmateCellIsSaving(setInmateCellOptions(setInmateCell(setInmateCellHasSearch(setInmateCellSearch(setInmateCellHasSearch(state, false), ""), false), {}), [], [], [], [], []), false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_SAVE_REQUEST_FAILURE:
            return setInmateCellIsSaving(setInmateCellError(state, action.error), false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST:
            return setInmatesCellsIsSaving(state, true);
        case CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_SUCCESS:
            return setInmatesCellsIsSaving(state, false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_REMOVE_REQUEST_FAILURE:
            return setInmatesCellsIsSaving(state, false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_SET:
            return setEditInmateCell(state, action.correctionalFacilityInMateCell);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST:
            return setEditInmateCellIsSaving(state, true);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_FAILURE:
            return setEditInmateCellIsSaving(state, false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_UPDATE_REQUEST_SUCCESS:
            return setEditInmateCellIsSaving(setEditInmateCell(state, undefined), false);
        case CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST:
            return setRiskStatusesIsLoading(state, true);
        case CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_SUCCESS: 
            return setRiskStatusesIsLoading(setRiskStatuses(setRiskStatusesPaging(state, action.paging), action.correctionalFacilityRiskStatuses), false);
        case CORRECTIONAL_FACILITY_RISK_STATUSES_REQUEST_FAILURE:
            return setRiskStatusesIsLoading(state, false);
        case CORRECTIONAL_FACILITY_RISK_STATUS_SET:
            return setRiskStatus(state, action.correctionalFacilityRiskStatus);
        case CORRECTIONAL_FACILITY_RISK_STATUS_RESET:
            return setRiskStatus(state, { correctionalFacilityRiskStatusId: -1 });
        case CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST:
            return setRiskStatusIsSaving(state, true);
        case CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_SUCCESS:
            return setRiskStatusIsSaving(setRiskStatuses(setRiskStatusesPaging(setRiskStatus(state, { correctionalFacilityRiskStatusId: -1 }), action.paging), action.correctionalFacilityRiskStatuses), false);
        case CORRECTIONAL_FACILITY_RISK_STATUS_SAVE_REQUEST_FAILURE:
            return setRiskStatusIsSaving(state, false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST:
            return setInmatesCellsIsSaving(setInmatesCellsDraftIncidentId(state, 0), true);
        case CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_SUCCESS:
            return setInmatesCellsIsSaving(setInmatesCellsDraftIncidentId(state, action.draftIncidentId), false);
        case CORRECTIONAL_FACILITY_INMATE_CELL_INSPECT_REQUEST_FAILURE:
            return setInmatesCellsIsSaving(state, false);
    }
    return state;
}