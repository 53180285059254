import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import SubTitle from '../../common/SubTitle';
import PagedList from '../../common/PagedList';
import PageMessage from '../../common/PageMessage';
import DefaultButton from '../../controls/DefaultButton';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueReportingPeriodCard from './VenueReportingPeriodCard';
import VenueReportingPeriodHeader from './VenueReportingPeriodHeader';
import VenueReportingPeriodRow from './VenueReportingPeriodRow';
import VenueEventReportingPeriodCard from './VenueEventReportingPeriodCard';
import Spacer from '../../common/Spacer';

export interface IVenueReportingPeriodCurrentProps {
    isLoading: boolean;
    isSaving: boolean;
    venueId: number;
    venueReportingPeriod: AusComplyDtos.VenueReportingPeriod;
    venueEventReportingPeriods: AusComplyDtos.VenueReportingPeriod[];
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onClose: Function;
}

interface IVenueReportingPeriodCurrentState {
}

class VenueReportingPeriodCurrent extends Component<IVenueReportingPeriodCurrentProps, IVenueReportingPeriodCurrentState> {
    constructor(props: IVenueReportingPeriodCurrentProps) {
        super(props)

        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
    }

    onCommand(command: string, venueReportingPeriod: AusComplyDtos.VenueReportingPeriod) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(venueReportingPeriod.venueReportingPeriodId);
                break;
            case "stop":
                this.props.onClose(venueReportingPeriod.venueReportingPeriodId);
                break;
            default:
                break;
        }
    }

    render() {
        let content;
        let events;
        if (this.props.isLoading) {
            content = (
                <Loading />
            );
        } else if (this.props.isSaving) {
            content = (
                <Saving />
            );
        } else if (!this.props.venueReportingPeriod || this.props.venueReportingPeriod.venueReportingPeriodId == 0) {
            content = (
                <PageMessage text={"No current reporting period"} >
                    <Box p={1} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}></Box>
                        <Box p={0}>
                            <DefaultButton 
                                onClick={() => this.onCommand("add", new AusComplyDtos.VenueReportingPeriod())} 
                                text="Start reporting period" />
                        </Box>
                        <Box flexGrow={1} p={0}></Box>
                    </Box>
                </ PageMessage>
            );
        } else if (this.props.venueReportingPeriod) {
            content = (
                <VenueReportingPeriodCard
                    onCommand={this.onCommand}
                    key={"card-" + this.props.venueReportingPeriod.venueReportingPeriodId.toString()}
                    venueReportingPeriod={this.props.venueReportingPeriod}
                />
            );
        }
        if(!this.props.isLoading && !this.props.isSaving && this.props.venueEventReportingPeriods && this.props.venueEventReportingPeriods.length > 0) {
            events = <>
                <SubTitle text={"Current Events"} />
                {this.props.venueEventReportingPeriods.map((item, index) => <VenueEventReportingPeriodCard key={"event-card-" + item.venueReportingPeriodId.toString()} venueReportingPeriod={item} onCommand={this.onCommand} />)}
            </>
        }
        return (
            <>
                <SubTitle text={"Current"} />
                {content}
                {events}
            </>
        );
    }
}

export default VenueReportingPeriodCurrent;