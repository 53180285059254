import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { KeyName } from '../../common/dto/common';
import MoreCommands from '../common/MoreCommands';
import classNames from 'classnames';

export interface ITableRowProps {
    commands?: KeyName[];
    onCommand?: Function;
    onClick?: Function;
    isObsolete?: boolean;
    isDanger?: boolean;
    isWarning?: boolean;
    isInfo?: boolean;
    isSuccess?: boolean;
    isRejected?: boolean;
    style?: any;
    classes: any;
    theme: any;
}

class TableRow extends React.Component<ITableRowProps, any> {
    constructor(props: ITableRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command);
        }
    }

    onRowClick() {
        if (!this.props.commands && this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { classes } = this.props;
        let className = classes.rowStatusPending;
        let classNameFields = classes.rowFields;
        if (this.props.isObsolete) {
            className = classes.rowStatusObsolete;
            classNameFields = classes.rowFieldsObsolete;
        } else if (this.props.isDanger) {
            className = classes.rowStatusDanger;
        } else if (this.props.isWarning) {
            className = classes.rowStatusDraft;
        } else if (this.props.isInfo) {
            className = classes.rowStatusInfo;
        } else if (this.props.isSuccess) {
            className = classes.rowStatusApproved;
        } else if (this.props.isRejected) {
            className = classes.rowStatusRejected;
        }


        return (
            <tr className={classNames(classes.tableTr, classNameFields)} style={{...this.props.style}} onClick={() => this.onRowClick()} >
                <td className={className} ></td>
                {this.props.children}
                <td>
                    {(this.props.commands && this.props.commands.length > 0) && (
                        <MoreCommands
                            commands={this.props.commands}
                            onCommand={this.onCommand} />
                    )}
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(TableRow);