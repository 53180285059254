
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { scheduleServiceApi } from '../services/scheduleService';

import * as scheduleActions from "../actions/schedule";

import {
    notifyError, notifyErrorMessage
} from './common';
import { notificationSuccessShow, notificationShow } from "../actions/notification";


const schedulesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.find(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => scheduleActions.schedulesRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "schedulesRequestEpic.find", scheduleActions.schedulesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "schedulesRequestEpic", scheduleActions.schedulesRequestFailure))
    );

const schedulesSecurityFirmRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULES_SECURITYFIRMS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.find(undefined, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => scheduleActions.schedulesRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "schedulesRequestEpic.find", scheduleActions.schedulesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "schedulesSecurityFirmRequestEpic", scheduleActions.schedulesRequestFailure))
    );

const scheduleRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['scheduleId'])
                .pipe(
                    map(response => scheduleActions.scheduleRequestSuccess(
                        response.schedule,
                        response.checklistTemplates,
                        response.scheduleDatas
                    )),
                    catchError(error => notifyError(error, "scheduleRequestEpic.get", scheduleActions.scheduleRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleRequestEpic", scheduleActions.scheduleRequestFailure))
    );

const scheduleCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => scheduleActions.scheduleCreateRequestSuccess(
                        response.schedule,
                        response.checklistTemplates,
                        response.scheduleDatas
                    )),
                    catchError(error => notifyError(error, "scheduleCreateEpic.create", scheduleActions.scheduleCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleCreateEpic", scheduleActions.scheduleCreateRequestFailure))
    );

const scheduleSecurityFirmsCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_CREATE_SECURITYFIRM_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.create(
                undefined,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => scheduleActions.scheduleCreateRequestSuccess(
                        response.schedule,
                        response.checklistTemplates,
                        response.scheduleDatas
                    )),
                    catchError(error => notifyError(error, "scheduleCreateEpic.create", scheduleActions.scheduleCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleSecurityFirmsCreateEpic", scheduleActions.scheduleCreateRequestFailure))
    );

const scheduleUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['schedule'])
                .pipe(
                    map(response => scheduleActions.scheduleUpsertRequestSuccess(response.schedule)),
                    catchError(error => notifyError(error, "scheduleUpsertEpic.upsert", scheduleActions.scheduleUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleUpsertEpic", scheduleActions.scheduleUpsertRequestFailure))
    );

const scheduleProcessRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_PROCESS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.process(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['scheduleId'])
                .pipe(
                    map(response => scheduleActions.scheduleProcessRequestSuccess()),
                    catchError(error => notifyError(error, "scheduleProcessRequestEpic.process", scheduleActions.scheduleProcessRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleProcessRequestEpic", scheduleActions.scheduleProcessRequestFailure))
    );

const scheduleProcessRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_PROCESS_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Processed Successfully"))
    );

    /*
const scheduleProcessRequestSuccessReloadEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_PROCESS_REQUEST_SUCCESS),
        map((action: any) => scheduleActions.schedulesRequest())
    );*/

const scheduleProcessRequestFailureReloadEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_UPSERT_REQUEST_FAILURE),
        map((action: any) => scheduleActions.schedulesRequest())
    );

const scheduleLogsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_LOGS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.scheduleLogs(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,
                action['scheduleId'],
                action['paging'])
                .pipe(
                    map(response => scheduleActions.scheduleLogsRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "scheduleLogsRequestEpic.scheduleLogs", scheduleActions.scheduleLogsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleLogsRequestEpic", scheduleActions.scheduleLogsRequestFailure))
    );

const scheduleCopyEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_COPY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.copy(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,
                action['scheduleId'])
                .pipe(
                    map(response => scheduleActions.scheduleCreateRequestSuccess(
                        response.schedule,
                        response.checklistTemplates,
                        response.scheduleDatas
                    )),
                    catchError(error => notifyError(error, "scheduleCopyEpic.copy", scheduleActions.scheduleCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleCopyEpic", scheduleActions.scheduleCreateRequestFailure))
    );

const scheduleLogDeleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(scheduleActions.SCHEDULE_LOG_DELETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            scheduleServiceApi.deleteScheduleLog(
                state.user.details.userSession.user.venueId, 
                state.user.details.userSession.user.securityFirmId,
                action['scheduleLogId'],
                action['scheduleId'],
                action['paging'])
                .pipe(
                    map(response => scheduleActions.scheduleLogDeleteRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "scheduleLogDeleteRequestEpic.scheduleLogs", scheduleActions.scheduleLogDeleteRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "scheduleLogDeleteRequestEpic", scheduleActions.scheduleLogDeleteRequestFailure))
    );


export const scheduleEpics = combineEpics(
    schedulesRequestEpic,
    scheduleRequestEpic,
    scheduleCreateEpic,
    scheduleUpsertEpic,
    scheduleProcessRequestEpic,
    scheduleProcessRequestSuccessEpic,
    //scheduleProcessRequestSuccessReloadEpic,
    scheduleProcessRequestFailureReloadEpic,
    scheduleLogsRequestEpic,
    schedulesSecurityFirmRequestEpic,
    scheduleSecurityFirmsCreateEpic,
    scheduleCopyEpic,
    scheduleLogDeleteRequestEpic
);