/*
export const
    BASE_URL = process.env.REACT_APP_API;

export const
    BASE_PATH = process.env.REACT_APP_BASE_PATH;

export const
    BASE_IMAGES_PATH = process.env.REACT_APP_BASE_IMAGES_PATH;
*/


export const appSource = {
    getBaseUrl: () => {
        return process.env.REACT_APP_API;
    },
    getBasePath: () => {
        return process.env.REACT_APP_BASE_PATH;
    },
    getBaseImagesPath: () => {
        return process.env.REACT_APP_BASE_IMAGES_PATH;
    },
    isDemoMode: () => {
        if (process.env.REACT_APP_API !== undefined) {
            return process.env.REACT_APP_API.startsWith("https://demo.");
        }
        return false;
    },
};

export const FILE_UPLOAD_MAX_SIZE_MB: number = 200;

export const STORE_TIMEOUT = undefined;