import { JsonServiceClient } from "@servicestack/client";
import { LocalStorage } from '../../utilities/storage';
import { appSource } from '../../utilities/constants';

const fakeError = function () {

}

export class AusComplyJsonServiceClient extends JsonServiceClient {
  constructor() {
    super(appSource.getBaseUrl());

    if (typeof window != "undefined" && typeof (<any>window).DOMException == "undefined") {
      (<any>window).DOMException = fakeError;
    }

    let bearerToken = LocalStorage.get("bearerToken");
    if (bearerToken) {
      this.setBearerToken(bearerToken);
    }

    let refreshToken = LocalStorage.get("refreshToken");
    if (refreshToken) {
      this.refreshToken = refreshToken;
    }

    this.requestFilter = req => {
      if (req.headers) {
        // allow the service to optimise the request for an app while we are running and pwa/native in same code base
        (req.headers as any).append("x-auscomply-source","app");
      }
    };

    this.responseFilter = res => {
      try {
        var ids = res.headers.get("X-MiniProfiler-Ids");
        if (ids != null && (<any>window).MiniProfiler) {
          (<any>window).MiniProfiler.fetchResultsExposed(JSON.parse(ids));
        }
      } catch (error) {

      }
    };


  }
}
