import { connect } from 'react-redux'
import EditRoleType from '../components/admin/roleTypes/editRoleType/EditRoleType'
import { roleTypeLoadRequest, roleTypeUpdate, roleTypeUpdateSave  } from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        roleTypeId: props.roleTypeId,
        isLoading: state.userRole.roleType.isLoading,
        isNewRoleType: false,
        roleType: state.userRole.roleType.roleType,
        availableUserPermissions: state.userRole.roleType.availableUserPermissions,
        availableIndustryCategories: state.userRole.roleType.availableIndustryCategories,
        availableStates: state.userRole.roleType.availableStates,
        isSaving: state.userRole.isSaving,
        error: state.userRole.error,
        onSuccess: props.onSuccess,
        onClose: props.onClose
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (roleTypeId) => {
            dispatch(roleTypeLoadRequest(roleTypeId))
        },
        onChange: (roleType) => {
            dispatch(roleTypeUpdate(roleType))
        },
        onSave: (roleType) => {
            dispatch(roleTypeUpdateSave(roleType, false, undefined, undefined))
        }
    }
}

const AdminEditRoleTypeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditRoleType)

export default AdminEditRoleTypeContainer