import React from 'react';
import { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import classNames from 'classnames';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ISecondaryFabProps {
    classes: any;
    theme: any;
    onClick?: Function;
    isExtended?: boolean;
    actions?: any;
    style?: any;
}

class SecondaryFab extends Component<ISecondaryFabProps, any> {
    constructor(props: ISecondaryFabProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        const { classes } = this.props;
        let className = classNames(classes.fab, classes.fabSecondary);

        if (this.props.isExtended) {
            return (
                <Fab
                    className={className}
                    color={'inherit'}
                    variant="extended"
                    size="small"
                    onClick={() => this.onClick()}
                    style={this.props.style} >
                    {this.props.children || <></>}
                </Fab>
            );
        }
        return (
            <Fab
                className={className}
                color={'inherit'}
                size="small"
                onClick={() => this.onClick()}
                style={this.props.style}  >
                {this.props.children || <></>}
            </Fab>
        );
    }
}


export default withStyles(styles, { withTheme: true })(SecondaryFab);