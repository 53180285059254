import { connect } from 'react-redux'
import WelcomeUserComplianceDocuments from '../components/home/WelcomeUserComplianceDocuments'
import * as profileActions from '../common/actions/profile';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        isLoading: state.profile.isProfileLoading || state.user.isLoading,
        isSaving: state.profile.isSaving,
        documents: state.profile.complianceDocuments,
        documentTypes: state.profile.complianceDocumentTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(profileActions.profileRequest())
        },
        onSaveComplianceDocuments: (documentTypeId, expiryDate, files) => {
            dispatch(profileActions.profileSaveComplianceDocuments(documentTypeId, expiryDate, files))
        },
        onRemoveComplianceDocument: (complianceDocument) => {
            dispatch(profileActions.profileRemoveComplianceDocument(complianceDocument))
        },
    }
}

const WelcomeUserComplianceDocumentsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WelcomeUserComplianceDocuments)

export default WelcomeUserComplianceDocumentsContainer