import React from 'react';
import { RadioOption } from '../../common/dto/common';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import View from '../common/View';
import PrimaryButton from './PrimaryButton';
import TransparentButton from './TransparentButton';

export interface IRadioButtonControlProps {
    name: string;
    selected?: string;
    options?: RadioOption[];
    onChange?: Function;
    readonly?: boolean;
}
export interface IRadioButtonControlState {
    selected: string;
    options: RadioOption[];
    displayOptions: RadioOption[];
}

class RadioButtonControl extends React.PureComponent<IRadioButtonControlProps, IRadioButtonControlState> {

    constructor(props: IRadioButtonControlProps) {
        super(props);

        this.state = {
            selected: "",
            options: [],
            displayOptions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    async componentDidMount() {
        var selected = this.props.selected || "";
        var options = this.props.options || [];
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        this.setState({
            selected,
            options,
            displayOptions
        });
    }

    componentDidUpdate() {
        var selected = this.props.selected || this.state.selected;
        var options = this.props.options || this.state.options;
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        if (selected != this.state.selected ||
            JSON.stringify(options) != JSON.stringify(this.state.options) ||
            JSON.stringify(displayOptions) != JSON.stringify(this.state.displayOptions)) {
            this.setState({
                selected,
                options,
                displayOptions
            });
        }
    }

    handleChange = item => {
        let val = item;
        if (val != null && !this.props.readonly) {
            this.setState({ selected: val });
            if (this.props.onChange) {
                this.props.onChange({ value: val });
            }
        }
    };


    render() {
        let radioOptions = this.state.options.map((item, index) => {
            let paddingLeft = 0;// index % 2 == 1 ? 3 : 0;
            let paddingRight = 0;// index % 2 != 1 ? 3 : 0;
            let paddingTop = '10px';// index > 1 ? 6 : 0;

            return (
                <View key={item.id} style={{ paddingLeft, paddingRight, paddingTop, paddingBottom: 0, minWidth: '140px' }}>
                    {this.props.selected !== undefined && this.props.selected.toString() == item.id && (
                        <PrimaryButton noWrap={true} text={item.name} onClick={() => this.handleChange(item.id)} />
                    )}
                    {this.props.selected === undefined || this.props.selected.toString() !== item.id && (
                        <TransparentButton noWrap={true} text={item.name} onClick={() => this.handleChange(item.id)} />
                    )}
                </View>
            )
        });

        return (
            <div
                style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-around', flexWrap: 'wrap' }}
            >
                {radioOptions}
            </div>
        );
    }
}

export default RadioButtonControl;