import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import CheckListItem from '../../checklists/checklist/CheckListItem';
import DefaultButton from '../../controls/DefaultButton';
import ErrorButton from '../../controls/ErrorButton';
import EditIcon from '@material-ui/icons/Edit';
import zIndex from '@material-ui/core/styles/zIndex';

export interface IIncidentViewChecklistsProps {
    incident: IncidentDTO;
    openIncident?: Function;
}

export default class IncidentViewChecklists extends Component<IIncidentViewChecklistsProps, any> {
    render() {
        if (!this.props.incident.checklists || this.props.incident.checklists.length == 0) {
            return (<></>);
        }

        let otherVenueIncident: boolean = false;
        if (this.props.incident.accessViaVenueId && this.props.incident.accessViaVenueId > 0)
        {
            otherVenueIncident = true;
        }

        let checklists = this.props.incident.checklists.map((checklist, index) => {
            return (
                <ControlGroup text={checklist.name} 
                    key={"incident-checklist" + index + "-" + checklist.checklistId.toString()}>
                    {checklist.checkListDisplayItems && checklist.checkListDisplayItems.map((item, itemIndex) => {
                        return <div key={"incident-checklist-item" + index + "-" + itemIndex} 
                        style={{ position: 'relative' }}>
                            {(!otherVenueIncident && item.isRequired && this.props.openIncident) && (
                                <ErrorButton style={{
                                     position: 'absolute',
                                      top: '5px', right: '5px', 
                                      padding: '1px', zIndex: 1 }} onClick={this.props.openIncident}>
                                    <EditIcon style={{ fontSize: '1.3rem' }} />
                                </ErrorButton>
                            )}
                            <CheckListItem 
                                key={"incident-checklist-item" + item.template.checklistTemplateItemId} 
                                checkListDisplayItem={item} 
                                checklistUserSelections={this.props.incident.checklistUserSelections}
                                isUploading={false} isReadonly={true} onUploadAttachment={() => { }} onUpdate={() => { }} />
                        </div>
                    })}
                </ControlGroup>
            );
        });
        return (
            <>{checklists}</>
        );
    }
}