import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ILabelProps {
    theme: any;
    text: string;
    style?: any;
}

class Label extends React.PureComponent<ILabelProps, any> {
    constructor(props: ILabelProps){
        super(props)
    }
    render() {
        const { theme } = this.props;
        return (
            <label
                style={{...theme.custom.label, ...this.props.style}}
                >{this.props.text}</label>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Label);