import React, { Component } from 'react';
import BroadcastNotificationsContainer from '../../../containers/BroadcastNotificationsContainer'

export interface ISecurityFirmBroadcastNotificationss {
    securityFirmId: number;
}

export default class SecurityFirmBroadcastNotifications extends Component<ISecurityFirmBroadcastNotificationss, any> {
    render() {
        return (
            <BroadcastNotificationsContainer securityFirmId={this.props.securityFirmId}/>
        );
    }
}