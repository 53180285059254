import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const referenceDataApi = {
  getStates: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var statesRequest = new AusComplyDtos.GetStates();
    statesRequest.venueId = venueId;
    statesRequest.securityFirmId = securityFirmId;
    const request = client.get(statesRequest);
    return from(request);
  },
  getIndustryCategories: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var industryCategoriesRequest = new AusComplyDtos.GetIndustryCategories();
    industryCategoriesRequest.venueId = venueId;
    industryCategoriesRequest.securityFirmId = securityFirmId;
    const request = client.get(industryCategoriesRequest);
    return from(request);
  },
  getChecklistStatuses: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var checklistStatusesRequest = new AusComplyDtos.GetChecklistStatuses();
    checklistStatusesRequest.venueId = venueId;
    checklistStatusesRequest.securityFirmId = securityFirmId;
    const request = client.get(checklistStatusesRequest);
    return from(request);
  },
  getVenues: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var venuesRequest = new AusComplyDtos.Venues();
    venuesRequest.venueId = venueId;
    venuesRequest.securityFirmId = securityFirmId;
    const request = client.get(venuesRequest);
    return from(request);
  },
  getSecurityFirms: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var securityFirmsRequest = new AusComplyDtos.SecurityFirms();
    securityFirmsRequest.venueId = venueId;
    securityFirmsRequest.securityFirmId = securityFirmId;
    const request = client.get(securityFirmsRequest);
    return from(request);
  },
  getVenuePersonnel: venueId => {
    var client = new AusComplyJsonServiceClient();
    var venuePersonnelRequest = new AusComplyDtos.GetVenuePersonnel();
    venuePersonnelRequest.venueId = venueId;
    const request = client.get(venuePersonnelRequest);
    return from(request);
  },
  getIncidentTypes: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var incidentTypesRequest = new AusComplyDtos.GetIncidentTypesReferenceData();
    incidentTypesRequest.venueId = venueId;
    incidentTypesRequest.securityFirmId = securityFirmId;
    const request = client.get(incidentTypesRequest);
    return from(request);
  },
  getIncidentCategoryTypes: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var incidentCategoryTypesRequest = new AusComplyDtos.GetIncidentCategoryTypesReferenceData();
    incidentCategoryTypesRequest.venueId = venueId;
    incidentCategoryTypesRequest.securityFirmId = securityFirmId;
    const request = client.get(incidentCategoryTypesRequest);
    return from(request);
  },
  getChecklistTemplateCategories: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var params = new AusComplyDtos.GetChecklistTemplateCategoriesReferenceData();
    params.venueId = venueId;
    params.securityFirmId = securityFirmId;
    const request = client.get(params);
    return from(request);
  },
  getUserRoleTypes: (venueId, securityFirmId, forVenueId, forSecurityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var params = new AusComplyDtos.GetUserRoleTypes();
    //params.venueId = venueId;
    //params.securityFirmId = securityFirmId;
    params.forVenueId = forVenueId;
    params.forSecurityFirmId = forSecurityFirmId;
    const request = client.get(params);
    return from(request);
  },
  getUserVenues: () => {
    var client = new AusComplyJsonServiceClient();
    var params = new AusComplyDtos.UserVenues();
    const request = client.get(params);
    return from(request);
  },
  getUserSecurityFirms: () => {
    var client = new AusComplyJsonServiceClient();
    var params = new AusComplyDtos.UserSecurityFirms();
    const request = client.get(params);
    return from(request);
  }
};
