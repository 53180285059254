import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DuplicateMobileNumbersContainer from '../../containers/DuplicateMobileNumbersContainer';
import AdminRoleTypesContainer from '../../containers/AdminRoleTypesContainer';
import AdminUserRolesContainer from '../../containers/AdminUserRolesContainer';
import AdminUsersContainer from '../../containers/AdminUsersContainer';
import AdminUserContainer from '../../containers/AdminUserContainer';
import AdminVenuesContainer from '../../containers/AdminVenuesContainer';
import AdminVenueContainer from '../../containers/AdminVenueContainer';
import IndustryCategoriesContainer from '../../containers/IndustryCategoriesContainer';
import WebsitePropertiesContainer from '../../containers/WebsitePropertiesContainer';
import IncidentTypesContainer from '../../containers/IncidentTypesContainer';
import IncidentFlagTypesContainer from '../../containers/IncidentFlagTypesContainer';
import IncidentCategoryTypesContainer from '../../containers/IncidentCategoryTypesContainer';
import AdminVenueLocationsContainer from '../../containers/AdminVenueLocationsContainer';
import AdminSystemLogContainer from '../../containers/AdminSystemLogContainer';
import AdminDocumentTypesContainer from '../../containers/AdminDocumentTypesContainer';
import AdminSecurityFirmsContainer from '../../containers/AdminSecurityFirmsContainer';
import AdminSecurityFirmContainer from '../../containers/AdminSecurityFirmContainer';
import AdminDashboardContainer from '../../containers/AdminDashboardContainer';
import AdminChecklistTemplatesContainer from '../../containers/AdminChecklistTemplatesContainer';
import CheckListTemplateContainer from '../../containers/CheckListTemplateContainer';
import AdminFacialRecognitionNotificationsContainer from '../../containers/AdminFacialRecognitionNotificationsContainer';
import AdminIncidentActionTemplatesContainer from '../../containers/AdminIncidentActionTemplatesContainer';
import AdminIncidentActionTemplateContainer from '../../containers/AdminIncidentActionTemplateContainer';
import AdminGroupsContainer from '../../containers/AdminGroupsContainer';
import AdminGroupContainer from '../../containers/AdminGroupContainer';
import AdminGroupChecklistTemplateContainer from '../../containers/AdminGroupChecklistTemplateContainer';
import AdminGroupIncidentActionTemplateContainer from '../../containers/AdminGroupIncidentActionTemplateContainer';
import AdminFacialRecognitionWatchlistTypesContainer from '../../containers/AdminFacialRecognitionWatchlistTypesContainer';
import AdminFacialRecognitionServers from '../../components/admin/adminFacialRecognitionServers/AdminFacialRecognitionServers';
import AdminFacialRecognitionWatchlists from '../../components/admin/adminFacialRecognitionWatchlists/AdminFacialRecognitionWatchlists';
import AdminBroadcastNotificationsContainer from '../../containers/AdminBroadcastNotificationsContainer';
import AdminBroadcastNotificationContainer from '../../containers/AdminBroadcastNotificationContainer';
import AdminDirectoryContainer from '../../containers/AdminDirectoryContainer';
import POIExpiryFailuresContainer from '../../containers/POIExpiryFailuresContainer';
import AdminSignOnRegisterTypesContainer from '../../containers/AdminSignOnRegisterTypesContainer';

export default class AdminRoute extends Component<any, any> {

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/dashboard`} component={AdminDashboardContainer} />
                <Route path={`${this.props.match.url}/incidentActionTemplates/:incidentActionTemplateId`} component={AdminIncidentActionTemplateContainer} />
                <Route path={`${this.props.match.url}/incidentActionTemplates`} component={AdminIncidentActionTemplatesContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates/:checklistTemplateId`} component={CheckListTemplateContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates`} component={AdminChecklistTemplatesContainer} />
                <Route path={`${this.props.match.url}/duplicatemobilenumbers`} component={DuplicateMobileNumbersContainer} />
                <Route path={`${this.props.match.url}/duplicatemobilenumber/:userId`} component={AdminUserContainer} />
                <Route path={`${this.props.match.url}/roles`} component={AdminRoleTypesContainer} />
                <Route path={`${this.props.match.url}/userroles`} component={AdminUserRolesContainer} />
                <Route path={`${this.props.match.url}/userrole/:userId`} component={AdminUserContainer} />
                <Route path={`${this.props.match.url}/users`} component={AdminUsersContainer} />
                <Route path={`${this.props.match.url}/user/:userId`} component={AdminUserContainer} />
                <Route path={`${this.props.match.url}/venues`} component={AdminVenuesContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/subscription`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/securityfirms`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/servers`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/cameras`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/watchlists`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/events`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/enrol`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/facialrecognition/expiryfailures`} component={POIExpiryFailuresContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId/utilities`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/venue/:venueId`} component={AdminVenueContainer} />
                <Route path={`${this.props.match.url}/securityfirms`} component={AdminSecurityFirmsContainer} />
                <Route path={`${this.props.match.url}/securityfirm/:securityFirmId`} component={AdminSecurityFirmContainer} />
                <Route path={`${this.props.match.url}/settings`} component={WebsitePropertiesContainer} />
                <Route path={`${this.props.match.url}/industryCategories`} component={IndustryCategoriesContainer} />
                <Route path={`${this.props.match.url}/incidenttypes`} component={IncidentTypesContainer} />
                <Route path={`${this.props.match.url}/incidentflags`} component={IncidentFlagTypesContainer} />
                <Route path={`${this.props.match.url}/incidentcategorytypes`} component={IncidentCategoryTypesContainer} />
                <Route path={`${this.props.match.url}/venuelocations`} component={AdminVenueLocationsContainer} />
                <Route path={`${this.props.match.url}/documentTypes`} component={AdminDocumentTypesContainer} />
                <Route path={`${this.props.match.url}/facialrecognitions`} component={AdminFacialRecognitionNotificationsContainer} />
                <Route path={`${this.props.match.url}/systemlog`} component={AdminSystemLogContainer} />
                <Route path={`${this.props.match.url}/groups/:groupId/checklistTemplate/:checklistTemplateId`} component={AdminGroupChecklistTemplateContainer} />
                <Route path={`${this.props.match.url}/groups/:groupId/incidentActionTemplate/:incidentActionTemplateId`} component={AdminGroupIncidentActionTemplateContainer} />
                <Route path={`${this.props.match.url}/groups/:groupId`} component={AdminGroupContainer} />
                <Route path={`${this.props.match.url}/groups`} component={AdminGroupsContainer} />
                <Route path={`${this.props.match.url}/groups`} component={AdminGroupsContainer} />
                <Route path={`${this.props.match.url}/watchlisttypes`} component={AdminFacialRecognitionWatchlistTypesContainer} />
                <Route path={`${this.props.match.url}/facialrecognitionservers`} component={AdminFacialRecognitionServers} />
                <Route path={`${this.props.match.url}/facialrecognitionwatchlists`} component={AdminFacialRecognitionWatchlists} />
                <Route path={`${this.props.match.url}/broadcastnotifications/:broadcastnotificationId`} component={AdminBroadcastNotificationContainer} />
                <Route path={`${this.props.match.url}/broadcastnotifications`} component={AdminBroadcastNotificationsContainer} />
                <Route path={`${this.props.match.url}/directories`} component={AdminDirectoryContainer} />
                <Route path={`${this.props.match.url}/signonregistertypes`} component={AdminSignOnRegisterTypesContainer} />
                
                <Redirect to={`${this.props.match.url}`} />
            </Switch>
        );
    }
}