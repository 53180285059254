import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import View from '../../common/View';
import TransparentButton from '../../controls/TransparentButton';
import DefaultButton from '../../controls/DefaultButton';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardRow from '../../common/CardRow';
import PaperclipIcon from '../../../resources/PaperclipIcon';
import TickIcon from '../../../resources/TickIcon';
import ChecklistTemplateItemCard from './ChecklistTemplateItemCard';
import ChecklistTemplateItemDrop from './ChecklistTemplateItemDrop';
import { ItemTypes } from './ItemTypes';
import NoRowsMessage from '../../common/NoRowsMessage'

export interface IChecklistTemplateItemCardsProps {
    checklistTemplateItems: AusComplyDtos.ChecklistTemplateItem[];
    notificationTriggerParentIds: number[];
    parentId: number;
    onEdit: Function;
    onRemove: Function;
    onMove: Function;
}

export default class ChecklistTemplateItemCards extends Component<IChecklistTemplateItemCardsProps, any> {
    constructor(props: IChecklistTemplateItemCardsProps) {
        super(props)
    }

    render() {
        const items = this.props.checklistTemplateItems;
        if (!items) {
            return <></>;
        }

        if (items.length === 0) {
            return (
                <NoRowsMessage message={"No items yet, Drag items or from the toolbox here"} >
                    <ChecklistTemplateItemDrop position={items.length} parentId={this.props.parentId} />
                </NoRowsMessage>
            );
        }

        return (
            <>
                {items.map((item, index) => {
                    return <ChecklistTemplateItemCard
                        key={"ChecklistTemplateItemCard-" + item.temporaryId}
                        checklistTemplateItem={item}
                        notificationTriggerParentIds={this.props.notificationTriggerParentIds}
                        notification={this.props.notificationTriggerParentIds && this.props.notificationTriggerParentIds.find(f => f === item.checklistTemplateItemId) ? true : false}
                        index={index}
                        onEdit={this.props.onEdit}
                        onRemove={this.props.onRemove}
                        onMove={this.props.onMove}
                    />
                })}
                <ChecklistTemplateItemDrop position={items.length} parentId={this.props.parentId} />
            </>
        );
    }
}