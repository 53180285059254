import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import TableRow from '../../common/TableRow';
import { venueLogic } from '../../../common/logic/venueLogic';

export interface IVenuesRowProps {
    onClick: Function;
    onCommand?: Function;
    classes: any;
    theme: any;
    venue: AusComplyDtos.Venue;
}

class VenuesRow extends React.Component<IVenuesRowProps, any> {
    constructor(props: IVenuesRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venue);
        }
    }

    render() {
        const { classes } = this.props;
        let item = this.props.venue;
        let className = classes.rowStatusPending;
        if (!this.props.venue.registrationComplete) {
            className = classes.rowStatusDanger;
        } 
        let activeTick;
        if (this.props.venue.isActive) {
            activeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        
        var commands = venueLogic.getAdminCommands(this.props.venue);

        return (
            <TableRow isDanger={!this.props.venue.registrationComplete} commands={commands} onCommand={this.onCommand} key={"row-" + item.venueId.toString()} >
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.venueId}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.address}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.suburb}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.stateDisplayName}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.licenceeOwnerName}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {activeTick}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.venue)}>
                    <p>
                        {item.industryCategoryDisplay}
                    </p>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenuesRow);