import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { checklistApi } from "../services/checklist";
import { notificationShow } from "../actions/notification";
import {
  notifyError, notifyErrorMessage, notifyErrorResponseStatus
} from './common';
import { checklistTemplateApi } from "../services/checklistTemplate";
import {
    checklistTemplatesReset,
  loadChecklistTemplatesRequest,
  loadChecklistTemplatesSuccess,
  loadChecklistTemplatesFailure,
  LOAD_CHECKLIST_TEMPLATES_REQUEST,
  LOAD_CHECKLIST_TEMPLATES_SUCCESS,
  LOAD_CHECKLIST_TEMPLATES_FAILURE,
  clearChecklistTemplateSelection,
  ARCHIVE_CHECKLIST_TEMPLATES_REQUEST,
  ARCHIVE_CHECKLIST_TEMPLATES_SUCCESS,
  ARCHIVE_CHECKLIST_TEMPLATES_FAILURE,
  COPY_CHECKLIST_TEMPLATES_REQUEST,
  COPY_CHECKLIST_TEMPLATES_SUCCESS,
  copyChecklistTemplatesSuccess,
  copyChecklistTemplatesFailure,
} from "../actions/checklistTemplate";
import {
    USER_CHANGED,
    userLogoutRequest
  } from '../actions/userLogin';
/*
import {
  getUserVenuesEpic,
  getUserSecurityFirmsEpic,
  getUserSessionEpic,
  getUserDetailsEpic
} from "./userSession";
import {
  getIndustryCategoriesEpic,
  getStatesEpic,
  getVenuesEpic,
  getSecurityFirmsEpic
} from "./referenceData";
import {
  getChecklistTemplateCategoriesEpic,
  archiveChecklistTemplatesEpic,
  copyChecklistTemplateEpic
} from "./checklistTemplate";
*/

const checklistTemplatesResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => checklistTemplatesReset())
    );

const getChecklistTemplatesEpic = (action$, state$) =>
    action$.pipe(
        ofType(LOAD_CHECKLIST_TEMPLATES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        checklistTemplateApi.getChecklistTemplates(
          state.user.details.userSession.user.venueId,
          state.user.details.userSession.user.securityFirmId,
          state.checklistTemplate.checklistTemplatesPage,
          state.checklistTemplate.checklistTemplatesPageSize,
          action['searchText'],
          action['venues'],
          action['securityFirms'],
          action['industryCategories'],
          action['states'],
          action['checklistTemplateCategories']
          )
                .pipe(
                    map(response => loadChecklistTemplatesSuccess(response.checklistTemplateSummaries, response.paging)),
                    catchError(error => notifyErrorResponseStatus(error, "getChecklistTemplatesEpic.getChecklistTemplates", loadChecklistTemplatesFailure))
                )
        ),
        catchError(error => notifyErrorResponseStatus(error, "getChecklistTemplatesEpic", loadChecklistTemplatesFailure))
    );

    const copyChecklistTemplate = (action$, state$) =>
        action$.pipe(
            ofType(COPY_CHECKLIST_TEMPLATES_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
            checklistTemplateApi.copyChecklistTemplates(
              state.user.details.userSession.user.venueId,
              state.user.details.userSession.user.securityFirmId,
              action['checklistTemplateIds'],
              action['isAdmin'],
              null)
              .pipe(
                  map(response => copyChecklistTemplatesSuccess(response.checklistTemplate)),
                  catchError(error => notifyErrorResponseStatus(error, "copyChecklistTemplate.copyChecklistTemplates", copyChecklistTemplatesFailure))
              )
            ),
            catchError(error => notifyErrorResponseStatus(error, "copyChecklistTemplate", loadChecklistTemplatesFailure))
        );


/*
export const clearChecklistTemplateSelectionEpic = action$ =>
  action$
    .ofType(LOAD_CHECKLIST_TEMPLATES_SUCCESS)
    .mergeMap(action =>
      from$(Observable.of(clearChecklistTemplateSelection()))
    );

export const checklistTemplateSearchEpic = (action$, store) =>
  action$
    .filter(
      action =>
        action.type === COPY_CHECKLIST_TEMPLATES_SUCCESS ||
        action.type === ARCHIVE_CHECKLIST_TEMPLATES_SUCCESS
    )
    .mergeMap(action =>
      from$(
        Observable.of(
          loadChecklistTemplatesRequest(
            store.getState().checklistTemplate.checklistTemplateSearch
              .searchText,
            store.getState().checklistTemplate.checklistTemplateSearch.venues,
            store.getState().checklistTemplate.checklistTemplateSearch
              .securityFirms,
            store.getState().checklistTemplate.checklistTemplateSearch
              .industryCategories,
            store.getState().checklistTemplate.checklistTemplateSearch.states,
            store.getState().checklistTemplate.checklistTemplateSearch
              .checklistTemplateCategories
          )
        )
      )
    );
    */

export const checklistTemplatesEpics = combineEpics(
    checklistTemplatesResetOnUserChangeEpic,
    getChecklistTemplatesEpic,
    copyChecklistTemplate
  /*getUserVenuesEpic,
  getUserSecurityFirmsEpic,
  getIndustryCategoriesEpic,
  getStatesEpic,
  getChecklistTemplateCategoriesEpic,
  getVenuesEpic,
  getSecurityFirmsEpic,
  archiveChecklistTemplatesEpic,
  clearChecklistTemplateSelectionEpic,
  checklistTemplateSearchEpic,
  copyChecklistTemplateEpic,
  getUserSessionEpic,
  getUserDetailsEpic*/
);
