import { connect } from 'react-redux'
import VenueUsers from '../components/venue/VenueUsers'

const mapStateToProps = state => {
    return {
        venueId:  state.user.details.userSession.user.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueUsersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueUsers)

export default VenueUsersContainer