import React, { Component } from 'react';
import { styles } from '../../../styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import View from '../../common/View';
import PageTitle from '../../common/PageTitle';
import NewCheckListSelectTemplateContainer from '../../../containers/NewCheckListSelectTemplateContainer';
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import SecondaryFab from '../../common/SecondaryFab';
import ErrorBoundary from '../../common/ErrorBoundary';
import CheckListCard from '../CheckListCard';
import CheckListRow from '../CheckListRow';
import CheckListHeader from '../CheckListHeader';
import CheckListLegend from '../CheckListLegend';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import CheckListsFilter from './CheckListsFilter';
import CheckListsFilterSummary from './CheckListsFilterSummary';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import PrimaryButton from '../../controls/PrimaryButton';

export interface ICheckListsProps {
    isLoading: boolean;
    checklists: AusComplyDtos.ChecklistSummary[];
    checklistfilter: AusComplyDtos.ChecklistFilter;
    paging: AusComplyDtos.Paging;
    pagesize: number;
    page: number;
    canAddNewChecklist: boolean;
    onSetChecklistsPage: Function;
    onLoadCheckLists: Function;
    onLoadCheckListStatus: Function;
    onLoadCheckList: Function;
    onFilterUpdate: Function;
    onFilterReset: Function;
    canApprove: boolean;
    canReject: boolean;
    canClose: boolean;
    canArchive: boolean;
    canSearch: boolean;
    onApprove: Function;
    onArchive: Function;
    onRestore: Function;
    showEvents: boolean;
    onVoid: Function;
    history: any;
}

interface ICheckListsState {
    showNewCheckListSelectTemplate: boolean;
    filterExpanded: boolean;
    confirmArchive: boolean;
    checklistToArchive: number;
    confirmRestore: boolean;
    checklistToRestore: number;
}

class CheckLists extends Component<ICheckListsProps, ICheckListsState> {

    constructor(props: ICheckListsProps) {
        super(props)

        this.state = {
            showNewCheckListSelectTemplate: false,
            filterExpanded: false,
            confirmArchive: false,
            checklistToArchive: 0,
            confirmRestore: false,
            checklistToRestore: 0
        }
        this.showNewCheckListSelectTemplate = this.showNewCheckListSelectTemplate.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.onRestore = this.onRestore.bind(this);
        this.restoreCancel = this.restoreCancel.bind(this);
        this.restoreConfirmed = this.restoreConfirmed.bind(this);
        this.onVoid = this.onVoid.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.removeArchiveCancel = this.removeArchiveCancel.bind(this);
        this.removeArchiveConfirmed = this.removeArchiveConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onSetChecklistsPage(1);
        this.props.onLoadCheckLists("");
    }

    componentDidUpdate(preProps) {
        if (this.props.checklistfilter && this.props.checklistfilter.byReportingPeriod && this.props.checklistfilter.reportingPeriodId == 0 && !this.props.isLoading) {
            this.props.onSetChecklistsPage(1);
            this.props.onLoadCheckLists("");

        }
    }

    showNewCheckListSelectTemplate(value: boolean) {
        this.setState({ showNewCheckListSelectTemplate: value }, () => {
            if (!value) {
                this.props.onLoadCheckLists("");
            }
        });
    }

    handlePageSelected(page) {
        this.props.onSetChecklistsPage(page);
        this.props.onLoadCheckLists("");
        //let filter = { ...this.state.filter };
        //filter.paging.page = page;
        //this.props.onRefresh(filter);
    }

    onOpen(checklistId) {
        this.props.onLoadCheckList(checklistId);
        this.props.history.push('/checklist');
    }

    onApprove(checklistId) {
        this.props.onApprove([checklistId]);
    }

    onArchive(checklistId) {
        this.setState({
            confirmArchive: true,
            checklistToArchive: checklistId
        });
    }

    onRestore(checklistId) {
        this.setState({
            confirmRestore: true,
            checklistToRestore: checklistId
        });
    }

    restoreCancel() {
        this.setState({
            confirmRestore: false,
            checklistToRestore: 0
        });
    }

    restoreConfirmed() {
        if (this.props.onRestore) {
            this.props.onRestore([this.state.checklistToRestore]);
            this.setState({
                confirmRestore: false,
                checklistToRestore: 0
            });
        }
    }

    onVoid(checklistId) {
        this.props.onVoid([checklistId]);
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoadCheckLists("");
        }
        this.setState({
            filterExpanded: value
        })
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onFilterReset();
            this.props.onSetChecklistsPage(1);
            this.props.onLoadCheckLists("");
        });
    }

    removeArchiveCancel() {
        this.setState({
            confirmArchive: false,
            checklistToArchive: 0
        });
    }

    removeArchiveConfirmed() {
        if (this.props.onArchive) {
            this.props.onArchive([this.state.checklistToArchive]);
            this.setState({
                confirmArchive: false,
                checklistToArchive: 0
            });
        }
    }

    render() {
        let paging: AusComplyDtos.Paging = this.props.paging;
        if (!paging) {
            paging = new AusComplyDtos.Paging();
            paging.page = this.props.page;
            paging.pageSize = this.props.pagesize;
            paging.pages = 1;
            paging.total = 1;
        }

        let cardList;
        let rowList;
        let rows;
        if (this.props.checklists && this.props.checklists.length > 0) {
            cardList = this.props.checklists.map((item, index) => {
                return (
                    <CheckListCard
                        key={"card-" + item.checklistId}
                        onClick={() => this.onOpen(item.checklistId)}
                        canApprove={this.props.canApprove}
                        canReject={this.props.canReject}
                        canClose={this.props.canClose}
                        canArchive={false}
                        onApprove={() => this.onApprove(item.checklistId)}
                        onArchive={() => this.onArchive(item.checklistId)}
                        onUndoArchive={() => this.onRestore(item.checklistId)}
                        onVoid={() => this.onVoid(item.checklistId)}
                        showEvents={this.props.showEvents}
                        checklistSummary={item}
                    />
                );
            });

            rows = this.props.checklists.map((item, index) => {
                return (
                    <CheckListRow
                        key={"row-" + item.checklistId.toString()}
                        onClick={() => this.onOpen(item.checklistId)}
                        canApprove={this.props.canApprove}
                        canReject={this.props.canReject}
                        canClose={this.props.canClose}
                        canArchive={this.props.canArchive}
                        onApprove={() => this.onApprove(item.checklistId)}
                        onArchive={() => this.onArchive(item.checklistId)}
                        onUndoArchive={() => this.onRestore(item.checklistId)}
                        onVoid={() => this.onVoid(item.checklistId)}
                        showEvents={this.props.showEvents}
                        checklistSummary={item} />
                );
            });
        }
        rowList = (
            <TableContainer
                header={<CheckListHeader
                    showEvents={this.props.showEvents} />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <CheckListsFilter
                filter={this.props.checklistfilter}
                onUpdateFilter={filter => this.props.onFilterUpdate(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;

        } else if (this.props.canSearch) {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <CheckListsFilterSummary filter={this.props.checklistfilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let footerLeftContent;
        let newChecklist;
        if (!this.props.isLoading && this.props.canAddNewChecklist) {
            footerLeftContent = <TransparentButton text={"Start new checklist"} onClick={() => this.showNewCheckListSelectTemplate(true)} />

            newChecklist = <NewCheckListSelectTemplateContainer
                            open={this.state.showNewCheckListSelectTemplate}
                            onClose={() => this.showNewCheckListSelectTemplate(false)}
                         />
        }

        return (
            <PageLayout
                headerText={"Checklists"}
                loading={this.props.isLoading}
                footerLeftContent={footerLeftContent}
            >
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={paging}
                    cards={cardList}
                    rows={rowList}
                    footer={<CheckListLegend />}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                {newChecklist}
                <FullPageDialog
                    open={this.state.filterExpanded}
                    title="Search"
                    notFullWidth={true}
                    footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.filterExpanded(false)} />}
                    footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
                    onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <ConfirmDialog
                    title={"Confirm archive"}
                    text={"Are you sure you wish to archive this checklist"}
                    show={this.state.confirmArchive}
                    onCancel={this.removeArchiveCancel}
                    onConfirm={this.removeArchiveConfirmed} />
                <ConfirmDialog
                    title={"Confirm restore"}
                    text={"Are you sure you wish to restore this checklist"}
                    show={this.state.confirmRestore}
                    onCancel={this.restoreCancel}
                    onConfirm={this.restoreConfirmed} />
            </PageLayout>
        );

    }
}


export default withStyles(styles, { withTheme: true })(withRouter(CheckLists));