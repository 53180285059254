import { connect } from 'react-redux'
import VenueAssociations from '../components/admin/venue/venueAssociations/VenueAssociations'
import { withRouter } from "react-router";
import { adminVenueAssociationsRequest, adminVenueSecurityFirmDescendantRemoveRequest, adminVenueSecurityFirmRemoveRequest } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        isLoading: state.admin.venues.isLoading,
        venueSecurityFirms: state.admin.venues.venueSecurityFirms,
        securityFirmDescendants: state.admin.venues.securityFirmDescendants
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (venueId) => {
            dispatch(adminVenueAssociationsRequest(venueId))
        },
        onRemoveVenueSecurityFirm: (venueSecurityFirmId) => {
            dispatch(adminVenueSecurityFirmRemoveRequest(venueSecurityFirmId))
        },
        onRemoveSecurityFirmDescendant: (securityFirmDescendantId) => {
            dispatch(adminVenueSecurityFirmDescendantRemoveRequest(securityFirmDescendantId))
        }
    }
}

const AdminVenueAssociationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueAssociations))

export default AdminVenueAssociationsContainer