import update from "immutability-helper";
import {
    COPY_CHECKLIST_TEMPLATES_FAILURE,
    COPY_CHECKLIST_TEMPLATES_REQUEST,
    COPY_CHECKLIST_TEMPLATES_SUCCESS
} from "../actions/checklistTemplate";
import {
    CHECKLIST_TEMPLATES_RESET,
    CHECKLIST_TEMPLATES_SET_FILTER,
    CHECKLIST_TEMPLATES_FILTER_REQUEST,
    CHECKLIST_TEMPLATES_FILTER_REQUEST_FAILURE,
    CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS,
    CHECKLIST_TEMPLATES_PAGE,
    CHECKLIST_TEMPLATES_REQUEST,
    CHECKLIST_TEMPLATES_REQUEST_FAILURE,
    CHECKLIST_TEMPLATES_REQUEST_SUCCESS,
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST,
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS,
    CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_FAILURE
} from "../actions/checklistTemplates";

const initialState = {
    isLoading: false,
    isSaving: false,
    isCloning: false,
    clonedId: 0,
    filter: { checklistTemplateFilterId: -1, isAdmin: false },
    checklistTemplates: [],
    paging: { pageSize: 0, page: 1 },
    checklistTemplate: {
        checklistTemplate: { checklistTemplate: -1 },
        isLoading: false,
        isSaving: false
    }
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function cloning(state, isCloning, clonedId) {
    let newState = update(state, {
        isCloning: { $set: isCloning },
        clonedId: { $set: clonedId }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function updateFilter(state, filter) {
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setPaging(state, paging) {
    let newState = update(state, {
        paging: { $set: paging }
    });
    return newState;
}

function setChecklistTemplates(state, checklistTemplates) {
    let newState = update(state, {
        checklistTemplates: { $set: checklistTemplates }
    });
    return newState;
}

export default function checklistTemplates(state = initialState, action) {
    switch (action.type) {
        case CHECKLIST_TEMPLATES_RESET:
            return { ...initialState };
        case CHECKLIST_TEMPLATES_SET_FILTER:
            return updateFilter(state, action.filter);
        case CHECKLIST_TEMPLATES_FILTER_REQUEST:
            return loading(state, true);
        case CHECKLIST_TEMPLATES_FILTER_REQUEST_SUCCESS:
            return loading(setPaging(updateFilter(state, action.filter), { page: 1, pageSize: state.paging.pageSize }), false);
        case CHECKLIST_TEMPLATES_FILTER_REQUEST_FAILURE:
            return loading(state, false);
        case CHECKLIST_TEMPLATES_PAGE:
            return loading(setPaging(state, { page: action.page, pageSize: state.paging.pageSize }), true);
        case CHECKLIST_TEMPLATES_REQUEST:
            return setChecklistTemplates(setPaging(loading(state, true), { page: 1, pageSize: state.paging.pageSize }), []);
        case CHECKLIST_TEMPLATES_REQUEST_SUCCESS:
            return setChecklistTemplates(setPaging(updateFilter(loading(state, false), action.filter), action.paging), action.checklistTemplates);
        case CHECKLIST_TEMPLATES_REQUEST_FAILURE:
            return loading(state, false);
        case COPY_CHECKLIST_TEMPLATES_REQUEST:
            return cloning(state, true, 0);
        case COPY_CHECKLIST_TEMPLATES_SUCCESS:
            return cloning(state, false, action.checklistTemplate.checklistTemplateId);
        case COPY_CHECKLIST_TEMPLATES_FAILURE:
            return cloning(state, false, 0);
        case CHECKLIST_TEMPLATES_ARCHIVE_REQUEST:
            return loading(state, true);
        case CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_SUCCESS:
        case CHECKLIST_TEMPLATES_ARCHIVE_REQUEST_FAILURE:
            return loading(state, false);
    }
    return state;
}