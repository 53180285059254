import { connect } from 'react-redux' 
import Terms from '../components/general/Terms'
import { contentTermsRequest } from '../common/actions/content'

const mapStateToProps = state => {
    return {
        isLoading: state.content.isLoading,
        title: state.content.terms.title,
        text: state.content.terms.text
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefreshData: () =>{
            dispatch(contentTermsRequest())
        }
    }
}

const TermsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Terms)

export default TermsContainer