import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POIExpiryFailures from '../components/facialRecognition/facialRecognitionPOI/POIExpiryFailures'
import * as facialRecognitionPOIActions from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.match.params.venueId,
        isLoading: state.facialRecognitionPOI.poiExpiryFailures.isLoading,
        isSaving: state.facialRecognitionPOI.poiExpiryFailures.isSaving,
        data: state.facialRecognitionPOI.poiExpiryFailures.data,
        filter: state.facialRecognitionPOI.poiExpiryFailures.filter,
        paging: state.facialRecognitionPOI.poiExpiryFailures.paging,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (page, pageSize, filter) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiExpiryFailureRequest(page, pageSize,filter))
        },
        onRetry: (filter, facialRecognitionPOIIncidentId) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiExpiryRetryRequest(filter, facialRecognitionPOIIncidentId))
        },
        onDismiss: (facialRecognitionPOIIncidentId) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiExpiryDismissRequest(facialRecognitionPOIIncidentId))
        }
    }
}

const POIExpiryFailuresContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POIExpiryFailures))

export default POIExpiryFailuresContainer