import React from 'react';
import ControlGroup from "./ControlGroup";
import { LocalDate } from "../../common/dto/AusComply.dtos";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import DarkButton from '../controls/DarkButton';
import Grid from '@material-ui/core/Grid';

export interface IHourMinuteControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    rightText?: string;
    error?: string;
    hourValue?: any;
    minuteValue?: any;
    readonly?: boolean;
    onChange?: Function;
    onEnter?: Function;
    labelAbove?: boolean;
    defaultTime?: string;
}

class HourMinuteControlGroup extends React.PureComponent<IHourMinuteControlGroupProps, any> {
    constructor(props: IHourMinuteControlGroupProps) {
        super(props)
        this.state = {
            date: "",
            time: "",
            isEmpty: true
        }

        this.onClear = this.onClear.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onBlurTime = this.onBlurTime.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.localDateTime = this.localDateTime.bind(this);
    }

    componentDidMount() {
        let localDate = this.localDateTime();
        let date = this.convertedDate(localDate, false);
        let time = this.convertedTime(localDate, false);
        let isEmpty = false;
        if (localDate) {
            isEmpty = localDate.isEmpty;
        }
        this.setState({
            date,
            time,
            isEmpty
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.hourValue != prevProps.hourValue || this.props.minuteValue != prevProps.minuteValue) {
            let localDate = this.localDateTime();
            let date = this.convertedDate(localDate, false);
            let datePrevious = this.convertedDate(prevProps.date, false);
            let time = this.convertedTime(localDate, false);
            let timePrevious = this.convertedTime(prevProps.date, false);
            let isEmpty = this.state.isEmpty;
            if (localDate) {
                isEmpty = localDate.isEmpty;
            }
            if (datePrevious != date || timePrevious != time) {
                this.setState({
                    date,
                    time,
                    isEmpty
                });
            }
        }

    }

    localDateTime() {
        let now = new Date();
        let localDateTime = new LocalDate();
        localDateTime.isEmpty = false;
        localDateTime.year = now.getFullYear();
        localDateTime.month = now.getMonth();
        localDateTime.day = now.getDate();
        localDateTime.hour = this.props.hourValue || 0;
        localDateTime.minute = this.props.minuteValue || 0;
        return localDateTime;
    }

    convertedDate(value, force) {
        if (value && (!value.isEmpty || force)) {
            return [value.year, "-",
            (value.month > 9 ? '' : '0') + value.month, "-",
            (value.day > 9 ? '' : '0') + value.day
            ].join('');
        }
        return "";
    }

    convertedTime(value, force) {
        if (value && (!value.isEmpty || force)) {
            return [
                (value.hour > 9 ? '' : '0') + value.hour, ":",
                (value.minute > 9 ? '' : '0') + value.minute,
                ":00"
            ].join('');
        }
        return "";
    }

    onChangeTime(event) {
        let that = this;
        if (event.target.value) {
            let time = this.state.time;
            let newTime = event.target.value.toString();
            time = newTime;
            this.setState({
                time,
                isEmpty: false
            });
        }
    }

    onBlurTime(event) {
        this.onUpdated(false);
    }

    onClear() {
        this.setState({
            date: "",
            time: "",
            isEmpty: true
        }, () => {
            this.onUpdated(false);
        });

    }

    onUpdated(wasEnter) {
        if (this.props.onChange) {
            var newDate = this.localDateTime();
            newDate.isEmpty = this.state.isEmpty;
            if (this.state.time) {
                let timeValue = new Date("1 Jan 2000 " + this.state.time);
                if (isNaN(timeValue.getHours())) {
                    newDate.hour = new Date().getHours();
                } else {
                    newDate.hour = timeValue.getHours();
                }
                if (isNaN(timeValue.getMinutes())) {
                    newDate.minute = new Date().getMinutes();
                } else {
                    newDate.minute = timeValue.getMinutes();
                }
            }
            this.props.onChange(newDate);
            if (wasEnter && this.props.onEnter) {
                this.props.onEnter();
            }
        }
    }

    onKeyPress = (event) => {
        if (event.key == 'Enter') {
            this.onUpdated(true);
        }
    }

    render() {

        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;

        return (
            <ControlGroup text={this.props.text} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>

                <Grid container spacing={1}>
                    <Grid item xs={10}>
                        <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                            <InputBase
                                type="time"
                                value={this.state.time}
                                onBlur={this.onBlurTime}
                                onChange={this.onChangeTime}
                                disabled={this.props.readonly}
                                onKeyPress={this.onKeyPress}
                                style={style}
                                error={this.props.error ? true : false}
                                classes={{ root: classes.inputControl, input: classes.inputRoot }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        {!this.props.readonly && (
                            <DarkButton onClick={this.onClear} >X</DarkButton>
                        )}
                    </Grid>
                </Grid>


            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(HourMinuteControlGroup);