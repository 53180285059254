import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import ImagePreviewWithProcessingContainer from '../../../containers/ImagePreviewWithProcessingContainer';

export interface IIncidentViewDocumentsProps {
    incident: IncidentDTO;
    theme: any;
}

class IncidentViewDocuments extends Component<IIncidentViewDocumentsProps, any> {

    constructor(props: IIncidentViewDocumentsProps) {
        super(props)
        this.state = {
            incidentDocumentId: 0,
            selectedFilePath: "",
            selectedFileName: "",
            imageText: "",
            index: -1,
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onSelectFilePath(incidentDocumentId, filePath, text, index) {
        this.setState({
            incidentDocumentId,
            selectedFilePath: filePath,
            selectedFileName: text,
            index
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render() {
        if (!this.props.incident.incidentDocuments || this.props.incident.incidentDocuments.length == 0) {
            return (<></>);
        }
        let nonObsoleteDocs = this.props.incident.incidentDocuments.filter(item => !item.obsolete);
        let documents = nonObsoleteDocs.map((item, index) => {
            let imageTextLength = 0;
            if(item.imageText != undefined && item.imageText !== "")
            {
                imageTextLength = item.imageText.length;
            }
            return (
                <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.originalFilename + "_" + index.toString() + "_" + imageTextLength.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        fullText={item.originalFilename}
                        onPreview={(path, text) => this.onSelectFilePath(item.incidentDocumentId, path, text, index)}
                        onDownload={path => this.onDownload(path)}
                        hasImageText={imageTextLength > 0}
                    />
                </Box>
            )
        });

        let imageText;
        if (this.state.index > -1) {
            imageText = nonObsoleteDocs[this.state.index].imageText;
        }

        return (
            <>
                <ControlGroup text="Photos, Videos and Documents">
                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                        {documents}
                    </Box>
                </ControlGroup>
                <ImagePreviewWithProcessingContainer 
                    incidentDocumentId={this.state.incidentDocumentId}
                    filePath={this.state.selectedFilePath} 
                    fileName={this.state.selectedFileName} 
                    imageText={imageText}
                    saveToDocument={true}
                    onDismissed={() => this.onSelectFilePath(0, "", "", -1)} />
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentViewDocuments);