import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import TableRow from '../../common/TableRow';

export interface IUsersRowProps {
    onClick: Function;
    classes: any;
    theme: any;
    user: AusComplyDtos.User;
}

class UsersRow extends React.Component<IUsersRowProps, any> {

    render() {
        const { classes } = this.props;
        let item = this.props.user;
        let className = classes.rowStatusPending;
        let verifiedTick;
        if (this.props.user.isVerified) {
            verifiedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <TableRow isDanger={this.props.user.obsolete}  key={"row-" + item.userId.toString()} onClick={() => this.props.onClick(this.props.user)} >
                <td>
                    <p>
                        {item.givenName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.surname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.nickname}
                    </p>
                </td>
                <td>
                    <p>
                        {item.mobileNo}
                    </p>
                </td>
                <td>
                    <p>
                        {item.email}
                    </p>
                </td>
                <td>
                    <p>
                        {verifiedTick}
                    </p>
                </td>
                <td>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UsersRow);