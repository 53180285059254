import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { IdName } from "../../common/dto/common";
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';
import SubTitle from '../common/SubTitle';
import TextControlGroup from '../controls/TextControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import ControlGroup from '../controls/ControlGroup';
import ControlText from '../controls/ControlText';
import TextControl from '../controls/TextControl';
import ScheduleIncidentCategoryTypes from './ScheduleIncidentCategoryTypes';
import ScheduleVenueLocations from './ScheduleVenueLocations';
import ScheduleVenues from './ScheduleVenues';
import ScheduleVenue from './ScheduleVenue';
import ScheduleIncidentFlags from './ScheduleIncidentFlags';
import ScheduleUserRoleTypes from './ScheduleUserRoleTypes';
import ScheduleEscalateUserRoleTypes from './ScheduleEscalateUserRoleTypes';
import ScheduleVenueEvents from './ScheduleVenueEvents';
import HourMinuteControlGroup from '../controls/HourMinuteControlGroup';
import TimeZoneDateControlGroup from '../controls/TimeZoneDateControlGroup';
import SchedulePattern from './SchedulePattern';

export interface IScheduleProps {
    scheduleId: number;
    copyScheduleId: number;
    schedule: AusComplyDtos.Schedule;
    checklistTemplates: AusComplyDtos.ChecklistTemplateSummary[],
    incidentTypes: AusComplyDtos.IncidentType[],
    incidentFlagTypes: AusComplyDtos.IncidentFlagType[],
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[],
    venueLocations: AusComplyDtos.VenueLocation[],
    timeZones: AusComplyDtos.TimeZone[],
    userRoleTypes: AusComplyDtos.UserRoleType[],
    venueEvents: AusComplyDtos.VenueEvent[],
    showEvents: boolean;
    venues: IdName[],
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    onLoad: Function;
    onCreate: Function;
    onUpdate: Function;
    onSave: Function;
    onCopy: Function;
    history: any;
    location: any;
}

interface IScheduleState {
}

export default class Schedule extends Component<IScheduleProps, IScheduleState> {
    constructor(props: IScheduleProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onTimeChanged = this.onTimeChanged.bind(this);
        this.onUntilTimeChanged = this.onUntilTimeChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.scheduleId != undefined && this.props.scheduleId > 0) {
            this.props.onLoad(this.props.scheduleId);
        } else {
            if (this.props.copyScheduleId && this.props.copyScheduleId > 0) {
                this.props.onCopy(this.props.copyScheduleId);
            } else {
                this.props.onCreate();
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && this.props.error == "") {
            this.onCancel();
        }
    }

    onSave() {
        this.props.onSave(this.props.schedule);
    }

    onCancel() {
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        updatedPath = path.pop();
        var url = path.join('/');
        this.props.history.push(url);
    }

    onValueChanged(fieldName: string, value: any) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    onTimeChanged(value: AusComplyDtos.LocalDate) {
        let schedule = { ...this.props.schedule };
        if (value.isEmpty) {
            schedule.hour = 9;
            schedule.minute = 0;

        } else {
            schedule.hour = value.hour;
            schedule.minute = value.minute;
        }
        this.props.onUpdate(schedule)
    }

    onUntilTimeChanged(value: AusComplyDtos.LocalDate) {
        let schedule = { ...this.props.schedule };
        if (value.isEmpty) {
            schedule.untilHour = 9;
            schedule.untilMinute = 0;

        } else {
            schedule.untilHour = value.hour;
            schedule.untilMinute = value.minute;
        }
        this.props.onUpdate(schedule)
    }

    render() {

        let content;
        if (this.props.schedule) {
            let entity;
            if (this.props.schedule.entityTypeID && this.props.schedule.entityTypeID == AusComplyDtos.ngtEntityType.Checklist) {
                entity = <>
                    <SelectControlGroup
                        text="Checklist Template"
                        onChange={(value) => this.onValueChanged("checklistTemplateId", Number(value))}
                        defaultValue={this.props.schedule.checklistTemplateId}>
                        <option key={0} value={0}>Select...</option>
                        {this.props.checklistTemplates.map((item, index) => (
                            <option key={item.checklistTemplateId} value={item.checklistTemplateId}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    {(this.props.schedule.securityFirmId && this.props.schedule.securityFirmId > 0) && (
                        <>
                            <SubTitle text='For Venues' />
                            <ControlGroup text=' '>
                                <ScheduleVenues
                                    schedule={this.props.schedule}
                                    venues={this.props.venues}
                                    onUpdate={value => this.props.onUpdate(value)}
                                />
                            </ControlGroup>
                        </>
                    )}
                </>
            } else if (this.props.schedule.entityTypeID && this.props.schedule.entityTypeID == AusComplyDtos.ngtEntityType.Incident) {
                entity = <>
                    {(this.props.schedule.securityFirmId && this.props.schedule.securityFirmId > 0) && (
                        <ScheduleVenue
                            schedule={this.props.schedule}
                            venues={this.props.venues}
                            onUpdate={value => this.props.onUpdate(value)}
                        />
                    )}
                    <SelectControlGroup
                        text="Incident Type"
                        onChange={(value) => this.onValueChanged("incidentTypeId", Number(value))}
                        defaultValue={this.props.schedule.incidentTypeId}>
                        <option key={0} value={0}>Select...</option>
                        {this.props.incidentTypes.map((item, index) => (
                            <option key={item.incidentTypeId} value={item.incidentTypeId}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    <ControlGroup text=' '>
                        <ScheduleIncidentCategoryTypes
                            schedule={this.props.schedule}
                            incidentCategoryTypes={this.props.incidentCategoryTypes}
                            onUpdate={value => this.props.onUpdate(value)} />
                    </ControlGroup>
                    <ControlGroup text=' '>
                        <ScheduleVenueLocations
                            schedule={this.props.schedule}
                            venueLocations={this.props.venueLocations}
                            onUpdate={value => this.props.onUpdate(value)} />
                    </ControlGroup>
                    <ControlGroup text=' '>
                        <ScheduleIncidentFlags
                            schedule={this.props.schedule}
                            incidentFlagTypes={this.props.incidentFlagTypes}
                            onUpdate={value => this.props.onUpdate(value)} />
                    </ControlGroup>
                </>
            }
            content = <>
                <SubTitle text='Task' />
                <SelectControlGroup
                    text="Create a new"
                    onChange={(value) => this.onValueChanged("entityTypeID", value)}
                    defaultValue={this.props.schedule.entityTypeID}>
                    {this.props.schedule.entityTypes && this.props.schedule.entityTypes.map((item, index) => <option key={item} value={item}>{item.toString()}</option>)}
                </SelectControlGroup>
                {this.props.showEvents && <ScheduleVenueEvents 
                    schedule={this.props.schedule}
                    venueEvents={this.props.venueEvents}
                    onUpdate={value => this.props.onUpdate(value)} />}
                {entity}
                <SubTitle text='Notify' />
                <CheckboxControlGroup
                    text={"Include associated security firm users"}
                    label={" "}
                    defaultValue={this.props.schedule.includeAssociatedUsers}
                    onChanged={(value) => this.onValueChanged("includeAssociatedUsers", value)} />
                <CheckboxControlGroup
                    text={"Only users currently logged in"}
                    label={" "}
                    defaultValue={this.props.schedule.onlySignedOn}
                    onChanged={(value) => this.onValueChanged("onlySignedOn", value)} />
                <ControlGroup text=' '>
                    <ScheduleUserRoleTypes
                        schedule={this.props.schedule}
                        userRoleTypes={this.props.userRoleTypes}
                        onUpdate={value => this.props.onUpdate(value)} />
                </ControlGroup>
                <SubTitle text='Time' />
                <HourMinuteControlGroup
                    text={"Run at"}
                    hourValue={this.props.schedule.hour}
                    minuteValue={this.props.schedule.minute}
                    onChange={(value) => this.onTimeChanged(value)}
                />
                <TextControlGroup text={'Complete within minutes'}
                    numericOnly={true}
                    defaultValue={(this.props.schedule.dueInMinutes || "0").toString()}
                    onChange={(value) => this.onValueChanged("dueInMinutes", Number(value))} />
                <CheckboxControlGroup
                    text={"Escalate"}
                    label={" "}
                    defaultValue={this.props.schedule.escalate}
                    onChanged={(value) => this.onValueChanged("escalate", value)} />
                {this.props.schedule.escalate && (<>
                    <TextControlGroup text={'Escalate after minutes'}
                        numericOnly={true}
                        defaultValue={(this.props.schedule.escalationAfterMinutes || "0").toString()}
                        onChange={(value) => this.onValueChanged("escalationAfterMinutes", Number(value))} />
                    <CheckboxControlGroup
                        text={"Include associated security firm users"}
                        label={" "}
                        defaultValue={this.props.schedule.escalateIncludeAssociatedUsers}
                        onChanged={(value) => this.onValueChanged("escalateIncludeAssociatedUsers", value)} />
                    <CheckboxControlGroup
                        text={"Only users currently logged in"}
                        label={" "}
                        defaultValue={this.props.schedule.escalateOnlySignedOn}
                        onChanged={(value) => this.onValueChanged("escalateOnlySignedOn", value)} />
                    <ControlGroup text=' '>
                        <ScheduleEscalateUserRoleTypes
                            schedule={this.props.schedule}
                            userRoleTypes={this.props.userRoleTypes}
                            onUpdate={value => this.props.onUpdate(value)} />
                    </ControlGroup>
                </>)}
                <CheckboxControlGroup
                    text={"Repeat"}
                    label={" "}
                    defaultValue={this.props.schedule.repeat}
                    onChanged={(value) => this.onValueChanged("repeat", value)} />
                {this.props.schedule.repeat && (<>
                    <TextControlGroup text={'Every minutes'}
                        numericOnly={true}
                        defaultValue={(this.props.schedule.everyMinutes || "0").toString()}
                        onChange={(value) => this.onValueChanged("everyMinutes", Number(value))} />
                    <HourMinuteControlGroup
                        text={"Until"}
                        hourValue={this.props.schedule.untilHour}
                        minuteValue={this.props.schedule.untilMinute}
                        onChange={(value) => this.onUntilTimeChanged(value)}
                    />
                </>)}
                <SchedulePattern schedule={this.props.schedule} onUpdate={this.props.onUpdate} />
                <SubTitle text='Range' />
                <TimeZoneDateControlGroup
                    text={"Starting from"}
                    onChange={(value) => this.onValueChanged("rangeStartTimeZoneDate", value)}
                    date={this.props.schedule.rangeStartTimeZoneDate} />
                <SelectControlGroup
                    text="Until"
                    onChange={(value) => this.onValueChanged("rangeOption", value)}
                    defaultValue={this.props.schedule.rangeOption}>
                    <option key={AusComplyDtos.ngtRangeOption.Ends} value={AusComplyDtos.ngtRangeOption.Ends}>Ends</option>
                    <option key={AusComplyDtos.ngtRangeOption.EndsAfter} value={AusComplyDtos.ngtRangeOption.EndsAfter}>Ends After</option>
                    <option key={AusComplyDtos.ngtRangeOption.NoEnd} value={AusComplyDtos.ngtRangeOption.NoEnd}>No End</option>
                </SelectControlGroup>
                {this.props.schedule.rangeOption === AusComplyDtos.ngtRangeOption.Ends && (
                    <TimeZoneDateControlGroup
                        text={"End date"}
                        onChange={(value) => this.onValueChanged("rangeStopTimeZoneDate", value)}
                        date={this.props.schedule.rangeStopTimeZoneDate} />
                )}
                {this.props.schedule.rangeOption === AusComplyDtos.ngtRangeOption.EndsAfter && (
                    <ControlGroup text='End After'>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={0} p={0}>
                                <TextControl numericOnly={true}
                                    value={(this.props.schedule.rangeOccurances || "0").toString()}
                                    onChange={(value) => this.onValueChanged("rangeOccurances", value)} />
                            </Box>
                            <Box flexGrow={1} p={0}>
                                <ControlText text='Occurances' />
                            </Box>
                        </Box>
                    </ControlGroup>
                )}
                <SubTitle text='General' />
                <CheckboxControlGroup
                    text={"Active"}
                    label={" "}
                    isReversed={true}
                    defaultValue={this.props.schedule.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
            </>
        }


        return (
            <PageLayout
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                error={this.props.error}
                headerText={"Scheduled Task"}
                footerLeftContent={<TransparentButton onClick={() => this.onCancel()} text={"Cancel"} />}
                footerRightContent={<PrimaryButton disabled={this.props.isLoading || this.props.isSaving} onClick={() => this.onSave()} text={"Save"} />}
            >
                {content}
            </PageLayout >
        );
    }
}