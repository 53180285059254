import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PersonIcon from '../../resources/PersonIcon';
import { Palette } from '../../common/constants/palette';

export interface IThumbnailPlaceholderProps {
    theme: any;
    classes: any;
    fullText?: string;
    title?: string;
    borderColor?: string;
    isDanger?: boolean;
    style?: any;
    small?: boolean;
    tiny?: boolean;
}

class ThumbnailPlaceholder extends React.PureComponent<IThumbnailPlaceholderProps, any> {
    constructor(props: IThumbnailPlaceholderProps) {
        super(props)
    }

    render() {
        const { theme } = this.props;

        let preview;
        if (this.props.tiny) {
            preview = (
                <PersonIcon
                    style={{
                        color: Palette.ControlBorder,
                        width: '23px',
                        height: '23px',
                        margin: this.props.tiny ? '0 0 0 3px' : '15px 17px 22px'
                    }}
                />
            );
        } else if (this.props.small) {
            preview = (
                <PersonIcon
                    style={{
                        color: Palette.ControlBorder,
                        width: '23px',
                        height: '23px',
                        margin: '15px 17px 22px'
                    }}
                />
            );
        } else {
            preview = (
                <PersonIcon
                    style={{
                        color: Palette.ControlBorder,
                        width: '46px',
                        height: '46px',
                        margin: '15px 17px 22px'
                    }}
                />
            );
        }
        if (this.props.tiny) {
            return (
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Box p={0} flexGrow={1}>
                        <div style={{
                            ...theme.custom.fileUploaderTinyContainer,
                            position: 'relative',
                            ...this.props.style
                        }}>
                            {preview}
                        </div>
                    </Box>
                </Box>
            );
        }
        var borderColor = Palette.ControlBorder;
        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box p={1} flexGrow={1} >
                    <div style={{
                        ...theme.custom.fileUploaderContainer,
                        position: 'relative',
                        ...this.props.style,
                        borderColor: borderColor
                    }}>
                        {preview}
                        {this.props.title && (
                            <p style={{ ...theme.custom.fileUploaderText, fontSize: '8px', bottom: '2px', opacity: 0.7 }}>{this.props.title}</p>
                        )}
                    </div>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ThumbnailPlaceholder);