import { connect } from 'react-redux'
import { withRouter } from "react-router";
import Schedules from '../components/schedule/Schedules'
import * as scheduleActions from '../common/actions/schedule';

const mapStateToProps = (state, props) => {
    return {
        schedules:  state.schedules.schedules.schedules,
        isLoading: state.schedules.schedules.isLoading,
        isProcessing: state.schedules.schedules.isProcessing,
        processingId: state.schedules.schedules.processingId,
        showEvents: false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(scheduleActions.schedulesSecurityFirmRequest())
        },
        onProcess: (scheduleId) => {
            dispatch(scheduleActions.scheduleProcessRequest(scheduleId))
        }
    }
}

const SecurityFirmSchedulesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedules))

export default SecurityFirmSchedulesContainer