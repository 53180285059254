import { connect } from 'react-redux'
import UserChangePassword from '../components/admin/user/UserChangePassword'
import { withRouter } from "react-router";
import { adminUserChangePasswordRequest } from '../common/actions/admin';

const mapStateToProps = (state, props) => {
    return {
        userId: props.userId,
        isSavingPassword: state.admin.user.isSavingPassword,
        changePasswordError: state.admin.user.changePasswordError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword:(userId, password, confirmPassword) => {
            dispatch(adminUserChangePasswordRequest(userId, password, confirmPassword))
        }
    }
}

const AdminUserChangePasswordContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserChangePassword))

export default AdminUserChangePasswordContainer