import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SubLocations from '../components/subLocations/SubLocations'
import * as subLocationActions from '../common/actions/subLocation';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        paging: state.subLocations.subLocations.paging,
        filter: state.subLocations.subLocations.filter,
        isLoading: state.subLocations.subLocations.isLoading,
        subLocations: state.subLocations.subLocations.subLocations,
        subLocationId: state.subLocations.subLocation.subLocationId,
        canEdit: permissionsLogic.hasPermissionForState(state, "SubLocationsEdit")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(subLocationActions.subLocationsRequest(page, pageSize, filter))
        },
        onLoadItem: (subLocationId) => {
            dispatch(subLocationActions.subLocationRequest(subLocationId))
        },
        onCreate: (forGroupId, forVenueId) => {
            dispatch(subLocationActions.subLocationCreateRequest(forGroupId, forVenueId))
        },
        onResetItem: () => {
            dispatch(subLocationActions.subLocationReset())
        },
        onUpdate: (subLocation) => {
            dispatch(subLocationActions.subLocationUpdate(subLocation))
        },
        onSave: () => {
            dispatch(subLocationActions.subLocationSaveRequest())
        }
    }
}

const VenueSubLocationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubLocations))

export default VenueSubLocationsContainer