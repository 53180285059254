import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const radioChannelServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRadioChannels();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forGroupId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRadioChannelNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forGroupId = forGroupId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, radioChannel) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRadioChannel();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.radioChannel = radioChannel;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, radioChannelId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetRadioChannel();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.radioChannelId = radioChannelId;
    const request = client.get(query);
    return from(request);
  }
};
