
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const documentTypeLogic = {
    getCommands(documentType: AusComplyDtos.DocumentType) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        return commands;
    }
}