import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import SubTitle from '../../common/SubTitle';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import VenueTabs from './VenueTabs';
import PrimaryButton from '../../controls/PrimaryButton';
import PageLayout from '../../layout/PageLayout';
import { KeyName } from '../../../common/dto/common';

export interface IVenueImageProcessingHistoricalProps {
    venueId: number;
    isProcessing: boolean;
    onProcess: Function;
}

export interface IVenueImageProcessingHistoricalState {
    fromDate: AusComplyDtos.SimpleDate;
}

export default class VenueImageProcessingHistorical extends Component<IVenueImageProcessingHistoricalProps, IVenueImageProcessingHistoricalState> {
    constructor(props: IVenueImageProcessingHistoricalProps) {
        super(props)
        let fromDate = new AusComplyDtos.SimpleDate();
        fromDate.isEmpty = true;
        this.state = {
            fromDate
        };
    }

    render() {
        let self = this;
        return (
            <>
                <SimpleDateControlGroup 
                    text={"Scan historical ID images from"} 
                    date={this.state.fromDate}
                    onChange={fromDate => self.setState({fromDate})}
                    />
                <ControlGroup text={" "}>
                    <PrimaryButton 
                        text={"Process"} 
                        onClick={() => {
                            self.props.onProcess(this.props.venueId, this.state.fromDate);
                        }}
                        disabled={this.state.fromDate.isEmpty} 
                        />
                </ControlGroup>
            </>
        );
    }
}