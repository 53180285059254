import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import SubTitle from '../../common/SubTitle';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import PrimaryButton from '../../controls/PrimaryButton';
import SelectControlGroup from '../../controls/SelectControlGroup';
import Grid from '@material-ui/core/Grid';
import UserRolesSuspendContainer from '../../../containers/UserRolesSuspendContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import VenueTabs from './VenueTabs';
import PageLayout from '../../layout/PageLayout';

export interface IVenueUtilitiesProps {
    venueId: number;
    onNotificationClear: Function;
    onApproveIncidents: Function;
    history: any;
    location: any;
}

export default class VenueUtilities extends Component<IVenueUtilitiesProps, any> {
    constructor(props: IVenueUtilitiesProps) {
        super(props)
        let date = new AusComplyDtos.SimpleDate();
        date.isEmpty = true;
        let approvalDate = new AusComplyDtos.SimpleDate();
        approvalDate.isEmpty = true;
        this.state = {
            date,
            approvalDate
        };
        this.onNotificationClear = this.onNotificationClear.bind(this);
        this.onApproveIncidents = this.onApproveIncidents.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let state = { ...this.state };
        state[fieldName] = value;
        this.setState(state);
    }

    onNotificationClear() {
        if (!this.state.date.isEmpty) {
            this.props.onNotificationClear(this.props.venueId, this.state.date);
        }
    }

    onApproveIncidents() {
        if (!this.state.approvalDate.isEmpty) {
            this.props.onApproveIncidents(this.props.venueId, this.state.approvalDate);
        }
    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }


    render() {
        let self = this;

        return (
            <PageLayout
                headerText={"Venue"}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                footerCenterContent={<></>}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Utilities"} history={this.props.history} />
                <SimpleDateControlGroup text="Clear notifications earlier than" date={this.state.date} onChange={(value) => this.onValueChanged("date", value)} />
                <ControlGroup text={" "}>
                    <Grid container spacing={1} style={{ marginTop: '20px' }}>
                        <Grid item xs={6}>
                            <ErrorButton disabled={this.state.date.isEmpty} onClick={() => this.onNotificationClear()}>Clear</ErrorButton>
                        </Grid>
                    </Grid>
                </ControlGroup>
                <SimpleDateControlGroup text="Approve all incidents earlier than" date={this.state.approvalDate} onChange={(value) => this.onValueChanged("approvalDate", value)} />
                <ControlGroup text={" "}>
                    <Grid container spacing={1} style={{ marginTop: '20px' }}>
                        <Grid item xs={6}>
                            <ErrorButton disabled={this.state.approvalDate.isEmpty} onClick={() => this.onApproveIncidents()}>Approve</ErrorButton>
                        </Grid>
                    </Grid>
                </ControlGroup>
            </PageLayout>
        );
    }
}