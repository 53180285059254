import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { 
    correctionalFacilityInMateCellUpdateSet,
    correctionalFacilityInMateCellUpdateRequest
} from '../common/actions/correctionalFacility';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import EditInMateCell from '../components/facility/correctionalFacilityInMateCell/EditInMateCell';

const mapStateToProps = state => {
    return {
        isSaving: state.correctionalFacility.editInMateCell.isSaving,
        error: state.correctionalFacility.editInMateCell.error,
        correctionalFacilityInMateCell: state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell,
        correctionalFacilityInMate: state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell ? state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell.correctionalFacilityInMate : undefined,
        correctionalFacilityInMateVenueLocation: state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell ? state.correctionalFacility.editInMateCell.correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation : undefined,
        cells: state.correctionalFacility.inmatesCells.cells,
        risks: state.correctionalFacility.inmatesCells.risks,
        genders: state.correctionalFacility.inmatesCells.genders,
        nationalities: state.correctionalFacility.inmatesCells.nationalities,
        isValid: state.correctionalFacility.editInMateCell.isValid
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSave: () => {
            dispatch(correctionalFacilityInMateCellUpdateRequest())
        },
        onSet: (correctionalFacilityInMate) => {
            dispatch(correctionalFacilityInMateCellUpdateSet(correctionalFacilityInMate))
        }
    }
}
const CorrectionalFacilityEditInMateCellContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditInMateCell))

export default CorrectionalFacilityEditInMateCellContainer