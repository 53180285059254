import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../../controls/PrimaryButton';
import PrimaryDoubleHeightButton from '../../controls/PrimaryDoubleHeightButton';
import DefaultButton from '../../controls/DefaultButton';
import TickIcon from '../../../resources/TickIcon';
import CrossIcon from '../../../resources/CrossIcon';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TransparentButton from '../../controls/TransparentButton';
import NavigationButton from '../../controls/NavigationButton';
import NavigateNextIcon from '../../../resources/NavigateNextIcon';
import NavigatePreviousIcon from '../../../resources/NavigatePreviousIcon';

export interface IRegisterUserNavigationProps {
    step: number;
    steps: number;
    onBack: Function;
    onNext: Function;
    onCancel: Function;
    onSubmit: Function;
    canNext: boolean;
    canSubmit?: boolean;
}

export default class RegisterUserNavigation extends Component<IRegisterUserNavigationProps, any> {

    render() {
        let deleteButton = <NavigationButton
            onClick={this.props.onCancel}
            text={"Close"}
            isError={true}
            Icon={CrossIcon} />;

        let nextButton = <NavigationButton
            onClick={this.props.onNext}
            text={"Next"}
            Icon={NavigateNextIcon}
            active={this.props.step < this.props.steps}
            disabled={this.props.step == this.props.steps || !this.props.canNext} />;

        let previousButton = <NavigationButton
            onClick={this.props.onBack}
            text={"Back"}
            Icon={NavigatePreviousIcon}
            active={this.props.step > 1}
            disabled={this.props.step < 2} />


        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '0 20px'
                    }}>
                        <div style={{ flex: 0 }}>
                            {previousButton}
                        </div>
                        <div style={{ flex: 0 }}>
                            {deleteButton}
                        </div>
                        <div style={{ flex: 0 }}>
                            {nextButton}
                        </div>
                    </div>
                </Grid>
            </Grid>
        );

    }
}