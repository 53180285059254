import { connect } from 'react-redux'
import IndustryCategories from '../components/admin/industryCategories/IndustryCategories'
import { withRouter } from "react-router";
import { adminIndustryCategoriesRequest, adminIndustryCategoryCreateRequest, adminIndustryCategoryUpdateRequest } from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.industryCategories.isLoading,
        isSaving: state.admin.industryCategories.isSaving,
        industryCategories: state.admin.industryCategories.industryCategories,
        paging: state.admin.industryCategories.paging,
        filter: state.admin.industryCategories.filter,
        error: state.admin.industryCategories.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(adminIndustryCategoriesRequest(page, pageSize, filter))
        },
        onCreate:(industryCategories) => {
            dispatch(adminIndustryCategoryCreateRequest(industryCategories))
        },
        onUpdate:(industryCategories) => {
            dispatch(adminIndustryCategoryUpdateRequest(industryCategories))
        }
    }
}

const IndustryCategoriesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IndustryCategories))

export default IndustryCategoriesContainer