export const
    SECURITY_FIRM_ASSOCIATIONS_REFRESH = "SECURITY_FIRM_ASSOCIATIONS_REFRESH",
    SECURITY_FIRM_ASSOCIATIONS_REQUEST = "SECURITY_FIRM_ASSOCIATIONS_REQUEST",
    SECURITY_FIRM_ASSOCIATIONS_REQUEST_SUCCESS = "SECURITY_FIRM_ASSOCIATIONS_REQUEST_SUCCESS",
    SECURITY_FIRM_ASSOCIATIONS_REQUEST_FAILURE = "SECURITY_FIRM_ASSOCIATIONS_REQUEST_FAILURE",
    SECURITY_FIRM_ASSOCIATION_RESET = "SECURITY_FIRM_ASSOCIATION_RESET",
    SECURITY_FIRM_ASSOCIATION_SET = "SECURITY_FIRM_ASSOCIATION_SET",
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST = "SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST",
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS = "SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS",
    SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_FAILURE = "SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_FAILURE",
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST = "SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST",
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS = "SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS",
    SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_FAILURE = "SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_FAILURE",
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST = "SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST",
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS = "SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS",
    SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE = "SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE",

    SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST = "SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST",
    SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS = "SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS",
    SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE = "SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE",

    SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH = "SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH",
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST = "SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST",
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_SUCCESS = "SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_SUCCESS",
    SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_FAILURE = "SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_FAILURE",
    SECURITY_FIRM_SUB_ASSOCIATION_RESET = "SECURITY_FIRM_SUB_ASSOCIATION_RESET",
    SECURITY_FIRM_SUB_ASSOCIATION_SET = "SECURITY_FIRM_SUB_ASSOCIATION_SET",
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST = "SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST",
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_SUCCESS = "SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_SUCCESS",
    SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_FAILURE = "SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_FAILURE",
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST = "SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST",
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS = "SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS",
    SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_FAILURE = "SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_FAILURE",
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST = "SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST",
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS = "SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS",
    SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_FAILURE = "SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_FAILURE"
    ;

export function securityFirmAssociationReset() {
    return {
        type: SECURITY_FIRM_ASSOCIATION_RESET
    };
}

export function securityFirmAssociationSet(venueSecurityFirm) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_SET,
        venueSecurityFirm
    };
}

export function securityFirmAssociationCreateRequest(forVenueId, forParentSecurityFirmId) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST,
        forVenueId,
        forParentSecurityFirmId
    };
}

export function securityFirmAssociationCreateRequestSuccess(venueSecurityFirm, securityFirms) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_SUCCESS,
        venueSecurityFirm,
        securityFirms
    };
}

export function securityFirmAssociationCreateRequestFailure(error) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmAssociationInsertRequest() {
    return {
        type: SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST
    };
}

export function securityFirmAssociationInsertRequestSuccess() {
    return {
        type: SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_SUCCESS
    };
}

export function securityFirmAssociationInsertRequestFailure(error) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_INSERT_REQUEST_FAILURE,
        error
    };
}

export function securityFirmAssociationRemoveRequest(venueSecurityFirmId) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST,
        venueSecurityFirmId
    };
}

export function securityFirmAssociationRemoveRequestSuccess() {
    return {
        type: SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_SUCCESS
    };
}

export function securityFirmAssociationRemoveRequestFailure(error) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmAssociationRestoreRequest(venueSecurityFirmId) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST,
        venueSecurityFirmId
    };
}

export function securityFirmAssociationRestoreRequestSuccess() {
    return {
        type: SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_SUCCESS
    };
}

export function securityFirmAssociationRestoreRequestFailure(error) {
    return {
        type: SECURITY_FIRM_ASSOCIATION_RESTORE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmAssociationsRefresh() {
    return {
        type: SECURITY_FIRM_ASSOCIATIONS_REFRESH
    }
}

export function securityFirmAssociationsRequest(forVenueId, forSecurityFirmId, page, pageSize) {
    return {
        type: SECURITY_FIRM_ASSOCIATIONS_REQUEST,
        forVenueId,
        forSecurityFirmId,
        page,
        pageSize
    };
}

export function securityFirmAssociationsRequestSuccess(venueSecurityFirms, paging) {
    return {
        type: SECURITY_FIRM_ASSOCIATIONS_REQUEST_SUCCESS,
        venueSecurityFirms,
        paging
    };
}

export function securityFirmAssociationsRequestFailure(error) {
    return {
        type: SECURITY_FIRM_ASSOCIATIONS_REQUEST_FAILURE,
        error
    };
}

// Sub Associations


export function securityFirmSubAssociationReset() {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_RESET
    };
}

export function securityFirmSubAssociationSet(securityFirmAssociation) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_SET,
        securityFirmAssociation
    };
}

export function securityFirmSubAssociationCreateRequest(forSecurityFirmId) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST,
        forSecurityFirmId
    };
}

export function securityFirmSubAssociationCreateRequestSuccess(securityFirmAssociation, securityFirms) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_SUCCESS,
        securityFirmAssociation,
        securityFirms
    };
}

export function securityFirmSubAssociationCreateRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmSubAssociationInsertRequest() {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST
    };
}

export function securityFirmSubAssociationInsertRequestSuccess() {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_SUCCESS
    };
}

export function securityFirmSubAssociationInsertRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_INSERT_REQUEST_FAILURE,
        error
    };
}

export function securityFirmSubAssociationRemoveRequest(securityFirmAssociationId, allAssociations) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST,
        securityFirmAssociationId,
        allAssociations
    };
}

export function securityFirmSubAssociationRemoveRequestSuccess() {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_SUCCESS
    };
}

export function securityFirmSubAssociationRemoveRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATION_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function securityFirmSubAssociationsRefresh() {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATIONS_REFRESH
    }
}

export function securityFirmSubAssociationsRequest(forSecurityFirmId, page, pageSize) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST,
        forSecurityFirmId,
        page,
        pageSize
    };
}

export function securityFirmSubAssociationsRequestSuccess(securityFirmAssociations, paging) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_SUCCESS,
        securityFirmAssociations,
        paging
    };
}

export function securityFirmSubAssociationsRequestFailure(error) {
    return {
        type: SECURITY_FIRM_SUB_ASSOCIATIONS_REQUEST_FAILURE,
        error
    };
}