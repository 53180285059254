import React, { Component } from 'react';
import PageTitle from '../../common/PageTitle';
import AdminFacialRecognitionServersContainer from '../../../containers/AdminFacialRecognitionServersContainer';

export interface IAAdminFacialRecognitionServersProps {
}

interface IAdminFacialRecognitionServersState {
}

export default class AdminFacialRecognitionServers extends Component<IAAdminFacialRecognitionServersProps, IAdminFacialRecognitionServersState> {
    constructor(props: IAAdminFacialRecognitionServersProps) {
        super(props)

        this.state = {
        };
    }

    render() {
        
        return (<>
            <PageTitle title="Cloud Servers"></PageTitle>
            <AdminFacialRecognitionServersContainer venueId={0} />
        </>);
    }
}