import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import EventsCopy from '../components/externalEvents/common/EventsCopy'
import * as externalEventActions from '../common/actions/externalEvent';


const mapStateToProps = (state, props) => {
    return {
        open: props.open,
        onClose: props.onClose,
        filter: state.externalEvents.externalEvents.filter,
        isLoading: state.externalEvents.copy.isLoading,
        isSaving: state.externalEvents.copy.isSaving,
        canSave: state.externalEvents.copy.canSave,
        toVenueId: state.externalEvents.copy.toVenueId,
        venues: state.externalEvents.copy.venues,
        externalEventSelections: state.externalEvents.copy.externalEventSelections,
        errorMessage: state.externalEvents.copy.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (filter) => {
            dispatch(externalEventActions.externalEventCopyRequest(filter))
        },
        onSave: () => {
            dispatch(externalEventActions.externalEventCopySaveRequest())
        },
        onSetVenue: (toVenueId) => {
            dispatch(externalEventActions.externalEventCopySetVenue(toVenueId))
        },
        onSetSelections: (externalEventSelections) => {
            dispatch(externalEventActions.externalEventCopySetSelections(externalEventSelections))
        }
    }
}

const EventsCopyContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EventsCopy))

export default EventsCopyContainer