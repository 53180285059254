export const
    GROUPS_RESET = "GROUPS_RESET",
    GROUPS_SET_FILTER = "GROUPS_SET_FILTER",
    GROUPS_REQUEST = "GROUPS_REQUEST",
    GROUPS_REQUEST_FAILURE = "GROUPS_REQUEST_FAILURE",
    GROUPS_REQUEST_SUCCESS = "GROUPS_REQUEST_SUCCESS",
    GROUP_SET = "GROUP_SET",
    GROUP_REQUEST = "GROUP_REQUEST",
    GROUP_REQUEST_SUCCESS = "GROUP_REQUEST_SUCCESS",
    GROUP_REQUEST_FAILURE = "GROUP_REQUEST_FAILURE",
    GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST",
    GROUP_CREATE_REQUEST_SUCCESS = "GROUP_CREATE_REQUEST_SUCCESS",
    GROUP_CREATE_REQUEST_FAILURE = "GROUP_CREATE_REQUEST_FAILURE",
    GROUP_UPSERT_REQUEST = "GROUP_UPSERT_REQUEST",
    GROUP_UPSERT_REQUEST_SUCCESS = "GROUP_UPSERT_REQUEST_SUCCESS",
    GROUP_UPSERT_REQUEST_FAILURE = "GROUP_UPSERT_REQUEST_FAILURE",
    GROUP_VENUES_AVAILABLE_REQUEST = "GROUP_VENUES_AVAILABLE_REQUEST",
    GROUP_VENUES_AVAILABLE_REQUEST_SUCCESS = "GROUP_VENUES_AVAILABLE_REQUEST_SUCCESS",
    GROUP_VENUES_AVAILABLE_REQUEST_FAILURE = "GROUP_VENUES_AVAILABLE_REQUEST_FAILURE",
    GROUP_VENUES_INSERT_REQUEST = "GROUP_VENUES_INSERT_REQUEST",
    GROUP_VENUES_INSERT_REQUEST_SUCCESS = "GROUP_VENUES_INSERT_REQUEST_SUCCESS",
    GROUP_VENUES_INSERT_REQUEST_FAILURE = "GROUP_VENUES_INSERT_REQUEST_FAILURE",
    GROUP_VENUES_SET = "GROUP_VENUES_SET",
    GROUP_VENUES_AUTOAPPROVAL_SET = "GROUP_VENUES_AUTOAPPROVAL_SET",
    GROUP_VENUE_UPDATE_REQUEST = "GROUP_VENUE_UPDATE_REQUEST",
    GROUP_VENUE_UPDATE_REQUEST_SUCCESS = "GROUP_VENUE_UPDATE_REQUEST_SUCCESS",
    GROUP_VENUE_UPDATE_REQUEST_FAILURE = "GROUP_VENUE_UPDATE_REQUEST_FAILURE",
    GROUP_USERS_AVAILABLE_REQUEST = "GROUP_USERS_AVAILABLE_REQUEST",
    GROUP_USERS_AVAILABLE_REQUEST_SUCCESS = "GROUP_USERS_AVAILABLE_REQUEST_SUCCESS",
    GROUP_USERS_AVAILABLE_REQUEST_FAILURE = "GROUP_USERS_AVAILABLE_REQUEST_FAILURE",
    GROUP_USERS_INSERT_REQUEST = "GROUP_USERS_INSERT_REQUEST",
    GROUP_USERS_INSERT_REQUEST_SUCCESS = "GROUP_USERS_INSERT_REQUEST_SUCCESS",
    GROUP_USERS_INSERT_REQUEST_FAILURE = "GROUP_USERS_INSERT_REQUEST_FAILURE",
    GROUP_USERS_SET = "GROUP_USERS_SET",
    GROUP_USERS_AUTOAPPROVAL_SET = "GROUP_USERS_AUTOAPPROVAL_SET",
    GROUP_USERS_USERROLETYPE_SET = "GROUP_USERS_USERROLETYPE_SET",
    GROUP_USERS_TEMPORARY_SET = "GROUP_USERS_TEMPORARY_SET",
    GROUP_USER_UPDATE_REQUEST = "GROUP_USER_UPDATE_REQUEST",
    GROUP_USER_UPDATE_REQUEST_SUCCESS = "GROUP_USER_UPDATE_REQUEST_SUCCESS",
    GROUP_USER_UPDATE_REQUEST_FAILURE = "GROUP_USER_UPDATE_REQUEST_FAILURE",
    GROUP_SET_TAB = "GROUP_SET_TAB",
    GROUP_DASHBOARD_RESET = "GROUP_DASHBOARD_RESET",
    GROUP_DASHBOARD_FILTER_REQUEST = "GROUP_DASHBOARD_FILTER_REQUEST",
    GROUP_DASHBOARD_FILTER_REQUEST_FAILURE = "GROUP_DASHBOARD_FILTER_REQUEST_FAILURE",
    GROUP_DASHBOARD_FILTER_REQUEST_SUCCESS = "GROUP_DASHBOARD_FILTER_REQUEST_SUCCESS",
    GROUP_DASHBOARD_SET_FILTER = "GROUP_DASHBOARD_SET_FILTER",
    GROUP_DASHBOARD_REQUEST = "GROUP_DASHBOARD_REQUEST",
    GROUP_DASHBOARD_REQUEST_FAILURE = "GROUP_DASHBOARD_REQUEST_FAILURE",
    GROUP_DASHBOARD_REQUEST_SUCCESS = "GROUP_DASHBOARD_REQUEST_SUCCESS",
    GROUP_DASHBOARD_EXPORT_REQUEST = "GROUP_DASHBOARD_EXPORT_REQUEST",
    GROUP_DASHBOARD_EXPORT_REQUEST_SUCCESS = "GROUP_DASHBOARD_EXPORT_REQUEST_SUCCESS",
    GROUP_DASHBOARD_EXPORT_REQUEST_FAILURE = "GROUP_DASHBOARD_EXPORT_REQUEST_FAILURE",
    GROUPS_SELECTION_REQUEST = "GROUPS_SELECTION_REQUEST",
    GROUPS_SELECTION_REQUEST_SUCCESS = "GROUPS_SELECTION_REQUEST_SUCCESS",
    GROUPS_SELECTION_REQUEST_FAILURE = "GROUPS_SELECTION_REQUEST_FAILURE",
    GROUP_ACCESS_REQUEST = "GROUP_ACCESS_REQUEST",
    GROUP_ACCESS_REQUEST_SUCCESS = "GROUP_ACCESS_REQUEST_SUCCESS",
    GROUP_ACCESS_REQUEST_FAILURE = "GROUP_ACCESS_REQUEST_FAILURE",
    GROUP_ACCESS_REQUEST_REJECT = "GROUP_ACCESS_REQUEST_REJECT",
    GROUP_ACCESS_REQUEST_REJECT_SUCCESS = "GROUP_ACCESS_REQUEST_REJECT_SUCCESS",
    GROUP_ACCESS_REQUEST_REJECT_FAILURE = "GROUP_ACCESS_REQUEST_REJECT_FAILURE",
    GROUP_DASHBOARD_INCIDENTS_REQUEST = "GROUP_DASHBOARD_INCIDENTS_REQUEST",
    GROUP_DASHBOARD_INCIDENTS_REQUEST_FAILURE = "GROUP_DASHBOARD_INCIDENTS_REQUEST_FAILURE",
    GROUP_DASHBOARD_INCIDENTS_REQUEST_SUCCESS = "GROUP_DASHBOARD_INCIDENTS_REQUEST_SUCCESS",
    GROUP_DASHBOARD_SET_OPTIONS = "GROUP_DASHBOARD_SET_OPTIONS",
    GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST = "GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST",
    GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_FAILURE = "GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_FAILURE",
    GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_SUCCESS = "GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_SUCCESS"
    ;

export function groupsSelectionRequest() {
    return {
        type: GROUPS_SELECTION_REQUEST
    };
}

export function groupsSelectionRequestSuccess(groups) {
    return {
        type: GROUPS_SELECTION_REQUEST_SUCCESS,
        groups
    };
}

export function groupsSelectionRequestFailure(error) {
    return {
        type: GROUPS_SELECTION_REQUEST_FAILURE,
        error
    };
}

export function groupAccessRequest(groupId) {
    return {
        type: GROUP_ACCESS_REQUEST,
        groupId
    };
}

export function groupAccessRequestSuccess() {
    return {
        type: GROUP_ACCESS_REQUEST_SUCCESS
    };
}

export function groupAccessRequestFailure(error) {
    return {
        type: GROUP_ACCESS_REQUEST_FAILURE,
        error
    };
}

export function groupAccessRequestReject(notificationId, groupId) {
    return {
        type: GROUP_ACCESS_REQUEST_REJECT,
        notificationId,
        groupId
    };
}

export function groupAccessRequestRejectSuccess(group) {
    return {
        type: GROUP_ACCESS_REQUEST_REJECT_SUCCESS,
        group
    };
}

export function groupAccessRequestRejectFailure(error) {
    return {
        type: GROUP_ACCESS_REQUEST_REJECT_FAILURE,
        error
    };
}

export function groupsReset() {
    return {
        type: GROUPS_RESET
    };
}

export function groupsSetFilter(filter) {
    return {
        type: GROUPS_SET_FILTER,
        filter
    }
}

export function groupsRequest() {
    return {
        type: GROUPS_REQUEST
    };
}

export function groupsRequestSuccess(filter, groups) {
    return {
        type: GROUPS_REQUEST_SUCCESS,
        filter,
        groups
    };
}

export function groupsRequestFailure(error) {
    return {
        type: GROUPS_REQUEST_FAILURE,
        error
    };
}

export function groupSet(group) {
    return {
        type: GROUP_SET,
        group
    };
}

export function groupRequest(groupId) {
    return {
        type: GROUP_REQUEST,
        groupId
    };
}

export function groupRequestSuccess(group, userRoleStatuses, userRoleTypes, timeZones) {
    return {
        type: GROUP_REQUEST_SUCCESS,
        group,
        userRoleStatuses,
        userRoleTypes,
        timeZones
    };
}

export function groupRequestFailure(error) {
    return {
        type: GROUP_REQUEST_FAILURE,
        error
    };
}

export function groupCreateRequest() {
    return {
        type: GROUP_CREATE_REQUEST
    };
}

export function groupCreateRequestSuccess(group, timeZones) {
    return {
        type: GROUP_CREATE_REQUEST_SUCCESS,
        group,
        timeZones
    };
}

export function groupCreateRequestFailure(error) {
    return {
        type: GROUP_CREATE_REQUEST_FAILURE,
        error
    };
}

export function groupUpsertRequest() {
    return {
        type: GROUP_UPSERT_REQUEST
    };
}

export function groupUpsertRequestSuccess(group) {
    return {
        type: GROUP_UPSERT_REQUEST_SUCCESS,
        group
    };
}

export function groupUpsertRequestFailure(error) {
    return {
        type: GROUP_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function groupVenuesAvailableRequest(groupId) {
    return {
        type: GROUP_VENUES_AVAILABLE_REQUEST,
        groupId
    };
}

export function groupVenuesAvailableRequestSuccess(venues) {
    return {
        type: GROUP_VENUES_AVAILABLE_REQUEST_SUCCESS,
        venues
    };
}

export function groupVenuesAvailableRequestFailure(error) {
    return {
        type: GROUP_VENUES_AVAILABLE_REQUEST_FAILURE,
        error
    };
}

export function groupSetVenues(venues) {
    return {
        type: GROUP_VENUES_SET,
        venues
    };
}

export function groupSetVenueAutoApprove(autoApproval) {
    return {
        type: GROUP_VENUES_AUTOAPPROVAL_SET,
        autoApproval
    };
}

export function groupVenuesInsertRequest(groupId, venues, autoApproval) {
    return {
        type: GROUP_VENUES_INSERT_REQUEST,
        groupId,
        venues,
        autoApproval
    };
}

export function groupVenuesInsertRequestSuccess(venues, group) {
    return {
        type: GROUP_VENUES_INSERT_REQUEST_SUCCESS,
        venues,
        group
    };
}

export function groupVenuesInsertRequestFailure(error) {
    return {
        type: GROUP_VENUES_INSERT_REQUEST_FAILURE,
        error
    };
}

export function groupUsersAvailableRequest(groupId) {
    return {
        type: GROUP_USERS_AVAILABLE_REQUEST,
        groupId
    };
}

export function groupUsersAvailableRequestSuccess(users, userRoleTypes) {
    return {
        type: GROUP_USERS_AVAILABLE_REQUEST_SUCCESS,
        users,
        userRoleTypes
    };
}

export function groupUsersAvailableRequestFailure(error) {
    return {
        type: GROUP_USERS_AVAILABLE_REQUEST_FAILURE,
        error
    };
}

export function groupSetUsers(users) {
    return {
        type: GROUP_USERS_SET,
        users
    };
}

export function groupSetUserAutoApprove(autoApproval) {
    return {
        type: GROUP_USERS_AUTOAPPROVAL_SET,
        autoApproval
    };
}

export function groupUsersInsertRequest(groupId, users, autoApproval, userRoleTypeId, isTemporary, expiryDate) {
    return {
        type: GROUP_USERS_INSERT_REQUEST,
        groupId,
        users,
        autoApproval,
        userRoleTypeId,
        isTemporary,
        expiryDate
    };
}

export function groupUsersInsertRequestSuccess(users, group) {
    return {
        type: GROUP_USERS_INSERT_REQUEST_SUCCESS,
        users,
        group
    };
}

export function groupUsersInsertRequestFailure(error) {
    return {
        type: GROUP_USERS_INSERT_REQUEST_FAILURE,
        error
    };
}

export function groupSetUserRoleType(userRoleTypeId) {
    return {
        type: GROUP_USERS_USERROLETYPE_SET,
        userRoleTypeId
    };
}

export function groupSetTemporary(isTemporary, expiryDate) {
    return {
        type: GROUP_USERS_TEMPORARY_SET,
        isTemporary,
        expiryDate
    };
}

export function groupSetTab(tabIndex) {
    return {
        type: GROUP_SET_TAB,
        tabIndex
    };
}

export function groupDashboardExportRequest() {
    return {
        type: GROUP_DASHBOARD_EXPORT_REQUEST
    };
}

export function groupDashboardExportRequestSuccess() {
    return {
        type: GROUP_DASHBOARD_EXPORT_REQUEST_SUCCESS
    };
}

export function groupDashboardExportRequestFailure(error) {
    return {
        type: GROUP_DASHBOARD_EXPORT_REQUEST_FAILURE
    };
}

export function groupDashboardReset() {
    return {
        type: GROUP_DASHBOARD_RESET
    };
}

export function groupDashboardSetFilter(filter) {
    return {
        type: GROUP_DASHBOARD_SET_FILTER,
        filter
    }
}

export function groupDashboardFilterRequest(groupId) {
    return {
        type: GROUP_DASHBOARD_FILTER_REQUEST,
        groupId
    };
}

export function groupDashboardFilterRequestSuccess(filter) {
    return {
        type: GROUP_DASHBOARD_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function groupDashboardFilterRequestFailure(error) {
    return {
        type: GROUP_DASHBOARD_FILTER_REQUEST_FAILURE,
        error
    };
}

export function groupDashboardRequest() {
    return {
        type: GROUP_DASHBOARD_REQUEST
    };
}

export function groupDashboardRequestSuccess(filter, data) {
    return {
        type: GROUP_DASHBOARD_REQUEST_SUCCESS,
        filter,
        data
    };
}

export function groupDashboardRequestFailure(error) {
    return {
        type: GROUP_DASHBOARD_REQUEST_FAILURE,
        error
    };
}

export function groupVenueUpdateRequest(groupId, groupVenue) {
    return {
        type: GROUP_VENUE_UPDATE_REQUEST,
        groupId,
        groupVenue
    };
}

export function groupVenueUpdateRequestSuccess(group) {
    return {
        type: GROUP_VENUE_UPDATE_REQUEST_SUCCESS,
        group
    };
}

export function groupVenueUpdateRequestFailure(error) {
    return {
        type: GROUP_VENUE_UPDATE_REQUEST_FAILURE,
        error
    };
}

export function groupUserUpdateRequest(groupId, groupUser) {
    return {
        type: GROUP_USER_UPDATE_REQUEST,
        groupId,
        groupUser
    };
}

export function groupUserUpdateRequestSuccess(group) {
    return {
        type: GROUP_USER_UPDATE_REQUEST_SUCCESS,
        group
    };
}

export function groupUserUpdateRequestFailure(error) {
    return {
        type: GROUP_USER_UPDATE_REQUEST_FAILURE,
        error
    };
}

export function groupDashboardSetOptions(options) {
    return {
        type: GROUP_DASHBOARD_SET_OPTIONS,
        options
    };
}

export function groupDashboardIncidentsRequest() {
    return {
        type: GROUP_DASHBOARD_INCIDENTS_REQUEST
    };
}

export function groupDashboardIncidentsRequestSuccess(incidents, venueMarkers) {
    return {
        type: GROUP_DASHBOARD_INCIDENTS_REQUEST_SUCCESS,
        incidents,
        venueMarkers
    };
}

export function groupDashboardIncidentsRequestFailure(error) {
    return {
        type: GROUP_DASHBOARD_INCIDENTS_REQUEST_FAILURE,
        error
    };
}

export function groupChecklistTemplateCloneRequest(groupId, checklistTemplateId) {
    return {
        type: GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST,
        groupId, 
        checklistTemplateId
    };
}

export function groupChecklistTemplateCloneRequestSuccess(checklistTemplateId) {
    return {
        type: GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_SUCCESS,
        checklistTemplateId
    };
}

export function groupChecklistTemplateCloneRequestFailure(error) {
    return {
        type: GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_FAILURE,
        error
    };
}
