import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SearchDate } from "../../common/dto/AusComply.dtos";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import DarkButton from '../controls/DarkButton';
import Grid from '@material-ui/core/Grid';

export interface ISearchDateControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    rightText?: string;
    error?: string;
    date?: SearchDate;
    readonly?: boolean;
    onChange?: Function;
    onEnter?: Function;
    labelAbove?: boolean;
    defaultTime?: string;
}
class SearchDateControlGroup extends React.PureComponent<ISearchDateControlGroupProps, any> {
    constructor(props: ISearchDateControlGroupProps) {
        super(props)
        this.state = {
            date: "",
            time: "",
            isEmpty: true
        }

        this.onClear = this.onClear.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onBlurDate = this.onBlurDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onBlurTime = this.onBlurTime.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        let date = this.convertedDate(this.props.date, false);
        let time = this.convertedTime(this.props.date, false);
        let isEmpty = false;
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
        }
        this.setState({
            date,
            time,
            isEmpty
        });
    }


    componentDidUpdate(prevProps) {
        let date = this.convertedDate(this.props.date, false);
        let datePrevious = this.convertedDate(prevProps.date, false);
        let time = this.convertedTime(this.props.date, false);
        let timePrevious = this.convertedTime(prevProps.date, false);
        let isEmpty = this.state.isEmpty;
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
        }
        if (datePrevious != date || timePrevious != time) {
            this.setState({
                date,
                time,
                isEmpty
            });
        }
    }

    convertedDate(value, force) {
        if (value && (!value.isEmpty || force)) {
            return [value.year, "-",
            (value.month > 9 ? '' : '0') + value.month, "-",
            (value.day > 9 ? '' : '0') + value.day
            ].join('');
        }
        return "";
    }

    convertedTime(value, force) {
        if (value && (!value.isEmpty || force)) {
            return [
                (value.hour > 9 ? '' : '0') + value.hour, ":",
                (value.minute > 9 ? '' : '0') + value.minute,
                ":00"
            ].join('');
        }
        return "";
    }

    onChangeDate(event) {
        if (event.target.value) {
            let time = this.state.time;
            if (this.state.isEmpty) {
                time = this.props.defaultTime || this.convertedTime(this.props.date, true);
            }
            this.setState({
                date: event.target.value.toString(),
                time,
                isEmpty: false
            });
        }
    }

    onBlurDate(event) {
        this.onUpdated(false);
    }

    onChangeTime(event) {
        if (event.target.value) {
            let date = this.state.date;
            if (this.state.isEmpty) {
                date = this.convertedDate(this.props.date, true);
            }
            let time = this.state.time;
            let newTime = event.target.value.toString();
            time = newTime;
            this.setState({
                date,
                time,
                isEmpty: false
            });
        }
    }

    onBlurTime(event) {
        this.onUpdated(false);
    }

    onClear() {
        this.setState({
            date: "",
            time: "",
            isEmpty: true
        }, () => {
            this.onUpdated(false);
        });

    }

    onUpdated(wasEnter) {
        if (this.props.onChange && this.props.date) {
            var newDate = this.props.date;
            newDate.isEmpty = this.state.isEmpty;
            if (this.state.date) {
                let dateValue = new Date(this.state.date);
                if (isNaN(dateValue.getFullYear())) {
                    newDate.year = new Date().getFullYear();
                } else {
                    newDate.year = dateValue.getFullYear();
                }
                if (isNaN(dateValue.getMonth() + 1)) {
                    newDate.month = new Date().getMonth() + 1;
                } else {
                    newDate.month = dateValue.getMonth() + 1;
                }
                if (isNaN(dateValue.getDate())) {
                    newDate.day = new Date().getDate();
                } else {
                    newDate.day = dateValue.getDate();
                }
            }
            if (this.state.time) {
                let timeValue = new Date("1 Jan 2000 " + this.state.time);
                if (isNaN(timeValue.getHours())) {
                    newDate.hour = new Date().getHours();
                } else {
                    newDate.hour = timeValue.getHours();
                }
                if (isNaN(timeValue.getMinutes())) {
                    newDate.minute = new Date().getMinutes();
                } else {
                    newDate.minute = timeValue.getMinutes();
                }
            }
            this.props.onChange(newDate);
            if (wasEnter && this.props.onEnter) {
                this.props.onEnter();
            }
        }
    }

    onKeyPress = (event) => {
        if (event.key == 'Enter') {
            this.onUpdated(true);
        }
    }

    render() {

        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;

        return (
            <ControlGroup text={this.props.text} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>

                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                            <InputBase
                                type="date"
                                value={this.state.date}
                                onBlur={this.onBlurDate}
                                onChange={this.onChangeDate}
                                disabled={this.props.readonly}
                                onKeyPress={this.onKeyPress}
                                style={style}
                                error={this.props.error ? true : false}
                                classes={{ root: classes.inputControl, input: classes.inputRoot }}
                                inputProps={{max: '9999-12-31'}}
                            />
                            {this.props.error && (
                                <FormHelperText>{this.props.error}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                            <InputBase
                                type="time"
                                value={this.state.time}
                                onBlur={this.onBlurTime}
                                onChange={this.onChangeTime}
                                disabled={this.props.readonly}
                                onKeyPress={this.onKeyPress}
                                style={style}
                                error={this.props.error ? true : false}
                                classes={{ root: classes.inputControl, input: classes.inputRoot }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        {!this.props.readonly && (
                            <DarkButton onClick={this.onClear} >X</DarkButton>
                        )}
                    </Grid>
                </Grid>


            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SearchDateControlGroup);