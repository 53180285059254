import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { deviceInfo } from '../../utilities/device';

export const facialRecognitionServerServiceApi = {
  getServers: (venueId: number, securityFirmId: number, forVenueId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionServers();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.get(query);
    return from(request);
  },
  postServersTest: (venueId: number, securityFirmId: number, facialRecognitionServerId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionServerTest();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionServerId = facialRecognitionServerId;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionServerNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, facialRecognitionServer) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionServer();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionServer = facialRecognitionServer;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, facialRecognitionServerId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionServer();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionServerId = facialRecognitionServerId;
    const request = client.get(query);
    return from(request);
  },
  clone: (venueId, securityFirmId, facialRecognitionServerId, toVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionServerClone();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionServerId = facialRecognitionServerId;
    query.toVenueId = toVenueId;
    const request = client.post(query);
    return from(request);
  }
};
