import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ControlGroup from "./ControlGroup";
import Box from '@material-ui/core/Box';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import NumericUpDown from './NumericUpDown';
import FormControl from '@material-ui/core/FormControl';
import Label from './Label'

export interface IAgeRangeProps {
    theme: any;
    classes: any;
    from: number;
    to: number;
    readonly?: boolean;
    onChanged: Function;
}

class AgeRange extends React.PureComponent<IAgeRangeProps, any> {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <ControlGroup text={"Age range"}>
                <Box p={0} display="flex" flexDirection={'row'}>
                    <Box flexGrow={1} p={0}>
                        <FormControl style={{ width: '100%' }}>
                            <NumericUpDown value={this.props.from || 0} onChange={(value) => this.props.onChanged(value, this.props.to)} />
                        </FormControl>
                    </Box>
                    <Box p={0}>
                        <Label text={" To "} />
                    </Box>
                    <Box flexGrow={1} p={0}>
                        <FormControl style={{ width: '100%' }}>
                            <NumericUpDown value={this.props.to || 0} onChange={(value) => this.props.onChanged(this.props.from, value)} />
                        </FormControl>
                    </Box>
                </Box>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AgeRange);