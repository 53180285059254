import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { RadioOption } from '../../common/dto/common';
import RadioControl from '../controls/RadioControl';
import CheckboxControl from '../controls/CheckboxControl'
import SelectControlGroup from '../controls/SelectControlGroup';
import SearchDateControlGroup from '../controls/SearchDateControlGroup';
import TextControlGroup from '../controls/TextControlGroup';
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import SignOnRegisterFilterTypes from './SignOnRegisterFilterTypes';
import View from '../common/View';
import LayoutForm from '../layout/LayoutForm';
import FullPageDialog from '../layout/FullPageDialog';

export interface ISignOnRegisterFilterProps {
    open: boolean;
    filter: AusComplyDTOs.SignOnRegisterFilter;
    showActuals: boolean;
    onShowActualsChanged: Function;
    onUpdateFilter: Function;
    onCancel: Function;
    onReset: Function;
    onSearch: Function;
    classes: any;
    theme: any;
}

interface ISignOnRegisterFilterState {
    filter: AusComplyDTOs.SignOnRegisterFilter;
}

class SignOnRegisterFilter extends React.Component<ISignOnRegisterFilterProps, ISignOnRegisterFilterState> {

    constructor(props: ISignOnRegisterFilterProps) {
        super(props);
        this.showActualsChanged = this.showActualsChanged.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onFilterByOptionChanged = this.onFilterByOptionChanged.bind(this);
        this.onSelectedReportingPeriodChanged = this.onSelectedReportingPeriodChanged.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);

        this.state = {
            filter: new AusComplyDTOs.SignOnRegisterFilter()
        };


    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    showActualsChanged(value) {
        if (this.props.onShowActualsChanged) {
            this.props.onShowActualsChanged(value);
        }
    }

    onOptionChanged(value) {
        let filter = { ... this.state.filter };
        filter.signOnRegisterViewOptionId = value.value;
        this.setState({
            filter
        }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onFilterByOptionChanged(value) {
        let filter = { ... this.state.filter };
        filter.byReportingPeriod = value.value == "1";
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onSelectedReportingPeriodChanged(value: any) {
        let filter = { ... this.state.filter };
        filter.reportingPeriodId = parseInt(value.toString());
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onFromChanged(value) {
        let filter = { ...this.state.filter };
        filter.from = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onToChanged(value) {
        let filter = { ...this.state.filter };
        filter.to = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onSearchChange(value) {
        let filter = { ... this.state.filter };
        filter.search = value;
        filter.paging.page = 1;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <></>
            );
        }
        const { classes, theme } = this.props;

        let self = this;
        let viewOptions: RadioOption[] = [];
        let viewOption;

        if (this.state.filter.options) {
            viewOption = this.state.filter.signOnRegisterViewOptionId.toString();
            this.state.filter.options.forEach(item => {
                viewOptions.push({ id: item.signOnRegisterViewOptionId.toString(), name: item.name });
            });
        }

        let filterByOptions: RadioOption[] = [
            { id: "1", name: "Reporting Period" },
            { id: "2", name: "History" }
        ];
        let filterByOption = this.state.filter.byReportingPeriod ? "1" : "2";
        let filterOptionDisplay;

        if (this.state.filter.byReportingPeriod) {
            filterOptionDisplay = (
                <SelectControlGroup
                    text="Reporting period"
                    labelAbove={true}
                    onChange={this.onSelectedReportingPeriodChanged}
                    defaultValue={this.state.filter.reportingPeriodId}>
                    <option value={0} />
                    {this.state.filter.reportingPeriods.map((item, index) => (
                        <option key={item.venueReportingPeriodID} value={item.venueReportingPeriodID}>{item.reportingPeriodDisplayString}</option>
                    ))}
                </SelectControlGroup>
            );
        } else {
            filterOptionDisplay = (
                <View>
                    <SearchDateControlGroup
                        text="Time Period"
                        labelAbove={true}
                        rightText={"From"}
                        onEnter={() => this.props.onSearch()}
                        date={this.state.filter.from}
                        onChange={this.onFromChanged}
                        defaultTime={"00:00"}
                    />
                    <SearchDateControlGroup
                        rightText="To"
                        labelAbove={true}
                        onEnter={() => this.props.onSearch()}
                        date={this.state.filter.to}
                        onChange={this.onToChanged}
                        defaultTime={"23:59"}
                    />
                    <TextControlGroup text={"Search"}
                        labelAbove={true}
                        defaultValue={this.state.filter.search}
                        onChange={this.onSearchChange}
                        onSubmit={value => {
                            this.onSearchChange(value);
                            this.props.onSearch();
                        }}
                    ></TextControlGroup>
                </View>
            );
        }


        let viewOptionDisplay;
        if (viewOptions.length > 1) {
            viewOptionDisplay = (
                <RadioControl
                    selected={viewOption}
                    options={viewOptions}
                    name={"viewOptions"}
                    onChange={this.onOptionChanged} />
            );
        }

        return <FullPageDialog
            open={this.props.open}
            notFullWidth={true}
            title="Search"
            footerLeftContent={<TransparentButton text={"Reset"} onClick={this.onResetSearch} />}
            footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.props.onSearch()} />}
            onDismissed={() => this.props.onCancel()}>
            <RadioControl
                selected={filterByOption}
                options={filterByOptions}
                name={"filterByOptions"}
                onChange={this.onFilterByOptionChanged} />
            {filterOptionDisplay}
            <View style={{ margin: '20px 0' }}>
                <CheckboxControl text="Show actual start times" defaultValue={this.props.showActuals} onChanged={this.showActualsChanged} />
            </View>
            {viewOptionDisplay}
            <SignOnRegisterFilterTypes
                filter={this.state.filter}
                onUpdate={filter => {
                    filter.paging.page = 1;
                    this.setState({ filter }, () => {
                        this.props.onUpdateFilter(this.state.filter);
                    });
                }}
            />
        </FullPageDialog>

        return (
            <LayoutForm>

            </LayoutForm >
        );
    }
}

export default withStyles(styles, { withTheme: true })(SignOnRegisterFilter);
