import React from 'react';
import { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { HomeStatistics } from '../../common/dto/AusComply.dtos';
import { Link } from 'react-router-dom';
import FabContainer from '../layout/FabContainer';
import PopupModal from '../layout/PopupModal';
import { KeyName } from '../../common/dto/common';
import PrintQueueContainer from '../../containers/PrintQueueContainer';
import ClearButton from '../controls/ClearButton';
import MiddleButtonIcon from '../../resources/MiddleButtonIcon';
import View from '../common/View';

export interface IVenueQuickActionsProps {
    classes: any;
    theme: any;
    compact?: boolean;
    venueIsSelected: boolean;
    requiresSecurityCode: boolean;
    isStatisticsLoading: boolean;
    statistics: HomeStatistics;
    hasDraftIncident: boolean;
    draftIncidentTypeId: number;
    entryPage: string;
    history: any;
}

class VenueQuickActions extends Component<IVenueQuickActionsProps, any> {
    constructor(props: IVenueQuickActionsProps) {
        super(props)
        this.state = {
            open: false
        };
        this.navigateAndClose = this.navigateAndClose.bind(this);
        this.showMenu = this.showMenu.bind(this);
    }

    showMenu = () => {
        this.setState({ open: true });
    };

    navigateAndClose = to => {
        this.setState({ open: false });
        if (to != "") {
            this.props.history.push(to);
        }
    }

    render() {
        let actionCount = 0;
        let singleActionUrl = "";
        if (!this.props.isStatisticsLoading) {
            if (this.props.statistics.incidentTypes && this.props.statistics.incidentTypes.length > 0) {
                if (this.props.hasDraftIncident) {
                    if (this.props.entryPage && this.props.entryPage != "") {
                        singleActionUrl = "/" + this.props.entryPage + "/0";
                        actionCount++;
                        actionCount++;
                    } else {
                        singleActionUrl = "/draftincident/0";
                        actionCount++;
                        actionCount++;
                    }
                }
                else {
                    actionCount = this.props.statistics.incidentTypes.length;
                    if (this.props.statistics.incidentTypes.length == 1 && this.props.statistics.incidentTypes[0].entryPage && this.props.statistics.incidentTypes[0].entryPage != "") {
                        singleActionUrl = "/" + this.props.statistics.incidentTypes[0].entryPage + "/" + this.props.statistics.incidentTypes[0].incidentTypeId;
                    } else if (this.props.statistics.incidentTypes.length > 0) {
                        singleActionUrl = "/draftincident/" + this.props.statistics.incidentTypes[0].incidentTypeId;
                    }
                }
            }
        }

        const { classes } = this.props;
        let className = classNames(classes.fab, classes.fabGreen);

        let mainFab;
        let actions: KeyName[] = [];

        if (this.props.compact) {
            if (this.props.venueIsSelected && !this.props.requiresSecurityCode) {
                if (actionCount === 0) {
                    mainFab = (
                        <></>
                    );
                } else if (actionCount === 1) {
                    mainFab = (
                        <ClearButton
                            className={classes.footerPrimaryButton}
                            to={singleActionUrl}
                            component={Link} >
                            <MiddleButtonIcon />
                        </ClearButton>
                    );
                } else {
                    if (!this.props.hasDraftIncident) {
                        this.props.statistics.incidentTypes.forEach(item => {
                            if (item.entryPage && item.entryPage != "") {
                                actions.push({ key: "/" + item.entryPage + "/" + item.incidentTypeId, name: item.name });
                            } else {
                                actions.push({ key: "/draftincident/" + item.incidentTypeId, name: item.name });
                            }
                        });
                    } else {
                        if (this.props.draftIncidentTypeId && this.props.draftIncidentTypeId > 0) {
                            var incidentType = this.props.statistics.incidentTypes.find(i => i.incidentTypeId == this.props.draftIncidentTypeId);
                            if (incidentType && incidentType.entryPage && incidentType.entryPage != "") {
                                actions.push({ key: "/" + incidentType.entryPage + "/0", name: "Continue entering incident" });
                            } else {
                                actions.push({ key: "/draftincident/0", name: "Continue entering incident" });
                            }
                        } else {
                            actions.push({ key: "/draftincident/0", name: "Continue entering incident" });
                        }
                        actions.push({ key: "/draftincident/4", name: "Save draft and start new incident" });
                    }
                    mainFab = (
                        <ClearButton className={classes.footerPrimaryButton} style={{}}
                            aria-haspopup="true"
                            onClick={this.showMenu}
                        >
                            <MiddleButtonIcon className={classes.footerPrimaryButtonIcon} />
                        </ClearButton>
                    );
                }
            }
            return <View style={{ width: '100%', height: '100%' }}>
                {mainFab}
                <PopupModal open={this.state.open} onAction={action => this.navigateAndClose(action)} actions={actions} />
            </View>
        }


        if (this.props.venueIsSelected && !this.props.requiresSecurityCode) {
            if (actionCount === 0) {
                mainFab = (
                    <></>
                );
            } else if (actionCount === 1) {
                mainFab = (
                    <Fab className={className}
                        color={'inherit'}
                        onClick={() => this.navigateAndClose(singleActionUrl)} >
                        <AddIcon style={{ marginTop: '5px', fontSize: '2.5em' }} />
                    </Fab>
                );
            } else {
                if (!this.props.hasDraftIncident) {
                    this.props.statistics.incidentTypes.forEach(item => {
                        if (item.entryPage && item.entryPage != "") {
                            actions.push({ key: "/" + item.entryPage + "/" + item.incidentTypeId, name: item.name });
                        } else {
                            actions.push({ key: "/draftincident/" + item.incidentTypeId, name: item.name });
                        }
                    });
                } else {
                    // we should open the corrent type
                    if (this.props.draftIncidentTypeId && this.props.draftIncidentTypeId > 0) {
                        var incidentType = this.props.statistics.incidentTypes.find(i => i.incidentTypeId == this.props.draftIncidentTypeId);
                        if (incidentType && incidentType.entryPage && incidentType.entryPage != "") {
                            actions.push({ key: "/" + incidentType.entryPage + "/0", name: "Continue entering incident" });
                        } else {
                            actions.push({ key: "/draftincident/0", name: "Continue entering incident" });
                        }
                    } else {
                        actions.push({ key: "/draftincident/0", name: "Continue entering incident" });
                    }
                    actions.push({ key: "/draftincident/4", name: "Save draft and start new incident" });
                }
                mainFab = (
                    <Fab className={className}
                        color={'inherit'}
                        aria-haspopup="true"
                        onClick={this.showMenu}
                    >
                        <AddIcon style={{ marginTop: '5px', fontSize: '2.5em' }} />
                    </Fab>
                );
            }
        }

        return (
            <>
                <FabContainer>
                    {this.props.children}
                    <PrintQueueContainer />
                    {mainFab}
                </FabContainer>
                <PopupModal open={this.state.open} onAction={action => this.navigateAndClose(action)} actions={actions} />
            </>
        );
    }
}


export default withStyles(styles, { withTheme: true })(VenueQuickActions);