import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IIncidentFlagTypeProps {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IIncidentFlagTypeState {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
    industryCategories: MulitSelectOption[];
    error: string;
}

export default class IncidentFlagType extends Component<IIncidentFlagTypeProps, IIncidentFlagTypeState> {
    constructor(props: IIncidentFlagTypeProps) {
        super(props)
        let incidentFlagType = new AusComplyDtos.IncidentFlagType();
        incidentFlagType.industryCategorySelections = [];
        let error = "";
        this.state = {
            incidentFlagType,
            industryCategories: [],
            error
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
    }

    componentDidMount() {
        let incidentFlagType = { ...this.props.incidentFlagType };
        let industryCategories: MulitSelectOption[] = [];
        if (this.props.incidentFlagType.industryCategorySelections) {
            this.props.incidentFlagType.industryCategorySelections.forEach((item, index) => {
                let industryCategory = new MulitSelectOption();
                industryCategory.id = item.industryCategoryId;
                industryCategory.name = item.name;
                industryCategory.selected = item.selected;
                industryCategory.isCommon = false;
                industryCategories.push(industryCategory);
            });
        }


        this.setState({
            incidentFlagType,
            industryCategories,
            error: ""
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.error) {
                this.setState({ error: this.props.error });
            } else {
                this.props.onCancel();
            }
        }
    }

    onSave() {
        let incidentFlagType = { ...this.state.incidentFlagType };
        if (incidentFlagType.industryCategorySelections) {
            incidentFlagType.industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
        }
        this.props.onSave(incidentFlagType);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentFlagType = { ...this.state.incidentFlagType };
        incidentFlagType[fieldName] = value;
        this.setState({ incidentFlagType });
    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        });
    }

    render() {
        let rank = "0";
        if (this.state.incidentFlagType.rank) {
            rank = this.state.incidentFlagType.rank.toString();
        }


        return (
            <LayoutForm
                loading={false}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        defaultValue={this.state.incidentFlagType.name}
                        onChange={value => this.onValueChanged("name", value)} />

                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />
                    <CheckboxControlGroup
                        text={"Additional Field"}
                        defaultValue={this.state.incidentFlagType.additionalField}
                        onChanged={(value) => this.onValueChanged("additionalField", value)} />
                    {this.state.incidentFlagType.additionalField && (
                        <TextControlGroup
                            text={"Additional Field Name"}
                            defaultValue={this.state.incidentFlagType.fieldLabel}
                            onChange={value => this.onValueChanged("fieldLabel", value)} />
                    )}
                    <CheckboxControlGroup
                        text={"Active"}
                        isReversed={true}
                        defaultValue={this.state.incidentFlagType.obsolete}
                        onChanged={(value) => this.onValueChanged("obsolete", value)} />
                    <CardContainer
                        title={"Industries"}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <div style={{ marginTop: '-4px' }}>
                                <MultiSelectControl
                                    items={this.state.industryCategories}
                                    minimumCharactersRequired={3}
                                    canShowAll={true}
                                    searchText={"Type at least three letters..."}
                                    onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                            </div>
                        </CardContent>
                    </CardContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}