import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import DefaultButton from '../controls/DefaultButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BusinessIcon from '@material-ui/icons/Business';
import IncidentIcon from '../../resources/IncidentIcon';
import ChecklistIcon from '../../resources/ChecklistIcon';
import Box from '@material-ui/core/Box';
import { Palette } from '../../common/constants/palette';

export interface INotificationProps {
    notification: AusComplyDtos.Notification;
    onDismiss?: Function;
    onAccept?: Function;
    canAccept: boolean;
    canDismiss: boolean;
    history?: any;
    theme: any;
    classes: any;
}

class Notification extends Component<INotificationProps, any> {
    constructor(props: INotificationProps) {
        super(props)
        this.state = {
        }
        this.onNavigateTo = this.onNavigateTo.bind(this);
    }

    onNavigateTo(notification: AusComplyDtos.Notification, url: string) {
        if (this.props.canAccept && this.props.onAccept) {
            this.props.onAccept(notification);
        }
        if (this.props.history) {
            this.props.history.push(url);
        }
    }

    onCreateActions(notification: AusComplyDtos.Notification) {
        // build this list from addNotificationActions in controls/notifications.ascx.cs
        if (notification.notificationTypeCode == 'UserRequestsVenueAccess' ||
            notification.notificationTypeCode == 'UserRequestsSecurityFirmAccess' ||
            notification.notificationTypeCode == 'RegisterSecurityFirmUser' ||
            notification.notificationTypeCode == 'RegisterVenueUser' ||
            notification.notificationTypeCode == 'RegisterOtherUser') {
            // show the modal to confirm or deny a user request
            return (
                <Box p={0} style={{ marginTop: '10px' }}>
                    {this.props.canAccept && (
                        <DefaultButton text={"Approve or Deny"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} />
                    )}
                </Box>
            )
        }
        if (notification.notificationTypeId == 'SuspendL1VenueUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1VenueUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1VenueUserReinstate_Command)
        }
        if (notification.notificationTypeId == 'SuspendL1SecurityFirmUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1SecurityFirmUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1SecurityFirmUserReinstate_Command)
        }
        if (notification.notificationTypeId == 'RecommendVenueIncidentDeletion' ||
            notification.notificationTypeId == 'RecommendSecurityFirmIncidentDeletion') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentRequiresSubmission' ||
            notification.notificationTypeId == 'SecurityFirmIncidentRequiresApproval') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'RequestInvalidSLEDAccess') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Approve or Deny"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry2Weeks' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeId == 'UserDocumentMissing') {

            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Update Documents"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'L4VenueRegisterUserComplianceDocumentExpiring') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Review Compliance Documents"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'L3SecurityFirmRegisterUserComplianceDocumentExpiring' ||
            notification.notificationTypeId == 'L4SecurityFirmRegisterUserComplianceDocumentExpiring') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Review Compliance Documents"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentBulkApproval') {
            // show the modal to confirm or deny a user request
            return (
                <>
                    {this.props.canAccept && (
                        <Box p={0} style={{ marginTop: '10px' }}>
                            <DefaultButton text={"View"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} />
                        </Box>
                    )}
                </>
            );
        }
        if (notification.notificationTypeId == 'Broadcast') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1} >
                                <DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} />
                            </Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'FacialRecognition') {
            return (
                <Box p={0}>
                    {this.props.canDismiss && (
                        <Box display="flex" flexDirection="row">
                            <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        </Box>
                    )}
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentFlagNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentCategoryNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'IncidentActionNotification' ||
            notification.notificationTypeId == 'IncidentActionValueNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'GroupUserAccessRequestNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Group"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == 'GroupVenueInvitationNotification') {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {this.props.canAccept && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Accept"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo(notification, "/incident/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo(notification, "/checklist/" + notification.checklistId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.WatchlistEventNotification) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        {notification.incidentId && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.props.onAccept && this.props.onAccept(notification)} /></Box>
                        )}
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ChecklistSubmitted ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ChecklistValueSubmitted) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo(notification, "/checklist/" + notification.checklistId.toString())} /></Box>
                        {this.props.canDismiss && (
                            <Box flexGrow={1} p={1}><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        )}
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmIncident) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Incident"} onClick={() => this.onNavigateTo(notification, "/incident/" + notification.incidentId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmChecklist) {
            return (
                <Box p={0}>
                    <Box display="flex" flexDirection="row">
                        <Box flexGrow={1} p={1}><DefaultButton text={"View Checklist"} onClick={() => this.onNavigateTo(notification, "/checklist/" + notification.checklistId.toString())} /></Box>
                    </Box>
                </Box>
            )
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenue ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirm) {
            return (
                <Box p={0}>
                    {this.props.canDismiss && (
                        <Box display="flex" flexDirection="row">
                            <Box flexGrow={1} p={1} ><DefaultButton text={"Dismiss"} onClick={() => this.props.onDismiss && this.props.onDismiss(notification)} /></Box>
                        </Box>
                    )}
                </Box>
            )
        }
        return (
            <>
            </>
        );
    }

    notificationBackgroundColor(notification: AusComplyDtos.Notification) {
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#C62828";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#FEFFC6";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist) {
                if (notification.obsolete) {
                    return Palette.Obsolete;
                }
            return notification.isOverDue ? Palette.Error : Palette.Warning;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
            return Palette.Error;
        }
        return "#061570";
    }

    notificationColor(notification: AusComplyDtos.Notification) {
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#5d5c1d";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist) {
            if (notification.obsolete) {
                return Palette.AlternativeText;
            }
            return notification.isOverDue ? Palette.ErrorText : Palette.WarningText;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
            return Palette.ErrorText;
        }
        return "white";
    }

    notificationDateColor(notification: AusComplyDtos.Notification) {
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#bdbdbd";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#5d5c1d";

        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist) {
                if (notification.obsolete) {
                    return Palette.ObsoleteContrastText;
                }
            return notification.isOverDue ? Palette.ErrorTextAlt : Palette.WarningTextAlt;
        }
        if (notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation ||
            notification.notificationTypeCode == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
            return Palette.ErrorTextAlt;
        }
        return "#888";
    }

    notificationBorderColor(notification: AusComplyDtos.Notification) {
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeCode == 'UserDocumentMissing') {
            // expired
            // expires-soon
            // document-missing
            return "#d32f2f";
        }
        if (notification.notificationTypeCode == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeCode == 'UserComplianceDocumentExpiry2Weeks') {
            // expiring
            return "#FEFFC6";
        }
        return "#061570";
    }

    renderIcon(notification: AusComplyDtos.Notification) {
        let icon;
        if (notification.notificationCategoryId == 1) {
            icon = <PermIdentityIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 2) {
            icon = <BusinessIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 3) {
            icon = <BusinessIcon style={{ color: this.notificationDateColor(notification) }} />
        }
        if (notification.notificationCategoryId == 4) {
            icon = <div style={{ height: '20px' }}>
                <IncidentIcon fill={this.notificationDateColor(notification)} />
            </div>
        }
        if (notification.notificationCategoryId == 5) {
            icon = <div style={{ height: '20px' }}>
                <ChecklistIcon fill={this.notificationDateColor(notification)} />
            </div>
        }
        return icon;
    }

    render() {
        const { classes, notification } = this.props;

        if (!notification) {
            return <></>;
        }
        return (
            <Box
                key={"notification-" + notification.notificationId.toString()}
                display="flex" flexDirection="row" justifyContent="center" style={{
                    borderRadius: '5px',
                    backgroundColor: this.notificationBackgroundColor(notification),
                    borderColor: this.notificationBorderColor(notification),
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    width: '100%', marginBottom: '10px'
                }}>
                <Box p={2}>
                    {this.renderIcon(notification)}
                </Box>
                <Box p={2} flexGrow={1}>
                    <Box display="flex" flexDirection="column">
                        <Box p={0}>
                            <p style={{ margin: '2px 0', color: this.notificationColor(notification), whiteSpace: 'pre-line' }}>
                                <span style={{ color: this.notificationDateColor(notification), marginRight: '10px' }}>{notification.dateTimeFormatted}</span>
                                {notification.message}
                            </p>
                        </Box>
                        {this.onCreateActions(notification)}
                    </Box>
                </Box>
            </Box>
        );
    }
}
export default withStyles(styles, { withTheme: true })(Notification)