import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import PageTitle from '../../../common/PageTitle';
import PagedList from '../../../common/PagedList';
import TableContainer from '../../../layout/TableContainer';
import UserActionAuditsCard from './UserActionAuditsCard';
import UserActionAuditsHeader from './UserActionAuditsHeader';
import UserActionAuditsRow from './UserActionAuditsRow';
import UserActionAudit from './UserActionAudit';
import FullPageDialog from '../../../layout/FullPageDialog';

export interface IUserActionAuditsProps {
    userId: number;
    isLoading: boolean;
    userActionAudits: AusComplyDtos.UserActionAudit[];
    paging: AusComplyDtos.Paging;
    onRefresh: Function;
}

interface IUserActionAuditsState {
    userActionAudit: AusComplyDtos.UserActionAudit
}

class UserActionAudits extends Component<IUserActionAuditsProps, IUserActionAuditsState> {
    constructor(props: IUserActionAuditsProps) {
        super(props)

        let userActionAudit = new AusComplyDtos.UserActionAudit();
        userActionAudit.userActionAuditId = 0;
        this.state = {
            userActionAudit
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.userId, this.props.paging.page);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.userId, page, this.props.paging.pageSize);
    }

    onClick(userActionAudit: AusComplyDtos.UserActionAudit) {
        this.setState({ userActionAudit });
    }

    onHide() {
        let userActionAudit = new AusComplyDtos.UserActionAudit();
        userActionAudit.userActionAuditId = 0;
        this.setState({ userActionAudit });
    }

    render() {
        let cardList;
        let rowList;
        let rows;
        let show: boolean = this.state.userActionAudit.userActionAuditId != 0;
        let userActionAudit;
        if (show) {
            userActionAudit = (
                <UserActionAudit userActionAudit={this.state.userActionAudit} />
            );
        }

        if (this.props.userActionAudits && this.props.userActionAudits.length > 0) {
            cardList = this.props.userActionAudits.map((item, index) => {
                return (
                    <UserActionAuditsCard
                        onClick={this.onClick}
                        key={"card-audit-" + item.userActionAuditId.toString()}
                        userActionAudit={item}
                    />
                );
            });
            
            rows = this.props.userActionAudits.map((item, index) => {
                return (
                    <UserActionAuditsRow
                        onClick={this.onClick}
                        key={"row-audit-" + item.userActionAuditId.toString()}
                        userActionAudit={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<UserActionAuditsHeader />}
                rows={rows}
            />
        );

        return (
            <>
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <FullPageDialog open={show} title="User Action" onDismissed={() => this.onHide()}>
                    {userActionAudit}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserActionAudits);