import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Palette } from '../../common/constants/palette';


export interface ICardDividerProps {
    theme: any;
    text?: string;
}

class CardDivider extends React.PureComponent<ICardDividerProps, any> {
    render() {
        return (
            <hr style={{ borderColor: Palette.ControlBorderDisabled, borderStyle: 'solid' }} />
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardDivider);