import update from "immutability-helper";
import {
    SYSTEM_LOG_REQUEST,
    SYSTEM_LOG_REQUEST_SUCCESS,
    SYSTEM_LOG_REQUEST_FAILURE
} from "../actions/systemLog";

const initialState = {
    isLoading: false,
    notifications: [],
    error: null
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setNotifications(state, notifications) {
    let newState = update(state, {
        notifications: { $set: notifications }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

export default function systemLog(state = initialState, action) {
    switch (action.type) {
        case SYSTEM_LOG_REQUEST:
            return loading(setNotifications(setError(state, null), []), true);
        case SYSTEM_LOG_REQUEST_SUCCESS:
            return loading(setError(setNotifications(state, action.notifications), ""), false);
        case SYSTEM_LOG_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
    }
    return state;
}