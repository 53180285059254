
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userServiceApi } from '../services/userService';

import {
    REGISTER_USER_NEW_REQUEST,
    REGISTER_USER_SAVE_REQUEST_SUCCESS,
    registerUserNewSuccess,
    registerUserNewFailure,
    REGISTER_USER_SLED_CHECK_REQUEST,
    registerUserSledCheckRequestFailure,
    registerUserSledCheckRequestSuccess,
    REGISTER_USER_SAVE_REQUEST,
    registerUserSaveRequestSuccess,
    registerUserSaveRequestFailure
} from "../actions/registerUser";

import {
    profileReset
} from "../actions/profile";

import { notificationShow } from "../actions/notification";

const registerUserNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_USER_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.newUser()
                .pipe(
                    map(response => registerUserNewSuccess(response.user, response.venues, response.securityFirms, response.states, response.genders, response.userOtherDocuments, response.userOtherDocumentTypes, response.userComplianceDocuments, response.userComplianceDocumentTypes, response.avatar)),
                    catchError(error => of(
                        registerUserNewFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to initialise a new user")
                    ))
                )
        ),
        catchError(error => of(
            registerUserNewFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to initialise a new user")
        ))
    );

const registerUserSledCheckEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_USER_SLED_CHECK_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.sledCheck(action['user'])
                .pipe(
                    map(response => registerUserSledCheckRequestSuccess(response.user)),
                    catchError(error => of(
                        registerUserSledCheckRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to check license number")
                    ))
                )
        ),
        catchError(error => of(
            registerUserSledCheckRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to check license number")
        ))
    );
    
const registerUserSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_USER_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveNewUser(state.registerUser.user, state.registerUser.registerAs, state.registerUser.venueIds, state.registerUser.securityFirmIds, state.registerUser.otherDocumnets, state.registerUser.complianceDocuments, state.registerUser.avatar)
                .pipe(
                    map(response => registerUserSaveRequestSuccess(response.user)),
                    catchError(error => of(
                        registerUserSaveRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save user")
                    ))
                )
        ),
        catchError(error => of(
            registerUserSaveRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save user")
        ))
    );


const registerUserSaveNewSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_USER_SAVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
                if (state.user.details.userSession.user.userID > 0) {
                    return false;
                }
            }
            return true;
        }),
        map(action => profileReset())
    );

export const registerUserEpics = combineEpics(
    registerUserNewEpic,
    registerUserSledCheckEpic,
    registerUserSaveNewEpic,
    registerUserSaveNewSuccessEpic
);