import { connect } from 'react-redux'
import IncidentActionTemplate from '../components/incidentActions/incidentActionTemplate/IncidentActionTemplate'
import { withRouter } from "react-router";
import { notificationShow } from '../common/actions/notification';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { loadAllReferenceData } from '../common/actions/referenceData';
import { printShow } from '../common/actions/print';
import {
    incidentActionTemplateRequest,
    incidentActionTemplateCreateRequest,
    incidentActionTemplateSet,
    incidentActionTemplateUpsertRequest,
    incidentActionTemplatesReset,
    incidentActionTemplateAddChecklistTemplateItem,
    incidentActionMoveChecklistTemplateItem,
    incidentActionRemoveChecklistTemplateItem,
    incidentActionSetChecklistTemplateItem
} from '../common/actions/incidentActionTemplates';

import {
    groupRequest
} from '../common/actions/groups';

const mapStateToProps = (state, props) => {
    return {
        venueId: undefined,
        groupId: props.match.params.groupId,
        incidentActionTemplateId: props.match.params.incidentActionTemplateId,
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        groupedChecklistTemplateItems: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate.groupedChecklistTemplateItems,
        checklistTemplateItemsVersion: state.incidentActionTemplates.incidentActionTemplate.checklistTemplateItemsVersion,
        isLoading: state.incidentActionTemplates.incidentActionTemplate.isLoading,
        isSaving: state.incidentActionTemplates.incidentActionTemplate.isSaving,
        incidentCategoryTypes: state.incidentActionTemplates.incidentActionTemplate.incidentCategoryTypes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (checklistTemplateId) => {
            dispatch(incidentActionTemplateRequest(checklistTemplateId))
        },
        onCreate: (venueId, groupId) => {
            dispatch(incidentActionTemplateCreateRequest(venueId, groupId))
        },
        onUpdate: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateSet(incidentActionTemplate));
        },
        onSave: () => {
           dispatch(incidentActionTemplateUpsertRequest());
        },
        onReset: () => {
            dispatch(incidentActionTemplatesReset());
        },
        onUpdateItem: (checklistTemplateItem) => {
            dispatch(incidentActionSetChecklistTemplateItem(checklistTemplateItem));
        },
        onAddItem: (checklistItemType, parentId, position) => {
            dispatch(incidentActionTemplateAddChecklistTemplateItem(checklistItemType, parentId, position))
        },
        onMoveItem: (checklistTemplateItemId, parentId, position) => {
            dispatch(incidentActionMoveChecklistTemplateItem(checklistTemplateItemId, parentId, position))
        },
        onRemoveItem: (checklistTemplateItem) => {
            dispatch(incidentActionRemoveChecklistTemplateItem(checklistTemplateItem))
        },
        onReloadGroup: (groupId) => {
            dispatch(groupRequest(groupId))
        }
    }
}

const AdminGroupIncidentActionTemplateContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentActionTemplate))

export default AdminGroupIncidentActionTemplateContainer