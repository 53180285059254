import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';
import { makePost, makeGet, makeDelete } from './common';

export const userServiceApi = {
  getCurrentUser: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUser();
    query.id = 0; // current user
    query.includeReferences = true;
    const request = client.get(query);
    return from(request);
  },
  getUser: (userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUser();
    query.id = userId; 
    query.includeReferences = true;
    const request = client.get(query);
    return from(request);
  },
  getUserView: (venueId: number, securityFirmId: number, userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUserView();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.id = userId; 
    const request = client.get(query);
    return from(request);
  },
  getUserDetails: (venueId: number, securityFirmId: number, userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUser();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.id = userId; 
    query.includeReferences = true;
    query.showObsoleteUserRoles = true;
    const request = client.get(query);
    return from(request);
  },
  getUserWithObsoleteUserRoles: (userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUser();
    query.id = userId; 
    query.includeReferences = true;
    query.showObsoleteUserRoles = true;
    const request = client.get(query);
    return from(request);
  },
  getUserWithObsoleteUserRolesAndDocuments: (userId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUser();
    query.id = userId; 
    query.includeReferences = true;
    query.showObsoleteUserRoles = true;
    query.showObsoleteDocuments = true;
    const request = client.get(query);
    return from(request);
  },
  newUser: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetNewUser();
    const request = client.get(query);
    return from(request);
  },
  saveNewUser: (
    user: AusComplyDtos.User,
    registerAs: AusComplyDtos.ngtUserRegistrationType,
    venueIds: number[],
    securityFirmIds: number[],
    otherDocuments: AusComplyDtos.File[],
    complianceDocuments: AusComplyDtos.File[],
    avatar: AusComplyDtos.File) => {

    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostNewUser();
    query.user = user;
    query.registerAs = registerAs;
    query.venueIds = venueIds;
    query.securityFirmIds = securityFirmIds;
    query.userOtherDocuments = otherDocuments;
    query.userComplianceDocuments = complianceDocuments;
    query.avatar = avatar;
    const request = client.post(query);
    return from(request);
  },
  resendVerificationEmail: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserResendVerificationEmail();
    const request = client.post(query);
    return from(request);
  },
  sendForgotPassword: (username) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserForgotPassword();
    query.username = username;
    const request = client.post(query);
    return from(request);
  },
  requestForgotPasswordCode: (mobile:string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRequestForgotPasswordCode();
    query.mobile = mobile;
    const request = client.post(query);
    return from(request);
  },
  validateForgotPasswordCode: (mobile:string, code: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostValidateForgotPasswordCode();
    query.mobile = mobile;
    query.code = code;
    const request = client.post(query);
    return from(request);
  },
  resetPasswordFromCode: (mobile:string, code: number, newPassword:string, newPasswordConfirm:string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserResetPasswordWithCode();
    query.mobile = mobile;
    query.code = code;
    query.newPassword = newPassword;
    query.newPasswordConfirm = newPasswordConfirm;
    const request = client.post(query);
    return from(request);
  },
  forgotPasswordValidate: (userId, userForgotPasswordId, forgotPasswordGuid) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserForgotPasswordValidate();
    query.userId = userId;
    query.userForgotPasswordId = userForgotPasswordId;
    query.forgotPasswordGuid = forgotPasswordGuid;
    const request = client.post(query);
    return from(request);
  },
  resetPassword: (userId, userForgotPasswordId, forgotPasswordGuid, newPassword, newPasswordConfirm) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserResetPassword();
    query.userId = userId;
    query.userForgotPasswordId = userForgotPasswordId;
    query.forgotPasswordGuid = forgotPasswordGuid;
    query.newPassword = newPassword;
    query.newPasswordConfirm = newPasswordConfirm;
    const request = client.post(query);
    return from(request);
  },
  emailConfirmation: (userId, emailConfirmationGuid, password) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserEmailVerification();
    query.userID = userId;
    query.guid = emailConfirmationGuid;
    query.password = password;
    const request = client.post(query);
    return from(request);
  },
  updateUser: (user: AusComplyDtos.User) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUpdateUser();
    query.id = user.userId;
    query.user = user;
    const request = client.post(query);
    return from(request);
  },
  updateAvatar: (userId: number, file: AusComplyDtos.File) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUpdateUserAvatar();
    query.userId = userId;
    query.file = file;
    const request = client.post(query);
    return from(request);
  },
  sledCheck: (user: AusComplyDtos.User) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserSledCheck();
    query.user = user;
    const request = client.post(query);
    return from(request);
  },
  saveUserOtherDocuments: (userId: number, documentTypeId: number, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserOtherDocument();
    query.userId = userId; 
    query.documentTypeId = documentTypeId;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeUserOtherDocuments: (userId: number, document: AusComplyDtos.UserOtherDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserOtherDocumentRemove();
    query.userId = userId; 
    query.userDocumentId = document.userDocumentId;
    query.userOtherDocumentId = document.userOtherDocumentId;
    const request = client.post(query);
    return from(request);
  },
  saveUserComplianceDocuments: (userId: number, documentTypeId: number, expiryDate: AusComplyDtos.SimpleDate, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocument();
    query.userId = userId; 
    query.documentTypeId = documentTypeId;
    query.expiryDate = expiryDate;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeUserComplianceDocuments: (userId: number, document: AusComplyDtos.UserComplianceDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocumentRemove();
    query.userId = userId; 
    query.userDocumentId = document.userDocumentId;
    query.userComplianceDocumentId = document.userComplianceDocumentId;
    const request = client.post(query);
    return from(request);
  },
  getDuplicateUserMobiles: (page, pageSize, search) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetUsersWithDuplicateMobiles();
    query.pageSize = pageSize;
    query.page = page;
    query.search = search;
    const request = client.get(query);
    return from(request);
  },
  updateDuplicateUserMobile: (userId, mobileNo) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserResetMobile();
    query.userId = userId;
    query.mobileNo = mobileNo;
    const request = client.post(query);
    return from(request);
  },
  changePassword: (userId, oldPassword, newPassword, newPasswordConfirm) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserChangePassword();
    query.userId = userId;
    query.oldPassword = oldPassword;
    query.newPassword = newPassword;
    query.newPasswordConfirm = newPasswordConfirm;
    const request = client.post(query);
    return from(request);
  },
  getLoggedInUsers: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetLoggedInUsers();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  getAdminUserRoles: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.UserRoleFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminUserRoles();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  getAdminUsers: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.UserFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminUsers();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  export: (venueId: number, securityFirmId: number, filter: AusComplyDtos.UserRoleFilter) => {
    var client = new AusComplyJsonServiceClient();
    var parameters = new AusComplyDtos.AnyUserRolesExport();
    parameters.venueId = venueId;
    parameters.securityFirmId = securityFirmId;
    parameters.filter = filter;
    const request = client.post(parameters);
    return from(request);
  },
  saveForUserOtherDocuments: (venueId: number, securityFirmId: number, userId: number, documentTypeId: number, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserOtherDocument();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId; 
    query.documentTypeId = documentTypeId;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeForUserOtherDocuments: (venueId: number, securityFirmId: number, userId: number, document: AusComplyDtos.UserOtherDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserOtherDocumentRemove();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId; 
    query.userDocumentId = document.userDocumentId;
    query.userOtherDocumentId = document.userOtherDocumentId;
    const request = client.post(query);
    return from(request);
  },
  saveForUserComplianceDocuments: (venueId: number, securityFirmId: number, userId: number, documentTypeId: number, expiryDate: AusComplyDtos.SimpleDate, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocument();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId; 
    query.documentTypeId = documentTypeId;
    query.expiryDate = expiryDate;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeForUserComplianceDocuments: (venueId: number, securityFirmId: number, userId: number, document: AusComplyDtos.UserComplianceDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostUserComplianceDocumentRemove();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId; 
    query.userDocumentId = document.userDocumentId;
    query.userComplianceDocumentId = document.userComplianceDocumentId;
    const request = client.post(query);
    return from(request);
  },
  pushTest: request.makePost(
    AusComplyDtos.PostUserPushTest,
    'venueId', 'securityFirmId', 'userId', 'incidentId'
  ),
  delete: request.makePost(
    AusComplyDtos.PostUserDelete,
    'reconfirmedEmail'
  ),
};
