import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SecurityFirmEdit from '../components/admin/securityFirm/SecurityFirmEdit'
import {
    securityFirmRequest,
    securityFirmUpdate,
    securityFirmSaveRequest
} from '../common/actions/securityFirm'

const mapStateToProps = (state, props) => {
    return {
        securityFirmId: props.securityFirmId,
        isLoading: state.securityFirm.isLoading,
        isSaving: state.securityFirm.isSaving,
        securityFirm: state.securityFirm.securityFirm,
        states: state.securityFirm.states,
        securityLicenceClasses: state.securityFirm.securityLicenceClasses,
        error: state.securityFirm.error,
        errors: state.securityFirm.errors,
        canEditMasterLicence: permissionsLogic.hasPermissionForState(state, "EditSecurityFirmMasterLicence")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (securityFirmId) => {
            dispatch(securityFirmRequest(securityFirmId, true))
        },
        onUpdate: (securityFirm) => {
            dispatch(securityFirmUpdate(securityFirm))
        },
        onSave: () => {
            dispatch(securityFirmSaveRequest())
        }
    }
}

const AdminSecurityFirmEditContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmEdit))

export default AdminSecurityFirmEditContainer