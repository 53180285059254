import { makeActionCreator } from './helpers';

export const
NOTIFICATION_TRIGGER_REQUEST = "NOTIFICATION_TRIGGER_REQUEST",
NOTIFICATION_TRIGGER_REQUEST_SUCCESS = "NOTIFICATION_TRIGGER_REQUEST_SUCCESS",
NOTIFICATION_TRIGGER_REQUEST_FAILURE = "NOTIFICATION_TRIGGER_REQUEST_FAILURE",
NOTIFICATION_TRIGGER_SET = "NOTIFICATION_TRIGGER_SET",
NOTIFICATION_TRIGGER_UPSERT_REQUEST = "NOTIFICATION_TRIGGER_UPSERT_REQUEST",
NOTIFICATION_TRIGGER_UPSERT_REQUEST_SUCCESS = "NOTIFICATION_TRIGGER_UPSERT_REQUEST_SUCCESS",
NOTIFICATION_TRIGGER_UPSERT_REQUEST_FAILURE = "NOTIFICATION_TRIGGER_UPSERT_REQUEST_FAILURE",
NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST = "NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST",
NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_SUCCESS = "NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_SUCCESS",
NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_FAILURE = "NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_FAILURE";


export const notificationTriggerRequest = makeActionCreator(NOTIFICATION_TRIGGER_REQUEST, 'notificationTriggerParentType', 'parentId', 'forGroupId', 'forVenueId', 'forSecurityFirmId');
export const notificationTriggerRequestSuccess = makeActionCreator(NOTIFICATION_TRIGGER_REQUEST_SUCCESS, 'notificationTrigger');
export const notificationTriggerRequestFailure = makeActionCreator(NOTIFICATION_TRIGGER_REQUEST_FAILURE, 'error');

export const notificationTriggerSet = makeActionCreator(NOTIFICATION_TRIGGER_SET, 'notificationTrigger');

export const notificationTriggerUpsertRequest = makeActionCreator(NOTIFICATION_TRIGGER_UPSERT_REQUEST, 'notificationTrigger');
export const notificationTriggerUpsertRequestSuccess = makeActionCreator(NOTIFICATION_TRIGGER_UPSERT_REQUEST_SUCCESS, 'notificationTrigger');
export const notificationTriggerUpsertRequestFailure = makeActionCreator(NOTIFICATION_TRIGGER_UPSERT_REQUEST_FAILURE, 'error');

export const notificationTriggerUsersSummaryRequest = makeActionCreator(NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST, 'notificationTriggerId');
export const notificationTriggerUsersSummaryRequestSuccess = makeActionCreator(NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_SUCCESS, 'notificationTriggerUsersSummary');
export const notificationTriggerUsersSummaryRequestFailure = makeActionCreator(NOTIFICATION_TRIGGER_USERS_SUMMARY_REQUEST_FAILURE, 'error');
