import { connect } from 'react-redux'
import { withRouter } from "react-router";
import Cell from '../components/facility/cell/Cell'
import { 
    venueLocationReset,
    venueLocationSet,
    venueLocationUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.venueLocation.isLoading,
        isSaving: state.venue.venueLocation.isSaving,
        venueLocation: state.venue.venueLocation.venueLocation
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueLocationReset())
        },
        onUpdate: (venueLocation) => {
            dispatch(venueLocationSet(venueLocation))
        },
        onSave: () => {
            dispatch(venueLocationUpsertRequest())
        }
    }
}

const FacilityCellContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Cell))

export default FacilityCellContainer