import { connect } from 'react-redux'
import UserRoles from '../components/admin/userRoles/UserRoles'
import { withRouter } from "react-router";
import { 
    adminUserRolesRequest, 
    adminUserRolesFilterSet
 } from '../common/actions/admin';
 import { 
    userRoleEnableRequest
 } from '../common/actions/userRole';
import { loadUserRoleTypesRequest } from '../common/actions/referenceData';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.userRoles.isLoading,
        userRoles: state.admin.userRoles.userRoles,
        userRoleTypes: state.referenceData.userRoleTypes,
        paging: state.admin.userRoles.paging,
        filter: state.admin.userRoles.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh:(filter, page, pageSize) => {
            dispatch(adminUserRolesRequest(filter, page, pageSize))
        },
        onFilterUpdate:(filter) => {
            dispatch(adminUserRolesFilterSet(filter))
        },
        onUserRoleEnableRequest: (userRoleId) => {
            dispatch(userRoleEnableRequest(userRoleId))
        },
        onLoadReferenceData:(forVenueId?: number, forSecurityFirmId?: number) => {
            dispatch(loadUserRoleTypesRequest(forVenueId, forSecurityFirmId));
        }
    }
}

const AdminUserRolesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRoles))

export default AdminUserRolesContainer