import React, { Component } from 'react';
import Loading from '../../common/Loading';
import Error from '../../common/Error'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { AdminDashboardOptions } from '../../../common/dto/common';
import VenueSecurityCodeContainer from '../../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../../containers/SignOnOffSummaryContainer';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SecondaryFab from '../../common/SecondaryFab';
import PageTitle from '../../common/PageTitle';
import SubTitle from '../../common/SubTitle';
import FullPageDialog from '../../layout/FullPageDialog';
import Information from '../../common/Information';
import WelcomeContainer from '../../../containers/WelcomeContainer';
import TransparentButton from '../../controls/TransparentButton';
import HomeStatistics from '../../home/HomeStatistics';
import Map from './Map';
import View from '../../common/View';
import Tabs from '../../common/Tabs';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import { Chart } from "react-google-charts";
import NoRowsMessage from '../../common/NoRowsMessage';

export interface IDashboardTotalsProps {
    isLoading: boolean;
    venues: AusComplyDtos.RangeChartTotal[];
    securityFirms: AusComplyDtos.RangeChartTotal[];
    incidents: AusComplyDtos.RangeChartTotal[];
    users: AusComplyDtos.RangeChartTotal[];
    history: any;
    location: any;
    theme: any;
    classes: any;
}

class DashboardTotals extends Component<IDashboardTotalsProps, any> {
    constructor(props: IDashboardTotalsProps) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    loadData() {
        // process the data
        if (this.props.incidents) {
            //venueMarkers = 
        }
    }

    getMaxValue(dataValues) {
        var _iMaxValue = -1;

        for (var i = 0; i < dataValues.length; i++) {
            if (dataValues[i].Total > _iMaxValue) {
                _iMaxValue = dataValues[i].Total;
            }
        }

        return _iMaxValue;
    }

    rangeColumnChartOptions(title: string, maxValue: number) {
        return {
            //title: title,
            backgroundColor: '#2E2C2D',
            fontSize: '14',
            chartArea: {
                backgroundColor: '#000000',
                height: '80%',
                width: '90%'
            },
            colors: ['#818183'],
            height: 400,
            legend: {
                position: 'none',
                textStyle: {
                    color: '#ffffff'
                }
            },
            sliceVisibilityThreshold: 0,
            titleTextStyle: {
                color: '#ffffff'
            },
            hAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                },
                slantedText: false
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                },
                viewWindow: {
                    'min': 0,
                    'max': maxValue
                }
            },
        };
    }

    securityFirmChart() {

        let chart;
        if (!this.props.isLoading && this.props.securityFirms.length > 1) {
            let data: any[] = [
                ['Date', 'Value']
            ];
            let maxValue: number = 100;
            this.props.securityFirms.forEach(v => {
                data.push([new Date(v.dateEntered), v.total]);
                if (v.total > maxValue) {
                    maxValue = v.total;
                }
            });

            let startDate = new Date(2000, 0, 1);
            let endDate = new Date(2000, 0, 2);
            let rangeInDays = -1;

            if (data != null &&
                data.length > 0) {
                endDate = new Date(data[data.length - 1][0]);

                if (typeof endDate !== "undefined" && endDate != null) {
                    if (rangeInDays > 0) {
                        startDate = new Date();
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * rangeInDays));
                    } else {
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * 365));
                    }
                }
            }
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px 10px 60px 10px' }}>
                    <SubTitle text={"Total Security Firms"} />
                    <div className={"gchRangeStatsSecurityFirm"}>
                        <Chart
                            options={this.rangeColumnChartOptions("Total Security Firms", maxValue)}
                            chartType="ColumnChart"
                            data={data}
                            width="100%"
                            legendToggle
                            formatters={
                                [
                                    {
                                        type: 'DateFormat',
                                        column: 1,
                                        options: {
                                            formatType: 'short',
                                        },
                                    }
                                ]
                            }
                            controls={[
                                {
                                    controlType: 'ChartRangeFilter',
                                    options: {
                                        filterColumnIndex: 0,
                                        ui: {
                                            chartType: 'LineChart',
                                            chartOptions: {
                                                backgroundColor: '#2E2C2D',
                                                chartArea: {
                                                    backgroundColor: '#000000',
                                                    width: '90%'
                                                },
                                                colors: ['#818183'],
                                                hAxis: {
                                                    baselineColor: 'none',
                                                    gridlines: {
                                                        color: '#fefefe'
                                                    },
                                                    textStyle: {
                                                        color: '#ffffff'
                                                    },
                                                    titleTextStyle: {
                                                        color: '#ffffff'
                                                    }
                                                },
                                                height: '50'
                                            },
                                            chartView: {
                                                columns: [0, 1]
                                            },
                                            minRangeSize: (86400000 * 28)
                                        },
                                    },
                                    controlPosition: 'bottom',
                                    controlWrapperParams: {
                                        state: {
                                            range: { start: startDate, end: endDate },
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </View>
            );
        }

        return chart;
    }

    incidentChart() {

        let chart;
        if (!this.props.isLoading && this.props.incidents.length > 1) {
            let data: any[] = [
                ['Date', 'Value']
            ];
            let maxValue: number = 100;
            this.props.incidents.forEach(v => {
                data.push([new Date(v.dateEntered), v.total]);
                if (v.total > maxValue) {
                    maxValue = v.total;
                }
            });

            let startDate = new Date(2000, 0, 1);
            let endDate = new Date(2000, 0, 2);
            let rangeInDays = -1;

            if (data != null &&
                data.length > 0) {
                endDate = new Date(data[data.length - 1][0]);

                if (typeof endDate !== "undefined" && endDate != null) {
                    if (rangeInDays > 0) {
                        startDate = new Date();
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * rangeInDays));
                    } else {
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * 365));
                    }
                }
            }
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px 10px 60px 10px' }}>
                    <SubTitle text={"Total Incidents"} />
                    <div className={"gchRangeStatsIncidents"}>
                        <Chart
                            options={this.rangeColumnChartOptions("Total Incidents", maxValue)}
                            chartType="ColumnChart"
                            data={data}
                            width="100%"
                            legendToggle
                            formatters={
                                [
                                    {
                                        type: 'DateFormat',
                                        column: 1,
                                        options: {
                                            formatType: 'short',
                                        },
                                    }
                                ]
                            }
                            controls={[
                                {
                                    controlType: 'ChartRangeFilter',
                                    options: {
                                        filterColumnIndex: 0,
                                        ui: {
                                            chartType: 'LineChart',
                                            chartOptions: {
                                                backgroundColor: '#2E2C2D',
                                                chartArea: {
                                                    backgroundColor: '#000000',
                                                    width: '90%'
                                                },
                                                colors: ['#818183'],
                                                hAxis: {
                                                    baselineColor: 'none',
                                                    gridlines: {
                                                        color: '#fefefe'
                                                    },
                                                    textStyle: {
                                                        color: '#ffffff'
                                                    },
                                                    titleTextStyle: {
                                                        color: '#ffffff'
                                                    }
                                                },
                                                height: '50'
                                            },
                                            chartView: {
                                                columns: [0, 1]
                                            },
                                            minRangeSize: (86400000 * 28)
                                        },
                                    },
                                    controlPosition: 'bottom',
                                    controlWrapperParams: {
                                        state: {
                                            range: { start: startDate, end: endDate },
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </View>
            );
        }

        return chart;
    }

    usersChart() {

        let chart;
        if (!this.props.isLoading && this.props.users.length > 1) {
            let data: any[] = [
                ['Date', 'Value']
            ];
            let maxValue: number = 100;
            this.props.users.forEach(v => {
                data.push([new Date(v.dateEntered), v.total]);
                if (v.total > maxValue) {
                    maxValue = v.total;
                }
            });

            let startDate = new Date(2000, 0, 1);
            let endDate = new Date(2000, 0, 2);
            let rangeInDays = -1;

            if (data != null &&
                data.length > 0) {
                endDate = new Date(data[data.length - 1][0]);

                if (typeof endDate !== "undefined" && endDate != null) {
                    if (rangeInDays > 0) {
                        startDate = new Date();
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * rangeInDays));
                    } else {
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * 365));
                    }
                }
            }
            chart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px 10px 60px 10px' }}>
                    <SubTitle text={"Total Users"} />
                    <div className={"gchRangeStatsUsers"}>
                        <Chart
                            options={this.rangeColumnChartOptions("Total Users", maxValue)}
                            chartType="ColumnChart"
                            data={data}
                            width="100%"
                            legendToggle
                            formatters={
                                [
                                    {
                                        type: 'DateFormat',
                                        column: 1,
                                        options: {
                                            formatType: 'short',
                                        },
                                    }
                                ]
                            }
                            controls={[
                                {
                                    controlType: 'ChartRangeFilter',
                                    options: {
                                        filterColumnIndex: 0,
                                        ui: {
                                            chartType: 'LineChart',
                                            chartOptions: {
                                                backgroundColor: '#2E2C2D',
                                                chartArea: {
                                                    backgroundColor: '#000000',
                                                    width: '90%'
                                                },
                                                colors: ['#818183'],
                                                hAxis: {
                                                    baselineColor: 'none',
                                                    gridlines: {
                                                        color: '#fefefe'
                                                    },
                                                    textStyle: {
                                                        color: '#ffffff'
                                                    },
                                                    titleTextStyle: {
                                                        color: '#ffffff'
                                                    }
                                                },
                                                height: '50'
                                            },
                                            chartView: {
                                                columns: [0, 1]
                                            },
                                            minRangeSize: (86400000 * 28)
                                        },
                                    },
                                    controlPosition: 'bottom',
                                    controlWrapperParams: {
                                        state: {
                                            range: { start: startDate, end: endDate },
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </View>
            );
        }

        return chart;
    }

    render() {

        if (this.props.isLoading) {
            return (<Loading />);
        }

        let venueChart;
        if (!this.props.isLoading && this.props.venues.length > 1) {
            let data: any[] = [
                ['Date', 'Value']
            ];
            let maxValue: number = 100;
            this.props.venues.forEach(v => {
                data.push([new Date(v.dateEntered), v.total]);
                if (v.total > maxValue) {
                    maxValue = v.total;
                }
            });
            let data2 = [
                ['Date', 'Value'],
                [new Date(1996, 1, 1), 2000 * Math.random()],
                [new Date(1997, 1, 1), 2000 * Math.random()],
                [new Date(1998, 1, 1), 2000 * Math.random()],
                [new Date(1999, 1, 1), 2000 * Math.random()],
                [new Date(2000, 1, 1), 2000 * Math.random()],
                [new Date(2001, 1, 1), 2000 * Math.random()],
                [new Date(2002, 1, 1), 2000 * Math.random()],
                [new Date(2003, 1, 1), 2000 * Math.random()],
                [new Date(2004, 1, 1), 2000 * Math.random()],
                [new Date(2005, 1, 1), 2000 * Math.random()],
                [new Date(2006, 1, 1), 2000 * Math.random()],
                [new Date(2007, 1, 1), 2000 * Math.random()],
                [new Date(2008, 1, 1), 2000 * Math.random()],
                [new Date(2009, 1, 1), 2000 * Math.random()],
            ];

            let startDate = new Date(2000, 0, 1);
            let endDate = new Date(2000, 0, 2);
            let rangeInDays = -1;

            if (data != null &&
                data.length > 0) {
                endDate = new Date(data[data.length - 1][0]);

                if (typeof endDate !== "undefined" && endDate != null) {
                    if (rangeInDays > 0) {
                        startDate = new Date();
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * rangeInDays));
                    } else {
                        startDate.setTime(endDate.getTime() - ((24 * 60 * 60 * 1000) * 365));
                    }
                }
            }
            venueChart = (
                <View style={{ backgroundColor: '#2E2C2D', padding: '10px 10px 60px 10px' }}>
                    <SubTitle text={"Total Venues"} />
                    <div className={"gchRangeStatsVenue"}>
                        <Chart
                            options={this.rangeColumnChartOptions("Total Venues", maxValue)}
                            chartType="ColumnChart"
                            data={data}
                            width="100%"
                            legendToggle
                            formatters={
                                [
                                    {
                                        type: 'DateFormat',
                                        column: 1,
                                        options: {
                                            formatType: 'short',
                                        },
                                    }
                                ]
                            }
                            controls={[
                                {
                                    controlType: 'ChartRangeFilter',
                                    options: {
                                        filterColumnIndex: 0,
                                        ui: {
                                            chartType: 'LineChart',
                                            chartOptions: {
                                                backgroundColor: '#2E2C2D',
                                                chartArea: {
                                                    backgroundColor: '#000000',
                                                    width: '90%'
                                                },
                                                colors: ['#818183'],
                                                hAxis: {
                                                    baselineColor: 'none',
                                                    gridlines: {
                                                        color: '#fefefe'
                                                    },
                                                    textStyle: {
                                                        color: '#ffffff'
                                                    },
                                                    titleTextStyle: {
                                                        color: '#ffffff'
                                                    }
                                                },
                                                height: '50'
                                            },
                                            chartView: {
                                                columns: [0, 1]
                                            },
                                            minRangeSize: (86400000 * 28)
                                        },
                                    },
                                    controlPosition: 'bottom',
                                    controlWrapperParams: {
                                        state: {
                                            range: { start: startDate, end: endDate },
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </View>
            );
        }

        return (
            <>
                {venueChart}
                {this.securityFirmChart()}
                {this.incidentChart()}
                {this.usersChart()}
            </>
        );
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(DashboardTotals))