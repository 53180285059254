import { connect } from 'react-redux'
import RemoveUserRole from '../components/userRoles/removeUserRole/RemoveUserRole'
import { userRoleLoadRequest, userRoleRemoveRequest  } from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        userRoleId: props.userRoleId,
        isLoading: state.userRole.isLoading,
        userRole: state.userRole.userRole,
        isSaving: state.userRole.isSaving,
        error: state.userRole.error,
        onSuccess: props.onSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (userRoleId) => {
            dispatch(userRoleLoadRequest(userRoleId))
        },
        onRemove: (userRoleId, adminNotes) => {
            dispatch(userRoleRemoveRequest(userRoleId, adminNotes))
        }
    }
}

const RemoveUserRoleContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveUserRole)

export default RemoveUserRoleContainer