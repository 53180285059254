import { connect } from 'react-redux'
import Incidents from '../components/incidents/Incidents'
import { withRouter } from "react-router";
import { incidentsFilterRequest, incidentsRequest, incidentsSelectForApproval, incidentsSelectAllForApproval, incidentsApproveSelectedRequest} from '../common/actions/incidents'
import { editIncidentClear } from '../common/actions/editIncident'
import { printShow } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = state => {
    return {
        isLoading: state.incidents.isLoading,
        isApproving: state.incidents.isApproving,
        items: state.incidents.items,
        paging: state.incidents.paging,
        filter: state.incidents.filter,
        initialFilterLoaded: state.incidents.initialFilterLoaded,
        showEvents: permissionsLogic.hasPermissionForState(state, "ViewEvent")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onResetFilter:(forEntryPage) => {
            dispatch(incidentsFilterRequest(true, forEntryPage))
        },
        onRefresh:(incidentFilter) => {
            dispatch(incidentsRequest(incidentFilter))
        },
        onIncidentsSelectForApproval:(incidentId, isSelected) => {
            dispatch(incidentsSelectForApproval(incidentId, isSelected))
        },
        onIncidentsSelectAllForApproval:(isSelected) => {
            dispatch(incidentsSelectAllForApproval(isSelected))
        },
        onIncidentsApproveSelectedRequest:() => {
            dispatch(incidentsApproveSelectedRequest())
        },
        onIncidentClear:() => {
            dispatch(editIncidentClear())
        },
        onPrint: () => {
            dispatch(printShow())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message))
        }
    }
}

const IncidentsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Incidents))

export default IncidentsContainer