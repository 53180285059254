import { connect } from 'react-redux'
import { withRouter } from "react-router";
import BroadcastNotification from '../components/broadcastNotifications/broadcastNotification/BroadcastNotification'
import { 
    broadcastNotificationReset,
    broadcastNotificationSet,
    broadcastNotificationUpsertRequest
 } from '../common/actions/broadcastNotification';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.broadcastNotification.broadcastNotification.isLoading,
        isSaving: state.broadcastNotification.broadcastNotification.isSaving,
        broadcastNotification: state.broadcastNotification.broadcastNotification.broadcastNotification,
        error: state.broadcastNotification.broadcastNotification.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(broadcastNotificationReset())
        },
        onUpdate: (broadcastNotification) => {
            dispatch(broadcastNotificationSet(broadcastNotification))
        },
        onSave: () => {
            dispatch(broadcastNotificationUpsertRequest())
        }
    }
}

const BroadcastNotificationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BroadcastNotification))

export default BroadcastNotificationContainer