import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { RadioOption, MulitSelectOption, SingleSelectList } from '../../../../common/dto/common';
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../../common/SubTitle';
import Title from '../../../common/Title';
import Paragraph from '../../../common/Paragraph';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import RadioControlGroup from '../../../controls/RadioControlGroup';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckListItemAttachments from './CheckListItemAttachments';
import CheckListItemAttachment from './CheckListItemAttachment';
import ControlGroup from '../../../controls/ControlGroup';
import TextDisplay from '../../../controls/TextDisplay';
import SingleSelectListControl from '../../../controls/SingleSelectListControl';
import SelectControlGroup from '../../../controls/SelectControlGroup';

export interface ICheckListItemSelectionProps {
    checkListDisplayItem: CheckListDisplayItem;
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemSelectionState {
    items: SingleSelectList[];
}

class CheckListItemSelection extends Component<ICheckListItemSelectionProps, ICheckListItemSelectionState> {
    constructor(props: ICheckListItemSelectionProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
    }

    componentDidMount() {
        this.buildOptions();
    }

    componentDidUpdate(preProps) {
        if (JSON.stringify(this.props.checkListDisplayItem.item) != JSON.stringify(preProps.checkListDisplayItem.item)) {
            this.buildOptions();
        } else if (JSON.stringify(this.props.checkListDisplayItem.template) != JSON.stringify(preProps.checkListDisplayItem.template)) {
            this.buildOptions();
        }
    }

    buildOptions() {
        let items: SingleSelectList[] = [];
        if (this.props.checkListDisplayItem.template.checklistTemplateItemOptions) {
            this.props.checkListDisplayItem.template.checklistTemplateItemOptions.forEach(option => {
                if ((this.props.checkListDisplayItem.item.value && option.value == this.props.checkListDisplayItem.item.value) || !option.obsolete) {
                    items.push({ 
                        id: option.value, 
                        name: option.name, 
                        isCommon: false, 
                        selected: this.props.checkListDisplayItem.item.value === option.value, 
                        info: "", 
                        infoColor: "" });
                }
            });
        }
        this.setState({ items });
    }

    onSelectedItemChanged(item) {
        let checklistItem = { ...this.props.checkListDisplayItem.item };
        if (item) {
            checklistItem.value = item.id;
        } else {
            checklistItem.value = "";
        }
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
    }

    onValueChanged(value) {
        let checklistItem = { ...this.props.checkListDisplayItem.item };
        if (value) {
            checklistItem.value = value;
        } else {
            checklistItem.value = "";
        }
        if (this.props.onUpdate) {
            this.props.onUpdate(checklistItem);
        }
    }

    render() {
        const { theme } = this.props;

        let cardStyle;
        if (this.props.checkListDisplayItem.isRequired) {
            cardStyle = { borderColor: theme.custom.colors.error };
        }

        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplay>{this.props.checkListDisplayItem.item.display}</TextDisplay>
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <>
                            <SelectControlGroup
                                text={this.props.checkListDisplayItem.template.name}
                                onChange={(value) => this.onValueChanged(value)}
                                defaultValue={this.props.checkListDisplayItem.item.value}>
                                <option value={0} />
                                {this.state.items.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            </>
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );

        /*
        return (
            <div style={theme.custom.checklist.container}>
                <CardContainer style={cardStyle}>
                    <CardContent style={{ padding: '10px' }}>
                        {this.props.isReadonly && (
                            <ControlGroup text={this.props.checkListDisplayItem.template.name}>
                                <TextDisplay>{this.props.checkListDisplayItem.item.value}</TextDisplay>
                            </ControlGroup>
                        )}
                        {!this.props.isReadonly && (
                            <>
                            <Title text={this.props.checkListDisplayItem.template.name} />
                            <SingleSelectListControl
                                items={this.state.items}
                                selectedTitle={""}
                                hideSelectedTitle={true}
                                hideEmptyMessage={true}
                                commonTitle=""
                                hideAvailableText={true}
                                //availableTitle="Search venues"
                                //searchText="Type at least three letters..."
                                minimumCharactersRequired={0}
                                onSelectedItemChanged={this.onSelectedItemChanged}
                                autoHideOnSelected={true}
                            ></SingleSelectListControl>
                            <SelectControlGroup
                                text={this.props.checkListDisplayItem.template.name}
                                onChange={(value) => this.onValueChanged(value)}
                                defaultValue={this.props.checkListDisplayItem.item.value}>
                                <option value={0} />
                                {this.state.items.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            </>
                        )}
                        {this.props.checkListDisplayItem.template.description && (
                            <Paragraph text={this.props.checkListDisplayItem.template.description} />
                        )}
                        <CheckListItemAttachment checkListDisplayItem={this.props.checkListDisplayItem} />
                        {(this.props.checkListDisplayItem.template.enableInstanceAttachments) && (
                            <CheckListItemAttachments checkListDisplayItem={this.props.checkListDisplayItem} isReadonly={this.props.isReadonly} onUpdate={this.props.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} />
                        )}
                    </CardContent>
                </CardContainer>
            </div>
        );
        */
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemSelection);