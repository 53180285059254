import { connect } from 'react-redux'
import User from '../components/users/user/User';
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { 
    userRolesUserRequest,
    userRoleEnableRequest,
    userRoleSaveOtherDocuments,
    userRoleRemoveOtherDocument,
    userRoleSaveComplianceDocuments,
    userRoleRemoveComplianceDocument
 } from '../common/actions/userRole';

const canEdit = (state, groupId) => {
    return permissionsLogic.hasAnyGroupPermissionForState(state, groupId, ["EditL1UserAccess", "EditTempL1UserAccess", "EditTempL2UserAccess", "EditL2UserAccess", "EditTempL3UserAccess", "EditL3UserAccess", "EditL4UserAccess", "EditTempL4UserAccess"])
}

const mapStateToProps = (state, props) => {
    return {
        groupId: props.groupId,
        venueId: props.venueId,
        userId: props.userId,
        securityFirmId: props.securityFirmId,
        isLoading: state.userRole.user.isLoading,
        user: state.userRole.user.user,
        avatar: state.userRole.user.avatar,
        otherDocuments: state.userRole.user.otherDocuments,
        otherDocumentTypes: state.userRole.user.otherDocumentTypes,
        complianceDocuments: state.userRole.user.complianceDocuments,
        complianceDocumentTypes: state.userRole.user.complianceDocumentTypes,
        hasEditPermission: canEdit(state, props.groupId),
        isVenueUserRoleType: permissionsLogic.isVenueUserRoleType(state),
        isSecurityFirmRoleType: permissionsLogic.isSecurityFirmRoleType(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad:(userId) => {
            dispatch(userRolesUserRequest(userId))
        },
        onUserRoleEnableRequest:(userRoleId) => {
            dispatch(userRoleEnableRequest(userRoleId))
        },
        onSaveOtherDocuments: (userId, documentTypeId, files) => {
            dispatch(userRoleSaveOtherDocuments(userId, documentTypeId, files))
        },
        onRemoveOtherDocument: (userId, otherDocument) => {
            dispatch(userRoleRemoveOtherDocument(userId, otherDocument))
        },
        onSaveComplianceDocuments: (userId, documentTypeId, expiryDate, files) => {
            dispatch(userRoleSaveComplianceDocuments(userId, documentTypeId, expiryDate, files))
        },
        onRemoveComplianceDocument: (userId, complianceDocument) => {
            dispatch(userRoleRemoveComplianceDocument(userId, complianceDocument))
        }
    }
}

const UserContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(User))

export default UserContainer