import React, { Component } from 'react';
import Loading from '../common/Loading';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ITermsProps {
    title: string;
    text: string;
    isLoading: Boolean;
    onRefreshData: Function;
    theme: any;
    classes: any;
}

 class Terms extends Component<ITermsProps, any> {
    
    componentDidMount() {
        // on entered, so this page was navigated to...
        if (!this.props.title &&! this.props.isLoading) {
            // request the data
            this.props.onRefreshData();
        }
    }

    render() {
        const { theme } = this.props;

        if (this.props.isLoading) {
            return (
                <Loading />
            );
        }
        let style = {
            color: theme.palette.text.primary
        }
        return (
            <div style={{...style}}>
                <h3>{this.props.title}</h3>
                <span dangerouslySetInnerHTML={{__html: this.props.text}} />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Terms)