import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const documentTypeServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDocumentTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDocumentTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, documentType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostDocumentType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.documentType = documentType;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, documentTypeId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetDocumentType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.documentTypeId = documentTypeId;
    const request = client.get(query);
    return from(request);
  }
};
