import { connect } from 'react-redux'
import BroadcastNotification from '../components/broadcastNotifications/broadcastNotification/BroadcastNotification'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { withRouter } from "react-router";
import * as broadcastNotificationActions from '../common/actions/broadcastNotification';

const mapStateToProps = (state, props) => {
    return {
        broadcastNotificationId: props.match.params.broadcastnotificationId,
        venueId: null,
        securityFirmId: null,
        isLoading: state.broadcastNotification.broadcastNotification.isLoading,
        isSaving: state.broadcastNotification.broadcastNotification.isSaving,
        error: state.broadcastNotification.broadcastNotification.error,
        broadcastNotification: state.broadcastNotification.broadcastNotification.broadcastNotification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (broadcastNotificationId) => {
            dispatch(broadcastNotificationActions.broadcastNotificationRequest(broadcastNotificationId))
        },
        onCreate: (venueId, securityFirmId, cloneId) => {
            dispatch(broadcastNotificationActions.broadcastNotificationCreateRequest(venueId, securityFirmId, cloneId))
        },
        onResetItem: () => {
            dispatch(broadcastNotificationActions.broadcastNotificationReset())
        },
        onUpdate: (broadcastNotification) => {
            dispatch(broadcastNotificationActions.broadcastNotificationSet(broadcastNotification))
        },
        onSave: () => {
            dispatch(broadcastNotificationActions.broadcastNotificationUpsertRequest())
        }
    }
}

const AdminBroadcastNotificationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BroadcastNotification))

export default AdminBroadcastNotificationContainer