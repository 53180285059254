import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const externalApiConnectionServiceApi = {
  get: (venueId, securityFirmId, entityType, forVenueId, forSecurityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetExternalApiConnection();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.entityType = entityType;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.get(query);
    return from(request);
  },
  refreshToken: (venueId, securityFirmId, externalApiConnectionId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostExternalApiConnectionRefresh();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.externalApiConnectionId = externalApiConnectionId;
    const request = client.post(query);
    return from(request);
  },
  remove: (venueId, securityFirmId, externalApiConnectionId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostExternalApiConnectionRemove();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.externalApiConnectionId = externalApiConnectionId;
    const request = client.post(query);
    return from(request);
  },
  update: (venueId, securityFirmId, externalApiConnection) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostExternalApiConnection();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.externalApiConnection = externalApiConnection;
    const request = client.post(query);
    return from(request);
  },
  roublerEmployeeMappings: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetRoublerEmployeeMapping();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  setRoublerEmployeeMappings: (venueId, securityFirmId, entityMapping) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRoublerEmployeeMapping();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.entityMapping = entityMapping;
    const request = client.post(query);
    return from(request);
  },
  exportUserToRoubler: (venueId, securityFirmId, userId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRoublerEmployeeExport();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.userId = userId;
    const request = client.post(query);
    return from(request);
  }
};
