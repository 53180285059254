import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Progress from '../../controls/Progress';
import LayoutForm from '../../layout/LayoutForm';
import { withRouter } from "react-router";
import PageTitle from '../../common/PageTitle';
import FixedFooter from '../../layout/FixedFooter';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Error from '../../common/Error';
import Fab from '@material-ui/core/Fab';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TransparentButton from '../../controls/TransparentButton';
import TickIcon from '../../../resources/TickIcon';
import MagnifyingIcon from '../../../resources/MagnifyingIcon';
import CrossIcon from '../../../resources/CrossIcon';
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import InmateSummary from '../inmate/InmateSummary';
import InmateSelectRow from '../inmate/InmateSelectRow';
import InmateCell from '../inmate/InmateCell';
import Inmate from '../inmate/Inmate';
// import { incidentLogic } from '../../common/logic/incidentLogic';

export interface ICorrectionalFacilityInMateCellProps {
    isLoading: boolean;
    isSaving: boolean;
    search: string;
    hasSearched: boolean;
    error: string;
    correctionalFacilityInMateCell: AusComplyDtos.CorrectionalFacilityInMateCell;
    correctionalFacilityInMate: AusComplyDtos.CorrectionalFacilityInMate;
    correctionalFacilityInMateVenueLocation: AusComplyDtos.CorrectionalFacilityInMateVenueLocation;
    inmates: AusComplyDtos.CorrectionalFacilityInMate[];
    cells: AusComplyDtos.VenueLocation[];
    risks: AusComplyDtos.NamedValue[];
    genders: AusComplyDtos.NamedValue[];
    nationalities: AusComplyDtos.Nationality[];
    isValid: boolean;
    onReset: Function;
    onCreate: Function;
    onSave: Function;
    onSetSearch: Function;
    onUpdateInmate: Function;
    onUpdateInmateCell: Function;
    onUpdateInmateVenueLocation: Function;
    history: any;
    location: any;
}


class CreateInMateCell extends Component<ICorrectionalFacilityInMateCellProps, any> {

    constructor(props: ICorrectionalFacilityInMateCellProps) {
        super(props)

        this.state = {
        }

        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onInmateValueChanged = this.onInmateValueChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onInmateVenueLocationValueChanged = this.onInmateVenueLocationValueChanged.bind(this);
        this.getDefaultInspectionInterval = this.getDefaultInspectionInterval.bind(this);
    }

    componentDidMount() {
        this.props.onReset();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && this.props.error == "") {
            this.onCancel();
        }
    }

    onCancel() {
        this.props.history.push('/inmatescells');
    }

    onSubmit() {
        this.props.onSave();
    }

    onInmateValueChanged(fieldName: string, value: any) {
        let correctionalFacilityInMate = { ...this.props.correctionalFacilityInMate };
        correctionalFacilityInMate[fieldName] = value;
        this.props.onUpdateInmate(correctionalFacilityInMate);
    }

    onInmateChanged(correctionalFacilityInMate: any) {
        this.props.onUpdateInmate(correctionalFacilityInMate);
    }

    onValueChanged(fieldName: string, value: any) {
        let correctionalFacilityInMateCell = { ...this.props.correctionalFacilityInMateCell };
        correctionalFacilityInMateCell[fieldName] = value;
        this.props.onUpdateInmateCell(correctionalFacilityInMateCell);
    }

    onInmateVenueLocationValueChanged(fieldName: string, value: any) {
        let inspectionInterval = this.props.correctionalFacilityInMateVenueLocation.inspectionInterval;
        let correctionalFacilityInMateVenueLocation = { ...this.props.correctionalFacilityInMateVenueLocation };
        if (fieldName == "correctionalFacilityRiskStatusId") {
            // get the default interval
            inspectionInterval = this.getDefaultInspectionInterval(value);
        }
        correctionalFacilityInMateVenueLocation.inspectionInterval = inspectionInterval;
        correctionalFacilityInMateVenueLocation[fieldName] = value;
        this.props.onUpdateInmateVenueLocation(correctionalFacilityInMateVenueLocation);
    }

    onInmateVenueLocationChanged(correctionalFacilityInMateVenueLocation: any) {
        this.props.onUpdateInmateVenueLocation(correctionalFacilityInMateVenueLocation);
    }

    getDefaultInspectionInterval(riskId) {
        if (this.props.risks != null) {
            var risk = this.props.risks.find(f => f.id == riskId);
            if (risk) {
                return risk.rank;
            }
        }
        return 0;
    }

    render() {
        let self = this;
        let buttonGap = '7px';
        let buttonSpace = '6px';
        let buttonStyle = {
            minHeight: '40px',
            minWidth: '40px'
        };

        let submitButton = this.props.isValid ? (
            <PrimaryButton className={"IncidentWizardNavigation-buttons"} style={{ marginLeft: buttonGap, marginBottom: buttonSpace, marginTop: buttonSpace, borderRadius: '5px' }} onClick={this.onSubmit}><TickIcon fill={'#fff'} /></PrimaryButton>
        ) : (
            <TransparentButton className={"IncidentWizardNavigation-buttons"} style={{ marginLeft: buttonGap, marginBottom: buttonSpace, marginTop: buttonSpace }} onClick={() => { }}><TickIcon /></TransparentButton>
        );

        let search;
        let cell;
        let inmate;
        let noteContent;
        if (this.props.hasSearched) {
            let inmateContent;
            let title = "Inmate";
            if (!this.props.correctionalFacilityInMateCell.correctionalFacilityInMate) {
                title = "Select or create a new Inmate";
                // show list and create button
                let inmates = this.props.inmates.map((item, index) =>
                    <InmateSelectRow correctionalFacilityInMate={item} onSelect={(item) => {
                        self.onInmateChanged(item);
                    }} />
                );
                inmateContent = <>
                    {inmates}
                    <Box display="flex" flexDirection="row" p={0} style={{ width: '100%' }}>
                        <Box p={0} flex={1}></Box>
                        <Box p={0}>
                            <TransparentButton onClick={() => {
                                let correctionalFacilityInMate = new AusComplyDtos.CorrectionalFacilityInMate();
                                correctionalFacilityInMate.correctionalFacilityInMateId = 0;
                                self.onInmateChanged(correctionalFacilityInMate);
                            }} text={"Create Inmate"} style={{ marginTop: '10px' }} />
                        </Box>
                        <Box p={0} flex={1}></Box>
                    </Box>
                </>
            } else {
                // show display or create
                if (this.props.correctionalFacilityInMateCell.correctionalFacilityInMate.correctionalFacilityInMateId > 0) {
                    // display
                    inmateContent = <>
                        <InmateSummary
                            correctionalFacilityInMate={this.props.correctionalFacilityInMateCell.correctionalFacilityInMate}
                            onUpdate={(value) => self.onInmateChanged(value)} />
                    </>;

                } else {
                    // create
                    title = "Create a new Inmate";
                    inmateContent = <>
                        <Inmate
                            correctionalFacilityInMate={this.props.correctionalFacilityInMate}
                            genders={this.props.genders}
                            nationalities={this.props.nationalities}
                            onUpdate={(value) => this.onValueChanged("correctionalFacilityInMate", value)}
                        />

                    </>;
                    noteContent = <CardContainer style={{ position: 'relative' }} title={"Add Note"}>
                        <CardContent>
                            <TextAreaControlGroup text="Note"
                                defaultValue={this.props.correctionalFacilityInMateCell.notes}
                                readonly={false}
                                defaultRows={2}
                                onBlur={(value) => this.onValueChanged("notes", value)} />
                            <CheckboxControlGroup
                                label={" "}
                                text={"Important"}
                                defaultValue={this.props.correctionalFacilityInMateCell.noteIsImportant}
                                onChanged={(value) => this.onValueChanged("noteIsImportant", value)} />
                        </CardContent>
                    </CardContainer>;
                }

                cell = <InmateCell
                    correctionalFacilityInMateVenueLocation={this.props.correctionalFacilityInMateVenueLocation}
                    risks={this.props.risks}
                    cells={this.props.cells}
                    onUpdate={(value) => self.onInmateVenueLocationChanged(value)} />;
            }
            inmate =
                <CardContainer style={{ position: 'relative' }} title={title}>
                    <CardContent>
                        <Fab color={'inherit'}
                            style={{ position: 'absolute', top: '2px', right: '2px' }}
                            size="small"
                            onClick={() => self.props.onSetSearch("")} >
                            <div style={{ width: '24px', height: '24px' }}>
                                <CrossIcon style={{ color: '#000' }} />
                            </div>
                        </Fab>
                        {inmateContent}
                    </CardContent>
                </CardContainer>;
        } else {
            let searchContent = <Loading />;
            if (!this.props.isLoading) {
                searchContent = <>
                    <Box p={0} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}>
                            <TextControlGroup text={"Search"}
                                defaultValue={this.props.search}
                                onSubmit={value => {
                                    self.props.onCreate(value);
                                }}
                                onChange={value => {
                                    self.props.onSetSearch(value);
                                }} />
                        </Box>
                        <Box p={0}>
                            <DefaultButton
                                style={{ marginLeft: '10px' }}
                                onClick={() => this.props.onCreate(self.props.search)}
                            >
                                <MagnifyingIcon />
                            </DefaultButton>
                        </Box>
                    </Box>
                </>;
            }
            search = <CardContainer style={{ position: 'relative' }} title={"Inmate CNI or Master Index Number"}>
                <CardContent>
                    {searchContent}
                </CardContent>
            </CardContainer>
        }

        return (
            <LayoutForm
                header={<PageTitle title={"New Inmate Cell"} />}
                loading={false}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                {search}
                {inmate}
                {noteContent}
                {cell}
                <FixedFooter>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} style={{ textAlign: 'right', position: 'relative' }}>
                                    <TransparentButton className={"IncidentWizardNavigation-buttons"}
                                        style={buttonStyle} onClick={this.onCancel}>
                                        <DeleteOutlinedIcon style={{}} />
                                    </TransparentButton>
                                    {submitButton}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FixedFooter>
            </LayoutForm>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(CreateInMateCell));