import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { directoryServiceApi } from '../services/directory';
import * as directoryActions from "../actions/directory";
import {
    notifyError, notifyErrorMessage
} from './common';
import { notificationSuccessShow, notificationShow } from "../actions/notification";

const directoryRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(directoryActions.DIRECTORY_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            directoryServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['entityType'],
                action['id'],
                action['showObsolete'])
                .pipe(
                    map(response => directoryActions.directoryRequestSuccess(response.directory)),
                    catchError(error => notifyError(error, "directoryRequestEpic.get", directoryActions.directoryRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "directoryRequestEpic", directoryActions.directoryRequestFailure))
    );

const directoryUploadRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(directoryActions.DIRECTORY_UPLOAD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            directoryServiceApi.post(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['documentUpload'],
                state.directories.showObsolete)
                .pipe(
                    map(response => directoryActions.directoryUploadRequestSuccess(response.directory)),
                    catchError(error => notifyError(error, "directoryUploadRequestEpic.post", directoryActions.directoryUploadRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "directoryUploadRequestEpic", directoryActions.directoryUploadRequestFailure))
    );

const directoryRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(directoryActions.DIRECTORY_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            directoryServiceApi.remove(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['document'],
                state.directories.showObsolete)
                .pipe(
                    map(response => directoryActions.directoryRemoveRequestSuccess(response.directory)),
                    catchError(error => notifyError(error, "directoryRemoveRequestEpic.post", directoryActions.directoryRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "directoryRemoveRequestEpic", directoryActions.directoryRemoveRequestFailure))
    );

const directoryUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(directoryActions.DIRECTORY_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            directoryServiceApi.update(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['document'],
                state.directories.showObsolete)
                .pipe(
                    map(response => directoryActions.directoryUpdateRequestSuccess(response.directory)),
                    catchError(error => notifyError(error, "directoryUpdateRequestEpic.post", directoryActions.directoryUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "directoryUpdateRequestEpic", directoryActions.directoryUpdateRequestFailure))
    );

    const directoryUpsertFolderRequestEpic = (action$, state$) =>
        action$.pipe(
            ofType(directoryActions.DIRECTORY_FOLDER_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                directoryServiceApi.upsertFolder(
                    state.user.details.userSession.user.venueId,
                    state.user.details.userSession.user.securityFirmId,
                    action['entityType'],
                    action['id'],
                    action['folder'],
                    state.directories.showObsolete)
                    .pipe(
                        map(response => directoryActions.directoryUpsertFolderRequestSuccess(response.directory)),
                        catchError(error => notifyError(error, "directoryUpsertFolderRequestEpic.post", directoryActions.directoryUpsertFolderRequestFailure))
                    )
            ),
            catchError(error => notifyError(error, "directoryUpsertFolderRequestEpic", directoryActions.directoryUpsertFolderRequestFailure))
        );

const directoryAdminRequestEpic = (action$, state$) =>
        action$.pipe(
            ofType(directoryActions.DIRECTORY_ADMIN_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                directoryServiceApi.adminGet(
                    state.user.details.userSession.user.venueId,
                    state.user.details.userSession.user.securityFirmId,
                    action['entityType'])
                    .pipe(
                        map(response => directoryActions.directoryAdminRequestSuccess(response.directory)),
                        catchError(error => notifyError(error, "directoryAdminRequestEpic.get", directoryActions.directoryAdminRequestFailure))
                    )
            ),
            catchError(error => notifyError(error, "directoryAdminRequestEpic", directoryActions.directoryAdminRequestFailure))
        );

const directoryAdminUpsertFolderRequestEpic = (action$, state$) =>
        action$.pipe(
            ofType(directoryActions.DIRECTORY_ADMIN_FOLDER_REQUEST),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                directoryServiceApi.adminUpsertFolder(
                    state.user.details.userSession.user.venueId,
                    state.user.details.userSession.user.securityFirmId,
                    action['entityType'],
                    action['folder'])
                    .pipe(
                        map(response => directoryActions.directoryAdminUpsertFolderRequestSuccess(response.directory)),
                        catchError(error => notifyError(error, "directoryAdminUpsertFolderRequestEpic.post", directoryActions.directoryAdminUpsertFolderRequestFailure))
                    )
            ),
            catchError(error => notifyError(error, "directoryAdminUpsertFolderRequestEpic", directoryActions.directoryAdminUpsertFolderRequestFailure))
        );

export const directoryEpics = combineEpics(
    directoryRequestEpic,
    directoryUploadRequestEpic,
    directoryUpdateRequestEpic,
    directoryRemoveRequestEpic,
    directoryUpsertFolderRequestEpic,
    directoryAdminRequestEpic,
    directoryAdminUpsertFolderRequestEpic
);