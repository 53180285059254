import { connect } from 'react-redux' 
import PrivacyPolicy from '../components/general/PrivacyPolicy'
import { contentPrivacyPolicyRequest } from '../common/actions/content'

const mapStateToProps = state => {
    return {
        isLoading: state.content.isLoading,
        title: state.content.privacyPolicy.title,
        text: state.content.privacyPolicy.text
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefreshData: () =>{
            dispatch(contentPrivacyPolicyRequest())
        }
    }
}

const PrivacyPolicyContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicy)

export default PrivacyPolicyContainer