import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_FAILURE = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_RESET = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_RESET",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_SET = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_SET",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_FAILURE = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_FAILURE = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_FAILURE = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_FAILURE = "FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_FAILURE"
    ;

export const facialRecognitionAutomaticEnrolmentsRequest = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST, 'forVenueId');
export const facialRecognitionAutomaticEnrolmentsRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_SUCCESS, 'facialRecognitionAutomaticEnrolments');
export const facialRecognitionAutomaticEnrolmentsRequestFailure = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENTS_REQUEST_FAILURE, 'error');
export const facialRecognitionAutomaticEnrolmentReset = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_RESET);
export const facialRecognitionAutomaticEnrolmentSet = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_SET, 'facialRecognitionAutomaticEnrolment');
export const facialRecognitionAutomaticEnrolmentRequest = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST ,'facialRecognitionAutomaticEnrolmentId');
export const facialRecognitionAutomaticEnrolmentRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_SUCCESS, 'facialRecognitionAutomaticEnrolment', 'incidentTypes', 'incidentTypeCategoryTypes', 'watchlists');
export const facialRecognitionAutomaticEnrolmentRequestFailure = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_REQUEST_FAILURE, 'error');
export const facialRecognitionAutomaticEnrolmentCreateRequest = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST, 'forVenueId');
export const facialRecognitionAutomaticEnrolmentCreateRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_SUCCESS, 'facialRecognitionAutomaticEnrolment', 'incidentTypes', 'incidentTypeCategoryTypes', 'watchlists');
export const facialRecognitionAutomaticEnrolmentCreateRequestFailure = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_CREATE_REQUEST_FAILURE, 'error');
export const facialRecognitionAutomaticEnrolmentUpsertRequest = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST, 'facialRecognitionAutomaticEnrolment');
export const facialRecognitionAutomaticEnrolmentUpsertRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_SUCCESS, 'facialRecognitionAutomaticEnrolment');
export const facialRecognitionAutomaticEnrolmentUpsertRequestFailure = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_UPSERT_REQUEST_FAILURE, 'error');
export const facialRecognitionAutomaticEnrolmentProcessRequest = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST, 'facialRecognitionAutomaticEnrolmentId', 'date');
export const facialRecognitionAutomaticEnrolmentProcessRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_SUCCESS);
export const facialRecognitionAutomaticEnrolmentProcessRequestFailure = makeActionCreator(FACIAL_RECOGNITION_AUTOMATIC_ENROLMENT_PROCESS_REQUEST_FAILURE, 'error');