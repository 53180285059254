import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Display } from '../../common/constants/display';

interface IFixedFooterProps {
    theme: any;
    classes: any;
    noAppDrawer?: boolean;
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        padding: '10px 0',
        marginLeft: Display.DrawerWidth,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${Display.DrawerWidth}px)`,
        },
    },
    appBarNoDrawer: {
        top: 'auto',
        bottom: 0,
        padding: '10px 0',
        marginLeft: Display.DrawerWidth
    }
})


class FixedFooter extends React.Component<IFixedFooterProps, any> {
    render() {
        const { classes, theme } = this.props;

        let appClassName = classes.appBar;
        if (this.props.noAppDrawer){
            appClassName = classes.appBarNoDrawer;
        }

        return (

            <AppBar position="fixed" 
                classes={{root: classes.footerBar}}
                className={appClassName}
                style={{padding: '4px 0', backgroundColor: 'black', borderTop: '1px solid', borderTopColor: theme.custom.colors.boundary}}
                >
                <Toolbar style={{padding: '0 8px'}}>
                    {this.props.children}
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FixedFooter);