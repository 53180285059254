import { connect } from 'react-redux' ;
import IncidentsPrint from '../components/incidents/IncidentsPrint';
import { printOptionsRequest, printHide, printIncidentsPdfRequest, printIncidentsEmailRequest, printIncidentsCsvRequest } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = (state) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        filter: state.incidents.filter,
        isLoading: state.print.isLoading,
        show: state.print.show,
        printOptions: state.print.printOptions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadPrintOptions:() => {
            dispatch(printOptionsRequest())
        },        
        onHide:() => {
            dispatch(printHide())
        },
        onPrintPdf:(reportType, filter, imageOption) => {
            dispatch(printIncidentsPdfRequest(reportType, filter, imageOption))
        },
        onExport:(filter) => {
            dispatch(printIncidentsCsvRequest(filter))
        },
        onEmail:(filter, send, imageOption) => {
            dispatch(printIncidentsEmailRequest(filter, send, imageOption))
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message))
        }
    }
}

const IncidentsPrintContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentsPrint)

export default IncidentsPrintContainer