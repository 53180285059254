import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { GridSpacing } from '@material-ui/core/Grid';
import Media from "react-media";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyName } from '../../common/dto/common';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IPageTitleProps {
    theme: any;
    classes: any;
    title?: string;
    moreActions?: KeyName[];
    onCommand?: Function;
    headerStyle?: any;
}

class PageTitle extends React.Component<IPageTitleProps, any> {
    constructor(props: IPageTitleProps) {
        super(props)
        this.handleClickItem = this.handleClickItem.bind(this);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickItem = option => event => {
        if (this.props.onCommand) {
            this.props.onCommand(option.key);
        }
        this.setState({ anchorEl: null });
    };

    render() {
        const { theme, classes } = this.props;
        let pageTitleStyle: any = {
            color: '#999999',
            padding: '0',
            margin: '15px 0 20px',
            textAlign: 'center',
            fontSize: '22px'
        };
        let pageTitleStyleDesktop: any = {
            color: '#999999',
            padding: '0',
            margin: '0 0 15px 0',
            textAlign: 'left',
            fontSize: '22px'
        };

        let desktopAndMobilePageTitle = (
            <Media query={{ maxWidth: 1279 }}>
                {matches =>
                    matches ? (
                        <h2 className={classes.title} style={{ ...this.props.headerStyle }}>{this.props.title}</h2>
                    ) : (
                            <h2 className={classes.title} style={{ ...this.props.headerStyle }}>{this.props.title}</h2>
                        )
                }
            </Media>);

        if (!this.props.children && !this.props.moreActions) {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                        {desktopAndMobilePageTitle}
                    </Grid>
                </Grid>
            );
        }
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        let moreActionsControls;
        if (this.props.moreActions) {
            moreActionsControls = (
                <>
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        style={{ padding: 0 }}
                        onClick={this.handleClick}
                    >
                        <MoreVertIcon style={{ color: theme.palette.text.primary }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                width: 200,
                            },
                        }}
                    >
                        {this.props.moreActions && this.props.moreActions.map(option => (
                            <MenuItem key={option.key} onClick={this.handleClickItem(option)}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            );
        }

        if (!this.props.children && this.props.moreActions) {
            return (
                <Media query={{ maxWidth: 1279 }}>
                    {matches =>
                        matches ? (
                            <Grid container spacing={0}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    {desktopAndMobilePageTitle}
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'right' }}>
                                    {moreActionsControls}
                                </Grid>
                            </Grid>
                        ) : (
                                <Grid container spacing={0}>
                                    <Grid item xs={10}>
                                        {desktopAndMobilePageTitle}
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                        {moreActionsControls}
                                    </Grid>
                                </Grid>
                            )
                    }
                </Media>

            );
        }

        return (
            <Grid container spacing={0}>
                <Grid item xs={2} sm={3}>

                </Grid>
                <Grid item xs={8} sm={6}>
                    <h2 className={classes.title} style={{ ...this.props.headerStyle }}>{this.props.title}</h2>
                </Grid>
                <Grid item xs={2} sm={3} style={{ textAlign: 'right' }}>
                    {this.props.children}
                    {this.props.moreActions && (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={open ? 'long-menu' : undefined}
                                aria-haspopup="true"
                                style={{ padding: 0 }}
                                onClick={this.handleClick}
                            >
                                <MoreVertIcon style={{ color: theme.palette.text.primary }} />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                    style: {
                                        width: 200,
                                    },
                                }}
                            >
                                {this.props.moreActions && this.props.moreActions.map(option => (
                                    <MenuItem key={option.key} onClick={this.handleClickItem(option)}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles, { withTheme: true })(PageTitle);