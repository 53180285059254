import React from "react";

const ListIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 21.6 17.5"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="21.6" y1="1" x2="5.5" y2="1" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="3.1" y1="1" x2="0" y2="1" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="21.6" y1="6.3" x2="5.5" y2="6.3" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="3.1" y1="6.3" x2="0" y2="6.3" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="21.6" y1="11.5" x2="5.5" y2="11.5" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="3.1" y1="11.5" x2="0" y2="11.5" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="21.6" y1="16.5" x2="5.5" y2="16.5" />
                <line fill={'none'} stroke={fill} strokeWidth={2} x1="3.1" y1="16.5" x2="0" y2="16.5" />
            </g>
        </svg>
    );

export default ListIcon;
