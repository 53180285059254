import React from 'react';
import { MulitSelectOption } from '../../common/dto/common';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Palette } from '../../common/constants/palette';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpward from '@material-ui/icons/KeyboardArrowUp';
import TransparentButton from '../controls/TransparentButton';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../controls/ControlGroup';
import Label from '../common/Label';
import { BorderColor } from '@material-ui/icons';

export interface IMultiRadioChipControlProps {
    name?: string;
    commonTitle?: string;
    selected?: number[];
    options?: MulitSelectOption[];
    onChange?: Function;
    readonly?: boolean;
    required?: boolean;
    chipWidth?: string;
    collapseOnFirstSelection?: boolean;
    wrapCardInControlGroup?: boolean;
    wrapInCard?: boolean;
    cardTitle?: string;
    wrapChipsInControlGroup?: boolean;
    controlGroupTitle?: string;
    controlGroupLabelAbove?: boolean;
    hideIfNoOptions?: boolean;
    theme: any;
    classes: any;
}
export interface IMultiRadioChipControlState {
    expanded: boolean;
}

class MultiRadioChipControl extends React.PureComponent<IMultiRadioChipControlProps, IMultiRadioChipControlState> {

    constructor(props: IMultiRadioChipControlProps) {
        super(props);

        let selected = this.props.selected || [];
        this.state = {
            expanded: selected.length === 0
        };

        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onDeSelected = this.onDeSelected.bind(this);
    }

    componentDidMount() {
        let selected = this.props.selected || [];
        this.setState({ expanded: selected.length === 0 })
    }

    componentDidUpdate(prevProps) {
        let options = this.props.options || [];
        let selected = this.props.selected || [];
        let prevOptions = prevProps.options || [];
        let prevSelected = prevProps.selected || [];
        if (JSON.stringify(options) !== JSON.stringify(prevOptions) ||
            JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
            this.setState({ expanded: selected.length === 0 })
        }
    }

    onSelected(item: MulitSelectOption) {
        if (this.props.onChange) {
            let selected = this.props.selected || [];
            this.props.onChange([...selected, item.id]);
            if (selected.length === 0) {
                this.setState({ expanded: false });
            }
        }
    }

    onDeSelected(id: number) {
        if (this.props.onChange) {
            let selected = this.props.selected || [];
            let newSelected = selected.filter(f => f !== id);
            this.props.onChange(newSelected);
            if (newSelected.length === 0) {
                this.setState({ expanded: true });
            }
        }
    }

    render() {
        const { theme } = this.props;
        let options = this.props.options || [];
        let selected = this.props.selected || [];
        let content;
        let selectedContent;
        let chipWidth = this.props.chipWidth || 'auto';

        if (this.props.hideIfNoOptions && options.length === 0) {
            return <></>;
        }

        let availableOptions = options.filter(item => selected.indexOf(item.id) === -1);
        let expanded = this.state.expanded && availableOptions.length > 0;

        if (selected && options) {
            let selectedOptions = options.filter(item => selected.indexOf(item.id) > -1).map((item, index) => {
                return <div style={{ position: 'relative' }}>
                    <Chip key={item.id} label={item.name}
                        style={{
                            marginLeft: 0,
                            marginTop: 0,
                            marginRight: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            padding: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: theme.custom.colors.controlTextLight,
                            backgroundColor: theme.custom.colors.controlBackgroundLight,
                            borderColor: theme.custom.colors.boundary,
                            borderRadius: '15px',
                            width: chipWidth
                        }} variant="outlined" deleteIcon={<div style={{
                            backgroundColor: '#fff',
                            fontSize: '10px',
                            height: '16px',
                            width: '16px',
                            borderRadius: '9px',
                            padding: 0,
                            position: 'absolute',
                            top: '-3px',
                            right: '-3px'
                        }} ><CancelIcon style={{
                            fontSize: '10px',
                            height: '18px',
                            width: '18px',
                            margin: '-1px',
                            color: Palette.Error
                        }} /></div>} onDelete={() => this.onDeSelected(item.id)} />
                </div>
            });

            if (selectedOptions.length > 0) {
                let more = this.state.expanded || this.props.readonly || availableOptions.length === 0 ? <></> : <TransparentButton 
                    onClick={() => this.setState({ expanded: true })} 
                    autoFocus={false}
                    style={{
                        width: '26px',
                        height: '26px',
                        marginTop: '4px',
                        fontSize: '12px',
                        borderColor: Palette.Primary,
                        marginBottom: theme.spacing(1),
                    }}>
                    <AddIcon  style={{
                        fontSize: '10px',
                        height: '18px',
                        width: '18px',
                        color: Palette.Primary
                    }} />
                </TransparentButton>;

                let less = this.state.expanded ? <TransparentButton onClick={() => this.setState({ expanded: false })} autoFocus={false}
                    style={{
                        width: '26px',
                        height: '26px',
                        marginTop: '4px',
                        fontSize: '12px',
                        borderColor: Palette.Primary,
                        marginBottom: theme.spacing(1),
                    }}>
                    <ArrowUpward style={{
                        fontSize: '10px',
                        height: '18px',
                        width: '18px',
                        color: Palette.Primary
                    }} />
                </TransparentButton> : <></>;

                selectedContent =
                    <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', marginTop: '8px', marginBottom: '-14px', position: 'relative' }}>
                        {selectedOptions}{more}{less}
                    </div>
            }
        }

        if (expanded && !this.props.readonly) {
            let commonOptions = availableOptions.filter(f => f.isCommon).map((item, index) => {
                return (
                    <TransparentButton key={item.id} text={item.name} onClick={() => this.onSelected(item)} autoFocus={false}
                        style={{
                            width: 'auto',
                            height: '32px',
                            marginRight: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            fontSize: '12px',
                            textTransform: 'none',
                            padding: '4px',
                            paddingLeft: '18px',
                            paddingRight: '18px',
                        }} />
                )
            });
            let radioOptions = availableOptions.filter(f => !f.isCommon).map((item, index) => {
                return (
                    <TransparentButton key={item.id} text={item.name} onClick={() => this.onSelected(item)} autoFocus={false}
                        style={{
                            width: 'auto',
                            height: '32px',
                            marginRight: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                            fontSize: '12px',
                            textTransform: 'none',
                            padding: '4px',
                            paddingLeft: '18px',
                            paddingRight: '18px',
                        }} />
                )
            });

            if (radioOptions.length > 0 || commonOptions.length > 0) {
                content = <>{commonOptions.length > 0 && (
                    <>
                        <Label text={this.props.commonTitle || "Common"} style={{ textTransform: 'uppercase', margin: '15px 0 0 0', display: 'block' }} />
                        <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', marginTop: '8px', marginBottom: '-14px', position: 'relative' }}>
                            {commonOptions}
                        </div>
                    </>
                )}
                    {(radioOptions.length > 0 && commonOptions.length > 0) && <div style={{ marginTop: '14px', borderBottom: '1px dotted ' + Palette.ControlBorder }}></div>}
                    {radioOptions.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', marginTop: '8px', marginBottom: '-14px', position: 'relative' }}>
                            {radioOptions}
                        </div>
                    )}
                </>;
            }
        }

        content = <>
            {selectedContent}
            {(selectedContent && content) && <div style={{ marginTop: '14px', borderBottom: '1px dotted ' + Palette.ControlBorder }}></div>}
            {content}
        </>

        if (this.props.wrapChipsInControlGroup) {
            content = <ControlGroup labelAbove={this.props.controlGroupLabelAbove} text={this.props.controlGroupTitle} inline={this.state.expanded}>
                {content}
            </ControlGroup>
        }

        if (this.props.wrapInCard) {
            content = <CardContainer
                title={this.props.cardTitle}
                style={{ paddingTop: 0 }}>
                <CardContent style={{ paddingTop: 0 }}>
                    {content}
                </CardContent>
            </CardContainer>
        }

        if (this.props.wrapCardInControlGroup) {
            content = <ControlGroup
                labelAbove={false}
                text={" "}>
                {content}
            </ControlGroup>
        }

        return content
    }
}

export default withStyles(styles, { withTheme: true })(MultiRadioChipControl);