
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const notificationLogic = {

    dismissable(notification: AusComplyDtos.Notification) {
        // build this list from addNotificationActions in controls/notifications.ascx.cs
        if (notification.notificationTypeCode == 'UserRequestsVenueAccess' ||
            notification.notificationTypeCode == 'UserRequestsSecurityFirmAccess' ||
            notification.notificationTypeCode == 'RegisterSecurityFirmUser' ||
            notification.notificationTypeCode == 'RegisterVenueUser' ||
            notification.notificationTypeCode == 'RegisterOtherUser') {
            // show the modal to confirm or deny a user request
            return false;
        }
        if (notification.notificationTypeId == 'SuspendL1VenueUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1VenueUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1VenueUserReinstate_Command)
            return false;
        }
        if (notification.notificationTypeId == 'SuspendL1SecurityFirmUser') {
            // NOTIFICATION_CONFIRM (btnSuspendL1SecurityFirmUserConfirm_Command)
            // NOTIFICATION_REINSTATE (btnSuspendL1SecurityFirmUserReinstate_Command)
            return false;
        }
        if (notification.notificationTypeId == 'RecommendVenueIncidentDeletion' ||
            notification.notificationTypeId == 'RecommendSecurityFirmIncidentDeletion') {
                return false;
        }
        if (notification.notificationTypeId == 'IncidentRequiresSubmission' ||
            notification.notificationTypeId == 'SecurityFirmIncidentRequiresApproval') {
                return false;
        }
        if (notification.notificationTypeId == 'RequestInvalidSLEDAccess') {
            return false;
        }
        if (notification.notificationTypeId == 'UserComplianceDocumentExpiry6Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry3Months' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Month' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry2Weeks' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpiry1Week' ||
            notification.notificationTypeId == 'UserComplianceDocumentExpired' ||
            notification.notificationTypeId == 'UserDocumentMissing') {
                return true;
        }
        if (notification.notificationTypeId == 'L4VenueRegisterUserComplianceDocumentExpiring') {
            return true;
        }
        if (notification.notificationTypeId == 'L3SecurityFirmRegisterUserComplianceDocumentExpiring' ||
            notification.notificationTypeId == 'L4SecurityFirmRegisterUserComplianceDocumentExpiring') {
                return true;
        }
        if (notification.notificationTypeId == 'IncidentBulkApproval') {
            return false;
        }
        if (notification.notificationTypeId == 'Broadcast') {
            return true;
        }
        if (notification.notificationTypeId == 'FacialRecognition') {
            return true;
        }
        if (notification.notificationTypeId == 'IncidentFlagNotification') {
            return true;
        }
        if (notification.notificationTypeId == 'IncidentCategoryNotification') {
            return true;
        }
        if (notification.notificationTypeId == 'IncidentActionNotification' ||
            notification.notificationTypeId == 'IncidentActionValueNotification') {
                return true;
        }
        if (notification.notificationTypeId == 'GroupUserAccessRequestNotification') {
            return true;
        }

        if (notification.notificationTypeId == 'GroupVenueInvitationNotification') {
            return true;
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskIncidentEscalation) {
                return false;
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ScheduledTaskChecklistEscalation) {
                return false;
        }
        if (notification.notificationTypeId == 'WatchlistEventNotification') {
            return true;
        }
        if (notification.notificationTypeId == 'ChecklistSubmitted' ||
            notification.notificationTypeId == 'ChecklistValueSubmitted') {
                return true;
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueIncident ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmIncident) {
                return false;
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenueChecklist ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirmChecklist) {
                return false;
        }
        if (notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventVenue ||
            notification.notificationTypeId == AusComplyDtos.ngtNotificationType.ExternalEventSecurityFirm) {
                return true;
        }
        return false;
    },
    groupHasDismissable(notificationGroup: AusComplyDtos.NotificationGroup) {
        let hasDismissable: boolean = false;
        if (notificationGroup && notificationGroup.notifications != null) {
            notificationGroup.notifications.forEach(notification => {
                if (this.dismissable(notification)){
                    hasDismissable = true;
                }
            });
        }
        return hasDismissable;
    }
}
