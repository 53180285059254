import { makeActionCreator } from './helpers';

export const
    FUNCTIONALAREAS_REQUEST = "FUNCTIONALAREAS_REQUEST",
    FUNCTIONALAREAS_REQUEST_SUCCESS = "FUNCTIONALAREAS_REQUEST_SUCCESS",
    FUNCTIONALAREAS_REQUEST_FAILURE = "FUNCTIONALAREAS_REQUEST_FAILURE",
    FUNCTIONALAREAS_REFRESH = "FUNCTIONALAREAS_REFRESH",
    FUNCTIONALAREA_CREATE_REQUEST = "FUNCTIONALAREA_CREATE_REQUEST",
    FUNCTIONALAREA_CREATE_REQUEST_SUCCESS = "FUNCTIONALAREA_CREATE_REQUEST_SUCCESS",
    FUNCTIONALAREA_CREATE_REQUEST_FAILURE = "FUNCTIONALAREA_CREATE_REQUEST_FAILURE",
    FUNCTIONALAREA_REQUEST = "FUNCTIONALAREA_REQUEST",
    FUNCTIONALAREA_REQUEST_SUCCESS = "FUNCTIONALAREA_REQUEST_SUCCESS",
    FUNCTIONALAREA_REQUEST_FAILURE = "FUNCTIONALAREA_REQUEST_FAILURE",
    FUNCTIONALAREA_UPDATE = "FUNCTIONALAREA_UPDATE",
    FUNCTIONALAREA_SAVE_REQUEST = "FUNCTIONALAREA_SAVE_REQUEST",
    FUNCTIONALAREA_SAVE_REQUEST_SUCCESS = "FUNCTIONALAREA_SAVE_REQUEST_SUCCESS",
    FUNCTIONALAREA_SAVE_REQUEST_FAILURE = "FUNCTIONALAREA_SAVE_REQUEST_FAILURE",
    FUNCTIONALAREA_RESET = "FUNCTIONALAREA_RESET";


export const functionalAreasRequest = makeActionCreator(FUNCTIONALAREAS_REQUEST, 'page', 'pageSize', 'filter');
export const functionalAreasRequestSuccess = makeActionCreator(FUNCTIONALAREAS_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const functionalAreasRequestFailure = makeActionCreator(FUNCTIONALAREAS_REQUEST_FAILURE, 'error');
export const functionalAreaCreateRequest = makeActionCreator(FUNCTIONALAREA_CREATE_REQUEST, 'forGroupId', 'forVenueId');
export const functionalAreaCreateRequestSuccess = makeActionCreator(FUNCTIONALAREA_CREATE_REQUEST_SUCCESS, 'functionalArea');
export const functionalAreaCreateRequestFailure = makeActionCreator(FUNCTIONALAREA_CREATE_REQUEST_FAILURE, 'error');
export const functionalAreaRequest = makeActionCreator(FUNCTIONALAREA_REQUEST, 'functionalAreaId');
export const functionalAreaRequestSuccess = makeActionCreator(FUNCTIONALAREA_REQUEST_SUCCESS, 'functionalArea');
export const functionalAreaRequestFailure = makeActionCreator(FUNCTIONALAREA_REQUEST_FAILURE, 'error');
export const functionalAreaSaveRequest = makeActionCreator(FUNCTIONALAREA_SAVE_REQUEST, 'functionalArea');
export const functionalAreaSaveRequestSuccess = makeActionCreator(FUNCTIONALAREA_SAVE_REQUEST_SUCCESS, 'functionalArea');
export const functionalAreaSaveRequestFailure = makeActionCreator(FUNCTIONALAREA_SAVE_REQUEST_FAILURE, 'error');
export const functionalAreaUpdate = makeActionCreator(FUNCTIONALAREA_UPDATE, 'functionalArea');
export const functionalAreasRefresh = makeActionCreator(FUNCTIONALAREAS_REFRESH);
export const functionalAreaReset = makeActionCreator(FUNCTIONALAREA_RESET);