import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { subLocationServiceApi } from '../services/subLocationService';

import * as subLocationActions from '../actions/subLocation';
import {
    notifyError
} from './common';

const subLocationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
        subLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => subLocationActions.subLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "subLocationsRequestEpic.find", subLocationActions.subLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationsRequestEpic", subLocationActions.subLocationsRequestFailure))
    );


const subLocationsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            subLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.subLocations.subLocations.paging.page,
                state.subLocations.subLocations.paging.pageSize,
                state.subLocations.subLocations.filter)
                .pipe(
                    map(response => subLocationActions.subLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "subLocationsRefreshEpic.find", subLocationActions.subLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationsRefreshEpic", subLocationActions.subLocationsRequestFailure))
    );
/*
const subLocationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            subLocationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['subLocationId'])
                .pipe(
                    map(response => subLocationActions.subLocationRequestSuccess(response.subLocation)),
                    catchError(error => notifyError(error, "subLocationRequestEpic.get", subLocationActions.subLocationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationRequestEpic", subLocationActions.subLocationRequestFailure))
    );
*/
const subLocationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            subLocationServiceApi.get2(state, action)
                .pipe(
                    map(response => subLocationActions.subLocationRequestSuccess(response.subLocation)),
                    catchError(error => notifyError(error, "subLocationRequestEpic.get", subLocationActions.subLocationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationRequestEpic", subLocationActions.subLocationRequestFailure))
    );

const subLocationCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            subLocationServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forGroupId'],
                action['forVenueId'])
                .pipe(
                    map(response => subLocationActions.subLocationCreateRequestSuccess(response.subLocation)),
                    catchError(error => notifyError(error, "subLocationCreateEpic.create", subLocationActions.subLocationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationCreateEpic", subLocationActions.subLocationCreateRequestFailure))
    );

const subLocationSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            subLocationServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.subLocations.subLocation.subLocation)
                .pipe(
                    map(response => subLocationActions.subLocationSaveRequestSuccess(response.subLocation)),
                    catchError(error => notifyError(error, "subLocationUpsertEpic.upsert", subLocationActions.subLocationSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "subLocationUpsertEpic", subLocationActions.subLocationSaveRequestFailure))
    );

const subLocationSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_SAVE_REQUEST_SUCCESS),
        map((action: any) => subLocationActions.subLocationReset())
    );

const subLocationSaveSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(subLocationActions.SUBLOCATION_SAVE_REQUEST_SUCCESS),
        map((action: any) => subLocationActions.subLocationsRefresh())
    );

export const subLocationEpics = combineEpics(
    subLocationsRefreshEpic,
    subLocationsRequestEpic,
    subLocationRequestEpic,
    subLocationCreateEpic,
    subLocationSaveEpic,
    subLocationSaveSuccessEpic,
    subLocationSaveSuccessRefreshEpic
);