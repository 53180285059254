import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextControl from '../controls/TextControl';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CardTitle from '../common/CardTitle';
import PrimaryButton from '../controls/PrimaryButton';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IVenueSecurityCodeProps {
    theme: any;
    onLoginToVenue: Function
}



class VenueSecurityCode extends Component<IVenueSecurityCodeProps, any> {
    constructor(props: IVenueSecurityCodeProps) {
        super(props)
        this.onLogin = this.onLogin.bind(this);
        this.state = {
            code: ""
        }
    }

    onLogin() {
        this.props.onLoginToVenue(this.state.code);
    }

    render() {
        return (
            <CardContainer title={"Security Code"}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextControl
                                value={this.state.code}
                                placeholder="4 digit reporting period code..."
                                onSubmit={this.onLogin}
                                onChange={(value) => this.setState({ code: value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PrimaryButton onClick={this.onLogin} >Login to venue</PrimaryButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardContainer>
        );
    }
}
export default withStyles(styles, { withTheme: true })(VenueSecurityCode);