import { connect } from 'react-redux';
import VenueImageProcessingHistorical from '../components/admin/venue/VenueImageProcessingHistorical';
import * as imageProcessingActions from '../common/actions/imageProcessing';
import { withRouter } from "react-router";

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        isProcessing: state.imageProcessing.isProcessing
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onProcess: (forVenueId, fromDate) => {
            dispatch(imageProcessingActions.imageProcessingVenueRequest(forVenueId, fromDate))
        }
    }
}

const VenueImageProcessingHistoricalContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueImageProcessingHistorical))

export default VenueImageProcessingHistoricalContainer