import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import MoreCommands from '../../common/MoreCommands';

export interface IUserRoleRequestRowProps {
    classes: any;
    theme: any;
    userRoleRequest: AusComplyDtos.UserRoleRequest;
    onDismiss?: Function;
}

class UserRoleRequestRow extends React.Component<IUserRoleRequestRowProps, any> {
    constructor(props: IUserRoleRequestRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "dismiss":
                if (this.props.onDismiss) {
                    this.props.onDismiss(this.props.userRoleRequest.notificationID);
                }
                break;
        }
    }


    render() {
        const { classes } = this.props;
        let item = this.props.userRoleRequest;
        let className = classes.rowStatusPending;
        var commands: KeyName[] = [];
        if (this.props.onDismiss) {
            commands.push({ key: "dismiss", name: "Dismiss" });
        }

        return (
            <tr className={classes.tableTr} key={"row-" + item.notificationID.toString()} >
                <td className={className} ></td>
                <td>
                    <p>
                        {item.type}
                    </p>
                </td>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td>
                    <p>
                        {item.dateFormatted}
                    </p>
                </td>
                <td>
                    <p>
                        {item.status}
                    </p>
                </td>
                <td>
                    {(commands.length > 0) && (
                        <MoreCommands
                            commands={commands}
                            onCommand={this.onCommand}/>
                    )}
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRequestRow);