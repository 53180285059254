import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CheckboxControl from '../../controls/CheckboxControl'
import TextControlGroup from '../../controls/TextControlGroup';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import SingleSelectListControl from '../../controls/SingleSelectListControl';
import ControlGroup from '../../controls/ControlGroup';
import { SingleSelectList } from '../../../common/dto/common';

export interface IAdminFacialRecognitionNotificationFilterProps {
    filter: AusComplyDTOs.FacialRecognitionNotificationFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
}

interface IAdminFacialRecognitionNotificationFilterState {
    filter: AusComplyDTOs.FacialRecognitionNotificationFilter;
}

export default class AdminFacialRecognitionNotificationFilter extends React.Component<IAdminFacialRecognitionNotificationFilterProps, IAdminFacialRecognitionNotificationFilterState> {
    constructor(props: IAdminFacialRecognitionNotificationFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showRemovedChanged = this.showRemovedChanged.bind(this);
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
        this.onFromChanged = this.onFromChanged.bind(this);
        this.onToChanged = this.onToChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.FacialRecognitionNotificationFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onSelectedItemChanged(item) {
        if (item) {
            this.onChange("venueId", item.id);
        } else {
            this.onChange("venueId", null);
        }
    }

    onFromChanged(value) {
        this.onChange("from", value);
    }

    onToChanged(value) {
        this.onChange("to", value);
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    showRemovedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                </Grid>
            </Grid>
        );

        let venues;
        if (this.state.filter && this.state.filter.venues && this.state.filter.venues.length > 1) {
            let items: SingleSelectList[] = [];
            this.state.filter.venues.forEach((venue) => {
                items.push({ id: venue.venueId, name: venue.name, isCommon: false, selected: this.state.filter.venueId === venue.venueId, info: "", infoColor: "" });
            });
            venues = (
                <ControlGroup text={"Venue"}>
                    <SingleSelectListControl
                        items={items}
                        selectedTitle=""
                        emptyMessage="Select a Venue"
                        hideEmptyMessage={true}
                        commonTitle=""
                        availableTitle="Search venues"
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectedItemChanged={this.onSelectedItemChanged}
                        autoHideOnSelected={true}
                    ></SingleSelectListControl>
                </ControlGroup>
            )
        }
        let dates;
        if (this.state.filter) {
            dates = (
                <>
                    <SearchDateControlGroup
                        text="Time Period"
                        rightText={"From"}
                        labelAbove={true}
                        date={this.state.filter.from}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onFromChanged}
                        defaultTime={"00:00"} />
                    <SearchDateControlGroup
                        rightText="To"
                        labelAbove={true}
                        date={this.state.filter.to}
                        onEnter={() => this.props.onSearch()}
                        onChange={this.onToChanged}
                        defaultTime={"23:59"} />
                </>);
        }

        return (
            <LayoutForm>
                { venues}
                { dates }
                {resetButton}
            </LayoutForm>
        );
    }
}