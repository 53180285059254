import React, { Component } from 'react';
import Loading from '../common/Loading';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SubTitle from '../common/SubTitle';
import DashboardFilter from './DashboardFilter';
import DashboardFilterSummary from './DashboardFilterSummary';
import View from '../common/View';
import Tabs from '../common/Tabs';
import { render } from "react-dom";
import { Chart } from "react-google-charts";
import NoRowsMessage from '../common/NoRowsMessage';
import PageLayout from '../layout/PageLayout';
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';

export interface IDashboardProps {
    venueId: number;
    securityFirmId: number;
    isLoading: boolean;
    filter: AusComplyDtos.DashboardFilter;
    data: AusComplyDtos.ChartDetails[];
    isLoadingIncidentType: boolean;
    incidentTypes: any[];
    incidentTypesColors: string[];
    isLoadingIncidentCategories: boolean;
    incidentCategories: any[];
    incidentCategoriesGridLinesCount: number;
    isLoadingIncidentLocations: boolean;
    incidentLocations: any[];
    incidentLocationsGridLinesCount: number;
    isLoadingIncidentRefuseEntryReasons: boolean;
    incidentRefuseEntryReasons: any[];
    incidentRefuseEntryReasonsGridLinesCount: number;
    isLoadingIncidentAskedToLeave: boolean;
    incidentAskedToLeave: any[];
    incidentAskedToLeaveGridLinesCount: number;
    isLoadingIncidentWatchlists: boolean;
    incidentWatchlists: any[];
    incidentWatchlistsGridLinesCount: number;
    isLoadingPatronDemographics: boolean;
    patronDemographics: any[];
    isLoadingPatronGender: boolean;
    patronGender: any[];
    isLoadingPatronRace: boolean;
    patronRace: any[];
    isLoadingStaffEntered: boolean;
    staffEntered: any[];
    staffEnteredGridLinesCount: number;
    isLoadingManagersApproved: boolean;
    managersApproved: any[];
    managersApprovedGridLinesCount: number;
    canViewChecklists: boolean;
    isLoadingChecklistTemplate: boolean;
    checklistTemplates: any[];
    checklistTemplatesGridLinesCount: number;
    canViewGamblingIncidents: boolean;
    isLoadingRGOIncidentType: boolean;
    rgoIncidentTypes: any[];
    rgoIncidentTypesColors: string[];
    isLoadingRGOIncidentCategories: boolean;
    rgoIncidentCategories: any[];
    rgoIncidentCategoriesGridLinesCount: number;
    isLoadingRGOIncidentLocations: boolean;
    rgoIncidentLocations: any[];
    rgoIncidentLocationsGridLinesCount: number;
    onSetFilter: Function;
    onResetFilter: Function;
    onLoad: Function;
    onExport: Function;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

interface IDashboardState {
    selectedTab: number;
    filterExpanded: boolean;
    incidentTypes: any[];
    incidentCategories: any[];
    incidentCategoriesGridLinesCount: number;
    incidentLocations: any[];
    incidentLocationsGridLinesCount: number;
    incidentRefuseEntryReasons: any[];
    incidentRefuseEntryReasonsGridLinesCount: number;
    incidentAskedToLeave: any[];
    incidentAskedToLeaveGridLinesCount: number;
    incidentWatchlists: any[];
    incidentWatchlistsGridLinesCount: number;
    patronDemographics: any[];
    patronGender: any[];
    patronRace: any[];
    staffEntered: any[];
    staffEnteredGridLinesCount: number;
    managersApproved: any[];
    managersApprovedGridLinesCount: number;
}


class Dashboard extends Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props)

        this.state = {
            selectedTab: 0,
            filterExpanded: false,
            incidentTypes: [['Incident Type', 'Count']],
            incidentCategories: [['Incident Type', 'Count']],
            incidentCategoriesGridLinesCount: 5,
            incidentLocations: [['Incident Location', 'Count']],
            incidentLocationsGridLinesCount: 5,
            incidentRefuseEntryReasons: [['Reason', 'Count']],
            incidentRefuseEntryReasonsGridLinesCount: 5,
            incidentAskedToLeave: [['Reason', 'Count']],
            incidentAskedToLeaveGridLinesCount: 5,
            incidentWatchlists: [['Watchlist', 'Count']],
            incidentWatchlistsGridLinesCount: 5,
            patronDemographics: [],
            patronGender: [],
            patronRace: [],
            staffEntered: [],
            staffEnteredGridLinesCount: 5,
            managersApproved: [],
            managersApprovedGridLinesCount: 5

        };

        this.handleTabChange = this.handleTabChange.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onExport = this.onExport.bind(this);

    }

    componentDidMount() {
        if (this.props.filter.dashboardId == -1) {
            if (this.props.onResetFilter) {
                this.props.onResetFilter(this.props.venueId, this.props.securityFirmId);
            }
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onSearch(filter: AusComplyDtos.DashboardFilter) {
        this.props.onSetFilter(filter);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onResetFilter(this.props.venueId, this.props.securityFirmId);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            //this.props.onLoad();
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.DashboardFilter) {

    }

    getMaxValue(dataValues) {
        var _iMaxValue = -1;
        for (var i = 0; i < dataValues.length; i++) {
            if (dataValues[i].Total > _iMaxValue) {
                _iMaxValue = dataValues[i].Total;
            }
        }
        return _iMaxValue;
    }

    getGridlinesCount(dataValues) {
        var _iMaxValue = this.getMaxValue(dataValues);

        if (_iMaxValue <= 5) {
            return _iMaxValue + 1;
        } else {
            return 5;
        }
    }

    pieChartOptions(title: string, colors?: string[]) {
        let options: any = {
            //title: title,
            backgroundColor: '#2E2C2D',
            fontSize: '14',
            chartArea: {
                backgroundColor: '#000000'
            },
            legend: {
                position: 'labeled',
                labeledValueText: 'both',
                textStyle: {
                    color: '#ffffff'
                }
            },
            sliceVisibilityThreshold: 0,
            titleTextStyle: {
                color: '#ffffff'
            }
        };

        if (colors && colors.length > 0) {
            options = { ...options, colors: colors };
        }

        return options;
    }

    barChartOptions(title: string, gridLinesCount: number, count: number) {

        var chartAreaHeight = count * 30;
        var chartHeight = chartAreaHeight;

        return {
            legend: { position: 'none' },
            isStacked: true,
            annotations: {
                alwaysOutside: true,
            },
            backgroundColor: '#2E2C2D',
            chartArea: {
                left: '40%',
                width: '55%',
                backgroundColor: '#000000',
                height: chartAreaHeight
            },
            colors: ['#818183'],
            fontSize: '14',
            height: chartHeight,
            hAxis: {
                format: '#',
                minValue: 0,
                gridlines: {
                    count: gridLinesCount,
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            titleTextStyle: {
                color: '#ffffff'
            },
            vAxis: {
                gridlines: {
                    color: '#fefefe'
                },
                textStyle: {
                    color: '#ffffff',
                    fontSize: '14'
                },
                titleTextStyle: {
                    color: '#ffffff'
                }
            },
            animation: {
                duration: 1200,
                easing: 'out',
                startup: true
            },
            
        };
    }

    renderBarChartWithoutHeader(name: string, isLoading: boolean, data: any[], gridLinesCount: number) {
        let chartContent;
        if (isLoading) {
            chartContent = (
                <Loading />
            );
        } else if (data && data.length > 1) {
            chartContent = (
                <div className={name.replace(" ", "") + "Chart-container"}>
                    <Chart
                        options={this.barChartOptions(name, gridLinesCount, data.length)}
                        chartType="BarChart"
                        data={data}
                        legendToggle
                    />
                </div>
            );
        } else {
            chartContent = (
                <NoRowsMessage message={"No data found"} />
            );
        }
        return (
            <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                {chartContent}
            </View>
        );
    }

    renderBarChart(name: string, isLoading: boolean, data: any[], gridLinesCount: number) {
        let chartContent;
        if (isLoading) {
            chartContent = (
                <Loading />
            );
        } else if (data && data.length > 1) {
            chartContent = (
                <div className={name.replace(" ", "") + "Chart-container"}>
                    <Chart
                        options={this.barChartOptions(name, gridLinesCount, data.length)}
                        chartType="BarChart"
                        data={data}
                        legendToggle
                    />
                </div>
            );
        } else {
            chartContent = (
                <NoRowsMessage message={"No data found"} />
            );
        }
        return (
            <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                <SubTitle text={name} />
                {chartContent}
            </View>
        );
    }

    renderPieChart(name: string, isLoading: boolean, data: any[], colors?: string[]) {
        let chartContent;
        if (isLoading) {
            chartContent = (
                <Loading />
            );
        } else if (data && data.length > 1) {
            chartContent = (
                <div className={name.replace(" ", "") + "Chart-container"}>
                    <Chart
                        options={this.pieChartOptions(name, colors)}
                        chartType="PieChart"
                        data={data}
                        width="100%"
                        height="320px"
                        legendToggle
                    />
                </div>
            );
        } else {
            chartContent = (
                <NoRowsMessage message={"No data found"} />
            );
        }
        return (
            <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                <SubTitle text={name} />
                {chartContent}
            </View>
        );
    }

    onExport() {
        if (this.props.onExport) {
            this.props.onExport();
        }
    }

    renderChart(title: string, isLoading: boolean, count: number, chart: Function) {
        let chartContent;
        if (isLoading) {
            chartContent = (
                <Loading />
            );
        } else if (count > 1) {
            chartContent = chart();
        } else {
            chartContent = (
                <NoRowsMessage message={"No data found"} />
            );
        }
        return (
            <View style={{ backgroundColor: '#2E2C2D', padding: '10px' }}>
                <SubTitle text={title} />
                {chartContent}
            </View>
        );
    }

    getTabs() {
        let tabs = ["Incident Analysis"];
        if (this.props.canViewGamblingIncidents)
        {
            tabs.push("Gambling Analysis")
        }
        tabs.push("Patron Details");
        tabs.push("Staff Analysis");
        if (this.props.canViewChecklists)
        {
            tabs.push("Checklist Analysis")
        }
        return tabs;
    }

    tabIsSelected(name: string) {
        const tabs = this.getTabs();
        if (tabs.length > this.state.selectedTab) {
            return name == tabs[this.state.selectedTab];
        }
        return false;
    }

    render() {

        //let filter;
        let filterSummary;
        //if (this.state.filterExpanded) {
            //filter = ;
        //} else 
        if (this.props.isLoadingIncidentType) {
            let tempFilter = new AusComplyDtos.DashboardFilter();
            tempFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <DashboardFilterSummary filter={tempFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <DashboardFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let exportData;
        if (!this.props.isLoading) {
            exportData = <TransparentButton text={"Export"} onClick={() => this.onExport()} />
        }

        const tabs = this.getTabs();

        return (
            <PageLayout
                headerText={"Dashboard"}
                footerLeftContent={exportData}
            >
                {filterSummary}
                <View style={{ marginTop: '10px' }}>
                    <Tabs selected={this.state.selectedTab} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
                </View>
                {this.tabIsSelected("Incident Analysis") && (
                    <>
                        {this.renderPieChart("Incident Type", this.props.isLoadingIncidentType, this.props.incidentTypes, this.props.incidentTypesColors)}
                        {this.renderBarChart("Incident Category", this.props.isLoadingIncidentCategories, this.props.incidentCategories, this.props.incidentCategoriesGridLinesCount)}
                        {this.renderBarChart("Incident Locations", this.props.isLoadingIncidentLocations, this.props.incidentLocations, this.props.incidentLocationsGridLinesCount)}
                        {this.renderBarChart("Refuse Entry - Reasons", this.props.isLoadingIncidentRefuseEntryReasons, this.props.incidentRefuseEntryReasons, this.props.incidentRefuseEntryReasonsGridLinesCount)}
                        {this.renderBarChart("Asked to Leave - Reasons", this.props.isLoadingIncidentAskedToLeave, this.props.incidentAskedToLeave, this.props.incidentAskedToLeaveGridLinesCount)}
                        {this.renderBarChart("Incident Event Watchlists", this.props.isLoadingIncidentWatchlists, this.props.incidentWatchlists, this.props.incidentWatchlistsGridLinesCount)}
                    </>
                )}
                {this.tabIsSelected("Gambling Analysis") && (
                    <>
                        {this.renderPieChart("Incident Type", this.props.isLoadingRGOIncidentType, this.props.rgoIncidentTypes, this.props.rgoIncidentTypesColors)}
                        {this.renderBarChart("Incident Category", this.props.isLoadingRGOIncidentCategories, this.props.rgoIncidentCategories, this.props.rgoIncidentCategoriesGridLinesCount)}
                        {this.renderBarChart("Incident Locations", this.props.isLoadingRGOIncidentLocations, this.props.rgoIncidentLocations, this.props.rgoIncidentLocationsGridLinesCount)}
                    </>
                )}
                {this.tabIsSelected("Patron Details") && (
                    <>
                        {this.renderPieChart("Involved Person Demographic", this.props.isLoadingPatronDemographics, this.props.patronDemographics)}
                        {this.renderPieChart("Involved Person Gender", this.props.isLoadingPatronGender, this.props.patronGender)}
                        {this.renderPieChart("Involved Person Racial Appearance", this.props.isLoadingPatronRace, this.props.patronRace)}
                    </>
                )}
                {this.tabIsSelected("Staff Analysis") && (
                    <>
                        {this.renderBarChart("Incident Entered By", this.props.isLoadingStaffEntered, this.props.staffEntered, this.props.staffEnteredGridLinesCount)}
                        {this.renderBarChart("Approving Manager Details", this.props.isLoadingManagersApproved, this.props.managersApproved, this.props.managersApprovedGridLinesCount)}
                    </>
                )}
                {this.tabIsSelected("Checklist Analysis") && (
                    <>
                        {this.renderPieChart("Checklists", this.props.isLoadingChecklistTemplate, this.props.checklistTemplates)}
                        {this.renderBarChartWithoutHeader("Checklists", this.props.isLoadingChecklistTemplate, this.props.checklistTemplates, this.props.checklistTemplatesGridLinesCount)}
                    </>
                )}
                <DashboardFilter
                    open={this.state.filterExpanded}
                    filter={this.props.filter}
                    onUpdateFilter={filter => this.onUpdateFilter(filter)}
                    onSearch={this.onSearch}
                    onClose={() => this.setState({filterExpanded: false})}
                    onReset={() => this.onResetSearch()} />
                
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(Dashboard))