import React, { Component, forwardRef } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Progress from '../../controls/Progress';
import { withRouter } from "react-router";
import PageTitle from '../../common/PageTitle';
import ButtonLink from '../../controls/ButtonLink';
import FixedFooter from '../../layout/FixedFooter';
import RegisterVenueNavigation from './RegisterVenueNavigation';
import RegisterVenueDetails from './RegisterVenueDetails';
import RegisterVenueAddress from './RegisterVenueAddress';
import RegisterVenueComplianceDocuments from './RegisterVenueComplianceDocuments';
import RegisterVenueDocuments from './RegisterVenueDocuments';
import RegisterVenueType from './RegisterVenueType';
import RegisterVenueContact from './RegisterVenueContact';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Error from '../../common/Error';
import { Link } from 'react-router-dom';
import { ProgressItem } from '../../controls/Progress';
import PageLayout from '../../layout/PageLayout';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IRegisterVenueProps {
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    errors: any;
    venue: AusComplyDTOs.Venue;
    venueContact: AusComplyDTOs.VenueContact;
    licenseContact: AusComplyDTOs.VenueContact;
    states: AusComplyDTOs.State[],
    industryCategories: AusComplyDTOs.IndustryCategory[],
    precincts: AusComplyDTOs.VenuePrecinct[],
    licenceTypes: AusComplyDTOs.VenueLicenceType[],
    tradingHours: AusComplyDTOs.VenueTradingHours[],
    otherDocuments: AusComplyDTOs.File[],
    otherDocumentTypes: AusComplyDTOs.DocumentType[],
    complianceDocuments: AusComplyDTOs.File[],
    complianceDocumentTypes: AusComplyDTOs.DocumentType[],
    onReset: Function;
    onInitVenue: Function;
    onSave: Function;
    onUpdate: Function;
    onUpdateOtherDocuments: Function;
    onUpdateComplianceDocuments: Function;
    onErrorNotification: Function;
    step: number;
    onSetStep: Function;
    history: any;
    location: any;
}

interface IRegisterVenueState {
    canSubmit: boolean;
    validSubmit: boolean;
}

const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/register/venue/privacypolicy" {...props} />);

const TermsLink = forwardRef((props, ref) => <Link to="/register/venue/terms" {...props} />);

class RegisterVenue extends Component<IRegisterVenueProps, IRegisterVenueState> {

    constructor(props: IRegisterVenueProps) {
        super(props)

        this.state = {
            canSubmit: true,
            validSubmit: true
        }

        this.onBack = this.onBack.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onUpdateVenueContact = this.onUpdateVenueContact.bind(this);
        this.onUpdateLicenseContact = this.onUpdateLicenseContact.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onStep = this.onStep.bind(this);
        this.canNext = this.canNext.bind(this);
        this.canBack = this.canBack.bind(this);
        this.onUpdateOtherDocuments = this.onUpdateOtherDocuments.bind(this);
        this.onUpdateComplianceDocuments = this.onUpdateComplianceDocuments.bind(this);
    }

    componentDidMount() {
        if (this.props.venue.venueId == -1) {
            this.props.onInitVenue();
        }
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                if (this.props.onReset) {
                    this.props.onReset();
                }
                this.props.history.push('/register/venue/success');
            }
        }

        // eslint-disable-next-line

        if (this.props.isLoading && !this.props.venue.venueId != preProps.venue.venueId) {
            // set the step to 1
        }
    }

    onBack() {
        if (this.canBack()) {
            this.props.onSetStep(this.props.step - 1);
        }
    }

    onNext() {
        if (this.canNext()) {
            this.props.onSetStep(this.props.step + 1);
        }
    }

    canNext() {
        let canNext = true;
        return canNext;
    }

    canBack() {
        let canBack = true;
        return canBack;
    }

    onStep(progressItem: ProgressItem) {
        if (this.canNext() && this.canBack()) {
            this.props.onSetStep(progressItem.step);
        }
    }



    onCancel() {
        // cancel and navigate to home
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.props.history.push('/');
    }

    onSubmit() {
        this.props.onSave();
    }

    onUpdate(venue) {
        this.props.onUpdate(venue, this.props.venueContact, this.props.licenseContact);
    }

    onUpdateVenueContact(venueContact) {
        this.props.onUpdate(this.props.venue, venueContact, this.props.licenseContact);
    }

    onUpdateLicenseContact(licenseContact) {
        this.props.onUpdate(this.props.venue, this.props.venueContact, licenseContact);
    }

    onUpdateOtherDocuments(otherDocuments) {
        this.props.onUpdateOtherDocuments(otherDocuments);
    }

    onUpdateComplianceDocuments(complianceDocuments) {
        this.props.onUpdateComplianceDocuments(complianceDocuments);
    }
    render() {
        let progressItems: ProgressItem[] = [];
        let stepDisplays = [
            <>
                <RegisterVenueDetails venue={this.props.venue} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} />
                <ButtonLink text="Privacy Policy" small={false} component={PrivacyPolicyLink} />
                <ButtonLink text="Terms of Use" small={false} component={TermsLink} />
            </>,
            <>
                <RegisterVenueAddress venue={this.props.venue} states={this.props.states} canMoveMarker={true} errors={this.props.errors} onUpdate={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>,
            <>
                <RegisterVenueContact
                    venue={this.props.venue}
                    venueContact={this.props.venueContact}
                    licenseContact={this.props.licenseContact}
                    errors={this.props.errors}
                    onUpdate={this.onUpdate}
                    onUpdateVenueContact={this.onUpdateVenueContact}
                    onUpdateLicenseContact={this.onUpdateLicenseContact}
                    onSwipedLeft={this.onNext}
                    onSwipedRight={this.onBack} />
            </>,
            <>
                <RegisterVenueType
                    venue={this.props.venue}
                    industryCategories={this.props.industryCategories}
                    precincts={this.props.precincts}
                    licenceTypes={this.props.licenceTypes}
                    tradingHours={this.props.tradingHours}
                    canEditVenueIndustryCategory={true}
                    errors={this.props.errors}
                    onUpdate={this.onUpdate}
                    onSwipedLeft={this.onNext}
                    onSwipedRight={this.onBack} />
            </>,
            <>
                <RegisterVenueComplianceDocuments venue={this.props.venue} documents={this.props.complianceDocuments} documentTypes={this.props.complianceDocumentTypes} onUpdate={this.onUpdateComplianceDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <RegisterVenueDocuments venue={this.props.venue} documents={this.props.otherDocuments} documentTypes={this.props.otherDocumentTypes} onUpdate={this.onUpdateOtherDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>
        ];
        progressItems.push({ step: progressItems.length + 1, key: "details", name: "Details" });
        progressItems.push({ step: progressItems.length + 1, key: "address", name: "Address" });
        progressItems.push({ step: progressItems.length + 1, key: "contact", name: "Contact" });
        progressItems.push({ step: progressItems.length + 1, key: "industry", name: "Industry" });
        progressItems.push({ step: progressItems.length + 1, key: "documents", name: "Documents" });

        let stepDisplay = stepDisplays[this.props.step - 1];

        if (this.props.isLoading) {
            return (
                <>
                    <PageTitle title="Register Venue" />
                    <Loading message={"Initialising"} onCancel={this.onCancel} />
                </>
            );
        }

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title="Registering Venue" />
                    <Saving />
                </>
            );
        }

        return (
            <PageLayout
                headerText={"Register Venue"}
                footerContent={
                    <RegisterVenueNavigation
                        step={this.props.step}
                        steps={stepDisplays.length}
                        onNext={this.onNext}
                        onBack={this.onBack}
                        onCancel={this.onCancel}
                        onSubmit={this.onSubmit}
                        canSubmit={this.props.errors.count == 0}
                        canNext={this.canNext()}
                    />
                }
                aboveFooterContent={<>
                    {(this.props.step == stepDisplays.length && this.state.canSubmit) && (<>
                        <PrimaryButton text={"Register Venue"} onClick={this.onSubmit} />
                    </>)}
                </>}
            >
                <Progress
                    value={this.props.step}
                    items={progressItems}
                    showStep={true}
                    isError={this.props.errors.count > 0}
                    onClick={this.onStep} />

                {this.props.error && (
                    <Error message={this.props.error} />
                )}

                {stepDisplay}
            </PageLayout>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(RegisterVenue));