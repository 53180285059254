import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICheckboxControlGroupProps {
    theme: any;
    text?: string;
    label?: string;
    isReversed?: boolean;
    defaultValue: boolean;
    readonly?: boolean;
    onChanged?: Function;
    labelAbove?: boolean;
}

class CheckboxControlGroup extends React.PureComponent<ICheckboxControlGroupProps, any> {

    constructor(props: ICheckboxControlGroupProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.state = {
            value: false
        }
    }

    onChange = event => {
        let val = event.target.checked;
        this.setState({ value: val }, () => {
            if (this.props.onChanged) {
                if (this.props.isReversed) {
                    this.props.onChanged(!val);
                } else {
                    this.props.onChanged(val);
                }
            }
        });
    };

    componentDidMount() {
        if (typeof this.props.defaultValue !== 'undefined') {
            let value = this.props.defaultValue;
            if (this.props.isReversed) {
                value = !value;
            }
            this.setState({
                value
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.defaultValue !== 'undefined') {
            if (prevProps.defaultValue != this.props.defaultValue) {
                let value = this.props.defaultValue;
                if (this.props.isReversed) {
                    value = !value;
                }
                this.setState({ value })
            }
        }
    }


    render() {
        const { theme } = this.props;
        return (
            <ControlGroup text={this.props.label} labelAbove={this.props.labelAbove}>
                <FormControlLabel
                    disabled={this.props.readonly}
                    control={
                        <Checkbox
                            checked={this.state.value}
                            onChange={this.onChange}
                            color="primary"
                            icon={<CheckBoxOutlineBlankIcon fontSize="large" style={{ color: theme.palette.text.primary }} />}
                            checkedIcon={<CheckBoxIcon fontSize="large" style={{ color: theme.palette.text.primary }} />}
                        />
                    }
                    label={this.props.text}
                />
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CheckboxControlGroup);