import React from 'react';
import View from '../common/View';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import RosterFilterSources from './RosterFilterSources';
import TransparentButton from '../controls/TransparentButton';
import SimpleDateControlGroup from '../controls/SimpleDateControlGroup';
import FullPageDialog from '../layout/FullPageDialog';
import PrimaryButton from '../controls/PrimaryButton';

export interface IRosterFilterProps {
    open: boolean;
    filter: AusComplyDTOs.RosterFilter;
    onUpdateFilter: Function;
    onReset: Function;
    onSearch: Function;
    onClose: Function;
}

interface IRosterFilterState {
    filter: AusComplyDTOs.RosterFilter;
}

export default class RosterFilter extends React.Component<IRosterFilterProps, IRosterFilterState> {
    constructor(props: IRosterFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            filter: new AusComplyDTOs.RosterFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        return <FullPageDialog
            open={this.props.open}
            footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />}
            footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
            notFullWidth={true}
            title="Search" onDismissed={() => this.props.onClose()}>
            <View>
                <SimpleDateControlGroup
                    text="Time Period"
                    rightText={"From"}
                    labelAbove={true}
                    date={this.state.filter.from}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("from", value)} />
                <SimpleDateControlGroup
                    rightText="To"
                    labelAbove={true}
                    date={this.state.filter.to}
                    onEnter={() => this.props.onSearch(this.state.filter)}
                    onChange={(value) => this.onChange("to", value)} />
                <RosterFilterSources
                    filter={this.state.filter}
                    onUpdate={value => this.onChange("sources", value.sources)}
                 />
            </View>
        </FullPageDialog>
    }
}