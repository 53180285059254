import React, { Component } from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { AusComplyJsonServiceClient } from "../../common/services/AusComplyJsonServiceClient";
import Grid from '@material-ui/core/Grid';
import PageTitle from '../common/PageTitle';
import DefaultButton from '../controls/DefaultButton';
import Information from '../common/Information';
import TextControlGroup from '../controls/TextControlGroup';
import TimeZoneDateControlGroup from '../controls/TimeZoneDateControlGroup';
import ExternalApiConnectionContainer from '../../containers/ExternalApiConnectionContainer';


export default class Test extends Component<any, any> {
    constructor(props) {
        super(props)



        this.state = {
            numericValue: 0,
            textValue: "",
            timezoneData: this.getDefaultTimezone()
        }
        this.onTest = this.onTest.bind(this);
    }

    onTest() {
        var client = new AusComplyJsonServiceClient();
        var params = new AusComplyDTOs.PostTemporaryBase64File();
        params.base64ImageData = new AusComplyDTOs.Base64ImageData();
        params.base64ImageData.data = "iVBORw0KGgoAAAANSUhEUgAAAAYAAAAFEAIAAAC5qtbyAAAAyUlEQVR4nAC5AEb/BF9IlPHg1fth+vP3b/Ud4STBOPz483TqUw8KBzf62wpHHoI1rwMtQCa1ARbmKdIaut7w6OC7yDALAfr+5REvWyKOD2Bp+FhcNCME7xfad7ls+FTtjf9O/5n/zwZRFeAdwiHWISEi7hvQD7MXkRLDAwW9C1EdRP2AAeULvxOtExYaR/SR+3D/SgK1AlMBH+2F6l3oQgTohvEgAOIAAAAc/N/ja+td4xf9aP1V+8LDYcgr2PoCAgOy/2EBAAD//ynUWeereFhIAAAAAElFTkSuQmCC";
        params.base64ImageData.fileName = "image-9c2aaf05-2f47-4399-9118-4afeda7e0908.jpg";
        params.base64ImageData.fileType = "image/jpeg";


        client.post(params).then(response => {

        }, error => {

            console.error(error);
        });
    }

    render() {
        return (
            <>
                <PageTitle title={"Test page"}></PageTitle>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Information message={"This page is used by developers to test new functionality"} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <TimeZoneDateControlGroup
                            text={"Date"}
                            labelAbove={true}
                            futureOnly={true}
                            onChange={(value) => this.setState({timezoneData: value})}
                            date={this.state.timezoneData} />
                    </Grid>
                </Grid>
                
            </>
        );
    }

    /*
    <Grid container spacing={1}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultButton text={"Run test"} onClick={() => this.onTest()} />
                    </Grid>
                </Grid>
                */

    getDefaultTimezone() {
        let timezoneData = new AusComplyDTOs.TimeZoneDate();
        timezoneData.timeZones = [
            {
                "timezoneId": "Dateline Standard Time",
                "timezoneAbbreviation": "Dateline Standard Time",
                "timezoneName": "(UTC-12:00) International Date Line West",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC-11",
                "timezoneAbbreviation": "UTC-11",
                "timezoneName": "(UTC-11:00) Coordinated Universal Time-11",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Aleutian Standard Time",
                "timezoneAbbreviation": "Aleutian Standard Time",
                "timezoneName": "(UTC-10:00) Aleutian Islands",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Hawaiian Standard Time",
                "timezoneAbbreviation": "Hawaiian Standard Time",
                "timezoneName": "(UTC-10:00) Hawaii",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Marquesas Standard Time",
                "timezoneAbbreviation": "Marquesas Standard Time",
                "timezoneName": "(UTC-09:30) Marquesas Islands",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Alaskan Standard Time",
                "timezoneAbbreviation": "Alaskan Standard Time",
                "timezoneName": "(UTC-09:00) Alaska",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC-09",
                "timezoneAbbreviation": "UTC-09",
                "timezoneName": "(UTC-09:00) Coordinated Universal Time-09",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Pacific Standard Time (Mexico)",
                "timezoneAbbreviation": "Pacific Standard Time (Mexico)",
                "timezoneName": "(UTC-08:00) Baja California",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC-08",
                "timezoneAbbreviation": "UTC-08",
                "timezoneName": "(UTC-08:00) Coordinated Universal Time-08",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Pacific Standard Time",
                "timezoneAbbreviation": "Pacific Standard Time",
                "timezoneName": "(UTC-08:00) Pacific Time (US & Canada)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "US Mountain Standard Time",
                "timezoneAbbreviation": "US Mountain Standard Time",
                "timezoneName": "(UTC-07:00) Arizona",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Mountain Standard Time (Mexico)",
                "timezoneAbbreviation": "Mountain Standard Time (Mexico)",
                "timezoneName": "(UTC-07:00) La Paz, Mazatlan",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Mountain Standard Time",
                "timezoneAbbreviation": "Mountain Standard Time",
                "timezoneName": "(UTC-07:00) Mountain Time (US & Canada)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Yukon Standard Time",
                "timezoneAbbreviation": "Yukon Standard Time",
                "timezoneName": "(UTC-07:00) Yukon",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central America Standard Time",
                "timezoneAbbreviation": "Central America Standard Time",
                "timezoneName": "(UTC-06:00) Central America",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Standard Time",
                "timezoneAbbreviation": "Central Standard Time",
                "timezoneName": "(UTC-06:00) Central Time (US & Canada)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Easter Island Standard Time",
                "timezoneAbbreviation": "Easter Island Standard Time",
                "timezoneName": "(UTC-06:00) Easter Island",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Standard Time (Mexico)",
                "timezoneAbbreviation": "Central Standard Time (Mexico)",
                "timezoneName": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Canada Central Standard Time",
                "timezoneAbbreviation": "Canada Central Standard Time",
                "timezoneName": "(UTC-06:00) Saskatchewan",
                "defaultTimezone": false
            },
            {
                "timezoneId": "SA Pacific Standard Time",
                "timezoneAbbreviation": "SA Pacific Standard Time",
                "timezoneName": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Eastern Standard Time (Mexico)",
                "timezoneAbbreviation": "Eastern Standard Time (Mexico)",
                "timezoneName": "(UTC-05:00) Chetumal",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Eastern Standard Time",
                "timezoneAbbreviation": "Eastern Standard Time",
                "timezoneName": "(UTC-05:00) Eastern Time (US & Canada)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Haiti Standard Time",
                "timezoneAbbreviation": "Haiti Standard Time",
                "timezoneName": "(UTC-05:00) Haiti",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Cuba Standard Time",
                "timezoneAbbreviation": "Cuba Standard Time",
                "timezoneName": "(UTC-05:00) Havana",
                "defaultTimezone": false
            },
            {
                "timezoneId": "US Eastern Standard Time",
                "timezoneAbbreviation": "US Eastern Standard Time",
                "timezoneName": "(UTC-05:00) Indiana (East)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Turks And Caicos Standard Time",
                "timezoneAbbreviation": "Turks and Caicos Standard Time",
                "timezoneName": "(UTC-05:00) Turks and Caicos",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Paraguay Standard Time",
                "timezoneAbbreviation": "Paraguay Standard Time",
                "timezoneName": "(UTC-04:00) Asuncion",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Atlantic Standard Time",
                "timezoneAbbreviation": "Atlantic Standard Time",
                "timezoneName": "(UTC-04:00) Atlantic Time (Canada)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Venezuela Standard Time",
                "timezoneAbbreviation": "Venezuela Standard Time",
                "timezoneName": "(UTC-04:00) Caracas",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Brazilian Standard Time",
                "timezoneAbbreviation": "Central Brazilian Standard Time",
                "timezoneName": "(UTC-04:00) Cuiaba",
                "defaultTimezone": false
            },
            {
                "timezoneId": "SA Western Standard Time",
                "timezoneAbbreviation": "SA Western Standard Time",
                "timezoneName": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Pacific SA Standard Time",
                "timezoneAbbreviation": "Pacific SA Standard Time",
                "timezoneName": "(UTC-04:00) Santiago",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Newfoundland Standard Time",
                "timezoneAbbreviation": "Newfoundland Standard Time",
                "timezoneName": "(UTC-03:30) Newfoundland",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Tocantins Standard Time",
                "timezoneAbbreviation": "Tocantins Standard Time",
                "timezoneName": "(UTC-03:00) Araguaina",
                "defaultTimezone": false
            },
            {
                "timezoneId": "E. South America Standard Time",
                "timezoneAbbreviation": "E. South America Standard Time",
                "timezoneName": "(UTC-03:00) Brasilia",
                "defaultTimezone": false
            },
            {
                "timezoneId": "SA Eastern Standard Time",
                "timezoneAbbreviation": "SA Eastern Standard Time",
                "timezoneName": "(UTC-03:00) Cayenne, Fortaleza",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Argentina Standard Time",
                "timezoneAbbreviation": "Argentina Standard Time",
                "timezoneName": "(UTC-03:00) City of Buenos Aires",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Montevideo Standard Time",
                "timezoneAbbreviation": "Montevideo Standard Time",
                "timezoneName": "(UTC-03:00) Montevideo",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Magallanes Standard Time",
                "timezoneAbbreviation": "Magallanes Standard Time",
                "timezoneName": "(UTC-03:00) Punta Arenas",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Saint Pierre Standard Time",
                "timezoneAbbreviation": "Saint Pierre Standard Time",
                "timezoneName": "(UTC-03:00) Saint Pierre and Miquelon",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Bahia Standard Time",
                "timezoneAbbreviation": "Bahia Standard Time",
                "timezoneName": "(UTC-03:00) Salvador",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC-02",
                "timezoneAbbreviation": "UTC-02",
                "timezoneName": "(UTC-02:00) Coordinated Universal Time-02",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Greenland Standard Time",
                "timezoneAbbreviation": "Greenland Standard Time",
                "timezoneName": "(UTC-02:00) Greenland",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Mid-Atlantic Standard Time",
                "timezoneAbbreviation": "Mid-Atlantic Standard Time",
                "timezoneName": "(UTC-02:00) Mid-Atlantic - Old",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Azores Standard Time",
                "timezoneAbbreviation": "Azores Standard Time",
                "timezoneName": "(UTC-01:00) Azores",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Cape Verde Standard Time",
                "timezoneAbbreviation": "Cabo Verde Standard Time",
                "timezoneName": "(UTC-01:00) Cabo Verde Is.",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC",
                "timezoneAbbreviation": "Coordinated Universal Time",
                "timezoneName": "(UTC) Coordinated Universal Time",
                "defaultTimezone": false
            },
            {
                "timezoneId": "GMT Standard Time",
                "timezoneAbbreviation": "GMT Standard Time",
                "timezoneName": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Greenwich Standard Time",
                "timezoneAbbreviation": "Greenwich Standard Time",
                "timezoneName": "(UTC+00:00) Monrovia, Reykjavik",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Sao Tome Standard Time",
                "timezoneAbbreviation": "Sao Tome Standard Time",
                "timezoneName": "(UTC+00:00) Sao Tome",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Morocco Standard Time",
                "timezoneAbbreviation": "Morocco Standard Time",
                "timezoneName": "(UTC+01:00) Casablanca",
                "defaultTimezone": false
            },
            {
                "timezoneId": "W. Europe Standard Time",
                "timezoneAbbreviation": "W. Europe Standard Time",
                "timezoneName": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Europe Standard Time",
                "timezoneAbbreviation": "Central Europe Standard Time",
                "timezoneName": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Romance Standard Time",
                "timezoneAbbreviation": "Romance Standard Time",
                "timezoneName": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central European Standard Time",
                "timezoneAbbreviation": "Central European Standard Time",
                "timezoneName": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
                "defaultTimezone": false
            },
            {
                "timezoneId": "W. Central Africa Standard Time",
                "timezoneAbbreviation": "W. Central Africa Standard Time",
                "timezoneName": "(UTC+01:00) West Central Africa",
                "defaultTimezone": false
            },
            {
                "timezoneId": "GTB Standard Time",
                "timezoneAbbreviation": "GTB Standard Time",
                "timezoneName": "(UTC+02:00) Athens, Bucharest",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Middle East Standard Time",
                "timezoneAbbreviation": "Middle East Standard Time",
                "timezoneName": "(UTC+02:00) Beirut",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Egypt Standard Time",
                "timezoneAbbreviation": "Egypt Standard Time",
                "timezoneName": "(UTC+02:00) Cairo",
                "defaultTimezone": false
            },
            {
                "timezoneId": "E. Europe Standard Time",
                "timezoneAbbreviation": "E. Europe Standard Time",
                "timezoneName": "(UTC+02:00) Chisinau",
                "defaultTimezone": false
            },
            {
                "timezoneId": "West Bank Standard Time",
                "timezoneAbbreviation": "West Bank Gaza Standard Time",
                "timezoneName": "(UTC+02:00) Gaza, Hebron",
                "defaultTimezone": false
            },
            {
                "timezoneId": "South Africa Standard Time",
                "timezoneAbbreviation": "South Africa Standard Time",
                "timezoneName": "(UTC+02:00) Harare, Pretoria",
                "defaultTimezone": false
            },
            {
                "timezoneId": "FLE Standard Time",
                "timezoneAbbreviation": "FLE Standard Time",
                "timezoneName": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Israel Standard Time",
                "timezoneAbbreviation": "Jerusalem Standard Time",
                "timezoneName": "(UTC+02:00) Jerusalem",
                "defaultTimezone": false
            },
            {
                "timezoneId": "South Sudan Standard Time",
                "timezoneAbbreviation": "South Sudan Standard Time",
                "timezoneName": "(UTC+02:00) Juba",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Kaliningrad Standard Time",
                "timezoneAbbreviation": "Russia TZ 1 Standard Time",
                "timezoneName": "(UTC+02:00) Kaliningrad",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Sudan Standard Time",
                "timezoneAbbreviation": "Sudan Standard Time",
                "timezoneName": "(UTC+02:00) Khartoum",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Libya Standard Time",
                "timezoneAbbreviation": "Libya Standard Time",
                "timezoneName": "(UTC+02:00) Tripoli",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Namibia Standard Time",
                "timezoneAbbreviation": "Namibia Standard Time",
                "timezoneName": "(UTC+02:00) Windhoek",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Jordan Standard Time",
                "timezoneAbbreviation": "Jordan Standard Time",
                "timezoneName": "(UTC+03:00) Amman",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Arabic Standard Time",
                "timezoneAbbreviation": "Arabic Standard Time",
                "timezoneName": "(UTC+03:00) Baghdad",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Syria Standard Time",
                "timezoneAbbreviation": "Syria Standard Time",
                "timezoneName": "(UTC+03:00) Damascus",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Turkey Standard Time",
                "timezoneAbbreviation": "Turkey Standard Time",
                "timezoneName": "(UTC+03:00) Istanbul",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Arab Standard Time",
                "timezoneAbbreviation": "Arab Standard Time",
                "timezoneName": "(UTC+03:00) Kuwait, Riyadh",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Belarus Standard Time",
                "timezoneAbbreviation": "Belarus Standard Time",
                "timezoneName": "(UTC+03:00) Minsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Russian Standard Time",
                "timezoneAbbreviation": "Russia TZ 2 Standard Time",
                "timezoneName": "(UTC+03:00) Moscow, St. Petersburg",
                "defaultTimezone": false
            },
            {
                "timezoneId": "E. Africa Standard Time",
                "timezoneAbbreviation": "E. Africa Standard Time",
                "timezoneName": "(UTC+03:00) Nairobi",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Volgograd Standard Time",
                "timezoneAbbreviation": "Volgograd Standard Time",
                "timezoneName": "(UTC+03:00) Volgograd",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Iran Standard Time",
                "timezoneAbbreviation": "Iran Standard Time",
                "timezoneName": "(UTC+03:30) Tehran",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Arabian Standard Time",
                "timezoneAbbreviation": "Arabian Standard Time",
                "timezoneName": "(UTC+04:00) Abu Dhabi, Muscat",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Astrakhan Standard Time",
                "timezoneAbbreviation": "Astrakhan Standard Time",
                "timezoneName": "(UTC+04:00) Astrakhan, Ulyanovsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Azerbaijan Standard Time",
                "timezoneAbbreviation": "Azerbaijan Standard Time",
                "timezoneName": "(UTC+04:00) Baku",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Russia Time Zone 3",
                "timezoneAbbreviation": "Russia TZ 3 Standard Time",
                "timezoneName": "(UTC+04:00) Izhevsk, Samara",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Mauritius Standard Time",
                "timezoneAbbreviation": "Mauritius Standard Time",
                "timezoneName": "(UTC+04:00) Port Louis",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Saratov Standard Time",
                "timezoneAbbreviation": "Saratov Standard Time",
                "timezoneName": "(UTC+04:00) Saratov",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Georgian Standard Time",
                "timezoneAbbreviation": "Georgian Standard Time",
                "timezoneName": "(UTC+04:00) Tbilisi",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Caucasus Standard Time",
                "timezoneAbbreviation": "Caucasus Standard Time",
                "timezoneName": "(UTC+04:00) Yerevan",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Afghanistan Standard Time",
                "timezoneAbbreviation": "Afghanistan Standard Time",
                "timezoneName": "(UTC+04:30) Kabul",
                "defaultTimezone": false
            },
            {
                "timezoneId": "West Asia Standard Time",
                "timezoneAbbreviation": "West Asia Standard Time",
                "timezoneName": "(UTC+05:00) Ashgabat, Tashkent",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Qyzylorda Standard Time",
                "timezoneAbbreviation": "Qyzylorda Standard Time",
                "timezoneName": "(UTC+05:00) Astana",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Ekaterinburg Standard Time",
                "timezoneAbbreviation": "Russia TZ 4 Standard Time",
                "timezoneName": "(UTC+05:00) Ekaterinburg",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Pakistan Standard Time",
                "timezoneAbbreviation": "Pakistan Standard Time",
                "timezoneName": "(UTC+05:00) Islamabad, Karachi",
                "defaultTimezone": false
            },
            {
                "timezoneId": "India Standard Time",
                "timezoneAbbreviation": "India Standard Time",
                "timezoneName": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Sri Lanka Standard Time",
                "timezoneAbbreviation": "Sri Lanka Standard Time",
                "timezoneName": "(UTC+05:30) Sri Jayawardenepura",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Nepal Standard Time",
                "timezoneAbbreviation": "Nepal Standard Time",
                "timezoneName": "(UTC+05:45) Kathmandu",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Asia Standard Time",
                "timezoneAbbreviation": "Central Asia Standard Time",
                "timezoneName": "(UTC+06:00) Bishkek",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Bangladesh Standard Time",
                "timezoneAbbreviation": "Bangladesh Standard Time",
                "timezoneName": "(UTC+06:00) Dhaka",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Omsk Standard Time",
                "timezoneAbbreviation": "Omsk Standard Time",
                "timezoneName": "(UTC+06:00) Omsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Myanmar Standard Time",
                "timezoneAbbreviation": "Myanmar Standard Time",
                "timezoneName": "(UTC+06:30) Yangon (Rangoon)",
                "defaultTimezone": false
            },
            {
                "timezoneId": "SE Asia Standard Time",
                "timezoneAbbreviation": "SE Asia Standard Time",
                "timezoneName": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Altai Standard Time",
                "timezoneAbbreviation": "Altai Standard Time",
                "timezoneName": "(UTC+07:00) Barnaul, Gorno-Altaysk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "W. Mongolia Standard Time",
                "timezoneAbbreviation": "W. Mongolia Standard Time",
                "timezoneName": "(UTC+07:00) Hovd",
                "defaultTimezone": false
            },
            {
                "timezoneId": "North Asia Standard Time",
                "timezoneAbbreviation": "Russia TZ 6 Standard Time",
                "timezoneName": "(UTC+07:00) Krasnoyarsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "N. Central Asia Standard Time",
                "timezoneAbbreviation": "Novosibirsk Standard Time",
                "timezoneName": "(UTC+07:00) Novosibirsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Tomsk Standard Time",
                "timezoneAbbreviation": "Tomsk Standard Time",
                "timezoneName": "(UTC+07:00) Tomsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "China Standard Time",
                "timezoneAbbreviation": "China Standard Time",
                "timezoneName": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
                "defaultTimezone": false
            },
            {
                "timezoneId": "North Asia East Standard Time",
                "timezoneAbbreviation": "Russia TZ 7 Standard Time",
                "timezoneName": "(UTC+08:00) Irkutsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Singapore Standard Time",
                "timezoneAbbreviation": "Malay Peninsula Standard Time",
                "timezoneName": "(UTC+08:00) Kuala Lumpur, Singapore",
                "defaultTimezone": false
            },
            {
                "timezoneId": "W. Australia Standard Time",
                "timezoneAbbreviation": "W. Australia Standard Time",
                "timezoneName": "(UTC+08:00) Perth",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Taipei Standard Time",
                "timezoneAbbreviation": "Taipei Standard Time",
                "timezoneName": "(UTC+08:00) Taipei",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Ulaanbaatar Standard Time",
                "timezoneAbbreviation": "Ulaanbaatar Standard Time",
                "timezoneName": "(UTC+08:00) Ulaanbaatar",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Aus Central W. Standard Time",
                "timezoneAbbreviation": "Aus Central W. Standard Time",
                "timezoneName": "(UTC+08:45) Eucla",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Transbaikal Standard Time",
                "timezoneAbbreviation": "Transbaikal Standard Time",
                "timezoneName": "(UTC+09:00) Chita",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Tokyo Standard Time",
                "timezoneAbbreviation": "Tokyo Standard Time",
                "timezoneName": "(UTC+09:00) Osaka, Sapporo, Tokyo",
                "defaultTimezone": false
            },
            {
                "timezoneId": "North Korea Standard Time",
                "timezoneAbbreviation": "North Korea Standard Time",
                "timezoneName": "(UTC+09:00) Pyongyang",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Korea Standard Time",
                "timezoneAbbreviation": "Korea Standard Time",
                "timezoneName": "(UTC+09:00) Seoul",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Yakutsk Standard Time",
                "timezoneAbbreviation": "Russia TZ 8 Standard Time",
                "timezoneName": "(UTC+09:00) Yakutsk",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Cen. Australia Standard Time",
                "timezoneAbbreviation": "Cen. Australia Standard Time",
                "timezoneName": "(UTC+09:30) Adelaide",
                "defaultTimezone": false
            },
            {
                "timezoneId": "AUS Central Standard Time",
                "timezoneAbbreviation": "AUS Central Standard Time",
                "timezoneName": "(UTC+09:30) Darwin",
                "defaultTimezone": false
            },
            {
                "timezoneId": "E. Australia Standard Time",
                "timezoneAbbreviation": "E. Australia Standard Time",
                "timezoneName": "(UTC+10:00) Brisbane",
                "defaultTimezone": false
            },
            {
                "timezoneId": "AUS Eastern Standard Time",
                "timezoneAbbreviation": "AUS Eastern Standard Time",
                "timezoneName": "(UTC+10:00) Canberra, Melbourne, Sydney",
                "defaultTimezone": true
            },
            {
                "timezoneId": "West Pacific Standard Time",
                "timezoneAbbreviation": "West Pacific Standard Time",
                "timezoneName": "(UTC+10:00) Guam, Port Moresby",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Tasmania Standard Time",
                "timezoneAbbreviation": "Tasmania Standard Time",
                "timezoneName": "(UTC+10:00) Hobart",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Vladivostok Standard Time",
                "timezoneAbbreviation": "Russia TZ 9 Standard Time",
                "timezoneName": "(UTC+10:00) Vladivostok",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Lord Howe Standard Time",
                "timezoneAbbreviation": "Lord Howe Standard Time",
                "timezoneName": "(UTC+10:30) Lord Howe Island",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Bougainville Standard Time",
                "timezoneAbbreviation": "Bougainville Standard Time",
                "timezoneName": "(UTC+11:00) Bougainville Island",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Russia Time Zone 10",
                "timezoneAbbreviation": "Russia TZ 10 Standard Time",
                "timezoneName": "(UTC+11:00) Chokurdakh",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Magadan Standard Time",
                "timezoneAbbreviation": "Magadan Standard Time",
                "timezoneName": "(UTC+11:00) Magadan",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Norfolk Standard Time",
                "timezoneAbbreviation": "Norfolk Standard Time",
                "timezoneName": "(UTC+11:00) Norfolk Island",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Sakhalin Standard Time",
                "timezoneAbbreviation": "Sakhalin Standard Time",
                "timezoneName": "(UTC+11:00) Sakhalin",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Central Pacific Standard Time",
                "timezoneAbbreviation": "Central Pacific Standard Time",
                "timezoneName": "(UTC+11:00) Solomon Is., New Caledonia",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Russia Time Zone 11",
                "timezoneAbbreviation": "Russia TZ 11 Standard Time",
                "timezoneName": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
                "defaultTimezone": false
            },
            {
                "timezoneId": "New Zealand Standard Time",
                "timezoneAbbreviation": "New Zealand Standard Time",
                "timezoneName": "(UTC+12:00) Auckland, Wellington",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC+12",
                "timezoneAbbreviation": "UTC+12",
                "timezoneName": "(UTC+12:00) Coordinated Universal Time+12",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Fiji Standard Time",
                "timezoneAbbreviation": "Fiji Standard Time",
                "timezoneName": "(UTC+12:00) Fiji",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Kamchatka Standard Time",
                "timezoneAbbreviation": "Kamchatka Standard Time",
                "timezoneName": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Chatham Islands Standard Time",
                "timezoneAbbreviation": "Chatham Islands Standard Time",
                "timezoneName": "(UTC+12:45) Chatham Islands",
                "defaultTimezone": false
            },
            {
                "timezoneId": "UTC+13",
                "timezoneAbbreviation": "UTC+13",
                "timezoneName": "(UTC+13:00) Coordinated Universal Time+13",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Tonga Standard Time",
                "timezoneAbbreviation": "Tonga Standard Time",
                "timezoneName": "(UTC+13:00) Nuku'alofa",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Samoa Standard Time",
                "timezoneAbbreviation": "Samoa Standard Time",
                "timezoneName": "(UTC+13:00) Samoa",
                "defaultTimezone": false
            },
            {
                "timezoneId": "Line Islands Standard Time",
                "timezoneAbbreviation": "Line Islands Standard Time",
                "timezoneName": "(UTC+14:00) Kiritimati Island",
                "defaultTimezone": false
            }
        ];

        timezoneData.isEmpty = false;
        timezoneData.timeZone = "AUS Eastern Standard Time";
        timezoneData.year = 2024;
        timezoneData.month = 5;
        timezoneData.day = 20;
        timezoneData.hour = 11;
        timezoneData.minute = 0;
        timezoneData.display = "20/5/2024";
        timezoneData.displayShortDate = "20/05/2024 11:00 AM";
        timezoneData.timeZoneDisplay = "(UTC+10:00) Canberra, Melbourne, Sydney";

        return timezoneData;
    }
}