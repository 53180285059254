import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IRegisterSecurityFirmAddressProps {
    securityFirm: AusComplyDTOs.SecurityFirm;
    errors: any;
    states: AusComplyDTOs.State[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterSecurityFirmAddress extends Component<IRegisterSecurityFirmAddressProps, any> {
    constructor(props: IRegisterSecurityFirmAddressProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(fieldname, value) {
        let securityFirm = { ...this.props.securityFirm };
        securityFirm[fieldname] = value;
        this.props.onUpdate(securityFirm);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Address"}>
                    <CardContent>
                        <LayoutForm>
                            <TextControlGroup
                                text={"Address"}
                                error={this.props.errors["address"]}
                                defaultValue={this.props.securityFirm.address}
                                onBlur={(value) => this.onChange("address", value)} />
                            <TextControlGroup
                                text={"Suburb"}
                                error={this.props.errors["suburb"]}
                                defaultValue={this.props.securityFirm.suburb}
                                onBlur={(value) => this.onChange("suburb", value)} />
                            <SelectControlGroup
                                text="State"
                                error={this.props.errors["stateId"]}
                                onChange={(value) => this.onChange("stateId", value)}
                                defaultValue={this.props.securityFirm.stateId}>
                                <option value={0} />
                                {this.props.states && this.props.states.map((item, index) => (
                                    <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                ))}
                            </SelectControlGroup>
                            <TextControlGroup
                                text={"postcode"}
                                error={this.props.errors["postcode"]}
                                defaultValue={this.props.securityFirm.postcode}
                                onBlur={(value) => this.onChange("postcode", value)} />
                            
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}