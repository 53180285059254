import React from 'react';
import { styles } from '../../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorButton from '../../../../controls/ErrorButton';
import * as AusComplyDtos from "../../../../../common/dto/AusComply.dtos";

export interface ISecurityFirmDescendantRowProps {
    securityFirmDescendant: AusComplyDtos.SecurityFirmDescendant;
    onClick?: Function;
    onDelete?: Function;
    classes: any;
    theme: any;
}

class SecurityFirmDescendantRow extends React.Component<ISecurityFirmDescendantRowProps, any> {
    constructor(props: ISecurityFirmDescendantRowProps) {
        super(props)
        this.onDelete = this.onDelete.bind(this);
    }

    onDelete() {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.securityFirmDescendant)
        }
    }

    render() {
        const { classes } = this.props;
        let item = this.props.securityFirmDescendant;
        let className = classes.rowStatusPending;

        return (
            <tr className={classes.tableTr} key={"row-" + item.securityFirmDescendantID.toString()} >
                <td className={className} ></td>
                <td>
                    <p>
                        <b>{item.descendantSecurityFirmName}</b>
                    </p>
                </td>
                <td>
                    <p>
                        {item.securityFirmName}
                    </p>
                </td>
                <td>
                    <p style={{ color: '#aaa' }}>
                        {item.enteredDisplay}
                    </p>
                </td>
                <td>
                    {this.props.onDelete && (
                        <ErrorButton text={"Remove"} onClick={this.onDelete} />
                    )}
                </td>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(SecurityFirmDescendantRow);