import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as venueEventActions from "../actions/venueEvent";

const initialState = {
    error: null,
    errors: {},
    venueEvents: {
        isLoading: false,
        filter: {},
        venueEvents: [],
        paging: { pageSize: 10, page: 1 }
    },
    venueEvent: {
        venueEventId: -1,
        isLoading: false,
        isSaving: false,
        venueEvent: { venueEventId: 0 }
    },
};


function loadingVenueEvents(state, isLoading) {
    let newState = update(state, {
        venueEvents: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueEvents(state, venueEvents, filter, paging) {
    let newState = update(state, {
        venueEvents: {
            venueEvents: { $set: venueEvents },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingVenueEvent(state, isLoading) {
    let newState = update(state, {
        venueEvent: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueEvent(state, venueEvent) {
    let newState = update(state, {
        venueEvent: {
            venueEvent: { $set: venueEvent }
        }
    });
    return newState;
}

function savingVenueEvent(state, isSaving) {
    let newState = update(state, {
        venueEvent: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setVenueEventId(state, venueEventId) {
    let newState = update(state, {
        venueEvent: {
            venueEventId: { $set: venueEventId }
        }
    });
    return newState;
}

export default function venueEvents(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case venueEventActions.VENUEEVENTS_REFRESH:
            return loadingVenueEvents(setVenueEventId(state, -1), true);
        case venueEventActions.VENUEEVENTS_REQUEST:
            return setVenueEvents(loadingVenueEvents(setVenueEventId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case venueEventActions.VENUEEVENTS_REQUEST_SUCCESS:
            return setVenueEvents(loadingVenueEvents(state, false), action.data, action.filter, action.paging);
        case venueEventActions.VENUEEVENTS_REQUEST_FAILURE:
            return loadingVenueEvents(state, false);
        case venueEventActions.VENUEEVENT_RESET:
            return setVenueEvent(setVenueEventId(state, -1), { venueEventId: -1});
        case venueEventActions.VENUEEVENT_REQUEST:
            return setVenueEvent(loadingVenueEvent(setVenueEventId(state, action.venueEventId), true), {});
        case venueEventActions.VENUEEVENT_REQUEST_SUCCESS:
            return setVenueEvent(loadingVenueEvent(state, false), action.venueEvent);
        case venueEventActions.VENUEEVENT_REQUEST_FAILURE:
            return loadingVenueEvent(setVenueEventId(state, -1), false);
        case venueEventActions.VENUEEVENT_CREATE_REQUEST:
            return setVenueEvent(loadingVenueEvent(setVenueEventId(state, 0), true), {});
        case venueEventActions.VENUEEVENT_CREATE_REQUEST_SUCCESS:
            return setVenueEvent(loadingVenueEvent(state, false), action.venueEvent);
        case venueEventActions.VENUEEVENT_CREATE_REQUEST_FAILURE:
            return loadingVenueEvent(setVenueEventId(state, -1), false);
        case venueEventActions.VENUEEVENT_SAVE_REQUEST:
            return savingVenueEvent(state, true);
        case venueEventActions.VENUEEVENT_SAVE_REQUEST_SUCCESS:
            return setVenueEvent(savingVenueEvent(setVenueEventId(state, -1), false), action.venueEvent);
        case venueEventActions.VENUEEVENT_SAVE_REQUEST_FAILURE:
            return savingVenueEvent(state, false);
        case venueEventActions.VENUEEVENT_UPDATE:
            return setVenueEvent(state, action.venueEvent);

    }

    return state;
}