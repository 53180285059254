import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';


export interface INoRowsProps {
    theme: any;
    message?: string;
    newItemLink?: string;
}
class NoRows extends React.PureComponent<INoRowsProps, any> {
    render() {
        return (
            <Card style={{ marginTop: '6px' }}>
                <CardContent>
                    <CardTypography style={{textAlign: 'center'}}>
                        {this.props.message || "No rows found."}{" "}
                    </CardTypography>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles, { withTheme: true })(NoRows);