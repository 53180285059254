import React from 'react';

const SignOnRegisterIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M16.79,47.59H8.01c-3.12,0-5.67-2.54-5.67-5.67V12.46c0-3.13,2.54-5.67,5.67-5.67h4.53c0.63,0,1.13,0.51,1.13,1.13c0,0.63-0.51,1.13-1.13,1.13H8.01c-1.87,0-3.4,1.53-3.4,3.4v29.46c0,1.87,1.53,3.4,3.4,3.4h8.78c0.63,0,1.13,0.51,1.13,1.13C17.92,47.08,17.41,47.59,16.79,47.59" />
            <path d="M39.73,18.7c-0.63,0-1.13-0.51-1.13-1.13v-5.1c0-1.87-1.53-3.4-3.4-3.4h-4.53c-0.63,0-1.13-0.51-1.13-1.13c0-0.62,0.51-1.13,1.13-1.13h4.53c3.13,0,5.67,2.54,5.67,5.67v5.1C40.86,18.19,40.36,18.7,39.73,18.7" />
            <path d="M13.67,6.8L13.67,6.8v3.4c0,0.62,0.51,1.13,1.13,1.13h13.6c0.62,0,1.13-0.51,1.13-1.13V6.8h-3.4C25.51,6.8,25,6.29,25,5.67c0-1.87-1.53-3.4-3.4-3.4c-1.87,0-3.4,1.53-3.4,3.4c0,0.63-0.51,1.13-1.13,1.13H13.67z M28.4,13.6H14.8c-1.87,0-3.4-1.53-3.4-3.4V5.67c0-0.62,0.51-1.13,1.13-1.13h3.51C16.58,1.95,18.87,0,21.6,0c2.73,0,5.03,1.95,5.55,4.53h3.51c0.62,0,1.13,0.51,1.13,1.13v4.53C31.8,12.07,30.28,13.6,28.4,13.6" />
            <path d="M32.93,20.4H10.27c-0.63,0-1.13-0.51-1.13-1.13c0-0.63,0.51-1.13,1.13-1.13h22.66c0.63,0,1.13,0.51,1.13,1.13C34.07,19.89,33.56,20.4,32.93,20.4" />
            <path d="M27.27,27.19h-17c-0.63,0-1.13-0.51-1.13-1.13c0-0.63,0.51-1.13,1.13-1.13h17c0.63,0,1.13,0.51,1.13,1.13C28.4,26.69,27.89,27.19,27.27,27.19" />
            <path d="M21.6,33.99H10.27c-0.63,0-1.13-0.51-1.13-1.13c0-0.63,0.51-1.13,1.13-1.13H21.6c0.63,0,1.13,0.51,1.13,1.13C22.74,33.48,22.23,33.99,21.6,33.99" />
            <path d="M28.24,47.26L28.24,47.26h0.02H28.24z M22.88,41.41L22.88,41.41l-1.2,6.01l5.86-1.17c0.08-0.02,0.2-0.08,0.25-0.14l17.28-17.28c0.44-0.44,0.44-1.16,0-1.6l-3.2-3.2c-0.44-0.44-1.16-0.44-1.6,0L22.88,41.41z M20.23,50c-0.23,0.03-0.59-0.12-0.8-0.33c-0.27-0.27-0.39-0.65-0.31-1.02l1.6-8.01c0.05-0.22,0.15-0.42,0.31-0.58l17.62-17.62c1.32-1.32,3.48-1.32,4.81,0l3.2,3.2c1.33,1.33,1.33,3.48,0,4.81L29.04,48.06c-0.16,0.16-0.36,0.27-0.58,0.31l-8.01,1.6C20.38,49.99,20.3,50,20.23,50" />
        </g>
    </svg>
);

export default SignOnRegisterIcon;

