import * as AusComplyDtos from "./AusComply.dtos";

export class RadioOption {
    id: string;
    name: string;
    content?: any;
}

export class IdName {
    id: number;
    name: string;
}

export class KeyName {
    key: string;
    name: string;
}

export class MulitSelectOption {
    id: number;
    code?: string;
    isCommon: boolean;
    name: string;
    selected: boolean;
    info?: string;
    locked?: boolean;
}

export class SingleSelectList {
    id: number | string;
    parentId?: number | string | undefined;
    subParentId?: number | string | undefined;
    isCommon: boolean;
    name: string;
    selected: boolean;
    info: string;
    infoColor: string;
}

export class PrintSend {
    recipients: string[];
    ccRecipients: string[];
    bccRecipients: string[];
}

export class AdminDashboardOptions {
    showVenueMarkers: boolean;
    showActiveVenues: boolean;
    showInactiveVenues: boolean;
    showPostcodeBoundaries: boolean;
    showHeatmap: boolean;
    postcodes: string[];
}