import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import TableRow from '../../common/TableRow';
import { securityFirmLogic } from '../../../common/logic/securityFirmLogic';

export interface ISecurityFirmsRowProps {
    onClick: Function;
    onCommand?: Function;
    classes: any;
    theme: any;
    securityFirm: AusComplyDtos.SecurityFirm;
}

class SecurityFirmsRow extends React.Component<ISecurityFirmsRowProps, any> {
    constructor(props: ISecurityFirmsRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.securityFirm);
        }
    }

    render() {
        const { classes } = this.props;
        let item = this.props.securityFirm;
        let className = classes.rowStatusPending;
        if (!this.props.securityFirm.registrationComplete) {
            className = classes.rowStatusDanger;
        } 
        let activeTick;
        if (this.props.securityFirm.isActive) {
            activeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        
        var commands = securityFirmLogic.getAdminCommands(this.props.securityFirm);

        return (
            <TableRow isDanger={!this.props.securityFirm.registrationComplete} commands={commands} onCommand={this.onCommand} key={"row-" + item.securityFirmId.toString()} >
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.securityFirmId}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.name}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.masterLicenceNumber}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.address}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.suburb}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.stateDisplayName}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {item.managerOwnerName}
                    </p>
                </td>
                <td onClick={() => this.props.onClick(this.props.securityFirm)}>
                    <p>
                        {activeTick}
                    </p>
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(SecurityFirmsRow);