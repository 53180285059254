import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueLocation from '../components/venue/venueLocation/VenueLocation'
import { 
    venueLocationReset,
    venueLocationSet,
    venueLocationUpsertRequest
 } from '../common/actions/venue';
 import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.venueLocation.isLoading,
        isSaving: state.venue.venueLocation.isSaving,
        venueLocationId: state.venue.venueLocation.venueLocationId,
        venueLocation: state.venue.venueLocation.venueLocation,
        canViewSubLocations: permissionsLogic.hasPermissionForState(state, "SubLocations"),
        canEditSubLocations: permissionsLogic.hasPermissionForState(state, "SubLocationsEdit"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueLocationReset())
        },
        onUpdate: (venueLocation) => {
            dispatch(venueLocationSet(venueLocation))
        },
        onSave: () => {
            dispatch(venueLocationUpsertRequest())
        },
    }
}

const VenueLocationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueLocation))

export default VenueLocationContainer