import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../common/View';
import Tabs from '../../common/Tabs';

export interface IVenueTabsProps {
    venueId: number;
    selectedTab: string;
    history: any;
}

class VenueTabs extends Component<IVenueTabsProps, any> {
    constructor(props: IVenueTabsProps) {
        super(props)

        this.state = {
        };
        this.tabs = this.tabs.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (event, value) => {
        let tabName = this.tabs()[value];
        switch (tabName) {
            case "Subscription":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + "/subscription");
                break;
            case "Security Firms":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + "/securityfirms");
                break;
            case "Facial Recognition":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + "/facialrecognition/servers");
                break;
            case "Utilities":
                this.props.history.push('/admin/venue/' + this.props.venueId.toString() + "/utilities");
                break;

            default:
                this.props.history.push('/admin/venue/' + this.props.venueId.toString());
                break;
        }
    };

    tabs() {
        let tabs: string[] = [];
        // the tabs are dymanic based on permissions
        tabs.push('Details');
        tabs.push('Subscription');
        tabs.push('Security Firms');
        tabs.push('Facial Recognition');
        tabs.push('Utilities');
        return tabs;
    }

    selected(tabs: string[]) {
        let index = tabs.indexOf(this.props.selectedTab);
        if (index < 0) index = 0;
        return index;
    }

    render() {
        const tabs = this.tabs();

        return (<View style={{ marginTop: '10px' }}>
            <Tabs selected={this.selected(tabs)} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </View>
        );
    }
}

export default withStyles(styles, { withTheme: true })(VenueTabs);