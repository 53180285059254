export const
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST = "INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST",
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH = "INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH",
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_SUCCESS = "INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_SUCCESS",
    INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_FAILURE = "INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_FAILURE",
    INCIDENT_FLAG_TYPE_NOTIFICATION_RESET = "INCIDENT_FLAG_TYPE_NOTIFICATION_RESET",
    INCIDENT_FLAG_TYPE_NOTIFICATION_SET = "INCIDENT_FLAG_TYPE_NOTIFICATION_SET",
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST = "INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST",
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_SUCCESS = "INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_SUCCESS",
    INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_FAILURE = "INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_FAILURE",
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST = "INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST",
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS = "INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS",
    INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_FAILURE = "INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_FAILURE";

export function incidentFlagTypeNotificationsRefresh() {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATIONS_REFRESH
    }
}

export function incidentFlagTypeNotificationsRequest(page, pageSize, filter) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function incidentFlagTypeNotificationsRequestSuccess(incidentFlagTypeNotifications, paging, filter) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_SUCCESS,
        incidentFlagTypeNotifications,
        paging,
        filter
    };
}

export function incidentFlagTypeNotificationsRequestFailure(error) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATIONS_REQUEST_FAILURE,
        error
    };
}

export function incidentFlagTypeNotificationReset() {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_RESET
    };
}

export function incidentFlagTypeNotificationSet(incidentFlagTypeNotification) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_SET,
        incidentFlagTypeNotification
    };
}

export function incidentFlagTypeNotificationRequest(incidentFlagTypeId) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST,
        incidentFlagTypeId
    };
}

export function incidentFlagTypeNotificationRequestSuccess(incidentFlagTypeNotification) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_SUCCESS,
        incidentFlagTypeNotification
    };
}

export function incidentFlagTypeNotificationRequestFailure(error) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_REQUEST_FAILURE,
        error
    };
}

export function incidentFlagTypeNotificationUpsertRequest() {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST
    };
}

export function incidentFlagTypeNotificationUpsertRequestSuccess(incidentFlagTypeNotification) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_SUCCESS,
        incidentFlagTypeNotification
    };
}

export function incidentFlagTypeNotificationUpsertRequestFailure(error) {
    return {
        type: INCIDENT_FLAG_TYPE_NOTIFICATION_UPSERT_REQUEST_FAILURE,
        error
    };
}