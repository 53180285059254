import React from 'react';
import { withStyles } from '@material-ui/core/styles';

interface IFabContainerProps {
    theme: any;
    classes: any;
    style?: any;
}

const styles = theme => ({
    root: {
        display: 'flex',
    }
})

class FabContainer extends React.Component<IFabContainerProps, any> {
    render() {
        return (
            <div style={{position: 'fixed', textAlign: 'right', bottom: 0, right: 0, ...this.props.style }}>
               {this.props.children}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FabContainer);