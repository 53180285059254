import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import TextControlGroup from '../../controls/TextControlGroup';

export interface IRegisterUserRSANumberProps {
    user: AusComplyDTOs.User;
    errors: any;
    isLicensedVenue: boolean;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserRSANumber extends Component<IRegisterUserRSANumberProps, any> {
    constructor(props: IRegisterUserRSANumberProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
    }


    onChange(value) {
        let user = { ...this.props.user };
        user.rsaNumber = value;
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={""}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <TextControlGroup 
                                        text={this.props.user.registerUserAs == AusComplyDTOs.ngtRegisterUserAs.RSAMarshal && this.props.isLicensedVenue ? "RSA Number (optional)" : "RSA Number"} 
                                        defaultValue={this.props.user.rsaNumber} 
                                        error={this.props.errors["rsaNumber"]}
                                        onBlur={(value) => this.onChange(value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}