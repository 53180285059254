import { makeActionCreator } from './helpers';

export const
    DIRECTORY_REQUEST = "DIRECTORY_REQUEST",
    DIRECTORY_REQUEST_SUCCESS = "DIRECTORY_REQUEST_SUCCESS",
    DIRECTORY_REQUEST_FAILURE = "DIRECTORY_REQUEST_FAILURE",
    DIRECTORY_UPLOAD_REQUEST = "DIRECTORY_UPLOAD_REQUEST",
    DIRECTORY_UPLOAD_REQUEST_SUCCESS = "DIRECTORY_UPLOAD_REQUEST_SUCCESS",
    DIRECTORY_UPLOAD_REQUEST_FAILURE = "DIRECTORY_UPLOAD_REQUEST_FAILURE",
    DIRECTORY_REMOVE_REQUEST = "DIRECTORY_REMOVE_REQUEST",
    DIRECTORY_REMOVE_REQUEST_SUCCESS = "DIRECTORY_REMOVE_REQUEST_SUCCESS",
    DIRECTORY_REMOVE_REQUEST_FAILURE = "DIRECTORY_REMOVE_REQUEST_FAILURE",
    DIRECTORY_UPDATE_REQUEST = "DIRECTORY_UPDATE_REQUEST",
    DIRECTORY_UPDATE_REQUEST_SUCCESS = "DIRECTORY_UPDATE_REQUEST_SUCCESS",
    DIRECTORY_UPDATE_REQUEST_FAILURE = "DIRECTORY_UPDATE_REQUEST_FAILURE",
    DIRECTORY_FOLDER_REQUEST = "DIRECTORY_FOLDER_REQUEST",
    DIRECTORY_FOLDER_REQUEST_SUCCESS = "DIRECTORY_FOLDER_REQUEST_SUCCESS",
    DIRECTORY_FOLDER_REQUEST_FAILURE = "DIRECTORY_FOLDER_REQUEST_FAILURE",
    DIRECTORY_ADMIN_REQUEST = "DIRECTORY_ADMIN_REQUEST",
    DIRECTORY_ADMIN_REQUEST_SUCCESS = "DIRECTORY_ADMIN_REQUEST_SUCCESS",
    DIRECTORY_ADMIN_REQUEST_FAILURE = "DIRECTORY_ADMIN_REQUEST_FAILURE",
    DIRECTORY_ADMIN_FOLDER_REQUEST = "DIRECTORY_ADMIN_FOLDER_REQUEST",
    DIRECTORY_ADMIN_FOLDER_REQUEST_SUCCESS = "DIRECTORY_ADMIN_FOLDER_REQUEST_SUCCESS",
    DIRECTORY_ADMIN_FOLDER_REQUEST_FAILURE = "DIRECTORY_ADMIN_FOLDER_REQUEST_FAILURE"
    ;

export const directoryRequest = makeActionCreator(DIRECTORY_REQUEST, 'entityType', 'id', 'showObsolete');
export const directoryRequestSuccess = makeActionCreator(DIRECTORY_REQUEST_SUCCESS, 'directory');
export const directoryRequestFailure = makeActionCreator(DIRECTORY_REQUEST_FAILURE, 'error');
export const directoryUploadRequest = makeActionCreator(DIRECTORY_UPLOAD_REQUEST, 'documentUpload');
export const directoryUploadRequestSuccess = makeActionCreator(DIRECTORY_UPLOAD_REQUEST_SUCCESS, 'directory');
export const directoryUploadRequestFailure = makeActionCreator(DIRECTORY_UPLOAD_REQUEST_FAILURE, 'error');
export const directoryRemoveRequest = makeActionCreator(DIRECTORY_REMOVE_REQUEST, 'document');
export const directoryRemoveRequestSuccess = makeActionCreator(DIRECTORY_REMOVE_REQUEST_SUCCESS, 'directory');
export const directoryRemoveRequestFailure = makeActionCreator(DIRECTORY_REMOVE_REQUEST_FAILURE, 'error');
export const directoryUpdateRequest = makeActionCreator(DIRECTORY_UPDATE_REQUEST, 'document');
export const directoryUpdateRequestSuccess = makeActionCreator(DIRECTORY_UPDATE_REQUEST_SUCCESS, 'directory');
export const directoryUpdateRequestFailure = makeActionCreator(DIRECTORY_UPDATE_REQUEST_FAILURE, 'error');
export const directoryUpsertFolderRequest = makeActionCreator(DIRECTORY_FOLDER_REQUEST, 'entityType', 'id', 'folder');
export const directoryUpsertFolderRequestSuccess = makeActionCreator(DIRECTORY_FOLDER_REQUEST_SUCCESS, 'directory');
export const directoryUpsertFolderRequestFailure = makeActionCreator(DIRECTORY_FOLDER_REQUEST_FAILURE, 'error');

export const directoryAdminRequest = makeActionCreator(DIRECTORY_ADMIN_REQUEST, 'entityType');
export const directoryAdminRequestSuccess = makeActionCreator(DIRECTORY_ADMIN_REQUEST_SUCCESS, 'directory');
export const directoryAdminRequestFailure = makeActionCreator(DIRECTORY_ADMIN_REQUEST_FAILURE, 'error');
export const directoryAdminUpsertFolderRequest = makeActionCreator(DIRECTORY_ADMIN_FOLDER_REQUEST, 'entityType', 'folder');
export const directoryAdminUpsertFolderRequestSuccess = makeActionCreator(DIRECTORY_ADMIN_FOLDER_REQUEST_SUCCESS, 'directory');
export const directoryAdminUpsertFolderRequestFailure = makeActionCreator(DIRECTORY_ADMIN_FOLDER_REQUEST_FAILURE, 'error');