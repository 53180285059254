import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import DefaultButton from '../../controls/DefaultButton';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import TableContainer from '../../layout/TableContainer';
import ChecklistTemplateItemOption from './ChecklistTemplateItemOption';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import { commonLogic } from '../../../common/logic/common';


export interface IChecklistTemplateItemOptionsProps {
    checklistTemplateItem: AusComplyDTOs.ChecklistTemplateItem;
    options: AusComplyDTOs.ChecklistTemplateItemOption[];
    onUpdate: Function;
    theme: any;
    classes: any;
}

class ChecklistTemplateItemOptions extends Component<IChecklistTemplateItemOptionsProps, any> {
    constructor(props: IChecklistTemplateItemOptionsProps) {
        super(props)
        this.state = {

        }
        this.onChangeOption = this.onChangeOption.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onYesNoNa = this.onYesNoNa.bind(this);
        this.onSafeAtRickNA = this.onSafeAtRickNA.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    /*
    onChange(fieldname, value) {
        let checklistTemplate = [] ...this.props.checklistTemplate ];
        checklistTemplate[fieldname] = value;
        this.props.onUpdate(checklistTemplate);
    }
    */


    onChangeOption(index: number, checklistTemplateItemOption: AusComplyDTOs.ChecklistTemplateItemOption) {
        let options: AusComplyDTOs.ChecklistTemplateItemOption[] = [... this.props.options];
        options.splice(index, 1, checklistTemplateItemOption);
        this.props.onUpdate(options);
    }

    /*
     seed: number) {
        let date = new Date();
        let dateString = moment(date).format("DDHHmmss");
        let dateNumber = Number(dateString);
        let offset = (new Date().getUTCMilliseconds()) * 10;
        return dateNumber + offset + seed;
    }
        */

    onAdd() {
        // get the max number
        let rank = 1;
        this.props.options.forEach(item => {
            if (item.rank >= rank) {
                rank = item.rank + 1;
            }
        });
        let option = new AusComplyDTOs.ChecklistTemplateItemOption();
        option.value = rank.toString();
        option.name = "New Option " + rank.toString();
        option.rank = rank;
        option.temporaryId = commonLogic.uniqueNumber(1);
        let options = [... this.props.options, option];
        this.props.onUpdate(options);
    }

    onYesNoNa() {
        let optionYes = new AusComplyDTOs.ChecklistTemplateItemOption();
        optionYes.value = "Yes";
        optionYes.name = "Yes";
        optionYes.rank = 1;
        optionYes.temporaryId = commonLogic.uniqueNumber(1);

        let optionNo = new AusComplyDTOs.ChecklistTemplateItemOption();
        optionNo.value = "No";
        optionNo.name = "No";
        optionNo.rank = 2;
        optionNo.temporaryId = commonLogic.uniqueNumber(2);

        let optionNA = new AusComplyDTOs.ChecklistTemplateItemOption();
        optionNA.value = "N/A";
        optionNA.name = "N/A";
        optionNA.rank = 3;
        optionNA.temporaryId = commonLogic.uniqueNumber(3);

        let options = [optionYes, optionNo, optionNA];
        this.props.onUpdate(options);
    }

    onSafeAtRickNA() {
        let option1 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option1.value = "Safe";
        option1.name = "Safe";
        option1.rank = 1;
        option1.temporaryId = commonLogic.uniqueNumber(1);

        let option2 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option2.value = "At Risk";
        option2.name = "At Risk";
        option2.rank = 2;
        option2.temporaryId = commonLogic.uniqueNumber(2);

        let option3 = new AusComplyDTOs.ChecklistTemplateItemOption();
        option3.value = "N/A";
        option3.name = "N/A";
        option3.rank = 3;
        option3.temporaryId = commonLogic.uniqueNumber(3);

        let options: AusComplyDTOs.ChecklistTemplateItemOption[] = [option1, option2, option3];
        this.props.onUpdate(options);
    }

    onRemove(index) {
        let options = [... this.props.options];
        options.splice(index, 1);
        this.props.onUpdate(options);
    }

    render() {
        const { classes } = this.props;

        if (!this.props.checklistTemplateItem) {
            return (<></>);
        }
        if (this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Question &&
            this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.Selection &&
            this.props.checklistTemplateItem.checklistItemType !== AusComplyDTOs.ChecklistItemType.MultipleChoice) {
            return (<></>);
        }
        if (!this.props.options) {
            return (<></>);
        }

        let rows = this.props.options.map((item, index) => {
            return (
                <ChecklistTemplateItemOption
                    index={index}
                    key={"checklistTemplateItemOption-" + item.temporaryId}
                    option={item}
                    onUpdate={p => this.onChangeOption(index, p)}
                    onRemove={i => this.onRemove(i)}
                />
            );
        });
        let table = (
            <TableContainer
                header={
                    <tr>
                        <th className={classes.tableHrIndicator}></th>
                        <th className={classes.tableHr}>Value</th>
                        <th className={classes.tableHr}>Label</th>
                        <th className={classes.tableHr}></th>
                    </tr>
                }
                rows={rows}
            />
        );

        let suggestions;
        if (this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Question || 
            this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Selection) {
            suggestions = (
                <>
                    <DefaultButton text={"YES / NO / NA"} onClick={() => this.onYesNoNa()} style={{ width: 'auto', marginRight: '10px' }} />
                    <DefaultButton text={"SAFE / AT RISK / NA"} onClick={() => this.onSafeAtRickNA()} style={{ width: 'auto', marginRight: '10px' }} />
                </>);
        }

        return (
            <CardContainer
                title={"Options"}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            {suggestions}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <DefaultButton text={"Add"} onClick={() => this.onAdd()} style={{ width: 'auto' }} />
                        </Grid>
                    </Grid>
                    {table}
                </CardContent>
            </CardContainer>

        );
    }
}

export default withStyles(styles, { withTheme: true })(ChecklistTemplateItemOptions)