import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { securityFirmAssociationLogic } from '../../../common/logic/securityFirmAssociationLogic';

export interface ISecurityFirmSubAssociationRowProps {
    securityFirmAssociation: AusComplyDtos.SecurityFirmAssociation;
    canRemove: boolean;
    onCommand?: Function;
}

export default class SecurityFirmSubAssociationRow extends React.Component<ISecurityFirmSubAssociationRowProps, any> {
    constructor(props: ISecurityFirmSubAssociationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.securityFirmAssociation);
        }
    }

    render() {
        if (!this.props.securityFirmAssociation) {
            return (<></>);
        }

        var commands = securityFirmAssociationLogic.getCommands(this.props.securityFirmAssociation, this.props.canRemove);
        var item = this.props.securityFirmAssociation;

        return (
            <TableRow commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        <b>{item.securityFirm}</b>
                    </p>
                </td>
                <td>
                    <p>
                        {item.masterLicenceNumber}
                    </p>
                </td>
                <td>
                    <p>
                        {item.suburb}
                    </p>
                </td>
                <td>
                    <p>
                        {item.stateDisplayName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.postcode}
                    </p>
                </td>
                <td>
                    <p>
                        {item.phone}
                    </p>
                </td>
                <td>
                    <p>
                        {item.managerName}
                    </p>
                </td>
                <td>
                    <p>
                        {item.website}
                    </p>
                </td>
                <td>
                    <p>
                        {item.dateEnteredDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {item.dateModifiedDisplay}
                    </p>
                </td>
            </TableRow>
        );
    }
}