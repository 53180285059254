
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userServiceApi } from '../services/userService';
import { dashboardServiceApi } from '../services/dashboard';
import * as dashboardActions from "../actions/dashboard";

import {
    USER_CHANGED, USER_RESET
} from '../actions/userLogin';

import {
    printQueueRequest
} from '../actions/print';

import {
    VENUE_SECURITYFIRM_SELECT

} from "../actions/venueSecurityFirm";

import {
    notifyError, notifyErrorMessage
} from './common';
import { notificationSuccessShow } from "../actions/notification";

const dashboardResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED, USER_RESET, VENUE_SECURITYFIRM_SELECT),
        map(action => dashboardActions.dashboardReset())
    );

const dashboardFilterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.newFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.dashboard.forVenueId,
                state.dashboard.forsecurityFirmId,
                undefined
            )
                .pipe(
                    map(response => dashboardActions.dashboardFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "dashboardFilterRequestEpic.newFilter", dashboardActions.dashboardFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "dashboardFilterRequestEpic", dashboardActions.dashboardFilterRequestFailure))
    );

const dashboardFilterRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_FILTER_REQUEST_SUCCESS),
        map((action: any) => dashboardActions.dashboardRequest())
    );

const dashboardSetFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_SET_FILTER),
        map((action: any) => dashboardActions.dashboardRequest())
    );

const dashboardExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.export(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.dashboard.forVenueId,
                state.dashboard.forsecurityFirmId,
                { ...state.dashboard.filter, mode: AusComplyDtos.ngtStatsMode.All })
                .pipe(
                    map(response => dashboardActions.dashboardExportRequestSuccess()),
                    catchError(error => notifyError(error, "dashboardExportRequestEpic.export", dashboardActions.dashboardExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "dashboardExportRequestEpic", dashboardActions.dashboardExportRequestFailure))
    );

const dashboardExportRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_EXPORT_REQUEST_SUCCESS),
        map((action: any) => printQueueRequest())
    );

const dashboardRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.dashboard.forVenueId,
                state.dashboard.forSecurityFirmId,
                state.dashboard.filter)
                .pipe(
                    map(response => dashboardActions.dashboardRequestSuccess(response.filter, response.data)),
                    catchError(error => notifyError(error, "dashboardRequestEpic.get", dashboardActions.dashboardRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "dashboardRequestEpic", dashboardActions.dashboardRequestFailure))
    );


const dashboardStatsModeRequestEpic = (action$, state$, mode: AusComplyDtos.ngtStatsMode, onSuccess) =>
    action$.pipe(
        ofType(dashboardActions.DASHBOARD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            dashboardServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.dashboard.forVenueId,
                state.dashboard.forSecurityFirmId,
                { ...state.dashboard.filter, mode: mode })
                .pipe(
                    map(response => onSuccess(response.filter, response.data)),
                    catchError(error => notifyError(error, "dashboardIncidentTypeRequestEpic.get", dashboardActions.dashboardRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "dashboardIncidentTypeRequestEpic", dashboardActions.dashboardRequestFailure))
    );

const dashboardIncidentTypeRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentType, dashboardActions.dashboardRequestIncidentTypeSuccess)

const dashboardIncidentCategoriesRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentCategoryType, dashboardActions.dashboardRequestIncidentCategoriesSuccess)

const dashboardIncidentLocationsRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentLocation, dashboardActions.dashboardRequestIncidentLocationsSuccess)

const dashboardIncidentRefuseRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentRefusalReason, dashboardActions.dashboardRequestIncidentRefusedSuccess)

const dashboardIncidentAskedRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentRemovalReason, dashboardActions.dashboardRequestIncidentAskedSuccess)

const dashboardIncidentWatchlistRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentWatchlist, dashboardActions.dashboardRequestIncidentWatchlistSuccess)

const dashboardIncidentDemographicRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedDemographic, dashboardActions.dashboardRequestIncidentDemographicsSuccess)

const dashboardIncidentGenderRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedGender, dashboardActions.dashboardRequestIncidentGenderSuccess)

const dashboardIncidentRaceRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentPersonInvolvedRacialAppearance, dashboardActions.dashboardRequestIncidentRaceSuccess)

const dashboardIncidentEnteredRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentEnteredBy, dashboardActions.dashboardRequestIncidentEnteredSuccess)

const dashboardIncidentApprovedRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.IncidentApprovedBy, dashboardActions.dashboardRequestIncidentApprovedSuccess)

const dashboardChecklistRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.ChecklistTemplate, dashboardActions.dashboardRequestChecklistSuccess)

const dashboardRGOIncidentTypeRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.RGOIncidentType, dashboardActions.dashboardRequestRGOIncidentTypeSuccess)

const dashboardRGOIncidentCategoriesRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.RGOIncidentCategoryType, dashboardActions.dashboardRequestRGOIncidentCategoriesSuccess)

const dashboardRGOIncidentLocationsRequestEpic = (action$, state$) =>
    dashboardStatsModeRequestEpic(action$, state$, AusComplyDtos.ngtStatsMode.RGOIncidentLocation, dashboardActions.dashboardRequestRGOIncidentLocationsSuccess)

export const dashboardEpics = combineEpics(
    dashboardResetOnUserChangeEpic,
    dashboardFilterRequestEpic,
    dashboardFilterRequestSuccessEpic,
    dashboardSetFilterEpic,
    dashboardExportRequestEpic,
    dashboardExportRequestSuccessEpic,
    dashboardIncidentTypeRequestEpic,
    dashboardIncidentCategoriesRequestEpic,
    dashboardIncidentLocationsRequestEpic,
    dashboardIncidentRefuseRequestEpic,
    dashboardIncidentAskedRequestEpic,
    dashboardIncidentWatchlistRequestEpic,
    dashboardIncidentDemographicRequestEpic,
    dashboardIncidentGenderRequestEpic,
    dashboardIncidentRaceRequestEpic,
    dashboardIncidentEnteredRequestEpic,
    dashboardIncidentApprovedRequestEpic,
    dashboardChecklistRequestEpic,
    dashboardRGOIncidentTypeRequestEpic,
    dashboardRGOIncidentCategoriesRequestEpic,
    dashboardRGOIncidentLocationsRequestEpic
);