import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import { TimeZoneDate } from "../../common/dto/AusComply.dtos";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import NativeSelect from '@material-ui/core/NativeSelect';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import DarkButton from '../controls/DarkButton';
import Grid from '@material-ui/core/Grid';

export interface ITimeZoneDateControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    rightText?: string;
    error?: string;
    date?: TimeZoneDate;
    readonly?: boolean;
    onChange?: Function;
    onEnter?: Function;
    labelAbove?: boolean;
    defaultTime?: string;
    futureOnly?: boolean;
    layout?: TimeZoneLayout;
}

export enum TimeZoneLayout {
    Default = 1,
    StackedWithoutTimezoneAndClear = 2
}

class TimeOption {
    value: string;
    label: string;
}

interface ITimeZoneDateControlGroupState {
    date: string;
    time: string;
    timeZone: string;
    isEmpty: boolean;
    option: string;
    options: TimeOption[];
}

class TimeZoneDateControlGroup extends React.PureComponent<ITimeZoneDateControlGroupProps, ITimeZoneDateControlGroupState> {
    constructor(props: ITimeZoneDateControlGroupProps) {
        super(props)
        this.state = {
            date: "",
            time: "",
            timeZone: "",
            isEmpty: true,
            option: "",
            options: []
        }

        this.onClear = this.onClear.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onBlurDate = this.onBlurDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onBlurTime = this.onBlurTime.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.timeZoneChanged = this.timeZoneChanged.bind(this);
        this.timeSelected = this.timeSelected.bind(this);
    }

    componentDidMount() {
        let date = this.convertedDate(this.props.date, false);
        let time = this.convertedTime(this.props.date, false);
        let timeZone = "";
        let isEmpty = false;
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
            timeZone = this.props.date.timeZone;
        }

        let timeOptions: TimeOption[] = [];
        for (var i = 0; i < 24; i++) {
            for (var j = 0; j < 4; j++) {
                let option = "";
                if (i < 10) {
                    option = "0" + i.toString() + ":";
                } else {
                    option = i.toString() + ":";
                }
                if (j == 0) {
                    option = option + "00";
                } else if (j == 1) {
                    option = option + "15";
                } else if (j == 2) {
                    option = option + "30";
                } else if (j == 3) {
                    option = option + "45";
                }
                timeOptions.push({ value: option, label: option });
            }
        }

        this.setState({
            date,
            time,
            isEmpty,
            timeZone,
            options: timeOptions
        });

        this.processPropsToSelection();

    }


    componentDidUpdate(prevProps) {
        let date = this.convertedDate(this.props.date, false);
        let datePrevious = this.convertedDate(prevProps.date, false);
        let time = this.convertedTime(this.props.date, false);
        let timePrevious = this.convertedTime(prevProps.date, false);
        let isEmpty = this.state.isEmpty;
        let timeZone = "";
        let timeZonePrevious = "";
        if (prevProps.date) {
            timeZonePrevious = prevProps.date.timeZone;
        }
        if (this.props.date) {
            isEmpty = this.props.date.isEmpty;
            timeZone = this.props.date.timeZone;
        }
        if (datePrevious != date || timePrevious != time || timeZone != timeZonePrevious) {
            this.setState({
                date,
                time,
                isEmpty,
                timeZone
            }, () => {
                this.processPropsToSelection();
            });
        }
    }

    processPropsToSelection() {
        let option = "";
        if (this.props.date && !this.props.date.isEmpty && this.props.date.hour) {
            if (this.props.date.hour < 10) {
                option = "0" + this.props.date.hour.toString() + ":";
            } else {
                option = this.props.date.hour.toString() + ":";
            }
            if (this.props.date.minute >= 45 && this.props.date.minute <= 60) {
                option = option + "45";
            } else if (this.props.date.minute >= 30 && this.props.date.minute <= 44) {
                option = option + "30";
            } else if (this.props.date.minute >= 15 && this.props.date.minute <= 29) {
                option = option + "15";
            } else {
                option = option + "00";
            }
        }

        let timeChanged = false;
        if (option != this.state.option && this.props.date && this.props.date.is15MinuteIncrements) {
            timeChanged = true;
        }
        // convert the default value into 
        this.setState({
            option: option
        });

        if (timeChanged) {
            this.timeSelected(option);
        }
    }

    convertDateToTime(date: Date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        let result = "";
        if (hours < 10) {
            result = "0" + hours.toString() + ":";
        } else {
            result = hours.toString() + ":";
        }
        if (minutes >= 45 && minutes <= 60) {
            result = result + "45";
        } else if (minutes >= 30 && minutes <= 44) {
            result = result + "30";
        } else if (minutes >= 15 && minutes <= 29) {
            result = result + "15";
        } else {
            result = result + "00";
        }
        return result;
    }

    convertedDate(value: TimeZoneDate | undefined, force: boolean) {
        if (value && (!value.isEmpty || force)) {
            return [value.year, "-",
            (value.month > 9 ? '' : '0') + value.month, "-",
            (value.day > 9 ? '' : '0') + value.day
            ].join('');
        }
        return "";
    }

    convertedTime(value: TimeZoneDate | undefined, force: boolean) {
        if (value && (!value.isEmpty || force)) {
            return [
                (value.hour > 9 ? '' : '0') + value.hour, ":",
                (value.minute > 9 ? '' : '0') + value.minute,
                ":00"
            ].join('');
        }
        return "";
    }

    onChangeDate(event) {
        let self = this;
        if (event.target.value) {
            let time = this.state.time;
            let option = this.state.option;
            if (this.state.isEmpty) {
                if (this.props.date && this.props.date.is15MinuteIncrements) {
                    // incremental time now
                    option = this.convertDateToTime(new Date(event.target.value.toString()));
                } else {
                    time = this.props.defaultTime || this.convertedTime(this.props.date, true);
                }
            }
            this.setState({
                date: event.target.value.toString(),
                time,
                option,
                isEmpty: false
            }, () => {
                if (!self.props.futureOnly) {
                    // delay until blur
                    self.onUpdated(false);
                }
            });
        }
    }

    onBlurDate(event) {
        this.onUpdated(false);
    }

    onChangeTime(event) {
        let self = this;
        if (event.target.value) {
            let date = this.state.date;
            if (this.state.isEmpty) {
                date = this.convertedDate(this.props.date, true);
            }
            let time = this.state.time;
            let newTime = event.target.value.toString();
            time = newTime;
            this.setState({
                date,
                time,
                isEmpty: false
            }, () => {
                // delay until blur
                if (!self.props.futureOnly) {
                    self.onUpdated(false);
                }
            });
        }
    }

    onBlurTime(event) {
        this.onUpdated(false);
    }

    onClear() {
        this.setState({
            date: "",
            time: "",
            option: "",
            isEmpty: true
        }, () => {
            this.onUpdated(false);
        });

    }

    onUpdated(wasEnter: boolean) {
        let self = this;
        if (this.props.onChange && this.props.date) {
            let newDate: TimeZoneDate = { ...this.props.date };
            newDate.timeZone = this.state.timeZone;
            newDate.isEmpty = this.state.isEmpty;
            if (this.state.date) {
                let dateValue = new Date(this.state.date);
                if (isNaN(dateValue.getFullYear())) {
                    newDate.year = new Date().getFullYear();
                } else {
                    newDate.year = dateValue.getFullYear();
                }
                if (isNaN(dateValue.getMonth() + 1)) {
                    newDate.month = new Date().getMonth() + 1;
                } else {
                    newDate.month = dateValue.getMonth() + 1;
                }
                if (isNaN(dateValue.getDate())) {
                    newDate.day = new Date().getDate();
                } else {
                    newDate.day = dateValue.getDate();
                }
            }
            if (this.state.time) {
                let timeValue = new Date("1 Jan 2000 " + this.state.time);
                if (isNaN(timeValue.getHours())) {
                    newDate.hour = new Date().getHours();
                } else {
                    newDate.hour = timeValue.getHours();
                }
                if (isNaN(timeValue.getMinutes())) {
                    newDate.minute = new Date().getMinutes();
                } else {
                    newDate.minute = timeValue.getMinutes();
                }
            }
            if (this.props.futureOnly && !newDate.isEmpty) {
                var compareDate = new Date();
                var date = new Date(newDate.year, newDate.month - 1, newDate.day, newDate.hour, newDate.minute, 0, 0);
                if (date < compareDate) {
                    // reset to now
                    newDate.year = compareDate.getFullYear();
                    newDate.month = compareDate.getMonth() + 1;
                    newDate.day = compareDate.getDate();
                    newDate.hour = compareDate.getHours();
                    newDate.minute = compareDate.getMinutes();
                    this.setState({ date: self.convertedDate(newDate, false), time: self.convertedTime(newDate, false) });
                }
            }
            if (self.props.onChange) {
                self.props.onChange(newDate);
                if (wasEnter && this.props.onEnter) {
                    this.props.onEnter();
                }
            }
        }
    }

    onKeyPress = (event) => {
        if (event.key == 'Enter') {
            this.onUpdated(true);
        }
    }

    timeZoneChanged = (event) => {
        if (event.target.value != null) {
            this.setState({ timeZone: event.target.value });
        }
    }

    timeSelected(time) {
        let self = this;
        if (time) {
            let date = this.state.date;
            if (this.state.isEmpty) {
                date = this.convertedDate(this.props.date, true);
            }
            this.setState({
                date,
                time,
                option: time,
                isEmpty: false
            }, () => {
                self.onUpdated(false);
            });
        }
    }

    render() {

        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        let timecontrol;
        if (this.props.date && this.props.date.is15MinuteIncrements) {
            timecontrol = <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
                <NativeSelect
                    value={this.state.option}
                    onChange={event => this.timeSelected(event.target.value)}
                    name="time"
                    classes={{ icon: classes.backgroundColorDefault }}
                    input={<InputBase style={style}
                    />}
                >
                    <option>--:--</option>
                    {this.state.options && this.state.options.map((timeOption, timeOptionIndex) => (
                        <option key={timeOption.value} value={timeOption.value}>{timeOption.label}</option>
                    ))}
                </NativeSelect>
                {this.props.error && (
                    <FormHelperText>{this.props.error}</FormHelperText>
                )}
            </FormControl>
        } else {
            timecontrol = <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
                <InputBase
                    type="time"
                    value={this.state.time}
                    onBlur={this.onBlurTime}
                    onChange={this.onChangeTime}
                    disabled={this.props.readonly}
                    onKeyPress={this.onKeyPress}
                    style={style}
                    error={this.props.error ? true : false}
                    classes={{ root: classes.inputControl, input: classes.inputRoot }}
                />
            </FormControl>;
        }
        let dateControl = <FormControl error={this.props.error ? true : false} style={{ width: '100%' }}>
            <InputBase
                type="date"
                value={this.state.date}
                onBlur={this.onBlurDate}
                onChange={this.onChangeDate}
                disabled={this.props.readonly}
                onKeyPress={this.onKeyPress}
                style={style}
                error={this.props.error ? true : false}
                classes={{ root: classes.inputControl, input: classes.inputRoot }}
                inputProps={{ max: '9999-12-31' }}
            />
            {this.props.error && (
                <FormHelperText>{this.props.error}</FormHelperText>
            )}
        </FormControl>;

        let clearButton;
        if (!this.props.readonly) {
            clearButton = <DarkButton onClick={this.onClear} >X</DarkButton>
        }

        let timezones = <FormControl error={this.props.error ? true : false} style={{ width: '100%' }} disabled={this.props.readonly}>
            <NativeSelect
                value={this.state.timeZone}
                onChange={this.timeZoneChanged}
                name="timeZone"
                classes={{ icon: classes.backgroundColorDefault }}
                input={<InputBase style={style}
                />}
            >
                <option key={"0"} value={""}>Select...</option>
                {this.props.date && this.props.date.timeZones && this.props.date.timeZones.map((item, index) => (
                    <option key={item.timezoneId} value={item.timezoneId}>{item.timezoneName}</option>
                ))}
            </NativeSelect>
        </FormControl>

        if (this.props.layout && this.props.layout == TimeZoneLayout.StackedWithoutTimezoneAndClear) {
            return <>
                <ControlGroup text={this.props.text + " date"} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                    {dateControl}
                </ControlGroup>
                <ControlGroup text={this.props.text + " time"} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                    {timecontrol}
                </ControlGroup>
            </>
        }

        return (
            <ControlGroup text={this.props.text} rightText={this.props.rightText} error={this.props.error ? true : false} labelAbove={this.props.labelAbove}>
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            {dateControl}
                        </Grid>
                        <Grid item xs={5}>
                            {timecontrol}
                        </Grid>
                        <Grid item xs={2}>
                            {clearButton}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {timezones}
                        </Grid>
                    </Grid>
                </>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TimeZoneDateControlGroup);