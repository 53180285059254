import React, { Component } from 'react';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CardTitle from '../common/CardTitle';
import CardInformation from '../common/CardInformation';
import CardDivider from '../common/CardDivider';
import Grid from '@material-ui/core/Grid';
import * as AuscomplyDtos from '../../common/dto/AusComply.dtos';
import { Palette } from '../../common/constants/palette';


export interface ISnapshotSummaryProps {
    isLoading: boolean;
    snapshotSummary: AuscomplyDtos.SnapshotSummary;
    onRefresh: Function;
}


export default class SnapshotSummary extends Component<ISnapshotSummaryProps, any> {
    constructor(props: ISnapshotSummaryProps) {
        super(props)
    }

    render() {
        let content;

        if (!this.props.isLoading && this.props.snapshotSummary) {
            content = <>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <CardTitle>Incident Types</CardTitle>
                        <CardInformation style={{
                            textAlign: 'left',
                            paddingLeft: '20px',
                            marginBottom: '10px'
                        }}>{this.props.snapshotSummary.incidentTypeStatistics && this.props.snapshotSummary.incidentTypeStatistics.map((item, index) => {
                            let incidentType = item.name + ": " + item.total;
                            if (item.incidentTypeId != 3) {
                                if (item.numberInvolved > 1) {
                                    incidentType += " (" + item.numberInvolved + " people)";
                                }
                                else if (item.numberInvolved == 1) {
                                    incidentType += " (1 person)";
                                }
                            }
                            return <p key={"snapshot-incident-types-" + item.incidentTypeId} style={{ margin: '2px', fontSize: '16px', fontWeight: 500 }}>{incidentType}</p>
                        })}
                            {(!this.props.snapshotSummary.incidentTypeStatistics || this.props.snapshotSummary.incidentTypeStatistics.length == 0) && <p style={{ margin: '2px', fontSize: '16px', fontWeight: 500 }}>No data</p>}</CardInformation>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardTitle>Incident Categories</CardTitle>
                        <CardInformation style={{
                            textAlign: 'left',
                            paddingLeft: '20px'
                        }}>{this.props.snapshotSummary.incidentCategoryTypeStatistics && this.props.snapshotSummary.incidentCategoryTypeStatistics.map((item, index) => {
                            let incidentType = item.incidentCategoryTypeName + "(" + item.incidentTypeName + "): " + item.total;
                            if (item.incidentTypeId != 3) {
                                if (item.numberInvolved > 1) {
                                    incidentType += " (" + item.numberInvolved + " people)";
                                }
                                else if (item.numberInvolved == 1) {
                                    incidentType += " (1 person)";
                                }
                            }
                            return <p key={"snapshot-incident-categories-" + item.incidentTypeId + "-" + item.incidentCategoryTypeId} style={{ margin: '2px', fontSize: '16px', fontWeight: 500 }}>{incidentType}</p>
                        })}
                            {(!this.props.snapshotSummary.incidentCategoryTypeStatistics || this.props.snapshotSummary.incidentCategoryTypeStatistics.length == 0) && <p style={{ margin: '2px', fontSize: '16px', fontWeight: 500 }}>No data</p>}</CardInformation>
                    </Grid>
                </Grid>
                {this.props.snapshotSummary.checklistStatistics && this.props.snapshotSummary.checklistStatistics.length > 0 && (
                    <>
                        <CardDivider />
                        <CardTitle>Checklists</CardTitle>
                        <CardInformation style={{
                            textAlign: 'left',
                            paddingLeft: '20px',
                            marginBottom: '10px'
                        }}>
                        {this.props.snapshotSummary.checklistStatistics.map((item, index) => 
                        <p key={"snapshot-checklist-" + item.checklistTemplateId} style={{ margin: '2px', fontSize: '16px', fontWeight: 500 }}>&nbsp;{item.checklistTemplateName}: {item.total}</p>
                        )}
                        </CardInformation>
                    </>
                )}
                {this.props.snapshotSummary.timeZoneDate && (
                    <>
                        <CardDivider />
                        <CardTitle>Date</CardTitle>
                        <CardInformation style={{
                            color: Palette.SubTitle,
                            fontSize: '12px'
                        }}>{this.props.snapshotSummary.timeZoneDate.displayShortDate}</CardInformation>
                    </>
                )}
            </>;
        }
        return (
            <CardContainer
                title={"Snapshot Summary"}
                isLoading={this.props.isLoading}
                hideLabel={"Collapse"}
                showLabel={"Expand"}
                collapsed={<></>}
                expanded={<CardContent style={{ paddingBottom: '10px' }}>
                {content}
            </CardContent>}
            >
            </CardContainer>
        );
    }
}