import { makeActionCreator } from './helpers';

export const
    NOTES_REQUEST = "NOTES_REQUEST",
    NOTES_REQUEST_SUCCESS = "NOTES_REQUEST_SUCCESS",
    NOTES_REQUEST_FAILURE = "NOTES_REQUEST_FAILURE",
    NOTES_REFRESH = "NOTES_REFRESH",
    NOTE_UPDATE_NEW = "NOTE_UPDATE_NEW",
    NOTE_UPDATE_EXISTING = "NOTE_UPDATE_EXISTING",
    NOTE_RESET_EXISTING = "NOTE_RESET_EXISTING",
    NOTE_SAVE_NEW_REQUEST = "NOTE_SAVE_NEW_REQUEST",
    NOTE_SAVE_NEW_REQUEST_SUCCESS = "NOTE_SAVE_NEW_REQUEST_SUCCESS",
    NOTE_SAVE_NEW_REQUEST_FAILURE = "NOTE_SAVE_NEW_REQUEST_FAILURE",
    NOTE_SAVE_EXISTING_REQUEST = "NOTE_SAVE_EXISTING_REQUEST",
    NOTE_SAVE_EXISTING_REQUEST_SUCCESS = "NOTE_SAVE_EXISTING_REQUEST_SUCCESS",
    NOTE_SAVE_EXISTING_REQUEST_FAILURE = "NOTE_SAVE_EXISTING_REQUEST_FAILURE";


export const notesRequest = makeActionCreator(NOTES_REQUEST, 'page', 'pageSize', 'filter');
export const notesRequestSuccess = makeActionCreator(NOTES_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const notesRequestFailure = makeActionCreator(NOTES_REQUEST_FAILURE, 'error');
export const noteSaveNewRequest = makeActionCreator(NOTE_SAVE_NEW_REQUEST);
export const noteSaveNewRequestSuccess = makeActionCreator(NOTE_SAVE_NEW_REQUEST_SUCCESS);
export const noteSaveNewRequestFailure = makeActionCreator(NOTE_SAVE_NEW_REQUEST_FAILURE, 'error');
export const noteSaveExistingRequest = makeActionCreator(NOTE_SAVE_EXISTING_REQUEST);
export const noteSaveExistingRequestSuccess = makeActionCreator(NOTE_SAVE_EXISTING_REQUEST_SUCCESS);
export const noteSaveExistingRequestFailure = makeActionCreator(NOTE_SAVE_EXISTING_REQUEST_FAILURE, 'error');
export const noteNewUpdate = makeActionCreator(NOTE_UPDATE_NEW, 'note');
export const noteExistingUpdate = makeActionCreator(NOTE_UPDATE_EXISTING, 'note');
export const noteResetExisting = makeActionCreator(NOTE_RESET_EXISTING);
export const notesRefresh = makeActionCreator(NOTES_REFRESH);