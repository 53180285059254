import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '../../../resources/PersonIcon';
import IdCardIcon from '../../../resources/IdCardIcon';
import TickIcon from '../../../resources/TickIcon';
import DocumentIcon from '../../../resources/DocumentIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import ImagePreview from '../../common/ImagePreview';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import Thumbnail from '../../common/Thumbnail';
import InmatePhoto from './InmatePhoto';
import Box from '@material-ui/core/Box';

export interface IInmateSelectRowProps {
    correctionalFacilityInMate: AusComplyDtos.CorrectionalFacilityInMate;
    onSelect: Function;
    theme: any;
}

class InmateSelectRow extends Component<IInmateSelectRowProps, any> {
    constructor(props: IInmateSelectRowProps) {
        super(props)
    }

    render() {
        const { theme } = this.props;


        return (
            <>
                <Box p={0} display="flex" flexDirection={'row'} style={{ borderBottom: '1px dotted #545454', paddingBottom: '10px' }}>
                    <Box flexGrow={1} p={0}>
                        <ControlGroup text="CNI Number">
                            <TextDisplay>{this.props.correctionalFacilityInMate.cniNumber}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="Master Index Number">
                            <TextDisplay>{this.props.correctionalFacilityInMate.masterIndexNumber}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="Name">
                            <TextDisplay>{this.props.correctionalFacilityInMate.firstName} {this.props.correctionalFacilityInMate.lastName}</TextDisplay>
                        </ControlGroup>
                    </Box>
                    <Box p={0}>
                        {this.props.correctionalFacilityInMate.photo && (
                            <Box display="flex" flexDirection="column" p={0} style={{ height: '100%' }}>
                                <Box p={0} flex={1}></Box>
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + this.props.correctionalFacilityInMate.photo.filePreviewPath}
                                        previewPath={this.props.correctionalFacilityInMate.photo.filePreviewPath}
                                        displayPath={this.props.correctionalFacilityInMate.photo.fileDisplayPath}
                                        isImage={this.props.correctionalFacilityInMate.photo.isImage}
                                        text={this.props.correctionalFacilityInMate.photo.filePreviewText}
                                        fullText={this.props.correctionalFacilityInMate.photo.originalName}
                                        style={{ marginTop: '8px' }}
                                    /></Box>
                                <Box p={0} flex={1}></Box>
                            </Box>
                        )}
                        {!this.props.correctionalFacilityInMate.photo && (
                            <Box display="flex" flexDirection="column" p={0} style={{ height: '100%' }}>
                                <Box p={0} flex={1}></Box>
                                <Box p={0}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" p={0}
                                        style={{
                                            position: 'relative',
                                            textAlign: 'center',
                                            width: '88px',
                                            margin: '8px',
                                            height: '84px',
                                            paddingTop: '4px',
                                            borderRadius: '3px',
                                            border: '1px solid #636364'
                                        }}>
                                        <Box p={0}></Box>
                                        <Box p={0}>
                                            <PersonIcon style={{ fontSize: '2.5em' }} />
                                        </Box>
                                        <Box p={0}></Box>
                                    </Box>
                                </Box>
                                <Box p={0} flex={1}></Box>
                            </Box>
                        )}
                    </Box>
                    <Box p={0}>
                        <Box display="flex" flexDirection="column" p={0} style={{ height: '100%' }}>
                            <Box p={0} flex={1}></Box>
                            <Box p={0}>
                                <TransparentButton text={"Select"} onClick={() => this.props.onSelect(this.props.correctionalFacilityInMate)} />
                            </Box>
                            <Box p={0} flex={1}></Box>
                        </Box>
                    </Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(InmateSelectRow);