import React from 'react';
import { RadioOption } from '../../common/dto/common';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export interface IRadioControlProps {
    name: string;
    leftAlignRadios?: boolean;
    selected?: string;
    options?: RadioOption[];
    onChange?: Function;
    readonly?: boolean;
}

export interface IRadioControlState {
    selected: string;
    options: RadioOption[];
    displayOptions: RadioOption[];
}

class RadioControl extends React.PureComponent<IRadioControlProps, IRadioControlState> {

    constructor(props: IRadioControlProps) {
        super(props);

        this.state = {
            selected: "",
            options: [],
            displayOptions: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    async componentDidMount() {
        var selected = this.props.selected || "";
        var options = this.props.options || [];
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        this.setState({
            selected,
            options,
            displayOptions
        });
    }

    componentDidUpdate() {
        var selected = this.props.selected || this.state.selected;
        var options = this.props.options || this.state.options;
        let displayOptions = options.filter(item => !this.props.readonly || item.id === selected);
        if (selected != this.state.selected ||
            JSON.stringify(options) != JSON.stringify(this.state.options) ||
            JSON.stringify(displayOptions) != JSON.stringify(this.state.displayOptions)){
                this.setState({
                selected,
                options,
                displayOptions
            });
        }
    }

    handleChange = event => {
        let val = event.target.value;
        if (val != null) {
            this.setState({ selected: val });
            if (this.props.onChange) {
                this.props.onChange({ value: val });
            }
        }
    };

    render() {

        let radioOptions = this.state.displayOptions.map((item, index) => {
            return (
                <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} style={{ minWidth: "185px" }} />
            )
        });

        return (
            <FormControl>
                <RadioGroup
                    value={this.state.selected}
                    style={{ flexDirection: "row" }}
                    onChange={this.handleChange}>
                    {radioOptions}
                </RadioGroup>
            </FormControl>
        );
    }
}

export default RadioControl;