import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { withRouter } from "react-router";
import ControlGroup from '../controls/ControlGroup';
import Saving from '../common/Saving';
import View from '../common/View';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import DefaultButton from '../controls/DefaultButton';
import PrintIcon from '../../resources/PrintIcon';

export interface IIncidentCategoryTypeDocumentsProps {
    wrapInCard?: boolean;
    incidentId: number;
    isBuilding: boolean;
    buildingIncidentCategoryTypeDocumentId: number;
    buildingIncidentId: number;
    incidentCategoryTypeDocuments: AusComplyDtos.IncidentCategoryTypeDocument[];
    onBuild: Function;
}

class IncidentCategoryTypeDocuments extends Component<IIncidentCategoryTypeDocumentsProps, any> {

    constructor(props: IIncidentCategoryTypeDocumentsProps) {
        super(props)
        this.state = {
        };
    }

    render() {
        if (!this.props.incidentId || this.props.incidentId === 0 || !this.props.incidentCategoryTypeDocuments || this.props.incidentCategoryTypeDocuments.length === 0) {
            return <></>
        }

        let content = <>
            {this.props.isBuilding ? <Saving message='Building document...' /> : <>
                {this.props.incidentCategoryTypeDocuments.map((item, index) =>
                    <DefaultButton style={{ width: "auto" }}
                        onClick={() => this.props.onBuild(this.props.incidentId, item.incidentCategoryTypeDocumentId)}
                        disabled={this.props.isBuilding}>
                        <View style={{ width: '18px', height: '18px', marginRight: '5px' }}>
                            <PrintIcon fill={"#000"} />
                        </View>
                        {item.name}</DefaultButton>
                )}</>}
        </>;

        if (this.props.wrapInCard) {
            return <CardContainer
                title={"Generate Incident Document"}>
                <CardContent>
                    {content}
                </CardContent>
            </CardContainer>;
        }

        return (
            <ControlGroup text='Generate Incident Document'>
                {content}
            </ControlGroup>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(IncidentCategoryTypeDocuments));