import React from 'react';

export interface FormErrorMessageProps {
    title?: string;
    error?: string;
    style? : any;
}

class FormErrorMessage extends React.PureComponent<FormErrorMessageProps, any> {
    render() {
        if (!this.props.error) {
            return <div></div>;
        }
        return (
            <div style={{
                padding: '10px',
                textAlign: 'center',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '0',
                background: '#d2242b',
                borderColor: '#d47d7d',
                color: 'white',
                marginBottom: '10px',
                ...this.props.style
                }}>
                { this.props.title && (
                    <h4 style={{margin: 0}}>{this.props.title}</h4>
                )}
                <p style={{margin: 0}}>
                    {this.props.error}
                </p>
            </div>
        );
    }
}

export default FormErrorMessage;