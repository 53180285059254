import React from 'react';
import { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import classNames from 'classnames';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import PopupModal from '../layout/PopupModal';
import { KeyName } from '../../common/dto/common';

export interface IDefaultFabProps {
    classes: any;
    theme: any;
    disabled?: boolean;
    onClick?: Function;
    actions?: KeyName[];
    isExtended?: boolean;
}

class DefaultFab extends Component<IDefaultFabProps, any> {
    constructor(props: IDefaultFabProps) {
        super(props)
        this.state = {
            open: false
        }
        this.onClick = this.onClick.bind(this);
        this.itemClicked = this.itemClicked.bind(this);
        this.showMenu = this.showMenu.bind(this);
    }

    showMenu = () => {
        this.setState({ open: true });
    };

    itemClicked = option => {
        this.setState({ open: false });
        if (option != "" && this.props.onClick) {
            this.props.onClick(option);
        }
    }

    onClick() {
        if (this.props.actions) {
            this.setState({ open: true });
        } else {
            if (this.props.onClick) {
                this.props.onClick();
            }
        }
    }

    render() {

        const { classes, theme } = this.props;
        let className = classNames(classes.fab, classes.fabDefault);
        let menuControls;
        if (this.props.actions) {
            menuControls = (
                <PopupModal open={this.state.open} onAction={action => this.itemClicked(action)} actions={this.props.actions} />
            );
        }
        if (this.props.children !== undefined) {
            if (this.props.isExtended) {
                return (
                    <>
                        <Fab
                            className={className}
                            color={'inherit'}
                            variant="extended"
                            size="small"
                            disabled={this.props.disabled}
                            onClick={() => this.onClick()}
                             >
                            {this.props.children || <></>}
                        </Fab>
                        {menuControls}
                    </>
                );
            }
            return (
                <>
                    <Fab
                        className={className}
                        color={'inherit'}
                        size="small"
                        disabled={this.props.disabled}
                        onClick={() => this.onClick()} >
                        {this.props.children || <></>}
                    </Fab>
                    {menuControls}
                </>
            );
        } else {
            if (this.props.isExtended) {
                return (
                    <>
                        <Fab
                            className={className}
                            color={'inherit'}
                            variant="extended"
                            size="small"
                            disabled={this.props.disabled}
                            onClick={() => this.onClick()} >
                        </Fab>
                        {menuControls}
                    </>
                );
            }
            return (
                <>
                    <Fab
                        className={className}
                        color={'inherit'}
                        size="small"
                        disabled={this.props.disabled}
                        onClick={() => this.onClick()} >
                    </Fab>
                    {menuControls}
                </>
            );
        }
    }
}


export default withStyles(styles, { withTheme: true })(DefaultFab);