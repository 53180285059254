import { connect } from 'react-redux'
import UserView from '../components/users/user/UserView'
import {
    userRolesUserViewRequest
} from '../common/actions/userRole'

const mapStateToProps = (state, props) => {
    return {
        userId:  props.userId,
        isLoading: state.userRole.userView.isLoading,
        userView: state.userRole.userView.userView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (userId) => {
            dispatch(userRolesUserViewRequest(userId))
        }
    }
}

const UserViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserView)

export default UserViewContainer