import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import FileUpload from '../../common/FileUpload';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IRegisterVenueDocumentsProps {
    venue: AusComplyDTOs.Venue;
    documentTypes: AusComplyDTOs.DocumentType[];
    documents: AusComplyDTOs.File[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    classes: any;
    theme: any;
}

interface IRegisterVenueDocumentsState {
    selectedFilePath: string;
    documentTypeId: number;
}

class RegisterVenueDocuments extends Component<IRegisterVenueDocumentsProps, IRegisterVenueDocumentsState> {
    constructor(props: IRegisterVenueDocumentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            documentTypeId: 0
        };
        this.onChange = this.onChange.bind(this);
        this.onDocumentTypeChanged = this.onDocumentTypeChanged.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeTemporary = this.removeTemporary.bind(this);
        this.documentTypeDescription = this.documentTypeDescription.bind(this);
    }

    onSelectFilePath(filePath) {
        this.setState({
            selectedFilePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    onChange(value) {
        /*
        let venue = { ...this.props.venue };
        venue. = value;
        this.props.onUpdate(venue);
        */
    }

    fileWasUploaded(temporaryFiles: AusComplyDTOs.File[]) {
        temporaryFiles.forEach(t => {
            t.documentTypeId = this.state.documentTypeId;
        });
        let documents = [...this.props.documents, ...temporaryFiles];
        this.props.onUpdate(documents);

    }

    documentTypeDescription(documentTypeId) {
        let value = "";
        this.props.documentTypes.forEach(dt => {
            if (dt.documentTypeId == documentTypeId) {
                value = dt.name;
            }
        });
        return value;
    }

    removeTemporary(index: number) {
        let documents = [...this.props.documents];
        documents.splice(index, 1);
        this.props.onUpdate(documents);
    }

    onDocumentTypeChanged(value) {
        this.setState({ documentTypeId: value });
    }

    render() {
        const { classes, theme } = this.props;


        let files = this.props.documents.map((item, index) => {
            let description = this.documentTypeDescription(item.documentTypeId);
            return (
                <Box p={0} key={"file-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        title={description}
                        onPreview={path => this.onSelectFilePath(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporary(index)}
                    />
                </Box >
            );
        })

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Other Documents"}>
                    <CardContent>
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                            {files}
                        </Box>
                        <SelectControlGroup text={"Document Folder"} onChange={this.onDocumentTypeChanged}>
                            <option value={0} />
                            {this.props.documentTypes.map((item, index) => (
                                <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                            ))}
                        </SelectControlGroup>
                        {this.state.documentTypeId != 0 && (
                            <>
                                <FileUpload onFileWasUploaded={this.fileWasUploaded} alternateIcons={true}  />
                            </>
                        )}
                    </CardContent>
                </CardContainer>
                <ImagePreview filePath={this.state.selectedFilePath} onDismissed={() => this.onSelectFilePath("")} />
            </SwipeContainer>
        );
    }
}
export default withStyles(styles, { withTheme: true })(RegisterVenueDocuments);