import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionWatchlistMove from '../components/facialRecognition/facialRecognitionWatchlists/FacialRecognitionWatchlistMove'
import * as facialRecognitionWatchlistActions from '../common/actions/facialRecognitionWatchlist';

const mapStateToProps = (state, props) => {
    return {
        move: state.facialRecognitionWatchlists.facialRecognitionWatchlistMove.facialRecognitionWatchlistMove,
        isLoading: state.facialRecognitionWatchlists.facialRecognitionWatchlistMove.isLoading,
        isSaving: state.facialRecognitionWatchlists.facialRecognitionWatchlistMove.isSaving
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (facialRecognitionWatchlistMove) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistMoveSet(facialRecognitionWatchlistMove))
        },
        onSave: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistMoveRequest())
        },
        onReLoad: (venueId, groupId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistsRequest(venueId, groupId))
        },
    }
}

const FacialRecognitionWatchlistMoveContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionWatchlistMove))

export default FacialRecognitionWatchlistMoveContainer