import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import * as radioChannelActions from "../actions/radioChannel";

const initialState = {
    error: null,
    errors: {},
    radioChannels: {
        isLoading: false,
        filter: {},
        radioChannels: [],
        paging: { pageSize: 10, page: 1 }
    },
    radioChannel: {
        radioChannelId: -1,
        isLoading: false,
        isSaving: false,
        radioChannel: { radioChannelId: 0 }
    },
};


function loadingRadioChannels(state, isLoading) {
    let newState = update(state, {
        radioChannels: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRadioChannels(state, radioChannels, filter, paging) {
    let newState = update(state, {
        radioChannels: {
            radioChannels: { $set: radioChannels },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingRadioChannel(state, isLoading) {
    let newState = update(state, {
        radioChannel: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRadioChannel(state, radioChannel) {
    let newState = update(state, {
        radioChannel: {
            radioChannel: { $set: radioChannel }
        }
    });
    return newState;
}

function savingRadioChannel(state, isSaving) {
    let newState = update(state, {
        radioChannel: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setRadioChannelId(state, radioChannelId) {
    let newState = update(state, {
        radioChannel: {
            radioChannelId: { $set: radioChannelId }
        }
    });
    return newState;
}

export default function radioChannels(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        
        case radioChannelActions.RADIOCHANNELS_REFRESH:
            return loadingRadioChannels(setRadioChannelId(state, -1), true);
        case radioChannelActions.RADIOCHANNELS_REQUEST:
            return setRadioChannels(loadingRadioChannels(setRadioChannelId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case radioChannelActions.RADIOCHANNELS_REQUEST_SUCCESS:
            return setRadioChannels(loadingRadioChannels(state, false), action.data, action.filter, action.paging);
        case radioChannelActions.RADIOCHANNELS_REQUEST_FAILURE:
            return loadingRadioChannels(state, false);
        case radioChannelActions.RADIOCHANNEL_RESET:
            return setRadioChannelId(state, -1);
        case radioChannelActions.RADIOCHANNEL_REQUEST:
            return setRadioChannel(loadingRadioChannel(setRadioChannelId(state, action.radioChannelId), true), {});
        case radioChannelActions.RADIOCHANNEL_REQUEST_SUCCESS:
            return setRadioChannel(loadingRadioChannel(state, false), action.radioChannel);
        case radioChannelActions.RADIOCHANNEL_REQUEST_FAILURE:
            return loadingRadioChannel(setRadioChannelId(state, -1), false);
        case radioChannelActions.RADIOCHANNEL_CREATE_REQUEST:
            return setRadioChannel(loadingRadioChannel(setRadioChannelId(state, 0), true), {});
        case radioChannelActions.RADIOCHANNEL_CREATE_REQUEST_SUCCESS:
            return setRadioChannel(loadingRadioChannel(state, false), action.radioChannel);
        case radioChannelActions.RADIOCHANNEL_CREATE_REQUEST_FAILURE:
            return loadingRadioChannel(setRadioChannelId(state, -1), false);
        case radioChannelActions.RADIOCHANNEL_SAVE_REQUEST:
            return savingRadioChannel(state, true);
        case radioChannelActions.RADIOCHANNEL_SAVE_REQUEST_SUCCESS:
            return setRadioChannel(savingRadioChannel(setRadioChannelId(state, -1), false), action.radioChannel);
        case radioChannelActions.RADIOCHANNEL_SAVE_REQUEST_FAILURE:
            return savingRadioChannel(state, false);
        case radioChannelActions.RADIOCHANNEL_UPDATE:
            return setRadioChannel(state, action.radioChannel);

    }

    return state;
}