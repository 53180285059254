import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import SubTitle from '../../common/SubTitle';
import Grid from '@material-ui/core/Grid';
import DarkButton from '../../controls/DarkButton';
import * as AusComplyDtos from '../../../common/dto/AusComply.dtos';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IIncidentVersionsProps {
    incidentId: number;
    isLoading: boolean;
    incidentVersions: AusComplyDtos.IncidentVersion[];
    onRefresh: Function;
    history: any;
}

interface IIncidentVersionsState {

}

class IncidentVersions extends Component<IIncidentVersionsProps, IIncidentVersionsState> {

    constructor(props: IIncidentVersionsProps) {
        super(props)

        this.state = {
        }

        this.onCancel = this.onCancel.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line
        this.props.onRefresh(this.props.incidentId);
    }

    componentDidUpdate(preProps) {
    }

    onCancel() {
        this.props.history.push('/incidentview/' + this.props.incidentId);
    }

    onClick(incidentVersion: AusComplyDtos.IncidentVersion) {
        let securityFirmId = "0";
        if (incidentVersion.securityFirmId) {
            securityFirmId =incidentVersion.securityFirmId.toString();
        }
        this.props.history.push('/incidentview/' + this.props.incidentId + "/versions/" + securityFirmId + "/" + incidentVersion.incidentRelevantID + "/" + incidentVersion.incidentModificationCount);
    }

    render() {

        let lastSecurityFirm = -1;
        let lastVenue = -1;
        let items;
        if (!this.props.isLoading) {
            items = this.props.incidentVersions.map((item, index) => {
                let subheading;
                if (item.venueId && !item.securityFirmId && item.venueId != lastVenue) {
                    lastVenue = item.venueId;
                    subheading = (<SubTitle text={item.venueName} />);
                } else if (item.securityFirmId && item.securityFirmId != lastSecurityFirm) {
                    lastSecurityFirm = item.securityFirmId;
                    subheading = (<SubTitle text={item.securityFirmName} />);
                }

                return (
                    <div key={item.relevantIncidentID + "_" + item.securityFirmId + "_" + item.incidentModificationCount}>
                        {subheading}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <DarkButton text={item.incidentSerialNumber + " / " + item.incidentModificationCount} onClick={() => this.onClick(item)} />
                            </Grid>
                        </Grid>

                    </div>
                );
            });
        }

        return <PageLayout
            headerText={"Incident Versions"}
            footerLeftContent={<TransparentButton text={"Close"} onClick={() => this.onCancel()} />}
            loading={this.props.isLoading}
            hideQuickActions={true}
            >
            {items}
        </PageLayout>
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(IncidentVersions));