import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ResponsiveList from '../common/ResponsiveList';
import FabContainer from '../layout/FabContainer';
import FullPageDialog from '../layout/FullPageDialog';
import DefaultFab from '../common/DefaultFab';
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';
import LayoutForm from '../layout/LayoutForm';
import Error from '../common/Error';
import TextControlGroup from '../controls/TextControlGroup';
import PasswordControlGroup from '../controls/PasswordControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import FormWarningMessage from '../alerts/FormWarningMessage';
import { KeyName } from '../../common/dto/common';
import Information from '../common/Information';
import { TimerSharp } from '@material-ui/icons';
import SubTitle from '../common/SubTitle';
import View from '../common/View';
import ControlGroup from '../controls/ControlGroup';
import PageLayout from '../layout/PageLayout';
import VenueTabs from '../admin/venue/VenueTabs';
import TransparentButton from '../controls/TransparentButton';
import FacialRecognitionTabs from '../admin/venue/facialRecognition/FacialRecognitionTabs';
import TextDisplay from '../controls/TextDisplay';
import NoRowsMessage from '../common/NoRowsMessage';
import Notification from '../notifications/Notification';


export interface IScheduleLogsProps {
    scheduleId: number;
    schedule: AusComplyDtos.Schedule;
    isLoading: boolean;
    scheduleLogs: AusComplyDtos.ScheduleLog[];
    paging: AusComplyDtos.Paging;
    onLoad: Function;
    onDelete: Function;
    onLoadSchedule: Function;
    history: any;
    location: any;
}

export default class ScheduleLogs extends Component<IScheduleLogsProps, any> {
    constructor(props: IScheduleLogsProps) {
        super(props)
        this.state = {
            scheduleLogId: 0,
            notifications: []
        };
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.props.onLoadSchedule(this.props.scheduleId);
        this.props.onLoad(this.props.scheduleId, this.props.paging);
    }

    getCommands(scheduleLog: AusComplyDtos.ScheduleLog) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Show Notifications" });
        if (!scheduleLog.success) {
            commands.push({ key: "delete", name: "Delete" });
        }
        return commands;
    }

    onCommand(command: string, scheduleLog: AusComplyDtos.ScheduleLog) {
        switch (command) {
            case "edit":
                this.setState({ scheduleLogId: scheduleLog.scheduleId, notifications: scheduleLog.notifications || [] });
                break;
            case "delete":
                this.props.onDelete(scheduleLog.scheduleLogId, scheduleLog.scheduleId, this.props.paging);
                break;
            default:
                break;
        }
    }

    onClose() {
        var path = this.props.location.pathname.split('/');
        var updatedPath = path.pop();
        updatedPath = path.pop();
        var url = path.join('/');
        this.props.history.push(url);
    }

    render() {
        let self = this;
        return (
            <PageLayout
                headerText={"Scheduled Task Logs"}
                loading={this.props.isLoading}
                footerLeftContent={<PrimaryButton text={"Close"} onClick={() => { this.onClose() }} />}
            >
                <TextDisplay style={{ marginBottom: '10px' }}>{this.props.schedule.display}</TextDisplay>
                <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.scheduleLogs}
                    headers={["Date", "Created", "Due", "Escalate", "Submitted", "By", "Notification(s)", "Error"]}
                    columns={["dateEnteredDisplay", "created", "dueDateDisplay", "escalateDateDisplay", "submittedDateDisplay", "submittedBy", "notifications", "errorMessage"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isWarning={item => !item.submitted}
                    isWarningLabel={"Draft"}
                    isDanger={item => !item.success}
                    isDangerLabel={"Error"}
                    toggleObsolete={false}
                    canEdit={true}
                    onFormatValue={(column, value, data, row) => {
                        if (column == "notifications") {
                            return row.notifications.length.toString();
                        }
                        return value;
                    }}
                />
                <FullPageDialog
                    open={this.state.scheduleLogId > 0}
                    title="Notifications"
                    footerLeftContent={<DefaultButton text={"Close"} onClick={() => self.setState({ scheduleLogId: 0, notifications: [] })}></DefaultButton>}
                    onDismissed={() => self.setState({ scheduleLogId: 0, notifications: [] })}>
                    {this.state.notifications.length == 0 && <NoRowsMessage message={"No notifications were generated"}  />}
                    {this.state.notifications.map((notification, index) => <div key={notification.notificationId}>
                        <p>{notification.userDisplay}</p>
                        <Notification 
                            notification={notification} 
                            canAccept={true} 
                            history={this.props.history}
                            canDismiss={true} />
                    </div>)}
                </FullPageDialog>
            </PageLayout>
        );
    }
    
}