import { connect } from 'react-redux' ;
import PrintQueue from '../components/print/PrintQueue';
import { printQueueDismissRequest, printQueueRequest, printQueueDownloadRequest, printQueueDismissAllRequest } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = (state) => {
    return {
        queue: state.print.queue
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRefresh:() => {
            dispatch(printQueueRequest())
        },
        onDownloaded:(reportGenerationId) => {
            dispatch(printQueueDownloadRequest(reportGenerationId))
        },
        onDismissed:(reportGenerationId) => {
            dispatch(printQueueDismissRequest(reportGenerationId))
        },
        onDismissAll:() => {
            dispatch(printQueueDismissAllRequest())
        }
    }
}

const PrintQueueContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PrintQueue)

export default PrintQueueContainer