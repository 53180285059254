import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PrimaryButton from '../controls/PrimaryButton';
import DefaultButton from '../controls/DefaultButton';
import SelectControlGroup from '../controls/SelectControlGroup';
import TextAreaControlGroup from '../controls/TextAreaControlGroup';
import TimeZoneDateControlGroup from '../controls/TimeZoneDateControlGroup';
import FullPageDialog from '../layout/FullPageDialog';

export interface ILeaveRequestProps {
    open: boolean;
    leaveRequest: AusComplyDtos.LeaveRequest;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    onNew: Function;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ILeaveRequestState {
}

export default class LeaveRequest extends Component<ILeaveRequestProps, ILeaveRequestState> {
    constructor(props: ILeaveRequestProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.notValid = this.notValid.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && !this.props.isSaving) {
            if (this.props.open && !prevProps.open) {
                this.props.onNew();
            }
            if (prevProps.isSaving) {
                if (!this.props.error) {
                    this.onCancel();
                }
            }
        }
    }

    onSave() {
        this.props.onSave(this.props.leaveRequest);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let leaveRequest = { ...this.props.leaveRequest };
        leaveRequest[fieldName] = value;
        if (fieldName == "fromDate" && !leaveRequest.toDate.isEmpty) {
            const fromDate = new Date(leaveRequest.fromDate.year, leaveRequest.fromDate.month - 1, leaveRequest.fromDate.day, leaveRequest.fromDate.hour, leaveRequest.fromDate.minute, 0, 0);
            const toDate = new Date(leaveRequest.toDate.year, leaveRequest.toDate.month - 1, leaveRequest.toDate.day, leaveRequest.toDate.hour, leaveRequest.toDate.minute, 0, 0);
            if (fromDate > toDate) {
                leaveRequest["toDate"] = value;
            }
        }
        if (fieldName == "toDate" && !leaveRequest.fromDate.isEmpty) {
            const fromDate = new Date(leaveRequest.fromDate.year, leaveRequest.fromDate.month - 1, leaveRequest.fromDate.day, leaveRequest.fromDate.hour, leaveRequest.fromDate.minute, 0, 0);
            const toDate = new Date(leaveRequest.toDate.year, leaveRequest.toDate.month - 1, leaveRequest.toDate.day, leaveRequest.toDate.hour, leaveRequest.toDate.minute, 0, 0);
            if (fromDate > toDate) {
                leaveRequest["fromDate"] = value;
            }
        }
        if (fieldName == "companyId" && this.props.leaveRequest.companyId != leaveRequest.companyId) {
            leaveRequest["leaveTypeId"] = 0;
        }
        this.props.onUpdate(leaveRequest)
    }

    notValid() {
        if (this.props.isLoading) return true;
        if (this.props.isSaving) return true;
        if (!this.props.leaveRequest) return true;
        if (this.props.leaveRequest.companyId == 0) return true;
        if (this.props.leaveRequest.leaveTypeId == 0) return true;
        if (this.props.leaveRequest.fromDate && this.props.leaveRequest.fromDate.isEmpty ) return true;
        if (this.props.leaveRequest.toDate && this.props.leaveRequest.toDate.isEmpty ) return true;
        return false;
    }

    render() {
        let leaveRequest;
        let disabled = this.notValid();
        if (!this.props.isLoading && this.props.leaveRequest) {
            let leaveTypes: AusComplyDtos.NamedValue[] = [];
            if (this.props.leaveRequest.companyId > 0) {
                if (this.props.leaveRequest.companies) {
                    leaveTypes = this.props.leaveRequest.leaveTypes.filter(f => f.parentId == this.props.leaveRequest.companyId)
                }
            }
            leaveRequest = <>
                <SelectControlGroup
                    text="Business"
                    defaultValue={this.props.leaveRequest.companyId}
                    onChange={(value) => this.onValueChanged("companyId", Number(value))}>
                    {(!this.props.leaveRequest.companies || this.props.leaveRequest.companies.length === 0 || this.props.leaveRequest.companyId == 0) &&
                        <option value={0} />
                    }
                    {this.props.leaveRequest.companies && this.props.leaveRequest.companies.map((item) => (
                        <option key={"company-" + item.id} value={item.id}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <SelectControlGroup
                    text="Leave Type"
                    defaultValue={this.props.leaveRequest.leaveTypeId}
                    onChange={(value) => this.onValueChanged("leaveTypeId", value)}>
                    <option value={0} />
                    {leaveTypes && leaveTypes.map((item) => (
                        <option key={"leave-type-" + item.id} value={item.id}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <TimeZoneDateControlGroup
                    text={"From date"}
                    futureOnly={true}
                    onChange={(value) => this.onValueChanged("fromDate", value)}
                    date={this.props.leaveRequest.fromDate} />
                <TimeZoneDateControlGroup
                    text={"To date"}
                    futureOnly={true}
                    onChange={(value) => this.onValueChanged("toDate", value)}
                    date={this.props.leaveRequest.toDate} />
                <TextAreaControlGroup
                    text={"Notes"}
                    defaultValue={this.props.leaveRequest.notes}
                    onBlur={(value) => this.onValueChanged("notes", value)} />
            </>
        }
        return (
            <FullPageDialog
                open={this.props.open}
                footerRightContent={<PrimaryButton text={"Save"} disabled={disabled} onClick={this.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                loading={this.props.isLoading}
                error={this.props.error}
                title="Leave Request" onDismissed={() => this.onCancel()}>
                {leaveRequest}
            </FullPageDialog >
        );
    }
}