import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const facialRecognitionNotificationServiceApi = {
  create: (venueId, securityFirmId, forVenueId, forSecurityFirmId, facialRecognitionNotification) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.facialRecognitionNotification = facialRecognitionNotification
    const request = client.post(query);
    return from(request);
  },
  defaultFilter:(venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionNotificationFilter();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  find:(venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionNotifications();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  get:(venueId, securityFirmId, facialRecognitionNotificationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionNotificationId = facialRecognitionNotificationId;
    const request = client.get(query);
    return from(request);
  },
  getEvents:(venueId, securityFirmId, forVenueId, forGroupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionEvent();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forGroupId = forGroupId;
    const request = client.get(query);
    return from(request);
  },
  saveEvents:(venueId, securityFirmId, facialRecognitionEvent) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionEvent();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionEvent = facialRecognitionEvent;
    const request = client.post(query);
    return from(request);
  },
  updateEvents:(venueId, securityFirmId, facialRecognitionEvents) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionEvents();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionEvents = facialRecognitionEvents;
    const request = client.post(query);
    return from(request);
  },
  getCameras:(venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionCameras();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.get(query);
    return from(request);
  },
  saveCameras:(venueId, securityFirmId, facialRecognitionCameras) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionCameras();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionCameras = facialRecognitionCameras;
    const request = client.post(query);
    return from(request);
  },
  saveCamera:(venueId, securityFirmId, facialRecognitionCamera) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionCamera();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionCamera = facialRecognitionCamera;
    const request = client.post(query);
    return from(request);
  },
  syncCameras: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionSyncCameras();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  saveCameraStatus:(venueId, securityFirmId, facialRecognitionCameraId, cameraStatus, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionCameraStatus();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionCameraId = facialRecognitionCameraId;
    query.cameraStatus = cameraStatus;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  process:(venueId, securityFirmId, facialRecognitionNotificationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostProcessFacialRecognitionNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionNotificationId = facialRecognitionNotificationId;
    const request = client.post(query);
    return from(request);
  },
  newCameraImport: request.makePost(
    AusComplyDtos.PostFacialRecognitionCameraNewImport,
    'venueId', 'securityFirmId', 'forVenueId'
  ),
  cameraImport: request.makePost(
    AusComplyDtos.PostFacialRecognitionCameraImport,
    'venueId', 'securityFirmId', 'facialRecognitionCameraImports'
  ),
};
