import React, { Component } from 'react';

interface ILabelAlertProps {
    text: string;
}

class LabelAlert extends React.Component<ILabelAlertProps, any> {

    constructor(props: ILabelAlertProps) {
        super(props);
    }

    render() {
        
        return (
            <p style={{display: "inline-block", borderRadius: '3px', borderColor: 'white', borderStyle: "solid", borderWidth: '1px', background: "red", color: "white", margin: '3px', padding: '5px', textAlign: 'center'}}>
                {this.props.text}
            </p>
        );
    }
}

export default LabelAlert;
