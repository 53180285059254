import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICardTypographyProps {
    theme: any;
    classes: any;
    text?: string;
    isObsolete?: boolean;
    style?: any;
}

class CardTypography extends React.PureComponent<ICardTypographyProps, any> {
    constructor(props: ICardTypographyProps) {
        super(props)
    }
    render() {
        const { classes } = this.props;
        return (
            <p
                className={this.props.isObsolete ? classes.typographyObsolete : classes.typography}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minWidth: 0, ...this.props.style
                }}
            >{this.props.text}{this.props.children}</p>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CardTypography);