import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CheckListItem from '../../checklists/checklist/CheckListItem';

export interface IChecklistTemplatePreviewProps {
    checklistTemplate: AusComplyDtos.ChecklistTemplate;
    isLoadingItem: boolean;
}

export default class ChecklistTemplatePreview extends Component<IChecklistTemplatePreviewProps, any> {
    constructor(props: IChecklistTemplatePreviewProps) {
        super(props)

        this.state = {
            preview: []
        };
    }

    componentDidMount() {
        if (this.props.checklistTemplate && this.props.checklistTemplate.checklistTemplateItems) {
            this.loadPreview(this.props.checklistTemplate);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoadingItem && prevProps.isLoadingItem && this.props.checklistTemplate && this.props.checklistTemplate.checklistTemplateItems) {
            this.loadPreview(this.props.checklistTemplate);
        }
    }

    loadPreview(checklistTemplate: AusComplyDtos.ChecklistTemplate) {
        let preview: AusComplyDtos.CheckListDisplayItem[] = [];
        let offset: number = 0;

        checklistTemplate.checklistTemplateItems.forEach(item => {
            if (item.level === 0) {
                var display = new AusComplyDtos.CheckListDisplayItem();
                display.offset = offset;
                display.template = item;
                display.item = new AusComplyDtos.ChecklistItem();
                display.isRequired = item.mandatory;
                display.show = true;
                preview.push(display);
                this.flattenForPreview(preview, item.groupedItems, offset + 1);
            }
        });

        this.setState({
            preview
        });
    }

    flattenForPreview(preview: AusComplyDtos.CheckListDisplayItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[], offset: number) {
        groupedItems.forEach(item => {
            var display = new AusComplyDtos.CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });
    }

    render() {

        let preview = this.state.preview.map((item, index) => {
            return <CheckListItem key={"checklistTemplateitem-" + index.toString()}
                checkListDisplayItem={item}
                checklistUserSelections={[]}
                onUploadAttachment={() => { }}
                onUpdate={(i) => { }}
                isReadonly={false}
                isUploading={false} />;
        });
        return (<> {preview}
        </>);
    }
}