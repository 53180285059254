import { connect } from 'react-redux'
import RoublerEmployeeMapping from '../components/users/mapping/RoublerEmployeeMapping'
import * as externalApiConnectionActions from '../common/actions/externalApiConnection'

const mapStateToProps = (state, props) => {
    return {
        roublerEmployeeMapping: state.externalApiConnections.roublerEmployeeMapping.roublerEmployeeMapping,
        mappings: state.externalApiConnections.roublerEmployeeMapping.roublerEmployeeMapping.mappings,
        suggested: state.externalApiConnections.roublerEmployeeMapping.roublerEmployeeMapping.suggested,
        users: state.externalApiConnections.roublerEmployeeMapping.roublerEmployeeMapping.users,
        employees: state.externalApiConnections.roublerEmployeeMapping.roublerEmployeeMapping.employees,
        isLoading: state.externalApiConnections.roublerEmployeeMapping.isLoading,
        isSaving: state.externalApiConnections.roublerEmployeeMapping.isSaving,
        error: state.externalApiConnections.roublerEmployeeMapping.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(externalApiConnectionActions.roublerEmployeeMappingRequest())
        },
        onUpdate: (entityMapping) => {
            dispatch(externalApiConnectionActions.roublerEmployeeMappingUpdateRequest(entityMapping))
        },
        onExport: (userId) => {
            dispatch(externalApiConnectionActions.roublerEmployeeExportRequest(userId))
        }
    }
}

const RoublerEmployeeMappingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RoublerEmployeeMapping)

export default RoublerEmployeeMappingContainer