import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../controls/TextControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SubTitle from '../../common/SubTitle';

export interface IIncidentFlagTypeSummaryProps {
    incidentFlagType: AusComplyDtos.IncidentFlagType;
}

interface IIncidentFlagTypeSummaryState {
}

export default class IncidentFlagTypeSummary extends Component<IIncidentFlagTypeSummaryProps, IIncidentFlagTypeSummaryState> {
    constructor(props: IIncidentFlagTypeSummaryProps) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {

        if (!this.props.incidentFlagType) {
            return (<></>);
        }




        return (
            <>
                <SubTitle text={"Details"} />
                <TextControlGroup
                    text={"Name"}
                    readonly={true}
                    defaultValue={this.props.incidentFlagType.name} />

                <TextControlGroup
                    text={"Rank"}
                    readonly={true}
                    defaultValue={this.props.incidentFlagType.rank.toString()} />

                <CheckboxControlGroup
                    label={" "}
                    text={"Additional Field"}
                    readonly={true}
                    defaultValue={this.props.incidentFlagType.additionalField} />
                {this.props.incidentFlagType.additionalField && (
                    <TextControlGroup
                        text={"Additional Field Name"}
                        readonly={true}
                        defaultValue={this.props.incidentFlagType.fieldLabel} />
                )}
            </>
        );
    }
}