import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IIndustryCategoryAddProps {
    open: boolean;
    defaultRank: number;
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IIndustryCategoryAddState {
    industryCategory: AusComplyDtos.IndustryCategory;
    error: string;
}

export default class IndustryCategoryAdd extends Component<IIndustryCategoryAddProps, IIndustryCategoryAddState> {
    constructor(props: IIndustryCategoryAddProps) {
        super(props)
        let industryCategory = new AusComplyDtos.IndustryCategory();
        industryCategory.rank = this.props.defaultRank;
        let error = "";
        this.state = {
            industryCategory,
            error
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
        let industryCategory = new AusComplyDtos.IndustryCategory();
        industryCategory.rank = this.props.defaultRank;
        industryCategory.activityMonitorTimeout = 120;
        industryCategory.activityNotificationTimeout = 480;
        this.setState({
            industryCategory,
            error: ""
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.error) {
                this.setState({ error: this.props.error });
            } else {
                this.props.onCancel();
            }
        }
    }

    onSave() {
        this.props.onSave(this.state.industryCategory);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let industryCategory = { ...this.state.industryCategory };
        industryCategory[fieldName] = value;
        this.setState({ industryCategory });
    }

    render() {

        let rank = "0";
        if (this.state.industryCategory.rank) {
            rank = this.state.industryCategory.rank.toString();
        }
        let activityMonitorTimeout = "0";
        if (this.state.industryCategory.activityMonitorTimeout) {
            activityMonitorTimeout = this.state.industryCategory.activityMonitorTimeout.toString();
        }
        let activityNotificationTimeout = "0";
        if (this.state.industryCategory.activityNotificationTimeout) {
            activityNotificationTimeout = this.state.industryCategory.activityNotificationTimeout.toString();
        }

        return (
            <FullPageDialog
                open={this.props.open}
                footerRightContent={<PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>}
                footerLeftContent={<DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>}
                notFullWidth={true}
                saving={this.props.isSaving}
                error={this.props.error}
                title="New Industry" onDismissed={() => this.onCancel()}>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.state.industryCategory.name}
                    onChange={value => this.onValueChanged("name", value)} />
                <TextControlGroup
                    text={"Rank"}
                    numericOnly={true}
                    defaultValue={rank}
                    onChange={value => this.onValueChanged("rank", value)} />
                <TextControlGroup
                    text={"Require re-login if in-active for minutes"}
                    numericOnly={true}
                    defaultValue={activityMonitorTimeout}
                    onChange={value => this.onValueChanged("activityMonitorTimeout", value)} />
                <TextControlGroup
                    text={"Considered logged-in even if in-active for minutes"}
                    numericOnly={true}
                    defaultValue={activityNotificationTimeout}
                    onChange={value => this.onValueChanged("activityNotificationTimeout", value)} />
                <CheckboxControlGroup
                    text={"Active"}
                    isReversed={true}
                    defaultValue={this.state.industryCategory.obsolete}
                    onChanged={(value) => this.onValueChanged("obsolete", value)} />
            </FullPageDialog>
        );
    }
}