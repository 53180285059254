import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardTypography from '../common/CardTypography';
import CardTitle from '../common/CardTitle';
import CardRow from '../common/CardRow';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { userRoleLogic } from '../../common/logic/userRoleLogic';

export interface IUserRoleCardProps {
    userRole: AusComplyDtos.UserRoleSummary;
    canAdminEdit?: boolean;
    excludeViewEditCommands?: boolean;
    onCommand: Function;
}

class UserRoleCard extends React.Component<IUserRoleCardProps, any> {
    constructor(props: IUserRoleCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onCommand(command: string) {
        if (this.props.onCommand){
            this.props.onCommand(command, this.props.userRole, this.props.excludeViewEditCommands);
        }
    }

    onClick() {
        this.onCommand(userRoleLogic.getViewOrEditCommand(this.props.userRole, this.props.excludeViewEditCommands));
    }


    render() {
        var commands = userRoleLogic.getCommands(this.props.userRole, this.props.excludeViewEditCommands, this.props.canAdminEdit);
        return (
            <CardRow 
                isDanger={this.props.userRole.userRoleObsolete || this.props.userRole.userObsolete} 
                isInfo={this.props.userRole.isLoggedIn}
                commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onClick()}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Venue</CardTitle>
                        <CardTypography>{this.props.userRole.venue}</CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Security Firm</CardTitle>
                        <CardTypography>{this.props.userRole.securityFirm}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onClick()}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography><b>{this.props.userRole.givenName} {this.props.userRole.surname}</b></CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Access</CardTitle>
                        <CardTypography>{this.props.userRole.userRoleType}  / {this.props.userRole.accessLevel}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleCard);