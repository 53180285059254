import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginLegacy from '../login/LoginLegacy';
import LoginContainer from '../../containers/LoginContainer';
import LoginTerms from '../../components/login/LoginTerms';
import LoginPrivacyPolicy from '../../components/login/LoginPrivacyPolicy';
import LoginForgotPasswordContainer from '../../containers/LoginForgotPasswordContainer';
import LoginResetPasswordContainer from '../../containers/LoginResetPasswordContainer';
import LoginResetPasswordCodeContainer from '../../containers/LoginResetPasswordCodeContainer';
import ProfileEmailConfirmationContainer from '../../containers/ProfileEmailConfirmationContainer';
import RegisterUserContainer from '../../containers/RegisterUserContainer';
import RegisterUserTerms from '../../components/register/profile/RegisterUserTerms';
import RegsiterUserPrivacyPolicy from '../../components/register/profile/RegsiterUserPrivacyPolicy';
import { withRouter } from "react-router";
//import React GA from 'react-ga';
import ReactGA from 'react-ga4';

class AuthRoute extends Component<any, any> {
    componentDidMount() {
        try {
            this.props.history.listen((location, action) => {
                // location is an object like window.location
                ReactGA.set({ page: location.pathname }); // Update the user's current page
                ReactGA.send({ hitType: "pageview", page: location.pathname });
                //React GA.pageview(location.pathname); // Record a pageview for the given page
            });
            // console.log(this.props.history, this.props.history.location.pathname);
        } catch (error) {
            // console .log(error);
        }
    }

    render() {
        return (
            <Switch>
                <Route path='/terms' component={LoginTerms} />
                <Route path='/privacypolicy' component={LoginPrivacyPolicy} />
                <Route path='/login' component={LoginContainer} />
                <Route path='/legacy' component={LoginLegacy} />
                <Route path='/forgotpassword' component={LoginForgotPasswordContainer} />
                <Route path='/register/user/terms' component={RegisterUserTerms} />
                <Route path='/register/user/privacypolicy' component={RegsiterUserPrivacyPolicy} />
                <Route path='/register/user' component={RegisterUserContainer} />
                <Route path='/resetpassword/:userId/:userForgotPasswordId/:forgotPasswordGuid' component={LoginResetPasswordContainer} />
                <Route path='/resetpasswordcode' component={LoginResetPasswordCodeContainer} />
                <Route path='/emailverification/:userId/:emailConfirmationGuid' component={ProfileEmailConfirmationContainer} />
                <Redirect to={'/login'} />
            </Switch>
        );
    }
}

export default withRouter(AuthRoute);