import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICellHeaderProps {
    showCameras?: boolean;
    classes: any;
}

class CellHeader extends React.Component<ICellHeaderProps, any> {
    render() {
        const { classes } = this.props;
        if (this.props.showCameras) {
            return (
                <tr>
                    <th className={classes.tableHrIndicator}></th>
                    <th className={classes.tableHr}>Name</th>
                    <th className={classes.tableHr}>Cameras</th>
                    <th className={classes.tableHr}>Code</th>
                    <th className={classes.tableHr}>Rank</th>
                    <th className={classes.tableHrCommands}></th>
                </tr>
            );
        }
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Code</th>
                <th className={classes.tableHr}>Rank</th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CellHeader);