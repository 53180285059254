import update from "immutability-helper";
import {
    REGISTER_USER_RESET,
    REGISTER_USER_NEW_REQUEST,
    REGISTER_USER_NEW_REQUEST_SUCCESS,
    REGISTER_USER_NEW_REQUEST_FAILURE,
    REGISTER_USER_SET_STEP,
    REGISTER_USER_UPDATE,
    REGISTER_USER_SLED_CHECK_REQUEST,
    REGISTER_USER_SLED_CHECK_REQUEST_SUCCESS,
    REGISTER_USER_SLED_CHECK_REQUEST_FAILURE,
    REGISTER_USER_UPDATE_OTHER_DOCUMENTS,
    REGISTER_USER_UPDATE_COMPLIANCE_DOCUMENTS,
    REGISTER_USER_SAVE_REQUEST,
    REGISTER_USER_SAVE_REQUEST_SUCCESS,
    REGISTER_USER_SAVE_REQUEST_FAILURE,
    REGISTER_USER_UPDATE_AVATAR,
    REGISTER_USER_POST_SAVE
} from "../actions/registerUser";
import { userLogic } from '../logic/userLogic';


const initialState = {
    user: { userId: -1 },
    isLicensedVenue: false,
    venueIds: [],
    securityFirmIds: [],
    genders: [],
    states: [],
    venues: [],
    securityFirms: [],
    otherDocuments: [],
    otherDocumentTypes: [],
    complianceDocuments: [],
    complianceDocumentTypes: [],
    isLoading: false,
    isCheckingSled: false,
    venuesAvailableToRequest: [],
    securityFirmsAvailableToRequest: [],
    isVenuesLoading: false,
    isSecurityFirmsLoading: false,
    isSaving: false,
    isCheckingLicense: false,
    error: null,
    errors: {},
    step: 1,
    postSaveAction: ""
};

function updatePostSaveAction(state, postSaveAction) {
    let newState = update(state, {
        postSaveAction: { $set: postSaveAction }
    });
    return newState;
}

function updateUser(state, user, venueIds, securityFirmIds) {
    let isLicensedVenue = userLogic.isLicensedVenueSelected(venueIds, state.venues);
    let newState = update(state, {
        user: { $set: user },
        isLicensedVenue: { $set: isLicensedVenue },
        venueIds: { $set: venueIds },
        securityFirmIds: { $set: securityFirmIds }
    });
    return newState;
}

function updateAvatar(state, avatar) {
    let newState = update(state, {
        avatar: { $set: avatar }
    });
    return newState;
}

function setOptions(state, venues, securityFirms, states, genders, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    let isLicensedVenue = userLogic.isLicensedVenueSelected(state.venueIds, venues);
    let newState = update(state, {
        venues: { $set: venues },
        isLicensedVenue: { $set: isLicensedVenue},
        securityFirms: { $set: securityFirms },
        states: { $set: states },
        genders: { $set: genders },
        otherDocuments: { $set: otherDocuments },
        otherDocumentTypes: { $set: otherDocumentTypes },
        complianceDocuments: { $set: complianceDocuments },
        complianceDocumentTypes: { $set: complianceDocumentTypes }
    });
    return newState;
}

function setStep(state, step) {
    let newState = update(state, {
        step: { $set: step }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setErrors(state) {
    let newState = update(state, {
        errors: { $set: userLogic.validateRegisterUser(state.user, state.venueIds, state.securityFirmIds, state.isLicensedVenue) }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        otherDocuments: { $set: otherDocuments },
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        complianceDocuments: { $set: complianceDocuments },
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function isCheckingSled(state, isCheckingSled) {
    let newState = update(state, {
        isCheckingSled: { $set: isCheckingSled }
    });
    return newState;
}

export default function content(state = initialState, action) {
    switch (action.type) {
        case REGISTER_USER_NEW_REQUEST:
            return loading(updateUser(setStep(state, 1), { userId: 0 }, [], []), true);
        case REGISTER_USER_NEW_REQUEST_SUCCESS:
            return setErrors(loading(updateAvatar(updateUser(setOptions(setError(state, ""), action.venues, action.securityFirms, action.states, action.genders, action.otherDocuments, action.otherDocumentTypes, action.complianceDocuments, action.complianceDocumentTypes), action.user, [], []),action.avatar), false));
        case REGISTER_USER_NEW_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case REGISTER_USER_RESET:
            return setErrors(updateUser(isCheckingSled(state, false), { userId: -1 }, [], []));
        case REGISTER_USER_SET_STEP:
            return setStep(state, action.step);
        case REGISTER_USER_UPDATE:
            return setErrors(updateUser(state, action.user, action.venueIds, action.securityFirmIds));
        case REGISTER_USER_SLED_CHECK_REQUEST:
            return setError(isCheckingSled(state, true), "");
        case REGISTER_USER_SLED_CHECK_REQUEST_SUCCESS:
            return setErrors(isCheckingSled(updateUser(state, action.user, state.venueIds, state.securityFirmIds), false));
        case REGISTER_USER_SLED_CHECK_REQUEST_FAILURE:
            return isCheckingSled(state, false);
        case REGISTER_USER_UPDATE_OTHER_DOCUMENTS:
            return setOtherDocuments(state, action.otherDocuments);
        case REGISTER_USER_UPDATE_COMPLIANCE_DOCUMENTS:
            return setComplianceDocuments(state, action.complianceDocuments);
        case REGISTER_USER_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case REGISTER_USER_SAVE_REQUEST_SUCCESS:
            return saving(state, false);
        case REGISTER_USER_SAVE_REQUEST_FAILURE:
            return setErrors(saving(setError(state, action.error), false));
        case REGISTER_USER_UPDATE_AVATAR:
            return updateAvatar(state, action.avatar);
        case REGISTER_USER_POST_SAVE:
            return updatePostSaveAction(state, action.postSaveAction);
    }
    return state;
}