import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import IncidentCategoryTypes from '../components/venue/incidentCategoryTypes/IncidentCategoryTypes'
import { 
    venueIncidentCategoryTypesRequest, 
    venueIncidentCategoryTypeCreateRequest, 
    venueIncidentCategoryTypeRequest, 
    venueIncidentCategoryTypeReset,
    venueIncidentCategoryTypeSet,
    venueIncidentCategoryTypeUpsertRequest,
    venueIncidentCategoryTypesFilterSet,
    venueIncidentCategoryTypeHideRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        paging: state.venue.incidentCategoryTypes.paging,
        filter: state.venue.incidentCategoryTypes.filter,
        isLoading: state.venue.incidentCategoryTypes.isLoading,
        incidentCategoryTypes: state.venue.incidentCategoryTypes.incidentCategoryTypes,
        incidentCategoryTypeId: state.venue.incidentCategoryType.incidentCategoryTypeId,
        canCreate: permissionsLogic.hasPermissionForState(state, "AddIncidentCategory")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(venueIncidentCategoryTypesRequest(page, pageSize, filter))
        },
        onLoadItem: (incidentCategoryTypeId) => {
            dispatch(venueIncidentCategoryTypeRequest(incidentCategoryTypeId))
        },
        onCreate: (venueId, securityFirmId) => {
            dispatch(venueIncidentCategoryTypeCreateRequest(venueId, securityFirmId))
        },
        onResetItem: () => {
            dispatch(venueIncidentCategoryTypeReset())
        },
        onUpdate: (incidentCategoryType) => {
            dispatch(venueIncidentCategoryTypeSet(incidentCategoryType))
        },
        onSave: () => {
            dispatch(venueIncidentCategoryTypeUpsertRequest())
        },
        onFilterUpdate: (filter) => {
            dispatch(venueIncidentCategoryTypesFilterSet(filter))
        },
        onHide: (incidentCategoryTypeId, hide, forVenueId, forSecurityFirmId) => {
            dispatch(venueIncidentCategoryTypeHideRequest(incidentCategoryTypeId, hide, forVenueId, forSecurityFirmId))
        }
    }
}

const SecurityFirmIncidentCategoryTypesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentCategoryTypes))

export default SecurityFirmIncidentCategoryTypesContainer