import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles';
import classNames from 'classnames';

const PersonAddIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%",
    classes: any
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 83.7 50.2"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M70.3,13.9C70.3,6.2,64.1,0,56.4,0c-7.7,0-13.9,6.2-13.9,13.9c0,7.7,6.2,13.9,13.9,13.9 C64.1,27.8,70.3,21.6,70.3,13.9 M81.4,40.8c-2.8-7-9.2-11.4-15.9-11.7c-2.5,2.2-5.7,3.5-9.2,3.5c-3.5,0-6.7-1.3-9.2-3.5 c-6.7,0.4-13,4.8-15.8,11.7c-0.2,0.5-2.2,6.7-2.4,9.4h54.6C83.4,47.5,81.6,41.3,81.4,40.8" />
            </g>
            <polygon fill={fill} points="13,0 13,13 0,13 0,16 13,16 13,29 16,29 16,16 29,16 29,13 16,13 16,0 " />
        </svg>
    );

export default withStyles(styles, { withTheme: true })(PersonAddIcon);
