import { connect } from 'react-redux'
import ActiveSessionMonitor from '../components/common/ActiveSessionMonitor'
import { userActive, userLogoutRequest, userReset  } from '../common/actions/userLogin'

const activityMonitorTimeout = (state) => {
    if (state.user && state.user.details && state.user.details.userSession && state.user.details.userSession.user) {
        return state.user.details.userSession.user.activityMonitorTimeout;
    }
    return 120;
}

const mapStateToProps = (state, props) => {
    return {
        lastActionDate: state.user.lastActionDate,
        lastActionEnabled: state.user.lastActionEnabled,
        duration: activityMonitorTimeout(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUserActive: () => {
            dispatch(userActive())
        },
        onLogout: () => {
            dispatch(userReset())
        }
    }
}

const ActiveSessionMonitorContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActiveSessionMonitor)

export default ActiveSessionMonitorContainer