import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import PrimaryButton from '../../controls/PrimaryButton';
import ErrorButton from '../../controls/ErrorButton';
import View from '../../common/View';
import SubTitle from '../../common/SubTitle';
import NoRowsMessage from '../../common/NoRowsMessage';
import ResponsiveList from '../../common/ResponsiveList';
import RoublerEmployeeMappingAdd from './RoublerEmployeeMappingAdd';
import ExternalApiConnectionContainer from '../../../containers/ExternalApiConnectionContainer';

export interface IRoublerEmployeeMappingProps {
    roublerEmployeeMapping: AusComplyDtos.RoublerEmployeeMapping,
    mappings: AusComplyDtos.EntityMapping[],
    suggested: AusComplyDtos.EntityMapping[],
    users: AusComplyDtos.NamedValue[],
    employees: AusComplyDtos.NamedValue[],
    error: string,
    isLoading: boolean,
    isSaving: boolean,
    onLoad: Function,
    onUpdate: Function,
    onExport: Function
}

interface IRoublerEmployeeMappingState {
    userId: number;
    employeeId: number;
    open: boolean;
}

export default class RoublerEmployeeMapping extends Component<IRoublerEmployeeMappingProps, IRoublerEmployeeMappingState> {
    constructor(props: IRoublerEmployeeMappingProps) {
        super(props)
        this.state = {
            userId: 0,
            employeeId: 0,
            open: false
        }
        this.onUseSuggestion = this.onUseSuggestion.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount(): void {
        this.props.onLoad();
    }

    componentDidUpdate(prevProps: Readonly<IRoublerEmployeeMappingProps>, prevState: Readonly<IRoublerEmployeeMappingState>, snapshot?: any): void {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({
                userId: 0,
                employeeId: 0,
                open: false
            })
        }
    }

    onUseSuggestion(entityMapping: AusComplyDtos.EntityMapping){
        this.props.onUpdate(entityMapping);
    }

    onRemove(entityMapping: AusComplyDtos.EntityMapping){
        let updatedEntityMapping = {...entityMapping, obsolete: true};
        this.props.onUpdate(updatedEntityMapping);
    }

    onSave(userId: number, employeeId: number) {
        if (userId > 0 && employeeId > 0) {
            let entityMapping: AusComplyDtos.EntityMapping = new AusComplyDtos.EntityMapping();
            entityMapping.externalApiConnectionId = this.props.roublerEmployeeMapping.externalApiConnectionId;
            entityMapping.entityId = userId;
            entityMapping.externalId = employeeId;
            this.props.onUpdate(entityMapping);
        } else if (userId > 0 && employeeId == 0) {
            this.props.onExport(userId)
        }
    }

    render() {
        let self = this;
        let isValid: boolean = this.props.roublerEmployeeMapping && this.props.roublerEmployeeMapping.isConnectionValid;
        let mapping;
        let title;
        if (isValid && this.props.mappings && this.props.mappings.length > 0) {
            title = <SubTitle text='Mapped Employees and Users' />;
            mapping = <>
                <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.mappings}
                    headers={["AusComply User", "Roubler Employee", ""]}
                    columns={["entityDisplay", "externalDisplay", "action"]}
                    widths={[0, 0, 100]}
                    getCommands={item => []}
                    onCommand={(command, item) => { }}
                    onFormatValue={(column, value, data, row) => {
                        if (column == "action") {
                            value = <ErrorButton text='Remove' onClick={() => self.onRemove(row)} />
                        }
                        return value;
                    }}
                />
                <View style={{ padding: '10px' }}></View>
            </>
        } else if (isValid) {
            title = <SubTitle text='Mapped Employees and Users' />;
            mapping = <NoRowsMessage />
        } else if (!isValid && !this.props.isLoading && !this.props.isSaving && this.props.roublerEmployeeMapping && this.props.roublerEmployeeMapping.externalApiConnectionId > 0) {
            mapping = <ExternalApiConnectionContainer 
                entityType={AusComplyDtos.ngtEntityType.RoublerRoster} 
                venueId={this.props.roublerEmployeeMapping.venueId}
                securityFirmId={this.props.roublerEmployeeMapping.securityFirmId} />
        }
        let suggested;
        if (isValid && this.props.suggested && this.props.suggested.length > 0) {
            suggested = <>
                <SubTitle text='Suggested mappings' />
                <ResponsiveList
                    loading={this.props.isLoading}
                    data={this.props.suggested}
                    headers={["AusComply User", "Roubler Employee", ""]}
                    columns={["entityDisplay", "externalDisplay", "action"]}
                    widths={[0, 0, 100]}
                    getCommands={item => []}
                    onCommand={(command, item) => { }}
                    onFormatValue={(column, value, data, row) => {
                        if (column == "action") {
                            value = <PrimaryButton text='Save' onClick={() => self.onUseSuggestion(row)} />
                        }
                        return value;
                    }}
                />
                <View style={{ padding: '10px' }}></View>
            </>
        }

        return <PageLayout
            headerText={"Roubler Employee Mapping"}
            error={this.props.error}
            loading={this.props.isLoading}
            saving={!this.state.open && this.props.isSaving}
            footerLeftContent={<TransparentButton text={"Add"} disabled={!isValid} onClick={() => self.setState({open: true})} />}
            footerRightContent={<TransparentButton text={"Refresh"} onClick={() => self.props.onLoad()} />}
        >
            {title}
            {mapping}
            {suggested}
            <RoublerEmployeeMappingAdd
                open={this.state.open}
                users={this.props.users}
                employees={this.props.employees}
                isSaving={this.props.isSaving}
                error={this.props.error}
                onSave={this.onSave}
                onCancel={() => self.setState({open: false})}
             />
        </PageLayout>
    }
}