import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionCameraImport from '../components/facialRecognition/facialRecognitionCameras/FacialRecognitionCameraImport';
import * as facialRecognitionNotificationActions from "../common/actions/facialRecognitionNotification";

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        groupId: props.groupId,
        buttonMode: props.buttonMode,
        facialRecognitionCameraImports: state.facialRecognitionNotification.facialRecognitionCameraImports.facialRecognitionCameraImports,
        isLoading: state.facialRecognitionNotification.facialRecognitionCameraImports.isLoading,
        isSaving: state.facialRecognitionNotification.facialRecognitionCameraImports.isSaving,
        isSavedSuccessful: state.facialRecognitionNotification.facialRecognitionCameraImports.isSavedSuccessful,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (forVenueId) => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionCameraNewImportRequest(forVenueId))
        },
        onLoadCameras: (forVenueId) => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionNotificationCamerasRequest(forVenueId))
        },
        onSet: (facialRecognitionCameraImports) => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionCameraImportSet(facialRecognitionCameraImports))
        },
        onSave: () => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionCameraImportRequest())
        }
    }
}

const FacialRecognitionCameraImportContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionCameraImport))

export default FacialRecognitionCameraImportContainer