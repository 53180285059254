import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../../utilities/constants';
import PersonIcon from '../../../resources/PersonIcon';
import IdCardIcon from '../../../resources/IdCardIcon';
import TickIcon from '../../../resources/TickIcon';
import DocumentIcon from '../../../resources/DocumentIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import ImagePreview from '../../common/ImagePreview';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import Thumbnail from '../../common/Thumbnail';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import InmatePhoto from './InmatePhoto';
import Box from '@material-ui/core/Box';

export interface IInmateProps {
    correctionalFacilityInMate: AusComplyDtos.CorrectionalFacilityInMate;
    genders: AusComplyDtos.NamedValue[];
    nationalities: AusComplyDtos.Nationality[];
    onUpdate: Function;
    theme: any;
}

class Inmate extends Component<IInmateProps, any> {
    constructor(props: IInmateProps) {
        super(props)
        this.state = {
        };
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let correctionalFacilityInMate = { ...this.props.correctionalFacilityInMate };
        correctionalFacilityInMate[fieldName] = value;
        this.props.onUpdate(correctionalFacilityInMate);
    }

    render() {
        const { theme } = this.props;

        return (
            <Box p={0} display="flex" flexDirection={'row'}>
                <Box flexGrow={1} p={0}>
                    <TextControlGroup text={"CNI Number"}
                        defaultValue={this.props.correctionalFacilityInMate.cniNumber}
                        onBlur={(value) => this.onValueChanged("cniNumber", value)} />
                    <TextControlGroup text={"Master Index Number"}
                        defaultValue={this.props.correctionalFacilityInMate.masterIndexNumber}
                        onBlur={(value) => this.onValueChanged("masterIndexNumber", value)} />
                    <TextControlGroup text={"First Name"}
                        defaultValue={this.props.correctionalFacilityInMate.firstName}
                        onBlur={(value) => this.onValueChanged("firstName", value)} />
                    <TextControlGroup text={"Last Name"}
                        defaultValue={this.props.correctionalFacilityInMate.lastName}
                        onBlur={(value) => this.onValueChanged("lastName", value)} />
                    <SelectControlGroup
                        text="Gender"
                        onChange={(value) => this.onValueChanged("genderId", value)}
                        defaultValue={this.props.correctionalFacilityInMate.genderId}>
                        <option value={0} />
                        {this.props.genders.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    <SelectControlGroup
                        text="Nationality"
                        onChange={(value) => this.onValueChanged("nationalityId", value)}
                        defaultValue={this.props.correctionalFacilityInMate.nationalityId}>
                        <option value={0} />
                        {this.props.nationalities.map((item, index) => (
                            <option key={item.nationalityId} value={item.nationalityId}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                    <SimpleDateControlGroup text="Date of Birth"
                        date={this.props.correctionalFacilityInMate.dobDate}
                        onChange={(value) => this.onValueChanged("dobDate", value)} />
                </Box>
                <Box p={0}>
                    <InmatePhoto
                        file={this.props.correctionalFacilityInMate.photo}
                        onUpdate={(value) => this.onValueChanged("photo", value)} />
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Inmate);