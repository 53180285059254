
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';
import { permissionsLogic } from "./permissionsLogic";
import checklist from "../reducers/checklist";

export const checklistTemplatesLogic = {
    getCommands(checklistTemplateSummary: AusComplyDtos.ChecklistTemplateSummary,
        canEdit?: boolean,
        canEditGlobal?: boolean,
        canClone?: boolean,
        canEditGroup?: boolean,
        canPreview?: boolean
    ) {
        var commands: KeyName[] = [];
        let isGroup: boolean = checklistTemplateSummary.groupId != undefined && checklistTemplateSummary.groupId != null && checklistTemplateSummary.groupId > 0

        if (checklistTemplateSummary.isGroup && canEditGroup) {
                commands.push({ key: "edit", name: "Edit" });
        } else if (!checklistTemplateSummary.isGroup && (checklistTemplateSummary.isGlobal ? canEditGlobal : canEdit)) {
            commands.push({ key: "edit", name: "Edit" });
        }


        if (!isGroup && canEdit) {
            if (checklistTemplateSummary.obsolete) {
                commands.push({ key: "restore", name: "Restore" });
            } else {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        if (canClone) {
            commands.push({ key: "clone", name: "Copy" });
        }

        if (canPreview) {
            commands.push({ key: "preview", name: "Preview" });
        }

        return commands;
    }
}