import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';


export interface ISnackbarNotificationsProps {
    message: string;
    messageType: string;
    onClearNotification: Function;
}

export default class SnackbarNotifications extends Component<ISnackbarNotificationsProps, any> {
    constructor(props: ISnackbarNotificationsProps) {
        super(props)

        let open = false;
        if (props.message) {
            if (props.message !== "") {
                open = true;
            }
        }
        this.state = {
            open: open,
            message: props.message,
            color: 'red'
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.message !== prevProps.message) {
            let open = false;
            if (this.props.message) {
                if (this.props.message !== "") {
                    open = true;
                }
            }
            let color = this.state.color;
            if (this.props.message !== "") {
                if (this.props.messageType === "success") {
                    color = "green";
                } else {
                    color = "red";
                }
            }
            this.setState({ open: open, message: this.props.message, color: color });
        }
    }

    handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ open: false, message: "" }, this.props.onClearNotification());
    }

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={this.state.open}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        style={{ backgroundColor: this.state.color, color: 'white' }}
                        aria-describedby="client-snackbar"
                        onClick={(e) => this.handleClose(e, "click")}
                        message={
                            <span id="client-snackbar">
                                {this.state.message}
                            </span>
                        }
                    />
                </Snackbar>
            </div>
        );
    }
}