import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const websiteAdminApi = {
  resetCache: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostWebsiteAdminClearCache();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  resetConnectionPool: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetPatchConnectionPools();
    const request = client.get(query);
    return from(request);
  },
  import: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostWebsiteAdminImportDemo();
    const request = client.get(query);
    return from(request);
  },
};
