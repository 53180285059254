import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import MultiSelectControl from '../controls/MutiSelectControl';
import { MulitSelectOption } from '../../common/dto/common';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../controls/SelectControlGroup';
import ControlGroup from '../controls/ControlGroup';

export interface IScheduleVenueEventsProps {
    schedule: AusComplyDtos.Schedule;
    venueEvents: AusComplyDtos.VenueEvent[];
    onUpdate: Function;
}

interface IScheduleVenueEventsState {
    items: MulitSelectOption[];
}

export default class ScheduleVenueEvents extends Component<IScheduleVenueEventsProps, IScheduleVenueEventsState> {
    constructor(props: IScheduleVenueEventsProps) {
        super(props)
        this.state = {
            items: []
        }
        this.onSelectItemsChanged = this.onSelectItemsChanged.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    componentDidMount() {
        this.onBuildItems();
    }

    componentDidUpdate(prevProps, prevState): void {
        if (JSON.stringify(prevProps.venueEvents) !== JSON.stringify(this.props.venueEvents)) {
            this.onBuildItems();
        }
        else if (JSON.stringify(prevProps.schedule.scheduleVenueEvents) !== JSON.stringify(this.props.schedule.scheduleVenueEvents)) {
            this.onBuildItems();
        }
    }

    onBuildItems() {
        let items: MulitSelectOption[] = [];
        let venueEventIds: number[] = [];
        if (this.props.schedule && this.props.schedule.scheduleVenueEvents) {
            venueEventIds = this.props.schedule.scheduleVenueEvents.filter(f => !f.obsolete).map(item => item.venueEventId);
        }
        if (this.props.venueEvents) {
            this.props.venueEvents.map(item => {
                let isSelected = venueEventIds.indexOf(item.venueEventId) > -1;
                items.push({
                    id: item.venueEventId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }
        this.setState({
            items
        });
    }

    onSelectItemsChanged(items) {
        let previousIds = this.props.schedule.scheduleVenueEvents.map(item => item.venueEventId);
        let currentIds = items.map(item => item.id);

        // remove
        let newItems = this.props.schedule.scheduleVenueEvents.filter(item => currentIds.indexOf(item.venueEventId) > -1);

        // add
        items.filter(item => previousIds.indexOf(item.id) == -1).forEach(item => {
            let newItem: AusComplyDtos.ScheduleVenueEvent = new AusComplyDtos.ScheduleVenueEvent();
            newItem.venueEventId = item.id;
            newItems.push(newItem);
        });
        let schedule = { ...this.props.schedule };
        schedule.scheduleVenueEvents = newItems;
        this.props.onUpdate(schedule);
    }

    onChanged(fieldName: string, value) {
        let schedule = { ...this.props.schedule };
        schedule[fieldName] = value;
        this.props.onUpdate(schedule)
    }

    render() {
        let showSelector: boolean = false;
        if (this.props.schedule && 
            (
                this.props.schedule.scheduleEventOption == AusComplyDtos.ngtScheduleEventOption.VenueAndSelectedEvents ||
                this.props.schedule.scheduleEventOption == AusComplyDtos.ngtScheduleEventOption.SelectedEventsOnly
            )){
                showSelector = true;
        }

        return (
            <>
            <SelectControlGroup
                    text="Event Option (*events with current reporting periods)"
                    onChange={(value) => this.onChanged("scheduleEventOption", value)}
                    defaultValue={this.props.schedule.scheduleEventOption}>
                    <option key={AusComplyDtos.ngtScheduleEventOption.VenueOnly} value={AusComplyDtos.ngtScheduleEventOption.VenueOnly}>Venue only</option>
                    <option key={AusComplyDtos.ngtScheduleEventOption.AllEventsOnly} value={AusComplyDtos.ngtScheduleEventOption.AllEventsOnly}>All events*</option>
                    <option key={AusComplyDtos.ngtScheduleEventOption.VenueAndAllEvents} value={AusComplyDtos.ngtScheduleEventOption.VenueAndAllEvents}>Venue and all events*</option>
                    <option key={AusComplyDtos.ngtScheduleEventOption.VenueAndSelectedEvents} value={AusComplyDtos.ngtScheduleEventOption.VenueAndSelectedEvents}>Venue and selected events*</option>
                    <option key={AusComplyDtos.ngtScheduleEventOption.SelectedEventsOnly} value={AusComplyDtos.ngtScheduleEventOption.SelectedEventsOnly}>Selected events only*</option>
                </SelectControlGroup>
            {showSelector && <ControlGroup text=' '><CardContainer title={"Events"}>
                <CardContent style={{ paddingTop: 0 }}>
                    <MultiSelectControl
                        items={this.state.items}
                        label={""}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectItemsChanged={this.onSelectItemsChanged}
                        canShowAll={true} />
                </CardContent>
            </CardContainer></ControlGroup>
            }
            </>
        );
    }
}