import { connect } from 'react-redux' ;
import WebsiteProperties from '../components/admin/websiteProperties/WebsiteProperties';
import * as adminActions from '../common/actions/admin';

const mapStateToProps = state => {
    return {
        isLoading: state.admin.websiteProperties.isLoading,
        isSaving: state.admin.websiteProperties.isSaving,
        websiteProperties: state.admin.websiteProperties.websiteProperties
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: () =>{
            dispatch(adminActions.adminWebsitePropertiesRequest())
        },
        onSave: (websiteProperties) => {
            dispatch(adminActions.adminWebsitePropertiesSaveRequest(websiteProperties))
        },
        onResetCache: () => {
            dispatch(adminActions.adminWebsiteResetCacheRequest())
        },
        onResetPool: () => {
            dispatch(adminActions.adminWebsiteResetPoolRequest())
        },
        onImport: () => {
            dispatch(adminActions.adminWebsiteImportRequest())
        }
    }
}

const WebsitePropertiesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(WebsiteProperties)

export default WebsitePropertiesContainer