import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FacialRecognitionNotificationsTabs from '../components/venue/facialRecognitionNotifications/FacialRecognitionNotificationsTabs'

const mapStateToProps = (state, props) => {
    return {
        selectedTab: props.selectedTab,
        venueId: state.user.details.userSession.user.venueId,
        history: props.history,
        canManageCameras: permissionsLogic.hasPermissionForState(state, 'ManageVenueCameras'),
        canManageWatchlists: permissionsLogic.hasPermissionForState(state, 'ManageVenueWatchlists'),
        canManageEvents: permissionsLogic.hasPermissionForState(state, 'ManageVenueEvents'),
        canManageEnrolments: permissionsLogic.hasPermissionForState(state, 'ManageVenueEnrolments'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueFacialRecognitionNotificationsTabsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionNotificationsTabs))

export default VenueFacialRecognitionNotificationsTabsContainer