import update from "immutability-helper";
import {
    ROLE_TYPES_REQUEST,
    ROLE_TYPES_REQUEST_SUCCESS,
    ROLE_TYPES_REQUEST_FAILURE,
    ROLE_TYPES_FILTER_META_REQUEST,
    ROLE_TYPES_FILTER_META_REQUEST_SUCCESS,
    ROLE_TYPES_FILTER_META_REQUEST_FAILURE,
    ROLE_TYPES_FILTER_SET,
    ROLE_TYPE_UPDATE,
    ROLE_TYPE_LOAD_REQUEST,
    ROLE_TYPE_LOAD_REQUEST_FAILURE,
    ROLE_TYPE_LOAD_REQUEST_SUCCESS,
    ROLE_TYPE_ADD_REQUEST,
    ROLE_TYPE_ADD_REQUEST_SUCCESS,
    ROLE_TYPE_ADD_REQUEST_FAILURE,
    ROLE_TYPE_ADD_SAVE_REQUEST,
    ROLE_TYPE_ADD_SAVE_REQUEST_SUCCESS,
    ROLE_TYPE_ADD_SAVE_REQUEST_FAILURE,
    ROLE_TYPE_UPDATE_SAVE,
    ROLE_TYPE_UPDATE_SAVE_FAILURE,
    ROLE_TYPE_UPDATE_SAVE_SUCCESS,
    ROLE_TYPE_DELETE_REQUEST,
    ROLE_TYPE_DELETE_REQUEST_SUCCESS,
    ROLE_TYPE_DELETE_REQUEST_FAILURE,
    USER_ROLES_RESET,
    USER_ROLES_REQUEST,
    USER_ROLES_REQUEST_SUCCESS,
    USER_ROLES_REQUEST_FAILURE,
    USER_ROLES_FILTER_SET,
    USER_ROLE_UPDATE,
    USER_ROLE_ADD_REQUEST,
    USER_ROLE_ADD_REQUEST_FAILURE,
    USER_ROLE_ADD_REQUEST_SUCCESS,
    USER_ROLE_ADD_SAVE,
    USER_ROLE_ADD_SAVE_FAILURE,
    USER_ROLE_ADD_SAVE_SUCCESS,
    USER_ROLE_LOAD_REQUEST,
    USER_ROLE_LOAD_REQUEST_FAILURE,
    USER_ROLE_LOAD_REQUEST_SUCCESS,
    USER_ROLE_UPDATE_SAVE,
    USER_ROLE_UPDATE_SAVE_FAILURE,
    USER_ROLE_UPDATE_SAVE_SUCCESS,
    USER_ROLE_REMOVE_REQUEST,
    USER_ROLE_REMOVE_REQUEST_SUCCESS,
    USER_ROLE_REMOVE_REQUEST_FAILURE,
    USER_ROLE_REQUEST_DISMISS_REQUEST,
    USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS,
    USER_ROLE_REQUEST_DISMISS_REQUEST_FAILURE,
    USER_ROLE_ENABLE_REQUEST,
    USER_ROLE_ENABLE_REQUEST_SUCCESS,
    USER_ROLE_ENABLE_REQUEST_FAILURE,
    USER_ROLE_USER_VIEW_REQUEST,
    USER_ROLE_USER_VIEW_REQUEST_SUCCESS,
    USER_ROLE_USER_VIEW_REQUEST_FAILURE,
    USER_ROLE_USER_REQUEST,
    USER_ROLE_USER_REQUEST_SUCCESS,
    USER_ROLE_USER_REQUEST_FAILURE,
    USER_ROLE_OTHER_DOCUMENTS_SAVE,
    USER_ROLE_OTHER_DOCUMENTS_SAVE_SUCCESS,
    USER_ROLE_OTHER_DOCUMENTS_SAVE_FAILURE,
    USER_ROLE_OTHER_DOCUMENT_REMOVE,
    USER_ROLE_OTHER_DOCUMENT_REMOVE_FAILURE,
    USER_ROLE_OTHER_DOCUMENT_REMOVE_SUCCESS,
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE,
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
    USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE,
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
    USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
    USER_ROLES_SUSPEND_SAVE,
    USER_ROLES_SUSPEND_SAVE_SUCCESS,
    USER_ROLES_SUSPEND_SAVE_FAILURE
} from "../actions/userRole";

const initialState = {
    roleTypes: {
        isLoading: false,
        roleTypes: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" },
        industryCategories: []
    },
    roleType: { 
        isLoading: false,
        availableUserPermissions: []
    },
    userRoles: {
        isLoading: false,
        isSaving: false,
        userRoles: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" }
    },
    userView: {
        isLoading: false,
        userView: { userId: 0 }
    },
    user: {
        isLoading: false,
        user: { userId: 0 },
        avatar: {},
        otherDocuments: [], 
        complianceDocuments: [],
        otherDocumentTypes: [], 
        complianceDocumentTypes: []
    },
    isLoading: false,
    userRole: { venueId: 0 },
    venues: [],
    securityFirms: [],
    userRoleTypes: [],
    userRoleStatus: [],
    isSaving: false,
    error: ""
};

function setLoadingRoleTypes(state, isLoading) {
    let newState = update(state, {
        roleTypes: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRoleTypes(state, roleTypes, paging, filter) {
    let newState = update(state, {
        roleTypes: {
            roleTypes: { $set: roleTypes },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setRoleTypesFilterMeta(state, industryCategories) {
    let newState = update(state, {
        roleTypes: {
            industryCategories: { $set: industryCategories },
        }
    });
    return newState;
}

function setRoleTypesFilter(state, filter) {
    let newState = update(state, {
        roleTypes: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setLoadingRoleType(state, isLoading) {
    let newState = update(state, {
        roleType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setRoleType(state, roleType) {
    let newState = update(state, {
        roleType: {
            roleType: { $set: roleType }
        }
    });
    return newState;
}

function setRoleTypeWithMeta(state, roleType, availableUserPermissions, availableIndustryCategories, availableStates) {
    let stateWithRole = setRoleType(state, roleType);

    let newState = update(stateWithRole, {
        roleType: {
            availableUserPermissions: { $set: availableUserPermissions },
            availableIndustryCategories: { $set: availableIndustryCategories },
            availableStates: { $set: availableStates }
        }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        user: {
            otherDocuments: { $set: otherDocuments }
        }
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        user: {
            complianceDocuments: { $set: complianceDocuments }
        }
    });
    return newState;
}

function resetUserRoles(state, ) {
    let newState = update(state, {
        userRoles: {
            isLoading: { $set: false },
            userRoles: { $set: [] },
            paging: { $set: { pageSize: 0, page: 1 } },
            filter: { $set: { search: "" } }
        }
    });
    return newState;
}

function setLoadingUserRoles(state, isLoading) {
    let newState = update(state, {
        userRoles: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSavingingUserRoles(state, isSaving) {
    let newState = update(state, {
        userRoles: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setUserRoles(state, users, paging, filter) {
    let newState = update(state, {
        userRoles: {
            userRoles: { $set: users },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setUserRolesFilter(state, filter) {
    let newState = update(state, {
        userRoles: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading },
        isSaving: { $set: false },
        error: { $set: "" }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isLoading: { $set: false },
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setUserRole(state, userRole) {
    let newState = update(state, {
        userRole: { $set: userRole }
    });
    return newState;
}

function setAvailable(state, venues, securityFirms, userRoleTypes, userRoleStatus) {
    let newState = update(state, {
        venues: { $set: venues },
        securityFirms: { $set: securityFirms },
        userRoleTypes: { $set: userRoleTypes },
        userRoleStatus: { $set: userRoleStatus }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setLoadingUserView(state, isLoading) {
    let newState = update(state, {
        userView: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUserView(state, userView) {
    let newState = update(state, {
        userView: {
            userView: { $set: userView }
        }
    });
    return newState;
}

function setLoadingUser(state, isLoading) {
    let newState = update(state, {
        user: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUser(state, user, avatar, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes) {
    let newState = update(state, {
        user: {
            user: { $set: user },
            avatar: { $set: avatar },
            otherDocuments: { $set: otherDocuments },
            complianceDocuments: { $set: complianceDocuments },
            otherDocumentTypes: { $set: otherDocumentTypes },
            complianceDocumentTypes: { $set: complianceDocumentTypes }
        }
    });
    return newState;
}

export default function userRole(state = initialState, action) {
    switch (action.type) {
        case ROLE_TYPES_REQUEST:
            return setLoadingRoleTypes(state, true);
        case ROLE_TYPES_REQUEST_SUCCESS:
            return setLoadingRoleTypes(setRoleTypes(state, action.users, action.paging, action.filter), false);
        case ROLE_TYPES_REQUEST_FAILURE:
            return setLoadingRoleTypes(setRoleTypes(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        //case ROLE_TYPES_FILTER_META_REQUEST:
            //return setLoadingRoleTypesMeta(state, true);
        case ROLE_TYPES_FILTER_META_REQUEST_SUCCESS:
            return setRoleTypesFilterMeta(state, action.industryCategories);
        case ROLE_TYPES_FILTER_META_REQUEST_FAILURE:
            return setError(state, action.error);
        case ROLE_TYPES_FILTER_SET:
            return setRoleTypesFilter(state, action.filter);
        case ROLE_TYPE_UPDATE:
            return setRoleType(state, action.roleType);
        case ROLE_TYPE_LOAD_REQUEST:
            return setLoadingRoleType(state, true);
        case ROLE_TYPE_LOAD_REQUEST_FAILURE:
            return setLoadingRoleType(setError(state, action.error), false);
        case ROLE_TYPE_LOAD_REQUEST_SUCCESS:
            return setLoadingRoleType(setRoleTypeWithMeta(setError(state, ""), action.userRoleType, action.availableUserPermissions, action.availableIndustryCategories, action.availableStates), false);
        case ROLE_TYPE_ADD_REQUEST:
            return setLoadingRoleType(state, true);
        case ROLE_TYPE_ADD_REQUEST_FAILURE:
            return setLoadingRoleType(setError(state, action.error), false);
        case ROLE_TYPE_ADD_REQUEST_SUCCESS:
            return setLoadingRoleType(setRoleTypeWithMeta(state, action.userRoleType, action.availableUserPermissions, action.availableIndustryCategories, action.availableStates), false);
        case ROLE_TYPE_DELETE_REQUEST:
            return saving(setError(state, ""), true);
        case ROLE_TYPE_DELETE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case ROLE_TYPE_DELETE_REQUEST_SUCCESS:
            return saving(state, false);
        case ROLE_TYPE_ADD_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case ROLE_TYPE_ADD_SAVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case ROLE_TYPE_ADD_SAVE_REQUEST_SUCCESS:
            return saving(state, false);
        case ROLE_TYPE_UPDATE_SAVE:
            return saving(setError(state, ""), true);
        case ROLE_TYPE_UPDATE_SAVE_FAILURE:
            return saving(setError(state, action.error), false);
        case ROLE_TYPE_UPDATE_SAVE_SUCCESS:
            return saving(state, false);
        case USER_ROLE_UPDATE:
            return setUserRole(state, action.userRole);
        case USER_ROLE_ADD_REQUEST:
            return loading(state, true);
        case USER_ROLE_ADD_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case USER_ROLE_ADD_REQUEST_SUCCESS:
            return loading(setAvailable(setUserRole(state, action.userRole), action.venues, action.securityFirms, action.userRoleTypes, []), false);
        case USER_ROLE_ADD_SAVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_ADD_SAVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_ADD_SAVE_SUCCESS:
            return saving(state, false);
        case USER_ROLE_LOAD_REQUEST:
            return loading(state, true);
        case USER_ROLE_LOAD_REQUEST_FAILURE:
            return loading(setError(state, action.error), false);
        case USER_ROLE_LOAD_REQUEST_SUCCESS:
            return loading(setAvailable(setUserRole(state, action.userRole), [], [], action.userRoleTypes, action.userRoleStatus), false);
        case USER_ROLE_UPDATE_SAVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_UPDATE_SAVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_UPDATE_SAVE_SUCCESS:
            return saving(state, false);
        case USER_ROLE_REMOVE_REQUEST:
            return saving(setError(state, ""), true);
        case USER_ROLE_REMOVE_REQUEST_SUCCESS:
            return saving(state, false);
        case USER_ROLE_REMOVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_REQUEST_DISMISS_REQUEST:
            return saving(setError(state, ""), true);
        case USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS:
            return saving(state, false);
        case USER_ROLE_REQUEST_DISMISS_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_ENABLE_REQUEST:
            return saving(setError(state, ""), true);
        case USER_ROLE_ENABLE_REQUEST_SUCCESS:
            return saving(state, false);
        case USER_ROLE_ENABLE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLES_RESET:
            return resetUserRoles(state);
        case USER_ROLES_REQUEST:
            return setLoadingUserRoles(state, true);
        case USER_ROLES_REQUEST_SUCCESS:
            return setLoadingUserRoles(setUserRoles(state, action.userRoles, action.paging, action.filter), false);
        case USER_ROLES_REQUEST_FAILURE:
            return setLoadingUserRoles(setUserRoles(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        case USER_ROLES_FILTER_SET:
            return setUserRolesFilter(state, action.filter);
        case USER_ROLE_USER_VIEW_REQUEST:
            return setLoadingUserView(setUserView(state, { userId: 0 }), true);
        case USER_ROLE_USER_VIEW_REQUEST_SUCCESS:
            return setLoadingUserView(setUserView(state, action.userView), false);
        case USER_ROLE_USER_VIEW_REQUEST_FAILURE:
            return setLoadingUserView(state, false);
        case USER_ROLE_USER_REQUEST:
            return setLoadingUser(setUser(state, { userId: 0 }, {}, [], [], [], []), true);
        case USER_ROLE_USER_REQUEST_SUCCESS:
            return setLoadingUser(setUser(state, action.user, action.avatar, action.otherDocuments, action.otherDocumentTypes, action.complianceDocuments, action.complianceDocumentTypes), false);
        case USER_ROLE_USER_REQUEST_FAILURE:
            return setLoadingUser(state, false);
        case USER_ROLE_OTHER_DOCUMENTS_SAVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_OTHER_DOCUMENTS_SAVE_SUCCESS:
            return saving(setOtherDocuments(state, action.userOtherDocuments), false);
        case USER_ROLE_OTHER_DOCUMENTS_SAVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_OTHER_DOCUMENT_REMOVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_OTHER_DOCUMENT_REMOVE_SUCCESS:
            return saving(setOtherDocuments(state, action.userOtherDocuments), false);
        case USER_ROLE_OTHER_DOCUMENT_REMOVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS:
            return saving(setComplianceDocuments(state, action.userComplianceDocuments), false);
        case USER_ROLE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE:
            return saving(setError(state, ""), true);
        case USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
            return saving(setComplianceDocuments(state, action.userComplianceDocuments), false);
        case USER_ROLE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE:
            return saving(setError(state, action.error), false);
        case USER_ROLES_SUSPEND_SAVE:
            return setSavingingUserRoles(state, true);
        case USER_ROLES_SUSPEND_SAVE_SUCCESS:
        case USER_ROLES_SUSPEND_SAVE_FAILURE:
            return setSavingingUserRoles(state, false);
    }
    return state;
}