import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../common/View';
import Map from '../common/Map';
import CardContainer from '../common/CardContainer';
import Error from '../common/Error';
import LargeIconClearButton from '../controls/LargeIconClearButton';
import SmallIconClearButton from '../controls/SmallIconClearButton';
import MapLocationIcon from '../../resources/MapLocationIcon';
import RefreshIcon from '../../resources/RefreshIcon';
import { Palette } from '../../common/constants/palette';

export interface ILocationProps {
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onRefreshLocation: Function;
    theme: any;
    classes: any;
}

export interface ILocationState {
    showMap: boolean;
}

class Location extends Component<ILocationProps, ILocationState> {

    constructor(props: ILocationProps) {
        super(props);

        this.onRefresh = this.onRefresh.bind(this);

        this.state = {
            showMap: false
        }
    }

    onRefresh() {
        if (this.props.onRefreshLocation) {
            this.props.onRefreshLocation();
        }
    }

    render() {
        const { theme, classes } = this.props;
        let locationControls;
        let map;
        let self = this;
        if (this.props.locationError || !this.props.locationFound) {
            map = <View>
                <Error message={this.props.locationError} />
                <View style={{ padding: 10 }}></View>
            </View>;
        } else if (this.state.showMap && this.props.locationFound && this.props.latitude && this.props.longitude) {
            map = (
                <Map
                    hideByDefault={false}
                    hideControls={true}
                    latitude={this.props.latitude}
                    longitude={this.props.longitude} />
            );
        }
        locationControls = this.state.showMap ? (
            <View style={{ margin: '10px', display: 'flex', flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                    <SmallIconClearButton
                        text={"Hide Location"}
                        icon={<MapLocationIcon
                            fill={Palette.ControlBorder} />}
                        onClick={() => self.setState({ showMap: false })} />
                </View>
                    <View className={classes.horizontalDivider} style={{ flex: 0 }}>
                    </View>
                <View style={{ flex: 1 }}>
                    <SmallIconClearButton
                        text={"Refresh"}
                        icon={<RefreshIcon fill={Palette.ControlBorder} />}
                        onClick={() => this.onRefresh()} />

                </View>
            </View>
        ) : (
            <View>
                <View style={{ margin: '10px', display: 'flex', flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                        <LargeIconClearButton
                            text={"Show Location"}
                            icon={<MapLocationIcon
                                fill={Palette.ControlBorder} />}
                            onClick={() => self.setState({ showMap: true })} />
                    </View>
                    <View className={classes.horizontalDivider} style={{ flex: 0 }}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <LargeIconClearButton
                            text={"Refresh"}
                            icon={<RefreshIcon fill={Palette.ControlBorder} />}
                            onClick={() => this.onRefresh()} />

                    </View>
                </View>
            </View>);

        return <CardContainer title={"Location"}>
            {map}
            {locationControls}
            {this.props.children}
        </CardContainer>
    }
}

export default withStyles(styles, { withTheme: true })(Location)