import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";


export const checklistTemplateApi = {
  /*createChecklist: (
    venueId,
    securityFirmId,
    templateId) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.CreateChecklist();
      query.venueId = venueId;
      query.securityFirmId = securityFirmId;
      query.templateId = templateId;
      const request = client.post(query);
      return from(request);
  },*/
  saveChecklistTemplate: (
    venueId,
    securityFirmId,
    checklistTemplate
  ) => {
    var client = new AusComplyJsonServiceClient();
    var saveChecklistTemplateRequest = new AusComplyDtos.SaveChecklistTemplate();
    saveChecklistTemplateRequest.venueId = venueId;
    saveChecklistTemplateRequest.securityFirmId = securityFirmId;
    saveChecklistTemplateRequest.checklistTemplate = checklistTemplate;
    const request = client.post(saveChecklistTemplateRequest);
    return from(request);
  },
  getChecklistTemplate: (
    venueId,
    securityFirmId,
    checklistTemplateId
  ) => {
    var client = new AusComplyJsonServiceClient();
    var getChecklistTemplateRequest = new AusComplyDtos.GetChecklistTemplate();
    getChecklistTemplateRequest.venueId = venueId;
    getChecklistTemplateRequest.securityFirmId = securityFirmId;
    getChecklistTemplateRequest.id = checklistTemplateId;
    const request = client.get(getChecklistTemplateRequest);
    return from(request);
  },
  getChecklistTemplates: (
    venueId,
    securityFirmId,
    page,
    pageSize,
    searchText,
    venues,
    securityFirms,
    checklistTemplateCategories,
    industryCategories,
    states
  ) => {
    var client = new AusComplyJsonServiceClient();
    var getChecklistTemplatesRequest = new AusComplyDtos.GetChecklistTemplateSummaries();
    getChecklistTemplatesRequest.venueId = venueId;
    getChecklistTemplatesRequest.securityFirmId = securityFirmId;
    getChecklistTemplatesRequest.page = page;
    getChecklistTemplatesRequest.pageSize = pageSize;
    getChecklistTemplatesRequest.searchText = searchText;
    getChecklistTemplatesRequest.venues = venues;
    getChecklistTemplatesRequest.securityFirms = securityFirms;
    getChecklistTemplatesRequest.checklistTemplateCategories = checklistTemplateCategories;
    getChecklistTemplatesRequest.states = states;
    getChecklistTemplatesRequest.industryCategories = industryCategories;
    const request = client.get(getChecklistTemplatesRequest);
    return from(request);
  },
  archiveChecklistTemplates: checklistTemplateIds => {
    var client = new AusComplyJsonServiceClient();
    var archiveChecklistTemplatesRequest = new AusComplyDtos.ArchiveChecklistTemplates();
    archiveChecklistTemplatesRequest.checklistTemplateIds = checklistTemplateIds;
    const request = client.post(archiveChecklistTemplatesRequest);
    return from(request);
  },
  copyChecklistTemplates: (
    venueId,
    securityFirmId,
    checklistTemplateIds,
    isAdmin, 
    groupId) => {
    var client = new AusComplyJsonServiceClient();
    var copyChecklistTemplatesRequest = new AusComplyDtos.CopyChecklistTemplates();
    copyChecklistTemplatesRequest.checklistTemplateIds = checklistTemplateIds;
    if (!isAdmin) {
      copyChecklistTemplatesRequest.venueId = venueId;
      copyChecklistTemplatesRequest.securityFirmId = securityFirmId;
      copyChecklistTemplatesRequest.groupId = groupId;
    }
    const request = client.post(copyChecklistTemplatesRequest);
    return from(request);
  },
  getChecklistTemplateCategories: () => {
    var client = new AusComplyJsonServiceClient();
    var checklistTemplateCategoriesRequest = new AusComplyDtos.GetChecklistTemplateCategories();
    const request = client.get(checklistTemplateCategoriesRequest);
    return from(request);
  },
  getChecklistTemplateCategorySummaries: (
    searchText,
    venues,
    securityFirms,
    industryCategories,
    states
  ) => {
    var client = new AusComplyJsonServiceClient();
    var checklistTemplateCategoriesRequest = new AusComplyDtos.GetChecklistTemplateCategorySummaries();
    checklistTemplateCategoriesRequest.searchText = searchText;
    checklistTemplateCategoriesRequest.venues = venues;
    checklistTemplateCategoriesRequest.securityFirms = securityFirms;
    checklistTemplateCategoriesRequest.states = states;
    checklistTemplateCategoriesRequest.industryCategories = industryCategories;
    const request = client.get(checklistTemplateCategoriesRequest);
    return from(request);
  },
  saveChecklistTemplateCategory: checklistTemplateCategory => {
    var client = new AusComplyJsonServiceClient();
    var saveChecklistTemplateCategoryRequest = new AusComplyDtos.SaveChecklistTemplateCategory();
    saveChecklistTemplateCategoryRequest.checklistTemplateCategory = checklistTemplateCategory;
    const request = client.post(saveChecklistTemplateCategoryRequest);
    return from(request);
  },
  archiveChecklistTemplateCategories: checklistTemplateCategoryIds => {
    var client = new AusComplyJsonServiceClient();
    var archiveChecklistTemplateCategoriesRequest = new AusComplyDtos.ArchiveChecklistTemplateCategories();
    archiveChecklistTemplateCategoriesRequest.checklistTemplateCategoryIds = checklistTemplateCategoryIds;
    const request = client.post(archiveChecklistTemplateCategoriesRequest);
    return from(request);
  },
  getChecklistTemplateCategory: checklistTemplateCategoryId => {
    var client = new AusComplyJsonServiceClient();
    var getChecklistTemplateCategoryRequest = new AusComplyDtos.GetChecklistTemplateCategory();
    getChecklistTemplateCategoryRequest.id = checklistTemplateCategoryId;
    const request = client.get(getChecklistTemplateCategoryRequest);
    return from(request);
  }
};