import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '../controls/ButtonLink';
import FormErrorMessage from '../alerts/FormErrorMessage';
import CardContainer from '../common/CardContainer';
import Box from '@material-ui/core/Box';
import Media from "react-media";
import Logo from '../../resources/Logo';
import Loading from '../common/Loading';
import PasswordControlGroup from '../controls/PasswordControlGroup';
import DefaultButton from '../controls/DefaultButton';
import Information from '../common/Information';

export interface IProfileEmailConfirmationProps {
    isLoggedIn: boolean;
    userId: number;
    emailConfirmationGuid: string;
    isConfirming: boolean;
    onEmailConfirmation: Function;
    onUserDetailsRefresh: Function;
    error: string;
    history: any;
    location: any;
    theme: any;
    classes: any;
}

const CancelLink = forwardRef((props, ref) => <Link to="/" {...props} />);

class ProfileEmailConfirmation extends Component<IProfileEmailConfirmationProps, any> {
    constructor(props: IProfileEmailConfirmationProps) {
        super(props)

        this.state = {
            isChecking: true
        }

    }

    componentDidMount() {
        this.props.onEmailConfirmation(this.props.userId, this.props.emailConfirmationGuid, "");
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isConfirming && prevProps.isConfirming) {
            this.setState({ isChecking: false });
        }
        if (!this.props.isConfirming && prevProps.isConfirming && !this.props.error) {
            if (this.props.isLoggedIn) {
                this.props.onUserDetailsRefresh();
                this.props.history.push('/');
            }
        }
    }

    render() {
        const { classes, theme } = this.props;
        let content;
        if (this.props.isConfirming) {
            content = (<Loading message={"Confirming email..."} />);
        } else if (!this.props.isConfirming && this.props.error) {
            content = (<FormErrorMessage error={this.props.error} />);
        } else if (!this.props.isConfirming && !this.state.isChecking) {
            content = (
                <>
                    <Information style={{padding: '10px'}} message={"Your email has been confirmed. Login in to the website or close this page and refresh your profile on your device."} />
                </>
            );
        } else {
            content = (
                <>

                </>
            );
        }
        return (
            <>
                {!this.props.isLoggedIn && (
                    <Media query={{ maxWidth: 1025 }}>
                        {matches =>
                            matches ? (
                                <img src={require('../../resources/MobileBackground.jpg')} style={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} alt="backdrop" />
                            ) : (
                                    <img src={require('../../resources/DesktopBackground.jpg')} style={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} alt="backdrop" />
                                )
                        }
                    </Media>
                )}
                <Box display="flex" flexDirection="column" justifyContent="center" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    {!this.props.isLoggedIn && (
                        <Box p={0} flexGrow={1}></Box>
                    )}
                    <Box p={0}>
                        <form noValidate autoComplete="off" style={{ margin: '20px' }} >

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1} md={2} lg={4}></Grid>
                                <Grid item xs={12} sm={10} md={8} lg={4} style={{ textAlign: 'center' }}>
                                    {!this.props.isLoggedIn && (
                                        <>
                                            <Logo fill={'#fff'} style={{ width: '80%', height: 'auto' }} />
                                            <h3 style={{
                                                color: '#999999',
                                                padding: '0',
                                                margin: '10px',
                                                textAlign: 'center',
                                                marginBottom: '10px',
                                                textShadow: '0 0 5px #000000',
                                                fontSize: '22px'
                                            }}>Digital Incident Register</h3>
                                        </>
                                    )}

                                    <CardContainer style={{ padding: '30px 30px 0 30px', margin: '20px 0' }}>
                                        {content}

                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            <Box p={2} flexGrow={1}>
                                                <ButtonLink text="Close" small={true} component={CancelLink} />
                                            </Box>
                                        </Box>
                                    </CardContainer>
                                </Grid>
                            </Grid>

                        </form>

                    </Box>
                    <Box p={0} flexGrow={0}></Box>
                </Box>

            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ProfileEmailConfirmation));