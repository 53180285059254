import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { incidentActionTemplateLogic } from '../../../common/logic/incidentActionTemplateLogic';

export interface IIncidentActionTemplateCardProps {
    incidentActionTemplate: AusComplyDtos.IncidentActionTemplate;
    showFunctionalAreas: boolean;
    canEdit: boolean;
    onCommand?: Function;
}

export default class IncidentActionTemplateCard extends React.Component<IIncidentActionTemplateCardProps, any> {
    constructor(props: IIncidentActionTemplateCardProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.incidentActionTemplate);
        }
    }

    render() {
        if (!this.props.incidentActionTemplate) {
            return (<></>);
        }

        var commands = incidentActionTemplateLogic.getCommands(this.props.incidentActionTemplate, this.props.canEdit);
        var item = this.props.incidentActionTemplate;

        return (
            <CardRow isInfo={!item.groupId && !item.venueId} isSuccess={(item.groupId != null && item.groupId > 0)} isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{item.name}</CardTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardTitle>Incident Categories</CardTitle>
                        <CardTypography>{item.incidentCategoryTypeDisplay}</CardTypography>
                    </Grid>
                    {this.props.showFunctionalAreas && <>
                        <Grid item xs={12}>
                            <CardTitle>Functional Areas</CardTitle>
                            <CardTypography>{item.selectedFunctionalAreasDisplay}</CardTypography>
                        </Grid>
                    </>}
                </Grid>
            </CardRow>
        );
    }
}