import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueEvent from '../components/venue/venueEvent/VenueEvent'
import * as venueEventActions from '../common/actions/venueEvent';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venueEvent.venueEvent.isLoading,
        isSaving: state.venueEvent.venueEvent.isSaving,
        venueEvent: state.venueEvent.venueEvent.venueEvent,
        canEditReportingPeriods: permissionsLogic.hasPermissionForState(state, "ManageReportingPeriods")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueEventActions.venueEventReset())
        },
        onUpdate: (venueEvent) => {
            dispatch(venueEventActions.venueEventUpdate(venueEvent))
        },
        onSave: () => {
            dispatch(venueEventActions.venueEventSaveRequest())
        },
        onReset: () => {
            dispatch(venueEventActions.venueEventReset())
        },
    }
}

const VenueEventContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueEvent))

export default VenueEventContainer