import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { 
    correctionalFacilityInMateCellCreateRequest,
    correctionalFacilityInMateCellReset,
    correctionalFacilityInMateCellSetSearch,
    correctionalFacilityInMateCellUpdateInmate,
    correctionalFacilityInMateCellUpdateCell,
    correctionalFacilityInMateCellUpdateVenueLocation,
    correctionalFacilityInMateCellSaveRequest
} from '../common/actions/correctionalFacility';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import CreateInMateCell from '../components/facility/correctionalFacilityInMateCell/CreateInMateCell';

const mapStateToProps = state => {
    return {
        isLoading: state.correctionalFacility.createInMateCell.isLoading,
        isSaving: state.correctionalFacility.createInMateCell.isSaving,
        search: state.correctionalFacility.createInMateCell.search,
        hasSearched: state.correctionalFacility.createInMateCell.hasSearched,
        error: state.correctionalFacility.createInMateCell.error,
        correctionalFacilityInMateCell: state.correctionalFacility.createInMateCell.correctionalFacilityInMateCell,
        correctionalFacilityInMate: state.correctionalFacility.createInMateCell.correctionalFacilityInMateCell.correctionalFacilityInMate,
        correctionalFacilityInMateVenueLocation: state.correctionalFacility.createInMateCell.correctionalFacilityInMateCell.correctionalFacilityInMateVenueLocation,
        inmates: state.correctionalFacility.createInMateCell.inmates,
        cells: state.correctionalFacility.createInMateCell.cells,
        risks: state.correctionalFacility.createInMateCell.risks,
        genders: state.correctionalFacility.createInMateCell.genders,
        nationalities: state.correctionalFacility.createInMateCell.nationalities,
        isValid: state.correctionalFacility.createInMateCell.isValid
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onReset: () => {
            dispatch(correctionalFacilityInMateCellReset())
        },
        onCreate: (search) => {
            dispatch(correctionalFacilityInMateCellCreateRequest(search))
        },
        onSave: () => {
            dispatch(correctionalFacilityInMateCellSaveRequest())
        },
        onSetSearch: (search) => {
            dispatch(correctionalFacilityInMateCellSetSearch(search))
        },
        onUpdateInmate: (correctionalFacilityInMate) => {
            dispatch(correctionalFacilityInMateCellUpdateInmate(correctionalFacilityInMate))
        },
        onUpdateInmateCell: (correctionalFacilityInMateCell) => {
            dispatch(correctionalFacilityInMateCellUpdateCell(correctionalFacilityInMateCell))
        },
        onUpdateInmateVenueLocation: (correctionalFacilityInMateVenueLocation) => {
            dispatch(correctionalFacilityInMateCellUpdateVenueLocation(correctionalFacilityInMateVenueLocation))
        }
    }
}
const CorrectionalFacilityCreateInMateCellContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateInMateCell))
export default CorrectionalFacilityCreateInMateCellContainer