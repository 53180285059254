import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import { venueLocationLogic } from '../../../common/logic/venueLocationLogic';

export interface IVenueLocationRowProps {
    venueLocation: AusComplyDtos.VenueLocation;
    showIndustyCagetories?: boolean;
    showCameras?: boolean;
    showSubLocations?: boolean;
    onCommand?: Function;
}

export default class VenueLocationRow extends React.Component<IVenueLocationRowProps, any> {
    constructor(props: IVenueLocationRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.venueLocation);
        }
    }

    render() {
        if (!this.props.venueLocation) {
            return (<></>);
        }

        var commands = venueLocationLogic.getCommands(this.props.venueLocation);
        var item = this.props.venueLocation;

        var categories = this.props.venueLocation.industryCategoriesDisplay;
        if (categories.length > 100) {
            categories = categories.substring(0, 100) + "... (" + this.props.venueLocation.industryCategorySelections.filter(f => f.selected).length.toString() + ")";
        }

        if (this.props.showIndustyCagetories) {
            return (
                <TableRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                    <td>
                        <p>
                            {item.name}
                        </p>
                    </td>
                    <td>
                        <p>
                            {categories}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.code}
                        </p>
                    </td>
                    <td>
                        <p>
                            {item.rank}
                        </p>
                    </td>
                </TableRow>
            );
        }
        return (
            <TableRow isDanger={item.obsolete} commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {item.name}
                    </p>
                </td>
                {this.props.showCameras && (
                    <td>
                        <p>
                            {item.facialRecognitionCameraSummary}
                        </p>
                    </td>
                )}
                {this.props.showSubLocations && (
                    <td>
                        <p>
                            {item.selectedSubLocationsDisplay}
                        </p>
                    </td>
                )}
                <td>
                    <p>
                        {item.code}
                    </p>
                </td>
                <td>
                    <p>
                        {item.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}