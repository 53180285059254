import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IContainedParagraphProps {
    text: string;
    maxLines: number;
}

export default class ContainedParagraph extends React.PureComponent<IContainedParagraphProps, any> {
    constructor(props: IContainedParagraphProps){
        super(props)
    }
    render() {
        const { text, maxLines } = this.props;
        let content;
        if (text) {            
            content = text.split('\n').map((item, i) => {
                return <span>{item || ""}<br /></span>;
            });
        }

        return (
            <p className={"contained-paragraph contained-paragraph-" + maxLines.toString()}
                >{content}</p>
        );
    }
}
        