import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const incidentFlagTypeApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentFlagTypes();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  newEmpty: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentFlagTypeNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  addOrUpdate: (venueId, securityFirmId, incidentFlagType) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostIncidentFlagType();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.incidentFlagTypeId = incidentFlagType.incidentFlagTypeId;
    query.incidentFlagType = incidentFlagType;
    const request = client.post(query);
    return from(request);
  }
};
