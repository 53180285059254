import React from 'react';
import { UserSignOnStart, UserSignOnStatus, TimeZoneDate } from "../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Map from '../common/Map'
import LayoutForm from '../layout/LayoutForm'
import PageTitle from '../common/PageTitle'
import FormWarningMessage from '../alerts/FormWarningMessage'
import FormErrorMessage from '../alerts/FormErrorMessage'
import TextControlGroup from '../controls/TextControlGroup'
import SelectControlGroup from '../controls/SelectControlGroup'
import CheckboxControlGroup from '../controls/CheckboxControlGroup'
import DateControlGroup from '../controls/DateControlGroup'
import Time24HourSelectorControlGroup from '../controls/Time24HourSelectorControlGroup'
import ShiftDurationControlGroup from '../controls/ShiftDurationControlGroup'
import PasswordControlGroup from '../controls/PasswordControlGroup'
import update from "immutability-helper";
import { withRouter } from "react-router";
import PrimaryButton from '../controls/PrimaryButton';
import TransparentButton from '../controls/TransparentButton';
import PageLayout from '../layout/PageLayout';
import Location from '../common/Location';
import View from '../common/View';
import SignOnRegisterTypes from './SignOnRegisterTypes';
import TimeZoneDateControlGroup, { TimeZoneLayout } from '../controls/TimeZoneDateControlGroup';
import CardRow from '../common/CardRow';
import { Palette } from '../../common/constants/palette';

export interface ISignOnProps {
    isLoading: boolean,
    isSaving: boolean,
    saveError: string,
    userSignOnStart: UserSignOnStart;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    isValidating: boolean;
    isSignedOnToOtherVenueBlocked: boolean;
    isSignedOnToOtherVenueWarning: boolean;
    onRefresh: Function;
    onRefreshLocation: Function;
    onCheckSignOnUser: Function;
    onCheckSignOnUserReset: Function;
    onSignOn: Function;
    history: any;
    location: any;
    canViewSignOnRegister: boolean;
    userId: number;
}

export interface ISignOnState {
    error: string;
    errors: any;
    userSignOnStatus: UserSignOnStatus;
    rosteredStartDate: TimeZoneDate;
}

class SignOn extends React.PureComponent<ISignOnProps, ISignOnState> {
    constructor(props: ISignOnProps) {
        super(props);

        this.onRefresh = this.onRefresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onReturn = this.onReturn.bind(this);
        this.onValidateUser = this.onValidateUser.bind(this);
        this.onSelectedUserChanged = this.onSelectedUserChanged.bind(this);
        this.onGuardNumberChanged = this.onGuardNumberChanged.bind(this);
        this.onSupervisorRequiredChanged = this.onSupervisorRequiredChanged.bind(this);
        this.onSelectedSupervisorUserChanged = this.onSelectedSupervisorUserChanged.bind(this);
        this.onDurationChanged = this.onDurationChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);

        this.state = {
            error: "",
            errors: {},
            userSignOnStatus: new UserSignOnStatus(),
            rosteredStartDate: new TimeZoneDate()
        }
    }

    componentDidMount() {
        if (!this.props.isLoading && !this.props.isLocationLoading) {
            this.props.onRefreshLocation();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // on changed...
        if (prevProps.isLocationLoading && !this.props.isLocationLoading) {
            this.props.onRefresh();
        }
        if (JSON.stringify(prevProps.userSignOnStart) !== JSON.stringify(this.props.userSignOnStart) && !this.props.isLoading) {
            
            let rosteredStart = new Date();
            if (this.props.userSignOnStart.userSignOnStatus.rosteredStartDate) {
                rosteredStart = new Date(this.props.userSignOnStart.userSignOnStatus.rosteredStartDate);
            } else {
                this.props.userSignOnStart.userSignOnStatus.rosteredStartDate = rosteredStart.toJSON()
            }
            
            let userSignOnStatus: UserSignOnStatus = { ...this.props.userSignOnStart.userSignOnStatus };
            let rosteredStartDate = { ...this.props.userSignOnStart.userSignOnStatus.rosteredStartTimeZoneDate };
            this.setState({
                userSignOnStatus,
                rosteredStartDate
            })
        }

        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.saveError) {
                this.setState({
                    errors: {},
                    error: this.props.saveError
                });
            } else {
                // save was successful - redirect back
                this.onReturn();
            }
        }
    }

    onRefresh() {
        this.props.onRefreshLocation();
    }


    onSelectedUserChanged(value: any) {
        if (value != null) {
            this.setState({ userSignOnStatus: update(this.state.userSignOnStatus, { userRoleId: { $set: parseInt(value.toString()) } }) },
                () => this.onValidateUser());
        }
    }

    onGuardNumberChanged(value: string) {
        let newValue = value || '';
        if (newValue != null) {
            this.setState({ userSignOnStatus: update(this.state.userSignOnStatus, { guardNum: { $set: newValue } }) });
        }
    }

    onSupervisorRequiredChanged(value) {
        if (value != null) {
            this.setState({ userSignOnStatus: update(this.state.userSignOnStatus, { supervisorRequired: { $set: value } }) });
        }
    }

    onSelectedSupervisorUserChanged(value: any) {
        if (value != null) {
            this.setState({ userSignOnStatus: update(this.state.userSignOnStatus, { supervisorUserRoleId: { $set: parseInt(value.toString()) } }) });
        }
    }

    onDurationChanged(value) {
        if (value != null) {
            this.setState({
                userSignOnStatus: update(this.state.userSignOnStatus,
                    {
                        shiftHours: { $set: value.hours },
                        shiftMinutes: { $set: value.minutes }
                    })
            });
        }
    }

    onPasswordChanged(value) {
        if (value != null) {
            this.setState({ userSignOnStatus: update(this.state.userSignOnStatus, { password: { $set: value } }) });
        }
    }

    onValueChanged(fieldName: string, value) {
        let userSignOnStatus = { ...this.state.userSignOnStatus };
        userSignOnStatus[fieldName] = value;
        this.setState({ userSignOnStatus });
    }

    onValidateUser() {
        if (this.state.userSignOnStatus.userRoleId === 0) {
            this.props.onCheckSignOnUserReset();
            return;
        }
        this.props.onCheckSignOnUser(this.state.userSignOnStatus.venueId, this.state.userSignOnStatus.userRoleId);
    }

    onSave() {

        // Validate
        let errors = {}
        let error = "";
        if (!this.state.userSignOnStatus.userRoleId || this.state.userSignOnStatus.userRoleId === 0) {
            errors["userRoleId"] = "Sign on user is required";
        }

        if (this.props.userSignOnStart.guardNumberIsRequired && (!this.state.userSignOnStatus.guardNum || this.state.userSignOnStatus.guardNum.length === 0)) {
            errors["guardNum"] = "Guard number is required";
        }

        if (this.props.userSignOnStart.guardNumberIsRequired && this.state.userSignOnStatus.guardNum && this.state.userSignOnStatus.guardNum.length > 0) {
            if (isNaN(this.state.userSignOnStatus.guardNum as any)) {
                errors["guardNum"] = "Guard number must be a number";
            }
            let guardNumber = parseInt(this.state.userSignOnStatus.guardNum);
            if (guardNumber <= 0) {
                errors["guardNum"] = "Guard number must be greater than zero (0)";
            }
        }

        if (!this.state.userSignOnStatus.password || this.state.userSignOnStatus.password.length === 0) {
            errors["password"] = "Password is required";
        }

        if (this.state.userSignOnStatus.shiftHours === 0 && this.state.userSignOnStatus.shiftMinutes === 0) {
            errors["shiftLength"] = "Shift length is required";
        }

        if (this.state.userSignOnStatus.signOnRegisterTypes && this.state.userSignOnStatus.signOnRegisterTypes.length > 1) {
            if (this.state.userSignOnStatus.selectedSignOnRegisterTypes.length == 0) {
                errors["selectedSignOnRegisterTypes"] = "Select a sign on type";
            }
        }

        if (Object.keys(errors).length > 0) {
            if (Object.keys(errors).length > 1) {
                error = "Please fix the highlighted fields and try again";
            } else if (Object.keys(errors).length === 1) {
                error = errors[Object.keys(errors)[0]];
            }
            this.setState({ errors: errors, error: error });
            return;
        }

        this.setState({
            errors: {},
            error: ""
        }); // set saving
        this.props.onSignOn(this.state.userSignOnStatus);
    }

    onReturn() {
        if (this.props.canViewSignOnRegister) {
            this.props.history.push('/signonoffregister');
        } else {
            this.props.history.push('/');
        }
    }

    locationRefreshComponent(children?: any) {
        return <Location
            isLocationLoading={this.props.isLocationLoading}
            latitude={this.props.latitude}
            longitude={this.props.longitude}
            locationFound
            locationError={this.props.locationError}
            onRefreshLocation={this.onRefresh}
        >
            {children}
        </Location>
    }

    render() {
        let self = this;
        let loading = this.props.isLoading || this.props.isLocationLoading;
        let alternateMessage;
        let locationRefresh;

        if (!this.props.userSignOnStart.signOnEnabled) {
            alternateMessage = (
                <span>The sign on register is not available at the venue</span>
            );
        } else if (!this.props.userSignOnStart.hasOpenReportingPeriod && this.props.userSignOnStart.canStartReportingPeriod) {
            alternateMessage = (
                <span>There is no open reporting period for the selected venue, <Link to="/venuereportingperiod">Start reporting period.</Link></span>
            );
        } else if (!this.props.userSignOnStart.hasOpenReportingPeriod) {
            alternateMessage = (
                <span>There is no open reporting period for the selected venue.</span>
            );
        } else if (!this.props.userSignOnStart.canChangeUser && this.props.userSignOnStart.isAlreadySignedOn) {
            alternateMessage = (
                <span>You are already signed on to the register.</span>
            );
        } else if (!this.props.userSignOnStart.isInRangeOfVenue && !this.props.userSignOnStart.allowOutOfRangeSignIn) {
            if (!this.props.locationFound) {
                alternateMessage = (
                    <span>We could not determine your location. Please ensure location services are enabled on your device and try again or speak to your supervisor.</span>
                );
                locationRefresh = this.locationRefreshComponent();
            } else {
                let message = (
                    <View style={{ paddingLeft: '10px', paddingRight: '10px' }}><span>You do not appear to be within range of your selected venue, return to the venue to sign on.<br />({this.props.latitude.toFixed(6)}, {this.props.longitude.toFixed(6)})</span></View>
                );
                locationRefresh = this.locationRefreshComponent(message);
            }
        }

        let warning;
        if (!this.props.userSignOnStart.isInRangeOfVenue && this.props.userSignOnStart.allowOutOfRangeSignIn) {
            let warningMessage = "You do not appear to be within range of your selected venue. (" + this.props.latitude.toFixed(6) + ", " + this.props.longitude.toFixed(6) + ")";
            let warning2 = <View style={{ paddingLeft: '10px', paddingRight: '10px' }}><FormWarningMessage warning={warningMessage}></FormWarningMessage></View>;
            locationRefresh = this.locationRefreshComponent(warning2);
        }
        let alreadySignedInWarning;
        if (this.props.isSignedOnToOtherVenueWarning) {
            alreadySignedInWarning = <FormWarningMessage warning="This user is already signed into another venue reporting period."></FormWarningMessage>;
        }
        if (this.props.isSignedOnToOtherVenueBlocked) {
            alreadySignedInWarning = <FormErrorMessage error="This user is already signed into another venue reporting period, sign out of the other venue or contact your manager."></FormErrorMessage>;
        }

        let signOnForm;
        if (this.props.userSignOnStart.userSignOnStatus) {
            let manualRoster = <><TimeZoneDateControlGroup
                text='Rostered start'
                date={this.state.rosteredStartDate}
                layout={TimeZoneLayout.StackedWithoutTimezoneAndClear}
                onChange={value => this.onValueChanged("rosteredStartTimeZoneDate", value)}
            />
                <ShiftDurationControlGroup
                    text="Shift length"
                    hours={this.state.userSignOnStatus.shiftHours}
                    minutes={this.state.userSignOnStatus.shiftMinutes}
                    error={this.state.errors["shiftLength"]}
                    onChanged={this.onDurationChanged}
                />
            </>

            let rosteredStart: JSX.Element[] = [];
            let currentUser = (this.props.userSignOnStart.userSignOnStatus.userRoleId == this.state.userSignOnStatus.userRoleId);
            if (currentUser && this.state.userSignOnStatus && this.state.userSignOnStatus.roster && this.state.userSignOnStatus.roster.rosterItems && this.state.userSignOnStatus.roster.rosterItems.length > 0) {

                this.state.userSignOnStatus.roster.rosterItems.forEach(rosterItem => {
                    const color = this.state.userSignOnStatus.rosterItemId == rosterItem.id ? Palette.Primary : Palette.Text;
                    rosteredStart = [...rosteredStart, <CardRow isSuccess={this.state.userSignOnStatus.rosterItemId == rosterItem.id} onClick={() => self.onValueChanged("rosterItemId", rosterItem.id)}>
                        <p style={{ padding: '5px', margin: 0, color: color }}>Rostered shift {rosterItem.description}</p>
                    </CardRow>];
                });
                const color = this.state.userSignOnStatus.rosterItemId == 0 ? Palette.Primary : Palette.Text;
                rosteredStart = [...rosteredStart, <CardRow isSuccess={this.state.userSignOnStatus.rosterItemId == 0} onClick={() => self.onValueChanged("rosterItemId", 0)}>
                    <p style={{ padding: '5px', margin: 0, color: color }}>Custom shift</p>
                </CardRow>];
                if (this.state.userSignOnStatus.rosterItemId == 0) {
                    rosteredStart = [...rosteredStart, manualRoster];
                }
            } else {
                rosteredStart = [...rosteredStart, manualRoster];
            }

            signOnForm = (
                <>
                    <TextControlGroup text="Venue"
                        defaultValue={this.state.userSignOnStatus.venue}
                        readonly={true}></TextControlGroup>
                    <SelectControlGroup text="User"
                        error={this.state.errors["userRoleId"]}
                        defaultValue={this.state.userSignOnStatus.userRoleId}
                        onChange={this.onSelectedUserChanged}
                        readonly={!this.props.userSignOnStart.canChangeUser}>
                        <option value={0} />
                        {this.props.userSignOnStart.signOnUsers.map((item, index) => (
                            <option key={item.userRoleId} value={item.userRoleId}>{item.display}</option>
                        ))}
                    </SelectControlGroup>
                    <SignOnRegisterTypes
                        userSignOnStatus={this.state.userSignOnStatus}
                        error={this.state.errors["selectedSignOnRegisterTypes"]}
                        onUpdate={(value: UserSignOnStatus) => this.onValueChanged("selectedSignOnRegisterTypes", value.selectedSignOnRegisterTypes)}
                    />
                    <TextControlGroup text="Guard #, Radio or Reference"
                        error={this.state.errors["guardNum"]}
                        id={"grr"}
                        autoComplete={"off"}
                        defaultValue={this.state.userSignOnStatus.guardNum}
                        onChange={this.onGuardNumberChanged}
                        readonly={false} />
                    {(this.props.userSignOnStart.showRestrictedLicenseeOption) &&
                        <CheckboxControlGroup
                            text="Restricted Licensee"
                            defaultValue={this.state.userSignOnStatus.supervisorRequired}
                            readonly={false}
                            onChanged={this.onSupervisorRequiredChanged}
                        />
                    }
                    {((this.props.userSignOnStart.showRestrictedLicenseeOption) && this.state.userSignOnStatus.supervisorRequired) &&
                        <SelectControlGroup
                            text="Supervisor (Qld only)"
                            onChange={this.onSelectedSupervisorUserChanged}
                            defaultValue={this.state.userSignOnStatus.supervisorUserRoleId}>
                            <option value={0} />
                            {this.props.userSignOnStart.supervisors.map((item, index) => (
                                <option key={item.userRoleId} value={item.userRoleId}>{item.display}</option>
                            ))}
                        </SelectControlGroup>
                    }
                    <View style={{ marginTop: '10px' }}>
                        {rosteredStart}
                    </View>
                    {/* decoy control for autofill */}
                    <input style={{ border: 'none', fontSize: '1px', color: 'transparent', backgroundColor: 'transparent' }} name="username" id="username" />
                    <PasswordControlGroup
                        text="Password"
                        error={this.state.errors["password"]}
                        onChange={this.onPasswordChanged}
                    />
                </>
            );
        }


        return (
            <PageLayout
                headerText={"Sign on"}
                loading={loading}
                saving={this.props.isSaving}
                error={this.state.error}
                notFullWidth={true}
                hideQuickActions={true}
                alternateMessage={alternateMessage}
                extraMessages={locationRefresh}
                footerLeftContent={<TransparentButton text="Cancel" onClick={this.onReturn} />}
                footerRightContent={<PrimaryButton text="Sign on"
                    onClick={this.onSave}
                    disabled={this.props.isValidating || this.state.userSignOnStatus.userRoleId === 0 || this.props.isSignedOnToOtherVenueBlocked}
                />}
            >
                {warning}
                {alreadySignedInWarning}
                {signOnForm}
            </PageLayout>
        );
    }
}

export default withRouter(SignOn)