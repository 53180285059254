import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName, PrintSend } from '../../common/dto/common';
import FullPageDialog from '../layout/FullPageDialog';
import PrintAndSend from '../print/PrintAndSend';
import LayoutForm from '../layout/LayoutForm';

export interface IIncidentPrintProps {
    theme: any;
    classes: any;
    incidentId: number;
    printOptions?: AusComplyDtos.PrintOptions,
    show: boolean;
    isLoading: boolean;
    onHide: Function;
    onLoadPrintOptions: Function;
    onPrint: Function;
    onErrorNotification: Function;
}

export interface IIncidentPrintState {
}

class IncidentPrint extends React.PureComponent<IIncidentPrintProps, any> {

    constructor(props: IIncidentPrintProps) {
        super(props);
        this.state = {
        };
        this.onDismissed = this.onDismissed.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.props.onLoadPrintOptions();
        }
        if (!this.props.isLoading && prevProps.isLoading && this.props.printOptions) {

        }
    }

    onDismissed() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    handleExport(option: string) {
        this.onPrint("EXPORT", this.props.incidentId, false, [], [], [], "NONE");
    }

    handlePrint(option: string, imageOption: string) {
        this.onPrint("DOWNLOAD", this.props.incidentId, option === "VERSIONS", [], [], [], imageOption);
    }

    handleEmail(option: string, send: PrintSend, imageOption: string) {
        this.onPrint("EMAIL", this.props.incidentId, false, send.recipients, send.ccRecipients, send.bccRecipients, imageOption);
    }

    onPrint(option: string, id: number, includeVersions: boolean, to: string[], cc: string[], bcc: string[], imageOption: string) {
        if (this.props.onPrint) {
            this.props.onPrint(option, id, includeVersions, to, cc, bcc, imageOption);
        }
    }

    render() {
        let pdfOptions: KeyName[] = [];
        pdfOptions.push({ key: "LATEST", name: "Incident Report" });
        pdfOptions.push({ key: "VERSIONS", name: "Incident Report (With Version History)" });

        let imageOptions: KeyName[] = [];
        imageOptions.push({ key: "LOW", name: "Include low-resolution" });
        imageOptions.push({ key: "MEDIUM", name: "Include medium-resolution" });
        imageOptions.push({ key: "HIGH", name: "Include high-resolution" });
        imageOptions.push({ key: "NONE", name: "Excluded" });

        return (
            <FullPageDialog open={this.props.show} onDismissed={this.onDismissed} title={"Print"} notFullWidth={true}>
                <LayoutForm>
                    <PrintAndSend
                        isLoading={this.props.isLoading}
                        printOptions={this.props.printOptions}
                        onExport={this.handleExport}
                        onPrint={this.handlePrint}
                        onCancel={this.onDismissed}
                        onEmail={this.handleEmail}
                        pdfOptions={pdfOptions}
                        imageOptions={imageOptions} />
                </LayoutForm>
            </FullPageDialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPrint);