import React, { Component, forwardRef } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Progress from '../../controls/Progress';
import { withRouter } from "react-router";
import PageTitle from '../../common/PageTitle';
import ButtonLink from '../../controls/ButtonLink';
import FixedFooter from '../../layout/FixedFooter';
import RegisterSecurityFirmNavigation from './RegisterSecurityFirmNavigation';
import RegisterSecurityFirmDetails from './RegisterSecurityFirmDetails';
import RegisterSecurityFirmAddress from './RegisterSecurityFirmAddress';
import RegisterSecurityFirmComplianceDocuments from './RegisterSecurityFirmComplianceDocuments';
import RegisterSecurityFirmOtherDocuments from './RegisterSecurityFirmOtherDocuments';
import RegisterSecurityFirmContact from './RegisterSecurityFirmContact';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Error from '../../common/Error';
import { Link } from 'react-router-dom';
import { ProgressItem } from '../../controls/Progress';
import PageLayout from '../../layout/PageLayout';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IRegisterSecurityFirmProps {
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    errors: any;
    securityFirm: AusComplyDTOs.SecurityFirm;
    primaryContact: AusComplyDTOs.SecurityFirmContact;
    managerContact: AusComplyDTOs.SecurityFirmContact;
    states: AusComplyDTOs.State[],
    securityLicenceClasses: AusComplyDTOs.SecurityLicenceClass[],
    otherDocuments: AusComplyDTOs.File[],
    otherDocumentTypes: AusComplyDTOs.DocumentType[],
    complianceDocuments: AusComplyDTOs.File[],
    complianceDocumentTypes: AusComplyDTOs.DocumentType[],
    onReset: Function;
    onInitSecurityFirm: Function;
    onSave: Function;
    onUpdate: Function;
    onUpdateOtherDocuments: Function;
    onUpdateComplianceDocuments: Function;
    onErrorNotification: Function;
    step: number;
    onSetStep: Function;
    history: any;
    location: any;
}

interface IRegisterSecurityFirmState {
    canSubmit: boolean;
    validSubmit: boolean;
}

const PrivacyPolicyLink = forwardRef((props, ref) => <Link to="/register/securityFirm/privacypolicy" {...props} />);

const TermsLink = forwardRef((props, ref) => <Link to="/register/securityFirm/terms" {...props} />);

class RegisterSecurityFirm extends Component<IRegisterSecurityFirmProps, IRegisterSecurityFirmState> {

    constructor(props: IRegisterSecurityFirmProps) {
        super(props)

        this.state = {
            canSubmit: true,
            validSubmit: true
        }

        this.onBack = this.onBack.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onUpdatePrimaryContact = this.onUpdatePrimaryContact.bind(this);
        this.onUpdateManagerContact = this.onUpdateManagerContact.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onStep = this.onStep.bind(this);
        this.canNext = this.canNext.bind(this);
        this.canBack = this.canBack.bind(this);
        this.onUpdateOtherDocuments = this.onUpdateOtherDocuments.bind(this);
        this.onUpdateComplianceDocuments = this.onUpdateComplianceDocuments.bind(this);
    }

    componentDidMount() {
        if (this.props.securityFirm && this.props.securityFirm.securityFirmId == -1) {
            this.props.onInitSecurityFirm();
        }
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error) {
                // Saved successfully
                if (this.props.onReset) {
                    this.props.onReset();
                }
                this.props.history.push('/register/securityFirm/success');
            }
        }

        // eslint-disable-next-line

        if (this.props.isLoading && !this.props.securityFirm.securityFirmId != preProps.securityFirm.securityFirmId) {
            // set the step to 1
        }
    }

    onBack() {
        if (this.canBack()) {
            this.props.onSetStep(this.props.step - 1);
        }
    }

    onNext() {
        if (this.canNext()) {
            this.props.onSetStep(this.props.step + 1);
        }
    }

    canNext() {
        let canNext = true;
        return canNext;
    }

    canBack() {
        let canBack = true;
        return canBack;
    }

    onStep(progressItem: ProgressItem) {
        if (this.canNext() && this.canBack()) {
            this.props.onSetStep(progressItem.step);
        }
    }



    onCancel() {
        // cancel and navigate to home
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.props.history.push('/');
    }

    onSubmit() {
        this.props.onSave();
    }

    onUpdate(securityFirm) {
        this.props.onUpdate(securityFirm, this.props.managerContact, this.props.primaryContact);
    }

    onUpdatePrimaryContact(primaryContact) {
        this.props.onUpdate(this.props.securityFirm, this.props.managerContact, primaryContact);
    }

    onUpdateManagerContact(managerContact) {
        this.props.onUpdate(this.props.securityFirm, managerContact, this.props.primaryContact);
    }

    onUpdateOtherDocuments(otherDocuments) {
        this.props.onUpdateOtherDocuments(otherDocuments);
    }

    onUpdateComplianceDocuments(complianceDocuments) {
        this.props.onUpdateComplianceDocuments(complianceDocuments);
    }
    render() {
        let progressItems: ProgressItem[] = [];
        let stepDisplays = [
            <>
                <RegisterSecurityFirmDetails
                    securityFirm={this.props.securityFirm}
                    states={this.props.states}
                    securityLicenceClasses={this.props.securityLicenceClasses}
                    errors={this.props.errors}
                    canEditMasterLicence={true}
                    onUpdate={this.onUpdate}
                    onSwipedLeft={this.onNext} />
                <ButtonLink text="Privacy Policy" small={false} component={PrivacyPolicyLink} />
                <ButtonLink text="Terms of Use" small={false} component={TermsLink} />
            </>,
            <>
                <RegisterSecurityFirmAddress
                    securityFirm={this.props.securityFirm}
                    states={this.props.states}
                    errors={this.props.errors}
                    onUpdate={this.onUpdate}
                    onSwipedLeft={this.onNext}
                    onSwipedRight={this.onBack} />
            </>,
            <>
                <RegisterSecurityFirmContact
                    securityFirm={this.props.securityFirm}
                    managerContact={this.props.managerContact}
                    primaryContact={this.props.primaryContact}
                    errors={this.props.errors}
                    onUpdate={this.onUpdate}
                    onUpdateManagerContact={this.onUpdateManagerContact}
                    onUpdatePrimaryContact={this.onUpdatePrimaryContact}
                    onSwipedLeft={this.onNext}
                    onSwipedRight={this.onBack} />
            </>,
            <>
                <RegisterSecurityFirmComplianceDocuments securityFirm={this.props.securityFirm} documents={this.props.complianceDocuments} documentTypes={this.props.complianceDocumentTypes} onUpdate={this.onUpdateComplianceDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <RegisterSecurityFirmOtherDocuments securityFirm={this.props.securityFirm} documents={this.props.otherDocuments} documentTypes={this.props.otherDocumentTypes} onUpdate={this.onUpdateOtherDocuments} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>
        ];
        progressItems.push({ step: progressItems.length + 1, key: "details", name: "Details" });
        progressItems.push({ step: progressItems.length + 1, key: "address", name: "Address" });
        progressItems.push({ step: progressItems.length + 1, key: "contact", name: "Contact" });
        progressItems.push({ step: progressItems.length + 1, key: "documents", name: "Documents" });

        let stepDisplay = stepDisplays[this.props.step - 1];

        if (this.props.isLoading) {
            return (
                <>
                    <PageTitle title="Register Security Firm" />
                    <Loading message={"Initialising"} onCancel={this.onCancel} />
                </>
            );
        }

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title="Registering Security Firm" />
                    <Saving />
                </>
            );
        }

        return (
            <PageLayout
                headerText={"Register Security Firm"}
                footerContent={<RegisterSecurityFirmNavigation
                    step={this.props.step}
                    steps={stepDisplays.length}
                    onNext={this.onNext}
                    onBack={this.onBack}
                    onCancel={this.onCancel}
                    onSubmit={this.onSubmit}
                    canSubmit={this.props.errors.count == 0}
                    canNext={this.canNext()}
                />}
                aboveFooterContent={<>
                    {(this.props.step == stepDisplays.length && this.state.canSubmit) && (<>
                        <PrimaryButton text={"Register Security Firm"} onClick={this.onSubmit} />
                    </>)}
                </>}
            >
                <Progress
                    value={this.props.step}
                    items={progressItems}
                    isError={this.props.errors.count > 0}
                    showStep={true}
                    onClick={this.onStep} />

                {
                    this.props.error && (
                        <Error message={this.props.error} />
                    )
                }

                {stepDisplay}
            </PageLayout >
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(RegisterSecurityFirm));