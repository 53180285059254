import { connect } from 'react-redux'
import { withRouter } from "react-router";
import GroupUser from '../components/groups/group/GroupUser'

const mapStateToProps = (state, props) => {
    return {
        groupId:  props.match.params.groupId,
        userId: props.match.params.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const GroupUserContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupUser))

export default GroupUserContainer