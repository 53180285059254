import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICorrectionalFacilityInMateCellHeaderProps {
    classes: any;
}

class CorrectionalFacilityInMateCellHeader extends React.Component<ICorrectionalFacilityInMateCellHeaderProps, any> {
    render() {
        const { classes } = this.props;
        
        return (
            <tr>
                <th className={classes.tableHrIndicator}></th>
                <th className={classes.tableHr}>Cell</th>
                <th className={classes.tableHr}>Bed</th>
                <th className={classes.tableHr}>Name</th>
                <th className={classes.tableHr}>Gender</th>
                <th className={classes.tableHr}>Age</th>
                <th className={classes.tableHr}>Nationality</th>
                <th className={classes.tableHr}>Risk Status</th>
                <th className={classes.tableHrCenter}>Notes</th>
                <th className={classes.tableHr}>Next Inspection</th>
                <th className={classes.tableHrRight} style={{ width: '35px'}}></th>
                <th className={classes.tableHrCommands}></th>
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CorrectionalFacilityInMateCellHeader);