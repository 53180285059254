import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import ListIcon from '../../resources/ListIcon';
import MagnifyingIcon from '../../resources/MagnifyingIcon';
import { Palette } from '../../common/constants/palette';

export interface ISearchControlProps {
    theme: any;
    classes: any;
    value?: string;
    searchText?: string;
    showAll?: boolean;
    onShowAll?: Function;
    onChange?: Function;
    onSearch?: Function;
    compact?: boolean;
}

class SearchControl extends React.PureComponent<ISearchControlProps, any> {

    constructor(props: ISearchControlProps) {
        super(props)
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onShowAll = this.onShowAll.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.state = {
            value: ""
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value })
        }
    }

    onChange(event) {
        if (event != null) {
            let val = event.target.value;
            this.setState({ value: val }, () => {
                if (this.props.onChange) {
                    this.props.onChange(val);
                }
            });
        }
    }

    onClear() {
        this.setState({ value: "" }, () => {
            if (this.props.onChange) {
                this.props.onChange("");
            }
            if (this.props.onSearch) {
                this.props.onSearch("");
            }
        });
    }

    onShowAll() {
        if (this.props.onShowAll) {
            this.props.onShowAll();
        }
    }

    onKeyPress = event => {
        if (event.key === 'Enter') {
            if (this.props.onSearch) {
                this.props.onSearch(this.state.value);
                event.preventDefault();
            }
        }
    }

    render() {
        const { classes, theme } = this.props;

        if (this.props.compact) {
            return (
                <div style={{
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    border: 'none',
                }}>
                    <InputBase
                        style={{
                            marginLeft: 8,
                            flex: 1, ...theme.custom.textField,
                            border: 'none',
                            textAlign: 'left',
                            fontSize: '12px'
                        }}
                        autoComplete='off'
                        placeholder={this.props.searchText || "Search"}
                        classes={{ root: classes.searchRootCompact, input: classes.searchInput }}
                        value={this.state.value || ''}
                        onKeyPress={this.onKeyPress}
                        onChange={this.onChange} />

                    {this.state.value && (
                        <IconButton style={{ padding: 6, color: theme.custom.errorColor.color }} aria-label="Clear" onClick={this.onClear}>
                            <ClearIcon style={{ height: '12px', width: '12px' }} />
                        </IconButton>
                    )}
                    {(!this.state.value && this.props.showAll) && (
                        <IconButton style={{ padding: 6, color: theme.custom.label.color }} aria-label="Show all" onClick={this.onShowAll}>
                            <ListIcon style={{ height: '12px', width: '12px' }} />
                        </IconButton>
                    )}
                    {!this.state.value &&
                        <IconButton style={{ padding: 6, color: Palette.ControlBackgroundDark }}
                            onClick={() => {
                                if (this.props.onSearch) {
                                    this.props.onSearch(this.state.value)
                                }
                            }}
                            aria-label="Search">
                            <MagnifyingIcon style={{ height: '10px', width: '10px' }} />
                        </IconButton>
                    }
                </div>
            );
        }

        return (
            <div style={{
                padding: '0',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderBottomColor: theme.custom.colors.controlBorder,
                borderBottomWidth: "1",
                borderBottomStyle: "solid",
            }}>
                <InputBase
                    style={{
                        marginLeft: 8,
                        flex: 1, ...theme.custom.textField,
                        border: 'none',
                        textAlign: 'left'
                    }}
                    autoComplete='off'
                    placeholder={this.props.searchText || "Search"}
                    classes={{ root: classes.searchRoot, input: classes.searchInput }}
                    value={this.state.value || ''}
                    onKeyPress={this.onKeyPress}
                    onChange={this.onChange} />

                {this.state.value && (
                    <IconButton style={{ padding: 10, color: theme.custom.errorColor.color }} aria-label="Clear" onClick={this.onClear}>
                        <ClearIcon />
                    </IconButton>
                )}
                {(!this.state.value && this.props.showAll) && (
                    <IconButton style={{ padding: 10, color: theme.custom.label.color }} aria-label="Show all" onClick={this.onShowAll}>
                        <ListIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                )}
                <Divider style={{
                    width: 1,
                    height: '20px',
                    margin: 4,
                    backgroundColor: theme.custom.colors.controlBorder
                }} />
                <IconButton style={{ padding: 10, color: theme.custom.colors.controlBorder }}
                    onClick={() => {
                        if (this.props.onSearch) {
                            this.props.onSearch(this.state.value)
                        }
                    }}
                    aria-label="Search">
                    <MagnifyingIcon style={{ height: '20px', width: '20px' }} />
                </IconButton>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SearchControl);