import update from "immutability-helper";
import * as imageProcessingActions from "../actions/imageProcessing";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";

const initialState = {
    incidentDocumentId: 0,
    incidentPatronPhotoId: 0,
    isProcessing: false,
    imageText: ""
};

function setIsProcessing(state, isProcessing) {
    let newState = update(state, {
        isProcessing: { $set: isProcessing },
    });
    return newState;
}

function setId(state, incidentDocumentId, incidentPatronPhotoId) {
    let newState = update(state, {
        incidentDocumentId: { $set: incidentDocumentId },
        incidentPatronPhotoId: { $set: incidentPatronPhotoId },
    });
    return newState;
}

function setImageText(state, imageText) {
    let newState = update(state, {
        imageText: { $set: imageText }
    });
    return newState;
}

export default function imageProcessing(state = initialState, action) {
    switch (action.type) {
        case imageProcessingActions.IMAGE_PROCESSING_VENUE_REQUEST:
            return setIsProcessing(state, true);
        case imageProcessingActions.IMAGE_PROCESSING_VENUE_REQUEST_SUCCESS:
            return setIsProcessing(state, false);
        case imageProcessingActions.IMAGE_PROCESSING_VENUE_REQUEST_FAILURE:
            return setIsProcessing(state, false);

        case imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST:
            return setIsProcessing(setImageText(state, ""), true);
        case imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS:
            return setIsProcessing(setImageText(state, action.imageText), false);
        case imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_FAILURE:
            return setIsProcessing(state, false);

        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST:
            return setImageText(setId(setIsProcessing(state, true), action.incidentDocumentId, 0), "");
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_SUCCESS:
            return setIsProcessing(setImageText(state, action.imageText), false);
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_FAILURE:
            return setIsProcessing(state, false);
            
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST:
            return setImageText(setId(setIsProcessing(state, true), 0, action.incidentPatronPhotoId), "");
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_SUCCESS:
            return setIsProcessing(setImageText(state, action.imageText), false);
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST_FAILURE:
            return setIsProcessing(state, false);
    }
    return state;
}