import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const rosterServiceApi = {
  find: (venueId, securityFirmId, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRoster();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  newLeaveRequest: (venueId, securityFirmId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRosterNewLeave();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.post(query);
    return from(request);
  },
  saveLeaveRequest: (venueId, securityFirmId, leaveRequest) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostRosterLeave();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.leaveRequest = leaveRequest;
    const request = client.post(query);
    return from(request);
  }
};
