
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { userServiceApi } from '../services/userService';
import { venueServiceApi } from '../services/venueService';
import { securityFirmServiceApi } from '../services/securityFirmService';
import { notificationServiceApi } from '../services/notificationService';
import * as profileActions from "../actions/profile";
import * as userRoleActions from '../actions/userRole';
import * as userLoginActions from "../actions/userLogin";
import * as epicCommon from './common';

import {
    notifyError, notifyErrorMessage
} from './common';

import { notificationShow, notificationSuccessShow } from "../actions/notification";

const profileResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_CHANGED),
        map(action => profileActions.profileReset())
    );

const profileRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getCurrentUser()
                .pipe(
                    map(response => profileActions.profileRequestSuccess(response.user, response.states, response.genders, response.userOtherDocuments, response.userOtherDocumentTypes, response.userComplianceDocuments, response.userComplianceDocumentTypes, response.avatar, response.groupAccessRequests)),
                    catchError(error => notifyErrorMessage(error, "Failed to load user profile", profileActions.profileRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load user profile", profileActions.profileRequestFailure))
    );

const profileRequestReloadAfterUserRoleSaveEpic = action$ =>
    action$.pipe(
        ofType(userRoleActions.USER_ROLE_ADD_SAVE_SUCCESS),
        map((action: any) => profileActions.profileRequest())
    );

const profileRequestReloadAfterUserRoleUpdateEpic = action$ =>
    action$.pipe(
        ofType(userRoleActions.USER_ROLE_UPDATE_SAVE_SUCCESS),
        map((action: any) => profileActions.profileRequest())
    );


const profileVenuesEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.availableToRequestUserRoles()
                .pipe(
                    map(response => profileActions.profileVenuesAvailableToRequestSuccess(response.venues)),
                    catchError(error => notifyErrorMessage(error, "Failed to load available venues", profileActions.profileVenuesAvailableToRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to load available venues", profileActions.profileVenuesAvailableToRequestFailure))
    );

const profileSecurityFirmsEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.availableToRequestUserRoles()
                .pipe(
                    map(response => profileActions.profileSecurityFirmsAvailableToRequestSuccess(response.securityFirms)),
                    catchError(error => of(
                        profileActions.profileSecurityFirmsAvailableToRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to load available security firms")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileSecurityFirmsAvailableToRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to load available security firms")
        ))
    );

const profileUserRoleRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_USER_ROLE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.userRoleRequestNotification(action['registerAs'], action['venueIds'], action['securityFirmIds'])
                .pipe(
                    map(response => profileActions.profileUserRoleRequestSuccess()),
                    catchError(error => of(
                        profileActions.profileUserRoleRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to request user role")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileUserRoleRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to request user role")
        ))
    );

const profileUserRoleRequestSuccessNotificationEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_USER_ROLE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Role was requested"))
    );

const profileUserRoleRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_USER_ROLE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) => of(userLoginActions.userDetailsRequest(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, state.user.details.userSession.user.venueEventId)))
    );

const profileReloadOnUserRoleRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_USER_ROLE_REQUEST_SUCCESS),
        map((action: any) => profileActions.profileRequest())
    );

const profileOtherDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_OTHER_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveUserOtherDocuments(0, action['documentTypeId'], action['files'])
                .pipe(
                    map(response => profileActions.profileSaveOtherDocumentsSuccess(response.userOtherDocuments)),
                    catchError(error => of(
                        profileActions.profileSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save user other documents")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save user other documents")
        ))
    );

const profileOtherDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_OTHER_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeUserOtherDocuments(0, action['otherDocument'])
                .pipe(
                    map(response => profileActions.profileRemoveOtherDocumentSuccess(response.userOtherDocuments)),
                    catchError(error => of(
                        profileActions.profileRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove user other document")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove user other document")
        ))
    );

const profileComplianceDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_COMPLIANCE_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveUserComplianceDocuments(0, action['documentTypeId'], action['expiryDate'], action['files'])
                .pipe(
                    map(response => profileActions.profileSaveComplianceDocumentsSuccess(response.userComplianceDocuments)),
                    catchError(error => of(
                        profileActions.profileSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save user compliance documents")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save user compliance documents")
        ))
    );

const profileComplianceDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_COMPLIANCE_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeUserComplianceDocuments(0, action['complianceDocument'])
                .pipe(
                    map(response => profileActions.profileRemoveComplianceDocumentSuccess(response.userComplianceDocuments)),
                    catchError(error => of(
                        profileActions.profileRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove user compliance document")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove user compliance document")
        ))
    );

const profileSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.updateUser(action['profile'])
                .pipe(
                    map(response => profileActions.profileSaveRequestSuccess(response.user)),
                    catchError(error => of(
                        profileActions.profileSaveRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save profile")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileSaveRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save profile")
        ))
    );

const profileSledCheckEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_SLED_CHECK_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.sledCheck(action['profile'])
                .pipe(
                    map(response => profileActions.profileSledCheckRequestSuccess(response.user)),
                    catchError(error => of(
                        profileActions.profileSledCheckRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to check license number")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileSledCheckRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to check license number")
        ))
    );

const profileResendVerificationEmailEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.resendVerificationEmail()
                .pipe(
                    map(response => profileActions.profileResendVerificationEmailRequestSuccess()),
                    catchError(error => of(
                        profileActions.profileResendVerificationEmailRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to request verification email")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileResendVerificationEmailRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to request verification email")
        ))
    );

const profileResendVerificationEmailSuccessEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Verification email was sent"))
    );

const profileResendVerificationEmailSuccessRefreshEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS),
        map((action: any) => userLoginActions.userDetailsRefresh())
    );

const profileSendForgotPasswordEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_FORGOT_PASSWORD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.sendForgotPassword(action['username'])
                .pipe(
                    map(response => notificationSuccessShow("Check your inbox for the link to reset your password")),
                    catchError(error => of(
                        notificationShow("Failed to request password reset")
                    ))
                )
        ),
        catchError(error => of(
            notificationShow("Failed to request password reset")
        ))
    );

const profileForgotPasswordValidateEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.forgotPasswordValidate(action['userId'], action['userForgotPasswordId'], action['forgotPasswordGuid'])
                .pipe(
                    map(response => userLoginActions.userLoginForgotPasswordValidateRequestSuccess(response.isValid)),
                    catchError(error => of(
                        userLoginActions.userLoginForgotPasswordValidateRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to validate password reset")
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userLoginForgotPasswordValidateRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to validate password reset")
        ))
    );

const profileResetPasswordEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.resetPassword(action['userId'], action['userForgotPasswordId'], action['forgotPasswordGuid'], action['newPassword'], action['newPasswordConfirm'])
                .pipe(
                    map(response => userLoginActions.userLoginPasswordResetRequestSuccess()),
                    catchError(error => of(
                        userLoginActions.userLoginPasswordResetRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to validate password reset")
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userLoginPasswordResetRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to validate password reset")
        ))
    );

const profileResetPasswordSuccessEpic = action$ =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Password was reset"))
    );

const userEmailConfirmEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_EMAIL_CONFIRM_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.emailConfirmation(action['userId'], action['emailConfirmationGuid'], action['password'])
                .pipe(
                    map(response => userLoginActions.userEmailConfirmRequestSuccess()),
                    catchError(error => of(
                        userLoginActions.userEmailConfirmRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to confirm email")
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userEmailConfirmRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to confirm email")
        ))
    );

const userUpdateAvatarEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_UPDATE_AVATAR_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.updateAvatar(state.profile.profile.userId, action['file'])
                .pipe(
                    map(response => profileActions.profileUpdateAvatarRequestSuccess(response.file)),
                    catchError(error => of(
                        profileActions.profileUpdateAvatarRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save avatar")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileUpdateAvatarRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save avatar")
        ))
    );


const profileChangePasswordEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_CHANGE_PASSWORD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.changePassword(action['userId'], action['oldPassword'], action['newPassword'], action['newPasswordConfirm'])
                .pipe(
                    map(response => profileActions.profileChangePasswordRequestSuccess()),
                    catchError(error => of(
                        profileActions.profileChangePasswordRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to change password")
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileChangePasswordRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to change password")
        ))
    );

const profileChangePasswordSuccessEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Password was changed"))
    );

const userLoginPasswordResetCodeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_PASSWORD_RESET_CODE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.requestForgotPasswordCode(action['mobile'])
                .pipe(
                    map(response => userLoginActions.userLoginPasswordResetCodeSuccess()),
                    catchError(error => of(
                        userLoginActions.userLoginPasswordResetCodeFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userLoginPasswordResetCodeFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
        ))
    );

const userLoginPasswordResetCodeRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Code was generated and sent"))
    );

const userLoginValidatePasswordResetCodeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.validateForgotPasswordCode(action['mobile'], action['code'])
                .pipe(
                    map(response => userLoginActions.userLoginValidatePasswordResetCodeRequestSuccess(response.isValid)),
                    catchError(error => of(
                        userLoginActions.userLoginValidatePasswordResetCodeRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userLoginValidatePasswordResetCodeRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
        ))
    );

const userLoginValidatePasswordResetCodeRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_SUCCESS),
        filter((action: any) => { return !action['isValid']; }),
        map((action: any) => notificationShow("Invalid or expired code"))
    );


const userLoginResetPasswordWithCodeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.resetPasswordFromCode(action['mobile'], action['code'], action['newPassword'], action['newPasswordConfirm'])
                .pipe(
                    map(response => userLoginActions.userLoginResetPasswordWithCodeRequestSuccess()),
                    catchError(error => of(
                        userLoginActions.userLoginResetPasswordWithCodeRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
                    ))
                )
        ),
        catchError(error => of(
            userLoginActions.userLoginResetPasswordWithCodeRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
        ))
    );

const userLoginResetPasswordWithCodeRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(userLoginActions.USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Password was changed"))
    );

const profileSaveRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_SAVE_REQUEST_SUCCESS),
        map((action: any) => userLoginActions.userDetailsRefresh())
    );

const userDeleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(profileActions.PROFILE_DELETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.delete(action['reconfirmedEmail'])
                .pipe(
                    map(response => profileActions.profileDeleteRequestSuccess()),
                    catchError(error => of(
                        profileActions.profileDeleteRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
                    ))
                )
        ),
        catchError(error => of(
            profileActions.profileDeleteRequestFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow(error.responseStatus ? error.responseStatus.message : error.message)
        ))
    );

const userDeleteRequestEpicSuccessEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_DELETE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("Account removed"))
    );

const userDeleteRequestEpicSuccessLogoutEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_DELETE_REQUEST_SUCCESS),
        map((action: any) => userLoginActions.userReset())
    );

const profileSendVerificationSmsRequestEpic = epicCommon.epicApiPost(
    profileActions.PROFILE_RESEND_VERIFICATION_SMS_REQUEST,
    AusComplyDtos.PostUserResendVerificationSms,
    [],
    response => profileActions.profileSendVerificationSmsRequestSuccess(),
    profileActions.profileSendVerificationSmsRequestFailure);

const profileVerificationSmsCodeRequestEpic = epicCommon.epicApiPost(
    profileActions.PROFILE_VERIFICATION_SMS_CODE_REQUEST,
    AusComplyDtos.PostValidateSmsCode,
    ['code'],
    response => profileActions.profileVerificationSmsCodeRequestSuccess(),
    profileActions.profileVerificationSmsCodeRequestFailure);

const profileVerificationSmsCodeRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(profileActions.PROFILE_VERIFICATION_SMS_CODE_REQUEST_SUCCESS),
        map((action: any) => userLoginActions.userDetailsRefresh())
    );


export const profileEpics = combineEpics(
    profileRequestEpic,
    profileVenuesEpic,
    profileSecurityFirmsEpic,
    profileUserRoleRequestEpic,
    profileUserRoleRequestSuccessNotificationEpic,
    profileUserRoleRequestSuccessEpic,
    profileReloadOnUserRoleRequestSuccessEpic,
    profileOtherDocumentsSaveEpic,
    profileOtherDocumentRemoveEpic,
    profileComplianceDocumentsSaveEpic,
    profileComplianceDocumentRemoveEpic,
    profileSaveEpic,
    profileSledCheckEpic,
    profileRequestReloadAfterUserRoleSaveEpic,
    profileRequestReloadAfterUserRoleUpdateEpic,
    profileResendVerificationEmailEpic,
    profileResendVerificationEmailSuccessEpic,
    profileResendVerificationEmailSuccessRefreshEpic,
    profileSendForgotPasswordEpic,
    profileForgotPasswordValidateEpic,
    profileResetPasswordEpic,
    profileResetPasswordSuccessEpic,
    userEmailConfirmEpic,
    userUpdateAvatarEpic,
    profileChangePasswordEpic,
    profileChangePasswordSuccessEpic,
    profileResetOnUserChangeEpic,
    userLoginPasswordResetCodeRequestEpic,
    userLoginPasswordResetCodeRequestSuccessEpic,
    userLoginValidatePasswordResetCodeRequestEpic,
    userLoginValidatePasswordResetCodeRequestSuccessEpic,
    userLoginResetPasswordWithCodeRequestEpic,
    userLoginResetPasswordWithCodeRequestSuccessEpic,
    profileSaveRequestSuccessEpic,
    userDeleteRequestEpic,
    userDeleteRequestEpicSuccessEpic,
    userDeleteRequestEpicSuccessLogoutEpic,
    profileSendVerificationSmsRequestEpic,
    profileVerificationSmsCodeRequestEpic,
    profileVerificationSmsCodeRequestSuccessEpic
);