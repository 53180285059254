import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import DefaultButton from '../../controls/DefaultButton';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import PrimaryButton from '../../controls/PrimaryButton';
import Grid from '@material-ui/core/Grid';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardList from '../../common/CardList';
import FullPageDialog from '../../layout/FullPageDialog';
import MultiSelectControl from '../../controls/MutiSelectControl';
import SingleSelectListControl from '../../controls/SingleSelectListControl';
import { KeyName } from '../../../common/dto/common';
import FacialRecognitionEventsList from './facialRecognitionEventsList/FacialRecognitionEventsList';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PageLayout from '../../layout/PageLayout';
import VenueFacialRecognitionNotificationsTabsContainer from '../../../containers/VenueFacialRecognitionNotificationsTabsContainer';
import VenueTabs from '../../admin/venue/VenueTabs';
import TransparentButton from '../../controls/TransparentButton';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';
import FacialRecognitionEventTypes from './FacialRecognitionEventType';
import AgeRange from '../../controls/AgeRange';
import NotificationTrigger from '../../notifications/NotificationTrigger';

export interface IFacialRecognitionEventsProps {
    venueId: number;
    groupId: number;
    venueMode?: boolean;
    groupMode?: boolean;
    adminMode?: boolean;
    isLoading: boolean;
    isSaving: boolean;
    accessLevel: number;
    facialRecognitionEvents: AusComplyDtos.FacialRecognitionEvent[];
    facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent;
    facialRecognitionCameras: AusComplyDtos.FacialRecognitionCamera[],
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[],
    incidentTypes: AusComplyDtos.IncidentType[],
    incidentTypeCategoryTypes: AusComplyDtos.IncidentTypeCategoryTypes[],
    incidentFlagTypes: AusComplyDtos.IncidentFlagType[],
    userRoleTypes: AusComplyDtos.UserRoleType[],
    defaultNotificationTrigger: AusComplyDtos.NotificationTrigger,
    onLoad: Function;
    onSet: Function;
    onSave: Function;
    onUpdate: Function;
    onMoveItem: Function;
    onGenerateAgeAppearance: Function;
    classes: any;
    theme: any;
    history: any;
    location: any;
}

interface IFacialRecognitionEventsState {
    cameras: MulitSelectOption[];
    categories: MulitSelectOption[];
    flags: MulitSelectOption[];
}

class FacialRecognitionEvents extends Component<IFacialRecognitionEventsProps, IFacialRecognitionEventsState> {
    constructor(props: IFacialRecognitionEventsProps) {
        super(props)

        this.state = {
            cameras: [],
            categories: [],
            flags: []
        };
        this.onReset = this.onReset.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onSelectCameraChanged = this.onSelectCameraChanged.bind(this);
        this.onSelectIncidentFlagTypeChanged = this.onSelectIncidentFlagTypeChanged.bind(this);
        this.onSelectIncidentCategoryTypesChanged = this.onSelectIncidentCategoryTypesChanged.bind(this);
        this.loadSelections = this.loadSelections.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.isGroup = this.isGroup.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onAgeRangeChange = this.onAgeRangeChange.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, this.props.groupId);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillReceiveProps() {
    }

    compareEvents(a, b) {
        if (a.processRank > b.processRank) {
            return -1;
        } else if (a.processRank <= b.processRank) {
            return 1;
        }
    }

    onCancelEdit() {
        var facialRecognitionEvent = new AusComplyDtos.FacialRecognitionEvent();
        facialRecognitionEvent.facialRecognitionEventId = -1;
        this.props.onSet(facialRecognitionEvent);
    }

    loadSelections(facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        let cameras: MulitSelectOption[] = [];
        if (this.props.facialRecognitionCameras) {
            this.props.facialRecognitionCameras.forEach((item, index) => {
                let isSelected = false;
                if (facialRecognitionEvent.facialRecognitionEventCameras) {
                    isSelected = facialRecognitionEvent.facialRecognitionEventCameras.filter(f => f.facialRecognitionCameraId == item.facialRecognitionCameraId).length > 0;
                }
                if (isSelected || !item.obsolete) {
                    cameras.push({
                        id: item.facialRecognitionCameraId,
                        name: item.displayName,
                        isCommon: false,
                        selected: isSelected
                    });
                }
            });
        }

        let categories: MulitSelectOption[] = [];
        if (this.props.incidentTypeCategoryTypes) {
            this.props.incidentTypeCategoryTypes.forEach((incidentType, index) => {
                if (incidentType.incidentTypeId.toString() === (facialRecognitionEvent.incidentTypeId || 0).toString()) {
                    incidentType.incidentCategoryTypes.forEach((item, index) => {
                        let isSelected = false;
                        if (facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes) {
                            isSelected = facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes.filter(f => f.incidentCategoryTypeId == item.incidentCategoryTypeId).length > 0;
                        }
                        
                        if ((this.props.accessLevel == 6) || 
                            (isSelected) || 
                            (!item.apiOnlyCreation))  {
                            // hide api apiOnlyCreation if not selected for non level 6
                            categories.push({
                                id: item.incidentCategoryTypeId,
                                name: item.name,
                                isCommon: false,
                                selected: isSelected
                            });
                        }
                    });
                }
            });
        }

        let flags: MulitSelectOption[] = [];
        if (this.props.incidentFlagTypes) {
            this.props.incidentFlagTypes.forEach((item, index) => {
                let isSelected = false;
                if (facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes) {
                    isSelected = facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes.filter(f => f.incidentFlagTypeId == item.incidentFlagTypeId).length > 0;
                }
                flags.push({
                    id: item.incidentFlagTypeId,
                    name: item.name,
                    isCommon: false,
                    selected: isSelected
                });
            });
        }


        this.setState({
            cameras,
            categories,
            flags
        }, () => { });
    }

    isGroup() {
        if (this.props.groupId && this.props.groupId > 0) {
            return true;
        }
        return false;
    }

    onCreate() {
        var facialRecognitionEvent = new AusComplyDtos.FacialRecognitionEvent();
        facialRecognitionEvent.notificationRefreshRate = 60;
        facialRecognitionEvent.facialRecognitionEventType = AusComplyDtos.ngtFacialRecognitionEventType.Watchlist;
        facialRecognitionEvent.ageFrom = 1;
        facialRecognitionEvent.ageTo = 17;
        facialRecognitionEvent.venueId = this.props.venueId;
        facialRecognitionEvent.groupId = this.props.groupId;
        facialRecognitionEvent.anyCamera = this.isGroup();
        facialRecognitionEvent.createIncident = true;
        facialRecognitionEvent.notificationTrigger = { ...this.props.defaultNotificationTrigger };
        this.loadSelections(facialRecognitionEvent);
        this.props.onSet(facialRecognitionEvent);
    }

    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        var reloadSelections = false;
        if (fieldName === "incidentTypeId" && facialRecognitionEvent[fieldName] != value) {
            reloadSelections = true;
        }

        facialRecognitionEvent[fieldName] = value;
        if (reloadSelections) {
            this.loadSelections(facialRecognitionEvent);

        }
        this.props.onSet(facialRecognitionEvent);
    }

    onAgeRangeChange(from: number, to: number) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.ageFrom = from;
        facialRecognitionEvent.ageTo = to;
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectCameraChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventCameras = [];
        items.forEach(item => {
            var facialRecognitionEventCamera = new AusComplyDtos.FacialRecognitionEventCamera();
            facialRecognitionEventCamera.facialRecognitionCameraId = item.id;
            facialRecognitionEvent.facialRecognitionEventCameras.push(facialRecognitionEventCamera);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectIncidentFlagTypeChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes = [];
        items.forEach(item => {
            var facialRecognitionEventIncidentFlagType = new AusComplyDtos.FacialRecognitionEventIncidentFlagType();
            facialRecognitionEventIncidentFlagType.incidentFlagTypeId = item.id;
            facialRecognitionEvent.facialRecognitionEventIncidentFlagTypes.push(facialRecognitionEventIncidentFlagType);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    onSelectIncidentCategoryTypesChanged(items) {
        let facialRecognitionEvent = { ...this.props.facialRecognitionEvent };
        facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes = [];
        items.forEach(item => {
            var facialRecognitionEventIncidentCategoryType = new AusComplyDtos.FacialRecognitionEventIncidentCategoryType();
            facialRecognitionEventIncidentCategoryType.incidentCategoryTypeId = item.id;
            facialRecognitionEvent.facialRecognitionEventIncidentCategoryTypes.push(facialRecognitionEventIncidentCategoryType);
        });
        this.props.onSet(facialRecognitionEvent);
    }

    getCommands(facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        var commands: KeyName[] = [];
        if (!facialRecognitionEvent.obsolete) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });
            if (facialRecognitionEvent.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Age) {
                commands.push({ key: "test", name: "Test Under 18 Appearance" });
            }
        } else {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionEvent: AusComplyDtos.FacialRecognitionEvent) {
        switch (command) {
            case "edit":
                this.loadSelections(facialRecognitionEvent);
                this.props.onSet(facialRecognitionEvent);
                break;
            case "delete":
                var facialRecognitionEvent = { ...facialRecognitionEvent };
                facialRecognitionEvent.obsolete = true;
                this.props.onSet(facialRecognitionEvent);
                this.props.onSave();
                break;
            case "restore":
                var updated = { ...facialRecognitionEvent };
                updated.obsolete = false;
                this.props.onSet(updated);
                this.props.onSave();
                break;
            case "test":
                this.props.onGenerateAgeAppearance(facialRecognitionEvent.facialRecognitionEventId);
                break;
            default:
                break;
        }
    }

    onReset() {
        this.props.onLoad(this.props.venueId, this.props.groupId);
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }

    render() {

        let createFab;
        let create;
        let self = this;
        createFab = (
            <DefaultFab onClick={() => this.onCreate()} isExtended={true}>Add Watchlist Event</DefaultFab>
        );
        create = <TransparentButton text="Add Watchlist Event" onClick={() => this.onCreate()} />

        let watchlists = this.props.facialRecognitionWatchlists;
        if (this.props.facialRecognitionEvent && this.props.facialRecognitionEvent.facialRecognitionWatchlistId) {
            watchlists = watchlists.filter(f => !f.obsolete || f.facialRecognitionWatchlistId == this.props.facialRecognitionEvent.facialRecognitionWatchlistId);
        } else {
            watchlists = watchlists.filter(f => !f.obsolete);
        }


        let showItem = false;
        let facialRecognitionEvent;
        if (this.props.facialRecognitionEvent != null && this.props.facialRecognitionEvent.facialRecognitionEventId != -1) {
            let item = this.props.facialRecognitionEvent;
            

            showItem = true;
            facialRecognitionEvent = (
                <LayoutForm saving={this.props.isSaving}>
                    <SubTitle text={"For Events on"} />
                    <FacialRecognitionEventTypes value={item.facialRecognitionEventType} onChanged={(value) => this.onValueChanged("facialRecognitionEventType", value)} />
                    {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Watchlist && (
                        <SelectControlGroup
                            text="Watchlist"
                            onChange={(value) => this.onValueChanged("facialRecognitionWatchlistId", value)}
                            defaultValue={item.facialRecognitionWatchlistId}>
                            <option value={0} />
                            {watchlists.map((option, index) => {
                                let info = "";
                                if (option.venueId == 0 && option.groupId == 0) {
                                    info = "[global]";
                                }
                                if (option.venueId == 0 && option.groupId > 0) {
                                    info = "[group]";
                                }
                                return (
                                    <option key={option.facialRecognitionWatchlistId} value={option.facialRecognitionWatchlistId}>{option.displayName}      {info}</option>
                                )
                            })}
                        </SelectControlGroup>
                    )}
                    {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Age && (
                        <AgeRange from={item.ageFrom} to={item.ageTo} onChanged={(from, to) => {
                            self.onAgeRangeChange(from, to);
                        }} />
                    )}

                    <CheckboxControlGroup
                        text={"Any Camera"}
                        label={" "}
                        defaultValue={item.anyCamera}
                        readonly={this.isGroup()}
                        onChanged={(value) => this.onValueChanged("anyCamera", value)} />
                    {!item.anyCamera && (
                        <ControlGroup text={" "}>
                            <CardContainer
                                title={"Cameras"}
                                style={{ paddingTop: 0 }}>
                                <CardContent style={{ paddingTop: 0 }}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <MultiSelectControl
                                                label={""}
                                                items={this.state.cameras}
                                                searchText="Type at least three letters..."
                                                minimumCharactersRequired={3}
                                                onSelectItemsChanged={this.onSelectCameraChanged}
                                                canShowAll={true} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardContainer>
                        </ControlGroup>
                    )}

                    <CheckboxControlGroup
                        text={"Stop Event Processing After This Event"}
                        label={" "}
                        defaultValue={item.stopProcessing ? item.stopProcessing : false}
                        onChanged={(value) => this.onValueChanged("stopProcessing", value)} />
                    <TextControlGroup
                        text={"Ignore Duplicates (minutes)"}
                        defaultValue={item.notificationRefreshRate.toString()}
                        numericOnly={true}
                        onBlur={(value) => this.onValueChanged("notificationRefreshRate", value)}
                    />
                    <ControlGroup text={" "}>
                        <CardContainer
                            title={"Incident"}
                            style={{ marginTop: "10px" }}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <CheckboxControlGroup
                                    text={"Create Incident"}
                                    label={" "}
                                    labelAbove={true}
                                    defaultValue={item.createIncident}
                                    onChanged={(value) => this.onValueChanged("createIncident", value)} />
                                {item.createIncident &&
                                    <>
                                        <SelectControlGroup
                                            text="Incident Type"
                                            onChange={(value) => this.onValueChanged("incidentTypeId", value)}
                                            defaultValue={item.incidentTypeId}>
                                            <option value={0} />
                                            {this.props.incidentTypes.map((option, index) => (
                                                <option key={option.incidentTypeId} value={option.incidentTypeId}>{option.name}</option>
                                            ))}
                                        </SelectControlGroup>
                                        <CardContainer
                                            title={"Incident Categories"}
                                            style={{ paddingTop: 0, marginTop: "10px" }}>
                                            <CardContent style={{ paddingTop: 0 }}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <MultiSelectControl
                                                            label={""}
                                                            items={this.state.categories}
                                                            searchText="Type at least three letters..."
                                                            minimumCharactersRequired={3}
                                                            onSelectItemsChanged={this.onSelectIncidentCategoryTypesChanged}
                                                            canShowAll={true} />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardContainer>
                                        <CardContainer
                                            title={"Incident Flags"}
                                            style={{ paddingTop: 0 }}>
                                            <CardContent style={{ paddingTop: 0 }}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <MultiSelectControl
                                                            label={""}
                                                            items={this.state.flags}
                                                            searchText="Type at least three letters..."
                                                            minimumCharactersRequired={3}
                                                            onSelectItemsChanged={this.onSelectIncidentFlagTypeChanged}
                                                            canShowAll={true} />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </CardContainer>
                                    </>}

                            </CardContent>
                        </CardContainer>
                    </ControlGroup>
                    {(item.notificationTrigger) &&
                        <>
                            <ControlGroup text={" "}>
                                <NotificationTrigger
                                    notificationTrigger={item.notificationTrigger}
                                    showEscalation={true}
                                    noControlGroup={true}
                                    hideSummary={true}
                                    onUpdate={(value) => this.onValueChanged("notificationTrigger", value)} />
                            </ControlGroup>
                        </>
                    }


                </LayoutForm>
            );
        }

        let events;
        let groupEvents;
        if (!this.props.isLoading && !this.props.isSaving && this.props.facialRecognitionEvents) {

            if (this.props.groupId > 0) {
                groupEvents = (<FacialRecognitionEventsList
                    loading={this.props.isLoading}
                    data={this.props.facialRecognitionEvents.filter(f => f.groupId > 0)}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    toggleDanger={true}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    isDangerLabel={''}
                    classes={undefined}
                    isWarning={item => item.attachedCameraIsObsolete}
                    isWarningLabel={"Attached to an in-active camera"}
                    onMoveItem={this.props.onMoveItem}
                />);
            } else {
                events = (<FacialRecognitionEventsList
                    loading={this.props.isLoading}
                    data={this.props.facialRecognitionEvents.filter(f => f.venueId > 0)}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    toggleDanger={true}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    isDangerLabel={''}
                    classes={undefined}
                    isWarning={item => item.attachedCameraIsObsolete}
                    isWarningLabel={"Attached to an in-active camera"}
                    onMoveItem={this.props.onMoveItem}
                />);
                groupEvents = (
                    <CardList
                        loading={this.props.isLoading}
                        data={this.props.facialRecognitionEvents.filter(f => f.groupId > 0 && !f.obsolete)}
                        getCommands={item => []}
                        isSuccess={item => item.venueId == 0 && item.groupId > 0}
                        isSuccessLabel={"Group"}
                        isWarning={item => item.attachedCameraIsObsolete}
                        isWarningLabel={"Attached to an in-active camera"}
                        onCommand={(command, item) => { }}
                        canEdit={false}
                        card={(item: AusComplyDtos.FacialRecognitionEvent) => <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Watchlist && (<>
                                        <CardTitle>Watchlist</CardTitle>
                                        <CardTypography>{item.watchlistDisplay}</CardTypography>
                                    </>)}
                                    {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Age && (<>
                                        <CardTitle>Age Range</CardTitle>
                                        <CardTypography>{item.ageFrom}<span>&nbsp;to&nbsp;</span>{item.ageTo}</CardTypography>
                                    </>)}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>For Camera(s)</CardTitle>
                                    <CardTypography>{item.cameraDisplay}</CardTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>Creates an incident type</CardTitle>
                                    <CardTypography>{item.incidentTypeDisplay}</CardTypography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>With incident categories</CardTitle>
                                    <CardTypography>{item.incidentCategoryTypesDisplay}</CardTypography>
                                </Grid>
                                {item.incidentFlagTypesDisplay !== "" && (
                                    <Grid item xs={12} sm={6}>
                                        <CardTitle>With incident flags</CardTitle>
                                        <CardTypography>{item.incidentFlagTypesDisplay}</CardTypography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container spacing={1}>
                                {(item.eventNotificationUserRoleTypesDisplay !== undefined && item.eventNotificationUserRoleTypesDisplay !== "") && (
                                    <Grid item xs={12} sm={6}>
                                        <CardTitle>Creates notification for role types</CardTitle>
                                        <CardTypography>{item.eventNotificationUserRoleTypesDisplay}</CardTypography>
                                    </Grid>
                                )}
                                {(item.notificationTrigger.escalate &&  item.notificationTrigger.escalateAfterMinutes > 0) && (
                                    <Grid item xs={12} sm={6}>
                                        <CardTitle>Escalate after minutes</CardTitle>
                                        <CardTypography>{item.notificationTrigger.escalateAfterMinutes}</CardTypography>
                                    </Grid>
                                )}
                            </Grid>
                        </>}
                    />);
            }
            //
        }

        if (this.props.venueMode) {
            return (
                <DndProvider backend={HTML5Backend}>
                    <PageLayout
                        headerText={"Facial Recognition"}
                        loading={this.props.isLoading}
                        saving={this.props.isSaving}
                        footerLeftContent={create}
                    >
                        <VenueFacialRecognitionNotificationsTabsContainer selectedTab={"Events"} history={this.props.history} />
                        {events}
                        {groupEvents}
                    </PageLayout>
                    <FullPageDialog open={showItem} title="Watchlist Event"
                        footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.onCancelEdit()}></DefaultButton>}
                        footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.onSave()}></PrimaryButton>}
                        onDismissed={() => this.onCancelEdit()}>
                        {facialRecognitionEvent}
                    </FullPageDialog>
                </DndProvider>
            );
        }
        if (this.props.groupMode) {
            return (
                <DndProvider backend={HTML5Backend}>
                    <PageLayout
                        loading={this.props.isLoading}
                        saving={this.props.isSaving}
                        footerLeftContent={create}
                    >
                        {events}
                        {groupEvents}
                    </PageLayout>
                    <FullPageDialog open={showItem} title="Watchlist Event"
                        onDismissed={() => this.onCancelEdit()}
                        footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.onCancelEdit()}></DefaultButton>}
                        footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.onSave()}></PrimaryButton>}>
                        {facialRecognitionEvent}
                    </FullPageDialog>
                </DndProvider>
            );
        }
        if (this.props.adminMode) {
            return (
                <DndProvider backend={HTML5Backend}>
                    <PageLayout
                        headerText={"Venue"}
                        loading={this.props.isLoading}
                        saving={this.props.isSaving}
                        footerRightContent={create}
                        footerLeftContent={<TransparentButton text={"Cancel"} onClick={() => this.onCancel()}></TransparentButton>}
                        hideQuickActions={true}
                    >
                        <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
                        <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Events"} history={this.props.history} />
                        {events}
                        {groupEvents}
                    </PageLayout>
                    <FullPageDialog open={showItem} title="Watchlist Event"
                        footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.onCancelEdit()}></DefaultButton>}
                        footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.onSave()}></PrimaryButton>}
                        onDismissed={() => this.onCancelEdit()}>
                        {facialRecognitionEvent}
                    </FullPageDialog>
                </DndProvider>
            );
        }

        return (
            <DndProvider backend={HTML5Backend}>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    fullWidth={true}
                >
                    {events}
                    {groupEvents}
                </LayoutForm>
                <FullPageDialog
                    open={showItem}
                    title="Watchlist Event"
                    footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.onCancelEdit()}></DefaultButton>}
                    footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.onSave()}></PrimaryButton>}
                    onDismissed={() => this.onCancelEdit()}>
                    {facialRecognitionEvent}
                </FullPageDialog>
                <VenueQuickActionsContainer>
                    {createFab}
                </VenueQuickActionsContainer>
            </DndProvider>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionEvents);