
import React from 'react';

const IncidentIcon = ({
    name = "",
    style = {},
    fill = "#FFFFFF",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || "0 0 50 50"}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g fill={fill}>
            <path d="M38.88,4.32c-5.24-3.48-13.18-5.21-17.73-3.85c-0.11,0-0.25,0.02-0.32,0.03C14.27,1.63,7.05,6.2,4.71,10.7c-3.83,5.44-5.7,13.77-4.18,18.61c0.19,1.08,0.54,2.53,0.79,3.23C2.69,37,2.26,43.02,0.36,45.99c-0.35,0.73-0.22,1.72,0.29,2.22c0.52,0.62,1.48,0.92,2.14,0.67l7.68-2.1c0.9-0.25,2.27-0.05,3.07,0.44c2.5,1.53,6.95,2.78,11.5,2.78c1.65,0,3.31-0.16,4.97-0.5c6.53-1.31,13.62-6.07,15.84-10.63C53.45,27.43,50.33,11.96,38.88,4.32z M42.38,36.57c-3.07,4.62-8.98,8.59-13.19,8.86c-5.43,1.09-12.41-0.28-15.6-3.07c-0.34-0.23-0.86-0.38-1.15-0.35c-0.18,0-0.37,0.03-0.55,0.08l-6.34,1.73c1.07-4.08,0.99-8.41-0.27-12.52C5,30.41,4.71,29.2,4.62,28.6c-0.95-5.47,0.61-12.41,3.48-15.51c3.14-4.46,9.03-8.24,13.15-8.45c0.1,0,0.23-0.02,0.3-0.03c5.25-0.91,11.98,0.51,15.03,3.17C46.12,14.12,48.72,27.01,42.38,36.57z" />
            <path d="M25.09,12.4c-1.15,0-2.08,0.93-2.08,2.08v12.46c0,1.15,0.93,2.08,2.08,2.08s2.08-0.93,2.08-2.08V14.48C27.16,13.33,26.23,12.4,25.09,12.4z" />
            <path d="M26.56,33.98c-0.1-0.08-0.25-0.2-0.33-0.25c-0.1-0.08-0.26-0.17-0.35-0.19c-0.12-0.06-0.3-0.12-0.39-0.12c-0.66-0.14-1.5,0.11-1.87,0.56c-0.19,0.19-0.38,0.49-0.44,0.68c-0.1,0.25-0.18,0.6-0.17,0.79c0,0.27,0.06,0.54,0.17,0.79c0.1,0.25,0.3,0.56,0.44,0.69c0.21,0.19,0.51,0.38,0.68,0.43c0.25,0.1,0.6,0.18,0.79,0.17c0.27,0,0.54-0.06,0.79-0.17c0.25-0.1,0.56-0.3,0.68-0.43c0.19-0.21,0.38-0.51,0.44-0.69c0.1-0.25,0.18-0.6,0.17-0.79c0-0.27-0.06-0.54-0.17-0.79C26.89,34.39,26.7,34.09,26.56,33.98z" />
        </g>
    </svg>
);

export default IncidentIcon;

