import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import PrimaryButton from '../../controls/PrimaryButton';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TransparentButton from '../../controls/TransparentButton';
import { incidentLogic } from '../../../common/logic/incidentLogic';
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IRegisterUserAddressProps {
    user: AusComplyDTOs.User;
    errors: any[];
    states: AusComplyDTOs.State[];
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserAddress extends Component<IRegisterUserAddressProps, any> {
    constructor(props: IRegisterUserAddressProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error){
                return error.error;
            }
        }
        return "";
    }

    onChange(fieldname, value) {
        let user = { ...this.props.user };
        user[fieldname] = value;
        this.props.onUpdate(user);
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Address"}>
                    <CardContent>
                        <LayoutForm>
                            <TextControlGroup
                                text={"Address"}
                                error={this.fieldError("address")}
                                defaultValue={this.props.user.address}
                                onBlur={(value) => this.onChange("address", value)} />
                            <TextControlGroup
                                text={"Suburb"}
                                error={this.fieldError("suburb")}
                                defaultValue={this.props.user.suburb}
                                onBlur={(value) => this.onChange("suburb", value)} />
                            <SelectControlGroup
                                text="State"
                                error={this.fieldError("stateId")}
                                onChange={(value) => this.onChange("stateId", value)}
                                defaultValue={this.props.user.stateId}>
                                <option value={0} />
                                {this.props.states.map((item, index) => (
                                    <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                ))}
                            </SelectControlGroup>
                            <TextControlGroup
                                text={"postcode"}
                                error={this.fieldError("postcode")}
                                defaultValue={this.props.user.postcode}
                                onBlur={(value) => this.onChange("postcode", value)} />
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}