
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userServiceApi } from '../services/userService';
import { adminDashboardServiceApi } from '../services/adminDashboard';

import {
    adminDashboardReset,
    ADMIN_DASHBOARD_FILTER_REQUEST,
    ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS,
    adminDashboardFilterRequestSuccess,
    adminDashboardFilterRequestFailure,
    ADMIN_DASHBOARD_REQUEST,
    adminDashboardRequest,
    adminDashboardRequestSuccess,
    adminDashboardRequestFailure,
    ADMIN_DASHBOARD_INCIDENTS_REQUEST,
    adminDashboardIncidentsRequest,
    adminDashboardIncidentsRequestSuccess,
    adminDashboardIncidentsRequestFailure,
    ADMIN_DASHBOARD_SET_FILTER,
    adminDashboardTotalsRequest,
    ADMIN_DASHBOARD_TOTALS_REQUEST,
    adminDashboardTotalsRequestSuccess,
    adminDashboardTotalsRequestFailure,
    ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST,
    adminDashboardOverviewQuickRequestSuccess,
    adminDashboardOverviewQuickRequestFailure,
    ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST,
    adminDashboardIncidentTotalsRequestSuccess,
    adminDashboardIncidentTotalsRequestFailure,
    ADMIN_DASHBOARD_USER_TOTALS_REQUEST,
    adminDashboardUserTotalsRequestSuccess,
    adminDashboardUserTotalsRequestFailure,
    ADMIN_DASHBOARD_REQUEST_SUCCESS
} from "../actions/adminDashboard";

import {
    USER_CHANGED, USER_RESET
} from '../actions/userLogin';

import {
    notifyError, notifyErrorMessage
} from './common'; 
import { notificationSuccessShow } from "../actions/notification";

const adminDashboardResetOnUserChangeEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED, USER_RESET),
        map(action => adminDashboardReset())
    );

const adminDashboardFilterRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_FILTER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.newFilter(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId
            )
                .pipe(
                    map(response => adminDashboardFilterRequestSuccess(response.filter)),
                    catchError(error => notifyError(error, "adminDashboardFilterRequestEpic.newFilter", adminDashboardFilterRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardFilterRequestEpic", adminDashboardFilterRequestFailure))
    );

const adminDashboardFilterRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS),
        map((action: any) => adminDashboardRequest())
    );

const adminDashboardSetFilterEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_SET_FILTER),
        map((action: any) => adminDashboardRequest())
    );

const adminDashboardRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.adminDashboard.filter, 
                false,
                true)
                .pipe(
                    map(response => adminDashboardRequestSuccess(response.filter, response.incidents, response.venueMarkers)),
                    catchError(error => notifyError(error, "adminDashboardRequestEpic.get", adminDashboardRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardRequestEpic", adminDashboardRequestFailure))
    );

const adminDashboardRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_REQUEST_SUCCESS),
        map((action: any) => adminDashboardIncidentsRequest())
);

const adminDashboardIncidentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_INCIDENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.adminDashboard.filter, 
                true,
                false)
                .pipe(
                    map(response => adminDashboardIncidentsRequestSuccess(response.incidents)),
                    catchError(error => notifyError(error, "adminDashboardIncidentsRequestEpic.get", adminDashboardIncidentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardIncidentsRequestEpic", adminDashboardIncidentsRequestFailure))
    );

//const adminDashboardFilterRequestTotalsSuccessEpic = (action$, state$) =>
//    action$.pipe(
//        ofType(ADMIN_DASHBOARD_FILTER_REQUEST_SUCCESS),
//        map((action: any) => adminDashboardTotalsRequest())
//    );

const adminDashboardTotalsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_TOTALS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.totals(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.adminDashboard.filter)
                .pipe(
                    map(response => adminDashboardTotalsRequestSuccess(response.filter, response.totalVenues, response.totalSecurityFirms, response.totalIncidents, response.totalUsers)),
                    catchError(error => notifyError(error, "adminDashboardTotalsRequestEpic.totals", adminDashboardTotalsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardTotalsRequestEpic", adminDashboardTotalsRequestFailure))
    );

const adminDashboardOverviewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_OVERVIEW_QUICK_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.overview(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                AusComplyDtos.ngtOverviewStatsMode.Quick)
                .pipe(
                    map(response => adminDashboardOverviewQuickRequestSuccess(response.data)),
                    catchError(error => notifyError(error, "adminDashboardOverviewRequestEpic.overview", adminDashboardOverviewQuickRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardOverviewRequestEpic", adminDashboardOverviewQuickRequestFailure))
    );

const adminDashboardIncidentTotalsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_INCIDENT_TOTALS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.incidentTotals(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminDashboardIncidentTotalsRequestSuccess(response.incidents, response.venueIncidents, response.securityFirmIncidents)),
                    catchError(error => notifyError(error, "adminDashboardIncidentTotalsRequestEpic.incidentTotals", adminDashboardIncidentTotalsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardIncidentTotalsRequestEpic", adminDashboardIncidentTotalsRequestFailure))
    );

const adminDashboardUserTotalsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(ADMIN_DASHBOARD_USER_TOTALS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            adminDashboardServiceApi.usertotals(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminDashboardUserTotalsRequestSuccess(response.venueUsers, response.securityFirmUsers)),
                    catchError(error => notifyError(error, "adminDashboardUserTotalsRequestEpic.usertotals", adminDashboardUserTotalsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDashboardUserTotalsRequestEpic", adminDashboardUserTotalsRequestFailure))
    );

export const adminDashboardEpics = combineEpics(
    adminDashboardResetOnUserChangeEpic,
    adminDashboardFilterRequestEpic,
    adminDashboardFilterRequestSuccessEpic,
    adminDashboardRequestEpic,
    adminDashboardSetFilterEpic,
    adminDashboardTotalsRequestEpic,
    //adminDashboardFilterRequestTotalsSuccessEpic,
    adminDashboardOverviewRequestEpic,
    adminDashboardIncidentTotalsRequestEpic,
    adminDashboardUserTotalsRequestEpic,
    adminDashboardRequestSuccessEpic,
    adminDashboardIncidentsRequestEpic
);