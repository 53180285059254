
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { securityFirmServiceApi } from '../services/securityFirmService';

import {
    REGISTER_SECURITY_FIRM_NEW_REQUEST,
    registerSecurityFirmNewSuccess,
    registerSecurityFirmNewFailure,
    REGISTER_SECURITY_FIRM_SAVE_REQUEST,
    registerSecurityFirmSaveRequestSuccess,
    REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS,
    registerSecurityFirmSaveRequestFailure,
    registerSecurityFirmReset
} from '../actions/registerSecurityFirm';
import {
    notifyError, notifyErrorMessage
} from './common';


const registerSecurityFirmNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_SECURITY_FIRM_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.createNew()
                .pipe(
                    map(response => registerSecurityFirmNewSuccess(
                        response.securityFirm,
                        response.managerContact,
                        response.primaryContact,
                        response.states,
                        response.securityLicenceClasses,
                        response.otherDocuments,
                        response.otherDocumentTypes,
                        response.complianceDocuments,
                        response.complianceDocumentTypes)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise a new Security Firm", registerSecurityFirmNewFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise a new Security Firm", registerSecurityFirmNewFailure))
    );

const registerSecurityFirmSaveNewEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_SECURITY_FIRM_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.saveNew(
                state.registerSecurityFirm.securityFirm,
                state.registerSecurityFirm.managerContact,
                state.registerSecurityFirm.primaryContact,
                state.registerSecurityFirm.otherDocumnets, 
                state.registerSecurityFirm.complianceDocuments)
                .pipe(
                    map(response => registerSecurityFirmSaveRequestSuccess(response.securityFirm)),
                    catchError(error => notifyErrorMessage(error, "Failed to initialise a new Security Firm", registerSecurityFirmSaveRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to initialise a new Security Firm", registerSecurityFirmSaveRequestFailure))
    );

const registerSecurityFirmSaveNewSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(REGISTER_SECURITY_FIRM_SAVE_REQUEST_SUCCESS),
        map(action => registerSecurityFirmReset())
    );


export const registerSecurityFirmEpics = combineEpics(
    registerSecurityFirmNewEpic,
    registerSecurityFirmSaveNewEpic,
    registerSecurityFirmSaveNewSuccessEpic
);