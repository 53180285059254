import React, { Component, forwardRef } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import TextControlGroup from '../controls/TextControlGroup';
import DefaultButton from '../controls/DefaultButton';
import PageLayout from '../layout/PageLayout';
import ErrorButton from '../controls/ErrorButton';

export interface IProfileDeleteProps {
    profile: AusComplyDtos.User,
    isLoading: boolean,
    isSaving: boolean,
    error: string,
    onLoad: Function;
    onRemove: Function;
    history: any;
    location: any;
}

interface IProfileDeleteState {
    reconfirmedEmail: string;
}

class ProfileDelete extends Component<IProfileDeleteProps, IProfileDeleteState> {
    constructor(props: IProfileDeleteProps) {
        super(props)
        this.state = {
            reconfirmedEmail: ""
        }
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onValueChanged(value: any) {
        this.setState({reconfirmedEmail: value})
    }

    componentDidMount() {
        this.props.onLoad();
    }
    
    onSave() {
        this.props.onRemove(this.state.reconfirmedEmail);
    }

    onCancel() {
        this.props.history.push('/profile');
    }

    render() {
        let self = this;

        return (
            <>
                <PageLayout
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={this.props.error}
                    notFullWidth={true}
                    headerText={"Delete My Account"}
                    footerLeftContent={
                        <DefaultButton text={"Cancel"} onClick={self.onCancel}></DefaultButton>
                    }
                    footerRightContent={
                        <ErrorButton 
                            onClick={() => self.onSave()}>
                                Confirm Account Deletion</ErrorButton>
                    }
                >
                    <form>
                        <p style={{textAlign: 'center', padding: '10px' }}>
                            Deleting your account will remove your address, email address, mobile number, emergency contact, uploaded documents, and roles. Your name and security license number or RSA number will be retained since they might be linked to the legislated retention of incidents. This action cannot be reversed. After clicking the button below, you wil be logged out of AusComply and will not be able to log back in.
                        </p>
                        <TextControlGroup
                            text={"Re-confirm your email address to delete"}
                            defaultValue={this.state.reconfirmedEmail}
                            labelAbove={true}
                            placeholder={this.props.profile.email}
                            readonly={this.props.isLoading || this.props.isSaving}
                            onBlur={(value) => this.onValueChanged(value)} />
                    </form>
                </PageLayout>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(ProfileDelete));