import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import SecurityFirmAssociations from '../components/securityFirm/securityFirmAssociations/SecurityFirmAssociations'
import { 
    securityFirmAssociationsRequest,
    securityFirmAssociationCreateRequest,
    securityFirmAssociationReset,
    securityFirmAssociationRemoveRequest,
    securityFirmSubAssociationsRequest,
    securityFirmSubAssociationCreateRequest,
    securityFirmSubAssociationReset,
    securityFirmSubAssociationRemoveRequest
 } from '../common/actions/securityFirmAssociations';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        venueSecurityFirmId: state.securityFirmAssociations.venueSecurityFirm.venueSecurityFirm.venueSecurityFirmId,
        paging: state.securityFirmAssociations.venueSecurityFirms.paging,
        isLoading: state.securityFirmAssociations.venueSecurityFirms.isLoading,
        isSaving: state.securityFirmAssociations.venueSecurityFirm.isSaving,
        venueSecurityFirmAssociations: state.securityFirmAssociations.venueSecurityFirms.venueSecurityFirms,

        securityFirmAssociationId: state.securityFirmAssociations.securityFirmAssociation.securityFirmAssociation.securityFirmAssociationId,
        pagingAssociations: state.securityFirmAssociations.securityFirmAssociations.paging,
        isLoadingAssociations: state.securityFirmAssociations.securityFirmAssociations.isLoading,
        isSavingAssociations: state.securityFirmAssociations.securityFirmAssociation.isSaving,
        securityFirmAssociations: state.securityFirmAssociations.securityFirmAssociations.securityFirmAssociations,

        canRemove: permissionsLogic.hasPermissionForState(state, "RemoveAssocSecurityFirmAndSecurityFirm"),
        canCreate: permissionsLogic.hasPermissionForState(state, "AddAssocSecurityFirmAndSecurityFirm")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId, securityFirmId, pageSize, page) => {
            dispatch(securityFirmAssociationsRequest(venueId, securityFirmId, page, pageSize))
        },
        onCreate: (venueId, parentSecurityFirmId) => {
            dispatch(securityFirmAssociationCreateRequest(venueId, parentSecurityFirmId))
        },
        onReset: () => {
            dispatch(securityFirmAssociationReset())
        },
        onRemove: (venueSecurityFirmId) => {
            dispatch(securityFirmAssociationRemoveRequest(venueSecurityFirmId))
        },
        onLoadAssociations: (securityFirmId, pageSize, page) => {
            dispatch(securityFirmSubAssociationsRequest(securityFirmId, page, pageSize))
        },
        onCreateAssociation: (securityFirmId) => {
            dispatch(securityFirmSubAssociationCreateRequest(securityFirmId))
        },
        onResetAssociation: () => {
            dispatch(securityFirmSubAssociationReset())
        },
        onRemoveAssociation: (securityFirmAssociationId, allAssociations) => {
            dispatch(securityFirmSubAssociationRemoveRequest(securityFirmAssociationId, allAssociations))
        },
    }
}

const SecurityFirmAssociationsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmAssociations))

export default SecurityFirmAssociationsContainer