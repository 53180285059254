import update from "immutability-helper";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import moment from "moment";
import { USER_RESET } from '../actions/userLogin';
import * as groupActions from "../actions/groups";

const initialState = {
    groups: {
        isLoading: false,
        filter: {
            search: "",
            paging: {
                pageSize: 0,
                page: 1
            },
            display: "all"
        },
        groups: [],
        userRequestAvailable: []
    },
    group: {
        isLoading: false,
        isSaving: false,
        isSavingUserRequest: false,
        group: {
            groupId: -1
        },
        userRoleStatuses: [],
        tabIndex: 0,
        timeZones: []
    },
    venues: {
        isLoading: false,
        isSaving: false,
        autoApproval: false,
        venues: [],
        error: ""
    },
    users: {
        isLoading: false,
        isSaving: false,
        autoApproval: false,
        userRoleTypes: [],
        userRoleTypeId: 0,
        isTemporary: false,
        expiryDate: {
            isEmpty: true
        },
        users: [],
        error: ""
    },
    dashboard: {
        isLoading: false,
        groupId: -1,
        filter: { dashboardId: -1, groupId: -1 },
        data: [],
        isLoadingIncidents: false,
        incidents: [],
        venueMarkers: [],
        options: {
            showVenueMarkers: true,
            showActiveVenues: true,
            showInactiveVenues: true,
            showPostcodeBoundaries: true,
            showHeatmap: false,
            postcodes: []
        },
        heatmapCount: 0,
        filteredVenueMarkers: [],
        filteredIncidents: [],
        heatmap: [],
    },
    checklistTemplates: {
        isCloning: false,
        checklistTemplateId: 0
    }
};

function loadingGroups(state, isLoading) {
    let newState = update(state, {
        groups: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFilter(state, filter) {
    let newState = update(state, {
        groups: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setGroups(state, groups, filter) {
    let newState = update(state, {
        groups: {
            groups: { $set: groups },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setTimeZones(state, timeZones) {
    let newState = update(state, {
        group: {
            timeZones: { $set: timeZones }
        }
    });
    return newState;
}

function setUserRequestAvailable(state, groups) {
    let newState = update(state, {
        groups: {
            userRequestAvailable: { $set: groups }
        }
    });
    return newState;
}

function loadingGroup(state, isLoading) {
    let newState = update(state, {
        group: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function resetGroup(state) {
    let newState = update(state, {
        group: {
            group: { $set: { groupId: -1 } },
        }
    });
    return newState;
}

function setGroup(state, group) {
    let newState = update(state, {
        group: {
            group: { $set: group },
        },
        users: {
            users: { $set: [] },
            isLoading: { $set: false },
            isSaving: { $set: false }
        },
        venues: {
            venues: { $set: [] },
            isLoading: { $set: false },
            isSaving: { $set: false }
        }
    });
    return newState;
}

function setUserRoleStatuses(state, userRoleStatuses) {
    let newState = update(state, {
        group: {
            userRoleStatuses: { $set: userRoleStatuses }
        }
    });
    return newState;
}

function setTabIndex(state, tabIndex) {
    let newState = update(state, {
        group: {
            tabIndex: { $set: tabIndex },
        }
    });
    return newState;
}

function savingGroup(state, isSaving) {
    let newState = update(state, {
        group: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function savingUserRequest(state, isSavingUserRequest) {
    let newState = update(state, {
        group: {
            isSavingUserRequest: { $set: isSavingUserRequest }
        }
    });
    return newState;

}

function loadingGroupVenues(state, isLoading) {
    let newState = update(state, {
        venues: {
            isLoading: { $set: isLoading },
            venues: { $set: [] },
            error: { $set: "" }
        }
    });
    return newState;
}

function savingGroupVenues(state, isSaving) {
    let newState = update(state, {
        venues: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setGroupVenues(state, venues) {
    let newState = update(state, {
        venues: {
            venues: { $set: venues },
            error: { $set: "" }
        }
    });
    return newState;
}

function setGroupVenuesOnSuccess(state, venues: AusComplyDtos.VenueSelection[], group) {
    let error = "";
    venues.forEach(v => { if (v.error && v.error != "") { error = v.error; } });
    venues = error == "" ? [] : venues;
    let newState = update(state, {
        venues: {
            venues: { $set: venues },
            error: { $set: error }
        },
        group: {
            group: { $set: group }
        }
    });
    return newState;
}

function setGroupVenueAutoApproval(state, autoApproval) {
    let newState = update(state, {
        venues: {
            autoApproval: { $set: autoApproval }
        }
    });
    return newState;
}

function loadingGroupUsers(state, isLoading) {
    let newState = update(state, {
        users: {
            isLoading: { $set: isLoading },
            users: { $set: [] },
            error: { $set: "" },
            isTemporary: { $set: false },
            expiryDate: { $set: { isEmpty: true } },
            userRoleTypeId: { $set: 0 }
        }
    });
    return newState;
}

function savingGroupUsers(state, isSaving) {
    let newState = update(state, {
        users: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setGroupUsers(state, users) {
    let newState = update(state, {
        users: {
            users: { $set: users },
            error: { $set: "" }
        }
    });
    return newState;
}

function setGroupUserRoleTypes(state, userRoleTypes) {
    let newState = update(state, {
        users: {
            userRoleTypes: { $set: userRoleTypes }
        }
    });
    return newState;
}

function setGroupUsersOnSuccess(state, users: AusComplyDtos.UserSelection[], group) {
    let error = "";
    users.forEach(v => { if (v.error && v.error != "") { error = v.error; } });
    users = error == "" ? [] : users;
    let newState = update(state, {
        users: {
            users: { $set: users },
            error: { $set: error }
        },
        group: {
            group: { $set: group }
        }
    });
    return newState;
}

function setGroupUserAutoApproval(state, autoApproval) {
    let newState = update(state, {
        users: {
            autoApproval: { $set: autoApproval }
        }
    });
    return newState;
}

function setGroupUserRoleType(state, userRoleTypeId) {
    let newState = update(state, {
        users: {
            userRoleTypeId: { $set: userRoleTypeId }
        }
    });
    return newState;
}

function setGroupTemporary(state, isTemporary, expiryDate) {
    if (!isTemporary) {
        expiryDate = { isEmpty: true };
    }
    let newState = update(state, {
        users: {
            isTemporary: { $set: isTemporary },
            expiryDate: { $set: expiryDate }
        }
    });
    return newState;
}

function loadingDashboard(state, isLoading) {
    let newState = update(state, {
        dashboard: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function updateDashboardFilter(state, filter) {
    let newState = update(state, {
        dashboard: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setDashboardGroupId(state, groupId) {
    let newState = update(state, {
        dashboard: {
            groupId: { $set: groupId }
        }
    });
    return newState;
}

function setDashboardData(state, data) {
    let newState = update(state, {
        dashboard: {
            data: { $set: data }
        }
    });
    return newState;
}

function resetDashboard(state) {
    let newState = update(state, {
        dashboard: {
            $set: {
                isLoading: false,
                groupId: -1,
                filter: { dashboardId: -1, groupId: -1 },
                data: [],
                isLoadingIncidents: false,
                incidents: [],
                venueMarkers: [],
                options: {
                    showVenueMarkers: true,
                    showActiveVenues: true,
                    showInactiveVenues: true,
                    showPostcodeBoundaries: true,
                    showHeatmap: false,
                    postcodes: []
                },
                heatmapCount: 0,
                filteredVenueMarkers: [],
                filteredIncidents: [],
                heatmap: []
            }
        }
    });
    return newState;
}

function loadingIncidents(state, isLoadingIncidents) {
    let newState = update(state, {
        dashboard: {
            isLoadingIncidents: { $set: isLoadingIncidents }
        }
    });
    return newState;
}

function setIncidentsData(state, incidents, venueMarkers) {
    let newState = update(state, {
        dashboard: {
            incidents: { $set: incidents },
            venueMarkers: { $set: venueMarkers }
        }
    });
    return newState;
}

function setOptions(state, options) {
    let newState = update(state, {
        dashboard: {
            options: { $set: options }
        }
    });
    return newState;
}

function processMapData(state) {
    let filteredVenueMarkers = [...state.dashboard.venueMarkers];
    if (!state.dashboard.options.showVenueMarkers) {
        filteredVenueMarkers = [];
    }

    // filter venues on postcodes
    let venueIds = state.dashboard.venueMarkers.map((v) => v.venueId);
    let filteredIncidents: AusComplyDtos.IncidentHeatmap[] = [...state.dashboard.incidents];
    if (state.dashboard.options.postcodes.length > 0) {
        filteredVenueMarkers = filteredVenueMarkers.filter(v => state.dashboard.options.postcodes.indexOf(v.postcode) > -1);
        venueIds = [...state.dashboard.venueMarkers].filter(v => state.dashboard.options.postcodes.indexOf(v.postcode) > -1).map((v) => v.venueId);
    }

    // filter incidents
    filteredIncidents = filteredIncidents.filter(i => venueIds.indexOf(i.venueId) > -1);

    let heatmap: any[] = [];
    if (state.dashboard.options.showHeatmap) {
        filteredIncidents.forEach(i => {
            if (i.latitude && i.longitude) {
                heatmap.push(new google.maps.LatLng(i.latitude, i.longitude));
            }
        });
    }

    let newState = update(state, {
        dashboard: {
            filteredVenueMarkers: { $set: filteredVenueMarkers },
            heatmap: { $set: heatmap },
            filteredIncidents: { $set: filteredIncidents }
        }
    });
    return newState;
}

function cloningChecklistTemplate(state, isCloning, checklistTemplateId) {
    let newState = update(state, {
        checklistTemplates: {
            isCloning: { $set: isCloning },
            checklistTemplateId: { $set: checklistTemplateId }
        }
    });
    return newState;
}

export default function groups(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };
        case groupActions.GROUPS_SET_FILTER:
            return setFilter(state, action.filter);
        case groupActions.GROUPS_REQUEST:
            return setGroups(loadingGroups(state, true), [], state.groups.filter);
        case groupActions.GROUPS_REQUEST_SUCCESS:
            return setGroups(loadingGroups(state, false), action.groups, action.filter);
        case groupActions.GROUPS_REQUEST_FAILURE:
            return loadingGroups(state, false);
        case groupActions.GROUP_REQUEST:
            return loadingGroup(state, true);
        case groupActions.GROUP_REQUEST_SUCCESS:
            return setGroup(setTimeZones(setGroupUserRoleTypes(setUserRoleStatuses(setGroupUserAutoApproval(setGroupVenueAutoApproval(loadingGroup(state, false), false), false), action.userRoleStatuses), action.userRoleTypes), action.timeZones), action.group);
        case groupActions.GROUP_REQUEST_FAILURE:
            return loadingGroup(resetGroup(state), false);
        case groupActions.GROUP_CREATE_REQUEST:
            return loadingGroup(state, true);
        case groupActions.GROUP_CREATE_REQUEST_SUCCESS:
            return setGroup(setTimeZones(loadingGroup(state, false), action.timeZones), action.group);
        case groupActions.GROUP_CREATE_REQUEST_FAILURE:
            return loadingGroup(resetGroup(state), false);
        case groupActions.GROUP_UPSERT_REQUEST:
        case groupActions.GROUP_USER_UPDATE_REQUEST:
        case groupActions.GROUP_VENUE_UPDATE_REQUEST:
            return savingGroup(state, true);
        case groupActions.GROUP_UPSERT_REQUEST_SUCCESS:
        case groupActions.GROUP_USER_UPDATE_REQUEST_SUCCESS:
        case groupActions.GROUP_VENUE_UPDATE_REQUEST_SUCCESS:
            return setGroup(savingGroup(state, false), action.group);
        case groupActions.GROUP_UPSERT_REQUEST_FAILURE:
        case groupActions.GROUP_USER_UPDATE_REQUEST_FAILURE:
        case groupActions.GROUP_VENUE_UPDATE_REQUEST_FAILURE:
            return savingGroup(state, false);
        case groupActions.GROUP_SET:
            return setGroup(state, action.group);
        case groupActions.GROUP_VENUES_AUTOAPPROVAL_SET:
            return setGroupVenueAutoApproval(state, action.autoApproval);
        case groupActions.GROUPS_RESET:
            return resetGroup(state);
        case groupActions.GROUP_VENUES_AVAILABLE_REQUEST:
            return loadingGroupVenues(state, true);
        case groupActions.GROUP_VENUES_AVAILABLE_REQUEST_SUCCESS:
            return setGroupVenues(loadingGroupVenues(state, false), action.venues);
        case groupActions.GROUP_VENUES_AVAILABLE_REQUEST_FAILURE:
            return loadingGroupVenues(setGroupVenues(state, []), false);
        case groupActions.GROUP_VENUES_SET:
            return setGroupVenues(state, action.venues);
        case groupActions.GROUP_VENUES_INSERT_REQUEST:
            return savingGroupVenues(state, true);
        case groupActions.GROUP_VENUES_INSERT_REQUEST_SUCCESS:
            return setGroupVenuesOnSuccess(savingGroupVenues(state, false), action.venues, action.group);
        case groupActions.GROUP_VENUES_INSERT_REQUEST_FAILURE:
            return savingGroupVenues(setGroupVenues(state, []), false);


        case groupActions.GROUP_USERS_AVAILABLE_REQUEST:
            return loadingGroupUsers(state, true);
        case groupActions.GROUP_USERS_AVAILABLE_REQUEST_SUCCESS:
            return setGroupUserRoleTypes(setGroupUsers(loadingGroupUsers(state, false), action.users), action.userRoleTypes);
        case groupActions.GROUP_USERS_AVAILABLE_REQUEST_FAILURE:
            return loadingGroupUsers(setGroupUsers(state, []), false);
        case groupActions.GROUP_USERS_SET:
            return setGroupUsers(state, action.users);
        case groupActions.GROUP_USERS_INSERT_REQUEST:
            return savingGroupUsers(state, true);
        case groupActions.GROUP_USERS_INSERT_REQUEST_SUCCESS:
            return setGroupUsersOnSuccess(savingGroupUsers(state, false), action.users, action.group);
        case groupActions.GROUP_USERS_INSERT_REQUEST_FAILURE:
            return savingGroupUsers(setGroupUsers(state, []), false);
        case groupActions.GROUP_USERS_AUTOAPPROVAL_SET:
            return setGroupUserAutoApproval(state, action.autoApproval);
        case groupActions.GROUP_USERS_USERROLETYPE_SET:
            return setGroupUserRoleType(state, action.userRoleTypeId);
        case groupActions.GROUP_USERS_TEMPORARY_SET:
            return setGroupTemporary(state, action.isTemporary, action.expiryDate);
        case groupActions.GROUP_SET_TAB:
            return setTabIndex(state, action.tabIndex);
        case groupActions.GROUP_DASHBOARD_RESET:
            return resetDashboard(state);
        case groupActions.GROUP_DASHBOARD_SET_FILTER:
            return updateDashboardFilter(state, action.filter);
        case groupActions.GROUP_DASHBOARD_FILTER_REQUEST:
            return loadingDashboard(setDashboardGroupId(state, action.groupId), true);
        case groupActions.GROUP_DASHBOARD_FILTER_REQUEST_SUCCESS:
            return loadingDashboard(updateDashboardFilter(state, action.filter), false);
        case groupActions.GROUP_DASHBOARD_FILTER_REQUEST_FAILURE:
            return loadingDashboard(state, false);
        case groupActions.GROUP_DASHBOARD_REQUEST:
            return setDashboardData(loadingDashboard(state, true), []);
        case groupActions.GROUP_DASHBOARD_REQUEST_SUCCESS:
            return setDashboardData(updateDashboardFilter(loadingDashboard(state, false), action.filter), action.data);
        case groupActions.GROUP_DASHBOARD_REQUEST_FAILURE:
            return loadingDashboard(state, false);


        case groupActions.GROUPS_SELECTION_REQUEST:
            return setUserRequestAvailable(loadingGroups(state, true), []);
        case groupActions.GROUPS_SELECTION_REQUEST_SUCCESS:
            return setUserRequestAvailable(loadingGroups(state, false), action.groups);
        case groupActions.GROUPS_SELECTION_REQUEST_FAILURE:
            return loadingGroups(state, false);

        case groupActions.GROUP_ACCESS_REQUEST:
            return savingUserRequest(state, true);
        case groupActions.GROUP_ACCESS_REQUEST_SUCCESS:
            return savingUserRequest(state, false);
        case groupActions.GROUP_ACCESS_REQUEST_FAILURE:
            return savingUserRequest(state, false);
        case groupActions.GROUP_ACCESS_REQUEST_REJECT:
            return savingUserRequest(state, true);
        case groupActions.GROUP_ACCESS_REQUEST_REJECT_SUCCESS:
            return setGroup(savingUserRequest(state, false), action.group);
        case groupActions.GROUP_ACCESS_REQUEST_REJECT_FAILURE:
            return savingUserRequest(state, false);
        case groupActions.GROUP_DASHBOARD_SET_OPTIONS:
            return processMapData(setOptions(state, action.options));
        case groupActions.GROUP_DASHBOARD_INCIDENTS_REQUEST:
            return loadingIncidents(state, true);
        case groupActions.GROUP_DASHBOARD_INCIDENTS_REQUEST_SUCCESS:
            return processMapData(
                setIncidentsData(loadingIncidents(state, false), action.incidents, action.venueMarkers)
            );
        case groupActions.GROUP_DASHBOARD_INCIDENTS_REQUEST_FAILURE:
            return loadingIncidents(state, false);

        case groupActions.GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST:
            return cloningChecklistTemplate(state, true, 0);
        case groupActions.GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_SUCCESS:
            return cloningChecklistTemplate(state, false, action.checklistTemplateId);
        case groupActions.GROUP_CHECKLISTTEMPLATE_CLONE_REQUEST_FAILURE:
            return cloningChecklistTemplate(state, false, 0);
    }
    return state;
}