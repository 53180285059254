import React, { Component } from 'react';
import VenueSecurityFirmSelectionContainer from '../../containers/VenueSecurityFirmSelectionContainer'
import VenueSecurityFirmSummaryContainer from '../../containers/VenueSecurityFirmSummaryContainer'
import Loading from '../common/Loading';
import Error from '../common/Error'
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import MobileVerifyCodeContainer from '../../containers/MobileVerifyCodeContainer';
import VenueSecurityCodeContainer from '../../containers/VenueSecurityCodeContainer';
import SignOnOffSummaryContainer from '../../containers/SignOnOffSummaryContainer';
import SnapshotSummaryContainer from '../../containers/SnapshotSummaryContainer';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import FullPageDialog from '../layout/FullPageDialog';
import Information from '../common/Information';
import WelcomeContainer from '../../containers/WelcomeContainer';
import WelcomeUserComplianceDocumentsContainer from '../../containers/WelcomeUserComplianceDocumentsContainer';
import TransparentButton from '../controls/TransparentButton';
import HomeStatistics from '../home/HomeStatistics';
import View from '../common/View';
import PageLayout from '../layout/PageLayout';
import CommsLogsSummaryContainer from '../../containers/CommsLogsSummaryContainer';

export interface IHomeProps {
    onRefreshLocation: Function;
    venueId: number;
    securityFirmId: number;
    isVenueSelected: boolean;
    isSecurityFirmSelected: boolean;
    isAndHasNothingSelected: boolean;
    isUserLoading: boolean;
    requiresSecurityCode: boolean;
    hasOpenReportingPeriod: boolean;
    onRefreshData: Function;
    onResetDataQuiet: Function;
    onRefresh: Function;
    isStatisticsLoading: boolean;
    statistics: AusComplyDtos.HomeStatistics;
    statisticsError: string;
    onResetData: Function;
    canViewIncidents: boolean;
    canViewCommsLogs: boolean;
    canSignOnToRegister: boolean;
    history: any;
    location: any;
    onResetFilter: Function;
    onClearVenue: Function;
    onCancelChangeVenue: Function;
    showVenueMessage: boolean;
    onDismissVenueMessage: Function;
    pendingInvalidSLEDAccess: boolean;
    hasUserRoles: boolean;
    verified: boolean;
    onResetChecklistFilter: Function;
    onRegisterUserPostSave: Function;
    maintenanceMessage: string;
    postSaveAction: string;
    showGamblingIncidentRegister: boolean;
    theme: any;
    classes: any;
}


class Home extends Component<IHomeProps, any> {
    constructor(props: IHomeProps) {
        super(props)
        this.onRefresh = this.onRefresh.bind(this);
        this.onIncidents = this.onIncidents.bind(this);
        this.onCancelVenueSelection = this.onCancelVenueSelection.bind(this);
    }

    componentDidMount() {
        if (this.props.postSaveAction == "venue") {
            this.props.history.push('/register/venue');
            this.props.onRegisterUserPostSave("");
        }
        if (this.props.postSaveAction == "securityfirm") {
            this.props.history.push('/register/securityFirm');
            this.props.onRegisterUserPostSave("");
        }
        // on entered, so this page was navigated to...
        if (!this.props.requiresSecurityCode && this.props.isVenueSelected) {
            // request the data
            this.props.onResetDataQuiet();
        }
        this.props.onRefreshLocation();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // on changed...
        if (!prevProps.isAndHasNothingSelected && this.props.isAndHasNothingSelected) {
            if (prevProps.securityFirmId != this.props.securityFirmId || prevProps.venueId != this.props.venueId) {
                // request the data
                this.props.onRefresh();
            }
        } else if ((prevProps.requiresSecurityCode && !this.props.requiresSecurityCode) ||
            (!prevProps.isVenueSelected && this.props.isVenueSelected && !this.props.requiresSecurityCode) ||
            (!prevProps.isAndHasNothingSelected && this.props.isAndHasNothingSelected)) {
            // request the data
            this.props.onResetDataQuiet();
        }
    }

    onRefresh() {
        this.props.onRefresh();
    }

    onCancelVenueSelection() {
        this.props.onClearVenue();
        this.props.onCancelChangeVenue();
    }

    onIncidents() {
        // reset filters to be the current reporting period and navigate
        this.props.onResetFilter();
        this.props.history.push('/incidents');
    }

    render() {
        let display;
        let refreshError;
        if (this.props.statisticsError && this.props.statisticsError !== "") {
            refreshError = (
                <Grid container spacing={1} style={{ marginBottom: '20px' }}>
                    <Grid item xs={12} >
                        <Error message={"The statistics failed to load, click refresh to try again"} />
                    </Grid>
                </Grid>
            );
        }

        if (this.props.isUserLoading) {
            display = (
                <Loading />
            );
        }
        else if (!this.props.isVenueSelected && (!this.props.hasUserRoles || !this.props.verified)) {
            return (
                <>
                    {refreshError}
                    <WelcomeContainer />
                    <MobileVerifyCodeContainer />
                    <WelcomeUserComplianceDocumentsContainer />
                </>
            );
        }
        else {
            let home;
            if (this.props.isVenueSelected && this.props.venueId && this.props.venueId > 0) {
                if (this.props.pendingInvalidSLEDAccess) {
                    home = (
                        <Information message={"The SLED check on your security licence failed, or returned an expired licence. Access to the venue has been requested"} />
                    );
                } else if (this.props.requiresSecurityCode && !this.props.hasOpenReportingPeriod) {
                    // TO DO: Add here the ability to start a new reporting period
                    home = (
                        <div>
                            <Error message={"There is no open reporting period for the selected venue."} />
                        </div>
                    );
                } else if (this.props.requiresSecurityCode) {
                    home = <VenueSecurityCodeContainer />
                } else {
                    if (this.props.isStatisticsLoading) {
                        home = <Loading />
                    } else {
                        let signOnOff;
                        if (this.props.canSignOnToRegister) {
                            signOnOff = (
                                <View style={{ marginBottom: '10px' }}>
                                    <SignOnOffSummaryContainer />
                                </View>
                            );
                        }
                        home = (
                            <View>
                                {signOnOff}
                                <View style={{ marginBottom: '10px' }}>
                                    <SnapshotSummaryContainer />
                                </View>
                                <View style={{ marginBottom: '10px' }}>
                                    <HomeStatistics
                                        statistics={this.props.statistics}
                                        canViewIncidents={this.props.canViewIncidents}
                                        canViewCommsLogs={this.props.canViewCommsLogs}
                                        isStatisticsLoading={this.props.isStatisticsLoading}
                                        onResetIncidentFilter={this.props.onResetFilter}
                                        onResetChecklistFilter={this.props.onResetChecklistFilter}
                                        showGamblingIncidentRegister={this.props.showGamblingIncidentRegister}
                                        history={this.props.history} />
                                </View>
                                <View style={{ marginBottom: '10px' }}>
                                    <CommsLogsSummaryContainer history={this.props.history} />
                                </View>
                            </View>
                        );
                    }
                }
            }
            display = (
                <div>
                    {refreshError}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <VenueSecurityFirmSummaryContainer />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <MobileVerifyCodeContainer />
                            {home}
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return <PageLayout
            headerText={"Digital Compliance Solutions"}
            warningMessage={this.props.maintenanceMessage}
            footerLeftContent={<TransparentButton text={"Refresh"} onClick={this.onRefresh} />}
            loading={this.props.isUserLoading}
        >
            {display}
            <FullPageDialog title="Instructions for licensed venues" open={!this.props.isUserLoading && this.props.showVenueMessage && this.props.isVenueSelected}
                onDismissed={() => this.props.onDismissVenueMessage()}>
                <p style={{ ...this.props.theme.custom.label, textAlign: 'center' }}>The law requires all licensed venues in the Kings Cross and CBD precincts to keep an incident register at all times.</p>
            </FullPageDialog>
            <FullPageDialog
                title="Location"
                notFullWidth={true}
                open={!this.props.isUserLoading && !this.props.isVenueSelected && !this.props.isSecurityFirmSelected && !this.props.isAndHasNothingSelected}
                onDismissed={this.onCancelVenueSelection}
                footerRightContent={<TransparentButton text={"Refresh"} onClick={() => this.props.onRefreshLocation()} />}>
                {!this.props.isUserLoading && !this.props.isVenueSelected && (
                    <VenueSecurityFirmSelectionContainer />
                )}
            </FullPageDialog>
        </PageLayout>;
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(Home))