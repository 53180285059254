
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { RegexConstants } from '../constants/regex';
import { isLatitude, isLongitude } from '../constants/validators';
import { KeyName } from '../../common/dto/common';

export const venueLogic = {
    validateRegisterVenue(venue: AusComplyDtos.Venue,
        venueContact: AusComplyDtos.VenueContact,
        licenseContact: AusComplyDtos.VenueContact) {
        var errors = {};
        var count = 0;

        if (venue) {

            if (!venue.name || venue.name === "") {
                errors["name"] = "Required";
                count++;
            }

            if (!venue.phone || venue.phone === "") {
                errors["phone"] = "Required";
                count++;
            } else {
                if (!RegexConstants.PHONE.test(venue.phone)) {
                    errors["phone"] = RegexConstants.PHONE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.address || venue.address === "") {
                errors["address"] = "Required";
                count++;
            }

            if (!venue.suburb || venue.suburb === "") {
                errors["suburb"] = "Required";
                count++;
            }

            if (!venue.stateId || venue.stateId == 0) {
                errors["stateId"] = "Required";
                count++;
            }

            if (!venue.postcode || venue.postcode === "") {
                errors["postcode"] = "Required";
                count++;
            } else {
                if (!RegexConstants.POST_CODE.test(venue.postcode)) {
                    errors["postcode"] = RegexConstants.POST_CODE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.latitude) {
                errors["latitude"] = "Required";
                count++;
            } else {
                if (!isLatitude(venue.latitude)) {
                    errors["latitude"] = "Not a valid format";
                    count++;
                }
            }
            if (!venue.longitude) {
                errors["longitude"] = "Required";
                count++;
            } else {
                if (!isLongitude(venue.longitude)) {
                    errors["longitude"] = "Not a valid format";
                    count++;
                }
            }

            if (venueContact && licenseContact && this.registerVenueShowPrimaryContactDetails(venueContact, licenseContact)) {
                if (!venueContact.firstname || venueContact.firstname === "") {
                    errors["venueContact.firstname"] = "Required";
                    count++;
                }

                if (!venueContact.lastname || venueContact.lastname === "") {
                    errors["venueContact.lastname"] = "Required";
                    count++;
                }

                if (!venueContact.phone || venueContact.phone === "") {
                    errors["venueContact.phone"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.PHONE.test(venueContact.phone)) {
                        errors["venueContact.phone"] = RegexConstants.PHONE_DESCRIPTION;
                        count++;
                    }
                }

                if (!venueContact.email || venueContact.email === "") {
                    errors["venueContact.email"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.EMAIL.test(venueContact.email)) {
                        errors["venueContact.email"] = RegexConstants.EMAIL_DESCRIPTION;
                        count++;
                    }
                }
            }
            if (licenseContact && venueContact && this.registerVenueShowLicenseeContactDetails(venueContact, licenseContact)) {
                if (!licenseContact.firstname || licenseContact.firstname === "") {
                    errors["licenseContact.firstname"] = "Required";
                    count++;
                }

                if (!licenseContact.lastname || licenseContact.lastname === "") {
                    errors["licenseContact.lastname"] = "Required";
                    count++;
                }

                if (!licenseContact.phone || licenseContact.phone === "") {
                    errors["licenseContact.phone"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.PHONE.test(licenseContact.phone)) {
                        errors["licenseContact.phone"] = RegexConstants.PHONE_DESCRIPTION;
                        count++;
                    }
                }

                if (!licenseContact.email || licenseContact.email === "") {
                    errors["licenseContact.email"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.EMAIL.test(licenseContact.email)) {
                        errors["licenseContact.email"] = RegexConstants.EMAIL_DESCRIPTION;
                        count++;
                    }
                }
            }

            if (!venue.soleTrader) {
                if (!venue.abn || venue.abn === "") {
                    errors["abn"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.ABN.test(venue.abn)) {
                        errors["abn"] = RegexConstants.ABN_DESCRIPTION;
                        count++;
                    }
                }
            }

            if (!venue.industryCategoryId || venue.industryCategoryId == 0) {
                errors["industryCategoryId"] = "Required";
                count++;
            }

            if (!venue.liquorLicenceNumber || venue.liquorLicenceNumber === "") {
                errors["liquorLicenceNumber"] = "Required";
                count++;
            } else {
                if (!RegexConstants.LIQUOR_LICENCE.test(venue.liquorLicenceNumber)) {
                    errors["liquorLicenceNumber"] = RegexConstants.LIQUOR_LICENCE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.liquorLicenceDate || venue.liquorLicenceDate.isEmpty) {
                errors["liquorLicenceDate"] = "Required";
                count++;
            }

            if (!venue.venueLicenceTypeId || venue.venueLicenceTypeId == 0) {
                errors["venueLicenceTypeId"] = "Required";
                count++;
            }

            if (!venue.patronCapacity) {
                errors["patronCapacity"] = "Required";
                count++;
            } else {
                if (venue.patronCapacity <= 0) {
                    errors["patronCapacity"] = "Must be greater than 0";
                    count++;
                }
            }

            if (!venue.venuePrecinctId || venue.venuePrecinctId == 0) {
                errors["venuePrecinctId"] = "Required";
                count++;
            }

            if (!venue.venueTradingHoursId || venue.venueTradingHoursId == 0) {
                errors["venueTradingHoursId"] = "Required";
                count++;
            }

        }
        errors["count"] = count;
        return errors;
    },
    validateVenue(venue: AusComplyDtos.Venue) {
        var errors = {};
        var count = 0;

        if (venue) {

            if (!venue.name || venue.name === "") {
                errors["name"] = "Required";
                count++;
            }

            if (!venue.phone || venue.phone === "") {
                errors["phone"] = "Required";
                count++;
            } else {
                if (!RegexConstants.PHONE.test(venue.phone)) {
                    errors["phone"] = RegexConstants.PHONE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.address || venue.address === "") {
                errors["address"] = "Required";
                count++;
            }

            if (!venue.suburb || venue.suburb === "") {
                errors["suburb"] = "Required";
                count++;
            }

            if (!venue.stateId || venue.stateId == 0) {
                errors["stateId"] = "Required";
                count++;
            }

            if (!venue.postcode || venue.postcode === "") {
                errors["postcode"] = "Required";
                count++;
            } else {
                if (!RegexConstants.POST_CODE.test(venue.postcode)) {
                    errors["postcode"] = RegexConstants.POST_CODE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.latitude) {
                errors["latitude"] = "Required";
                count++;
            } else {
                if (!isLatitude(venue.latitude)) {
                    errors["latitude"] = "Not a valid format";
                    count++;
                }
            }
            if (!venue.longitude) {
                errors["longitude"] = "Required";
                count++;
            } else {
                if (!isLongitude(venue.longitude)) {
                    errors["longitude"] = "Not a valid format";
                    count++;
                }
            }

            if (!venue.soleTrader) {
                if (!venue.abn || venue.abn === "") {
                    errors["abn"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.ABN.test(venue.abn)) {
                        errors["abn"] = RegexConstants.ABN_DESCRIPTION;
                        count++;
                    }
                }
            }

            if (!venue.industryCategoryId || venue.industryCategoryId == 0) {
                errors["industryCategoryId"] = "Required";
                count++;
            }

            if (!venue.liquorLicenceNumber || venue.liquorLicenceNumber === "") {
                errors["liquorLicenceNumber"] = "Required";
                count++;
            } else {
                if (!RegexConstants.LIQUOR_LICENCE.test(venue.liquorLicenceNumber)) {
                    errors["liquorLicenceNumber"] = RegexConstants.LIQUOR_LICENCE_DESCRIPTION;
                    count++;
                }
            }

            if (!venue.liquorLicenceDate || venue.liquorLicenceDate.isEmpty) {
                errors["liquorLicenceDate"] = "Required";
                count++;
            }

            if (!venue.venueLicenceTypeId || venue.venueLicenceTypeId == 0) {
                errors["venueLicenceTypeId"] = "Required";
                count++;
            }

            if (!venue.patronCapacity) {
                errors["patronCapacity"] = "Required";
                count++;
            } else {
                if (venue.patronCapacity <= 0) {
                    errors["patronCapacity"] = "Must be greater than 0";
                    count++;
                }
            }

            if (!venue.venuePrecinctId || venue.venuePrecinctId == 0) {
                errors["venuePrecinctId"] = "Required";
                count++;
            }

            if (!venue.venueTradingHoursId || venue.venueTradingHoursId == 0) {
                errors["venueTradingHoursId"] = "Required";
                count++;
            }

        }
        errors["count"] = count;
        return errors;
    },
    registerVenueShowLicenseeContactDetails(
        venueContact: AusComplyDtos.VenueContact,
        licenseContact: AusComplyDtos.VenueContact) {
        return !licenseContact.obsolete;
    },
    registerVenueShowLicenseeIsPrimaryContactDetails(
        venueContact: AusComplyDtos.VenueContact,
        licenseContact: AusComplyDtos.VenueContact) {
        return !licenseContact.obsolete && !venueContact.obsolete;
    },
    registerVenueShowPrimaryContactDetails(
        venueContact: AusComplyDtos.VenueContact,
        licenseContact: AusComplyDtos.VenueContact) {
        if (!venueContact.obsolete && licenseContact.obsolete) {
            return true;
        } else if (!licenseContact.obsolete) {
            if (!licenseContact.primaryContact && !venueContact.obsolete) {
                return true;
            }
        }
        return false;
    },
    getAdminCommands(venue: AusComplyDtos.Venue) {
        var commands: KeyName[] = [];
            commands.push({ key: "edit", name: "Edit" });
            if (!venue.registrationComplete && !venue.obsolete) {
                commands.push({ key: "dismiss", name: "Dismiss Registration" });
                //commands.push({ key: "delete", name: "Delete" });
            } else if (!venue.obsolete) {
                commands.push({ key: "remove", name: "Remove" });
            }
        return commands;
    }
}