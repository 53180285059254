import React from "react";

const MiddleButtonIcon = ({
    name = "",
    style = {},
    fill = "#42C23B",
    fillPlus = "#27282D",
    viewBox = "",
    width = "100%",
    className = "",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 190 95"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
        	<path fill={fill} d="M0,93.9c0,0.37,0,0.74,0.01,1.1h189.99c0-0.37,0.01-0.74,0.01-1.1C190,42.04,147.47,0,95,0C42.53,0,0,42.04,0,93.9"/>
	        <path fill={fillPlus} d="M119.33,49.26H96.75v22.01h-3.62V49.26H70.67v-3.4h22.45V23.73h3.62v22.13h22.57V49.26z"/>
        </svg>
    );

export default MiddleButtonIcon;
