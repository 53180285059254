import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SubTitle from '../../common/SubTitle';
import NotificationTrigger from '../../notifications/NotificationTrigger';
import Spacer from '../../common/Spacer';
import FullPageDialog from '../../layout/FullPageDialog';

export interface IIncidentCategoryTypeProps {
    incidentCategoryTypeId: number;
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    canEditNotifications: boolean;
    isSaving: boolean;
    isLoading: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
    onClose: Function;
}

interface IIncidentCategoryTypeState {
    industryCategories: MulitSelectOption[];
    incidentTypes: MulitSelectOption[];
    userRoleTypes: MulitSelectOption[];
    //incidentCategoryTypeNotification: AusComplyDtos.IncidentCategoryTypeNotification;
}

export default class IncidentCategoryType extends Component<IIncidentCategoryTypeProps, IIncidentCategoryTypeState> {
    constructor(props: IIncidentCategoryTypeProps) {
        super(props)
        this.state = {
            industryCategories: [],
            incidentTypes: [],
            userRoleTypes: [],
            //incidentCategoryTypeNotification: new AusComplyDtos.IncidentCategoryTypeNotification()
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.onIncidentTypesChanged = this.onIncidentTypesChanged.bind(this);
        //this.onUserRoleTypesChanged = this.onUserRoleTypesChanged.bind(this);
        //this.setUserRoleTypes = this.setUserRoleTypes.bind(this);
        //this.onIncidentCategoryTypeNotificationChanged = this.onIncidentCategoryTypeNotificationChanged.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            let industryCategories: MulitSelectOption[] = [];
            if (this.props.incidentCategoryType.industryCategorySelections) {
                this.props.incidentCategoryType.industryCategorySelections.forEach((item, index) => {
                    let industryCategory = new MulitSelectOption();
                    industryCategory.id = item.industryCategoryId;
                    industryCategory.name = item.name;
                    industryCategory.selected = item.selected;
                    industryCategory.isCommon = false;
                    industryCategories.push(industryCategory);
                });
            }

            let incidentTypes: MulitSelectOption[] = [];
            if (this.props.incidentCategoryType.incidentTypeSelections) {
                this.props.incidentCategoryType.incidentTypeSelections.forEach((item, index) => {
                    let incidentType = new MulitSelectOption();
                    incidentType.id = item.incidentTypeId;
                    incidentType.name = item.name;
                    incidentType.selected = item.selected;
                    incidentType.isCommon = false;
                    incidentTypes.push(incidentType);
                });
            }

            /*
            let includeSecurity = true;
            if (this.props.incidentCategoryType.incidentCategoryTypeNotification && !this.props.incidentCategoryType.incidentCategoryTypeNotification.includeAssociatedUsers) {
                includeSecurity = false;
            }

            let incidentCategoryTypeNotification = new AusComplyDtos.IncidentCategoryTypeNotification();
            if (this.props.incidentCategoryType.incidentCategoryTypeNotification) {
                incidentCategoryTypeNotification = { ...this.props.incidentCategoryType.incidentCategoryTypeNotification };
            }
            */

            this.setState({
                industryCategories,
                incidentTypes,
                //incidentCategoryTypeNotification
                //}//, () => {
                // this.setUserRoleTypes(includeSecurity);
            });
        }
    }

    /*
    setUserRoleTypes(includeSecurity: boolean) {
        let excludeSecurity = false;
        if (this.props.incidentCategoryType.incidentCategoryTypeNotification && this.props.incidentCategoryType.incidentCategoryTypeNotification.venueId && this.props.incidentCategoryType.incidentCategoryTypeNotification.venueId > 0 && !includeSecurity) {
            excludeSecurity = true;
        }
        let userRoleTypes: MulitSelectOption[] = [];
        if (this.props.incidentCategoryType.incidentCategoryTypeNotification && this.props.incidentCategoryType.incidentCategoryTypeNotification.userRoleTypeSelections) {
            this.props.incidentCategoryType.incidentCategoryTypeNotification.userRoleTypeSelections.forEach((item, index) => {
                if (item.security && excludeSecurity) {
                    // dont include it
                } else {
                    let userRoleType = new MulitSelectOption();
                    userRoleType.id = item.userRoleTypeId;
                    userRoleType.name = item.name;
                    userRoleType.selected = item.selected;
                    userRoleType.isCommon = false;

                    let info = "";
                    if (item.isVenueSpecific) {
                        info = item.parentName;
                        if (item.accessLevel > 0) {
                            info = ", Access level " + item.accessLevel;
                        }
                    } else if (item.accessLevel > 0) {
                        info = "Access level " + item.accessLevel;
                    }
                    userRoleType.info = info;

                    userRoleTypes.push(userRoleType);
                }
            });
        }

        this.setState({
            userRoleTypes
        });
    }
        */

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentCategoryType = { ...this.props.incidentCategoryType };
        incidentCategoryType[fieldName] = value;
        this.props.onUpdate(incidentCategoryType)
    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        });

        let incidentCategoryType = { ...this.props.incidentCategoryType };
        if (incidentCategoryType.industryCategorySelections) {
            incidentCategoryType.industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(incidentCategoryType);
    }

    onIncidentTypesChanged(value) {
        let incidentTypes = [...this.state.incidentTypes];
        incidentTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            incidentTypes
        });

        let incidentCategoryType = { ...this.props.incidentCategoryType };
        if (incidentCategoryType.incidentTypeSelections) {
            incidentCategoryType.incidentTypeSelections.forEach((item, index) => {
                item.selected = this.state.incidentTypes.filter(s => s.id === item.incidentTypeId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(incidentCategoryType);
    }

    /*
    onIncidentCategoryTypeNotificationChanged(fieldName: string, value: any) {
        let incidentCategoryTypeNotification = { ...this.state.incidentCategoryTypeNotification };
        incidentCategoryTypeNotification[fieldName] = value;
        if (fieldName == "includeAssociatedUsers" && value == true) {
            incidentCategoryTypeNotification.onlySignedOn = true;
        }
        this.setState({ incidentCategoryTypeNotification }, () => {
            if (fieldName == "includeAssociatedUsers") {
                this.setUserRoleTypes(value);
            }
            this.onValueChanged("incidentCategoryTypeNotification", incidentCategoryTypeNotification);
        });
    }
    */

    /*
    onUserRoleTypesChanged(value) {
        let userRoleTypes = [...this.state.userRoleTypes];
        userRoleTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            userRoleTypes
        });

        // push to a local state
        let incidentCategoryTypeNotification = { ...this.state.incidentCategoryTypeNotification };
        if (incidentCategoryTypeNotification.userRoleTypeSelections) {
            incidentCategoryTypeNotification.userRoleTypeSelections.forEach((item, index) => {
                item.selected = this.state.userRoleTypes.filter(s => s.id === item.userRoleTypeId && s.selected).length > 0;
            });
        }
        this.onIncidentCategoryTypeNotificationChanged("incidentCategoryTypeNotification", incidentCategoryTypeNotification)
    }
        */


    render() {

        if (!this.props.incidentCategoryType) {
            return (<></>);
        }

        let rank = "0";
        if (this.props.incidentCategoryType.rank) {
            rank = this.props.incidentCategoryType.rank.toString();
        }

        let industries;
        if (!this.props.incidentCategoryType.isSpecificForVenueOrSecurityFirm) {
            industries = (
                <CardContainer
                    title={"Industries"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <div style={{ marginTop: '-4px' }}>
                            <MultiSelectControl
                                items={this.state.industryCategories}
                                minimumCharactersRequired={3}
                                canShowAll={true}
                                searchText={"Type at least three letters..."}
                                onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                        </div>
                    </CardContent>
                </CardContainer>
            );
        }
        let notifications;
        if (this.props.canEditNotifications && this.props.incidentCategoryType && this.props.incidentCategoryType.notificationTrigger) {
            /*
            let userRoleTypes;
            if (this.props.incidentCategoryType.incidentCategoryTypeNotification && this.props.incidentCategoryType.incidentCategoryTypeNotification.userRoleTypeSelections) {
                userRoleTypes = (
                    <MultiSelectControl
                        items={this.state.userRoleTypes}
                        minimumCharactersRequired={3}
                        canShowAll={true}
                        hideTitles={true}
                        searchText={"Type at least three letters..."}
                        onSelectItemsChanged={this.onUserRoleTypesChanged} />

                );
            }

            
            notifications = (
                <>
                    <SubTitle text={"Notifications"} style={{ marginTop: '20px' }} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Include associated security firm users"}
                        defaultValue={this.state.incidentCategoryTypeNotification.includeAssociatedUsers}
                        onChanged={(value) => this.onIncidentCategoryTypeNotificationChanged("includeAssociatedUsers", value)} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Only users currently logged in"}
                        readonly={this.state.incidentCategoryTypeNotification.includeAssociatedUsers}
                        defaultValue={this.state.incidentCategoryTypeNotification.onlySignedOn}
                        onChanged={(value) => this.onIncidentCategoryTypeNotificationChanged("onlySignedOn", value)} />
                    <CheckboxControlGroup
                        label={" "}
                        text={"Attach PDF Report"}
                        defaultValue={this.state.incidentCategoryTypeNotification.attachReport}
                        onChanged={(value) => this.onIncidentCategoryTypeNotificationChanged("attachReport", value)} />
                    <ControlGroup text={"Recipient User roles"}>
                        {userRoleTypes}
                    </ControlGroup>
                </>
            );
            */
            notifications = <NotificationTrigger
                noControlGroup={true}
                notificationTrigger={this.props.incidentCategoryType.notificationTrigger}
                onUpdate={(value) => this.onValueChanged("notificationTrigger", value)}
            />
        }

        let details;
        if ((this.props.incidentCategoryType.incidentCategoryTypeId == null || this.props.incidentCategoryType.incidentCategoryTypeId == 0) || (this.props.incidentCategoryType.incidentCategoryTypeId > 0 && this.props.incidentCategoryType.editAllowed)) {
            details = (
                <>
                    <SubTitle text={"Details"} />
                    <TextControlGroup
                        text={"Name"}
                        readonly={this.props.incidentCategoryType.isInUse}
                        defaultValue={this.props.incidentCategoryType.name}
                        onChange={value => this.onValueChanged("name", value)} />
                    <TextControlGroup
                        text={"Code"}
                        maxlength={8}
                        defaultValue={this.props.incidentCategoryType.code}
                        onChange={value => this.onValueChanged("code", value)} />
                    {
                        this.props.incidentCategoryType.venueId && this.props.incidentCategoryType.venueId > 0 && (
                            <ControlGroup text="Venue">
                                <TextDisplay>{this.props.incidentCategoryType.venueName}</TextDisplay>
                            </ControlGroup>
                        )
                    }
                    {
                        this.props.incidentCategoryType.securityFirmId && this.props.incidentCategoryType.securityFirmId > 0 && (
                            <ControlGroup text="Security Firm">
                                <TextDisplay>{this.props.incidentCategoryType.securityFirmName}</TextDisplay>
                            </ControlGroup>
                        )
                    }
                    <CardContainer
                        style={{ marginTop: '10px' }}
                        title={"Incident Types"}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <div style={{ marginTop: '-4px' }}>
                                <MultiSelectControl
                                    items={this.state.incidentTypes}
                                    minimumCharactersRequired={3}
                                    canShowAll={true}
                                    isReadonly={false} //lthis.state.incidentCategoryType.isInUse}
                                    searchText={"Type at least three letters..."}
                                    onSelectItemsChanged={this.onIncidentTypesChanged} />
                            </div>
                        </CardContent>
                    </CardContainer>
                    {industries}
                    <CheckboxControlGroup
                        text={"Legislated"}
                        defaultValue={this.props.incidentCategoryType.legislated}
                        onChanged={(value) => this.onValueChanged("legislated", value)} />
                    <CheckboxControlGroup
                        text={"Narrative Required"}
                        defaultValue={this.props.incidentCategoryType.narrativeRequired}
                        onChanged={(value) => this.onValueChanged("narrativeRequired", value)} />
                    <CheckboxControlGroup
                        text={"Allow Bulk Approval"}
                        defaultValue={this.props.incidentCategoryType.allowBulkApproval}
                        onChanged={(value) => this.onValueChanged("allowBulkApproval", value)} />
                    <CheckboxControlGroup
                        text={"Person Description Required"}
                        defaultValue={this.props.incidentCategoryType.requiresPersonDescription}
                        onChanged={(value) => this.onValueChanged("requiresPersonDescription", value)} />
                    <CheckboxControlGroup
                        text={"Add Snapshot"}
                        defaultValue={this.props.incidentCategoryType.regulatedWalkthrough}
                        onChanged={(value) => this.onValueChanged("regulatedWalkthrough", value)} />
                    <CheckboxControlGroup
                        text={"Contact Tracing"}
                        defaultValue={this.props.incidentCategoryType.contactTracing}
                        onChanged={(value) => this.onValueChanged("contactTracing", value)} />
                    <CheckboxControlGroup
                        text={"Auto Approve"}
                        defaultValue={this.props.incidentCategoryType.autoApprove}
                        onChanged={(value) => this.onValueChanged("autoApprove", value)} />
                    <CheckboxControlGroup
                        text={"Date of Birth Required"}
                        defaultValue={this.props.incidentCategoryType.requiresPersonDob}
                        onChanged={(value) => this.onValueChanged("requiresPersonDob", value)} />
                    <CheckboxControlGroup
                        text={"Internal Only"}
                        defaultValue={this.props.incidentCategoryType.apiOnlyCreation}
                        onChanged={(value) => this.onValueChanged("apiOnlyCreation", value)} />
                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />
                </>
            );
        } else {
            details = (
                <>
                    <SubTitle text={"Details"} />
                    <TextControlGroup
                        text={"Name"}
                        readonly={true}
                        defaultValue={this.props.incidentCategoryType.name} />
                    <TextControlGroup
                        text={"Code"}
                        readonly={true}
                        defaultValue={this.props.incidentCategoryType.code} />
                    {
                        this.props.incidentCategoryType.venueId && this.props.incidentCategoryType.venueId > 0 && (
                            <ControlGroup text="Venue">
                                <TextDisplay>{this.props.incidentCategoryType.venueName}</TextDisplay>
                            </ControlGroup>
                        )
                    }
                    {
                        this.props.incidentCategoryType.securityFirmId && this.props.incidentCategoryType.securityFirmId > 0 && (
                            <ControlGroup text="Security Firm">
                                <TextDisplay>{this.props.incidentCategoryType.securityFirmName}</TextDisplay>
                            </ControlGroup>
                        )
                    }
                </>
            );
        }

        return (
            <FullPageDialog
                open={this.props.incidentCategoryTypeId != -1}
                notFullWidth={true}
                title="Incident Category Type"
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerRightContent={<PrimaryButton text='Save' onClick={() => this.onSave()} />}
                onDismissed={() => this.props.onClose()}>

                <LayoutForm
                    fullWidth={true}
                >
                    <form>
                        {details}
                        <Spacer />
                        {notifications}
                    </form>
                </LayoutForm >
            </FullPageDialog>
        );
    }
}