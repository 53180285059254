import { connect } from 'react-redux'
import CheckLists from '../components/checklists/checklists/CheckLists'
import { withRouter } from "react-router";
import * as AusComplyDtos from '../common/dto/AusComply.dtos';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as checklistActions from '../common/actions/checklist';
import { getChecklistStatusRequest } from '../common/actions/referenceData';

const mapStateToProps = state => {
    return {
        canAddNewChecklist: permissionsLogic.hasPermissionForState(state, "ChecklistCreate"),
        isLoading: state.checklist.checklistsLoading,
        checklists: state.checklist.checklists,
        checklistfilter: state.checklist.checklistfilter,
        paging: state.checklist.checklistsPaging,
        pagesize: state.checklist.checklistsPageSize,
        page: state.checklist.checklistsPage,
        canApprove: permissionsLogic.hasPermissionForState(state, "ChecklistApprove"),
        canReject: permissionsLogic.hasPermissionForState(state, "ChecklistReject"),
        canClose: permissionsLogic.hasPermissionForState(state, "ChecklistClose"),
        canArchive: permissionsLogic.hasPermissionForState(state, "ChecklistArchive"),
        canSearch: permissionsLogic.hasPermissionForState(state, "ChecklistSearch"),
        showEvents: permissionsLogic.hasPermissionForState(state, "ViewEvent")
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSetChecklistsPage: (page) => {
            dispatch(checklistActions.setChecklistsPage(page))
        },
        onLoadCheckLists: (search) => {
            dispatch(checklistActions.loadChecklistsRequest(search.searchText, search.checklistStatuses, search.startDate, search.endDate))
        },
        onLoadCheckListStatus: () => {
            dispatch(getChecklistStatusRequest())
        },
        onLoadCheckList: (checklistId) => {
            dispatch(checklistActions.loadChecklistRequest(checklistId))
        },
        onApprove: (checklistIds) => {
            dispatch(checklistActions.approveChecklistsRequest(checklistIds))
        },
        onArchive: (checklistIds) => {
            dispatch(checklistActions.archiveChecklistsRequest(checklistIds))
        },
        onRestore: (checklistIds) => {
            dispatch(checklistActions.restoreChecklistsRequest(checklistIds))
        },
        onVoid: (checklistIds) => {
            dispatch(checklistActions.closeChecklistsRequest(checklistIds))
        }, 
        onFilterUpdate: (filter) => {
            dispatch(checklistActions.checklistFilterSet(filter))
        },
        onFilterReset: () => {
            dispatch(checklistActions.checklistFilterReset())
        }
    }
}
const CheckListsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckLists))
export default CheckListsContainer