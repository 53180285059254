import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ThumbnailLarge from '../../../common/ThumbnailLarge';
import Box from '@material-ui/core/Box';
import ImagePreview from '../../../common/ImagePreview';
import DocumentIcon from '../../../../resources/DocumentIcon';

export interface ICheckListItemAttachmentFileProps {
    checkListDisplayItem: CheckListDisplayItem;
    theme: any;
}

interface ICheckListItemAttachmentFileState {
    selectedFilePath: string;
    selectedFileName: string;
}

class CheckListItemAttachmentFile extends Component<ICheckListItemAttachmentFileProps, ICheckListItemAttachmentFileState> {
    constructor(props: ICheckListItemAttachmentFileProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        }
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render() {
        const { theme } = this.props;
        const hasFile = (this.props.checkListDisplayItem.template.file 
            && !this.props.checkListDisplayItem.template.file.obsolete 
            && this.props.checkListDisplayItem.template.file.fileDisplayPath 
            && this.props.checkListDisplayItem.template.file.fileDisplayPath.length > 0);
        let attachments;
        if (hasFile && this.props.checkListDisplayItem.template.file) {
            let item = this.props.checkListDisplayItem.template.file;
                attachments = (
                    <Box p={0} key={"file-box-" + item.fileName}>
                        <ThumbnailLarge
                                key={"file-" + item.fileName }
                                previewPath={item.filePreviewPath}
                                displayPath={item.fileDisplayPath}
                                alwaysShowText={true}
                                isImage={item.isImage}
                                text={item.fileName}
                                fullText={item.fileName}
                                onPreview={(path, text) => this.onSelectFilePath(path, text)}
                                onDownload={path => this.onDownload(path)}
                            />
                    </Box>);
        }

        
        return (
            <div >
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {attachments}
                </Box>
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemAttachmentFile);