import React, { Component } from 'react';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CardTitle from '../common/CardTitle';
import CardInformation from '../common/CardInformation';
import CardDivider from '../common/CardDivider';
import DefaultButton from '../controls/DefaultButton';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HomeIcon from '@material-ui/icons/Home';


export interface IVenueSecurityFirmSummaryProps {
    venue?: string;
    securityFirm?: string;
    venueEvent?: string;
    showSecurityCode?: boolean;
    securityCode?: string;
    currentReportingEndTimeFormatted?: string;
    onChangeVenue: Function;
    onClearVenue: Function;
}


export default class VenueSecurityFirmSummary extends Component<IVenueSecurityFirmSummaryProps, any> {
    constructor(props: IVenueSecurityFirmSummaryProps) {
        super(props)
        this.state = {
            showSecurityCode: false
        };
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.onToggleSecurityCode = this.onToggleSecurityCode.bind(this);
    }

    componentDidMount() {
        this.setState({
            showSecurityCode: false
        });
    }

    onChangeSelection() {
        this.props.onClearVenue();
        this.props.onChangeVenue();
    }

    onToggleSecurityCode() {
        this.setState({ showSecurityCode: !this.state.showSecurityCode });
    }

    render() {
        let actions = (
            <Grid container spacing={1}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <DefaultButton onClick={this.onChangeSelection} text="Change Venue / Security" />
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        );
        return (
            <CardContainer
                title={"Venue"}
                actions={actions}
            >
                <CardContent style={{ paddingBottom: '6px' }}>
                    <CardTitle>Venue</CardTitle>
                    <CardInformation>{this.props.venue}</CardInformation>
                    {this.props.venueEvent && (<>
                        <CardDivider />
                        <CardTitle>Event</CardTitle>
                        <CardInformation>{this.props.venueEvent}</CardInformation>
                    </>)}
                    <CardDivider />
                    <CardTitle>Security Firm</CardTitle>
                    <CardInformation>{this.props.securityFirm}</CardInformation>
                    <CardDivider />
                    {this.props.currentReportingEndTimeFormatted && (
                        <>
                            <CardTitle>Reporting Period</CardTitle>
                            <CardInformation>{this.props.currentReportingEndTimeFormatted}</CardInformation>
                            <CardDivider />
                        </>
                    )}
                    {(this.props.showSecurityCode) && (
                        <>
                            <CardTitle>Security Code</CardTitle>
                            <CardInformation >{this.state.showSecurityCode ? this.props.securityCode : "****"} {!this.state.showSecurityCode && (<VisibilityIcon onClick={this.onToggleSecurityCode} style={{fontSize: '20px'}} />)}{this.state.showSecurityCode && (<VisibilityOffIcon onClick={this.onToggleSecurityCode} style={{fontSize: '20px'}} />)}</CardInformation>
                            <CardDivider />
                        </>
                    )}
                </CardContent>
            </CardContainer>
        );
    }
}