import { connect } from 'react-redux'
import Roster from '../components/roster/Roster'
import * as rosterActions from '../common/actions/roster'

const mapStateToProps = state => {
    return {
        rosters: state.rosters.rosters.rosters,
        filter: state.rosters.rosters.filter,
        isLoading: state.rosters.rosters.isLoading,
        error: state.rosters.rosters.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRefresh: (filter) => {
            dispatch(rosterActions.rosterRequest(filter))
        },
        onSetFilter: (filter) => {
            dispatch(rosterActions.rosterFilterSet(filter))
        }
    }
}

const RosterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Roster)

export default RosterContainer