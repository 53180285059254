import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const broadcastNotificationServiceApi = {
  find: (venueId, securityFirmId, page, pageSize, filter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetBroadcastNotifications();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId, forSecurityFirmId, cloneId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostBroadcastNotificationNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.cloneId = cloneId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, broadcastNotification) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostBroadcastNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.broadcastNotification = broadcastNotification;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, broadcastNotificationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetBroadcastNotification();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.broadcastNotificationId = broadcastNotificationId;
    const request = client.get(query);
    return from(request);
  },
  test: (venueId, securityFirmId, broadcastNotificationId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostBroadcastNotificationTest();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.broadcastNotificationId = broadcastNotificationId;
    const request = client.post(query);
    return from(request);
  }
};
