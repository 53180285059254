import { Box } from '@material-ui/core';
import * as React from 'react';
import RadioChipControlGroup from '../../../controls/RadioChipControlGroup';

export interface IIncidentPatronRequiredChipSelectionProps {
    title: string,
    name: string,
    selected: number,
    onChanged: Function,
    list: any,
    required: boolean,
    labelAbove: boolean,
    theme: any
}

export default class IncidentPatronRequiredChipSelection extends React.Component<IIncidentPatronRequiredChipSelectionProps, any> {
    constructor(props: IIncidentPatronRequiredChipSelectionProps) {
        super(props);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(value: any) {
        this.props.onChanged(value);
    }

    public render() {
        let patronGenderWidthStyle = (!this.props.selected || this.props.selected == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        return (!this.props.selected ?
            <div style={{
                ...this.props.theme.custom.fileUploaderContainer,
                position: 'relative',
                borderColor: this.props.theme.custom.colors.error,
                width: '100%'
            }}>
                <Box style={patronGenderWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>{this.props.title}</p></Box>
                <Box p={0} style={patronGenderWidthStyle}>
                    <RadioChipControlGroup
                        chipWidth={"120px"}
                        name={this.props.name}
                        selected={this.props.selected}
                        onChanged={(value) => this.onSelectionChanged(value)}
                        list={this.props.list}
                        required={true}
                        labelAbove={true} />
                </Box>
            </div> : 
            <>
                <Box style={patronGenderWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>The patron involved is </p></Box>
                <Box p={0} style={patronGenderWidthStyle}>
                    <RadioChipControlGroup
                        chipWidth={"120px"}
                        name="gender"
                        selected={this.props.selected}
                        onChanged={this.props.onChanged}
                        list={this.props.list}
                        required={true}
                        labelAbove={true} />
                </Box>
            </>);
    }
}
