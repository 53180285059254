import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import PageInfo from '../../common/PageInfo';
import FormWarningMessage from '../../alerts/FormWarningMessage';

export interface IIncidentViewOtherVenueProps {
    incident: IncidentDTO;
}

export default class IncidentViewOtherVenue extends Component<IIncidentViewOtherVenueProps, any> {
    render() {
        if (!this.props.incident.accessViaVenueId) {
            return <></>
        }
        let warning = "This incident is attached to venue '" + this.props.incident.accessViaVenue + "' . To edit, switch to this venue and then reload the incident.";

        return (
            <>
                <FormWarningMessage warning={warning}  />
            </>
        );
    }
}