import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_FEED_REQUEST = "FACIAL_RECOGNITION_FEED_REQUEST",    
    FACIAL_RECOGNITION_FEED_REQUEST_SUCCESS = "FACIAL_RECOGNITION_FEED_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_FEED_REQUEST_FAILURE = "FACIAL_RECOGNITION_FEED_REQUEST_FAILURE",
    FACIAL_RECOGNITION_FEED_SET_AUTOREFRESH = "FACIAL_RECOGNITION_FEED_SET_AUTOREFRESH";

export const facialRecognitionFeedRequest = makeActionCreator(FACIAL_RECOGNITION_FEED_REQUEST, 'forVenueId', 'venueLocationId', 'limit');
export const facialRecognitionFeedRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_FEED_REQUEST_SUCCESS, 'feed');
export const facialRecognitionFeedRequestFailure = makeActionCreator(FACIAL_RECOGNITION_FEED_REQUEST_FAILURE, 'error');
export const facialRecognitionFeedSetAutorefresh = makeActionCreator(FACIAL_RECOGNITION_FEED_SET_AUTOREFRESH, 'value');
