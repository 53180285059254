import React, { Component } from 'react';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import SelectControlGroup from '../controls/SelectControlGroup';
import { IdName } from "../../common/dto/common";

export interface IScheduleVenueProps {
    schedule: AusComplyDtos.Schedule;
    venues: IdName[];
    onUpdate: Function;
}

interface IScheduleVenueState {
}

export default class ScheduleVenue extends Component<IScheduleVenueProps, IScheduleVenueState> {
    constructor(props: IScheduleVenueProps) {
        super(props)
        this.state = {
        }
        this.getVenueId = this.getVenueId.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    getVenueId() {
        if (this.props.schedule && this.props.schedule.scheduleVenues) {
            const venueIds = this.props.schedule.scheduleVenues.filter(f => !f.obsolete).map(item => item.venueId);
            if (venueIds.length > 0) {
                return venueIds[0];
            }
        }
        return 0;
    }

    onValueChanged(venueId: number) {
        let newItems: AusComplyDtos.ScheduleVenue[] = [];
        if (venueId > 0) {
            let newItem: AusComplyDtos.ScheduleVenue = new AusComplyDtos.ScheduleVenue();
            newItem.venueId = venueId;
            newItems.push(newItem);
        }
        let schedule = { ...this.props.schedule };
        schedule.scheduleVenues = newItems;
        this.props.onUpdate(schedule);
    }

    render() {
        return (
            <SelectControlGroup
                text="Venue"
                onChange={(value) => this.onValueChanged(Number(value))}
                defaultValue={this.getVenueId()}>
                <option key={0} value={0}>Select...</option>
                {this.props.venues.map((item, index) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </SelectControlGroup>
        );
    }
}