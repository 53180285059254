import update from "immutability-helper";
import {
    CONTENT_PRIVACYPOLICY_REQUEST,
    CONTENT_PRIVACYPOLICY_REQUEST_FAILURE,
    CONTENT_PRIVACYPOLICY_REQUEST_SUCCESS,
    CONTENT_TERMS_REQUEST,
    CONTENT_TERMS_REQUEST_FAILURE,
    CONTENT_TERMS_REQUEST_SUCCESS
} from "../actions/content";

const initialState = {
    isLoading: false,
    terms: { title: '', text: '' },
    privacyPolicy: { title: '', text: '' }
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}



function updateTerms(state, content) {
    let newState = update(state, {
        terms: { $set: content }
    });
    return newState;
}

function updatePrivacyPolicy(state, content) {
    let newState = update(state, {
        privacyPolicy: { $set: content }
    });
    return newState;
}

export default function content(state = initialState, action) {
    switch (action.type) {
        case CONTENT_PRIVACYPOLICY_REQUEST:
            return loading(state, true);
        case CONTENT_PRIVACYPOLICY_REQUEST_SUCCESS:
            return loading(updatePrivacyPolicy(state, action.content), false);
        case CONTENT_PRIVACYPOLICY_REQUEST_FAILURE:
            return loading(state, false);
        case CONTENT_TERMS_REQUEST:
            return loading(state, true);
        case CONTENT_TERMS_REQUEST_SUCCESS:
            return loading(updateTerms(state, action.content), false);
        case CONTENT_TERMS_REQUEST_FAILURE:
            return loading(state, false);
    }
    return state;
}