import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import PrimaryButton from '../../controls/PrimaryButton';
import Information from '../../common/Information';
import View from '../../common/View';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';

export interface IFacialRecognitionWatchlistMoveProps {
    isLoading: boolean;
    isSaving: boolean;
    move: AusComplyDtos.FacialRecognitionWatchlistMove;
    onSave: Function;
    onSet: Function;
    onReLoad: Function;
    theme: any;
    classes: any;
}

interface IFacialRecognitionWatchlistMoveState {
}

class FacialRecognitionWatchlistMove extends Component<IFacialRecognitionWatchlistMoveProps, IFacialRecognitionWatchlistMoveState> {
    constructor(props: IFacialRecognitionWatchlistMoveProps) {
        super(props)
        this.state = {
        };
        this.onCancel = this.onCancel.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSaving && !this.props.isSaving && !this.props.move.error) {
            this.props.onReLoad(this.props.move.facialRecognitionWatchlist.venueId, 0);
            this.onCancel();
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let move = { ...this.props.move };
        move[fieldName] = value;
        this.props.onSet(move)
    }

    onCancel() {
        this.props.onSet({ facialRecognitionWatchlistId: -1 });
    }

    onMove() {
        this.props.onSave();
    }

    render() {
        let self = this;
        const { theme } = this.props;
        let show = this.props.move && this.props.move.facialRecognitionWatchlistId > -1;
        let content;

        if (this.props.move && this.props.move.groups) {
            content = <>
                <TextDisplayControlGroup text={"Watchlist"} value={this.props.move.facialRecognitionWatchlist.displayName} />
                <SelectControlGroup
                    text="Move to group"
                    onChange={(value) => this.onValueChanged("groupId", value)}
                    defaultValue={self.props.move.groupId}>
                    <option value={0} />
                    {this.props.move.groups.map((group, index) => (
                        <option key={group.groupId} value={group.groupId}>{group.name}</option>
                    ))}
                </SelectControlGroup>
                <View style={{margin: '10px 0'}}>
                    <Information
                        isWarning={true}
                    >
                        <p style={{ ...theme.custom.label, fontSize: '0.7em', padding: '0 10px', textAlign: 'center', color: theme.custom.colors.warning }}>
                            <span>
                                WARNING: INFORMATION ENTERED HERE WILL BE VISIBLE TO OTHER VENUES SHARING THIS WATCHLIST.
                                <br /> <br />
                            </span>
                            <span>AusComply Facial Recognition must continue to be used in line with our Terms &amp; Conditions, Privacy Statement and any Service Level Agreement. A breach of these conditions may result in restriction or termination of service.</span>
                        </p>
                    </Information>
                </View>
                <CheckboxControlGroup
                    label={" "}
                    text={"Acknowledge the privacy warning"}
                    defaultValue={this.props.move.privacyAck}
                    onChanged={(value) => this.onValueChanged("privacyAck", value)} />
            </>;
        }

        return (
            <div style={{ display: 'inline-table' }}>
                <FullPageDialog
                    open={show}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    title={"Move watchlist"}
                    error={this.props.move.error}
                    notFullWidth={true}
                    footerRightContent={<PrimaryButton text="Move" onClick={this.onMove} disabled={!this.props.move.privacyAck || this.props.move.groupId === 0} />}
                    onDismissed={() => this.onCancel()}>
                    {content}
                </FullPageDialog>
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(FacialRecognitionWatchlistMove);