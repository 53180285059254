import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../common/dto/AusComply.dtos";
import { incidentLogic } from '../../common/logic/incidentLogic';
import Progress from '../controls/Progress';
import IncidentWizardNavigation from './wizard/IncidentWizardNavigation';
import { withRouter } from "react-router";
import PageTitle from '../common/PageTitle';
import FixedFooter from '../layout/FixedFooter';
import IncidentWizardType from './wizard/IncidentWizardType';
import IncidentWizardDates from './wizard/IncidentWizardDates';
import IncidentWizardCategory from './wizard/IncidentWizardCategory';
import IncidentWizardSnapshot from './wizard/IncidentWizardSnapshot';
import IncidentWizardLocation from './wizard/IncidentWizardLocation';
import IncidentWizardPersonnel from './wizard/IncidentWizardPersonnel';
import IncidentWizardNarrative from './wizard/IncidentWizardNarrative';
import IncidentWizardFlags from './wizard/IncidentWizardFlags';
import IncidentWizardDocuments from './wizard/IncidentWizardDocuments';
import IncidentWizardAttachButton from './wizard/IncidentWizardAttachButton';
import IncidentWizardRelatedIncidents from './wizard/IncidentWizardRelatedIncidents';
import IncidentWizardPeopleAndGroups from './wizard/IncidentWizardPeopleAndGroups';
import IncidentWizardChecklists from './wizard/IncidentWizardChecklists';
import Loading from '../common/Loading';
import Saving from '../common/Saving';
import Error from '../common/Error';
import ErrorBoundary from '../common/ErrorBoundary';
import { commonLogic } from '../../common/logic/common';
import { ProgressItem } from '../controls/Progress';
import PageLayout from '../layout/PageLayout';
import PrimaryButton from '../controls/PrimaryButton';

export interface IDraftIncidentProps {
    incidentTypeId: number;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    hasDraftIncident: boolean;
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onCreateIncident: Function;
    onUpdateIncident: Function;
    onClearError: Function;
    onClear: Function;
    onSaveDraft: Function;
    onSaveFinal: Function;
    onCheckIncidentDateReportingPeriod: Function;
    onErrorNotification: Function;
    onResetFilter: Function;
    step: number;
    onSetStep: Function;
    history: any;
    location: any;
    isLocationLoading: boolean;
    latitude: number;
    longitude: number;
    locationFound: boolean;
    locationError: string;
    onRefreshLocation: Function;
    isUploading: boolean;
    onUploadFiles: Function;
    onUploadPatronFiles: Function;
    hasChecklists: boolean;
    onUploadAttachment: Function;
    onLoadChecklists: Function;
    isLoadingChecklists: boolean;
    onLoadIncidentOptions: Function;
}

interface IDraftIncidentState {
    errors: any[];
    canSubmit: boolean;
    validDraft: boolean;
    validSubmit: boolean;
}

class DraftIncident extends Component<IDraftIncidentProps, IDraftIncidentState> {

    constructor(props: IDraftIncidentProps) {
        super(props)

        this.state = {
            errors: [],
            canSubmit: true,
            validDraft: true,
            validSubmit: true
        }

        this.onBack = this.onBack.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onDraft = this.onDraft.bind(this);
        this.onClearError = this.onClearError.bind(this);
        this.onUpdateDate = this.onUpdateDate.bind(this);
        this.clearAndClose = this.clearAndClose.bind(this);
        this.onUpdateLocation = this.onUpdateLocation.bind(this);
        this.setErrorPage = this.setErrorPage.bind(this);
        this.onStep = this.onStep.bind(this);
        this.onUpdateAndRefreshChecklists = this.onUpdateAndRefreshChecklists.bind(this);
    }

    componentDidMount() {
        if (!this.props.hasDraftIncident || (this.props.hasDraftIncident && !this.props.incident)) {
            this.props.onCreateIncident(this.props.incidentTypeId);
            if (!this.props.locationFound) {
                this.props.onRefreshLocation();
            }
        }
        if (this.props.hasDraftIncident && !this.props.incidentOptions.incidentTypes) {
            this.props.onLoadIncidentOptions();
        } else if (this.props.hasDraftIncident && this.props.incidentTypeId == 4) {
            this.onDraft();
        }
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed
            if (!this.props.error && this.props.incidentTypeId == 4) {
                this.props.history.push('/draftincident/0');
            } else if (!this.props.error) {
                // Saved successfully
                this.clearAndClose();
            }
        }

        if (!this.props.isLoading && preProps.isLoading && this.props.incidentTypeId == 4) {
            this.onDraft();
        } else if (!this.props.isLoading && preProps.isLoading && this.props.error != "") {
            this.clearAndClose();
            return;
        }

        // eslint-disable-next-line
        if (!this.props.isLoading && preProps.isLoading && (!this.props.incident.incidentId || this.props.incident.incidentId == 0)) {
            this.onUpdateLocation();
        } else if (!this.props.isLoading && preProps.isLocationLoading && !this.props.isLocationLoading) {
            this.onUpdateLocation();
        }

        // eslint-disable-next-line
        if (this.props.incident && this.props.incident != preProps.incident) {

            var errors = incidentLogic.validateIncident2(this.props.incident, this.props.incidentOptions);
            let validDraft = true;
            let validSubmit = true;
            if (errors.length > 0) {
                validDraft = errors[0].draftOk;
                validSubmit = errors[0].submitOk;
            }
            //var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            this.setState({
                errors,
                validDraft,//: validationResult.draftOk,
                validSubmit//: validationResult.submitOk
            });
        }
    }

    onBack() {
        this.props.onSetStep(this.props.step - 1);
    }

    onNext() {

        this.props.onSetStep(this.props.step + 1);
    }

    onStep(progressItem: ProgressItem) {
        this.props.onSetStep(progressItem.step);
    }

    onClearError() {
        this.props.onClearError();
    }

    onCancel() {
        // cancel and navigate to home
        let url: string = "/incidents";
        if (this.props.incident && this.props.incidentOptions) {
            if (incidentLogic.isRGO(this.props.incident, this.props.incidentOptions)) {
                url = "/gamblingincidentregister";
            }
        }
        // if never saved - clear, otherwise delete
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        this.props.history.push(url);
    }

    clearAndClose() {
        let url: string = "/incidents";
        if (this.props.incident && this.props.incidentOptions) {
            if (incidentLogic.isRGO(this.props.incident, this.props.incidentOptions)) {
                url = "/gamblingincidentregister";
            }
        }
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.onResetFilter();
        this.props.history.push(url);
    }

    onUpdateLocation() {
        let incident = { ...this.props.incident };
        incident.locationFound = this.props.locationFound;
        incident.latitude = this.props.latitude;
        incident.longitude = this.props.longitude;
        this.props.onUpdateIncident(incident);
    }

    onDraft() {
        if (this.props.isUploading) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.draftOk) {
            this.props.onErrorNotification(validationResult.error);
            this.setErrorPage(validationResult.page);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveDraft();
    }

    onSubmit() {
        if (this.props.isUploading) {
            this.props.onErrorNotification("Please wait until all files are uploaded");
            return;
        }
        // prevent save if the reporting period is not correct setting or any other validation, switch to that page
        var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
        if (!validationResult.submitOk) {
            this.props.onErrorNotification(validationResult.error);
            this.setErrorPage(validationResult.page);
            return;
        }
        // update the location and save
        if (!this.props.incident.incidentId || this.props.incident.incidentId == 0) {
            this.onUpdateLocation();
        }
        this.props.onSaveFinal();
    }

    setErrorPage(page) {
        switch (page) {
            case "INCIDENT_TYPE":
                this.props.onSetStep(1);
                break;
            case "INCIDENT_DATE":
                this.props.onSetStep(1);
                break;
            case "INCIDENT_CATEGORY":
                this.props.onSetStep(2);
                break;
            case "INCIDENT_LOCATION":
                this.props.onSetStep(3);
                break;
            case "INCIDENT_PEOPLE":
                if (this.props.hasChecklists) {
                    this.props.onSetStep(5);
                } else {
                    this.props.onSetStep(4);
                }
                break;
            case "INCIDENT_ACTIONS":
                this.props.onSetStep(4);
                break;
            default:
        }
    }

    onUpdate(incident) {
        this.props.onUpdateIncident(incident);
    }

    onUpdateAndRefreshChecklists(incident) {
        this.props.onUpdateIncident(incident);
        this.props.onLoadChecklists();
    }

    onUpdateDate(incident) {
        this.props.onUpdateIncident(incident);
        this.props.onCheckIncidentDateReportingPeriod(incident.incidentDate);
    }

    onApprove() {

    }

    render() {
        let progressItems: ProgressItem[] = [];
        let stepDisplays = [
            <>
                <IncidentWizardType incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} />
                <IncidentWizardDates incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateDate} onSwipedLeft={this.onNext} />
                <IncidentWizardDocuments incident={this.props.incident}
                    isUploading={this.props.isUploading}
                    onUploadFiles={this.props.onUploadFiles}
                    onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} />
            </>,
            <>
                <IncidentWizardCategory incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdateAndRefreshChecklists} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <IncidentWizardSnapshot incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>,
            <IncidentWizardLocation incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate}
                isLocationLoading={this.props.isLocationLoading}
                locationError={this.props.locationError}
                locationFound={this.props.locationFound}
                latitude={this.props.latitude}
                longitude={this.props.longitude}
                onUpdateLocation={this.props.onRefreshLocation}
                onSwipedLeft={this.onNext} onSwipedRight={this.onBack}
            />
        ];
        progressItems.push({ step: 1, key: "type", name: "Type", isError: this.state.errors.filter(f => f.page == "INCIDENT_TYPE" || f.page == "INCIDENT_DATE").length > 0 });
        progressItems.push({ step: 2, key: "categories", name: "Categories", isError: this.state.errors.filter(f => f.page == "INCIDENT_CATEGORY").length > 0 });
        progressItems.push({ step: 3, key: "location", name: "Location", isError: this.state.errors.filter(f => f.page == "INCIDENT_LOCATION").length > 0 });

        if (this.props.hasChecklists) {
            stepDisplays.push(<IncidentWizardChecklists onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />);
            progressItems.push({ step: progressItems.length + 1, key: "actions", name: "Actions", isError: this.state.errors.filter(f => f.page == "INCIDENT_ACTIONS").length > 0 });
        }
        stepDisplays.push(
            <>
                <IncidentWizardNarrative incident={this.props.incident} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <IncidentWizardPeopleAndGroups incident={this.props.incident} incidentOptions={this.props.incidentOptions}
                    onUploadPatronFiles={this.props.onUploadPatronFiles}
                    isUploading={this.props.isUploading}
                    onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>
        );
        progressItems.push({ step: progressItems.length + 1, key: "narrative", name: "Narrative", isError: this.state.errors.filter(f => f.page == "INCIDENT_PEOPLE").length > 0 });

        stepDisplays.push(
            <>
                <IncidentWizardFlags incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
                <IncidentWizardPersonnel incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedLeft={this.onNext} onSwipedRight={this.onBack} />
            </>
        );
        progressItems.push({ step: progressItems.length + 1, key: "flags", name: "Flags" });

        stepDisplays.push(<IncidentWizardRelatedIncidents incident={this.props.incident} incidentOptions={this.props.incidentOptions} onUpdateIncident={this.onUpdate} onSwipedRight={this.onBack} />);
        progressItems.push({ step: progressItems.length + 1, key: "related", name: "Related" });

        let stepDisplay = stepDisplays.filter((item, index) => index == this.props.step - 1).map((item) => {
            return (
                <div key={"step-" + (this.props.step - 1).toString()}>
                    {item}
                </div>)
        });
        //let stepDisplay = stepDisplays[this.props.step - 1];

        if (this.props.isLoading) {
            return (
                <>
                    <PageTitle title="New incident" />
                    <Loading />
                </>
            );
        }

        if (this.props.isSaving) {
            return (
                <>
                    <PageTitle title="New incident" />
                    <Saving />
                </>
            );
        }

        let isError = false;
        let isWarning = false;
        if (this.props.incident.completed == false) {
            isError = !this.state.validDraft && !this.state.validSubmit;
            isWarning = this.state.validDraft && !this.state.validSubmit;
        } else {
            isError = !this.state.validDraft || !this.state.validSubmit;
        }

        let incidentType = "Incident";
        if (this.props.incident.incidentTypeId && this.props.incidentOptions && this.props.incidentOptions.incidentTypes) {
            this.props.incidentOptions.incidentTypes
                .filter(item =>
                    item.incidentTypeId == this.props.incident.incidentTypeId)
                .forEach(item => {
                    incidentType = item.name;
                });
        }

        return <PageLayout
            headerText={"New " + incidentType}
            footerContent={<IncidentWizardNavigation
                step={this.props.step}
                steps={stepDisplays.length}
                onNext={this.onNext}
                onBack={this.onBack}
                onCancel={this.onCancel}
                onSubmit={this.onSubmit}
                canSubmit={this.state.canSubmit && !isError && !isWarning && !this.props.isLoadingChecklists}
                canApprove={false}
                onApprove={this.onApprove}
                onDraft={this.onDraft}
                canDraft={!this.props.isLoadingChecklists}
                canDelete={true}
                attachButton={<IncidentWizardAttachButton incident={this.props.incident} onUpdateIncident={this.onUpdate} />}
            />}
            aboveFooterContent={<>
                {(this.props.step == stepDisplays.length && this.state.canSubmit && !isError && !isWarning && !this.props.isLoadingChecklists) && (<>
                    <PrimaryButton text={"Submit incident report"} onClick={this.onSubmit} />
                </>)}
                {(this.props.step == stepDisplays.length && this.props.incident.canApprove && !this.props.incident.approved && !this.props.isLoadingChecklists) && (<>
                    <PrimaryButton text={"Approve incident report"} onClick={this.onApprove} style={{ marginTop: '10px' }} />
                </>)}
            </>}
        >
            <Progress
                value={this.props.step}
                items={progressItems}
                showStep={true}
                onClick={this.onStep} />

            {this.props.error && (
                <Error message={this.props.error} onClear={this.onClearError} />
            )}

            <ErrorBoundary>
                {stepDisplay}
            </ErrorBoundary>

        </PageLayout>

        return (
            <div>
                <PageTitle title={"New " + incidentType} />

                {this.props.error && (
                    <Error message={this.props.error} onClear={this.onClearError} />
                )}

                <ErrorBoundary>
                    {stepDisplay}
                </ErrorBoundary>

                <FixedFooter>
                    <IncidentWizardNavigation
                        step={this.props.step}
                        steps={stepDisplays.length}
                        onNext={this.onNext}
                        onBack={this.onBack}
                        onCancel={this.onCancel}
                        onSubmit={this.onSubmit}
                        canSubmit={this.state.canSubmit && !isError && !isWarning && !this.props.isLoadingChecklists}
                        canApprove={false}
                        onApprove={this.onApprove}
                        onDraft={this.onDraft}
                        canDraft={!this.props.isLoadingChecklists}
                        canDelete={true}
                        attachButton={<IncidentWizardAttachButton incident={this.props.incident} onUpdateIncident={this.onUpdate} />}
                    />
                </FixedFooter>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(DraftIncident));