import React from "react";

const PaperclipIcon = ({
    name = "",
    style = {},
    fill = "#919191",
    viewBox = "",
    width = "100%",
    className = "MuiSvgIcon-root",
    height = "100%"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox || "0 0 30.5 30.2"}
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path fill={fill} d="M5.4,30.2c-1.4,0-2.8-0.6-3.8-1.7c-2.1-2.3-2.1-6,0-8.2l16.7-18c1.3-1.5,3.1-2.2,5-2.2c1.9,0,3.7,0.8,5,2.2 c1.3,1.5,2.1,3.4,2.1,5.4c0,2.1-0.7,4-2.1,5.4L15.2,27.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1.1,0-1.5L27,11.6c1-1,1.5-2.4,1.5-3.9 c0-1.5-0.5-2.9-1.5-3.9c-1-1-2.3-1.6-3.6-1.6c-1.4,0-2.7,0.6-3.6,1.6L3,21.8c-1.3,1.4-1.3,3.7,0,5.2c1.3,1.4,3.5,1.4,4.8,0L19,14.8 c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2c-0.6-0.7-1.7-0.7-2.3,0L9,20.6c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1.1,0-1.5 l7.7-8.3c1.4-1.5,3.8-1.5,5.2,0c0.7,0.7,1.1,1.7,1.1,2.8c0,1-0.4,2.1-1.1,2.8L9.2,28.5C8.2,29.6,6.9,30.2,5.4,30.2" />
            </g>
        </svg>
    );

export default PaperclipIcon;
