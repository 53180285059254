import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import Groups from '../components/groups/groups/Groups'
import {
    groupSet,
    groupsSetFilter,
    groupUpsertRequest,
    groupsReset,
    groupRequest
} from '../common/actions/groups';

const mapStateToProps = (state, props) => {
    return {
        isAdmin: true,
        filter: state.groups.groups.filter,
        isLoading: state.groups.groups.isLoading,
        isSaving: state.groups.group.isSaving,
        groups: state.groups.groups.groups,
        canCreate: permissionsLogic.hasPermissionForState(state, 'CreateGroup')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(groupsSetFilter(filter))
        },
        onSet: (group) => {
            dispatch(groupSet(group))
        },
        onUpdate: () => {
            dispatch(groupUpsertRequest())
        },
        onReset: () => {
            dispatch(groupsReset())
        }
    }
}

const AdminGroupsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Groups))

export default AdminGroupsContainer