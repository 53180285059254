import React, { Component, forwardRef } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';

export interface IRegisterSecurityFirmSuccessProps {
    history: any;
    location: any;
}

interface IRegisterSecurityFirmSuccessState {
}

const HomeLink = forwardRef((props, ref) => <Link to="/" {...props} />);

class RegisterSecurityFirmSuccess extends Component<IRegisterSecurityFirmSuccessProps, IRegisterSecurityFirmSuccessState> {

    constructor(props: IRegisterSecurityFirmSuccessProps) {
        super(props)

        this.state = {
        }
    }
    render() {

        return (
            <CardContainer
                title={"Register Security Firm"}>
            <CardContent>
                    <p>
                    <b>Your security firm registration has been successful</b><br /><br />
                    You will be contacted soon about setting up payment or contact AusComply Pty Ltd Customer Service (email: sales@auscomply.com.au or by telephone: 1300 2 COMPLY) for further information.
                    </p>
            </CardContent>
        </CardContainer>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(RegisterSecurityFirmSuccess));