import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import CardContainer from '../../common/CardContainer';
import Grid from '@material-ui/core/Grid';
import CardTitle from '../../common/CardTitle';
import CardTypography from '../../common/CardTypography';
import CheckboxControl from '../../controls/CheckboxControl';
import FormErrorMessage from '../../alerts/FormErrorMessage';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IFacialRecognitionServerCloneProps {
    facialRecognitionServerId?: number;
    venues: AusComplyDtos.NamedValue[];
    isLoading: boolean;
    isSaving: boolean;
    isSavedSuccessful: boolean
    onLoadVenues: Function;
    onSave: Function;
    onDismissed?: Function;
}

interface IFacialRecognitionServerCloneState {
    show: boolean;
    venueId: number;
}

export default class FacialRecognitionServerClone extends Component<IFacialRecognitionServerCloneProps, IFacialRecognitionServerCloneState> {
    constructor(props: IFacialRecognitionServerCloneProps) {
        super(props)
        this.state = {
            show: false,
            venueId: 0
        };
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.isSaving && !this.props.isSaving) {
            if (this.props.isSavedSuccessful) {
                this.setState({ show: false }, () => {
                    if (self.props.onDismissed) {
                        self.props.onDismissed();
                    }
                });
            }
        }
        else if (this.props.facialRecognitionServerId == 0 && this.state.show) {
            this.setState({ show: false });
        }
        else if (prevProps.facialRecognitionServerId != this.props.facialRecognitionServerId) {
            this.setState({ show: true, venueId: 0 }, () => {
                this.props.onLoadVenues();
            });
        }
    }

    onCancel() {
        let self = this;
        this.setState({ show: false }, () => {
            if (self.props.onDismissed) {
                self.props.onDismissed();
            }
        });
    }

    onSave() {
        let self = this;
        var from = this.props.facialRecognitionServerId;
        this.props.onSave(from, this.state.venueId);
        this.setState({ show: false }, () => {
            if (self.props.onDismissed) {
                self.props.onDismissed();
            }
        });
    }

    render() {
        let self = this;
        let content;
        if (this.props.isSaving) {
            content = <Saving />
        } else if (this.props.isLoading) {
            content = <Loading />
        } else if (!this.props.isLoading && this.props.venues) {
            const data = this.props.venues;
            content = <>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <SelectControlGroup
                            defaultValue={this.state.venueId}
                            onChange={(value) => self.setState({ venueId: value })}>
                            <option key={0} value={0}>Select Venue...</option>
                            {data.map((venue) => <option key={venue.id} value={venue.id}>{venue.name}</option>)}
                        </SelectControlGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <PrimaryButton text={"Save"} onClick={() => self.onSave()}></PrimaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <DefaultButton text={"Cancel"} onClick={() => self.onCancel()}></DefaultButton>
                    </Grid>
                </Grid>
            </>
        }

        return (
            <>
                <div style={{ display: 'inline-table' }}>
                    <FullPageDialog open={this.state.show || this.props.isLoading} title={"Clone Server"} onDismissed={() => this.onCancel()}>
                        {content}
                    </FullPageDialog>
                </div>
            </>
        );
    }
}