import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import Progress from '../../controls/Progress';
import LayoutForm from '../../layout/LayoutForm';
import { withRouter } from "react-router";
import PageTitle from '../../common/PageTitle';
import FixedFooter from '../../layout/FixedFooter';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Error from '../../common/Error';
import Fab from '@material-ui/core/Fab';
import ErrorBoundary from '../../common/ErrorBoundary';
import SearchControl from '../../controls/SearchControl';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TransparentButton from '../../controls/TransparentButton';
import SaveIcon from '@material-ui/icons/Save';
import TickIcon from '../../../resources/TickIcon';
import MagnifyingIcon from '../../../resources/MagnifyingIcon';
import CrossIcon from '../../../resources/CrossIcon';
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import { commonLogic } from '../../../common/logic/common';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import TextControlGroup from '../../controls/TextControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import ThemeSwitcher from '../../general/ThemeSwitcher';
import InmatePhoto from '../inmate/InmatePhoto';
// import { incidentLogic } from '../../common/logic/incidentLogic';

export interface IInmateCellProps {
    correctionalFacilityInMateVenueLocation: AusComplyDtos.CorrectionalFacilityInMateVenueLocation;
    cells: AusComplyDtos.VenueLocation[];
    risks: AusComplyDtos.NamedValue[];
    onUpdate: Function;
    history: any;
    location: any;
}

class InmateCell extends Component<IInmateCellProps, any> {

    constructor(props: IInmateCellProps) {
        super(props)

        this.state = {
        }
        this.onValueChanged = this.onValueChanged.bind(this);
        this.getDefaultInspectionInterval = this.getDefaultInspectionInterval.bind(this);
    }

    onValueChanged(fieldName: string, value: any) {
        let inspectionInterval = this.props.correctionalFacilityInMateVenueLocation.inspectionInterval;
        let correctionalFacilityInMateVenueLocation = { ...this.props.correctionalFacilityInMateVenueLocation };
        if (fieldName == "correctionalFacilityRiskStatusId") {
            // get the default interval
            inspectionInterval = this.getDefaultInspectionInterval(value);
        }
        correctionalFacilityInMateVenueLocation.inspectionInterval = inspectionInterval;
        correctionalFacilityInMateVenueLocation[fieldName] = value;
        this.props.onUpdate(correctionalFacilityInMateVenueLocation);
    }

    getDefaultInspectionInterval(riskId) {
        if (this.props.risks != null) {
            var risk = this.props.risks.find(f => f.id == riskId);
            if (risk) {
                return risk.rank;
            }
        }
        return 0;
    }

    render() {
        let self = this;
        if (!this.props.correctionalFacilityInMateVenueLocation) return <></>;

        let numberInvolved = this.props.correctionalFacilityInMateVenueLocation.inspectionInterval;
        if (!numberInvolved) {
            numberInvolved = 0;
        }
        let showInspectionNotes = false;
        if (this.props.correctionalFacilityInMateVenueLocation.correctionalFacilityRiskStatusId) {
            if (this.getDefaultInspectionInterval(this.props.correctionalFacilityInMateVenueLocation.correctionalFacilityRiskStatusId)
                != this.props.correctionalFacilityInMateVenueLocation.inspectionInterval
            ) {
                showInspectionNotes = true;
            }
        }

        return (<CardContainer style={{ position: 'relative' }} title={"Cell and Risk"}>
            <CardContent>
                <SelectControlGroup
                    text="Cell"
                    onChange={(value) => this.onValueChanged("venueLocationId", value)}
                    defaultValue={this.props.correctionalFacilityInMateVenueLocation.venueLocationId}>
                    <option value={0} />
                    {this.props.cells.map((item, index) => (
                        <option key={item.venueLocationId} value={item.venueLocationId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <TextControlGroup text={"Bed"}
                    defaultValue={this.props.correctionalFacilityInMateVenueLocation.subLocation}
                    onBlur={(value) => this.onValueChanged("subLocation", value)} />
                <SelectControlGroup
                    text="Risk"
                    onChange={(value) => this.onValueChanged("correctionalFacilityRiskStatusId", value)}
                    defaultValue={this.props.correctionalFacilityInMateVenueLocation.correctionalFacilityRiskStatusId}>
                    <option value={0} />
                    {this.props.risks.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </SelectControlGroup>
                <NumericUpDownControlGroup
                    text="Inspection Interval"
                    value={numberInvolved}
                    onChange={(value) => this.onValueChanged("inspectionInterval", value)} editable={true} />
                {showInspectionNotes && (
                    <TextAreaControlGroup text="Inspection Interval Change Reason"
                        defaultValue={this.props.correctionalFacilityInMateVenueLocation.inspectionIntervalReason}
                        readonly={false}
                        defaultRows={2}
                        onBlur={(value) => this.onValueChanged("inspectionIntervalReason", value)} />
                )}
            </CardContent>
        </CardContainer>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(InmateCell));