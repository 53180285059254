import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import PrimaryButton from '../../controls/PrimaryButton';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface ISecurityFirmSubscriptionProps {
    securityFirmId: number;
    isLoading: boolean;
    securityFirmSubscriptionSummary: AusComplyDtos.SecurityFirmSubscriptionSummary;
    canAdminEdit: boolean;
    onLoad: Function;
    error: any;
    history: any;
}

export default class SecurityFirmSubscription extends Component<ISecurityFirmSubscriptionProps, any> {
    constructor(props: ISecurityFirmSubscriptionProps) {
        super(props)
        this.state = {
        };
        this.onAdminEdit = this.onAdminEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    onAdminEdit() {
        this.props.history.push('/admin/securityFirm/' + this.props.securityFirmId);
    }

    render() {
        var adminLink;
        if (this.props.canAdminEdit) {
            adminLink = (
                <TransparentButton text={"Admin"} onClick={() => this.onAdminEdit()} />
            );
        }
        return (
            <PageLayout
                loading={this.props.isLoading}
                saving={false}
                error={this.props.error}
                headerText={"Security Firm Subscription"}
                footerLeftContent={adminLink}
            >
                <form>
                    <ControlGroup text="ID">
                        <TextDisplay>{this.props.securityFirmSubscriptionSummary.securityFirmId}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Direct Debit">
                        <PrimaryButton
                            component={forwardRef((props, ref) => (
                                <a
                                    href={this.props.securityFirmSubscriptionSummary.paywayUrl}
                                    rel="noreferrer"
                                    target="_blank"
                                    {...props}
                                />
                            ))}>Enter Details</PrimaryButton>
                    </ControlGroup>
                </form>
            </PageLayout>
        );
    }
}