import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SubTitle from '../../common/SubTitle';
import List from '../../common/List';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import VenueContactCard from './VenueContactCard';
import VenueContactHeader from './VenueContactHeader';
import VenueContactRow from './VenueContactRow';
import PageMessage from '../../common/PageMessage';
import VenueContactContainer from '../../../containers/VenueContactContainer';
import VenueContactLegend from './VenueContactLegend';
import ConfirmDialog from '../../controls/ConfirmDialog';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IVenueContactsProps {
    isLoading: boolean;
    venueId: number;
    venueContacts: AusComplyDtos.VenueContact[];
    venueContactId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.ContactFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
}

export default class VenueContacts extends Component<IVenueContactsProps, any> {
    constructor(props: IVenueContactsProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            contactToRemove: {}
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    componentDidUpdate(prevProps) {
       
    }

    prepareFilter(filter: AusComplyDtos.ContactFilter) {
        let preparedFilter = { ...filter };
        if (this.props.venueId && this.props.venueId > 0) {
            preparedFilter.forVenueId = this.props.venueId;
        } else {
            preparedFilter.forVenueId = 0;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    onCommand(command: string, venueContact: AusComplyDtos.VenueContact) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(venueContact.venueContactId);
                break;
            case "primary":
                var updated = { ...venueContact };
                updated.primaryContact = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...venueContact };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    contactToRemove: updated
                });
                //this.props.onUpdate(updated);
                //this.props.onSave();
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let venueContact = { ...this.state.contactToRemove };
            this.props.onUpdate(venueContact);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                contactToRemove: {}
            });
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.venueContacts && this.props.venueContacts.length > 0) {
            cardList = this.props.venueContacts.map((item, index) => {
                return (
                    <VenueContactCard
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"card-" + item.venueContactId.toString()}
                        venueContact={item}
                    />
                );
            });
            rows = this.props.venueContacts.map((item, index) => {
                return (
                    <VenueContactRow
                        onCommand={this.onCommand}
                        canEdit={this.props.canEdit}
                        key={"row-" + item.venueContactId.toString()}
                        venueContact={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<VenueContactHeader />}
                    rows={rows}
                />
            );
        }

        let venueContact;
        if (this.props.venueContactId != -1) {
            if (!this.props.venueId || this.props.venueId == 0) {
                //venueContact = (
                //    <AdminVenueContactContainer />
                //);
            } else {
                venueContact = (
                    <VenueContactContainer />
                );
            }
        }

        let create;
        if (this.props.canEdit) {
            create = (
                <TransparentButton 
                    onClick={() => this.props.onCreate(this.props.venueId)} 
                    text={"Add Contact"} />
            );
        }

        return (<PageLayout
        headerText={"Venue Contacts"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            <PagedList
                    
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    footer={ <VenueContactLegend />}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
            <FullPageDialog 
                open={this.props.venueContactId != -1} 
                title="Venue Contact" 
                notFullWidth={true}
                onDismissed={() => this.props.onResetItem()}>
                {venueContact}
            </FullPageDialog>
            <ConfirmDialog
                    title={"Confirm remove"}
                    text={"Are you sure you wish to remove this contact"}
                    show={this.state.confirmRemove}
                    onCancel={this.removeCancel}
                    onConfirm={this.removeConfirmed} />
        </PageLayout>);
    }
}