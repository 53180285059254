import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentDocument, File as FileDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import ImagePreviewWithProcessingContainer from '../../../containers/ImagePreviewWithProcessingContainer';

export interface ICommLogDocumentsProps {
    incident: IncidentDTO;
    isUploading: boolean;
    onUploadFiles: Function;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    onCompressing?: Function;
    theme: any;
}

interface ICommLogDocumentsState {
    selectedFilePath: string;
    selectedFileName: string;
    incidentDocumentId: number;
    imageText: string;
    index: number;
}

class CommLogDocuments extends Component<ICommLogDocumentsProps, ICommLogDocumentsState> {
    constructor(props: ICommLogDocumentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: "",
            incidentDocumentId: 0,
            imageText: "",
            index: -1,
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    onSelectFilePath(incidentDocumentId, filePath, text, index) {
        this.setState({
            incidentDocumentId,
            selectedFilePath: filePath,
            selectedFileName: text,
            index
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    removeDocument(document: IncidentDocument) {
        let newIncident = { ...this.props.incident };
        if (document.incidentDocumentId === 0) {
            newIncident.incidentDocuments = newIncident.incidentDocuments.filter(item => item.fileDisplayPath !== document.fileDisplayPath);
        } else {
            newIncident.incidentDocuments.forEach(item => {
                if (item.fileDisplayPath === document.fileDisplayPath) {
                    item.hasChanged = true;
                    item.obsolete = true;
                }
            });
        }
        this.props.onUpdateIncident(newIncident);
    }

    expandImage(document: IncidentDocument) {

    }

    fileWasUploaded(temporaryFiles: FileDTO[]) {
        let newIncident = { ...this.props.incident };
        temporaryFiles.forEach(item => {
            let document = new IncidentDocument();
            document.obsolete = false;
            document.originalFilename = item.originalName;
            document.isImage = item.isImage;
            document.incidentId = this.props.incident.incidentId;
            document.canvasRescaled = false;
            document.filePath = item.path;
            document.filePreviewPath = item.filePreviewPath;
            document.fileDisplayPath = item.fileDisplayPath;
            document.extension = item.extension;
            document.incidentDocumentId = 0;
            document.filePreviewText = item.filePreviewText;
            document.hasChanged = true;
            document.canvasRescaled = item.canvasRescaled;
            newIncident.incidentDocuments.push(document);
        });
        this.props.onUpdateIncident(newIncident);
    }

    render() {
        const { theme } = this.props;

        // build the current list of documents
        let documents;
        let hasDocuments = false;
        let nonObsoleteDocs;
        if (this.props.incident && this.props.incident.incidentDocuments) {
            nonObsoleteDocs = this.props.incident.incidentDocuments.filter(item => !item.obsolete);
            documents = nonObsoleteDocs.filter(item => !item.obsolete).map((item, index) => {
                hasDocuments = true;
                let imageTextLength = 0;
                if (item.imageText != undefined && item.imageText !== "") {
                    imageTextLength = item.imageText.length;
                }
                return (
                    <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFilename + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => {
                                this.onSelectFilePath(item.incidentDocumentId, path, text, index);
                            }}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                            hasImageText={imageTextLength > 0}
                        />
                    </Box>
                )
            });
        }

        let imageText;
        if (nonObsoleteDocs && this.state.index > -1) {
            imageText = nonObsoleteDocs[this.state.index].imageText;
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Photos, videos and documents"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                            {documents}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <FileUpload
                                            onFileWasUploaded={this.fileWasUploaded}
                                            isUploading={this.props.isUploading}
                                            onUploadFiles={this.props.onUploadFiles}
                                            hasFiles={hasDocuments} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ImagePreviewWithProcessingContainer
                                incidentDocumentId={this.state.incidentDocumentId}
                                filePath={this.state.selectedFilePath}
                                fileName={this.state.selectedFileName}
                                imageText={imageText}
                                onDismissed={() => this.onSelectFilePath(0, "", "", -1)} />
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(CommLogDocuments);