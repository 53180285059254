import React, { Component, forwardRef } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import PrimaryButton from '../../controls/PrimaryButton';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IVenueSubscriptionProps {
    venueId: number;
    isLoading: boolean;
    venueSubscriptionSummary: AusComplyDtos.VenueSubscriptionSummary;
    canAdminEdit: boolean;
    onLoad: Function;
    error: any;
    history: any;
}

export default class VenueSubscription extends Component<IVenueSubscriptionProps, any> {
    constructor(props: IVenueSubscriptionProps) {
        super(props)
        this.state = {
        };
        this.onAdminEdit = this.onAdminEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoad();
    }

    onAdminEdit() {
        this.props.history.push('/admin/venue/' + this.props.venueId);
    }

    render() {
        var adminVenueLink;
        if (this.props.canAdminEdit) {
            adminVenueLink =(
                <TransparentButton 
                    onClick={() => this.onAdminEdit()} 
                    text={"Admin"} />
            );
        }
        return (
                <PageLayout
                    loading={this.props.isLoading}
                    saving={false}
                    error={this.props.error}
                    headerText={"Venue Subscription"}
                    footerLeftContent={adminVenueLink}
                >
                    <form>
                        <ControlGroup text="ID">
                            <TextDisplay>{this.props.venueSubscriptionSummary.venueId}</TextDisplay>
                        </ControlGroup>
                        <ControlGroup text="Direct Debit">
                            <PrimaryButton
                                component={forwardRef((props, ref) => (
                                <a 
                                    href={this.props.venueSubscriptionSummary.paywayUrl} 
                                    rel="noreferrer"
                                    target="_blank"
                                     {...props}
                                      />
                            ))}>Enter Details</PrimaryButton>
                        </ControlGroup>
                    </form>
                </PageLayout>
        );
    }
}