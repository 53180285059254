import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const websitePropertiesApi = {
  get: (venueId: number, securityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetWebsiteProperties();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    const request = client.get(query);
    return from(request);
  },
  save: (venueId: number, securityFirmId: number, websiteProperties: AusComplyDtos.WebsiteProperties) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostWebsiteProperties();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.websiteProperties = websiteProperties;
    const request = client.post(query);
    return from(request);
  }
};
