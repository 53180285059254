import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import CopyIcon from '../../resources/CopyIcon';
import { Palette } from '../../common/constants/palette';
import * as notificationActions from '../../common/actions/notification';
import { connect } from 'react-redux'

export interface ICopyToClipboardButtonProps {
    theme: any;
    classes: any;
    text: string;
    disabled?: boolean;
    onNotification: Function;
}

class CopyToClipboardButton extends React.PureComponent<ICopyToClipboardButtonProps, any> {
    constructor(props: ICopyToClipboardButtonProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        // copy the test to the clipboard
        navigator.clipboard.writeText(this.props.text);

        // send notification
        this.props.onNotification("Copied to clipboard")
    }
    render() {
        const { classes, theme } = this.props;

        return (
            <Button
                variant="contained"
                onClick={e => this.onClick(e)}
                color="default"
                size={"small"}
                disabled={this.props.disabled}
                className={classNames(classes.buttonTransparent, classes.buttonTransparentState)}
                style={{ color: theme.palette.text.secondary, minWidth: '100px' }}>
                    Copy Text
            </Button>
        );
        // <CopyIcon fill={Palette.AlternativeText} />
    }
}

const CopyToClipboardButtonStyled = withStyles(styles, { withTheme: true })(CopyToClipboardButton);

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNotification: (message) => {
            dispatch(notificationActions.notificationSuccessShow(message))
        }
    }
}

const CopyToClipboardButtonContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyToClipboardButtonStyled)

export default CopyToClipboardButtonContainer
