import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import FacialRecognitionWatchlistsContainer from '../../../containers/FacialRecognitionWatchlistsContainer';

export interface IVenueFacialRecognitionNotificationsWatchlistsProps {
    venueId: number;
    venueMode: boolean;
    history: any;
    location: any;
}

class VenueFacialRecognitionNotificationsWatchlists extends Component<IVenueFacialRecognitionNotificationsWatchlistsProps, any> {
    
    render() {
        return <FacialRecognitionWatchlistsContainer venueId={this.props.venueId} groupId={0} venueMode={true} />
    }
}

export default withStyles(styles, { withTheme: true })(VenueFacialRecognitionNotificationsWatchlists);