import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface ISecurityFirmContactProps {
    securityFirmContact: AusComplyDtos.SecurityFirmContact;
    securityFirmContactTypes: AusComplyDtos.SecurityFirmContactType[];
    securityFirmUsers: AusComplyDtos.UserSelection[];
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
}

interface ISecurityFirmContactState {
}

export default class SecurityFirmContact extends Component<ISecurityFirmContactProps, ISecurityFirmContactState> {
    constructor(props: ISecurityFirmContactProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    componentDidMount() {

    }
    
    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let securityFirmContact = { ...this.props.securityFirmContact };
        securityFirmContact[fieldName] = value;
        this.props.onUpdate(securityFirmContact)
    }

    render() {

        if (!this.props.securityFirmContact) {
            return (<></>);
        }

        let securityFirmContactTypes;
        if (this.props.securityFirmContactTypes && this.props.securityFirmContactTypes.length > 1) {
            securityFirmContactTypes = (
                <SelectControlGroup
                    text="Contact Type"
                    onChange={(value) => this.onValueChanged("securityFirmContactTypeId", Number(value))}
                    defaultValue={this.props.securityFirmContact.securityFirmContactTypeId}>
                    <option key={0} value={0}>Select...</option>
                    {this.props.securityFirmContactTypes.map((item, index) => (
                        <option key={item.securityFirmContactTypeId} value={item.securityFirmContactTypeId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let securityFirmUsers;
        if (this.props.securityFirmUsers && this.props.securityFirmUsers.length > 1) {
            securityFirmUsers = (
                <SelectControlGroup
                    text="User"
                    onChange={(value) => this.onValueChanged("userId", Number(value))}
                    defaultValue={this.props.securityFirmContact.userId}>
                    <option key={0} value={0}>Other (specify)</option>
                    {this.props.securityFirmUsers.map((item, index) => (
                        <option key={item.userId} value={item.userId}>{item.name}</option>
                    ))}
                </SelectControlGroup>
            );
        }

        let secondaryContact;
        if(!this.props.securityFirmContact.primaryContact) {
            secondaryContact = (
                <CheckboxControlGroup
                    text={"Secondary Contact"}
                    defaultValue={this.props.securityFirmContact.secondaryContact}
                    onChanged={(value) => this.onValueChanged("secondaryContact", value)} />
            );
        }

        let details;
        if (!this.props.securityFirmContact.userId || this.props.securityFirmContact.userId === 0)
        {
            details = (
                <>
                    <TextControlGroup
                        text={"First name"}
                        defaultValue={this.props.securityFirmContact.firstname}
                        onBlur={(value) => this.onValueChanged("firstname", value)} />

                    <TextControlGroup
                        text={"Last name"}
                        defaultValue={this.props.securityFirmContact.lastname}
                        onBlur={(value) => this.onValueChanged("lastname", value)} />
                    
                    <TextControlGroup
                        text={"Phone"}
                        defaultValue={this.props.securityFirmContact.phone}
                        onBlur={(value) => this.onValueChanged("phone", value)} />

                    <TextControlGroup
                        text={"Email"}
                        defaultValue={this.props.securityFirmContact.email}
                        onBlur={(value) => this.onValueChanged("email", value)} />
                </>
            );
        }

        return (
            <LayoutForm
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    { securityFirmContactTypes }
                    { securityFirmUsers }
                    { details }
                    { secondaryContact }
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}