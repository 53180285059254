import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident } from '../../common/dto/AusComply.dtos';
import CheckboxControl from '../controls/CheckboxControl';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CrossIcon from '../../resources/CrossIcon';
import Button from '@material-ui/core/Button';
import ContainedParagraph from '../common/ContainedParagraph';

export interface IIncidentRowProps {
    classes: any;
    theme: any;
    incident: Incident;
    onSelected?: Function;
    onEdit?: Function;
    onView?: Function;
    canApprove: boolean;
    canEdit: boolean;
    canView: boolean;
    onRemove?: Function;
    showEvents: boolean;
}

class IncidentRow extends React.Component<IIncidentRowProps, any> {
    constructor(props: IIncidentRowProps) {
        super(props);
        this.onSelected = this.onSelected.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onSelected(value) {
        var incident = { ...this.props.incident };
        incident.selected = value;
        if (this.props.onSelected) {
            this.props.onSelected(incident);
        }
    }

    onClick() {
        if (this.props.canEdit && this.props.onEdit) {
            this.props.onEdit(this.props.incident);
        }
        else if (this.props.canView && this.props.onView) {
            this.props.onView(this.props.incident);
        }
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove();
        }
    }

    render() {
        const { classes, theme } = this.props;
        let item = this.props.incident;

        let className = classes.rowStatusPending;
        if (!this.props.incident.completed) {
            className = classes.rowStatusDraft;
        } else if (this.props.incident.approved) {
            className = classes.rowStatusApproved;
        } else if (this.props.incident.hasRequiredActions) {
            className = classes.rowStatusDanger;
        }

        return (
            <tr className={classes.tableTr} key={"row-" + item.incidentId.toString()} onClick={this.onClick}>
                <td className={className} ></td>
                <td>
                    <p>
                        {item.incidentSerialNumber}
                    </p>
                </td>
                <td>
                    <p>
                        {item.incidentDateStringTz}
                    </p>
                </td>
                <td>
                    <p>
                        {item.incidentType}
                    </p>
                </td>
                <td>
                    <p>
                        {item.categories}
                    </p>
                </td>
                <td>
                    <ContainedParagraph maxLines={6} text={item.summary} />
                </td>
                {this.props.showEvents && <td>
                    <p>
                        {item.venueEvent}
                    </p>
                </td>}
                <td>
                    <p>
                        {item.locations}
                    </p>
                </td>
                <td onClick={e => { e.stopPropagation(); }}>
                    <p>
                        {item.enteredByUser}
                    </p>
                </td>
                <td onClick={e => { e.stopPropagation(); }}>
                    <p>
                        {item.lastModifiedByUser}
                    </p>
                </td>
                {this.props.canApprove && (
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }} onClick={e => { e.stopPropagation(); }}>
                        {(item.allowBulkApproval && item.completed && !item.approved) && (
                            <div className="ngt-form-responsive" style={{ minHeight: '10px', marginLeft: '16px' }}>
                                <CheckboxControl defaultValue={item.selected} onChanged={this.onSelected} />
                            </div>
                        )}
                    </td>
                )}
                <td style={{ textAlign: 'center', verticalAlign: 'middle', paddingTop: '10px' }}>
                    {item.hasAttachment && (
                        <AttachFileIcon />
                    )}
                </td>
                {this.props.onRemove && (
                    <td>
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                                display: 'inline-flex',
                                padding: '4px', minWidth: '0'
                            }}
                            onClick={e => { e.stopPropagation(); this.onRemove(); }}
                            className={classes.linearOutlineColorError}
                        ><CrossIcon fill={theme.custom.colors.error} style={{ fontSize: '0.8em' }} /></Button>
                    </td>
                )}
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentRow);