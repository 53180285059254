import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const facialRecognitionAutomaticEnrolmentServiceApi = {
  find: request.makePost(
    AusComplyDtos.PostFacialRecognitionAutomaticEnrolments,
    'venueId', 'securityFirmId', 'forVenueId'
  ),
  create: request.makePost(
    AusComplyDtos.PostFacialRecognitionAutomaticEnrolmentNew,
    'venueId', 'securityFirmId', 'forVenueId'
  ),
  upsert: request.makePost(
    AusComplyDtos.PostFacialRecognitionAutomaticEnrolment,
    'venueId', 'securityFirmId', 'facialRecognitionAutomaticEnrolment'
  ),
  get: request.makeGet(
    AusComplyDtos.GetFacialRecognitionAutomaticEnrolment,
    'venueId', 'securityFirmId', 'facialRecognitionAutomaticEnrolmentId'
  ),
  process: request.makePost(
    AusComplyDtos.PostFacialRecognitionAutomaticEnrolmentProcess,
    'venueId', 'securityFirmId', 'facialRecognitionAutomaticEnrolmentId', 'simpleDate'
  ),
};
