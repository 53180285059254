import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface IIncidentLegendProps {
    showCurrent?: boolean;
    classes: any;
    theme: any;
}

class IncidentLegend extends React.Component<IIncidentLegendProps, any> {

    render() {
        const { classes } = this.props;

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentDraft}></div>
                    <div className={classes.colorSwatchText}>Draft</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentPending}></div>
                    <div className={classes.colorSwatchText}>Approval Pending</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentApproved}></div>
                    <div className={classes.colorSwatchText}>Approved</div>
                </Box>
                <Box p={1} >
                    <div className={classes.colorSwatchIncidentDeleted}></div>
                    <div className={classes.colorSwatchText}>Action Required</div>
                </Box>
                {this.props.showCurrent && (
                    <Box p={1} >
                        <div className={classes.colorSwatchIncidentCurrent}></div>
                        <div className={classes.colorSwatchText}>Current</div>
                    </Box>
                )}
            </Box>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentLegend);