export const
SYSTEM_LOG_REQUEST = "SYSTEM_LOG_REQUEST",
SYSTEM_LOG_REQUEST_SUCCESS = "SYSTEM_LOG_REQUEST_SUCCESS",
SYSTEM_LOG_REQUEST_FAILURE = "SYSTEM_LOG_REQUEST_FAILURE";

export function systemLogRequest(venueId ,securityFirmId) {
    return {
        type: SYSTEM_LOG_REQUEST,
        venueId,
        securityFirmId
    };
}

export function systemLogRequestSuccess(notifications) {
    return {
        type: SYSTEM_LOG_REQUEST_SUCCESS,
        notifications
    };
}

export function systemLogRequestFailure(error) {
    return {
        type: SYSTEM_LOG_REQUEST_FAILURE,
        error
    };
}
