import React from 'react';
import View from '../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import CheckboxControl from '../controls/CheckboxControl'
import TextControlGroup from '../controls/TextControlGroup';
import TransparentButton from '../controls/TransparentButton';
import LayoutForm from '../layout/LayoutForm';
import DefaultButton from '../controls/DefaultButton';

export interface ISimpleFilterProps {
    filter: AusComplyDTOs.SimpleFilter;
    onUpdateFilter?: Function;
    onReset: Function;
    onSearch: Function;
}

interface ISimpleFilterState {
    filter: AusComplyDTOs.SimpleFilter;
}

export default class SimpleFilter extends React.Component<ISimpleFilterProps, ISimpleFilterState> {
    constructor(props: ISimpleFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showRemovedChanged = this.showRemovedChanged.bind(this);

        this.state = {
            filter: new AusComplyDTOs.SimpleFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            if (this.props.onUpdateFilter) {
                this.props.onUpdateFilter(this.state.filter);
            }
        });
    }

    showRemovedChanged(value) {
        let filter = { ...this.state.filter };
        filter.showObsolete = value;
        this.setState({ filter }, () => {
            if (this.props.onUpdateFilter) {
                this.props.onUpdateFilter(this.state.filter);
            }
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let resetButton = (
            <Grid container spacing={1} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                    <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                </Grid>
                <Grid item xs={6}>
                    <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                </Grid>
            </Grid>
        );


        return (
            <LayoutForm>
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChange('search', value);
                        this.props.onSearch();
                    }}
                    onChange={value => this.onChange('search', value)} />
                <CheckboxControl text="Show Removed" defaultValue={this.state.filter.showObsolete} onChanged={this.showRemovedChanged} />
                {resetButton}
            </LayoutForm>
        );
    }
}