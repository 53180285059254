import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { images } from '../../utilities/images';

export const fileServiceApi = {
  uploadFiles: (files: any[]) => {
    return from(new Promise<AusComplyDtos.PostTemporaryFilesResponse>((resolve, reject) => {
      var client = new AusComplyJsonServiceClient();
      var parameters = new AusComplyDtos.PostTemporaryFiles();
      const formData = new FormData();
      // compress any images
      let promises: Promise<any>[] = [];
      files.forEach((file, i) => {
        promises.push(images.compressIfImage(file));
      });
      Promise.all(promises)
        .then((results) => {
          results.forEach(r => {
            formData.append('files[]', r, r.name);
          });
          client.postBody(parameters, formData).then(r => {
            resolve(r);
          }).catch(x => {
            reject(x)
          });
        })
        .catch((e) => {
          console.log(e)
        });
    }));
  },
  uploadBase64File: (file: string, fileType: string, fileName: string, poiGuid: string, appearanceGuid: string) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostTemporaryBase64File();
    var base64File = new AusComplyDtos.Base64ImageData();
    base64File.data = file;
    base64File.fileName = fileName;
    base64File.fileType = fileType;
    query.base64ImageData = base64File;
    query.poiGuid = poiGuid;
    query.appearanceGuid = appearanceGuid;
    const request = client.post(query);
    return from(request);
  }
};
