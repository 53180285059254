

import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import SingleSelectListControl from '../../controls/SingleSelectListControl';
import { SingleSelectList } from '../../../common/dto/common';
import TransparentButton from '../../controls/TransparentButton';
import FullPageDialog from '../../layout/FullPageDialog';

export interface ISecurityFirmAssociationProps {
    venueSecurityFirm: AusComplyDtos.VenueSecurityFirm;
    securityFirms: AusComplyDtos.SecurityFirmSelection[];
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onCancel: Function;
    onSave: Function;
    onClosed?: Function;
    open: boolean;
}

interface ISecurityFirmAssociationState {
}

export default class SecurityFirmAssociation extends Component<ISecurityFirmAssociationProps, ISecurityFirmAssociationState> {
    constructor(props: ISecurityFirmAssociationProps) {
        super(props)
        this.state = {
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
    }

    componentDidMount() {

    }
    
    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && prevProps.isLoading) {
            
        }
    }

    onSave() {
        this.props.onSave();
    }

    onCancel() {
        this.props.onCancel();
        if (this.props.onClosed) {
            this.props.onClosed();
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let venueSecurityFirm = { ...this.props.venueSecurityFirm };
        venueSecurityFirm[fieldName] = value;
        this.props.onUpdate(venueSecurityFirm)
    }

    onSelectedItemChanged(item) {
        if (item) {
            this.onValueChanged("securityFirmId", item.id);
        } else {
            this.onValueChanged("securityFirmId", null);
        }
    }

    render() {

        if (!this.props.venueSecurityFirm) {
            return (<></>);
        }

        let securityFirms;
        if (this.props.securityFirms && this.props.securityFirms.length > 1) {
            let items: SingleSelectList[] = [];
            this.props.securityFirms.forEach((securityFirm) => {
                items.push({ id: securityFirm.securityFirmId, name: securityFirm.name, isCommon: false, selected: this.props.venueSecurityFirm.securityFirmId === securityFirm.securityFirmId, info: "", infoColor: "" });
            });
            securityFirms = (
                <ControlGroup text={"Security Firm"}>
                    <SingleSelectListControl
                        items={items}
                        selectedTitle=""
                        emptyMessage="Select a Security Firm"
                        hideEmptyMessage={true}
                        commonTitle=""
                        availableTitle="Search security firms"
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={3}
                        onSelectedItemChanged={this.onSelectedItemChanged}
                        autoHideOnSelected={true}
                    ></SingleSelectListControl>
                </ControlGroup>
            )
        }

        let canSave = false;
        if (this.props.venueSecurityFirm.securityFirmId != undefined)
        {
            canSave = this.props.venueSecurityFirm.securityFirmId > 0;
        }

        return (
            <FullPageDialog
                open={this.props.open}
                notFullWidth={true}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                title="Security Firm Association"
                footerRightContent={<PrimaryButton text={"Save"} disabled={!canSave} onClick={this.onSave} />}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={() => this.onCancel()} />}
                onDismissed={() => this.onCancel()}>
                <TextControlGroup
                    text={"Venue"}
                    defaultValue={this.props.venueSecurityFirm.venueName}
                    readonly={true} />
                {securityFirms}
            </FullPageDialog>
        );
    }
}