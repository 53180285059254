import { connect } from 'react-redux'
import { withRouter } from "react-router";
import SecurityFirmAssociation from '../components/securityFirm/securityFirmAssociation/SecurityFirmAssociation';
import { 
    securityFirmAssociationReset,
    securityFirmAssociationSet,
    securityFirmAssociationInsertRequest
 } from '../common/actions/securityFirmAssociations';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.securityFirmAssociations.venueSecurityFirm.isLoading,
        isSaving: state.securityFirmAssociations.venueSecurityFirm.isSaving,
        venueSecurityFirm: state.securityFirmAssociations.venueSecurityFirm.venueSecurityFirm,
        securityFirms: state.securityFirmAssociations.venueSecurityFirm.securityFirms,
        open: props.open,
        onClosed: props.onClosed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(securityFirmAssociationReset())
        },
        onUpdate: (venueSecurityFirm) => {
            dispatch(securityFirmAssociationSet(venueSecurityFirm))
        },
        onSave: () => {
            dispatch(securityFirmAssociationInsertRequest())
        }
    }
}

const SecurityFirmAssociationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmAssociation))

export default SecurityFirmAssociationContainer