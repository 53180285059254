import React, { Component } from 'react';
import UserContainer from '../../containers/UserContainer'

export interface ISecurityFirmUser {
    securityFirmId: number;
    userId: number;
}

export default class SecurityFirmUser extends Component<ISecurityFirmUser, any> {
    render() {
        return (
            <UserContainer securityFirmId={this.props.securityFirmId} userId={this.props.userId} />
        );
    }
}