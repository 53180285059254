import update from "immutability-helper";
import { USER_RESET } from '../actions/userLogin';
import {
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST,
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_FAILURE,
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_REFRESH,
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_SUCCESS,
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST,
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_FAILURE,
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_REFRESH,
    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_SUCCESS
} from "../actions/userComplianceDocument";

const initialState = {
    expiring: {
        isLoading: false,
        filter: { includeAssociatedSecurityFirms: true },
        userComplianceDocuments: [],
        paging: { pageSize: 0, page: 1 }
    },
    missing: {
        isLoading: false,
        filter: { includeAssociatedSecurityFirms: true },
        userComplianceDocuments: [],
        paging: { pageSize: 0, page: 1 }
    }
};

function loadingExpiring(state, isLoading) {
    let newState = update(state, {
        expiring: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setExpiring(state, userComplianceDocuments, filter, paging) {
    let newState = update(state, {
        expiring: {
            userComplianceDocuments: { $set: userComplianceDocuments },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingMissing(state, isLoading) {
    let newState = update(state, {
        missing: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setMissing(state, userComplianceDocuments, filter, paging) {
    let newState = update(state, {
        missing: {
            userComplianceDocuments: { $set: userComplianceDocuments },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}


export default function venue(state = initialState, action) {
    switch (action.type) {
        case USER_RESET:
            return { ...initialState };

        case USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST:
            return setExpiring(loadingExpiring(state, true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_SUCCESS:
            return setExpiring(loadingExpiring(state, false), action.userComplianceDocuments, action.filter, action.paging);
        case USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST_FAILURE:
            return loadingExpiring(state, false);

        case USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST:
            return setMissing(loadingMissing(state, true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_SUCCESS:
            return setMissing(loadingMissing(state, false), action.userComplianceDocuments, action.filter, action.paging);
        case USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST_FAILURE:
            return loadingMissing(state, false);
        
    }

    return state;
}