import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const securityFirmServiceApi = {
  find: (venueId: number, securityFirmId: number, page, pageSize, filter: AusComplyDtos.SecurityFirmFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminSecurityFirms();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.pageSize = pageSize;
    query.page = page;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId: number, securityFirmId: number, forSecurityFirmId: number, includeOptions: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirm();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.includeOptions = includeOptions;
    const request = client.get(query);
    return from(request);
  },
  availableToRequestUserRoles: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmsAvailableForUserRoleRequest();
    const request = client.get(query);
    return from(request);  
  },
  createNew: () => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetNewSecurityFirm();
    const request = client.get(query);
    return from(request);
  },
  saveNew: (
    securityFirm: AusComplyDtos.SecurityFirm, 
    managerContact: AusComplyDtos.SecurityFirmContact,
    primaryContact: AusComplyDtos.SecurityFirmContact,
    otherDocuments: AusComplyDtos.File[],
    complianceDocuments: AusComplyDtos.File[]) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostNewSecurityFirm();
      query.securityFirm = securityFirm;
      query.managerContact = managerContact;
      query.primaryContact = primaryContact;
      query.complianceDocuments = complianceDocuments;
      query.otherDocuments = otherDocuments;
      const request = client.post(query);
      return from(request);
  },
  save:(venueId: number, securityFirmId: number, securityFirm: AusComplyDtos.SecurityFirm) => {
      var client = new AusComplyJsonServiceClient();
      var query = new AusComplyDtos.PostSecurityFirm();
      query.venueId = venueId;
      query.securityFirmId = securityFirmId;
      query.securityFirm = securityFirm;
      const request = client.post(query);
      return from(request);
  },
  getDocuments: (venueId: number, securityFirmId: number, forSecurityFirmId: number, showObsoleteDocuments: boolean) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetSecurityFirmDocuments();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.showObsoleteDocuments = showObsoleteDocuments;
    const request = client.get(query);
    return from(request);
  },
  saveOtherDocuments: (venueId: number, securityFirmId: number, forSecurityFirmId: number, documentTypeId: number, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmOtherDocument();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.documentTypeId = documentTypeId;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeOtherDocuments: (venueId: number, securityFirmId: number, forSecurityFirmId: number, document: AusComplyDtos.SecurityFirmOtherDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmOtherDocumentRemove();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.securityFirmDocumentId = document.securityFirmDocumentId;
    query.securityFirmOtherDocumentId = document.securityFirmOtherDocumentId;
    const request = client.post(query);
    return from(request);
  },
  saveComplianceDocuments: (venueId: number, securityFirmId: number, forSecurityFirmId: number, documentTypeId: number, visibleToVenue: boolean, expiryDate: AusComplyDtos.SimpleDate, files: AusComplyDtos.File[]) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmComplianceDocument();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.documentTypeId = documentTypeId;
    query.visibleToVenue = visibleToVenue;
    query.expiryDate = expiryDate;
    query.files = files;
    const request = client.post(query);
    return from(request);
  },
  removeComplianceDocuments: (venueId: number, securityFirmId: number, forSecurityFirmId: number, document: AusComplyDtos.SecurityFirmComplianceDocument) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmComplianceDocumentRemove();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.securityFirmDocumentId = document.securityFirmDocumentId;
    query.securityFirmComplianceDocumentId = document.securityFirmComplianceDocumentId;
    const request = client.post(query);
    return from(request);
  },
  getSecurityFirmVenueAssociations: (venueId: number, securityFirmId: number, forSecurityFirmId: number, page: number, pageSize: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmVenueSummary();
    query.venueId = venueId; 
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  export: (venueId: number, securityFirmId: number, filter: AusComplyDtos.SecurityFirmFilter) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.AnySecurityFirmsExport();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.filter = filter;
    const request = client.post(query);
    return from(request);
  },
  obsolete: (venueId: number, securityFirmId: number, securityFirmToRemoveId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostSecurityFirmObsolete();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.securityFirmToRemoveId = securityFirmToRemoveId;
    const request = client.post(query);
    return from(request);
  },
  delete: (venueId: number, securityFirmId: number, securityFirmToDeleteId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.DeleteSecurityFirm();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.securityFirmToDeleteId = securityFirmToDeleteId;
    const request = client.delete(query);
    return from(request);
  },
  adminDismissRegistration: (venueId: number, securityFirmId: number, forSecurityFirmId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostAdminSecurityFirmDismiss();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forSecurityFirmId = forSecurityFirmId;
    const request = client.post(query);
    return from(request);
  },
};
