import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { imageProcessingServiceApi } from '../services/imageProcessing';
import * as imageProcessingActions from "../actions/imageProcessing";
import { notifyError, notifyErrorMessage } from './common';
import * as notificationActions from "../actions/notification";

const imageProcessingHistoricalProcessingRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(imageProcessingActions.IMAGE_PROCESSING_VENUE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            imageProcessingServiceApi.postHistoricalProcessing(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['fromDate'])
                .pipe(
                    map(response => imageProcessingActions.imageProcessingVenueRequestSuccess(response.count)),
                    catchError(error => notifyError(error, "imageProcessingHistoricalProcessingRequestEpic.postHistoricalProcessing", imageProcessingActions.imageProcessingVenueRequestFailure,))
                )
        ),
        catchError(error => notifyError(error, "imageProcessingHistoricalProcessingRequestEpic", imageProcessingActions.imageProcessingVenueRequestFailure))
    );

const imageProcessingHistoricalProcessingRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(imageProcessingActions.IMAGE_PROCESSING_VENUE_REQUEST_SUCCESS),
        map((action:any) => notificationActions.notificationSuccessShow(action["count"].toString() + " Images queued for scanning"))
    );

const imageProcessingTemporaryFileRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            imageProcessingServiceApi.postTemporaryFile(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['filePath'])
                .pipe(
                    map(response => imageProcessingActions.imageProcessingTemporaryFileRequestSuccess(response.imageText, response.filePath)),
                    catchError(error => notifyError(error, "imageProcessingTemporaryFileRequestEpic.postTemporaryFile", imageProcessingActions.imageProcessingTemporaryFileRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "imageProcessingTemporaryFileRequestEpic", imageProcessingActions.imageProcessingTemporaryFileRequestFailure))
    );

const imageProcessingIncidentDocumentRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(imageProcessingActions.IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            imageProcessingServiceApi.postIncidentDocument(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentDocumentId'],
                action['saveToDocument'])
                .pipe(
                    map(response => imageProcessingActions.imageProcessingIncidentDocumentRequestSuccess(response.imageText, response.incidentDocumentId, response.savedToDocument)),
                    catchError(error => notifyError(error, "imageProcessingIncidentDocumentRequestEpic.postIncidentDocument", imageProcessingActions.imageProcessingIncidentDocumentRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "imageProcessingIncidentDocumentRequestEpic", imageProcessingActions.imageProcessingIncidentDocumentRequestFailure))
    );

const imageProcessingIncidentPatronPhotoRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(imageProcessingActions.IMAGE_PROCESSING_INCIDENT_PATRON_IMAGE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            imageProcessingServiceApi.postIncidentPatronPhoto(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentPatronPhotoId'],
                action['saveToDocument'])
                .pipe(
                    map(response => imageProcessingActions.imageProcessingIncidentPatronPhotoRequestSuccess(response.imageText, response.incidentPatronPhotoId, response.savedToDocument)),
                    catchError(error => notifyError(error, "imageProcessingIncidentPatronPhotoRequestEpic.postIncidentPatronPhoto", imageProcessingActions.imageProcessingIncidentPatronPhotoRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "imageProcessingIncidentPatronPhotoRequestEpic", imageProcessingActions.imageProcessingIncidentPatronPhotoRequestFailure))
    );

export const imageProcessingEpics = combineEpics(
    imageProcessingHistoricalProcessingRequestSuccessEpic,
    imageProcessingHistoricalProcessingRequestEpic,
    imageProcessingTemporaryFileRequestEpic,
    imageProcessingIncidentDocumentRequestEpic,
    imageProcessingIncidentPatronPhotoRequestEpic
);