import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TransparentButton from '../controls/TransparentButton';
import PrimaryButton from '../controls/PrimaryButton';
import ClearButton from '../controls/ClearButton';


export interface ITabsProps {
    classes: any;
    theme: any;
    selected: number;
    labels: string[];
    onClick?: Function;
}


class Tabs extends React.PureComponent<ITabsProps, any> {
    constructor(props: ITabsProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(index: number) {
        if (this.props.onClick) {
            this.props.onClick(index);
        }
    }

    render() {
        const { classes, theme } = this.props;

        return (
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" style={{
                position: 'relative',
                //backgroundColor: theme.custom.colors.controlBackgroundDark, 
                //border: '1px solid', 
                //borderColor: theme.custom.colors.controlBorder, 
                //borderRadius: '24px', marginBottom: theme.spacing(1)
            }} >
                {this.props.labels.map((item, index) =>
                    <Box key={"tab-click-" + index} p={0} flexGrow={1} style={{ height: '100%', maxWidth: '140px' }}>
                        {(false && index === this.props.selected) && (
                            <PrimaryButton text={item} onClick={() => this.onClick(index)} style={{ borderColor: 'transparent', width: '100%', height: '100%', borderRadius: '24px' }}></PrimaryButton>
                        )}
                        {(false && index !== this.props.selected) && (
                            <TransparentButton text={item} onClick={() => this.onClick(index)}
                                style={{ borderColor: 'transparent', width: '100%', height: '100%', textTransform: 'uppercase', color: '#fff' }}>

                            </TransparentButton>
                        )}
                        <ClearButton onClick={() => this.onClick(index)}
                            style={{ borderColor: 'transparent', width: '100%', height: '100%', textTransform: 'uppercase', color: '#fff', padding: '10px 3px 10px 0' }}>
                            <Box flex={1} display="flex" flexDirection="column" p={0}>
                                <Box p={0} style={{ 
                                        backgroundColor: index !== this.props.selected ? theme.custom.colors.controlBorder : theme.custom.colors.primary
                                        , height: '10px'
                                        , borderRadius: '5px' }}>
                                </Box>
                                <Box p={0}>
                                    <p style={{
                                        textAlign: 'center',
                                        padding: 0,
                                        margin: 0,
                                        textTransform: 'uppercase',
                                        fontSize: '9px',
                                        marginTop: '3px',
                                        color: index !== this.props.selected ? theme.custom.colors.controlBorder : theme.custom.colors.primary
                                    }}>{item}</p>
                                </Box>
                            </Box>
                        </ClearButton>
                    </Box>
                )}
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Tabs);