import * as AusComplyDtos from "../dto/AusComply.dtos";
import { makePost } from './common';

export const facialRecognitionPOIServiceApi = {
  createRegistration: makePost(
    AusComplyDtos.PostFacialRecognitionPOIRegistration,
    'venueId', 'securityFirmId', 'incidentId'
  ),
  createVenueRegistration: makePost(
    AusComplyDtos.PostFacialRecognitionPOIVenueRegistration,
    'venueId', 'securityFirmId', 'forVenueId'
  ),
  createGroupRegistration: makePost(
    AusComplyDtos.PostFacialRecognitionPOIGroupRegistration,
    'venueId', 'securityFirmId', 'forGroupId'
  ),
  scanFaces: makePost(
    AusComplyDtos.PostFacialRecognitionPOIRegistrationScanFaces,
    'venueId', 'securityFirmId', 'poiRegistration'
  ),
  searchFace: makePost(
    AusComplyDtos.PostFacialRecognitionPOIRegistrationSearchFace,
    'venueId', 'securityFirmId', 'poiRegistration'
  ),
  save: makePost(
    AusComplyDtos.PostFacialRecognitionPOIRegistrationSave,
    'venueId', 'securityFirmId', 'poiRegistration'
  ),
  poiIncidents: makePost(
    AusComplyDtos.PostFacialRecognitionPOIIncidents,
    'venueId', 'securityFirmId', 'facialRecognitionPOIIncidentId'
  ),
  poisIncidents: makePost(
    AusComplyDtos.PostFacialRecognitionPOIGuids,
    'venueId', 'securityFirmId', 'forVenueId', 'poiGuids'
  ),
  generateAppearance: makePost(
    AusComplyDtos.PacialRecognitionPOIIncidentGenerateAppearance,
    'venueId', 'securityFirmId', 'poiGuid', 'facialRecognitionWatchlistId', 'facialRecognitionCameraId', 'facialRecognitionEventId'
  ),
  search: makePost(
    AusComplyDtos.PostFacialRecognitionPOIScan,
    'venueId', 'securityFirmId', 'incidentId', 'poiScan'
  ),
  expiryFailures: makePost(
    AusComplyDtos.PostFacialRecognitionExpiryFailed,
    'venueId', 'securityFirmId', 'page', 'pageSize', 'filter'
  ),
  expiryRetry: makePost(
    AusComplyDtos.PostFacialRecognitionExpiryRetry,
    'venueId', 'securityFirmId', 'filter', 'facialRecognitionPOIIncidentId'
  ),
  expiryDismiss: makePost(
    AusComplyDtos.PostFacialRecognitionExpiryDismiss,
    'venueId', 'securityFirmId', 'facialRecognitionPOIIncidentId'
  )
};
