import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import ResponsiveList from '../common/ResponsiveList';
import FullPageDialog from '../layout/FullPageDialog';
import PrimaryButton from '../controls/PrimaryButton';
import TextControlGroup from '../controls/TextControlGroup';
import ControlGroup from '../controls/ControlGroup';
import SelectControlGroup from '../controls/SelectControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import TextDisplayControlGroup from '../controls/TextDisplayControlGroup';
import { KeyName } from '../../common/dto/common';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import Box from '@material-ui/core/Box';
import ClearButton from '../controls/ClearButton';
import CardContent from '@material-ui/core/CardContent';
import CardContainer from '../common/CardContainer';
import VenueIcon from '../../resources/VenueIcon';
import FolderOpenIcon from '../../resources/FolderOpenIcon';
import FolderClosedIcon from '../../resources/FolderClosedIcon';
import EditIcon from '../../resources/EditIcon';
import { Palette } from './../../common/constants/palette';
import { appSource } from '../../utilities/constants';
import SimpleDateControlGroup from '../controls/SimpleDateControlGroup';
import Media from "react-media";
import FileUpload from '../common/FileUpload';
import Thumbnail from '../common/Thumbnail';
import ImagePreview from '../common/ImagePreview';
import ConfirmDialog from '../controls/ConfirmDialog';
import PopupModal from '../layout/PopupModal';
import DirectoryShowEmptyToggle from './DirectoryShowEmptyToggle';


export interface IDirectoryProps {
    entityType: AusComplyDtos.ngtEntityType;
    id: number;
    tabs?: any;
    title?: string;
    readOnly?: boolean;
    complianceDocumentsOnly?: boolean;
    showObsolete: boolean;
    directory?: AusComplyDtos.Directory;
    isLoading: boolean;
    isSaving: boolean;
    onLoad: Function;
    onUpload: Function;
    onUpdate: Function;
    onRemove: Function;
    onUpsertFolder: Function;
    canViewObsolete?: boolean;
    history: any;
    location: any;
    classes: any;
}

interface IDirectoryState {
    uniqueId: string;
    show: boolean;
    addFolders: AusComplyDtos.Folder[];
    addFolder?: AusComplyDtos.Folder;
    addExpiresDate: AusComplyDtos.SimpleDate;
    addFiles: AusComplyDtos.File[];
    filePath: string;
    visibleToVenue: boolean;
    documentToMove: AusComplyDtos.Document;
    documentToRemove: AusComplyDtos.Document;
    parentFolders: AusComplyDtos.Folder[];
    upsertFolder: AusComplyDtos.Folder;
    selectAddOption: boolean;
    showEmptyFolders: boolean;
}

class Directory extends Component<IDirectoryProps, IDirectoryState> {
    constructor(props: IDirectoryProps) {
        super(props)
        let upsertFolder = new AusComplyDtos.Folder();
        upsertFolder.folderId = -1;
        upsertFolder.name = "";
        this.state = {
            uniqueId: "",
            show: false,
            addFolders: [],
            addFolder: undefined,
            addExpiresDate: new AusComplyDtos.SimpleDate(),
            addFiles: [],
            filePath: "",
            visibleToVenue: false,
            documentToMove: new AusComplyDtos.Document,
            documentToRemove: new AusComplyDtos.Document,
            upsertFolder,
            parentFolders: [],
            selectAddOption: false,
            showEmptyFolders: false // Move to shared state?
        };
        this.onCommand = this.onCommand.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.add = this.add.bind(this);
        this.onSave = this.onSave.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.removeTemporary = this.removeTemporary.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.removeDocumentConfirmed = this.removeDocumentConfirmed.bind(this);
        this.removeDocumentCancel = this.removeDocumentCancel.bind(this);
        this.onAddPopupCommand = this.onAddPopupCommand.bind(this);
        this.cancelFolderUpsert = this.cancelFolderUpsert.bind(this);
        this.onUpsertFolderValueChanged = this.onUpsertFolderValueChanged.bind(this);
        this.onSaveFolder = this.onSaveFolder.bind(this);
        this.editFolder = this.editFolder.bind(this);
        this.onMoveDocument = this.onMoveDocument.bind(this);
        this.cancelDocumentMove = this.cancelDocumentMove.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.entityType, this.props.id, this.props.showObsolete);
    }

    componentDidUpdate(prevProps: Readonly<IDirectoryProps>, prevState: Readonly<IDirectoryState>, snapshot?: any): void {
        if (!this.props.isLoading && this.props.directory && (this.props.entityType !== this.props.directory.entityType || this.props.id !== this.props.directory.id)) {
            this.props.onLoad(this.props.entityType, this.props.id)
        }
    }

    getCommands(document: AusComplyDtos.Document) {
        var commands: KeyName[] = [];
        if (document.fileDisplayPath && document.fileDisplayPath.length > 0) {
            commands.push({ key: "download", name: "Download" });
        }
        if (!document.locked && !document.obsolete && !this.props.readOnly) {
            commands.push({ key: "edit", name: "Edit/Move" });
            commands.push({ key: "remove", name: "Remove" });
        }
        return commands;
    }

    onCommand(command: string, document: AusComplyDtos.Document) {
        switch (command) {
            case "download":
                let base = appSource.getBaseImagesPath();
                if (base === '/') {
                    base = "";
                }
                let downloadPath = base + document.fileDisplayPath;
                this.downloadFile(downloadPath);
                break;
            case "edit":
                let addFolders: AusComplyDtos.Folder[] = []
                if (this.props.directory && this.props.directory.allFolders) {
                    addFolders = this.props.directory.allFolders.filter(f => !f.locked)
                }
                let addFolder = addFolders.find(f => f.folderId == Number(document.folderId))
                this.setState({ documentToMove: document, addFolders, addFolder });
                break;
            case "remove":
                this.setState({ documentToRemove: document });
                break;
            default:
                break;
        }
    }

    onAddPopupCommand(command: string) {
        switch (command) {
            case "add_folder":
                let parentFolders: AusComplyDtos.Folder[] = []
                if (this.props.directory && this.props.directory.allFolders) {
                    parentFolders = this.props.directory.allFolders.filter(f => f.canAddSubFolders)
                }
                let upsertFolder = new AusComplyDtos.Folder();
                upsertFolder.folderId = 0;
                upsertFolder.name = "";
                let parentFolder = parentFolders.find(f => f.uniqueId == this.state.uniqueId);
                if (parentFolder) {
                    upsertFolder.parentFolderId = parentFolder.folderId;
                }
                this.setState({
                    upsertFolder,
                    //parentFolder,
                    parentFolders,
                    selectAddOption: false,
                });
                break;
            case "add_document":
                this.setState({
                    selectAddOption: false,
                });
                this.add();
                break;
            default:
                this.setState({
                    selectAddOption: false,
                });
                break;
        }
    }

    cancelFolderUpsert() {
        let upsertFolder = new AusComplyDtos.Folder();
        upsertFolder.folderId = -1;
        upsertFolder.name = "";
        this.setState({ upsertFolder });
    }

    removeDocumentCancel() {
        this.setState({
            documentToRemove: new AusComplyDtos.Document()
        });
    }

    removeDocumentConfirmed() {
        this.props.onRemove({ ...this.state.documentToRemove });
        this.setState({
            documentToRemove: new AusComplyDtos.Document()
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    downloadFile(path) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                const blobUrl = window.URL.createObjectURL(xmlHttp.response);
                const e = document.createElement('a');
                e.href = blobUrl;
                e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
                document.body.appendChild(e);
                e.click();
                document.body.removeChild(e);
            }
        };
        xmlHttp.responseType = 'blob';
        xmlHttp.open('GET', path, true);
        xmlHttp.send(null);
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {

        let addFiles = [...this.state.addFiles, ...temporaryFiles];
        this.setState({ addFiles });
    }

    removeTemporary(index: number) {
        let addFiles = [...this.state.addFiles];
        addFiles.splice(index, 1);
        this.setState({ addFiles });
    }

    onSelectFilePath(filePath) {
        this.setState({
            filePath: filePath
        });
    }

    onUpsertFolderValueChanged(fieldName: string, value: any) {
        let upsertFolder = { ...this.state.upsertFolder };
        upsertFolder[fieldName] = value;
        this.setState({ upsertFolder });
    }

    onSaveFolder() {
        this.props.onUpsertFolder(this.props.entityType, this.props.id, { ...this.state.upsertFolder });
        this.cancelFolderUpsert();
    }

    add() {
        // reset the add details
        let addFolders: AusComplyDtos.Folder[] = []
        if (this.props.directory && this.props.directory.allFolders) {
            addFolders = this.props.directory.allFolders.filter(f => !f.locked)
        }
        let addExpiresDate = new AusComplyDtos.SimpleDate();
        addExpiresDate.isEmpty = true;
        let addFiles: AusComplyDtos.File[] = [];
        let addFolder = addFolders.find(f => f.uniqueId == this.state.uniqueId && !f.locked);

        this.setState({
            show: true,
            addFolder,
            addFolders,
            addExpiresDate,
            addFiles,
            visibleToVenue: false
        });
    }

    onSave() {
        if (this.state.addFolder) {
            let documentUpload: AusComplyDtos.DocumentUpload = new AusComplyDtos.DocumentUpload();
            documentUpload.entityType = this.props.entityType;
            documentUpload.id = this.props.id;
            documentUpload.files = [...this.state.addFiles];
            documentUpload.documentTypeId = this.state.addFolder.folderId;
            if (this.state.addFolder.compliance) {
                documentUpload.expires = { ...this.state.addExpiresDate };
            }
            documentUpload.visibleToVenue = this.state.visibleToVenue;
            this.props.onUpload(documentUpload);
            this.setState({ show: false });
        }
    }

    editFolder(folder: AusComplyDtos.Folder) {
        let parentFolders: AusComplyDtos.Folder[] = []
        if (this.props.directory && this.props.directory.allFolders) {
            // Add the 
            parentFolders = this.props.directory.allFolders.filter(f => f.canAddSubFolders && (f.folderId < 0 || !f.path.startsWith(folder.path)))
        }
        let upsertFolder = {...folder};
        if (upsertFolder && upsertFolder.parentFolderId == 0) {
            upsertFolder.parentFolderId = upsertFolder.compliance ? -1 : -2;
        }
        this.setState({
            upsertFolder,
            parentFolders,
        });
    }

    renderSubTree(folders: AusComplyDtos.Folder[], level: number) {
        let self = this;
        return <>
            {folders.filter(f => 
            (
                this.state.showEmptyFolders || (!this.state.showEmptyFolders && f.countIncludingSubFolders > 0)
            ) 
            &&
            (   !this.props.complianceDocumentsOnly || 
                (this.props.complianceDocumentsOnly && f.compliance)
            )
            ).map(folder => <>
                <Box
                    key={"folder-tree-" + folder.folderId}
                    display="flex"
                    flexDirection="row"
                    style={{ marginLeft: (level * 20).toString() + "px", marginTop: '5px', color: this.state.uniqueId === folder.uniqueId ? "#FFF" : "#888" }}
                     >
                    <Box p={0} onClick={() => self.setState({ uniqueId: folder.uniqueId })}>
                        <div style={{ height: '20px', width: '20px', display: 'block' }}>
                            {this.state.uniqueId == folder.uniqueId ? (<FolderOpenIcon fill={folder.expiredCount > 0 ? "red" : this.state.uniqueId === folder.uniqueId ? "#FFF" : "#888"} />) : (<FolderClosedIcon fill={folder.expiredCount > 0 ? "red" : folder.expiredCount > 0 ? "red" : "#ccc"} />)}
                        </div>
                    </Box>
                    <Box p={0} flex={1} onClick={() => self.setState({ uniqueId: folder.uniqueId })}>
                        <span style={{ color: folder.canEdit ? "#FFF" : "#888"}}>&nbsp;{folder.name} {folder.count > 0 ? "(" + folder.count + ")" : ""}</span>
                    </Box>
                    <Box p={0}>
                        {(folder.canEdit && !this.props.readOnly) && (
                            <ClearButton style={{padding: '2px', minWidth: 0, height: '20px', width: '20px'}} onClick={() => this.editFolder(folder)}>
                                    <EditIcon fill={"#888"} />
                            </ClearButton>
                        )}
                    </Box>
                </Box>
                {this.renderSubTree(folder.folders, level + 1)}
            </>)}
        </>
    }

    onMoveDocument() {
        this.props.onUpdate({ ...this.state.documentToMove });
        this.setState({
            documentToMove: new AusComplyDtos.Document()
        });
    }

    cancelDocumentMove() {
        this.setState({
            documentToMove: new AusComplyDtos.Document()
        });
    }

    render() {
        const { classes } = this.props;
        let self = this;
        let subFolders: any[] = [];
        if (this.props.directory && this.props.directory.allFolders) {
            if (this.state.uniqueId === "") {
                subFolders = this.props.directory.allFolders.filter(f => f.folderId < 0);
            } else {
                let folder = this.props.directory.allFolders.find(f => f.uniqueId === this.state.uniqueId);
                if (folder) {
                    subFolders = folder.folders;
                }
            }
        }

        let documents: AusComplyDtos.Document[] = [];
        if (this.props.directory && this.props.directory.documents) {
            if (this.state.uniqueId === "") {
                // all
                documents = this.props.directory.documents;
            } else {
                documents = this.props.directory.documents.filter(f => f.folderUniqueId == this.state.uniqueId);
            }
        }

        let list = <ResponsiveList
            loading={this.props.isLoading}
            data={documents.filter(f => !this.props.complianceDocumentsOnly || (this.props.complianceDocumentsOnly && f.folder.compliance))}
            headers={["Preview", "Name", "Folder", "Expiry", ""]}
            columns={["filePreviewPath", "originalFileName", "folder.path", "expires.display", "visibleToVenue"]}
            getCommands={item => this.getCommands(item)}
            onCommand={this.onCommand}
            isDanger={item => !item.obsolete && item.expired}
            isWarning={item => item.obsolete}
            canEdit={false}
            toggleDanger={false}
            isDangerLabel={"Expired"}
            isWarningLabel={"Obsolete"}
            legendExtra={
                <Box p={1} >
                    <Box display="flex" flexDirection="row" >
                        <Box p={0}>
                            <div style={{ height: '10px', width: "10px", marginRight: '6px' }}><VenueIcon fill={Palette.LegendText} /></div>
                        </Box>
                        <Box p={0} flex={1}>
                            <div className={classes.colorSwatchText} >Visible to Venues</div>
                        </Box>
                    </Box>
                </Box>
            }
            onFormatValue={(column, value, data) => {
                if (column == "visibleToVenue") {
                    //debugger;
                    return value ? <div style={{ height: '20px' }}><VenueIcon fill={Palette.Text} /></div> : "";
                }
                return value;
            }}

            filter={<>
                <SelectControlGroup
                    text="Select Folder"
                    labelAbove={true}
                    onChange={(value) => self.setState({ uniqueId: value })}
                    defaultValue={this.state.uniqueId}>
                    <option key={""} value={""}>(all)</option>
                    {(this.props.directory && this.props.directory.allFolders) && this.props.directory.allFolders.map((item, index) => (
                        <option key={item.uniqueId} value={item.uniqueId}>{item.path}</option>
                    ))}
                </SelectControlGroup>
                { this.props.canViewObsolete && <CheckboxControlGroup
                                text={"Show Obsolete"}
                                labelAbove={true}
                                label={" "}
                                defaultValue={this.props.showObsolete}
                                onChanged={(value) => self.props.onLoad(this.props.entityType, this.props.id, value)} /> }
            </>}
        />;
        /*
        aboveListContent={
                <>
                    {subFolders.map(subFolder => <p
                        key={"folder-selector-" + subFolder.folderId}
                        style={{ color: Palette.Primary }}
                        onClick={() => self.setState({ uniqueId: subFolder.uniqueId })} >/{subFolder.name}</p>)}
                </>
            }
        */
        let addFolderId: number = 0;
        let missingExpiry: boolean = false;
        if (this.state.addFolder) {
            addFolderId = this.state.addFolder.folderId;
            missingExpiry = this.state.addFolder.compliance && this.state.addExpiresDate.isEmpty;
        }

        let parentFolderId: number = 0;
        if (this.state.upsertFolder) {
            parentFolderId = this.state.upsertFolder.compliance ? -1 : -2;
        }
        if (this.state.upsertFolder && this.state.upsertFolder.parentFolderId != 0) {
            parentFolderId = this.state.upsertFolder.parentFolderId;
        }

        let files = this.state.addFiles.map((item, index) => {
            return (
                <Box p={0} key={"file-compliance-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-compliance-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        onPreview={path => this.onSelectFilePath(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporary(index)}
                    />
                </Box >
            );
        })
        return <PageLayout
            headerText={this.props.title ? this.props.title : this.props.entityType + " Documents"}
            loading={this.props.isLoading}
            saving={this.props.isSaving}
            footerLeftContent={this.props.readOnly ? <></> : <TransparentButton
                text={"Add"}
                onClick={() => self.setState({ selectAddOption: true })}
            />}
        >
            {this.props.tabs}
            <Media query="(max-width: 1279px)">
                {matches =>
                    matches ? (
                        <Box p={0} display="flex" flexDirection={'row'}>

                            <Box flexGrow={1} p={1}>
                                {list}
                            </Box>
                        </Box>
                    ) : (
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box p={1}>
                                <div style={{ minWidth: '260px' }}>
                                    <CardContainer title={"Folders"}>
                                        <CardContent>
                                            <DirectoryShowEmptyToggle show={this.state.showEmptyFolders} onChange={(value => { this.setState({showEmptyFolders: value})})} />
                                            {(this.props.directory && this.props.directory.folders) && this.renderSubTree(this.props.directory.folders, 0)}
                                            <Box
                                                key={"folder-tree-all"}
                                                display="flex"
                                                flexDirection="row"
                                                style={{ color: this.state.uniqueId === "" ? "#FFF" : "#888", marginTop: '20px' }}
                                                onClick={() => self.setState({ uniqueId: "" })} >
                                                <Box p={0} flex={1}>
                                                    <span>&nbsp;(All documents)</span>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </CardContainer>
                                </div>
                            </Box>
                            <Box flexGrow={1} p={1}>
                                {list}
                            </Box>
                        </Box>
                    )
                }
            </Media>
            <FullPageDialog
                open={this.state.show}
                notFullWidth={true}
                title="Add Document"
                footerRightContent={<PrimaryButton text="Upload" onClick={this.onSave} disabled={missingExpiry || !this.state.addFolder || this.state.addFiles.length === 0} />}
                onDismissed={() => this.setState({ show: false })}>
                <SelectControlGroup
                    text="Folder"
                    onChange={(value) => self.setState({ addFolder: self.state.addFolders.find(f => f.folderId == Number(value)) })}
                    defaultValue={addFolderId}>
                    <option key={0} value={0}>Select...</option>
                    {this.state.addFolders.map((item, index) => (
                        <option key={"add-folder-" + item.folderId} value={item.folderId}>{item.path}</option>
                    ))}
                </SelectControlGroup>
                {(this.state.addFolder && this.state.addFolder.compliance) && (
                    <>
                        <SimpleDateControlGroup text="Expiry Date"
                            date={this.state.addExpiresDate}
                            onChange={(value) => self.setState({ addExpiresDate: value })} />
                        {this.props.entityType === AusComplyDtos.ngtEntityType.SecurityFirm && (
                            <CheckboxControlGroup
                                text={"Make Visible to Associated Venues"}
                                label={" "}
                                defaultValue={this.state.visibleToVenue}
                                onChanged={(value) => self.setState({ visibleToVenue: value })} />
                        )}
                    </>
                )}
                <ControlGroup text=" ">
                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                        {files}
                    </Box>
                    <FileUpload onFileWasUploaded={this.fileWasUploaded} alternateIcons={true} hasFiles={this.state.addFiles.length > 0} />
                </ControlGroup>

            </FullPageDialog>
            <FullPageDialog
                open={this.state.upsertFolder.folderId > -1}
                notFullWidth={true}
                title={this.state.upsertFolder.folderId == 0 ? "Add Folder" : "Update Folder"}
                footerRightContent={<PrimaryButton text="Save" onClick={() => self.onSaveFolder()} disabled={this.state.upsertFolder.parentFolderId == 0 || this.state.upsertFolder.name.length == 0} />}
                onDismissed={() => this.cancelFolderUpsert()}>
                <SelectControlGroup
                    text="Parent Folder"
                    onChange={(value) => self.onUpsertFolderValueChanged("parentFolderId", Number(value))}
                    defaultValue={parentFolderId}>
                    <option key={0} value={0}>Select...</option>
                    {this.state.parentFolders.map((item, index) => (
                        <option key={"parent-folder-" + item.folderId} value={item.folderId}>{item.path}</option>
                    ))}
                </SelectControlGroup>
                <TextControlGroup
                    text={"Name"}
                    defaultValue={this.state.upsertFolder.name}
                    onChange={(value) => self.onUpsertFolderValueChanged("name", value)}
                />
                {this.state.upsertFolder.folderId > 0 && (<>
                    <CheckboxControlGroup
                                text={"Active"}
                                label={" "}
                                isReversed={true}
                                readonly={!this.state.upsertFolder.canDelete}
                                defaultValue={this.state.upsertFolder.obsolete}
                                onChanged={(value) => self.onUpsertFolderValueChanged("obsolete", value)} />
                </>)}
            </FullPageDialog>
            <FullPageDialog
                open={this.state.documentToMove.documentId > 0}
                notFullWidth={true}
                title={"Edit document"}
                footerRightContent={<PrimaryButton text="Save" onClick={() => self.onMoveDocument()} disabled={this.state.documentToMove.folderId == 0} />}
                onDismissed={() => this.cancelDocumentMove()}>
                    <TextDisplayControlGroup text={"Name"} value={this.state.documentToMove.originalFileName} />
                    <ControlGroup text={"Preview"}>
                        <Box p={0} key={"file-preview-move"}>
                            <Thumbnail
                                key={"file-compliance-" + this.state.documentToMove.obsolete}
                                previewPath={this.state.documentToMove.filePreviewPath}
                                displayPath={this.state.documentToMove.fileDisplayPath}
                                isImage={this.state.documentToMove.isImage}
                                text={this.state.documentToMove.filePreviewText}
                                onPreview={path => this.onSelectFilePath(path)}
                                onDownload={path => this.onDownload(path)}
                            />
                        </Box >
                    </ControlGroup>
                    {(this.state.addFolder && this.state.addFolder.compliance) && (
                        <>
                            <SimpleDateControlGroup text="Expiry Date"
                                date={this.state.documentToMove.expires}
                                onChange={(value) => self.setState({documentToMove: {...self.state.documentToMove, expires: value} })} />
                            {this.props.entityType === AusComplyDtos.ngtEntityType.SecurityFirm && (
                                <CheckboxControlGroup
                                    text={"Make Visible to Associated Venues"}
                                    label={" "}
                                    defaultValue={this.state.documentToMove.visibleToVenue}
                                    onChanged={(value) => self.setState({documentToMove: {...self.state.documentToMove, visibleToVenue: value}})} />
                            )}
                        </>
                    )}
                <SelectControlGroup
                    text="Folder"
                    onChange={(value) => self.setState({documentToMove: {...self.state.documentToMove, folderId: Number(value)}, addFolder: self.state.addFolders.find(f => f.folderId == Number(value))})}
                    defaultValue={self.state.documentToMove.folderId}>
                    <option key={0} value={0}>Select...</option>
                    {this.state.addFolders.map((item, index) => (
                        <option key={"add-folder-" + item.folderId} value={item.folderId}>{item.path}</option>
                    ))}
                </SelectControlGroup>
            </FullPageDialog>
            <PopupModal open={this.state.selectAddOption} onAction={action => this.onAddPopupCommand(action)} actions={[{ key: "add_document", name: "Add Document" }, { key: "add_folder", name: "Add Folder" }]} />
            <ConfirmDialog
                title={"Confirm document removal"}
                text={"Are you sure you wish to remove '" + (this.state.documentToRemove.folder ? this.state.documentToRemove.folder.name : "") + "' document '" + this.state.documentToRemove.originalFileName + "'"}
                show={this.state.documentToRemove.documentId > 0}
                onCancel={this.removeDocumentCancel}
                onConfirm={this.removeDocumentConfirmed} />
            <ImagePreview filePath={this.state.filePath} onDismissed={() => this.onSelectFilePath("")} />
        </PageLayout>
    }
}
export default withStyles(styles, { withTheme: true })(Directory);