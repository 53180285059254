import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TextControl from './TextControl';

export interface INumericUpDownProps {
    classes: any;
    theme: any;
    value: number;
    disabled?: boolean;
    editable?: boolean;
    onChange?: Function;
}

class NumericUpDown extends React.PureComponent<INumericUpDownProps, any> {
    constructor(props: INumericUpDownProps) {
        super(props)
        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onUp() {
        if (this.props.onChange) {
            this.props.onChange(this.props.value + 1);
        }
    }

    onDown() {
        if (this.props.onChange && this.props.value > 0) {
            this.props.onChange(this.props.value - 1);
        }
    }

    onChange(value) {
        //if (event != null) {
            let val = value;
            if (isNaN(val)) {
                if (this.props.onChange){
                    this.props.onChange(0);
                }
            } else {
                let intValue = parseInt(val);
                if (this.props.onChange){
                    this.props.onChange(intValue);
                }
            }
        //}
    }

    render() {
        const { classes, theme } = this.props;
        let classNamesAdd = classes.linearOutlineColorPrimary;
        let classNamesRemove = classes.linearOutlineColorError;
        if (this.props.disabled) {
            classNamesAdd = classes.linearOutlineColorDisabled;
            classNamesRemove = classes.linearOutlineColorDisabled;
        }
        let value = "";
        if (this.props.value){
            value = this.props.value.toString();
        }

        return (
            <div style={{
                padding: '2px 4px',
                display: 'block',
                textAlign: 'center',
                width: '100%'
            }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{ display: 'inline-flex' }}
                    onClick={this.onDown}
                    disabled={this.props.disabled}
                    className={classNamesRemove}
                ><RemoveIcon /></Button>
                {(!this.props.editable || this.props.disabled) && (
                    <p style={{ display: 'inline-block', margin: '0', minWidth: '60px', fontSize: '24px', color: theme.palette.text.primary }}>{this.props.value}</p>
                )}
                {(this.props.editable && !this.props.disabled) && (
                    <TextControl
                        value={value}
                        onChange={this.onChange}
                        readonly={false}
                        inputType={"number"}
                        style={{width: '100px',margin: '0 10px', textAlign: 'center'}}
                    />
                )}
                <Button
                    variant="outlined"
                    color="default"
                    style={{ display: 'inline-flex' }}
                    onClick={this.onUp}
                    disabled={this.props.disabled}
                    className={classNamesAdd}
                ><AddIcon /></Button>

            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(NumericUpDown);