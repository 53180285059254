import update from "immutability-helper";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { checklistLogic } from '../../common/logic/checklistLogic';
import moment from "moment";
import * as checklistActions from "../actions/checklist";

const initialState = {
  checklistTemplateCategoriesLoading: false,
  checklistLoading: false,
  checklistLoadSuccessful: true,
  checklistSaving: false,
  checklistModified: false,
  checklistSaveSuccessful: false,
  checklist: {
    checklistItems: []
  },
  checklistDisplayItems: [],
  checklistDisplayTemplate: [],
  checklistfilter: {
    byReportingPeriod: true,
    reportingPeriodId: 0
  },
  checklistItemAttachmentSavingCount: 0,
  checklistsLoading: false,
  checklists: [],
  checklistsPageSize: 10,
  checklistsPage: 0,
  checklistsPaging: {},
  checklistsSortBy: "dateEntered",
  checklistsSortDirection: "DESC",
  selectedChecklistIds: [],
  checklistSearch: {
    searchText: "",
    checklistStatuses: [],
    startDate: moment().subtract(6, "months"),
    endDate: moment()
  },
  isUploading: false,
  checkListIsReadonly: false,
  checkListHasErrors: false
};

function updateChecklist(state, checklist) {
  let newState = update(state, {
    checklist: { $set: checklist }
  });
  return loadingChecklist(newState, false);
}

function resetCheckList(state) {
  let newState = update(state, {
    checklist: {
      $set: {
        checklistItems: []
      }
    }
  });
  return newState;
}

function resetDisplayItems(state) {
  let newState = update(state, {
    checklistDisplayItems: { $set: [] },
    checklistDisplayTemplate: { $set: {} }
  });
  return newState;
}

function setModified(state, isModified) {
  let newState = update(state, {
    checklistModified: { $set: isModified }
  });
  return newState;

}

function setDisplayItems(state, checklist, template) {
  let checklistDisplayItems = checklistLogic.buildDisplayItems(checklist, template);
  let newState = update(state, {
    checklistDisplayItems: { $set: checklistDisplayItems },
    checklistDisplayTemplate: { $set: { ...template } }
  });
  return newState;
}

function setDisplayTemplate(state, template) {
  let newState = update(state, {
    checklistDisplayTemplate: { $set: { ...template } }
  });
  return newState;
}

function setCheckListStatus(state) {
  let checkListIsReadonly = checklistLogic.isReadonly(state.checklist.checklistStatus);
  if (state.checklist.checklist && state.checklist.checklist.obsolete) {
    checkListIsReadonly = true;
  }
  let checkListHasErrors = false;
  if (state.checklistDisplayItems) {
    state.checklistDisplayItems.forEach((item, index) => {
      if (item.isRequired) {
        checkListHasErrors = true;
      }
    });
  }
  let newState = update(state, {
    checkListIsReadonly: { $set: checkListIsReadonly },
    checkListHasErrors: { $set: checkListHasErrors }
  });
  return newState;
}

function updateDisplayItems(state, checklist) {
  let checklistDisplayItems = checklistLogic.buildDisplayItems(checklist, state.checklistDisplayTemplate);
  let newState = update(state, {
    checklistDisplayItems: { $set: checklistDisplayItems }
  });
  return newState;
}

function resetFilter(state) {
  let checklistfilter = new AusComplyDtos.ChecklistFilter();
  checklistfilter.byReportingPeriod = true;
  checklistfilter.reportingPeriodId = 0;
  let newState = update(state, {
    checklistfilter: { $set: checklistfilter }
  });
  return newState;
}

function updateFilter(state, checklistfilter) {
  let newState = update(state, {
    checklistfilter: { $set: checklistfilter }
  });
  return newState;
}

function updateChecklistAttachmentsOnly(state, checklist, attachmentsChanged) {
  if (!attachmentsChanged) {
    return loadingChecklist(state, false);
  }
  let currentchecklist = { ...state.checklist };
  if (currentchecklist.checklistId != checklist.checklistId) {
    return updateChecklist(state, checklist);
  }
  if (currentchecklist.checklistItems) {
    currentchecklist.checklistItems.forEach((item, index) => {
      let checklistItem = checklist.checklistItems.find(f => f.checklistTemplateItemId == item.checklistTemplateItemId);
      if (checklistItem) {
        item.attachments = [...checklistItem.attachments];
        item.files = [...checklistItem.files];
      };
    });

    let checklistDisplayItems = checklistLogic.buildDisplayItems(currentchecklist, state.checklistDisplayTemplate);
    let newState = update(state, {
      checklist: { $set: currentchecklist },
      checklistDisplayItems: { $set: checklistDisplayItems }
    });
    return loadingChecklist(newState, false);
  }
  return loadingChecklist(state, false);
}

function submitChecklist(state) {
  let checklist = { ...state.checklist };
  checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.Submitted;
  let newState = update(state, {
    checklist: { $set: checklist }
  });
  return newState;
}

function saveChecklistFailed(state) {
  let checklist = { ...state.checklist };
  if (checklist.checklistStatus == AusComplyDtos.ChecklistStatuses.Submitted) {
    checklist.checklistStatus = AusComplyDtos.ChecklistStatuses.InProgress;
  }
  let newState = update(state, {
    checklist: { $set: checklist }
  });
  return newState;
}

function loadingChecklist(state, isLoading) {
  let newState = update(state, {
    checklistLoading: { $set: isLoading }
  });

  if (isLoading) {
    newState = update(newState, {
      checklistLoadSuccessful: { $set: false }
    });
  }
  return newState;
}

function checklistTemplateLoaded(state, successful) {
  let newState = update(state, {
    checklistLoadSuccessful: { $set: successful }
  });
  return loadingChecklist(newState, false);
}

function savingChecklist(state, isSaving) {
  let newState = update(state, {
    checklistSaving: { $set: isSaving }
  });
  return newState;
}

function checklistSaved(state, successful) {
  let newState = update(state, {
    checklistSaveSuccessful: { $set: successful }
  });
  return savingChecklist(newState, false);
}

function updateChecklists(state, checklists) {
  let newState = update(state, {
    checklists: { $set: checklists }
  });
  return loadingChecklists(newState, false);
}

function loadingChecklists(state, isLoading) {
  let newState = update(state, {
    checklistsLoading: { $set: isLoading }
  });
  return newState;
}

function updateChecklistsPageSize(state, pageSize) {
  let newState = update(state, {
    checklistsPageSize: { $set: pageSize }
  });
  return newState;
}

function updateChecklistsPage(state, page) {
  let newState = update(state, {
    checklistsPage: { $set: page }
  });
  return newState;
}

function updateChecklistsPaging(state, paging) {
  if (!paging) {
    return state;
  }
  let newState = update(state, {
    checklistsPageSize: { $set: paging.pageSize },
    checklistsPage: { $set: paging.page },
    checklistsPaging: { $set: paging }
  });
  return newState;
}

function toggleChecklistSelected(state, checklistId) {
  let idx = state.selectedChecklistIds.indexOf(checklistId);

  if (idx === -1) {
    let newState = update(state, {
      selectedChecklistIds: { $push: [checklistId] }
    });
    return newState;
  } else {
    let newState = update(state, {
      selectedChecklistIds: { $splice: [[idx, 1]] }
    });
    return newState;
  }
}

function clearChecklistSelection(state) {
  let newState = update(state, {
    selectedChecklistIds: { $set: [] }
  });
  return newState;
}

function updateChecklistSortParameters(state, sortBy, sortDirection) {
  let newState = update(state, {
    checklistsSortBy: { $set: sortBy },
    checklistsSortDirection: { $set: sortDirection }
  });
  return newState;
}

function savingChecklistItemAttachment(
  state,
  checklistTemplateItemId,
  file,
  isSaving
) {
  let idx = state.checklist.checklistItems.findIndex(
    checklistItem =>
      checklistItem.checklistTemplateItemId === checklistTemplateItemId
  );

  if (idx >= 0) {
    if (isSaving) {
      // temporarily track the file
      let newState = update(state, {
        checklist: {
          checklistItems: {
            [idx]: {
              $apply: item => {
                return update(item, {
                  attachmentsToSave: {
                    $apply: attachmentsToSave =>
                      update(attachmentsToSave || [], {
                        $push: [file]
                      })
                  }
                });
              }
            }
          }
        }
      });
      return newState;
    } else {
      // remove the temp copy of the file
      // find the file index
      let fileIdx = state.checklist.checklistItems[
        idx
      ].attachmentsToSave.findIndex(
        attachmentToSave => attachmentToSave.name === file.name
      );

      if (fileIdx >= 0) {
        let newState = update(state, {
          checklist: {
            checklistItems: {
              [idx]: {
                attachmentsToSave: { $splice: [[fileIdx, 1]] }
              }
            }
          }
        });
        return newState;
      }
    }
  }

  return state;

  // let newState = update(state, {
  //   checklistItemAttachmentSavingCount: {
  //     $apply: function(x) {
  //       return isSaving ? x + 1 : x - 1;
  //     }
  //   }
  // });
  // return newState;
}

function checklistItemAttachmentSaved(
  state,
  checklistTemplateItemId,
  file,
  successful
) {
  // let newState = update(state, {
  //   checklistSaveSuccessful: { $set: successful }
  // });
  return savingChecklistItemAttachment(
    state,
    checklistTemplateItemId,
    file,
    false
  );
}

function deleteChecklistItemAttachment(
  state,
  checklistTemplateItemId,
  attachmentId
) {
  let idx = state.checklist.checklistItems.findIndex(
    checklistItem =>
      checklistItem.checklistTemplateItemId === checklistTemplateItemId
  );

  if (idx >= 0) {
    // get the attachment idx
    let attachmentIdx = state.checklist.checklistItems[
      idx
    ].attachments.findIndex(
      attachment => attachment.attachmentId === attachmentId
    );

    if (attachmentIdx >= 0) {
      let newState = update(state, {
        checklist: {
          checklistItems: {
            [idx]: {
              attachments: { $splice: [[attachmentIdx, 1]] }
            }
          }
        }
      });

      return newState;
    }
  }

  return state;
}

function updateChecklistItemAttachment(
  state,
  checklistTemplateItemId,
  attachment
) {
  let idx = state.checklist.checklistItems.findIndex(
    checklistItem =>
      checklistItem.checklistTemplateItemId === checklistTemplateItemId
  );

  if (idx >= 0) {
    let newState = update(state, {
      checklist: {
        checklistItems: {
          [idx]: {
            $apply: item => {
              return update(item, {
                attachments: {
                  $apply: attachments =>
                    update(attachments || [], {
                      $push: [attachment]
                    })
                }
              });
            }
          }
        }
      }
    });
    return newState;
  }
  return state;
}

function uploading(state, isUploading) {
  let newState = update(state, {
    isUploading: { $set: isUploading }
  });
  return newState;
}

function filesUploaded(state, temporaryFiles: AusComplyDtos.File[], checklistTemplateItemId) {
  let checklist = { ...state.checklist };
  checklist.checklistItems.forEach((item, index) => {
    if (item.checklistTemplateItemId == checklistTemplateItemId) {
      temporaryFiles.forEach(file => {
        if (!checklist.checklistItems[index].files) {
          checklist.checklistItems[index].files = [];
        }
        checklist.checklistItems[index].files.push(file);
      });
    }
  });
  checklist.checklistItems = [...checklist.checklistItems];
  let checklistDisplayItems = checklistLogic.buildDisplayItems(checklist, state.checklistDisplayTemplate);
  let newState = update(state, {
    checklist: { $set: checklist },
    checklistDisplayItems: { $set: checklistDisplayItems }
  });
  return newState;
}

export default function checklist(state = initialState, action) {
  switch (action.type) {
    case checklistActions.CHECKLIST_RESET:
      return { ...initialState };
    case checklistActions.CHECKLIST_FILTER_RESET:
      return resetFilter(state);
    case checklistActions.CHECKLIST_FILTER_SET:
      return updateFilter(state, action.filter);
    case checklistActions.UPDATE_CHECKLIST:
      return setModified(setCheckListStatus(updateDisplayItems(updateChecklist(state, action.checklist), action.checklist)), true);
    case checklistActions.SUBMIT_CHECKLIST:
      return submitChecklist(state);
      
    case checklistActions.SAVE_CHECKLIST_REQUEST:
      return savingChecklist(state, true);
    case checklistActions.SAVE_CHECKLIST_SUCCESS:
      return setCheckListStatus(setModified(checklistSaved(updateChecklistAttachmentsOnly(state, action.checklist, action.attachmentsChanged), true), false));
    case checklistActions.SAVE_CHECKLIST_FAILURE:
      return checklistSaved(saveChecklistFailed(state), false);

    case checklistActions.CREATE_CHECKLIST_REQUEST:
      return loadingChecklist(resetDisplayItems(resetCheckList(setModified(uploading(savingChecklist(state, false), false), false))), true);
    case checklistActions.CREATE_CHECKLIST_SUCCESS:
      return checklistTemplateLoaded(
        setCheckListStatus(setDisplayItems(updateChecklist(state, action.checklist), action.checklist, action.checklistTemplate)), 
        true)
        ;
    case checklistActions.CREATE_CHECKLIST_FAILURE:
      return checklistTemplateLoaded(loadingChecklist(state, true), false);

    case checklistActions.LOAD_CHECKLIST_REQUEST:
      return loadingChecklist(resetDisplayItems(resetCheckList(setModified(uploading(savingChecklist(state, false), false), false))), true);
    case checklistActions.LOAD_NEW_CHECKLIST_REQUEST:
      return loadingChecklist(resetDisplayItems(uploading(savingChecklist(state, false), false)), true);
    case checklistActions.LOAD_NEW_CHECKLIST_REQUEST_SUCCESS:
      return setModified(setDisplayTemplate(state, action.checklistTemplate), true);
    case checklistActions.SET_NEW_CHECKLIST:
      return updateChecklist(state, action.checklist);
    case checklistActions.LOAD_CHECKLIST_SUCCESS:
      return checklistTemplateLoaded(
        setCheckListStatus(setDisplayItems(updateChecklist(state, action.checklist), action.checklist, action.checklistTemplate)),
        true
      );
    case checklistActions.LOAD_CHECKLIST_FAILURE:
      return checklistTemplateLoaded(loadingChecklist(state, true), false);

    case checklistActions.LOAD_CHECKLISTS_REQUEST:
      return loadingChecklists(state, true);
    case checklistActions.LOAD_CHECKLISTS_SUCCESS:
      return updateChecklists(updateChecklistsPaging(updateFilter(state, action.filter), action.paging), action.checklists);
    case checklistActions.LOAD_CHECKLISTS_FAILURE:
      return loadingChecklists(updateChecklists(updateChecklistsPaging(state, { page: 1, pageSize: state.checklistsPageSize, total: 0, pages: 0 }), []), false);

    case checklistActions.TOGGLE_CHECKLIST_SELECTED:
      return toggleChecklistSelected(state, action.checklistId);
    case checklistActions.CLEAR_CHECKLIST_SELECTION:
      return clearChecklistSelection(state);
    case checklistActions.SET_CHECKLISTS_PAGE:
      return updateChecklistsPage(state, action.page);
    case checklistActions.SET_CHECKLISTS_PAGE_SIZE:
      return updateChecklistsPageSize(state, action.pageSize);
    case checklistActions.SET_CHECKLISTS_SORT_PARAMETERS:
      return updateChecklistSortParameters(
        state,
        action.sortBy,
        action.sortDirection
      );

    case checklistActions.SAVE_CHECKLIST_ITEM_ATTACHMENT_SUCCESS:
      return checklistItemAttachmentSaved(
        updateChecklistItemAttachment(
          state,
          action.checklistTemplateItemId,
          action.attachment
        ),
        action.checklistTemplateItemId,
        action.file,
        true
      );
    case checklistActions.SAVE_CHECKLIST_ITEM_ATTACHMENT_REQUEST:
      return savingChecklistItemAttachment(
        state,
        action.checklistTemplateItemId,
        action.file,
        true
      );
    case checklistActions.SAVE_CHECKLIST_ITEM_ATTACHMENT_FAILURE:
      return checklistItemAttachmentSaved(
        state,
        action.checklistTemplateItemId,
        action.file,
        false
      );
    case checklistActions.DELETE_CHECKLIST_ITEM_ATTACHMENT_REQUEST:
      return deleteChecklistItemAttachment(
        state,
        action.checklistTemplateItemId,
        action.attachmentId
      );
    case checklistActions.CHECKLIST_ITEM_UPLOAD_REQUEST:
      return uploading(state, true);
    case checklistActions.CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS:
      return setCheckListStatus(setModified(filesUploaded(uploading(state, false), action.temporaryFiles, action.checklistTemplateItemId), true));
    case checklistActions.CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE:
      return uploading(state, false);
  }
  return state;
}
