import React, { FunctionComponent, ReactNode, useState } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import { useSwipeable } from "react-swipeable";

const SwipeContainer: FunctionComponent<{
    children: ReactNode,
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    onSwipedDown?: Function;
    delta?: number;
}> = (props) => {

    const [ignore, setIgnoring] = useState(false);

    const handlers = useSwipeable({
        onSwipeStart: (eventData) => {
            // @ts-ignore
            if (eventData.event && eventData.event.srcElement) {
                // @ts-ignore
                const element = eventData.event.srcElement;
                //console.log("Start Swipe", eventData, element.localName);
                if (element && (element.localName == "input" || element.localName == "textarea")) {
                    if (!ignore) {
                        setIgnoring(true);
                    }
                } else {
                    if (ignore) {
                        setIgnoring(false);
                    }
                }
            }
        },
        onSwipedLeft: (eventData) => {
            //console.log("Left Swipe", eventData, props.delta);
            const delta = eventData.deltaX;
            if ((-1 * delta) < (props.delta || 200)) {
                return;
            }
            if (props.onSwipedLeft && !ignore) {
                props.onSwipedLeft();
            }
        },
        onSwipedRight: (eventData) => {
            //console.log("Right Swipe", eventData, props.delta);
            const delta = eventData.deltaX;
            if (delta < (props.delta || 200)) {
                return;
            }
            if (props.onSwipedRight && !ignore) {
                props.onSwipedRight();
            }
        },
        onSwipedDown: (eventData) => {
            //console.log("Down Swipe", eventData);
            const delta = eventData.deltaY;
            if (delta < (props.delta || 200)) {
                return;
            }
            if (props.onSwipedDown && !ignore) {
                props.onSwipedDown();
            }
        },
        onSwiped: () => {
            setIgnoring(false);
        },
        delta: 0,
        preventScrollOnSwipe: false,
        trackTouch: true,                      // track touch input
        trackMouse: true,                     // track mouse input
        rotationAngle: 0,                      // set a rotation angle
        swipeDuration: Infinity,
        touchEventOptions: { passive: true },
    });

    return (
        <div {...handlers}>
            {props.children}
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(SwipeContainer));