import { connect } from 'react-redux'
import UserRoles from '../components/users/UserRoles'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { withRouter } from "react-router";
import { 
    userRolesRequest, 
    userRolesFilterSet, 
    userRolesReset,
    userRoleEnableRequest,
    userRolesExportRequest
 } from '../common/actions/userRole';
import { loadUserRoleTypesRequest } from '../common/actions/referenceData';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.userRole.userRoles.isLoading,
        userRoles: state.userRole.userRoles.userRoles,
        userRoleTypes: state.referenceData.userRoleTypes,
        canAdminEdit: permissionsLogic.isLevelSix(state),
        groupId: props.groupId,
        venueId: props.venueId,
        securityFirmId: props.securityFirmId,
        paging: state.userRole.userRoles.paging,
        filter: state.userRole.userRoles.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReset:() => {
            dispatch(userRolesReset())
        },
        onRefresh:(filter, page, pageSize) => {
            dispatch(userRolesRequest(filter, page, pageSize))
        },
        onFilterUpdate:(filter) => {
            dispatch(userRolesFilterSet(filter))
        },
        onUserRoleEnableRequest: (userRoleId) => {
            dispatch(userRoleEnableRequest(userRoleId))
        },
        onExport: () => {
            dispatch(userRolesExportRequest())
        },
        onLoadReferenceData:(forVenueId?: number, forSecurityFirmId?: number) => {
            dispatch(loadUserRoleTypesRequest(forVenueId, forSecurityFirmId));
        }
    }
}

const UserRolesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRoles))

export default UserRolesContainer