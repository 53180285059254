import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ResponsiveList from '../../common/ResponsiveList';
import FabContainer from '../../layout/FabContainer';
import FullPageDialog from '../../layout/FullPageDialog';
import DefaultFab from '../../common/DefaultFab';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import Error from '../../common/Error';
import TextControlGroup from '../../controls/TextControlGroup';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import FormWarningMessage from '../../alerts/FormWarningMessage';
import { KeyName } from '../../../common/dto/common';
import Information from '../../common/Information';
import FacialRecognitionServerCloneContainer from '../../../containers/FacialRecognitionServerCloneContainer';
import { TimerSharp } from '@material-ui/icons';
import SubTitle from '../../common/SubTitle';
import View from '../../common/View';
import ControlGroup from '../../controls/ControlGroup';
import PageLayout from '../../layout/PageLayout';
import VenueTabs from '../../admin/venue/VenueTabs';
import TransparentButton from '../../controls/TransparentButton';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';


export interface IFacialRecognitionServersProps {
    venueId: number;
    adminMode?: boolean;
    facialRecognitionServers: AusComplyDtos.FacialRecognitionServer[];
    facialRecognitionServer: AusComplyDtos.FacialRecognitionServer;
    facialRecognitionServerId: number;
    isLoading: boolean;
    isLoadingItem: boolean;
    isSaving: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onCancel: Function;
    onTest: Function;
    history: any;
    location: any;
}

interface IFacialRecognitionServersState {
    cloneFacialRecognitionServerId: number;
}

export default class FacialRecognitionServers extends Component<IFacialRecognitionServersProps, IFacialRecognitionServersState> {
    constructor(props: IFacialRecognitionServersProps) {
        super(props)
        this.state = {
            cloneFacialRecognitionServerId: 0
        };
        this.onCommand = this.onCommand.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionServer = { ...this.props.facialRecognitionServer };
        facialRecognitionServer[fieldName] = value;
        this.props.onUpdate(facialRecognitionServer)
    }

    getCommands(facialRecognitionServer: AusComplyDtos.FacialRecognitionServer) {
        var commands: KeyName[] = [];
        if (facialRecognitionServer.venueId != this.props.venueId && !facialRecognitionServer.obsolete) {
            commands.push({ key: "test", name: "Test" });
        } else if (!facialRecognitionServer.obsolete) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });
            commands.push({ key: "test", name: "Test" });
            if (facialRecognitionServer.venueId > 0) {
                commands.push({ key: "clone", name: "Clone" });
            }
        } else if (facialRecognitionServer.venueId == this.props.venueId) {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionServer: AusComplyDtos.FacialRecognitionServer) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(facialRecognitionServer.facialRecognitionServerId);
                break;
            case "restore":
                var updated = { ...facialRecognitionServer };
                updated.obsolete = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...facialRecognitionServer };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "test":
                this.props.onTest(facialRecognitionServer.facialRecognitionServerId);
                break;
            case "clone":
                this.setState({ cloneFacialRecognitionServerId: facialRecognitionServer.facialRecognitionServerId });
                break;
            default:
                break;
        }

    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }

    render() {
        let self = this;
        let facialRecognitionServer;
        if (this.props.facialRecognitionServerId != -1) {

            var obsolete = false;
            if (this.props.facialRecognitionServer && this.props.facialRecognitionServer.obsolete != undefined) {
                obsolete = this.props.facialRecognitionServer.obsolete;
            }

            let appearanceMode;
            if ((
                    this.props.facialRecognitionServer.apiVersion == "0.27.1" ||
                    this.props.facialRecognitionServer.apiVersion == "0.30.1" ||
                    this.props.facialRecognitionServer.apiVersion == "0.30.2"
                ) 
                &&(this.props.facialRecognitionServer.serverType === AusComplyDtos.ngtServerType.Edge)) {
                    appearanceMode = (<CheckboxControlGroup
                        label={" "}
                        text={"Appearance Mode"}
                        defaultValue={this.props.facialRecognitionServer.appearanceMode}
                        onChanged={(value) => this.onValueChanged("appearanceMode", value)} />);
            }

            facialRecognitionServer = (
                <LayoutForm
                    loading={this.props.isLoadingItem}
                    saving={this.props.isSaving}
                >
                    {this.props.facialRecognitionServer && (
                        <form>
                            <TextControlGroup
                                text={"Name"}
                                defaultValue={this.props.facialRecognitionServer.name}
                                onBlur={(value) => this.onValueChanged("name", value)} />

                            <SelectControlGroup
                                text="Type"
                                onChange={(value) => this.onValueChanged("serverType", value)}
                                defaultValue={this.props.facialRecognitionServer.serverType}>
                                {(this.props.facialRecognitionServer.serverType == AusComplyDtos.ngtServerType.OnPremise || this.props.venueId > 0) &&
                                    <option key={AusComplyDtos.ngtServerType.OnPremise} value={AusComplyDtos.ngtServerType.OnPremise}>On Premise</option>
                                }
                                <option key={AusComplyDtos.ngtServerType.Cloud} value={AusComplyDtos.ngtServerType.Cloud}>{AusComplyDtos.ngtServerType.Cloud}</option>
                                {(this.props.facialRecognitionServer.serverType == AusComplyDtos.ngtServerType.Edge || this.props.venueId > 0) &&
                                    <option key={AusComplyDtos.ngtServerType.Edge} value={AusComplyDtos.ngtServerType.Edge}>Edge</option>
                                }
                            </SelectControlGroup>

                            {this.props.facialRecognitionServer.serverType == AusComplyDtos.ngtServerType.Edge && (
                                <ControlGroup text={" "}>
                                    <Information style={{ marginTop: '10px' }} message={"All face appearances will be forwarded to the cloud server(s) and cameras will be automatically sync'd to the cloud server"} />
                                </ControlGroup>
                            )}

                            <SelectControlGroup
                                text="Api Compatible Version"
                                onChange={(value) => this.onValueChanged("apiVersion", value)}
                                defaultValue={this.props.facialRecognitionServer.apiVersion}>
                                <option key={"0.27.1"} value={"0.27.1"}>0.27.1</option>
                                <option key={"0.30.1"} value={"0.30.1"}>0.30.1</option>
                                <option key={"0.30.2"} value={"0.30.2"}>0.30.2</option>
                                <option key={"0.37.1"} value={"0.37.1"}>0.37.1</option>
                                <option key={"0.38.0"} value={"0.38.0"}>0.38.0</option>
                            </SelectControlGroup>
                            {appearanceMode}
                            <TextControlGroup
                                text={"Users Endpoint"}
                                defaultValue={this.props.facialRecognitionServer.endpointUsers}
                                onBlur={(value) => this.onValueChanged("endpointUsers", value)} />


                            {this.props.facialRecognitionServer.serverType == AusComplyDtos.ngtServerType.Cloud && (
                                <>
                                    <TextControlGroup
                                        text={"Websocket Username"}
                                        defaultValue={this.props.facialRecognitionServer.websocketUsername}
                                        onBlur={(value) => this.onValueChanged("websocketUsername", value)} />

                                    <PasswordControlGroup
                                        text={"Websocket Password"}
                                        defaultValue={this.props.facialRecognitionServer.websocketNewPassword}
                                        onChange={(value) => this.onValueChanged("websocketNewPassword", value)} />

                                    <TextControlGroup
                                        text={"Websocket Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointWebsocket}
                                        onBlur={(value) => this.onValueChanged("endpointWebsocket", value)} />
                                </>
                            )}

                            <CheckboxControlGroup
                                label={" "}
                                text={"Active"}
                                isReversed={true}
                                defaultValue={obsolete}
                                onChanged={(value) => this.onValueChanged("obsolete", value)} />

                            <CheckboxControlGroup
                                label={" "}
                                text={"Advanced Settings"}
                                defaultValue={this.props.facialRecognitionServer.advancedConfiguration}
                                onChanged={(value) => this.onValueChanged("advancedConfiguration", value)} />

                            {this.props.facialRecognitionServer.advancedConfiguration && (
                                <View>


                                    <TextControlGroup
                                        text={"Username"}
                                        defaultValue={this.props.facialRecognitionServer.username}
                                        onBlur={(value) => this.onValueChanged("username", value)} />

                                    <PasswordControlGroup
                                        text={"Password"}
                                        defaultValue={this.props.facialRecognitionServer.newPassword}
                                        onChange={(value) => this.onValueChanged("newPassword", value)} />

                                    <TextControlGroup
                                        text={"POI Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointPOI}
                                        onBlur={(value) => this.onValueChanged("endpointPOI", value)} />
                                    <TextControlGroup
                                        text={"Cameras Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointCameras}
                                        onBlur={(value) => this.onValueChanged("endpointCameras", value)} />
                                    <TextControlGroup
                                        text={"History Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointHistory}
                                        onBlur={(value) => this.onValueChanged("endpointHistory", value)} />
                                    <TextControlGroup
                                        text={"Users Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointUsers}
                                        onBlur={(value) => this.onValueChanged("endpointUsers", value)} />
                                    <TextControlGroup
                                        text={"Controller Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointController}
                                        onBlur={(value) => this.onValueChanged("endpointController", value)} />
                                    <TextControlGroup
                                        text={"Frame Storage Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointFramesStorage}
                                        onBlur={(value) => this.onValueChanged("endpointFramesStorage", value)} />

                                    <TextControlGroup
                                        text={"Event Username"}
                                        defaultValue={this.props.facialRecognitionServer.eventUsername}
                                        onBlur={(value) => this.onValueChanged("eventUsername", value)} />

                                    <PasswordControlGroup
                                        text={"Event Password"}
                                        defaultValue={this.props.facialRecognitionServer.eventNewPassword}
                                        onChange={(value) => this.onValueChanged("eventNewPassword", value)} />
                                    <TextControlGroup
                                        text={"Events Endpoint"}
                                        defaultValue={this.props.facialRecognitionServer.endpointEvents}
                                        onBlur={(value) => this.onValueChanged("endpointEvents", value)} />
                                </View>
                            )}

                            {(this.props.facialRecognitionServer.linkedServerWarning) && (
                                <FormWarningMessage warning={this.props.facialRecognitionServer.linkedServerWarning} />
                            )}

                            {(!this.props.facialRecognitionServer.verified && this.props.facialRecognitionServer.verificationError) && <>
                                <Error message={this.props.facialRecognitionServer.verificationError} />
                            </>}


                        </form>
                    )}
                </LayoutForm >
            );
        }

        let createFab = (
            <DefaultFab onClick={() => self.props.onCreate(this.props.venueId)} isExtended={true}>Add Server</DefaultFab>
        );

        if (this.props.adminMode) {
            return (
                <PageLayout
                    headerText={"Venue"}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                    hideQuickActions={true}
                    footerRightContent={<PrimaryButton text={"Add Server"} onClick={() => self.props.onCreate(this.props.venueId)} />}
                >
                    <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
                    <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Servers"} history={this.props.history} />
                    <ResponsiveList
                        loading={this.props.isLoading || this.props.isSaving}
                        data={this.props.facialRecognitionServers}
                        headers={["Name", "Type", "Api Version", "User Endpoint"]}
                        columns={["name", "serverType", "apiVersionDisplay", "endpointUsers"]}
                        getCommands={item => this.getCommands(item)}
                        onCommand={(command, item) => this.onCommand(command, item)}
                        isObsolete={item => item.obsolete}
                        toggleObsolete={true}
                        isDanger={item => !item.obsolete && !item.verified && (item.verificationError || "").length > 0}
                        isDangerLabel={"Verification Error"}
                        isInfo={item => item.venueId != this.props.venueId}
                        isInfoLabel={"Global"}
                        // toggleDanger={true}
                        canEdit={true}
                    />
                    <FullPageDialog
                        open={this.props.facialRecognitionServerId != -1}
                        title="Server"
                        footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.props.onCancel()}></DefaultButton>}
                        footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>}
                        onDismissed={() => this.props.onResetItem()}>
                        {facialRecognitionServer}
                    </FullPageDialog>
                    <FacialRecognitionServerCloneContainer facialRecognitionServerId={this.state.cloneFacialRecognitionServerId} onDismissed={() => { self.setState({ cloneFacialRecognitionServerId: 0 }) }} />
                </PageLayout>
            );
        }

        return (
            <>
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionServers}
                    headers={["Name", "Type", "Api Version", "User Endpoint"]}
                    columns={["name", "serverType", "apiVersion", "endpointUsers"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isObsolete={item => item.obsolete}
                    toggleObsolete={true}
                    isDanger={item => !item.obsolete && !item.verified && (item.verificationError || "").length > 0}
                    isDangerLabel={"Verification Error"}
                    isInfo={item => item.venueId != this.props.venueId}
                    isInfoLabel={"Global"}
                    canEdit={true}
                />
                <FabContainer>
                    {createFab}
                </FabContainer>
                <FullPageDialog
                    open={this.props.facialRecognitionServerId != -1}
                    title="Server"
                    footerLeftContent={<DefaultButton text={"Cancel"} onClick={() => this.props.onCancel()}></DefaultButton>}
                    footerRightContent={<PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>}
                    onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionServer}
                </FullPageDialog>
                <FacialRecognitionServerCloneContainer facialRecognitionServerId={this.state.cloneFacialRecognitionServerId} onDismissed={() => { self.setState({ cloneFacialRecognitionServerId: 0 }) }} />
            </>
        );

    }
}