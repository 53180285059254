import { connect } from 'react-redux'
import { withRouter } from "react-router";
import VenueReportingPeriodSchedule from '../components/venue/reportingPeriodSchedule/VenueReportingPeriodSchedule'
import { 
    venueReportingPeriodScheduleReset,
    venueReportingPeriodScheduleSet,
    venueReportingPeriodScheduleUpsertRequest
 } from '../common/actions/venue';

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.venue.venueReportingPeriodSchedule.isLoading,
        isSaving: state.venue.venueReportingPeriodSchedule.isSaving,
        venueReportingPeriodSchedule: state.venue.venueReportingPeriodSchedule.venueReportingPeriodSchedule
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => {
            dispatch(venueReportingPeriodScheduleReset())
        },
        onUpdate: (venueReportingPeriodSchedule) => {
            dispatch(venueReportingPeriodScheduleSet(venueReportingPeriodSchedule))
        },
        onSave: () => {
            dispatch(venueReportingPeriodScheduleUpsertRequest())
        }
    }
}

const VenueReportingPeriodScheduleContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueReportingPeriodSchedule))

export default VenueReportingPeriodScheduleContainer