import { connect } from 'react-redux'
import SecurityFirmSubscription from '../components/securityFirm/securityFirmSubscription/SecurityFirmSubscription'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import {
    securityFirmSubscriptionSummaryRequest
} from '../common/actions/securityFirm'

const mapStateToProps = state => {
    return {
        securityFirmId:  state.user.details.userSession.user.securityFirmId,
        isLoading: state.securityFirm.securityFirmSubscriptionSummary.isLoading,
        error: state.securityFirm.securityFirmSubscriptionSummary.error,
        canAdminEdit: permissionsLogic.isLevelSix(state),
        securityFirmSubscriptionSummary: state.securityFirm.securityFirmSubscriptionSummary.securityFirmSubscriptionSummary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(securityFirmSubscriptionSummaryRequest())
        }
    }
}

const SecurityFirmSubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SecurityFirmSubscription)

export default SecurityFirmSubscriptionContainer