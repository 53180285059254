
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueServiceApi } from '../services/venueService';

import {
    VENUE_DOCUMENTS_REQUEST,
    venueDocumentsRequestSuccess,
    venueDocumentsRequestFailure,
    VENUE_OTHER_DOCUMENTS_SAVE,
    venueSaveOtherDocumentsSuccess,
    venueSaveOtherDocumentsFailure,
    VENUE_OTHER_DOCUMENT_REMOVE,
    venueRemoveOtherDocumentFailure,
    venueRemoveOtherDocumentSuccess,
    VENUE_COMPLIANCE_DOCUMENTS_SAVE,
    venueSaveComplianceDocumentsSuccess,
    venueSaveComplianceDocumentsFailure,
    VENUE_COMPLIANCE_DOCUMENT_REMOVE,
    venueRemoveComplianceDocumentFailure,
    venueRemoveComplianceDocumentSuccess
} from "../actions/venue";

import {
    notifyError, notifyErrorMessage
} from './common';

import { notificationShow, notificationSuccessShow } from "../actions/notification";

const venueDocumentsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_DOCUMENTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 
                action['showObsoleteDocuments']
            )
                .pipe(
                    map(response => venueDocumentsRequestSuccess(
                        response.venueOtherDocuments, 
                        response.venueOtherDocumentTypes, 
                        response.venueComplianceDocuments, 
                        response.venueComplianceDocumentTypes,
                        response.associatedSecurityComplianceDocuments,
                        response.associatedSecurityComplianceDocumentTypes)),
                    catchError(error => notifyError(error, "venueDocumentsRequestEpic.getDocuments", venueDocumentsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "venueDocumentsRequestEpic", venueDocumentsRequestFailure))
    );


const venueOtherDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_OTHER_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.saveOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 
                action['documentTypeId'], 
                action['files'])
                .pipe(
                    map(response => venueSaveOtherDocumentsSuccess(response.venueOtherDocuments)),
                    catchError(error => of(
                        venueSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save venue other documents")
                    ))
                )
        ),
        catchError(error => of(
            venueSaveOtherDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save venue other documents")
        ))
    );

const venueOtherDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_OTHER_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.removeOtherDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 
                action['otherDocument'])
                .pipe(
                    map(response => venueRemoveOtherDocumentSuccess(response.venueOtherDocuments)),
                    catchError(error => of(
                        venueRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove venue other document")
                    ))
                )
        ),
        catchError(error => of(
            venueRemoveOtherDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove venue other document")
        ))
    );

const venueComplianceDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_COMPLIANCE_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.saveComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 
                action['documentTypeId'], 
                action['expiryDate'], 
                action['files'])
                .pipe(
                    map(response => venueSaveComplianceDocumentsSuccess(response.venueComplianceDocuments)),
                    catchError(error => of(
                        venueSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to save venue compliance documents")
                    ))
                )
        ),
        catchError(error => of(
            venueSaveComplianceDocumentsFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to save venue compliance documents")
        ))
    );

const venueComplianceDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(VENUE_COMPLIANCE_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.removeComplianceDocuments(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'], 
                action['complianceDocument'])
                .pipe(
                    map(response => venueRemoveComplianceDocumentSuccess(response.venueComplianceDocuments)),
                    catchError(error => of(
                        venueRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
                        notificationShow("Failed to remove venue compliance document")
                    ))
                )
        ),
        catchError(error => of(
            venueRemoveComplianceDocumentFailure(error.responseStatus ? error.responseStatus.message : error.message),
            notificationShow("Failed to remove venue compliance document")
        ))
    );

export const venueDocumentEpics = combineEpics(
    venueDocumentsRequestEpic,
    venueOtherDocumentsSaveEpic,
    venueOtherDocumentRemoveEpic,
    venueComplianceDocumentsSaveEpic,
    venueComplianceDocumentRemoveEpic
);