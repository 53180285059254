import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import FileUpload from '../../common/FileUpload';
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SelectControlGroup from '../../controls/SelectControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import DefaultButton from '../../controls/DefaultButton';
import ControlGroup from '../../controls/ControlGroup';
import ConfirmDialog from '../../controls/ConfirmDialog';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import NoRowsMessage from '../../common/NoRowsMessage';
import PageLayout from '../../layout/PageLayout';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';

export interface ISecurityFirmDocumentsProps {
    securityFirmId: number;
    error: string;
    isLoading: boolean;
    isSaving: boolean;
    complianceDocuments: AusComplyDtos.SecurityFirmComplianceDocument[];
    complianceDocumentTypes: AusComplyDtos.DocumentType[];
    otherDocuments: AusComplyDtos.SecurityFirmOtherDocument[];
    otherDocumentTypes: AusComplyDtos.DocumentType[];
    onLoad: Function;
    onSaveCompliance: Function;
    onRemoveCompliance: Function;
    onSaveOther: Function;
    onRemoveOther: Function;
    showObsolete?: boolean;
    classes: any;
    theme: any;
}

interface ISecurityFirmDocumentsState {
    complianceSelectedFilePath: string;
    complianceFiles: AusComplyDtos.File[];
    complianceDocumentToRemove: AusComplyDtos.SecurityFirmComplianceDocument,
    complianceDocumentTypeId: number;
    complianceExpiryDate: AusComplyDtos.SimpleDate;
    complianceVisibleToVenues: boolean;
    otherSelectedFilePath: string;
    otherFiles: AusComplyDtos.File[];
    otherDocumentToRemove: AusComplyDtos.SecurityFirmOtherDocument,
    otherDocumentTypeId: number;
}

class SecurityFirmDocuments extends React.Component<ISecurityFirmDocumentsProps, ISecurityFirmDocumentsState> {
    constructor(props: ISecurityFirmDocumentsProps) {
        super(props)
        let complianceExpiryDate = new AusComplyDtos.SimpleDate();
        complianceExpiryDate.isEmpty = true;
        let complianceDocumentTypeId = 0
        if (this.props.complianceDocumentTypes && this.props.complianceDocumentTypes.length == 1) {
            complianceDocumentTypeId = this.props.complianceDocumentTypes[0].documentTypeId;
        }
        let otherDocumentTypeId = 0
        if (this.props.otherDocumentTypes && this.props.otherDocumentTypes.length == 1) {
            otherDocumentTypeId = this.props.otherDocumentTypes[0].documentTypeId;
        }
        this.state = {
            complianceSelectedFilePath: "",
            complianceFiles: [],
            complianceDocumentToRemove: new AusComplyDtos.SecurityFirmComplianceDocument(),
            complianceDocumentTypeId,
            complianceExpiryDate,
            complianceVisibleToVenues: false,
            otherSelectedFilePath: "",
            otherFiles: [],
            otherDocumentToRemove: new AusComplyDtos.SecurityFirmOtherDocument,
            otherDocumentTypeId
        };

        this.onDownload = this.onDownload.bind(this);
        this.onSelectFilePathCompliance = this.onSelectFilePathCompliance.bind(this);
        this.removeDocumentCompliance = this.removeDocumentCompliance.bind(this);
        this.fileWasUploadedCompliance = this.fileWasUploadedCompliance.bind(this);
        this.onDocumentTypeChangedCompliance = this.onDocumentTypeChangedCompliance.bind(this);
        this.onUploadCompliance = this.onUploadCompliance.bind(this);
        this.removeDocumentConfirmedCompliance = this.removeDocumentConfirmedCompliance.bind(this);
        this.removeDocumentCancelCompliance = this.removeDocumentCancelCompliance.bind(this);
        this.onExpiryDateChanged = this.onExpiryDateChanged.bind(this);
        this.onSelectFilePathOther = this.onSelectFilePathOther.bind(this);
        this.removeDocumentOther = this.removeDocumentOther.bind(this);
        this.fileWasUploadedOther = this.fileWasUploadedOther.bind(this);
        this.onDocumentTypeChangedOther = this.onDocumentTypeChangedOther.bind(this);
        this.onUploadOther = this.onUploadOther.bind(this);
        this.removeDocumentConfirmedOther = this.removeDocumentConfirmedOther.bind(this);
        this.removeDocumentCancelOther = this.removeDocumentCancelOther.bind(this);
        this.onVisibleToVenueChange = this.onVisibleToVenueChange.bind(this);
    }


    componentDidMount() {
        this.props.onLoad(this.props.securityFirmId);
    }

    componentDidUpdate(prevProps) {
        let complianceDocumentTypeId = this.state.complianceDocumentTypeId;
        if (this.props.complianceDocumentTypes &&
            this.props.complianceDocumentTypes.length === 1 &&
            this.state.complianceDocumentTypeId === 0) {
            complianceDocumentTypeId = this.props.complianceDocumentTypes[0].documentTypeId
        }

        let otherDocumentTypeId = this.state.otherDocumentTypeId;
        if (this.props.otherDocumentTypes &&
            this.props.otherDocumentTypes.length === 1 &&
            this.state.otherDocumentTypeId === 0) {
                ;
            otherDocumentTypeId = this.props.complianceDocumentTypes[0].documentTypeId;
        }
        if (complianceDocumentTypeId != this.state.complianceDocumentTypeId ||
            otherDocumentTypeId != this.state.otherDocumentTypeId) {
            this.setState({
                complianceDocumentTypeId,
                otherDocumentTypeId
            });
        }
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    onSelectFilePathCompliance(filePath) {
        this.setState({
            complianceSelectedFilePath: filePath
        });
    }

    removeDocumentCompliance(document: AusComplyDtos.SecurityFirmComplianceDocument) {
        this.setState({
            complianceDocumentToRemove: document
        });
    }

    removeDocumentCancelCompliance() {
        this.setState({
            complianceDocumentToRemove: new AusComplyDtos.SecurityFirmComplianceDocument()
        });
    }

    removeDocumentConfirmedCompliance() {
        this.props.onRemoveCompliance(this.props.securityFirmId, this.state.complianceDocumentToRemove);
        this.setState({
            complianceDocumentToRemove: new AusComplyDtos.SecurityFirmComplianceDocument()
        });
    }

    removeTemporaryCompliance(index: number) {
        let complianceFiles = [...this.state.complianceFiles];
        complianceFiles.splice(index, 1);
        this.setState({ complianceFiles });
    }

    fileWasUploadedCompliance(temporaryFiles: AusComplyDtos.File[]) {

        let complianceFiles = [...this.state.complianceFiles, ...temporaryFiles];
        this.setState({ complianceFiles });
    }

    onDocumentTypeChangedCompliance(value) {
        this.setState({ complianceDocumentTypeId: value });
    }

    onExpiryDateChanged(value: AusComplyDtos.SimpleDate) {
        this.setState({ complianceExpiryDate: value });
    }

    onUploadCompliance() {
        let files = [...this.state.complianceFiles];
        this.props.onSaveCompliance(this.props.securityFirmId, this.state.complianceDocumentTypeId, this.state.complianceVisibleToVenues, this.state.complianceExpiryDate, files);
        let complianceExpiryDate = new AusComplyDtos.SimpleDate();
        complianceExpiryDate.isEmpty = true;
        let complianceDocumentTypeId = 0
        if (this.props.complianceDocumentTypes && this.props.complianceDocumentTypes.length == 1) {
            complianceDocumentTypeId = this.props.complianceDocumentTypes[0].documentTypeId;
        }
        this.setState({
            complianceFiles: [],
            complianceDocumentTypeId,
            complianceExpiryDate,
        });
    }

    onSelectFilePathOther(filePath) {
        this.setState({
            otherSelectedFilePath: filePath
        });
    }

    removeDocumentOther(document: AusComplyDtos.SecurityFirmOtherDocument) {
        this.setState({
            otherDocumentToRemove: document
        });
    }

    removeDocumentCancelOther() {
        this.setState({
            otherDocumentToRemove: new AusComplyDtos.SecurityFirmOtherDocument()
        });
    }

    removeDocumentConfirmedOther() {
        this.props.onRemoveOther(this.props.securityFirmId, this.state.otherDocumentToRemove);
        this.setState({
            otherDocumentToRemove: new AusComplyDtos.SecurityFirmOtherDocument()
        });
    }

    removeTemporaryOther(index: number) {
        let otherFiles = [...this.state.otherFiles];
        otherFiles.splice(index, 1);
        this.setState({ otherFiles });
    }

    fileWasUploadedOther(temporaryFiles: AusComplyDtos.File[]) {

        let otherFiles = [...this.state.otherFiles, ...temporaryFiles];
        this.setState({ otherFiles });
    }

    onDocumentTypeChangedOther(value) {
        this.setState({ otherDocumentTypeId: value });
    }

    onUploadOther() {
        let files = [...this.state.otherFiles];
        this.props.onSaveOther(this.props.securityFirmId, this.state.otherDocumentTypeId, files);
        let otherDocumentTypeId = 0
        if (this.props.otherDocumentTypes && this.props.otherDocumentTypes.length == 1) {
            otherDocumentTypeId = this.props.otherDocumentTypes[0].documentTypeId;
        }
        this.setState({
            otherFiles: [],
            otherDocumentTypeId
        });
    }

    onVisibleToVenueChange(value) {
        this.setState({
            complianceVisibleToVenues: value
        });
    }

    render() {
        const { classes, theme } = this.props;

        let documents;
        let hasDocuments = false;
        if (this.props.complianceDocuments) {
            documents = this.props.complianceDocuments.filter(item => !item.obsolete || this.props.showObsolete).map((item, index) => {
                hasDocuments = true;
                let borderColor = item.hasExpired ? theme.custom.colors.error : "#151515";
                if (item.obsolete) {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={"file-compliance-poi-item-" + item.originalFileName + "_" + index.toString()}>
                            <div key={"file-compliance-poi-" + item.originalFileName + "_" + index.toString()}
                                style={{
                                    backgroundColor: '#151515',
                                    borderRadius: '5px',
                                    borderColor: theme.custom.colors.warning,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    margin: '0 10px 10px 0',
                                    padding: '10px',
                                    minWidth: '180px',
                                    overflow: 'hidden'
                                }}>
                                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                    <Box p={0}>
                                        <Thumbnail
                                            key={"file-compliance-" + item.originalFileName + "_" + index.toString()}
                                            previewPath={item.filePreviewPath}
                                            displayPath={item.fileDisplayPath}
                                            isImage={item.isImage}
                                            text={""}
                                            onPreview={path => this.onSelectFilePathCompliance(path)}
                                            onDownload={path => this.onDownload(path)}
                                        />
                                    </Box>
                                    <Box p={0} flexGrow={1}>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0', color: theme.custom.colors.warning }}>REMOVED</p>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                        <p style={{ borderRadius: '3px', backgroundColor: borderColor, display: 'inline-block', padding: '3px', margin: '3px 0' }}>{item.dateOfExpiry.display}</p>
                                        <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                        <p style={{ marginTop: '3px' }}>Visible To Venues: {item.visibleToVenue ? "true" : "false"}</p>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    )
                }
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"file-compliance-poi-item-" + item.originalFileName + "_" + index.toString()}>
                        <div key={"file-compliance-poi-" + item.originalFileName + "_" + index.toString()}
                            style={{
                                backgroundColor: '#151515',
                                borderRadius: '5px',
                                borderColor: borderColor,
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                margin: '0 10px 10px 0',
                                padding: '10px',
                                minWidth: '180px',
                                overflow: 'hidden'
                            }}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + item.originalFileName + "_" + index.toString()}
                                        previewPath={item.filePreviewPath}
                                        displayPath={item.fileDisplayPath}
                                        isImage={item.isImage}
                                        text={""}
                                        onPreview={path => this.onSelectFilePathCompliance(path)}
                                        onDownload={path => this.onDownload(path)}
                                        onRemove={() => this.removeDocumentCompliance(item)}
                                    />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                    <p style={{ borderRadius: '3px', backgroundColor: borderColor, display: 'inline-block', padding: '3px', margin: '3px 0' }}>{item.dateOfExpiry.display}</p>
                                    <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                    <p style={{ marginTop: '3px' }}>Visible To Venues: {item.visibleToVenue ? "true" : "false"}</p>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                )
            });
        }

        let files = this.state.complianceFiles.map((item, index) => {
            return (
                <Box p={0} key={"file-compliance-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-compliance-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        onPreview={path => this.onSelectFilePathCompliance(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporaryCompliance(index)}
                    />
                </Box >
            );
        })

        let showConfirm = false;
        if (this.state.complianceDocumentToRemove && this.state.complianceDocumentToRemove.securityFirmDocumentId && this.state.complianceDocumentToRemove.securityFirmDocumentId != 0) {
            showConfirm = true;
        }


        let documentsOther;
        let hasDocumentsOther = false;
        if (this.props.otherDocuments) {
            documentsOther = this.props.otherDocuments.filter(item => !item.obsolete || this.props.showObsolete).map((item, index) => {
                hasDocumentsOther = true;
                let borderColor = "#151515";
                if (item.obsolete) {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={"file-other-poi-item-" + item.originalFileName + "_" + index.toString()}>
                            <div key={"file-other-poi-" + item.originalFileName + "_" + index.toString()}
                                style={{
                                    backgroundColor: '#151515',
                                    borderRadius: '5px',
                                    borderColor: theme.custom.colors.warning,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    margin: '0 10px 10px 0',
                                    padding: '10px',
                                    minWidth: '180px',
                                    overflow: 'hidden'
                                }}>
                                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                    <Box p={0}>
                                        <Thumbnail
                                            key={"file-other-" + item.originalFileName + "_" + index.toString()}
                                            previewPath={item.filePreviewPath}
                                            displayPath={item.fileDisplayPath}
                                            isImage={item.isImage}
                                            text={""}
                                            onPreview={path => this.onSelectFilePathOther(path)}
                                            onDownload={path => this.onDownload(path)}
                                        />
                                    </Box>
                                    <Box p={0} flexGrow={1}>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0', color: theme.custom.colors.warning }}>REMOVED</p>
                                        <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                        <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    )
                }
                return (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={"file-other-poi-item-" + item.originalFileName + "_" + index.toString()}>
                        <div key={"file-other-poi-" + item.originalFileName + "_" + index.toString()}
                            style={{
                                backgroundColor: '#151515',
                                borderRadius: '5px',
                                borderColor: borderColor,
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                margin: '0 10px 10px 0',
                                padding: '10px',
                                minWidth: '180px',
                                overflow: 'hidden'
                            }}>
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + item.originalFileName + "_" + index.toString()}
                                        previewPath={item.filePreviewPath}
                                        displayPath={item.fileDisplayPath}
                                        isImage={item.isImage}
                                        text={""}
                                        onPreview={path => this.onSelectFilePathOther(path)}
                                        onDownload={path => this.onDownload(path)}
                                        onRemove={() => this.removeDocumentOther(item)}
                                    />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <p style={{ fontWeight: 'bold', wordBreak: 'break-all', margin: '3px 0' }}>{item.documentType}</p>
                                    <p style={{ wordBreak: 'break-all', marginTop: '3px' }}>{item.originalFileName}</p>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                )
            });
        }

        let filesOther = this.state.otherFiles.map((item, index) => {
            return (
                <Box p={0} key={"file-other-upload-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-other-" + item.fileName + "_" + index.toString()}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        onPreview={path => this.onSelectFilePathOther(path)}
                        onDownload={path => this.onDownload(path)}
                        onRemove={() => this.removeTemporaryOther(index)}
                    />
                </Box >
            );
        })

        let showConfirmOther = false;
        if (this.state.otherDocumentToRemove && this.state.otherDocumentToRemove.securityFirmDocumentId && this.state.otherDocumentToRemove.securityFirmDocumentId != 0) {
            showConfirmOther = true;
        }

        return (
            <PageLayout
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                headerText={"Security Firm Documents"}
            >
                {!hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <NoRowsMessage message={"No compliance documents"} />
                    </ControlGroup>
                )}
                {hasDocuments && (
                    <ControlGroup text="Compliance Documents" labelAbove={true}>
                        <Grid container spacing={1}>
                            {documents}
                        </Grid>
                    </ControlGroup>
                )}
                <CardContainer
                    title={"Upload compliance document"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <SelectControlGroup text={"Document Folder"}
                                    defaultValue={this.state.complianceDocumentTypeId}
                                    onChange={this.onDocumentTypeChangedCompliance}>
                                    <option value={0} />
                                    {this.props.complianceDocumentTypes.map((item, index) => (
                                        <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CheckboxControlGroup
                                    text={"Make Visible to Associated Venues"}
                                    label={" "}
                                    defaultValue={false}
                                    onChanged={(value) => this.onVisibleToVenueChange(value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SimpleDateControlGroup text="Expiry Date"
                                    date={this.state.complianceExpiryDate}
                                    onChange={(value) => this.onExpiryDateChanged(value)} />
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                            {files}
                        </Box>
                        {this.state.complianceDocumentTypeId != 0 && (
                            <>
                                <FileUpload onFileWasUploaded={this.fileWasUploadedCompliance} alternateIcons={true} hasFiles={this.state.complianceFiles.length > 0} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DefaultButton text={"Upload"} disabled={this.state.complianceFiles.length === 0 || this.state.complianceExpiryDate.isEmpty} onClick={this.onUploadCompliance} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </CardContainer>
                {!hasDocumentsOther && (
                    <ControlGroup text="Other Documents" labelAbove={true}>
                        <NoRowsMessage message={"No other documents"} />
                    </ControlGroup>
                )}
                {hasDocumentsOther && (
                    <ControlGroup text="Other Documents" labelAbove={true}>
                        <Grid container spacing={1}>
                            {documentsOther}
                        </Grid>
                    </ControlGroup>
                )}
                <CardContainer
                    title={"Upload other document"}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <SelectControlGroup text={"Document Folder"}
                                    defaultValue={this.state.otherDocumentTypeId}
                                    onChange={this.onDocumentTypeChangedOther}>
                                    <option value={0} />
                                    {this.props.otherDocumentTypes.map((item, index) => (
                                        <option key={item.documentTypeId} value={item.documentTypeId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                            {filesOther}
                        </Box>
                        {this.state.otherDocumentTypeId != 0 && (
                            <>
                                <FileUpload onFileWasUploaded={this.fileWasUploadedOther} alternateIcons={true} hasFiles={this.state.otherFiles.length > 0} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DefaultButton text={"Upload"} disabled={this.state.otherFiles.length === 0} onClick={this.onUploadOther} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </CardContainer>
                <ImagePreview filePath={this.state.complianceSelectedFilePath} onDismissed={() => this.onSelectFilePathCompliance("")} />
                <ConfirmDialog
                    title={"Confirm document removal"}
                    text={"Are you sure you wish to remove '" + this.state.complianceDocumentToRemove.documentType + "' document '" + this.state.complianceDocumentToRemove.originalFileName + "'"}
                    show={showConfirm}
                    onCancel={this.removeDocumentCancelCompliance}
                    onConfirm={this.removeDocumentConfirmedCompliance} />
                <ImagePreview filePath={this.state.otherSelectedFilePath} onDismissed={() => this.onSelectFilePathOther("")} />
                <ConfirmDialog
                    title={"Confirm document removal"}
                    text={"Are you sure you wish to remove '" + this.state.otherDocumentToRemove.documentType + "' document '" + this.state.otherDocumentToRemove.originalFileName + "'"}
                    show={showConfirmOther}
                    onCancel={this.removeDocumentCancelOther}
                    onConfirm={this.removeDocumentConfirmedOther} />
            </PageLayout>
        );
    }
}
export default withStyles(styles, { withTheme: true })(SecurityFirmDocuments);