import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import ErrorBoundary from '../../common/ErrorBoundary';

export interface ICashTraxNarrativeProps {
    incident: IncidentDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class CashTraxNarrative extends Component<ICashTraxNarrativeProps, any> {
    constructor(props: ICashTraxNarrativeProps) {
        super(props)
        this.state = {
            summmary: ""
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        try {
            let summary = "";
            if (this.props.incident && this.props.incident.summary) {
                summary = this.props.incident.summary;
            }
            this.setState({
                summary
            });
        } catch (err) {
            commonLogic.trackException(err, "CashTraxNarrative.componentDidMount", {})
        }
    }

    onChange(value) {
        this.setState({
            summary: value
        }, () => {
            let newIncident = { ...this.props.incident };
            newIncident.summary = value;
            this.props.onUpdateIncident(newIncident)
        });
    }

    render() {
        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Narrative"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ErrorBoundary>
                                            <TextAreaControlGroup text="" defaultValue={this.state.summary}
                                                readonly={false}
                                                defaultRows={2}
                                                onBlur={this.onChange} />
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}