import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

export interface IDarkButtonProps {
    classes: any;
    text?: string;
    onClick?: Function;
    style?: any;
    autoFocus?: boolean;
}

class DarkButton extends React.PureComponent<IDarkButtonProps, any> {
    constructor(props: IDarkButtonProps){
        super(props)
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <Button
                variant="contained"
                onClick={this.onClick}
                color="default"
                autoFocus={this.props.autoFocus}
                className={classNames(classes.buttonDark, classes.buttonState)}
                style={{ width: '100%', height: '100%', ...this.props.style }}>{this.props.text}{this.props.children}</Button>
        );
    }
}

export default withStyles(styles, { withTheme: true })(DarkButton);