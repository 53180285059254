import React, { Component } from 'react';
import { withRouter } from "react-router";
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';

export interface IIncidentViewParentProps {
    incident: IncidentDTO;
    canShowVersions?: boolean;
    history: any;
    location: any;
    classes: any;
    theme: any;
}

class IncidentViewParent extends Component<IIncidentViewParentProps, any> {
    constructor(props: IIncidentViewParentProps) {
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick(incidentId: number){
        if (this.props.history && this.props.canShowVersions) {
            this.props.history.push('/incidentview/' + this.props.incident.incidentId + "/versions");
        }
    }

    render() {
        if (!this.props.incident.parentIncidentId || this.props.incident.parentIncidentId === 0) {
            return (<></>);
        }
        let incident = (
            <span><a href="#" onClick={e=> {this.onClick(this.props.incident.parentIncidentId); e.preventDefault(); } } style={{ color: '#03a9f4', textDecoration: 'underline'}}>{this.props.incident.parentIncidentName}</a></span>
        );
        
        return (
            <ControlGroup text="Parent Incident">
                <TextDisplay>{incident}</TextDisplay>
            </ControlGroup>
        );
    }
}


export default withStyles(styles, { withTheme: true })(withRouter(IncidentViewParent));