import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import CardTitle from '../common/CardTitle';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { blockStatement } from '@babel/types';
import ErrorButton from '../controls/ErrorButton';

export interface IDuplicateMobileNumbersCardProps {
    user: AusComplyDtos.User;
    onUpdate: Function;
    onOpen: Function;
    classes: any;
    theme: any;
}

class DuplicateMobileNumbersCard extends React.Component<IDuplicateMobileNumbersCardProps, any> {
    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        return (
            <Card style={{ marginTop: '6px', marginBottom: '6px' }} className={className}>
                <CardContent>
                    <Grid container spacing={1} >
                        <Grid item xs={12}  onClick={() => this.props.onOpen(this.props.user)}>
                            <CardTitle>Name</CardTitle>
                            <CardTypography style={{ textDecoration: 'underline' }}>{this.props.user.displayName}</CardTypography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>Email</CardTitle>
                            <CardTypography>{this.props.user.email}</CardTypography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CardTitle>Mobile</CardTitle>
                            <CardTypography>
                                {this.props.user.mobileNo}
                                {this.props.user.mobileNo != "9999999999" && (
                                    <ErrorButton text={"Reset"} style={{ width: 'auto', marginLeft: '15px', padding: '2px' }} onClick={() => this.props.onUpdate(this.props.user)} />
                                )}
                            </CardTypography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(DuplicateMobileNumbersCard);